import { Form } from 'react-final-form';
import {
    canSendRentIncreaseLetter,
    isLeaseTypeResidentialTenancy,
    isLongTermRenewalSubLease,
    needWitnessSignature
} from '../../../../utils/agreementUtils';
import {
    NSW_STATE,
    COMPLETION_STATUS_STARTED,
    COMPLETION_STATUS_ERROR,
    COMPLETION_STATUS_PROCESSING
} from '../../../../config';
import ReactTooltip from 'react-tooltip';
import React, { memo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    startCompletionProcess,
    finishCompletionProcess,
    updateAgreementLoadingState
} from '../../../../actions/dashboard';
import { isEmpty } from 'lodash';
import {
    getAgentSignature,
    getCompletionErrors,
    getCompletionForm,
    getWitnessSignature
} from '../../../../selectors/completion';
import { useAgreementUpdate } from '../../../../hooks/useAgreementUpdate';
import CompletionProgress from './CompletionProgress';
import * as dashboard from '../../../../actions/dashboard';
import { getResTenLabel } from '../../../../utils/labelUtils';
import ButtonGroup from '../../../../common/components/ButtonGroup';
import { CheckboxCheck } from '../../../form/FormCheckboxCheck';
import { getIntegrationLeaseDataSync } from '@app/selectors/lease';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { RTA_OWNER_COPY } from '../../../../constants/featureFlags';
import { EmailOwnerCopySendDestination } from '../../../../constants/constants';
import arrayMutators from 'final-form-arrays';

import { RadioButtonGroup, RadioButtonGroupStyle } from '@app/common/design-system/radio-button-group/RadioButtonGroup';
import { isCorrectEmail } from '@app/components/Validate';
import styles from './AwaitingCompletion.module.scss';
import { hydrateValidationErrors } from '../../../../utils/finalFormUtils';
import SuggestionTagInput from '@app/components/form/SuggestionTagInput';
import * as yup from 'yup';
import EmailPreview from './EmailPreview';

const SEND_TYPE_OPTIONS = [
    { label: 'Send to Myself', value: EmailOwnerCopySendDestination.AGENT },
    { label: "Send to Owner with Agent CC'ed", value: EmailOwnerCopySendDestination.AGENT_AND_LANDLORD }
];

const emailValidation = yup
    .string()
    .test('is-correct-email', 'Please enter a valid email address', email => !email || isCorrectEmail(email));

const submitSchema = yup.object().shape({
    emailOwnerCopySendDestinationEmails: yup.array().when('emailOwnerCopySendDestination', {
        is: EmailOwnerCopySendDestination.AGENT_AND_LANDLORD,
        then: () =>
            yup
                .array()
                .required('Please enter at least one email')
                .min(1, 'Please enter at least one emails')
                .of(emailValidation)
    })
});

const AwaitingCompletion = props => {
    const dispatch = useDispatch();

    // Feature flag for RTA owner copy.
    const isRTAOwnerCopyEnabled = useFeatureFlag(RTA_OWNER_COPY);

    const leaseAllInfo = props.leaseAllInfo;
    const leaseId = leaseAllInfo.id;

    const landlordAcknowledged = props.landlordAcknowledged;

    const [emailToOwner, setEmailToOwner] = useState(!isLongTermRenewalSubLease(leaseAllInfo.subLeaseType));
    const [isSendingFinishCompletion, setIsSendingFinishCompletion] = useState(false);
    const [overrideLandlordAcknowledgement, setOverrideLandlordAcknowledgement] = useState(
        props.overrideLandlordAcknowledgement
    );
    const [progress, setProgress] = useState(leaseAllInfo.completion ? leaseAllInfo.completion.progress : []);

    const agentSignature = useSelector(getAgentSignature);
    const witnessSignature = useSelector(getWitnessSignature);
    const completionErrors = useSelector(getCompletionErrors);
    const completion = useSelector(getCompletionForm);
    const integrationDataSync = useSelector(getIntegrationLeaseDataSync);

    const isCompletionStarted = leaseAllInfo.completion && leaseAllInfo.completion.status === COMPLETION_STATUS_STARTED;
    const isCompletionError = leaseAllInfo.completion && leaseAllInfo.completion.status === COMPLETION_STATUS_ERROR;
    const isCompletionProcessing =
        leaseAllInfo.completion && leaseAllInfo.completion.status === COMPLETION_STATUS_PROCESSING;

    const isLeaseLocationNSW = leaseAllInfo.location === NSW_STATE;

    const disableSignButton = isLeaseLocationNSW && !(landlordAcknowledged || overrideLandlordAcknowledgement);

    let disabledFinishCompletion = needWitnessSignature(leaseAllInfo)
        ? isEmpty(agentSignature) || isEmpty(witnessSignature) || isSendingFinishCompletion
        : isEmpty(agentSignature) || isSendingFinishCompletion;

    const sendRentIncreaseLetter = canSendRentIncreaseLetter(leaseAllInfo);
    const leaseType = leaseType ? leaseType : leaseAllInfo.leaseType;

    let closed = false;

    /**
     * Hook to listen to updates of agreements within an agency
     */
    useAgreementUpdate(leaseAllInfo.agency, item => {
        if (item.data && item.data.id === leaseId) {
            if (item.data.completion && item.data.completion.progress) {
                setProgress(item.data.completion.progress);

                if (item.data.completion.status === 'finished') {
                    props.scrollToBottom();

                    setTimeout(() => {
                        if (!closed) {
                            dispatch(dashboard.closeAgreementInfoModal());
                            closed = true;
                        }
                    }, 3000);
                }

                // If the completion has stopped because of an error
                if (item.data.completion.status === COMPLETION_STATUS_ERROR && leaseAllInfo.isLoading) {
                    dispatch(updateAgreementLoadingState(leaseAllInfo.id, false));
                }
            }
        }
    });

    const handleStartCompletionProcess = () => {
        dispatch(startCompletionProcess(leaseId, overrideLandlordAcknowledgement));
        props.scrollToTop();
    };

    const handleFinishCompletionProcess = values => {
        const {
            leaseAllInfo: { id }
        } = props;

        let data = {
            toNotificationList: completion.toNotificationList,
            ccNotificationList: completion.ccNotificationList,
            bccNotificationList: completion.bccNotificationList,
            emailToOwner: emailToOwner, // values.emailToOwner,
            emailOwnerCopySendDestination: values?.emailOwnerCopySendDestination,
            emailOwnerCopySendDestinationEmails: values?.emailOwnerCopySendDestinationEmails,
            sendRentIncreaseLetter: sendRentIncreaseLetter ? values.isSendRentIncreaseLetter : false,
            isSendMeEmail: values.isSendMeEmail,
            witnessSignature: witnessSignature ? witnessSignature.id : null,
            integrationDataSync: integrationDataSync.enabled ? integrationDataSync : null
        };

        if (completion.renewalAlert === 'custom') {
            data.renewalAlert = {
                days: completion.renewalDays
            };
        } else if (completion.renewalAlert !== 'none') {
            data.renewalAlert = {
                days: completion.renewalAlert
            };
        }

        setIsSendingFinishCompletion(true);
        // generating agreement has started
        dispatch(updateAgreementLoadingState(id));
        dispatch(finishCompletionProcess(id, data))
            .then(() => {
                // immediate sending finish completion has finished
                setIsSendingFinishCompletion(false);
            })
            .catch(() => {
                // immediate sending finish completion has finished
                setIsSendingFinishCompletion(false);
                // generating agreement has not started
                dispatch(updateAgreementLoadingState(id, false));
            });
    };

    const isLeaseRTA = isLeaseTypeResidentialTenancy(leaseType);

    let leaseTypeLabel = isLeaseRTA ? 'lease' : 'agreement';
    if (isLongTermRenewalSubLease(leaseAllInfo.subLeaseType)) {
        leaseTypeLabel = 'Notice of Lease Extension';
    }

    const shouldShowEmailOwnerCopySection =
        isLeaseRTA && // Lease type is Residential Tenancy
        (isCompletionStarted || isCompletionError); // Completion process has started or failed

    return (
        <React.Fragment>
            <Form
                onSubmit={values => {
                    const errors = hydrateValidationErrors(() =>
                        submitSchema.validateSync(values, { abortEarly: false })
                    );
                    if (errors) {
                        return errors;
                    }
                    handleFinishCompletionProcess(values);
                }}
                initialValues={{
                    isSendRentIncreaseLetter: true,
                    emailToOwner,
                    isSendMeEmail: false,
                    emailOwnerCopySendDestination: EmailOwnerCopySendDestination.AGENT
                }}
                mutators={{ ...arrayMutators }}
            >
                {({ handleSubmit, errors, values }) => {
                    const showPreviewEmailLink =
                        shouldShowEmailOwnerCopySection &&
                        values?.emailOwnerCopySendDestination === EmailOwnerCopySendDestination.AGENT_AND_LANDLORD;
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="section space-between docs-row">
                                <h6>Documents</h6>
                                <div>
                                    {props.saveStatusTimeout && props.leasePdfEmailSentSuccess && (
                                        <span className="savestatus-saved">
                                            Email Preview {leaseTypeLabel} PDF Sent Successfully
                                            <span className="icon savestatus-icon" />
                                        </span>
                                    )}

                                    {props.saveStatusTimeout &&
                                        props.leasePdfEmailSentFail &&
                                        props.action.indexOf('sendingLease') > -1 && (
                                            <span className="savestatus-failed">
                                                Email Preview {leaseTypeLabel} PDF Failed
                                                <span className="icon savestatus-icon" />
                                            </span>
                                        )}
                                    <ButtonGroup
                                        title={`Copy of ${leaseTypeLabel}`}
                                        toolTip="This will email you/open a copy of the document for review"
                                        buttonList={[
                                            {
                                                onClick: props.downloadLeasePDF,
                                                disabled: false,
                                                className: 'logo-button',
                                                label: 'View PDF',
                                                icon: 'view'
                                            },
                                            {
                                                onClick: props.emailLeasePDF,
                                                disabled: props.isSendingLeasePdfEmail,
                                                className: 'logo-button',
                                                label: 'Send me PDF',
                                                icon: 'send',
                                                loading: props.isSendingLeasePdfEmail
                                            }
                                        ]}
                                    />
                                    <props.Section48PdfButton leaseAllInfo={leaseAllInfo} />
                                </div>
                            </div>

                            {isRTAOwnerCopyEnabled && (isCompletionStarted || isCompletionError) && (
                                <div className="section space-between docs-row">
                                    {shouldShowEmailOwnerCopySection && (
                                        <>
                                            <h6>Email the Owner Copy of the Lease</h6>
                                            <div className={styles.emailOwnerGroup}>
                                                <span>The Tenant’s Contact details will be removed from the copy.</span>
                                                <RadioButtonGroup
                                                    name="emailOwnerCopySendDestination"
                                                    radioGroupStyle={RadioButtonGroupStyle.SEPARATED}
                                                    options={SEND_TYPE_OPTIONS}
                                                    disabled={leaseAllInfo.isLoading}
                                                />

                                                {values.emailOwnerCopySendDestination ===
                                                    EmailOwnerCopySendDestination.AGENT_AND_LANDLORD && (
                                                    <SuggestionTagInput
                                                        name={`emailOwnerCopySendDestinationEmails`}
                                                        suggestionsFieldName={`landlordEmails`}
                                                        tagInputProps={{
                                                            label: 'Owner Email',
                                                            placeholder: 'Enter email',
                                                            required: true,
                                                            validateTag: isCorrectEmail,
                                                            addTagOnBlur: true,
                                                            disabled: leaseAllInfo.isLoading
                                                        }}
                                                    />
                                                )}
                                                {showPreviewEmailLink && <EmailPreview leaseInfo={leaseAllInfo} />}
                                            </div>
                                        </>
                                    )}
                                    {isLeaseLocationNSW && (sendRentIncreaseLetter || isLeaseRTA) && (
                                        <div className={styles.additionActionsSection}>
                                            <span className={styles.additionActions}>Additional Actions</span>
                                            {sendRentIncreaseLetter && (
                                                <CheckboxCheck
                                                    className="footer-checkbox"
                                                    name="isSendRentIncreaseLetter"
                                                    label="Send the Rent Increase Letter to the Tenant (with Agent CC'ed)"
                                                />
                                            )}
                                            {isLeaseRTA && (
                                                <CheckboxCheck
                                                    className="footer-checkbox"
                                                    name="isSendMeEmail"
                                                    label="Send me an email with Tenant's details for Strata Roll update"
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}

                            {!isCompletionStarted && !isCompletionProcessing && !isCompletionError && (
                                <div className="section space-between">
                                    {isLeaseLocationNSW && !landlordAcknowledged && (
                                        <div className="footer-checkbox-wrap">
                                            <CheckboxCheck
                                                className="footer-checkbox"
                                                value={overrideLandlordAcknowledgement}
                                                name="overrideLandlordAcknowledgement"
                                                disabled={landlordAcknowledged}
                                                label="Landlord has acknowledged information statement outside of FLK it over"
                                                onClick={value => setOverrideLandlordAcknowledgement(value)}
                                            />
                                        </div>
                                    )}
                                    <div className="footer footer-draft">
                                        <div className="button-row">
                                            {disableSignButton && (
                                                <ReactTooltip
                                                    id="landlordAcknowledge"
                                                    class="tooltip"
                                                    place="top"
                                                    effect="solid"
                                                    globalEventOff="wheel"
                                                >
                                                    <p>
                                                        The landlord has not yet acknowledged the information statement,
                                                        either override it above, or wait for the landlord to come back.
                                                    </p>
                                                </ReactTooltip>
                                            )}

                                            <div className="button" data-tip={true} data-for="landlordAcknowledge">
                                                <button
                                                    type="button"
                                                    className="complete"
                                                    onClick={handleStartCompletionProcess}
                                                    disabled={disableSignButton}
                                                >
                                                    Sign
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-help-text for-lg-modal">
                                        Once pressed, agreement will only allow agent signing
                                    </div>
                                </div>
                            )}
                            {isCompletionProcessing && (
                                <div className="section space-between">
                                    <CompletionProgress progress={progress} />
                                    <div className="footer-help-text for-lg-modal">
                                        The agreement is processing, you can close this window.
                                    </div>
                                </div>
                            )}
                            {(isCompletionStarted || isCompletionError) && (
                                <div className="section space-between submit-row">
                                    <div className="footer footer-draft">
                                        {!isRTAOwnerCopyEnabled && (
                                            <>
                                                {isLeaseRTA && (
                                                    <CheckboxCheck
                                                        className="footer-checkbox"
                                                        name="emailToOwner"
                                                        value={emailToOwner}
                                                        label={`Email me a copy of the lease for the owner with the ${getResTenLabel(
                                                            'tenantsLower',
                                                            leaseAllInfo.location
                                                        )} contact details removed`}
                                                        onClick={value => setEmailToOwner(value)}
                                                    />
                                                )}
                                                {sendRentIncreaseLetter && isLeaseLocationNSW && (
                                                    <CheckboxCheck
                                                        className="footer-checkbox"
                                                        name="isSendRentIncreaseLetter"
                                                        label="Send rent increase letter to tenant (with agent Cc’ed)"
                                                    />
                                                )}
                                                {isLeaseRTA && isLeaseLocationNSW && (
                                                    <CheckboxCheck
                                                        className="footer-checkbox"
                                                        name="isSendMeEmail"
                                                        label="Send me an email with tenants details for strata roll update"
                                                    />
                                                )}
                                            </>
                                        )}
                                        {(completionErrors || isCompletionError) && (
                                            <span className="savestatus-failed">
                                                Failed to sign and complete, please try again or contact us on live chat
                                                <span className="icon savestatus-icon" />
                                            </span>
                                        )}
                                        <div className="button btn-await-completion">
                                            <button
                                                type="submit"
                                                disabled={disabledFinishCompletion || leaseAllInfo.isLoading}
                                            >
                                                {(isSendingFinishCompletion || leaseAllInfo.isLoading) && (
                                                    <span className="savestatus-spinner-await-completion" />
                                                )}
                                                Sign and complete
                                            </button>
                                        </div>
                                    </div>
                                    <div className="footer-help-text for-lg-modal">
                                        {isLeaseRTA && isLeaseLocationNSW
                                            ? 'By clicking "Sign and Complete", the Lease and Landlord Information Statement Acknowledgement will be signed and completed.'
                                            : 'By clicking "Sign and Complete", the Lease will be signed and completed.'}
                                    </div>
                                    <CompletionProgress progress={progress} />
                                </div>
                            )}
                        </form>
                    );
                }}
            </Form>
        </React.Fragment>
    );
};

AwaitingCompletion.propTypes = {
    leaseAllInfo: PropTypes.any,
    saveStatusTimeout: PropTypes.any,
    leasePdfEmailSentSuccess: PropTypes.any,
    leasePdfEmailSentFail: PropTypes.any,
    landlordAcknowledged: PropTypes.bool,
    action: PropTypes.any,
    downloadLeasePDF: PropTypes.func,
    emailLeasePDF: PropTypes.func,
    isSendingLeasePdfEmail: PropTypes.any,
    scrollToTop: PropTypes.any,
    scrollToBottom: PropTypes.any,
    overrideLandlordAcknowledgement: PropTypes.any
};

export default memo(AwaitingCompletion);
