import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboard from '../../../../actions/dashboard';
import { SelectField } from '../../../form/FormSelect';
import { FormRadioGroup } from '../../../form/FormRadioGroup';
import {
    isLeaseTypeSales,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseLocationVIC,
    isValidRenewalFlowDetails
} from '../../../../utils/agreementUtils';
import { getAgency } from '../../../../selectors/user';
import { getLeaseInfo } from '../../../../selectors/lease';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../../sass/resendModal.scss';
import '../../../../sass/reactConfirmAlert.scss';
import { getLabel } from '../../../../utils/labelUtils';
import { LEASE_TYPE_PROPERTY_MANAGEMENT, LEASE_TYPE_SALES, OPTION_TYPE_YES_OR_NO } from '../../../../config';
import Button from '../../../../common/components/Button';
import { FormYesNoCheckbox, FormDoneCheckbox } from '../../../form/responsive/FormSingleCheckbox';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { updateLeaseInfo } from '../../../../actions/lease';
import { updateManualSignorLinkGenerateSuccess } from '../../../../actions/dashboard';
import Tooltip from '../../../../common/components/tooltips/Tooltip';
import ButtonWithDropdown from '../../../../common/components/ButtonWithDropdown';
import { Icons } from '../../../../common/components/Icon';
export default function SendForSigningContainer({
    leaseAllInfo,
    isPostSigningFail,
    postSigningFailMessage,
    isPostReSigningFail,
    optionsNumber,
    leaseType,
    values,
    isSubscriptionCancelled,
    isLoading,
    handleSubmit
}) {
    const dispatch = useDispatch();
    const agencySelected = useSelector(getAgency);
    const lease = useSelector(getLeaseInfo);

    const actionItems = [
        {
            actionHandler: () => {
                getSignorLinks.mutate(values);
            },
            text: 'Get Signing Link(s)',
            icon: Icons.LINK
        }
    ];
    const sendForSigningActionItems = [
        {
            actionHandler: handleSubmit,
            text: 'Send for signing',
            icon: Icons.SIGN
        }
    ];

    const getSignorLinks = useMutation(
        formData => {
            return axios.post(`/api/agency/lease/${lease.id}/signorsLinks`, formData);
        },
        {
            onSuccess: result => {
                if (result?.data?.lease) {
                    dispatch(updateManualSignorLinkGenerateSuccess(true));
                }
            }
        }
    );

    const updateChecklistItem = useMutation(data => {
        return axios.post(`/api/agency/lease/${lease.id}/update-checklist`, data);
    });

    const checkCheckListItem = (clause, answer) => {
        updateChecklistItem.reset();
        updateChecklistItem.mutate(
            {
                ...clause,
                answer
            },
            {
                onSuccess: result => {
                    if (result?.data?.lease) {
                        dispatch(updateLeaseInfo(result.data.lease));
                    }
                }
            }
        );
    };

    const isChecklistCompletedFunction = () => {
        if (
            lease.status === dashboard.LEASE_STATUS_DRAFT &&
            agencySelected?.complianceChecklist?.clauseList.length > 0
        ) {
            let isCompleted = true;
            agencySelected.complianceChecklist.clauseList.forEach((clause, index) => {
                const leaseClause = lease?.complianceChecklistAnswers?.clauseList?.find(
                    c => c?.defaultSettingClauseId?.toString() === clause?._id?.toString()
                );
                if (!leaseClause) {
                    isCompleted = false;
                }
            });
            return isCompleted;
        }
        return true;
    };

    const isChecklistCompleted = useMemo(() => isChecklistCompletedFunction(), [agencySelected, lease]);

    return (
        <>
            {isLeaseTypeResidentialTenancy(lease.leaseType) &&
                lease.status === dashboard.LEASE_STATUS_DRAFT &&
                agencySelected?.complianceChecklist?.clauseList.length > 0 && (
                    <div className="checklist">
                        <h2>Checklist</h2>
                        {agencySelected.complianceChecklist.clauseList.map((clause, index) => {
                            const leaseClause = lease?.complianceChecklistAnswers?.clauseList?.find(
                                c => c?.defaultSettingClauseId?.toString() === clause?._id?.toString()
                            );
                            return (
                                <div key={index}>
                                    {clause.optionType === OPTION_TYPE_YES_OR_NO ? (
                                        <FormYesNoCheckbox
                                            name={`checklist-item-${clause._id}`}
                                            text={clause.description}
                                            onClick={answer => checkCheckListItem(clause, answer)}
                                            reverse
                                            value={leaseClause?.answer}
                                            hideDefaultValue={!leaseClause}
                                        />
                                    ) : (
                                        <FormDoneCheckbox
                                            name={`checklist-item-${clause._id}`}
                                            text={clause.description}
                                            onClick={answer => checkCheckListItem(clause, answer)}
                                            reverse
                                            value={leaseClause?.answer}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            <div className="send-for-signing-container">
                {leaseType.toLowerCase() !== 'sales' && (
                    <>
                        <div className="deadline-container for-lg-modal">
                            <SelectField
                                label="Deadline"
                                name="deadline"
                                options={optionsNumber}
                                containerClassName="deadline-select"
                            />
                        </div>
                        <div className="btn-group for-sm-modal">
                            <FormRadioGroup
                                label="Select deadline"
                                name="deadline"
                                data={optionsNumber}
                                value={values ? values.deadline : null}
                                radioGroupClass="wrapped-radio-group"
                            />
                        </div>
                    </>
                )}
                <div
                    className="button btn-footer"
                    data-tip="Please complete all items in compliance checklist"
                    data-for="send-for-signing-button"
                >
                    {isLeaseTypeResidentialTenancy(lease.leaseType) && !isChecklistCompleted && (
                        <Tooltip place="top" effect="solid" id="send-for-signing-button" html />
                    )}
                    {(isLeaseTypePm(leaseType) || isLeaseTypeSales(leaseType)) &&
                    leaseAllInfo.status === dashboard.LEASE_STATUS_DRAFT ? (
                        <>
                            {lease.createdViaUrl ? (
                                <ButtonWithDropdown
                                    buttonText="Get sharing link"
                                    buttonType="button"
                                    onclickHandler={() => getSignorLinks.mutate(values)}
                                    buttonClassName="send-signing"
                                    actionItems={sendForSigningActionItems}
                                    isLoading={getSignorLinks.isLoading}
                                    buttonLoadingText="Sending for signing"
                                    isActionLoading={isLoading}
                                    disabled={
                                        (leaseAllInfo.status === dashboard.LEASE_STATUS_DRAFT &&
                                            isSubscriptionCancelled()) ||
                                        !isValidRenewalFlowDetails(lease, agencySelected) ||
                                        (isLeaseTypeResidentialTenancy(lease.leaseType) && !isChecklistCompleted)
                                    }
                                />
                            ) : (
                                <ButtonWithDropdown
                                    buttonText="Send for signing"
                                    buttonType="submit"
                                    buttonClassName="send-signing"
                                    actionItems={actionItems}
                                    isLoading={isLoading}
                                    buttonLoadingText="Generating Link(s)"
                                    isActionLoading={getSignorLinks.isLoading}
                                    disabled={
                                        (leaseAllInfo.status === dashboard.LEASE_STATUS_DRAFT &&
                                            isSubscriptionCancelled()) ||
                                        !isValidRenewalFlowDetails(lease, agencySelected) ||
                                        (isLeaseTypeResidentialTenancy(lease.leaseType) && !isChecklistCompleted)
                                    }
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <Button
                                className="send-signing"
                                type="submit"
                                disabled={
                                    (leaseAllInfo.status === dashboard.LEASE_STATUS_DRAFT &&
                                        isSubscriptionCancelled()) ||
                                    !isValidRenewalFlowDetails(lease, agencySelected) ||
                                    (isLeaseTypeResidentialTenancy(lease.leaseType) && !isChecklistCompleted)
                                }
                                loading={isLoading}
                                primary
                            >
                                Send for signing
                            </Button>
                        </>
                    )}
                    {!getSignorLinks.isLoading && (
                        <SendForSigningMessage
                            location={leaseAllInfo.location}
                            leaseType={leaseType}
                            isPostSigningFail={isPostSigningFail}
                            postSigningFailMessage={postSigningFailMessage}
                            isPostReSigningFail={isPostReSigningFail}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

function SendForSigningMessage({
    location,
    leaseType,
    isPostSigningFail,
    postSigningFailMessage,
    isPostReSigningFail
}) {
    if (!isPostSigningFail && !isPostReSigningFail) {
        let message = '';
        if (isLeaseTypeResidentialTenancy(leaseType)) {
            message = `The lease will be sent to the ${
                isLeaseLocationVIC(location) ? 'renter' : 'tenant'
            }(s) via SMS for signing`;
        } else if (isLeaseTypeSales(leaseType)) {
            const label = getLabel('tenant', location, LEASE_TYPE_SALES);
            message = `The agreement will be sent to the ${label}(s) via SMS for signing`;
        } else if (isLeaseTypePm(leaseType)) {
            const label = getLabel('signorsHeader', location, LEASE_TYPE_PROPERTY_MANAGEMENT);
            message = `The agreement will be sent to the ${label} via SMS for signing`;
        }
        return (
            <div className="footer-help-text for-lg-modal">
                <p>{message}</p>
            </div>
        );
    }
    if (isPostSigningFail || isPostReSigningFail) {
        return (
            <div className="footer-help-text-error">
                <p className="error-message">
                    {postSigningFailMessage || (
                        <>
                            Something went wrong sending the agreement, you can try again or you move it to drafts and
                            check if all the details are correct. If this doesn't solve the problem, please contact us.
                        </>
                    )}
                </p>
            </div>
        );
    }
    return <></>;
}
