import React, { memo, useState } from 'react';

import cx from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import { removeDocumentInList, closeAllDocumentModals } from '../../actions/document';
import { useDispatch } from 'react-redux';
import { useRollbarProvider } from '../providers/RollbarProvider';
import * as dashboard from '../../actions/dashboard';
import '../../sass/popupLeaseMore.scss';

const PopupBasicDocumentsMore = props => {
    const dispatch = useDispatch();
    const Rollbar = useRollbarProvider();

    const docTypeToServer = {
        rent_increase_agreement: 'rent-increase',
        rent_reduction_agreement: 'rent-relief',
        termination_notice: 'termination-notice'
    };

    const handleClickOutside = event => {
        // close the list when list is open and clicked outside the list
        // ignore this if user click more-action button
        if (event.composedPath()[0].className !== 'more-action' || props.morePopupListAgreements) {
            props.closeMorePopupList();
        }
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    function docTypeToServerPath(docType) {
        return docTypeToServer[docType];
    }

    const deleteDocumentRequest = documentId => {
        return axios.delete(`/api/document/${documentId}`);
    };

    const deleteDocument = e => {
        e.stopPropagation();
        confirmAlert({
            title: '',
            message: `Are you sure want to delete this Document?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        return deleteDocumentRequest(props.doc.id)
                            .then(() => {
                                props.closeMorePopupList();
                                dispatch(removeDocumentInList(props.doc));
                                dispatch(closeAllDocumentModals());
                            })
                            .catch(error => {
                                Rollbar.error(
                                    'Failed to delete basic document',
                                    {
                                        error_message: error.message,
                                        status: 'error',
                                        env: ENVIRONMENT
                                    },
                                    error
                                );
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const { morePopupInfoAgreement, agreementStatus, morePopupListAgreements } = props;

    return (
        <div
            ref={ref}
            className={cx(
                morePopupInfoAgreement
                    ? 'popup-draft-create'
                    : morePopupListAgreements
                    ? 'popup-draft-create create-list'
                    : 'popup-draft-create hide'
            )}
        >
            {agreementStatus === dashboard.LEASE_STATUS_DRAFT && (
                <div>
                    <div>
                        <div className="icon-block" onClick={e => props.cloneDocument(e)}>
                            <i className="icon icon-duplicate" />
                            <p className="popup-draft-p">Clone document</p>
                        </div>
                    </div>
                    <div className="icon-block" onClick={e => deleteDocument(e)}>
                        <i className="icon icon-cancel-sign" />
                        <p className="popup-draft-p">Delete document</p>
                    </div>
                </div>
            )}
            {agreementStatus === dashboard.DOC_STATUS_ACTIVE && (
                <div>
                    <div className="icon-block" onClick={e => props.cancelDocument(e)}>
                        <i className="icon icon-cancel-sign" />
                        <p className="popup-draft-p">Cancel document</p>
                    </div>
                    <div className="icon-block" onClick={e => props.cancelCloneDocument(e)}>
                        <i className="icon icon-cancel-sign" />
                        <p className="popup-draft-p">Cancel & clone</p>
                    </div>
                </div>
            )}

            {agreementStatus === dashboard.DOC_STATUS_CANCELLED && (
                <div>
                    <div className="icon-block" onClick={e => props.cloneDocument(e)}>
                        <i className="icon icon-duplicate" />
                        <p className="popup-draft-p">Clone document</p>
                    </div>
                </div>
            )}
        </div>
    );
};

PopupBasicDocumentsMore.defaultProps = {
    showResendButton: true
};

export default memo(PopupBasicDocumentsMore);
