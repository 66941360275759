import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import { editPermissions } from '../../../utils/userPermissions';
import { tabs } from '../../../config';
import Panel from '../../../common/components/Panel';
import { getUserInfo } from '../../../selectors/user';
import { getAgency } from '../../../selectors/settings/account';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { has } from 'lodash';
import axios from 'axios';
import SaveButtonIcon from '../SaveButtonIcon';

const IdentificationImagesForm = () => {
    const loggedInUser = useSelector(getUserInfo);
    const agencySelected = useSelector(getAgency);

    const saveIdentificationImageSettings = useMutation(data => {
        return axios.put(`/api/agency/${agencySelected.id}/identification-images`, data);
    });

    const handleSubmit = async values => {
        // Reset mutate data
        // Otherwise it will keep old data and display old error/success messages
        saveIdentificationImageSettings.reset();
        try {
            await saveIdentificationImageSettings.mutateAsync(values);
        } catch (error) {
            let err = {};
            if (has(error, 'response.data.errors.identificationImages')) {
                err = error.response.data.errors.identificationImages;
            }
            return err;
        }
    };

    let userRole = loggedInUser.role;

    return (
        <Form initialValues={agencySelected.identificationImages} onSubmit={handleSubmit}>
            {({ handleSubmit, values }) => {
                return (
                    <form onSubmit={handleSubmit} id="identificationImagesForm">
                        <Panel
                            title="IDENTIFICATION IMAGES"
                            rightButton={
                                <div>
                                    {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                        <SaveButtonIcon
                                            isSaving={saveIdentificationImageSettings.isLoading}
                                            success={saveIdentificationImageSettings.isSuccess}
                                            failure={saveIdentificationImageSettings.isError}
                                        />
                                    )}
                                </div>
                            }
                        >
                            <p className="text-gray">
                                Determines whether agreements will request users to upload proof of identity during the
                                signing process.
                            </p>
                            <br />
                            <CheckboxCheck
                                name="propertyManagementIdentificationRequired"
                                label="Property Management Agreements - Require all clients to upload proof of identity"
                            />
                            <CheckboxCheck
                                name="salesIdentificationRequired"
                                label="Sales Agreements - Require all clients to upload proof of identity"
                            />
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};

export default IdentificationImagesForm;
