const { CLIENT_TRADING_NAME } = require('../config');
export const CreateAFLKGeneralFromFormFields = [
    {
        client: CLIENT_TRADING_NAME,
        fields: [
            {
                prerequisite: [],
                label: 'Trading Name',
                mappingFieldName: 'agencyName',
                mandatory: true,
                value: '',
                order: 0
            },
            {
                prerequisite: [],
                label: 'Address',
                mappingFieldName: 'address',
                mandatory: false,
                value: '',
                order: 1
            },
            {
                prerequisite: [],
                label: 'Company Name',
                mappingFieldName: 'companyName',
                mandatory: false,
                value: '',
                order: 2
            },
            {
                prerequisite: [],
                label: 'Email',
                mappingFieldName: 'email',
                mandatory: true,
                value: '',
                order: 3
            },
            {
                prerequisite: [],
                label: 'Phone',
                mappingFieldName: 'phone',
                mandatory: false,
                value: '',
                order: 4
            }
        ]
    },
    {
        client: 'Other',
        fields: [
            {
                prerequisite: [
                    {
                        isCorporation: false
                    }
                ],
                label: 'First Name',
                mappingFieldName: 'firstName',
                mandatory: true,
                value: '',
                order: 0
            },
            {
                prerequisite: [
                    {
                        isCorporation: false
                    }
                ],
                label: 'Middle Name',
                mappingFieldName: 'middleName',
                mandatory: false,
                value: '',
                order: 1
            },
            {
                prerequisite: [
                    {
                        isCorporation: false
                    }
                ],
                label: 'Last Name',
                mappingFieldName: 'secondName',
                mandatory: true,
                value: '',
                order: 2
            },
            {
                prerequisite: [
                    {
                        isCorporation: false
                    }
                ],
                label: 'Address',
                mappingFieldName: 'address',
                mandatory: false,
                value: '',
                order: 3
            },
            {
                prerequisite: [
                    {
                        isCorporation: true
                    }
                ],
                label: 'Corporation Name',
                mappingFieldName: 'corporationName',
                mandatory: true,
                value: '',
                order: 4
            },
            {
                prerequisite: [
                    {
                        isCorporation: true
                    }
                ],
                label: 'ABN / ACN',
                mappingFieldName: 'ABN',
                mandatory: true,
                value: '',
                order: 5
            },
            {
                prerequisite: [
                    {
                        isCorporation: true
                    }
                ],
                label: 'Corporation Address',
                mappingFieldName: 'address',
                mandatory: true,
                value: '',
                order: 6
            },
            {
                prerequisite: [],
                label: 'Mobile',
                mappingFieldName: 'phone',
                mandatory: true,
                value: '',
                order: 7
            },
            {
                prerequisite: [],
                label: 'Email',
                mappingFieldName: 'email',
                mandatory: true,
                value: '',
                order: 8
            }
        ]
    }
];
