import React from 'react';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from '../../selectors/login';
import { FormTextRegular } from '../../components/form/FormText';
import { CheckboxCheck } from '../../components/form/FormCheckboxCheck';
import Button from '../../common/components/Button';
import { TWO_FACTOR_AUTHENTICATION_APP, TWO_FACTOR_AUTHENTICATION_SMS } from '../../config';
import styles from './Mfa.module.scss';

interface SmsMfaProps {
    submitting: boolean;
    error: string | null;
    setError: (error: string | boolean) => void;
    setMfaMethod: (method: string) => void;
    setShowContactUs: (show: boolean) => void;
    send2faCode: any;
    resendRemainingTime: number;
}

export default function SmsMfa({
    submitting,
    error,
    setError,
    setMfaMethod,
    setShowContactUs,
    send2faCode,
    resendRemainingTime
}: SmsMfaProps) {
    const userFromLogin: any = useSelector(getLoggedInUser);

    return (
        <div className={styles.mfaSection}>
            <p className={styles.label}>
                {`For your security, we sent a code to mobile number ${userFromLogin.phone}`}
            </p>
            <FormTextRegular name="code" label="Type code here" validateOnTouch={true} />
            <Button
                secondary
                type="button"
                className={styles.resendButton}
                loading={send2faCode.isLoading}
                disabled={!!resendRemainingTime}
                onClick={() => {
                    send2faCode.mutate({
                        accountType: userFromLogin.accountType,
                        email: userFromLogin.email
                    });
                }}
            >
                Resend code {resendRemainingTime ? `in ${resendRemainingTime} seconds` : ''}
            </Button>
            <div className="form-checkbox">
                <CheckboxCheck name="rememberTrustDevice" label="Trust this device for 30 days" />
            </div>
            <Button primary type="submit" className={styles.nextButton} loading={submitting}>
                Next
            </Button>
            {error && <div className={styles.errorMessage}>{error}</div>}
            {userFromLogin.multiFactorAuthenticationMethods.includes(TWO_FACTOR_AUTHENTICATION_APP) && (
                <Button
                    type="button"
                    className={styles.actionButton}
                    onClick={() => {
                        setError(false);
                        setMfaMethod(TWO_FACTOR_AUTHENTICATION_APP);
                    }}
                >
                    Use two factor authentication app instead
                </Button>
            )}
            <Button type="button" className={styles.actionButton} onClick={() => setShowContactUs(true)}>
                Wrong phone number?
            </Button>
        </div>
    );
}
