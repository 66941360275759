import * as React from 'react';
import ModalDialog from '../../../../../../common/components/ModalDialog';
import '../../../../../../sass/conditions.scss';
import { isEmpty } from 'lodash';

export default function ModalServicesSchedule({ isOpen, closeModal, pmServicesSchedule }) {
    if (!pmServicesSchedule) {
        return <div> </div>;
    }
    const { leasingItems, propertyManagementItems, accountingItems } = pmServicesSchedule;
    return (
        <ModalDialog title="Services schedule terms" isOpen={isOpen} closeModal={closeModal}>
            <div className="agreement-section-text">
                <div>
                    <h3>Leasing terms</h3>
                    {isEmpty(leasingItems) ? (
                        <p> No leasing terms. </p>
                    ) : (
                        <div>
                            <ul>{leasingItems.map((value, index) => renderTerm(value, index))}</ul>
                        </div>
                    )}
                    <h3>Property management terms</h3>
                    {isEmpty(propertyManagementItems) ? (
                        <p> No property management terms. </p>
                    ) : (
                        <div>
                            <ul>{propertyManagementItems.map((value, index) => renderTerm(value, index))}</ul>
                        </div>
                    )}
                    <h3>Accounting terms</h3>
                    {isEmpty(accountingItems) ? (
                        <p> No accounting terms. </p>
                    ) : (
                        <div>
                            <ul>{accountingItems.map((value, index) => renderTerm(value, index))}</ul>
                        </div>
                    )}
                </div>
            </div>
        </ModalDialog>
    );

    function renderTerm(item, index) {
        const { text, enabled } = item;
        return (
            <li key={index}>
                {enabled ? (
                    <p>{text}</p>
                ) : (
                    <p>
                        <strike>{text}</strike>
                    </p>
                )}
            </li>
        );
    }
}
