import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';

export const UPDATE_SALES_LANDLORD_FORM = 'dashboard.sales.landlord.updateForm';
export const SALES_LANDLORD_FORM_ADD_PERSON = 'dashboard.sales.landlord.addPerson';
export const SALES_LANDLORD_FORM_REMOVE_PERSON = 'dashboard.sales.landlord.removePerson';

const initState = {
    persons: [
        {
            firstName: '',
            middleName: '',
            secondName: '',
            email: '',
            address: '',
            phone: '',
            registeredForGST: false
        }
    ],
    errors: null
};

export default function landlordReducer(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case UPDATE_SALES_LANDLORD_FORM: {
            newState = action.data;
            break;
        }
        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            if (action.result.data.lease.landlord) {
                newState = Object.assign({}, initState, action.result.data.lease.landlord);
            } else {
                newState = initState;
            }
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            newState = Object.assign({}, initState, action.payload.agreementInfo.landlord);
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            newState = Object.assign({}, initState, action.lease.landlord);
            break;
        }
        case SALES_LANDLORD_FORM_ADD_PERSON: {
            newState.persons = [...newState.persons, { firstName: '', secondName: '' }];
            break;
        }
        case SALES_LANDLORD_FORM_REMOVE_PERSON: {
            newState.persons = [
                ...newState.persons.slice(0, action.index),
                ...newState.persons.slice(action.index + 1)
            ];
            break;
        }
    }

    return newState;
}
