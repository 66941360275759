import axios from 'axios';
import * as Account from './../reducers/account';

export function updateDefaultPmFeesDetails(agencyId, values) {
    return {
        types: [
            Account.UPDATE_DEFAULT_PM_FEES_REQUEST,
            Account.UPDATE_DEFAULT_PM_FEES_SUCCESS,
            Account.UPDATE_DEFAULT_PM_FEES_FAIL
        ],
        promise: axios.put(`/api/agency/${agencyId}/fees/default/pm-fees`, values)
    };
}
