import * as Toast from '../reducers/toast';

export function addNewToast(toast) {
    return {
        type: Toast.ADD_NEW_TOAST,
        toast
    };
}

export function removeToast(id) {
    return {
        type: Toast.REMOVE_TOAST,
        id
    };
}
