import { createSelector } from 'reselect';
import { getTenants, getSignatories, hasAdditionalTermCommunityScheme } from './agreementInfo';

const createSignatureProps = ({ id, base64SignatureImage: imageUrl }, tooltip = { name: null, index: null }) => ({
    id,
    base64SignatureImage: imageUrl,
    tooltip: tooltip.name,
    index: tooltip.index
});

const filterInitials = (key, filterLawsKey) => {
    const isInitialKey = /Initial$/.test(key);

    //always remove the bond initial as we no longer use it
    if (isInitialKey && /^bond/.test(key)) {
        return false;
    }
    const isLawsKey = isInitialKey && /^laws/.test(key);

    return isLawsKey ? !filterLawsKey : isInitialKey;
};

const tooltipKeyMap = {
    defencePolicyInitial: { name: 'Defence policy', index: 1 },
    occupantsInitial: { name: 'Occupants', index: 1 },
    lawsInitial: { name: 'Strata schema', index: 2 },
    swimmingInitial: { name: 'Swimming pool', index: 3 },
    additionalTermsInitial: { name: 'Additional terms', index: 4 },
    feeInitial: { name: 'Break fee', index: 5 },
    petsInitial: { name: 'Pets', index: 6 },
    rentalIncreaseInitial: { name: 'Rent increase', index: 7 },
    leaseRenewalInitial: { name: 'Lease renewal', index: 8 },
    confirmGlobalInitial: { name: 'Bottom of each page', index: 9 },
    bondInitial: { name: 'Bond', index: 10 },
    specialConditionsInitial: { name: 'Special conditions', index: 11 },
    materialFactsInitial: { name: 'Material Facts', index: 12 },
    globalAdditionalTermsInitial: { name: 'Global additional terms', index: 13 },
    petAnnexureInitial: { name: 'Pet annexure', index: 14 },
    executedAsAgreementInitial: { name: 'Executed as agreement', index: 15 }
};

const salesTooltipKeyMap = {
    marketingInitial: { name: 'Marketing Initial', index: 1 },
    salesStandardTermsInitial: { name: 'Initial on the document', index: 2 },
    salesTermsInitial: { name: "Licensee's remuneration Initial", index: 3 },
    coolingOffInitial: { name: 'Cooling Off Initial', index: 4 },
    agentAdditionalTermsInitial: { name: 'Agent Additional Terms Initial', index: 5 }
};

const commercialLeaseTooltipKeyMap = {
    confirmGlobalInitial: { name: 'Bottom of each page', index: 1 }
};

export const getSignatures = createSelector(
    [getTenants, hasAdditionalTermCommunityScheme],
    (tenants, hasCommunityScheme) =>
        tenants?.reduce((signatures, tenant) => {
            const { id, fullName: name, isSigned: signed, isPrimaryTenant: primary, signature, witness } = tenant;
            const updates = [
                ...signatures,
                {
                    id,
                    name,
                    signed,
                    primary,
                    signature: signature ? createSignatureProps(signature) : null,
                    initials: Object.keys(tenant)
                        .filter(key => filterInitials(key, hasCommunityScheme))
                        .filter(key => tenant[key])
                        .map(key => createSignatureProps(tenant[key], tooltipKeyMap[key]))
                        .sort((a, b) => {
                            return a.index - b.index;
                        }) //sort by index in the tooltipMap, this is the order of signing
                }
            ];

            // add the witness to the signature list
            if (witness) {
                const witnessName = `${witness.firstName} ${witness.lastName}`;
                updates.push({
                    id: witness._id,
                    name: witnessName,
                    signed: true,
                    witness: true,
                    signature: witness.signature ? createSignatureProps(witness.signature) : null,
                    initials: []
                });
            }
            return updates;
        }, [])
);
export const getSalesSignatures = createSelector([getSignatories], signatories =>
    signatories.reduce((signatures, signatory) => {
        const { id, fullName: name, isSigned: signed, isPrimaryVendor: primary, signature } = signatory;
        const updates = [
            ...signatures,
            {
                id,
                name,
                signed,
                primary,
                signature: signature ? createSignatureProps(signature) : null,
                initials: Object.keys(signatory)
                    .filter(key => filterInitials(key))
                    .filter(key => signatory[key])
                    .map(key => createSignatureProps(signatory[key], salesTooltipKeyMap[key]))
                    .sort((a, b) => {
                        return a.index - b.index;
                    }) //sort by index in the tooltipMap, this is the order of signing
            }
        ];
        return updates;
    }, [])
);

export const getCommercialLeaseSignatures = createSelector([getSignatories], signatories =>
    signatories.reduce((signatures, signatory) => {
        const { id, fullName: name, isSigned: signed, signature, witness, isGuarantor } = signatory;
        const updates = [
            ...signatures,
            {
                id,
                name,
                signed,
                isGuarantor,
                signature: signature ? createSignatureProps(signature) : null,
                initials: Object.keys(signatory)
                    .filter(key => filterInitials(key))
                    .filter(key => signatory[key])
                    .map(key => createSignatureProps(signatory[key], commercialLeaseTooltipKeyMap[key]))
                    .sort((a, b) => {
                        return a.index - b.index;
                    }) //sort by index in the tooltipMap, this is the order of signing
            }
        ];
        // add the witness to the signature list
        if (witness) {
            const witnessName = `${witness.firstName} ${witness.lastName}`;
            updates.push({
                id: witness._id,
                name: witnessName,
                signed: true,
                witness: true,
                signature: witness.signature ? createSignatureProps(witness.signature) : null,
                initials: []
            });
        }
        return updates;
    }, [])
);
