import { createSelector } from 'reselect';

export const getResendLease = state => {
    return state.resendLease;
};

export const getIsResendLeaseModalOpen = createSelector(
    [getResendLease],
    resendLease => resendLease.isResendLeaseModalOpen
);
export const getData = createSelector([getResendLease], resendLease => resendLease.data);
export const getSelectedSignatories = createSelector([getResendLease], resendLease => resendLease.selectedSignatories);
export const getSendAttachedDocuments = createSelector(
    [getResendLease],
    resendLease => resendLease.sendAttachedDocuments
);
export const getIsResendingForSigning = createSelector(
    [getResendLease],
    resendLease => resendLease.isResendingForSigning
);
