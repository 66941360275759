import React from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getPmStepNo, getPmStepTitle } from '../../../../../../utils/agreementUtils';

const AgreementPmAuthorityPeriodDetails = props => {
    const { leaseAllInfo } = props;

    if (!leaseAllInfo.pmAuthorityPeriodDetails) {
        return null;
    }
    return (
        <div>
            <AgreementSection
                title={getPmStepTitle(leaseAllInfo.location, 'pmAuthorityPeriodDetails')}
                step={getPmStepNo(leaseAllInfo.location, 'pmAuthorityPeriodDetails')}
                leaseAllInfo={leaseAllInfo}
            >
                <div className="data data-row">
                    <p>
                        <strong>Date the authority is granted from:</strong>
                        {leaseAllInfo.pmAuthorityPeriodDetails.startDateFormatted}
                    </p>
                    <p>
                        <strong>Exclusive Authority Period:</strong>{' '}
                        {leaseAllInfo.pmAuthorityPeriodDetails.exclusiveAuthorityPeriod} days from the date of this
                        Authority
                    </p>
                    <p>
                        <strong>Continuing Authority Period:</strong>{' '}
                        {leaseAllInfo.pmAuthorityPeriodDetails.continuingAuthorityPeriod} days from the end of the
                        Exclusive Authority Period
                    </p>
                    <p>
                        <strong>Termination of Authority:</strong> The Authority can only be terminated by either the
                        Agent or the Client in writing, (in accordance with Term 3.1 & 16.1) giving no less than{' '}
                        {leaseAllInfo.pmAuthorityPeriodDetails.terminationOfAuthority} days notice
                    </p>
                    <br />
                    {renderConditions()}
                </div>
            </AgreementSection>
        </div>
    );

    function renderConditions() {
        const {
            isInitialLeasing,
            isOngoingManagement,
            isSubsequent,
            isSubsequentReLeasing
        } = leaseAllInfo.pmAuthorityPeriodDetails;
        if (![isInitialLeasing, isOngoingManagement, isSubsequent, isSubsequentReLeasing].includes(true)) {
            return null;
        }
        return (
            <div>
                <p>
                    <strong>Agent’s Authorisation</strong>
                </p>
                <i>This Authority is for the:</i>
                <ul>
                    {isInitialLeasing === true && (
                        <li>
                            <p>Ongoing management of the Property and collection of rent</p>
                        </li>
                    )}
                    {isOngoingManagement === true && (
                        <li>
                            <p>Initial Leasing of the Property</p>
                        </li>
                    )}
                    {isSubsequent === true && (
                        <li>
                            <p>Subsequent and each new leasing to a new renter</p>
                        </li>
                    )}
                    {isSubsequentReLeasing === true && (
                        <li>
                            <p>Subsequent re-leasing to initial renter or assignee</p>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
};

export default AgreementPmAuthorityPeriodDetails;
