import React from 'react';
import { DeleteSharp, AddSharp } from '@material-ui/icons/';
import { FormTextRegular } from '../../../components/form/FormText';
import { FieldArray } from 'react-final-form-arrays';
import './../../../sass/otherDetails.scss';

const OtherDetails = ({ fieldName, disabled, push }) => {
    return (
        <div className="other-details">
            <FieldArray name={fieldName}>
                {({ fields }) => (
                    <React.Fragment>
                        {fields.map((name, index) => {
                            return (
                                <div key={index} className="submodule">
                                    <div className="submodule__header">
                                        <h3>{`Other item ${index + 1}`}</h3>
                                        <div
                                            className="submodule--remove"
                                            title="Remove"
                                            onClick={() => fields.remove(index)}
                                        >
                                            <DeleteSharp />
                                            Remove
                                        </div>
                                    </div>
                                    <FormTextRegular
                                        name={`${fieldName}[${index}].label`}
                                        label={'Label'}
                                        disabled={disabled}
                                    />
                                    <FormTextRegular
                                        name={`${fieldName}[${index}].value`}
                                        label={'Value'}
                                        disabled={disabled}
                                    />
                                </div>
                            );
                        })}
                    </React.Fragment>
                )}
            </FieldArray>
            <div className="submodule__footer">
                <button
                    title="Add other"
                    className="add-button"
                    type="button"
                    onClick={() => push(`${fieldName}`, { label: '', value: '' })}
                >
                    <AddSharp />
                    Add other
                </button>
            </div>
        </div>
    );
};

export default OtherDetails;
