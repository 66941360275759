import * as React from 'react';
import cx from 'classnames';

import Icon, { Icons } from './Icon';
import Button from './Button.js';
import styles from './ButtonWithDropdown.module.scss';
import Dropdown from './dropdown/Dropdown';
import DropdownItem from './dropdown/DropdownItem';

interface ButtonWithDropdownProps {
    className: string;
    buttonText: string;
    buttonClassName?: string;
    onclickHandler?: () => void;
    actionItems?: Array<{
        text: string;
        icon: Icons;
        actionHandler: () => void;
    }>;
    isLoading: boolean;
    disabled: boolean;
    buttonType?: string;
    buttonLoadingText?: string;
    isActionLoading?: boolean;
}

interface DropDownItemProps {
    component: React.ReactNode;
    key: string;
    showItemCondition?: boolean;
}

const ButtonWithDropdown: React.FC<ButtonWithDropdownProps> = ({
    className,
    buttonText,
    buttonClassName,
    onclickHandler,
    actionItems,
    isLoading,
    disabled,
    buttonType,
    buttonLoadingText,
    isActionLoading
}) => {
    const radixDropdownPortalContainerRef = React.useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const dropDownItems: DropDownItemProps[] = actionItems?.map((item, index) => {
        return {
            component: (
                <DropdownItem
                    key={item.text}
                    endIcon={<Icon className={styles.icon} icon={item.icon} />}
                    text={item.text}
                    onClick={item.actionHandler}
                    className={styles.dropDownItem}
                />
            ),
            key: `action-item-${index}`,
            showItemCondition: true
        };
    });

    return (
        <>
            <div className={cx(styles.buttonWithDropdown, className)} ref={radixDropdownPortalContainerRef}>
                <Button
                    type={buttonType}
                    loading={isLoading || isActionLoading}
                    disabled={disabled}
                    className={cx(
                        styles.primaryButton,
                        styles.withoutLeftBorderRadius,
                        {
                            [styles.actionLoading]: isActionLoading
                        },
                        buttonClassName
                    )}
                    onClick={onclickHandler}
                >
                    {isActionLoading ? buttonLoadingText : buttonText}
                </Button>
                {!isActionLoading && (
                    <Dropdown
                        className={cx(styles.dropDownButton, styles.buttonWithThreeRem)}
                        dropdownContentClassName={styles.dropdownMenuContent}
                        radixDropdownPortalContainer={radixDropdownPortalContainerRef.current}
                        trigger={
                            <div
                                className={cx(styles.iconContainer, {
                                    [styles.disabled]: disabled || isLoading
                                })}
                                onClick={toggleDropdown}
                            >
                                <Icon className={styles.icon} icon={Icons.CHEVRON_DOWN} />
                            </div>
                        }
                        dropdownItems={dropDownItems}
                    />
                )}
            </div>
        </>
    );
};

export default ButtonWithDropdown;
