import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getUserInfo } from '../../../selectors/user';
import { Form } from 'react-final-form';
import { updateUserCredentials } from '../../../actions/user';
import SaveButtonIcon from '../SaveButtonIcon';
import { FormTextRegular } from '../../../components/form/FormText';
import ConnectionToolTip from '../ConnectionToolTip';

const AgentCode = () => {
    const dispatch = useDispatch();
    const userState = useSelector(getUser);
    const user = useSelector(getUserInfo);
    const handleFormSubmitAgentCode = values => {
        dispatch(updateUserCredentials(values));
    };

    const isPending = userState.UPDATE_USER_INFO_REQUEST_PENDING;

    return (
        <div className="change-profile">
            <h2>Partner Code</h2>
            <Form
                onSubmit={handleFormSubmitAgentCode}
                initialValues={{
                    connectionAgentCode: user.connectionAgentCode
                }}
            >
                {({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="form-content">
                                <FormTextRegular
                                    name="connectionAgentCode"
                                    label={userState.agentCode.label || 'Connections Company Agent Ref'}
                                />
                                <ConnectionToolTip title={'What is this?'} toolTip={userState.agentCode.helpText} />
                            </div>
                            <div className="footer">
                                <SaveButtonIcon isSaving={isPending} />
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export default AgentCode;
