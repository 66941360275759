import React, { Component } from 'react';

export default class TextRow extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <span className="text">{this.props.text}</span>;
    }
}
