import React from 'react';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import styles from './Tooltip.module.scss';

type TooltipProps = {
    place?: 'top' | 'right' | 'bottom' | 'left';
    className?: string;
    html?: boolean;
    effect?: 'solid' | 'float';
    id?: string;
    backgroundColor?: string;
    hideArrow?: boolean;
};

// We can't style the tooltip arrow with CSS, so instead we pass in this background colour to the <ReactTooltip />
// component, which colours the tooltip arrow for us
const DEFAULT_BACKGROUND_COLOR = '#3E3E3E';

const Tooltip: React.FunctionComponent<TooltipProps> = ({
    place = 'right',
    className,
    html,
    effect = 'float',
    id,
    backgroundColor = DEFAULT_BACKGROUND_COLOR,
    hideArrow
}) => {
    return (
        <ReactTooltip
            effect={effect}
            place={place}
            className={cx(styles.tooltip, className, { [styles.hideArrow]: hideArrow })}
            backgroundColor={backgroundColor}
            html={html}
            id={id}
            globalEventOff="wheel"
        />
    );
};

export default Tooltip;
