import * as React from 'react';
import FlkSelectBox from './FlkSelectBox';
import FlkAmountSelectBox from './FlkAmountSelectBox';
import { CheckboxCheck } from '../../form/FormCheckboxCheck';
import { FormTextCurrency } from '../../form/FormText';
import { isLeaseLocationSA, isLeaseTypeResidentialTenancy } from '../../../utils/agreementUtils';

import '../../../sass/newReportData.scss';
import { SUB_LEASE_TYPE_SA_RENEWALS, SUB_LEASE_TYPE_LONG_TERM_RENEWAL } from '../../../config';
import { useSelector } from 'react-redux';
import { getLeaseToClone } from '../../../selectors/dashboard';
import { ToggleButton } from '../../form/FormToggleButton';

const NewLeaseReportData = props => {
    const leaseToClone = useSelector(getLeaseToClone);
    const isLongTermRenewalFromCreateNewPopup =
        isLeaseTypeResidentialTenancy(props.leaseType) || props.leaseType === SUB_LEASE_TYPE_SA_RENEWALS;
    const isLongTermRenewalFromClone = leaseToClone.subLeaseType === SUB_LEASE_TYPE_LONG_TERM_RENEWAL;
    return (
        <div className={'new-report-data'}>
            <p className="new-report-data-warning">
                <span className="icon icon-warning"></span>The data below is used for your reporting only and is NOT
                entered onto your agreement
            </p>
            <FlkSelectBox
                noOfBoxes={4}
                firstBox={'S'}
                title={'No. of bedrooms'}
                setValue={props.setNumberOfBedrooms}
                selected={props.values.numberOfBedrooms}
            />

            <FlkSelectBox
                noOfBoxes={5}
                title={'No. of bathrooms'}
                setValue={props.setNumberOfBathrooms}
                selected={props.values.numberOfBathrooms}
            />

            <FlkSelectBox
                noOfBoxes={4}
                firstBox={'0'}
                title={'No. of car spaces'}
                setValue={props.setNumberOfCarSpaces}
                selected={props.values.numberOfCarSpaces}
            />

            {!(
                (isLongTermRenewalFromCreateNewPopup || isLongTermRenewalFromClone) &&
                isLeaseLocationSA(props.values?.location || leaseToClone.location)
            ) && (
                <React.Fragment>
                    <ToggleButton
                        label={'Is this a renewal?'}
                        name="isRenewal"
                        value={false}
                        dataTest="renewalCheckbox"
                    />
                    <ToggleButton
                        label={'Has the rent increased?'}
                        name="isRentIncrease"
                        value={false}
                        dataTest="rentIncreaseCheckbox"
                    />
                </React.Fragment>
            )}
            {props.values.isRentIncrease && (
                <div>
                    <FlkAmountSelectBox
                        noOfSelectable={5}
                        multiple={5}
                        title={'Rent increase amount'}
                        setValue={props.setRentIncreaseAmount}
                        selected={props.values.rentIncreaseAmountIndex}
                    />
                    {props.values.rentIncreaseAmountIndex === props.isRentIncreaseOtherSelectedIndex && (
                        <FormTextCurrency name="rentIncreaseAmount" label="Enter other amount" />
                    )}
                </div>
            )}
        </div>
    );
};

export default NewLeaseReportData;
