import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../sass/flkSelectBox.scss';

class FlkAmountSelectBox extends Component {
    static propTypes = {
        noOfSelectable: PropTypes.number,
        title: PropTypes.string,
        setValue: PropTypes.func,
        selected: PropTypes.number
    };

    constructor(props) {
        super(props);

        this.state = {
            selectableBoxes: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectableBoxes: this.renderBoxes(nextProps.selected / this.props.multiple)
        });
    }

    componentDidMount() {
        if (this.props.selected > 20) {
            this.setState({
                selectableBoxes: this.renderBoxes(this.props.multiple)
            });
        } else {
            this.setState({
                selectableBoxes: this.renderBoxes(this.props.selected / this.props.multiple)
            });
        }
    }
    setSelectedBox(boxIndex) {
        this.props.setValue(boxIndex * this.props.multiple);
    }

    renderBoxes = selectedIndex => {
        const boxes = [];
        for (let i = 1; i <= this.props.noOfSelectable; i++) {
            boxes.push(
                <div
                    key={i}
                    className={selectedIndex === i ? 'select-box-selected' : 'select-box'}
                    onClick={() => this.setSelectedBox(i)}
                >
                    <div className={selectedIndex === i ? 'select-box-content-selected' : 'select-box-content'}>
                        {this.props.noOfSelectable === i ? `other` : `$${i * this.props.multiple}`}
                    </div>
                </div>
            );
        }
        return boxes;
    };

    render() {
        return (
            <div className={'flk-select-box'}>
                <div className={'select-boxes-title'}>{this.props.title}</div>
                <div className={'select-boxes'}>{this.state.selectableBoxes}</div>
            </div>
        );
    }
}

export default FlkAmountSelectBox;
