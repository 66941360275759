import * as React from 'react';

import '../../../sass/template/footerTemplate.scss';
import { getTotalStepsCount } from '../../../utils/agreementUtils';

const FooterTemplate = props => {
    const {
        isServerBusy,
        nextStep,
        goBack,
        finishEditing,
        isSubmitButtonDisabled,
        isSubmittingLease,
        step,
        leaseType,
        location,
        subLeaseType
    } = props;
    let totalStepsCount = getTotalStepsCount(leaseType, subLeaseType, location);

    function getSaveTemplateButton() {
        return (
            <button
                className={isServerBusy ? 'save-button-busy' : 'save-button-ready'}
                onClick={finishEditing}
                disabled={isSubmittingLease}
            >
                {isServerBusy && <span className="savestatus-spinner-white" />}
                Save template
            </button>
        );
    }

    function getNextButton() {
        return (
            <div className={isServerBusy ? 'btn-spinner' : 'button'}>
                <button
                    className={isServerBusy ? 'button-busy' : 'button-ready'}
                    onClick={nextStep}
                    disabled={isServerBusy || isSubmitButtonDisabled}
                >
                    {isServerBusy ? <span className="savestatus-spinner-white" /> : null}
                    {isServerBusy ? 'Saving' : 'Next'}
                </button>
            </div>
        );
    }

    return (
        <footer>
            <div className="footer-template">
                <div className="button-row">
                    <div className="button button-back">
                        <button onClick={goBack}>Back</button>
                    </div>
                    {step != totalStepsCount && getNextButton()}
                    {step === totalStepsCount && getSaveTemplateButton()}
                </div>
                <div className="bottom-button-row">{step != totalStepsCount && getSaveTemplateButton()}</div>
            </div>
        </footer>
    );
};

export default FooterTemplate;
