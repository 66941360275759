import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmDetailsRepairs } from '../../../../selectors/lease/pmLease';

import { FormTextRegular } from '../../../../components/form/FormText';
import { FormResponsiveNumbers } from '../../../../components/form/responsive/FormResponsiveNumbers';
import { FormResponsiveText } from '../../../../components/form/responsive/FormResponsiveText';
import '../../../../sass/management/detailsRepairs.scss';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { ToggleButton } from '../../../../components/form/FormToggleButton';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';

const PM_DETAIL_REPAIRS_FORM = 'pmDetailsRepairsForm';
const initState = {
    description: '',
    approvalForPets: 'No',
    fittingsAndFixtures: '',
    fittings: '',
    detailsOfWorkToBeCompleted: '',
    nominateStrataDetails: false,
    strataPlanNo: '',
    lotNo: '',
    strataManagingAgent: '',
    byLawsprovided: false,
    inspectionsPerYear: '2'
};

const DetailsRepairs = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmDetailsRepairs = useSelector(getPmDetailsRepairs);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmDetailsRepairs, setPmDetailsRepairs] = useState(reduxPmDetailsRepairs || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_DETAIL_REPAIRS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmDetailRepairs(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmDetailsRepairs')) {
                    return error.response.data.errors.pmDetailsRepairs;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmDetailRepairs = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-details-repairs`, data);
    };

    return (
        <div className="detailsRepairs">
            <Form onSubmit={submitForm} initialValues={pmDetailsRepairs}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_DETAIL_REPAIRS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <FormTextRegular
                                    name="description"
                                    label="Description Of Property"
                                    required
                                    maxLength="130"
                                />
                                <FormResponsiveNumbers
                                    name="numberOfApprovedOccupants"
                                    label="Number Of Approved Occupants"
                                    options={[1, 2, 3]}
                                    otherLabel="4+"
                                    required
                                    form={form}
                                />
                                <FormResponsiveText
                                    name="inspectionsPerYear"
                                    label="Inspections Per Year"
                                    options={['1', '2', '3']}
                                    otherLabel="4+"
                                    required
                                    form={form}
                                />
                                <FormTextRegular name="titleReference" label="Title Reference" />
                                <CheckboxCheck name="nominateStrataDetails" label="Nominate Strata Details" />
                                {values.nominateStrataDetails && (
                                    <div className="form-section">
                                        <div className="form-section__body">
                                            <div className="full-width">
                                                <FormTextRegular name="strataPlanNo" label="Plan No" />
                                            </div>
                                            <div className="full-width">
                                                <FormTextRegular name="lotNo" label="Lot No" />
                                            </div>
                                            <div className="full-width">
                                                <FormTextRegular
                                                    name="strataManagingAgent"
                                                    label="Strata Managing Agent"
                                                />
                                            </div>
                                            <div className="full-width">
                                                <CheckboxCheck name="byLawsprovided" label="By Laws Provided" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <FormTextRegular
                                    name="fittingsAndFixtures"
                                    label="Fittings Included (for use by Tenant/s)"
                                />
                                <FormTextRegular name="fittings" label="Fittings Excluded (not for use by Tenant/s)" />
                                <FormTextRegular
                                    name="detailsOfWorkToBeCompleted"
                                    label="Details Of Work To Be Completed By The Lessor On The Property"
                                />
                                <ToggleButton label="The property is furnished" name="isPropertyFurnished" />
                                {values.isPropertyFurnished && (
                                    <FormRadioGroup
                                        label="Who will prepare the inventory list for the property"
                                        name={'whoPrepareInventoryList'}
                                        value={values.whoPrepareInventoryList}
                                        data={[
                                            {
                                                label: 'Agent',
                                                value: 'Agent'
                                            },
                                            {
                                                label: 'Landlord',
                                                value: 'Landlord'
                                            }
                                        ]}
                                    />
                                )}
                                <FormRadioGroup
                                    label="Approval For Pets"
                                    name={'approvalForPets'}
                                    value={values.approvalForPets}
                                    data={[
                                        {
                                            label: 'Yes',
                                            value: 'Yes'
                                        },
                                        {
                                            label: 'No',
                                            value: 'No'
                                        }
                                    ]}
                                />
                                {values.approvalForPets === 'Yes' && (
                                    <FormTextRegular name="conditions" label="Conditions" required />
                                )}
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(DetailsRepairs);
