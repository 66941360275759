import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { editPermissions } from '../../../utils/userPermissions';
import { tabs } from '../../../config';
import Panel from '../../../common/components/Panel';
import { FormNumber } from '../../../components/form/FormText';
import { getUserInfo } from '../../../selectors/user';
import { getAgency } from '../../../selectors/settings/account';
import { getAccount } from '../../../selectors/settings/account';
import { getValidationErrors } from '../../../selectors/settings/account';
import { updateAgencyDetails } from '../../../actions/account';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import SaveButtonIcon from '../SaveButtonIcon';

const AgencyDefaultDetailsForm = props => {
    const LEASE_PATH = 'licensee';
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getUserInfo);
    const agency = useSelector(getAgency);
    const accountState = useSelector(getAccount);
    const validationErrors = useSelector(getValidationErrors);

    const submit = (agencyId, values, form) => {
        const updatedData = values.details;

        // Set the dirty Fields
        updatedData.dirtyFields = { ...form.getState().dirtyFields, leasePath: LEASE_PATH };
        if (values.details.defaultRenewalPeriod !== 'custom' && values.details.customDefaultRenewalDays) {
            values.details.customDefaultRenewalDays = undefined;
        }
        dispatch(updateAgencyDetails(agencyId, updatedData));
    };

    let userRole = loggedInUser.role;
    const isPending = accountState.UPDATE_AGENCY_DEFAULT_DETAILS_PENDING;
    const savedSuccessfully = accountState.UPDATE_AGENCY_DEFAULT_DETAILS_SUCCESS;
    const saveFailed = accountState.UPDATE_AGENCY_DEFAULT_DETAILS_FAIL;

    return (
        <Form
            initialValues={agency}
            onSubmit={() => {}}
            validate={formValues => {
                let completionErrors = validationErrors ? validationErrors : {};
                let renewalDefaultDays = formValues?.details?.defaultRenewalPeriod;
                if (renewalDefaultDays === 'custom') {
                    renewalDefaultDays = formValues.details.customDefaultRenewalDays;
                }
                if (!isNaN(Number(renewalDefaultDays)) && Number(renewalDefaultDays) < 0) {
                    completionErrors.defaultRenewalPeriod = 'Days can not be less than zero';
                }
                if (!completionErrors) {
                    return;
                }

                return completionErrors;
            }}
        >
            {({ handleSubmit, values, errors, form }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Panel
                            title="Defaults"
                            rightButton={
                                <div>
                                    {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                        <SaveButtonIcon
                                            isSaving={isPending}
                                            success={savedSuccessfully}
                                            failure={saveFailed}
                                            onClick={() => submit(agency.id, values, form)}
                                        />
                                    )}
                                </div>
                            }
                        >
                            <FormRadioGroup
                                name="details.defaultRenewalPeriod"
                                label="Default renewal period (days):"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                                radioGroupClass="wrapped-radio-group"
                                data={[
                                    {
                                        label: '30 days',
                                        value: '30'
                                    },
                                    {
                                        label: '60 days',
                                        value: '60'
                                    },
                                    {
                                        label: '90 days',
                                        value: '90'
                                    },
                                    {
                                        label: 'None',
                                        value: 'none'
                                    },
                                    {
                                        label: 'Custom',
                                        value: 'custom'
                                    }
                                ]}
                            />
                            {values.details?.defaultRenewalPeriod === 'custom' && (
                                <FormNumber
                                    label="Custom renewal period (days)"
                                    name="details.customDefaultRenewalDays"
                                    disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                                />
                            )}
                            {errors?.defaultRenewalPeriod && (
                                <p className="has-error">{errors?.defaultRenewalPeriod}</p>
                            )}
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};

export default AgencyDefaultDetailsForm;
