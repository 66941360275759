import React, { useEffect } from 'react';
import { useTwilioSyncProvider } from '../components/providers/TwilioSyncProvider';
import { getAgreementsMapId, EVENT_ITEM_UPDATED, TWILIO_TIMED_OUT_ERROR_MESSAGE } from './helpers';
import logger from '../utils/logger';
import { useRollbarProvider } from '../components/providers/RollbarProvider';

export function useAgreementUpdate(agency, callback) {
    const syncClient = useTwilioSyncProvider();
    const Rollbar = useRollbarProvider();

    useEffect(() => {
        const handleItemUpdated = event => {
            callback(event.item);
        };

        let eventMap;

        let cleanedUp = false;

        if (agency && agency.id && syncClient) {
            syncClient
                .map(getAgreementsMapId(agency))
                .then(map => {
                    // Listen to updates on the Document
                    if (!cleanedUp) {
                        eventMap = map.on(EVENT_ITEM_UPDATED, handleItemUpdated);
                    }
                })
                .catch(error => {
                    if (!error.message.includes(TWILIO_TIMED_OUT_ERROR_MESSAGE)) {
                        Rollbar.error(
                            'Something went wrong getting the updated agreement',
                            {
                                error_message: error.message,
                                status: 'error',
                                env: ENVIRONMENT
                            },
                            error
                        );
                    }
                });
        }

        return () => {
            cleanedUp = true;
            if (eventMap) {
                eventMap.removeListener(event, handleItemUpdated);
            }
        };
    }, [syncClient, agency]);
}
