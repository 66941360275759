import React, { Component } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../../utils/agreementUtils';
import EmergencyContactDetails from '../../bloks/EmergencyContactDetails';
import { STEP_REPAIRS_AND_MAINTENANCE } from '../../../../../lease/steps';

class AgreementResidentialRepairsAndMaintenance extends Component {
    render() {
        const { leaseAllInfo } = this.props;
        return (
            <div>
                {!!leaseAllInfo.repairsAndMaintenance && (
                    <AgreementSection
                        title="Repairs and Maintenance"
                        step={getResTenStepNo(
                            leaseAllInfo.location,
                            STEP_REPAIRS_AND_MAINTENANCE,
                            leaseAllInfo.subLeaseType
                        )}
                        leaseAllInfo={leaseAllInfo}
                    >
                        <div>
                            <p>In circumstances where repairs and maintenance are required during business hours</p>
                            {leaseAllInfo.repairsAndMaintenance.contactPersonDuringBusinessHours === 'Agent' && (
                                <p>Agent</p>
                            )}
                            {leaseAllInfo.repairsAndMaintenance.contactPersonDuringBusinessHours ===
                                'Nominated Trades' &&
                                leaseAllInfo.repairsAndMaintenance.businessHoursContacts.map(item => {
                                    return <EmergencyContactDetails key={item.id} item={item} />;
                                })}
                            <br />
                            <p>In circumstances where repairs and maintenance are required after business hours</p>
                            {leaseAllInfo.repairsAndMaintenance.contacts.map(item => {
                                return <EmergencyContactDetails key={item.id} item={item} />;
                            })}
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementResidentialRepairsAndMaintenance;
