import React, { Component } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    getPmStepNo,
    getPmStepTitle,
    isLeaseLocationVIC,
    isLeaseLocationSA
} from '../../../../../utils/agreementUtils';
import { signorLinkExists } from '../../../../../utils/signorUtils';
import '../../../../../sass/infoAgreement.scss';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import SignorsTab from '../SignorsTab';
import ThumbDisplay from '../../../image/ThumbDisplay';
import useClipboard from '../../../../../hooks/useClipboard.ts';
import { getSignorLinks } from '../../../../../actions/lease';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_AWAITING_RENEWAL,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../../actions/dashboard';

function AgreementPmSignors({leaseAllInfo}) {
    const { copyToClipboard } = useClipboard();
    const { data: signorLinks } = useQuery(['signorLinks', leaseAllInfo], async () => {
        if (leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING) {
            const promise = getSignorLinks(leaseAllInfo.id).promise;
            const response = await promise;
            return response.data.signorsWithLinks;
        }
        return [];
    });
    const copyLinkToClipboard = (userId) => {
        if (signorLinks) {
            const matchingSignor = signorLinks.find((signor) => signor._id === userId);
            if (matchingSignor) {
                copyToClipboard(matchingSignor.signorLink, 'Link Copied!');
            }
        }
    };  
    return (
            <div>
                <SignorsTab leaseAllInfo={leaseAllInfo} />
                <AgreementSection
                    title={getPmStepTitle(leaseAllInfo.location, 'pmLandlord')}
                    step={getPmStepNo(leaseAllInfo.location, 'pmLandlord')}
                    leaseAllInfo={leaseAllInfo}
                >
                    {leaseAllInfo.pmLandlord &&
                        leaseAllInfo.pmLandlord.persons.map((item, i) => {
                            return (
                                <div className="data data-row" key={i}>
                                    {item.isCorporation && (
                                        <React.Fragment>
                                            <p className="name">{item.corporationName}</p>
                                            <p className="name">{item.corporationAddress}</p>
                                        </React.Fragment>
                                    )}
                                    <p className="name">{`${item.landlordFullName}${
                                        item.isCorporation && item.roleDescription ? ` (${item.roleDescription})` : ''
                                    }`}</p>
                                    <p className="name">{item.mobile}</p>
                                    <p className="name">{item.email}</p>
                                    {!(isLeaseLocationVIC(leaseAllInfo.location) && item.isCorporation) &&
                                        item.address && <p className="address">{item.address}</p>}
                                    {item.ABN && (
                                        <p>
                                            ABN: <strong>{item.ABN}</strong>
                                        </p>
                                    )}
                                    {item.ACN && (
                                        <p>
                                            ACN: <strong>{item.ACN}</strong>
                                        </p>
                                    )}
                                    {isLeaseLocationSA(leaseAllInfo.location) && (
                                        <>
                                            {item.isRegisteredForGST && <p className="text">Registered for GST</p>}
                                            <p className="text">
                                                Monthly Statement and Administration Reports will be sent via:{' '}
                                                <strong>{item.monthlyStatementSendType || 'Nil'}</strong>
                                            </p>
                                            <p className="text">
                                                Financial Year Reports will be sent via:{' '}
                                                <strong>{item.financialReportSendType || 'Nil'}</strong>
                                            </p>
                                        </>
                                    )}
                                    <p className="text">
                                        Required to upload proof of identity:{' '}
                                        <strong>
                                            {leaseAllInfo.pmLandlord.propertyManagementIdentificationRequired
                                                ? 'Yes'
                                                : 'No'}
                                        </strong>
                                    </p>
                                    <p className="image-url">
                                        {item.ownerImage && (
                                            <ThumbDisplay
                                                imageSrc={item.ownerImage.base64Image}
                                                imageTitle={'Landlord Id'}
                                            />
                                        )}
                                    </p>   
                                    {leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING  
                                    && signorLinkExists(signorLinks, item.id)
                                    && <div className="special-link" onClick={() => copyLinkToClipboard(item.id)}>Copy signing link</div> }                            
                                </div>
                            );
                        })}
                </AgreementSection>
            </div>
    );
}

export default AgreementPmSignors;
