import React, { memo, useEffect, useState } from 'react';
import cookie from 'react-cookie';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import logoImage from '../../assets/images/logo.svg';
import { LEASE_STATUS_DRAFT } from '../actions/dashboard';
import { acceptExistingUser, acceptUser, checkToken } from '../actions/joinTeam';
import { logOut } from '../actions/login';
import { updateUserInfo } from '../actions/user';
import { PreLoader } from '../common/components/PreLoader';
import { FormPassword } from '../components/form/FormPassword';
import { SelectField } from '../components/form/FormSelect';
import HaveAccount from '../components/signup/HaveAccount';
import HelpMessage from '../components/signup/HelpMessage';
import { INVALID_PASSWORD_MESSAGE, isValidPassword } from '../components/Validate';
import { DOCUMENT_CUSTOM, LEASE_TYPE_RESIDENTIAL, isMobileWidth } from '../config';
import { AGENCY_ACCOUNT_TYPE } from '../constants/constants';
import useCountry from '../hooks/useCountry';
import * as Login from '../reducers/login';
import '../sass/createAccountAgent.scss';
import {
    getAcceptUserStatus,
    getAcceptUserinfo,
    getAgency,
    getCheckTokenError,
    getCheckTokenStatus,
    getCheckTokenStatusRequesting,
    getInvite,
    getInviting,
    getUser
} from '../selectors/user/join';
import { listOfTimezones } from '../utils/dateUtils';
import { initLiveSession } from '../utils/generalUtils';
import pushDetailsToHubspot from '../utils/hubspotUtils';
import { toSaveToken } from '../utils/toSaveToken';
import { isBusinessUserAccount } from '../utils/userUtils';
import { extractUserAndAgencyInfo } from '../utils/vendorUtils';
import appHistory from './../AppHistory';

const WRONG_USER_ERROR = 'wrong user';
const ALREADY_ACCEPTED_ERROR = 'already been accepted';
const SECONDS_UNTIL_LOGOUT = 10;

const CreateAccountAgent = props => {
    const dispatch = useDispatch();
    const { boot } = useIntercom();
    const { country } = useCountry();

    const [time, setTime] = useState(SECONDS_UNTIL_LOGOUT);

    const acceptUserStatus = useSelector(getAcceptUserStatus);
    const checkTokenError = useSelector(getCheckTokenError);
    const checkTokenStatus = useSelector(getCheckTokenStatus);
    const checkTokenStatusRequesting = useSelector(getCheckTokenStatusRequesting);
    const acceptUserinfo = useSelector(getAcceptUserinfo);
    const agency = useSelector(getAgency);
    const user = useSelector(getUser);
    const invite = useSelector(getInvite);
    const inviting = useSelector(getInviting);

    const [selectedTimezone, setSelectedTimezone] = useState(user.timezone);

    const logUserOut = () => {
        cookie.remove('rememberMe', { path: '/' });
        cookie.remove('userFromLogin', { path: '/' });
        cookie.remove('accountType', { path: '/' });
        dispatch(logOut());
        appHistory.push('/');
    };
    useEffect(() => {
        document.title = 'Join the team';
        dispatch(checkToken(props.match.params.id));
    }, [boot, dispatch]);

    useEffect(() => {
        if (acceptUserStatus) {
            dispatch({
                type: Login.ACCEPT_INVITE_SUCCESS,
                result: acceptUserinfo
            });
            toSaveToken(
                acceptUserinfo.user,
                false,
                acceptUserinfo.user.accountType ? acceptUserinfo.user.accountType : AGENCY_ACCOUNT_TYPE
            );
            if (isBusinessUserAccount(acceptUserinfo.user.accountType)) {
                if (acceptUserinfo.user.timezone !== selectedTimezone) {
                    const userData = { timezone: selectedTimezone };
                    dispatch(updateUserInfo(userData));
                }
                return appHistory.push(`/dashboard/documents/${DOCUMENT_CUSTOM}/${LEASE_STATUS_DRAFT}`);
            } else {
                appHistory.push(`/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${LEASE_STATUS_DRAFT}`);
            }
        }
    }, [acceptUserStatus, acceptUserinfo]);

    useEffect(() => {
        if (checkTokenError && checkTokenError.includes(WRONG_USER_ERROR)) {
            const timeout = setTimeout(() => {
                logUserOut();
            }, SECONDS_UNTIL_LOGOUT * 1000);

            const interval = setInterval(() => {
                setTime(time => time - 1);
            }, 1000);
            return () => {
                clearInterval(interval);
                clearTimeout(timeout);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkTokenError]);

    useEffect(() => {
        if (invite && agency && !isMobileWidth()) {
            const user = extractUserAndAgencyInfo(invite.user, agency);
            boot({
                custom_launcher_selector: 'intercom_live_chat',
                customAttributes: { ...user, inviteType: invite.type, page: document.title }
            });
            pushDetailsToHubspot(user.email);

            initLiveSession(user);
        }
    }, [boot, invite, agency]);

    const postJoinTeam = data => {
        setSelectedTimezone(data.timezone);
        const dataReq = {
            password: data.password,
            repeatPassword: data.repeatPassword,
            inviteToken: props.match.params.id
        };
        dispatch(acceptUser(dataReq));
    };

    const postJoinExistingTeam = () => {
        const dataReq = {
            inviteToken: props.match.params.id
        };

        dispatch(acceptExistingUser(dataReq));
    };
    const { logo, details } = agency;

    return (
        <div className="join">
            <div className="content">
                {checkTokenStatusRequesting && (
                    <div className="left">
                        <div className="content-create">
                            <div className="create-account-agent">
                                <PreLoader />
                            </div>
                        </div>
                    </div>
                )}
                {!checkTokenStatusRequesting && checkTokenStatus && (
                    <React.Fragment>
                        {invite && invite.type === 'existing' && (
                            <div className="left">
                                <Link to="/" className="logo">
                                    <img src={logoImage} alt="logo" />
                                </Link>
                                <div className="content-create">
                                    <div className="create-account-agent">
                                        <div className="img">{logo && <img src={logo} alt="logo" />}</div>
                                        <h1>Join the team</h1>
                                        <p className="hi-text">
                                            Hi {user.firstName} {user.secondName}! {inviting.firstName}{' '}
                                            {inviting.secondName} has invited you to join the team at{' '}
                                            {details ? details.agencyName : ''}.
                                        </p>
                                        <p className="hi-text">
                                            Please click the button below to accept the invitation.
                                        </p>
                                        <div className="button">
                                            <button type="button" onClick={postJoinExistingTeam}>
                                                Join the team
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <HelpMessage />
                            </div>
                        )}
                        {invite && invite.type !== 'existing' && (
                            <div className="left">
                                <Link to="/" className="logo">
                                    <img src={logoImage} alt="logo" />
                                </Link>
                                <div className="content-create">
                                    <div className="create-account-agent">
                                        <div className="img">{logo && <img src={logo} alt="logo" />}</div>
                                        <h1>Join the team</h1>
                                        <p className="hi-text">
                                            Hi {user.firstName} {user.secondName}! {inviting.firstName}{' '}
                                            {inviting.secondName} has invited you to join the team at{' '}
                                            {details ? details.agencyName : ''}. Please create a password for your
                                            account.
                                        </p>
                                        <Form
                                            onSubmit={postJoinTeam}
                                            validate={({ password, repeatPassword }) => {
                                                return {
                                                    password:
                                                        (password && password.length) > 3 &&
                                                        (!isValidPassword(password)
                                                            ? INVALID_PASSWORD_MESSAGE
                                                            : undefined),
                                                    repeatPassword:
                                                        repeatPassword &&
                                                        (password !== repeatPassword
                                                            ? 'Passwords are not equal'
                                                            : undefined)
                                                };
                                            }}
                                        >
                                            {({ handleSubmit }) => {
                                                return (
                                                    <form onSubmit={handleSubmit}>
                                                        <FormPassword name="password" label="Password" />
                                                        <FormPassword name="repeatPassword" label="Confirm password" />
                                                        {isBusinessUserAccount(user.accountType) && (
                                                            <SelectField
                                                                defaultValue={{
                                                                    value: user.timezone,
                                                                    label: user.timezone,
                                                                    disabled: false
                                                                }}
                                                                name={'timezone'}
                                                                options={listOfTimezones(country)}
                                                                selectClassName="sel-4"
                                                            />
                                                        )}
                                                        <div className="button">
                                                            <button type="submit">Create account</button>
                                                        </div>
                                                    </form>
                                                );
                                            }}
                                        </Form>
                                    </div>
                                </div>
                                <HelpMessage />
                            </div>
                        )}
                    </React.Fragment>
                )}
                {!checkTokenStatusRequesting &&
                    !checkTokenStatus &&
                    !checkTokenError.includes(WRONG_USER_ERROR) &&
                    !checkTokenError.includes(ALREADY_ACCEPTED_ERROR) && (
                        <div className="left">
                            <div className="content-create">
                                <div className="create-account-agent">
                                    <h1>Join the team</h1>
                                    <p className="hi-text">
                                        The link to the invitation is invalid or expired. Please check your mailbox to
                                        see if you have received a new invitation after this one.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                {!checkTokenStatus && checkTokenError.includes(WRONG_USER_ERROR) && (
                    <div className="left">
                        <div className="content-create">
                            <div className="create-account-agent">
                                <h1>Warning</h1>
                                <p className="hi-text">
                                    This invitation was not sent to the user that is currently logged in.
                                </p>
                                <br />
                                <p>
                                    You will be automatically logged out in {SECONDS_UNTIL_LOGOUT} seconds or you can
                                    click{' '}
                                    <a onClick={logUserOut} className="special-link">
                                        here
                                    </a>{' '}
                                    to log out.
                                </p>
                                <br />
                                <p>
                                    <b>After you have been logged out, please click the link in your email again.</b>
                                </p>
                                <br />
                                <p>Logging you out in {time} seconds</p>
                            </div>
                        </div>
                    </div>
                )}
                {!checkTokenStatus && checkTokenError.includes(ALREADY_ACCEPTED_ERROR) && (
                    <div className="left">
                        <div className="content-create">
                            <div className="create-account-agent">
                                <h1>Already accepted</h1>
                                <p className="hi-text">This invitation was already accepted</p>
                                <br />
                                <a href="/" className="special-link">
                                    Please click here to go to Flk it over
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                <div className="right">
                    <HaveAccount />
                </div>
            </div>
        </div>
    );
};

export default memo(CreateAccountAgent);
