import React from 'react';

const TerminationNotice = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12pt" height="12pt" viewBox="0 0 12 12" version="1.1">
            <g id="surface1">
                <path
                    fill="white"
                    d="M 7.5 1 L 5.75 1 L 5.25 0.5 L 2.75 0.5 L 2.25 1 L 0.5 1 L 0.5 2 L 7.5 2 Z M 7.5 1 "
                />
                <path
                    fill="white"
                    d="M 8 4.5 C 7.648438 4.5 7.316406 4.550781 7 4.644531 L 7 2.5 L 1 2.5 L 1 9.5 L 4.839844 9.5 C 5.398438 10.679688 6.605469 11.5 8 11.5 C 9.933594 11.5 11.5 9.933594 11.5 8 C 11.5 6.066406 9.933594 4.5 8 4.5 Z M 8 10.5 C 6.621094 10.5 5.5 9.378906 5.5 8 C 5.5 6.621094 6.621094 5.5 8 5.5 C 9.378906 5.5 10.5 6.621094 10.5 8 C 10.5 9.378906 9.378906 10.5 8 10.5 Z M 8 10.5 "
                />
                <path
                    fill="white"
                    d="M 8.25 6 L 7.5 6 L 7.5 8.5 L 9.300781 9.550781 L 9.699219 8.949219 L 8.25 8.101562 Z M 8.25 6 "
                />
            </g>
        </svg>
    );
};
export default TerminationNotice;
