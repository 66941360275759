import styles from './Banner.module.scss';
import { Tag, TagColor, TagSize } from '../../common/components/tag/Tag';
import { subscriptionTitle } from '../../config';
import { formatDateTime, getAgencyTimezoneFromAgency } from '../../utils/dateUtils.js';
import React, { memo } from 'react';
import { Agency } from '../../types/Agency';
import ButtonEnterPaymentDetails from './buttons/ButtonEnterPaymentDetails';
import ButtonUpgrade from './buttons/ButtonUpgrade';
import { AgreementCountInfo } from '../../types/AgreementCountInfo';

const WAVE_HAND = '\u{1F44B}';

function WavingHand() {
    return (
        <div className={styles.emoji} role="img" aria-label="waving hand">
            {WAVE_HAND}
        </div>
    );
}

function getDocumentsText(agreements: number) {
    return agreements === 1 ? 'Document' : 'Documents';
}

type DocumentBannerProps = {
    agency: Agency;
    onTrial: boolean;
    agreementCountInfo: AgreementCountInfo;
    isUserAdmin: boolean;
};

const BannerMessage: React.FC<DocumentBannerProps> = ({
    agency,
    onTrial,
    agreementCountInfo: { daysLeftOnTrial, agreementsLeftOnTrial, agreementsLeftOnPlan, trialEnded },
    isUserAdmin
}) => {
    if (!agency) {
        return null;
    }

    const hasPaymentMethod = !!agency.stripeDefaultPaymentMethodId;

    if (onTrial && !trialEnded) {
        return (
            <>
                <div className={styles.bannerTags}>
                    <WavingHand />
                    <Tag
                        text={`${daysLeftOnTrial} Day${daysLeftOnTrial > 1 ? 's' : ''}`}
                        className={styles.countTag}
                        size={TagSize.Small}
                        color={TagColor.Default}
                    />
                    <div className={styles.text}>or</div>
                    <Tag
                        text={`${agreementsLeftOnTrial} ${getDocumentsText(agreementsLeftOnTrial)}`}
                        className={styles.countTag}
                        size={TagSize.Small}
                        color={TagColor.Default}
                    />
                    <div className={styles.text}>left in your trial</div>
                </div>
                {!hasPaymentMethod && <ButtonEnterPaymentDetails isUserAdmin={isUserAdmin} />}
            </>
        );
    }

    if (onTrial && trialEnded) {
        return (
            <>
                <div className={styles.bannerTags}>
                    <WavingHand />
                    <div className={styles.text}>Your trial period has ended.</div>
                </div>
                {!hasPaymentMethod && <ButtonEnterPaymentDetails isUserAdmin={isUserAdmin} />}
            </>
        );
    }

    if (agency.tariffPlan.hasFreeTrial && !hasPaymentMethod) {
        return (
            <>
                <div className={styles.bannerTags}>
                    <WavingHand />
                    <Tag
                        text={`${agreementsLeftOnPlan} ${getDocumentsText(agreementsLeftOnPlan)}`}
                        className={styles.countTag}
                        size={TagSize.Small}
                        color={TagColor.Default}
                    />
                    <div className={styles.text}>left in your plan</div>
                </div>
                {!hasPaymentMethod && <ButtonEnterPaymentDetails isUserAdmin={isUserAdmin} />}
            </>
        );
    }

    if (agreementsLeftOnPlan <= 0) {
        return (
            <>
                <div className={styles.bannerTags}>
                    <WavingHand />
                    <Tag
                        text={`${agreementsLeftOnPlan} ${getDocumentsText(agreementsLeftOnPlan)}`}
                        className={styles.countTag}
                        size={TagSize.Small}
                        color={TagColor.Default}
                    />
                    <div className={styles.text}>left in your plan</div>
                </div>
                {agency.tariffPlan.title !== subscriptionTitle.LARGE && <ButtonUpgrade isUserAdmin={isUserAdmin} />}
            </>
        );
    }

    if (agreementsLeftOnPlan <= 3 && agreementsLeftOnPlan > 0) {
        return (
            <>
                <div className={styles.bannerTags}>
                    <WavingHand />
                    <Tag
                        text={`${agreementsLeftOnPlan} ${getDocumentsText(agreementsLeftOnPlan)}`}
                        className={styles.countTag}
                        size={TagSize.Small}
                        color={TagColor.Default}
                    />
                    <div className={styles.text}>
                        {`left in your plan. It will renew on the ${formatDateTime(
                            agency.tariffPlan.currentPeriodEnd,
                            getAgencyTimezoneFromAgency(agency)
                        )}`}
                    </div>
                </div>
            </>
        );
    }

    return null;
};

export default memo(BannerMessage);
