import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import * as lease from '../../../actions/lease';
import * as dashboard from '../../../actions/dashboard';
import EditButton from '../../../common/components/EditButton';

class AgreementSection extends React.Component {
    getContainerClasses() {
        return classNames({
            'data-container': true,
            column:
                this.props.title === 'Additional Terms' ||
                this.props.title === 'Tenants' ||
                this.props.title === 'Renters' ||
                this.props.title === 'Landlord'
        });
    }

    render() {
        const { title, className, leaseAllInfo, step } = this.props;

        const lettersRegex = /[^A-Za-z]+/g;

        return (
            <div
                className={`section ${className ? className : ''} section-${title
                    .toLowerCase()
                    .replace(lettersRegex, '')}`}
            >
                {title && <h2>{title}</h2>}
                <div className={this.getContainerClasses()}>{this.props.children}</div>
                {title && leaseAllInfo.status === dashboard.LEASE_STATUS_DRAFT && step && (
                    <EditButton
                        onClick={this.editLeaseAgreement}
                        toolTip={`Edit ${title.toLowerCase()}`}
                        className="btn-edit-lease-agreement"
                        id={title}
                        toolTipPlace="bottom"
                    />
                )}
            </div>
        );
    }

    editLeaseAgreement = () => {
        const {
            leaseAllInfo,
            closeAgreementInfoModal,
            editLeaseAgreement,
            step,
            initialiseStateAgreement,
            user
        } = this.props;

        closeAgreementInfoModal();
        initialiseStateAgreement(leaseAllInfo);
        editLeaseAgreement(user, leaseAllInfo, step);
    };
}

export default connect(
    (state, props) => {
        return {
            leaseAllInfo: props.leaseAllInfo ? props.leaseAllInfo : state.lease.common.leaseAllInfo,
            user: state.user
        };
    },
    {
        editLeaseAgreement: lease.editLeaseAgreement,
        initialiseStateAgreement: lease.initialiseStateAgreement,
        closeAgreementInfoModal: dashboard.closeAgreementInfoModal
    }
)(AgreementSection);
