import React, { memo } from 'react';
import cx from 'classnames';
import { AccountType } from '../../../constants/constants';
import ModalDialog from '../../../common/components/ModalDialog.js';
import Button from '../../../common/components/Button.js';
import Icon, { Icons } from '../../../common/components/Icon';
import { isAgencyUserAccount } from '../../../utils/userUtils.js';
import styles from './EditSenderConfirmation.module.scss';
import * as dashboard from '../../../actions/dashboard.js';
import {
    BREACH_NOTICE,
    ENTRY_NOTICE,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE
} from '../../../config';

type EditSenderConfirmationProps = {
    isOpen: boolean;
    close: () => void;
    currentAgentName: string;
    assignedAgentName?: string;
    primaryHandler: () => void;
    secondaryHandler: (isEditPdf?: boolean) => void;
    revertHandler?: () => void;
    accountType?: AccountType;
    docStatus?: string;
    loggedUserInfo: {
        firstName: string;
        fullName: string;
    };
    isLoading: boolean;
    docType?: string;
};

type UploadADocEditSenderConfirmationContentProps = {
    currentAgentName: string;
    assignedAgentName?: string;
    primaryHandler: () => void;
    secondaryHandler: (isEditPdf?: boolean) => void;
    revertHandler?: () => void;
    accountType?: AccountType;
    docStatus?: string;
    loggedUserInfo: {
        firstName: string;
        fullName: string;
    };
    isLoading: boolean;
};

type EntryOrBreachNoticeSenderConfirmationContentProps = {
    currentAgentName: string;
    primaryHandler: () => void;
    secondaryHandler: (isEditPdf?: boolean) => void;
    loggedUserInfo: {
        firstName: string;
        fullName: string;
    };
    isLoading: boolean;
};

const EditSenderConfirmation: React.FC<EditSenderConfirmationProps> = ({
    isOpen,
    close,
    currentAgentName,
    assignedAgentName,
    primaryHandler,
    secondaryHandler,
    revertHandler,
    accountType,
    docStatus,
    loggedUserInfo,
    isLoading,
    docType
}) => {
    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={() => close()}
            contentLabel="Modal"
            zIndex={20}
            className={styles.editSenderConfirmation}
            shouldCloseOnOverlayClick={false}
            modalHeadClassName={styles.modalHead}
            bodyClassName={styles.modalBody}
        >
            <div className={styles.content}>
                {[
                    ENTRY_NOTICE,
                    BREACH_NOTICE,
                    DOCUMENT_INTENTION_TO_SELL,
                    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE
                ].includes(docType) ? (
                    <EntryOrBreachNoticeSenderConfirmationContent 
                        currentAgentName={currentAgentName}
                        primaryHandler={primaryHandler}
                        secondaryHandler={secondaryHandler}
                        loggedUserInfo={loggedUserInfo}
                        isLoading={isLoading}
                    />
                ) : (
                    <UploadADocEditSenderConfirmationContent
                        currentAgentName={currentAgentName}
                        assignedAgentName={assignedAgentName}
                        primaryHandler={primaryHandler}
                        secondaryHandler={secondaryHandler}
                        revertHandler={revertHandler}
                        accountType={accountType}
                        docStatus={docStatus}
                        loggedUserInfo={loggedUserInfo}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </ModalDialog>
    );
};

const UploadADocEditSenderConfirmationContent: React.FC<UploadADocEditSenderConfirmationContentProps> = ({
    currentAgentName,
    assignedAgentName,
    primaryHandler,
    secondaryHandler,
    revertHandler,
    accountType,
    docStatus,
    loggedUserInfo,
    isLoading
}) => {
    return (
        <>
            {docStatus && docStatus === dashboard.LEASE_STATUS_AWAITING_COMPLETION ? (
                <>
                    <p>
                        The {isAgencyUserAccount(accountType) ? 'AGENT' : 'SENDER'} NAME on the PDF is{' '}
                        {assignedAgentName}, The current assigned agent is {currentAgentName}.
                    </p>
                    <p>
                        If there is {isAgencyUserAccount(accountType) ? 'an AGENT' : 'a SENDER'} SIGNATURE box, the
                        logged in user {loggedUserInfo.fullName}'s signature will be placed on the document.
                    </p>
                </>
            ) : (
                <>
                    <p className={styles.title}>
                        The name of the assigned agent {currentAgentName} has been used on the PDF.
                    </p>
                    <div className={styles.editSenderMessage}>
                        <p>This document has placeholders for</p>
                        <div className={styles.editSenderAnnotation}>
                            <Button
                                draggable
                                startIcon={<Icon icon={Icons.LABEL} />}
                                className={styles.signatureButton}
                            >
                                {isAgencyUserAccount(accountType) ? 'Agent Name' : 'Sender Name'}
                            </Button>
                            <span> and/or </span>
                            <Button className={styles.signatureButton}>
                                {isAgencyUserAccount(accountType) ? 'Agent Signature' : 'Sender Signature'}
                            </Button>
                        </div>
                        <p>Changing the assigned agent will not update the name on the PDF.</p>
                    </div>
                </>
            )}
            <div className={cx(styles.buttonGroup, { [styles.threeButtons]: !!revertHandler })}>
                <Button
                    loading={isLoading}
                    secondary
                    className={styles.button}
                    onClick={() => secondaryHandler(docStatus !== dashboard.LEASE_STATUS_AWAITING_COMPLETION)}
                >
                    {docStatus === dashboard.LEASE_STATUS_AWAITING_COMPLETION ? 'Go Back' : 'Edit PDF'}
                </Button>
                {revertHandler && (
                    <Button secondary loading={isLoading} className={styles.button} onClick={revertHandler}>
                        Revert Change
                    </Button>
                )}
                <Button loading={isLoading} className={styles.button} primary onClick={primaryHandler}>
                    {docStatus === dashboard.LEASE_STATUS_AWAITING_COMPLETION ? 'Continue' : 'OK'}
                </Button>
            </div>
        </>
    );
};

const EntryOrBreachNoticeSenderConfirmationContent: React.FC<EntryOrBreachNoticeSenderConfirmationContentProps> = ({
    primaryHandler,
    secondaryHandler,
    loggedUserInfo,
    isLoading,
    currentAgentName
}) => {
    return (
        <div className={styles.entryNoticeContent}>
            <h3>This notice is assigned to {currentAgentName}, do you wish to continue?</h3>
            <div className={styles.message}>
                <p>
                    {currentAgentName}'s details will appear on the outgoing email to the tenant. Your signature will be
                    used to sign the notice.
                </p>
            </div>
            <div className={cx(styles.buttonGroup, styles.entryNoticeButtonGroup)}>
                <Button secondary loading={isLoading} className={styles.button} onClick={secondaryHandler}>
                    Edit
                </Button>
                <Button className={styles.button} loading={isLoading} primary onClick={primaryHandler}>
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default memo(EditSenderConfirmation);
