import axios from 'axios';
import * as User from './../reducers/user';
import { CLOSE_NO_SIGNATURE_MODAL, OPEN_NO_SIGNATURE_MODAL } from './types';
import { UPDATE_TEAM_MEMBER_VIEWING, CHANGE_AGENCY_DISABLED_GLOBAL_TEMPLATES } from '../reducers/user';

/**
 * Get user info request.
 * @returns {{types: [string|(function(*=, *): {userInfo: boolean, infoUser: {}, validationErrors: null, agencyId: string, syncIntegrations: {}, demoModule: null, isPending: boolean, agencyLocation: string}), string|(function(*=, *): {userInfo: boolean, infoUser: {}, validationErrors: null, agencyId: string, syncIntegrations: {}, demoModule: null, isPending: boolean, agencyLocation: string}), string|(function(*=, *): {userInfo: boolean, infoUser: {}, validationErrors: null, agencyId: string, syncIntegrations: {}, demoModule: null, isPending: boolean, agencyLocation: string})], promise: Promise<AxiosResponse<any>>}}
 */
export function getUserInfo() {
    return {
        types: [User.GET_USERINFO_REQUEST, User.GET_USERINFO_SUCCESS, User.GET_USERINFO_FAILURE],
        promise: axios.get('/api/user/info')
    };
}

/**
 * Get user request.
 * @returns {{types: [string|(function(*=, *): {userInfo: boolean, infoUser: {}, validationErrors: null, agencyId: string, syncIntegrations: {}, demoModule: null, isPending: boolean, agencyLocation: string}), string|(function(*=, *): {userInfo: boolean, infoUser: {}, validationErrors: null, agencyId: string, syncIntegrations: {}, demoModule: null, isPending: boolean, agencyLocation: string}), string|(function(*=, *): {userInfo: boolean, infoUser: {}, validationErrors: null, agencyId: string, syncIntegrations: {}, demoModule: null, isPending: boolean, agencyLocation: string})], promise: Promise<AxiosResponse<any>>}}
 */
export function getUser(id) {
    return {
        types: [User.GET_USER_REQUEST, User.GET_USER_SUCCESS, User.GET_USER_FAILURE],
        promise: axios.get(`/api/user/${id}`)
    };
}

/**
 * Get user info request.
 * @returns {{isSuperAdmin: *, type: string}}
 */
export function setIsSuperAdmin(isSuperAdmin) {
    return {
        type: User.UPDATE_IS_SUPER_ADMIN,
        isSuperAdmin
    };
}

export function updateUserInfo(values) {
    return () => axios.patch('/api/user', values);
}

export function updateUserStore(result) {
    return {
        type: User.UPDATE_USER_INFO_SUCCESS,
        result
    };
}

/**
 * Get agencies for a user request.
 * @returns {{types: [string|(function(*=, *): {userInfo: boolean, infoUser: {}, validationErrors: null, agencyId: string, syncIntegrations: {}, demoModule: null, isPending: boolean, agencyLocation: string}), string|(function(*=, *): {userInfo: boolean, infoUser: {}, validationErrors: null, agencyId: string, syncIntegrations: {}, demoModule: null, isPending: boolean, agencyLocation: string}), string|(function(*=, *): {userInfo: boolean, infoUser: {}, validationErrors: null, agencyId: string, syncIntegrations: {}, demoModule: null, isPending: boolean, agencyLocation: string})], promise: Promise<AxiosResponse<any>>}}
 */
export function getAgencies() {
    return {
        types: [User.GET_AGENCIES_REQUEST, User.GET_AGENCIES_SUCCESS, User.GET_AGENCIES_FAILURE],
        promise: axios.get('/api/user/agencies')
    };
}

/**
 * Add agency to list of agencies user can switch to
 * @returns {{agency: *, type: string}}
 */
export function addAgencyToAgencies(agency) {
    return {
        type: User.ADD_AGENCY_TO_AGENCIES,
        agency
    };
}

/**
 * Remove agency from list of agencies user can switch to
 * @returns {{agency: *, type: string}}
 */
export function removeAgencyFromAgencies(agencyId) {
    return {
        type: User.REMOVE_AGENCY_FROM_AGENCIES,
        agencyId
    };
}

export function addProfilePicture(logo) {
    return {
        types: [User.ADD_PROFILE_PICTURE_REQUEST, User.ADD_PROFILE_PICTURE_SUCCESS, User.ADD_PROFILE_PICTURE_FAIL],
        promise: axios.put('/api/user/profile-settings/image', logo)
    };
}

export function deleteProfilePicture() {
    return {
        types: [
            User.DELETE_PROFILE_PICTURE_REQUEST,
            User.DELETE_PROFILE_PICTURE_SUCCESS,
            User.DELETE_PROFILE_PICTURE_FAIL
        ],
        promise: axios.delete('/api/user/profile-settings/image')
    };
}

export const updateTeamMemberViewingState = teamMembers => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: UPDATE_TEAM_MEMBER_VIEWING,
                    teamMembers
                });
                resolve();
            } catch (error) {
                reject(error);
                throw error;
            }
        });
    };
};

export function updateTeamMemberViewing(teamMembers) {
    return {
        types: [
            User.UPDATE_TEAM_MEMBER_INFO_REQUEST,
            User.UPDATE_TEAM_MEMBER_INFO_SUCCESS,
            User.UPDATE_TEAM_MEMBER_INFO_FAIL
        ],
        promise: axios.post('/api/user/selected-team-members', { teamMembers })
    };
}

export function updateUserCredentials(credentials) {
    return {
        types: [User.UPDATE_USER_INFO_REQUEST, User.UPDATE_USER_INFO_SUCCESS, User.UPDATE_USER_INFO_FAIL],
        promise: axios.post('/api/user/info', credentials)
    };
}

export function updateUserLoginData(newUserData) {
    return {
        type: User.UPDATE_LOGIN_DATA_SUCCESS,
        payload: { newUserData }
    };
}

export function closeNoSignatureModal() {
    return {
        type: CLOSE_NO_SIGNATURE_MODAL
    };
}

export function openNoSignatureModal() {
    return {
        type: OPEN_NO_SIGNATURE_MODAL
    };
}

export function changeDisabledGlobalTemplates(disabledGlobalTemplates) {
    return {
        type: CHANGE_AGENCY_DISABLED_GLOBAL_TEMPLATES,
        payload: { disabledGlobalTemplates }
    };
}

export function getAgentCodeRequirementAndLabel() {
    return {
        types: [
            User.GET_CONNECTION_CODE_REQUIRED.REQUEST,
            User.GET_CONNECTION_CODE_REQUIRED.SUCCESS,
            User.GET_CONNECTION_CODE_REQUIRED.FAIL
        ],
        promise: axios.get('/api/connections/agent-code')
    };
}

/**
 * Use this function to attach/update/delete inspectionManager details to Agency
 *
 * @param {Object} params
 * @param {string} params.agencyId
 * @param {Object} param.data
 * @param {string} params.data.apiKey
 * @param {boolean} params.data.shouldRetrieveConditionReport
 * @param {boolean} params.data.deleteMe
 * @param {() => void} params.onEnd This function will be called once the API call finishes. Before going to the reducer
 * @param {Object} params.options other options you want to be associated in actions object inside reducer
 * @returns
 */
export function updateInspectionManagerIntegration(params) {
    const { agencyId, data, options = {}, onEnd } = params;
    const payload = { inspectionManager: data };
    return {
        types: [
            User.UPDATE_INSPECTION_MANAGER_INTEGRATION.REQUEST,
            User.UPDATE_INSPECTION_MANAGER_INTEGRATION.SUCCESS,
            User.UPDATE_INSPECTION_MANAGER_INTEGRATION.FAIL
        ],
        promise: axios
            .post(`/api/agency/${agencyId}/integration`, payload)
            .then(res => {
                if (onEnd) onEnd();
                return res;
            })
            .catch(error => {
                if (onEnd) onEnd();
                throw error;
            }),
        data,
        ...options
    };
}
