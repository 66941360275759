import * as React from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import Panel from '../../../common/components/Panel';
import LinksToolTip from '../../../common/components/tooltips/Links';
import { updateDefaultTenantLinks, clearLinkTemplatesToUpdate } from '../../../actions/document';
import { getUserAgency } from '../../../selectors/agency';
import {
    getDefaultTenantLinksByLeaseType,
    getAllDefaultTenantLinks,
    getLinksTemplatesToUpdate
} from '../../../selectors/settings/account';
import {
    isLeaseTypeSales,
    isLeaseTypeResidentialTenancy,
    getClientFromLeaseType,
    isLeaseTypeCommercialLease
} from '../../../utils/agreementUtils';
import Links from '../../shared/Links';

import '../../../sass/tenantLinks.scss';
import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmUpdateTemplates from '../../../containers/settings/UpdateTemplatesConfirmation';

export function DefaultDocumentLinks({ leaseType }) {
    const dispatch = useDispatch();
    const allLeaseTypeAgencyTenantLinks = useSelector(getAllDefaultTenantLinks);
    const templatesToUpdate = useSelector(getLinksTemplatesToUpdate);
    const initialAgencyTenantLinksForLeaseType = useSelector(state =>
        getDefaultTenantLinksByLeaseType(state, leaseType)
    );
    const agency = useSelector(getUserAgency);

    const clearTemplates = () => {
        dispatch(clearLinkTemplatesToUpdate());
    };

    useEffect(() => {
        if (templatesToUpdate && templatesToUpdate.templates && templatesToUpdate.templates.length > 0) {
            confirmAlert({
                customUI: ConfirmUpdateTemplates(templatesToUpdate, clearTemplates, () => {}, 'LINKS'),
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templatesToUpdate]);

    const updateLinksState = links => {
        const oldLinks = allLeaseTypeAgencyTenantLinks.links || [];

        // move links for this lease type
        const linksNotCurrentLeaseType = oldLinks.filter(link => {
            return link.leaseType !== leaseType;
        });
        const newAllLeaseTypeAgencyTenantLinks = { links: [] };
        newAllLeaseTypeAgencyTenantLinks.links = [].concat(linksNotCurrentLeaseType, links.links);

        return new Promise((resolve, reject) => {
            axios
                .post(`/api/agency/${agency.id}/${leaseType}/default-tenant-links`, newAllLeaseTypeAgencyTenantLinks)
                .then(response => {
                    dispatch(
                        updateDefaultTenantLinks(newAllLeaseTypeAgencyTenantLinks, response.data.templatesToUpdate)
                    );
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    const getTitle = () => {
        if (isLeaseTypeResidentialTenancy(leaseType)) {
            return 'Default Residential Tenancy Agreement links';
        } else if (isLeaseTypeSales(leaseType)) {
            return 'Default Sales Agreement links';
        } else if (isLeaseTypeCommercialLease(leaseType)) {
            return 'Default Commercial Lease Agreement links';
        } else {
            return 'Default Property Management Agreement links';
        }
    };

    return (
        <div className="tenant-links for-lg-modal">
            <Panel title={getTitle()}>
                <div className="text-gray">
                    Below you can add relevant links you would like your {getClientFromLeaseType(leaseType)} to access.{' '}
                    <LinksToolTip leaseType={leaseType} />
                </div>
                {initialAgencyTenantLinksForLeaseType && (
                    <Links
                        updateLinks={updateLinksState}
                        initialLinks={initialAgencyTenantLinksForLeaseType}
                        agencyDefault={true}
                        leaseType={leaseType}
                    />
                )}
            </Panel>
        </div>
    );
}

export default DefaultDocumentLinks;
