import React from 'react';
import cx from 'classnames';

import { UploadADoc } from '../../../types/UploadADoc';
import { UploadedDocumentState } from '../PdfViewer';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import UploadedDocumentInfoCard from './UploadedDocumentInfoCard';
import { removeFileExtension } from '../../../utils/documentUtils.js';
import Card, { CardShadow, CardStyles } from '../../../common/components/cards/Card';
import Icon, { Icons } from '../../../common/components/Icon';

import styles from './UploadedDocumentsDropdown.module.scss';

type Props = {
    customDoc: UploadADoc;
    uploadedDocumentStateItems: UploadedDocumentState[];
    selectedUploadedDocId?: string;
    onClickDocument: (documentId: string) => void;
    triggerClassName?: string;
};

const UploadedDocumentsDropdown: React.FC<Props> = ({
    customDoc,
    uploadedDocumentStateItems,
    selectedUploadedDocId,
    onClickDocument,
    triggerClassName
}) => {
    return (
        <RadixDropdown.Root>
            <RadixDropdown.Trigger className={cx(triggerClassName, styles.trigger)}>
                <span className={styles.count}>{customDoc.uploadedDocuments.length}</span>
                <span>Documents</span>
                <Icon icon={Icons.CHEVRON_DOWN} className={styles.icon} />
            </RadixDropdown.Trigger>
            <RadixDropdown.Portal>
                <RadixDropdown.Content
                    align="start"
                    className={styles.contentContainer}
                    data-test="uploadedDocumentsDropdown"
                >
                    <Card style={CardStyles.SQUARE} shadow={CardShadow.SMALL} className={styles.content}>
                        {customDoc.uploadedDocuments.map(uploadedDocument => {
                            const uploadedDocumentState = uploadedDocumentStateItems.find(
                                item => item.uploadedDocumentId === uploadedDocument.id
                            );
                            return (
                                <RadixDropdown.Item
                                    key={uploadedDocument.id}
                                    onSelect={() => {
                                        onClickDocument(uploadedDocument.id);
                                    }}
                                >
                                    <UploadedDocumentInfoCard
                                        displayName={removeFileExtension(uploadedDocument.document.documentName)}
                                        isSelected={uploadedDocument.id === selectedUploadedDocId}
                                        clientSignatureCounts={
                                            uploadedDocumentState?.clientAnnotationsCounts.signatureAnnotationCounts
                                        }
                                        agentSignatureCount={uploadedDocumentState?.agentSignatureAnnotationCount}
                                    />
                                </RadixDropdown.Item>
                            );
                        })}
                    </Card>
                </RadixDropdown.Content>
            </RadixDropdown.Portal>
        </RadixDropdown.Root>
    );
};

export default UploadedDocumentsDropdown;
