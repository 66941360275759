import React, { Component } from 'react';
import { forOwn } from 'lodash';
import { getPmStepNo, isLeaseLocationSA, isLeaseTypePm } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { upperFirst } from 'lodash';

export default class AgreementPmPayment extends Component {
    render() {
        const { paymentMethod } = this.props.leaseAllInfo;
        let selectedPaymentMethods = [];
        forOwn(paymentMethod, (item, key) => {
            if (item.enabled) {
                selectedPaymentMethods.push(key == 'OwnerToEnter' ? 'Owner To Enter' : key);
            }
        });
        const pmPaymentMethod =
            isLeaseLocationSA(this.props.leaseAllInfo.location) && isLeaseTypePm(this.props.leaseAllInfo.leaseType)
                ? 'paymentMethod'
                : 'pmPaymentMethod';
        return (
            <AgreementSection
                title="Payment Details"
                className="section-payment"
                step={getPmStepNo(this.props.leaseAllInfo.location, pmPaymentMethod)}
                leaseAllInfo={this.props?.leaseAllInfo}
            >
                <div className="data-container column">
                    <p className="text">
                        Payment method is <span className="bold">{selectedPaymentMethods.join(', ')}</span>
                    </p>
                    {isLeaseLocationSA(this.props.leaseAllInfo.location) &&
                        isLeaseTypePm(this.props.leaseAllInfo.leaseType) && (
                            <p className="text">
                                Frequency of funds deposit:&nbsp;
                                <span className="bold">
                                    {upperFirst(this.props.leaseAllInfo.paymentMethod?.frequencyOfFundsDeposit)}
                                </span>
                            </p>
                        )}
                </div>
            </AgreementSection>
        );
    }
}
