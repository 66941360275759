import React, { Component } from 'react';

import AgreementResidentialTimeExpires from './AgreementResidentialTimeExpires';
import AgreementResidentialSignors from '../residential/AgreementResidentialSignors';
import AgreementResidentialTenantsAgent from './AgreementResidentialTenantsAgent';
import AgreementResidentialTerm from './AgreementResidentialTerm';
import AgreementResidentialRent from './AgreementResidentialRent';
import AgreementResidentialRentIncrease from './AgreementResidentialRentIncrease';
import AgreementResidentialPayment from './AgreementResidentialPayment';
import AgreementResidentialInclusionsOccupants from './AgreementResidentialInclusionsOccupants';
import AgreementResidentialEmergencyContacts from './AgreementResidentialEmergencyContacts';
import AgreementResidentialNominatedRepairers from './QLD/AgreementResidentialNominatedRepairers';
import AgreementResidentialAdditional from './AgreementResidentialAdditional';
import AgreementResidentialMaterialFacts from './AgreementResidentialMaterialFacts';
import AgreementResidentialSpecialConditions from './AgreementResidentialSpecialConditions';
import AgreementMainDocuments from '../shared/AgreementMainDocuments';
import AgreementResidentialConnections from './AgreementResidentialConnections';
import AgreementMainAuditTrail from '../shared/AgreementMainAuditTrail';
import AgreementMainNotes from '../shared/AgreementMainNotes';

import '../../../../../sass/infoAgreement.scss';
import { LEASE_STATUS_DRAFT, LEASE_STATUS_SENT_SIGNING } from '../../../../../actions/dashboard';
import { QLD_STATE, SA_STATE, VIC_STATE } from '../../../../../config';
import AgreementResidentialExclusions from './SA/AgreementResidentialExclusions';
import AgreementResidentialOutgoings from './SA/AgreementResidentialOutgoings';
import AgreementResidentialInsurance from './SA/AgreementResidentialInsurance';
import AgreementResidentialPets from './SA/AgreementResidentialPets';
import AgreementResidentialDisclosure from './SA/AgreementResidentialDisclosure';
import AgreementResidentialInstructions from './SA/AgreementResidentialInstructions';
import AgreementResidentialExecutedAsAgreement from './SA/AgreementResidentialExecutedAsAgreement';
import AgreementResidentialRepairsAndMaintenance from './SA/AgreementResidentialRepairsAndMaintenance';
import {
    isDefaultSubLease,
    isLeaseLocationNSW,
    isLeaseLocationQLD,
    isLeaseLocationSA,
    isLongTermRenewalSubLease
} from '../../../../../utils/agreementUtils';
import RenewalFlow from './QLD/RenewalFlow';

import { AgreementResidentialPropertyTreeSync } from './AgreementResidentialPropertyTreeSync';
import { IntegrationType } from '../../../../../constants/constants';
import { RTA_PROPERTY_TREE_SYNC } from '../../../../../constants/featureFlags';
import { LEASE_STATUS_AWAITING_COMPLETION } from '../../../../../actions/dashboard';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
/**
 * Common component to render lease agreement info.
 */
const AgreementResidentialCommon = ({ leaseAllInfo }) => {
    const rtaPropertyTreeSyncEnabled = useFeatureFlag(RTA_PROPERTY_TREE_SYNC);
    if (isLongTermRenewalSubLease(leaseAllInfo.subLeaseType)) {
        return (
            <div>
                <AgreementResidentialSignors leaseAllInfo={leaseAllInfo} />
                <AgreementResidentialTerm leaseAllInfo={leaseAllInfo} />
                <AgreementResidentialRent leaseAllInfo={leaseAllInfo} />
                {leaseAllInfo?.rent?.isRentIncrease && <AgreementResidentialRentIncrease leaseAllInfo={leaseAllInfo} />}
            </div>
        );
    }

    const showPropertyTreeIntegration =
        rtaPropertyTreeSyncEnabled &&
        leaseAllInfo.status === LEASE_STATUS_AWAITING_COMPLETION &&
        leaseAllInfo?.integration?.active &&
        leaseAllInfo?.integration?.type === IntegrationType.PROPERTY_TREE;

    return (
        <div>
            {leaseAllInfo.status !== LEASE_STATUS_DRAFT && leaseAllInfo.status !== LEASE_STATUS_SENT_SIGNING && (
                <AgreementResidentialTimeExpires leaseAllInfo={leaseAllInfo} />
            )}
            <AgreementResidentialSignors leaseAllInfo={leaseAllInfo} />
            {!!leaseAllInfo.tenantsAgent &&
                ![VIC_STATE, SA_STATE, QLD_STATE].includes(leaseAllInfo.location) &&
                (!leaseAllInfo.tenantsAgent.doNotHaveAgent || leaseAllInfo.status === LEASE_STATUS_DRAFT) && (
                    <AgreementResidentialTenantsAgent
                        tenantsAgent={leaseAllInfo.tenantsAgent}
                        leaseAllInfo={leaseAllInfo}
                    />
                )}
            {isLeaseLocationSA(leaseAllInfo.location) && <AgreementResidentialExclusions leaseAllInfo={leaseAllInfo} />}
            <AgreementResidentialTerm leaseAllInfo={leaseAllInfo} />
            <AgreementResidentialRent leaseAllInfo={leaseAllInfo} />
            {leaseAllInfo?.rent?.isRentIncrease && <AgreementResidentialRentIncrease leaseAllInfo={leaseAllInfo} />}
            {!!leaseAllInfo.paymentMethod &&
                (leaseAllInfo.paymentMethod.DirectDebit ||
                    leaseAllInfo.paymentMethod.BPAY ||
                    leaseAllInfo.paymentMethod.CashorChequie ||
                    leaseAllInfo.paymentMethod.DEFT ||
                    leaseAllInfo.paymentMethod.EFT ||
                    leaseAllInfo.status === LEASE_STATUS_DRAFT) && (
                    <AgreementResidentialPayment
                        paymentMethod={leaseAllInfo.paymentMethod}
                        leaseAllInfo={leaseAllInfo}
                    />
                )}
            {isLeaseLocationSA(leaseAllInfo.location) && (
                <React.Fragment>
                    <AgreementResidentialOutgoings leaseAllInfo={leaseAllInfo} />
                    <AgreementResidentialInsurance leaseAllInfo={leaseAllInfo} />
                    <AgreementResidentialPets leaseAllInfo={leaseAllInfo} />
                    <AgreementResidentialRepairsAndMaintenance leaseAllInfo={leaseAllInfo} />
                    <AgreementResidentialDisclosure leaseAllInfo={leaseAllInfo} />
                    <AgreementResidentialInstructions leaseAllInfo={leaseAllInfo} />
                </React.Fragment>
            )}
            {!isLeaseLocationSA(leaseAllInfo.location) && (
                <AgreementResidentialInclusionsOccupants
                    leaseStatus={leaseAllInfo.status}
                    leaseAllInfoInclusions={leaseAllInfo.inclusions}
                    leaseAllInfoOccupants={leaseAllInfo.maxOccupants}
                    leaseAllInfo={leaseAllInfo}
                />
            )}

            {!isLeaseLocationSA(leaseAllInfo.location) &&
                !isLeaseLocationQLD(leaseAllInfo.location) &&
                leaseAllInfo.emergencyContacts &&
                (!!leaseAllInfo.emergencyContacts.length || leaseAllInfo.status === LEASE_STATUS_DRAFT) && (
                    <AgreementResidentialEmergencyContacts
                        emergencyContactList={leaseAllInfo.emergencyContacts}
                        leaseAllInfo={leaseAllInfo}
                    />
                )}
            {isLeaseLocationQLD(leaseAllInfo.location) && leaseAllInfo.nominatedRepairers && (
                <AgreementResidentialNominatedRepairers leaseAllInfo={leaseAllInfo} />
            )}

            {!isLeaseLocationSA(leaseAllInfo.location) &&
                !!leaseAllInfo.additionalTerms &&
                (leaseAllInfo.additionalTerms.separatedWaterUsage ||
                    leaseAllInfo.additionalTerms.communityScheme ||
                    leaseAllInfo.additionalTerms.allowAnimals ||
                    leaseAllInfo.additionalTerms.animals ||
                    leaseAllInfo.status === LEASE_STATUS_DRAFT) && (
                    <AgreementResidentialAdditional
                        additionalTerms={leaseAllInfo.additionalTerms}
                        leaseAllInfo={leaseAllInfo}
                    />
                )}
            {isLeaseLocationNSW(leaseAllInfo.location) && !!leaseAllInfo.materialFacts && (
                <AgreementResidentialMaterialFacts
                    materialFacts={leaseAllInfo.materialFacts}
                    leaseAllInfo={leaseAllInfo}
                />
            )}
            {!!leaseAllInfo.specialConditions && (
                <AgreementResidentialSpecialConditions
                    specialConditions={leaseAllInfo.specialConditions}
                    leaseAllInfo={leaseAllInfo}
                />
            )}

            {isDefaultSubLease(leaseAllInfo.subLeaseType) && (
                <AgreementMainDocuments documents={leaseAllInfo.documents} leaseAllInfo={leaseAllInfo} />
            )}
            {isLeaseLocationSA(leaseAllInfo.location) ? (
                <AgreementResidentialExecutedAsAgreement leaseAllInfo={leaseAllInfo} />
            ) : (
                <AgreementResidentialConnections connections={leaseAllInfo.connections} leaseAllInfo={leaseAllInfo} />
            )}
            {showPropertyTreeIntegration && <AgreementResidentialPropertyTreeSync leaseId={leaseAllInfo.id} />}
            {leaseAllInfo.auditTrail && leaseAllInfo.auditTrail.length ? (
                <AgreementMainAuditTrail leaseId={leaseAllInfo.leaseId} />
            ) : null}
            {leaseAllInfo.notes && leaseAllInfo.notes.length ? (
                <AgreementMainNotes notes={leaseAllInfo.notes} id={leaseAllInfo.leaseId} />
            ) : null}
            {isLeaseLocationQLD(leaseAllInfo.location) && leaseAllInfo.term && !leaseAllInfo.term.periodic && (
                <RenewalFlow leaseAllInfo={leaseAllInfo} />
            )}
        </div>
    );
};

export default AgreementResidentialCommon;
