import axios from 'axios';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import cookie from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import appHistory from '../../AppHistory';
import * as dashboard from '../../actions/dashboard';
import { loadDocumentList } from '../../actions/dashboard';
import { openDocumentForm } from '../../actions/document';
import * as loginActions from '../../actions/login';
import { PreLoader } from '../../common/components/PreLoader';
import { useRollbarProvider } from '../../components/providers/RollbarProvider';
import {
    CREATE_A_FLK_GLOBAL_TEMPLATE,
    CREATE_A_FLK_TEMPLATE,
    DEFAULT_PAGE,
    DEFAULT_RECORDS_PER_PAGE,
    DOCUMENT_CREATE_A_FLK,
    DOCUMENT_CUSTOM,
    DOCUMENT_EDIT_MODE,
    DOCUMENT_READ_ONLY_MODE,
    DOCUMENT_TEMPLATE_MODE,
    FLK_A_PDF_TEMPLATE
} from '../../config';
import '../../sass/_welcome.scss';
import '../../sass/layout.scss';
import { getIsPending } from '../../selectors/dashboard/agreementList';
import { getIsUserLoggedIn } from '../../selectors/login';
import { getSavedTeamMemberSelection, getUserInfo } from '../../selectors/user';

const Document = () => {
    const dispatch = useDispatch();
    const { docId } = useParams();
    const history = useHistory();
    const Rollbar = useRollbarProvider();

    const isPending = useSelector(getIsPending);
    const savedTeamMemberSelection = useSelector(getSavedTeamMemberSelection);
    const loggedInUser = useSelector(getUserInfo);

    let [doc, setDoc] = useState(false);

    const isUserLoggedIn = useSelector(getIsUserLoggedIn);

    const currentPath = `${appHistory.location.pathname}${appHistory.location.search}`;

    useEffect(() => {
        if (isUserLoggedIn == null) return;

        if (!isUserLoggedIn) {
            dispatch(loginActions.setLoginRedirect(currentPath));
            appHistory.push('/');
        } else {
            getDocument();
        }
    }, [currentPath, dispatch, isUserLoggedIn]);

    useEffect(() => {
        if (!isPending && doc && loggedInUser && loggedInUser.agency) {
            if (doc.agency.id === loggedInUser.agency.id) {
                //Used to check for #annotation-viewer-anchor
                const originalPath = window.location.href;
                if (doc.docType === FLK_A_PDF_TEMPLATE) {
                    history.push(`/user/upload-a-doc-templates`);
                    dispatch(openDocumentForm(DOCUMENT_CUSTOM, doc, DOCUMENT_TEMPLATE_MODE));
                } else if (doc.docType === CREATE_A_FLK_TEMPLATE) {
                    history.push(`/user/build-a-doc-templates`);
                    dispatch(openDocumentForm(DOCUMENT_CREATE_A_FLK, doc, DOCUMENT_TEMPLATE_MODE));
                } else if (doc.docType === CREATE_A_FLK_GLOBAL_TEMPLATE) {
                    history.push(`/user/build-a-doc-templates`);
                    dispatch(openDocumentForm(DOCUMENT_CREATE_A_FLK, doc, DOCUMENT_TEMPLATE_MODE, true));
                } else {
                    history.push(`/dashboard/documents/${doc.docType}/${doc.status}`);
                    if (doc.status === dashboard.LEASE_STATUS_DRAFT) {
                        dispatch(openDocumentForm(doc.docType, doc, DOCUMENT_EDIT_MODE));
                    } else {
                        dispatch(openDocumentForm(doc.docType, doc, DOCUMENT_READ_ONLY_MODE));
                    }
                }
                if (originalPath.indexOf('#annotation-viewer-anchor') !== -1) {
                    window.history.pushState({}, '', originalPath);
                } else {
                    window.history.pushState({}, '', `/dashboard/document/${doc.id}`);
                }
            } else {
                history.push(`/dashboard/documents/${doc.docType}/${doc.status}`);
                confirmAlert({
                    title: '',
                    message: `This document is linked to ${doc.agency.details.agencyName}, you are currently logged into ${loggedInUser.agency.details.agencyName} please switch to the correct account.`,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {
                                window.history.pushState({}, '', `/dashboard/document/${doc.id}`);
                            }
                        }
                    ]
                });
            }
        }
    }, [isPending, loggedInUser]);

    const getDocument = () => {
        return axios
            .get(`/api/document/${docId}`)
            .then(result => {
                const document = result.data.doc;
                setDoc(document);
                dispatch(
                    loadDocumentList(
                        document.docType,
                        DEFAULT_PAGE,
                        DEFAULT_RECORDS_PER_PAGE,
                        document.status,
                        savedTeamMemberSelection
                    )
                );
            })
            .catch(error => {
                Rollbar.error(
                    'Failed to get document from direct URL ',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );

                appHistory.push('/');
            });
    };

    return (
        <div className="preloader-centred">
            <PreLoader />
        </div>
    );
};

export default memo(Document);
