/**
 * @param {object} scope
 * @param {object} rules
 * @return {{}}
 * @private
 */
const _validate = (scope, rules) => {
    let errors = {};
    rules.forEach(rule => {
        let value = scope[rule.field];
        if (!rule.validate(value)) {
            if (!value) {
                return;
            }
            errors[rule.field] = rule.message || undefined;
        }
    });

    return errors;
};

/**
 * Handler for the client errors.
 *
 * @param {function} onErrorCallback function that will be invoked if there are client side validation errors.
 * @private
 */
const _handleClientSideErrors = (clientErrors, onErrorCallback = null) => {
    if (null !== onErrorCallback) {
        if (typeof onErrorCallback !== 'function') {
            throw new TypeError('onErrorCallback must be a function');
        }

        onErrorCallback(clientErrors);
    }
};

/**
 * Check that object is empty.
 *
 * @param {object} obj
 * @return {boolean}
 * @private
 */
export const isEmpty = obj => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }

    return true;
};

/**
 * Change handler for the react-form.
 *
 * @todo invoke this handler in all parts of lease creation forms.
 * @param {string} formName name of the form, e.g. 'term', 'rent'..
 * @param {object} serverValidationErrors server side errors. Usually it store in the `this.props.validationErrors`.
 * @param {array<{object}>} clientValidationRules array with setting for the client validator. Each item of this
 *                          array should contain next fields:
 *                          - `fields`: {string} - name of form value that will be validated.
 *                          - `rule`: {function} - function that will be invoked to check field. Callback receive `value`
 *                                               and should return bool - validation result.
 *                          - optional 'message`: {string} - error message will be shown in the form
 * @param {function} onErrorCallback function that will be invoked if there are client side validation errors.
 * @return {function(*)}
 */
export const formChangeHandler = (
    formName,
    serverValidationErrors,
    clientValidationRules = null,
    onErrorCallback = null
) => {
    return props => {
        let errors = serverValidationErrors;
        let clientErrors = null;
        if (!errors) {
            if (null !== clientValidationRules) {
                clientErrors = _validate(props.values, clientValidationRules);
            }
            _handleClientSideErrors(clientErrors, onErrorCallback);

            if (false === isEmpty(clientErrors)) {
                errors = {
                    [formName]: clientErrors
                };
            } else {
                return;
            }
        }
        let touched = !errors[formName] ? {} : errors[formName];

        return {
            values: props.values,
            touched: touched,
            errors: touched
        };
    };
};
