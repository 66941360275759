import React, { Component } from 'react';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo } from '../../../../../utils/agreementUtils';

class AgreementSalesInspectionDays extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {leaseAllInfo.inspectionDays && (
                    <AgreementSection
                        title="Inspection days"
                        step={getSalesStepNo(leaseAllInfo.location, 'specialConditions')}
                        leaseAllInfo={this.props.leaseAllInfo}
                    >
                        <div className="agreement-section-text">
                            <div>
                                {leaseAllInfo.inspectionDays.openForInspectionText && (
                                    <p className="text">
                                        <strong>Inspection days:</strong>{' '}
                                        {leaseAllInfo.inspectionDays.openForInspectionText}
                                    </p>
                                )}
                            </div>
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementSalesInspectionDays;
