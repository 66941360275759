import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../../sass/haveAccount.scss';

class HaveAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { step } = this.props;
        let content = <div />;

        if (step === 1 || step === 7) {
            content = (
                <div className="get-account">
                    <div className="account-text">
                        <p>Already have an account?</p>
                    </div>
                    <div>
                        <Link to="/" className="signup">
                            Sign In
                        </Link>
                    </div>
                </div>
            );
        }

        return content;
    }
}

export default HaveAccount;
