import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import Button from '../../../../../common/components/Button';
import { FormTextRegular } from '../../../../../components/form/FormText';
import { Form } from 'react-final-form';
import { AsyncSelect } from '../../../../../components/form/AsyncSelect';
import { customSelectStyle } from '../../../../../utils/formUtils';
import logger from '../../../../../utils/logger';
import { FLK_A_PDF_TEMPLATE, DOCUMENT_CUSTOM } from '../../../../../config';
import { getGeneralAppLabel } from '../../../../../utils/labelUtils';
import { cloneDeep, find, has, map } from 'lodash';
import DocumentUpload from '../../../../shared/DocumentUpload';
import { confirmAlert } from 'react-confirm-alert';
import { addOrReplaceDocumentInList, setCurrentDocument } from '../../../../../actions/document';
import { getTemplates } from '../../../../../actions/template';
import { useDispatch, useSelector } from 'react-redux';
import { LEASE_STATUS_DRAFT } from '../../../../../actions/dashboard';
import { getFiles } from '../../../../../selectors/document';
import { useRollbarProvider } from '../../../../../components/providers/RollbarProvider';
import { CLONE_TEMPLATE, ONBOARDING_TRACKING_PREFIX, SEARCH_RESULT_DOCUMENT, SEARCH_RESULT_TEMPLATE } from '../../../../../constants/constants';
import Tooltip from '../../../../../common/components/tooltips/Tooltip';

const FLK_A_PDF_CREATE_API_WITH_FILES = 'api/onboarding/create-custom-document-with-files';

const FlkAPdfCreatePublic = ({
    closeModal,
    creatingDocument,
    isTemplate,
    clonedSelectedTemplate,
    setCreatingDocument,
    setCustomDocument,
    setUploadADocId,
    setDocumentCreated
}) => {
    const Rollbar = useRollbarProvider();
    const history = useHistory();

    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [uploading, setUploading] = useState(false);
    const [formData, setFormData] = useState({});
    const [documentsList, setDocumentsList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
    const filesFromDashboard = useSelector(getFiles);

    useEffect(() => {
        updateDocumentsList(filesFromDashboard);
    }, [filesFromDashboard]);

    const canStart = values => {
        return !(!values.documentTitle || (isTemplate && !values.templateName));
    };

    const noOptionsMessage = props => {
        return (
            <div className="no-option-message" {...props}>
                {searchQuery
                    ? searchQuery.length < 3
                        ? 'Your search is too short, keep typing'
                        : 'No result found for your input'
                    : 'Please type to search templates and agreements'}
            </div>
        );
    };

    const loadOptions = (inputValue, callback) => {
        return callback([]);
    };

    const changeTemplate = (selectedTemplate, values) => {
        if (selectedTemplate) {
            const template = cloneDeep(find(availableTemplates, { id: selectedTemplate.value }));
            template.isCreatedFromTemplate = true;
            template.status = LEASE_STATUS_DRAFT;
            template.docType = FLK_A_PDF_TEMPLATE;
            if (isTemplate && template.client && template.client.clients) {
                template.client.clients.forEach(client => {
                    client.signature = undefined;
                    client.tempSignature = undefined;
                    client.acknowledgedDate = undefined;
                });
            }
            dispatch(setCurrentDocument(template));
            setSelectedTemplate(selectedTemplate);
            const data = {
                ...values,
                documentTitle: template.documentTitle
            };
            setFormData(data);
        } else if (selectedTemplate === null) {
            setSelectedTemplate(null);
        }
    };

    const updateDocumentsList = function (files) {
        let data;
        if (clonedSelectedTemplate) {
            data = {
                documentTitle: clonedSelectedTemplate.documentTitle
            };
        } else {
            data = {
                documentTitle: files && files[0] ? files[0].documentName.replace('.pdf', '') : ''
            };
        }
        setSelectedTemplate(clonedSelectedTemplate ? clonedSelectedTemplate : null);
        setFormData(data);
        setDocumentsList(files);
    };

    const createFlkAPdf = values => {
        setUploading(true);
        setCreatingDocument(true);

        const url = FLK_A_PDF_CREATE_API_WITH_FILES;

        let data = new FormData();
        if (documentsList.length > 0 && !selectedTemplate) {
            data.append('documentTitle', values.documentTitle);
            if (selectedTemplate) {
                data.append('selectedTemplate', selectedTemplate.value);
            }

            map(documentsList, (value, key) => {
                data.append(`documents[${key}][documentName]`, value.documentName);
                data.append(`documents[${key}][file]`, value.file);

                if (has(value, 'fileName')) {
                    data.append(`documents[${key}][fileName]`, value.fileName);
                }

                if (value.file.size) {
                    data.append(`documents[${key}][size]`, value.file.size);
                }
            });
        } else {
            data = cloneDeep(values);
            data.documents = documentsList;
            if (selectedTemplate?.type === SEARCH_RESULT_TEMPLATE || selectedTemplate?.type === CLONE_TEMPLATE) {
                data.selectedTemplate = selectedTemplate?.value;
            } else if (selectedTemplate?.type === SEARCH_RESULT_DOCUMENT) {
                data.selectedDocument = selectedTemplate?.value;
            }
        }

        return axios
            .post(url, data, {
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    setDocumentUploadProgress(Math.floor((loaded * 100) / total));
                }
            })
            .then(response => {
                setUploading(false);

                const flkAPdfDocument = response.data?.customDocument || response.data.flkAPdfTemplate;

                setDocumentCreated(true);
                history.push('?step=main');
                setCustomDocument(flkAPdfDocument);
                setUploadADocId(flkAPdfDocument.id);
            })
            .catch(error => {
                Rollbar.error(
                    'Error saving documents',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
                setUploading(false);
                confirmAlert({
                    title: '',
                    message: `Failed to save documents, please try again, if this does not resolve the issue please contact FLK`,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => { }
                        }
                    ]
                });
                const response = error.response;
                if (response && response.data && response.data.errors) {
                    return response.data.errors;
                }
                return false;
            })
            .finally(() => {
                setCreatingDocument(false);
            });
    };

    const handleSubmit = values => {
        const data = cloneDeep(values);

        // add documents to values
        data.documents = documentsList;
        setDocumentUploadProgress(0);

        return createFlkAPdf(data);
    };

    return (
        <div className="location-address-details">
            <Form initialValues={formData} onSubmit={handleSubmit}>
                {({ handleSubmit, values }) => {
                    // set the values to the ref so that the close modal can access these values
                    return (
                        <form noValidate onSubmit={values => handleSubmit(values)} id="create-custom-doc-form" data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-create-form`}>
                            <div className="template-selection">
                                {!isTemplate && !selectedTemplate && (
                                    <div className="document-upload-container">
                                        <DocumentUpload
                                            // happens on the server, so is not needed here
                                            flattenPdfs={false}
                                            leaseType={DOCUMENT_CUSTOM}
                                            rejectEncryptedPdfs
                                            hideFileCategory
                                            hideAttachedLabel
                                            uploading={uploading}
                                            additionalOptionsForDocument={{ leaseType: DOCUMENT_CUSTOM }}
                                            setDocumentUploadProgress={setDocumentUploadProgress}
                                            updateDocuments={files => {
                                                updateDocumentsList(files);
                                            }}
                                            documents={documentsList}
                                            documentUploadProgress={documentUploadProgress}
                                            maxFileSize={100}
                                            maxTotalSize={100}
                                        />
                                    </div>
                                )}
                                {isTemplate && (
                                    <FormTextRegular
                                        name="templateName"
                                        label="Template Name"
                                        disabled={values.disabled}
                                    />
                                )}
                                <FormTextRegular
                                    name="documentTitle"
                                    label="Document title"
                                    disabled={values.disabled}
                                />
                                {documentsList.length === 0 && (
                                    <>
                                        <AsyncSelect
                                            isDisabled
                                            defaultOptions
                                            isClearable={true}
                                            value={selectedTemplate}
                                            styles={customSelectStyle}
                                            loadOptions={loadOptions}
                                            onChange={selected => changeTemplate(selected, values)}
                                            onInputChange={setSearchQuery}
                                            selectClassName="async-select"
                                            placeholder="Select Template..."
                                            components={{
                                                NoOptionsMessage: noOptionsMessage
                                            }}
                                            data-tip="Account holders can create templates to use for future documents."
                                            data-for="templateTooltip"
                                        />
                                        <Tooltip id="templateTooltip" effect="solid" place="bottom" />
                                    </>
                                )}

                                <div className="footer footer-draft">
                                    <div className="button-row">
                                        <div className="button-cancel">
                                            <Button type="button" secondary className="btn-close" height={40} onClick={closeModal} data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-create-form-cancel-button`}>
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="button-start">
                                            <Button
                                                primary
                                                className="btn-start"
                                                height={40}
                                                type="submit"
                                                disabled={!canStart(values) || creatingDocument}
                                                data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-create-form-start-button`}
                                            >
                                                {creatingDocument && (
                                                    <span className="savestatus-spinner-await-completion" />
                                                )}
                                                Start
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};
export default FlkAPdfCreatePublic;
