import React from 'react';
import Card, { CardStyles } from '../../common/components/cards/Card';
import Button from '../../common/components/Button.js';
import { ReactComponent as FlkLogo } from '../../../assets/images/logo.svg';
import styles from './VerifyEmail.module.scss';

type VerifyEmailProps = {
    onResendEmail: () => void;
    emailAddress: string;
};

const VerifyEmail: React.FC<VerifyEmailProps> = ({ onResendEmail, emailAddress }) => {
    return (
        <div className={styles.background}>
            <Card className={styles.container} animate={false} style={CardStyles.SQUARE}>
                <FlkLogo className={styles.flkLogo} />
                <div className={styles.content}>
                    <div>
                        <strong>Congratulations! Your profile is all set.</strong>
                        <div>
                            To complete your account, please verify your email address. We've sent a confirmation email
                            to
                        </div>
                        <strong>{emailAddress}</strong>
                    </div>
                    <div>
                        <strong>Haven't received it yet?</strong>
                        <ul className={styles.itemList}>
                            <li>Check your spam folder.</li>
                            <li>
                                If it's not there, you can request a new verification email by clicking the button
                                below.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <strong>Think you might have entered the wrong email address?</strong>
                        <div>No worries, just contact our support team and we'll be happy to help.</div>
                    </div>
                </div>
                <Button primary onClick={onResendEmail}>
                    Resend Email
                </Button>
            </Card>
        </div>
    );
};

export default VerifyEmail;
