import React from 'react';
import { getPmStepNo, getPmStepTitle } from '../../../../../../utils/agreementUtils.js';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection.js';
import { LeaseAllInfo } from '../../../../../types';

interface AgreementPmOutgoingsProps {
    leaseAllInfo: LeaseAllInfo;
}

const prepareOutGoingList = function (outgoingList: Array<string>, type: string): string {
    let list = '';
    outgoingList.map((value: any) => {
        if (value.type === type) {
            list += `${value.label}, `;
        }
    });
    const preparedList = list.slice(0, -2);

    if (preparedList) {
        return preparedList;
    }
    return 'n/a';
};

const AgreementPmOutgoings: React.FC<AgreementPmOutgoingsProps> = ({ leaseAllInfo }) => {
    const { thirdPartyPayment, location } = leaseAllInfo;
    return (
        <React.Fragment>
            <AgreementSection
                title={getPmStepTitle(location, 'thirdPartyPayment')}
                step={getPmStepNo(location, 'thirdPartyPayment')}
                leaseAllInfo={leaseAllInfo}
            >
                <div className="agreement-section-text">
                    {thirdPartyPayment?.agentToPayList.length > 0 && (
                        <React.Fragment>
                            <div className="propertyDetails">
                                <p className="name">
                                    The Agent is authorised and instructed to pay the following on behalf of the
                                    Landlord:
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Fees & Expenses:&nbsp;
                                    <strong>{prepareOutGoingList(thirdPartyPayment.agentToPayList, 'fee')}</strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Service/Maintenance:&nbsp;
                                    <strong>{prepareOutGoingList(thirdPartyPayment.agentToPayList, 'service')}</strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Insurance:&nbsp;
                                    <strong>
                                        {prepareOutGoingList(thirdPartyPayment.agentToPayList, 'insurance')}
                                    </strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Other:&nbsp;
                                    <strong>{prepareOutGoingList(thirdPartyPayment.agentToPayList, 'other')}</strong>
                                </p>
                            </div>
                        </React.Fragment>
                    )}
                    {thirdPartyPayment?.ownerToInstructList.length > 0 && (
                        <React.Fragment>
                            <div className="propertyDetails">
                                <p className="name">
                                    The Landlord will be asked to indicate which outgoings they authorise and instruct
                                    the Agent to pay on their behalf:
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Fees & Expenses:&nbsp;
                                    <strong>{prepareOutGoingList(thirdPartyPayment.ownerToInstructList, 'fee')}</strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Service/Maintenance:&nbsp;
                                    <strong>
                                        {prepareOutGoingList(thirdPartyPayment.ownerToInstructList, 'service')}
                                    </strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Insurance:&nbsp;
                                    <strong>
                                        {prepareOutGoingList(thirdPartyPayment.ownerToInstructList, 'insurance')}
                                    </strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Other:&nbsp;
                                    <strong>
                                        {prepareOutGoingList(thirdPartyPayment.ownerToInstructList, 'other')}
                                    </strong>
                                </p>
                            </div>
                        </React.Fragment>
                    )}
                    {thirdPartyPayment?.notApplicableList.length > 0 && (
                        <React.Fragment>
                            <div className="propertyDetails">
                                <p className="name">The following outgoings are not applicable:</p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Fees & Expenses:&nbsp;
                                    <strong>{prepareOutGoingList(thirdPartyPayment.notApplicableList, 'fee')}</strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Service/Maintenance:&nbsp;
                                    <strong>
                                        {prepareOutGoingList(thirdPartyPayment.notApplicableList, 'service')}
                                    </strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Insurance:&nbsp;
                                    <strong>
                                        {prepareOutGoingList(thirdPartyPayment.notApplicableList, 'insurance')}
                                    </strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Other:&nbsp;
                                    <strong>{prepareOutGoingList(thirdPartyPayment.notApplicableList, 'other')}</strong>
                                </p>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </AgreementSection>
        </React.Fragment>
    );
};

export default AgreementPmOutgoings;
