import React from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo, getSalesStepTitle } from '../../../../../../utils/agreementUtils';
import DataString from '../../bloks/DataString';

export default function AgreementSalesManagingAgent({ leaseAllInfo }) {
    const partName = 'managingAgent';
    const { managingAgent, location } = leaseAllInfo;

    if (!managingAgent) {
        return <></>;
    }

    return (
        <AgreementSection
            title={getSalesStepTitle(location, partName)}
            step={getSalesStepNo(location, partName)}
            leaseAllInfo={leaseAllInfo}
        >
            <div className="agreement-section-text">
                {!managingAgent.hasManagingAgent && <p className="name">There is no managing agent</p>}
                {managingAgent.hasManagingAgent && (
                    <>
                        <p className="name">{managingAgent.agencyName}</p>
                        <DataString title={'Property Manager Name:'} text={managingAgent.agentFullName} />
                        <DataString title={'Address:'} text={managingAgent.address || 'Not stated'} />
                        <p className="phone">{managingAgent.phone}</p>
                        <p className="email">{managingAgent.email}</p>
                        {managingAgent.isSendNotifications && (
                            <p>
                                I wish to send the managing agent a notification of the intention to sell the property
                            </p>
                        )}
                    </>
                )}
            </div>
        </AgreementSection>
    );
}
