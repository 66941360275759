import * as Dashboard from '../actions/dashboard';
import * as Lease from './lease';
import {
    CREATE_A_FLK_TEMPLATE,
    CREATE_A_FLK_GLOBAL_TEMPLATE,
    FLK_A_PDF_TEMPLATE,
    TEMPLATE_TYPE_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_SALES,
    DEFAULT_SUB_LEASE_TYPE
} from '../config';
import logger from '../utils/logger';
import * as Renewal from './../reducers/renewal';
import {
    ADDITIONAL_SALES_TERMS_SUCCESS,
    CLONE_AGREEMENT_EXTRA_SUCCESS,
    CREATE_LEASE_SUCCESS,
    CREATE_PM_LEASE_SUCCESS,
    CREATE_SALES_LEASE_SUCCESS,
    GET_INFO_LEASE_SUCCESS,
    LEASE_ADDITIONAL_SUCCESS,
    LEASE_ADVERTISING_SUCCESS,
    LEASE_AGENT_AUTHORITY_SUCCESS,
    LEASE_AGREEMENT_TERM_SUCCESS,
    LEASE_CONDITIONS_SUCCESS,
    LEASE_CONNECTIONS_SUCCESS,
    LEASE_CONTACTS_SUCCESS,
    LEASE_DISCLOSURE_REBATE_SUCCESS,
    LEASE_DOCUMENTS_SUCCESS,
    LEASE_EXPENSES_AND_CHARGES_SUCCESS,
    LEASE_FEES_SUCCESS,
    LEASE_INCLUSIONS_SUCCESS,
    LEASE_EXCLUSIONS_SUCCESS,
    LEASE_INSPECTION_DAYS_SUCCESS,
    LEASE_LANDLORD_SOLICITOR_SUCCESS,
    LEASE_LANDLORD_SUCCESS,
    LEASE_LICENSEE_SUCCESS,
    LEASE_MANAGING_AGENT_SUCCESS,
    LEASE_PROPERTY_DESCRIPTION_SUCCESS,
    LEASE_PROPERTY_DETAILS_SUCCESS,
    LEASE_MARKETING_AND_ADVERTISING_SUCCESS,
    LEASE_MATERIAL_FACTS_SUCCESS,
    LEASE_OCCUPANTS_SUCCESS,
    LEASE_OTHER_SERVICES_SUCCESS,
    LEASE_OUTGOINGS_SUCCESS,
    LEASE_INSURANCE_SUCCESS,
    LEASE_DISCLOSURE_SUCCESS,
    LEASE_INSTRUCTIONS_SUCCESS,
    LEASE_EXECUTED_AS_AGREEMENT_SUCCESS,
    LEASE_REPAIRS_AND_MAINTENANCE_SUCCESS,
    LEASE_NOMINATED_REPAIRERS_SUCCESS,
    LEASE_PETS_SUCCESS,
    LEASE_PAYMENT_SUCCESS,
    PM_LEASE_PAYMENT_SUCCESS,
    LEASE_PM_AGREEMENT_TERM_SUCCESS,
    LEASE_PM_DETAILS_REPAIRS_SUCCESS,
    LEASE_PM_DUTIES_SUCCESS,
    LEASE_PM_SERVICES_SCHEDULE_SUCCESS,
    LEASE_PM_EXPENSES_AND_CHARGES_SUCCESS,
    LEASE_PM_FEES_SUCCESS,
    LEASE_PM_LANDLORD_SUCCESS,
    LEASE_PM_OTHER_SERVICES_SUCCESS,
    LEASE_PM_OUTGOINGS_SUCCESS,
    LEASE_PM_RENT_PAYMENT_SUCCESS,
    LEASE_PM_RENT_TERM_SUCCESS,
    LEASE_POOL_SAFETY_AND_SMOKE_ALARMS_SUCCESS,
    LEASE_PRICE_AND_FEE_SUCCESS,
    LEASE_RENT_AND_TERM_SUCCESS,
    LEASE_RENT_SUCCESS,
    LEASE_SALES_EXPENSES_CHARGES_SUCCESS,
    LEASE_SALES_INCLUSIONS_SUCCESS,
    LEASE_SALES_LANDLORD_SUCCESS,
    LEASE_SALES_TERM_SUCCESS,
    LEASE_SIGNATORY_SUCCESS,
    LEASE_TENANT_PAYMENTS_SUCCESS,
    LEASE_TENANTS_AGENT_SUCCESS,
    LEASE_TERM_SUCCESS,
    LEASE_WARRANTY_AND_MAINTENANCE_SUCCESS,
    OPEN_CLONE_RES_TEN_FORM,
    UPDATE_TENANT_LINKS_SUCCESS,
    UPDATE_TENANT_SUCCESS,
    UPDATE_LANDLORD_SUCCESS,
    UPDATE_VENDOR_SUCCESS,
    LEASE_PM_AUTHORITY_PERIOD_SUCCESS,
    LEASE_PM_MARKETING_COSTS_SUCCESS,
    LEASE_PM_ADDITIONAL_COSTS_SUCCESS,
    LEASE_SECTION_UPDATE_SUCCESS,
    UPDATE_INTEGRATION_SUCCESS,
    LEASE_PULL_INTEGRATION_DATA
} from './lease';
import { UPDATE_LEASE_DATA } from '../actions/types';
import { cloneDeep, merge, findIndex } from 'lodash';
import produce from 'immer';
import { mapLeaseStatusToCategory } from '../utils/agreementUtils';
import { LEASE_STATUS_AWAITING_COMPLETION, LEASE_STATUS_COMPLETE } from '../actions/dashboard';

export const GET_AGREEMENTS_REQUEST = 'GET_AGREEMENTS_REQUEST';
export const GET_AGREEMENTS_SUCCESS = 'GET_AGREEMENTS_SUCCESS';
export const GET_AGREEMENTS_FAILURE = 'GET_AGREEMENTS_FAILURE';

export const CLONE_AGREEMENTS_REQUEST = 'CLONE_AGREEMENTS_REQUEST';
export const CLONE_AGREEMENTS_SUCCESS = 'CLONE_AGREEMENTS_SUCCESS';
export const CLONE_AGREEMENTS_FAILURE = 'CLONE_AGREEMENTS_FAILURE';

export const DELETE_AGREEMENTS_REQUEST = 'DELETE_AGREEMENTS_REQUEST';
export const DELETE_AGREEMENTS_SUCCESS = 'DELETE_AGREEMENTS_SUCCESS';
export const DELETE_AGREEMENTS_FAILURE = 'DELETE_AGREEMENTS_FAILURE';

export const OPEN_AGREEMENT_INFO_MODAL = 'OPEN_AGREEMENT_INFO_MODAL';
export const CLOSE_AGREEMENT_INFO_MODAL = 'CLOSE_AGREEMENT_INFO_MODAL';

export const OPEN_LEASE_RENEWAL_MODAL = 'OPEN_LEASE_RENEWAL_MODAL';
export const CLOSE_LEASE_RENEWAL_MODAL = 'CLOSE_LEASE_RENEWAL_MODAL';

export const OPEN_EDIT_TENANTS_MODAL = 'OPEN_EDIT_TENANTS_MODAL';
export const CLOSE_EDIT_TENANTS_MODAL = 'CLOSE_EDIT_TENANTS_MODAL';

export const OPEN_EDIT_LANDLORDS_MODAL = 'OPEN_EDIT_LANDLORDS_MODAL';
export const CLOSE_EDIT_LANDLORDS_MODAL = 'CLOSE_EDIT_LANDLORDS_MODAL';

export const OPEN_EDIT_VENDORS_MODAL = 'OPEN_EDIT_VENDORS_MODAL';
export const CLOSE_EDIT_VENDORS_MODAL = 'CLOSE_EDIT_VENDORS_MODAL';

export const OPEN_EXTEND_EXPIRY_MODAL = 'OPEN_EXTEND_EXPIRY_MODAL';
export const CLOSE_EXTEND_EXPIRY_MODAL = 'CLOSE_EXTEND_EXPIRY_MODAL';

export const TOGGLE_LEFT_MENU = 'TOGGLE_LEFT_MENU';
export const CLOSE_LEFT_MENU = 'CLOSE_LEFT_MENU';

export const TOGGLE_NOTIFICATIONS_PANEL = 'TOGGLE_NOTIFICATIONS_PANEL';
export const CLOSE_NOTIFICATIONS_PANEL = 'CLOSE_NOTIFICATIONS_PANEL';

// Get all agreements
export const GET_AGREEMENT_LIST_REQUEST = 'dashboard.agreementList.loadData.request';
export const GET_AGREEMENT_LIST_SUCCESS = 'dashboard.agreementList.loadData.success';
export const GET_AGREEMENT_LIST_FAILURE = 'dashboard.agreementList.loadData.failure';

// Get all documents
export const GET_DOCUMENT_LIST_REQUEST = 'dashboard.documentList.loadData.request';
export const GET_DOCUMENT_LIST_SUCCESS = 'dashboard.documentList.loadData.success';
export const GET_DOCUMENT_LIST_FAILURE = 'dashboard.documentList.loadData.failure';

// Get all keys
export const GET_FLK_A_KEY_LIST_REQUEST = 'dashboard.flkAKeyList.loadData.request';
export const GET_FLK_A_KEY_LIST_SUCCESS = 'dashboard.flkAKeyList.loadData.success';
export const GET_FLK_A_KEY_LIST_FAILURE = 'dashboard.flkAKeyList.loadData.failure';

export const ADD_OR_REPLACE_DOCUMENT_IN_LIST = 'ADD_OR_REPLACE_DOCUMENT_IN_LIST';
export const REMOVE_DOCUMENT_IN_LIST = 'REMOVE_DOCUMENT_IN_LIST';
export const UPDATE_DOCUMENT_PROGRESS = 'UPDATE_DOCUMENT_PROGRESS';

export const ADD_OR_REPLACE_KEY_IN_LIST = 'ADD_OR_REPLACE_KEY_IN_LIST';
export const REMOVE_KEY_IN_LIST = 'REMOVE_KEY_IN_LIST';
export const UPDATE_KEY_IN_LIST = 'UPDATE_KEY_IN_LIST';

export const ADD_OR_REPLACE_TEMPLATE_IN_LIST = 'ADD_OR_REPLACE_TEMPLATE_IN_LIST';

export const UPDATE_RELOAD_DOCUMENT_LIST = 'UPDATE_RELOAD_DOCUMENT_LIST';

export const GET_TEMPLATE_LIST_REQUEST = 'GET_TEMPLATE_LIST_REQUEST';
export const GET_TEMPLATE_LIST_SUCCESS = 'GET_TEMPLATE_LIST_SUCCESS';
export const GET_TEMPLATE_LIST_FAILURE = 'GET_TEMPLATE_LIST_FAILURE';

export const GET_ALLOWED_TEMPLATE_LIST_REQUEST = 'GET_ALLOWED_TEMPLATE_LIST_REQUEST';
export const GET_ALLOWED_TEMPLATE_LIST_SUCCESS = 'GET_ALLOWED_TEMPLATE_LIST_SUCCESS';
export const GET_ALLOWED_TEMPLATE_LIST_FAILURE = 'GET_ALLOWED_TEMPLATE_LIST_FAILURE';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';

export const CLOSE_SIGNING_REQUEST = 'CLOSE_SIGNING_REQUEST';
export const CLOSE_SIGNING_SUCCESS = 'CLOSE_SIGNING_SUCCESS';
export const CLOSE_SIGNING_FAILURE = 'CLOSE_SIGNING_FAILURE';

export const UPDATE_PAGE_NAV = 'UPDATE_PAGE_NAV';
export const UPDATE_DOC_PAGE_NAV = 'UPDATE_DOC_PAGE_NAV';
export const UPDATE_KEY_PAGE_NAV = 'UPDATE_KEY_PAGE_NAV';

export const CLOSE_EDIT_ADDRESS_MODAL = 'CLOSE_EDIT_ADDRESS_MODAL';
export const OPEN_EDIT_ADDRESS_MODAL = 'OPEN_EDIT_ADDRESS_MODAL';

export const OPEN_AUDIT_TRAIL_MODAL = 'OPEN_AUDIT_TRAIL_MODAL';
export const CLOSE_AUDIT_TRAIL_MODAL = 'CLOSE_AUDIT_TRAIL_MODAL';

export const OPEN_NOTES_MODAL = 'OPEN_NOTES_MODAL';
export const CLOSE_NOTES_MODAL = 'CLOSE_NOTES_MODAL';

export const OPEN_INTENTION_RENEWAL_MODAL = 'OPEN_INTENTION_RENEWAL_MODAL';
export const CLOSE_INTENTION_RENEWAL_MODAL = 'CLOS_INTENTION_RENEWAL_MODAL';

export const OPEN_CONNECT_INTEGRATION_MODAL = 'OPEN_CONNECT_INTEGRATION_MODAL';
export const CLOSE_CONNECT_INTEGRATION_MODAL = 'CLOSE_CONNECT_INTEGRATION_MODAL';

export const CHANGE_ADDRESS_REQUEST = 'CHANGE_ADDRESS_REQUEST';
export const CHANGE_ADDRESS_SUCCESS = 'CHANGE_ADDRESS_SUCCESS';
export const CHANGE_ADDRESS_FAIL = 'CHANGE_ADDRESS_FAIL';

export const CHANGE_AGENT_REQUEST = 'CHANGE_AGENT_REQUEST';
export const CHANGE_AGENT_SUCCESS = 'CHANGE_AGENT_SUCCESS';
export const CHANGE_AGENT_FAIL = 'CHANGE_AGENT_FAIL';

export const CHANGE_TEMPLATE_NAME_REQUEST = 'CHANGE_TEMPLATE_NAME_REQUEST';
export const CHANGE_TEMPLATE_NAME_SUCCESS = 'CHANGE_TEMPLATE_NAME_SUCCESS';
export const CHANGE_TEMPLATE_NAME_FAIL = 'CHANGE_TEMPLATE_NAME_FAIL';

export const CHANGE_DOCUMENT_TITLE_REQUEST = 'CHANGE_DOCUMENT_TITLE_REQUEST';
export const CHANGE_DOCUMENT_TITLE_SUCCESS = 'CHANGE_DOCUMENT_TITLE_SUCCESS';
export const CHANGE_DOCUMENT_TITLE_FAIL = 'CHANGE_DOCUMENT_TITLE_FAIL';

export const CHANGE_DOCUMENT_AGENT_REQUEST = 'CHANGE_DOCUMENT_AGENT_REQUEST';
export const CHANGE_DOCUMENT_AGENT_SUCCESS = 'CHANGE_DOCUMENT_AGENT_SUCCESS';
export const CHANGE_DOCUMENT_AGENT_FAIL = 'CHANGE_DOCUMENT_AGENT_FAIL';

export const START_COMPLETION_PROCESS_REQUEST = 'dashboard.lease.start.completion.process.request';
export const START_COMPLETION_PROCESS_SUCCESS = 'dashboard.lease.start.completion.process.success';
export const START_COMPLETION_PROCESS_FAIL = 'dashboard.lease.start.completion.process.fail';

export const FINISH_COMPLETION_PROCESS_REQUEST = 'dashboard.lease.finish.completion.process.request';
export const FINISH_COMPLETION_PROCESS_SUCCESS = 'dashboard.lease.finish.completion.process.success';
export const FINISH_COMPLETION_PROCESS_FAIL = 'dashboard.lease.finish.completion.process.fail';

export const GET_AGENCY_SIGNATURES_LIST_REQUEST = 'dashboard.lease.get.agency.signature.list.request';
export const GET_AGENCY_SIGNATURES_LIST_SUCCESS = 'dashboard.lease.get.agency.signature.list.success';
export const GET_AGENCY_SIGNATURES_LIST_FAIL = 'dashboard.lease.get.agency.signature.list.fail';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_REQUEST_SUCCESS = 'UPDATE_ADDRESS_REQUEST_SUCCESS';
export const UPDATE_ADDRESS_REQUEST_FAIL = 'UPDATE_ADDRESS_REQUEST_FAIL';

export const SOCKET_UPDATE_AGREEMENT = 'SOCKET_UPDATE_AGREEMENT';
export const SOCKET_UPDATE_PAGE = 'SOCKET_UPDATE_PAGE';

export const RESET_REFRESH_PAGE = 'RESET_REFRESH_PAGE';

export const UPDATE_AGREEMENT_TO_LOADING = 'UPDATE_AGREEMENT_TO_LOADING';
export const UPDATE_AGREEMENT_TO_REMOVE_LATER = 'UPDATE_AGREEMENT_TO_REMOVE_LATER';

export const TOGGLE_AGREEMENTS_MENU = 'TOGGLE_AGREEMENTS_MENU';
export const TOGGLE_DOCUMENTS_MENU = 'TOGGLE_DOCUMENTS_MENU';

export const HANDLE_COMPLETION_FORM_CHANGE = 'dashboard.lease.handle.completion.form.change';

export const TOGGLE_MOBILE_VIEW = 'TOGGLE_MOBILE_VIEW';

export const UPDATE_RENT_INCREASE_DOCUMENT = 'UPDATE_RENT_INCREASE_DOCUMENT';

export const UPDATE_SORT_OPTIONS = 'UPDATE_SORT_OPTIONS';

export const UPDATE_LEASE_IN_LIST = 'UPDATE_LEASE_IN_LIST';

export const UPDATE_LEASE_TERMINATIONS = 'UPDATE_LEASE_TERMINATIONS';

export const REFRESH_PAGE_ON_AGREEMENTS_UPDATE = 'REFRESH_PAGE_ON_AGREEMENTS_UPDATE';

export const CHANGE_IS_PENDING_STATUS = 'CHANGE_IS_PENDING_STATUS';

export const LEASE_MANUAL_LINK_GENERATE_SUCCESS = 'LEASE_MANUAL_LINK_GENERATE_SUCCESS';

const initState = {
    refreshPage: false,
    forceRefreshPage: false,
    socketUpdateDetails: {},
    agreements: {},
    agreementList: {
        archived: [],
        awaiting_completion: [],
        awaiting_renewal: [],
        draft: [],
        sent_for_signing: [],
        cancelled: []
    },
    templateList: {
        [TEMPLATE_TYPE_PROPERTY_MANAGEMENT]: [],
        [TEMPLATE_TYPE_SALES]: [],
        [CREATE_A_FLK_TEMPLATE]: [],
        [CREATE_A_FLK_GLOBAL_TEMPLATE]: [],
        [FLK_A_PDF_TEMPLATE]: []
    },
    allowedTemplateList: [],
    templatesPagination: {
        [TEMPLATE_TYPE_PROPERTY_MANAGEMENT]: {
            limit: 10,
            page: 1
        },
        [TEMPLATE_TYPE_SALES]: {
            limit: 10,
            page: 1
        },
        [CREATE_A_FLK_TEMPLATE]: {
            limit: 10,
            page: 1
        },
        [CREATE_A_FLK_GLOBAL_TEMPLATE]: {
            limit: 10,
            page: 1
        },
        [FLK_A_PDF_TEMPLATE]: {
            limit: 10,
            page: 1
        }
    },

    refreshPagination: false,
    templatesSorting: {
        [TEMPLATE_TYPE_PROPERTY_MANAGEMENT]: {
            field: 'created',
            direction: 'desc'
        },
        [TEMPLATE_TYPE_SALES]: {
            field: 'created',
            direction: 'desc'
        },
        [CREATE_A_FLK_TEMPLATE]: {
            field: 'created',
            direction: 'desc'
        },
        [CREATE_A_FLK_GLOBAL_TEMPLATE]: {
            field: 'created',
            direction: 'desc'
        },
        [FLK_A_PDF_TEMPLATE]: {
            field: 'created',
            direction: 'desc'
        }
    },
    documentList: {
        termination_notice: []
    },
    agreementInfo: {},
    tenancy: {},
    private: {},
    auction: {},
    property: {},
    sales: {},
    isPopupOpen: false,
    isOpenAgreementInfoModal: false,
    isLeftMenuOpen: false,
    isNotificationPanelOpen: false,
    isOpenEditTenantsModal: false,
    //information about page navigation
    pageNav: {},
    sortOptions: {},
    docPageNav: {},
    agencySignatureList: [],
    completion: {},
    completionFormErrors: {},
    isPending: false,
    initialAgreementListRequested: false,
    initialDocumentListRequested: false,
    leaseToClone: {},
    isEditAddressModalOpen: false,
    isAuditTrailModalOpen: false,
    isAuditTrailDocument: false,
    isNotesOpen: false,
    leaseFromDashboard: {},
    isIntentionRenewalModalOpen: false,
    isNotesDocument: false,
    addressToEdit: {},
    leaseIdToEdit: {},
    mobileViewEnabled: true,
    // used to determine if we have a fully hydrated agreement vs one that is loaded from the dashboard
    hydrated: false,
    isAgreementsMenuOpen: true,
    isDocumentsMenuOpen: true,
    // FLK a Key
    flkAKeyList: [],
    keyPageNav: {},
    initialKeyListRequested: false
};

function mapAgreementList(agreementList = {}) {
    let result = {};

    Dashboard.availableAgreementStatuses.forEach(status => {
        result[status] = agreementList.filter(agreement => {
            if (agreement === undefined) {
                return false;
            }
            // map to the right name
            if (status === Dashboard.LEASE_STATUS_ARCHIVED) {
                return agreement.status === Dashboard.LEASE_STATUS_COMPLETE || agreement.status === status;
            }
            return agreement.status === status;
        });
    });

    return result;
}

function reMapAgreementList(agreementList = {}) {
    let allAgreements = [];
    Dashboard.availableAgreementStatuses.forEach(status => {
        allAgreements = allAgreements.concat(agreementList[status]);
    });

    return mapAgreementList(allAgreements);
}

const findLease = (leaseId, agreementList) => {
    let allAgreements = [];
    Dashboard.availableAgreementStatuses.forEach(status => {
        allAgreements = allAgreements.concat(agreementList[status]);
    });
    let agreement = false;

    if (allAgreements) {
        agreement = allAgreements.find(document => {
            if (document) {
                return document.id === leaseId;
            }
            return false;
        });
    }

    return agreement;
};

const findTemplate = (leaseId, templateList, leaseType) => {
    let agreement = false;

    if (templateList[leaseType]) {
        agreement = templateList[leaseType].find(document => {
            if (document) {
                return document.id === leaseId;
            }
            return false;
        });
    }
    return agreement;
};

function convertDataForSelect(templateList) {
    const returnSelectList = [];
    templateList.forEach(item => {
        returnSelectList.push({ value: item.id, label: item.templateName });
    });
    return returnSelectList;
}

function reducePaginationForTemplates(draftState, newTemplateList, templateType) {
    let newPagination = draftState.templatesPagination[templateType];
    const limit = draftState.templatesPagination[templateType].limit;
    if (draftState.templatesPagination[templateType]) {
        let page = draftState.templatesPagination[templateType].page;
        const pages = draftState.templatesPagination[templateType].pages;
        const total = newTemplateList.length;
        if (page > 1) {
            const pages = total / limit;
            // Check we have total pages if not whole we have pages left on current page
            const whole = pages % 1 === 0;
            if (whole && pages < page) {
                page--;
            }
        }
        newPagination = { success: true, page, limit, total, pages };
    }
    return newPagination;
}

function reducePaginationForAgreements(draftState, status) {
    const newPagination = {
        ...draftState.pageNav
    };
    const recordsPerPage = newPagination[status].recordsPerPage;
    if (newPagination[status]) {
        let page = newPagination[status].page;
        const totalPages = newPagination[status].totalPages;
        const total = newPagination[status].totalItemsCount - 1;
        let prevPage = newPagination[status].prevPage;
        let nextPage = newPagination[status].nextPage;
        if (page > 1) {
            const pages = total / recordsPerPage;
            // Check we have total pages if not whole we have pages left on current page
            const whole = pages % 1 === 0;
            if (whole && pages < page) {
                page--;
                prevPage--;
                nextPage--;
            }
        } else {
            prevPage = null;
            nextPage = null;
        }
        newPagination[status] = {
            hasNextPage: false,
            hasPrevPage: true,
            nextPage: nextPage,
            page: page,
            prevPage: prevPage,
            recordsPerPage: recordsPerPage,
            totalItemsCount: total,
            totalPages: totalPages,
            success: true
        };
    }
    return newPagination;
}

const removeKeyInList = (state, flkAKey) => {
    let newKeyList = cloneDeep(state.flkAKeyList);
    const index = findIndex(newKeyList, {
        id: flkAKey.id
    });
    if (index > -1) {
        newKeyList = [...newKeyList.slice(0, index), ...newKeyList.slice(index + 1)];
    }
    return newKeyList;
};

export default function dashboard(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case TOGGLE_MOBILE_VIEW: {
            newState.mobileViewEnabled = action.payload.data;
            break;
        }
        case Lease.SEND_TERMINATE_LEASE_SUCCESS: {
            return produce(state, draftState => {
                const lease = action.result.data.lease;
                lease.hydrated = true;
                draftState.agreementInfo = lease;
            });
        }
        case Lease.CREATE_RENT_INCREASE_PDF_SUCCESS:
        case Lease.ADD_NOTE_SUCCESS:
        case Lease.CREATE_ESP_LETTER_PDF_SUCCESS: {
            return produce(state, draftState => {
                const lease = action.payload.lease;
                lease.hydrated = true;
                draftState.agreementInfo = lease;
            });
        }
        case CHANGE_ADDRESS_FAIL: {
            break;
        }
        case CHANGE_AGENT_FAIL: {
            break;
        }
        case GET_AGREEMENTS_REQUEST: {
            newState.agreements = {};
            break;
        }
        case GET_AGREEMENTS_SUCCESS: {
            newState.agreements = action.result.data.data;
            break;
        }
        case GET_AGREEMENTS_FAILURE: {
            newState.agreements = {};
            break;
        }
        case OPEN_AGREEMENT_INFO_MODAL: {
            newState.isOpenAgreementInfoModal = true;
            if (action.payload) {
                newState.agreementInfo = action.payload.agreementInfo;
            }
            break;
        }
        case OPEN_EDIT_TENANTS_MODAL: {
            newState.isOpenEditTenantsModal = true;
            if (action.payload) {
                newState.agreementInfo = action.payload.agreementInfo;
            }
            break;
        }
        case OPEN_EDIT_LANDLORDS_MODAL: {
            newState.isOpenEditLandlordsModal = true;
            if (action.payload) {
                newState.agreementInfo = action.payload.agreementInfo;
            }
            break;
        }
        case Lease.UPDATE_LANDLORD_SUCCESS: {
            newState.isOpenEditLandlordsModal = false;
            newState.isOpenAgreementInfoModal = true;
            break;
        }
        case CLOSE_EDIT_LANDLORDS_MODAL: {
            newState.isOpenEditLandlordsModal = false;
            break;
        }
        case OPEN_EDIT_VENDORS_MODAL: {
            newState.isOpenEditVendorsModal = true;
            if (action.payload) {
                newState.agreementInfo = action.payload.agreementInfo;
            }
            break;
        }
        case OPEN_EXTEND_EXPIRY_MODAL: {
            newState.isOpenExtendExpiryModal = true;
            if (action.payload) {
                newState.agreementInfo = action.payload.agreementInfo;
            }
            break;
        }
        case CLOSE_EXTEND_EXPIRY_MODAL: {
            newState.isOpenExtendExpiryModal = false;
            break;
        }
        case Lease.UPDATE_VENDOR_SUCCESS: {
            newState.isOpenEditVendorsModal = false;
            newState.isOpenAgreementInfoModal = true;
            break;
        }
        case CLOSE_EDIT_VENDORS_MODAL: {
            newState.isOpenEditVendorsModal = false;
            break;
        }
        case Lease.UPDATE_TENANT_SUCCESS: {
            newState.isOpenEditTenantsModal = false;
            newState.isOpenAgreementInfoModal = true;
            break;
        }
        case CLOSE_EDIT_TENANTS_MODAL: {
            newState.isOpenEditTenantsModal = false;
            break;
        }
        case TOGGLE_NOTIFICATIONS_PANEL: {
            newState.isNotificationPanelOpen = !newState.isNotificationPanelOpen;
            break;
        }
        case CLOSE_NOTIFICATIONS_PANEL: {
            newState.isNotificationPanelOpen = false;
            break;
        }
        case TOGGLE_LEFT_MENU: {
            newState.isLeftMenuOpen = !newState.isLeftMenuOpen;
            break;
        }
        case CLOSE_LEFT_MENU: {
            newState.isLeftMenuOpen = false;
            break;
        }
        case CLOSE_AGREEMENT_INFO_MODAL: {
            newState.isOpenAgreementInfoModal = false;
            newState.agreementInfo = {};
            break;
        }
        case CLOSE_SIGNING_REQUEST: {
            newState.isPopupOpen = true;
            break;
        }
        case CLOSE_SIGNING_SUCCESS: {
            return {
                ...state,
                agreementInfo: action.result.data.lease,
                isPopupOpen: false
            };
        }
        case CLONE_AGREEMENTS_REQUEST: {
            newState.isPopupOpen = true;
            break;
        }

        case CLONE_AGREEMENTS_FAILURE: {
            break;
        }
        case OPEN_CLONE_RES_TEN_FORM: {
            let leaseToClone = {};
            Dashboard.availableAgreementStatuses.forEach(status => {
                if (state.agreementList[status]) {
                    state.agreementList[status].forEach(function (agreement) {
                        if (agreement.id === action.leaseId) {
                            leaseToClone = agreement;
                        }
                    });
                }
            });
            return {
                ...state,
                isPopupOpen: false,
                isOpenAgreementInfoModal: false,
                leaseToClone: leaseToClone
            };
        }
        case DELETE_AGREEMENTS_REQUEST: {
            newState.isPopupOpen = true;
            break;
        }
        case DELETE_AGREEMENTS_SUCCESS: {
            return produce(state, draftState => {
                let newPagination = reducePaginationForAgreements(draftState, action.result.data.leaseStatus);
                draftState.isPopupOpen = false;
                draftState.pageNav = newPagination;
            });
        }
        case DELETE_AGREEMENTS_FAILURE: {
            break;
        }
        case Renewal.CANCEL_RENEWAL_REQUEST: {
            newState.isPopupOpen = true;
            break;
        }
        case Renewal.CANCEL_RENEWAL_SUCCESS: {
            newState.isPopupOpen = false;
            break;
        }
        case Renewal.CANCEL_RENEWAL_FAIL: {
            newState.isPopupOpen = false;
            break;
        }
        case Renewal.OPEN_RENEWAL_MODAL: {
            newState.agreementInfo = action.lease;
            break;
        }
        case Renewal.START_RENEWAL_SUCCESS: {
            const response = action.result.data;
            if (response.lease && response.lease.subLeaseType !== DEFAULT_SUB_LEASE_TYPE) {
                newState.agreementInfo = response.lease;
            }
            break;
        }
        case Lease.POST_SIGNING_SUCCESS: {
            return produce(state, draftState => {
                draftState.deadline = action.payload.data.deadline;
            });
        }
        case Lease.POST_SIGNING_REQUEST:
        case Lease.RESEND_POST_SIGNING_REQUEST: {
            return produce(state, draftState => {
                draftState.agreementInfo.sendForSigningProgress = [];
            });
        }
        // Process Agreement List loading.
        case GET_AGREEMENT_LIST_FAILURE: {
            return produce(state, draftState => {
                draftState.pageNav = {};
                draftState.agreementList[mapLeaseStatusToCategory(action.payload.status)] = [];
            });
        }
        case GET_AGREEMENT_LIST_REQUEST: {
            return produce(state, draftState => {
                draftState.agreementList = action.clearList ? {} : state.agreementList;
                draftState.initialAgreementListRequested = true;
                draftState.isPending = true;
            });
        }
        case GET_AGREEMENT_LIST_SUCCESS: {
            return produce(state, draftState => {
                const { paginateConfig, agreementList } = action.result.data;

                let newAgreementList = {};
                if (action.payload && action.payload.status) {
                    newAgreementList = cloneDeep(state.agreementList);
                    newAgreementList[action.payload.status] = agreementList;
                } else {
                    newAgreementList = mapAgreementList(agreementList);
                }
                draftState.pageNav = {
                    ...state.pageNav,
                    [action.payload.status]: paginateConfig
                };
                draftState.agreementList = newAgreementList;
                draftState.isPending = false;
                draftState.refreshPage = false;
            });
        }
        // Process Document List loading.
        case GET_DOCUMENT_LIST_FAILURE: {
            newState.docPageNav = {};
            newState.documentList = {};
            break;
        }
        case GET_DOCUMENT_LIST_REQUEST: {
            return {
                ...state,
                documentList: {},
                initialDocumentListRequested: true,
                isPending: true
            };
        }
        case GET_DOCUMENT_LIST_SUCCESS: {
            const { paginateConfig, documentList } = action.result.data;

            let newDocumentList = {};
            newDocumentList = cloneDeep(state.documentList);
            if (!newDocumentList[action.docType]) {
                newDocumentList[action.docType] = {};
            }
            newDocumentList[action.docType][action.docStatus] = documentList;

            return {
                ...state,
                docPageNav: {
                    ...state.docPageNav,
                    [action.docStatus]: paginateConfig
                },
                documentList: newDocumentList,
                isPending: false
            };
        }

        /**
         * Finds a document in the dashboard list and replaces it with the passed in document or add new document
         */
        case ADD_OR_REPLACE_DOCUMENT_IN_LIST: {
            const { document, currentStatus } = action.payload;
            return produce(state, draftState => {
                let newDocumentList;
                if (state.documentList[document.docType]) {
                    newDocumentList = cloneDeep(state.documentList);
                    if (!newDocumentList[document.docType][currentStatus]) {
                        newDocumentList[document.docType][currentStatus] = [];
                    }
                } else {
                    newDocumentList = cloneDeep(state.documentList);
                    newDocumentList[document.docType] = {
                        [currentStatus]: []
                    };
                }
                const index = findIndex(newDocumentList[document.docType][currentStatus], {
                    id: document.id
                });
                if (index < 0) {
                    // if document is not in the list add document to the list
                    newDocumentList[document.docType][currentStatus] = [
                        document,
                        ...newDocumentList[document.docType][currentStatus]
                    ];
                } else {
                    // if document is in the list replace it with new document
                    newDocumentList[document.docType][currentStatus].splice(index, 1, document);
                }
                draftState.documentList = newDocumentList;
            });
        }
        case ADD_OR_REPLACE_TEMPLATE_IN_LIST: {
            const { template } = action.payload;

            return produce(state, draftState => {
                let newTemplateList;
                if (state.templateList[document.docType]) {
                    newTemplateList = cloneDeep(state.templateList);
                } else {
                    newTemplateList = cloneDeep(state.templateList);
                    newTemplateList[document.docType] = [];
                }
                const index = findIndex(newTemplateList[template.docType], {
                    id: template.id
                });
                if (index < 0) {
                    // if template is not in the list add template to the list
                    newTemplateList[template.docType] = [template, ...newTemplateList[template.docType]];
                } else {
                    // if template is in the list replace it with new template
                    newTemplateList[template.docType].splice(index, 1, template);
                }
                draftState.templateList = newTemplateList;
            });
        }
        /**
         * Finds a document in the dashboard list and remove it
         */
        case REMOVE_DOCUMENT_IN_LIST: {
            const { document, status } = action.payload;

            const removeDocumentInList = (state, draftState, docType) => {
                let newDocumentList = cloneDeep(state.documentList);
                if (newDocumentList[docType] && newDocumentList[docType][status]) {
                    const index = findIndex(newDocumentList[docType][status], {
                        id: document.id
                    });
                    if (index > -1) {
                        newDocumentList[docType][status] = [
                            ...newDocumentList[docType][status].slice(0, index),
                            ...newDocumentList[docType][status].slice(index + 1)
                        ];
                    }
                }
                draftState.documentList = newDocumentList;
            };
            const docType = action.payload.docType || document.docType;
            return produce(state, draftState => {
                removeDocumentInList(state, draftState, docType);
            });
        }
        case UPDATE_DOCUMENT_PROGRESS: {
            const { completion, id, docType, status, docStatus, pdf, sendForSigningProgress } = action.payload;
            return produce(state, draftState => {
                let newDocumentList = cloneDeep(state.documentList);
                if (newDocumentList[docType] && newDocumentList[docType][docStatus]) {
                    const index = findIndex(newDocumentList[docType][docStatus], {
                        id: id
                    });
                    if (index > -1) {
                        if (completion) {
                            newDocumentList[docType][docStatus][index].completion = completion;
                        }
                        if (sendForSigningProgress) {
                            newDocumentList[docType][docStatus][index].sendForSigningProgress = sendForSigningProgress;
                        }
                        if (pdf) {
                            newDocumentList[docType][docStatus][index].pdf = pdf;
                        }
                        // if document status has changed, then remove that from list
                        if (status !== docStatus) {
                            newDocumentList[docType][docStatus] = [
                                ...newDocumentList[docType][docStatus].slice(0, index),
                                ...newDocumentList[docType][docStatus].slice(index + 1)
                            ];
                        }
                        draftState.documentList = newDocumentList;
                    } else if (status === docStatus) {
                        // This means updated document is not in the list. but it should update.
                        // therefor we should reload the list
                        draftState.reloadDocumentList = true;
                    }
                }
            });
        }
        case UPDATE_RELOAD_DOCUMENT_LIST: {
            return produce(state, draftState => {
                draftState.reloadDocumentList = action.payload.reloadDocumentList;
            });
        }
        case GET_TEMPLATE_LIST_SUCCESS: {
            let { templateList, ...pagination } = action.result.data;
            return produce(state, draftState => {
                draftState.templateList[action.result.config.params.templateType] = templateList;
                draftState.templatesPagination[action.result.config.params.templateType] = pagination;
                draftState.refreshPagination = false;
                draftState.templatesSorting[action.result.config.params.templateType] = {
                    field: action.result.config.params.field,
                    direction: action.result.config.params.direction
                };
            });
        }

        case GET_ALLOWED_TEMPLATE_LIST_SUCCESS: {
            let { templateList } = action.result.data;
            let allowedTemplateList = convertDataForSelect(templateList);
            return {
                ...state,
                allowedTemplateList
            };
        }

        case DELETE_TEMPLATE_SUCCESS: {
            return produce(state, draftState => {
                const newTemplateList = action.result.data.templateList.filter(
                    template => template.leaseType === action.result.config.params.templateType
                );

                let newPagination = reducePaginationForTemplates(
                    draftState,
                    newTemplateList,
                    action.result.config.params.templateType
                );

                draftState.templateList[action.result.config.params.templateType] = newTemplateList;
                draftState.templatesPagination[action.result.config.params.templateType] = newPagination;
                draftState.refreshPagination = true;
            });
        }

        case Lease.FINISH_EDITING_LEASE_AGREEMENT_SUCCESS: {
            newState.agreementInfo = action.result.data.lease;
            return replaceLeaseWithNewLease(action, state);
        }
        case START_COMPLETION_PROCESS_SUCCESS: {
            return produce(state, draftState => {
                const lease = action.result.data.lease;
                lease.hydrated = true;
                draftState.agreementInfo = action.result.data.lease;
            });
        }
        case GET_AGENCY_SIGNATURES_LIST_SUCCESS: {
            return produce(state, draftState => {
                draftState.agencySignatureList = action.result.data.agencySignatureList;
            });
        }
        case FINISH_COMPLETION_PROCESS_SUCCESS: {
            return produce(state, draftState => {
                const lease = action.result.data.lease;
                if (!lease || lease?.id !== state?.agreementInfo?.id) {
                    return;
                }
                const currentLease = cloneDeep(state.agreementInfo);
                lease.isLoading = lease.completion.status === 'processing';
                lease.hydrated = true;
                if (lease && lease.id === currentLease.id) {
                    // Prevent data that doesn't come from lease to be discarded.
                    draftState.agreementInfo = merge(currentLease, lease);
                }
            });
        }
        case HANDLE_COMPLETION_FORM_CHANGE: {
            return {
                ...state,
                completion: action.data
            };
        }
        case CHANGE_ADDRESS_SUCCESS: {
            return {
                ...state,
                agreementInfo: action.result.data.lease
            };
        }
        case CHANGE_AGENT_SUCCESS: {
            return produce(state, draftState => {
                draftState.agreementInfo = action.result.data.lease;
                draftState.agreementInfo.hydrated = true;
            });
        }
        case UPDATE_PAGE_NAV: {
            return {
                ...state,
                pageNav: action.data
            };
        }

        case UPDATE_DOC_PAGE_NAV: {
            return {
                ...state,
                docPageNav: action.data
            };
        }

        case UPDATE_KEY_PAGE_NAV: {
            return {
                ...state,
                keyPageNav: action.data
            };
        }

        case GET_INFO_LEASE_SUCCESS: {
            return produce(state, draftState => {
                const lease = cloneDeep(action.result.data.lease);
                lease.isLoading = lease.completion && lease.completion.status === 'processing';
                lease.hydrated = true;
                draftState.agreementInfo = lease;
            });
        }

        case UPDATE_INTEGRATION_SUCCESS: {
            return produce(state, draftState => {
                draftState.agreementInfo.integration = action.data;
                draftState.suggestions = null;
            });
        }

        case LEASE_PULL_INTEGRATION_DATA.REQUEST: {
            return produce(state, draftState => {
                draftState.agreementInfo.integration.loading = true;
            });
        }
        case LEASE_PULL_INTEGRATION_DATA.SUCCESS: {
            return produce(state, draftState => {
                draftState.agreementInfo.integration.loading = false;
                draftState.agreementInfo.integration.leaseDetails = action.result.data;
            });
        }
        case LEASE_PULL_INTEGRATION_DATA.FAIL: {
            return produce(state, draftState => {
                draftState.agreementInfo.integration.loading = false;
                draftState.agreementInfo.integration.leaseDetails = null;
            });
        }
        case OPEN_EDIT_ADDRESS_MODAL: {
            return {
                ...state,
                isEditAddressModalOpen: true,
                addressToEdit: action.addressToEdit,
                leaseIdToEdit: action.leaseId
            };
        }

        case CLOSE_EDIT_ADDRESS_MODAL: {
            return {
                ...state,
                isEditAddressModalOpen: false
            };
        }

        case OPEN_AUDIT_TRAIL_MODAL: {
            return {
                ...state,
                isAuditTrailModalOpen: true,
                isAuditTrailDocument: action.isDocument
            };
        }

        case CLOSE_AUDIT_TRAIL_MODAL: {
            return {
                ...state,
                isAuditTrailModalOpen: false,
                isAuditTrailDocument: false
            };
        }

        case OPEN_NOTES_MODAL: {
            return {
                ...state,
                isNotesOpen: true,
                isNotesDocument: action.isDocument
            };
        }

        case CLOSE_NOTES_MODAL: {
            return {
                ...state,
                isNotesOpen: false,
                isNotesDocument: false
            };
        }

        case OPEN_INTENTION_RENEWAL_MODAL: {
            return {
                ...state,
                leaseFromDashboard: action.lease,
                isIntentionRenewalModalOpen: true
            };
        }

        case CLOSE_INTENTION_RENEWAL_MODAL: {
            return {
                ...state,
                isIntentionRenewalModalOpen: false
            };
        }

        case OPEN_CONNECT_INTEGRATION_MODAL: {
            return {
                ...state,
                isConnectIntegrationModalOpen: true
            };
        }

        case CLOSE_CONNECT_INTEGRATION_MODAL: {
            return {
                ...state,
                isConnectIntegrationModalOpen: false
            };
        }

        case UPDATE_ADDRESS_REQUEST_SUCCESS: {
            return {
                ...state,
                agreementInfo: action.result.data.lease,
                isEditAddressModalOpen: false
            };
        }
        case Lease.MOVE_TO_ARCHIVE_SUCCESS: {
            return {
                ...state,
                agreementInfo: action.result.data.lease
            };
        }
        case Lease.MOVE_TO_AWAITING_RENEWAL_SUCCESS: {
            return {
                ...state,
                agreementInfo: action.result.data.lease
            };
        }
        case UPDATE_LEASE_DATA: {
            return produce(state, draftState => {
                const lease = action.payload.lease;
                lease.hydrated = true;
                draftState.agreementInfo = lease;
            });
        }
        case SOCKET_UPDATE_AGREEMENT: {
            const newData = action.data;

            return produce(state, draftState => {
                if (newData) {
                    // find agreement in agreement list and update
                    const lease = cloneDeep(findLease(newData.id, draftState.agreementList));
                    if (lease) {
                        let agreementList = cloneDeep(draftState.agreementList);

                        const leaseIndex = agreementList[mapLeaseStatusToCategory(lease.status)].findIndex(
                            document => document.id === newData.id
                        );

                        if (newData.agent) {
                            if (lease.agent.id === newData.agent) {
                                // deleting agent from newData as we dont want to replace it in the agreement (it would lose all its data)
                                delete newData.agent;
                            }
                        }

                        let newLease = merge(lease, newData);

                        // replace agreement in list
                        agreementList[mapLeaseStatusToCategory(lease.status)].splice(leaseIndex, 1, newLease);

                        // remap agreements as one could have moved status
                        agreementList = reMapAgreementList(agreementList);

                        draftState.agreementList = agreementList;
                    }

                    if (draftState.agreementInfo && draftState.agreementInfo.id && newData.id) {
                        if (draftState.agreementInfo.id === newData.id) {
                            // don't update agreement info if the agreement has already moved to complete ( this can happen because there is no guarantee when the socket arrives)
                            if (
                                !(
                                    draftState.agreementInfo.status === LEASE_STATUS_COMPLETE &&
                                    newData.status === LEASE_STATUS_AWAITING_COMPLETION
                                )
                            ) {
                                draftState.agreementInfo = merge(draftState.agreementInfo, newData);
                            }
                        }
                    }
                }
            });
        }

        case RESET_REFRESH_PAGE: {
            return produce(state, draftState => {
                draftState.refreshPage = false;
                draftState.forceRefreshPage = false;
            });
        }

        case SOCKET_UPDATE_PAGE: {
            const newData = action.data;

            return produce(state, draftState => {
                draftState.refreshPage = action.refreshPage;
                draftState.socketUpdateDetails = newData;

                // update the agreement if user is looking at it
                if (draftState.agreementInfo && draftState.agreementInfo.id && newData && newData.id) {
                    if (draftState.agreementInfo.id === newData.id) {
                        draftState.agreementInfo = merge(draftState.agreementInfo, newData);
                    }
                }
            });
        }

        case UPDATE_AGREEMENT_TO_LOADING: {
            return produce(state, draftState => {
                const leaseId = action.leaseId;
                const lease = cloneDeep(findLease(leaseId, draftState.agreementList));
                // if lease is visible on agreementlist
                if (lease) {
                    lease.isLoading = action.isLoading;
                    const leaseIndex = draftState.agreementList[mapLeaseStatusToCategory(lease.status)].findIndex(
                        document => document.id === leaseId
                    );

                    draftState.agreementList[mapLeaseStatusToCategory(lease.status)].splice(leaseIndex, 1, lease);
                }
                draftState.agreementInfo.isLoading = action.isLoading;
            });
        }

        case UPDATE_AGREEMENT_TO_REMOVE_LATER: {
            return produce(state, draftState => {
                const leaseId = action.leaseId;
                const lease = findLease(leaseId, draftState.agreementList);
                if (lease && lease.isLoading) {
                    const leaseIndex = draftState.agreementList[mapLeaseStatusToCategory(lease.status)].findIndex(
                        document => document.id === leaseId
                    );

                    draftState.agreementList[mapLeaseStatusToCategory(lease.status)].splice(leaseIndex, 1);
                    // if deleted agreement is the last one of the current page, then go back to previous page
                    if (draftState.agreementList.length === 0) {
                        draftState.pageNav.page = draftState.pageNav.page - 1;
                        draftState.refreshPage = true;
                    }
                }
            });
        }

        case TOGGLE_AGREEMENTS_MENU: {
            return produce(state, draftState => {
                draftState.isAgreementsMenuOpen = action.data;
            });
        }

        case TOGGLE_DOCUMENTS_MENU: {
            return produce(state, draftState => {
                draftState.isDocumentsMenuOpen = action.data;
            });
        }

        case CLONE_AGREEMENT_EXTRA_SUCCESS:
        case CREATE_SALES_LEASE_SUCCESS:
        case CREATE_PM_LEASE_SUCCESS:
        case CREATE_LEASE_SUCCESS: {
            if (action.result.data.lease) {
                return produce(state, draftState => {
                    draftState.agreementList[mapLeaseStatusToCategory(action.result.data.lease.status)].unshift(
                        action.result.data.lease
                    );
                });
            }
            break;
        }

        case CLONE_AGREEMENTS_SUCCESS: {
            if (action.result.data.lease) {
                return produce(state, draftState => {
                    draftState.agreementList[mapLeaseStatusToCategory(action.result.data.lease.status)].unshift(
                        action.result.data.lease
                    );
                    draftState.isPopupOpen = false;
                });
            }
            return produce(state, draftState => {
                draftState.isPopupOpen = false;
            });
        }
        case Lease.REQUEST_RESOLVE_SUCCESS:
        case Lease.REQUEST_UNRESOLVE_SUCCESS: {
            return produce(state, draftState => {
                draftState.agreementInfo = action.result.data.lease;
            });
        }
        case LEASE_TENANTS_AGENT_SUCCESS:
        case LEASE_SIGNATORY_SUCCESS:
        case LEASE_TERM_SUCCESS:
        case LEASE_RENT_SUCCESS:
        case LEASE_PAYMENT_SUCCESS:
        case PM_LEASE_PAYMENT_SUCCESS:
        case LEASE_INCLUSIONS_SUCCESS:
        case LEASE_EXCLUSIONS_SUCCESS:
        case LEASE_OCCUPANTS_SUCCESS:
        case LEASE_LANDLORD_SUCCESS:
        case UPDATE_TENANT_SUCCESS:
        case UPDATE_LANDLORD_SUCCESS:
        case UPDATE_VENDOR_SUCCESS:
        case LEASE_CONTACTS_SUCCESS:
        case LEASE_ADDITIONAL_SUCCESS:
        case LEASE_CONDITIONS_SUCCESS:
        case LEASE_SALES_EXPENSES_CHARGES_SUCCESS:
        case LEASE_DOCUMENTS_SUCCESS:
        case UPDATE_TENANT_LINKS_SUCCESS:
        case LEASE_CONNECTIONS_SUCCESS:
        case LEASE_SALES_LANDLORD_SUCCESS:
        case LEASE_LANDLORD_SOLICITOR_SUCCESS:
        case LEASE_SALES_INCLUSIONS_SUCCESS:
        case LEASE_LICENSEE_SUCCESS:
        case LEASE_MANAGING_AGENT_SUCCESS:
        case LEASE_PROPERTY_DESCRIPTION_SUCCESS:
        case LEASE_PROPERTY_DETAILS_SUCCESS:
        case LEASE_AGREEMENT_TERM_SUCCESS:
        case LEASE_PRICE_AND_FEE_SUCCESS:
        case LEASE_EXPENSES_AND_CHARGES_SUCCESS:
        case LEASE_PM_EXPENSES_AND_CHARGES_SUCCESS:
        case LEASE_SALES_TERM_SUCCESS:
        case LEASE_MARKETING_AND_ADVERTISING_SUCCESS:
        case LEASE_INSPECTION_DAYS_SUCCESS:
        case LEASE_AGENT_AUTHORITY_SUCCESS:
        case ADDITIONAL_SALES_TERMS_SUCCESS:
        case LEASE_OTHER_SERVICES_SUCCESS:
        case LEASE_PM_OTHER_SERVICES_SUCCESS:
        case LEASE_PM_DETAILS_REPAIRS_SUCCESS:
        case LEASE_ADVERTISING_SUCCESS:
        case LEASE_FEES_SUCCESS:
        case LEASE_MATERIAL_FACTS_SUCCESS:
        case LEASE_PM_FEES_SUCCESS:
        case LEASE_PM_LANDLORD_SUCCESS:
        case LEASE_PM_RENT_TERM_SUCCESS:
        case LEASE_PM_RENT_PAYMENT_SUCCESS:
        case LEASE_RENT_AND_TERM_SUCCESS:
        case LEASE_OUTGOINGS_SUCCESS:
        case LEASE_INSURANCE_SUCCESS:
        case LEASE_DISCLOSURE_SUCCESS:
        case LEASE_INSTRUCTIONS_SUCCESS:
        case LEASE_EXECUTED_AS_AGREEMENT_SUCCESS:
        case LEASE_REPAIRS_AND_MAINTENANCE_SUCCESS:
        case LEASE_NOMINATED_REPAIRERS_SUCCESS:
        case LEASE_PETS_SUCCESS:
        case LEASE_PM_OUTGOINGS_SUCCESS:
        case LEASE_PM_AUTHORITY_PERIOD_SUCCESS:
        case LEASE_PM_MARKETING_COSTS_SUCCESS:
        case LEASE_PM_ADDITIONAL_COSTS_SUCCESS:
        case LEASE_WARRANTY_AND_MAINTENANCE_SUCCESS:
        case LEASE_TENANT_PAYMENTS_SUCCESS:
        case LEASE_PM_DUTIES_SUCCESS:
        case LEASE_PM_SERVICES_SCHEDULE_SUCCESS:
        case LEASE_POOL_SAFETY_AND_SMOKE_ALARMS_SUCCESS:
        case LEASE_DISCLOSURE_REBATE_SUCCESS:
        case LEASE_PM_AGREEMENT_TERM_SUCCESS:
        case LEASE_SECTION_UPDATE_SUCCESS:
        case UPDATE_LEASE_IN_LIST: {
            return replaceLeaseWithNewLease(action, state);
        }
        case Lease.LEASE_TENANT_SUCCESS: {
            newState.isOpenEditTenantsModal = false;
            return replaceLeaseWithNewLease(action, state);
        }
        case UPDATE_RENT_INCREASE_DOCUMENT: {
            return produce(state, draftState => {
                if (draftState.documentList.rent_increase && draftState.documentList.rent_increase[action.doc.status]) {
                    draftState.documentList.rent_increase[action.doc.status].forEach((doc, index) => {
                        if (doc.id === action.doc.id) {
                            draftState.documentList.rent_increase[action.doc.status][index] = action.doc;
                        }
                    });
                }
            });
        }
        case UPDATE_SORT_OPTIONS: {
            return produce(state, draftState => {
                draftState.sortOptions[action.status] = action.data;
            });
        }
        case UPDATE_LEASE_TERMINATIONS: {
            return produce(state, draftState => {
                draftState.agreementInfo.leaseTerminations = action.payload.leaseTerminations;
            });
        }
        case GET_FLK_A_KEY_LIST_REQUEST: {
            return {
                ...state,
                flkAKeyList: [],
                isPending: true,
                initialKeyListRequested: true
            };
        }
        case GET_FLK_A_KEY_LIST_SUCCESS: {
            const { paginateConfig, flkAKeyList } = action.result.data;

            return {
                ...state,
                keyPageNav: {
                    ...state.keyPageNav,
                    [action.keyStatus]: paginateConfig
                },
                flkAKeyList,
                isPending: false
            };
        }
        case GET_FLK_A_KEY_LIST_FAILURE: {
            newState.keyPageNav = {};
            newState.flkAKeyList = [];
            newState.isPending = false;
            break;
        }
        case ADD_OR_REPLACE_KEY_IN_LIST: {
            const { flkAKey } = action.payload;
            return produce(state, draftState => {
                let newKeyList = cloneDeep(state.flkAKeyList);
                const index = findIndex(newKeyList, {
                    id: flkAKey.id
                });
                if (index < 0) {
                    // if flkAKey is not in the list add flkAKey to the list
                    newKeyList = [flkAKey, ...newKeyList];
                } else {
                    // if flkAKey is in the list replace it with new flkAKey
                    newKeyList.splice(index, 1, flkAKey);
                }
                draftState.flkAKeyList = newKeyList;
            });
        }
        case REMOVE_KEY_IN_LIST: {
            const { flkAKey } = action.payload;
            return produce(state, draftState => {
                draftState.flkAKeyList = removeKeyInList(state, flkAKey);
            });
        }
        case UPDATE_KEY_IN_LIST: {
            const { flkAKey, status } = action.payload;
            return produce(state, draftState => {
                let newKeyList = cloneDeep(state.flkAKeyList);
                const index = findIndex(newKeyList, {
                    id: flkAKey.id
                });
                if (index > -1) {
                    if (
                        status === flkAKey.status ||
                        (status === Dashboard.FLK_A_KEY_STATUS_CHECKED_OUT &&
                            flkAKey.status === Dashboard.FLK_A_KEY_STATUS_OVERDUE)
                    ) {
                        // if flkAKey is in the list and new status is same replace reminders and images;
                        // Also current tab is checked out and updated key is overdue, then replace data
                        newKeyList[index].reminders = flkAKey.reminders;
                        newKeyList[index].images = flkAKey.images;
                        newKeyList[index].compressedImageUrls = flkAKey.compressedImageUrls;
                        newKeyList[index].imageUrls = flkAKey.compressedImageUrls;
                        newKeyList[index].status = flkAKey.status;
                    } else {
                        // if updated key status is changed, then remove it
                        newKeyList = removeKeyInList(state, flkAKey);
                    }
                }
                draftState.flkAKeyList = newKeyList;
            });
        }
        case REFRESH_PAGE_ON_AGREEMENTS_UPDATE: {
            return {
                ...state,
                forceRefreshPage: true
            };
        }
        case CHANGE_IS_PENDING_STATUS: {
            return {
                ...state,
                isPending: action.payload.triggerState
            };
        }
        case LEASE_MANUAL_LINK_GENERATE_SUCCESS: {
            return produce(state, draftState => {
                draftState.agreementInfo.isManuallyShareLinks = true;
            });
        }
    }

    return newState;
}

/**
 * clone passed in lease and update the tenantLinks
 * @param action
 * @param lease
 * @returns {{tenantLinks: {}}}
 */
const updateLinks = (action, lease) => {
    let tenantLinks = action.payload.links;
    return { ...lease, tenantLinks: tenantLinks };
};

/**
 * Get a new lease with the payload injected
 * @param lease
 * @param action
 * @returns Object
 */
const injectPayloadIntoLease = (action, lease) => {
    let newLease;
    switch (action.type) {
        case UPDATE_TENANT_LINKS_SUCCESS:
            newLease = updateLinks(action, lease);
            break;
        default:
            logger.push({
                status: 'error',
                env: ENVIRONMENT,
                message: 'injectPayloadIntoLease ERROR ' + action.type
            });
    }
    return newLease;
};

/**
 * Depending on the returned action we are going to update the lease with either the payload or the
 * returned lease from the server
 * @param action
 * @param state
 * @returns Object
 */
const replaceLeaseWithNewLease = (action, state) => {
    // if a lease was returned in the action, find and replace
    if (action.result && action.result.data.lease) {
        const lease = action.result.data.lease;
        return produce(state, draftState => {
            replaceAgreementInState(lease, draftState);
        });
        // if a payload was returned from the action, it might not include all the data we need, in that case we update the specific part that needs updating
    } else if (action.payload) {
        return produce(state, draftState => {
            const actionLease = action.payload.lease;

            let lease;
            if (actionLease.leaseType.includes('template')) {
                lease = findTemplate(actionLease.id, draftState.templateList, actionLease.leaseType);
            } else {
                lease = findLease(actionLease.id, draftState.agreementList);
            }

            const newLease = injectPayloadIntoLease(action, lease);

            replaceAgreementInState(newLease, draftState);
        });
    }
    return null;
};

/**
 * Find the lease in the template list or agreement list and update
 * @param lease
 * @param draftState
 */
const replaceAgreementInState = (lease, draftState) => {
    const leaseId = lease.id;
    let leaseIndex;
    if (lease.leaseType.includes('template')) {
        if (draftState.templateList[lease.leaseType]) {
            leaseIndex = draftState.templateList[lease.leaseType].findIndex(document => document.id === leaseId);
            draftState.templateList[lease.leaseType].splice(leaseIndex, 1, lease);
        }
    } else {
        if (draftState.agreementList[mapLeaseStatusToCategory(lease.status)]) {
            leaseIndex = draftState.agreementList[mapLeaseStatusToCategory(lease.status)].findIndex(
                document => document.id === leaseId
            );
            draftState.agreementList[mapLeaseStatusToCategory(lease.status)].splice(leaseIndex, 1, lease);
        }
    }
};
