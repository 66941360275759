import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { map, isEmpty, isEqual, debounce } from 'lodash';
import { withFeatureFlags } from '@harnessio/ff-react-client-sdk';
import Panel from '../../common/components/Panel';
import { CheckboxCheck } from '../../components/form/FormCheckboxCheck';
import PropertyMeIntegration from './account/PropertyMeIntegration';
import PropertyTreeIntegration from './account/PropertyTreeIntegration';
import { InspectionManagerIntegration } from '../../components/settings/account/InspectionManagerIntegration';
import SherlockIntegration from './account/SherlockIntegration';
import ManagedIntegration from './account/ManagedIntegration';
import FlkApiIntegration from '../../components/settings/account/FlkApiIntegration';
import ListNowIntegration from '../../components/settings/account/ListNowIntegration';

import * as account from '../../actions/account';
import * as connections from '../../actions/connections';
import { userRoles, services as servicesList, HIDE_MESSAGE_TIME, DEBOUNCE_TIME } from '../../config';
import { FormRadioGroup } from '../../components/form/FormRadioGroup';
import InspectRealEstateIntegration from '../../components/settings/account/InspectRealEstateIntegration';
import { FormTextRegular } from '../../components/form/FormText';
import ConnectionToolTip from './ConnectionToolTip';
import { isAgencyUserAccount } from '../../utils/userUtils';

const setAllServicesToSameValues = enabled => {
    let services = {};
    map(servicesList, value => {
        services[value.inputName] = enabled;
    });

    return services;
};

class Integrations extends Component {
    constructor(props) {
        super(props);
        this.onChange = debounce(this.onChange.bind(this), DEBOUNCE_TIME);
        let values = {
            connectionsEnabled: props.connections.connectionsEnabled,
            connectionCompany: props.connections.connectionCompany,
            partnerCode: props.connections.partnerCode,
            services: {
                ...props.connections.services
            }
        };

        // If services are not set, get all the default services and set the value to false
        if (isEmpty(values.services)) {
            values.services = setAllServicesToSameValues(false);
        }

        if (isEmpty(values.connectionCompany) && !isEmpty(props.connectionCompanies)) {
            values.connectionCompany = props.connectionCompanies[0]._id;
        }

        this.state = {
            values,
            saveStatusTimeout: null
        };
    }

    componentDidMount() {
        this.props.getConnections();
        this.props.getConnectionCompanies();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        // Update the form values if the connections have changed
        if (!isEqual(nextProps.connections, this.props.connections)) {
            let values = {
                connectionsEnabled: nextProps.connections.connectionsEnabled,
                connectionCompany: nextProps.connections.connectionCompany,
                partnerCode: nextProps.connections.partnerCode,
                services: {
                    ...nextProps.connections.services
                }
            };

            // If services are not set, get all the default services and set the value to false
            if (isEmpty(values.services)) {
                values.services = setAllServicesToSameValues(false);
            }

            if (isEmpty(values.connectionCompany) && !isEmpty(nextProps.connectionCompanies)) {
                values.connectionCompany = nextProps.connectionCompany;
            }

            this.setState({
                values
            });
        }

        if (!nextProps.isUpdating && this.props.isUpdating) {
            // create a timeout handler that we'll use to determine whether to show the save result
            this.setState({
                saveStatusTimeout: setTimeout(() => {
                    this.setState({ saveStatusTimeout: null });
                }, HIDE_MESSAGE_TIME)
            });
        }
    }

    componentWillUnmount() {
        // clear pending save status timeout
        if (this.state.saveStatusTimeout) {
            clearTimeout(this.state.saveStatusTimeout);
        }
    }

    submit = values => {
        // if a timeout handler already exists, clear it
        if (this.state.saveStatusTimeout) {
            clearTimeout(this.state.saveStatusTimeout);
        }

        let data = {};
        if (!values.connectionsEnabled) {
            data.connectionsEnabled = false;
            data.connectionCompany = '';
            data.services = {};
        } else {
            data = {
                connectionsEnabled: values.connectionsEnabled,
                connectionCompany: values.connectionCompany,
                partnerCode: values.partnerCode,
                services: {
                    ...values.services
                }
            };
        }

        this.props.updateConnections(data);
    };

    onChange = values => {
        if (values !== this.state.values) {
            this.props.clearValidationErrors();
        }
        this.setState({
            values: values
        });
    };

    render() {
        let { isUpdating, updateSuccessful, connectionCompanies, loggedInUser, flags } = this.props;
        const userRole = loggedInUser.role;
        const agencyId = loggedInUser?.agency?.id;

        return (
            <div className="settings">
                <Form
                    initialValues={this.state.values}
                    onSubmit={values => this.submit(values)}
                    validate={() => {
                        let errors = {};
                        if (this.props.validationErrors) {
                            errors = { ...this.props.validationErrors.connections };
                        }
                        if (isEmpty(errors)) {
                            return;
                        }
                        return errors;
                    }}
                >
                    {({ values, handleSubmit }) => {
                        const selectedConnectionCompany = connectionCompanies.find(
                            company => company._id === values.connectionCompany
                        );

                        return (
                            <div className="settings-container">
                                {userRole !== userRoles.ROLE_AGENCY_TEAMMATE && (
                                    <div className="column">
                                        {isAgencyUserAccount(loggedInUser?.accountType) && (
                                            <>
                                                <form onSubmit={handleSubmit} className="connections-settings">
                                                    <FormSpy onChange={state => this.onChange(state.values)} />
                                                    <Panel
                                                        title="Connections settings"
                                                        rightButton={
                                                            <div>
                                                                {!isUpdating && !this.state.saveStatusTimeout && (
                                                                    <button type="submit" className="savestatus-button">
                                                                        Save changes
                                                                    </button>
                                                                )}
                                                                {isUpdating && (
                                                                    <span>
                                                                        <button
                                                                            type="submit"
                                                                            disabled="disabled"
                                                                            className="savestatus-button"
                                                                        >
                                                                            <span className="savestatus-spinner" />{' '}
                                                                            Saving changes
                                                                        </button>
                                                                    </span>
                                                                )}
                                                                {updateSuccessful && this.state.saveStatusTimeout && (
                                                                    <span className="savestatus-saved">
                                                                        Saved
                                                                        <span className="icon savestatus-icon" />
                                                                    </span>
                                                                )}
                                                                {!updateSuccessful && this.state.saveStatusTimeout && (
                                                                    <span className="savestatus-failed">
                                                                        Save failed{' '}
                                                                        <span className="icon savestatus-icon" />
                                                                    </span>
                                                                )}
                                                            </div>
                                                        }
                                                    >
                                                        <div className="form-content">
                                                            <CheckboxCheck
                                                                name="connectionsEnabled"
                                                                label="Enable Connections"
                                                            />
                                                            {values.connectionsEnabled && (
                                                                <div>
                                                                    <div className="radioGroup">
                                                                        <FormRadioGroup
                                                                            label="Choose Connections Company"
                                                                            name={`connectionCompany`}
                                                                            className={'radioGroupConnections'}
                                                                            data={connectionCompanies.map(company => {
                                                                                return {
                                                                                    value: company._id,
                                                                                    label: company.name
                                                                                };
                                                                            })}
                                                                            value={values.connectionCompany}
                                                                        />
                                                                    </div>
                                                                    {selectedConnectionCompany?.needsPartnerCode && (
                                                                        <>
                                                                            <FormTextRegular
                                                                                name={'partnerCode'}
                                                                                label={
                                                                                    selectedConnectionCompany.partnerCodeLabel
                                                                                }
                                                                                required
                                                                            />
                                                                            <ConnectionToolTip
                                                                                title={'More info'}
                                                                                toolTip={
                                                                                    selectedConnectionCompany.partnerCodeHelpText
                                                                                }
                                                                            />
                                                                            <br />
                                                                            <br />
                                                                        </>
                                                                    )}
                                                                    <p className="gray-label">Choose Services</p>
                                                                    {map(servicesList, (value, key) => {
                                                                        return (
                                                                            <CheckboxCheck
                                                                                key={value.inputName}
                                                                                name={`services.${value.inputName}`}
                                                                                label={value.title}
                                                                            />
                                                                        );
                                                                    })}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Panel>
                                                </form>
                                                <PropertyMeIntegration />
                                                <ManagedIntegration />
                                            </>
                                        )}
                                    </div>
                                )}
                                <div className="column">
                                    {isAgencyUserAccount(loggedInUser?.accountType) && (
                                        <>
                                            {userRole !== userRoles.ROLE_AGENCY_TEAMMATE && <PropertyTreeIntegration />}
                                            {userRole !== userRoles.ROLE_AGENCY_TEAMMATE &&
                                                flags?.Is_Inspection_Manager_Integration_Enabled && (
                                                    <InspectionManagerIntegration />
                                                )}
                                            <SherlockIntegration />
                                            {userRole !== userRoles.ROLE_AGENCY_TEAMMATE && (
                                                <InspectRealEstateIntegration agencyId={agencyId} />
                                            )}
                                        </>
                                    )}
                                    <FlkApiIntegration />
                                    <ListNowIntegration />
                                </div>
                            </div>
                        );
                    }}
                </Form>
            </div>
        );
    }
}

export default connect(
    state => ({
        agencyId: state.user.agencyId,
        connections: state.connections.connections,
        connectionCompanies: state.connections.connectionCompanies,
        validationErrors: state.connections.validationErrors,
        isUpdating: state.connections.isUpdating,
        updateSuccessful: state.connections.updateSuccessful,
        loggedInUser: state.user.userInfo
    }),
    {
        updateConnections: connections.updateConnections,
        getConnections: connections.getConnections,
        getConnectionCompanies: connections.getConnectionCompanies,
        updateAgency: account.updateAgency,
        clearValidationErrors: connections.clearValidationErrors
    }
)(withFeatureFlags(Integrations));
