import { createSelector } from 'reselect';
import { getDashboard } from '.';
const getLeaseType = (_, leaseType) => leaseType;

export const getTemplatesForLeaseType = createSelector(getDashboard, getLeaseType, (dashboard, leaseType) => {
    return dashboard.templateList[leaseType] ? dashboard.templateList[leaseType] : [];
});
const getTemplateType = (_, templateType) => templateType;

export const getTemplatesPaginationByType = createSelector(getDashboard, getTemplateType, (dashboard, templateType) => {
    return dashboard.templatesPagination[templateType] ? dashboard.templatesPagination[templateType] : [];
});

export const getTemplatesSorting = createSelector(getDashboard, getTemplateType, (dashboard, templateType) => {
    return dashboard.templatesSorting[templateType] ? dashboard.templatesSorting[templateType] : {};
});
export const getTemplatesRefreshPagination = createSelector(
    [getDashboard],
    ({ refreshPagination }) => refreshPagination
);
