import * as React from 'react';
import '../../../sass/settings.scss';
import '../../../sass/reports.scss';
import FollowUp from './FollowUp';
import Activity from './Activity';

export const ReportsView = ({ receiverEmails, initialActivityReportSettings }) => {
    return (
        <div className="settings settings-container reports-container">
            <div className="column">
                <FollowUp receiverEmails={receiverEmails} />
            </div>
            <div className="column">
                <Activity initialActivityReportSettings={initialActivityReportSettings} />
            </div>
        </div>
    );
};
