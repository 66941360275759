import React from 'react';
import { useSortBy, useTable } from 'react-table';
import { ArrowDropDownSharp, ArrowDropUpSharp } from '@material-ui/icons';
import { formatDateTime, getAgencyTimezoneFromAgency } from '@app/utils/dateUtils';

const AgentReportTable = ({ reportType, documents }) => {
    const data = documents;

    function formatDateTimeBasedOnTimezone({ row, value }) {
        return formatDateTime(value, getAgencyTimezoneFromAgency(row.original.agency));
    }

    const columns = React.useMemo(() => {
        if (reportType === 'uploadADoc') {
            const leaseColumns = [
                {
                    Header: 'document title',
                    accessor: 'documentTitle'
                    /* Leaving this here in case in the future we will allow the user to see the document (It is likely that will come)
                    * At this stage there are some issues with it, ie when you close it, it goes to the document dashboard
                    Cell: props => {
                        return (
                            <a
                                className="special-link"
                                onClick={() =>
                                    dispatch(
                                        openDocumentForm(
                                            props.cell.row.original.docType,
                                            props.cell.row.original,
                                            DOCUMENT_READ_ONLY_MODE
                                        )
                                    )
                                }
                            >
                                {props.value}
                            </a>
                        );
                    }*/
                },
                {
                    Header: 'Status',
                    id: 'status',
                    accessor: 'status'
                },
                {
                    Header: 'template',
                    id: 'template',
                    accessor: 'templateName'
                },
                {
                    Header: 'First sent for signing',
                    id: 'firstSentForSigning',
                    accessor: 'firstSentForSigning',
                    Cell: formatDateTimeBasedOnTimezone
                },
                {
                    Header: 'Client signing date',
                    id: 'acknowledgedDate',
                    accessor: 'acknowledgedDate',
                    Cell: formatDateTimeBasedOnTimezone
                },
                {
                    Header: 'Completion date',
                    id: 'completionDate',
                    accessor: 'completion.signedDate',
                    Cell: formatDateTimeBasedOnTimezone
                },
                {
                    Header: 'DeliveryType',
                    id: 'deliverytype',
                    accessor: 'deliveryType'
                },
                {
                    Header: 'User',
                    id: 'agent',
                    accessor: 'agent.fullName'
                },
                {
                    Header: 'Company',
                    id: 'agency',
                    accessor: 'agency.details.agencyName'
                }
            ];

            return leaseColumns;
        }

        if (reportType === 'buildADoc') {
            const leaseColumns = [
                {
                    Header: 'documentTitle',
                    accessor: 'documentTitle'
                    /* Leaving this here in case in the future we will allow the user to see the document (It is likely that will come)
                    * At this stage there are some issues with it, ie when you close it, it goes to the document dashboard
                    Cell: props => {
                        return (
                            <a
                                className="special-link"
                                onClick={() =>
                                    dispatch(openDocumentForm(props.cell.row.original.docType, props.cell.row.original))
                                }
                            >
                                {props.value}
                            </a>
                        );
                    }*/
                },
                {
                    Header: 'Status',
                    id: 'status',
                    accessor: 'status'
                },
                {
                    Header: 'template',
                    id: 'template',
                    accessor: 'templateName'
                },
                {
                    Header: 'First sent for signing',
                    id: 'firstSentForSigning',
                    accessor: 'firstSentForSigning',
                    Cell: formatDateTimeBasedOnTimezone
                },
                {
                    Header: 'Client signing date',
                    id: 'signedByClientDate',
                    accessor: 'signedByClientDate',
                    Cell: formatDateTimeBasedOnTimezone
                },
                {
                    Header: 'Completion date',
                    id: 'completionDate',
                    accessor: 'completion.signedDate',
                    Cell: formatDateTimeBasedOnTimezone
                },
                {
                    Header: 'DeliveryType',
                    id: 'deliverytype',
                    accessor: 'deliveryType'
                },
                {
                    Header: 'User',
                    id: 'agent',
                    accessor: 'agent.fullName'
                },
                {
                    Header: 'Company',
                    id: 'agency',
                    accessor: 'agency.details.agencyName'
                }
            ];

            return leaseColumns;
        }

        return [];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: 'created',
                        desc: true
                    }
                ]
            }
        },
        useSortBy
    );

    return (
        <div className="table-sort data-table">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, key) => {
                        return (
                            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                                {headerGroup.headers.map((column, columnIndex) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={'sort'}
                                        key={columnIndex}
                                    >
                                        <div>
                                            <span className="table-header">{column.render('Header')}</span>
                                            <span className="table-sort-icon">
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ArrowDropDownSharp />
                                                    ) : (
                                                        <ArrowDropUpSharp />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        );
                    })}
                </thead>
                <tbody {...getTableBodyProps()} className="reactable-data">
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={rowIndex}>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <td {...cell.getCellProps()} key={cellIndex}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default AgentReportTable;
