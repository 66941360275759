import * as React from 'react';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import { FormTextRegular } from '../../../../components/form/FormText';

import '../../../../sass/extraContacts.scss';

export const ContactServiceExtraContacts = ({ index, extraContact, indexKey, removeContact, blockType, disabled }) => {
    const name = blockType
        ? `${blockType}[${index}].extraContacts[${indexKey}].contactValue`
        : `extraContacts[${indexKey}].contactValue`;
    return (
        <div className="extra-field">
            <FormTextRegular
                name={name}
                value={extraContact.contactValue}
                label={extraContact.contactType}
                disabled={disabled}
            />
            <button
                className="extra-field--remove"
                title="Remove"
                type="button"
                onClick={removeContact}
                disabled={disabled}
            >
                <DeleteSharpIcon />
            </button>
        </div>
    );
};
