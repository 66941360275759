import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as account from '../../../actions/account';
import AgencyDetailsForm from './AgencyDetailsForm';
import AgencyLogoForm from './AgencyLogoForm';
import AgencyLicenseDetailsForm from './AgencyLicenseDetailsForm';

import '../../../sass/details.scss';
import { isAgencyUserAccount } from '../../../utils/userUtils';
import { useAccountProvider } from '../../../components/providers/AccountProvider';
import { NSW_STATE } from '../../../config';

const Details = props => {
    const dispatch = useDispatch();
    let user = useSelector(state => state.user);
    const accountType = useAccountProvider();

    const [defaultState, setDefaultState] = useState(NSW_STATE);

    useEffect(() => {
        dispatch(account.getAgencyInfo(user.agencyId));
    }, []);

    const { updateAgencyInfo, addAgencyLogo, updateLicenseDetails, displayLogo, formType } = props;
    return (
        <div className="settings-container">
            <div className="column">
                <AgencyDetailsForm
                    updateAgencyInfo={updateAgencyInfo}
                    formType={formType}
                    setDefaultState={setDefaultState}
                />
            </div>
            <div className="column sideRight">
                {displayLogo && <AgencyLogoForm addAgencyLogo={addAgencyLogo} />}
                {isAgencyUserAccount(accountType) && (
                    <React.Fragment>
                        <AgencyLicenseDetailsForm
                            updateLicenseDetails={updateLicenseDetails}
                            formType={formType}
                            defaultState={defaultState}
                        />
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default Details;
