export const SAVE_DIRECT_LEASE_ID = 'SAVE_DIRECT_LEASE_ID';
export const CLEAR_DIRECT_LEASE_ID = 'CLEAR_DIRECT_LEASE_ID';

export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';

export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REDIRECT_TO_LOGIN_PAGE = 'login.redirect.to.login.page';
export const SET_LOGIN_REDIRECT = 'login.redirect.to.custom.page';
export const CLEAR_LOGIN_USER = 'CLEAR_LOGIN_USER';

const initState = {
    agencyId: '',
    directLeaseId: null,
    isPending: false,
    isUserLoggedin: undefined,
    loginRedirect: '',
    needMfaVerification: false,
    previousAccountType: '',
    rememberMe: true,
    statusStep: false,
    step: 1,
    validationError: null
};

export default function login(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case LOG_IN_REQUEST: {
            newState = {
                ...state,
                isPending: true,
                isUserLoggedin: false,
                statusStep: false
            };
            break;
        }
        case LOG_IN_SUCCESS: {
            newState = {
                ...state,
                isPending: false,
                isUserLoggedin: !action.result.data.needMfaVerification,
                needMfaVerification: action.result.data.needMfaVerification,
                rememberMe: action.rememberMe,
                user: action.result.data.user
            };
            if (action.hasUpdate) {
                window.location.reload(false);
            }
            break;
        }

        case VERIFY_SUCCESS: {
            newState = {
                ...state,
                isPending: false,
                isUserLoggedin: true,
                user: action.result.user
            };
            break;
        }
        case ACCEPT_INVITE_SUCCESS: {
            newState = {
                ...state,
                isPending: false,
                isUserLoggedin: true,
                user: action.result.user
            };
            break;
        }
        // can't use the constant at this stage as this will create a circular dependency, we need to figure out a better way to pass these constants around
        case 'GET_USERINFO_SUCCESS': {
            newState = {
                ...state,
                isUserLoggedin: true
            };
            break;
        }
        case LOG_IN_FAILURE: {
            newState = {
                ...state,
                error: action.error ? action.error : '',
                isPending: false,
                rememberMe: action.rememberMe,
                validationError: action.error.data ? action.error.data.error : ''
            };
            break;
        }
        case LOGOUT_SUCCESS: {
            newState = {
                ...state,
                agencyId: '',
                isUserLoggedin: false,
                loginRedirect: state.loginRedirect,
                previousAccountType: state.previousAccountType,
                rememberMe: state.rememberMe
            };
            break;
        }
        case SET_LOGIN_REDIRECT: {
            newState = {
                ...state,
                loginRedirect: action.payload.redirect,
                previousAccountType: action.payload.accountType
            };
            break;
        }
        case CLEAR_LOGIN_USER: {
            newState = {
                ...state,
                user: undefined
            };
            break;
        }
        case SAVE_DIRECT_LEASE_ID: {
            newState = { ...state, directLeaseId: action.payload.directLeaseId };
            break;
        }
        case CLEAR_DIRECT_LEASE_ID: {
            newState = { ...state, directLeaseId: null };
            break;
        }
    }

    return newState;
}
