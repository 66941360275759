import React, { useEffect, useState } from 'react';
import { PARTY_LANDLORD } from '../../../../config';

const AnswersGroup = ({ questions }) => {
    return (
        <React.Fragment>
            <table className="answers-table">
                <tbody>
                    <tr>
                        <th></th>
                        <th>Disclosure</th>
                        <th>Party To Answer</th>
                        <th>Answer</th>
                        <th>Details</th>
                    </tr>
                    {questions &&
                        questions.map((question, index) => {
                            return (
                                <tr key={index}>
                                    <td className="without-label forced-mobile-view">{index + 1}</td>
                                    <td className="without-label forced-mobile-view">
                                        {question.questionText}
                                        {question.note && (
                                            <React.Fragment>
                                                <br />
                                                <br />
                                                <p className="note">{question.note}</p>
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td>{question.partyToAnswer === PARTY_LANDLORD ? 'Rental Provider' : 'Agent'}</td>
                                    <td>{question.answer}</td>
                                    <td>{question.details}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </React.Fragment>
    );
};
export default AnswersGroup;
