import * as React from 'react';
import * as Account from '../../reducers/account';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import '../../sass/react-tabs.scss';

import HomeWorkSharpIcon from '@material-ui/icons/HomeWorkSharp';
import HomeSharpIcon from '@material-ui/icons/HomeSharp';

import Details from './agencyDetails/Details';
import * as account from '../../actions/account';
import { connect } from 'react-redux';
import { isAgencyUserAccount } from '../../utils/userUtils';
import { useAccountProvider } from '../../components/providers/AccountProvider';
import { getGeneralAppLabel } from '../../utils/labelUtils';

function AgencyLicenseDetailsTabs(props) {
    const accountType = useAccountProvider();
    return (
        <div className="settings">
            <Tabs>
                <TabList>
                    <Tab>
                        <HomeSharpIcon>{getGeneralAppLabel('agencyDetails', accountType)}</HomeSharpIcon>{' '}
                        {getGeneralAppLabel('agencyDetails', accountType)}
                    </Tab>
                    {isAgencyUserAccount(accountType) && (
                        <Tab>
                            <HomeWorkSharpIcon>Sales agency details</HomeWorkSharpIcon>Sales agency details (if
                            different)
                        </Tab>
                    )}
                </TabList>

                <TabPanel>
                    <Details
                        updateAgencyInfo={props.updateAgencyInfo}
                        addAgencyLogo={props.addAgencyLogo}
                        updateLicenseDetails={props.updateLicenseDetails}
                        displayLogo={true}
                        formType={Account.AGENCY_DETAILS}
                    />
                </TabPanel>
                {isAgencyUserAccount(accountType) && (
                    <TabPanel>
                        <Details
                            updateAgencyInfo={props.updateSalesAgencyInfo}
                            updateLicenseDetails={props.updateSalesLicenseDetails}
                            displayLogo={false}
                            formType={Account.SALES_AGENCY_DETAILS}
                        />
                    </TabPanel>
                )}
            </Tabs>
        </div>
    );
}

export default connect(
    state => ({
        loggedInUser: state.user.userInfo,
        agency: state.account.agency,
        accountState: state.account,
        validationErrors: state.account.validationErrors
    }),
    {
        updateAgencyInfo: account.updateAgencyInfo,
        addAgencyLogo: account.addAgencyLogo,
        updateLicenseDetails: account.updateLicenseDetails,
        updateSalesLicenseDetails: account.updateSalesLicenseDetails,
        updateSalesAgencyInfo: account.updateSalesAgencyInfo
    }
)(AgencyLicenseDetailsTabs);
