import React from 'react';
import { useSelector } from 'react-redux';
import { formatDateDayMonthFirst, getAgencyTimezoneFromUser } from '../../../../../utils/dateUtils';
import { LEASE_STATUS_DRAFT } from '../../../../../actions/dashboard';
import { getUserInfo } from '../../../../../selectors/user';

const AgreementResidentialTimeExpires = ({ leaseAllInfo }) => {
    const loggedInUser = useSelector(getUserInfo);
    let timeClass = 'time';
    let timeDate = '';
    let timeExpires = <div />;
    if (leaseAllInfo.term) {
        timeDate = formatDateDayMonthFirst(leaseAllInfo.term.endDate, getAgencyTimezoneFromUser(loggedInUser));
    }
    if (leaseAllInfo.awaitingRenewal) {
        timeClass = 'time awaiting_renewal';
    }
    switch (leaseAllInfo.status) {
        case LEASE_STATUS_DRAFT: {
            timeClass = 'time border';
            break;
        }
    }
    if (leaseAllInfo.term) {
        timeExpires = (
            <div className={timeClass}>
                <div className="icon" />
                <div className="text">The term of this agreement expires on {timeDate}</div>
            </div>
        );
    }
    return <main>{timeExpires}</main>;
};

export default AgreementResidentialTimeExpires;
