import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils';
import DataString from '../bloks/DataString';
import { LeaseAllInfo } from './types';

type Props = {
    leaseAllInfo: LeaseAllInfo,
}

export default function AgreementClLessor({ leaseAllInfo }: Props) {
    const part = 'lessor';
    if (!leaseAllInfo?.lessor) {
        return <></>;
    }
    const { location, lessor } = leaseAllInfo;
    return (
        <div>
            <AgreementSection
                title={getClStepTitle(location, part)}
                step={getClStepNo(location, part)}
                leaseAllInfo={leaseAllInfo}
            >
                {lessor && (
                    <div>
                        {lessor.isCorporation && (
                            <>
                                <div className="data data-row">
                                    <p className="text">
                                        <span className="bold">{lessor.corporationName}</span>
                                    </p>
                                    <p>{lessor.corporationAddress}</p>
                                    <DataString title={'ABN'} text={lessor.abn} />
                                    {lessor.acn && <DataString title={'ACN'} text={lessor.acn} />}
                                    {lessor.isRegisteredForGST && <p>Registered for GST</p>}
                                </div>
                                <br />
                            </>
                        )}
                        <div>
                            {!lessor.isCorporation && Array.isArray(lessor.persons) &&
                                lessor.persons.map((person, index) => (
                                    <div className="data data-row" key={index}>
                                        {lessor.isCorporation && (
                                            <p className="name">{`${person.name} (${person.role})`}</p>
                                        )}
                                        {!lessor.isCorporation && (
                                            <>
                                                <p className="name">{person.fullName}</p>
                                                <p>{person.address}</p>
                                            </>
                                        )}
                                        {[
                                            {
                                                contactType: 'ABN',
                                                contactValue: person.abn
                                            },
                                            {
                                                contactType: 'ACN',
                                                contactValue: person.acn
                                            },
                                            {
                                                contactType: 'Ph',
                                                contactValue: person.phone
                                            },
                                            {
                                                contactType: 'E',
                                                contactValue: person.email
                                            },
                                            ...person.extraContacts
                                        ]
                                            .filter(i => i.contactValue)
                                            .map((extraContact, index) => (
                                                <DataString
                                                    title={extraContact.contactType}
                                                    text={extraContact.contactValue}
                                                    key={index}
                                                />
                                            ))}
                                        {!lessor.isCorporation && person.isRegisteredForGST && <p>Registered for GST</p>}
                                        <br />
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
            </AgreementSection>
        </div>
    );
}
