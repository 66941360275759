import React, { Component } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../../utils/agreementUtils';
import EmergencyContactDetails from '../../bloks/EmergencyContactDetails';
import { STEP_NOMINATED_REPAIRERS } from '../../../../../lease/steps';
import { NO_LABEL } from '../../../../../../config';

class AgreementResidentialNominatedRepairers extends Component {
    render() {
        const { leaseAllInfo } = this.props;
        return (
            <div>
                {!!leaseAllInfo.nominatedRepairers && (
                    <AgreementSection
                        title="Nominated Repairers"
                        step={getResTenStepNo(
                            leaseAllInfo.location,
                            STEP_NOMINATED_REPAIRERS,
                            leaseAllInfo.subLeaseType
                        )}
                        leaseAllInfo={leaseAllInfo}
                    >
                        <div>
                            <p>
                                Are the nominated repairers the tenant's first point of contact for notifying the need
                                for emergency repairs?
                            </p>
                            {leaseAllInfo.nominatedRepairers.details?.isNominatedRepairers}
                            {leaseAllInfo.nominatedRepairers.details?.isNominatedRepairers === NO_LABEL && (
                                <>
                                    <p>Contact the lessor:</p>
                                    <p>{leaseAllInfo.nominatedRepairers.details.lessorName}</p>
                                    <p>{leaseAllInfo.nominatedRepairers.details.lessorPhone}</p>
                                </>
                            )}
                            <br />
                            <br />
                            <p>Nominated tradespeople for urgent repairs:</p>
                            {leaseAllInfo.nominatedRepairers.contacts.map(item => {
                                return <EmergencyContactDetails key={item.id} item={item} />;
                            })}
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementResidentialNominatedRepairers;
