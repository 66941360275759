export const OPEN_CONFIRM_PLAN_UPGRADE_MODAL = 'OPEN_CONFIRM_PLAN_UPGRADE_MODAL';
export const CLOSE_CONFIRM_PLAN_UPGRADE_MODAL = 'CLOSE_CONFIRM_PLAN_UPGRADE_MODAL';
export const UPDATE_PAYMENT_DETAILS_REQUEST = 'UPDATE_PAYMENT_DETAILS_REQUEST';
export const UPDATE_PAYMENT_DETAILS_SUCCESS = 'UPDATE_PAYMENT_DETAILS_SUCCESS';
export const UPDATE_PAYMENT_DETAILS_FAIL = 'UPDATE_PAYMENT_DETAILS_FAIL';

export const RETRY_PAYMENT_REQUEST = 'RETRY_PAYMENT_REQUEST';
export const RETRY_PAYMENT_SUCCESS = 'RETRY_PAYMENT_SUCCESS';
export const RETRY_PAYMENT_FAIL = 'RETRY_PAYMENT_FAIL';

export const RESET_PAYMENT_STATE = 'RESET_PAYMENT_STATE';

// Statistics
export const GET_STATISTICS_REQUEST = 'GET_STATISTICS_REQUEST';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_FAIL = 'GET_STATISTICS_FAIL';

// Agent reports
export const GET_AGENT_REPORTS_REQUEST = 'GET_AGENT_REPORTS_REQUEST';
export const GET_AGENT_REPORTS_SUCCESS = 'GET_AGENT_REPORTS_SUCCESS';
export const GET_AGENT_REPORTS_FAIL = 'GET_AGENT_REPORTS_FAIL';

/**
 * @TODO replace with statefulActions from src/reducers/utils.ts
 * @type {string}
 */
export const GET_ALL_UPLOAD_A_DOCUMENTS_REQUEST = 'GET_ALL_UPLOAD_A_DOCUMENTS_REQUEST';
export const GET_ALL_UPLOAD_A_DOCUMENTS_SUCCESS = 'GET_ALL_UPLOAD_A_DOCUMENTS_SUCCESS';
export const GET_ALL_UPLOAD_A_DOCUMENTS_FAIL = 'GET_ALL_UPLOAD_A_DOCUMENTS_FAIL';

export const GET_ALL_BUILD_A_DOCUMENTS_REQUEST = 'GET_ALL_BUILD_A_DOCUMENTS_REQUEST';
export const GET_ALL_BUILD_A_DOCUMENTS_SUCCESS = 'GET_ALL_BUILD_A_DOCUMENTS_SUCCESS';
export const GET_ALL_BUILD_A_DOCUMENTS_FAIL = 'GET_ALL_BUILD_A_DOCUMENTS_FAIL';

// Agent report
export const GET_AGENT_REPORT_REQUEST = 'GET_AGENT_REPORT_REQUEST';
export const GET_AGENT_REPORT_SUCCESS = 'GET_AGENT_REPORT_SUCCESS';
export const GET_AGENT_REPORT_FAIL = 'GET_AGENT_REPORT_FAIL';

// Mongo charts
export const GET_AGREEMENTS_PER_MONTH_REQUEST = 'GET_AGREEMENTS_PER_MONTH_REQUEST';
export const GET_AGREEMENTS_PER_MONTH_SUCCESS = 'GET_AGREEMENTS_PER_MONTH_SUCCESS';
export const GET_AGREEMENTS_PER_MONTH_FAIL = 'GET_AGREEMENTS_PER_MONTH_FAIL';

export const GET_AVERAGE_RENT_10KM_SYDNEY_REQUEST = 'GET_AVERAGE_RENT_10KM_SYDNEY_REQUEST';
export const GET_AVERAGE_RENT_10KM_SYDNEY_SUCCESS = 'GET_AVERAGE_RENT_10KM_SYDNEY_SUCCESS';
export const GET_AVERAGE_RENT_10KM_SYDNEY_FAIL = 'GET_AVERAGE_RENT_10KM_SYDNEY_FAIL';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';

export const GET_ALL_AGENCIES_REQUEST = 'GET_ALL_AGENCIES_REQUEST';
export const GET_ALL_AGENCIES_SUCCESS = 'GET_ALL_AGENCIES_SUCCESS';
export const GET_ALL_AGENCIES_FAIL = 'GET_ALL_AGENCIES_FAIL';

export const CHECK_USER_CAN_VIEW_STATISTICS_REQUEST = 'CHECK_USER_CAN_VIEW_STATISTICS_REQUEST';
export const CHECK_USER_CAN_VIEW_STATISTICS_SUCCESS = 'CHECK_USER_CAN_VIEW_STATISTICS_SUCCESS';
export const CHECK_USER_CAN_VIEW_STATISTICS_FAIL = 'CHECK_USER_CAN_VIEW_STATISTICS_FAIL';

// Resend for signing
export const OPEN_RESEND_FOR_SIGNING_MODAL = 'OPEN_RESEND_FOR_SIGNING_MODAL';
export const CLOSE_RESEND_FOR_SIGNING_MODAL = 'CLOSE_RESEND_FOR_SIGNING_MODAL';

// Lease termination
export const OPEN_TERMINATE_LEASE_MODAL = 'OPEN_TERMINATE_LEASE_MODAL';
export const CLOSE_TERMINATE_LEASE_MODAL = 'CLOSE_TERMINATE_LEASE_MODAL';
export const UPDATE_TERMINATE_LEASE_DATA = 'UPDATE_TERMINATE_LEASE_DATA';
export const OPEN_TERMINATE_LEASE_LIST_MODAL = 'OPEN_TERMINATE_LEASE_LIST_MODAL';
export const CLOSE_TERMINATE_LEASE_LIST_MODAL = 'CLOSE_TERMINATE_LEASE_LIST_MODAL';

// Rent increase
export const OPEN_RENT_INCREASE_LEASE_LIST_MODAL = 'OPEN_RENT_INCREASE_LEASE_LIST_MODAL';
export const CLOSE_RENT_INCREASE_LEASE_LIST_MODAL = 'CLOSE_RENT_INCREASE_LEASE_LIST_MODAL';

//Address Details
export const OPEN_ADDRESS_DETAILS_MODAL = 'OPEN_ADDRESS_DETAILS_MODAL';
export const CLOSE_ADDRESS_DETAILS_MODAL = 'CLOSE_ADDRESS_DETAILS_MODAL';
export const CONFIRM_ADDRESS_DETAILS = 'CONFIRM_ADDRESS_DETAILS';
export const CLEAR_ADDRESS_DETAILS = 'CLEAR_ADDRESS_DETAILS';

export const CLOSE_NO_SIGNATURE_MODAL = 'CLOSE_NO_SIGNATURE_MODAL';
export const OPEN_NO_SIGNATURE_MODAL = 'OPEN_NO_SIGNATURE_MODAL';

// Connections
export const UPDATE_CONNECTIONS_REQUEST = 'UPDATE_CONNECTIONS_REQUEST';
export const UPDATE_CONNECTIONS_SUCCESS = 'UPDATE_CONNECTIONS_SUCCESS';
export const UPDATE_CONNECTIONS_FAIL = 'UPDATE_CONNECTIONS_FAIL';

export const GET_CONNECTIONS_REQUEST = 'GET_CONNECTIONS_REQUEST';
export const GET_CONNECTIONS_SUCCESS = 'GET_CONNECTIONS_SUCCESS';
export const GET_CONNECTIONS_FAIL = 'GET_CONNECTIONS_FAIL';

export const GET_CONNECTIONS_COMPANIES_REQUEST = 'GET_CONNECTIONS_COMPANIES_REQUEST';
export const GET_CONNECTIONS_COMPANIES_SUCCESS = 'GET_CONNECTIONS_COMPANIES_SUCCESS';
export const GET_CONNECTIONS_COMPANIES_FAIL = 'GET_CONNECTIONS_COMPANIES_FAIL';

export const CLEAR_CONNECTIONS_VALIDATION_ERRORS = 'CLEAR_CONNECTIONS_VALIDATION_ERRORS';

// Agency
export const GET_AGENCY_FULL_TEAM_REQUEST = 'GET_AGENCY_FULL_TEAM_REQUEST';
export const GET_AGENCY_FULL_TEAM_SUCCESS = 'GET_AGENCY_FULL_TEAM_SUCCESS';
export const GET_AGENCY_FULL_TEAM_FAIL = 'GET_AGENCY_FULL_TEAM_FAIL';

export const OPEN_RESEND_LANDLORD_INFORMATION_MODAL = 'OPEN_RESEND_LANDLORD_INFORMATION_MODAL';
export const CLOSE_RESEND_LANDLORD_INFORMATION_MODAL = 'CLOSE_RESEND_LANDLORD_INFORMATION_MODAL';
export const UPDATE_LEASE_DATA = 'UPDATE_LEASE_DATA';

export const UPDATE_FOLLOW_UP_REPORT_EMAILS_SUCCESS = 'UPDATE_FOLLOW_UP_REPORT_EMAILS_SUCCESS';

export const UPDATE_ACTIVITY_REPORT_RECEIVER_SUCCESS = 'UPDATE_ACTIVITY_REPORT_RECEIVER_SUCCESS';

// Banner
export const CLOSE_DOCUMENT_HEADER_BANNER = 'CLOSE_DOCUMENT_HEADER_BANNER';
export const DISPLAY_DOCUMENT_HEADER_BANNER = 'DISPLAY_DOCUMENT_HEADER_BANNER';
