import React, { memo } from 'react';
import { CheckboxCheck } from '../../form/FormCheckboxCheck';
import * as PropTypes from 'prop-types';

function SignatoryCheckbox({ signatory, name, disabled = false }) {
    const label = (
        <React.Fragment>
            {signatory.fullName ? signatory.fullName : signatory.name}
            {(signatory.isSigned || signatory.acknowledgedDate) && (
                <React.Fragment>
                    {' '}
                    (<i className="marker success" /> Signed)
                </React.Fragment>
            )}
        </React.Fragment>
    );
    return <CheckboxCheck name={name} label={label} disabled={disabled} />;
}

SignatoryCheckbox.propTypes = {
    index: PropTypes.number,
    key: PropTypes.number,
    text: PropTypes.any
};

export default memo(SignatoryCheckbox);
