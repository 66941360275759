import React, { useState, useEffect } from 'react';
import { FormTextRegular } from '../../../../form/FormText';
import { FormEmail } from '../../../../form/FormEmail';
import { FormPhone } from '../../../../form/FormPhone';
import { FormMaskedText } from '../../../../form/FormText';
import { CheckboxCheck } from '../../../../form/FormCheckboxCheck';
import {
    corporationRolesList,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    SA_STATE,
    SEND_TYPE_EMAIL,
    SEND_TYPE_POST
} from '../../../../../config';
import { FormButtonSelect } from '../../../../form/responsive/FormButtonSelect';
import { getLabel } from '../../../../../utils/labelUtils';
import { getLocation } from '../../../../../selectors/lease';
import { useSelector } from 'react-redux';
import { FormRadioGroup } from '../../../../form/FormRadioGroup';
import { isLeaseLocationVIC } from '../../../../../utils/agreementUtils';

const LandlordPersonGroup = ({
    index,
    removePerson,
    handleFieldChange,
    landlord,
    handlePrimaryLandlordSelect,
    form
}) => {
    const [isCorporation, setIsCorporation] = useState(landlord.isCorporation);
    const location = useSelector(getLocation);

    useEffect(() => {
        setIsCorporation(landlord.isCorporation);
    }, [landlord]);

    function clearPerson(index) {
        form.change(`persons[${index}].mobile`, '+61');
        form.change(`persons[${index}].phone`, '');
        form.change(`persons[${index}].email`, '');
        form.change(`persons[${index}].corporationName`, '');
        form.change(`persons[${index}].corporationAddress`, '');
        form.change(`persons[${index}].firstName`, '');
        form.change(`persons[${index}].secondName`, '');
        form.change(`persons[${index}].address`, '');
        form.change(`persons[${index}].ABN`, '');
        form.change(`persons[${index}].ACN`, '');
        form.change(`persons[${index}].role`, '');
        form.change(`persons[${index}].monthlyStatementSendType`, undefined);
        form.change(`persons[${index}].financialReportSendType`, undefined);
    }

    const setCorporation = (value, index) => {
        setIsCorporation(value);
        clearPerson(index);
    };

    let header;
    let caption = getLabel('landlord', location, LEASE_TYPE_PROPERTY_MANAGEMENT);
    if (index === 0) {
        header = (
            <h3>
                {caption} {index + 1}
            </h3>
        );
    } else {
        header = (
            <span className="removePerson">
                <h3>
                    {caption} {index + 1}
                </h3>
                <button type="button" onClick={() => removePerson(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="person">
            {header}
            <div className="formBox">
                <div className="formBox-column">
                    <CheckboxCheck
                        name={`persons[${index}].isCorporation`}
                        className={`corporation-${index}-check`}
                        label={`${caption} is a corporation`}
                        value={`persons[${index}].isCorporation`}
                        onClick={value => setCorporation(value, index)}
                    />
                    {isCorporation && (
                        <React.Fragment>
                            <FormTextRegular
                                name={`persons[${index}].corporationName`}
                                label={getLabel(
                                    'landlord.persons.corporationName',
                                    location,
                                    LEASE_TYPE_PROPERTY_MANAGEMENT
                                )}
                                required
                            />
                            <FormTextRegular
                                name={`persons[${index}].corporationAddress`}
                                label={getLabel(
                                    'landlord.persons.corporationAddress',
                                    location,
                                    LEASE_TYPE_PROPERTY_MANAGEMENT
                                )}
                                required
                            />
                            <FormMaskedText name={`persons[${index}].ABN`} label="ABN" mask="11 111 111 111" />
                            <FormMaskedText name={`persons[${index}].ACN`} label="ACN" mask="111 111 111" />
                            <p className="form-field-label">Person signing:</p>
                        </React.Fragment>
                    )}
                </div>
                <div className="nowrap fields-full-width-for-mobile">
                    <FormTextRegular
                        name={`persons[${index}].firstName`}
                        label="First Name"
                        onChange={handleFieldChange}
                        required
                    />
                    <FormTextRegular
                        name={`persons[${index}].middleName`}
                        label="Middle Name"
                        onChange={handleFieldChange}
                    />
                </div>
                <div className="formBox-column">
                    <FormTextRegular
                        name={`persons[${index}].secondName`}
                        label="Last Name"
                        onChange={handleFieldChange}
                        required
                    />
                    {isCorporation && (
                        <React.Fragment>
                            <FormButtonSelect
                                name={`persons[${index}].role`}
                                selectClassName={`landlord-${index}-role`}
                                options={corporationRolesList}
                                value={landlord.role}
                                label="Role of person signing"
                                isButtonGroup={true}
                                required={!isLeaseLocationVIC(location)}
                            />
                            {landlord.role === 'Other' && (
                                <FormTextRegular name={`persons[${index}].otherRole`} label="Role name" required />
                            )}
                        </React.Fragment>
                    )}
                    {!isCorporation && (
                        <FormTextRegular
                            name={`persons[${index}].address`}
                            label="Address"
                            onChange={handleFieldChange}
                            required
                        />
                    )}
                    <FormTextRegular
                        name={`persons[${index}].phone`}
                        label="Phone"
                        onChange={handleFieldChange}
                        maxLength="12"
                    />
                    <FormPhone
                        name={`persons[${index}].mobile`}
                        label="Mobile"
                        required
                        className={`persons-${index}-mobile`}
                    />
                    <FormEmail name={`persons[${index}].email`} label="Email" onChange={handleFieldChange} required />
                    {(!isCorporation && location !== SA_STATE) && (
                        <React.Fragment>
                            <FormMaskedText
                                name={`persons[${index}].ABN`}
                                label="ABN"
                                mask="11 111 111 111"
                                onChange={handleFieldChange}
                            />
                        </React.Fragment>
                    )}
                    <CheckboxCheck
                        name={`persons[${index}].isRegisteredForGST`}
                        label="Registered for GST"
                        onChange={handleFieldChange}
                    />
                    {location === SA_STATE && (
                        <>
                            <FormRadioGroup
                                label="Monthly Statement and Administration Reports will be sent via"
                                required
                                className={`formRadioGroup`}
                                name={`persons[${index}].monthlyStatementSendType`}
                                data={[
                                    {
                                        label: SEND_TYPE_EMAIL,
                                        value: SEND_TYPE_EMAIL,
                                        id: `person-${index}-monthlyStatementSendType-email`
                                    },
                                    {
                                        label: SEND_TYPE_POST,
                                        value: SEND_TYPE_POST,
                                        id: `person-${index}-monthlyStatementSendType-post`
                                    }
                                ]}
                            />
                            <FormRadioGroup
                                label="Financial Year Reports will be sent via"
                                required
                                className={`formRadioGroup`}
                                name={`persons[${index}].financialReportSendType`}
                                data={[
                                    {
                                        label: SEND_TYPE_EMAIL,
                                        value: SEND_TYPE_EMAIL,
                                        id: `person-${index}-financialReportSendType-email`
                                    },
                                    {
                                        label: SEND_TYPE_POST,
                                        value: SEND_TYPE_POST,
                                        id: `person-${index}-financialReportSendType-post`
                                    }
                                ]}
                            />
                        </>
                    )}
                    <CheckboxCheck
                        name={`persons[${index}].isPrimaryLandlord`}
                        label={`Primary ${getLabel('tenantLower', location, LEASE_TYPE_PROPERTY_MANAGEMENT)}`}
                        onClick={value => handlePrimaryLandlordSelect(index, value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default LandlordPersonGroup;
