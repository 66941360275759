import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import produce from 'immer';

const initState = {
    contacts: [],
    businessHoursContacts: []
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS: {
            draftState = action.result.data.lease.repairsAndMaintenance;
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = action.payload.agreementInfo.repairsAndMaintenance || initState;
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = action.lease.repairsAndMaintenance;
            break;
        }

        case Lease.LEASE_REPAIRS_AND_MAINTENANCE_SUCCESS: {
            draftState = action.result.data.lease.repairsAndMaintenance;
        }
    }
    return draftState;
});
