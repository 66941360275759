import React, { forwardRef, useState, useImperativeHandle, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { addDays } from 'date-fns';
import axios from 'axios';
import { has } from 'lodash';
import { SelectField } from '../../../../components/form/FormSelect';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import { FormTextMultiline, FormTextRegular } from '../../../../components/form/FormText';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { calculateEndDate, getAgencyTimezoneDate, getTermDisplay } from '../../../../utils/dateUtils';
import { formSubmitFail, setDirtyStep, updateTermSuccess } from '../../../../actions/lease';
import { getLeaseInfo, getDirtyStep, getLeaseType, getLocation, getStep, getTerm } from '../../../../selectors/lease';
import { getUserInfo } from '../../../../selectors/user';
import { termPeriods } from '../../../../config';
import '../../../../sass/term.scss';

const TERM_FORM = 'termForm';

function Term({ leaseId }, ref) {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getUserInfo);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const reduxTerm = useSelector(getTerm);
    const location = useSelector(getLocation);
    const lease = useSelector(getLeaseInfo);
    const leaseType = useSelector(getLeaseType);

    const [term, setTerm] = useState({
        ...reduxTerm,
        startDate: reduxTerm.startDate ? new Date(reduxTerm.startDate) : null,
        endDate: reduxTerm.endDate ? new Date(reduxTerm.endDate) : null,
        currentLeaseExpireDate: reduxTerm.currentLeaseExpireDate ? new Date(reduxTerm.currentLeaseExpireDate) : null,
        renewalExpiryDate: reduxTerm.renewalExpiryDate ? new Date(reduxTerm.renewalExpiryDate) : null
    });

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(TERM_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    function submitForm(values) {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return axios
            .post(`/api/agency/lease/${leaseId}/term`, values)
            .then(result => {
                dispatch(updateTermSuccess(result)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.term')) {
                    return error.response.data.errors.term;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    }

    function handleFormDirtyChange(values, form) {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
            recalculateFormData(values, form);
        }
    }

    function recalculateFormData(values, form) {
        const formState = form.getState();
        const dirtyFields = formState.dirtyFields;
        if ('qty' in dirtyFields) {
            const value = values.qty;
            setTerm({
                ...term,
                qty: value,
                endDate: calculateEndDate(values)
            });
        } else if ('period' in dirtyFields) {
            const value = values.period;
            setTerm({
                ...term,
                period: value,
                endDate: calculateEndDate(values)
            });
        } else if ('startDate' in dirtyFields) {
            const value = values.startDate;
            setTerm({
                ...term,
                startDate: value,
                endDate: calculateEndDate(values)
            });
        } else {
            setTerm({
                ...term,
                ...values
            });
        }
    }

    return (
        <div className="term">
            <Form onSubmit={submitForm} initialValues={term}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Current lease expiring on"
                                    name="currentLeaseExpireDate"
                                    selected={values.currentLeaseExpireDate}
                                    disabled={values.disabled}
                                    onChange={value => {
                                        values.startDate = addDays(value, 1);
                                        setTerm({
                                            ...term,
                                            startDate: values.startDate,
                                            endDate: calculateEndDate(values),
                                            currentLeaseExpireDate: value
                                        });
                                    }}
                                />
                            </div>
                            
                            <div className="agreement-select">
                                <div className="quantity-input">
                                    <FormTextRegular name="qty" label="The term of this agreement is:" />
                                </div>
                                <div className="period-input">
                                    <SelectField name="period" options={termPeriods} selectClassName="period" />
                                </div>
                            </div>
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="The Lease will start on"
                                    name="startDate"
                                    selected={term.startDate}
                                />
                            </div>
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="and finish date is"
                                    name="endDate"
                                    selected={term.endDate}
                                />
                            </div>
                            <p>
                                The term of the agreement is{' '}
                                {term.endDate &&
                                    getTermDisplay(values.period, values.startDate, values.endDate, loggedInUser)}
                            </p>
                            <br />
                            <FormRadioGroup
                                label="The Terms of your lease are varied as follows and if not varied then remain the same:"
                                name="isVaried"
                                radioGroupClass="wrapped-radio-group"
                                data={[
                                    {
                                        label:
                                            'The Terms of your current lease will remain the same and apply to the lease extension',
                                        value: 'no',
                                        className: 'services-of-notices-selected-0'
                                    },
                                    {
                                        label: 'The Terms of your current lease are varied',
                                        value: 'yes',
                                        className: 'services-of-notices-selected-1'
                                    }
                                ]}
                            />
                            {values.isVaried === 'yes' && (
                                <React.Fragment>
                                    <p>The Terms of your current lease are varied as detailed below:*</p>
                                    <FormTextMultiline name="variedDetails" />
                                </React.Fragment>
                            )}

                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Lease offer expiry"
                                    name="renewalExpiryDate"
                                    popperPlacement="top-start"
                                    minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                                    selected={values.renewalExpiryDate}
                                />
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(Term));
