import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as dashboard from '../../../actions/dashboard';

import '../../../sass/signee.scss';

class Signee extends Component {
    render() {
        let { person, role, signature, status } = this.props;

        let fullName = '';

        if (person.middleName) {
            fullName = `${person.firstName} ${person.middleName} ${person.secondName}`;
        } else if (person.firstName) {
            fullName = `${person.firstName} ${person.secondName}`;
        } else if (person.corporation) {
            fullName = person.corporation;
        }

        return (
            <div className="item-signors">
                <div className={`avatar ${person.isSigned ? 'signed' : ''}`}>
                    {!person.isSigned &&
                        person.firstName &&
                        person.secondName &&
                        `${person.firstName[0]}${person.secondName[0]}`}
                    {!person.isSigned && person.firstName && !person.secondName && `${person.firstName[0]}`}
                    {!person.isSigned &&
                        !person.firstName &&
                        person.corporation &&
                        `${person.corporation[0]}${person.corporation[1]}`}
                </div>
                <div className="info-user">
                    <p className="name-info-user">{fullName}</p>
                    <p>{role}</p>
                    {status === dashboard.LEASE_STATUS_AWAITING_COMPLETION && signature && (
                        <div className="signature">
                            <img src={signature} alt="signature" />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Signee.propTypes = {
    person: PropTypes.shape({
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        secondName: PropTypes.string,
        isSigned: PropTypes.bool,
        role: PropTypes.string
    })
};

export default Signee;
