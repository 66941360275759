import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { has } from 'lodash';
import ModalDialog from '../../common/components/ModalDialog';
import { updateLeaseInList } from '../../actions/dashboard';
import { FormRadioGroup } from '../../components/form/FormRadioGroup';
import Button from '../../common/components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import '../../sass/editModal.scss';
import { DEADLINE_OPTIONS } from '../../utils/formUtils';
import { isLeaseLocationSA, isLeaseTypeResidentialTenancy } from '../../utils/agreementUtils';
import { getAgencyTimezoneDate } from '../../utils/dateUtils';
import DatePickerInAgencyTimeZone from '../form/DatePickerInAgencyTimeZone';
import { getUserInfo } from '../../selectors/user';
import { SUB_LEASE_TYPE_LONG_TERM_RENEWAL } from '../../config';
import useDoubleSendGuard, { clearDoubleSendGuard } from '../../hooks/useDoubleSendGuard';

const ExtendExpiryModal = ({ isOpen, closeExtendExpiryModal }) => {
    const dispatch = useDispatch();
    const agreementInfo = useSelector(state => state.dashboard.agreementInfo);
    const [formDeadLine, setFormDeadLine] = useState(null);
    const [formRenewalDate, setFormRenewalDate] = useState(null);
    const loggedInUser = useSelector(getUserInfo);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const doubleSendGuard = useDoubleSendGuard();

    const initialValues = {
        deadline: formDeadLine || DEADLINE_OPTIONS[2].value,
        renewalExpiryDate: formRenewalDate || new Date(agreementInfo.term?.renewalExpiryDate) || null
    };

    const submitValues = values => doubleSendGuard(() => {
        // This was added due to re rendering the form when submitting the form.
        if (values.deadline && values.renewalExpiryDate) {
            setIsSubmitting(true);
            setFormRenewalDate(new Date(values.renewalExpiryDate));
            setFormDeadLine(values.deadline);
        }
        return axios
            .post(`/api/agency/lease/${agreementInfo.id}/extend-expiry`, values)
            .then(response => {
                setIsSubmitting(false);
                dispatch(updateLeaseInList(response));
                closeModal();
            })
            .catch(err => {
                clearDoubleSendGuard();
                setIsSubmitting(false);
                if (has(err, 'response.data.error')) {
                    setError(err.response.data.error);
                } else {
                    setError('Something went wrong. Please try again.');
                }
                return {};
            });
    });
    const closeModal = () => {
        setError('');
        setIsSubmitting(false);
        closeExtendExpiryModal();
    };

    const optionsList = DEADLINE_OPTIONS.map(option => {
        return {
            ...option,
            id: `extend-option-${option.value}`
        };
    });

    return (
        <ModalDialog
            title="Extend Expiry"
            isOpen={isOpen}
            closeModal={closeModal}
            shouldCloseOnOverlayClick={false}
            className="extend-expiry-modal"
        >
            <div>
                <Form onSubmit={submitValues} initialValues={initialValues}>
                    {({ handleSubmit, values }) => {
                        return (
                            <form id="edit-expiry-form" onSubmit={handleSubmit}>
                                <p>Would you like to extend the link deadline?</p>
                                <br />
                                <FormRadioGroup
                                    label=""
                                    name="deadline"
                                    data={optionsList}
                                    value={values.deadline}
                                    radioGroupClass="wrapped-radio-group desktop-bordered"
                                />
                                <p className="information-text">
                                    <span className="icon icon-warning" />
                                    We will let all{' '}
                                    {isLeaseTypeResidentialTenancy(agreementInfo.leaseType)
                                        ? 'tenants'
                                        : 'landlords'}{' '}
                                    that haven’t signed know that the expiry has been extended.
                                </p>
                                {isLeaseLocationSA(agreementInfo.location) &&
                                    agreementInfo.subLeaseType === SUB_LEASE_TYPE_LONG_TERM_RENEWAL && (
                                        <DatePickerInAgencyTimeZone
                                            label="Lease offer expiry"
                                            name={'renewalExpiryDate'}
                                            popperPlacement="top-start"
                                            minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                                            selected={values.renewalExpiryDate}
                                        />
                                    )}
                                {error && (
                                    <p className="FormInput">
                                        <span className="FormError">{error}</span>
                                    </p>
                                )}
                                <div className="btn-group">
                                    <Button
                                        type="button"
                                        secondary
                                        disabled={isSubmitting}
                                        onClick={closeModal}
                                        loading={isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" primary disabled={isSubmitting} loading={isSubmitting}>
                                        Extend
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            </div>
        </ModalDialog>
    );
};

export default ExtendExpiryModal;
