import React, { memo } from 'react';
import '../../../sass/dashboardContentTable.scss';
import IntentionToSellActive from './tables/intentionToSell/IntentionToSellActive';
import IntentionToSellCancelled from './tables/intentionToSell/IntentionToSellCancelled';
import IntentionToSellDraft from './tables/intentionToSell/IntentionToSellDraft';
import { DOC_STATUS_CANCELLED, LEASE_STATUS_DRAFT } from '../../../actions/dashboard.js';
import { DEFINE_ME } from '../../../types/utilityTypes.js';

interface IntentionToSellListingsProps {
    documentList: DEFINE_ME;
    docType: string;
    agentSignature: DEFINE_ME;
    docStatus: string;
}

const IntentionToSellListings: React.FC<IntentionToSellListingsProps> = ({ documentList, docType, agentSignature, docStatus }) => {
    switch (docStatus) {
        case LEASE_STATUS_DRAFT: {
            return (
                <div className="table-sort">
                    <IntentionToSellDraft
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
        case DOC_STATUS_CANCELLED: {
            return (
                <div className="table-sort">
                    <IntentionToSellCancelled
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
        default: {
            return (
                <div className="table-sort">
                    <IntentionToSellActive
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
    }
};

export default memo(IntentionToSellListings);
