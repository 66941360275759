import * as SpecialConditions from './../reducers/specialConditions';

import axios from 'axios';

const SPECIAL_CONDITIONS_URL = '/api/agency/account/special-conditions';

export function getSpecialConditionsTemplateList() {
    return {
        types: [
            SpecialConditions.GET_SPECIAL_CONDITIONS_LIST_REQUEST,
            SpecialConditions.GET_SPECIAL_CONDITIONS_LIST_SUCCESS,
            SpecialConditions.GET_SPECIAL_CONDITIONS_LIST_FAIL
        ],
        promise: axios.get(SPECIAL_CONDITIONS_URL)
    };
}

export function addSpecialCondition(template) {
    return {
        type: SpecialConditions.ADD_SPECIAL_CONDITION_SUCCESS,
        template
    };
}

export function updateSpecialCondition(template, templatesToUpdate) {
    return {
        type: SpecialConditions.UPDATE_SPECIAL_CONDITION_SUCCESS,
        template,
        templatesToUpdate
    };
}

export function deleteSpecialCondition(template) {
    return {
        types: [
            SpecialConditions.DELETE_SPECIAL_CONDITION_REQUEST,
            SpecialConditions.DELETE_SPECIAL_CONDITION_SUCCESS,
            SpecialConditions.DELETE_SPECIAL_CONDITION_FAIL
        ],
        promise: axios.delete(SPECIAL_CONDITIONS_URL + `/${template.id}`)
    };
}

export function closeModalSpecialCondition() {
    return {
        type: SpecialConditions.CLOSE_MODAL_SPECIAL_CONDITION
    };
}

export function openModalSpecialCondition(type, values) {
    return {
        type: SpecialConditions.OPEN_MODAL_SPECIAL_CONDITION,
        data: { type, values }
    };
}

export function clearSpecialConditionsTemplatesToUpdate() {
    return {
        type: SpecialConditions.CLEAR_SPECIAL_CONDITIONS_TEMPLATES_TO_UPDATE
    };
}
