import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../common/components/Button';
import ModalDialog from '../../common/components/ModalDialog';
import { getIsOpenNoLicenseeNumberModal } from '../../selectors/confirmModals';
import { closeNoLicenseeNumberModal } from '../../actions/confirmModals';

import styles from './ConfirmModal.module.scss';
import appHistory from '../../AppHistory';
import { closeAgreementInfoModal } from '../../actions/dashboard';

function LicenseeNumberMissingAlertModal() {
    const dispatch = useDispatch();

    const isOpen = useSelector(getIsOpenNoLicenseeNumberModal);

    function closeModal() {
        dispatch(closeNoLicenseeNumberModal());
    }

    const moveToSettings = () => {
        dispatch(closeAgreementInfoModal());
        appHistory.push('/user/details');
        closeModal();
    };

    return (
        <ModalDialog
            isOpen={isOpen}
            showModalHeader={false}
            closeModal={closeModal}
            shouldCloseOnOverlayClick={false}
            className={styles.confirmModal}
        >
            For South Australia Residential Tenancy Agreements, please enter your agency's RLA Number and Licensee
            Number in Agency Details settings to send this agreement for signing
            <div className={styles.footerButtons}>
                <Button type="button" onClick={moveToSettings} primary className={styles.actionButton}>
                    Add License Details
                </Button>
                <Button type="button" onClick={closeModal} secondary className={styles.actionButton}>
                    Cancel
                </Button>
            </div>
        </ModalDialog>
    );
}

export default LicenseeNumberMissingAlertModal;
