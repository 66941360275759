import { OPEN_RESEND_LANDLORD_INFORMATION_MODAL, CLOSE_RESEND_LANDLORD_INFORMATION_MODAL } from '../actions/types';

const initState = {
    isResendLandlordInformationStatementModalOpen: false
};

export default function landlordInformationStatement(state = initState, action) {
    switch (action.type) {
        case OPEN_RESEND_LANDLORD_INFORMATION_MODAL: {
            return {
                ...state,
                isResendLandlordInformationStatementModalOpen: true
            };
        }
        case CLOSE_RESEND_LANDLORD_INFORMATION_MODAL: {
            return {
                ...initState
            };
        }
    }

    return state;
}
