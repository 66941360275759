import React from 'react';
import '../../../sass/dashboardContentTable.scss';
import * as dashboard from '../../../actions/dashboard';
import AllDocuments from './tables/allDocuments/AllDocuments';
import AllDocumentsSentForSigning from './tables/allDocuments/AllDocumentsSentForSigning';
import AllDocumentAwaitingCompletion from './tables/allDocuments/AllDocumentAwaitingCompletion';
import AllDocumentsCompleted from './tables/allDocuments/AllDocumentsCompleted';

function AllDocumentListings({ documentList, docType, docStatus }) {
    let contentTable;

    switch (docStatus) {
        case dashboard.LEASE_STATUS_DRAFT: {
            contentTable = <AllDocuments documentList={documentList} docType={docType} />;
            break;
        }
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            contentTable = <AllDocumentsSentForSigning documentList={documentList} docType={docType} />;
            break;
        }
        case dashboard.LEASE_STATUS_AWAITING_COMPLETION: {
            contentTable = <AllDocumentAwaitingCompletion documentList={documentList} docType={docType} />;
            break;
        }
        case dashboard.LEASE_STATUS_COMPLETE: {
            contentTable = <AllDocumentsCompleted documentList={documentList} docType={docType} />;
            break;
        }
    }

    return documentList && <div className="table-sort">{contentTable}</div>;
}

export default AllDocumentListings;
