import React, { memo } from 'react';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../config';

import AllowAnimals from '../../../../components/lease/mainScreen/common/additionalTerms/AllowAnimals';
import DefenceClause from '../../../../components/lease/mainScreen/common/additionalTerms/DefenceClause';
import { FormTextRegular } from '../../../../components/form/FormText';

const ActAdditional = ({ values, addPet, allowPets, clearPets, push, additional, location }) => {
    return (
        <React.Fragment>
            <AllowAnimals
                values={values}
                allowPets={allowPets}
                clearPets={clearPets}
                addPet={addPet}
                push={push}
                additional={additional}
                location={location}
            />
            <DefenceClause />
            <FormRadioGroup
                label="Break Lease clause enabled?"
                name={'breakLeaseClause'}
                addInfoIcon={true}
                infoIconToolTipData="breakLeaseToolTip"
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'break-lease-clause-yes',
                        id: 'break-lease-clause-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'break-lease-clause-no',
                        id: 'break-lease-clause-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <FormRadioGroup
                label="Fair clause enabled?"
                name={'fairClause'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'fair-clause-yes',
                        id: 'fair-clause-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'fair-clause-no',
                        id: 'fair-clause-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <FormRadioGroup
                label="90 Day Maintenance clause enabled? "
                name={'maintenanceClause'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'maintenance-clause-yes',
                        id: 'maintenance-clause-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'maintenance-clause-no',
                        id: 'maintenance-clause-no',
                        value: NO_VALUE
                    }
                ]}
            />
        </React.Fragment>
    );
};

export default memo(ActAdditional);
