import React from 'react';
import { FFContextValue, withFeatureFlags } from '@harnessio/ff-react-client-sdk';
import { Redirect, useLocation } from 'react-router';
import SignUpLoader from './SignUpLoader';

const SignupRedirect: React.FC<FFContextValue> = ({ flags, loading }) => {
    const location = useLocation();

    if (loading) return <SignUpLoader />

    return <Redirect to={{ ...location, pathname: flags.uploadADocOnboarding ? '/onboarding/upload-a-doc' : '/signup/business' }} />;
}

export default withFeatureFlags(SignupRedirect);
