import * as Lease from '../../reducers/lease';
import { PERIOD_MONTH } from '../../config';
import { calculateFinishDate } from '../../utils/agreementUtils';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';

export const UPDATE_SALES_TERM_FORM = 'UPDATE_SALES_TERM_FORM';

const initState = {
    qty: 1,
    period: PERIOD_MONTH,
    startDate: Date.now(),
    endDate: calculateFinishDate(PERIOD_MONTH, Date.now(), 1)
};

export default function salesTermReducer(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case UPDATE_SALES_TERM_FORM: {
            return { ...state, ...action.data };
        }

        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            return { ...initState, ...action.payload.agreementInfo.term };
        }

        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            if (action.result.data.lease.term) {
                return Object.assign({}, initState, action.result.data.lease.term);
            } else {
                return {
                    qty: 1,
                    period: PERIOD_MONTH,
                    startDate: Date.now(),
                    endDate: calculateFinishDate(PERIOD_MONTH, Date.now(), 1)
                };
            }
        }

        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            return { ...state, ...action.lease.term };
        }

        case Lease.LEASE_SALES_TERM_SUCCESS: {
            return { ...state, ...action.result.data.lease.term };
        }
        default: {
            return newState;
        }
    }
}
