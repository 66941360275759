import { cloneDeep, toLower, includes } from 'lodash';
import { propertyManagementRentPeriods, DEFAULT_NUMBER_OF_WEEKS_FOR_PROPERTY_MANAGEMENT_BOND } from '../config';

export const setQuestionsToFormFromBackend = backendQuestions => {
    const newQuestions = cloneDeep(backendQuestions);
    if (newQuestions) {
        newQuestions.forEach((element, index) => {
            if (element.answerOptionsSelected) {
                element.answerOptionsSelected.forEach(selectedOption => {
                    newQuestions[index][`option-${toLower(selectedOption.answer.replace(/ /g, '_'))}`] = true;
                });
            }
        });
    }
    return newQuestions;
};

export const canShowField = (field, client) => {
    let answers;
    if (field.prerequisite && field.prerequisite.length > 0) {
        answers = field.prerequisite.map(conditions => {
            let iterationValue = true;
            for (let label in conditions) {
                if (conditions[label] !== client[label]) {
                    iterationValue = false;
                }
            }
            return iterationValue;
        });
    }
    if (answers) {
        return includes(answers, true);
    }
    return true;
};
