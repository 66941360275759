import React, { Component } from 'react';

import '../../../sass/agentSignatures/header.scss';

class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.show) {
            return (
                <header>
                    <nav className="header-navbar">
                        <div className="nav-left">
                            <p className="nav-header"> Step {this.props.step}</p>
                        </div>
                    </nav>
                    <div className="divider" />
                </header>
            );
        }

        return <header className="hide" />;
    }
}

export default Header;
