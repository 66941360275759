import * as flkAKeyActions from '../actions/flkAKey';
import produce from 'immer';
import { FLK_A_KEY_STATUS_DRAFT } from '../actions/dashboard';
import { pull } from 'lodash';

const initState = {
    isOpenFlkAKeyForm: false,
    currentKey: null,
    isEditable: false,
    selectAll: false,
    selectedKeys: [],
    addressFilter: '',
    imageUrls: [],
    images: [],
    isReminderModalOpen: false,
    reminderKey: null
};
export default function document(state = initState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case flkAKeyActions.OPEN_CREATE_KEY_FORM: {
            return produce(state, draftState => {
                draftState.isOpenFlkAKeyForm = true;
                draftState.currentKey = action.key ? action.key : null;
                draftState.imageUrls = action.key ? action.key.compressedImageUrls : [];
                draftState.images = action.key ? action.key.images : [];
                draftState.isEditable = action.key.status === FLK_A_KEY_STATUS_DRAFT;
            });
        }
        case flkAKeyActions.SET_CURRENT_KEY: {
            return produce(state, draftState => {
                draftState.currentKey = action.currentKey;
                draftState.imageUrls = action.currentKey.compressedImageUrls;
                draftState.images = action.currentKey.images;
                draftState.isEditable = action.currentKey.status === FLK_A_KEY_STATUS_DRAFT;
            });
        }
        case flkAKeyActions.OPEN_FLK_A_KEY_FORM: {
            return produce(state, draftState => {
                draftState.isOpenFlkAKeyForm = true;
            });
        }
        case flkAKeyActions.CLOSE_FLK_A_KEY_FORM: {
            return produce(state, draftState => {
                draftState.isOpenFlkAKeyForm = false;
                draftState.currentKey = null;
                draftState.imageUrls = [];
                draftState.images = [];
            });
        }
        case flkAKeyActions.CHANGE_FLK_A_KEY_FORM_EDITABLE: {
            return produce(state, draftState => {
                draftState.isEditable = action.payload.isEditable;
            });
        }
        case flkAKeyActions.OPEN_FLK_A_KEY_CHECK_IN_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenFlkAKeyCheckInModal = true;
            });
        }
        case flkAKeyActions.CLOSE_FLK_A_KEY_CHECK_IN_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenFlkAKeyCheckInModal = false;
            });
        }
        case flkAKeyActions.CHANGE_SELECTED_FLK_A_KEY: {
            const { key, value } = action.payload;
            return produce(state, draftState => {
                if (value) {
                    draftState.selectedKeys.push(key.id);
                } else {
                    pull(draftState.selectedKeys, key.id);
                }
            });
        }
        case flkAKeyActions.CHANGE_SELECT_ALL_FLK_A_KEY: {
            return produce(state, draftState => {
                draftState.selectAll = action.payload.value;
                draftState.selectedKeys = action.payload.selectedIdList;
            });
        }
        case flkAKeyActions.RESET_SELECT_FLK_A_KEY: {
            return produce(state, draftState => {
                draftState.selectAll = false;
                draftState.selectedKeys = [];
            });
        }
        case flkAKeyActions.CHANGE_FLK_A_KEY_ADDRESS_FILTER: {
            return produce(state, draftState => {
                draftState.addressFilter = action.payload.value;
            });
        }
        case flkAKeyActions.CHANGE_FLK_A_KEY_IMAGE: {
            return produce(state, draftState => {
                draftState.imageUrls = action.payload.compressedImageUrls;
                draftState.images = action.payload.images;
            });
        }
        case flkAKeyActions.OPEN_FLK_A_KEY_REMINDER_MODAL: {
            return produce(state, draftState => {
                draftState.isReminderModalOpen = true;
                draftState.reminderKey = action.payload.flkAKey;
            });
        }
        case flkAKeyActions.CLOSE_FLK_A_KEY_REMINDER_MODAL: {
            return produce(state, draftState => {
                draftState.isReminderModalOpen = false;
                draftState.reminderKey = null;
            });
        }
        case flkAKeyActions.CHANGE_RELOAD_KEY_LIST_STATUS: {
            return produce(state, draftState => {
                draftState.reloadKeyList = action.payload.value;
            });
        }
    }
    return newState;
}
