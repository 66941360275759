import React from 'react';
import cx from 'classnames';
import * as dashboard from '../../../../actions/dashboard';
import Signee from '../../components/Signee.js';
import { get } from 'lodash';
import '../../../../sass/infoAgreement.scss';
import { formatDateTime, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';
import { getUserInfo } from '../../../../selectors/user';
import { useSelector } from 'react-redux';

const SignorsTab = ({ leaseAllInfo }) => {
    const loggedInUser = useSelector(getUserInfo);
    const isRequest = false;
    let listSignors = [];
    let activityDate = formatDateTime(new Date(leaseAllInfo.updated), getAgencyTimezoneFromUser(loggedInUser));
    if (leaseAllInfo.signatures) {
        leaseAllInfo.signatures.map((item, i) => {
            listSignors.push(
                <Signee
                    person={item}
                    role={item.role}
                    signature={
                        leaseAllInfo.leaseType === 'Sales'
                            ? get(leaseAllInfo.signatory.signatories[i], 'signature.base64SignatureImage', null)
                            : leaseAllInfo.leaseType === 'Property Management'
                            ? get(leaseAllInfo.pmLandlord.persons[i], 'signature.base64SignatureImage', null)
                            : get(leaseAllInfo.tenant.tenants[i], 'signature.base64SignatureImage', null)
                    }
                    status={leaseAllInfo.status}
                    key={`${item.role}${i}`}
                />
            );
        });
    }
    return (
        <div>
            <div className={cx('signors', { hide: leaseAllInfo.status === dashboard.LEASE_STATUS_DRAFT })}>
                <p className="name">Signors</p>
                <div className="items-signors">{listSignors}</div>
            </div>
            <div
                className={
                    leaseAllInfo.status === dashboard.LEASE_STATUS_SENT_SIGNING && leaseAllInfo.lastActivityWarning
                        ? 'activity'
                        : 'activity hide'
                }
            >
                <div className="icon" />
                <div className="text">There is no activity from {activityDate}</div>
            </div>
            <div
                className={
                    leaseAllInfo.status === dashboard.LEASE_STATUS_SENT_SIGNING && isRequest
                        ? 'requests'
                        : 'requests hide'
                }
            >
                <div className="requests-block">
                    <div className="icon" />
                    <div className="text">There are 5 unresolved requests.</div>
                </div>
                <div className="icon-right" />
            </div>
        </div>
    );
};

export default SignorsTab;
