import { LEASE_TERM_SUCCESS } from '../../reducers/lease';

export const UPDATE_TERM_FORM = 'dashboard.lease.term.updateForm';

import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import { START_RENEWAL_SUCCESS } from '../../reducers/renewal';

import { calculateFinishDate, isLongTermRenewalSubLease } from '../../utils/agreementUtils';
import { PERIOD_WEEK } from '../../config';
import produce from 'immer';

const initState = {
    qty: 1,
    period: PERIOD_WEEK,
    startDate: new Date(),
    endDate: calculateFinishDate(PERIOD_WEEK, Date.now(), 1)
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case UPDATE_TERM_FORM: {
            draftState = action.data;
            break;
        }
        case Lease.CREATE_LEASE_SUCCESS:
        case Lease.CREATE_COMMERCIAL_LEASE_SUCCESS: {
            const { lease } = action.result.data;

            if (lease.term) {
                draftState = lease.term;
            } else {
                draftState = initState;
            }
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = Object.assign({}, initState, action.payload.agreementInfo.term);
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = Object.assign({}, initState, action.lease.term);
            break;
        }
        case LEASE_TERM_SUCCESS: {
            draftState = action.result.data.lease.term;
            break;
        }
        case START_RENEWAL_SUCCESS: {
            const response = action.result.data;
            if (response.lease && isLongTermRenewalSubLease(response.lease.subLeaseType)) {
                draftState = response.lease.term;
            }
            break;
        }
    }
    return draftState;
});
