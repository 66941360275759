import React, { forwardRef, useState, useEffect, useImperativeHandle, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { SelectField } from '../../../components/form/FormSelect';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { FormTextMultiline, FormTextRegular } from '../../../components/form/FormText';
import { getUserInfo } from '../../../selectors/user';
import { termPeriods, termPeriodsWithDays, NSW_STATE, QLD_STATE, ACT_STATE, SA_STATE } from '../../../config';
import { calculateEndDate, getTermDisplay, isTermGreaterThanThreeYears } from '../../../utils/dateUtils';
import '../../../sass/term.scss';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { addDays, parseISO } from 'date-fns';
import { formSubmitFail, setDirtyStep, updateTermSuccess } from '../../../actions/lease';
import {
    getLeaseInfo,
    getDirtyStep,
    getLeaseType,
    getLocation,
    getStep,
    getTerm,
    getSubLeaseType
} from '../../../selectors/lease';
import axios from 'axios';
import { has } from 'lodash';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import {
    isDefaultSubLease,
    isLongTermRenewalSubLease,
    isLeaseTypeCommercialLease
} from '../../../utils/agreementUtils';
import { getLabel } from '../../../utils/labelUtils';
import { ToggleButton } from '../../../components/form/FormToggleButton';
const TERM_FORM = 'termForm';

const Term = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getUserInfo);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const reduxTerm = useSelector(getTerm);
    const location = useSelector(getLocation);
    const lease = useSelector(getLeaseInfo);
    const leaseType = useSelector(getLeaseType);
    const subLeaseType = useSelector(getSubLeaseType);

    const [term, setTerm] = useState({
        ...reduxTerm,
        startDate: reduxTerm.startDate ? new Date(reduxTerm.startDate) : null,
        endDate: reduxTerm.endDate ? new Date(reduxTerm.endDate) : null,
        currentLeaseExpireDate: reduxTerm.currentLeaseExpireDate ? new Date(reduxTerm.currentLeaseExpireDate) : null
    });
    const [termGreaterThanThreeYears, setTermGreaterThanThreeYears] = useState(false);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(TERM_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    useEffect(() => {
        if (location === NSW_STATE) {
            //We are adding a day here to end date because we take it off to calculate end date
            setTermGreaterThanThreeYears(false);
            const endDateAddedDay = addDays(parseISO(reduxTerm.endDate), 1);
            setTermGreaterThanThreeYears(isTermGreaterThanThreeYears(term.startDate, endDateAddedDay));
            setTerm({
                ...term,
                startDate: typeof reduxTerm.startDate == 'string' ? parseISO(reduxTerm.startDate) : reduxTerm.startDate,
                endDate: typeof reduxTerm.endDate == 'string' ? parseISO(reduxTerm.endDate) : reduxTerm.endDate,
                currentLeaseExpireDate:
                    typeof reduxTerm.currentLeaseExpireDate == 'string'
                        ? parseISO(reduxTerm.currentLeaseExpireDate)
                        : reduxTerm.currentLeaseExpireDate
            });
        }
    }, []);

    const updateTerm = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/term`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateTerm(values)
            .then(result => {
                dispatch(updateTermSuccess(result)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.term')) {
                    return error.response.data.errors.term;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="term">
            <Form onSubmit={submitForm} initialValues={term}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            {!isLeaseTypeCommercialLease(leaseType) &&
                                isDefaultSubLease(subLeaseType) &&
                                location !== ACT_STATE && (
                                    <ToggleButton
                                        label={'Periodic agreement'}
                                        name="periodic"
                                        value={false}
                                        onClick={value => setTerm({ ...values, periodic: value })}
                                    />
                                )}
                            {isLongTermRenewalSubLease(subLeaseType) && (
                                <div className="calendar">
                                    <DatePickerInAgencyTimeZone
                                        label="Current lease expiring on"
                                        name="currentLeaseExpireDate"
                                        selected={values.currentLeaseExpireDate}
                                        disabled={
                                            (lease.renewedFrom && isLongTermRenewalSubLease(subLeaseType)) ||
                                            values.disabled
                                        }
                                        onChange={value => {
                                            values.startDate = addDays(value, 1);
                                            setTerm({
                                                ...values,
                                                startDate: values.startDate,
                                                endDate: calculateEndDate(values),
                                                currentLeaseExpireDate: value
                                            });
                                        }}
                                    />
                                </div>
                            )}
                            {!values.periodic && (
                                <React.Fragment>
                                    <div className="agreement-select">
                                        <FormTextRegular
                                            label={getLabel('termQty', location, leaseType)}
                                            name="qty"
                                            onChange={value => {
                                                values.qty = value.target.value;
                                                setTerm({
                                                    ...values,
                                                    qty: value.target.value,
                                                    endDate: calculateEndDate(values)
                                                });
                                            }}
                                        />
                                        <SelectField
                                            addLabelSpacing
                                            name="period"
                                            options={
                                                location === NSW_STATE || location === ACT_STATE
                                                    ? termPeriodsWithDays
                                                    : termPeriods
                                            }
                                            selectClassName="period"
                                            onChange={value => {
                                                values.period = value.value;
                                                setTerm({
                                                    ...values,
                                                    period: value.value,
                                                    endDate: calculateEndDate(values)
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="calendar">
                                        <DatePickerInAgencyTimeZone
                                            label={getLabel('termStartDate', location, leaseType)}
                                            name="startDate"
                                            selected={term.startDate}
                                            onChange={value => {
                                                values.startDate = value;
                                                setTerm({
                                                    ...values,
                                                    startDate: value,
                                                    endDate: calculateEndDate(values)
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="calendar">
                                        <DatePickerInAgencyTimeZone
                                            label={getLabel('termEndDate', location, leaseType)}
                                            name="endDate"
                                            selected={term.endDate}
                                            onChange={value => {
                                                setTerm({
                                                    ...values,
                                                    endDate: value
                                                });
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            {values.periodic && (
                                <React.Fragment>
                                    <div className="calendar">
                                        <DatePickerInAgencyTimeZone
                                            label="The Lease will start on"
                                            name="startDate"
                                            selected={term.startDate}
                                            onChange={value => {
                                                values.startDate = value;
                                                setTerm({
                                                    ...values,
                                                    startDate: value,
                                                    endDate: null
                                                });
                                            }}
                                        />
                                    </div>
                                    {location === SA_STATE && (
                                        <p>and continues until it is terminated in accordance with this Agreement.</p>
                                    )}
                                </React.Fragment>
                            )}
                            {location !== QLD_STATE && !values.periodic && values.startDate && values.endDate && (
                                <p>
                                    The term of the agreement is{' '}
                                    {term.endDate &&
                                        getTermDisplay(values.period, values.startDate, values.endDate, loggedInUser)}
                                </p>
                            )}

                            {isLongTermRenewalSubLease(subLeaseType) && (
                                <>
                                    <br />
                                    <FormRadioGroup
                                        label="The Terms of your lease are varied as follows and if not varied then remain the same:"
                                        name="isVaried"
                                        radioGroupClass="wrapped-radio-group"
                                        data={[
                                            {
                                                label:
                                                    'The Terms of your current lease will remain the same and apply to the lease extension',
                                                value: 'no',
                                                className: 'services-of-notices-selected-0'
                                            },
                                            {
                                                label: 'The Terms of your current lease are varied',
                                                value: 'yes',
                                                className: 'services-of-notices-selected-1'
                                            }
                                        ]}
                                    />
                                    {values.isVaried === 'yes' && (
                                        <React.Fragment>
                                            <p>The Terms of your current lease are varied as detailed below:*</p>
                                            <FormTextMultiline name="variedDetails" />
                                        </React.Fragment>
                                    )}
                                </>
                            )}

                            {!isLeaseTypeCommercialLease(leaseType) &&
                                location === NSW_STATE &&
                                termGreaterThanThreeYears && (
                                    <p className="text-warning">
                                        <span className="icon icon-warning" /> Note. For a residential tenancy agreement
                                        having a fixed term of more than 3 years, the agreement must be annexed to the
                                        form approved by the Registrar-General for registration under the Real Property
                                        Act 1900.
                                    </p>
                                )}
                            {isLeaseTypeCommercialLease(leaseType) && (
                                <>
                                    <br />
                                    <FormTextMultiline name="furtherPeriod" label="Option - For a further period of" />
                                </>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Term);
