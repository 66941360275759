import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';

export const UPDATE_DOCUMENTS_FORM = 'UPDATE_DOCUMENTS_FORM';
export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';

const initState = {
    documents: []
};

export default function documentsReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_DOCUMENTS_FORM: {
            return {
                ...state,
                documents: action.data
            };
        }

        case Lease.CREATE_LEASE_SUCCESS:
        case Lease.CREATE_PM_LEASE_SUCCESS:
        case Lease.CREATE_SALES_LEASE_SUCCESS:
        case Lease.CREATE_COMMERCIAL_LEASE_SUCCESS: {
            return {
                ...state,
                documents: action.result.data.lease.documents
            };
        }

        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            return {
                ...initState,
                documents: action.payload.agreementInfo.documents
            };
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            return {
                ...state,
                documents: action.lease.documents
            };
        }
        case UPDATE_DOCUMENTS: {
            return {
                ...state,
                documents: action.payload.files
            };
        }
        case Lease.LEASE_DOCUMENTS_SUCCESS: {
            return {
                documents: action.result.data.lease.documents
            };
        }
    }

    return state;
}
