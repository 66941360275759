import { processServerErrors } from './lease';

export const CHECK_RENEWAL_REQUEST = 'CHECK_RENEWAL_REQUEST';
export const CHECK_RENEWAL_SUCCESS = 'CHECK_RENEWAL_SUCCESS';
export const CHECK_RENEWAL_FAIL = 'CHECK_RENEWAL_FAIL';

export const OPEN_RENEWAL_MODAL = 'OPEN_RENEWAL_MODAL';
export const CLOSE_RENEWAL_MODAL = 'CLOSE_RENEWAL_MODAL';

export const CANCEL_RENEWAL_REQUEST = 'CANCEL_RENEWAL_REQUEST';
export const CANCEL_RENEWAL_SUCCESS = 'CANCEL_RENEWAL_SUCCESS';
export const CANCEL_RENEWAL_FAIL = 'CANCEL_RENEWAL_FAIL';

export const START_RENEWAL_REQUEST = 'START_RENEWAL_REQUEST';
export const START_RENEWAL_SUCCESS = 'START_RENEWAL_SUCCESS';
export const START_RENEWAL_FAIL = 'START_RENEWAL_FAIL';

export const RESET_START_RENEWAL = 'RESET_START_RENEWAL';

export const UPDATE_RENEWAL_RENT_FORM = 'UPDATE_RENEWAL_RENT_FORM';

export const FINISH_RENEWAL_REQUEST = 'FINISH_RENEWAL_REQUEST';
export const FINISH_RENEWAL_SUCCESS = 'FINISH_RENEWAL_SUCCESS';
export const FINISH_RENEWAL_FAIL = 'FINISH_RENEWAL_FAIL';

import { PERIOD_WEEKLY, PERIOD_WEEK } from '../config';

const initState = {
    renewal: {
        rent: {
            amount: '',
            payablePeriod: PERIOD_WEEKLY,
            dateStart: null,
            bondAlreadyHeld: true,
            isRentIncrease: false,
            rentIncreaseList: [
                {
                    newRentAmount: '',
                    newRentPayablePeriod: PERIOD_WEEKLY,
                    newRentDateStart: null
                }
            ]
        },
        term: {
            qty: 1,
            period: PERIOD_WEEK,
            startDate: null,
            endDate: null
        },
        conditionReportDate: null,
        numberOfBedrooms: null,
        numberOfBathrooms: null,
        numberOfCarSpaces: null
    },
    lease: {},
    daysBeforeExpire: 0,
    isShowLeaseRenewallStart: false,
    avaliableStepsNumber: 0,
    activeStepNumber: 0,
    validationErrors: null,
    isFailRenewal: false,
    isOpenLeaseRenewalModal: false,
    success: true,
    errorCode: null,
    error: '',
    isCancellingRenewal: false,
    cancelRenewalSuccess: false,
    isStartingRenewal: false
};

export default function renewal(state = initState, action) {
    switch (action.type) {
        case OPEN_RENEWAL_MODAL: {
            return Object.assign({}, initState, {
                isOpenLeaseRenewalModal: true
            });
        }

        case CLOSE_RENEWAL_MODAL: {
            return Object.assign({}, state, {
                isOpenLeaseRenewalModal: false
            });
        }

        case CHECK_RENEWAL_SUCCESS: {
            const response = action.result.data;

            return Object.assign({}, state, {
                success: response.success
            });
        }

        case CHECK_RENEWAL_FAIL: {
            if (action.error.response) {
                const response = action.error.response.data;

                if (response.errorCode === 4001) {
                    return Object.assign({}, state, {
                        success: response.success,
                        error: response.error,
                        avaliableStepsNumber: response.step,
                        activeStepNumber: response.step,
                        errorCode: response.errorCode,
                        lease: response.renewal.lease,
                        daysBeforeExpire: response.renewal.daysBeforeExpire,
                        renewal: Object.assign({}, state.renewal, {
                            conditionReportDate:
                                response.renewal.conditionReportDate !== null
                                    ? new Date(response.renewal.conditionReportDate)
                                    : null,
                            rent: Object.assign({}, state.renewal.rent, response.renewal.rent)
                        })
                    });
                } else {
                    return Object.assign({}, state, {
                        success: response.success,
                        error: response.error
                    });
                }
            }
            return Object.assign({}, state, {
                success: false,
                error: 'Something went wrong. please try again.'
            });
        }
        case START_RENEWAL_REQUEST: {
            return {
                ...state,
                isStartingRenewal: true
            };
        }
        case START_RENEWAL_SUCCESS: {
            const response = action.result.data;
            if (response.renewal) {
                return Object.assign({}, state, {
                    isStartingRenewal: false,
                    avaliableStepsNumber: 1,
                    activeStepNumber: 1,
                    success: response.success,
                    lease: response.renewal.lease,
                    daysBeforeExpire: response.renewal.daysBeforeExpire,
                    renewal: Object.assign({}, state.renewal, {
                        conditionReportDate:
                            response.renewal.conditionReportDate !== null
                                ? new Date(response.renewal.conditionReportDate)
                                : null,
                        rent: Object.assign({}, state.renewal.rent, response.renewal.rent)
                    })
                });
            }
            return Object.assign({}, initState, {
                isStartingRenewal: false,
                isOpenLeaseRenewalModal: false
            });
        }

        case START_RENEWAL_FAIL: {
            if (action.error.response) {
                const response = action.error.response.data;

                return Object.assign({}, state, {
                    isStartingRenewal: false,
                    avaliableStepsNumber: response.step ? response.step : 1,
                    activeStepNumber: response.step ? response.step : 1,
                    success: response.success,
                    error: response.error,
                    errorCode: response.errorCode,
                    lease: response.renewal.lease,
                    daysBeforeExpire: response.renewal.daysBeforeExpire,
                    renewal: Object.assign({}, initState.renewal, {
                        conditionReportDate:
                            response.renewal.conditionReportDate !== null
                                ? new Date(response.renewal.conditionReportDate)
                                : null,
                        rent: Object.assign({}, initState.renewal.rent, response.renewal.rent)
                    })
                });
            }
            return Object.assign({}, state, {
                isStartingRenewal: false,
                success: false,
                error: 'Something went wrong. please try again.'
            });
        }

        case RESET_START_RENEWAL: {
            return Object.assign({}, state, initState);
        }

        case UPDATE_RENEWAL_RENT_FORM: {
            return {
                ...state,
                renewal: {
                    ...state.renewal,
                    ...action.data
                }
            };
        }

        case FINISH_RENEWAL_SUCCESS: {
            return {
                ...state,
                ...initState,
                isOpenLeaseRenewalModal: false
            };
        }

        case FINISH_RENEWAL_FAIL: {
            const { status, data } = action.error.response;
            let newState = {
                ...state,
                isFailRenewal: true
            };

            if (status === 400) {
                newState.validationErrors = processServerErrors(data.errors, true);
            }

            return newState;
        }
        case CANCEL_RENEWAL_REQUEST: {
            return {
                ...state,
                isCancellingRenewal: true
            };
        }
        case CANCEL_RENEWAL_SUCCESS: {
            return {
                ...state,
                ...initState,
                isCancellingRenewal: false,
                cancelRenewalSuccess: true
            };
        }
        case CANCEL_RENEWAL_FAIL: {
            return {
                ...state,
                isCancellingRenewal: false,
                cancelRenewalSuccess: false
            };
        }

        default:
            return state;
    }
}
