import React, { memo } from 'react';

import { FormMaskedText, FormTextRegular } from '../../../components/form/FormText';
import { FormPhone } from '../../../components/form/FormPhone';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormEmail } from '../../../components/form/FormEmail';
import ExtraContacts from '../../../components/lease/extraContacts/ExtraContacts';

const CommercialLeaseLicensee = ({ licensee, push }) => {
    return (
        <div className="formBox">
            <div className="formBox-column">
                <FormTextRegular name="agencyName" label="Agency Name" required />
                <FormTextRegular name="companyName" label="Company Name" required />
                <FormTextRegular name="address" label="Address" required />
                <FormPhone dataTest="licensee-phone" name="phone" label="Phone" required />
                <FormEmail name="email" label="Email" required />
                <FormMaskedText dataTest="licensee-abn" name="abn" label="ABN" mask="11 111 111 111" />
                <FormMaskedText dataTest="licensee-acn" name="acn" label="ACN" mask="111 111 111" />
                <FormTextRegular name="licenceNo" label="Licence No" required />
                <CheckboxCheck name="isRegisteredForGst" label="Registered for GST" />
                <ExtraContacts name="extraContacts" initialValue={licensee.extraContacts} push={push} />
            </div>
        </div>
    );
};

export default memo(CommercialLeaseLicensee);
