import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import { FormModel } from '../EstimatedSellingPriceForm.model';
import { isEmpty, uniq } from 'lodash';
import DeliveryTypeComponent from '../../../shared/DeliveryTypeComponent.js';
import { getAgencyTimezoneDate } from '../../../../../utils/dateUtils.js';
import DatePickerInAgencyTimeZone from '../../../../../components/form/DatePickerInAgencyTimeZone.js';
import { DELIVERY_TYPE_EMAIL } from '../../../../../config';
import { getUserInfo } from '../../../../../selectors/user';
import * as validators from '../../../../../components/Validate';
import { parseISO } from 'date-fns';

type NoticeDeliverySectionProps = {
    isReadOnly?: boolean;
};

const NoticeDeliverySection: React.FC<NoticeDeliverySectionProps> = ({ isReadOnly }) => {
    const [addEmailFieldError, setAddEmailFieldError] = useState('');
    const loggedInUser = useSelector(getUserInfo);
    const form = useForm<FormModel>();
    const { values } = form.getState();

    const validateEmail = (value: string) => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        }
        setAddEmailFieldError('');
        return true;
    };

    // Delivery email change functions
    const emailAgentAndTenantHandler = (
        values: FormModel,
        setValue: (fieldName: string, fieldValue?: string | string[]) => void
    ) => {
        const emailAddresses: string[] = [];
        values.principals.forEach(principal => {
            if (principal.email && validateEmail(principal.email)) {
                emailAddresses.push(principal.email);
            }
        });
        values.emailAddresses = uniq(emailAddresses);
        values.ccEmailAddresses = [loggedInUser.email];
        values.dateOfNotice = parseISO(new Date().toISOString());
        setValue('emailAddresses', values.emailAddresses);
        setValue('ccEmailAddresses', values.ccEmailAddresses);
        setValue('dateOfNotice', values.dateOfNotice);
        setValue('emailTo', values.emailTo);
    };

    return (
        <>
            <h4>Notice delivery</h4>
            <DeliveryTypeComponent
                emailAgentAndTenantHandler={emailAgentAndTenantHandler}
                addEmailFieldError={addEmailFieldError}
                values={values}
                form={form}
                isAgentAndVendor={true}
                isReadOnly={isReadOnly}
            />
            <div className="calender">
                <DatePickerInAgencyTimeZone
                    label="Date of the notice"
                    name="dateOfNotice"
                    selected={values.dateOfNotice}
                    minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                    disabled={isReadOnly || values.deliveryType === DELIVERY_TYPE_EMAIL}
                    required
                />
            </div>
        </>
    );
};

export default NoticeDeliverySection;
