import React from 'react';
import { Form } from 'react-final-form';
import * as dashboard from '../../../../actions/dashboard';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../../sass/resendModal.scss';
import '../../../../sass/reactConfirmAlert.scss';
import SendForSigningContainer from './SendForSigningContainer';

const RenderSendForSigningFormPm = ({
    leaseAllInfo,
    isPostSigningFail,
    postSigningFailMessage,
    isPostReSigningFail,
    optionsNumber,
    leaseType,
    confirmRequestsResolvedAndSendForSigning,
    handleSendForSigning,
    isSubscriptionCancelled,
    isLoading
}) => {
    return (
        <div>
            <Form
                initialValues={{
                    deadline: '72'
                }}
                onSubmit={values => {
                    if (!!leaseAllInfo.status && leaseAllInfo.status === dashboard.LEASE_STATUS_SENT_SIGNING) {
                        // Open the resend for signing tenant modal
                        confirmRequestsResolvedAndSendForSigning(values);
                    } else {
                        handleSendForSigning(values);
                    }
                }}
            >
                {({ handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SendForSigningContainer
                                leaseAllInfo={leaseAllInfo}
                                leaseType={leaseType}
                                values={values}
                                optionsNumber={optionsNumber}
                                isPostSigningFail={isPostSigningFail}
                                postSigningFailMessage={postSigningFailMessage}
                                isPostReSigningFail={isPostReSigningFail}
                                isSubscriptionCancelled={isSubscriptionCancelled}
                                isLoading={isLoading}
                                handleSubmit={handleSubmit}
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export default RenderSendForSigningFormPm;
