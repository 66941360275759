import React from 'react';
import { getPmStepNo, getPmStepTitle } from '../../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';

export default function AgreementPmAdditionalCosts({ leaseAllInfo }) {
    const { pmAdditionalCosts, location } = leaseAllInfo;
    if (!pmAdditionalCosts) {
        return null;
    }
    const additionalCostsList = pmAdditionalCosts.additionalCostsList || [];
    return (
        <div>
            {pmAdditionalCosts && (
                <AgreementSection
                    title={getPmStepTitle(location, 'pmAdditionalCosts')}
                    step={getPmStepNo(location, 'pmAdditionalCosts')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        {[
                            {
                                description: 'Administration Fee per Statement',
                                amount: pmAdditionalCosts.statementAdminFee
                            },
                            {
                                description: 'Condition Report Video',
                                amount: pmAdditionalCosts.conditionReportVideoFee
                            },
                            {
                                description: 'End of Financial Year Statement',
                                amount: pmAdditionalCosts.endOfYearStatementFee
                            },
                            {
                                description: 'VCAT Application Fee',
                                amount: pmAdditionalCosts.vcatApplicationFee
                            },
                            {
                                description: 'Preparation and attendance at VCAT',
                                amount: pmAdditionalCosts.vcatPreparationFee
                            },
                            {
                                description: 'Obtaining Warrant of Possession',
                                amount: pmAdditionalCosts.warrantFee
                            },
                            {
                                description: 'Registered Post',
                                amount: pmAdditionalCosts.registeredPostFee
                            },
                            {
                                description: 'Preparing and Lodging Insurance Claim',
                                amount: pmAdditionalCosts.insuranceClaimFee
                            },
                            {
                                description: 'Key Cutting',
                                amount: pmAdditionalCosts.keyCuttingFee
                            },
                            ...additionalCostsList
                        ].map((fee, index) => {
                            return (
                                <div key={index}>
                                    <p className="name">
                                        {fee.description}: <strong>{fee.amount || 'N/A'}</strong>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </AgreementSection>
            )}
        </div>
    );
}
