import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import { editPermissions } from '../../../utils/userPermissions';
import { SCHEDULE_TYPE_LATER, SCHEDULE_TYPE_NOW, tabs } from '../../../config';
import Panel from '../../../common/components/Panel';
import { FormNumber } from '../../../components/form/FormText';
import { getUserInfo } from '../../../selectors/user';
import { getAgency } from '../../../selectors/settings/account';
import { useEffect, useState } from 'react';
import { has } from 'lodash';
import axios from 'axios';
import { FormRadioGroupSummaryButton } from '../../../components/form/FormRadioGroupButton';
import { ReactComponent as ScheduleImage } from '../../../../assets/images/schedule_image.svg';
import { ReactComponent as SendTogetherImage } from '../../../../assets/images/send_together_image.svg';
import { ToggleButton } from '../../../components/form/FormToggleButton';
import SaveButtonIcon from '../SaveButtonIcon';

const NoticeToLeaveFlowDetailsForm = () => {
    const loggedInUser = useSelector(getUserInfo);
    const agencySelected = useSelector(getAgency);
    const [agency, setAgency] = useState(agencySelected);

    useEffect(() => {
        setAgency(agencySelected);
    }, [agencySelected]);

    const saveRenewal = useMutation(data => {
        return axios.put(`/api/agency/${agency.id}/renewal-details`, data);
    });

    const handleSubmit = async values => {
        // Reset mutate data
        // Otherwise it will keep old data and display old error/success messages
        saveRenewal.reset();
        try {
            await saveRenewal.mutateAsync(values);
        } catch (error) {
            let err = {};
            if (has(error, 'response.data.errors.renewalNoticeToLeaveDetails')) {
                err = error.response.data.errors.renewalNoticeToLeaveDetails;
            }
            return err;
        }
    };

    let userRole = loggedInUser.role;

    return (
        <Form initialValues={agency.renewalNoticeToLeaveDetails} onSubmit={handleSubmit}>
            {({ handleSubmit, values }) => {
                return (
                    <form onSubmit={handleSubmit} id="defaultNoticeToLeaveForm">
                        <Panel
                            title="ISSUE NOTICE TO LEAVE DEFAULTS"
                            rightButton={
                                <div>
                                    {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                        <SaveButtonIcon
                                            isSaving={saveRenewal.isLoading}
                                            success={saveRenewal.isSuccess}
                                            failure={saveRenewal.isError}
                                        />
                                    )}
                                </div>
                            }
                        >
                            <ToggleButton
                                label={'Issue Notice to Leave'}
                                name="isRenewalToLeaveFlow"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormRadioGroupSummaryButton
                                name="scheduleType"
                                value={values.scheduleType}
                                data={[
                                    {
                                        label: 'Schedule it',
                                        message: 'Automatically send the Notice to Leave at a date.',
                                        image: <ScheduleImage />,
                                        value: SCHEDULE_TYPE_LATER,
                                        dataTest: 'button-schedule-it'
                                    },
                                    {
                                        label: 'Send it together',
                                        message: 'Send both lease renewal and notice to leave at the same time',
                                        image: <SendTogetherImage />,
                                        value: SCHEDULE_TYPE_NOW,
                                        dataTest: 'button-send-it-together'
                                    }
                                ]}
                            />
                            <FormNumber
                                name={'daysBeforeNoticeToLeave'}
                                label="Default days before Lease End to send Notice to Leave (QLD leases only)"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormNumber
                                name={'daysBeforeReminderToSign'}
                                label="Default days before Notice to Leave for sending a reminder to sign (QLD Leases only)"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};

export default NoticeToLeaveFlowDetailsForm;
