import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { formatCurrency } from '../../../../utils/formUtils';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { STANDARD_DATE_AMERICAN_FORMAT } from '../../../../config';
import queryString from 'query-string';
import { useLocation } from 'react-router';

const AgentReportTable = ({ agentReports, filters }) => {
    const data = agentReports;
    const history = useHistory();
    const location = useLocation();

    const columns = React.useMemo(
        () => [
            {
                Header: 'Agent',
                accessor: 'agent.fullName',
                Footer: () => {
                    return <p>Total:</p>;
                },
                Cell: props => (
                    <a className="special-link" onClick={() => openAgentReport(props)}>
                        {props.value}
                    </a>
                )
            },
            {
                Header: 'New',
                accessor: 'countNew',
                Footer: info => {
                    const total = React.useMemo(() => info.data.reduce((sum, row) => row.countNew + sum, 0), [
                        info.data
                    ]);

                    return <p>{total}</p>;
                }
            },
            {
                Header: 'Renewals',
                accessor: 'countRenewals',
                Footer: info => {
                    const total = React.useMemo(() => info.data.reduce((sum, row) => row.countRenewals + sum, 0), [
                        info.data
                    ]);

                    return <p>{total}</p>;
                }
            },
            {
                Header: 'Total',
                id: 'totalResTen',
                accessor: 'countTotalResTen',
                Footer: info => {
                    const total = React.useMemo(() => info.data.reduce((sum, row) => row.countTotalResTen + sum, 0), [
                        info.data
                    ]);

                    return <p>{total}</p>;
                }
            },
            {
                Header: 'Lease rent increases',
                accessor: 'countWithIncrease',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(() => info.data.reduce((sum, row) => row.countWithIncrease + sum, 0), [
                        info.data
                    ]);

                    return <p>{total}</p>;
                }
            },
            {
                Header: 'Standalone rent increases',
                accessor: 'countStandaloneRentIncreases',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(
                        () => info.data.reduce((sum, row) => row.countStandaloneRentIncreases + sum, 0),
                        [info.data]
                    );

                    return <p>{total}</p>;
                }
            },
            {
                Header: 'Increase in annual rent collected',
                accessor: 'totalYearlyIncrease',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(
                        () => info.data.reduce((sum, row) => row.totalYearlyIncrease + sum, 0),
                        [info.data]
                    );

                    return <div> $ {formatCurrency(total, false)} </div>;
                },
                Cell: props => <div> $ {formatCurrency(props.value, false)} </div>
            },
            {
                Header: 'PM Agreements',
                accessor: 'countPmAgreements',
                Footer: info => {
                    const total = React.useMemo(() => info.data.reduce((sum, row) => row.countPmAgreements + sum, 0), [
                        info.data
                    ]);

                    return <p>{total}</p>;
                }
            },
            {
                Header: 'Sales Agreements',
                accessor: 'countSalesAgreements',
                Footer: info => {
                    const total = React.useMemo(
                        () => info.data.reduce((sum, row) => row.countSalesAgreements + sum, 0),
                        [info.data]
                    );

                    return <p>{total}</p>;
                }
            },
            {
                Header: 'Total termination notices',
                accessor: 'countTerminations',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(() => info.data.reduce((sum, row) => row.countTerminations + sum, 0), [
                        info.data
                    ]);

                    return <p>{total}</p>;
                }
            },
            {
                Header: 'Total breach notices',
                accessor: 'countBreaches',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(() => info.data.reduce((sum, row) => row.countBreaches + sum, 0), [
                        info.data
                    ]);

                    return <p>{total}</p>;
                }
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: 'total',
                        desc: true
                    }
                ]
            }
        },
        useSortBy
    );

    const openAgentReport = cell => {
        const row = cell.row;
        const id = row.original.agent.id;
        const queryParams = {
            startDate: format(filters.startDate, STANDARD_DATE_AMERICAN_FORMAT),
            endDate: format(filters.endDate, STANDARD_DATE_AMERICAN_FORMAT)
        };

        const stringified = queryString.stringify(queryParams);
        history.push(`/user/agent/${id}/report?${stringified}`, { from: location.pathname, search: location.search });
    };
    const getClassName = column => {
        let newClassName = 'no-sort';
        if (column && column.isSorted) {
            if (column.isSortedDesc) {
                newClassName = 'sort-desc';
            } else {
                newClassName = 'sort-asc';
            }
        }
        return newClassName;
    };
    return (
        <div className="table-sort data-table">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, key) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                            {headerGroup.headers.map((column, columnIndex) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={'sort'}
                                    key={columnIndex}
                                >
                                    <div className={`table-header-sort ${getClassName(column)}`}>
                                        {column.render('Header')}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="reactable-data">
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={rowIndex}>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <td {...cell.getCellProps()} key={cellIndex}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    {footerGroups.map((row, rowIndex) => (
                        <tr {...row.getFooterGroupProps()} key={rowIndex}>
                            {row.headers.map((cell, cellIndex) => (
                                <td {...cell.getFooterProps()} key={cellIndex}>
                                    {cell.render('Footer')}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </div>
    );
};

export default AgentReportTable;
