import React from 'react';

export default function AccountSupportText() {
    return (
        <div className="settings">
            <div className="customer-support">
                <h1>Support</h1>
                <p>
                    Having trouble with something? We're here to help!
                    <br />
                    <br />
                    Visit our help centre for resources on how to navigate your dashboard, create templates, learn new
                    features and much more.
                    <br />
                    <br />
                    <a href="https://help.flkitover.com/" rel="noreferrer" target="_blank" className="btn-primary">
                        Help centre
                    </a>
                </p>
                <br />
                <p>
                    Alternatively, email a request to our team{' '}
                    <a
                        href="mailto:support@flkitover.com?subject=Support request - FLK it over Business"
                        target="_blank"
                        rel="noreferrer"
                    >
                        support@flkitover.com
                    </a>
                </p>
            </div>
        </div>
    );
}
