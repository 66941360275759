import ReactTooltip from 'react-tooltip';
import * as PropTypes from 'prop-types';
import React from 'react';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';

function ViewButton(props) {
    return (
        <div className={`btn ${props.className}`}>
            {props.toolTip && (
                <ReactTooltip
                    id={props.id ? props.id : 'view'}
                    class="tooltip"
                    place={props.toolTipPlace ? props.toolTipPlace : 'top'}
                    effect="solid"
                    globalEventOff="wheel"
                >
                    <p>{props.toolTip}</p>
                </ReactTooltip>
            )}
            <span>
                <VisibilitySharpIcon data-tip={true} data-for={props.id ? props.id : 'view'} onClick={props.onClick} />
            </span>
        </div>
    );
}

ViewButton.propTypes = {
    onClick: PropTypes.func,
    toolTip: PropTypes.string
};

export default ViewButton;
