import * as LeaseWithRequest from '../leaseWithRequest';
import * as Lease from '../lease';

export const UPDATE_ADDITIONAL_SALES_TERMS_FORM = 'UPDATE_ADDITIONAL_SALES_TERMS_FORM';

const initState = {
    isCoolingOffPeriodWaived: false,
    termsList: []
};

export default function additionalSalesTermReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_ADDITIONAL_SALES_TERMS_FORM: {
            return { ...state, ...action.data };
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            return Object.assign({}, initState, action.payload.agreementInfo.additionalSalesTerms);
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            return Object.assign({}, initState, action.lease.additionalSalesTerms);
        }

        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            if (action.result.data.lease.additionalSalesTerms) {
                return Object.assign({}, initState, action.result.data.lease.additionalSalesTerms);
            } else {
                return initState;
            }
        }

        default: {
            return state;
        }
    }
}
