import React from 'react';

import { useFlkBusinessMobileRedirect } from '../hooks/useFlkBusinessMobileRedirect';
import { useAccountProvider } from '../components/providers/AccountProvider.js';
import FlkLogo from '../../assets/images/logo.png';

import styles from './BusinessMobileLanding.module.scss';

const BusinessMobileLanding = () => {
    const accountType = useAccountProvider();
    useFlkBusinessMobileRedirect(accountType);

    return (
        <main className={styles.main}>
            <img src={FlkLogo} className={styles.logo} />
            <div className={styles.content}>
                <h1>For now, let's go desktop.</h1>
                <p>
                    Welcome to FLK it over! Right now, the best experience for creating and sending documents is on our
                    desktop platform.
                </p>
                <p>
                    No worries, though - signing documents on your mobile is smooth and easy, and we're excited to
                    announce that mobile document creation is coming soon!
                </p>
                <p>
                    Thank you for choosing FLK. We're here to make your document management as seamless as possible,
                    every step of the way.
                </p>
            </div>
        </main>
    );
};

export default BusinessMobileLanding;
