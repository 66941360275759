import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import axios from 'axios';
import { isEmpty } from 'lodash';

import EmailNotificationFields from './EmailNotificationFields';
import { UploadADoc } from '@app/types/UploadADoc';
import EditSenderConfirmation from '@app/components/modals/document/EditSenderConfirmation';
import { User } from '@app/types/User';
import { getAllPdfSenderDetails } from '../../../../utils/documentUtils.js';
import { useAccountProvider } from '../../../../components/providers/AccountProvider.js';
import { getCurrentDocumentAgentName } from '../../../../selectors/document/index.js';
import { getUserInfo } from '../../../../selectors/user.js';
import { CompletionStatus } from '@app/config';
import { ACKNOWLEDGE_CONFIRMATION_TYPE } from '@app/constants/constants';
import Button from '../../../../common/components/Button.js';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck.js';
import { openNoSignatureModal } from '../../../../actions/user.js';
import { getAgentSignature } from '../../../../selectors/completion/index.js';
import {
    ApiModel,
    EmailSendType,
    getInitialValues,
    projectApiModel,
    validationSchema
} from './AwaitingCompletionForm.model';
import useToast from '@app/hooks/useToast';
import { ToastTypes } from '@app/common/components/Toast';
import { hydrateValidationErrors } from '@app/utils/finalFormUtils';

import styles from './AwaitingCompletionForm.module.scss';

type AwaitingCompletionFormProps = {
    auditTrail: React.ReactNode;
    showAuditTrail: boolean;
    document: UploadADoc;
    completionProgress: React.ReactNode;
};

const AwaitingCompletionForm: React.FC<AwaitingCompletionFormProps> = ({
    showAuditTrail,
    auditTrail,
    document,
    completionProgress
}) => {
    const dispatch = useDispatch();
    const currentDocumentAgentName: string = useSelector(getCurrentDocumentAgentName);
    const [isChangeSenderConfirmationModalOpen, setIsChangeSenderConfirmationModalOpen] = React.useState(false);
    const accountType = useAccountProvider();
    const loggedInUser: User = useSelector(getUserInfo);
    const agentSignature = useSelector(getAgentSignature);
    const { addNewToast } = useToast();

    const senderDetailsOnPdf = React.useMemo(
        () => getAllPdfSenderDetails(document.uploadedDocuments),
        [document.uploadedDocuments]
    );
    const initialFormValues = React.useMemo(() => getInitialValues(document), [document]);

    const isDocumentCompleting = document.completion && document.completion?.status !== CompletionStatus.Error;

    const completeDocument = async (values: ApiModel, document: UploadADoc) => {
        if ((document.hasAgentSignatureAnnotations || values.signedByAgent) && isEmpty(agentSignature)) {
            dispatch(openNoSignatureModal());
            return;
        }
        try {
            axios.post(`api/document/${document.id}/custom-document-complete`, values);
        } catch (error) {
            addNewToast('Document completion failed', ToastTypes.ERROR);
            if (error?.response?.data?.errors) {
                return error.response.data.errors;
            }
        }
    };

    return (
        <Form
            initialValues={initialFormValues}
            onSubmit={values => {
                const errors = hydrateValidationErrors(() =>
                    validationSchema.validateSync(values, { abortEarly: false })
                );
                if (!isEmpty(errors)) {
                    return errors;
                }
                return completeDocument(projectApiModel(values, document.client?.clients ?? []), document);
            }}
            mutators={{ ...arrayMutators }}
        >
            {({ handleSubmit, values, form, submitting }) => {
                return (
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className="summary-item">
                            <h2>Email Finalised Documents to...</h2>
                            <EmailNotificationFields
                                emailSendType={values?.emailSendType ?? EmailSendType.EVERYONE}
                                recipients={document.client?.clients ?? []}
                                agentEmail={document.agent.email}
                                disabled={isDocumentCompleting || submitting}
                            />
                        </div>
                        {showAuditTrail && auditTrail}
                        <CheckboxCheck
                            name="signedByAgent"
                            label="Add my signature to the acknowledgement page"
                            disabled={isDocumentCompleting || submitting}
                        />
                        {!isDocumentCompleting && (
                            <Button
                                className={styles.completeButton}
                                primary
                                type="button"
                                loading={submitting}
                                onClick={() => {
                                    if (
                                        document.agent.id !== loggedInUser.id &&
                                        senderDetailsOnPdf.totalAnnotationCount > 0
                                    ) {
                                        setIsChangeSenderConfirmationModalOpen(true);
                                    } else {
                                        form.submit();
                                    }
                                }}
                            >
                                {document.hasAgentSignatureAnnotations ? 'Sign and Complete' : 'Complete'}
                            </Button>
                        )}
                        {completionProgress}
                        {loggedInUser.firstName && loggedInUser.fullName && (
                            <EditSenderConfirmation
                                isOpen={isChangeSenderConfirmationModalOpen}
                                close={() => setIsChangeSenderConfirmationModalOpen(false)}
                                primaryHandler={async () => {
                                    await form.submit();
                                    setIsChangeSenderConfirmationModalOpen(false);
                                }}
                                secondaryHandler={() => setIsChangeSenderConfirmationModalOpen(false)}
                                currentAgentName={currentDocumentAgentName}
                                assignedAgentName={senderDetailsOnPdf?.name}
                                accountType={accountType}
                                docStatus={document.status}
                                loggedUserInfo={{
                                    firstName: loggedInUser.firstName,
                                    fullName: loggedInUser.fullName
                                }}
                                isLoading={submitting}
                            />
                        )}
                    </form>
                );
            }}
        </Form>
    );
};

export default AwaitingCompletionForm;
