import React from 'react';
import { FormTextRegular } from '../../../components/form/FormText.js';
import Button from '../../../common/components/Button.js';
import styles from './AdditionalLinks.module.scss';
import Icon, { Icons } from '../../../common/components/Icon';
import { formatUrl } from '../../../config';

type AdditionalLink = {
    title: string;
    url: string;
};

type AdditionalLinksProps = {
    title: string;
    addLink: () => void;
    removeLink: (index: number) => void;
    initialLinks?: [AdditionalLink] | [];
    addButtonLabel: string;
    removeButtonLabel?: string;
    details: AdditionalLink;
    disabled?: boolean;
};
const AdditionalLinks: React.FC<AdditionalLinksProps> = ({
    title,
    initialLinks,
    addButtonLabel,
    removeButtonLabel,
    details,
    addLink,
    removeLink,
    disabled
}) => {
    const [links, setLinks] = React.useState<{ title: string; url: string }[]>(initialLinks || []);
    const add = () => {
        addLink();
        setLinks([...links, { title: details.title, url: details.url }]);
    };
    const remove = (index: number) => {
        const newLinks = [...links];
        newLinks.splice(index, 1);
        removeLink(index);
        setLinks(newLinks);
    };
    return (
        <div className={styles.additionalLinks}>
            <h4>{title}</h4>
            <div className={styles.form}>
                <FormTextRegular
                    containerClassName={styles.formItem}
                    name="additionalLinkTitle"
                    label="Title"
                    disabled={disabled}
                />
                <FormTextRegular
                    containerClassName={styles.formItem}
                    name="additionalLinkUrl"
                    label="URL"
                    disabled={disabled}
                />
            </div>
            <Button
                primary
                data-test="add-link-btn"
                className={styles.addLink}
                onClick={add}
                disabled={!details.title || !details.url}
            >
                {addButtonLabel}
            </Button>
            {links && links.length >= 1 && (
                <ul className={styles.links}>
                    {links.map((link, index) => (
                        <li key={index} className={styles.link}>
                            <div className={styles.details}>
                                <Icon icon={Icons.LINK} className={styles.linkIcon} />
                                <div>
                                    <div data-test={`link-title-${index}`}>{link.title}</div>
                                    <a
                                        data-test={`link-url-${index}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={formatUrl(link.url)}
                                    >
                                        {link.url}
                                    </a>
                                </div>
                            </div>
                            <Button
                                className={styles.removeLink}
                                onClick={() => remove(index)}
                                startIcon={<Icon icon={Icons.CLOSE} className={styles.closeIcon} />}
                                disabled={disabled}
                                data-test={`remove-link-btn-${index}`}
                            >
                                {removeButtonLabel}
                            </Button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
export default AdditionalLinks;
