import produce from 'immer';
import { pull } from 'lodash';

export const OPEN_HELP = 'OPEN_HELP';
export const CLOSE_HELP = 'CLOSE_HELP';

export const CHANGE_MOBILE_HELP = 'CHANGE_MOBILE_HELP';

const initState = {
    openedHelpList: []
};

export default function help(state = initState, action) {
    const newState = { ...state };
    switch (action.type) {
        case OPEN_HELP: {
            return produce(state, draftState => {
                draftState.openedHelpList.push(action.helpId);
            });
        }
        case CLOSE_HELP: {
            return produce(state, draftState => {
                pull(draftState.openedHelpList, action.helpId);
            });
        }
    }
    return newState;
}
