import React from 'react';

import styles from './DashboardV2.module.scss';

type DashboardV2Props = {
    header: React.ReactNode;
    body: React.ReactNode;
};

const DashboardV2: React.FC<DashboardV2Props> = ({ header, body }) => {
    return (
        <div className={styles.container}>
            <div>{header}</div>
            <div>{body}</div>
        </div>
    );
};

export default DashboardV2;
