import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

/**
 * Returns tariffPlan for agancyId param
 * @param agencyId
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetchTariffPlanRequest = agencyId => {
    return axios.get(`/api/agency/${agencyId}/tariff-plan`).then(res => res.data);
};

/**
 * Hook for retrieving tariffPlan for agancy
 * @param agencyId
 * @param onSuccess
 * @param onError
 * @returns {UseQueryResult<unknown, unknown>}
 */
export const useGetTariffPlan = (agencyId, onSuccess, onError) => {
    return useQuery(['tariffPlan', agencyId], () => fetchTariffPlanRequest(agencyId), {
        onSuccess,
        onError,
        enabled: agencyId !== undefined,
        staleTime: 0,
        select: data => {
            // Manipulate data received from server for your display needs
            return data;
        }
    });
};

/**
 * Sets the tariffPlan for agency based on the stripeId
 * @param agencyId
 * @param stripeId
 * @returns {Promise<AxiosResponse<any>>}
 */
const setTariffPlanRequest = (agencyId, stripeId) => {
    return axios.post(`/api/agency/${agencyId}/tariff-plan`, { stripeId: stripeId }).then(res => res.data.tariffPlan);
};

/**
 * Mutation hook to setTariffPlan
 * @param agencyId
 * @param onSuccess
 * @param onError
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */
export const useSetTariffPlan = (agencyId, onSuccess, onError) => {
    const queryClient = useQueryClient();
    return useMutation(({ stripeId }) => setTariffPlanRequest(agencyId, stripeId), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['tariffPlan', agencyId]);
            onSuccess();
        },
        onError: () => {
            onError();
        }
    });
};

/**
 * Delete tariffPlan for agency
 * @param agencyId
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteTariffPlanRequest = agencyId => {
    return axios.delete(`/api/agency/${agencyId}/tariff-plan`);
};

/**
 * REmoves the tariffPlan from the agency
 * @param agencyId
 * @param onSuccess
 * @param onError
 * @returns {UseMutationResult<AxiosResponse<*>, unknown, void, unknown>}
 */
export const useDeleteTariffPlan = (agencyId, onSuccess, onError) => {
    const queryClient = useQueryClient();
    return useMutation(({ agencyId }) => deleteTariffPlanRequest(agencyId), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['tariffPlan', agencyId]);
            onSuccess();
        },
        onError: () => {
            onError();
        }
    });
};

const updateTariffPlanRequest = (agencyId, data) => {
    return axios.patch(`/api/agency/${agencyId}/tariff-plan`, { data });
};

export const useUpdateTariffPlan = (agencyId, onSuccess, onError) => {
    const queryClient = useQueryClient();
    return useMutation(({ agencyId, data }) => updateTariffPlanRequest(agencyId, data), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['tariffPlan', agencyId]);
            onSuccess();
        },
        onError: () => {
            onError();
        }
    });
};
