import React from 'react';
import { SelectField } from '../../../../components/form/FormSelect';
import { FormTextRegular, FormTextCurrency } from '../../../../components/form/FormText';
import { rebatePeriods } from '../../../../config';

export const RebateGroup = ({ index, item, removeRebate, maxLength }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Rebate {index + 1}</h3>
                    <div className="toggle" onClick={() => removeRebate(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular
                        name={`rebateList[${index}].source`}
                        label="Third Party to whom the Agent will receive a rebate"
                        maxLength={maxLength}
                        required
                    />
                    <FormTextCurrency name={`rebateList[${index}].amount`} label="The amount of the rebate" required />
                    <div className="for-lg-modal FormInput button-select">
                        <SelectField
                            label="Frequency of rebate"
                            name={`rebateList[${index}].frequency`}
                            selectClassName={`rebateList-${index}-frequency`}
                            options={rebatePeriods}
                            required
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
