import React, { useEffect, useState } from 'react';
import { FormTextRegular } from '../../../../form/FormText';
import { FormEmail } from '../../../../form/FormEmail';
import { FormPhone } from '../../../../form/FormPhone';
import { FormMaskedText } from '../../../../form/FormText';
import { CheckboxCheck } from '../../../../form/FormCheckboxCheck';
import { corporationRolesList, LEASE_TYPE_SALES } from '../../../../../config';
import { FormButtonSelect } from '../../../../form/responsive/FormButtonSelect';
import { getLabel } from '../../../../../utils/labelUtils';
import { isLeaseLocationQLD } from '../../../../../utils/agreementUtils';

const SignatoryPersonGroup = ({ index, removePerson, vendor, handlePrimaryVendorSelect, form, location }) => {
    const [isCorporation, setIsCorporation] = useState(vendor.isCorporation);

    useEffect(() => {
        setIsCorporation(vendor.isCorporation);
    }, [vendor]);

    function clearPerson(index) {
        form.change(`signatories[${index}].phone`, '+61');
        form.change(`signatories[${index}].corporationName`, '');
        form.change(`signatories[${index}].corporationAddress`, '');
        form.change(`signatories[${index}].firstName`, '');
        form.change(`signatories[${index}].secondName`, '');
        form.change(`signatories[${index}].address`, '');
        form.change(`signatories[${index}].ABN`, '');
        form.change(`signatories[${index}].ACN`, '');
        form.change(`signatories[${index}].role`, '');
    }

    const setCorporation = (value, index) => {
        setIsCorporation(value);
        clearPerson(index);
    };

    let header;
    if (index === 0) {
        header = (
            <h3>
                {getLabel('tenant', location, LEASE_TYPE_SALES)} {index + 1}
            </h3>
        );
    } else {
        header = (
            <span className="removePerson">
                <h3>
                    {getLabel('tenant', location, LEASE_TYPE_SALES)} {index + 1}
                </h3>
                <button type="button" onClick={() => removePerson(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="person">
            {header}
            <div className="formBox">
                <div className="formBox-column">
                    <CheckboxCheck
                        name={`signatories[${index}].isCorporation`}
                        className={`corporation-${index}-check`}
                        label={`${getLabel('tenant', location, LEASE_TYPE_SALES)} is a corporation`}
                        value={`signatories[${index}].isCorporation`}
                        onClick={value => setCorporation(value, index)}
                    />
                    {isCorporation && (
                        <React.Fragment>
                            <FormTextRegular
                                name={`signatories[${index}].corporationName`}
                                label="Corporation name"
                                required
                            />
                            <FormTextRegular
                                name={`signatories[${index}].corporationAddress`}
                                label="Corporation address"
                                required
                            />
                            <p className="form-field-label">Person signing:</p>
                        </React.Fragment>
                    )}
                </div>
                <div className="nowrap fields-full-width-for-mobile">
                    <FormTextRegular name={`signatories[${index}].firstName`} label="First Name" required />
                    <FormTextRegular name={`signatories[${index}].middleName`} label="Middle Name" />
                </div>
                <div className="formBox-column">
                    <FormTextRegular name={`signatories[${index}].secondName`} label="Last Name" required />
                    {isCorporation && (
                        <React.Fragment>
                            <FormButtonSelect
                                name={`signatories[${index}].role`}
                                selectClassName={`vendor-${index}-role`}
                                dataTest={`vendor-${index}-role`}
                                options={corporationRolesList}
                                value={vendor.role}
                                label="Role of person signing"
                                isButtonGroup={true}
                            />
                            {vendor.role === 'Other' && (
                                <FormTextRegular name={`signatories[${index}].otherRole`} label="Role name" required />
                            )}
                        </React.Fragment>
                    )}
                    <FormMaskedText name={`signatories[${index}].ABN`} label="ABN" mask="11 111 111 111" />
                    {isCorporation && (
                        <FormMaskedText name={`signatories[${index}].ACN`} label="ACN" mask="111 111 111" />
                    )}
                    {isLeaseLocationQLD(location) && (
                        <FormTextRegular name={`signatories[${index}].phoneNumber`} label="Phone" />
                    )}
                    <FormPhone
                        name={`signatories[${index}].phone`}
                        label="Mobile"
                        required
                        className={`signatories-${index}-phone`}
                        dataTest={`signatories-${index}-phone`}
                    />
                    <FormEmail name={`signatories[${index}].email`} label="Email" required />
                    {!isCorporation && (
                        <FormTextRegular name={`signatories[${index}].address`} label="Address" required />
                    )}

                    <CheckboxCheck name={`signatories[${index}].isRegisteredForGST`} label="Registered for GST" />
                    <CheckboxCheck
                        name={`signatories[${index}].isPrimaryVendor`}
                        label={`Primary ${getLabel('tenantLower', location, LEASE_TYPE_SALES)}`}
                        onClick={value => handlePrimaryVendorSelect(index, value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default SignatoryPersonGroup;
