import React from 'react';
import '../../../sass/dashboardContentTable.scss';
import Disclosure from './tables/disclosure/Disclosure';
import * as dashboard from '../../../actions/dashboard';
import DisclosureSentForSigning from './tables/disclosure/DisclosureSentForSigning';

const DisclosureListings = ({ documentList, docType, agentSignature, docStatus }) => {
    let contentTable;
    switch (docStatus) {
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            contentTable = (
                <DisclosureSentForSigning
                    documentList={documentList}
                    docType={docType}
                    agentSignature={agentSignature}
                />
            );
            break;
        }

        default:
            contentTable = <Disclosure documentList={documentList} docType={docType} agentSignature={agentSignature} />;
    }

    return <div className="table-sort">{contentTable}</div>;
};

export default DisclosureListings;
