export const UPDATE_LEASE_RENT_AND_TERM_FORM = 'UPDATE_LEASE_RENT_AND_TERM_FORM';

const initState = {
    termNotLessThanQty: 1,
    termNotLessThanPeriod: 'weeks',
    termNotMoreThenQty: 1,
    termNotMoreThenPeriod: 'weeks',
    rent: '',
    payablePeriod: 'weekly'
};

export default function leaseRentAndTermReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_LEASE_RENT_AND_TERM_FORM: {
            return { ...state, ...action.data };
        }

        default: {
            return state;
        }
    }
}
