import axios from 'axios';
import {
    GET_AGENCY_FULL_TEAM_REQUEST,
    GET_AGENCY_FULL_TEAM_SUCCESS,
    GET_AGENCY_FULL_TEAM_FAIL
} from '../actions/types';

export function getAllTeamMembers() {
    return {
        types: [GET_AGENCY_FULL_TEAM_REQUEST, GET_AGENCY_FULL_TEAM_SUCCESS, GET_AGENCY_FULL_TEAM_FAIL],
        promise: axios.get('/api/agency/team')
    };
}
