import React, { memo, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { remove, cloneDeep, concat, isEmpty, shallowEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';

import {
    closeRentReductionForm,
    sendRentReductionAgreement,
    rentReductionCreateSuccess,
    rentReductionCreateRequest,
    completeReductionAgreementAction,
    resendReductionAgreement,
    refreshDocumentList
} from '../../../../actions/document';
import { clearAddressDetailsStore, openAddressDetailsModal } from '../../../../actions/addressDetails';
import { getSpecialConditionsTemplateList } from '../../../../actions/specialConditions';
import FormAddressDetailsAutocomplete from '../../../../components/form/FormAddressDetailsAutocomplete';
import RentReductionTenantPersonGroup from '../../../../components/document/RentReductionTenantPersonGroup';
import { FormTextRegular } from '../../../../components/form/FormText';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import ModalDialog from '../../../../common/components/ModalDialog';
import Button from '../../../../common/components/Button';

import '../../../../sass/noAddressDocumentModal.scss';
import { ConditionsGroup } from '../../../../components/lease/mainScreen/common/ConditionsGroup';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { SelectField } from '../../../../components/form/FormSelect';
import { DOCUMENT_RENT_REDUCTION, HIDE_MESSAGE_TIME } from '../../../../config';

import { getUserInfo } from '../../../../selectors/user';
import { getSelectedAddressEdited } from '../../../../selectors/lease/addresses';
import { getIsOpenRentReductionForm } from '../../../../selectors/document/rentReduction';
import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document';
import { getSpecialConditionsTemplateListForLeaseType } from '../../../../selectors/specialConditions';
import AgreementMainAuditTrail from '../../../../components/dashboard/InfoAgreementComponents/AgreementMainComponents/shared/AgreementMainAuditTrail';
import DocumentHeader from '../../DocumentHeader';
import {
    isDocumentEditMode,
    isDocumentReadOnlyMode,
    isDocumentStatusDraft,
    isDocumentStatusSentForSigning,
    isDocumentAwaitingCompletion,
    isDocumentComplete,
    isDocumentCreateMode
} from '../../../../utils/generalUtils';
import { openNoSignatureModal } from '../../../../actions/user';
import ButtonGroup from '../../../../common/components/ButtonGroup';
import { ToggleButton } from '../../../../components/form/FormToggleButton';

const RentReductionForm = () => {
    const dispatch = useDispatch();

    const isOpenRentReductionForm = useSelector(getIsOpenRentReductionForm);
    const specialConditionsTemplateList = useSelector(state =>
        getSpecialConditionsTemplateListForLeaseType(state, DOCUMENT_RENT_REDUCTION)
    );
    const selectedAddressEdited = useSelector(getSelectedAddressEdited);
    const loggedInUser = useSelector(getUserInfo);
    const addressDetails = useSelector(state => state.addressDetails);
    const currentDocument = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);
    const agentSignature = useSelector(state => state.completion.agentSignature, shallowEqual);

    const isCompletingRentReduction = useSelector(state => state.document.isCompletingRentReduction, shallowEqual);
    const isCompletingRentReductionFail = useSelector(
        state => state.document.isCompletingRentReductionFail,
        shallowEqual
    );

    const isResendingRentReduction = useSelector(state => state.document.isResendingRentReduction, shallowEqual);
    const isResendingRentReductionSuccess = useSelector(state => state.document.isResendingRentReduction, shallowEqual);
    const isResendingRentReductionFail = useSelector(
        state => state.document.isResendingRentReductionFail,
        shallowEqual
    );

    let [formData, setFormData] = useState({});
    let [isSubmitSuccessfull, setIsSubmitSuccessfull] = useState(false);
    let [isSubmitFail, setIsSubmitFail] = useState(false);
    let [isSendingRentReduction, setIsSendingRentReduction] = useState(false);

    useEffect(() => {
        if (currentDocument && isOpenRentReductionForm) {
            window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
        }
    }, [isOpenRentReductionForm]);

    useEffect(() => {
        if (currentDocument) {
            dispatch(getSpecialConditionsTemplateList());
        }
        setFormData(
            currentDocument
                ? {
                      ...currentDocument.rentReductionAgreement,
                      startDate:
                          currentDocument && currentDocument.rentReductionAgreement
                              ? parseISO(currentDocument.rentReductionAgreement.startDate)
                              : null,
                      agentName: currentDocument.agent.fullName,
                      disabled: isDocumentReadOnlyMode(documentEditMode),
                      clauseList:
                          currentDocument.rentReductionAgreement &&
                          currentDocument.rentReductionAgreement.conditions &&
                          currentDocument.rentReductionAgreement.conditions.clauseList
                              ? currentDocument.rentReductionAgreement.conditions.clauseList
                              : []
                  }
                : {
                      agentId: loggedInUser.id,
                      agentName: loggedInUser.fullName,
                      tenants: [{ fullName: '', email: '', phone: '', index: 0 }],
                      address: null,
                      clauseList: []
                  }
        );
    }, [isOpenRentReductionForm, loggedInUser]);

    useEffect(() => {
        if (selectedAddressEdited) {
            setFormData({
                ...formData,
                address: selectedAddressEdited
            });
        }
    }, [selectedAddressEdited]);

    const clearFormData = () => {
        setFormData({
            agentId: loggedInUser.id,
            agentName: loggedInUser.fullName,
            tenants: [{ fullName: '', email: '', phone: '+61', index: 0 }],
            address: null,
            clauseList: []
        });
    };

    const closeModal = () => {
        dispatch(closeRentReductionForm());
        clearFormData();
        setIsSubmitFail(false);
        setIsSubmitSuccessfull(false);
        window.history.pushState({}, '', `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`);
    };

    const handleSubmit = values => {
        if (currentDocument && isDocumentStatusDraft(currentDocument.status)) {
            values.documentId = currentDocument.id;
        }
        if (!isEmpty(agentSignature)) {
            setIsSendingRentReduction(true);
            setIsSubmitFail(false);
            dispatch(rentReductionCreateRequest());
            return sendRentReductionAgreement(values)
                .then(() => {
                    setIsSendingRentReduction(false);
                    setIsSubmitFail(false);
                    setIsSubmitSuccessfull(true);
                    dispatch(rentReductionCreateSuccess());
                    clearFormData();
                    dispatch(refreshDocumentList());
                    // clear success message after 5 seconds
                    setTimeout(() => {
                        setIsSubmitSuccessfull(false);
                    }, HIDE_MESSAGE_TIME);
                })
                .catch(error => {
                    setIsSendingRentReduction(false);
                    setIsSubmitSuccessfull(false);
                    setIsSubmitFail(true);
                    const response = error.response;
                    let rentReductionAgreementError = response.data.errors.rentReductionAgreement;
                    if (rentReductionAgreementError.conditions) {
                        rentReductionAgreementError.clauseList = rentReductionAgreementError.conditions.clauseList;
                    }
                    return rentReductionAgreementError;
                });
        } else {
            dispatch(openNoSignatureModal());
        }
    };

    const resend = docId => {
        dispatch(resendReductionAgreement(docId));
    };

    const checkSignatureAndCompleteReductionAgreement = docId => {
        if (!isEmpty(agentSignature)) {
            dispatch(completeReductionAgreementAction(docId));
        } else {
            dispatch(openNoSignatureModal());
        }
    };

    const renderSendForSigningButton = () => {
        return (
            <Button primary className="btn-terminate" type="submit" disabled={isSendingRentReduction}>
                {isSendingRentReduction && <span className="savestatus-spinner-await-completion" />}
                Send
            </Button>
        );
    };

    const renderCancelButton = () => {
        return (
            <Button secondary className="btn-terminate" type="button" onClick={() => closeModal()}>
                Cancel
            </Button>
        );
    };

    const renderResendForSigningButton = () => {
        return (
            <Button primary className="btn-terminate" type="button" onClick={() => resend(currentDocument.id)}>
                Resend
                {isResendingRentReduction && <span className="savestatus-spinner-await-completion" />}
                {isResendingRentReductionSuccess && (
                    <span className="savestatus-saved">
                        <span className="icon savestatus-icon" />
                    </span>
                )}
                {isResendingRentReductionFail && (
                    <span className="savestatus-failed">
                        <span className="icon savestatus-icon" />
                    </span>
                )}
            </Button>
        );
    };

    const renderSignAndCompleteButton = () => {
        return (
            <Button
                primary
                type="button"
                disabled={isCompletingRentReduction}
                onClick={() => checkSignatureAndCompleteReductionAgreement(currentDocument.id)}
            >
                Sign and complete
                {isCompletingRentReduction && <span className="savestatus-spinner-await-completion" />}
                {isCompletingRentReductionFail && (
                    <span className="savestatus-failed">
                        <span className="icon savestatus-icon" />
                    </span>
                )}
            </Button>
        );
    };

    const previewPdf = docId => {
        window.open(`/reader/document/rent-relief/${docId}`, '_blank');
    };

    const addTenantPerson = values => {
        let data = values;
        const index = data.tenants.length;
        if (data.tenants) {
            data.tenants.push({ fullName: '', email: '', phone: '', index: index });
        } else {
            data.tenants = [{ fullName: '', email: '', phone: '', index: 0 }];
        }
        setFormData({
            ...data
        });
    };
    const removeTenantPerson = (index, values) => {
        let data = values;
        remove(data.emailAddress, function(n) {
            return n !== data.tenants[index].email;
        });
        data.tenants = [...data.tenants.slice(0, index), ...data.tenants.slice(index + 1)];
        setFormData({
            ...data
        });
    };

    const handleFormChange = values => {
        if (values.address === formData.address) {
            setFormData({
                ...values
            });
        }
    };

    const handleAddressChange = (setValue, address) => {
        setValue('address', address);
    };
    const setAddressDetails = (address, details, values) => {
        let data = values;
        data.address = address;
        data.addressDetails = details;
        setFormData({
            ...data
        });
    };

    const addConditions = () => {
        let newClauseList = cloneDeep(formData.clauseList);
        newClauseList.push({
            clause: '',
            comment: ''
        });
        setFormData({
            ...formData,
            clauseList: newClauseList
        });
    };

    const removeConditions = (index, disabled) => {
        if (!disabled) {
            let newClauseList = cloneDeep(formData.clauseList);

            newClauseList = [...newClauseList.slice(0, index), ...newClauseList.slice(index + 1)];

            setFormData({
                ...formData,
                clauseList: newClauseList
            });
        }
    };

    const selectTemplate = templateObject => {
        const template = specialConditionsTemplateList[templateObject.value];

        let newClauseList = concat(formData.clauseList, template.clauseList);

        setFormData({
            ...formData,
            clauseList: newClauseList
        });
    };

    const templateOptions = specialConditionsTemplateList.map((item, index) => {
        return {
            value: index,
            label: item.name
        };
    });

    function getCreateButtons() {
        return (
            <React.Fragment>
                <div className="button-row single">
                    {isSubmitSuccessfull && (
                        <span className="savestatus-saved">
                            <span className="icon savestatus-icon" />
                            Rent relief agreement sent successfully
                        </span>
                    )}
                    {isSubmitFail && (
                        <span className="savestatus-failed">
                            <span className="icon savestatus-icon" />
                            Rent relief failed
                        </span>
                    )}
                </div>
                <div className="button-row">
                    <div className="button-footer-docs">{renderCancelButton()}</div>
                    <div className="button-footer-docs">{renderSendForSigningButton()}</div>
                </div>
            </React.Fragment>
        );
    }

    function getDraftButtons() {
        return (
            <React.Fragment>
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => previewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
                <div className="button-row single">
                    {isSubmitSuccessfull && (
                        <span className="savestatus-saved">
                            <span className="icon savestatus-icon" />
                            Rent relief agreement sent successfully
                        </span>
                    )}
                    {isSubmitFail && (
                        <span className="savestatus-failed">
                            <span className="icon savestatus-icon" />
                            Rent relief failed
                        </span>
                    )}
                </div>
                <div className="button-row single">
                    <div className="button-footer-docs">{renderSendForSigningButton()}</div>
                </div>
                <div className="footer-help-text">The agreement will be sent to the tenant(s) via SMS for signing</div>
            </React.Fragment>
        );
    }

    function getSentForSigningButtons() {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => previewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
                <div className="button-row single">
                    {isSubmitSuccessfull && (
                        <span className="savestatus-saved">
                            <span className="icon savestatus-icon" />
                            Rent relief agreement sent successfully
                        </span>
                    )}
                    {isSubmitFail && (
                        <span className="savestatus-failed">
                            <span className="icon savestatus-icon" />
                            Rent relief failed
                        </span>
                    )}
                </div>
                <div className="button-row single">
                    <div className="button-footer-docs">{renderResendForSigningButton()}</div>
                </div>
            </div>
        );
    }

    function getAwaitingCompletionButtons() {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => previewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
                <div className="button-row single">
                    {isSubmitSuccessfull && (
                        <span className="savestatus-saved">
                            <span className="icon savestatus-icon" />
                            Rent relief agreement sent successfully
                        </span>
                    )}
                    {isSubmitFail && (
                        <span className="savestatus-failed">
                            <span className="icon savestatus-icon" />
                            Rent relief failed
                        </span>
                    )}
                </div>
                <div className="button-row single">
                    <div className="button-footer-docs">{renderSignAndCompleteButton()}</div>
                </div>
                <div className="footer-help-text">
                    Once pressed, agreement will be emailed, completed, and moved to Complete
                </div>
            </div>
        );
    }

    function getCompletedButtons() {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => previewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
            </div>
        );
    }

    return (
        <React.Fragment>
            <ModalDialog
                title="Rent relief agreement"
                isOpen={isOpenRentReductionForm}
                closeModal={closeModal}
                shouldCloseOnOverlayClick={false}
                className="document-centre-modal document-no-address-modal-dialog tenant with-header no-data-header"
                hideCloseButton={isSendingRentReduction}
            >
                <Form initialValues={formData} onSubmit={handleSubmit}>
                    {({ handleSubmit, values, form }) => {
                        return (
                            <form noValidate onSubmit={values => handleSubmit(values)} id="rent-reduction-form">
                                <DocumentHeader
                                    parentSelector="rent-reduction-form"
                                    doc={currentDocument}
                                    isFlowDocument={true}
                                    hideData={true}
                                />
                                <FormSpy onChange={state => handleFormChange(state.values)} />
                                {isDocumentEditMode(documentEditMode) && isOpenRentReductionForm && (
                                    <div className="address-auto-complete-group">
                                        <FormAddressDetailsAutocomplete
                                            name="address"
                                            value={values.address}
                                            editAddressFormData={addressDetails.editAddressFormData}
                                            editedNewAddress={addressDetails.selectedAddressEdited}
                                            editMode={addressDetails.editMode}
                                            showAddressBelow={false}
                                            detailsConfirmed={addressDetails.detailsConfirmed}
                                            label={'Address'}
                                            onChange={address => handleAddressChange(form.change, address)}
                                            setAddressDetails={(address, details) =>
                                                setAddressDetails(address, details, values)
                                            }
                                            openAddressDetailsModal={data => dispatch(openAddressDetailsModal(data))}
                                            clearAddressDetailsStore={() => dispatch(clearAddressDetailsStore())}
                                        />
                                    </div>
                                )}
                                {isDocumentReadOnlyMode(documentEditMode) && (
                                    <FormTextRegular name={'address'} label="Address" disabled={values.disabled} />
                                )}
                                <FormTextRegular
                                    name={'landLordName'}
                                    label="Landlord"
                                    required
                                    disabled={values.disabled}
                                />
                                <h2>Tenant Details</h2>
                                <br />
                                <div>
                                    <div className="tenants">
                                        {formData &&
                                            formData.tenants.map((tenant, index) => (
                                                <RentReductionTenantPersonGroup
                                                    key={index}
                                                    index={index}
                                                    tenant={tenant}
                                                    removeTenant={index => removeTenantPerson(index, values)}
                                                    disabled={values.disabled}
                                                />
                                            ))}
                                        {isDocumentEditMode(documentEditMode) && (
                                            <div className="button">
                                                <button
                                                    className="add-tenant"
                                                    type="button"
                                                    onClick={() => addTenantPerson(values)}
                                                    disabled={values.disabled}
                                                >
                                                    Add tenant
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <FormTextRegular name={'agentName'} label="Agent" disabled />
                                <h2>Agreement details</h2>
                                <FormTextRegular
                                    name="currentRent"
                                    label="Current rent details"
                                    required
                                    disabled={values.disabled}
                                />
                                <FormTextRegular
                                    name="reduction"
                                    label="Rent credit amount"
                                    required
                                    disabled={values.disabled}
                                />
                                <FormTextRegular
                                    name="newRent"
                                    label="Rent due once credit applied"
                                    required
                                    disabled={values.disabled}
                                />
                                <div className="calendar">
                                    <DatePickerInAgencyTimeZone
                                        label="Start date:"
                                        name="startDate"
                                        selected={values.startDate}
                                        required
                                        disabled={values.disabled}
                                    />
                                </div>
                                <FormTextRegular
                                    name="term"
                                    label="Term of the agreement"
                                    required
                                    disabled={values.disabled}
                                />
                                {(!currentDocument || isDocumentStatusDraft(currentDocument.status)) && (
                                    <div>
                                        <h2>Terms & Conditions</h2>
                                        {!specialConditionsTemplateList.length && (
                                            <div className="add-signature-block">
                                                <p>You don’t have templates yet.</p>
                                            </div>
                                        )}
                                        {templateOptions && templateOptions.length > 0 && (
                                            <ToggleButton
                                                label={'Use template?'}
                                                name="useTemplate"
                                                className="use-template"
                                                value={true}
                                            />
                                        )}
                                    </div>
                                )}
                                {values.useTemplate && (
                                    <SelectField
                                        name="template"
                                        options={templateOptions}
                                        onChange={selectTemplate}
                                        selectClassName="sel-6"
                                    />
                                )}
                                <div className="terminate-lease rent-reduction-conditions">
                                    <div>
                                        {formData.clauseList &&
                                            formData.clauseList.map((item, index) => (
                                                <ConditionsGroup
                                                    index={index}
                                                    item={item}
                                                    key={`${item.index}-${index}`}
                                                    removeConditions={index => removeConditions(index, values.disabled)}
                                                    disabled={values.disabled}
                                                />
                                            ))}
                                        {(!currentDocument || isDocumentStatusDraft(currentDocument.status)) && (
                                            <div className="button add-item-button-container">
                                                <button type="button" className="add-clause" onClick={addConditions}>
                                                    Create a T&C
                                                </button>
                                                (use this to enter any terms of your agreement)
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <br />
                                {currentDocument &&
                                    currentDocument.auditTrail &&
                                    currentDocument.auditTrail.length > 0 && (
                                        <AgreementMainAuditTrail id={currentDocument.id} isDocument={true} />
                                    )}
                                <br />
                                {isDocumentCreateMode(documentEditMode) && getCreateButtons()}
                                {currentDocument && isDocumentStatusDraft(currentDocument.status) && getDraftButtons()}
                                {currentDocument &&
                                    isDocumentStatusSentForSigning(currentDocument.status) &&
                                    getSentForSigningButtons()}
                                {currentDocument &&
                                    isDocumentAwaitingCompletion(currentDocument.status) &&
                                    getAwaitingCompletionButtons()}
                                {currentDocument && isDocumentComplete(currentDocument.status) && getCompletedButtons()}
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        </React.Fragment>
    );
};
export default memo(RentReductionForm);
