import { useDispatch } from "react-redux";
import { addNewToast as  addNewToastAction } from '../actions/toast';
import { ToastTypes } from '../common/components/Toast.tsx';

const useToast = () => {
    const dispatch = useDispatch();
    const addNewToast = (message: string, type = ToastTypes.SUCCESS, closable = false, className = '') => {
        dispatch(addNewToastAction({ message, type, id: new Date(), closable, className }));
    };
    return {
        addNewToast
    };
};

export default useToast;
