import { ACCEPT_INVITE_SUCCESS, LOG_IN_SUCCESS, VERIFY_SUCCESS } from './login';

export const RETURN_TO_PREVIOUS_STEP = 'RETURN_TO_PREVIOUS_STEP';

export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_DETAILS_SUCCESS = 'CREATE_DETAILS_SUCCESS';
export const SELECT_PLAN = 'SELECT_PLAN';
export const SET_COUPON_INFORMATION = 'SET_COUPON_INFORMATION';

export const CREATE_LICENSE_REQUEST = 'CREATE_LICENSE_REQUEST';
export const CREATE_LICENSE_SUCCESS = 'CREATE_LICENSE_SUCCESS';
export const CREATE_LICENSE_FAILURE = 'CREATE_LICENSE_FAILURE';

export const CREATE_PLAN_REQUEST = 'CREATE_PLAN_REQUEST';
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';
export const CREATE_PLAN_FAILURE = 'CREATE_PLAN_FAILURE';

export const CHANGE_PLAN_SUCCESS = 'CHANGE_PLAN_SUCCESS';

export const GET_PLAN_REQUEST = 'GET_PLAN_REQUEST';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'GET_PLAN_FAILURE';
export const UNLOAD_PLAN_LIST = 'UNLOAD_PLAN_LIST';

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';

export const CREATE_LOGO_REQUEST = 'CREATE_LOGO_REQUEST';
export const CREATE_LOGO_SUCCESS = 'CREATE_LOGO_SUCCESS';
export const CREATE_LOGO_FAILURE = 'CREATE_LOGO_FAILURE';

export const SETTING_STEP_REQUEST = 'SETTING_STEP_REQUEST';
export const EMAIL_AUTH_SETTING_STEP = 'EMAIL_AUTH_SETTING_STEP';

export const UNREGISTERED_SUCCESS = 'UNREGISTERED_SUCCESS';

export const SET_PENDING = 'SET_PENDING';

const initState = {
    isRegistrationComplete: false,
    agencyId: '',
    //Token to authenticate user.
    accessToken: '',
    refreshToken: '',
    //Array of available tariff plan
    plans: [],
    //Selected tariff plan
    selectedPlan: {},
    // Plans that should not be shown but still can be selected from the URL
    hiddenPlans: [],
    //Auth step
    step: 1,
    // Error during user creation.
    errorCreateUser: false,
    // Error message
    errorMessage: '',
    // Pending status for spinner
    isPending: false,
    validationErrors: null,
    couponInfo: null
};

export default function authorization(state = initState, action) {
    let newState = Object.assign({}, state);
    let registrationDetails = {
        user: {},
        details: {},
        licence: {}
    };

    switch (action.type) {
        case RETURN_TO_PREVIOUS_STEP: {
            newState.isPending = false;
            newState.step = state.step - 1;
            break;
        }

        // Retrieve plans (effectively step 0)
        case GET_PLAN_SUCCESS: {
            newState.plans = action.result.data.tariffPlans.filter(plan => !plan.hidden);
            newState.hiddenPlans = action.result.data.tariffPlans.filter(plan => plan.hidden);
            newState.selectedPlan = action.result.data.tariffPlans[1];
            break;
        }
        case CHANGE_PLAN_SUCCESS: {
            newState.selectedPlan = action.data;
            break;
        }
        case CREATE_PLAN_REQUEST: {
            newState.isPending = true;
            break;
        }

        case SET_COUPON_INFORMATION: {
            newState.couponInfo = action.data;
            break;
        }

        // Step 1
        case SELECT_PLAN: {
            newState.selectedPlan = action.data;
            newState.step = 2;
            break;
        }

        // Step 2
        case CREATE_ACCOUNT_SUCCESS: {
            const { email, phone } = action.data.user;
            newState.isPending = false;
            newState.errorCreateUser = false;
            newState.agencyId = action.data.agency.id;
            registrationDetails.user = action.data.user;
            registrationDetails.details = {
                ...registrationDetails?.details,
                email,
                phone
            };
            newState.registrationDetails = registrationDetails;
            newState.step = 3;
            break;
        }

        // Step 3
        case CREATE_DETAILS_SUCCESS: {
            registrationDetails.details = action.data.agency.details;
            registrationDetails.details.isCreated = true;
            newState.isPending = false;
            newState.registrationDetails = registrationDetails;
            break;
        }
        // This reducer is also invoked in step 3
        case CREATE_PLAN_SUCCESS: {
            newState.isPending = false;
            newState.step = 4;
            newState.selectedPlan = action.result.data.agency.tariffPlan;
            newState.registrationDetails = {
                ...state.registrationDetails,
                user: action.result.data.user
            };
            newState.isRegistrationComplete = true;
            break;
        }
        case CREATE_PLAN_FAILURE: {
            newState.isPending = false;
            newState.step = 3;
            break;
        }

        case SET_PENDING: {
            newState.isPending = action.data;
            break;
        }

        case LOG_IN_SUCCESS: {
            const { step, user } = action.result.data;
            const agency = user.agency;
            newState.step = step;
            newState.rememberMe = action.rememberMe;
            if (agency) {
                let registrationDetails = { ...agency, user };
                if (!registrationDetails.details) {
                    const { email, phone } = user;
                    registrationDetails.details = {
                        email,
                        phone
                    };
                }
                newState.registrationDetails = registrationDetails;
                newState.agencyId = agency.id;
            }
            break;
        }

        case VERIFY_SUCCESS: {
            const { user, agency } = action.result;
            if (agency && user) {
                newState.registrationDetails = { ...agency, user };
                newState.agencyId = agency.id;
            }
            break;
        }

        case ACCEPT_INVITE_SUCCESS: {
            const { user, agency } = action.result;
            if (agency && user) {
                newState.registrationDetails = { ...agency, user };
                newState.agencyId = agency.id;
            }
            break;
        }

        case CREATE_LOGO_REQUEST: {
            newState.isPending = true;
            newState.errorMessage = '';
            break;
        }
        case CREATE_LOGO_SUCCESS: {
            newState.isPending = false;
            newState.errorMessage = '';
            newState.step = 5;
            break;
        }
        case CREATE_LOGO_FAILURE: {
            newState.isPending = false;
            newState.errorMessage = action.error.response.data.error;
            newState.step = 4;
            break;
        }

        case CREATE_PAYMENT_REQUEST: {
            newState.isPending = true;
            break;
        }
        case CREATE_PAYMENT_SUCCESS: {
            newState.isPending = false;
            newState.isRegistrationComplete = true;
            newState.errorMessage = '';
            newState.step = 1;
            break;
        }
        case CREATE_PAYMENT_FAILURE: {
            newState.isPending = false;
            newState.errorMessage = action.error.response.data;
            newState.step = 4;
            break;
        }

        case SETTING_STEP_REQUEST: {
            newState.step = action.result.data.step;
            newState.agencyId = action.result.data.agency.id;
            newState.isRegistrationComplete = false;
            newState.selectedPlan = action.result.data.agency.tariffPlan;
            break;
        }

        case EMAIL_AUTH_SETTING_STEP: {
            newState.step = action.data.step;
            newState.agencyId = action.data.agency.id;
            newState.isRegistrationComplete = false;
            newState.selectedPlan = action.data.agency.tariffPlan;
            break;
        }
        case UNREGISTERED_SUCCESS: {
            newState.isRegistrationComplete = false;
            break;
        }
        case UNLOAD_PLAN_LIST: {
            newState.plans = [];
            break;
        }
    }

    return newState;
}
