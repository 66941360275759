import React, { forwardRef, useState, useEffect, useImperativeHandle, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { SelectField } from '../../../components/form/FormSelect';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { FormTextRegular } from '../../../components/form/FormText';
import { getUserInfo } from '../../../selectors/user';
import { termPeriodsWithDays } from '../../../config';
import { calculateEndDate, getTermDisplay } from '../../../utils/dateUtils';
import '../../../sass/term.scss';
import { parseISO } from 'date-fns';
import { formSubmitFail, setDirtyStep, updateTermSuccess } from '../../../actions/lease';
import { getDirtyStep, getLeaseType, getLocation, getStep, getTerm } from '../../../selectors/lease';
import axios from 'axios';
import { has } from 'lodash';
import { getLabel } from '../../../utils/labelUtils';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
const TERM_FORM = 'termForm';

function Term(props, ref) {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getUserInfo);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const reduxTerm = useSelector(getTerm);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);

    const [term, setTerm] = useState({
        ...reduxTerm,
        startDate: reduxTerm.startDate ? new Date(reduxTerm.startDate) : null,
        endDate: reduxTerm.endDate ? new Date(reduxTerm.endDate) : null,
        currentLeaseExpireDate: reduxTerm.currentLeaseExpireDate ? new Date(reduxTerm.currentLeaseExpireDate) : null
    });

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(TERM_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    useEffect(() => {
        //We are adding a day here to end date because we take it off to calculate end date
        const { startDate, endDate, currentLeaseExpireDate } = reduxTerm;
        setTerm(currentValue => ({
            ...currentValue,
            startDate: typeof startDate == 'string' ? parseISO(startDate) : startDate,
            endDate: typeof endDate == 'string' ? parseISO(endDate) : endDate,
            currentLeaseExpireDate:
                typeof currentLeaseExpireDate == 'string' ? parseISO(currentLeaseExpireDate) : currentLeaseExpireDate
        }));
    }, [reduxTerm]);

    const updateTerm = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/term`, data);
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateTerm(values)
            .then(result => {
                dispatch(updateTermSuccess(result)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.term')) {
                    return error.response.data.errors.term;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="term">
            <Form onSubmit={submitForm} initialValues={term}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="agreement-select">
                                <FormTextRegular
                                    label={getLabel('termQty', location, leaseType)}
                                    name="qty"
                                    onChange={value => {
                                        values.qty = value.target.value;
                                        setTerm({
                                            ...values,
                                            qty: value.target.value,
                                            endDate: calculateEndDate(values)
                                        });
                                    }}
                                />
                                <SelectField
                                    addLabelSpacing
                                    name="period"
                                    options={termPeriodsWithDays}
                                    selectClassName="period"
                                    onChange={value => {
                                        values.period = value.value;
                                        setTerm({
                                            ...values,
                                            period: value.value,
                                            endDate: calculateEndDate(values)
                                        });
                                    }}
                                />
                            </div>
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label={getLabel('termStartDate', location, leaseType)}
                                    name="startDate"
                                    selected={term.startDate}
                                    onChange={value => {
                                        values.startDate = value;
                                        setTerm({
                                            ...values,
                                            startDate: value,
                                            endDate: calculateEndDate(values)
                                        });
                                    }}
                                    required={true}
                                />
                            </div>
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label={getLabel('termEndDate', location, leaseType)}
                                    name="endDate"
                                    selected={term.endDate}
                                    onChange={value => {
                                        setTerm({
                                            ...values,
                                            endDate: value
                                        });
                                    }}
                                    required={true}
                                />
                            </div>
                            {values.startDate && values.endDate && (
                                <p>
                                    The term of the agreement is{' '}
                                    {term.endDate &&
                                        getTermDisplay(values.period, values.startDate, values.endDate, loggedInUser)}
                                </p>
                            )}

                            <br />

                            <CheckboxCheck name="isThereAnOption" label="Is there an option?" />
                            {values.isThereAnOption && (
                                <>
                                    <p>For a further period of</p>
                                    <div className="agreement-select">
                                        <FormTextRegular
                                            label="A term of"
                                            name="furtherPeriodQty"
                                            onChange={value => {
                                                values.furtherPeriodQty = value.target.value;
                                                setTerm({
                                                    ...values,
                                                    furtherPeriodQty: value.target.value
                                                });
                                            }}
                                        />
                                        <SelectField
                                            addLabelSpacing
                                            name="furtherPeriodPeriod"
                                            options={termPeriodsWithDays}
                                            selectClassName="period"
                                            onChange={value => {
                                                values.furtherPeriodPeriod = value.value;
                                                setTerm({
                                                    ...values,
                                                    furtherPeriodPeriod: value.value
                                                });
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(Term));
