import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ChangeRequestItem from './ChangeRequestItem';
import '../../sass/changeRequestToolbar.scss';
import '../../sass/form-chekbox.scss';
import { REQUEST_TO_CHANGE_STATUS_RESOLVED } from 'config';

class ChangeRequestToolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showResolved: false
        };

        this.selectRequest = this.selectRequest.bind(this);
    }

    getSelected(request) {
        const { step } = this.props;
        const selected = request.leaseAgreementStep.part.indexOf(step?.part) !== -1;
        return selected;
    }

    selectRequest(changeRequest) {
        this.props.select(changeRequest);
    }

    getChangeRequestList() {
        const { showResolved } = this.state;
        const { changeRequestList } = this.props;

        if (true === showResolved) {
            return changeRequestList.filter(item => item.status === REQUEST_TO_CHANGE_STATUS_RESOLVED);
        }

        return changeRequestList;
    }

    showResolvedHandler() {
        this.setState({
            ...this.state,
            showResolved: !this.state.showResolved
        });
    }

    render() {
        const { showResolved } = this.state;

        return (
            <div className="requests-side-bar">
                <header>
                    <div className="change-request-title">Change request(s)</div>
                </header>
                <main>
                    {this.getChangeRequestList().map((item, index) => {
                        return (
                            <ChangeRequestItem
                                key={index}
                                changeRequest={item}
                                selected={this.getSelected(item)}
                                select={this.selectRequest}
                                resolveRequest={this.props.resolveRequest}
                                unResolveRequest={this.props.unResolveRequest}
                            />
                        );
                    })}
                </main>
                <footer>
                    <div
                        className={showResolved ? 'input-checkbox active' : 'input-checkbox'}
                        onClick={() => {
                            this.showResolvedHandler();
                        }}
                    >
                        <p>Show Resolved</p>
                    </div>
                </footer>
            </div>
        );
    }
}

ChangeRequestToolbar.propTypes = {
    changeRequestList: PropTypes.array,
    step: PropTypes.object
};
ChangeRequestToolbar.defaultProps = {
    changeRequestList: []
};

export default ChangeRequestToolbar;
