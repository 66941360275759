import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManagedIntegration from '../../../components/settings/account/ManagedIntegration';
import { getAgencyManagedIntegration, isFetchingAgencyInfo, getSyncIntegrations } from '../../../selectors/agency';
import { deleteAccountIntegration, syncAccountIntegration } from '../../../actions/account';
import { getUserInfo } from '../../../actions/user';

const mapStateToProps = state => ({
    agencyIntegration: getAgencyManagedIntegration(state),
    isFetching: isFetchingAgencyInfo(state),
    syncIntegrations: getSyncIntegrations(state),
    agency: state.user.agency
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUserInfo,
            deleteAccountIntegration,
            syncAccountIntegration
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ManagedIntegration);
