import { createSelector } from 'reselect';

export const getLogin = state => state.login;

export const getIsUserLoggedIn = createSelector([getLogin], login => login.isUserLoggedin);
export const getNeedMfaVerification = createSelector([getLogin], login => login.needMfaVerification);
export const getLoginRedirect = createSelector([getLogin], login => login.loginRedirect);
export const getPreviousAccountType = createSelector([getLogin], login => login.previousAccountType);
export const getLoggedInUser = createSelector([getLogin], login => login.user);
export const getIsPending = createSelector([getLogin], login => login.isPending);
export const getValidationError = createSelector([getLogin], login => login.validationError);
export const getRememberMe = createSelector([getLogin], login => login.rememberMe);
export const getDirectLeaseId = createSelector([getLogin], login => login.directLeaseId);
