import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SherlockIntegration from '../../../components/settings/account/SherlockIntegration';
import { getUser, isFetchingUserInfo } from '../../../selectors/user';
import { updateUserInfo, updateUserStore } from '../../../actions/user';
import React from 'react';

const mapStateToProps = state => ({
    isFetching: isFetchingUserInfo(state),
    user: state.user,
    sherlockModule: state.user.sherlockModule
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUser,
            updateUserInfo,
            updateUserStore
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SherlockIntegration);
