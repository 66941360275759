import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import '../../../sass/agentSignatures/buttons.scss';

class Button extends React.Component {
    render() {
        const { blue, className, onClick, rightIcon, disabled } = this.props;
        let options = {};
        if (this.props.disabled) {
            options.disabled = 'disabled';
        }

        return (
            <button
                className={cx('button-default', { blue: blue }, { gray: disabled }, className)}
                onClick={onClick}
                {...options}
            >
                {this.props.children}
                {!!rightIcon && <i className="icon-button" />}
            </button>
        );
    }
}

Button.propTypes = {
    blue: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default Button;
