import React from 'react';
import { getPmStepNo, getPmStepTitle } from '../../../../../../utils/agreementUtils';
import { formatCurrency } from '../../../../../../utils/formUtils';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';

export default function AgreementPmFee({ leaseAllInfo }) {
    const { pmFees, location } = leaseAllInfo;
    if (!pmFees) {
        return null;
    }
    const money = value => {
        return formatCurrency(value, true, '0.00');
    };
    return (
        <div>
            {!!pmFees && (
                <AgreementSection
                    title={getPmStepTitle(location, 'pmFees')}
                    step={getPmStepNo(location, 'pmFees')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <React.Fragment>
                            <p className="name">
                                Estimated Weekly Rent: <strong>${pmFees.estimatedWeeklyRent}</strong>
                            </p>
                            <p className="name">
                                Estimated Monthly Rent: <strong>${pmFees.estimatedMonthlyRent}</strong>
                            </p>
                            <p className="name">
                                Estimated Annual Rent: <strong>${pmFees.estimatedAnnualRent}</strong>
                            </p>
                            <br />
                            <p>
                                <i>All fees listed below are inclusive of GST</i>
                            </p>
                            <p className="name">
                                Leasing Fee (applicable to initial and subsequent new renters):{' '}
                                <strong>{pmFees.leasingFee || '0.00'}</strong>
                            </p>
                            <p className="name">
                                Dollar amount of estimated Leasing Fee:{' '}
                                <strong>${money(pmFees.estimatedLeasingFee)}</strong>
                            </p>
                            <p className="name">
                                Lease Renewal Fee (applicable to existing renters only):{' '}
                                <strong>{pmFees.leaseRenewalFee || '0.00'}</strong>
                            </p>
                            <p className="name">
                                Dollar amount of estimated Lease Renewal Fee:{' '}
                                <strong>${money(pmFees.estimatedLeaseRenewalFee)}</strong>
                            </p>
                            <p className="name">
                                Management Fee: <strong>{pmFees.managementFee || '0.00'}%</strong>
                            </p>
                            <p className="name">
                                Dollar amount of estimated Management Fee:{' '}
                                <strong>${pmFees.estimatedManagementFee || '0.00'}</strong>
                            </p>
                            <p className="name">
                                Estimated management Fee <strong>${pmFees.estimatedManagementFee || '0.00'}</strong> per
                                week
                            </p>
                        </React.Fragment>
                        {pmFees.additionalFeesList.map((fee, index) => {
                            return (
                                <div key={index}>
                                    <p className="name">
                                        {fee.description}: <strong>{fee.amount}</strong>{' '}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </AgreementSection>
            )}
        </div>
    );
}
