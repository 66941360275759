import React from 'react';
import { FormEmail } from './../../../form/FormEmail';
import { FormPhone } from './../../../form/FormPhone';
import { isEmpty } from 'lodash';

const EditVendorPersonGroup = ({ index, vendor }) => {
    const { firstName, secondName, steps } = vendor;
    let isEditable = false;
    let blockingMessage = 'Loading data...';
    if (steps) {
        isEditable = true;
        // if steps is empty object that means vendor haven't opened link yet.
        // so agent can edit vendor details
        if (!isEmpty(steps)) {
            if (steps.steps && steps.steps[1] && steps.steps[1].status === 'confirmed') {
                isEditable = false;
                blockingMessage = 'The vendor has already confirmed mobile number and email address.';
            } else if (steps.step_1 && steps.step_1.status === 'confirmed') {
                isEditable = false;
                blockingMessage = 'The vendor has already confirmed mobile number and email address.';
            }
        }
    }
    let header = <h3>{`${firstName} ${secondName}`}</h3>;
    return (
        <div className={`tenant ${isEditable ? '' : 'disabled-tenant'}`}>
            {header}
            <div className="formBox">
                {!isEditable && (
                    <p className="FormInput already-signed">
                        <span className="FormError">{blockingMessage}</span>
                    </p>
                )}
                <div className="formBox-column">
                    <FormPhone
                        className={`signatories-${index}-mobile`}
                        name={`signatories[${index}].phone`}
                        label="Mobile"
                    />
                    <FormEmail name={`signatories[${index}].email`} label="Email" />
                </div>
            </div>
        </div>
    );
};

export default EditVendorPersonGroup;
