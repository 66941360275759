import { Tag, TagBorderRadius, TagSize } from '../../common/components/tag/Tag';
import React, { memo } from 'react';
import Icon, { Icons } from '../../common/components/Icon';
import styles from './Suggestion.module.scss';
import cx from 'classnames';

type SuggestionProps = {
    onClick: () => void;
    onClickEndIcon: (e?: any) => void;
    text: string;
    icon: Icons;
    className?: string;
};

const Suggestion: React.FC<SuggestionProps> = ({ text, onClick, onClickEndIcon, icon, className }) => {
    return (
        <Tag
            className={cx(styles.suggestionTag, className)}
            textClassName={styles.suggestionTagText}
            borderRadius={TagBorderRadius.Square}
            text={text}
            size={TagSize.Small}
            startIcon={<Icon icon={icon} className={styles.propertyMeIcon} />}
            endIcon={<Icon icon={Icons.CLOSE} className={styles.closeIcon} />}
            onClick={onClick}
            onClickEndIcon={onClickEndIcon}
        />
    );
};

export default memo(Suggestion);
