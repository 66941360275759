import React, { memo, useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentReports } from '../../../actions/statistics';

import AgentReportsTable from './components/AgentReportsTable';

import '../../../sass/agent-reports.scss';

import { getAgentReportsData, getIsLoadingAgentReports } from '../../../selectors/statistics';
import { PreLoader } from '../../../common/components/PreLoader';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { Form } from 'react-final-form';
import Button from '../../../common/components/Button';
import { startOfMonth, lastDayOfMonth, format, parseISO } from 'date-fns';
import QuickFilters from './components/QuickFilters';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { STANDARD_DATE_AMERICAN_FORMAT } from '../../../config';
import { getDownloadAgentReportCsvURL } from '../../../api/agentReportsApi';

const AgentReports = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let queryParams = queryString.parse(location.search);

    const agentReports = useSelector(getAgentReportsData);
    const isLoadingAgentReports = useSelector(getIsLoadingAgentReports);

    const [startDate, setStartDate] = useState(
        queryParams.startDate ? parseISO(queryParams.startDate) : startOfMonth(new Date())
    );
    const [endDate, setEndDate] = useState(
        queryParams.endDate ? parseISO(queryParams.endDate) : lastDayOfMonth(new Date())
    );

    useEffect(() => {
        dispatch(getAgentReports({ startDate, endDate }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const submitForm = values => {
        updateFiltersAndGetReports(values);
    };
    /**
     * Update the form and execute search
     * @param values
     */
    const updateFiltersAndGetReports = values => {
        setStartDate(values.startDate);
        setEndDate(values.endDate);

        const queryParams = {
            startDate: format(values.startDate, STANDARD_DATE_AMERICAN_FORMAT),
            endDate: format(values.endDate, STANDARD_DATE_AMERICAN_FORMAT)
        };
        const stringified = queryString.stringify(queryParams);
        history.push({
            search: stringified
        });
        dispatch(getAgentReports(values));
    };

    return (
        <div className="settings">
            <div className="agent-reports-page">
                <h1>Agency reports</h1>
                <Form initialValues={{ startDate, endDate }} onSubmit={submitForm} className={'filter-agent-reports'}>
                    {({ handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="filter-form">
                                    <DatePickerInAgencyTimeZone
                                        label="From"
                                        name="startDate"
                                        selected={values.startDate}
                                        className={'start-date'}
                                    />
                                    <DatePickerInAgencyTimeZone
                                        label="To"
                                        name="endDate"
                                        selected={values.endDate}
                                        className={'end-date'}
                                    />

                                    <Button
                                        type="submit"
                                        primary
                                        disabled={isLoadingAgentReports}
                                        className="agent-report-button"
                                    >
                                        Generate
                                    </Button>
                                    <Button
                                        primary
                                        disabled={isLoadingAgentReports}
                                        href={getDownloadAgentReportCsvURL({
                                            startDate: format(startDate, STANDARD_DATE_AMERICAN_FORMAT),
                                            endDate: format(endDate, STANDARD_DATE_AMERICAN_FORMAT)
                                        })}
                                        rel="noopener noreferrer"
                                        className={cx('export-csv-btn', 'agent-report-button')}
                                    >
                                        Export CSV
                                    </Button>
                                </div>

                                <QuickFilters
                                    updateFunction={updateFiltersAndGetReports}
                                    disabled={isLoadingAgentReports}
                                />
                                {isLoadingAgentReports && <PreLoader />}
                                {isLoadingAgentReports === false && agentReports && agentReports.length > 0 && (
                                    <>
                                        <AgentReportsTable agentReports={agentReports} filters={values} />
                                    </>
                                )}
                                {isLoadingAgentReports === false && agentReports && agentReports.length === 0 && (
                                    <p>No activity for this period</p>
                                )}
                            </form>
                        );
                    }}
                </Form>
            </div>
        </div>
    );
};

export default memo(AgentReports);
