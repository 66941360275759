import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import axios from 'axios';
import Button from '../../../../common/components/Button';
import { FormTextRegular } from '../../../../components/form/FormText';
import { Form } from 'react-final-form';
import { AsyncSelect } from '../../../../components/form/AsyncSelect';
import { customSelectStyle } from '../../../../utils/formUtils';
import logger from '../../../../utils/logger';
import { FLK_A_PDF_TEMPLATE, FLK_A_PDF_GLOBAL_TEMPLATE, DOCUMENT_CUSTOM, FLK_TEMPLATE_LABEL } from '../../../../config';
import { getGeneralAppLabel } from '../../../../utils/labelUtils';
import { cloneDeep, has, map, debounce } from 'lodash';
import DocumentUpload from '../../../shared/DocumentUpload';
import { confirmAlert } from 'react-confirm-alert';
import { addOrReplaceDocumentInList, setCurrentDocument } from '../../../../actions/document';
import { getTemplates } from '../../../../actions/template';
import { useDispatch, useSelector } from 'react-redux';
import { LEASE_STATUS_DRAFT } from '../../../../actions/dashboard';
import { getFiles, getIsGlobalTemplate } from '../../../../selectors/document';
import { useRollbarProvider } from '../../../../components/providers/RollbarProvider';
import { CLONE_TEMPLATE, SEARCH_RESULT_DOCUMENT, SEARCH_RESULT_TEMPLATE } from '../../../../constants/constants';
import { useMutation } from '@tanstack/react-query';
import { isAgencyUserAccount } from '../../../../utils/userUtils';
import IntegrationAddressSection from './IntegrationAddressSection';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { UPLOAD_A_DOC_INTEGRATION } from '../../../../constants/featureFlags';

import styles from './FlkAPdfCreate.module.scss';

const FLK_A_PDF_TEMPLATE_API = 'api/template/flk-a-pdf';
const FLK_A_PDF_TEMPLATE_API_WITH_FILES = 'api/template/create-custom-doc-with-files';
const FLK_A_PDF_CREATE_API = 'api/document/create-custom-document';
const FLK_A_PDF_CREATE_API_WITH_FILES = 'api/document/create-custom-document-with-files';

const FlkAPdfCreate = ({
    closeModal,
    creatingDocument,
    isTemplate,
    clonedSelectedTemplate,
    loggedInUser,
    setCreatingDocument,
    setCustomDocumentId,
    setDocumentCreated,
    isMobileView
}) => {
    const dispatch = useDispatch();
    const Rollbar = useRollbarProvider();
    const isUploadADocIntegrationEnabled = useFeatureFlag(UPLOAD_A_DOC_INTEGRATION);

    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateError, setTemplateError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [formData, setFormData] = useState({});
    const [documentsList, setDocumentsList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
    const filesFromDashboard = useSelector(getFiles);
    const isGlobalTemplate = useSelector(getIsGlobalTemplate);
    const [isLoadingIntegration, setIsLoadingIntegration] = useState(false);
    const [integrationError, setIntegrationError] = useState();

    const [showIntegrationSearch, setShowIntegrationSearch] = useState(false);

    useEffect(() => {
        updateDocumentsList(filesFromDashboard);
    }, [filesFromDashboard]);

    const canStart = values => {
        return !(!values.documentTitle || (isTemplate && !values.templateName));
    };

    const noOptionsMessage = props => {
        return (
            <div className="no-option-message" {...props}>
                {searchQuery
                    ? searchQuery.length < 3
                        ? 'Your search is too short, keep typing'
                        : 'No result found for your input'
                    : 'Please type to search templates and agreements'}
            </div>
        );
    };

    // load options using API call
    const loadOptions = (inputValue, callback) => {
        setSearchQuery(inputValue);
        // we need to load all templates in default. Also need 3 characters to start search
        if (!inputValue || (inputValue && inputValue.length > 2)) {
            const agencyTemplatesLabel = getGeneralAppLabel('agency_templates', loggedInUser.accountType);
            return axios
                .post(`/api/template/search-document-templates-and-documents`, {
                    templateTypesList: [FLK_A_PDF_TEMPLATE, FLK_A_PDF_GLOBAL_TEMPLATE],
                    documentTypesList: [isTemplate ? DOCUMENT_CUSTOM : ''],
                    searchQuery: inputValue,
                    isSendableFromMobile: isMobileView
                })
                .then(response => {
                    const templates = response.data.templates ? response.data.templates : [];
                    const documents = response.data.documents ? response.data.documents : [];

                    const newAvailableTemplates = [...templates, ...documents];

                    setAvailableTemplates(newAvailableTemplates);
                    const options = [
                        {
                            label: agencyTemplatesLabel,
                            options: []
                        },
                        {
                            label: FLK_TEMPLATE_LABEL,
                            options: []
                        },
                        {
                            label: 'Previous Documents',
                            options: []
                        }
                    ];
                    templates.forEach(template => {
                        if (template.docType === FLK_A_PDF_TEMPLATE) {
                            options[0].options.push({
                                label: template.templateName,
                                value: template.id,
                                type: SEARCH_RESULT_TEMPLATE
                            });
                        } else if (template.docType === FLK_A_PDF_GLOBAL_TEMPLATE) {
                            options[1].options.push({
                                label: template.templateName,
                                value: template.id,
                                type: SEARCH_RESULT_TEMPLATE
                            });
                        }
                    });
                    documents.forEach(document => {
                        options[2].options.push({
                            label: document.documentTitle,
                            value: document.id,
                            type: SEARCH_RESULT_DOCUMENT
                        });
                    });
                    return callback(options);
                })
                .catch(error => {
                    logger.push({
                        status: 'error',
                        env: ENVIRONMENT,
                        message: 'Something went wrong getting templates',
                        error_message: error.message,
                        errorObj: error
                    });
                });
        }
        return callback([]);
    };

    const loadOptionsDebounced = debounce(loadOptions, 500);

    const getTemplate = useMutation(
        data => {
            setTemplateError('');
            if (data.selectedTemplate?.type === SEARCH_RESULT_DOCUMENT) {
                return axios.get(`api/document/${data.selectedTemplate.value}`);
            } else if (data.selectedTemplate?.type === SEARCH_RESULT_TEMPLATE) {
                return axios.get(`api/template/${data.selectedTemplate.value}`);
            }
            return null;
        },
        {
            onSuccess: (res, data) => {
                const template = res.data.lease || res.data.doc;
                if (template) {
                    if (isMobileView && !template.isSendableFromMobile) {
                        setTemplateError(
                            'This template is not ready to be sent from the mobile experience. Please select another template.'
                        );
                    }
                    template.isCreatedFromTemplate = true;
                    template.status = LEASE_STATUS_DRAFT;
                    template.docType = FLK_A_PDF_TEMPLATE;
                    if (isTemplate && template.client && template.client.clients) {
                        template.client.clients.forEach(client => {
                            client.signature = undefined;
                            client.tempSignature = undefined;
                            client.acknowledgedDate = undefined;
                        });
                    }
                    dispatch(setCurrentDocument(template));
                    setSelectedTemplate(data.selectedTemplate);
                    const newFormData = {
                        ...data.values,
                        documentTitle: template.documentTitle
                    };
                    setFormData(newFormData);
                } else {
                    setSelectedTemplate(null);
                }
            },
            onError: () => {
                setSelectedTemplate(null);
            }
        }
    );

    const changeTemplate = (selectedTemplate, values) => {
        if (selectedTemplate) {
            getTemplate.mutate({ selectedTemplate, values });
        } else {
            setSelectedTemplate(null);
        }
    };

    const updateDocumentsList = function (files) {
        let data;
        if (clonedSelectedTemplate) {
            data = {
                documentTitle: clonedSelectedTemplate.documentTitle
            };
        } else {
            data = {
                documentTitle: files && files[0] ? files[0].documentName.replace('.pdf', '') : ''
            };
        }
        setSelectedTemplate(clonedSelectedTemplate ? clonedSelectedTemplate : null);
        setFormData(data);
        setDocumentsList(files);
    };

    const createFlkAPdf = values => {
        if (showIntegrationSearch && !values.integrationPropertyId) {
            setIntegrationError('Please select a valid address');
            return;
        }
        setUploading(true);
        setCreatingDocument(true);

        let url;
        let data = new FormData();
        if (isTemplate && documentsList.length > 0) {
            url = FLK_A_PDF_TEMPLATE_API_WITH_FILES;
            data.append('templateName', values.templateName);
        } else if (isTemplate) {
            url = FLK_A_PDF_TEMPLATE_API;
        } else if (selectedTemplate) {
            url = FLK_A_PDF_CREATE_API;
        } else {
            url = FLK_A_PDF_CREATE_API_WITH_FILES;
        }
        data.append('isGlobalTemplate', isGlobalTemplate);
        if (values.integrationPropertyId) {
            data.append('integrationPropertyId', values.integrationPropertyId);
        }
        if (documentsList.length > 0 && !selectedTemplate) {
            data.append('documentTitle', values.documentTitle);
            if (selectedTemplate) {
                data.append('selectedTemplate', selectedTemplate.value);
            }

            if (values.address) {
                data.append('address', values.address);
            }

            map(documentsList, (value, key) => {
                data.append(`documents[${key}][documentName]`, value.documentName);
                data.append(`documents[${key}][file]`, value.file);

                if (has(value, 'fileName')) {
                    data.append(`documents[${key}][fileName]`, value.fileName);
                }

                if (value.file.size) {
                    data.append(`documents[${key}][size]`, value.file.size);
                }
            });
        } else {
            data = cloneDeep(values);
            data.isGlobalTemplate = isGlobalTemplate;
            data.documents = documentsList;
            if (selectedTemplate?.type === SEARCH_RESULT_TEMPLATE || selectedTemplate?.type === CLONE_TEMPLATE) {
                data.selectedTemplate = selectedTemplate?.value;
            } else if (selectedTemplate?.type === SEARCH_RESULT_DOCUMENT) {
                data.selectedDocument = selectedTemplate?.value;
            }
        }
        return axios
            .post(url, data, {
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    setDocumentUploadProgress(Math.floor((loaded * 100) / total));
                }
            })
            .then(response => {
                setUploading(false);

                const flkAPdfDocument = response.data?.customDocument || response.data.flkAPdfTemplate;

                setDocumentCreated(true);
                setCustomDocumentId(flkAPdfDocument.id);

                dispatch(setCurrentDocument(flkAPdfDocument));
                if (isTemplate) {
                    dispatch(getTemplates(isGlobalTemplate ? FLK_A_PDF_GLOBAL_TEMPLATE : FLK_A_PDF_TEMPLATE));
                } else {
                    dispatch(addOrReplaceDocumentInList(flkAPdfDocument));
                }
            })
            .catch(error => {
                Rollbar.error(
                    'Error saving documents',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
                setUploading(false);
                confirmAlert({
                    title: '',
                    message: `Failed to save documents, please try again, if this does not resolve the issue please contact FLK`,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
                const response = error.response;
                if (response && response.data && response.data.errors) {
                    return response.data.errors;
                }
                return false;
            })
            .finally(() => {
                setCreatingDocument(false);
            });
    };

    const handleSubmit = values => {
        if (isMobileView && !selectedTemplate) {
            setTemplateError('Please select a template');
            return;
        }
        const data = cloneDeep(values);

        // add documents to values
        data.documents = documentsList;
        setDocumentUploadProgress(0);

        return createFlkAPdf(data);
    };

    return (
        <div className={cx('location-address-details', styles.container)}>
            <Form initialValues={formData} onSubmit={handleSubmit}>
                {({ handleSubmit, values }) => {
                    // set the values to the ref so that the close modal can access these values
                    return (
                        <form noValidate onSubmit={values => handleSubmit(values)} id="create-custom-doc-form">
                            <div className="template-selection">
                                {((!isTemplate && !selectedTemplate) || (isTemplate && !selectedTemplate)) &&
                                    !isMobileView && (
                                        <div className="document-upload-container">
                                            <DocumentUpload
                                                // happens on the server, so is not needed here
                                                flattenPdfs={false}
                                                leaseType={DOCUMENT_CUSTOM}
                                                rejectEncryptedPdfs
                                                hideFileCategory
                                                hideAttachedLabel
                                                uploading={uploading}
                                                additionalOptionsForDocument={{ leaseType: DOCUMENT_CUSTOM }}
                                                setDocumentUploadProgress={setDocumentUploadProgress}
                                                updateDocuments={files => {
                                                    updateDocumentsList(files);
                                                }}
                                                documents={documentsList}
                                                documentUploadProgress={documentUploadProgress}
                                                maxFileSize={100}
                                                maxTotalSize={100}
                                            />
                                        </div>
                                    )}
                                {isTemplate && (
                                    <FormTextRegular
                                        name="templateName"
                                        label="Template Name"
                                        disabled={values.disabled}
                                        required
                                    />
                                )}
                                <div className={styles.reverseForMobile}>
                                    <FormTextRegular
                                        name="documentTitle"
                                        label="Document title"
                                        disabled={values.disabled}
                                    />
                                    <div className={styles.templateInputContainer}>
                                        {documentsList.length === 0 && (
                                            <AsyncSelect
                                                defaultOptions
                                                isClearable={true}
                                                value={selectedTemplate}
                                                styles={customSelectStyle}
                                                loadOptions={loadOptionsDebounced}
                                                onChange={selected => changeTemplate(selected, values)}
                                                selectClassName="async-select"
                                                placeholder={`Select Template...${isMobileView ? '*' : ''}`}
                                                components={{
                                                    NoOptionsMessage: noOptionsMessage
                                                }}
                                            />
                                        )}
                                        {templateError && <div className={styles.error}>{templateError}</div>}
                                    </div>
                                </div>

                                {isAgencyUserAccount(loggedInUser.accountType) &&
                                    isUploadADocIntegrationEnabled &&
                                    !isMobileView && (
                                        <IntegrationAddressSection
                                            showIntegrationSearch={showIntegrationSearch}
                                            setShowIntegrationSearch={setShowIntegrationSearch}
                                            setIsLoadingIntegration={setIsLoadingIntegration}
                                            loggedInUser={loggedInUser}
                                            integrationError={integrationError}
                                            setIntegrationError={setIntegrationError}
                                        />
                                    )}
                                <div className="footer footer-draft">
                                    <div className="button-row">
                                        <div className="button-cancel">
                                            <Button secondary className="btn-close" height={40} onClick={closeModal}>
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="button-start">
                                            <Button
                                                primary
                                                className="btn-start"
                                                height={40}
                                                type="submit"
                                                disabled={
                                                    !canStart(values) || creatingDocument || getTemplate.isLoading
                                                }
                                                loading={
                                                    creatingDocument || getTemplate.isLoading || isLoadingIntegration
                                                }
                                            >
                                                Start
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};
export default FlkAPdfCreate;
