import { createSelector } from 'reselect';
export const getCompletion = state => state.completion;

export const getWitness = createSelector([getCompletion], completion => completion.witnesses);
export const getWitnessSignature = createSelector([getCompletion], completion => completion.witnessSignature);
export const getWitnessSignatureError = createSelector([getCompletion], completion => completion.witnessSignatureError);
export const getIsGettingWitnessSignature = createSelector(
    [getCompletion],
    completion => completion.isGettingWitnessSignature
);
export const getIsGettingWitnessSignatureSuccess = createSelector(
    [getCompletion],
    completion => completion.isGettingWitnessSignatureSuccess
);
export const getAgentSignature = createSelector([getCompletion], completion => completion.agentSignature);
export const getIsGettingAgentSignature = createSelector(
    [getCompletion],
    completion => completion.isGettingAgentSignature
);

export const getCompletionErrors = createSelector([getCompletion], completion => completion.completionErrors);
export const getCompletionFormErrors = createSelector([getCompletion], completion => completion.completionFormErrors);
export const getCompletionForm = createSelector([getCompletion], completion => completion.form);
