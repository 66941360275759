import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';

import ModalDialog from '../../common/components/ModalDialog';
import Button from '../../common/components/Button';
import { createEspLetterSuccess } from '../../actions/lease';

import '../../sass/espLetterFormModal.scss';
import { Form } from 'react-final-form';
import { FormTextRegular, FormTextMultiline } from '../../components/form/FormText';
import { FormRadioGroup } from '../../components/form/FormRadioGroup';
import DatePickerInAgencyTimeZone from '../../components/form/DatePickerInAgencyTimeZone';
import DocumentUpload from '../../containers/shared/DocumentUpload';
import { formatDocumentsForApi } from '../../utils/formUtils';
import Links from '../../containers/shared/Links';

import {
    rentPeriods as period,
    DELIVERY_TYPE_EMAIL,
    DELIVERY_TO_AGENT,
    DELIVERY_TYPE_HAND,
    HIDE_MESSAGE_TIME
} from '../../config';
import DeliveryTypeComponent from '../../containers/dashboard/shared/DeliveryTypeComponent';
const REASON_STATIC_TEXT =
    'The original ESP on the Agency Agreement is no longer a reasonable estimate of the likely selling price of the Property because: ';

const EspLetterModal = ({ isOpen, closeEspLetterModal, agreementInfo, loggedInUser }) => {
    const dispatch = useDispatch();
    let [formData, setFormData] = useState({
        agentName: agreementInfo.agent.fullName,
        venderNames: agreementInfo.signatory ? agreementInfo.signatory.signatoryListText : '',
        address: agreementInfo.address,
        agreementDate: new Date(agreementInfo.term?.startDate),
        revisedEsp: '',
        reason: '',
        evidence: 'agent_provided',
        authorisedPerson: agreementInfo.signatory ? agreementInfo.signatory.signatoryListText : '',
        dateOfService: new Date(),
        deliveryType: DELIVERY_TYPE_HAND,
        emailTo: DELIVERY_TO_AGENT,
        nameOfpersonServing: loggedInUser.fullName,
        ccEmailAddresses: [loggedInUser.email],
        emailAddresses: agreementInfo.signatory ? agreementInfo.signatory.signatories.map(vendor => vendor.email) : []
    });
    const [isSendingEspLetter, setIsSendingEspLetter] = useState(false);
    const [isSendingEspLetterSuccess, setIsSendingEspLetterSuccess] = useState(false);
    const [isSendingEspLetterFail, setIsSendingEspLetterFail] = useState(false);
    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
    const [busy, setBusy] = useState(null);
    const [backendError, setBackendError] = useState(null);
    const [documentsList, setDocumentsList] = useState([]);
    const [linksList, setLinksList] = useState({
        links: []
    });
    const [evidenceError, setEvidenceError] = useState('');
    const [addEmailFieldError, setAddEmailFieldError] = useState('');

    const updateDocumentsList = function(data) {
        setDocumentsList(data);
    };

    const updateLinksState = links => {
        return new Promise((resolve, reject) => {
            setLinksList(links);
            resolve(links);
        });
    };

    const closeModal = () => {
        setLinksList({
            links: []
        });
        setDocumentsList([]);
        setBackendError(null);
        setIsSendingEspLetter(false);
        setIsSendingEspLetterFail(false);
        setIsSendingEspLetterSuccess(false);
        closeEspLetterModal();
    };

    const handleSubmit = values => {
        const data = cloneDeep(values);
        setIsSendingEspLetter(true);
        setIsSendingEspLetterFail(false);
        setDocumentUploadProgress(0);
        setBackendError(null);
        setBusy(true);
        setEvidenceError('');
        setAddEmailFieldError('');
        if (data.deliveryType !== DELIVERY_TYPE_EMAIL || data.emailTo === DELIVERY_TO_AGENT) {
            data.emailAddresses = [loggedInUser.email];
            data.ccEmailAddresses = [];
        }
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                setDocumentUploadProgress(Math.floor((loaded * 100) / total));
            }
        };
        let documents = formatDocumentsForApi([...documentsList]);
        if (validateEmailList(data)) {
            return axios
                .post(`api/agency/lease/${agreementInfo.id}/esp-documents`, documents, options)
                .then(response => {
                    setBusy(false);
                    data.documents = response.data.documents;
                    data.linksList = linksList;
                    data.reason = data.reason ? `${REASON_STATIC_TEXT}${data.reason}` : '';
                    return axios.post(`api/agency/lease/${agreementInfo.id}/esp-letter`, data).then(response => {
                        setIsSendingEspLetter(false);
                        setIsSendingEspLetterFail(false);
                        setIsSendingEspLetterSuccess(true);
                        dispatch(createEspLetterSuccess(response.data.lease));
                        setTimeout(() => {
                            closeModal();
                        }, HIDE_MESSAGE_TIME);
                    });
                })
                .catch(error => {
                    setIsSendingEspLetter(false);
                    setIsSendingEspLetterSuccess(false);
                    setIsSendingEspLetterFail(true);
                    const errors = error.response.data.errors.revisedEspList
                        ? error.response.data.errors.revisedEspList[
                              error.response.data.errors.revisedEspList.length - 1
                          ]
                        : {};
                    if (errors.emailAddresses) {
                        errors.emailAddressField = errors.emailAddresses;
                    }
                    if (errors.evidence === 'Please add evidence') {
                        setEvidenceError(errors.evidence);
                        errors.evidence = undefined;
                    }
                    return errors;
                });
        } else {
            setIsSendingEspLetter(false);
            setIsSendingEspLetterSuccess(false);
            setIsSendingEspLetterFail(false);
        }
    };

    const validateEmailList = values => {
        if (values.deliveryType === DELIVERY_TYPE_EMAIL) {
            if (!values.emailAddresses || values.emailAddresses.length === 0) {
                setAddEmailFieldError('Please enter at least one email address');
                return false;
            }
        }
        setAddEmailFieldError('');
        return true;
    };

    const renderRightButton = () => {
        return (
            <Button primary className="btn-terminate" height={40} type="submit" disabled={isSendingEspLetter}>
                {isSendingEspLetter && <span className="savestatus-spinner-await-completion" />}
                Send
            </Button>
        );
    };

    const renderLeftButton = () => {
        return (
            <Button primary className="btn-close" height={40} onClick={closeModal} disabled={isSendingEspLetter}>
                Cancel
            </Button>
        );
    };
    return (
        <ModalDialog
            title="Notice of Revised Estimated Selling Price"
            isOpen={isOpen}
            closeModal={closeModal}
            shouldCloseOnOverlayClick={false}
            className="esp-letter-from-modal-dialog"
        >
            <Form onSubmit={handleSubmit} initialValues={formData}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="esp-letter">
                                <div>
                                    <FormTextRegular name={'venderNames'} label="Principal" disabled />
                                    <FormTextRegular name={'agentName'} label="Agent" disabled />
                                    <FormTextRegular name={'address'} label="Address" disabled />
                                    <DatePickerInAgencyTimeZone
                                        name={'agreementDate'}
                                        label="Agreement Date"
                                        selected={values.agreementDate}
                                        disabled
                                    />
                                    <FormTextRegular name={'revisedEsp'} label="Revised ESP" required />
                                    <FormTextMultiline
                                        name={'reason'}
                                        label="Reason"
                                        required
                                        staticText={REASON_STATIC_TEXT}
                                    />
                                    <FormRadioGroup
                                        label="Evidence"
                                        name="evidence"
                                        radioGroupClass="wrapped-radio-group"
                                        data={[
                                            {
                                                label: 'The Agent has attached evidence to this notice.',
                                                value: 'agent_attached'
                                            },
                                            {
                                                label:
                                                    'The Agent has provided the Principal with evidence before the date of this notice.',
                                                value: 'agent_provided'
                                            }
                                        ]}
                                    />
                                    <FormTextRegular
                                        name={'authorisedPerson'}
                                        label="Name of Person authorised to receive this notice on behalf of the Principal"
                                        required
                                    />
                                    <div className="calendar">
                                        <DatePickerInAgencyTimeZone
                                            label="Date of Service"
                                            name="dateOfService"
                                            popperPlacement="top-start"
                                            selected={values.dateOfService}
                                            required
                                        />
                                    </div>
                                    <DeliveryTypeComponent
                                        values={values}
                                        form={form}
                                        addEmailFieldError={addEmailFieldError}
                                        isAgentAndVendor={true}
                                    />
                                    {values.evidence === 'agent_attached' &&
                                        values.deliveryType === DELIVERY_TYPE_EMAIL && (
                                            <React.Fragment>
                                                <h3>Attach Evidence</h3>
                                                <h4>Upload Files</h4>
                                                <DocumentUpload
                                                    uploading={busy}
                                                    leaseType={agreementInfo.leaseType}
                                                    updateDocuments={updateDocumentsList}
                                                    documents={documentsList}
                                                    backendError={backendError}
                                                    documentUploadProgress={documentUploadProgress}
                                                    hideFileCategory={true}
                                                />
                                                <div className="tenant-links">
                                                    <h4>Add Relevant Links</h4>
                                                    <Links
                                                        updateLinks={updateLinksState}
                                                        initialLinks={linksList}
                                                        agencyDefault={true}
                                                        leaseType={agreementInfo.leaseType}
                                                        hideMobile={true}
                                                    />
                                                </div>
                                                {evidenceError && (
                                                    <p>
                                                        <span className="savestatus savestatus-failed">
                                                            {evidenceError}
                                                        </span>
                                                    </p>
                                                )}
                                                <br />
                                            </React.Fragment>
                                        )}
                                    <FormTextRegular
                                        name="nameOfpersonServing"
                                        label="Name of Person serving the notice"
                                        disabled
                                    />
                                    <div className="footer">
                                        {isSendingEspLetterSuccess && (
                                            <span className="savestatus savestatus-saved">
                                                <span className="icon savestatus-icon" />
                                                Esp letter Sent Successfully
                                            </span>
                                        )}
                                        {isSendingEspLetterFail && (
                                            <span className="savestatus savestatus-failed">
                                                <span className="icon savestatus-icon" />
                                                Sending Esp letter failed.
                                            </span>
                                        )}
                                        <div className="button-row">
                                            <div className="button btn-await-completion">{renderLeftButton()}</div>
                                            <div className="button btn-await-completion">{renderRightButton()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
};

export default EspLetterModal;
