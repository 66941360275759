import React, { Component } from 'react';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo } from '../../../../../utils/agreementUtils';

class AgreementSalesFixturesFittings extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.inclusions && (
                    <AgreementSection
                        title="Fixtures and Fittings"
                        step={getSalesStepNo(leaseAllInfo.location, 'salesInclusions')}
                        leaseAllInfo={this.props.leaseAllInfo}
                    >
                        <div className="agreement-section-text">
                            <div>
                                {leaseAllInfo.inclusions && leaseAllInfo.inclusions.description && (
                                    <p className="name">
                                        Description of property for sale:{' '}
                                        <strong>{leaseAllInfo.inclusions.description}.</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.inclusions && leaseAllInfo.inclusions.termsAndConds && (
                                    <p className="name">
                                        Other Terms and Conditions of Sale known to Licensee:{' '}
                                        <strong>{leaseAllInfo.inclusions.termsAndConds}.</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.inclusions && leaseAllInfo.inclusions.inclusions && (
                                    <p className="name">
                                        Fittings and Fixtures included in the Sale:{' '}
                                        <strong>{leaseAllInfo.inclusions.inclusions}.</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.inclusions && leaseAllInfo.inclusions.inclusions && (
                                    <p className="name">
                                        Fittings and Fixtures excluded from the Sale:{' '}
                                        <strong>{leaseAllInfo.inclusions.exclusions}.</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.inclusions && leaseAllInfo.inclusions.easementsEtc && (
                                    <p className="name">
                                        Details of any Covenants, Easements, Defects, Local Government Notices, or
                                        Orders affecting the Property that are known to the Licensee:{' '}
                                        <strong>{leaseAllInfo.inclusions.easementsEtc}.</strong>
                                    </p>
                                )}
                            </div>
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementSalesFixturesFittings;
