import React, { memo } from 'react';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { FormTextRegular } from '../../../../components/form/FormText';
import { FormNumber, FormTextCurrency, FormTextMultiline } from '../../../../components/form/FormText';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import {
    COMMISSION_PAYABLE_TYPE_OTHER,
    PRICE_TYPE_LIST,
    PRICE_TYPE_RESERVE,
    COMMISSION_PAYABLE_TYPE_SALES,
    YES_LABEL,
    NO_LABEL,
    COMMISSION_TYPE_FIXED,
    COMMISSION_TYPE_PERCENTAGE
} from '../../../../config';

const commissionPayableTypeOptions = [
    {
        value: COMMISSION_PAYABLE_TYPE_SALES,
        label:
            'For sales, including auctions, commission is payable if a contract is entered into and settlement of the contract occurs.'
    },
    { value: COMMISSION_PAYABLE_TYPE_OTHER, label: COMMISSION_PAYABLE_TYPE_OTHER }
];

const priceTypeOptions = [
    { value: PRICE_TYPE_RESERVE, label: PRICE_TYPE_RESERVE },
    { value: PRICE_TYPE_LIST, label: PRICE_TYPE_LIST }
];

const electronicListingProviderOptions = [
    { value: YES_LABEL, label: YES_LABEL },
    { value: NO_LABEL, label: NO_LABEL }
];

const commissionTypeOptions = [
    { value: COMMISSION_TYPE_FIXED, label: 'Fixed commission ' },
    { value: COMMISSION_TYPE_PERCENTAGE, label: 'Commission percentage ' }
];

function PriceAndFee({ values, handleRangeChange, opinionRangeFail }) {
    return (
        <>
            <FormRadioGroup label="Property selling price" name={'priceType'} data={priceTypeOptions} required />
            {values.priceType === PRICE_TYPE_RESERVE && (
                <>
                    <FormTextCurrency
                        name="reservePrice"
                        label="The reserve price will be"
                        required={!values.isReservePriceToBeAdvised}
                        precision="0"
                        disabled={values.isReservePriceToBeAdvised}
                    />
                    <CheckboxCheck
                        label="Reserve price to be advised in writing at a later date"
                        name="isReservePriceToBeAdvised"
                    />
                </>
            )}
            {values.priceType === PRICE_TYPE_LIST && (
                <>
                    <div className="form-checkbox">
                        <CheckboxCheck label="Range" name="isRange" onClick={() => handleRangeChange()} />
                    </div>
                    {values.isRange && (
                        <div className="form-half-col">
                            <div>
                                <FormTextCurrency name="opinionPriceFrom" label="From $ " required precision="0" />
                            </div>
                            <div className="custom-error-block">
                                <FormTextCurrency name="opinionPriceTo" label="To $ " required precision="0" />
                                {opinionRangeFail && (
                                    <div className="has-error custom-error-message">{opinionRangeFail}</div>
                                )}
                            </div>
                        </div>
                    )}
                    {!values.isRange && (
                        <FormTextCurrency name="exactPrice" label="Price to be disclosed $" required precision="0" />
                    )}
                </>
            )}
            <p className="margin-bottom-1rem">
                <b>For residential property auctions and residential properties to be marketed without a price</b>
            </p>
            <FormRadioGroup
                label="Will the property be marketed via an electronic listing provider?"
                name={'electronicListingProvider'}
                data={electronicListingProviderOptions}
                required
            />
            {values.electronicListingProvider === YES_LABEL && (
                <>
                    <div className="form-checkbox">
                        <CheckboxCheck
                            label="Range"
                            name="isElectronicListingRange"
                            onClick={() => handleRangeChange()}
                        />
                    </div>
                    {values.isElectronicListingRange && (
                        <div className="form-half-col">
                            <div>
                                <FormTextCurrency
                                    name="electronicListingOpinionPriceFrom"
                                    label="From $ "
                                    required
                                    precision="0"
                                />
                            </div>
                            <div className="custom-error-block">
                                <FormTextCurrency
                                    name="electronicListingOpinionPriceTo"
                                    label="To $ "
                                    required
                                    precision="0"
                                />
                            </div>
                        </div>
                    )}
                    {!values.isElectronicListingRange && (
                        <FormTextCurrency
                            name="electronicListingExactPrice"
                            label="Price to be disclosed $"
                            required
                            precision="0"
                        />
                    )}
                </>
            )}
            <FormRadioGroup
                label="Commission (including GST)"
                name={'commissionType'}
                data={commissionTypeOptions}
                required
            />
            {values.commissionType === COMMISSION_TYPE_FIXED && (
                <FormTextCurrency name="commissionFee" label="Commission fee" required precision="2" />
            )}
            {values.commissionType === COMMISSION_TYPE_PERCENTAGE && (
                <React.Fragment>
                    <FormNumber name="commissionPercent" label="Commission Percentage Including GST" required />
                </React.Fragment>
            )}
            <FormRadioGroup
                label="Commission is payable"
                name="commissionIsPayable"
                className="margin-bottom-5"
                radioGroupClass="wrapped-radio-group"
                data={commissionPayableTypeOptions}
                required
            />
            {values.commissionIsPayable === COMMISSION_PAYABLE_TYPE_OTHER && (
                <FormTextRegular name="otherPayable" label="" />
            )}
            <FormTextRegular name="otherTypesOfAppointments" label="For all other types of appointments" />
        </>
    );
}

export default memo(PriceAndFee);
