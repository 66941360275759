import React from 'react';

import { FormTextRegular } from '../../../../form/FormText';

export const OutgoingGroup = ({ index, removeOtherOutgoing }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Other outgoing {index + 1}</h3>
                    <div className="toggle" onClick={() => removeOtherOutgoing(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular
                        name={`otherOutgoings[${index}].outGoingDescription`}
                        label="Outgoing description"
                        required
                    />
                </div>
            </div>
        </div>
    );
};
