import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
export const UPDATE_INCLUSIONS_FORM = 'dashboard.lease.inclusions.updateForm';
import produce from 'immer';
const initState = {
    inclusions: '',
    isFurnished: false
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS: {
            draftState = {
                inclusions: '',
                isFurnished: false
            };
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = action.payload.agreementInfo.inclusions;
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = action.lease.inclusions;
            break;
        }

        case Lease.LEASE_INCLUSIONS_SUCCESS: {
            draftState = action.result.data.lease.inclusions;
        }
    }
    return draftState;
});
