import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import {
    DOCUMENT_CUSTOM,
    DOCUMENT_CREATE_A_FLK,
    ALL,
    CREATE_A_FLK_DISPLAY,
    FLK_A_PDF_DISPLAY,
    routes
} from '../../../config';
import '../../../sass/leftMenuDashboard.scss';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import { LEASE_STATUS_DRAFT, closeMenu, toggleDocumentsMenu } from '../../../actions/dashboard';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import { FileCopySharp, LibraryBooksSharp, PictureAsPdfSharp } from '@material-ui/icons';
import { getIsDocumentsMenuOpen } from '../../../selectors/dashboard';
import LeftMenuLink from '../../../common/components/LeftMenuLink';
import useOpenCreationModal from '../../../hooks/useOpenCreationModal';
import { TEMPLATE_SCREEN_V2 } from '../../../constants/featureFlags';

const LeftMenu = props => {
    const isTemplateScreenV2Active = useFeatureFlag(TEMPLATE_SCREEN_V2);
    const isDocumentsOpen = useSelector(getIsDocumentsMenuOpen);
    const dispatch = useDispatch();

    const closeMenuHandler = () => {
        dispatch(closeMenu());
    };

    const handleClickOutside = () => {
        closeMenuHandler();
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    const { leftMenu } = props;

    const { openDocumentCreationModal, isLeaseTypeEnabled } = useOpenCreationModal();

    return (
        <nav ref={ref} className={leftMenu ? 'left-menu on' : 'left-menu'}>
            <div className="menu">
                {isDocumentsOpen && (
                    <div className="section-wrapper">
                        <ul>
                            <li>
                                <LeftMenuLink
                                    label="Documents"
                                    onClick={closeMenuHandler}
                                    isActive={(match, location) => {
                                        return location.pathname.startsWith(`/dashboard/documents/${ALL}`);
                                    }}
                                    icon={<FileCopySharp />}
                                    toPath={`/dashboard/documents/${ALL}/${LEASE_STATUS_DRAFT}`}
                                />
                            </li>

                            <li>
                                <LeftMenuLink
                                    label={FLK_A_PDF_DISPLAY}
                                    onClick={closeMenuHandler}
                                    isActive={(match, location) => {
                                        return location.pathname.startsWith(`/dashboard/documents/${DOCUMENT_CUSTOM}`);
                                    }}
                                    icon={<PictureAsPdfSharp />}
                                    toPath={`/dashboard/documents/${DOCUMENT_CUSTOM}/${LEASE_STATUS_DRAFT}`}
                                    onCreateNew={
                                        isLeaseTypeEnabled(DOCUMENT_CUSTOM)
                                            ? () => openDocumentCreationModal(DOCUMENT_CUSTOM)
                                            : undefined
                                    }
                                    tooltipText={`${FLK_A_PDF_DISPLAY} will allow you to upload any PDF and send it to your client for signing or acknowledgement via email or SMS`}
                                />
                            </li>
                            <li>
                                <LeftMenuLink
                                    label={CREATE_A_FLK_DISPLAY}
                                    onClick={closeMenuHandler}
                                    isActive={(match, location) => {
                                        return location.pathname.startsWith(
                                            `/dashboard/documents/${DOCUMENT_CREATE_A_FLK}`
                                        );
                                    }}
                                    icon={<DescriptionSharpIcon />}
                                    toPath={`/dashboard/documents/${DOCUMENT_CREATE_A_FLK}/${LEASE_STATUS_DRAFT}`}
                                    onCreateNew={
                                        isLeaseTypeEnabled(DOCUMENT_CREATE_A_FLK)
                                            ? () => openDocumentCreationModal(DOCUMENT_CREATE_A_FLK)
                                            : undefined
                                    }
                                    tooltipText={`${CREATE_A_FLK_DISPLAY} allows you to create any document or agreement, input required data and send it to your client for review and sign via email or SMS`}
                                />
                            </li>
                        </ul>
                    </div>
                )}
                <hr className="menu-break" />
                <ul>
                    <li>
                        <LeftMenuLink
                            label={'Templates'}
                            onClick={closeMenuHandler}
                            isActive={(match, location) => {
                                return location.pathname.includes(`templates`);
                            }}
                            icon={<LibraryBooksSharp />}
                            toPath={
                                isTemplateScreenV2Active ? routes.ROUTE_TEMPLATES : routes.ROUTE_CREATE_A_FLK_TEMPLATES
                            }
                        />
                    </li>
                    <li>
                        <LeftMenuLink
                            label={'Settings'}
                            onClick={closeMenuHandler}
                            isActive={(match, location) => {
                                return (
                                    location.pathname.startsWith(`/user`) &&
                                    !location.pathname.includes(`templates`) &&
                                    !location.pathname.startsWith('/user/statistics')
                                );
                            }}
                            icon={<SettingsIcon />}
                            toPath={`/user`}
                        />
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default memo(LeftMenu);
