import React, { memo } from 'react';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { FormTextRegular, FormTextCurrency } from '../../../../components/form/FormText';
import { MarketingPlanGroup } from '../../../../components/lease/mainScreen/common/MarketingPlanGroup';
import { FormTextMultiline } from '../../../../components/form/FormText';

function MarketingAndAdvertising({ values, handleIsPlanSelect, removeItem, addPlan, marketingAndAdvertising }) {
    return (
        <>
            {!values.isMarketingPlan && (
                <FormTextCurrency name="marketingEstimate" label="Marketing Estimate $" precision="0" />
            )}
            <div>
                <FormTextRegular name="marketingPayable" label="Marketing is payable" required />
                <CheckboxCheck
                    name="isMarketingPlan"
                    label="Build itemised marketing campaign"
                    onClick={isMarketingPlan => handleIsPlanSelect(values, isMarketingPlan)}
                />
            </div>
            {values.isMarketingPlan && (
                <div>
                    {marketingAndAdvertising.marketingPlans.map((person, index) => (
                        <MarketingPlanGroup index={index} key={index} removeItem={() => removeItem(values, index)} />
                    ))}
                    {values.isMarketingPlan && (
                        <FormTextCurrency name="marketingEstimate" label="Marketing costs $" disabled precision="2" />
                    )}
                    <div className="button">
                        <button type="button" onClick={() => addPlan(values)}>
                            Add Marketing Service
                        </button>
                    </div>
                </div>
            )}
            <React.Fragment>
                <p className="text">
                    Advertising and promotion
                    <br />
                    (the manner in which the property is to be promoted):
                </p>
                <CheckboxCheck
                    className="mobile-multi-select-enabled"
                    name="isAgencyDatabase"
                    label="Agency Database"
                />
                <CheckboxCheck
                    className="mobile-multi-select-enabled"
                    name="isSignboard"
                    label="Authority to install signboard"
                />
                <CheckboxCheck className="mobile-multi-select-enabled" name="isListWebsites" label="List websites" />
                {values.isListWebsites && (
                    <FormTextRegular name="websiteList" label="List Websites (include a comma after each website)" />
                )}
                <CheckboxCheck
                    className="mobile-multi-select-enabled"
                    name="isOther"
                    label="Other advertising and promotion (optional)"
                />
                {values.isOther && <FormTextRegular name="otherText" label="List other advertising and promotion" />}
            </React.Fragment>
            <FormTextMultiline
                name="specialInstructions"
                label="Any special instruction about Marketing and Showing of the Property."
            />
        </>
    );
}

export default memo(MarketingAndAdvertising);
