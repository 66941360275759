import * as React from 'react';
import { forOwn } from 'lodash';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getPmStepNo, isLeaseLocationQLD, isLeaseLocationVIC } from '../../../../../utils/agreementUtils';
import { getLabel } from '../../../../../utils/labelUtils';
import { LEASE_TYPE_PROPERTY_MANAGEMENT } from '../../../../../config';

const AgreementPmRentAndPayment = ({ leaseAllInfo }) => {
    let paymentData = {};
    if (leaseAllInfo.pmRentAndPayment) {
        paymentData = leaseAllInfo.pmRentAndPayment.paymentData;
    }
    let selectedPaymentMethods = [];
    forOwn(paymentData, (item, key) => {
        if (item.enabled) {
            selectedPaymentMethods.push(key == 'OwnerToEnter' ? 'Owner To Enter' : key);
        }
    });
    return (
        <div>
            {!!leaseAllInfo.pmRentAndPayment && (
                <AgreementSection
                    title={getLabel('rentAndPayment', leaseAllInfo.location, leaseAllInfo.leaseType)}
                    step={getPmStepNo(leaseAllInfo.location, 'pmRentAndPayment')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <div>
                            {leaseAllInfo.pmRentAndPayment && (
                                <React.Fragment>
                                    {!isLeaseLocationVIC(leaseAllInfo.location) && (
                                        <React.Fragment>
                                            <p className="name">
                                                Rent: <strong>{leaseAllInfo.pmRentAndPayment.rent}</strong>
                                            </p>
                                            <p className="name">
                                                {`${getLabel(
                                                    'landlord',
                                                    leaseAllInfo.location,
                                                    LEASE_TYPE_PROPERTY_MANAGEMENT
                                                )} disbursement`}
                                                :{' '}
                                                <strong>
                                                    {leaseAllInfo.pmRentAndPayment.landlordDisbursement === 'Other'
                                                        ? leaseAllInfo.pmRentAndPayment.otherLandlordDisbursement
                                                        : leaseAllInfo.pmRentAndPayment.landlordDisbursement}
                                                </strong>
                                            </p>
                                            <p className="name">
                                                Statement issued:{' '}
                                                <strong>
                                                    {leaseAllInfo.pmRentAndPayment.statementIssued === 'Other'
                                                        ? leaseAllInfo.pmRentAndPayment.otherStatementIssued
                                                        : leaseAllInfo.pmRentAndPayment.statementIssued}
                                                </strong>
                                            </p>
                                        </React.Fragment>
                                    )}
                                    <div className="data-container column">
                                        <p className="name">
                                            Payment method is: <strong>{selectedPaymentMethods.join(', ')}</strong>
                                            {isLeaseLocationVIC(leaseAllInfo.location) &&
                                                renderVicPaymentMethod(selectedPaymentMethods)}
                                        </p>
                                    </div>
                                    {isLeaseLocationQLD(leaseAllInfo.location) && (
                                        <>
                                            <p className="name">
                                                Has the property been leased under a General Tenancy Agreement or a
                                                Rooming Agreement in the last 12 months?{' '}
                                                <strong>
                                                    {leaseAllInfo.pmRentAndPayment?.isPropertyBeenLeaseOutUnderGTAFormatted}
                                                </strong>
                                            </p>
                                            <p className="name">
                                                When was the last rent increase?{' '}
                                                <strong>
                                                    {leaseAllInfo.pmRentAndPayment?.rentIncreaseDateFormatted}
                                                </strong>
                                            </p>
                                            <p className="name">
                                                The previous weekly rental amount was{' '}
                                                <strong>
                                                    {leaseAllInfo.pmRentAndPayment?.rentIncreaseAmountFormatted}
                                                </strong>
                                            </p>
                                        </>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </AgreementSection>
            )}
        </div>
    );

    function renderVicPaymentMethod(selectedPaymentMethods) {
        if (Array.isArray(selectedPaymentMethods) && selectedPaymentMethods.includes('EFT')) {
            const { BSB, accountName, accountNumber, bankName } =
                leaseAllInfo?.pmRentAndPayment?.paymentData?.EFT?.details || {};
            return (
                <table className="agreement-section-table">
                    <tr>
                        <th>Account name</th>
                        <td>{accountName}</td>
                    </tr>
                    <tr>
                        <th>Bank name</th>
                        <td>{bankName}</td>
                    </tr>
                    <tr>
                        <th>BSB</th>
                        <td>{BSB}</td>
                    </tr>
                    <tr>
                        <th>Account number</th>
                        <td>{accountNumber}</td>
                    </tr>
                </table>
            );
        }
        return <div></div>;
    }
};

export default AgreementPmRentAndPayment;
