import React from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo } from '../../../../../../utils/agreementUtils';
import DataString from '../../bloks/DataString';

export default function AgreementAdvertisingAndMarketing({ leaseAllInfo }) {
    const partName = 'marketingAndAdvertising';
    const { marketingAndAdvertising, location } = leaseAllInfo;

    if (!marketingAndAdvertising) {
        return <></>;
    }
    const step = getSalesStepNo(location, partName);
    return (
        <>
            <AgreementSection title={'Advertising & Marketing'} step={step} leaseAllInfo={leaseAllInfo}>
                <div className="data data-row">
                    <DataString
                        title={'Special Instructions about marketing:'}
                        text={marketingAndAdvertising.specialInstructions || 'N/A'}
                    />
                    <DataString
                        title={'Authorised Advertising Amount:'}
                        text={marketingAndAdvertising.authorisedAdvertisingAmountText}
                    />
                    <DataString title={'When Payable:'} text={marketingAndAdvertising.whenPayable || 'N/A'} />
                    {marketingAndAdvertising.isMarketingPlan &&
                        Array.isArray(marketingAndAdvertising.marketingPlans) &&
                        marketingAndAdvertising.marketingPlans.length > 0 && (
                            <>
                                <div className="data-string">
                                    <span className="data-contact">Plans</span>
                                    <div className="text">
                                        <table>
                                            {marketingAndAdvertising.marketingPlans.map((plan, index) => (
                                                <tr key={index}>
                                                    <td>{plan.description}</td>
                                                    <td>$ {plan.cost}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            </AgreementSection>
        </>
    );
}
