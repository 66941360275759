import React from 'react';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from '../../selectors/login';
import { FormTextRegular } from '../../components/form/FormText';
import { CheckboxCheck } from '../../components/form/FormCheckboxCheck';
import Button from '../../common/components/Button';
import { TWO_FACTOR_AUTHENTICATION_SMS } from '../../config';
import styles from './Mfa.module.scss';

interface AppMfaProps {
    submitting: boolean;
    error: string | null;
    setError: (error: string | boolean) => void;
    setMfaMethod: (method: string) => void;
    setShowContactUs: (show: boolean) => void;
    send2faCode: any;
}

export default function AppMfa({
    submitting,
    error,
    setError,
    setMfaMethod,
    setShowContactUs,
    send2faCode
}: AppMfaProps) {
    const userFromLogin: any = useSelector(getLoggedInUser);

    return (
        <div className={styles.mfaSection}>
            <p className={styles.label}>For your security, please enter the code from your authentication app</p>
            <FormTextRegular name="code" label="Verification code" validateOnTouch={true} />
            <p className={styles.subLabel}>Enter the code from your authentication app</p>
            <div className="form-checkbox">
                <CheckboxCheck name="rememberTrustDevice" label="Trust this device for 30 days" />
            </div>
            <Button primary className={styles.nextButton} type="submit" loading={submitting}>
                Next
            </Button>
            {error && <div className={styles.errorMessage}>{error}</div>}
            {userFromLogin.multiFactorAuthenticationMethods.includes(TWO_FACTOR_AUTHENTICATION_SMS) && (
                <Button
                    type="button"
                    className={styles.actionButton}
                    onClick={() => {
                        setError(false);
                        setMfaMethod(TWO_FACTOR_AUTHENTICATION_SMS);
                        send2faCode.mutate({
                            accountType: userFromLogin.accountType,
                            email: userFromLogin.email
                        });
                    }}
                >
                    Send code to mobile number instead
                </Button>
            )}
            <Button type="button" className={styles.actionButton} onClick={() => setShowContactUs(true)}>
                Having trouble?
            </Button>
        </div>
    );
}
