import React from 'react';
import { getLabel } from '../../../../../utils/labelUtils';

import { FormTextRegular } from '../../../../form/FormText';

export const ChargesGroup = ({ index, removeCharge, location, leaseType }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Charge {index + 1}</h3>
                    <div className="toggle" onClick={() => removeCharge(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular
                        name={`chargeList[${index}].service`}
                        label={getLabel('service', location, leaseType)}
                        required
                    />
                    <FormTextRegular
                        name={`chargeList[${index}].estimate`}
                        label={getLabel('estimate', location, leaseType)}
                        required
                    />
                    <FormTextRegular
                        name={`chargeList[${index}].dueDate`}
                        label={getLabel('dueDate', location, leaseType)}
                        required
                    />
                </div>
            </div>
        </div>
    );
};
