import React, { Component } from 'react';
import classNames from 'classnames';

import '../../sass/tariffPlan.scss';

/**
 * Component to display one tariff plan cell in list.
 */
export default class TariffPlan extends Component {
    /**
     * Change selected tariff plan.
     * @param {object} tariffPlan - selected tariff plan.
     *
     * @return {void}
     */
    changePlan(tariffPlan) {
        if (this.props.disabled) {
            return;
        }

        this.props.changePlan(tariffPlan);
    }

    render() {
        const { isSelected, tariffPlan, disabled } = this.props;

        let classes = classNames({
            item: true,
            active: isSelected,
            disabled: disabled
        });

        return (
            <div className={classes} onClick={() => this.changePlan(tariffPlan)}>
                <div className="plan-header">
                    <p className="name">{tariffPlan.title}</p>
                    <p className="price">
                        {tariffPlan.price === 0 && <React.Fragment>Free</React.Fragment>}
                        {tariffPlan.price === -1 && <React.Fragment>Quote</React.Fragment>}
                        {tariffPlan.price !== 0 && tariffPlan.price !== -1 && (
                            <React.Fragment>
                                <span className="name">${tariffPlan.price}</span>/Month
                            </React.Fragment>
                        )}
                    </p>
                </div>
                <p className="plan-text">
                    {tariffPlan.subtitle && (
                        <React.Fragment>
                            {tariffPlan.subtitle}
                            <br />
                        </React.Fragment>
                    )}
                    <div>{tariffPlan.constraintText}</div>
                    {/*<div>{tariffPlan.bulkSendText}</div>*/}
                </p>
            </div>
        );
    }
}
