import { useEffect } from 'react';
import { isTouchScreen, isMobileWidth } from '../config';
import { isBusinessUserAccount } from '../utils/userUtils.js';
import { useHistory } from 'react-router-dom';
import { BUSINESS_MOBILE_LANDING_PATH } from '../constants/constants';
import { debounce } from 'lodash';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { BUSINESS_MOBILE_MESSAGE } from '../constants/featureFlags';

/**
 * A hook to redirect the user to/from the business mobile landing page based on the user device.
 * Listens to the window resize event to handle device rotation.
 *
 * @param accountType The type of the account
 * @param options Options to disable the redirect
 * @param options.disable Disables the redirect
 */
export const useFlkBusinessMobileRedirect = (
    accountType: string,
    options?: {
        disable?: boolean;
    }
) => {
    const history = useHistory();
    const isBusinessMobileMessageEnabled = useFeatureFlag(BUSINESS_MOBILE_MESSAGE);

    useEffect(() => {
        const isTouchScreenDevice = isTouchScreen();

        const handleResize = debounce(() => {
            const pathName = history.location.pathname;
            const shouldRedirectToMobileLanding =
                !options?.disable &&
                isBusinessMobileMessageEnabled &&
                isBusinessUserAccount(accountType) &&
                isTouchScreenDevice &&
                isMobileWidth(800);

            if (shouldRedirectToMobileLanding && pathName !== BUSINESS_MOBILE_LANDING_PATH) {
                history.push(BUSINESS_MOBILE_LANDING_PATH);
            } else if (!shouldRedirectToMobileLanding && pathName === BUSINESS_MOBILE_LANDING_PATH) {
                history.push('/');
            }
        }, 250);

        handleResize(); // Initial check on component mount

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [accountType, history, isBusinessMobileMessageEnabled, options?.disable]);
};
