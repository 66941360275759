import React from 'react';
import { useDispatch } from 'react-redux';
import '../../../../../sass/dashboardContentTable.scss';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import AgentLogo from '../../../../AgentLogo';
import { openDocumentForm } from '../../../../../actions/document';
import {
    DOCUMENT_CREATE_A_FLK,
    DOCUMENT_CUSTOM,
    DOCUMENT_EDIT_MODE,
    DOCUMENT_READ_ONLY_MODE
} from '../../../../../config';
import * as dashboard from '../../../../../actions/dashboard';
import FlowDocumentsMoreOptionBlock from '../../cells/FlowDocumentsMoreOptionBlock';
import { getGeneralAppLabel } from '../../../../../utils/labelUtils';
import { useAccountProvider } from '../../../../providers/AccountProvider';

import { ReactComponent as EmailImage } from '../../../../../../assets/images/receive-mail.svg';
import DocType from './DocType';
import Signer from './Signer';

const AllDocuments = ({ documentList }) => {
    const dispatch = useDispatch();
    const accountType = useAccountProvider();

    function openDocumentFormView(event, doc) {
        event.stopPropagation();
        if (doc.docType === DOCUMENT_CUSTOM) {
            if (doc.status === dashboard.LEASE_STATUS_DRAFT) {
                dispatch(openDocumentForm(DOCUMENT_CUSTOM, doc, DOCUMENT_EDIT_MODE));
            } else {
                dispatch(openDocumentForm(DOCUMENT_CUSTOM, doc, DOCUMENT_READ_ONLY_MODE));
            }
        } else if (doc.docType === DOCUMENT_CREATE_A_FLK) {
            if (doc.status === dashboard.LEASE_STATUS_DRAFT) {
                dispatch(openDocumentForm(DOCUMENT_CREATE_A_FLK, doc, DOCUMENT_EDIT_MODE));
            } else {
                dispatch(openDocumentForm(DOCUMENT_CREATE_A_FLK, doc, DOCUMENT_READ_ONLY_MODE));
            }
        }
    }

    return (
        <Table className="rent-reduction-table">
            <Thead>
                <Th column="agent">{getGeneralAppLabel('agent', accountType)}</Th>
                <Th column="docType">Type</Th>
                <Th column="isEmailDraft">&nbsp;</Th>
                <Th column="name">Signers name</Th>
                <Th column="docTitle">Document title</Th>
                <Th column="deliveryType">Delivery type</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {documentList &&
                documentList.map((doc, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openDocumentFormView(event, doc);
                            }}
                        >
                            <Td column="agent">
                                <AgentLogo agent={doc.agent} />
                            </Td>
                            <Td column="isEmailDraft">
                                {doc.createdViaEmail ? <EmailImage className="email-icon" src={EmailImage} /> : ''}
                            </Td>
                            <Td column="docType">
                                <DocType docType={doc.docType} />
                            </Td>
                            <Td column="name">
                                <Signer doc={doc} />
                            </Td>
                            <Td column="docTitle">
                                <b>{doc.documentTitle ? doc.documentTitle : '-'}</b>
                            </Td>
                            <Td column="deliveryType">
                                <b className="upperCase">{doc?.deliveryType || '-'}</b>
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <FlowDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        leaseType={doc.docType}
                                        doc={doc}
                                    />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default AllDocuments;
