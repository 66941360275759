import * as PropTypes from 'prop-types';
import * as React from 'react';
import { memo } from 'react';

const SpecialConditionWithResponse = ({ item }) => {
    return (
        <li className="agreement-section-text__item">
            <div className="agreement-section-text__item--text">
                <strong>Title:</strong> {item.title}
            </div>
            <div className="agreement-section-text__item--text">
                <strong>Description:</strong> {item.questionText}
            </div>
            <div className="agreement-section-text__item--text">
                Response:{' '}
                <strong>
                    {item.answer === 'true' && 'Yes'}
                    {item.answer === 'false' && 'No'}
                    {item.answer !== 'false' && item.answer !== 'true' && item.answer}
                </strong>
            </div>
        </li>
    );
};

SpecialConditionWithResponse.propTypes = { item: PropTypes.any };

export default memo(SpecialConditionWithResponse);
