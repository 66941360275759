import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/** Creates URLSearchParams from query string.
 * Usage: const query = useQuery();
 *        const param = query.get('param');
 */
const useQueryParams = () => {
    const { search } = useLocation();

    return useMemo(() => {
        return new URLSearchParams(search);
    }, [search]);
};

export default useQueryParams;
