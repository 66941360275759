import React from 'react';
import ThumbDisplay from '../../../../components/dashboard/image/ThumbDisplay';
import { PARTY_NONE } from '../../../../config';

const AnswersGroup = ({ questions, doc }) => {
    return (
        <div className="summary-item">
            <h2>{doc.clauseTitle}</h2>
            <table className="answers-table">
                <tbody>
                    <tr>
                        <th></th>
                        <th>Clause</th>
                        <th>Answer</th>
                        <th>Details</th>
                    </tr>
                    {questions &&
                        questions.map((question, index) => {
                            return (
                                <tr key={index}>
                                    <td className="without-label forced-mobile-view">{index + 1}</td>
                                    <td className="without-label forced-mobile-view">
                                        <p className="strong">{question.title}</p>
                                        {question.subTitle && (
                                            <p>
                                                <i>{question.subTitle}</i>
                                            </p>
                                        )}
                                        <pre>{question.description}</pre>
                                        {question.note && (
                                            <React.Fragment>
                                                <br />
                                                <p className="note">{question.note}</p>
                                            </React.Fragment>
                                        )}
                                    </td>
                                    <td>
                                        <p>
                                            {question.partyToAnswer === PARTY_NONE ? 'Not required' : question.answer}
                                        </p>
                                    </td>
                                    <td>
                                        <p>{question.details}</p>
                                        {question.fileUrl && (
                                            <ThumbDisplay imageSrc={question.fileUrl} imageTitle={question.title} />
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};
export default AnswersGroup;
