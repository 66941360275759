import React from 'react';
import styles from './SecurityCard.module.scss';
import cx from 'classnames';
import parse from 'html-react-parser';
import Icon, { Icons } from '../../common/components/Icon';
import Button from '../../common/components/Button';

interface SecurityCardProps {
    title: string;
    description: string;
    headerIcon?: Icons;
    onClick?: () => void;
    active?: boolean;
    disableAuth?: () => void;
    disabled?: boolean;
    loading?: boolean;
}

export default function SecurityCard({
    title,
    description,
    headerIcon,
    onClick,
    active,
    disableAuth,
    disabled,
    loading
}: SecurityCardProps) {
    return (
        <div
            className={cx(styles.securityCard, {
                [styles.active]: active,
                [styles.disabled]: !active && disabled
            })}
        >
            <div className={styles.header}>
                <div className={styles.titleGroup}>
                    {headerIcon && <Icon className={styles.icon} icon={headerIcon} />}
                    <h3 className={styles.title}>{title}</h3>
                </div>
                {active && <Icon className={styles.checkIcon} icon={Icons.CHECK} />}
            </div>
            <div className={styles.body}>
                <p className={styles.text}>{parse(description)}</p>
                {active ? (
                    <Button type="button" className={styles.disableButton} onClick={disableAuth} loading={loading}>
                        Disable
                    </Button>
                ) : (
                    <Button type="button" className={styles.submitButton} onClick={onClick} loading={loading}>
                        Setup
                    </Button>
                )}
            </div>
        </div>
    );
}
