import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import * as PropTypes from 'prop-types';
import MessageNotSent from '../../../../../assets/images/icons/send/MessageNotSent.svg';
import MessageSendingFailed from '../../../../../assets/images/icons/send/MessageSendingFailed.svg';
import { memo } from 'react';
import '../../../../sass/dashboard/keyReminder.scss';

const KeyReminderIndicator = ({ reminder, prefix }) => {
    return (
        <div className="reminder-container">
            {!reminder && (
                <React.Fragment>
                    <span
                        className={`reminder-status reminder-status-not-sent`}
                        data-tip={true}
                        data-for="explanation-not-sent"
                    >
                        <img src={MessageNotSent} alt="Not sent" />
                    </span>
                    <ReactTooltip
                        id="explanation-not-sent"
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>No reminder was sent</p>
                    </ReactTooltip>
                </React.Fragment>
            )}
            {reminder && !reminder.responseReceivedAt && (
                <React.Fragment>
                    <span
                        className={`reminder-status reminder-status-light reminder-status-pending`}
                        data-tip={true}
                        data-for={`explanation-pending-${prefix}-${reminder.id}`}
                    />
                    <ReactTooltip
                        id={`explanation-pending-${prefix}-${reminder.id}`}
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>{reminder.borrowerResponse}</p>
                    </ReactTooltip>
                </React.Fragment>
            )}
            {reminder && reminder.responseReceivedAt && reminder.response === 'no-response' && (
                <React.Fragment>
                    <span
                        className={`reminder-status reminder-status-no-response`}
                        data-tip={true}
                        data-for={`explanation-no-response-${prefix}-${reminder.id}`}
                    >
                        <img src={MessageSendingFailed} alt="Message sending failed" />
                    </span>
                    <ReactTooltip
                        id={`explanation-no-response-${prefix}-${reminder.id}`}
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>{reminder.borrowerResponse}</p>
                    </ReactTooltip>
                </React.Fragment>
            )}
            {reminder && reminder.responseReceivedAt && reminder.response !== 'no-response' && (
                <React.Fragment>
                    {
                        <React.Fragment>
                            <span
                                className={`reminder-status reminder-status-light reminder-status-${reminder.response}`}
                                data-tip={true}
                                data-for={`explanation-${reminder.response}-${prefix}-${reminder.id}`}
                            />

                            {reminder.response === 'call' && (
                                <ReactTooltip
                                    id={`explanation-call-${prefix}-${reminder.id}`}
                                    class="tooltip"
                                    place="top"
                                    effect="solid"
                                    globalEventOff="wheel"
                                >
                                    <p>{reminder.borrowerResponse}</p>
                                </ReactTooltip>
                            )}

                            {reminder.response === 'return' && (
                                <ReactTooltip
                                    id={`explanation-return-${prefix}-${reminder.id}`}
                                    class="tooltip"
                                    place="top"
                                    effect="solid"
                                    globalEventOff="wheel"
                                >
                                    <p>{reminder.borrowerResponse}</p>
                                </ReactTooltip>
                            )}

                            {reminder.response === 'working' && (
                                <ReactTooltip
                                    id={`explanation-working-${prefix}-${reminder.id}`}
                                    class="tooltip"
                                    place="top"
                                    effect="solid"
                                    globalEventOff="wheel"
                                >
                                    <p>{reminder.borrowerResponse}</p>
                                </ReactTooltip>
                            )}
                        </React.Fragment>
                    }
                </React.Fragment>
            )}
        </div>
    );
};

KeyReminderIndicator.propTypes = { reminder: PropTypes.object };

export default memo(KeyReminderIndicator);
