import React, { useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import ModalDialog from './../../common/components/ModalDialog';
import '../../sass/modals/auditTrailModal.scss';
import '../../sass/dashboardContentTable.scss';
import { closeNotesModal } from '../../actions/dashboard';
import { Form } from 'react-final-form';
import { FormTextMultiline } from '../../components/form/FormText';
import { addNoteSuccess } from '../../actions/lease';
import { isNotesOpen } from '../../selectors/dashboard';
import Button from '../../common/components/Button';

import '../../sass/notesModal.scss';
import { reverse, cloneDeep } from 'lodash';
import NotesTable from '../dashboard/components/NotesTable';

const NotesModal = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(isNotesOpen);
    const reduxNotes = useSelector(state => state.dashboard.agreementInfo.notes);
    const agreementInfo = useSelector(state => state.dashboard.agreementInfo);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({});
    const [addingNote, setAddingNote] = useState(false);
    const sortedNotes = reduxNotes ? reverse(cloneDeep(reduxNotes)) : [];
    const closeModal = () => {
        dispatch(closeNotesModal());
    };

    const addNewNote = (values, form) => {
        setAddingNote(true);
        return axios
            .post(`/api/agency/lease/${agreementInfo.id}/add-note`, values)
            .then(result => {
                setFormData({ note: '' });
                form.reset();
                setAddingNote(false);
                dispatch(addNoteSuccess(result.data.lease));
            })
            .catch(error => {
                setError(error);
                setAddingNote(false);
            });
    };

    return (
        <ModalDialog title="Notes" isOpen={isOpen} closeModal={closeModal} className="notes-modal" zIndex={7}>
            <Form onSubmit={addNewNote} initialValues={formData}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} className="add-note-form" noValidate>
                            <div className="add-new-group">
                                <FormTextMultiline name="note" label="Note" disabled={addingNote} />
                                <Button
                                    primary
                                    onClick={() => addNewNote(values, form)}
                                    disabled={!values.note || addingNote}
                                >
                                    Add
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Form>
            <NotesTable notesList={sortedNotes} allowDelete={false} setDisable={setAddingNote} />
        </ModalDialog>
    );
};

export default memo(NotesModal);
