import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { openNoSignatureModal } from '../../../../actions/user';
import { updateRentIncreaseDocument } from '../../../../actions/rentIncrease';
import '../../../../sass/dashboardContentTable.scss';
import '../../../../sass/documentListButton.scss';
import { HIDE_MESSAGE_TIME } from '../../../../config';

const RentIncreaseListButton = ({ doc, agentSignature, rentIncrease }) => {
    const dispatch = useDispatch();

    const [isEmailingRentIncrease, setIsEmailingRentIncrease] = useState(false);
    const [isEmailingRentIncreaseSuccess, setIsEmailingRentIncreaseSuccess] = useState(false);
    const [isEmailingRentIncreaseFail, setIsEmailingRentIncreaseFail] = useState(false);

    const emailPDF = (id, selectedRentIncrease) => {
        setIsEmailingRentIncrease(true);
        axios
            .post(`api/${selectedRentIncrease ? 'agency/lease' : 'document'}/${id}/pdf/rent-increase`, {
                rentIncrease: selectedRentIncrease,
                emailToMe: true
            })
            .then(result => {
                setIsEmailingRentIncrease(false);
                setIsEmailingRentIncreaseSuccess(true);
                setIsEmailingRentIncreaseFail(false);
                if (result.data.doc) {
                    dispatch(updateRentIncreaseDocument(result.data.doc));
                }
                setTimeout(() => {
                    setIsEmailingRentIncreaseSuccess(false);
                }, HIDE_MESSAGE_TIME);
            })
            .catch(() => {
                setIsEmailingRentIncrease(false);
                setIsEmailingRentIncreaseSuccess(false);
                setIsEmailingRentIncreaseFail(true);
                setTimeout(() => {
                    setIsEmailingRentIncreaseFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    const viewPdf = (docId, selectedRentIncrease) => {
        if (!isEmpty(agentSignature)) {
            if (selectedRentIncrease) {
                window.open(`/reader/lease/${docId}/rent-increase/${selectedRentIncrease.id}`, '_blank');
            } else {
                window.open(`/reader/document/rent-increase/${docId}`, '_blank');
            }
        } else {
            dispatch(openNoSignatureModal());
        }
    };

    return (
        <div className="document-buttons-container">
            <div className="document-buttons">
                <button
                    disabled={isEmailingRentIncrease}
                    className="btn-email"
                    onClick={() => emailPDF(doc.id, rentIncrease)}
                >
                    Send me PDF
                    {isEmailingRentIncrease && <span className="savestatus-spinner-await-completion" />}
                </button>
                <button className="btn-email" onClick={() => viewPdf(doc.id, rentIncrease)}>
                    View PDF
                </button>
            </div>
            {isEmailingRentIncreaseSuccess && (
                <p className="button-result savestatus-saved">
                    Email sent successfully
                    <span className="icon savestatus-icon" />
                </p>
            )}
            {isEmailingRentIncreaseFail && (
                <p className="button-result savestatus-failed">
                    Email failed <span className="icon savestatus-icon" />
                </p>
            )}
        </div>
    );
};

export default RentIncreaseListButton;
