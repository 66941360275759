import React from 'react';

function ItemDetails({ item }) {
    let details = '';
    // If there is a role display it inside []
    if (item.role) {
        details += `[${item.role}] `;
    }
    // If there is an email display it inside ()
    if (item.email) {
        // First check mobile and display it if not check for phone and display it
        // If both mobile and phone is missing then just display email
        if (item.mobile || item.phone) {
            details += `(${item.email} - ${item.mobile || item.phone})`;
        } else {
            details += `(${item.email})`;
        }
    }

    if (details) {
        return <React.Fragment>{details}</React.Fragment>;
    }
    return null;
}

export default ItemDetails;
