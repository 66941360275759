import {
    OPEN_CONFIRM_PLAN_UPGRADE_MODAL,
    CLOSE_CONFIRM_PLAN_UPGRADE_MODAL,
    UPDATE_PAYMENT_DETAILS_REQUEST,
    UPDATE_PAYMENT_DETAILS_SUCCESS,
    UPDATE_PAYMENT_DETAILS_FAIL,
    RETRY_PAYMENT_REQUEST,
    RETRY_PAYMENT_SUCCESS,
    RETRY_PAYMENT_FAIL,
    RESET_PAYMENT_STATE
} from '../actions/types';

import axios from 'axios';

import { upgradeSubscriptionModalStates as modalStates } from '../config';

export const openConfirmPlanUpgradeModal = activePlan => {
    return {
        type: OPEN_CONFIRM_PLAN_UPGRADE_MODAL,
        payload: {
            activePlan,
            modalType: modalStates.CONFIRM_UPGRADE
        }
    };
};

export const openCancelledPlanModal = () => {
    return {
        type: OPEN_CONFIRM_PLAN_UPGRADE_MODAL,
        payload: {
            modalType: modalStates.CANCELLED_PLAN
        }
    };
};

export const closeConfirmPlanUpgradeModal = () => {
    return {
        type: CLOSE_CONFIRM_PLAN_UPGRADE_MODAL
    };
};

export const dispatchUpdatingPaymentDetails = () => {
    return {
        type: UPDATE_PAYMENT_DETAILS_REQUEST
    };
};
export const dispatchUpdatingPaymentDetailsFail = error => {
    return {
        type: UPDATE_PAYMENT_DETAILS_FAIL,
        error: error
    };
};

export const updatePaymentDetails = (agencyId, data) => {
    return {
        types: [UPDATE_PAYMENT_DETAILS_REQUEST, UPDATE_PAYMENT_DETAILS_SUCCESS, UPDATE_PAYMENT_DETAILS_FAIL],
        promise: axios.post(`/api/agency/${agencyId}/payment-method`, data)
    };
};

export const retryPayment = agencyId => {
    return {
        types: [RETRY_PAYMENT_REQUEST, RETRY_PAYMENT_SUCCESS, RETRY_PAYMENT_FAIL],
        promise: axios.get(`/api/agency/${agencyId}/retry-payment`)
    };
};

export const resetPaymentState = () => {
    return {
        type: RESET_PAYMENT_STATE
    };
};
