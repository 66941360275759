import React from 'react';
import ModalDialog from '../../common/components/ModalDialog';
import EditVendor from './EditVendor';
import '../../sass/editModal.scss';

const EditVendorsModal = ({ isOpen, closeEditVendorsModal }) => {
    return (
        <ModalDialog
            title="Edit Vendors"
            isOpen={isOpen}
            closeModal={closeEditVendorsModal}
            shouldCloseOnOverlayClick={false}
            className="edit-signatory-modal"
        >
            <div>
                <EditVendor />
            </div>
        </ModalDialog>
    );
};

export default EditVendorsModal;
