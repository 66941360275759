import { createSelector } from 'reselect';

export const getResendLandlordInformationStatement = state => {
    return state.resendLandlordInformationStatement;
};

export const getIsResendLandlordInformationStatementModalOpen = createSelector(
    [getResendLandlordInformationStatement],
    resendLandlordInformationStatement =>
        resendLandlordInformationStatement.isResendLandlordInformationStatementModalOpen
);
