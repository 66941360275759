import React from 'react';
import CommonFooter from '../shared/CommonFooter.js';
import { EspApiModel, FormModel } from './EstimatedSellingPriceForm.model.js';
import { FormApi } from 'final-form';
interface EstimatedSellingPriceFormProps {
    isSendingEspNotice: boolean;
    isSendingEspNoticeSuccess: boolean;
    isSendingEspNoticeFail: boolean;
    currentDocument: EspApiModel;
    documentEditMode: boolean;
    previewPdf: () => void;
    viewPdf: (docId: string) => void;
    form: FormApi<FormModel>;
    isSaveDraft: boolean;
    showLoadingIcon: boolean;
}
const EstimatedSellingPriceFormFooter: React.FC<EstimatedSellingPriceFormProps> = ({
    isSendingEspNotice,
    isSendingEspNoticeSuccess,
    isSendingEspNoticeFail,
    currentDocument,
    documentEditMode,
    previewPdf,
    viewPdf,
    form,
    isSaveDraft,
    showLoadingIcon
}) => {
    // TODO: Resending handle in a separate ticket
    return (
        <CommonFooter
            isDoc={true}
            currentDocument={currentDocument}
            documentEditMode={documentEditMode}
            viewPdf={viewPdf}
            previewPdf={previewPdf}
            isResending={false}
            isSendingSuccess={isSendingEspNoticeSuccess}
            isSendingFail={isSendingEspNoticeFail}
            isSending={isSendingEspNotice}
            form={form}
            isSaveDraft={isSaveDraft}
            showLoadingIcon={showLoadingIcon}
            hideResend={true}
            dataTest="esp-notice-send-btn"
        />
    );
};
export default EstimatedSellingPriceFormFooter;
