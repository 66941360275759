import { FieldMetaState } from 'react-final-form';
import { setIn } from 'final-form';

/**
 * Get the error for an input, if we have frontend validation, we want to use the shouldBeTouched as this will only validate on submit or when the field was touched
 * @param meta
 * @param shouldBeTouched
 * @returns {*|boolean}
 */
export const getFormError = (meta: FieldMetaState<unknown>, shouldBeTouched = false) => {
    if (meta.error) {
        return shouldBeTouched ? meta.touched && meta.error : meta.error;
    } else if (meta.submitError && !meta.dirtySinceLastSubmit) {
        return meta.submitError;
    }
};

export const scrollToError = () => {
    const elementList = document.getElementsByClassName('FormError');
    if (elementList && elementList.length > 0) {
        elementList[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
};

export interface ErrorState {
    inner: {
        path: string;
        message: string;
    }[];
}

export function isValidationError(error: unknown): error is ErrorState {
    if (error && typeof error === 'object' && 'inner' in error) {
        return true;
    }

    return false;
}
type ValidationResult = { [key: string]: unknown };

export function hydrateValidationErrors(validator: () => void): ValidationResult | undefined {
    try {
        validator();
    } catch (err: unknown) {
        if (isValidationError(err)) {
            const errors = err.inner.reduce((formError, innerError) => {
                return setIn(formError, innerError.path, innerError.message);
            }, {});

            return errors;
        }

        throw err;
    }
}
