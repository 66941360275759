import React from 'react';
import { FormTextRegular, FormTextCurrency } from './../../../form/FormText';

export const MarketingPlanGroup = ({ index, removeItem }) => {
    const header = (
        <span className="marketing-plan-header">
            <h3>Service {index + 1}</h3>
            <div className="toggle" onClick={() => removeItem(index)}>
                <span className="span-remove">Remove</span>
            </div>
        </span>
    );

    return (
        <div>
            {header}
            <div className="form-half-col">
                <FormTextRegular name={`marketingPlans[${index}].description`} label="Description" required />
                <FormTextCurrency name={`marketingPlans[${index}].cost`} label="Cost $" precision="2" required/>
            </div>
        </div>
    );
};
