import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkRenewal } from '../../actions/renewal.js';
import { isCompletedAndValidated, getMissedStep } from '../../utils/agreementUtils.js';

import InfoAgreementModal from '../../components/dashboard/InfoAgreementModal.js';
import EditTenantsModal from '../../components/dashboard/EditTenantsModal.js';
import EditLandlordsModal from '../../components/dashboard/EditLandlordsModal.js';
import EditVendorsModal from '../../components/dashboard/EditVendorsModal.js';
import ExtendExpiryModal from '../../components/dashboard/ExtendExpiryModal.js';

import { getLeaseType, getLocation, getListLink } from '../../selectors/lease/index.js';
import { getAgreementInfo } from '../../selectors/dashboard/agreementInfo.js';
import {
    getIsOpenAgreementInfoModal,
    getIsOpenEditTenantsModal,
    getIsOpenEditLandlordsModal,
    getIsOpenEditVendorsModal,
    getIsOpenExtendExpiryModal
} from '../../selectors/dashboard/index.js';
import { getAddresses } from '../../selectors/lease/addresses.js';
import { getUser } from '../../selectors/user.js';
import * as dashboard from '../../actions/dashboard.js';
import * as lease from '../../actions/lease.js';
import * as renewal from '../../actions/renewal.js';
import * as leaseActions from '../../reducers/leaseWithRequest.js';

import { useOpenAgreement } from '../../hooks/useOpenAgreement.js';

const InfoAgreement: React.FC = () => {
    const dispatch = useDispatch();

    const agreementInfo: any = useSelector(getAgreementInfo);
    const leaseType = useSelector(getLeaseType);
    const location = useSelector(getLocation);
    const isOpenAgreementInfoModal = useSelector(getIsOpenAgreementInfoModal);
    const isOpenEditTenantsModal = useSelector(getIsOpenEditTenantsModal);
    const isOpenEditLandlordsModal = useSelector(getIsOpenEditLandlordsModal);
    const isOpenEditVendorsModal = useSelector(getIsOpenEditVendorsModal);
    const isOpenExtendExpiryModal = useSelector(getIsOpenExtendExpiryModal);
    const listLink = useSelector(getListLink);
    const addresses = useSelector(getAddresses);
    const loggedInUser = useSelector(getUser);

    const { openModalInfoAgreement } = useOpenAgreement();

    useEffect(() => {
        if (agreementInfo.status === dashboard.LEASE_STATUS_DRAFT && agreementInfo.invalidSteps.length > 0) {
            dispatch(dashboard.closeAgreementInfoModalWithoutAnyOtherAction());
            openModalInfoAgreement(agreementInfo, agreementInfo.leaseType);
        }
        if (isOpenAgreementInfoModal) {
            dispatch(lease.getInfoLease(agreementInfo.id));
        }
    }, [isOpenAgreementInfoModal]);

    const showChangeRequestForm = () => {
        dispatch(leaseActions.showForm());
    };

    const openLeaseRenewalModal = () => {
        dispatch(dashboard.closeAgreementInfoModalWithoutAnyOtherAction());
        dispatch(renewal.openLeaseRenewalModal(agreementInfo));
        dispatch(renewal.checkRenewal(agreementInfo.id));
    };

    const openSummaryView = () => {
        dispatch(dashboard.closeAgreementInfoModal());
        const step = getMissedStep(agreementInfo, agreementInfo.leaseType, agreementInfo.location);
        dispatch(lease.editLeaseAgreement(loggedInUser, agreementInfo, step));
    };

    return (
        <>
            <InfoAgreementModal
                isOpen={isOpenAgreementInfoModal}
                leaseAllInfo={agreementInfo}
                closeAgreementInfoModal={() => dispatch(dashboard.closeAgreementInfoModal())}
                closeAgreementInfoModalWithoutAnyOtherAction={() =>
                    dispatch(dashboard.closeAgreementInfoModalWithoutAnyOtherAction)
                }
                cloneAgreement={(leaseId: string) => dispatch(dashboard.cloneAgreement(leaseId))}
                openCloneResTenForm={(leaseId: string, leaseType: string) =>
                    dispatch(lease.openCloneResTenForm(leaseId, leaseType))
                }
                deleteAgreement={(leaseId: string) => dispatch(dashboard.deleteAgreement(leaseId))}
                closeSigning={(leaseId: string) => dispatch(dashboard.closeSigning(leaseId))}
                setSigning={(data: any) => dispatch(lease.setSigning(agreementInfo.id, data))}
                resendForSigning={(data: any) => dispatch(lease.resendForSigning(agreementInfo.id, data))}
                listLink={listLink}
                changeAgreementAgent={(data: any) => dispatch(dashboard.changeAgreementAgent(agreementInfo.id, data))}
                openLeaseRenewalModal={openLeaseRenewalModal}
                emailLeasePDF={(leaseId: string, data: any) => dispatch(lease.emailLeasePDF(leaseId, data))}
                emailRentIncreasePDF={(leaseId: string, data: any) =>
                    dispatch(lease.emailRentIncreasePDF(leaseId, data))
                }
                emailLeaseForManualSigning={(leaseId: string, data: any) =>
                    dispatch(lease.emailLeaseForManualSigning(leaseId, data))
                }
                emailOwnerCopyToAgent={(leaseId: string, data: any) =>
                    dispatch(lease.emailOwnerCopyToAgent(leaseId, data))
                }
                sendCompletedOwnerCopyToAgent={(leaseId: string, data: any) =>
                    dispatch(lease.sendCompletedOwnerCopyToAgent(leaseId, data))
                }
                addresses={addresses}
                getAddresses={(query: string) => dispatch(lease.getAddresses(query))}
                showChangeRequestForm={showChangeRequestForm}
                rejectRenewal={(leaseId: string) => dispatch(renewal.rejectRenewal(leaseId))}
                terminateLease={(leaseId: string, data: any) => dispatch(lease.terminateLease(leaseId, data))}
                isCompletedLease={isCompletedAndValidated(agreementInfo, leaseType, location)}
                openSummaryView={openSummaryView}
            />
            <EditTenantsModal
                isOpen={isOpenEditTenantsModal}
                closeEditTenantsModal={() => dispatch(dashboard.closeEditTenantsModal())}
            />
            <EditLandlordsModal
                isOpen={isOpenEditLandlordsModal}
                closeEditLandlordsModal={() => dispatch(dashboard.closeEditLandlordsModal())}
            />
            <EditVendorsModal
                isOpen={isOpenEditVendorsModal}
                closeEditVendorsModal={() => dispatch(dashboard.closeEditVendorsModal())}
            />
            <ExtendExpiryModal
                isOpen={isOpenExtendExpiryModal}
                closeExtendExpiryModal={() => dispatch(dashboard.closeExtendExpiryModal())}
            />
        </>
    );
};

export default memo(InfoAgreement);
