import axios from 'axios';
import * as Account from './../reducers/account';

export function updateDefaultPaymentDetails(agencyId, values) {
    values.isDefaultSettings = true;
    return {
        types: [
            Account.UPDATE_DEFAULT_PAYMENT_REQUEST,
            Account.UPDATE_DEFAULT_PAYMENT_SUCCESS,
            Account.UPDATE_DEFAULT_PAYMENT_FAIL
        ],
        promise: axios.put(`/api/agency/${agencyId}/payments/default`, values)
    };
}
