import { createSelector } from 'reselect';
import { DISCLOSURE_DOCUMENT_QUESTION } from '../../config';
import { getDocument } from './index';

export const getIsOpenDisclosureDocumentModal = createSelector(
    [getDocument],
    document => document.isOpenDisclosureDocumentModal
);

export const getDisclosureAvailableQuestions = createSelector(
    [getDocument],
    document => document.availableQuestions[DISCLOSURE_DOCUMENT_QUESTION]
);
