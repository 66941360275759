import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import AgentLogo from '../../AgentLogo';
import DeleteButton from '../../../common/components/DeleteButton';
import { addNoteSuccess } from '../../../actions/lease';
import '../../../sass/notes.scss';

const NotesTable = ({ notesList, allowDelete, setDisable }) => {
    const dispatch = useDispatch();
    const team = useSelector(state => state.agency.team);
    const deletedUsers = useSelector(state => state.agency.deletedUsers);
    const agreementInfo = useSelector(state => state.dashboard.agreementInfo);

    const fullTeam = [...team, ...deletedUsers];

    const deleteNote = noteId => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to delete this note?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setDisable(true);
                        axios
                            .delete(`/api/agency/lease/${agreementInfo.id}/delete-note/${noteId}`)
                            .then(result => {
                                setDisable(false);
                                dispatch(addNoteSuccess(result.data.lease));
                                return result;
                            })
                            .catch(error => {
                                setDisable(false);
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };
    return (
        <table className="notes-table">
            {notesList &&
                notesList.map((note, index) => {
                    const itemAgent = note.createdBy;
                    return (
                        <React.Fragment key={index}>
                            <tr>
                                <td className="agent-column">{itemAgent && <AgentLogo agent={itemAgent} />}</td>
                                <td>
                                    <b>{itemAgent ? itemAgent.fullName : ''}</b> - {note.createdText}
                                </td>
                                {!allowDelete && (
                                    <td className="delete-column">
                                        <DeleteButton
                                            onClick={() => {
                                                deleteNote(note.id);
                                            }}
                                            toolTip="Remove note"
                                        />
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <td colSpan={allowDelete ? 2 : 3} className="note-column">
                                    {note.note}
                                </td>
                            </tr>
                        </React.Fragment>
                    );
                })}
        </table>
    );
};

export default memo(NotesTable);
