import React, { memo } from 'react';
import HeaderBanner from './HeaderBanner';
import styles from './UpdateBanner.module.scss';
import ButtonSubscriptionInfo from './buttons/ButtonSubscription';
import { useSelector } from 'react-redux';
import { getUserInfo, isUserRoleAdmin } from '../../selectors/user.js';
import { User } from '../../types/User';

const SubscriptionBanner = () => {
    const loggedInUser: User = useSelector(getUserInfo);
    const isUserAdmin: boolean = useSelector(isUserRoleAdmin);

    if (!loggedInUser || !loggedInUser.agency?.tariffPlan) {
        return null;
    }
    return !loggedInUser.agency.tariffPlan.active ? (
        <HeaderBanner>
            <div className={styles.bannerBody}>
                <div className={styles.emoji}>
                    <span role="img" aria-label="waving hand">
                        &#x1F44B;
                    </span>
                </div>
                <div className={styles.text}>
                    Your subscription is currently inactive, please click the button to find out more
                </div>
                <ButtonSubscriptionInfo isUserAdmin={isUserAdmin} />
            </div>
        </HeaderBanner>
    ) : null;
};

export default SubscriptionBanner;
