/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop, identity } from 'lodash';
import '../../sass/searchBarResults.scss';
import {
    serverToStatus,
    serverToDocType,
    isLeaseTypeResidentialTenancy,
    isLeaseTypePm,
    isLeaseTypeSales,
    isDocTypeRentIncrease,
    isDocTypeCreateAFlk,
    isDocTypeFlkAPdf,
    isDocTypeTerminationNotice,
    isDocTypeRentRelief,
    isDocTypeBreachNotice,
    isLeaseTypeHolidayLetting,
    isLeaseTypeCommercialLease
} from '../../utils/agreementUtils';
import { escapeRegExp, union, isEmpty, forEach, get } from 'lodash';
import { formatDateShortMonth, getAgencyTimezoneFromUser } from '../../utils/dateUtils';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../selectors/user';
import { isAgencyUserAccount } from '../../utils/userUtils';

const NO_AGREEMENTS_RESULTS_MESSAGE = 'No agreements match your search.';
const NO_DOCUMENTS_RESULTS_MESSAGE = 'No documents match your search.';

function getHitsFromHighlight(highlight) {
    return highlight.texts.map(text => {
        if (text.type === 'hit') {
            return text.value;
        }
    });
}

const isTenant = string => {
    return string.startsWith('tenant');
};
const isLandlord = string => {
    return string.startsWith('landlord');
};
const isPmLandlord = string => {
    return string.startsWith('pmLandlord');
};
const isVendor = string => {
    return string.startsWith('signatory');
};
const isLessor = string => {
    return string.startsWith('lessor');
};
const isPrincipal = string => {
    return string.startsWith('principal');
};

function getRecipientType(leaseType) {
    if (isLeaseTypeResidentialTenancy(leaseType)) {
        return 'tenant';
    } else if (isLeaseTypeCommercialLease(leaseType)) {
        return 'lessor';
    } else if (isLeaseTypeHolidayLetting(leaseType)) {
        return 'principal';
    } else if (isLeaseTypeSales(leaseType)) {
        return 'signatory';
    } else if (isLeaseTypePm(leaseType)) {
        return 'pmLandlord';
    }
}

/**
 * Combine the highlights to a string for testing
 * @param item
 * @returns {JSX.Element}
 */
function highlightsToString(item) {
    const recipientType = getRecipientType(item.leaseType);
    const highlights = item.highlights;

    const matches = {};

    let highlightsText = '';

    /**
     * Go over the highlights and change the structure, so it becomes easier to show on the results what is what
     */
    if (highlights && highlights.length > 0) {
        highlights.forEach(highlight => {
            // if the match includes the name (secondName, firstname)
            if (highlight.path.includes('Name')) {
                if (item.schemaVersion) {
                    matches[`${recipientType}.name`] = union(
                        getHitsFromHighlight(highlight),
                        matches[`${recipientType}.name`] || []
                    );
                } else if (isTenant(highlight.path)) {
                    matches['tenant.tenantsListText'] = union(
                        getHitsFromHighlight(highlight),
                        matches['tenant.tenantsListText'] || []
                    );
                } else if (isPmLandlord(highlight.path)) {
                    matches['pmLandlord.pmLandlordListText'] = union(
                        getHitsFromHighlight(highlight),
                        matches['pmLandlord.pmLandlordListText'] || []
                    );
                } else if (isLandlord(highlight.path)) {
                    matches['landlord.landlordsListText'] = union(
                        getHitsFromHighlight(highlight),
                        matches['landlord.landlordsListText'] || []
                    );
                } else if (isVendor(highlight.path)) {
                    matches['signatory.signatoryListText'] = union(
                        getHitsFromHighlight(highlight),
                        matches['signatory.signatoryListText'] || []
                    );
                }
            } else if (highlight.path.includes('email')) {
                if (item.schemaVersion) {
                    matches[`${recipientType}.email`] = union(
                        getHitsFromHighlight(highlight),
                        matches[`${recipientType}.email`] || []
                    );
                } else if (isTenant(highlight.path)) {
                    matches['tenant.tenantsListEmailText'] = union(
                        getHitsFromHighlight(highlight),
                        matches['tenant.tenantsListEmailText'] || []
                    );
                } else if (isPmLandlord(highlight.path)) {
                    matches['pmLandlord.pmLandlordListEmaiText'] = union(
                        getHitsFromHighlight(highlight),
                        matches['pmLandlord.pmLandlordListEmaiText'] || []
                    );
                } else if (isVendor(highlight.path)) {
                    matches['signatory.signatoryListEmailText'] = union(
                        getHitsFromHighlight(highlight),
                        matches['signatory.signatoryListEmailText'] || []
                    );
                }
            } else {
                matches['matches'] = union(getHitsFromHighlight(highlight), matches['matches'] || []);
            }
        });
    }

    /**
     * Run over the matches
     * Get the long text from the item, then replace it so it shows in red and add a label in front of it
     */
    if (!isEmpty(matches)) {
        forEach(matches, (match, index) => {
            let dataField = get(item, index);

            if (item.schemaVersion) {
                if (index.split('.')[1] === 'name') {
                    dataField = item.searchIndex.recipients
                        .map(({ firstName, lastName }) => [firstName, lastName].filter(Boolean).join(' '))
                        .filter(Boolean)
                        .join(', ');
                } else if (index.split('.')[1] === 'email') {
                    dataField = item.searchIndex.recipients
                        .map(({ email }) => email)
                        .filter(Boolean)
                        .join(', ');
                }
            }

            let label = 'Matches: ';
            if (isTenant(index)) {
                label = 'Tenant(s): ';
            } else if (isLandlord(index) || isPmLandlord(index)) {
                label = 'Landlord(s): ';
            } else if (isVendor(index)) {
                label = 'Vendor(s): ';
            } else if (isLessor(index)) {
                label = 'Lessor(s): ';
            } else if (isPrincipal(index)) {
                label = 'Principal(s): ';
            }

            // if data was found in the item to display
            if (dataField) {
                highlightsText = label + dataField;
                // replace text with red text for each match
                match.forEach(value => {
                    highlightsText = replaceTextHighlight(highlightsText, value);
                });
            } else {
                if (match.length > 1) {
                    // don't do anything as we don't want to piece the string on which it matched together
                    // This happens when it matches on partial numbers, so if I search for +61411595337 I might get matches:
                    // [ 411, 959 ] etc. just hide this as it doesn't help the user
                } else {
                    // this will display the rest of matches, example: phone number matching
                    // Search +61400000000: if it matches the whole number that would come back here
                    highlightsText = label + replaceTextHighlight(match[0], match[0]);
                }
            }
        });
    }

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: highlightsText
            }}
        />
    );
}

/**
 * This functions runs through the highlights we get from mongo atlas search
 * This then highlights the text that the search has matched with
 * If there are no highlights it will use the query to highlight what was typed in in the results
 * @param highlights
 * @param text
 * @param path
 * @param query
 * @returns {JSX.Element|*}
 */
function highlightQuery(highlights, text, path, query) {
    if (!highlights && !query) {
        return text;
    }
    let newText = text;
    if (highlights && highlights.length > 0) {
        highlights.forEach(highlight => {
            if (highlight.texts.length > 0 && highlight.path === path) {
                highlight.texts.forEach(highlightText => {
                    if (highlightText.type === 'hit') {
                        const reg = new RegExp(escapeRegExp(highlightText.value), 'gi');
                        newText = newText.replace(reg, `<span class='highlighted-text'>${highlightText.value}</span>`);
                    }
                });
            }
        });
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: newText
                }}
            />
        );
    } else {
        newText = replaceTextHighlight(newText, query);
    }

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: newText
            }}
        />
    );
}

function replaceTextHighlight(text, replaceValue) {
    let newText = text;
    if (replaceValue) {
        const reg = new RegExp(escapeRegExp(replaceValue), 'gi');
        if (newText) {
            newText = newText.replace(reg, `<span class='highlighted-text'>${replaceValue}</span>`);
        }
    }
    return newText;
}

const Term = ({ item }) => {
    const loggedInUser = useSelector(getUserInfo);
    return (
        <React.Fragment>
            {isLeaseTypeResidentialTenancy(item.leaseType) && <p>{item.term && item.term.periodText}</p>}
            {isLeaseTypeResidentialTenancy(item.leaseType) &&
                item.rent &&
                item.rent.rentIncreaseList &&
                item.rent.rentIncreaseList.length > 0 &&
                item.rent.rentIncreaseList.map(document => (
                    <p key={document.id}>
                        Rent increase:{' '}
                        {formatDateShortMonth(
                            new Date(document.rentIncrease.newRentDateStart),
                            getAgencyTimezoneFromUser(loggedInUser)
                        )}{' '}
                        &rarr; &uarr;{' $'}
                        {document.rentIncrease.newRentAmount}
                    </p>
                ))}
            {isLeaseTypeResidentialTenancy(item.leaseType) &&
                item.leaseTerminations &&
                item.leaseTerminations.length > 0 &&
                item.leaseTerminations.map(document => (
                    <p key={document.id}>
                        Termination notice:{' '}
                        {formatDateShortMonth(
                            new Date(document.vacantPossessionDate),
                            getAgencyTimezoneFromUser(loggedInUser)
                        )}
                    </p>
                ))}
            {isLeaseTypeSales(item.leaseType) && <p>{item.term && item.term.startDateFormattedNormal}</p>}
            {isLeaseTypePm(item.leaseType) && (
                <p>{item.pmAgreementTerm && item.pmAgreementTerm.startDateFormattedNormal}</p>
            )}
            {(isDocTypeFlkAPdf(item.docType) ||
                isDocTypeCreateAFlk(item.docType) ||
                isDocTypeRentRelief(item.docType) ||
                isDocTypeTerminationNotice(item.docType) ||
                isDocTypeBreachNotice(item.docType) ||
                isDocTypeRentIncrease(item.docType)) &&
                item.sentForSigning && <p>Sent: {item.sentForSigningDateFormatted}</p>}
        </React.Fragment>
    );
};

const Signatories = ({ item, query }) => (
    <React.Fragment>
        {isLeaseTypeResidentialTenancy(item.leaseType) && (
            <small>
                {highlightQuery(
                    item.highlights,
                    item.tenant && item.tenant.tenantsLastNames && `( ${item.tenant.tenantsLastNames} )`,
                    'tenant.tenants.secondName',
                    query
                )}
            </small>
        )}
        {isLeaseTypeCommercialLease(item.leaseType) && (
            <small>
                {highlightQuery(
                    item.highlights,
                    item.lessor && item.lessor.lessorsLastNames && `( ${item.lessor.lessorsLastNames} )`,
                    'lessor.persons.secondName',
                    query
                )}
            </small>
        )}
        {isLeaseTypeHolidayLetting(item.leaseType) && (
            <small>
                {highlightQuery(
                    item.highlights,
                    `${item.searchIndex.recipients.map(({ lastName }) => lastName).join(', ') ? `( ${item.searchIndex.recipients.map(({ lastName }) => lastName).join(', ')} )` : ''}`,
                    'searchIndex.recipients.lastName',
                    query
                )}
            </small>
        )}
        {isLeaseTypeSales(item.leaseType) && (
            <small>
                {item.schemaVersion
                    ? highlightQuery(
                          item.highlights,
                          `${item.searchIndex.recipients.map(({ lastName }) => lastName).join(', ') ? `( ${item.searchIndex.recipients.map(({ lastName }) => lastName).join(', ')} )` : ''}`,
                          'searchIndex.recipients.lastName',
                          query
                      )
                    : highlightQuery(
                          item.highlights,
                          item.signatory &&
                              item.signatory.signatoryLastNames &&
                              `( ${item.signatory.signatoryLastNames} )`,
                          'signatory.signatories.secondName',
                          query
                      )}
            </small>
        )}
        {isLeaseTypePm(item.leaseType) && (
            <small>
                {item.schemaVersion
                    ? highlightQuery(
                          item.highlights,
                          `${item.searchIndex.recipients.map(({ lastName }) => lastName).join(', ') ? `( ${item.searchIndex.recipients.map(({ lastName }) => lastName).join(', ')} )` : ''}`,
                          'searchIndex.recipients.lastName',
                          query
                      )
                    : highlightQuery(
                          item.highlights,
                          item.pmLandlord &&
                              item.pmLandlord.landLordLastNames &&
                              `( ${item.pmLandlord.landLordLastNames} )`,
                          'pmLandlord.persons.secondName',
                          query
                      )}
            </small>
        )}
        {(isDocTypeRentIncrease(item.docType) ||
            isDocTypeTerminationNotice(item.docType) ||
            isDocTypeBreachNotice(item.docType)) && (
            <small>
                {item && item.tenant && item.tenant.tenantsLastNames && `( ${item.tenant.tenantsLastNames} )`}
            </small>
        )}
        {isDocTypeCreateAFlk(item.docType) && (
            <small>{item.to && item.to.clientsListText && `( ${item.to.clientsListText} )`}</small>
        )}
        {isDocTypeFlkAPdf(item.docType) && (
            <small>{item.client && item.client.clientsListText && `( ${item.client.clientsListText} )`}</small>
        )}
        {isDocTypeRentRelief(item.docType) && (
            <small>
                {item.rentReductionAgreement &&
                    item.rentReductionAgreement.tenantName &&
                    `( ${item.rentReductionAgreement.tenantName} )`}
            </small>
        )}
    </React.Fragment>
);

function SearchBarResults({
    items,
    highlightedIndex,
    getItemProps,
    menuRef,
    separatorIndex,
    displayMessage,
    query,
    ...props
}) {
    const loggedInUser = useSelector(getUserInfo);
    const hasItems = items.length > 0;
    let agreementResults = [];
    let documentResults = [];

    function getSearchItem(item, index) {
        return (
            <li
                {...getItemProps({
                    key: item.id,
                    index,
                    item,
                    className: classnames({
                        highlighted: highlightedIndex === index
                    })
                })}
                // adding this so our frontend test can recognise which ones need to be renewed and which one does not
                data-is-renewed={`${item.isRenewed}`}
            >
                <strong>
                    {highlightQuery(
                        item.highlights,
                        item.address || item.documentTitle,
                        item.address ? 'address' : 'documentTitle',
                        query
                    )}
                </strong>{' '}
                <Signatories item={item} query={query} />
                <p>
                    {item.leaseType ? item.leaseType : serverToDocType[item.docType]} &rarr;{' '}
                    {serverToStatus[item.status]}
                </p>
                <Term item={item} />
                {/*
                Leave for potentially debugging
                <p>
                    Score: <span className="debuginfo">{item.score}</span>
                </p>*/}
                <p>{highlightsToString(item)}</p>
            </li>
        );
    }

    if (hasItems) {
        // pre-render the items...
        items.map((item, index) => {
            if (item.leaseType) {
                agreementResults.push(getSearchItem(item, index));
            } else {
                documentResults.push(getSearchItem(item, index));
            }
        });

        // ... so we can inject the separator
        if (separatorIndex > 0 && separatorIndex < items.length) {
            agreementResults.splice(separatorIndex, 0, <li key="___SEPARATOR___" className="separator" />);
        }
    }

    return (
        <div>
            <ul {...props} className="search-bar-results" ref={menuRef}>
                {isAgencyUserAccount(loggedInUser.accountType) && (
                    <React.Fragment>
                        <li className="result-section-title">
                            <span>Agreements</span> -{' '}
                            <small>
                                Displaying top 10 results, enter more specific search information if not shown below
                            </small>
                        </li>
                        {agreementResults.length > 0 ? (
                            agreementResults
                        ) : (
                            <li className="no-results">
                                <span>{displayMessage ? displayMessage : NO_AGREEMENTS_RESULTS_MESSAGE}</span>
                            </li>
                        )}
                    </React.Fragment>
                )}
                <li className="result-section-title">
                    <span>Documents</span> -{' '}
                    <small>Displaying top 10 results, enter more specific search information if not shown below</small>
                </li>
                {documentResults.length > 0 ? (
                    documentResults
                ) : (
                    <li className="no-results">
                        <span>{displayMessage ? displayMessage : NO_DOCUMENTS_RESULTS_MESSAGE}</span>
                    </li>
                )}
            </ul>
        </div>
    );
}

SearchBarResults.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            leaseType: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired
        })
    ),
    highlightedIndex: PropTypes.number,
    getItemProps: PropTypes.func,
    menuRef: PropTypes.func,
    separatorIndex: PropTypes.number,
    displayMessage: PropTypes.string
};

SearchBarResults.defaultProps = {
    items: [],
    highlightedIndex: -1,
    getItemProps: identity,
    menuRef: noop,
    separatorIndex: -1,
    displayError: false
};

export default SearchBarResults;
