import React, { useState } from 'react';
import { isEmpty, pullAt, uniq } from 'lodash';

import { FormTextRegular } from './FormText';
import * as validators from '../Validate';

/**
 * We can use this component inside form to get list of unique emails.
 * ex: <EmailList name={'emailAddress'} value={values.emailAddress} form={form} />
 */
const EmailList = ({ name, value, form, inputField, disabled = false }) => {
    let [hasEmailFormatError, setHasEmailFormatError] = useState(false);

    const validateEmail = value => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setHasEmailFormatError(true);
            return false;
        }

        return true;
    };
    const addEmail = email => {
        setHasEmailFormatError(false);

        let isValid = validateEmail(email);
        if (!isValid) {
            return;
        }

        let emails = [...value];
        emails.push(email);

        form.change(name, uniq(emails));
        form.change(inputField, '');
    };

    const removeEmail = index => {
        if (!disabled) {
            let emails = [...value];

            pullAt(emails, index);

            form.change(name, uniq(emails));
        }
    };

    const pressEnter = event => {
        if (!disabled) {
            let emailAddress = event.target.value;

            if (event.keyCode === 13) {
                event.preventDefault();
                addEmail(emailAddress);
            }
        }
    };

    const handleBlur = event => {
        if (!disabled) {
            let emailAddress = event.target.value;
            addEmail(emailAddress);
        }
    };
    return (
        <div>
            <ul className={`emails-list ${disabled ? 'disabled' : ''}`}>
                {value &&
                    value.map((email, index) => (
                        <li className="individual-email" key={index}>
                            {email}
                            <button className="btn-delete" type="button" onClick={() => removeEmail(index)} />
                        </li>
                    ))}
            </ul>

            <FormTextRegular
                name={inputField}
                label="Enter email and press enter"
                onKeyDown={e => pressEnter(e)}
                onBlur={e => handleBlur(e)}
                disabled={disabled}
            />

            {hasEmailFormatError && <p className="has-error">Wrong email format</p>}
        </div>
    );
};
export default EmailList;
