import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import produce from 'immer';
import { LEASE_LANDLORD_SUCCESS } from '../../reducers/lease';
export const LANDLORD_FORM_ADD_PERSON = 'dashboard.lease.landlord.addPerson';
export const LANDLORD_FORM_REMOVE_PERSON = 'dashboard.lease.landlord.removePerson';

const initState = {
    persons: [{ firstName: '', secondName: '' }],
    phone: '',
    noticeAddress: '',
    noticeEmailAddress: '',
    useAgencyEmailAddress: true,
    useAgencyAddress: true,
    useAgentsPhone: true,
    landlordConsentToElectronicService: true,
    errors: null,
    servicesOfNoticesEmails: [],
    superiorTitles: []
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS: {
            const { lease } = action.result.data;
            if (lease.landlord) {
                draftState = lease.landlord;
            } else {
                draftState = {...initState};
            }
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = Object.assign({}, initState, action.payload.agreementInfo.landlord);
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = Object.assign({}, initState, action.lease.landlord);
            break;
        }
        case LANDLORD_FORM_ADD_PERSON: {
            draftState.persons = [...draftState.persons, { firstName: '', secondName: '' }];
            break;
        }
        case LANDLORD_FORM_REMOVE_PERSON: {
            draftState.persons = [
                ...draftState.persons.slice(0, action.index),
                ...draftState.persons.slice(action.index + 1)
            ];
            break;
        }
        case LEASE_LANDLORD_SUCCESS: {
            draftState = action.result.data.lease.landlord;
            break;
        }
    }

    return draftState;
});
