import * as Notification from '../reducers/notification';
import axios from 'axios';

/**
 * Get all requests to change.
 */
export function getRequestsToChange() {
    return {
        types: [
            Notification.GET_ALL_NOTIFICATIONS_REQUEST,
            Notification.GET_ALL_NOTIFICATIONS_SUCCESS,
            Notification.GET_ALL_NOTIFICATIONS_FAIL
        ],
        promise: axios.get('/api/agency/notification/all')
    };
}

export function clearResolvedNotifications() {
    return {
        type: Notification.CLEAR_RESOLVED_NOTIFICATIONS
    };
}

export function socketUpdateOrAddNotification(notification) {
    return {
        type: Notification.UPDATE_OR_ADD_NOTIFICATION,
        data: {
            notification
        }
    };
}
export function socketRemoveNotification(notification) {
    return {
        type: Notification.REMOVE_NOTIFICATION,
        data: {
            notification
        }
    };
}
