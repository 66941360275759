import React, { useState } from 'react';
import { getSalesStepNo } from '../../../../../../utils/agreementUtils';
import ModalViewMaterialFacts from '../../../../../modals/sales/ModalViewMaterialFacts';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import DataString from '../../bloks/DataString';
import { NO_LABEL, YES_LABEL } from '../../../../../../config';

export default function AgreementResidentialMaterialFacts({ leaseAllInfo }) {
    const partName = 'materialFacts';
    const { materialFacts, location } = leaseAllInfo;
    const [isOpenModalViewMaterialFacts, setIsOpenModalViewMaterialFacts] = useState(false);

    const showMaterialFactsModal = () => {
        setIsOpenModalViewMaterialFacts(true);
    };

    const closeMaterialFactsModal = () => {
        setIsOpenModalViewMaterialFacts(false);
    };

    return (
        <AgreementSection title="Material Facts" step={getSalesStepNo(location, partName)} leaseAllInfo={leaseAllInfo}>
            <div className="data data-row">
                <p>
                    <span className="material-link" onClick={showMaterialFactsModal}>
                        View material facts
                    </span>
                </p>
                <DataString
                    title={'Ask client to provide material facts:'}
                    text={materialFacts.askClientToDisclose ? YES_LABEL : NO_LABEL}
                />
            </div>
            <ModalViewMaterialFacts
                isOpen={isOpenModalViewMaterialFacts}
                closeModal={closeMaterialFactsModal}
                materialFacts={materialFacts?.clauseList || []}
            />
        </AgreementSection>
    );
}
