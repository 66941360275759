import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';

import '../../../../../sass/infoAgreement.scss';

type Clause = {
    description: string;
    formattedAnswer: string;
};

type ComplianceChecklistAnswers = {
    clauseList: Clause[];
};

interface AgreementResidentialComplianceChecklistProps {
    leaseAllInfo: {
        complianceChecklistAnswers: ComplianceChecklistAnswers;
    };
}

function AgreementResidentialComplianceChecklist({
    leaseAllInfo
}: AgreementResidentialComplianceChecklistProps): JSX.Element {
    return (
        <div>
            {leaseAllInfo?.complianceChecklistAnswers?.clauseList?.length > 0 && (
                <AgreementSection title="Checklist" step={1} leaseAllInfo={leaseAllInfo}>
                    <div className="data">
                        {leaseAllInfo.complianceChecklistAnswers.clauseList.map((clause, index) => {
                            return (
                                <p key={index} className="text">
                                    <b>{clause.description}</b>: {clause.formattedAnswer}
                                </p>
                            );
                        })}
                    </div>
                </AgreementSection>
            )}
        </div>
    );
}

export default AgreementResidentialComplianceChecklist;
