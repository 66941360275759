import React, { Component } from 'react';
import '../../sass/popupAccountSettings.scss';
import appHistory from './../../AppHistory';

class PopupAccountSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    cloneAgreement() {
        this.props.cloneAgreement();
    }

    deleteAgreement() {
        this.props.deleteAgreement();
    }

    logOut() {
        this.props.logOut();
    }

    render() {
        let { isAccountSettingsPopupOpen } = this.props;
        return (
            <div className={isAccountSettingsPopupOpen ? 'popup-account-settings' : 'popup-account-settings hide'}>
                <p
                    className="popup-account-settings-p menu-settings desktop-only"
                    onClick={() => appHistory.push('/user/profile')}
                >
                    Settings
                </p>
                <p className="popup-account-settings-p menu-logout" onClick={() => this.logOut()}>
                    Log out
                </p>
            </div>
        );
    }
}

export default PopupAccountSettings;
