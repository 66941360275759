import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo, getSalesStepTitle, isLeaseLocationNSW } from '../../../../../utils/agreementUtils';

export default function AgreementSalesLicensee({ leaseAllInfo }) {
    const partName = 'licensee';
    if (!leaseAllInfo.licensee) {
        return <></>;
    }
    const { location } = leaseAllInfo;

    let sectionTitle = getSalesStepTitle(location, partName);
    if (isLeaseLocationNSW(location)) {
        sectionTitle = leaseAllInfo.licensee.isConjunctAgent ? 'Licensee and Conjunct Agent' : sectionTitle;
    }
    return (
        <div>
            <AgreementSection
                title={sectionTitle}
                step={getSalesStepNo(leaseAllInfo.location, partName)}
                leaseAllInfo={leaseAllInfo}
            >
                <div className="data">
                    <p className="name">{leaseAllInfo.licensee.fullName}</p>
                    <p className="name">{leaseAllInfo.licensee.agencyName}</p>
                    <p className="name">{leaseAllInfo.licensee.companyName}</p>
                    <p className="abn">ABN: {leaseAllInfo.licensee.abn}</p>
                    <p className="licenceNo">Licence No: {leaseAllInfo.licensee.licenceNo}</p>
                    {leaseAllInfo.licensee.licenceExpiry && (
                        <p className="licenceExpiry">Licence Expiry: {leaseAllInfo.licensee.licenceExpiryFormatted}</p>
                    )}
                    <p className="address">{leaseAllInfo.licensee.address}</p>
                    <p className="mobile">Mobile: {leaseAllInfo.licensee.mobile}</p>
                    {leaseAllInfo.licensee.phone && <p className="phone">Phone: {leaseAllInfo.licensee.phone}</p>}
                    {leaseAllInfo.licensee.fax && <p className="fax">Fax: {leaseAllInfo.licensee.fax}</p>}
                    <p className="email">{leaseAllInfo.licensee.email}</p>
                    {leaseAllInfo.licensee.isRegisteredForGst && <p>Registered for GST</p>}
                    {leaseAllInfo.licensee.salesLicenseeType && (
                        <p>Licensee Type: {leaseAllInfo.licensee.salesLicenseeType}</p>
                    )}
                </div>
                {leaseAllInfo.licensee.isConjunctAgent && (
                    <div className="data">
                        {leaseAllInfo.licensee.agencyName && (
                            <p className="name">{leaseAllInfo.licensee.conjunctAgent.agencyName}</p>
                        )}
                        <p className="name">{leaseAllInfo.licensee.conjunctAgent.companyName}</p>
                        <p className="abn">ABN: {leaseAllInfo.licensee.conjunctAgent.abn}</p>
                        <p className="licenceNo">Licence No: {leaseAllInfo.licensee.conjunctAgent.licenceNo}</p>
                        <p className="address">{leaseAllInfo.licensee.conjunctAgent.address}</p>
                        <p className="phone">Phone: {leaseAllInfo.licensee.conjunctAgent.phone}</p>
                        {leaseAllInfo.licensee.conjunctAgent.fax && (
                            <p className="fax">Fax: {leaseAllInfo.licensee.conjunctAgent.fax}</p>
                        )}
                        <p className="email">{leaseAllInfo.licensee.conjunctAgent.email}</p>
                        {leaseAllInfo.licensee.conjunctAgent.isRegisteredForGst && <p>Registered for GST</p>}
                    </div>
                )}
            </AgreementSection>
        </div>
    );
}
