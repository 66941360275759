import { parseISO } from 'date-fns';
import { DELIVERY_TO_AGENT_AND_VENDOR, FormSubmitType, STATUS_DRAFT } from '../../../../config';
import { array, object, string } from 'yup';
import { Agent } from '../../../../types/Agent';
import * as validators from '../../../../components/Validate';

export type Principal = {
    name: string;
    email: string;
    phone?: string;
    address: string;
    ABN?: string;
    ACN?: string;
};

export type AttachedDocument = {
    documentName: string;
    fileName: string;
    file: string;
    size: string;
    category: string;
    leaseType?: string;
};

export type AdditionalLink = {
    title: string;
    url: string;
};

export type AgreementInfoType = {
    id: string;
    address: string;
    location: string;
    addressDetails: string;
    agent: Agent;
    term: {
        startDate: string;
    };
    signatory: {
        signatories: [
            {
                fullName: string;
                email: string;
                phone: string;
                address: string;
                ABN: string;
                ACN: string;
                isCorporation: boolean;
            }
        ];
    };
};

export type FormModel = {
    id?: string;
    address: string;
    location: string;
    agentName: string | null;
    agent: Agent | null;
    addressDetails: string;
    submitType?: FormSubmitType;
    salesAgencyAgreementDate: Date | null;
    nameOfAuthorisedPerson: string;
    nameOfpersonServing: string;
    revisedEsp: string;
    evidence: string;
    reason: string;
    documents?: [AttachedDocument] | [];
    principals: [Principal] | [];
    deliveryType: string;
    deliveryTypeDetails: string;
    completion: object;
    dateOfNotice: Date | null;
    additionalLinks?: [AdditionalLink] | [];
    additionalLinkTitle?: string;
    additionalLinkUrl?: string;
    emailAddresses?: [string] | [];
    ccEmailAddresses?: [string] | [];
    emailTo?: string;
    hasSalesAgreement?: boolean;
    salesAgreementId?: string | null;
};

export type EspUiModel = {
    isReadOnly?: boolean;
};

export type EspDetailsApiModel = {
    dateOfNotice: string | null;
    deliveryType: string;
    deliveryTypeDetails: string;
    salesAgencyAgreementDate: string | null;
    authorisedPerson: string;
    nameOfpersonServing: string;
    revisedEsp: string;
    evidence: string;
    reason: string;
    emailTo?: string;
    emailAddresses?: [string] | [];
    ccEmailAddresses?: [string] | [];
    documents?: [AttachedDocument] | [];
    vendors: [Principal] | [];
    linksList?: {
        links: [AdditionalLink] | [];
    };
};

export type BaseApiModel = {
    id?: string;
    address: string;
    location: string;
    addressDetails: string;
    status?: string;
    docType?: string;
    agent: Agent | null;
    hasSalesAgreement: boolean;
    salesAgreementId: string | null;
};

export type ApiModel = BaseApiModel & EspDetailsApiModel;

export type EspApiModel = BaseApiModel & {
    details: EspDetailsApiModel;
    disabled: boolean;
};

export type EspApiModelResponse = {
    data: {
        espNotice: EspApiModel;
    };
};

export const EspFormSchema = object().shape({
    salesAgencyAgreementDate: string().required('Date the sales agency agreement was signed is required'),
    revisedEsp: string().required('Revised ESP is required'),
    evidence: string().required('Evidence is required'),
    reason: string().required('Reason is required'),
    deliveryType: string().required('Delivery type is required'),
    nameOfAuthorisedPerson: string(),
    dateOfNotice: string().required('Date of notice is required'),
    principals: array().of(
        object().shape({
            name: string().required('Name is required'),
            email: string().required('Email is required'),
            phone: string().nullable(),
            address: string().required('Address is required'),
            ABN: string()
                .test('isCorrectABN', 'ABN is not valid', value => {
                    if (!value) return true;
                    return validators.isCorrectABN(value);
                })
                .nullable(),
            ACN: string()
                .test('isCorrectABN', 'ACN is not valid', value => {
                    if (!value) return true;
                    return validators.isCorrectACN(value);
                })
                .nullable()
        })
    )
});

export const projectEspApiModel = (values: FormModel): ApiModel => {
    return {
        id: values.id,
        address: values.address,
        location: values.location,
        addressDetails: values.addressDetails,
        authorisedPerson: values.nameOfAuthorisedPerson,
        nameOfpersonServing: values.nameOfpersonServing,
        salesAgencyAgreementDate: values.salesAgencyAgreementDate?.toISOString() ?? null,
        deliveryType: values.deliveryType,
        deliveryTypeDetails: values.deliveryTypeDetails,
        emailTo: values.emailTo,
        documents: values.documents,
        emailAddresses: values.emailAddresses,
        ccEmailAddresses: values.ccEmailAddresses,
        dateOfNotice: values.dateOfNotice?.toISOString() ?? null,
        revisedEsp: values.revisedEsp,
        evidence: values.evidence,
        reason: values.reason,
        vendors: values.principals,
        linksList: {
            links: values.additionalLinks ?? []
        },
        agent: values.agent,
        hasSalesAgreement: values.hasSalesAgreement ?? false,
        salesAgreementId: values.salesAgreementId ?? null
    };
};

export const projectFormModel = (values: EspApiModel): FormModel => {
    return {
        id: values.id,
        address: values.address,
        location: values.location,
        addressDetails: values.addressDetails,
        salesAgencyAgreementDate: values.details.salesAgencyAgreementDate
            ? parseISO(values.details.salesAgencyAgreementDate)
            : null,
        nameOfAuthorisedPerson: values.details.authorisedPerson,
        nameOfpersonServing: values.details.nameOfpersonServing,
        revisedEsp: values.details.revisedEsp,
        agentName: values?.agent?.fullName ?? '',
        agent: values.agent,
        principals: values.details.vendors ?? [generateNewPrincipal()],
        additionalLinks: values?.details?.linksList?.links ?? [],
        documents: values.details.documents,
        deliveryType: values.details.deliveryType,
        deliveryTypeDetails: values.details.deliveryTypeDetails,
        emailTo: values.details.emailTo,
        emailAddresses: values.details.emailAddresses,
        ccEmailAddresses: values.details.ccEmailAddresses,
        dateOfNotice:
            values.details.dateOfNotice && values.status !== STATUS_DRAFT
                ? parseISO(values.details.dateOfNotice)
                : parseISO(new Date().toISOString()),
        evidence: values.details.evidence,
        reason: values.details.reason,
        completion: {},
        submitType: FormSubmitType.Save
    };
};

export const projectFormSalesAgreementInfo = (values: AgreementInfoType): FormModel => {
    return {
        address: values.address,
        addressDetails: values.addressDetails,
        location: values.location,
        agentName: values.agent.fullName ?? '',
        agent: values.agent,
        salesAgencyAgreementDate: parseISO(values.term.startDate),
        nameOfAuthorisedPerson: '',
        nameOfpersonServing: values.agent.fullName,
        revisedEsp: '',
        evidence: '',
        reason: '',
        deliveryType: '',
        deliveryTypeDetails: '',
        emailTo: '',
        emailAddresses: [],
        ccEmailAddresses: [],
        dateOfNotice: null,
        principals: mapPrincipalsFromSalesAgreement(values),
        additionalLinks: [],
        documents: [],
        completion: {},
        submitType: FormSubmitType.Save,
        hasSalesAgreement: true,
        salesAgreementId: values.id
    };
};

export const generateNewPrincipal = (): Principal => {
    return {
        name: '',
        email: '',
        address: '',
        phone: '',
        ABN: '',
        ACN: ''
    };
};

export const mapPrincipalsFromSalesAgreement = (values: AgreementInfoType): [Principal] => {
    return values.signatory.signatories.map(signatory => ({
        name: signatory.fullName,
        email: signatory.email,
        address: signatory?.address,
        phone: signatory.phone,
        ABN: signatory.ABN,
        ACN: signatory.ACN
    }));
};

export const initializeFormModel = ({ nameOfpersonServing }: { nameOfpersonServing: string }): Partial<FormModel> => {
    return {
        dateOfNotice: parseISO(new Date().toISOString()),
        principals: [generateNewPrincipal()],
        submitType: FormSubmitType.Create,
        address: '',
        location: '',
        agentName: '',
        agent: null,
        salesAgencyAgreementDate: null,
        revisedEsp: '$',
        evidence: '',
        nameOfAuthorisedPerson: '',
        nameOfpersonServing,
        reason: '',
        deliveryType: '',
        deliveryTypeDetails: '',
        emailTo: DELIVERY_TO_AGENT_AND_VENDOR,
        additionalLinks: [],
        documents: [],
        addressDetails: '',
        completion: {}
    };
};
