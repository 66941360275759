import React from 'react';
import { COMMISSION_PAYABLE_TYPE_OTHER, PRICE_TYPE_LIST, PRICE_TYPE_RESERVE } from '../../../../../../config';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo, getSalesStepTitle } from '../../../../../../utils/agreementUtils';
import DataString from '../../bloks/DataString';

export default function AgreementSalesPrice({ leaseAllInfo }) {
    const partName = 'priceAndFee';
    const { priceAndFee, location } = leaseAllInfo;

    if (!priceAndFee) {
        return <></>;
    }
    const step = getSalesStepNo(location, partName);
    return (
        <>
            <AgreementSection title={'Price'} step={step} leaseAllInfo={leaseAllInfo}>
                <div className="data data-row">
                    <DataString title={'Price for which the property is to be sold:'} text={priceAndFee.priceType} />
                    {priceAndFee.priceType === PRICE_TYPE_RESERVE && (
                        <>
                            {priceAndFee.isReservePriceToBeAdvised && <p>to be advised in writing at a later date</p>}
                            {!priceAndFee.isReservePriceToBeAdvised && (
                                <DataString title="The reserve price:" text={`$${priceAndFee.reservePriceFormatted}`} />
                            )}
                        </>
                    )}
                    {priceAndFee.priceType === PRICE_TYPE_LIST && (
                        <DataString
                            title={priceAndFee.isRange ? 'Price range:' : 'Price:'}
                            text={priceAndFee.priceFormatted}
                        />
                    )}

                    <DataString title={'Cost to establish search criteria:'} text={priceAndFee.priceRange} />
                </div>
            </AgreementSection>
            <AgreementSection title={'Commissions'} step={step} leaseAllInfo={leaseAllInfo}>
                <div className="data data-row">
                    <DataString title="Commission (inc GST):" text={priceAndFee.payable || 'N/A'} />
                    <DataString
                        title={'Commission payable for:'}
                        text={
                            priceAndFee.commissionIsPayable !== COMMISSION_PAYABLE_TYPE_OTHER
                                ? priceAndFee.commissionIsPayable
                                : `${priceAndFee.commissionIsPayable} - ${priceAndFee.otherPayable}`
                        }
                    />
                    {priceAndFee.otherTypesOfAppointments && (
                        <DataString
                            title={'Other types of appointments:'}
                            text={priceAndFee.otherTypesOfAppointments}
                        />
                    )}
                </div>
            </AgreementSection>
        </>
    );
}
