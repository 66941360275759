import React from 'react';
import { CheckboxCheck } from './FormCheckboxCheck.js';
import { FormTextHidden } from './FormText.js';

import styles from './FormCheckboxGroup.module.scss';
import cx from 'classnames';

type FormCheckboxProps = {
    label: string;
    name: string;
    dataTest?: string;
};

type FormCheckboxGroupProps = {
    label: string;
    groupName: string;
    checkboxes: FormCheckboxProps[];
    selectAll: boolean;
    form: any;
    className?: string;
};

const FormCheckboxGroup: React.FC<FormCheckboxGroupProps> = ({
    label,
    groupName,
    checkboxes,
    selectAll,
    form,
    className
}) => {
    const clickSelectAll = (value: boolean) => {
        checkboxes.forEach(checkbox => {
            form.change(`${groupName}.${checkbox.name}`, value);
        });
    };

    return (
        <div className={cx(className, styles.multiSelectCheckboxGroup)}>
            <label>{label}</label>
            <div>
                {selectAll && (
                    <CheckboxCheck name={`${groupName}.selectAll`} label="Select all" onClick={clickSelectAll} />
                )}
                {checkboxes.map((checkbox, index) => (
                    <CheckboxCheck
                        key={index}
                        name={`${groupName}.${checkbox.name}`}
                        dataTest={checkbox.dataTest}
                        label={checkbox.label}
                    />
                ))}
                {/* Use this for display errors for checkbox group */}
                <FormTextHidden name={groupName} showError={true} />
            </div>
        </div>
    );
};

export default FormCheckboxGroup;
