export const initState = {
    flooding: {
        enabled: false,
        answer: 'yes',
        fact:
            'The residential premises have been subject to flooding from a natural weather event or bush fire within the last 5 years'
    },
    healthOrSafetyRisks: {
        enabled: false,
        answer: 'yes',
        fact:
            'The residential premises are subject to significant health or safety risks that are not apparent to a reasonable person on inspection of the premises, Note. Disclosure under this provision does not affect the legal obligations of the landlord with respect to the residential premises'
    },
    lfai: {
        enabled: false,
        answer: 'yes',
        fact: 'The residential premises are listed on the LFAI Register'
    },
    violentCrime: {
        enabled: false,
        answer: 'yes',
        fact: 'The residential premises have been the scene of a serious violent crime within the last 5 years'
    },
    drugMisuse: {
        enabled: false,
        answer: 'yes',
        fact:
            'The residential premises have been used for the purposes of the manufacture or cultivation of any prohibited drug or prohibited plant within the meaning of the Drug Misuse and Trafficking Act 1985 within the last 2 years'
    },
    councilWasteServices: {
        enabled: false,
        answer: 'yes',
        fact:
            'Any council waste services that will be provided to the tenant on a different basis than is generally applicable to residential premises within the area of the council'
    },
    parkingPermit: {
        enabled: false,
        answer: 'yes',
        fact:
            'The tenant will not be able to obtain a residential parking permit in an area where only paid parking is provided because of the zoning of the land or another law applying to development on the land'
    },
    drivewayOrWalkway: {
        enabled: false,
        answer: 'yes',
        fact:
            'The existence of a driveway or walkway on the residential premises which other persons are legally entitled to share with the tenant'
    },
    strata: {
        enabled: false,
        answer: 'yes',
        fact:
            'If the premises comprise or include a lot in a strata scheme—scheduled rectification work or major repairs (including replacement of roofing, guttering or fences) to be carried out to common property during the fixed term of the residential tenancy agreement'
    },
    fireSafetyOrder: {
        enabled: false,
        answer: 'yes',
        fact:
            'The residential premises are part of a building in relation to which a notice of intention to issue a fire safety order, or a fire safety order, has been issued requiring rectification of the building regarding external combustible cladding, or '
    },
    rectificationOrder: {
        enabled: false,
        answer: 'yes',
        fact:
            'The residential premises are part of a building in relation to which a notice of intention to issue a building product rectification order, or a building product rectification order, has been issued requiring rectification of the building regarding external combustible cladding'
    },
    developmentApplication: {
        enabled: false,
        answer: 'yes',
        fact:
            'The residential premises are part of a building in relation to which a development application or complying development certificate application has been lodged for rectification of the building regarding external combustible cladding'
    },
    other: [],
    otherDisclosures: {
        proposalToSell: {
            enabled: false,
            answer: 'yes',
            fact:
                '(a) any proposal to sell the residential premises, if the landlord has prepared a contract for sale of the residential premises,'
        },
        mortgageeCourtAction: {
            enabled: false,
            answer: 'yes',
            fact:
                '(b) that a mortgagee is taking action for possession of the residential premises, if the mortgagee has commenced proceedings in a court to enforce a mortgage over the premises.'
        }
    }
};

export const salesInitState = {
    clauseList: [
        {
            optionType: '',
            title: '',
            description: ''
        }
    ],
    askClientToDisclose: false
};
