import * as React from 'react';
import { useDispatch } from 'react-redux';
import { loadPlanList } from '../../../actions/authorization';
import PaymentDetails from '../../../components/settings/subscription/PaymentDetails';
import BillingDetails from '../../../components/settings/subscription/BillingDetails';
import useCountry from '../../../hooks/useCountry';
import SubscriptionPlan from './SubscriptionPlan';

import '../../../sass/settings.scss';

const Subscription = () => {
    const dispatch = useDispatch();
    const { country } = useCountry();

    React.useEffect(() => {
        if (country) {
            dispatch(loadPlanList(country));
        }
    }, [country]);

    return (
        <div className="settings settings-container">
            <div className="column">
                <SubscriptionPlan />
                <PaymentDetails />
            </div>
            <div className="column">
                <BillingDetails />
            </div>
        </div>
    );
};

export default Subscription;
