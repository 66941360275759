import React from 'react';
import { getPmStepNo } from '../../../../../../utils/agreementUtils.js';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection.js';
import { LeaseAllInfo } from '../../../../../types';

interface AgreementPmInsuranceProps {
    leaseAllInfo: LeaseAllInfo;
}

const prepareInsuranceAcknowledgementText = function (acknowledgements: any): string {
    let acknowledgementText = '';
    if (acknowledgements?.isLandlordInsuranceRecommended) {
        acknowledgementText += `${acknowledgements.landlordInsuranceRecommendedText}, `;
    }

    if (acknowledgements?.isLandlordInsuranceProvided) {
        acknowledgementText += `${acknowledgements.landlordInsuranceProvidedText}, `;
    }

    if (acknowledgementText) {
        return acknowledgementText.slice(0, -2);
    }

    return 'n/a';
};

const prepaareInsurancePolicyText = function (policies: any): string {
    let policyText = '';
    if (policies.isBasicContents) {
        policyText += `${policies.basicContentsText}, `;
    }

    if (policies.isBuildingStrata) {
        policyText += `${policies.buildingStrataText}, `;
    }

    if (policies.isLandlord) {
        policyText += `${policies.landlordText},`;
    }

    if (policies.isPublicLiability) {
        policyText += `${policies.publicLiabilityText}, `;
    }

    if (policyText) {
        return policyText.slice(0, -2);
    }

    return 'n/a';
};

const AgreementPmInsurance: React.FC<AgreementPmInsuranceProps> = ({ leaseAllInfo }) => {
    return (
        <React.Fragment>
            <AgreementSection
                title={'Insurance'}
                step={getPmStepNo(leaseAllInfo.location, 'insurance')}
                leaseAllInfo={leaseAllInfo}
            >
                {leaseAllInfo.insurance && (
                    <div className="data data-row">
                        <p className="text">
                            Acknowledgments applicable to this Agreement:&nbsp;
                            <strong>
                                {prepareInsuranceAcknowledgementText(leaseAllInfo.insurance.acknowledgements)}
                            </strong>
                        </p>
                        <p className="text">
                            Insurance policies required:&nbsp;
                            <strong>{prepaareInsurancePolicyText(leaseAllInfo.insurance.insurancePolicies)}</strong>
                        </p>
                        <p className="text">
                            Who will arrange Landlord insurance:&nbsp;
                            <strong>{leaseAllInfo?.insurance?.landlordInsuranceArrangePerson}</strong>
                        </p>
                    </div>
                )}
            </AgreementSection>
        </React.Fragment>
    );
};

export default AgreementPmInsurance;
