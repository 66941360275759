import React from 'react';
import { getPmStepNo } from '../../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';

export default function AgreementPmRepairs({ leaseAllInfo }) {
    const { pmRepairs, location } = leaseAllInfo;
    if (!pmRepairs) {
        return null;
    }
    return (
        <div>
            {pmRepairs && (
                <AgreementSection
                    title={'Repairs'}
                    step={getPmStepNo(location, 'pmRepairs')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <p className="name">
                            Expenditure limit on cost of repairs before approval required :{' '}
                            <strong>${pmRepairs.expenditureLimit || '0.00'}</strong>
                        </p>
                    </div>
                </AgreementSection>
            )}
        </div>
    );
}
