import React from 'react';

import { FormTextRegular, FormTextCurrency, FormNumber } from '../../../../components/form/FormText';
import '../../../../sass/CommissionGroup.scss';

export function CommissionGroup({ index, removeCommission, maxLength }) {
    return (
        <div className="commission-group item-condition">
            <div className="formBox clauses">
                <div className="clauses-header">
                    <h3>Details of commission {index + 1}</h3>
                    <div className="toggle" onClick={() => removeCommission(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular
                        name={`commissionList[${index}].sharingPersonName`}
                        label="Name of person whom the Commission and/or letting fee is to be shared"
                        maxLength={maxLength}
                        required
                    />
                    <FormTextRegular
                        name={`commissionList[${index}].address`}
                        label="Address"
                        maxLength={maxLength}
                        required
                    />
                    <FormTextRegular
                        name={`commissionList[${index}].description`}
                        label="Description of such person"
                        maxLength={maxLength}
                        required
                    />
                    <br />
                    <p className="label">Amount of proportion of commission to be shared</p>
                    <div className="inline">
                        <FormTextCurrency name={`commissionList[${index}].amount`} label="" />
                        or
                        <FormNumber name={`commissionList[${index}].percentage`} label="" />%
                    </div>
                </div>
            </div>
        </div>
    );
}
