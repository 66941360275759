import { CLOSE_DOCUMENT_HEADER_BANNER, DISPLAY_DOCUMENT_HEADER_BANNER } from '../types';

export function closeDocumentHeaderBanner() {
    return {
        type: CLOSE_DOCUMENT_HEADER_BANNER
    };
}

export function displayDocumentHeaderBanner() {
    return {
        type: DISPLAY_DOCUMENT_HEADER_BANNER
    };
}
