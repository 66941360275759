import React from 'react';
import styles from './LargeButton.module.scss';
import cx from 'classnames';

export function LargeButton({
    title,
    label,
    onClick,
    icon,
    className,
    error,
    dataTest,
    disabled,
    isLoading,
    loadingText
}) {
    return (
        <button
            type="button"
            onClick={onClick}
            className={cx(className, styles.largeButton)}
            data-test={dataTest}
            disabled={disabled || isLoading}
        >
            {isLoading ? (
                <>
                    <div className={styles.spinner} />
                    <div className={styles.label}>
                        <p>{loadingText}</p>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.icon}>{icon}</div>
                    <div className={styles.label}>
                        {title && <b className={styles.title}>{title}</b>}
                        <p>{label}</p>
                        {error && <span className="error-message">{error}</span>}
                    </div>
                </>
            )}
        </button>
    );
}
