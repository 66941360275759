import React, { Component } from 'react';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import EmergencyContactDetails from '../bloks/EmergencyContactDetails';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';

class AgreementResidentialEmergencyContacts extends Component {
    render() {
        const { emergencyContactList, leaseAllInfo } = this.props;

        return (
            <AgreementSection
                title="Emergency contacts"
                step={getResTenStepNo(leaseAllInfo.location, 'emergencyContacts', leaseAllInfo.subLeaseType)}
                leaseAllInfo={this.props.leaseAllInfo}
            >
                {emergencyContactList &&
                    emergencyContactList.map(item => {
                        return <EmergencyContactDetails key={item.id} item={item} />;
                    })}
            </AgreementSection>
        );
    }
}

export default AgreementResidentialEmergencyContacts;
