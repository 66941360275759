import { CheckboxCheck } from '../../form/FormCheckboxCheck';
import {
    LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_EMAIL,
    LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_SMS,
    LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
} from '../../../config';
import { FormRadio } from '../../form/FormRadio';
import SuggestionEmailField from '../../form/SuggestionEmailField';
import SuggestionPhoneField from '../../form/SuggestionPhoneField';
import { FormEmail } from '../../form/FormEmail';
import { FormPhone } from '../../form/FormPhone';
import * as PropTypes from 'prop-types';
import React, { memo } from 'react';

import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { SUGGESTIONS_LANDLORD_INFORMATION_STATEMENT } from '../../../constants/featureFlags';
import { useSelector } from 'react-redux';
import useFetchSuggestions from '../../../hooks/useFetchSuggestions';
import { getLeaseAllInfo } from '../../../selectors/lease';

const LandlordInformationStatementPerson = ({ form, landlordText, person, index }) => {
    const isSuggestionsLandlordInformationStatementActive = useFeatureFlag(SUGGESTIONS_LANDLORD_INFORMATION_STATEMENT);

    const leaseAllInfo = useSelector(getLeaseAllInfo);
    // Suggestions are fetched only when the integrationId is available
    // After fetched, the suggestions are stored in the redux store
    useFetchSuggestions(leaseAllInfo?.integration?.id);

    return (
        <div className="person" key={index}>
            <div className="formBox">
                <div className="formBox-column">
                    <div className="wrap-box">
                        <div className="item">
                            <CheckboxCheck
                                name={`persons[${index}].sendLandlordInformationStatement`}
                                disabled={
                                    person.landlordInformationStatementStatus ===
                                    LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
                                }
                                label={landlordText}
                            />
                        </div>
                        {person.sendLandlordInformationStatement && (
                            <div className="item">
                                <p className="radio-label">|</p>
                                <div
                                    className={`radioGroup ${
                                        person.landlordInformationStatementStatus ===
                                        LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
                                            ? 'disabled'
                                            : ''
                                    }`}
                                >
                                    <FormRadio
                                        name={`persons[${index}].sendMethod`}
                                        className={'send-method-email'}
                                        value={LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_EMAIL}
                                        id={`send-method-email-${index}`}
                                        validateOnTouch={true}
                                        disabled={
                                            person.landlordInformationStatementStatus ===
                                            LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
                                        }
                                    />
                                    <label htmlFor={`send-method-email-${index}`}>
                                        <span />
                                        Email
                                    </label>
                                    <FormRadio
                                        name={`persons[${index}].sendMethod`}
                                        className={`send-method-sms`}
                                        value={LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_SMS}
                                        id={`send-method-sms-${index}`}
                                        validateOnTouch={true}
                                        disabled={
                                            person.landlordInformationStatementStatus ===
                                            LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
                                        }
                                    />
                                    <label htmlFor={`send-method-sms-${index}`}>
                                        <span />
                                        SMS
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                    {form.getFieldState(`persons[${index}].sendMethod`) &&
                        form.getFieldState(`persons[${index}].sendMethod`).error && (
                            <div className="has-error">{form.getFieldState(`persons[${index}].sendMethod`).error}</div>
                        )}
                    {person.sendLandlordInformationStatement &&
                        person.sendMethod === LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_EMAIL && (
                            <>
                                {isSuggestionsLandlordInformationStatementActive ? (
                                    <SuggestionEmailField
                                        name={`persons[${index}].email`}
                                        label="Email"
                                        suggestionsFieldName={`landlords[${index}].email`}
                                        required
                                        validateOnTouch={true}
                                        disabled={
                                            person.landlordInformationStatementStatus ===
                                            LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
                                        }
                                    />
                                ) : (
                                    <FormEmail
                                        name={`persons[${index}].email`}
                                        label="Email"
                                        required
                                        validateOnTouch={true}
                                        disabled={
                                            person.landlordInformationStatementStatus ===
                                            LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
                                        }
                                    />
                                )}
                            </>
                        )}
                    {person.sendLandlordInformationStatement &&
                        person.sendMethod === LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_SMS && (
                            <>
                                {isSuggestionsLandlordInformationStatementActive ? (
                                    <SuggestionPhoneField
                                        name={`persons[${index}].mobile`}
                                        label="Mobile"
                                        suggestionsFieldName={`landlords[${index}].phone`}
                                        mask="+6\1 P11 111 111"
                                        required
                                        validateOnTouch={true}
                                        disabled={
                                            person.landlordInformationStatementStatus ===
                                            LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
                                        }
                                    />
                                ) : (
                                    <FormPhone
                                        name={`persons[${index}].mobile`}
                                        label="Mobile"
                                        mask="+6\1 P11 111 111"
                                        required
                                        validateOnTouch={true}
                                        disabled={
                                            person.landlordInformationStatementStatus ===
                                            LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
                                        }
                                    />
                                )}
                            </>
                        )}
                </div>
            </div>
        </div>
    );
};

LandlordInformationStatementPerson.propTypes = {
    index: PropTypes.number,
    person: PropTypes.any,
    landlordText: PropTypes.any,
    form: PropTypes.any
};

export default memo(LandlordInformationStatementPerson);
