import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import EditableTemplateName from '../../form/EditableTemplateName';
import { changeTemplateName } from '../../../actions/dashboard';
import { useDispatch } from 'react-redux';
import { getLeaseTypeForTemplateType } from '../../../utils/agreementUtils';

export const HeaderLeftTemplate = props => {
    const dispatch = useDispatch();
    const leaseAllInfo = props.leaseAllInfo;

    const saveTemplateName = newTemplateName => {
        return dispatch(changeTemplateName(leaseAllInfo.id, newTemplateName));
    };

    return (
        <div className="header-left">
            <div className="header-left-wrapper">
                <div>
                    <div className="header-title">
                        <h1>Agreement Template</h1>
                    </div>
                    <div className="header-title">
                        <h1>
                            {getLeaseTypeForTemplateType(leaseAllInfo.leaseType)} {leaseAllInfo.location}
                        </h1>
                    </div>
                    <div>
                        <div className="editable">
                            <EditableTemplateName
                                templateName={props.leaseAllInfo.templateName}
                                saveContent={saveTemplateName}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

HeaderLeftTemplate.propTypes = {
    leaseAllInfo: PropTypes.object.isRequired
};
