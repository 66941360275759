import React from 'react';
import { FormTextRegular } from '../form/FormText';
import { FormEmail } from '../form/FormEmail';

const RentIncreaseLandlordPersonGroup = ({ index, removeLandlord, rentIncreaseWithoutLease, disabled }) => {
    let header;
    if (index === 0) {
        header = <h3>Landlord {index + 1}</h3>;
    } else {
        header = (
            <span className="removePerson">
                <h3>Landlord {index + 1}</h3>
                <button type="button" onClick={disabled ? () => {} : () => removeLandlord(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="tenant">
            {header}
            <div className="formBox">
                <div className="formBox-column">
                    <div className="nowrap large">
                        <FormTextRegular
                            name={`landlords[${index}].firstName`}
                            label="First Name"
                            required
                            disabled={!rentIncreaseWithoutLease || disabled}
                        />
                        <FormTextRegular
                            name={`landlords[${index}].middleName`}
                            label="Middle Name"
                            disabled={!rentIncreaseWithoutLease || disabled}
                        />
                    </div>
                    <FormTextRegular
                        name={`landlords[${index}].lastName`}
                        label="Last Name"
                        required
                        disabled={!rentIncreaseWithoutLease || disabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default RentIncreaseLandlordPersonGroup;
