export const UPDATE_OTHER_SERVICES_FORM = 'UPDATE_OTHER_SERVICES_FORM';

const initState = {
    isInspectionReportFee: false,
    isArrangingRepaireAndMaintenance: false,
    isNegotiatingRentVariations: false,
    isServiceNotice: false,
    isAppearkingAtTribunal: false,
    isApplyingForTribunal: false
};

export default function otherServicesReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_OTHER_SERVICES_FORM: {
            return { ...state, ...action.data };
        }

        default: {
            return state;
        }
    }
}
