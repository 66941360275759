import React, { memo, useEffect, forwardRef, useState, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { setDirtyStep, updateSubmitTypeSuccess, formSubmitFail } from '../../../../actions/lease';
import { FormNumber } from '../../../../components/form/FormText';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import * as Lease from '../../../../reducers/lease';
import { has } from 'lodash';
import axios from 'axios';
import { getPmAuthorityPeriodDetails } from '../../../../selectors/lease/pmLease';
import '../../../../sass/authorityPeriodDetails.scss';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';

const PM_VIC_AUTHORITY_PERIOD_DETAILS_FORM = 'pmAuthorityPeriodDetailsForm';
const initState = {
    exclusiveAuthorityPeriod: '60',
    continuingAuthorityPeriod: '90',
    terminationOfAuthority: '90',
    isInitialLeasing: false,
    isOngoingManagement: false,
    isSubsequent: false,
    isSubsequentReLeasing: false
};

function AuthorityPeriodDetailsLayout(props, ref) {
    const dispatch = useDispatch();
    const leaseType = useSelector(getLeaseType);
    const dirtyStep = useSelector(getDirtyStep);
    const step = useSelector(getStep);
    const reduxPmAuthorityPeriodDetails = useSelector(getPmAuthorityPeriodDetails);
    const [pmAuthorityPeriodDetails, setPmAuthorityPeriodDetails] = useState(
        reduxPmAuthorityPeriodDetails || initState
    );

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document
                .getElementById(PM_VIC_AUTHORITY_PERIOD_DETAILS_FORM)
                .dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    useEffect(() => {
        if (pmAuthorityPeriodDetails.startDate) {
            setPmAuthorityPeriodDetails({
                ...pmAuthorityPeriodDetails,
                startDate: new Date(pmAuthorityPeriodDetails.startDate)
            });
        } else {
            setPmAuthorityPeriodDetails({ ...pmAuthorityPeriodDetails, startDate: new Date() });
        }
    }, []);

    function handleFormDirtyChange(values, form) {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    }

    function submitForm(values) {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        let payload = { ...values };
        return axios
            .post(`/api/agency/lease/${props.leaseId}/pm-authority-period`, payload)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmAuthorityPeriodDetails')) {
                    return error.response.data.errors.pmAuthorityPeriodDetails;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    }

    return (
        <div className="authority-period-details">
            <Form onSubmit={submitForm} initialValues={pmAuthorityPeriodDetails}>
                {({ handleSubmit, values, form, errors }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_VIC_AUTHORITY_PERIOD_DETAILS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Date the authority is granted from"
                                    name="startDate"
                                    selected={values.startDate}
                                    required
                                />
                            </div>
                            <div className="form-items">
                                <div className="flex input-with-days">
                                    <FormNumber label="Exclusive Authority Period" name="exclusiveAuthorityPeriod" />
                                    <div className="days">Days From the date of this Authority</div>
                                </div>
                                <br />
                                <div className="flex input-with-days">
                                    <FormNumber label="Continuing Authority Period" name="continuingAuthorityPeriod" />
                                    <div className="days">Days from the end of the Exclusive Authority Period</div>
                                </div>
                                <br />
                                <div className="flex input-with-days">
                                    <FormNumber label="Termination of Authority" name="terminationOfAuthority" />

                                    <div className="days">Days notice</div>
                                </div>
                                <small>
                                    The Authority can only be terminated by either the Agent or the Client in writing,
                                    (in accordance with Term 3.1 & 16.1) giving no less than:{' '}
                                    {values.terminationOfAuthority}
                                </small>
                            </div>

                            <div className={'authorisation'}>
                                <h3>Agent’s Authorisation</h3>
                                <small>Please select all applicable boxes</small>
                                <p className="subject">This authority is for the: </p>
                                <CheckboxCheck name={`isInitialLeasing`} label="Initial Leasing of the Property" />
                                <CheckboxCheck
                                    name={`isOngoingManagement`}
                                    label="Ongoing management of the Property and collection of rent"
                                />
                                <CheckboxCheck
                                    name={`isSubsequent`}
                                    label="Subsequent and each new leasing to a new renter"
                                />
                                <CheckboxCheck
                                    name={`isSubsequentReLeasing`}
                                    label="Subsequent re-leasing to initial renter or assignee"
                                />
                                {errors?.errors?.field?.isSubsequentReLeasing && (
                                    <div className="has-error">{errors?.errors?.field?.isSubsequentReLeasing}</div>
                                )}
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(AuthorityPeriodDetailsLayout));
