import * as React from 'react';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

import Icon, { Icons } from '../../../../common/components/Icon';
import CustomLabelTextInput, {
    CustomLabelTextInputData,
    clearCustomLabelTextInput
} from '../../../dashboard/documents/buildADoc/components/CustomLabelTextInput';
import Button from '../../../../common/components/Button.js';
import { generateRandomId } from '../../../../utils/generalUtils.js';
import { CustomPlaceholderRespondentType } from '../../../../types/UploadADoc';

import styles from './AddPlaceholder.module.scss';

type AddPlaceholderProps = {
    close: (addedPlaceholder?: boolean) => void;
};

export const AddPlaceholder: React.FC<AddPlaceholderProps> = ({ close }) => {
    const customPlaceholdersField = useFieldArray('customPlaceholders');
    const newCustomPlaceholderField = useField<CustomLabelTextInputData>('newCustomPlaceholder');
    const labelRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        labelRef.current?.focus();
    }, []);

    return (
        <>
            <div className={styles.addPlaceholderHeader}>
                <div className={styles.addPlaceholderHeaderText}>Add custom text or blank space</div>
                <Button
                    onClick={() => {
                        clearCustomLabelTextInput(newCustomPlaceholderField);
                        close();
                    }}
                    className={styles.closeButton}
                >
                    <Icon icon={Icons.CLOSE} />
                </Button>
            </div>
            <CustomLabelTextInput
                name="newCustomPlaceholder"
                labelInputRef={labelRef}
                valuePlaceholder="Enter response or leave blank"
                className={styles.newCustomPlaceholderInput}
            />
            <Button
                onClick={() => {
                    customPlaceholdersField.fields.push({
                        ...newCustomPlaceholderField.input.value,
                        id: generateRandomId(),
                        respondentType: CustomPlaceholderRespondentType.SENDER,
                        count: 0
                    });
                    clearCustomLabelTextInput(newCustomPlaceholderField);
                    close(true);
                }}
                className={styles.addButton}
                disabled={!newCustomPlaceholderField.input.value?.label}
            >
                Add
            </Button>
        </>
    );
};
