import React from 'react';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import Icon, { IconSize, Icons } from './Icon';
import useComponentId from '../../hooks/useComponentId';
import { Tag, TagColor, TagSize } from './tag/Tag';

import variables from '../../sass/variables.scss';
import styles from './TooltipIcon.module.scss';

export enum TooltipIconType {
    ERROR,
    WARNING,
    HELP,
    INFO
}

const getIcon = (type: TooltipIconType): Icons => {
    switch (type) {
        case TooltipIconType.ERROR:
            return Icons.ERROR;
        case TooltipIconType.INFO:
        case TooltipIconType.WARNING:
            return Icons.WARNING;
        case TooltipIconType.HELP:
            return Icons.HELP;
    }
};

const getTagColor = (type: TooltipIconType): TagColor => {
    switch (type) {
        case TooltipIconType.ERROR:
            return TagColor.Error;
        case TooltipIconType.WARNING:
            return TagColor.Warning;
        case TooltipIconType.HELP:
            return TagColor.Grey;
        case TooltipIconType.INFO:
            return TagColor.Blue;
    }
};

const getTagText = (type: TooltipIconType): string => {
    switch (type) {
        case TooltipIconType.ERROR:
            return 'Error';
        case TooltipIconType.WARNING:
            return 'Warning';
        case TooltipIconType.HELP:
            return 'Help';
        case TooltipIconType.INFO:
            return 'Information';
    }
};

const getShouldShowTag = (type: TooltipIconType): boolean => {
    switch (type) {
        case TooltipIconType.ERROR:
        case TooltipIconType.WARNING:
            return true;
        case TooltipIconType.INFO:
        case TooltipIconType.HELP:
            return false;
    }
};

type TooltipIconProps = {
    className?: string;
    iconSize?: IconSize;
    type: TooltipIconType;
    label?: string;
};

const TooltipIcon: React.FC<React.PropsWithChildren<TooltipIconProps>> = ({
    className,
    iconSize,
    type,
    children,
    label
}) => {
    const tooltipId = useComponentId().toString();
    const shouldShowTag = getShouldShowTag(type);

    return (
        <React.Fragment>
            <Icon
                className={cx(styles.icon, className, {
                    [styles.errorIcon]: type === TooltipIconType.ERROR,
                    [styles.warningIcon]: type === TooltipIconType.WARNING,
                    [styles.helpIcon]: type === TooltipIconType.HELP,
                    [styles.infoIcon]: type === TooltipIconType.INFO
                })}
                icon={getIcon(type)}
                size={iconSize}
                data-tip
                data-for={tooltipId}
            />
            <ReactTooltip
                id={tooltipId}
                effect="solid"
                className={styles.tooltip}
                place="right"
                backgroundColor={variables.white}
            >
                {shouldShowTag && (
                    <Tag
                        className={styles.statusTag}
                        color={getTagColor(type)}
                        size={TagSize.Small}
                        text={label || getTagText(type)}
                        startIcon={
                            <Icon
                                className={cx(styles.tagIcon, {
                                    [styles.errorIcon]: type === TooltipIconType.ERROR,
                                    [styles.warningIcon]: type === TooltipIconType.WARNING
                                })}
                                icon={getIcon(type)}
                            />
                        }
                    />
                )}
                {children}
            </ReactTooltip>
        </React.Fragment>
    );
};

export default TooltipIcon;
