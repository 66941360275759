import axios from 'axios';
import { AvailableCountries } from '../constants/constants';
import * as Auth from './../reducers/authorization';

/**
 * Create account Success.
 * @param {Object} data - user credentials.
 */
export function createAccountSuccess(data) {
    return {
        type: Auth.CREATE_ACCOUNT_SUCCESS,
        data
    };
}

export function createDetailsSuccess(data) {
    return {
        type: Auth.CREATE_DETAILS_SUCCESS,
        data
    };
}

export function selectPlan(data) {
    return {
        type: Auth.SELECT_PLAN,
        data
    };
}

export function setCouponInfo(data) {
    return {
        type: Auth.SET_COUPON_INFORMATION,
        data
    };
}

/**
 * Send set-tariff-plan request.
 *
 * @param {{planId:<string>}} payload - payload to be send.
 * @param {string} agencyId - agency id.
 *
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function setTariffPlan(payload, agencyId) {
    return {
        types: [Auth.CREATE_PLAN_REQUEST, Auth.CREATE_PLAN_SUCCESS, Auth.CREATE_PLAN_FAILURE],
        promise: axios.post(`/api/sign-up/${agencyId}/tariff-plan`, payload)
    };
}

/**
 * Load list of tariff plan from server.
 *
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function loadPlanList(country = AvailableCountries.AU) {
    return {
        types: [Auth.GET_PLAN_REQUEST, Auth.GET_PLAN_SUCCESS, Auth.GET_PLAN_FAILURE],
        promise: axios.get(`/api/tariff-plan?country=${country}`)
    };
}

export function loadPlanListForLanding(accountType, country, getHiddenPlans = false) {
    return {
        types: [Auth.GET_PLAN_REQUEST, Auth.GET_PLAN_SUCCESS, Auth.GET_PLAN_FAILURE],
        promise: axios.get(
            `/api/sign-up/tariff-plan-list/${accountType}?country=${country}&get_hidden=${getHiddenPlans ? 1 : 0}`
        )
    };
}

export function unloadPlanList() {
    return {
        type: Auth.UNLOAD_PLAN_LIST
    };
}

export function changePlan(data) {
    return {
        type: Auth.CHANGE_PLAN_SUCCESS,
        data
    };
}

export function setPending(data) {
    return {
        type: Auth.SET_PENDING,
        data
    };
}

export function createPayment(data, agencyId) {
    return {
        types: [Auth.CREATE_PAYMENT_REQUEST, Auth.CREATE_PAYMENT_SUCCESS, Auth.CREATE_PAYMENT_FAILURE],
        promise: axios.post(`/api/sign-up/${agencyId}/pay`, data)
    };
}

export function createLogo(data, agencyId) {
    return {
        types: [Auth.CREATE_LOGO_REQUEST, Auth.CREATE_LOGO_SUCCESS, Auth.CREATE_LOGO_FAILURE],
        promise: axios.post(`/api/sign-up/${agencyId}/logo`, data)
    };
}

export function settingStep(data) {
    return {
        type: Auth.SETTING_STEP_REQUEST,
        data
    };
}

export function settingEmailAuthStep(data) {
    return {
        type: Auth.EMAIL_AUTH_SETTING_STEP,
        data
    };
}

export function returnToPreviousStep() {
    return {
        type: Auth.RETURN_TO_PREVIOUS_STEP
    };
}

export function unRegister() {
    return {
        type: Auth.UNREGISTERED_SUCCESS
    };
}
