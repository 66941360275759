import { createSelector } from 'reselect';
import { getLease } from './';

export const getCommon = createSelector([getLease], lease => lease.common);

export const getValidationErrors = createSelector([getCommon], common => common.validationErrors);

export const getPriceAndFee = createSelector([getLease], lease => lease.priceAndFee);

export const getSalesTerm = createSelector([getLease], lease => lease.salesTerm);

export const getSalesInclusions = createSelector([getLease], lease => lease.salesInclusions);

export const getLandlordsSolicitor = createSelector([getLease], lease => lease.landlordsSolicitor);

export const getSignatoryReducer = createSelector([getLease], lease => lease.signatory);

export const getSalesExpensesAndCharges = createSelector([getLease], lease => lease.salesExpensesAndCharges);

export const getManagingAgent = createSelector([getLease], lease => lease.managingAgent);

export const getPropertyDetails = createSelector([getLease], lease => lease.propertyDetails);

export const getPropertyDescription = createSelector([getLease], lease => lease.propertyDescription);

export const getAgreementTerm = createSelector([getLease], lease => lease.agreementTerm);
