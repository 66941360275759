import { createSelector } from 'reselect';
import { getCurrentDocument, getDocument } from './index';

export const getIsCustomDocumentModalOpen = createSelector(
    [getDocument],
    document => document.isOpenCustomDocumentModal
);

export const getIsAnnotationWebViewerOpen = createSelector(
    [getDocument],
    document => document.isOpenAnnotationWebViewerModal
);

export const getClient = createSelector([getCurrentDocument], document => document.client);

export const getClients = createSelector([getClient], client => client.clients);

export const getAnnotationWebViewerPdf = createSelector([getDocument], document => document.WebViewerPdf);
export const getAnnotationWebViewerBlob = createSelector([getDocument], document => document.webViewerBlob);
