import React from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo, getSalesStepTitle } from '../../../../../../utils/agreementUtils';
import DataString from '../../bloks/DataString';

export default function AgreementSalesPropertyDetails({ leaseAllInfo }) {
    const partName = 'propertyDetails';
    const { propertyDetails, location } = leaseAllInfo;

    if (!propertyDetails) {
        return <></>;
    }

    return (
        <AgreementSection
            title={getSalesStepTitle(location, partName)}
            step={getSalesStepNo(location, partName)}
            leaseAllInfo={leaseAllInfo}
        >
            <div className="data data-row">
                <DataString title={'Description of Property:'} text={propertyDetails.description || 'Not stated'} />
                <DataString title={'Title Reference:'} text={propertyDetails.titleReference || 'Not stated'} />
                {propertyDetails.isNominateStrataDetails && (
                    <>
                        <DataString title={'Plan No:'} text={propertyDetails.planNo || 'Not stated'} />
                        <DataString title={'Lot No:'} text={propertyDetails.lotNo || 'Not stated'} />
                    </>
                )}
                <DataString title={'Property type:'} text={propertyDetails.propertyTypeText || 'Not stated'} />
                <DataString title={'Age of property:'} text={propertyDetails.age || 'Not stated'} />
                <DataString title={'Land size:'} text={propertyDetails.landSize || 'Not stated'} />
                <DataString title={'Zoning:'} text={propertyDetails.zoning || 'Not stated'} />
                {[
                    {
                        title: 'Electrical safety switches:',
                        enabled: propertyDetails.isElectricalSafetySwitches
                    },
                    {
                        title: 'Smoke alarms:',
                        enabled: propertyDetails.isSmokeAlarms
                    },
                    {
                        title: 'Is there a pool?',
                        enabled: propertyDetails.isPool
                    },
                    {
                        title: 'Pool Safety Certificate:',
                        enabled: propertyDetails.isPool && propertyDetails.isPoolSafetyCertificate
                    },
                    {
                        title: 'Is the Property tenanted?',
                        enabled: propertyDetails.isPropertyTenanted
                    }
                ]
                    .map(i => ({ ...i, text: i.enabled ? 'Yes' : 'No' }))
                    .map((condition, index) => (
                        <DataString {...condition} key={index} />
                    ))}

                <DataString
                    title={'Additional comments related to the property:'}
                    text={propertyDetails.additionalComments || 'Not stated'}
                />
            </div>
        </AgreementSection>
    );
}
