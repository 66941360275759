import produce from 'immer';
export const ADD_EMERGENCY_CONTACT = 'users.contacts.add.contact';
export const UPDATE_EMERGENCY_CONTACT = 'users.contacts.update.contact';

export const DELETE_EMERGENCY_CONTACT_REQUEST = 'users.contacts.delete.contact.request';
export const DELETE_EMERGENCY_CONTACT_SUCCESS = 'users.contacts.delete.contact.success';
export const DELETE_EMERGENCY_CONTACT_FAIL = 'users.contacts.delete.contact.fail';

export const GET_EMERGENCY_CONTACT_LIST_REQUEST = 'users.contacts.request';
export const GET_EMERGENCY_CONTACT_LIST_SUCCESS = 'users.contacts.success';
export const GET_EMERGENCY_CONTACT_LIST_FAIL = 'users.contacts.request.fail';

export const OPEN_MODAL_EMERGENCY_CONTACT = 'users.contacts.modal.open';
export const CLOSE_MODAL_EMERGENCY_CONTACT = 'users.contacts.modal.close';
export const CLEAR_CONTACTS_TEMPLATES_TO_UPDATE = 'CLEAR_CONTACTS_TEMPLATES_TO_UPDATE';

const initState = {
    contactList: [],
    emergencyContactsTemplatesToUpdate: {},
    validationErrors: null,
    isOpenModal: false,
    modalEmergencyContactData: {
        type: null,
        values: {}
    }
};

export default function emergencyContacts(state = initState, action) {
    switch (action.type) {
        case ADD_EMERGENCY_CONTACT: {
            return {
                ...state,
                contactList: [...state.contactList, action.contact],
                isOpenModal: false
            };
        }

        case UPDATE_EMERGENCY_CONTACT: {
            let updatedContact = action.contact;
            let updatedIndex = state.contactList.findIndex(contact => contact.id === updatedContact.id);

            return produce(state, draftState => {
                draftState.isOpenModal = false;
                draftState.emergencyContactsTemplatesToUpdate = action.templatesToUpdate;
                draftState.contactList[updatedIndex] = updatedContact;
            });
        }
        case DELETE_EMERGENCY_CONTACT_SUCCESS: {
            return produce(state, draftState => {
                draftState.isOpenModal = false;
                draftState.contactList = action.result.data.emergencyContactList;
                draftState.emergencyContactsTemplatesToUpdate = action.result.data.templatesToUpdate;
                draftState.validationErrors = null;
            });
        }

        case GET_EMERGENCY_CONTACT_LIST_SUCCESS: {
            return {
                ...state,
                contactList: action.result.data.emergencyContactList
            };
        }
        case OPEN_MODAL_EMERGENCY_CONTACT: {
            const { type, values } = action.data;
            return {
                ...state,
                isOpenModal: true,
                modalEmergencyContactData: {
                    type,
                    values
                }
            };
        }
        case CLOSE_MODAL_EMERGENCY_CONTACT: {
            return {
                ...state,
                isOpenModal: false,
                modalEmergencyContactData: {
                    type: null,
                    values: {}
                },
                validationErrors: null
            };
        }

        case CLEAR_CONTACTS_TEMPLATES_TO_UPDATE: {
            return produce(state, draftState => {
                draftState.emergencyContactsTemplatesToUpdate = {};
            });
        }
        default: {
            return state;
        }
    }
}
