import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import { Table, Th, Tr, Td, Thead } from 'reactable';
import ModalDialog from '../../common/components/ModalDialog';

import { getAgentSignature } from '../../selectors/completion';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../utils/dateUtils';

import '../../sass/terminateLeaseListModal.scss';
import RentIncreaseListButton from '../dashboard/documentList/components/RentIncreaseListButton';
import { closeRentIncreaseLeaseListModal } from '../../actions/terminateLease';

export default function RentIncreaseListModal({ isOpen, agreementInfo, loggedInUser }) {
    const agentSignature = useSelector(getAgentSignature);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(closeRentIncreaseLeaseListModal());
    };
    const rentIncreaseList = agreementInfo && agreementInfo.rent ? cloneDeep(agreementInfo.rent.rentIncreaseList) : [];
    const reversedRentIncreaseList = rentIncreaseList.reverse();
    return (
        <ModalDialog
            title="Rent Increase List"
            isOpen={isOpen}
            closeModal={closeModal}
            className="terminate-list-modal-dialog"
        >
            <p>Address: {agreementInfo.address}</p>

            <div className="table-sort">
                <Table>
                    <Thead>
                        <Th column="newRentDateStart">Payable from</Th>
                        <Th column="newRentAmount">New rent</Th>
                        <Th column="servicesOfNoticesEmails">Email list</Th>
                        <Th column="pdf">&nbsp;</Th>
                    </Thead>
                    {reversedRentIncreaseList.map((rentIncreaseListItem, key) => {
                        return (
                            <Tr key={key}>
                                <Td column="newRentDateStart">
                                    {formatDateStandard(
                                        rentIncreaseListItem.rentIncrease.newRentDateStart,
                                        getAgencyTimezoneFromUser(loggedInUser)
                                    )}
                                </Td>
                                <Td column="newRentAmount">{`$${rentIncreaseListItem.rentIncrease.newRentAmount}`}</Td>
                                <Td column="servicesOfNoticesEmails">
                                    {rentIncreaseListItem.rentIncrease.isNewRentIncrease
                                        ? rentIncreaseListItem.rentIncrease.emailAddresses.join(', ')
                                        : agreementInfo.tenant.emailAddressesForServiceNotices}
                                </Td>
                                <Td column="pdf">
                                    <RentIncreaseListButton
                                        rentIncrease={rentIncreaseListItem}
                                        doc={agreementInfo}
                                        agentSignature={agentSignature}
                                    />
                                </Td>
                            </Tr>
                        );
                    })}
                </Table>
            </div>
        </ModalDialog>
    );
}
