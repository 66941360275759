import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils';
import { pluralize } from '../../../../../utils/formUtils';
import { LeaseAllInfo, Term } from './types';

type TermTextProps = {
    term: Term
}

type Props = {
    leaseAllInfo: LeaseAllInfo,
}

function TermText({ term }: TermTextProps) {
    const { startDateFormatted, endDateFormatted, period, endDate, periodic, qty } = term;
    const periodText = pluralize(qty, period);

    if (endDate && !periodic) {
        return (
            <p>
                The term of agreement is&nbsp;
                <strong>
                    {qty} {periodText}
                </strong>
                , agreement starts on&nbsp;
                <strong>{startDateFormatted}&nbsp;</strong>
                and ends on&nbsp;
                <strong>{endDateFormatted}</strong>
            </p>
        );
    }
    return (
        <p>
            The term of agreement is&nbsp;
            <strong>
                {qty} {periodText}
            </strong>
            , agreement starts on&nbsp;
            <strong>{startDateFormatted}</strong>
        </p>
    );
}

export default function AgreementClTerm({ leaseAllInfo } : Props) {
    const part = 'term';
    if (!leaseAllInfo?.term) {
        return <></>;
    }
    const { location, term } = leaseAllInfo;

    return (
        <div>
            <AgreementSection
                title={getClStepTitle(location, part)}
                step={getClStepNo(location, part)}
                leaseAllInfo={leaseAllInfo}
            >
                {term && (
                    <div className="data data-row">
                        <TermText term={term} />
                        <br />
                        {term.isThereAnOption && (
                            <p className="text">For a further period of:&nbsp;<strong>{term.furtherPeriodText}</strong></p>
                        )}
                    </div>
                )}
            </AgreementSection>
        </div>
    );
}
