import axios from 'axios';
import * as Lease from '../../reducers/lease';
import * as Signatory from '../../reducers/sales/signatory';
import * as SalesLandlord from '../../reducers/sales/landlord';

/**
 * Create sales request.
 * @param {Object} data - user credentials.
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function createSalesLease(data) {
    return {
        types: [Lease.CREATE_SALES_LEASE_REQUEST, Lease.CREATE_SALES_LEASE_SUCCESS, Lease.CREATE_SALES_LEASE_FAILURE],
        promise: axios.post('/api/agency/lease', data)
    };
}

/**
 * Add sales agreement landlord
 * @return {{type, data: *}}
 */
export function addSalesLandlordPerson() {
    return {
        type: SalesLandlord.SALES_LANDLORD_FORM_ADD_PERSON
    };
}

/**
 * Remove sales agreement landlord
 * @return {{type, data: *}}
 */
export function removeSalesLandlordPerson(index) {
    return {
        type: SalesLandlord.SALES_LANDLORD_FORM_REMOVE_PERSON,
        index: index
    };
}

/**
 * Add sales agreement landlord
 * @return {{type, data: *}}
 */
export function addSignatoryPerson() {
    return {
        type: Signatory.SIGNATORY_FORM_ADD_PERSON
    };
}

/**
 * Remove sales agreement landlord
 * @return {{type, data: *}}
 */
export function removeSignatoryPerson(index) {
    return {
        type: Signatory.SIGNATORY_FORM_REMOVE_PERSON,
        index: index
    };
}

export function setSalesInclusions(leaseId, data) {
    return {
        types: [Lease.LEASE_SALES_INCLUSIONS_REQUEST, Lease.LEASE_SALES_INCLUSIONS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/inclusions`, data)
    };
}

export function setLandlordsSolicitor(leaseId, data) {
    return {
        types: [Lease.LEASE_LANDLORD_SOLICITOR_REQUEST, Lease.LEASE_LANDLORD_SOLICITOR_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/landlords-solicitor`, data)
    };
}

export function setLicensee(leaseId, data) {
    return {
        types: [Lease.LEASE_LICENSEE_REQUEST, Lease.LEASE_LICENSEE_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/licensee`, data)
    };
}

export function setAdditionalSalesTerms(leaseId, data) {
    return {
        types: [Lease.ADDITIONAL_SALES_TERMS_REQUEST, Lease.ADDITIONAL_SALES_TERMS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/additional-sales-terms`, data)
    };
}

export function setSalesExpensesAndCharges(leaseId, data) {
    return {
        types: [
            Lease.LEASE_SALES_EXPENSES_CHARGES_REQUEST,
            Lease.LEASE_SALES_EXPENSES_CHARGES_SUCCESS,
            Lease.LEASE_FORM_FAIL
        ],
        promise: axios.post(`/api/agency/lease/${leaseId}/expenses-and-charges`, data)
    };
}

export function setSalesTerm(leaseId, data) {
    return {
        types: [Lease.LEASE_SALES_TERM_REQUEST, Lease.LEASE_SALES_TERM_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/term`, data)
    };
}
