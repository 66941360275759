import React, { memo } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { LeaseServiceGroup } from '../LeaseServiceGroup';

const PremiseServices = ({ addAdditionalService, additional, push }) => {
    return (
        <div className="services">
            <h4>Services</h4>
            <FieldArray
                name="premiseServices.premiseServices"
                initialValue={additional.premiseServices.premiseServices}
            >
                {({ fields }) => (
                    <React.Fragment>
                        {fields.map((name, index) => {
                            return (
                                <LeaseServiceGroup
                                    field={`premiseServices${index}`}
                                    key={index}
                                    index={index}
                                    service={fields.value[index]}
                                    removeService={() => fields.remove(index)}
                                />
                            );
                        })}
                    </React.Fragment>
                )}
            </FieldArray>
            <div className="button">
                <button className="btn-add-service" type="button" onClick={() => addAdditionalService(push)}>
                    Add Service
                </button>
            </div>
        </div>
    );
};

export default memo(PremiseServices);
