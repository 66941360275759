import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import axios from 'axios';

import { closeDisclosureDocumentModal, refreshDocumentList } from '../../../../actions/document';
import { openNoSignatureModal } from '../../../../actions/user';

import AgreementMainAuditTrail from '../../../../components/dashboard/InfoAgreementComponents/AgreementMainComponents/shared/AgreementMainAuditTrail';
import Button from '../../../../common/components/Button';

import { HIDE_MESSAGE_TIME, FORM_SUBMIT_TYPE_SAVE, FORM_SUBMIT_TYPE_SEND } from '../../../../config';
import {
    isDocumentCreateMode,
    isDocumentEditMode,
    isDocumentStatusSentForSigning,
    isDocumentAwaitingCompletion,
    isDocumentComplete
} from '../../../../utils/generalUtils';
import ButtonGroup from '../../../../common/components/ButtonGroup';

import { getAgentSignature } from '../../../../selectors/completion';

const DisclosureDocumentFormFooter = ({
    closeModal,
    currentDocument,
    documentEditMode,
    isSendingDisclosure,
    isSubmitSuccessfull,
    isSubmitFail,
    form,
    isSaveDraft
}) => {
    const dispatch = useDispatch();

    const agentSignature = useSelector(getAgentSignature);

    const [isResending, setIsResending] = useState(false);
    const [isResendingSuccess, setIsResendingSuccess] = useState(false);
    const [isResendingFail, setIsResendingFail] = useState(false);

    const [iscompleting, setIscompleting] = useState(false);
    const [iscompletingSuccess, setIscompletingSuccess] = useState(false);
    const [iscompletingFail, setIscompletingFail] = useState(false);

    const resendLink = id => {
        setIsResending(true);
        axios
            .post(`api/document/${id}/resend-disclosure`)
            .then(() => {
                setIsResending(false);
                setIsResendingSuccess(true);
                setIsResendingFail(false);
                dispatch(refreshDocumentList());
                setTimeout(() => {
                    setIsResendingSuccess(false);
                }, HIDE_MESSAGE_TIME);
            })
            .catch(() => {
                setIsResending(false);
                setIsResendingSuccess(false);
                setIsResendingFail(true);
                setTimeout(() => {
                    setIsResendingFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    const completeDocument = id => {
        setIscompleting(true);
        axios
            .post(`api/document/${id}/complete-disclosure`)
            .then(() => {
                setIscompleting(false);
                setIscompletingSuccess(true);
                setIscompletingFail(false);
                setTimeout(() => {
                    setIscompletingSuccess(false);
                    dispatch(closeDisclosureDocumentModal());
                }, HIDE_MESSAGE_TIME);
            })
            .catch(() => {
                setIscompleting(false);
                setIscompletingSuccess(false);
                setIscompletingFail(true);
                setTimeout(() => {
                    setIscompletingFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    const viewPdf = docId => {
        if (!isEmpty(agentSignature)) {
            window.open(`/reader/document/disclosure/${docId}`, '_blank');
        } else {
            dispatch(openNoSignatureModal());
        }
    };

    const renderSaveButton = () => {
        return (
            <Button
                primary
                className="btn-terminate"
                height={40}
                type="submit"
                // set submitType in here, then use that is handleSubmit to decide send or save
                onClick={() => form.change('submitType', FORM_SUBMIT_TYPE_SAVE)}
                disabled={isSendingDisclosure}
            >
                {isSendingDisclosure && <span className="savestatus-spinner-await-completion" />}
                Save
            </Button>
        );
    };

    const renderSendButton = () => {
        return (
            <Button
                primary
                className="btn-terminate"
                height={40}
                type="submit"
                // set submitType in here, then use that is handleSubmit to decide send or save
                onClick={() => form.change('submitType', FORM_SUBMIT_TYPE_SEND)}
                disabled={isSendingDisclosure}
            >
                {isSendingDisclosure && <span className="savestatus-spinner-await-completion" />}
                Send
            </Button>
        );
    };

    const renderCloseButton = () => {
        return (
            <Button
                secondary
                className="btn-close"
                type="button"
                height={40}
                onClick={closeModal}
                disabled={isSendingDisclosure}
            >
                Cancel
            </Button>
        );
    };

    const getCreateButtons = () => (
        <div className="button-row">
            <div className="button-footer-docs">{renderCloseButton()}</div>
            <div className="button-footer-docs">{renderSaveButton()}</div>
            <div className="button-footer-docs">{renderSendButton()}</div>
        </div>
    );

    function getSentForSigningButtons() {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => viewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
                <div className="button-row single">
                    {isResendingSuccess && (
                        <p className="button-result savestatus-saved">
                            Document {isSaveDraft ? 'saved' : 'sent'} successfully
                            <span className="icon savestatus-icon" />
                        </p>
                    )}
                    {isResendingFail && (
                        <p className="button-result savestatus-failed">
                            {isSaveDraft ? 'Saving' : 'Sending'} document failed{' '}
                            <span className="icon savestatus-icon" />
                        </p>
                    )}
                </div>
                <div className="button-row single">
                    <div className="button-footer-docs">
                        <Button
                            primary
                            className="btn-terminate"
                            disabled={isResending}
                            type="button"
                            onClick={() => resendLink(currentDocument.id)}
                        >
                            Resend
                            {isResending && <span className="savestatus-spinner-await-completion" />}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    function getAwaitingCompletionButtons() {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => viewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
                <div className="button-row single">
                    {iscompletingSuccess && (
                        <p className="button-result savestatus-saved">
                            Completed successfully
                            <span className="icon savestatus-icon" />
                        </p>
                    )}
                    {iscompletingFail && (
                        <p className="button-result savestatus-failed">
                            Completion failed <span className="icon savestatus-icon" />
                        </p>
                    )}
                </div>
                <div className="button-row single">
                    <div className="button-footer-docs">
                        <Button
                            primary
                            className="btn-terminate"
                            disabled={iscompleting}
                            type="button"
                            onClick={() => completeDocument(currentDocument.id)}
                        >
                            Complete
                            {iscompleting && <span className="savestatus-spinner-await-completion" />}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    function getCompletedButtons() {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => viewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
            </div>
        );
    }

    return (
        <React.Fragment>
            {!isDocumentCreateMode(documentEditMode) &&
                currentDocument &&
                currentDocument.auditTrail &&
                currentDocument.auditTrail.length > 0 && (
                    <AgreementMainAuditTrail leaseId={currentDocument.id} isDocument={true} />
                )}
            {isDocumentEditMode(documentEditMode) && (
                <div className="button-row single">
                    {isSubmitSuccessfull && (
                        <span className="savestatus-saved">
                            <span className="icon savestatus-icon" />
                            Disclosure document {isSaveDraft ? 'saved' : 'sent'} successfully
                        </span>
                    )}
                    {isSubmitFail && (
                        <span className="savestatus-failed">
                            <span className="icon savestatus-icon" />
                            {isSaveDraft ? 'Saving' : 'Sending'} disclosure document failed
                        </span>
                    )}
                </div>
            )}
            {(isDocumentCreateMode(documentEditMode) || isDocumentEditMode(documentEditMode)) && getCreateButtons()}
            {currentDocument &&
                !isDocumentCreateMode(documentEditMode) &&
                isDocumentStatusSentForSigning(currentDocument.status) &&
                getSentForSigningButtons()}
            {currentDocument &&
                !isDocumentCreateMode(documentEditMode) &&
                isDocumentAwaitingCompletion(currentDocument.status) &&
                getAwaitingCompletionButtons()}
            {currentDocument &&
                !isDocumentCreateMode(documentEditMode) &&
                isDocumentComplete(currentDocument.status) &&
                getCompletedButtons()}
        </React.Fragment>
    );
};
export default DisclosureDocumentFormFooter;
