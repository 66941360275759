import React, { memo, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import ModalDialog from '../../../common/components/ModalDialog';
import Button from '../../../common/components/Button';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
    closeResendLandlordInformationStatementModal,
    updateLeaseData
} from '../../../actions/resendLandlordInformationStatement';

import * as validators from '../../Validate';
import { renderLandlordInformationStatementStatus } from '../../../utils/agreementUtils';
import {
    LANDLORD_INFORMATION_STATEMENT_STATUS_NOT_SENT,
    LANDLORD_INFORMATION_STATEMENT_READY_TO_BE_SENT,
    LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_EMAIL,
    LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_SMS,
    LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
} from '../../../config';

import '../../../sass/resendModal.scss';
import { ErrorOutlineSharp } from '@material-ui/icons';
import LandlordInformationStatementPerson from './LandlordInformationStatementPerson';
import ReactTooltip from 'react-tooltip';
import { LEASE_STATUS_AWAITING_COMPLETION, LEASE_STATUS_COMPLETE } from '../../../actions/dashboard';
import { getIsResendLandlordInformationStatementModalOpen } from '../../../selectors/resendInformationStatement';

const SendLandlordInformationStatementModal = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(getIsResendLandlordInformationStatementModalOpen);
    const agreementInfo = useSelector(state => state.dashboard.agreementInfo, shallowEqual);
    let [isResending, setIsResending] = useState(false);
    let [error, setError] = useState('');

    const closeModal = () => {
        dispatch(closeResendLandlordInformationStatementModal());
    };

    const handleResend = values => {
        let selectedPersons = values.persons.filter(item => item.sendLandlordInformationStatement);
        if (values.actionType !== 'save-and-send-with-lease') {
            selectedPersons = selectedPersons.filter(
                item => item.landlordInformationStatementStatus !== LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED
            );
        }
        if (selectedPersons.length > 0 || values.actionType === 'save-and-send-with-lease') {
            setIsResending(true);
            setError('');
            axios
                .post(`/api/agency/lease/${agreementInfo.id}/send-landlord-information-statement`, values)
                .then(response => {
                    setIsResending(false);
                    dispatch(updateLeaseData(response.data.lease));
                    closeModal();
                })
                .catch(err => {
                    setIsResending(false);
                    setError(err.response.data.error.message);
                    dispatch(updateLeaseData(err.response.data.lease));
                });
        } else if (selectedPersons.length === 0) {
            setError(`Please select a landlord to receive the statement.`);
        }
    };

    const renderRightButton = form => {
        return (
            <div className="button-group">
                <ReactTooltip
                    id={`saveAndSendWithLease`}
                    class="tooltip"
                    place="top"
                    effect="solid"
                    globalEventOff="wheel"
                >
                    <p>
                        Save these details and the landlord information statement will <br /> be automatically sent at
                        the same time you send the lease.
                    </p>
                </ReactTooltip>
                {agreementInfo.status !== LEASE_STATUS_AWAITING_COMPLETION &&
                    agreementInfo.status !== LEASE_STATUS_COMPLETE && (
                        <Button
                            type="submit"
                            onClick={() => {
                                form.change('actionType', 'save-and-send-with-lease');
                            }}
                            primary
                            className="btn-upgrade"
                            height={50}
                            disabled={isResending}
                            data-tip
                            data-for={`saveAndSendWithLease`}
                        >
                            Save details
                        </Button>
                    )}

                <Button
                    onClick={() => {
                        form.change('actionType', 'save-and-send');
                    }}
                    type="submit"
                    primary
                    className="btn-upgrade"
                    height={50}
                    disabled={isResending}
                >
                    Send now
                </Button>
            </div>
        );
    };

    const renderLeftButton = () => {
        return (
            <Button secondary className="btn-close" height={50} onClick={closeModal} disabled={isResending}>
                Cancel
            </Button>
        );
    };

    const hasSentLandlordStatement = agreementInfo => {
        if (agreementInfo.landlord) {
            let isSent = false;
            agreementInfo.landlord.persons.forEach(person => {
                if (
                    person.landlordInformationStatementStatus !== LANDLORD_INFORMATION_STATEMENT_STATUS_NOT_SENT &&
                    person.landlordInformationStatementStatus !== LANDLORD_INFORMATION_STATEMENT_READY_TO_BE_SENT
                ) {
                    isSent = true;
                }
            });
            return isSent;
        }
        return false;
    };

    const getLandlordText = (person, index) => {
        return (
            <React.Fragment>
                {person.isCorporation ? person.corporationName : person.fullName}{' '}
                {renderLandlordInformationStatementStatus(
                    person.landlordInformationStatementStatus,
                    agreementInfo.landlord.informationStatementManuallyAcknowledged,
                    person.sendMethod,
                    index
                )}
            </React.Fragment>
        );
    };

    return (
        <ModalDialog
            title={(hasSentLandlordStatement(agreementInfo) ? 'Resend' : 'Send') + ' landlord information statement'}
            isOpen={isOpen}
            closeModal={closeModal}
            customBodyAndFooter={true}
            shouldCloseOnOverlayClick={false}
            className="resend-lease-modal small-modal-dialog"
        >
            <Form
                initialValues={{
                    persons:
                        agreementInfo.landlord && agreementInfo.landlord.persons ? agreementInfo.landlord.persons : [],
                    actionType: ''
                }}
                mutators={{
                    ...arrayMutators
                }}
                onSubmit={handleResend}
                validate={({ persons }) => {
                    let errors = [];
                    persons.forEach(person => {
                        let error = {};
                        if (person.sendLandlordInformationStatement) {
                            if (!person.sendMethod) {
                                error.sendMethod = 'Select send method';
                            } else if (person.sendMethod === LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_EMAIL) {
                                if (!person.email) {
                                    error.email = 'Email is required';
                                } else if (!validators.isCorrectEmail(person.email)) {
                                    error.email = 'Email is not valid';
                                }
                            } else if (person.sendMethod === LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_SMS) {
                                if (
                                    !person.mobile ||
                                    person.mobile === '+' ||
                                    person.mobile === '+61' ||
                                    person.mobile.length <= 5
                                ) {
                                    error.mobile = 'Mobile number is required';
                                } else if (!validators.isMobilePhone(person.mobile)) {
                                    error.mobile = 'Mobile number is not valid';
                                }
                            }
                        }
                        errors.push(error);
                    });

                    return {
                        persons: errors
                    };
                }}
            >
                {({ handleSubmit, form }) => {
                    return (
                        <form id="resend-landlord-information-statement-form" onSubmit={handleSubmit} noValidate>
                            <div className="modal-body">
                                <p className="text-warning">
                                    <ErrorOutlineSharp />
                                    If you clone this lease the landlord activity will no longer be linked to the new
                                    version of the lease
                                </p>
                                <br />
                                <p className="instructions">
                                    {'Select which landlord should receive the information statement'}
                                </p>
                                <div className="send-information-wrapper">
                                    <div className="send-information-email">
                                        <FieldArray name="persons">
                                            {({ fields }) =>
                                                fields.map((name, index) => (
                                                    <LandlordInformationStatementPerson
                                                        key={index}
                                                        index={index}
                                                        person={fields.value[index]}
                                                        landlordText={getLandlordText(fields.value[index], index)}
                                                        form={form}
                                                    />
                                                ))
                                            }
                                        </FieldArray>
                                        {error && <div className="has-error">{error}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div>{renderLeftButton()}</div>
                                <div>{renderRightButton(form)}</div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
};

export default memo(SendLandlordInformationStatementModal);
