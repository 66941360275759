import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmAgreementTerm } from '../../../../selectors/lease/pmLease';

import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import { FormResponsiveNumbers } from '../../../../components/form/responsive/FormResponsiveNumbers';
import '../../../../sass/management/agreementTerm.scss';

const PM_AGREEMENT_TERM_FORM = 'pmAgreementTermForm';
const initState = {
    startDate: '',
    endDate: '',
    propertyAvailabilityDate: '',
    noticePeriod: 30
};

const AgreementTerm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmAgreementTerm = useSelector(getPmAgreementTerm);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmAgreementTerm, setPmAgreementTerm] = useState(reduxPmAgreementTerm || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_AGREEMENT_TERM_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmAgreementTerm(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmAgreementTerm')) {
                    return error.response.data.errors.pmAgreementTerm;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmAgreementTerm = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-agreement-term`, data);
    };

    useEffect(() => {
        if (pmAgreementTerm.startDate) {
            setPmAgreementTerm({ ...pmAgreementTerm, startDate: new Date(pmAgreementTerm.startDate) });
        } else {
            setPmAgreementTerm({ ...pmAgreementTerm, startDate: new Date() });
        }
    }, []);

    return (
        <div className="agreementTerm">
            <Form onSubmit={submitForm} initialValues={pmAgreementTerm}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off" id={PM_AGREEMENT_TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Date the authority is granted from"
                                    name="startDate"
                                    selected={values.startDate}
                                    required
                                />
                            </div>
                            <FormResponsiveNumbers
                                name="noticePeriod"
                                label="Agreement can be terminated with"
                                options={[30, 60, 90]}
                                otherLabel="Other"
                                required
                                postFix=" days notice"
                                form={form}
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(AgreementTerm);
