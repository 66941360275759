export const UPDATE_EXPENSES_AND_CHARGES_FORM = 'UPDATE_EXPENSES_AND_CHARGES_FORM';

const initState = {
    officeExpenses: '',
    advertising: ''
};

export default function expensesAndChargesReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_EXPENSES_AND_CHARGES_FORM: {
            return { ...state, ...action.data };
        }

        default: {
            return state;
        }
    }
}
