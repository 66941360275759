import React from 'react';

import { FormTextRegular } from '../../../form/FormText';
import { FormRadioGroup } from '../../../form/FormRadioGroup';

export const OtherMaterialFactsGroup = ({ index, removeOtherMaterialFact }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Custom Material Fact {index + 1}</h3>
                    <div className="toggle" onClick={() => removeOtherMaterialFact(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular name={`other[${index}].fact`} label="Material Fact" />
                    <FormRadioGroup
                        label=""
                        name={`other[${index}].answer`}
                        radioGroupClass="other-facts"
                        data={[
                            {
                                label: 'Yes',
                                className: `other${index}-answer-yes`,
                                value: 'yes',
                                id: `material-other${index}-answer-yes`
                            },
                            {
                                label: 'No',
                                className: `other${index}-answer-no`,
                                value: 'no',
                                id: `material-other${index}-answer-no`
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};
