import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../sass/faq.scss';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}
    render() {
        return <h1>Faq</h1>;
    }
}

export default connect(
    state => ({}),
    {}
)(Faq);
