import React, { Component } from 'react';
import '../../sass/helpMessage.scss';

class HelpMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="help-message">
                <p className="message">
                    By proceeding to create your account and use FLK, you are agreeing to our{' '}
                    <a
                        href="https://www.flkitover.com/terms-conditions/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="bold"
                    >
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://www.flkitover.com/privacy-policy/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="bold"
                    >
                        Privacy Policy
                    </a>
                    . If you do not agree, you cannot use FLK.
                </p>
            </div>
        );
    }
}

export default HelpMessage;
