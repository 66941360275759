import React from 'react';
import { FormTextRegular } from '../form/FormText';
import { FormEmail } from '../form/FormEmail';
import { CheckboxCheck } from '../form/FormCheckboxCheck';
import { FormPhone } from '../form/FormPhone';

const DisclosureRentalProviderGroup = ({
    index,
    removeRentalProvider,
    disabled,
    values,
    handlePrimaryLandlordSelect
}) => {
    let header;
    if (index === 0) {
        header = <h3 className="form-section__title">Rental provider {index + 1}</h3>;
    } else {
        header = (
            <span className="form-section__header">
                <h3>Rental provider {index + 1}</h3>
                <button type="button" onClick={disabled ? () => {} : () => removeRentalProvider(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="form-section">
            {header}
            <div className="form-section__body">
                <div className="formBox-column">
                    <div className="nowrap larger">
                        <FormTextRegular
                            name={`landlords[${index}].firstName`}
                            label="First Name"
                            required
                            disabled={disabled}
                        />
                        <FormTextRegular
                            name={`landlords[${index}].lastName`}
                            label="Last Name"
                            required
                            disabled={disabled}
                        />
                    </div>
                    <div className="nowrap larger">
                        <FormEmail
                            name={`landlords[${index}].email`}
                            label="Email"
                            required={values.landlords[index].isPrimaryLandlord}
                            disabled={disabled}
                            tooltip="Email address the finalised document will be sent to"
                        />
                        {values.landlords[index].isPrimaryLandlord && (
                            <FormPhone
                                name={`landlords[${index}].phone`}
                                dataTest={`landlords-${index}-phone`}
                                label="Mobile"
                                required
                                disabled={disabled}
                                tooltip="If there are multiple rental providers, it will only be sent to one RRP (primary) to capture answers to the Disclosure doc"
                                tooltipPlace="right"
                            />
                        )}
                    </div>
                    <CheckboxCheck
                        name={`landlords[${index}].isPrimaryLandlord`}
                        label="Primary rental provider"
                        value={`persons[${index}].isPrimaryLandlord`}
                        onClick={value => handlePrimaryLandlordSelect(index, value)}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default DisclosureRentalProviderGroup;
