import React from 'react';
import { LEASE_TYPE_HOLIDAY_LETTING } from '../../../config';
import * as dashboard from '../../../actions/dashboard.js';

import TableDraft from './tables/TableDraft.js';
import TableSentForSigning from './tables/TableSentForSigning.js';
import TableAwaiting from './tables/TableAwaiting.js';
import TableArchived from './tables/TableArchived.js';

import '../../../sass/dashboardContentTable.scss';
import '../../../sass/_radial-progress.scss';
import { DEFINE_ME } from '../../../types/utilityTypes';

function getContentTable(showModalInfoAgreement: (lease: DEFINE_ME) => void, agreementList: [], leaseStatus: string) {
    switch (leaseStatus) {
        case dashboard.LEASE_STATUS_DRAFT: {
            return (
                <TableDraft
                    agreementList={agreementList}
                    showModalInfoAgreement={(lease: DEFINE_ME) => showModalInfoAgreement(lease)}
                    leaseStatus={leaseStatus}
                    leaseType={LEASE_TYPE_HOLIDAY_LETTING}
                />
            );
        }
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            return (
                <TableSentForSigning
                    agreementList={agreementList}
                    showModalInfoAgreement={(lease: DEFINE_ME) => showModalInfoAgreement(lease)}
                    leaseStatus={leaseStatus}
                    leaseType={LEASE_TYPE_HOLIDAY_LETTING}
                />
            );
        }
        case dashboard.LEASE_STATUS_AWAITING_COMPLETION: {
            return (
                <TableAwaiting
                    agreementList={agreementList}
                    showModalInfoAgreement={(lease: DEFINE_ME) => showModalInfoAgreement(lease)}
                    leaseStatus={leaseStatus}
                    leaseType={LEASE_TYPE_HOLIDAY_LETTING}
                />
            );
        }
        case dashboard.LEASE_STATUS_ARCHIVED: {
            return (
                <TableArchived
                    agreementList={agreementList}
                    showModalInfoAgreement={(lease: DEFINE_ME) => showModalInfoAgreement(lease)}
                    leaseStatus={leaseStatus}
                    leaseType={LEASE_TYPE_HOLIDAY_LETTING}
                />
            );
        }
    }
}

type HolidayLettingType = {
    showModalInfoAgreement: (lease: DEFINE_ME) => void;
    agreementList: [];
    leaseStatus: string;
};

const HolidayLetting: React.FC<HolidayLettingType> = ({ showModalInfoAgreement, agreementList, leaseStatus }) => {
    const contentTable = getContentTable(showModalInfoAgreement, agreementList, leaseStatus);
    return <div className="table-sort">{contentTable}</div>;
};

export default HolidayLetting;
