import { OPEN_ADDRESS_DETAILS_MODAL, CLOSE_ADDRESS_DETAILS_MODAL, CONFIRM_ADDRESS_DETAILS, CLEAR_ADDRESS_DETAILS } from '../actions/types';

export function openAddressDetailsModal(data) {
    return {
        type: OPEN_ADDRESS_DETAILS_MODAL,
        payload: {
            data
        }
    };
}

export function closeAddressDetailsModal() {
    return {
        type: CLOSE_ADDRESS_DETAILS_MODAL
    };
}

export function clearAddressDetailsStore() {
    return {
        type: CLEAR_ADDRESS_DETAILS
    };
}

export function confirmAddressDetails(selectedAddress, editAddressFormData) {
    const data = {
        selectedAddress: selectedAddress,
        editAddressFormData: editAddressFormData
    };
    return {
        type: CONFIRM_ADDRESS_DETAILS,
        payload: {
            data
        }
    };
}
