import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ThumbDisplay from '../../../image/ThumbDisplay';
import parse from 'html-react-parser';
import { getLabel } from '../../../../../utils/labelUtils';
import {
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    NSW_STATE,
    QLD_STATE,
    VIC_STATE,
    SA_STATE,
    YES_LABEL,
    NO_LABEL,
    LANDLORD_LABEL
} from '../../../../../config';
import { upperFirst } from 'lodash';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../../../../../utils/agreementUtils';

const ANSWER_VALUE_TRUE = 'true';

function Outgoings({ leaseAllInfo }) {
    if (leaseAllInfo.pmLandlord.pmPrimaryLandlord.pmOutGoingsAnswers) {
        return (
            <Accordion className="accordian-with-border">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="outgoings-content"
                    id="outgoings-header"
                >
                    Declared outgoings to pay? &nbsp;
                    <b>{leaseAllInfo.pmLandlord.pmAnswersSummary.hasOutgoings}</b>
                </AccordionSummary>
                <AccordionDetails className="accordian-details">
                    {leaseAllInfo.pmLandlord.pmPrimaryLandlord.pmOutGoingsAnswers.questions.map((outgoing, index) => {
                        if (outgoing.answer === ANSWER_VALUE_TRUE) {
                            return (
                                <div key={index}>
                                    <p>{outgoing.questionText}</p>
                                    <p>
                                        Instructions: &nbsp;
                                        <b>{outgoing.details ? outgoing.details : 'Nil'}</b>
                                    </p>
                                    <div>
                                        {outgoing.image && (
                                            <ThumbDisplay
                                                imageSrc={outgoing.image}
                                                imageTitle={outgoing.questionText}
                                            />
                                        )}
                                    </div>
                                    <br />
                                </div>
                            );
                        } else {
                            return (
                                <div key={index}>
                                    <p>
                                        <strike>{outgoing.questionText}</strike>
                                    </p>
                                    <br />
                                </div>
                            );
                        }
                    })}
                </AccordionDetails>
            </Accordion>
        );
    }
    return null;
}

function BankDetails({ leaseAllInfo }) {
    return (
        <Accordion className="fixed-accordian">
            <AccordionSummary>
                Entered bank details? &nbsp;
                <b>{leaseAllInfo.pmLandlord.pmAnswersSummary.enteredBankDetails}</b>
            </AccordionSummary>
        </Accordion>
    );
}

function Insurance({ leaseAllInfo }) {
    const insurance = leaseAllInfo.pmLandlord.pmAnswersSummary.insurance;
    if (!insurance) {
        return <div />;
    }
    if (isLeaseLocationNSW(leaseAllInfo.location)) {
        return (
            <Accordion className="fixed-accordian">
                <AccordionSummary>
                    Authorises the Agent to arrange landlord insurance? &nbsp;
                    <b>{insurance}</b>
                </AccordionSummary>
            </Accordion>
        );
    } else if (isLeaseLocationQLD(leaseAllInfo.location)) {
        const {
            noInsurance,
            agentToObtainQuote,
            agreeToObtainInsurance,
            landlordInsurance,
            buildingInsurance,
            publicLiabilityInsurance,
            otherInsurance,
            otherInsuranceAnswer
        } = insurance;
        return (
            <>
                {!noInsurance && (
                    <Accordion className="accordian-with-border">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="outgoings-content"
                            id="outgoings-header"
                        >
                            Entered insurance currently held? &nbsp;
                            <b>{YES_LABEL}</b>
                        </AccordionSummary>
                        <AccordionDetails className="accordian-details">
                            <p>
                                Landlord insurance &nbsp;
                                <b>{landlordInsurance ? YES_LABEL : NO_LABEL}</b>
                            </p>
                            <p>
                                Building insurance &nbsp;
                                <b>{buildingInsurance ? YES_LABEL : NO_LABEL}</b>
                            </p>
                            <p>
                                Public Liability insurance &nbsp;
                                <b>{publicLiabilityInsurance ? YES_LABEL : NO_LABEL}</b>
                            </p>
                            <p>
                                Other insurance &nbsp;
                                <b>{otherInsurance ? YES_LABEL : NO_LABEL}</b>
                            </p>
                            <p>
                                <i>{otherInsuranceAnswer}</i>
                            </p>
                        </AccordionDetails>
                    </Accordion>
                )}
                {noInsurance && (
                    <Accordion className="accordian-with-border">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="outgoings-content"
                            id="outgoings-header"
                        >
                            Authorises the Agent to arrange landlord insurance? &nbsp;
                            <b>{YES_LABEL}</b>
                        </AccordionSummary>
                        <AccordionDetails className="accordian-details">
                            {agreeToObtainInsurance && (
                                <p>
                                    I acknowledge and agree to obtain insurance, which provides public liability cover
                                    up to $10m, on or before the commencement of a tenancy &nbsp;
                                    <b>{YES_LABEL}</b>
                                </p>
                            )}
                            {agentToObtainQuote && (
                                <p>
                                    I authorise the agent to obtain a quote &nbsp;
                                    <b>{YES_LABEL}</b>
                                </p>
                            )}
                        </AccordionDetails>
                    </Accordion>
                )}
            </>
        );
    }
    return <div />;
}

function MaterialFacts({ leaseAllInfo }) {
    return (
        <Accordion
            className={leaseAllInfo.pmLandlord.pmAnswersSummary.hasMaterialFacts === YES_LABEL ? '' : 'fixed-accordian'}
        >
            <AccordionSummary
                expandIcon={
                    leaseAllInfo.pmLandlord.pmAnswersSummary.hasMaterialFacts === YES_LABEL && <ExpandMoreIcon />
                }
                aria-controls="material-content"
                id="material-header"
            >
                Declared a material fact? &nbsp;
                <b>{leaseAllInfo.pmLandlord.pmAnswersSummary.hasMaterialFacts}</b>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {leaseAllInfo.pmLandlord.pmPrimaryLandlord.pmMaterialFactAnswers &&
                        leaseAllInfo.pmLandlord.pmPrimaryLandlord.pmMaterialFactAnswers.questions.map((fact, index) => {
                            if (fact.answer === ANSWER_VALUE_TRUE) {
                                return (
                                    <div key={index}>
                                        {parse(fact.questionText)} <b>Yes</b>
                                        <p>
                                            <i>{fact.details}</i>
                                        </p>
                                        <br />
                                    </div>
                                );
                            }
                        })}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

function SwimmingPoolCompliance({ leaseAllInfo }) {
    return (
        <Accordion className="fixed-accordian">
            <AccordionSummary>
                Authorises the agent to obtain one swimming pool compliance? &nbsp;
                <b>{leaseAllInfo.pmLandlord.pmAnswersSummary.swimmingPoolCompliance}</b>
            </AccordionSummary>
        </Accordion>
    );
}

function IntentionToSell({ leaseAllInfo }) {
    return (
        <Accordion
            className={leaseAllInfo.pmLandlord.pmAnswersSummary.intentionToSell === YES_LABEL ? '' : 'fixed-accordian'}
        >
            <AccordionSummary
                expandIcon={
                    leaseAllInfo.pmLandlord.pmAnswersSummary.intentionToSell === YES_LABEL && <ExpandMoreIcon />
                }
                aria-controls="disclosure-content"
                id="disclosure-header"
            >
                Declared an intention to sell? &nbsp;
                <b>{leaseAllInfo.pmLandlord.pmAnswersSummary.intentionToSell}</b>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {leaseAllInfo.pmLandlord.pmPrimaryLandlord.pmDisclosureOfInformationAnswers &&
                        leaseAllInfo.pmLandlord.pmPrimaryLandlord.pmDisclosureOfInformationAnswers.questions.map(
                            (fact, index) => {
                                if (fact.answer) {
                                    return (
                                        <div key={index}>
                                            {fact.questionText} &nbsp;
                                            <b>{fact.answer === ANSWER_VALUE_TRUE ? YES_LABEL : NO_LABEL}</b>
                                        </div>
                                    );
                                }
                            }
                        )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

function WaterCharges({ leaseAllInfo }) {
    const waterCharges = leaseAllInfo.pmLandlord.pmAnswersSummary.waterCharges;
    if (!waterCharges) {
        return <div />;
    }
    const { doesComply, doesNotComply, notIndividuallyMetered, waterCost } = waterCharges;
    return (
        <Accordion className="accordian-with-border">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="outgoings-content" id="outgoings-header">
                Advised of the property's water consumption charges preference? &nbsp;
                <b>{YES_LABEL}</b>
            </AccordionSummary>
            <AccordionDetails className="accordian-details">
                {doesComply && (
                    <p>
                        The Property is compliant with prescribed water efficiency requirements and therefore all water
                        consumption charges are recoverable from the tenant.
                    </p>
                )}
                {doesNotComply && (
                    <p>
                        The Property is not compliant with prescribed water efficiency levels and is individually water
                        metered. The Client may pay the water consumption costsup to a maximum of {waterCost}
                    </p>
                )}
                {notIndividuallyMetered && (
                    <p>
                        The property is not individually metered for water. The Client must pay all water costs
                        associated with the Property.
                    </p>
                )}
            </AccordionDetails>
        </Accordion>
    );
}

function PoolSafetyCompliance({ leaseAllInfo }) {
    const poolSafety = leaseAllInfo.pmLandlord.pmAnswersSummary.poolSafety;
    if (!poolSafety) {
        return <div />;
    }
    return (
        <Accordion className="fixed-accordian">
            <AccordionSummary>
                Authorises the Agent to erect a portable pool with a depth of 300mm or greater? &nbsp;
                <b>{poolSafety}</b>
            </AccordionSummary>
        </Accordion>
    );
}

function PriorAppointment({ leaseAllInfo }) {
    const isNoOtherPriorAppointment = leaseAllInfo.pmLandlord.pmAnswersSummary.isNoOtherPriorAppointment;
    return (
        <Accordion className="accordian-with-border">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="outgoings-content" id="outgoings-header">
                Advised of any prior appointments? &nbsp;
                <b>{YES_LABEL}</b>
            </AccordionSummary>
            <AccordionDetails className="accordian-details">
                {isNoOtherPriorAppointment && <p>No other appointment is current as at the date of this Agreement.</p>}
                {!isNoOtherPriorAppointment && (
                    <p>
                        Another appointment engaging the services of a Property Agent is current for the services
                        outlined in this appointment.
                    </p>
                )}
            </AccordionDetails>
        </Accordion>
    );
}

function ThirdPartyPayments({ leaseAllInfo }) {
    return (
        <Accordion
            className={
                leaseAllInfo.pmLandlord.pmAnswersSummary.enteredThirdPartyPayment === YES_LABEL ? '' : 'fixed-accordian'
            }
        >
            <AccordionSummary
                expandIcon={
                    leaseAllInfo.pmLandlord.pmAnswersSummary.enteredThirdPartyPayment === YES_LABEL && (
                        <ExpandMoreIcon />
                    )
                }
                aria-controls="third-party-payments-content"
                id="third-party-payments-header"
            >
                Entered third party payment authority details? &nbsp;
                <b>{YES_LABEL}</b>
            </AccordionSummary>
            <AccordionDetails className="accordian-details">
                <div>
                    {leaseAllInfo.pmLandlord.pmAnswersSummary?.thirdPartyPaymentAnswers?.agentToPayList.length > 0 && (
                        <>
                            <h4>Agent to pay</h4>
                            {leaseAllInfo.pmLandlord.pmAnswersSummary.thirdPartyPaymentAnswers.agentToPayList.map(
                                (payment, index) => {
                                    return (
                                        <div key={index} className="data">
                                            <p>
                                                {payment.label} <b>{payment.answer}</b>
                                            </p>
                                            {payment.displayDetails && (
                                                <p>
                                                    <i>{payment.displayDetails}</i>
                                                </p>
                                            )}
                                            {payment.imageUrl && <ThumbDisplay imageSrc={payment.imageUrl} />}
                                        </div>
                                    );
                                }
                            )}
                        </>
                    )}
                    {leaseAllInfo.pmLandlord.pmAnswersSummary?.thirdPartyPaymentAnswers?.ownerToInstructList.length >
                        0 && (
                        <>
                            <h4>Owner to instruct</h4>
                            {leaseAllInfo.pmLandlord.pmAnswersSummary.thirdPartyPaymentAnswers.ownerToInstructList.map(
                                (payment, index) => {
                                    return (
                                        <div key={index} className="data">
                                            <p>
                                                {payment.label} <b>{payment.answer}</b>
                                            </p>
                                            {payment.displayDetails && (
                                                <p>
                                                    <i>{payment.displayDetails}</i>
                                                </p>
                                            )}
                                            {payment.imageUrl && <ThumbDisplay imageSrc={payment.imageUrl} />}
                                        </div>
                                    );
                                }
                            )}
                        </>
                    )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

function CurrentInsurance({ leaseAllInfo }) {
    const currentInsuranceAnswers = leaseAllInfo.pmLandlord.pmPrimaryLandlord.currentInsuranceAnswers;
    return (
        <Accordion
            className={
                leaseAllInfo.pmLandlord.pmAnswersSummary.enteredCurrentInsurance === YES_LABEL ? '' : 'fixed-accordian'
            }
        >
            <AccordionSummary
                expandIcon={
                    leaseAllInfo.pmLandlord.pmAnswersSummary.enteredCurrentInsurance === YES_LABEL && <ExpandMoreIcon />
                }
                aria-controls="insurance-content"
                id="insurance-header"
            >
                Provided their current Insurance details?&nbsp;
                <b>{leaseAllInfo.pmLandlord.pmAnswersSummary.enteredCurrentInsurance}</b>
            </AccordionSummary>
            <AccordionDetails className="accordian-details">
                {currentInsuranceAnswers.isPropertyInsurance === YES_LABEL && (
                    <div className="data">
                        <p>Current Property Insurance: {YES_LABEL}</p>
                        <p>
                            {currentInsuranceAnswers.propertyInsuranceName} - Policy number{' '}
                            {currentInsuranceAnswers.propertyInsuranceNumber} expiring{' '}
                            {currentInsuranceAnswers.propertyInsuranceExpiry}
                        </p>
                    </div>
                )}
                {currentInsuranceAnswers.isLandlordInsurance === YES_LABEL && (
                    <div className="data">
                        <p>Current Landlord Insurance: {YES_LABEL}</p>
                        <p>
                            {currentInsuranceAnswers.landlordInsuranceName} - Policy number{' '}
                            {currentInsuranceAnswers.landlordInsuranceNumber} expiring{' '}
                            {currentInsuranceAnswers.landlordInsuranceExpiry}
                        </p>
                    </div>
                )}
            </AccordionDetails>
        </Accordion>
    );
}

function OwnerDisclosure({ leaseAllInfo }) {
    if (leaseAllInfo.pmRentAndPayment) {
        return (
            <Accordion className="accordian-with-border">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="owners-disclosure-content"
                    id="owners-disclosure-header"
                >
                    Has the property been leased out in the past 12 months under a General Tenancy Agreement or a rooming agreement as of today's date? &nbsp;
                    <b>{leaseAllInfo.pmRentAndPayment.isPropertyBeenLeaseOutUnderGTAFormatted}</b>
                </AccordionSummary>
                <AccordionDetails className="accordian-details">
                    <div>
                        <p>
                            Notice to leave for Sale Contract {' '}
                            <strong>
                                {leaseAllInfo.pmRentAndPayment.decidedToSellPropertyFormatted}
                            </strong>
                        </p>
                        <p>
                            Notice to leave for change of use {' '}
                            <strong>
                                {leaseAllInfo.pmRentAndPayment.decidedToChangeUsageFormatted}
                            </strong>
                        </p>
                        <p>
                            Notice to leave for owner occupation {' '}
                            <strong>
                                {leaseAllInfo.pmRentAndPayment.decidedToLiveInPropertyFormatted}
                            </strong>
                        </p>
                        <p className="name">
                            When was the last rent increase?{' '}
                            <strong>
                                {leaseAllInfo.pmRentAndPayment?.rentIncreaseDateFormatted}
                            </strong>
                        </p>
                        <p className="name">
                            The previous weekly rental amount was{' '}
                            <strong>
                                {leaseAllInfo.pmRentAndPayment?.rentIncreaseAmountFormatted}
                            </strong>
                        </p>
                        <br />
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }
    return null;
}

export default function AgreementPmClientInstructions({ leaseAllInfo }) {
    if (!leaseAllInfo) {
        return <div />;
    }
    return (
        <div>
            {leaseAllInfo && (
                <div className="section client-answers-section">
                    <h2>Client instructions</h2>
                    <p>
                        Has the{' '}
                        {getLabel('landlord', leaseAllInfo.location, LEASE_TYPE_PROPERTY_MANAGEMENT).toLowerCase()}
                        ...
                    </p>
                    <br />
                    {renderComponents()}
                </div>
            )}
        </div>
    );

    function renderComponents() {
        switch (leaseAllInfo.location) {
            case NSW_STATE:
                return (
                    <>
                        <Outgoings leaseAllInfo={leaseAllInfo} />
                        <BankDetails leaseAllInfo={leaseAllInfo} />
                        <Insurance leaseAllInfo={leaseAllInfo} />
                        <MaterialFacts leaseAllInfo={leaseAllInfo} />
                        <SwimmingPoolCompliance leaseAllInfo={leaseAllInfo} />
                        <IntentionToSell leaseAllInfo={leaseAllInfo} />
                    </>
                );
            case VIC_STATE:
                return (
                    <>
                        <Outgoings leaseAllInfo={leaseAllInfo} />
                        <BankDetails leaseAllInfo={leaseAllInfo} />
                    </>
                );
            case QLD_STATE:
                return (
                    <>
                        <BankDetails leaseAllInfo={leaseAllInfo} />
                        <Insurance leaseAllInfo={leaseAllInfo} />
                        <Outgoings leaseAllInfo={leaseAllInfo} />
                        <WaterCharges leaseAllInfo={leaseAllInfo} />
                        <PoolSafetyCompliance leaseAllInfo={leaseAllInfo} />
                        <PriorAppointment leaseAllInfo={leaseAllInfo} />
                        <OwnerDisclosure leaseAllInfo={leaseAllInfo} />
                    </>
                );
            case SA_STATE:
                return (
                    <>
                        <ThirdPartyPayments leaseAllInfo={leaseAllInfo} />
                        <BankDetails leaseAllInfo={leaseAllInfo} />
                        <CurrentInsurance leaseAllInfo={leaseAllInfo} />
                    </>
                );
            default:
                return <div />;
        }
    }
}
