import { AccountType, AGENCY_ACCOUNT_TYPE } from '../constants/constants';
import { TariffPlan } from '../types/TariffPlan';

export function pushPaymentDetailsToGTM(accountType: AccountType, tariffPlan: TariffPlan) {
    const accountTypeFriendly = accountType === AGENCY_ACCOUNT_TYPE ? 'Real Estate' : 'Business';
    const accountTypePrefix = accountType === AGENCY_ACCOUNT_TYPE ? 'RE' : 'BIZ';
    const planName = `${accountTypePrefix}_${tariffPlan.title.toLowerCase()}`;

    // intialise the datalayer if there is nothing there
    window.dataLayer = window.dataLayer || [];
    const GTMLayer = window.dataLayer;

    const data = {
        event: 'purchase',
        ecommerce: {
            transaction_id: Date.now(),
            value: tariffPlan.price,
            tax: 0,
            currency: 'AUD',
            coupon: 'n/a',
            items: [
                {
                    item_id: planName,
                    item_name: tariffPlan.title,
                    affiliation: accountTypeFriendly,
                    coupon: 'n/a',
                    discount: 'n/a',
                    index: 0,
                    item_brand: accountTypeFriendly,
                    item_category: accountTypeFriendly,
                    price: tariffPlan.price,
                    quantity: 1
                }
            ]
        }
    };
    // digital minds told us to put this here. it clears anything previous that was there
    GTMLayer.push({ ecommerce: null });
    GTMLayer.push(data);
}
