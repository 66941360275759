import React, { memo } from 'react';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../config';

import CommunityScheme from '../../../../components/lease/mainScreen/common/additionalTerms/CommunityScheme';
import AllowAnimals from '../../../../components/lease/mainScreen/common/additionalTerms/AllowAnimals';
import SwimmingPool from '../../../../components/lease/mainScreen/common/additionalTerms/SwimmingPool';
import DefenceClause from '../../../../components/lease/mainScreen/common/additionalTerms/DefenceClause';
import SeparatedWaterUsage from '../../../../components/lease/mainScreen/common/additionalTerms/SeparatedWaterUsage';
import LeaseRenewal from '../../../../components/lease/mainScreen/common/additionalTerms/LeaseRenewal';
import PremiseServices from '../../../../components/lease/mainScreen/common/additionalTerms/PremiseServices';

const QldAdditional = ({
    values,
    addPet,
    allowPets,
    clearPets,
    addAdditionalService,
    push,
    additional,
    location,
    loggedInUser
}) => {
    return (
        <React.Fragment>
            {values.communityScheme !== null && <CommunityScheme />}
            {values.communityScheme === YES_VALUE && values.tenantReceivedCommunityScheme !== null && (
                <div className="additional-group">
                    <div className="formBox-column">
                        <FormRadioGroup
                            label="Has the tenant been given a copy of the relevant by-laws?"
                            name={'tenantReceivedCommunityScheme'}
                            className="additional-term-item"
                            data={[
                                {
                                    label: YES_LABEL,
                                    className: 'yes-confirm-yes',
                                    id: 'yes-confirm-yes',
                                    value: YES_VALUE
                                },
                                {
                                    label: NO_LABEL,
                                    className: 'yes-confirm-no',
                                    id: 'yes-confirm-no',
                                    value: NO_VALUE
                                }
                            ]}
                        />
                    </div>
                </div>
            )}
            <AllowAnimals
                values={values}
                allowPets={allowPets}
                clearPets={clearPets}
                addPet={addPet}
                push={push}
                additional={additional}
                location={location}
            />
            <SwimmingPool values={values} hasSwimingPoolMaintenance={false} location={location} />
            <DefenceClause />
            <SeparatedWaterUsage />
            {values.isLeaseRenewalEnabled !== null && <LeaseRenewal values={values} loggedInUser={loggedInUser} />}
            {additional.premiseServices && (
                <PremiseServices addAdditionalService={addAdditionalService} additional={additional} push={push} />
            )}
        </React.Fragment>
    );
};

export default memo(QldAdditional);
