/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop, identity } from 'lodash';
import '../../sass/searchBarResults.scss';
import ItemDetails from './ItemDetails';

const NO_RESULTS_MESSAGE = 'No contacts match your search.';
const SEARCHING_MESSAGE = 'Searching...';

function ContactSearchResults({
    items,
    highlightedIndex,
    getItemProps,
    menuRef,
    displayMessage,
    searching,
    allowStaffSearch,
    contactType,
    ...props
}) {
    const hasItems = items.length > 0;
    let searchResults = [];
    let staffResults = [];

    function getSearchItem(item, index) {
        return (
            <li
                {...getItemProps({
                    key: item.id,
                    index,
                    item,
                    className: classnames({
                        highlighted: highlightedIndex === index
                    })
                })}
            >
                <p>
                    <b>{item.fullName || item.corporationName}</b> <ItemDetails item={item} />
                </p>
            </li>
        );
    }

    if (hasItems) {
        // pre-render the items...
        items.map((item, index) => {
            if (item.isStaff) {
                staffResults.push(getSearchItem(item, index));
            } else {
                searchResults.push(getSearchItem(item, index));
            }
        });
    }

    return (
        <div>
            <ul {...props} className="search-bar-results" ref={menuRef}>
                {searchResults.length > 0 ? (
                    searchResults
                ) : (
                    <li className="no-results">
                        {searching && <span>{SEARCHING_MESSAGE}</span>}
                        {!searching && <span>{displayMessage ? displayMessage : NO_RESULTS_MESSAGE}</span>}
                    </li>
                )}
            </ul>
            <ul {...props} className="search-bar-results" ref={menuRef}>
                {contactType && (
                    <React.Fragment>
                        <li className="result-section-title">
                            <span>Contacts</span>
                        </li>
                        {searchResults.length > 0 ? (
                            searchResults
                        ) : (
                            <li className="no-results">
                                {searching && <span>{SEARCHING_MESSAGE}</span>}
                                {!searching && <span>{displayMessage ? displayMessage : NO_RESULTS_MESSAGE}</span>}
                            </li>
                        )}
                    </React.Fragment>
                )}
                {allowStaffSearch && (
                    <React.Fragment>
                        <li className="result-section-title">
                            <span>Staff</span>
                        </li>
                        {staffResults.length > 0 ? (
                            staffResults
                        ) : (
                            <li className="no-results">
                                {searching && <span>{SEARCHING_MESSAGE}</span>}
                                {!searching && <span>{displayMessage ? displayMessage : NO_RESULTS_MESSAGE}</span>}
                            </li>
                        )}
                    </React.Fragment>
                )}
            </ul>
        </div>
    );
}

ContactSearchResults.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired
        })
    ),
    highlightedIndex: PropTypes.number,
    getItemProps: PropTypes.func,
    menuRef: PropTypes.func,
    displayMessage: PropTypes.string,
    allowStaffSearch: PropTypes.bool,
    contactType: PropTypes.string
};

ContactSearchResults.defaultProps = {
    items: [],
    highlightedIndex: -1,
    getItemProps: identity,
    menuRef: noop,
    displayError: false,
    allowStaffSearch: false
};

export default ContactSearchResults;
