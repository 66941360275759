import * as React from 'react';
import ModalDialog from '../../../common/components/ModalDialog';
import '../../../sass/conditions.scss';
import { isEmpty, map } from 'lodash';
import SpecialConditionWithResponse from '../shared/SpecialConditionWithAnswer';
import SpecialCondition from '../shared/SpecialCondition';

export const ModalViewPmAdditionalTerms = props => {
    return (
        <ModalDialog title="Additional terms" isOpen={props.isOpen} closeModal={props.closeModal}>
            <div className="agreement-section-text">
                <div>
                    {isEmpty(props.leaseAllInfo.specialConditions.clauseList) ? (
                        <p>No additional terms.</p>
                    ) : (
                        <div>
                            <ul>
                                {!(
                                    props.leaseAllInfo &&
                                    props.leaseAllInfo.pmLandlord.pmPrimaryLandlord &&
                                    props.leaseAllInfo.pmLandlord.pmPrimaryLandlord.pmAdditionalTerms
                                ) &&
                                    map(props.leaseAllInfo.specialConditions.clauseList, (value, index) => {
                                        return <SpecialCondition key={index} item={value} />;
                                    })}
                                {props.leaseAllInfo &&
                                    props.leaseAllInfo.pmLandlord.pmPrimaryLandlord &&
                                    props.leaseAllInfo.pmLandlord.pmPrimaryLandlord.pmAdditionalTerms &&
                                    props.leaseAllInfo.pmLandlord.pmPrimaryLandlord.pmAdditionalTerms.questions.map(
                                        (value, index) => {
                                            return <SpecialConditionWithResponse key={index} item={value} />;
                                        }
                                    )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </ModalDialog>
    );
};
