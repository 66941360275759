import { has } from 'lodash';
import {
    OPEN_CONFIRM_PLAN_UPGRADE_MODAL,
    CLOSE_CONFIRM_PLAN_UPGRADE_MODAL,
    UPDATE_PAYMENT_DETAILS_REQUEST,
    UPDATE_PAYMENT_DETAILS_SUCCESS,
    UPDATE_PAYMENT_DETAILS_FAIL,
    RETRY_PAYMENT_REQUEST,
    RETRY_PAYMENT_SUCCESS,
    RETRY_PAYMENT_FAIL,
    RESET_PAYMENT_STATE
} from '../actions/types';

import { SET_TARIFF_PLAN_SUCCESS, SET_TARIFF_PLAN_FAIL } from '../reducers/account';

import { upgradeSubscriptionModalStates as modalStates } from '../config';

const initState = {
    isConfirmPlanUpgradeModalOpen: false,
    activePlan: {},
    modalType: modalStates.CONFIRM_UPGRADE,
    isUpdatingPaymentDetails: false,
    updatedPaymentDetailsSuccess: false,
    validationErrors: {}
};

export default function search(state = initState, action) {
    switch (action.type) {
        case OPEN_CONFIRM_PLAN_UPGRADE_MODAL: {
            let newState = {
                ...state,
                isConfirmPlanUpgradeModalOpen: true,
                modalType: action.payload.modalType
            };

            if (has(action.payload, 'activePlan')) {
                newState.activePlan = action.payload.activePlan;
            }

            return newState;
        }
        case CLOSE_CONFIRM_PLAN_UPGRADE_MODAL: {
            return {
                ...initState
            };
        }
        case SET_TARIFF_PLAN_SUCCESS: {
            return {
                ...state,
                modalType: modalStates.UPGRADE_SUCCESSFUL
            };
        }
        case SET_TARIFF_PLAN_FAIL: {
            return {
                ...state,
                modalType: modalStates.UPGRADE_FAILED
            };
        }
        case UPDATE_PAYMENT_DETAILS_REQUEST: {
            return {
                ...state,
                isUpdatingPaymentDetails: true,
                updatedPaymentDetailsSuccess: false,
                updatedPaymentDetailsFail: false
            };
        }
        case UPDATE_PAYMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                isUpdatingPaymentDetails: false,
                updatedPaymentDetailsSuccess: true,
                updatedPaymentDetailsFail: false,
                retriedPaymentFail: false,
                retriedPaymentSuccess: false,
                retryPaymentError: ''
            };
        }
        case UPDATE_PAYMENT_DETAILS_FAIL: {
            const { data } = action.error.response;

            return {
                ...state,
                isUpdatingPaymentDetails: false,
                updatedPaymentDetailsSuccess: false,
                updatedPaymentDetailsFail: true,
                validationErrors: data.errors
            };
        }
        case RETRY_PAYMENT_REQUEST: {
            return {
                ...state,
                isRetryingPayment: true,
                retriedPaymentFail: false,
                retriedPaymentSuccess: false
            };
        }
        case RETRY_PAYMENT_SUCCESS: {
            return {
                ...state,
                isRetryingPayment: false,
                retriedPaymentSuccess: true,
                retriedPaymentFail: false
            };
        }
        case RETRY_PAYMENT_FAIL: {
            const { data } = action.error.response;

            return {
                ...state,
                isRetryingPayment: false,
                retriedPaymentSuccess: false,
                retriedPaymentFail: true,
                retryPaymentError: data.message
            };
        }
        case RESET_PAYMENT_STATE: {
            return {
                ...state,
                isUpdatingPaymentDetails: false,
                updatedPaymentDetailsSuccess: false,
                validationErrors: {}
            };
        }
    }

    return state;
}
