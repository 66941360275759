import React, { memo, useImperativeHandle, useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FormTextMultiline, FormTextRegular } from '../../../../components/form/FormText';

import '../../../../sass/inclusions.scss';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getLeaseType, getStep, getExclusions } from '../../../../selectors/lease';
import * as Lease from '../../../../reducers/lease';
import axios from 'axios';
import { has } from 'lodash';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { YES_LABEL, NO_LABEL } from '../../../../config';
const EXCLUSIONS_FORM = 'exclusionsForm';

const Exclusions = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const leaseType = useSelector(getLeaseType);
    const reduxExclusions = useSelector(getExclusions);

    let [formState, setFormState] = useState({
        exclusions: reduxExclusions.exclusions,
        meetsMinStandards: reduxExclusions.meetsMinStandards
    });

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(EXCLUSIONS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateExclusions = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/exclusions`, data);
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateExclusions(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_EXCLUSIONS_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.exclusions')) {
                    return error.response.data.errors.exclusions;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="exclusions">
            <Form onSubmit={submitForm} initialValues={formState}>
                {({ handleSubmit, form }) => {
                    return (
                        <form id={EXCLUSIONS_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <p className="help-text big with-margin">Exclusion of any part of property</p>
                            <br />
                            <p>
                                <i>(Detail the parts of the property and premises not included in this agreement)</i>
                            </p>
                            <FormTextMultiline name="exclusions" label="" />
                            <FormRadioGroup
                                required
                                label="Do the Premises meet Minimum Housing Standards"
                                name="meetsMinStandards"
                                className="full-width"
                                data={[
                                    { label: YES_LABEL, value: YES_LABEL, id: YES_LABEL },
                                    { label: NO_LABEL, value: NO_LABEL, id: NO_LABEL }
                                ]}
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Exclusions);
