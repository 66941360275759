import React, { Component } from 'react';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { formatDateDay, getAgencyTimezoneFromUser } from '../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { getSalesStepNo } from '../../../../../utils/agreementUtils';

const AgreementSalesTerm = ({ leaseAllInfo }) => {
    const loggedInUser = useSelector(getUserInfo);
    return (
        <div>
            {leaseAllInfo.term && (
                <AgreementSection
                    title="Term of agreement"
                    step={getSalesStepNo(leaseAllInfo.location, 'salesTerm')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <div>
                            {leaseAllInfo.term.startDate && (
                                <p className="name">
                                    Start date:{' '}
                                    <strong>
                                        {formatDateDay(
                                            leaseAllInfo.term.startDate,
                                            getAgencyTimezoneFromUser(loggedInUser)
                                        )}
                                    </strong>
                                </p>
                            )}
                        </div>
                        <div>
                            {leaseAllInfo.term.endDate && (
                                <p className="name">
                                    End date:{' '}
                                    <strong>
                                        {formatDateDay(
                                            leaseAllInfo.term.endDate,
                                            getAgencyTimezoneFromUser(loggedInUser)
                                        )}
                                    </strong>
                                </p>
                            )}
                        </div>
                        <div>{leaseAllInfo.term.isSignboard && <p className="text">Signboard included.</p>}</div>
                        <div>
                            {leaseAllInfo.term.termFormatted && (
                                <p className="name">
                                    Term: <strong>{leaseAllInfo.term.termFormatted}</strong>
                                </p>
                            )}
                        </div>
                    </div>
                </AgreementSection>
            )}
        </div>
    );
};

export default AgreementSalesTerm;
