import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import { DOCUMENT_CREATE_A_FLK } from '../config';
import '../sass/dashboardContentTable.scss';
import '../sass/global.scss';

const ContactListViewer = props => {
    const dispatch = useDispatch();
    const [contactList, setContactList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`api/contacts/get-contact-list/${props.match.params.contactListId}`)
            .then(response => {
                setContactList(response.data.contactList);
                setIsLoading(false);
            })
            .catch(err => {
                setError("Can't get data for this contact list. Please retry again");
                setIsLoading(false);
                return false;
            });
    }, []);

    return (
        <div>
            {isLoading && <div className="blue-spinner" />}
            {contactList && (
                <div className="team-block full-width">
                    <div className="name">
                        <p>{contactList.contactListName}</p>
                    </div>
                    <div className="table-sort table">
                        <Table
                            sortable={[
                                {
                                    column: 'fullName',
                                    sortFunction: function(a, b) {
                                        return a.props.children > b.props.children ? 1 : -1;
                                    }
                                },
                                {
                                    column: 'email',
                                    sortFunction: function(a, b) {
                                        return a.props.children > b.props.children ? 1 : -1;
                                    }
                                },
                                {
                                    column: 'address',
                                    sortFunction: function(a, b) {
                                        return a.props.children > b.props.children ? 1 : -1;
                                    }
                                }
                            ]}
                            defaultSort={{ column: 'fullName', direction: 'asc' }}
                        >
                            <Thead>
                                <Th column="fullName">
                                    <b>Full name</b>
                                </Th>
                                <Th column="mobile">
                                    <b>Mobile</b>
                                </Th>
                                <Th column="email">
                                    <b>Email</b>
                                </Th>
                                {contactList.contactType === DOCUMENT_CREATE_A_FLK && (
                                    <Th column="address">
                                        <b>Address</b>
                                    </Th>
                                )}
                            </Thead>
                            {contactList.contacts.map((contact, index) => (
                                <Tr key={index}>
                                    <Td column="fullName">
                                        <b>{contact.fullName}</b>
                                    </Td>
                                    <Td column="mobile">
                                        <b>{contact.mobile}</b>
                                    </Td>
                                    <Td column="email">
                                        <b>{contact.email}</b>
                                    </Td>
                                    {contactList.contactType === DOCUMENT_CREATE_A_FLK && (
                                        <Td column="address">
                                            <b>{contact.address}</b>
                                        </Td>
                                    )}
                                </Tr>
                            ))}
                        </Table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactListViewer;
