import React, { useState, memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import '../../../sass/account.scss';
import '../../../sass/settings.scss';
import { getAllBuildADocuments, getAllUploadADocuments } from '@app/actions/statistics';
import { PreLoader } from '@app/common/components/PreLoader';
import {
    getAllBuildADocumentsData,
    getAllUploadADocumentsData,
    getIsLoadingAllBuildADocuments,
    getIsLoadingAllUploadADocuments
} from '@app/selectors/statistics';
import AllDocumentsTable from '@app/containers/settings/agencyReports/components/AllDocumentsTable';

/**
 * Ideally if this project is built up on the following should be done:
 * TODO: Actions below
 * * Converted to Typescript
 * * Use TanStack
 * * Reduce usage of redux
 * @returns {JSX.Element}
 * @constructor
 */
const AllDocuments = () => {
    const dispatch = useDispatch();
    const allUploadADocuments = useSelector(getAllUploadADocumentsData);
    const allBuildADocuments = useSelector(getAllBuildADocumentsData);
    const isLoadingAllUploadADocuments = useSelector(getIsLoadingAllUploadADocuments);
    const isLoadingAllBuildADocuments = useSelector(getIsLoadingAllBuildADocuments);

    useEffect(() => {
        dispatch(getAllUploadADocuments());
        dispatch(getAllBuildADocuments());
    }, [dispatch]);

    return (
        <div className="settings">
            <div className="agent-reports-page">
                <h1>Document activity report</h1>
                <h2>Upload a doc</h2>
                {isLoadingAllUploadADocuments && <PreLoader />}
                {isLoadingAllUploadADocuments === false && allUploadADocuments && allUploadADocuments.length > 0 && (
                    <>
                        <AllDocumentsTable documents={allUploadADocuments} reportType={'uploadADoc'} />
                    </>
                )}
                <h2>Build a doc</h2>
                {isLoadingAllBuildADocuments && <PreLoader />}
                {isLoadingAllBuildADocuments === false && allBuildADocuments && allBuildADocuments.length > 0 && (
                    <>
                        <AllDocumentsTable documents={allBuildADocuments} reportType={'buildADoc'} />
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(AllDocuments);
