import React, { memo, useImperativeHandle, useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { FormTextRegular } from '../../../../components/form/FormText';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getLeaseType, getStep, getInstructions } from '../../../../selectors/lease';
import * as Lease from '../../../../reducers/lease';
import axios from 'axios';
import { has } from 'lodash';

const INSTRUCTIONS_FORM = 'instructionsForm';

const Instructions = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const leaseType = useSelector(getLeaseType);
    const reduxInstructions = useSelector(getInstructions);

    let [formState, setFormState] = useState(reduxInstructions);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(INSTRUCTIONS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateInsurance = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/instructions`, data);
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateInsurance(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_INSTRUCTIONS_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.instructions')) {
                    return error.response.data.errors.instructions;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="outgoings">
            <Form
                onSubmit={submitForm}
                initialValues={formState}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form id={INSTRUCTIONS_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <p className="gray-label">
                                Manufacturers’ manuals or written instructions for domestic facilities that require
                                instructions for use, will be provided to the tenant before or upon occupation of the
                                premises as either an Annexure to this Agreement or in the table below.
                            </p>
                            <br />
                            <p className="gray-label">
                                This information will be reasonably given where available and the tenant acknowledges
                                that instructions may be provided by the Agent separately to this Agreement.
                            </p>
                            <br />
                            <FieldArray name="instructionsList" initialValue={formState.instructionsList}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <div key={index} className="item-condition">
                                                    <div className="clauses">
                                                        <div className="clauses-header">
                                                            <h3>Manual/Instruction {index + 1}</h3>
                                                            <div
                                                                className="toggle"
                                                                onClick={() => fields.remove(index)}
                                                            >
                                                                <span className="span-remove">Remove</span>
                                                            </div>
                                                        </div>
                                                        <div className="formBox-column">
                                                            <FormTextRegular
                                                                name={`instructionsList[${index}].facility`}
                                                                label="Facility "
                                                                required
                                                            />
                                                            <FormTextRegular
                                                                name={`instructionsList[${index}].name`}
                                                                label="Model / Name"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <br />
                            <div className="button">
                                <button
                                    className="mobile add-item"
                                    type="button"
                                    onClick={() => {
                                        push('instructionsList', {
                                            facility: '',
                                            name: ''
                                        });
                                    }}
                                >
                                    Add Manual / Instruction
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Instructions);
