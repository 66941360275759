import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HARNESS_IO_ANON_USER } from '../../constants/constants';
import { omit } from 'lodash';
import { extractUserAndAgencyInfo } from '../../utils/vendorUtils';
import { getUserInfo as userInfoSelector } from '../../selectors/user';
import { getAgency } from '../../selectors/settings/account';
import { FFContextProvider } from '@harnessio/ff-react-client-sdk';
import { HARNESS_TARGET_DATA } from '@app/constants/constants';

// The extra methods are not implemented but harness does not use the logger
// if they are not present in this object
const logger = {
    debug: (...data) => {},
    info: (...data) => {},
    warn: (...data) => {},
    error: (...data) => {
        window.rollbar.error('Error occurred in Harness', {
            message: data[0],
            error: data[1]?.message
        });
    }
};

/**
 * ONLY EXPORTED TO TEST THE REGEX
 * Harness has a constraint that limits the types of characters
 * that can be entered in the name field, Some names can cause harness to break if these chars are present
 * Docs: https://developer.harness.io/docs/feature-flags/use-ff/ff-sdks/client-sdks/react-client#add-a-target
 */

export const sanitiseUserName = name => {
    return typeof name === 'string' ? name.replaceAll(/[^\p{L}\d .@_-]/gu, '') : undefined;
};

export const getHarnessTargetData = (userInfo, agency) => {
    const user = extractUserAndAgencyInfo(userInfo, agency);
    // set harness.io target, omit phone as that should never be needed
    const customUserAttributes = omit(user, ['key', 'name', 'email', 'country', 'phone']);
    return {
        name: sanitiseUserName(user.name),
        identifier: user.key,
        attributes: customUserAttributes
    };
};

export function HarnessProvider({ children }) {
    const targetUserId = React.useRef();
    const targetAgencyId = React.useRef();

    const userInfo = useSelector(userInfoSelector);
    const agency = useSelector(getAgency);

    // Iniitialise harnessTargetData from sessionStorage
    const cachedHarnessTargetData = window.sessionStorage.getItem(HARNESS_TARGET_DATA);
    const harnessTargetData = cachedHarnessTargetData ? JSON.parse(cachedHarnessTargetData) : undefined;
    const [harnessIOTarget, setHarnessIOTarget] = useState(harnessTargetData || { identifier: HARNESS_IO_ANON_USER });

    useEffect(() => {
        // Only update harness target if user or agency has changed
        if (
            userInfo?.id &&
            agency?.id &&
            (userInfo.id !== targetUserId.current || agency.id !== targetAgencyId.current)
        ) {
            targetUserId.current = userInfo.id;
            targetAgencyId.current = agency.id;
            setHarnessIOTarget(getHarnessTargetData(userInfo, agency));
        }
    }, [userInfo, agency, harnessIOTarget]);

    return (
        <FFContextProvider apiKey={HARNESS_IO_API_KEY} target={harnessIOTarget} options={{ logger }} async>
            {children}
        </FFContextProvider>
    );
}
