const _ = require('lodash');
// Hubspot will watch this array and pop entries from it when it sends those
// updates to hubspot
const hsq = (window._hsq = window._hsq || []);

function trackPageView() {
    hsq.push(['trackPageView']);
}

// There are some useEffect hooks that call this function multiple times
// becuase track page view sends a request everytime we want rate limit these requests
const debouncedTrackPageView = _.debounce(trackPageView, 2000);

export default function pushDetailsToHubspot(email) {
    if (hsq.length < 10) {
        const detailsToSend = { email };

        // If hubspot isn't loaded is won't pop the values from the array. In this case we just want to stop pushing items onto the array.
        hsq.push(['identify', detailsToSend]);
        debouncedTrackPageView();
    }
}
