/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from 'react-modal';
import SignatureCanvas from 'react-signature-canvas';

import Label from './Label';

import '../../../sass/agentSignatures/sign.scss';

class Sign extends Component {
    constructor() {
        super();

        this.state = {
            isSignature: false
        };
    }

    eraseSignature = () => {
        const signature = this.refs.mySignature;

        this.setState({
            isSignature: false
        });

        signature.clear();
    };

    confirmButtonHandler = () => {
        const signature = this.refs.mySignature;
        const signImage = signature.getTrimmedCanvas().toDataURL();
        if (!signature.isEmpty()) {
            this.props.confirmButtonHandler({ signature: signImage });
        }
    };

    drawStart = () => {
        this.setState({
            isSignature: this.refs.mySignature && !this.refs.mySignature.isEmpty()
        });
    };

    closeModal = () => {
        const { closeModal } = this.props;
        closeModal();
        this.setState({
            isSignature: false
        });
    };

    render() {
        const { isOpen, title, subtitle, agentSignaturePreview, agentName } = this.props;
        const closeHandle = this.closeModal;

        return (
            <Modal
                parentSelector={() => document.getElementById('main-layout')}
                ariaHideApp={false}
                isOpen={isOpen}
                style={customStyle}
                className="sign-modal"
                contentLabel="Modal"
                onRequestClose={closeHandle}
            >
                <div
                    className={cx('sign', {
                        open: isOpen,
                        'full-height': agentSignaturePreview
                    })}
                >
                    {!!agentSignaturePreview && (
                        <div className="tenant-signature-preview">
                            <Label>You're witnessing {agentName}'s signature</Label>
                            <img src={agentSignaturePreview} alt="" />
                        </div>
                    )}
                    <div className="sign-header">
                        <button className="button-cancel" onClick={closeHandle}>
                            Cancel
                        </button>
                        <div>
                            <h3>{title}</h3>
                            {!!subtitle && <span>{subtitle}</span>}
                        </div>
                        <button
                            className={cx('button-done', { disabled: !this.state.isSignature })}
                            onClick={this.confirmButtonHandler}
                        >
                            Done
                        </button>
                    </div>
                    <div className="signature-pad-block">
                        <SignatureCanvas
                            onEnd={this.drawStart}
                            ref="mySignature"
                            penColor="#000000"
                            minWidth={1.5}
                            canvasProps={{
                                width: window.innerWidth,
                                height: window.innerHeight * 0.54,
                                className: 'signCanvas'
                            }}
                        />
                    </div>
                    <div className="sign-footer">
                        <div />
                        {this.state.isSignature && <button className="button-erase" onClick={this.eraseSignature} />}
                    </div>
                </div>
            </Modal>
        );
    }
}

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 95
    }
};

Sign.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    closeModal: PropTypes.func,
    isPdf: PropTypes.bool,
    agentName: PropTypes.string,
    agentSignature: PropTypes.func
};

export default Sign;
