import { createSelector } from 'reselect';

export const getSpecialConditions = state => state.specialConditions;

export const getSpecialConditionsTemplateList = createSelector(
    [getSpecialConditions],
    specialConditions => specialConditions.specialConditionsList
);

const getLeaseType = (_, leaseType) => leaseType;

export const getSpecialConditionsTemplateListForLeaseType = createSelector(
    getSpecialConditions,
    getLeaseType,
    (specialConditions, leaseType) => {
        return specialConditions.specialConditionsList
            ? specialConditions.specialConditionsList.filter(item => {
                  return (
                      !item.agreementType ||
                      leaseType
                          .replace('_', ' ')
                          .toLowerCase()
                          .includes(item.agreementType.replace('_', ' ').toLowerCase())
                  );
              })
            : [];
    }
);

export const getValidationErrors = createSelector(
    [getSpecialConditions],
    specialConditions => specialConditions.validationErrors
);

export const getIsOpenModalSpecialCondition = createSelector(
    [getSpecialConditions],
    specialConditions => specialConditions.isOpenModalSpecialCondition
);

export const getModalSpecialCondition = createSelector(
    [getSpecialConditions],
    specialConditions => specialConditions.modalSpecialCondition
);

export const getSpecialConditionsTemplatesToUpdate = createSelector(
    [getSpecialConditions],
    specialConditions => specialConditions.specialConditionsTemplatesToUpdate
);
