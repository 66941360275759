import React, { memo } from 'react';
import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../../config';
import { FormRadioGroup } from '../../../../form/FormRadioGroup';

const GardeningClause = () => {
    return (
        <React.Fragment>
            <FormRadioGroup
                label="Cross out gardening clause on the lease?"
                name={'gardeningClause'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'gardening-clause-yes',
                        id: 'gardening-clause-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'gardening-clause-no',
                        id: 'gardening-clause-no',
                        value: NO_VALUE
                    }
                ]}
            />
        </React.Fragment>
    );
};

export default memo(GardeningClause);
