import Icon, { Icons } from '@app/common/components/Icon';
import Note, { NoteType } from '@app/common/components/Note';
import { ToastTypes } from '@app/common/components/Toast';
import ToggleButtonV2 from '@app/common/components/ToggleButtonV2';
import useToast from '@app/hooks/useToast';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import Button from '../../../../common/components/Button.js';
import { getErrorMessageForToast } from '@app/utils/errorUtils';

import styles from './QrCodeSharing.module.scss';

type QrCodeSharingProps = {
    enableQrCode: boolean;
    templateId: string;
};

const QrCodeSharing: React.FC<QrCodeSharingProps> = props => {
    const { enableQrCode, templateId } = props;
    const { addNewToast } = useToast();

    const MOCK_QR_LINK = `${LETS_URL}/#/self-serve/${templateId}`;

    const [qrCodePng, setQrCodePng] = useState<string>();
    const [hasQrCodeError, setHasQrCodeError] = useState(false);

    useEffect(() => {
        const generateQrCodePng = async () => {
            try {
                const qrCode = await QRCode.toDataURL(MOCK_QR_LINK, { width: 512 });

                setQrCodePng(qrCode);
            } catch (error) {
                setHasQrCodeError(true);
            }
        };

        generateQrCodePng();
    }, [MOCK_QR_LINK]);

    const copyQRCode = async (qrCodePng: string) => {
        const imageBlob = await fetch(qrCodePng).then(response => response.blob());

        const data = [new ClipboardItem({ 'image/png': imageBlob })];

        try {
            await navigator.clipboard.write(data);
            addNewToast('QR code copied successfully!', ToastTypes.SUCCESS);
        } catch (error) {
            addNewToast(getErrorMessageForToast('Failed to copy QR code'), ToastTypes.ERROR);
        }
    };

    const downloadQRCode = (qrCodePng: string) => {
        const link = document.createElement('a');
        link.href = qrCodePng;
        link.download = 'qrcode.png';
        link.click();

        addNewToast('QR code downloaded successfully!', ToastTypes.SUCCESS);
    };

    // Copy QR code to clipboard
    const copySigningLink = async () => {
        try {
            await navigator.clipboard.writeText(MOCK_QR_LINK);
            addNewToast('Signing link copied successfully!', ToastTypes.SUCCESS);
        } catch (error) {
            addNewToast(getErrorMessageForToast('Failed to copy signing link'), ToastTypes.ERROR);
        }
    };

    return (
        <div className={styles.shareQrCode}>
            <div className={styles.header}>
                <h3 className={styles.title}>Share QR Code/Link</h3>
                <ToggleButtonV2
                    name="enableQrCode"
                    label="Active"
                    switchClassName={styles.activeToggleButton}
                    labelClassName={styles.activeToggleLabel}
                    className={styles.activeToggle}
                    dataId="enableQrCode"
                />
            </div>
            {hasQrCodeError && (
                <Note title="Unable to generate QR Code/Link" type={NoteType.ERROR}>
                    We are unable to generate the QR Code/Link due to a technical issue. Please try again. If the
                    problem persists, you can contact our Customer Support team via livechat or email.
                </Note>
            )}
            {!hasQrCodeError && enableQrCode && qrCodePng && (
                <>
                    <Note
                        title="Subscription Usage Reminder:"
                        type={NoteType.WARNING2}
                        className={styles.usageReminder}
                    >
                        <p>
                            Each time a link or QR code is used it will count as one document from your upload a doc
                            subscription.
                        </p>
                        <p>Share responsibly to avoid unauthorised use.</p>
                    </Note>
                    <div className={styles.qrContainer}>
                        <img className={styles.qrCode} src={qrCodePng} data-test="qrCodeImg" />
                        <div className={styles.qrActions}>
                            <p className={styles.title}>
                                Get responses by sharing your document using the options below:
                            </p>

                            <div className={styles.buttons}>
                                <Button
                                    type="button"
                                    className={styles.button}
                                    onClick={() => copyQRCode(qrCodePng)}
                                    startIcon={<Icon icon={Icons.COPY} />}
                                >
                                    Copy Image
                                </Button>
                                <Button
                                    type="button"
                                    className={styles.button}
                                    onClick={() => downloadQRCode(qrCodePng)}
                                    startIcon={<Icon icon={Icons.DOWNLOAD} />}
                                >
                                    Download Image
                                </Button>
                                <Button
                                    type="button"
                                    className={styles.button}
                                    onClick={copySigningLink}
                                    startIcon={<Icon icon={Icons.LINK} />}
                                >
                                    Copy Signing Link
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!enableQrCode && (
                <Note title="Sharing is disabled" type={NoteType.WARNING}>
                    Recipients will not be able to access the document. To enable access, click on the Active toggle
                    above.
                </Note>
            )}
        </div>
    );
};

export default QrCodeSharing;
