import { connect } from 'react-redux';
import RentIncreaseListModal from '../../components/modals/RentIncreaseListModal';
import { getAgreementInfo } from '../../selectors/dashboard/agreementInfo';
import { getUserInfo } from '../../selectors/user';
import { getIsRentIncreaseListOpen } from '../../selectors/rentIncrease';

export default connect(state => ({
    isOpen: getIsRentIncreaseListOpen(state),
    agreementInfo: getAgreementInfo(state),
    loggedInUser: getUserInfo(state)
}))(RentIncreaseListModal);
