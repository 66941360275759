import * as React from 'react';
import { connect } from 'react-redux';

import { Route, Switch, Redirect } from 'react-router';
import * as account from '../actions/account';
import UpdateBanner from '../components/banners/UpdateBanner';
import SideBar from './settings/SideBar';
import Layout from './Layout';
import { isRouteAllowed } from '../utils/userPermissions';
import '../sass/settingsAccount.scss';
import Profile from './settings/Profile';
import Account from './settings/Account';
import AgencyLicenseDetailsTabs from './settings/AgencyLicenseDetailsTabs';
import Team from './settings/Team';
import Subscription from './settings/subscription/Subscription';
import Invoice from './settings/Invoice';
import EmergencyContacts from './settings/emergencyContacts/EmergencyContacts';
import Signatures from './settings/signatures/Signatures';
import SpecialConditions from './settings/SpecialConditions';
import Integrations from './settings/Integrations';
import DefaultSettingsPayments from './settings/payments/defaultSettingsPayments';
import ContactListTabs from './settings/contacts/ContactListTabs';
import AgencyDefaultDocumentsTabs from './settings/AgencyDefaultDocumentsTabs';
import Support from './settings/Support';
import Faq from './settings/Faq';
import Statistics from './settings/statistics/Statistics';
import Reports from './settings/reports/Reports';
import residentialTenancyFees from './settings/fees/residentialTenancyFees';
import propertyManagementFees from './settings/fees/propertyManagement';
import salesFees from './settings/fees/sales';
import TemplateTabs from './settings/templates/TemplateTabs';
import CreateFlkTemplateTabs from './settings/templates/CreateFlkTemplateTabs';
import UploadADocTemplate from './settings/templates/UploadADocTemplate.tsx';
import appHistory from './../AppHistory';
import AgencyReports from './settings/agencyReports/agentReports';
import AgentReport from './settings/agencyReports/agentReport';
import AllDocuments from './settings/agencyReports/allDocuments';
import DefaultSettings from './settings/agencyDetails/DefaultSettings';
import Security from './settings/Security.tsx';
import BulkImport from './settings/BulkImport';
import EmailStudio from './settings/EmailStudio';

class SettingsAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            props: this.props
        };
    }

    componentDidMount() {
        document.title = 'Agency Account';
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.user.userInfo &&
            (nextProps.user.userInfo.role !== prevState.props.user.userInfo.role ||
                nextProps.location.pathname !== prevState.props.location.pathname)
        ) {
            let isAllowed = isRouteAllowed(nextProps.user.userInfo.role, nextProps.location.pathname);

            // Redirect users to profile page is route is not allowed.
            if (!isAllowed) {
                appHistory.push('/dashboard/agreements/residential_tenancy/draft');
                return null;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!prevProps.user.userInfo && prevProps.user.userInfo !== this.props.user.userInfo) {
            prevProps.getAgencyInfo(this.props.user.agencyId);
        }
    }

    render() {
        let checkUserRole = this.props.user.userInfo.role;

        return (
            <Layout className="settings-account">
                {checkUserRole && (
                    <div className="settings-account-wrapper">
                        <UpdateBanner />
                        <div className="settings-account-container">
                            <Route path={`${this.props.match.url}`} component={SideBar} />
                            <Switch>
                                <Route exact path={`${this.props.match.url}/`}>
                                    <Redirect to={`${this.props.match.url}/profile`} />
                                </Route>
                                <Route path={`${this.props.match.url}/security`} component={Security} />
                                <Route path={`${this.props.match.url}/profile`} component={Profile} />
                                <Route path={`${this.props.match.url}/account`} component={Account} />
                                <Route path={`${this.props.match.url}/details`} component={AgencyLicenseDetailsTabs} />
                                <Route path={`${this.props.match.url}/defaults`} component={DefaultSettings} />
                                <Route path={`${this.props.match.url}/team`} component={Team} />
                                <Route path={`${this.props.match.url}/subscribe`} component={Subscription} />
                                <Route path={`${this.props.match.url}/invoice`} component={Invoice} />
                                <Route path={`${this.props.match.url}/contacts`} component={EmergencyContacts} />
                                <Route path={`${this.props.match.url}/signatures`} component={Signatures} />
                                <Route path={`${this.props.match.url}/conditions`} component={SpecialConditions} />
                                <Route path={`${this.props.match.url}/integrations`} component={Integrations} />
                                <Route path={`${this.props.match.url}/bulk-import`} component={BulkImport} />
                                <Route path={`${this.props.match.url}/email-studio`} component={EmailStudio} />
                                <Route path={`${this.props.match.url}/payments`} component={DefaultSettingsPayments} />
                                <Route path={`${this.props.match.url}/user-contacts`} component={ContactListTabs} />
                                <Route
                                    path={`${this.props.match.url}/document-links`}
                                    component={AgencyDefaultDocumentsTabs}
                                />
                                <Route path={`${this.props.match.url}/agreement-templates`} component={TemplateTabs} />
                                <Route
                                    path={`${this.props.match.url}/build-a-doc-templates`}
                                    component={CreateFlkTemplateTabs}
                                />
                                <Route
                                    path={`${this.props.match.url}/upload-a-doc-templates`}
                                    component={UploadADocTemplate}
                                />
                                <Route path={`${this.props.match.url}/support`} component={Support} />
                                <Route path={`${this.props.match.url}/faq`} component={Faq} />
                                <Route path={`${this.props.match.url}/statistics`} component={Statistics} />
                                <Route path={`${this.props.match.url}/reports`} component={Reports} />
                                <Route path={`${this.props.match.url}/agency/reports`} component={AgencyReports} />
                                <Route path={`${this.props.match.url}/agent/:id/report`} component={AgentReport} />
                                <Route path={`${this.props.match.url}/agent/report`} component={AgentReport} />
                                <Route path={`${this.props.match.url}/all-documents`} component={AllDocuments} />
                                <Route path={`${this.props.match.url}/fees`}>
                                    <Redirect to="residentialTenancyFees" />
                                </Route>
                                <Route
                                    path={`${this.props.match.url}/fees/residentialTenancyFees`}
                                    component={residentialTenancyFees}
                                />
                                <Route
                                    path={`${this.props.match.url}/fees/propertyManagement`}
                                    component={propertyManagementFees}
                                />
                                <Route path={`${this.props.match.url}/fees/sales`} component={salesFees} />
                            </Switch>
                        </div>
                    </div>
                )}
            </Layout>
        );
    }
}

export default connect(
    state => ({
        user: state.user
    }),
    {
        getAgencyInfo: account.getAgencyInfo
    }
)(SettingsAccount);
