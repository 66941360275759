import cx from 'classnames';
import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

import Button from '../../../../../common/components/Button.js';
import { getFormError } from '../../../../../utils/finalFormUtils';

import Icon, { Icons } from '../../../../../common/components/Icon';
import styles from './TextInput.module.scss';

type TextInputFieldProps = {
    name: string;
    label: string;
    labelClassName?: string;
    isRequired?: boolean;
    validateOnTouch?: boolean;
    placeholder?: string;
    showClearButton?: boolean;
    showDeleteButton?: boolean;
    onDelete?: () => void;
    disabled?: boolean;
    type?: string;
    className?: string;
};

type TextInputProps = FieldRenderProps<string, HTMLElement> & TextInputFieldProps;

const TextInput: React.FC<TextInputProps> = ({
    input,
    meta,
    className,
    label,
    labelClassName,
    endButton,
    isRequired,
    placeholder,
    disabled,
    type,
    onDelete,
    showDeleteButton = false,
    showClearButton = true,
    validateOnTouch = true
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const error = getFormError(meta, validateOnTouch);

    return (
        <div
            className={cx(styles.container, className, {
                [styles.withButton]: !!endButton,
                [styles.withError]: !!error,
                [styles.disabled]: disabled
            })}
            data-has-error={!!error}
        >
            <label className={cx(styles.label, labelClassName)} htmlFor={input.name}>
                {label + (isRequired ? '*' : '')}
            </label>
            <div className={styles.inputContainer}>
                <input
                    disabled={disabled}
                    required={isRequired}
                    ref={inputRef}
                    className={styles.input}
                    {...input}
                    placeholder={placeholder}
                    type={type}
                />
                {!!input.value && showClearButton && (
                    <Button
                        onClick={() => {
                            input.onChange('');
                            inputRef.current?.focus();
                        }}
                        type="button"
                        className={cx(styles.endButton, styles.clearButton)}
                        disabled={disabled}
                    >
                        <Icon className={styles.clearIcon} icon={Icons.CLOSE} />
                    </Button>
                )}
                {showDeleteButton && onDelete && (
                    <Button
                        onClick={onDelete}
                        type="button"
                        className={cx(styles.endButton, styles.deleteButton)}
                        disabled={disabled}
                    >
                        <Icon className={styles.deleteIcon} icon={Icons.TRASH} />
                    </Button>
                )}
            </div>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

const TextInputField: React.FC<TextInputFieldProps> = ({ ...props }) => {
    return <Field component={TextInput} {...props} />;
};

export default TextInputField;
