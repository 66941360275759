import { createSelector } from 'reselect';
export const getContacts = state => state.contacts;

export const getEmergencyContacts = createSelector([getContacts], contacts => contacts.contactList);

export const getValidationErrors = createSelector([getContacts], contacts => contacts.validationErrors);

export const getIsOpenModalEmergencyContacts = createSelector([getContacts], contacts => contacts.isOpenModal);

export const getModalEmergencyContactData = createSelector(
    [getContacts],
    contacts => contacts.modalEmergencyContactData
);

export const getContactsTemplatesToUpdate = createSelector(
    [getContacts],
    contacts => contacts.emergencyContactsTemplatesToUpdate
);
