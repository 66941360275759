import React, { useState } from 'react';
import styles from './AuthenticationAppSetupCard.module.scss';
import cx from 'classnames';
import Icon, { Icons } from '../../common/components/Icon';
import Card, { CardStyles } from '../../common/components/cards/Card';
import { Form } from 'react-final-form';
import Button from '../../common/components/Button';
import { FormTextRegular } from '../form/FormText';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import QRCode from 'react-qr-code';

interface AuthenticationAppSetupCardProps {
    title: string;
    headerIcon?: Icons;
    submit: (method: string, code: string) => void;
    goBack: () => void;
    loading: boolean;
    method: string;
}
interface StepOneProps {
    goBack: () => void;
    setTotpData: (data: any) => void;
    setStep: (step: number) => void;
}

interface StepTwoProps {
    totpData: { uri: string; secret: string } | null;
    setStep: (step: number) => void;
}

interface StepThreeProps {
    values: any;
    setStep: (step: number) => void;
    loading: boolean;
}

function StepOne({ goBack, setTotpData, setStep }: StepOneProps) {
    const createTotpFactor = useMutation(() => {
        return axios.post(`/api/user/create-totp-factor`);
    });

    const moveToSecondStep = async () => {
        const result = await createTotpFactor.mutateAsync();
        setTotpData(result.data.data);
        setStep(2);
    };

    return (
        <>
            <div className={styles.body}>
                <p className={styles.description}>Download App</p>
                <p className={styles.label}>
                    A third party app will display a code for you when you log in, in order to achieve 2-step
                    verification.
                </p>
                <p className={styles.label}>Download Google Authenticator</p>
                <div className={styles.buttonGroup}>
                    <Button
                        type="button"
                        onClick={() =>
                            window.open('https://apps.apple.com/us/app/google-authenticator/id388497605', '_blank')
                        }
                        secondary
                        className={cx(styles.button, styles.appleLogo)}
                    >
                        <Icon icon={Icons.APPLE} /> App Store
                    </Button>
                    <Button
                        type="button"
                        onClick={() =>
                            window.open(
                                'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US',
                                '_blank'
                            )
                        }
                        secondary
                        className={cx(styles.button, styles.googleLogo)}
                    >
                        <Icon icon={Icons.GOOGLE_PLAY} /> Google Play
                    </Button>
                </div>
            </div>
            <div className={styles.footer}>
                <Button type="button" className={styles.button} onClick={goBack}>
                    Back
                </Button>
                <Button
                    loading={createTotpFactor.isLoading}
                    type="button"
                    primary
                    className={styles.nextButton}
                    onClick={moveToSecondStep}
                >
                    I’ve downloaded it, next
                </Button>
            </div>
        </>
    );
}

function StepTwo({ totpData, setStep }: StepTwoProps) {
    return (
        <>
            <div className={styles.body}>
                <div className={styles.qrBlock}>
                    <div className={styles.text}>
                        <p className={styles.description}>Scan this QR code</p>
                        <p className={styles.label}>Scan this QR code with the Google Authenticator app.</p>
                    </div>
                    {totpData?.uri && <QRCode value={totpData.uri} size={100} className={styles.qrCode} />}
                </div>
                <p className={styles.label}>Unable to scan? Enter the code below into the app instead.</p>
                <div className={styles.code}>{totpData?.secret}</div>
            </div>
            <div className={styles.footer}>
                <Button type="button" className={styles.button} onClick={() => setStep(1)}>
                    Back
                </Button>
                <Button type="button" primary className={styles.nextButton} onClick={() => setStep(3)}>
                    Next step
                </Button>
            </div>
        </>
    );
}

function StepThree({ values, setStep, loading }: StepThreeProps) {
    return (
        <>
            <div className={styles.body}>
                <p className={styles.description}>Lastly, enable third party authentication app</p>
                <FormTextRegular name="code" label="Verification code" />
                <p className={styles.inputLabel}>Enter the code sent to your authentication app</p>
            </div>
            <div className={styles.footer}>
                <Button type="button" className={styles.button} onClick={() => setStep(2)}>
                    Back
                </Button>
                <Button type="submit" primary loading={loading} className={styles.nextButton} disabled={!values.code}>
                    Done
                </Button>
            </div>
        </>
    );
}

export default function AuthenticationAppSetupCard({
    title,
    headerIcon,
    submit,
    goBack,
    loading,
    method
}: AuthenticationAppSetupCardProps) {
    const [step, setStep] = useState(1);
    const [totpData, setTotpData] = useState<{ uri: string; secret: string } | null>(null);

    function renderCorrectStep(values: any) {
        switch (step) {
            case 1:
                return <StepOne goBack={goBack} setTotpData={setTotpData} setStep={setStep} />;
            case 2:
                return <StepTwo totpData={totpData} setStep={setStep} />;
            case 3:
                return <StepThree values={values} setStep={setStep} loading={loading} />;
            default:
                return null;
        }
    }

    return (
        <Form onSubmit={data => submit(method, data.code)}>
            {({ handleSubmit, values }) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card style={CardStyles.SQUARE} className={cx(styles.securitySetupCard)}>
                            <div className={styles.header}>
                                <div className={styles.titleGroup}>
                                    {headerIcon && <Icon className={styles.icon} icon={headerIcon} />}
                                    <p className={styles.title}>{title}</p>
                                </div>
                                <p className={styles.step}>Step {step} of 3</p>
                            </div>
                            {renderCorrectStep(values)}
                        </Card>
                    </form>
                );
            }}
        </Form>
    );
}
