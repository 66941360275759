import React, { Component } from 'react';
import { isEmpty, map } from 'lodash';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getPmStepNo } from '../../../../../../utils/agreementUtils';

class AgreementPmRebates extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { leaseAllInfo } = this.props;
        const { disclosureOfRebates } = leaseAllInfo;

        if (!disclosureOfRebates) {
            return null;
        }

        return (
            <AgreementSection
                title="Disclosure of Rebates"
                step={getPmStepNo(leaseAllInfo.location, 'disclosureOfRebates')}
                leaseAllInfo={leaseAllInfo}
            >
                <div>
                    <div className="agreement-section-text">
                        <h5>Rebates</h5>
                        <React.Fragment>
                            <p>
                                The Agent will be, or is likely to be, entitled to any rebates. A rebate includes any
                                discount, commission, or the other benefit, and includes non-monetary benefits.
                            </p>
                            <br />
                        </React.Fragment>
                        {isEmpty(disclosureOfRebates.rebateList) ? (
                            <React.Fragment>
                                <p>No rebates exist for this agreement.</p>
                            </React.Fragment>
                        ) : (
                            <div>
                                <ul>
                                    {map(disclosureOfRebates.rebateList, (value, index) => {
                                        return (
                                            <div className="agreement-section-text__item" key={index}>
                                                Source: <strong>{value.source}</strong> Frequency:{' '}
                                                <strong>{value.frequency}</strong> Amount:{' '}
                                                <strong>{value.amount}</strong>
                                            </div>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                    <br />
                    <div className="agreement-section-text">
                        <h5>Commissions</h5>
                        <p>
                            The Agent's Commission and/or Letting Fee will be shared with other people (other than a
                            licensed estate agent or an agent's representative employed by the Agent, or a licensed
                            agent who is in partnership with the Agent).
                        </p>
                        <br />
                        {isEmpty(disclosureOfRebates.commissionList) ? (
                            <React.Fragment>
                                <p>No commissions exist for this agreement.</p>
                            </React.Fragment>
                        ) : (
                            <div>
                                <ul>
                                    {map(disclosureOfRebates.commissionList, (value, index) => {
                                        return (
                                            <li className="agreement-section-text__item" key={index}>
                                                <p>
                                                    <strong>{value.sharingPersonName}</strong>,&nbsp;
                                                    {value.address}
                                                </p>
                                                {value.description}
                                                <p>
                                                    Amount&nbsp;{value.amount}&nbsp;<strong>{value.percentage}%</strong>
                                                </p>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </AgreementSection>
        );
    }
}

export default AgreementPmRebates;
