import React from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import checkMarkGreen from '../../../assets/images/icons/icon_checkmark_circle_green.svg';
import checkMarkGray from '../../../assets/images/icons/icon_checkmark_circle_gray.svg';
import '../../sass/agreement-signatures-new.scss';

const cx = classnames.bind({
    status: 'agreement-signatures__status'
});

export function StatusIcon({ success, className, ...divProps }) {
    const iconUrl = success ? checkMarkGreen : checkMarkGray;

    return (
        <div
            {...divProps}
            className={cx('status', className)}
            style={{
                backgroundImage: `url(${iconUrl}`
            }}
        />
    );
}

StatusIcon.propTypes = {
    className: PropTypes.string,
    success: PropTypes.bool
};

StatusIcon.defaultProps = {
    className: '',
    success: false
};
