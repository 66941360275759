import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { isEmpty, uniq, remove, cloneDeep } from 'lodash';
import { parseISO, addMonths, endOfDay, addDays } from 'date-fns';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { addOrReplaceDocumentInList, rentIncreaseCreateSuccess } from '../../../../actions/document';
import { createRentIncreaseSuccess } from '../../../../actions/lease';
import { openNoSignatureModal } from '../../../../actions/user';

import * as validators from '../../../../components/Validate';
import RentIncreaseTenantPersonGroup from '../../../../components/document/RentIncreaseTenantPersonGroup';
import RentIncreaseLandlordPersonGroup from '../../../../components/document/RentIncreaseLandlordPersonGroup';
import { FormTextCurrency, FormTextRegular } from '../../../../components/form/FormText';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import ModalDialog from '../../../../common/components/ModalDialog';
import Warning from '../../../../components/dashboard/warnings/Warning';
import { SelectField } from '../../../../components/form/FormSelect';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import LocationAndAddressSelection from '../../LocationAndAddressSelection';
import DocumentHeader from '../../DocumentHeader';
import DeliveryTypeComponent from '../../shared/DeliveryTypeComponent';
import RentAmount from '../../../../components/form/RentAmount.tsx';

import '../../../../sass/documentModalDialog.scss';

import {
    rentPeriods as period,
    PERIOD_WEEKLY,
    HIDE_MESSAGE_TIME,
    NSW_STATE,
    QLD_STATE,
    FORM_SUBMIT_TYPE_SEND,
    CLOSE_MODAL_MESSAGE,
    DELIVERY_TO_AGENT_AND_TENANT,
    DELIVERY_TYPE_EMAIL,
    DELIVERY_TYPE_POST,
    DELIVERY_TYPE_OVER_16,
    DELIVERY_TYPE_LETTERBOX,
    DELIVERY_TYPE_IN_PERSON,
    formatPeriod
} from '../../../../config';

import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';
import { isDocumentEditMode, isDocumentReadOnlyMode } from '../../../../utils/generalUtils';

import { getUserInfo } from '../../../../selectors/user';
import { getAgentSignature } from '../../../../selectors/completion';
import { getSelectedAddressEdited } from '../../../../selectors/lease/addresses';
import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document';
import RentIncreaseFooter from './RentIncreaseFooter';
import { CloseSharp } from '@material-ui/icons';

const RENT_INCREASE_TYPE_PERIODIC = 'Periodic Agreement';
const RENT_INCREASE_TYPE_FIXED_TERM = 'Fixed term Agreement';

const RENT_INCREASE_API = 'api/document/rent-increase';

const allowedStates = [NSW_STATE, QLD_STATE];

const RentIncreaseForm = ({ isOpen, closeRentIncreaseModal, agreementInfo, rentIncreaseWithoutLease }) => {
    const dispatch = useDispatch();
    const formValuesRef = React.useRef(null);

    const selectedAddressEdited = useSelector(getSelectedAddressEdited);
    const loggedInUser = useSelector(getUserInfo);
    const agentSignature = useSelector(getAgentSignature);
    const currentDocument = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);

    const [currentDocumentId, setCurrentDocumentId] = useState(currentDocument ? currentDocument.id : null);
    const [isSubmitSuccessfull, setIsSubmitSuccessfull] = useState(false);
    const [isSubmitFail, setIsSubmitFail] = useState(false);
    const [isSendingRentIncrease, setIsSendingRentIncrease] = useState(false);
    const [integrationPropertyId, setIntegrationPropertyId] = useState(null);
    const [integration, setIntegration] = useState(null);
    const [isCreatingRentIncreaseNotice, setIsCreatingRentIncreaseNotice] = useState(false);

    const [formData, setFormData] = useState({});
    const [location, setLocation] = useState(null);
    const [locationAndAddressSelected, setLocationAndAddressSelected] = useState(false);

    const [addressError, setAddressError] = useState('');
    const [tenantError, setTenantError] = useState('');

    const [isDirty, setIsDirty] = useState(false);
    const [isSaveDraft, setIsSaveDraft] = useState(false);

    const [addEmailFieldError, setAddEmailFieldError] = useState('');

    const handleFormDirtyChange = form => {
        if (form.getState().dirty && !isDirty) {
            setIsDirty(true);
        }
    };

    useEffect(() => {
        if (currentDocument && isOpen) {
            window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        if (currentDocument) {
            if (currentDocument.integration && currentDocument.integration.id) {
                setIntegrationPropertyId(currentDocument.integration.id);
                setIntegration(currentDocument.integration);
            } else {
                setIntegrationPropertyId(null);
                setIntegration(null);
            }
            setFormData({
                ...currentDocument.rentIncrease,
                agentName: currentDocument.agent.fullName,
                tenants: rentIncreaseWithoutLease && currentDocument.tenant ? currentDocument.tenant.tenants : [],
                landlords: rentIncreaseWithoutLease && currentDocument.landlord ? currentDocument.landlord.persons : [],
                newRentDateStart:
                    currentDocument && currentDocument.rentIncrease
                        ? parseISO(currentDocument.rentIncrease.newRentDateStart)
                        : null,
                leaseStartDate:
                    currentDocument && currentDocument.rentIncrease
                        ? parseISO(currentDocument.rentIncrease.leaseStartDate)
                        : null,
                emailTo: DELIVERY_TO_AGENT_AND_TENANT,
                address: currentDocument.address,
                addressDetails: currentDocument.details,
                disabled:
                    (currentDocument.completion || currentDocument.pdf) && isDocumentReadOnlyMode(documentEditMode)
            });
            setLocation(currentDocument.location);
            setCurrentDocumentId(currentDocument.id);
            setLocationAndAddressSelected(true);
        } else {
            setLocation(
                rentIncreaseWithoutLease
                    ? loggedInUser && loggedInUser.agency
                        ? loggedInUser.agency.details.location
                        : null
                    : agreementInfo.location
            );
            setLocationAndAddressSelected(!rentIncreaseWithoutLease);
            clearFormData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, loggedInUser, currentDocument]);

    useEffect(() => {
        if (selectedAddressEdited) {
            setFormData({
                ...formData,
                address: selectedAddressEdited
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddressEdited]);

    const clearFormData = () => {
        setFormData({
            agentName: loggedInUser.fullName,
            agentId: loggedInUser.id,
            agentPhone: loggedInUser.phone,
            tenants: rentIncreaseWithoutLease ? [{ firstName: '', middleName: '', secondName: '', email: '' }] : [],
            landlords: rentIncreaseWithoutLease ? [{ firstName: '', middleName: '', lastName: '', email: '' }] : [],
            tenantList: !rentIncreaseWithoutLease && agreementInfo.tenant ? agreementInfo.tenant.tenantsListText : '',
            deliveryType: DELIVERY_TYPE_EMAIL,
            emailTo: DELIVERY_TO_AGENT_AND_TENANT,
            emailAddresses:
                !rentIncreaseWithoutLease && agreementInfo.tenant && agreementInfo.tenant.isServicesOfNoticesEmail
                    ? agreementInfo.tenant.servicesOfNoticesEmails
                    : [],
            ccEmailAddresses: [loggedInUser.email],
            address: rentIncreaseWithoutLease ? null : agreementInfo.address,
            newRentAmount: '',
            newRentAmountHidden: '',
            newRentPayablePeriod: PERIOD_WEEKLY,
            newRentDateStart: '',
            leaseStartDate: '',
            currentRent: null,
            currentRentPayablePeriod: PERIOD_WEEKLY,
            disabled: false
        });
        setAddressError('');
        setTenantError('');
        setCurrentDocumentId(null);
    };

    const closeModal = () => {
        setCurrentDocumentId(null);
        setIsDirty(false);
        closeRentIncreaseModal();
        clearFormData();
        setIsSubmitFail(false);
        setIsSubmitSuccessfull(false);
        if (currentDocument) {
            window.history.pushState(
                {},
                '',
                `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`
            );
        }
    };

    const handleCloseModal = () => {
        if (isDirty && rentIncreaseWithoutLease) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const values = formValuesRef.current;
                            saveDraft(values).then(response => {
                                setIsSendingRentIncrease(false);
                                dispatch(addOrReplaceDocumentInList(response.data.rentIncreaseDocument));
                            });
                            closeModal();
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            closeModal();
                        }
                    },
                    {
                        className: 'close close-modal',
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            closeModal();
        }
    };

    const confirmDetails = () => {
        const values = formValuesRef.current;
        setIsCreatingRentIncreaseNotice(true);
        saveDraft(values)
            .then(response => {
                setCurrentDocumentId(response.data.rentIncreaseDocument.id);
                setIsDirty(false);
                dispatch(addOrReplaceDocumentInList(response.data.rentIncreaseDocument));
                setLocationAndAddressSelected(true);
            })
            .finally(() => {
                setIsCreatingRentIncreaseNotice(false);
            });
    };

    const viewPdf = docId => {
        window.open(`/reader/document/rent-increase/${docId}`, '_blank');
    };

    const previewPdf = values => {
        if (!isEmpty(agentSignature)) {
            setIsSendingRentIncrease(true);
            setIsSubmitFail(false);
            setIsSubmitSuccessfull(false);
            saveDraft(values).then(response => {
                viewPdf(response.data.rentIncreaseDocument.id);
                setIsSendingRentIncrease(false);
                dispatch(addOrReplaceDocumentInList(response.data.rentIncreaseDocument));
                setCurrentDocumentId(response.data.rentIncreaseDocument.id);
                setIsDirty(false);
            });
        } else {
            dispatch(openNoSignatureModal());
        }
    };
    const handleSubmit = values => {
        setIsSendingRentIncrease(true);
        setIsSubmitFail(false);
        setIsSubmitSuccessfull(false);
        if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
            setIsSaveDraft(false);
            return sendDocument(values);
        } else {
            setIsSaveDraft(true);
            return saveDraft(values).then(response => {
                setIsSendingRentIncrease(false);
                setIsSubmitSuccessfull(true);
                dispatch(addOrReplaceDocumentInList(response.data.rentIncreaseDocument));
                setCurrentDocumentId(response.data.rentIncreaseDocument.id);
                setIsDirty(false);
            });
        }
    };
    const saveDraft = values => {
        const data = cloneDeep(values);
        data.location = location;
        data.integrationPropertyId = integrationPropertyId;
        if (data.deliveryType !== DELIVERY_TYPE_EMAIL || data.emailTo !== DELIVERY_TO_AGENT_AND_TENANT) {
            data.emailAddresses = [loggedInUser.email];
            data.ccEmailAddresses = [];
        }
        return axios.post(`${RENT_INCREASE_API}${currentDocumentId ? `/${currentDocumentId}/save` : ''}`, data);
    };

    const sendDocument = values => {
        const data = cloneDeep(values);
        if (data.deliveryType !== DELIVERY_TYPE_EMAIL || data.emailTo !== DELIVERY_TO_AGENT_AND_TENANT) {
            data.emailAddresses = [loggedInUser.email];
            data.ccEmailAddresses = [];
        }
        if (validateEmailList(data) && !isEmpty(agentSignature)) {
            setAddressError('');
            setTenantError('');
            if (rentIncreaseWithoutLease) {
                data.location = location;
                data.integrationPropertyId = integrationPropertyId;
                return axios
                    .post(`${RENT_INCREASE_API}${currentDocumentId ? `/${currentDocumentId}/send` : '/send'}`, data)
                    .then(() => {
                        setIsSendingRentIncrease(false);
                        setIsSubmitFail(false);
                        setIsSubmitSuccessfull(true);
                        dispatch(rentIncreaseCreateSuccess());
                        setIntegrationPropertyId(null);
                        setIntegration(null);
                        closeModal();
                    })
                    .catch(error => {
                        setIsSendingRentIncrease(false);
                        setIsSubmitSuccessfull(false);
                        setIsSubmitFail(true);
                        const response = error.response;
                        let rentIncreaseAgreementError = response.data.errors;
                        if (response.data.errors.rentIncrease) {
                            rentIncreaseAgreementError = {
                                ...rentIncreaseAgreementError,
                                ...response.data.errors.rentIncrease
                            };
                        }
                        if (response.data.errors.tenant) {
                            if (response.data.errors.tenant.tenants) {
                                rentIncreaseAgreementError.tenants = response.data.errors.tenant.tenants;
                            } else {
                                setTenantError(response.data.errors.tenant);
                            }
                        }
                        if (response.data.errors.landlord) {
                            rentIncreaseAgreementError.landlords = response.data.errors.landlord.persons;
                        }
                        if (response.data.errors.address) {
                            setAddressError(response.data.errors.address);
                        }
                        return rentIncreaseAgreementError;
                    });
            }
            return axios
                .post(`api/agency/lease/${agreementInfo.id}/rent-increase`, data)
                .then(response => {
                    setIsSendingRentIncrease(false);
                    setIsSubmitFail(false);
                    setIsSubmitSuccessfull(true);
                    dispatch(createRentIncreaseSuccess(response.data.lease));
                    // clear success message after 5 seconds
                    setTimeout(() => {
                        setIsSubmitSuccessfull(false);
                        closeModal();
                    }, HIDE_MESSAGE_TIME);
                })
                .catch(error => {
                    setIsSendingRentIncrease(false);
                    setIsSubmitSuccessfull(false);
                    setIsSubmitFail(true);
                    const response = error.response;
                    const rentRentIncreaseError = response.data.errors.rent.rentIncreaseList;
                    const errors = rentRentIncreaseError[rentRentIncreaseError.length - 1].rentIncrease;
                    if (errors.emailAddresses) {
                        errors.emailAddressField = errors.emailAddresses;
                    }
                    return errors;
                });
        } else if (!validateEmailList(data)) {
            setIsSendingRentIncrease(false);
            setIsSubmitSuccessfull(false);
            setIsSubmitFail(true);
        } else {
            setIsSendingRentIncrease(false);
            setIsSubmitSuccessfull(false);
            setIsSubmitFail(true);
            dispatch(openNoSignatureModal());
        }
    };

    const removeTenantPerson = (index, values, disabled) => {
        if (!disabled) {
            let data = cloneDeep(values);
            let emailAddresses = remove(data.emailAddresses, function(n) {
                return n !== data.tenants[index].email;
            });
            data.tenants = [...data.tenants.slice(0, index), ...data.tenants.slice(index + 1)];
            data.emailAddresses = emailAddresses;
            setFormData(data);
        }
    };

    const emailAgentAndTenantHandler = (values, setValue) => {
        values.emailTo = DELIVERY_TO_AGENT_AND_TENANT;
        let emailAddresses = [];
        if (rentIncreaseWithoutLease) {
            values.tenants.forEach(tenant => {
                if (tenant.email && validateEmail(tenant.email)) {
                    emailAddresses.push(tenant.email);
                }
            });
        } else {
            emailAddresses =
                agreementInfo.tenant && agreementInfo.tenant.isServicesOfNoticesEmail
                    ? agreementInfo.tenant.servicesOfNoticesEmails
                    : [];
        }
        values.emailAddresses = uniq(emailAddresses);
        values.ccEmailAddresses = [loggedInUser.email];
        setValue('emailAddresses', values.emailAddresses);
        setValue('ccEmailAddresses', values.ccEmailAddresses);
        setFormData(values);
    };

    const onChangeEmail = (value, index, values, setValue) => {
        const data = cloneDeep(values);
        // change email list only email to is agentAndTenant
        if (data.emailTo === DELIVERY_TO_AGENT_AND_TENANT) {
            // set changed value to form values
            data.tenants[index].email = value.target.value;
            emailAgentAndTenantHandler(data, setValue);
        }
    };

    const validateEmail = value => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        }
        setAddEmailFieldError(undefined);
        return true;
    };

    const validateEmailList = values => {
        if (values.deliveryType === DELIVERY_TYPE_EMAIL && location === NSW_STATE) {
            if (!values.emailAddresses || values.emailAddresses.length === 0) {
                setAddEmailFieldError('Please enter at least one email address');
                return false;
            }
        }
        setAddEmailFieldError('');
        return true;
    };

    const handleAddressChange = (setValue, address) => {
        setValue('address', address);
    };
    const setAddressDetails = (address, details) => {
        setFormData({
            address,
            agentName: loggedInUser.fullName,
            tenants: rentIncreaseWithoutLease ? [{ firstName: '', middleName: '', secondName: '', email: '' }] : [],
            landlords: rentIncreaseWithoutLease ? [{ firstName: '', middleName: '', lastName: '', email: '' }] : [],
            addressDetails: details,
            deliveryType: DELIVERY_TYPE_EMAIL,
            emailTo: DELIVERY_TO_AGENT_AND_TENANT,
            ccEmailAddresses: [loggedInUser.email],
            emailAddresses: []
        });
        setIntegrationPropertyId(null);
        setIntegration(null);
    };

    const setUpdateAddressDetails = (address, details, values) => {
        let data = cloneDeep(values);
        setFormData({
            address,
            agentName: loggedInUser.fullName,
            tenants: rentIncreaseWithoutLease ? [{ firstName: '', middleName: '', secondName: '', email: '' }] : [],
            landlords: rentIncreaseWithoutLease ? [{ firstName: '', middleName: '', lastName: '', email: '' }] : [],
            addressDetails: details,
            ...data
        });
        setIntegrationPropertyId(null);
        setIntegration(null);
    };

    const setIntegrationProperty = propertyDetails => {
        if (propertyDetails) {
            const emailAddresses = [];
            if (propertyDetails.tenants) {
                propertyDetails.tenants.forEach(tenant => {
                    emailAddresses.push(tenant.email);
                });
            }
            setFormData({
                address: propertyDetails.address,
                agentName: loggedInUser.fullName,
                // for rent increase we display the current existing "rent per period" instead of a weekly amount
                currentRent: propertyDetails.originalRentAmount || propertyDetails.rentAmount,
                currentRentPayablePeriod: propertyDetails.period,
                tenants: propertyDetails.tenants,
                landlords: location === QLD_STATE ? setLandlords(propertyDetails.landlords) : [],
                deliveryType: DELIVERY_TYPE_EMAIL,
                emailTo: DELIVERY_TO_AGENT_AND_TENANT,
                ccEmailAddresses: [loggedInUser.email],
                emailAddresses
            });
            setIntegrationPropertyId(propertyDetails.id);
            setIntegration(propertyDetails);
        } else {
            clearFormData();
        }
    };

    const setLandlords = landlords => {
        const landlordList = [];
        if (!landlords) {
            landlordList.push({ firstName: '', middleName: '', lastName: '' });
        } else {
            landlords.forEach(landlord => {
                landlordList.push({
                    firstName: landlord.firstName,
                    middleName: '',
                    lastName: landlord.secondName
                });
            });
        }
        return landlordList;
    };

    const tomorrow = addDays(new Date(), 1);

    return (
        <React.Fragment>
            <ModalDialog
                title={
                    locationAndAddressSelected && location
                        ? `Rent increase - ${location}`
                        : 'Create new Rent Increase for'
                }
                isOpen={isOpen}
                closeModal={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                className={`document-centre-modal document-modal-dialog tenant ${
                    locationAndAddressSelected && location ? 'with-header' : 'without-header'
                }`}
                hideCloseButton={isSendingRentIncrease}
                // Quick fix for search results being cut off by modal
                allowOverflow={!locationAndAddressSelected}
            >
                <Form
                    initialValues={formData}
                    mutators={{
                        ...arrayMutators
                    }}
                    onSubmit={handleSubmit}
                >
                    {({
                        handleSubmit,
                        values,
                        form,
                        form: {
                            mutators: { push }
                        }
                    }) => {
                        // set the values to the ref so that the close modal can access these values
                        formValuesRef.current = values;
                        return (
                            <form noValidate onSubmit={values => handleSubmit(values)} id="create-rent-increase-form">
                                <FormSpy subscription={{ values: true }} onChange={() => handleFormDirtyChange(form)} />
                                {locationAndAddressSelected && location && (
                                    <React.Fragment>
                                        <DocumentHeader
                                            parentSelector="create-rent-increase-form"
                                            confirmDetails={confirmDetails}
                                            address={values.address}
                                            addressError={addressError}
                                            handleAddressChange={address => handleAddressChange(form.change, address)}
                                            setAddressDetails={(address, details) =>
                                                setUpdateAddressDetails(address, details, values)
                                            }
                                            disabled={
                                                isDocumentReadOnlyMode(documentEditMode) ||
                                                !rentIncreaseWithoutLease ||
                                                integrationPropertyId
                                            }
                                            agentName={values.agentName}
                                            integration={integration}
                                            viewOnlyIntegration={true}
                                            doc={currentDocument}
                                        />
                                        {!rentIncreaseWithoutLease && (
                                            <React.Fragment>
                                                <h3 className="margin-bottom-1rem">Tenant Details</h3>
                                                <FormTextRegular name="tenantList" label="Tenants" disabled={true} />
                                            </React.Fragment>
                                        )}
                                        {rentIncreaseWithoutLease && (
                                            <div>
                                                <div className="tenants">
                                                    <FieldArray name="tenants">
                                                        {({ fields }) =>
                                                            fields.map((name, index) => (
                                                                <RentIncreaseTenantPersonGroup
                                                                    key={index}
                                                                    index={index}
                                                                    rentIncreaseWithoutLease={rentIncreaseWithoutLease}
                                                                    removeTenant={index =>
                                                                        removeTenantPerson(
                                                                            index,
                                                                            values,
                                                                            values.disabled
                                                                        )
                                                                    }
                                                                    onChangeEmail={(value, index) =>
                                                                        onChangeEmail(value, index, values, form.change)
                                                                    }
                                                                    disabled={values.disabled}
                                                                />
                                                            ))
                                                        }
                                                    </FieldArray>
                                                    {rentIncreaseWithoutLease && isDocumentEditMode(documentEditMode) && (
                                                        <div className="button">
                                                            <button
                                                                className="add-tenant"
                                                                type="button"
                                                                onClick={
                                                                    values.disabled
                                                                        ? () => {}
                                                                        : () =>
                                                                              push('tenants', {
                                                                                  firstName: '',
                                                                                  middleName: '',
                                                                                  secondName: '',
                                                                                  email: ''
                                                                              })
                                                                }
                                                            >
                                                                Add tenant
                                                            </button>
                                                            {tenantError && <p className="text-error">{tenantError}</p>}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {location === QLD_STATE && (
                                            <React.Fragment>
                                                <div className="tenants">
                                                    <FieldArray name="landlords">
                                                        {({ fields }) =>
                                                            fields.map((name, index) => (
                                                                <RentIncreaseLandlordPersonGroup
                                                                    key={index}
                                                                    index={index}
                                                                    rentIncreaseWithoutLease={rentIncreaseWithoutLease}
                                                                    removeLandlord={index => fields.remove(index)}
                                                                    disabled={values.disabled}
                                                                />
                                                            ))
                                                        }
                                                    </FieldArray>
                                                    {rentIncreaseWithoutLease && isDocumentEditMode(documentEditMode) && (
                                                        <div className="button">
                                                            <button
                                                                className="add-tenant"
                                                                type="button"
                                                                onClick={
                                                                    values.disabled
                                                                        ? () => {}
                                                                        : () =>
                                                                              push('landlords', {
                                                                                  firstName: '',
                                                                                  middleName: '',
                                                                                  lastName: '',
                                                                                  email: ''
                                                                              })
                                                                }
                                                            >
                                                                Add landlord
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                <FormRadioGroup
                                                    label="Rent Increase Type"
                                                    name="rentIncreaseType"
                                                    data={[
                                                        {
                                                            label: RENT_INCREASE_TYPE_PERIODIC,
                                                            value: RENT_INCREASE_TYPE_PERIODIC,
                                                            disabled: values.disabled
                                                        },
                                                        {
                                                            label: RENT_INCREASE_TYPE_FIXED_TERM,
                                                            value: RENT_INCREASE_TYPE_FIXED_TERM,
                                                            disabled: values.disabled
                                                        }
                                                    ]}
                                                />
                                            </React.Fragment>
                                        )}
                                        {values.rentIncreaseType === RENT_INCREASE_TYPE_FIXED_TERM &&
                                            location === QLD_STATE && (
                                                <DatePickerInAgencyTimeZone
                                                    label={
                                                        'The start date of the lease this fixed term increase applies to is:'
                                                    }
                                                    name="leaseStartDate"
                                                    selected={values.leaseStartDate}
                                                    popperPlacement="top-start"
                                                    disabled={values.disabled}
                                                />
                                            )}
                                        <h3 className="margin-bottom-1rem">Current Rent</h3>
                                        <Warning className="rent-details-warning">
                                            This figure should be the actual rent payable
                                        </Warning>
                                        <div className="agreement-select">
                                            <div className="cell">
                                                <FormTextCurrency
                                                    name="currentRent"
                                                    label="Current rent is"
                                                    required
                                                    precision="2"
                                                    disabled={values.disabled}
                                                />
                                            </div>
                                            <div className="cell">
                                                <SelectField
                                                    label="payable"
                                                    name="currentRentPayablePeriod"
                                                    selectClassName="current-rent-period"
                                                    options={period}
                                                    disabled={values.disabled}
                                                />
                                            </div>
                                        </div>
                                        {values.currentRent && values.currentRentPayablePeriod && (
                                            <RentAmount
                                                label="The tenant is paying"
                                                value={`$${values.currentRent} per ${formatPeriod(
                                                    values.currentRentPayablePeriod
                                                )}`}
                                            />
                                        )}
                                        <h3 className="margin-bottom-1rem">New Rent</h3>
                                        <div className="agreement-select">
                                            <div className="cell">
                                                <FormTextCurrency
                                                    name="newRentAmount"
                                                    label="New rent is"
                                                    precision="2"
                                                    required
                                                    disabled={values.disabled}
                                                />
                                            </div>
                                            <div className="cell">
                                                <SelectField
                                                    label="payable"
                                                    name="newRentPayablePeriod"
                                                    selectClassName="new-rent-period"
                                                    options={period}
                                                    disabled={values.disabled}
                                                />
                                            </div>
                                        </div>
                                        {values.newRentAmount && values.newRentPayablePeriod && (
                                            <RentAmount
                                                label="The tenant will pay"
                                                value={`$${values.newRentAmount} per ${formatPeriod(
                                                    values.newRentPayablePeriod
                                                )}`}
                                            />
                                        )}
                                        <div className="calendar-group">
                                            <div className="calendar">
                                                <DatePickerInAgencyTimeZone
                                                    label={
                                                        location === QLD_STATE
                                                            ? 'Date the rent increase takes effect:'
                                                            : 'Effective from:'
                                                    }
                                                    name="newRentDateStart"
                                                    selected={values.newRentDateStart}
                                                    popperPlacement="top-start"
                                                    disabled={values.disabled}
                                                />
                                            </div>
                                            {location === QLD_STATE && (
                                                <p>
                                                    Please ensure date gives no less <b>than two (2) months’ notice</b>{' '}
                                                    of rent increase and cannot be{' '}
                                                    <b>earlier than twelve (12) months</b> since last increase, or the
                                                    start of the tenancy
                                                    <br />
                                                    <br />
                                                </p>
                                            )}
                                            {values.newRentDateStart &&
                                                location === QLD_STATE &&
                                                endOfDay(values.newRentDateStart) < addMonths(tomorrow, 2) && (
                                                    <Warning>
                                                        {`This date is less than 2 months
                                                        from today's date. 2 months from now is on the ${formatDateStandard(
                                                            addMonths(tomorrow, 2),
                                                            getAgencyTimezoneFromUser(loggedInUser)
                                                        )}.`}
                                                    </Warning>
                                                )}
                                            {values.newRentDateStart &&
                                                location === NSW_STATE &&
                                                endOfDay(values.newRentDateStart) < addDays(tomorrow, 60) && (
                                                    <Warning>
                                                        {`This date is less than 60 days
                                                        from today's date. 60 days from now is on the ${formatDateStandard(
                                                            addDays(tomorrow, 60),
                                                            getAgencyTimezoneFromUser(loggedInUser)
                                                        )}.`}
                                                    </Warning>
                                                )}
                                        </div>
                                        {location === NSW_STATE && (
                                            <DeliveryTypeComponent
                                                emailAgentAndTenantHandler={emailAgentAndTenantHandler}
                                                addEmailFieldError={addEmailFieldError}
                                                values={values}
                                                form={form}
                                                optionsList={[
                                                    DELIVERY_TYPE_EMAIL,
                                                    DELIVERY_TYPE_IN_PERSON,
                                                    DELIVERY_TYPE_POST,
                                                    DELIVERY_TYPE_OVER_16,
                                                    DELIVERY_TYPE_LETTERBOX
                                                ]}
                                            />
                                        )}
                                        <RentIncreaseFooter
                                            previewPdf={() => previewPdf(values)}
                                            viewPdf={viewPdf}
                                            form={form}
                                            rentIncreaseWithoutLease={rentIncreaseWithoutLease}
                                            isSubmitSuccessfull={isSubmitSuccessfull}
                                            isSubmitFail={isSubmitFail}
                                            isSendingRentIncrease={isSendingRentIncrease}
                                            isSaveDraft={isSaveDraft}
                                        />
                                    </React.Fragment>
                                )}
                                {!locationAndAddressSelected && (
                                    <LocationAndAddressSelection
                                        location={location}
                                        allowedStates={allowedStates}
                                        setLocation={setLocation}
                                        closeModal={closeModal}
                                        confirmDetails={confirmDetails}
                                        address={values.address}
                                        isCreating={isCreatingRentIncreaseNotice}
                                        handleAddressChange={address => handleAddressChange(form.change, address)}
                                        setAddressDetails={(address, details) => setAddressDetails(address, details)}
                                        setIntegrationProperty={property => setIntegrationProperty(property)}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        </React.Fragment>
    );
};
export default memo(RentIncreaseForm);
