import React from 'react';
import { FormTextRegular } from './../../../form/FormText';
import { getResTenLabel } from '../../../../utils/labelUtils';

export const LandlordPersonGroup = ({ index, removePerson, location }) => {
    let header;
    if (index == 0) {
        header = (
            <h3>
                {getResTenLabel('landlord', location)} {index + 1}
            </h3>
        );
    } else {
        header = (
            <span className="removePerson">
                <h3>
                    {getResTenLabel('landlord', location)} {index + 1}
                </h3>
                <button type="button" onClick={() => removePerson(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }

    return (
        <div className="person">
            {header}
            <div className="formBox">
                <FormTextRegular name={`persons[${index}].firstName`} label="First Name" required />
                <FormTextRegular name={`persons[${index}].secondName`} label="Last Name" required />
            </div>
        </div>
    );
};
