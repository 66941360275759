import axios from 'axios';
import * as Team from './../reducers/joinTeam';

/**
 * Get the agency team list.
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function getAgencyTeam() {
    return {
        types: [Team.GET_AGENCY_TEAM_REQUEST, Team.GET_AGENCY_TEAM_SUCCESS, Team.GET_AGENCY_TEAM_FAILURE],
        promise: axios.get('/api/agency/team')
    };
}

export function addUserTeam(data) {
    return {
        type: Team.ADD_USER_SUCCESS,
        payload: { data }
    };
}

/**
 * Update user info.
 * @param {string} userId - user ID
 * @param {object} data - user info
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function updateUserInfo() {
    return {
        type: Team.UPDATE_TEAM_USER_INFO_SUCCESS
    };
}

/**
 * Delete user.
 * @param {string} userId - user ID
 * @param transferUserId
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function deleteUser(userId, transferUserId) {
    return {
        types: [Team.DELETE_USER_REQUEST, Team.DELETE_USER_SUCCESS, Team.DELETE_USER_FAILURE],
        promise: axios.delete(`/api/agency/team/${userId}`, { data: { transferUserId } })
    };
}

/**
 * Block teammate account.
 * @param {string} userId - user ID
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function blockUser(userId) {
    return {
        types: [Team.BLOCK_USER_REQUEST, Team.UPDATE_TEAM_USER_INFO_SUCCESS, Team.UPDATE_TEAM_USER_INFO_FAILURE],
        promise: axios.post(`/api/agency/team/${userId}/block`)
    };
}

/**
 * Unblock teammate account.
 * @param {string} userId - user ID
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function unblockUser(userId) {
    return {
        types: [Team.UNBLOCK_USER_REQUEST, Team.UPDATE_TEAM_USER_INFO_SUCCESS, Team.UPDATE_TEAM_USER_INFO_FAILURE],
        promise: axios.post(`/api/agency/team/${userId}/unblock`)
    };
}

export function checkToken(token) {
    return {
        types: [Team.CHECK_TOKEN_REQUEST, Team.CHECK_TOKEN_SUCCESS, Team.CHECK_TOKEN_FAILURE],
        promise: axios.get(`/api/invite/check/${token}`)
    };
}

export function acceptUser(data) {
    return {
        types: [Team.ACCEPT_USER_REQUEST, Team.ACCEPT_USER_SUCCESS, Team.ACCEPT_USER_FAILURE],
        promise: axios.post('/api/invite/accept', data)
    };
}
export function acceptExistingUser(data) {
    return {
        types: [Team.ACCEPT_USER_REQUEST, Team.ACCEPT_USER_SUCCESS, Team.ACCEPT_USER_FAILURE],
        promise: axios.post('/api/invite/existing/accept', data)
    };
}

/**
 * Open modal team.
 * @returns {{types}}
 */
export function openModalTeam() {
    return {
        type: Team.OPEN_MODAL_TEAM
    };
}

/**
 * Close modal team.
 * @returns {{types}}
 */
export function closeModalTeam() {
    return {
        type: Team.CLOSE_MODAL_TEAM
    };
}
export function replaceInvitation(oldInvitationId, invitation) {
    return {
        type: Team.REPLACE_INVITATION,
        data: { oldInvitationId, invitation }
    };
}

/**
 * Cancel/delete invitation.
 * @param {string} invitationId - invitation ID
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function cancelInvitation(invitationId) {
    return {
        types: [Team.CANCEL_INVITATION_REQUEST, Team.CANCEL_INVITATION_SUCCESS, Team.CANCEL_INVITATION_FAILURE],
        promise: axios.delete(`/api/agency/team/invite/${invitationId}`)
    };
}
