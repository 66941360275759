import React, { useState } from 'react';
import axios from 'axios';
import { HIDE_MESSAGE_TIME } from '../../../../config';
import CommonFooter from '../shared/CommonFooter';

const BreachNoticeWithoutLeaseFooter = ({
    isSendingBreachNotice,
    isSendingBreachNoticeSuccess,
    isSendingBreachNoticeFail,
    currentDocument,
    documentEditMode,
    previewPdf,
    viewPdf,
    form,
    isSaveDraft,
    showLoadingIcon
}) => {
    const [isResendBreachNotice, setIsResendBreachNotice] = useState(false);
    const [isResendBreachNoticeSuccess, setIsResendBreachNoticeSuccess] = useState(false);
    const [isResendBreachNoticeFail, setIsResendBreachNoticeFail] = useState(false);

    const resendPDF = docId => {
        setIsResendBreachNotice(true);
        return axios
            .post(`api/document/breach-notice/${docId}/resend`)
            .then(() => {
                setIsResendBreachNotice(false);
                setIsResendBreachNoticeSuccess(true);
                setIsResendBreachNoticeFail(false);
                setTimeout(() => {
                    setIsResendBreachNoticeSuccess(false);
                }, HIDE_MESSAGE_TIME);
            })
            .catch(() => {
                setIsResendBreachNotice(false);
                setIsResendBreachNoticeSuccess(false);
                setIsResendBreachNoticeFail(true);
                setTimeout(() => {
                    setIsResendBreachNoticeFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    return (
        <CommonFooter
            isDoc={true}
            currentDocument={currentDocument}
            documentEditMode={documentEditMode}
            resend={resendPDF}
            viewPdf={viewPdf}
            previewPdf={previewPdf}
            isResending={isResendBreachNotice}
            isResendingSuccess={isResendBreachNoticeSuccess}
            isResendingFail={isResendBreachNoticeFail}
            isSendingSuccess={isSendingBreachNoticeSuccess}
            isSendingFail={isSendingBreachNoticeFail}
            isSending={isSendingBreachNotice}
            form={form}
            isSaveDraft={isSaveDraft}
            showLoadingIcon={showLoadingIcon}
            hideResend={true}
        />
    );
};
export default BreachNoticeWithoutLeaseFooter;
