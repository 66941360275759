import { isEmpty } from 'lodash';
import * as React from 'react';
import ModalDialog from '../../../common/components/ModalDialog';
import MaterialFact from '../shared/MaterialFact';
import '../../../sass/materialFacts.scss';

export default function ModalViewMaterialFacts({ isOpen, closeModal, materialFacts }) {
    return (
        <ModalDialog title="Material Facts" isOpen={isOpen} closeModal={closeModal}>
            <div className="agreement-section-text">
                <div>
                    {isEmpty(materialFacts) ? (
                        <p>No material facts.</p>
                    ) : (
                        <div>
                            <ul>
                                {materialFacts.map((value, index) => {
                                    return <MaterialFact key={index} item={value} />;
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </ModalDialog>
    );
}
