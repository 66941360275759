import React, { useState } from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import ModalViewSpecialConditions from '../../../../modals/viewSpecialConditions/ModalViewSpecialCondition';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils';
import { LeaseAllInfo } from './types';

type Props = {
    leaseAllInfo: LeaseAllInfo,
}

export default function AgreementClSpecialConditions({ leaseAllInfo }: Props) {
    const part = 'specialConditions';
    const [isOpen, setIsOpen] = useState(false);
    if (!leaseAllInfo?.specialConditions) {
        return <></>;
    }
    const { location, specialConditions } = leaseAllInfo;
    return (
        <AgreementSection
            title={getClStepTitle(location, part)}
            step={getClStepNo(location, part)}
            leaseAllInfo={leaseAllInfo}
        >
            <span className="special-link" onClick={() => setIsOpen(true)}>
                View special terms & conditions
            </span>
            <ModalViewSpecialConditions
                isOpen={isOpen}
                closeModal={() => setIsOpen(false)}
                specialConditions={specialConditions}
            />
        </AgreementSection>
    );
}
