import { createSelector } from 'reselect';
import { DOCUMENT_CREATE_A_FLK } from '../../config';
import { getDocument } from './index';

export const getIsOpenCreateFlkModal = createSelector([getDocument], document => document.isOpenCreateFlkModal);

export const getCreateFlkClientAvailableFields = createSelector(
    [getDocument],
    document => document.availableClientFieldDetails[DOCUMENT_CREATE_A_FLK]
);
