import React from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import { getError } from '../../utils/formUtils';

const passwordInput = ({
    input,
    label,
    meta,
    disabledLabel,
    validateOnTouch,
    alwaysShowOn,
    containerClassName,
    required,
    ...rest
}) => {
    let className = 'form-group regular';
    if (alwaysShowOn || input.value) {
        className += ' on';
    }
    return (
        <div
            className={cx('FormInput', containerClassName, {
                'FormInput--error': getError(meta, validateOnTouch)
            })}
        >
            <div className={className}>
                <label className={getError(meta, validateOnTouch) ? `FormError ${disabledLabel}` : disabledLabel}>
                    {label}
                    {required ? '*' : null}
                </label>
                <input type="password" {...input} {...rest} />
                {getError(meta, validateOnTouch) && <span className={'FormError'}>{getError(meta)}</span>}
            </div>
        </div>
    );
};

export const FormPassword = ({ name, label, value, validateOnTouch, alwaysShowOn, containerClassName, required }) => {
    return (
        <Field
            name={name}
            type="password"
            label={label}
            value={value}
            validateOnTouch={validateOnTouch}
            alwaysShowOn={alwaysShowOn}
            component={passwordInput}
            containerClassName={containerClassName}
            required={required}
        />
    );
};
