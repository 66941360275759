import { statefulActions } from './utils';

export const GET_EMAIL_TEMPLATE = statefulActions('GET_EMAIL_TEMPLATE');
export const UPDATE_EMAIL_TEMPLATE = statefulActions('GET_EMAIL_TEMPLATE');

const initState = {
    templates: []
};

// todo update state for the update email template
export default function emailTemplates(state = initState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case GET_EMAIL_TEMPLATE.FAIL:
            // todo throw a toasts here
            break;
        case GET_EMAIL_TEMPLATE.SUCCESS:
            newState.templates = action.result.data.templates;
            newState.fields = action.result.data.fields;
            break;
    }
    return newState;
}
