import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import {
    getLocation,
    getStep,
    getDirtyStep,
    getLeaseType,
    getAddress,
    getLeaseInfoAgent
} from '../../../../selectors/lease';
import { getManagingAgent } from '../../../../selectors/lease/salesLease';

import { FormTextRegular } from '../../../../components/form/FormText';
import { FormPhone } from '../../../../components/form/FormPhone';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { getUserAgency } from '../../../../selectors/agency';

const MANAGING_AGENT_FORM = 'managingAgentForm';

const ManagingAgent = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const address = useSelector(getAddress);
    const agency = useSelector(getUserAgency);
    const agent = useSelector(getLeaseInfoAgent);
    const leaseType = useSelector(getLeaseType);
    const reduxManagingAgent = useSelector(getManagingAgent);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [managingAgent, setManagingAgent] = useState(reduxManagingAgent || {});

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(MANAGING_AGENT_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateManagingAgent(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_MANAGING_AGENT_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.managingAgent')) {
                    return error.response.data.errors.managingAgent;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateManagingAgent = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/managing-agent`, data);
    };

    return (
        <div className="form-container managingAgent">
            <Form
                onSubmit={submitForm}
                mutators={{
                    ...arrayMutators
                }}
                initialValues={managingAgent}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={MANAGING_AGENT_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="formBox">
                                <CheckboxCheck
                                    name="hasManagingAgent"
                                    label="There is a managing agent for this property"
                                />
                                {values.hasManagingAgent && (
                                    <div>
                                        <FormTextRegular name="agencyName" label="Managing Agency Name" required />
                                        <div className="nowrap fields-full-width-for-mobile">
                                            <FormTextRegular
                                                name="agentFirstName"
                                                label="Property Manager First Name"
                                                required
                                            />
                                            <FormTextRegular name="agentLastName" label="Property Manager Last Name" />
                                        </div>
                                        <FormTextRegular name="address" label="Address" />
                                        <FormPhone name="phone" label="Phone" />
                                        <FormTextRegular name="email" label="Email" required />
                                        <CheckboxCheck
                                            name="shouldReceiveNotifications"
                                            label="I wish to send the managing agent a notification of the intention to sell the property"
                                            toolTip={renderEmailTooltip(values, agency, agent, address)}
                                        />
                                    </div>
                                )}
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

function renderEmailTooltip(values, agency, agent, address) {
    return `
            Hi ${
                values.agentFirstName || values.agentLastName
                    ? [values.agentFirstName, values.agentLastName].join(' ')
                    : '[Managing Agent Name]'
            }
            <br />
            <br />
            <br />
            We have been advised that you are the managing agent of the property ${address}.
            <br />
            <br />
            This email is to advise that ${
                agency?.details?.agencyName
            } have been appointed as the selling agency of for this property.
            <br />
            <br />
            Our contact details are:
            <br />
            ${agent?.fullName}
            <br />
            ${agency?.details?.agencyName}
            <br />
            e: ${agent?.email}
            <br />
            m: ${agent?.phone}
            <br />
            <br />
            We are required to provide you with written notice of this appointment.
            <br />
            <br />
            Can you please notify the tenants of ${address} of our appointment and provide us with the tenancy information.
            <br />
            <br />
            Regards,
            <br />
            ${agent?.fullName}
            <br />
            ${agency?.details?.agencyName}
            <br />
            ${agency?.details?.email}
            <br />
            ${agency?.details?.phone}`;
}

export default memo(ManagingAgent);
