import { FieldMetaState } from 'react-final-form';

export const fieldConfig = {
    subscription: {
        error: true,
        submitError: true,
        dirtySinceLastSubmit: true,
        touched: true,
        modified: true
    }
};

/**
 * Get the error for an input, if we have frontend validation
 * @param meta
 * @param shouldBeTouched
 * @returns {*|boolean}
 */
export const getFormError = (meta: FieldMetaState<unknown>, shouldBeTouched = false) => {
    if (meta.error) {
        return shouldBeTouched ? meta.touched && meta.error : meta.error;
    }
};

export type ShouldShowError = boolean | ((meta: FieldMetaState<string>) => boolean);

// By default, only show the error if the field has been touched
export const defaultShouldShowErrorFunction = (meta: FieldMetaState<string>) =>
    (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit);
