import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import axios from 'axios';

import { closeCreateFlkModal, refreshDocumentList } from '../../../../actions/document';
import { openNoSignatureModal } from '../../../../actions/user';

import AgreementMainAuditTrail from '../../../../components/dashboard/InfoAgreementComponents/AgreementMainComponents/shared/AgreementMainAuditTrail';
import Button from '../../../../common/components/Button';

import {
    HIDE_MESSAGE_TIME,
    FORM_SUBMIT_TYPE_SAVE,
    FORM_SUBMIT_TYPE_SEND,
    COMPLETION_STATUS_PROCESSING,
    DOCUMENT_DELIVERY_TYPE_EMAIL,
    DOCUMENT_DELIVERY_TYPE_SMS
} from '../../../../config';
import {
    isDocumentCreateMode,
    isDocumentEditMode,
    isDocumentTemplateReadOnlyMode,
    isDocumentStatusDraft,
    isDocumentStatusSentForSigning,
    isDocumentAwaitingCompletion,
    isDocumentComplete
} from '../../../../utils/generalUtils';
import ButtonGroup from '../../../../common/components/ButtonGroup';

import { getAgentSignature, getCompletionForm } from '../../../../selectors/completion';
import ResendDocForSigningModal from '../../../../components/modals/ResendForSigningModal/ResendDocForSigningModal';
import CompletionProgress from '../../../../components/dashboard/InfoAgreementComponents/footerComponents/CompletionProgress';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { DOC_COMPLETION_EMAIL_SEND_LIST } from '../../../../constants/featureFlags';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { clearDoubleSendGuard } from '../../../../hooks/useDoubleSendGuard';

const CreateFlkFooter = ({
    currentDocument,
    documentEditMode,
    isSendingCreateFlk,
    isSubmitSuccessfull,
    isSubmitFail,
    isSaveDraft,
    isTemplate,
    form,
    values,
    resendWaitSeconds,
    onBeforeSend
}) => {
    const dispatch = useDispatch();

    const agentSignature = useSelector(getAgentSignature);
    const completion = useSelector(getCompletionForm);

    const [isResending, setIsResending] = useState(false);
    const [isResendingSuccess, setIsResendingSuccess] = useState(false);
    const [isResendingFail, setIsResendingFail] = useState(false);
    const [signedByAgent, setSignedByAgent] = useState(false);

    const [iscompleting, setIscompleting] = useState(false);
    const [iscompletingFail, setIscompletingFail] = useState(false);
    const [isOpenResendModal, setIsOpenResendModal] = useState(false);
    const [completionProgress, setCompletionProgress] = useState(
        currentDocument && currentDocument.completion && currentDocument.completion.progress
            ? currentDocument && currentDocument.completion && currentDocument.completion.progress
            : []
    );

    const isCompletionSendListActive = useFeatureFlag(DOC_COMPLETION_EMAIL_SEND_LIST);

    useEffect(() => {
        if (currentDocument && currentDocument.completion && currentDocument.completion.progress) {
            setCompletionProgress(currentDocument.completion.progress);
            setIscompleting(currentDocument.completion.status === COMPLETION_STATUS_PROCESSING);
        }
    }, [currentDocument]);

    const resendLink = (id, selectedClients) => {
        setIsResending(true);
        axios
            .post(`api/document/${id}/resend-create-a-flk`, { selectedClients })
            .then(() => {
                setIsOpenResendModal(false);
                setIsResending(false);
                setIsResendingSuccess(true);
                setIsResendingFail(false);
                dispatch(refreshDocumentList());
                setTimeout(() => {
                    setIsResendingSuccess(false);
                }, HIDE_MESSAGE_TIME);
            })
            .catch(() => {
                clearDoubleSendGuard();
                setIsResending(false);
                setIsResendingSuccess(false);
                setIsResendingFail(true);
                setTimeout(() => {
                    setIsResendingFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    const completeDocument = id => {
        let data;
        if (signedByAgent && isEmpty(agentSignature)) {
            dispatch(openNoSignatureModal());
        } else {
            if (isCompletionSendListActive) {
                data = {
                    signedByAgent,
                    toNotificationList: completion?.toNotificationList,
                    ccNotificationList: completion?.ccNotificationList,
                    bccNotificationList: completion?.bccNotificationList
                };
            } else {
                data = {
                    signedByAgent
                };
            }
            setIscompleting(true);
            axios
                .post(`api/document/${id}/complete-create-a-flk`, data)
                .then(() => {
                    setIscompletingFail(false);
                    setTimeout(() => {
                        dispatch(closeCreateFlkModal());
                    }, HIDE_MESSAGE_TIME);
                })
                .catch(() => {
                    setIscompleting(false);
                    setIscompletingFail(true);
                    setTimeout(() => {
                        setIscompletingFail(false);
                    }, HIDE_MESSAGE_TIME);
                });
        }
    };

    const viewPdf = docId => {
        window.open(`/reader/document/create-a-flk/${docId}`, '_blank');
    };

    const renderSaveButton = () => {
        if (isTemplate) {
            return (
                <Button
                    primary
                    className="button-large"
                    height={40}
                    type="submit"
                    // set submitType in here, then use that is handleSubmit to decide send or save
                    onClick={() => form.change('submitType', FORM_SUBMIT_TYPE_SAVE)}
                    loading={isSendingCreateFlk}
                >
                    Save Template
                </Button>
            );
        }
        return (
            <Button
                secondary
                height={40}
                type="submit"
                // set submitType in here, then use that is handleSubmit to decide send or save
                onClick={() => form.change('submitType', FORM_SUBMIT_TYPE_SAVE)}
                loading={isSendingCreateFlk}
            >
                Save
            </Button>
        );
    };

    const renderSendButton = () => {
        let sendButtonText = 'Send';
        if (resendWaitSeconds > 0) {
            sendButtonText = `Resend in ${resendWaitSeconds} seconds`;
        } else if (values.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL) {
            sendButtonText = 'Send via Email';
        } else if (values.deliveryType === DOCUMENT_DELIVERY_TYPE_SMS) {
            sendButtonText = 'Send via SMS';
        }
        return (
            <Button
                primary
                height={40}
                disabled={resendWaitSeconds > 0}
                loading={isSendingCreateFlk}
                type="submit"
                // set submitType in here, then use that is handleSubmit to decide send or save
                onClick={() => {
                    onBeforeSend?.();
                    form.change('submitType', FORM_SUBMIT_TYPE_SEND);
                }}
            >
                {sendButtonText}
            </Button>
        );
    };

    const getCreateButtons = () => (
        <div className={`button-row ${isTemplate ? 'single' : ''}`}>
            <div className="button-footer-docs">{renderSaveButton()}</div>
            {!isTemplate && <div className="button-footer-docs btn-footer">{renderSendButton()}</div>}
        </div>
    );

    function getSentForSigningButtons() {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => viewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
                <div className="button-row single">
                    {isResendingSuccess && (
                        <p className="button-result savestatus-saved">
                            Document sent successfully
                            <span className="icon savestatus-icon" />
                        </p>
                    )}
                    {isResendingFail && (
                        <p className="button-result savestatus-failed">
                            Sending document failed <span className="icon savestatus-icon" />
                        </p>
                    )}
                </div>
                <div className="button-row single">
                    <div className="button-footer-docs">
                        <Button
                            primary
                            disabled={resendWaitSeconds > 0}
                            loading={isResending}
                            type="button"
                            onClick={() => setIsOpenResendModal(true)}
                        >
                            Resend{resendWaitSeconds > 0 && ` in ${resendWaitSeconds} seconds`}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    function getAwaitingCompletionButtons() {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => viewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
                <div className="button-row single">
                    {iscompletingFail && (
                        <p className="button-result savestatus-failed">
                            Completion failed <span className="icon savestatus-icon" />
                        </p>
                    )}
                </div>
                <div className="button-row single">
                    <div className="footer-draft">
                        <div className="section space-between submit-row">
                            <CheckboxCheck
                                className="footer-checkbox"
                                name="signedByAgent"
                                value={signedByAgent}
                                label="Add my name and signature to the completed document"
                                onClick={value => setSignedByAgent(value)}
                            />
                        </div>
                        <div>
                            <Button
                                primary
                                loading={iscompleting}
                                type="button"
                                onClick={() => completeDocument(currentDocument.id)}
                            >
                                Complete
                            </Button>
                        </div>
                    </div>
                </div>
                <CompletionProgress progress={completionProgress} />
            </div>
        );
    }

    function getCompletedButtons() {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                <ButtonGroup
                    title="Copy of document"
                    buttonList={[
                        {
                            onClick: () => viewPdf(currentDocument.id),
                            disabled: false,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        }
                    ]}
                />
            </div>
        );
    }

    return (
        <React.Fragment>
            {!isDocumentCreateMode(documentEditMode) &&
                currentDocument &&
                currentDocument.auditTrail &&
                currentDocument.auditTrail.length > 0 && (
                    <AgreementMainAuditTrail leaseId={currentDocument.id} isDocument={true} />
                )}
            {isDocumentEditMode(documentEditMode) && (
                <div className="button-row single">
                    {isSubmitSuccessfull && (
                        <span className="savestatus-saved">
                            <span className="icon savestatus-icon" />
                            {isTemplate ? 'Template' : 'Document'} {isSaveDraft ? 'saved' : 'sent'} successfully
                        </span>
                    )}
                    {isSubmitFail && (
                        <span className="savestatus-failed">
                            <span className="icon savestatus-icon" />
                            {isSaveDraft ? 'Saving' : 'Sending'} {isTemplate ? 'template' : 'document'} failed
                        </span>
                    )}
                </div>
            )}
            {currentDocument && (
                <React.Fragment>
                    {(isDocumentCreateMode(documentEditMode) || isDocumentEditMode(documentEditMode)) &&
                        isDocumentStatusDraft(currentDocument.status) &&
                        !isDocumentTemplateReadOnlyMode(documentEditMode) &&
                        getCreateButtons()}
                    {currentDocument &&
                        !isDocumentCreateMode(documentEditMode) &&
                        isDocumentStatusSentForSigning(currentDocument.status) &&
                        getSentForSigningButtons()}
                    {currentDocument &&
                        !isDocumentCreateMode(documentEditMode) &&
                        isDocumentAwaitingCompletion(currentDocument.status) &&
                        getAwaitingCompletionButtons()}
                    {currentDocument &&
                        !isDocumentCreateMode(documentEditMode) &&
                        isDocumentComplete(currentDocument.status) &&
                        getCompletedButtons()}
                    <ResendDocForSigningModal
                        isOpen={isOpenResendModal}
                        clients={currentDocument && currentDocument.to ? currentDocument.to.clients : []}
                        closeModal={() => setIsOpenResendModal(false)}
                        shouldResendForAll={true}
                        resendForSigning={selectedClients => resendLink(currentDocument.id, selectedClients)}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
export default CreateFlkFooter;
