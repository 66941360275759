import { createSelector } from 'reselect';

export const getResetPassword = state => state.resetPassword;

export const getIsSendingResetRequest = createSelector(
    [getResetPassword],
    resetPassword => resetPassword.isSendingResetRequest
);
export const getRequestSentSuccess = createSelector(
    [getResetPassword],
    resetPassword => resetPassword.requestSentSuccess
);

export const getIsUpdatingPassword = createSelector(
    [getResetPassword],
    resetPassword => resetPassword.isUpdatingPassword
);

export const getIsCheckingToken = createSelector([getResetPassword], resetPassword => resetPassword.isCheckingToken);

export const getIsTokenExpired = createSelector([getResetPassword], resetPassword => resetPassword.isTokenExpired);

export const getServerError = createSelector([getResetPassword], resetPassword => resetPassword.serverError);

export const getUpdatePasswordSuccess = createSelector(
    [getResetPassword],
    resetPassword => resetPassword.updatePasswordSuccess
);
export const getUpdatePasswordFail = createSelector(
    [getResetPassword],
    resetPassword => resetPassword.updatePasswordFail
);
export const getValidationError = createSelector([getResetPassword], resetPassword => resetPassword.validationError);
