import produce from 'immer';
import { camelCase, omit } from 'lodash';
import { MFA_AUTHENTICATION_FAILED, MFA_CODE_EXPIRE_MESSAGE, VERIFICATION_CODE_REQUIRED_MESSAGE } from '../constants/constants';
import { CANCEL_TARIFF_PLAN_SUCCESS, SET_TARIFF_PLAN_SUCCESS, UPDATE_AGENCY_INFO_SUCCESS } from './account';
import { statefulActions } from './utils';

export const GET_USERINFO_REQUEST = 'GET_USERINFO_REQUEST';
export const GET_USERINFO_SUCCESS = 'GET_USERINFO_SUCCESS';
export const GET_USERINFO_FAILURE = 'GET_USERINFO_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_AGENCIES_REQUEST = 'GET_AGENCIES_REQUEST';
export const GET_AGENCIES_SUCCESS = 'GET_AGENCIES_SUCCESS';
export const GET_AGENCIES_FAILURE = 'GET_AGENCIES_FAILURE';

export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';

export const UPDATE_TEAM_MEMBER_INFO_REQUEST = 'UPDATE_TEAM_MEMBER_INFO_REQUEST';
export const UPDATE_TEAM_MEMBER_INFO_SUCCESS = 'UPDATE_TEAM_MEMBER_INFO_SUCCESS';
export const UPDATE_TEAM_MEMBER_INFO_FAIL = 'UPDATE_TEAM_MEMBER_INFO_FAIL';

export const UPDATE_TEAM_MEMBER_VIEWING = 'UPDATE_TEAM_MEMBER_VIEWING';

export const UPDATE_LOGIN_DATA_SUCCESS = 'UPDATE_LOGIN_DATA_SUCCESS';

export const ADD_PROFILE_PICTURE_REQUEST = 'ADD_PROFILE_PICTURE_REQUEST';
export const ADD_PROFILE_PICTURE_SUCCESS = 'ADD_PROFILE_PICTURE_SUCCESS';
export const ADD_PROFILE_PICTURE_FAIL = 'ADD_PROFILE_PICTURE_FAIL';

export const DELETE_PROFILE_PICTURE_REQUEST = 'DELETE_PROFILE_PICTURE_REQUEST';
export const DELETE_PROFILE_PICTURE_SUCCESS = 'DELETE_PROFILE_PICTURE_SUCCESS';
export const DELETE_PROFILE_PICTURE_FAIL = 'DELETE_PROFILE_PICTURE_FAIL';

export const DELETE_ACCOUNT_INTEGRATION_REQUEST = 'DELETE_ACCOUNT_INTEGRATION_REQUEST';
export const DELETE_ACCOUNT_INTEGRATION_SUCCESS = 'DELETE_ACCOUNT_INTEGRATION_SUCCESS';
export const DELETE_ACCOUNT_INTEGRATION_FAIL = 'DELETE_ACCOUNT_INTEGRATION_FAIL';

export const SYNC_ACCOUNT_INTEGRATION_REQUEST = 'SYNC_ACCOUNT_INTEGRATION_REQUEST';
export const SYNC_ACCOUNT_INTEGRATION_SUCCESS = 'SYNC_ACCOUNT_INTEGRATION_SUCCESS';
export const SYNC_ACCOUNT_INTEGRATION_FAIL = 'SYNC_ACCOUNT_INTEGRATION_FAIL';

export const UPDATE_IS_SUPER_ADMIN = 'UPDATE_IS_SUPER_ADMIN';
export const ADD_AGENCY_TO_AGENCIES = 'ADD_AGENCY_TO_AGENCIES';
export const REMOVE_AGENCY_FROM_AGENCIES = 'REMOVE_AGENCY_FROM_AGENCIES';

export const CHANGE_AGENCY_DISABLED_GLOBAL_TEMPLATES = 'CHANGE_AGENCY_DISABLED_GLOBAL_TEMPLATES';

export const GET_CONNECTION_CODE_REQUIRED = statefulActions('GET_CONNECTION_CODE_REQUIRED');

export const UPDATE_INSPECTION_MANAGER_INTEGRATION = statefulActions('update_inspection_manager_integration');

const initState = {
    userInfo: false,
    agencyId: '',
    agencyLocation: 'NSW',
    infoUser: {},
    isPending: false,
    validationErrors: null,
    mfaError: false,
    syncIntegrations: {},
    demoModule: null,
    modules: null,
    isSuperAdmin: false,
    gettingAgent: false,
    agent: {}
};

export default function authorization(state = initState, action) {
    const newState = Object.assign({}, state);

    switch (action.type) {
        case UPDATE_IS_SUPER_ADMIN:
            newState.isSuperAdmin = action.isSuperAdmin;
            break;
        case GET_USERINFO_REQUEST: {
            newState.userInfo = true;
            break;
        }
        case UPDATE_AGENCY_INFO_SUCCESS: {
            newState.agencyId = action.payload.agency.id;
            newState.agencyLocation = action.payload.agency.details.location;
            newState.agency = action.payload.agency;
            break;
        }

        case GET_USERINFO_SUCCESS: {
            newState.userInfo = action.result.data.user;
            newState.agencyId = action.result.data.agency.id;
            newState.agencyLocation = action.result.data.agency.details.location;
            newState.agency = action.result.data.agency;
            newState.modules = action.result.data.agency.productModules;
            newState.isSuperAdmin = action.result.data.user.isSuperAdmin;

            /**
             * FEATURE FLAGS
             */
            const sherlockFound = action.result.data.agency.productModules.filter(productModule => {
                return productModule.productKey === 'SHERLOCK';
            });
            newState.sherlockModule = sherlockFound ? sherlockFound[0] : null;

            const demoFound = action.result.data.agency.productModules.filter(productModule => {
                return productModule.productKey === 'DEMO';
            });
            newState.demoModule = demoFound ? demoFound[0] : null;
            break;
        }
        case GET_USERINFO_FAILURE: {
            return produce(state, draftState => {
                draftState.userInfo = false;
            });
        }
        case GET_USER_REQUEST: {
            return produce(state, draftState => {
                draftState.gettingAgent = false;
            });
        }
        case GET_USER_SUCCESS: {
            return produce(state, draftState => {
                draftState.agent = action.result.data.user;
                draftState.gettingAgent = false;
            });
        }
        case GET_USER_FAILURE: {
            return produce(state, draftState => {
                draftState.agent = false;
                draftState.gettingAgent = false;
            });
        }

        case GET_AGENCIES_REQUEST: {
            newState.requestingAgencies = true;
            break;
        }
        case GET_AGENCIES_SUCCESS: {
            newState.requestingAgencies = false;
            newState.agencies = action.result.data.agencies;
            break;
        }

        case REMOVE_AGENCY_FROM_AGENCIES: {
            const agencies = [...state.agencies];

            // remove one agency from the agencies list
            agencies.splice(
                agencies.findIndex(agency => agency.id === action.agencyId),
                1
            );
            newState.agencies = agencies;
            break;
        }

        case ADD_AGENCY_TO_AGENCIES: {
            const agencies = [...state.agencies];
            // Add one agency to the agencies list
            agencies.push(action.agency);
            newState.agencies = agencies;
            break;
        }

        case GET_AGENCIES_FAILURE: {
            newState.requestingAgencies = false;
            break;
        }

        case UPDATE_USER_INFO_REQUEST: {
            newState.UPDATE_USER_INFO_REQUEST_PENDING = true;
            newState.UPDATE_USER_INFO_SUCCESS = false;
            newState.UPDATE_USER_INFO_FAIL = false;
            newState.validationErrors = null;
            newState.mfaError = false;
            break;
        }
        case UPDATE_USER_INFO_SUCCESS: {
            newState.userInfo = action.result.data.user;
            newState.UPDATE_USER_INFO_REQUEST_PENDING = false;
            newState.UPDATE_USER_INFO_SUCCESS = true;
            newState.UPDATE_USER_INFO_FAIL = false;
            newState.validationErrors = null;
            newState.mfaError = false;
            break;
        }
        case UPDATE_USER_INFO_FAIL: {
            newState.UPDATE_USER_INFO_REQUEST_PENDING = false;
            newState.UPDATE_USER_INFO_SUCCESS = false;
            newState.UPDATE_USER_INFO_FAIL = true;
            const { status, data } = action.error.response;
            if (status === 400) {
                newState.validationErrors = data.errors;
            }
            if ([MFA_AUTHENTICATION_FAILED, MFA_CODE_EXPIRE_MESSAGE, VERIFICATION_CODE_REQUIRED_MESSAGE].includes(data.error)) {
                newState.mfaError = data.error;
            }
            break;
        }

        case UPDATE_TEAM_MEMBER_INFO_REQUEST: {
            newState.UPDATE_TEAM_MEMBER_INFO_REQUEST = true;
            newState.UPDATE_TEAM_MEMBER_INFO_SUCCESS = false;
            newState.UPDATE_TEAM_MEMBER_INFO_FAIL = false;
            break;
        }
        case UPDATE_TEAM_MEMBER_INFO_SUCCESS: {
            return produce(state, draftState => {
                draftState.UPDATE_TEAM_MEMBER_INFO_REQUEST = false;
                draftState.UPDATE_TEAM_MEMBER_INFO_SUCCESS = true;
                draftState.UPDATE_TEAM_MEMBER_INFO_FAIL = false;
            });
        }

        case UPDATE_TEAM_MEMBER_VIEWING: {
            return produce(state, draftState => {
                draftState.userInfo.savedTeamMemberSelection = action.teamMembers;
            });
        }

        case UPDATE_TEAM_MEMBER_INFO_FAIL: {
            newState.UPDATE_TEAM_MEMBER_INFO_REQUEST = false;
            newState.UPDATE_TEAM_MEMBER_INFO_SUCCESS = false;
            newState.UPDATE_TEAM_MEMBER_INFO_FAIL = true;
            break;
        }
        case ADD_PROFILE_PICTURE_REQUEST: {
            newState.ADD_PROFILE_PICTURE_REQUEST_PENDING = true;
            newState.ADD_PROFILE_PICTURE_SUCCESS = false;
            newState.ADD_PROFILE_PICTURE_FAIL = false;
            break;
        }

        case ADD_PROFILE_PICTURE_FAIL: {
            newState.ADD_PROFILE_PICTURE_REQUEST_PENDING = false;
            newState.ADD_PROFILE_PICTURE_SUCCESS = false;
            newState.ADD_PROFILE_PICTURE_FAIL = true;
            break;
        }

        case ADD_PROFILE_PICTURE_SUCCESS: {
            newState.userInfo.image = action.result.data.image;
            newState.ADD_PROFILE_PICTURE_REQUEST_PENDING = false;
            newState.ADD_PROFILE_PICTURE_SUCCESS = true;
            newState.ADD_PROFILE_PICTURE_FAIL = false;
            break;
        }

        case UPDATE_LOGIN_DATA_SUCCESS: {
            newState.userInfo = action.payload.newUserData;
            break;
        }
        case SET_TARIFF_PLAN_SUCCESS: {
            newState.agency = action.result.data.agency;
            break;
        }
        case CANCEL_TARIFF_PLAN_SUCCESS: {
            newState.agency = { ...state.agency, tariffPlan: { ...state.agency.tariffPlan, active: false } };
            break;
        }

        case DELETE_ACCOUNT_INTEGRATION_SUCCESS: {
            if (newState.agency) {
                const newIntegrations = omit(newState.agency.integrations, [camelCase(action.integrationType)]);

                newState.agency = Object.assign({}, newState.agency, {
                    integrations: newIntegrations
                });
            }

            break;
        }

        case SYNC_ACCOUNT_INTEGRATION_REQUEST:
            newState.syncIntegrations = true;
            break;

        case SYNC_ACCOUNT_INTEGRATION_FAIL:
            newState.syncIntegrations = new Error('Failed to sync.');
            break;

        case SYNC_ACCOUNT_INTEGRATION_SUCCESS:
            newState.syncIntegrations = null;
            break;
        case CHANGE_AGENCY_DISABLED_GLOBAL_TEMPLATES:
            newState.agency.disabledGlobalTemplates = action.payload.disabledGlobalTemplates;
            break;

        case GET_CONNECTION_CODE_REQUIRED.SUCCESS:
            newState.agentCode = {
                required: action.result.data.agentCodeRequired,
                label: action.result.data.agentCodeLabel,
                helpText: action.result.data.agentCodeHelpText
            };
            break;
        case GET_CONNECTION_CODE_REQUIRED.FAIL:
            newState.agentCode = {
                required: false
            };
            break;
        case UPDATE_INSPECTION_MANAGER_INTEGRATION.REQUEST: {
            return produce(state, draftState => {
                if (!draftState.agency.integrations) {
                    draftState.agency.integrations = {};
                }
                if (!draftState.agency.integrations.inspectionManager) {
                    draftState.agency.integrations.inspectionManager = {
                        ...(action?.initialData || {})
                    };
                }
                // Overwrite inspectionManager so form will not be blank.
                draftState.agency.integrations.inspectionManager = action.data;
                draftState.agency.integrations.inspectionManager.hasError = false;
                if (action.disconnecting) {
                    draftState.agency.integrations.inspectionManager.disconnecting = true;
                } else {
                    draftState.agency.integrations.inspectionManager.loading = true;
                }
            });
        }
        case UPDATE_INSPECTION_MANAGER_INTEGRATION.SUCCESS: {
            return produce(state, draftState => {
                let data = action.result?.data?.agency?.integrations?.inspectionManager;
                data = data || { apiKey: '', shouldRetrieveConditionReport: false };
                draftState.agency.integrations.inspectionManager = data;
            });
        }
        case UPDATE_INSPECTION_MANAGER_INTEGRATION.FAIL: {
            return produce(state, draftState => {
                draftState.agency.integrations.inspectionManager.hasError = true;
                draftState.agency.integrations.inspectionManager.loading = false;
                draftState.agency.integrations.inspectionManager.disconnecting = false;
            });
        }
    }

    return newState;
}
