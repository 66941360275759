import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import { isEqual, isEmpty, map, has, pullAt, indexOf, capitalize, cloneDeep } from 'lodash';

import Button from '../../../common/components/Button';
import ModalSignatureRequest from '../../../components/modals/ModalSignatureRequest';
import ModalSignatureDeleteRequest from '../../../components/modals/ModalSignatureDeleteRequest';

import '../../../sass/signatures.scss';
import '../../../sass/tables.scss';
import EditButton from '../../../common/components/EditButton';
import DeleteButton from '../../../common/components/DeleteButton';
import { useEffect, useState } from 'react';
import { getUserInfo } from '../../../selectors/user';
import { getSignatureList, requestSignatureUpdateURL } from './../../../actions/signatures';
import { getSignatures } from '../../../selectors/signatures';

const modalType = {
    success: 'success',
    fail: 'fail',
    edit: 'edit',
    delete: 'delete'
};

const Signatures = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [mySignatures, setMySignatures] = useState([]);
    const [teamSignatures, setTeamSignatures] = useState([]);

    const dispatch = useDispatch();

    const signatureState = useSelector(getSignatures);
    const loggedInUser = useSelector(getUserInfo);

    useEffect(() => {
        dispatch(getSignatureList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showTeamSignatures = () => {
        return indexOf(['agency-principal', 'admin'], loggedInUser.role) !== -1;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterSignatures = signaturesList => {
        const clonedSignatureList = cloneDeep(signaturesList);

        let mySignatures = [];
        let teamSignatures = [];
        if (isEmpty(clonedSignatureList)) {
            setMySignatures(mySignatures);
            setTeamSignatures(teamSignatures);
            return;
        }

        // Find the signature of the logged in person
        map(clonedSignatureList, (value, key) => {
            if (has(value, 'createdBy.id') && value.createdBy.id === loggedInUser.id) {
                mySignatures = pullAt(clonedSignatureList, key);
                return false;
            }
        });

        if (showTeamSignatures()) {
            teamSignatures = clonedSignatureList;
        }
        setMySignatures(mySignatures);
        setTeamSignatures(teamSignatures);
    };

    useEffect(() => {
        filterSignatures(signatureState.signaturesList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureState.signaturesList]);

    const openModal = type => {
        if (type === modalType.success) {
            setIsModalOpen(true);
            setIsEdit(false);
        } else if (type === modalType.edit) {
            setIsModalOpen(true);
            setIsEdit(true);
        }
    };

    const openDeleteSignature = signatureId => {
        setIsDeleteModalOpen(true);
        setDeleteId(signatureId);
    };

    const closeModal = () => {
        dispatch(requestSignatureUpdateURL());
        dispatch(getSignatureList());
        signatureState.requestToAddSignatureFailed = false;
        signatureState.requestToAddSignatureSent = false;
        signatureState.isSendingRequestToAddSignature = false;
        setIsModalOpen(false);
    };

    const closeDeleteModal = () => {
        dispatch(requestSignatureUpdateURL());
        dispatch(getSignatureList());
        setIsDeleteModalOpen(false);
        setDeleteId('');
    };

    const renderSignatureRow = (signature, canEdit = true) => {
        return (
            <Tr key={`agent-${signature.id}`}>
                <Td column="signature" className="signature-thumb">
                    <img src={signature.base64SignatureImage} />
                </Td>
                <Td column="name" className="name">
                    {`${signature.firstName} ${signature.secondName}`}
                </Td>
                <Td column="role">{capitalize(signature.type)}</Td>
                <Td column="actions" className="actions">
                    <div className="flex">
                        {canEdit && (
                            <EditButton
                                className="edit-signature-btn"
                                onClick={() => openModal(modalType.edit)}
                                toolTip="Update signature"
                            />
                        )}
                        <DeleteButton
                            className="delete-signature-btn"
                            toolTip="Delete signature"
                            onClick={() => openDeleteSignature(signature)}
                        />
                    </div>
                </Td>
            </Tr>
        );
    };

    return (
        <div className="settings">
            {isEmpty(mySignatures) && (
                <div className="add-signature-block">
                    <p>You don’t have any signatures yet</p>
                    <Button className="add-signature-btn" primary onClick={() => openModal(modalType.success)}>
                        Add signature
                    </Button>
                </div>
            )}
            {!isEmpty(mySignatures) && (
                <div className="table-sort table table-signatures table-default">
                    <div className="table-head">
                        <div className="title">My Signatures</div>
                    </div>
                    <Table>
                        <Thead>
                            <Th column="signature">&nbsp;</Th>
                            <Th column="name">name</Th>
                            <Th column="role">role</Th>
                            <Th column="actions">Actions</Th>
                        </Thead>
                        {map(mySignatures, (signature, index) => {
                            return renderSignatureRow(signature);
                        })}
                    </Table>
                </div>
            )}
            {showTeamSignatures() && !isEmpty(teamSignatures) && (
                <div className="table-sort table table-signatures table-default">
                    <div className="table-head">
                        <div className="title">Team Signatures</div>
                    </div>
                    <Table>
                        <Thead>
                            <Th column="signature">&nbsp;</Th>
                            <Th column="name">name</Th>
                            <Th column="role">role</Th>
                            <Th column="actions">Actions</Th>
                        </Thead>
                        {map(teamSignatures, (signatures, index) => {
                            return renderSignatureRow(signatures, false);
                        })}
                    </Table>
                </div>
            )}
            <ModalSignatureRequest isEdit={isEdit} isOpen={isModalOpen} closeModal={closeModal} />
            <ModalSignatureDeleteRequest
                isOpen={isDeleteModalOpen}
                signatureId={deleteId}
                closeModal={closeDeleteModal}
            />
        </div>
    );
};

export default Signatures;
