import React, { useState } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import ModalServicesSchedule from './ModalServicesSchedule';
import { getPmStepNo } from '../../../../../../utils/agreementUtils';

export default function AgreementPmServicesSchedule({ leaseAllInfo }) {
    const { pmServicesSchedule, location } = leaseAllInfo;

    let [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    if (!pmServicesSchedule) {
        return null;
    }
    return (
        <AgreementSection
            title="Services Schedule"
            step={getPmStepNo(location, 'pmServicesSchedule')}
            leaseAllInfo={leaseAllInfo}
        >
            <span className="special-link" onClick={showModal}>
                View services schedule terms
            </span>
            <ModalServicesSchedule
                isOpen={isModalOpen}
                closeModal={closeModal}
                pmServicesSchedule={pmServicesSchedule}
            />
        </AgreementSection>
    );
}
