import React, { FC, ChangeEvent } from 'react';
import styles from './FormRadioGroupTable.module.scss';
import { FormRadio } from './FormRadio.js';
import { useField } from 'react-final-form';
import cx from 'classnames';
import { getFormError } from '../../utils/finalFormUtils';
import { DEFINE_ME } from '../../types/utilityTypes';
import { isEmpty } from 'lodash';

/**
 * We can use this component inside form to add radio buttons table.
 * ex:  <FormRadioGroupTable
            data= {{
                labelsTitle: 'Fees & Expenses',
                answers: [
                    {
                        value: 'Owner to instruct',
                        label: 'Owner to instruct'
                    },
                    {
                        value: 'Agent to pay',
                        label: 'Agent to pay'
                    },
                    {
                        value: 'Not applicable',
                        label: 'Not applicable'
                    }
                ],
                sections: [
                    {
                        name: 'ratesUtilitiesStrata',
                        label: 'Rates/Utilities/Strata',
                        options: [
                            {
                                name: 'professionalFees',
                                label: 'Professional Fees (in accordance with Item 7)'
                            },
                            {
                                name: 'marketingExpenses',
                                label: 'Marketing Expenses (in accordance with Item 8)'
                            }
                        ],
                        // Override main answer options for this section
                        answers: [
                            {
                                value: OUTGOING_OWNER_TO_INSTRUCT,
                                label: OUTGOING_OWNER_TO_INSTRUCT
                            },
                            {
                                value: OUTGOING_AGENT_TO_PAY,
                                label: OUTGOING_AGENT_TO_PAY
                            }
                        ]
                    },
                    {
                        name: 'serviceMaintenance',
                        label: 'Service/Maintenance',
                        options: [
                            {
                                name: 'maintenanceCosts',
                                label: 'Maintenance Costs'
                            },
                            {
                                name: 'councilRates',
                                label: 'Council Rates'
                            }
                        ]
                    },
                    {
                        name: 'insurance',
                        label: 'Insurance',
                        options: [
                            {
                                name: 'landlordInsurance',
                                label: 'Landlord Insurance'
                            },
                            {
                                name: 'buildingInsurance',
                                label: 'Building Insurance'
                            }
                        ]
                    }
                ]
            }}
            form={form}
        />
 */

const config = {
    subscription: {
        error: true,
        submitError: true,
        dirtySinceLastSubmit: true,
        touched: true,
        modified: true
    }
};

type Option = { label: string; name: string };
type Answer = { label: string; value: string };
type Section = {
    label: string;
    name: string;
    options: Option[];
    answers?: Answer[];
};
type Data = {
    labelsTitle: string;
    answers: Answer[];
    sections: Section[];
};
interface FormRadioGroupTableProps {
    data: Data;
    form: DEFINE_ME;
    validateOnTouch: boolean;
}

interface FormRadioGroupTableRowProps {
    answers: Answer[];
    option: Option;
    section: Section;
    form: DEFINE_ME;
    validateOnTouch: boolean;
    customizedCell: boolean;
}

const FormRadioGroupTableRow: FC<FormRadioGroupTableRowProps> = ({
    option,
    answers,
    section,
    validateOnTouch,
    form,
    customizedCell
}) => {
    const field = useField(option.name, config);
    return (
        <>
            <tr className={cx({ [styles.formError]: getFormError(field.meta, validateOnTouch) })}>
                <td>{option.label}</td>
                {answers.map((answer, answerIndex) => {
                    return (
                        <td key={answerIndex} className={cx({ [styles.customizedCell]: customizedCell })}>
                            {!isEmpty(answer) && (
                                <FormRadio
                                    name={option.name}
                                    value={answer.value}
                                    className={styles.radioWithoutLabel}
                                    id={`${section.name}.${option.name}.${answer.value}`}
                                    onClick={() => form.change(`${section.name}.all`, null)}
                                />
                            )}
                        </td>
                    );
                })}
            </tr>
            {getFormError(field.meta, validateOnTouch) && (
                <tr>
                    <td className={styles.formError} colSpan={answers.length}>
                        {getFormError(field.meta, validateOnTouch)}
                    </td>
                </tr>
            )}
        </>
    );
};

export const FormRadioGroupTable: FC<FormRadioGroupTableProps> = ({ data, form, validateOnTouch }) => {
    const clickAll = (sectionIndex: number, value: string) => {
        data.sections[sectionIndex].options.forEach(option => {
            form.change(option.name, value);
        });
    };
    return (
        <table className={styles.radioGroupTable}>
            <tr>
                <th>{data.labelsTitle}</th>
                {data.answers.map((answer, index) => (
                    <th key={index}>{answer.label}</th>
                ))}
            </tr>
            {data.sections.map((section, index) => {
                const answers = section.answers || data.answers;
                const customizedCell = !!section.answers;
                return (
                    <React.Fragment key={index}>
                        <tr className={styles.sectionHeader}>
                            <td>
                                <b>{section.label}</b>
                            </td>
                            {answers.map((answer, answerIndex) => (
                                <td key={answerIndex} className={cx({ [styles.customizedCell]: customizedCell })}>
                                    {!isEmpty(answer) && (
                                        <div className={styles.radioGroup}>
                                            <FormRadio
                                                name={`${section.name}.all`}
                                                value={answer.value}
                                                id={`${section.name}.all.${answer.value}`}
                                                className={styles.radioButton}
                                                onClick={(e: ChangeEvent<HTMLInputElement>) =>
                                                    clickAll(index, e.target.value)
                                                }
                                            />
                                            <label className={styles.label}>All</label>
                                        </div>
                                    )}
                                </td>
                            ))}
                        </tr>
                        {section.options.map((option, optionIndex) => (
                            <FormRadioGroupTableRow
                                key={optionIndex}
                                option={option}
                                answers={answers}
                                form={form}
                                section={section}
                                validateOnTouch={validateOnTouch}
                                customizedCell={customizedCell}
                            />
                        ))}
                    </React.Fragment>
                );
            })}
        </table>
    );
};
