import flatten from 'lodash/flatten';
import values from 'lodash/values';
import size from 'lodash/size';
import { createSelector } from 'reselect';
import { getDashboard } from '.';

export const getDocumentList = createSelector([getDashboard], dashboard => dashboard.documentList);

export const getDocPageNav = createSelector([getDashboard], dashboard => dashboard.docPageNav);

export const getHasDocuments = createSelector([getDocumentList], documents => {
    return documents ? size(flatten(values(documents))) > 0 : false;
});

export const getIsPending = createSelector([getDashboard], ({ isPending }) => isPending);

export const getHasInitialDocumentListRequested = createSelector(
    [getDashboard],
    ({ initialDocumentListRequested }) => initialDocumentListRequested
);

export const getRefreshPage = createSelector([getDashboard], dashboard => dashboard.refreshPage);
export const getSocketUpdateDetails = createSelector([getDashboard], dashboard => dashboard.socketUpdateDetails);
