import React from 'react';

const qldIcon = (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
        width="150"
        height="150"
    >
        <defs>
            <path
                d="M482.18 558.65C470.76 555.67 469.13 559.75 466.65 565.47C464.2 571.19 461.75 567.13 452.77 565.47C443.78 563.83 459.3 570.39 443.78 563.83C428.26 557.31 432.36 557 419.27 556.33C406.18 555.69 401.29 556.33 401.29 556.33C401.07 556.54 400.01 557.6 398.1 559.51L210.93 559.51L210.93 465.79L164.37 465.79C164.37 319.11 164.37 237.62 164.37 221.32C167.97 224.87 172.68 228.09 179.49 230.07C199.09 235.79 203.59 265.21 222.79 261.95C241.99 258.67 254.63 216.17 255.45 185.15C256.29 154.09 259.56 144.28 266.89 139.79C274.23 135.29 261.99 104.23 273.83 101.8C285.69 99.35 287.32 59.74 294.25 69.55C301.21 79.34 293.43 102.62 299.97 105.48C306.5 108.36 309.77 107.13 306.5 121.42C303.24 135.72 324.89 142.25 320.39 156.95C315.91 171.67 308.13 176.97 317.94 182.28C327.73 187.61 328.15 171.67 336.74 184.75C345.3 197.8 360.02 196.17 351.83 210.47C343.67 224.78 353.49 237.83 359.61 245.21C365.72 252.55 355.94 265.63 355.94 281.98C355.94 298.33 373.93 290.54 378.81 310.15C383.69 329.77 410.26 331.39 415.57 341.21C420.89 351 417.62 352.63 420.89 365.72C424.15 378.79 427.83 397.59 438.45 396.36C449.08 395.15 458.07 387.37 457.24 411.05C456.42 434.76 462.95 442.11 474.41 451.08C485.83 460.07 483.38 464.57 488.71 479.69C494.02 494.81 498.92 493.59 494.42 514.01C492.04 524.79 495.48 535.67 498.23 546.51C497.7 546.68 495.08 547.56 490.36 549.15C492.53 557.45 489.81 560.62 482.18 558.65Z"
                id="c5ZZrizEZ"
            ></path>
        </defs>
        <g>
            <g>
                <g>
                    <use xlinkHref="#c5ZZrizEZ" opacity="1" fill="#f3f5fa" fillOpacity="1"></use>
                    <g>
                        <use
                            xlinkHref="#c5ZZrizEZ"
                            opacity="1"
                            fillOpacity="0"
                            stroke="#427bff"
                            strokeWidth="10"
                            strokeOpacity="1"
                        ></use>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const nswIcon = (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640"
        width="150"
        height="150"
    >
        <defs>
            <path
                d="M521.15 245.39C521.15 270.32 519.15 246.08 492.87 278.39C466.6 310.73 442.33 333.63 439.64 355.86C436.95 378.1 422.81 385.51 410.68 411.1C398.57 436.66 391.18 454.86 390.5 469C390.5 469 368.92 484.67 356.31 474.07C343.7 463.46 354.81 468.53 331.07 456.37C307.33 444.26 321.97 438.69 326 432.15C330.04 425.58 324.5 415.95 302.26 409.91C280.02 403.85 264.86 408.41 250.22 402.82C235.58 397.28 245.68 381.08 226.47 390.18C207.3 399.28 201.23 398.25 190.1 381.08C178.99 363.91 169.89 367.95 170.31 349.8C170.76 331.6 153.75 335.63 143.65 337.64C133.52 339.67 125.44 312.39 125.44 312.39C122.68 312.39 108.88 312.39 84.03 312.39L84.03 110.28L392.61 110.28C395.76 107.13 397.51 105.38 397.86 105.03C397.86 105.03 405.93 103.97 427.51 105.03C449.08 106.14 442.31 106.66 467.92 117.43C493.53 128.24 467.92 117.43 482.74 120.12C497.54 122.86 501.58 129.59 505.61 120.12C509.68 110.7 512.39 103.97 531.2 108.88C550.06 113.76 544.68 93.21 544.68 93.21C545.98 92.78 556.39 89.29 557.69 88.86C561.77 104.84 564.94 120.72 559.53 136.31C548.11 169.31 521.15 220.51 521.15 245.39Z"
                id="a8TVSZCPH"
            ></path>
        </defs>
        <g>
            <g>
                <g>
                    <use xlinkHref="#a8TVSZCPH" opacity="1" fill="#f3f5fa" fillOpacity="1"></use>
                    <g>
                        <use
                            xlinkHref="#a8TVSZCPH"
                            opacity="1"
                            fillOpacity="0"
                            stroke="#427bff"
                            strokeWidth="10"
                            strokeOpacity="1"
                        ></use>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const uploadIcon = (
    <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="#427CFF"
            stroke="#427CFF"
            d="M39.7878 31.1364C39.6234 30.9545 39.375 30.9545 39.2106 31.1364L28.1419 43.3844C28.0087 43.5335 27.9583 43.7765 28.0384 43.9917C28.1158 44.1998 28.2828 44.3003 28.43 44.3003H34.6416V57.25C34.6416 58.2588 35.3731 59 36.1792 59H42.8214C43.6275 59 44.359 58.2588 44.359 57.25V44.3003H50.5706C50.7142 44.3003 50.8803 44.2032 50.9611 43.9889C51.0416 43.7752 50.9923 43.5347 50.8581 43.3862L39.7878 31.1364ZM39.5003 32.2329L49.457 43.2504H43.9284C43.7329 43.2504 43.4979 43.4408 43.4979 43.7753V57.9501H35.5027V43.7753C35.5027 43.4408 35.2676 43.2504 35.0722 43.2504H29.5436L39.5003 32.2329Z"
        />
        <path
            fill="#427CFF"
            stroke="#427CFF"
            d="M38.75 16.5C31.6331 16.5 25.4742 21.6118 24.0933 28.521L24.0184 28.8958L23.6371 28.9219C18.5419 29.2698 14.5 33.5349 14.5 38.7301C14.5 43.7446 18.2494 47.9429 23.2181 48.4965C23.2439 48.4991 23.2636 48.5 23.2833 48.5C23.5488 48.5 23.7795 48.2986 23.8093 48.0262L23.8095 48.0244C23.8423 47.7334 23.6321 47.4697 23.3404 47.4366L23.3968 46.9398L23.3415 47.4368C18.9047 46.9436 15.5625 43.1994 15.5625 38.7301C15.5625 33.89 19.4921 29.9646 24.4775 29.9646C24.7404 29.9646 24.966 29.7692 25.0034 29.5058C25.9624 22.6984 31.8703 17.5664 38.75 17.5664C44.1268 17.5664 49.06 20.7057 51.3245 25.5648C51.408 25.7439 51.5812 25.8613 51.777 25.8719C57.4769 26.1884 61.9375 30.931 61.9375 36.6637C61.9375 41.498 58.7087 45.776 54.078 47.06L54.077 47.0603C53.7968 47.1374 53.6294 47.4305 53.7085 47.7153L53.7089 47.7169C53.7862 47.9992 54.0754 48.166 54.3627 48.088C59.4442 46.677 63 41.9786 63 36.6637C63 30.5587 58.3973 25.4819 52.4196 24.8537L52.1509 24.8254L52.0273 24.5853C49.4895 19.656 44.3397 16.5 38.75 16.5Z"
        />
    </svg>
);

const documentIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30" fill="none">
        <path
            fill="#427CFF"
            d="M6.33268 20.6663H17.666V23.4997H6.33268V20.6663ZM6.33268 14.9997H17.666V17.833H6.33268V14.9997ZM14.8327 0.833008H3.49935C1.94102 0.833008 0.666016 2.10801 0.666016 3.66634V26.333C0.666016 27.8913 1.92685 29.1663 3.48518 29.1663H20.4993C22.0577 29.1663 23.3327 27.8913 23.3327 26.333V9.33301L14.8327 0.833008ZM20.4993 26.333H3.49935V3.66634H13.416V10.7497H20.4993V26.333Z"
        />
    </svg>
);

const checkCircleIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
        <path
            d="M16.6549 2.4873C8.83495 2.4873 2.48828 8.83397 2.48828 16.654C2.48828 24.474 8.83495 30.8206 16.6549 30.8206C24.4749 30.8206 30.8216 24.474 30.8216 16.654C30.8216 8.83397 24.4749 2.4873 16.6549 2.4873Z"
            fill="#78D953"
        />
        <path
            d="M9.45249 18.0231L13.5198 22.0609C13.9617 22.4995 14.6868 22.4995 15.1173 22.0609L23.7165 13.5242C24.1584 13.0856 24.1584 12.377 23.7165 11.9384C23.2747 11.4997 22.5609 11.4997 22.119 11.9384L14.3242 19.6765L11.05 16.4373C10.6081 15.9986 9.89434 15.9986 9.45249 16.4373C9.01063 16.8759 9.01063 17.5845 9.45249 18.0231Z"
            fill="white"
        />
    </svg>
);

const removeCircleIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <path
            d="M14.5 0C6.49177 0 0 6.49177 0 14.5C0 22.5082 6.49177 29 14.5 29C22.5082 29 29 22.5082 29 14.5C29 6.49177 22.5082 0 14.5 0Z"
            fill="#F65F7E"
        />
        <path
            d="M13.1705 14.5007L8.65267 9.98237C8.5284 9.85799 8.45983 9.69206 8.45963 9.51512C8.45963 9.33808 8.5282 9.17195 8.65267 9.04777L9.04857 8.65194C9.17304 8.52726 9.33897 8.45898 9.51609 8.45898C9.69292 8.45898 9.85885 8.52726 9.98331 8.65194L14.5011 13.1699L19.0191 8.65194C19.1434 8.52726 19.3094 8.45898 19.4864 8.45898C19.6632 8.45898 19.8293 8.52726 19.9535 8.65194L20.3496 9.04777C20.6074 9.30556 20.6074 9.72487 20.3496 9.98237L15.8317 14.5007L20.3496 19.0187C20.474 19.1433 20.5425 19.3092 20.5425 19.4862C20.5425 19.6631 20.474 19.8291 20.3496 19.9535L19.9536 20.3494C19.8294 20.4739 19.6632 20.5423 19.4865 20.5423C19.3095 20.5423 19.1435 20.4739 19.0192 20.3494L14.5012 15.8313L9.98341 20.3494C9.85894 20.4739 9.69302 20.5423 9.51619 20.5423H9.51599C9.33907 20.5423 9.17314 20.4739 9.04867 20.3494L8.65277 19.9535C8.5285 19.8292 8.45993 19.6631 8.45993 19.4862C8.45993 19.3092 8.5285 19.1433 8.65277 19.0188L13.1705 14.5007Z"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
        />
    </svg>
);

export { qldIcon, nswIcon, uploadIcon, documentIcon, checkCircleIcon, removeCircleIcon };
