import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getSalesInclusions } from '../../../selectors/lease/salesLease';

import { salesOccupation } from '../../../config';
import { FormTextMultiline, FormTextRegular } from '../../../components/form/FormText';
import { SelectField } from '../../../components/form/FormSelect';

const SALES_INCLUTIONS_FORM = 'salesInclusionsForm';

const Inclusions = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxSalesInclusions = useSelector(getSalesInclusions);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [salesInclusions, setSalesInclusions] = useState(reduxSalesInclusions || {});

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(SALES_INCLUTIONS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateSalesInclusions(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SALES_INCLUSIONS_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.inclusions')) {
                    return error.response.data.errors.inclusions;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateSalesInclusions = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/inclusions`, data);
    };
    return (
        <div className="form-container">
            <Form onSubmit={submitForm} initialValues={salesInclusions}>
                {({ handleSubmit, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={SALES_INCLUTIONS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormTextMultiline name="description" label="Description of property for sale" required />
                            <SelectField name="occupationType" options={salesOccupation} />
                            <FormTextMultiline
                                name="termsAndConds"
                                label="Other Terms and Conditions of Sale known to Licensee"
                                required
                            />
                            <p>Fittings and Fixtures included in sales:</p>
                            <FormTextRegular name="inclusions" label="List here all inclusions" required />
                            <p>Fittings and Fixtures excluded in sales:</p>
                            <FormTextRegular name="exclusions" label="List here all exclusions" required />
                            <FormTextMultiline
                                name="easementsEtc"
                                label="Details of any Covenants, Easements, Defects, Local Government Notices, or Orders affecting the Property that are known to the Licensee"
                                required
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Inclusions);
