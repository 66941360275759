import React from 'react';
import { YES_LABEL } from '../../../../../config';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils';
import { LeaseAllInfo } from './types';

type Props = {
    leaseAllInfo: LeaseAllInfo,
}

export default function AgreementClPremises({ leaseAllInfo }: Props) {
    const part = 'premises';
    if (!leaseAllInfo?.premises) {
        return <></>;
    }
    const { location, premises } = leaseAllInfo;
    return (
        <div>
            <AgreementSection
                title={getClStepTitle(location, part)}
                step={getClStepNo(location, part)}
                leaseAllInfo={leaseAllInfo}
            >
                {premises && (
                    <div className="data data-row">
                        <div className="data-string">
                            <span className="data-contact">Premises (the Premises) known as:</span>
                            <p className="text">{premises.premises}</p>
                        </div>
                        {premises.anyAttachedInventory === YES_LABEL && (
                            <div className="data-string">
                                <span className="data-contact">Attachments belongs here:</span>
                                <p className="text">Documents</p>
                            </div>
                        )}
                    </div>
                )}
            </AgreementSection>
        </div>
    );
}
