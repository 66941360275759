import React, { Component } from 'react';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import SalesAgreementSignatures from '../../../../../containers/dashboard/SalesAgreementSignatures';
import AgreementResidentialTimeExpires from '../residential/AgreementResidentialTimeExpires';
import AgreementSalesSignatory from '../sales/AgreementSalesSignatory';
import AgreementSalesLandlordsSolicitor from '../sales/AgreementSalesLandlordsSolicitor';
import AgreementSalesLicensee from '../sales/AgreementSalesLicensee';
import AgreementSalesFixturesFittings from '../sales/AgreementSalesFixturesFittings';
import AgreementSalesPriceFee from '../sales/AgreementSalesPriceFee';
import AgreementSalesMarketing from '../sales/AgreementSalesMarketing';
import AgreementSalesTerm from '../sales/AgreementSalesTerm';
import AgreementSalesInspectionDays from '../sales/AgreementSalesInspectionDays';
import AgreementSalesAdditionalTerms from '../sales/AgreementSalesAdditionalTerms';
import AgreementSalesRebates from '../sales/AgreementSalesRebates';
import AgreementSalesManagingAgent from './QLD/AgreementSalesManagingAgent';
import AgreementSalesPropertyDetails from './QLD/AgreementSalesPropertyDetails';
import AgreementSalesPropertyDescription from './QLD/AgreementSalesPropertyDescription';
import AgreementSalesAgreementTerm from './QLD/AgreementSalesAgreementTerm';
import AgreementSalesPrice from './QLD/AgreementSalesPrice';
import AgreementAdvertisingAndMarketing from './QLD/AgreementAdvertisingAndMarketing';
import AgreementSalesMaterialFacts from './QLD/AgreementSalesMaterialFacts';
import AgreementSalesQldClientInstructions from './QLD/AgreementSalesClientInstructions';

import AgreementMainAuditTrail from '../shared/AgreementMainAuditTrail';
import AgreementMainNotes from '../shared/AgreementMainNotes';
import AgreementMainDocuments from '../shared/AgreementMainDocuments';
import AgreementSignorLinks from '../shared/AgreementSignorLinks';

import '../../../../../sass/infoAgreement.scss';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../../actions/dashboard';
import AgreementSalesClientInstructions from './AgreementSalesClientInstructions';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../../../../../utils/agreementUtils';

/**
 * Common component to render sales agreement info.
 */
export default function AgreementSalesCommon({ leaseAllInfo }) {
    const { location } = leaseAllInfo;
    return (
        <div>
            {isLeaseLocationNSW(location) && renderNSWComponents()}
            {isLeaseLocationQLD(location) && renderQLDComponents()}
        </div>
    );

    function renderNSWComponents() {
        return (
            <>
                {![LEASE_STATUS_DRAFT, LEASE_STATUS_SENT_SIGNING].includes(leaseAllInfo.status) && (
                    <>
                        <AgreementResidentialTimeExpires leaseAllInfo={leaseAllInfo} />
                        <AgreementSalesClientInstructions leaseAllInfo={leaseAllInfo} />
                    </>
                )}
                {[LEASE_STATUS_SENT_SIGNING, LEASE_STATUS_AWAITING_COMPLETION].includes(leaseAllInfo.status) && (
                    <SalesAgreementSignatures location={leaseAllInfo.location} />
                )}
                <AgreementSalesSignatory leaseAllInfo={leaseAllInfo} />
                <AgreementSalesLandlordsSolicitor leaseAllInfo={leaseAllInfo} />
                <AgreementSalesLicensee leaseAllInfo={leaseAllInfo} />
                <AgreementSalesFixturesFittings leaseAllInfo={leaseAllInfo} />
                <AgreementSalesPriceFee leaseAllInfo={leaseAllInfo} />
                {isLeaseLocationNSW(leaseAllInfo.location) && <AgreementSalesTerm leaseAllInfo={leaseAllInfo} />}
                <AgreementSalesMarketing leaseAllInfo={leaseAllInfo} />
                <AgreementSalesInspectionDays leaseAllInfo={leaseAllInfo} />
                <AgreementSalesAdditionalTerms leaseAllInfo={leaseAllInfo} />
                <AgreementMainDocuments documents={leaseAllInfo.documents} leaseAllInfo={leaseAllInfo} />
                <AgreementSalesRebates leaseAllInfo={leaseAllInfo} />
                {leaseAllInfo?.auditTrail?.length > 0 && (
                    <AgreementMainAuditTrail auditTrail={leaseAllInfo.auditTrail} showText={true} />
                )}
                {leaseAllInfo?.notes?.length > 0 && (
                    <AgreementMainNotes notes={leaseAllInfo.notes} id={leaseAllInfo.leaseId} />
                )}
                {leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING && leaseAllInfo.isManuallyShareLinks && (
                    <AgreementSignorLinks leaseAllInfo={leaseAllInfo} />
                )}
            </>
        );
    }

    function renderQLDComponents() {
        return (
            <>
                {![LEASE_STATUS_DRAFT, LEASE_STATUS_SENT_SIGNING].includes(leaseAllInfo.status) && (
                    <>
                        <AgreementResidentialTimeExpires leaseAllInfo={leaseAllInfo} />
                        <AgreementSalesQldClientInstructions leaseAllInfo={leaseAllInfo} />
                    </>
                )}
                {[LEASE_STATUS_SENT_SIGNING, LEASE_STATUS_AWAITING_COMPLETION].includes(leaseAllInfo.status) && (
                    <SalesAgreementSignatures location={leaseAllInfo.location} />
                )}
                <AgreementSalesSignatory leaseAllInfo={leaseAllInfo} />
                <AgreementSalesLandlordsSolicitor leaseAllInfo={leaseAllInfo} />
                <AgreementSalesLicensee leaseAllInfo={leaseAllInfo} />
                <AgreementSalesManagingAgent leaseAllInfo={leaseAllInfo} />
                <AgreementSalesPropertyDetails leaseAllInfo={leaseAllInfo} />
                <AgreementSalesPropertyDescription leaseAllInfo={leaseAllInfo} />
                <AgreementSalesAgreementTerm leaseAllInfo={leaseAllInfo} />
                <AgreementSalesPrice leaseAllInfo={leaseAllInfo} />
                <AgreementAdvertisingAndMarketing leaseAllInfo={leaseAllInfo} />
                <AgreementSalesRebates leaseAllInfo={leaseAllInfo} />
                <AgreementSalesMaterialFacts leaseAllInfo={leaseAllInfo} />
                <AgreementSalesAdditionalTerms leaseAllInfo={leaseAllInfo} />
                {leaseAllInfo?.auditTrail?.length > 0 && (
                    <AgreementMainAuditTrail auditTrail={leaseAllInfo.auditTrail} showText={true} />
                )}
                {leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING && leaseAllInfo.isManuallyShareLinks && (
                    <AgreementSignorLinks leaseAllInfo={leaseAllInfo} />
                )}
            </>
        );
    }
}
