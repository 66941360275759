import React, { memo, useImperativeHandle, useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getLeaseType, getStep, getInsurance } from '../../../../selectors/lease';
import * as Lease from '../../../../reducers/lease';
import axios from 'axios';
import { has } from 'lodash';
const INSURANCE_FORM = 'insuranceForm';

const Insurance = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const leaseType = useSelector(getLeaseType);
    const reduxInsurance = useSelector(getInsurance);

    let [formState, setFormState] = useState(reduxInsurance);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(INSURANCE_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateInsurance = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/insurance`, data);
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateInsurance(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_INSURANCE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.insurance')) {
                    return error.response.data.errors.insurance;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="insurance">
            <Form onSubmit={submitForm} initialValues={formState}>
                {({ handleSubmit, form, values }) => {
                    return (
                        <form id={INSURANCE_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="isLandlordResponsible"
                                label="The Landlord has responsibility for insurance of the building and premises"
                            />
                            <CheckboxCheck
                                className="wrap"
                                name="isTenantResponsible"
                                label="The Tenant has responsibility for insurance of contents of the premises (for property other than that of the Landlord)"
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Insurance);
