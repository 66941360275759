import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import Layout from '../Layout.js';
import Dropdown from '../../common/components/dropdown/Dropdown';
import DropdownItem from '../../common/components/dropdown/DropdownItem';
import UpdateBanner from '../../components/banners/UpdateBanner.js';
import Button from '../../common/components/Button.js';
import Icon, { Icons } from '../../common/components/Icon';
import TemplateTabs from '../settings/templates/TemplateTabs.js';
import CreateFlkTemplateTabs from '../settings/templates/CreateFlkTemplateTabs.js';
import UploadADocTemplate from '../settings/templates/UploadADocTemplate';
import SpecialCondition from '../settings/SpecialConditions.js';
import styles from './index.module.scss';
import {
    CREATE_A_FLK_GLOBAL_TEMPLATE,
    FLK_A_PDF_GLOBAL_TEMPLATE,
    DOCUMENT_CREATE_A_FLK,
    DOCUMENT_CUSTOM,
    DOCUMENT_TEMPLATE_MODE
} from '../../config';
import { openDocumentForm } from './../../actions/document.js';
import { openAgreementTemplateForm } from './../../actions/template.js';
import * as specialConditions from './../../actions/specialConditions.js';
import { useAccountProvider } from '../../components/providers/AccountProvider.js';
import { AGENCY_ACCOUNT_TYPE, AccountType, GENERAL_ACCOUNT_TYPE } from '../../constants/constants';
type DropDownItemType = {
    key: string;
    component: React.ReactNode;
    onClick: () => void;
    className?: string;
};
type TemplateType = {
    name: string;
    key: string;
    accountType: AccountType[];
};
enum TemplateKeys {
    AGREEMENTS = 'agreements',
    SPECIAL_CONDITIONS = 'special_conditions',
    BUILD_A_DOC = 'build_a_doc',
    UPLOAD_A_DOC = 'flk_a_template'
}
const TEMPLATE_DROPDOWN_ITEMS: TemplateType[] = [
    { name: 'Agreements', key: TemplateKeys.AGREEMENTS, accountType: [AGENCY_ACCOUNT_TYPE] },
    { name: 'Special conditions', key: TemplateKeys.SPECIAL_CONDITIONS, accountType: [AGENCY_ACCOUNT_TYPE] },
    { name: 'Build a doc', key: TemplateKeys.BUILD_A_DOC, accountType: [AGENCY_ACCOUNT_TYPE, GENERAL_ACCOUNT_TYPE] },
    { name: 'Upload a doc', key: TemplateKeys.UPLOAD_A_DOC, accountType: [AGENCY_ACCOUNT_TYPE, GENERAL_ACCOUNT_TYPE] }
];
const Templates = () => {
    const dispatch = useDispatch();
    const radixDropdownPortalContainerRef = useRef(null);
    const accountType = useAccountProvider();
    const [template, setTemplate] = useState<TemplateType>(
        accountType === AGENCY_ACCOUNT_TYPE ? TEMPLATE_DROPDOWN_ITEMS[0] : TEMPLATE_DROPDOWN_ITEMS[2]
    );
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [leaseType, setLeaseType] = useState<string>('');
    const [documentType, setDocumentType] = useState<string>('');

    const dropDownItems: DropDownItemType[] = TEMPLATE_DROPDOWN_ITEMS?.map((item: TemplateType) => {
        return {
            component: (
                <DropdownItem
                    key={item.key}
                    text={item.name}
                    onClick={() => {
                        setTemplate(item);
                    }}
                />
            ),
            key: item.key,
            showItemCondition: item.accountType.includes(accountType)
        };
    });
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const addTemplateOnClickHandler = () => {
        switch (template.key) {
            case TemplateKeys.AGREEMENTS:
                dispatch(openAgreementTemplateForm(leaseType));
                break;
            case TemplateKeys.BUILD_A_DOC:
                dispatch(
                    openDocumentForm(
                        DOCUMENT_CREATE_A_FLK,
                        null,
                        DOCUMENT_TEMPLATE_MODE,
                        documentType === CREATE_A_FLK_GLOBAL_TEMPLATE
                    )
                );
                break;
            case TemplateKeys.UPLOAD_A_DOC:
                dispatch(
                    openDocumentForm(
                        DOCUMENT_CUSTOM,
                        null,
                        DOCUMENT_TEMPLATE_MODE,
                        documentType === FLK_A_PDF_GLOBAL_TEMPLATE
                    )
                );
                break;
            case TemplateKeys.SPECIAL_CONDITIONS:
                dispatch(specialConditions.openModalSpecialCondition('Create'));
                break;
        }
    };
    return (
        <Layout>
            <div className={styles.templatesWrapper}>
                <UpdateBanner />
                <div className={styles.templateHeader}>
                    <div ref={radixDropdownPortalContainerRef}>
                        <Dropdown
                            className={styles.dropdown}
                            radixDropdownPortalContainer={radixDropdownPortalContainerRef.current}
                            trigger={
                                <div className={styles.selectedTemplate} onClick={toggleDropdown}>
                                    <span>{template.name}</span>
                                    <Icon
                                        icon={Icons.CHEVRON_DOWN}
                                        className={cx(styles.icon, styles.templateDropdownChevron, {
                                            [styles.opened]: isDropdownOpen
                                        })}
                                    />
                                </div>
                            }
                            dropdownItems={dropDownItems}
                            dropdownContentClassName={styles.dropDownItem}
                        />
                    </div>
                    <Button
                        primary
                        className={styles.addTemplateButton}
                        endIcon={<Icon icon={Icons.PLUS} className={styles.icon} />}
                        onClick={addTemplateOnClickHandler}
                    >
                        Add Template
                    </Button>
                </div>
                <div className={styles.templateTable}>
                    {template.key === TemplateKeys.AGREEMENTS && <TemplateTabs onTabChange={setLeaseType} />}
                    {template.key === TemplateKeys.BUILD_A_DOC && (
                        <CreateFlkTemplateTabs onTabChange={setDocumentType} />
                    )}
                    {template.key === TemplateKeys.UPLOAD_A_DOC && <UploadADocTemplate onTabChange={setDocumentType} />}
                    {template.key === TemplateKeys.SPECIAL_CONDITIONS && <SpecialCondition />}
                </div>
            </div>
        </Layout>
    );
};
export default Templates;
