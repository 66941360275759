import { capitalize } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@app/common/components/Button.js';
import { CouponInfo, getPriceWithCoupon, Subscription } from '@app/common/components/cards/SubscriptionCard';
import Icon, { Icons } from '@app/common/components/Icon';
import { AccountType, AvailableCountries } from '@app/constants/constants';
import { getCouponInfo } from '@app/selectors/authorization.js';
import { getCountryEmoji, getCountryLabel } from '@app/utils/countryUtils';

import styles from './SelectionSummary.module.scss';

const getAccountTypeEmoji = (accountType: AccountType) => {
    switch (accountType) {
        case AccountType.AGENCY_ACCOUNT_TYPE:
            return '🏠';
        case AccountType.GENERAL_ACCOUNT_TYPE:
            return '💼';
    }
};

const getAccountTypeDisplayName = (accountType: AccountType) => {
    switch (accountType) {
        case AccountType.AGENCY_ACCOUNT_TYPE:
            return 'Real Estate';
        case AccountType.GENERAL_ACCOUNT_TYPE:
            return 'Other Business';
    }
};

type SelectionSummaryProps = {
    country: AvailableCountries;
    accountType: AccountType;
    subscription?: Subscription;
    isEditable: boolean;
    onEdit: () => void;
};

const SelectionSummary: React.FC<SelectionSummaryProps> = ({
    country,
    accountType,
    subscription,
    isEditable,
    onEdit
}) => {
    const couponInfo: CouponInfo | undefined = useSelector(getCouponInfo);
    const displayedPrice =
        couponInfo && subscription && subscription.price > 0
            ? getPriceWithCoupon(subscription.price, couponInfo)
            : subscription?.price;
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.heading}>Subscription Details</h2>
                <Button
                    className={styles.editButton}
                    onClick={onEdit}
                    tertiary
                    small
                    disabled={!isEditable}
                    startIcon={<Icon icon={Icons.EDIT} className={styles.editIcon} />}
                >
                    Edit
                </Button>
            </div>
            <div className={styles.selection}>
                <h3 className={styles.selectionTitle}>Country</h3>
                <div className={styles.selectionDetails}>
                    {getCountryEmoji(country)} <p>{getCountryLabel(country)}</p>
                </div>
            </div>
            <div className={styles.selection}>
                <h3 className={styles.selectionTitle}>Industry</h3>
                <div className={styles.selectionDetails}>
                    {getAccountTypeEmoji(accountType)} <p>{getAccountTypeDisplayName(accountType)}</p>
                </div>
            </div>
            {subscription && (
                <div className={styles.selection}>
                    <h3 className={styles.selectionTitle}>Subscription Plan</h3>
                    {capitalize(subscription.size)} - ${displayedPrice} {subscription.currency} (
                    {subscription.taxDescription})
                </div>
            )}
        </div>
    );
};

export default SelectionSummary;
