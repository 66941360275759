import { capitalize } from 'lodash';
import React from 'react';
import cx from 'classnames';

import styles from './MobileCompletionProgress.module.scss';
import Icon, { Icons } from '../../../../common/components/Icon';

type CompletionStep = {
    completed: boolean;
    error?: string;
    step: string;
    stepNumber: number;
};

type MobileCompletionProgressProps = {
    progress: CompletionStep[];
};

const getStepDisplayName = (step: string) => {
    return capitalize(step.split('_').join(' '));
};

const getStepIcon = (step: CompletionStep, isCurrentStep: boolean, hasError: boolean) => {
    if (step.completed) {
        return <Icon icon={Icons.CHECK} className={cx(styles.completedIcon, styles.icon)} />;
    }

    if (hasError) {
        return <Icon icon={Icons.ERROR} className={cx(styles.errorIcon, styles.icon)} />;
    }

    if (isCurrentStep) {
        return <div className={styles.spinner} />;
    }

    return null;
};

export const MobileCompletionProgress: React.FC<MobileCompletionProgressProps> = ({ progress }) => {
    const currentStepNumber = progress.find(step => !step.completed)?.stepNumber;

    return (
        <div className={styles.container}>
            {progress.map((step, index) => {
                const isCurrentStep = step.stepNumber === currentStepNumber;
                const hasError = !step.completed && !!step.error;

                return (
                    <div
                        key={index}
                        className={cx(styles.step, {
                            [styles.notStarted]: !step.completed && !isCurrentStep,
                            [styles.current]: isCurrentStep
                        })}
                    >
                        {getStepIcon(step, isCurrentStep, hasError)}
                        <div className={styles.name}>{getStepDisplayName(step.step)}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default MobileCompletionProgress;
