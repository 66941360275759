import React, { Component } from 'react';
import { formatCurrency, formatCurrencyWithSeparator } from '../../../../../utils/formUtils';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { COMMISSION_TYPE_PERCENTAGE, SALE_METHOD_AUCTION, SALE_METHOD_PRIVATE_TREATY } from '../../../../../config';
import { getSalesStepNo } from '../../../../../utils/agreementUtils';

class AgreementSalesPriceFee extends Component {
    render() {
        const { leaseAllInfo } = this.props;
        let salesType = SALE_METHOD_AUCTION;

        if (leaseAllInfo.priceAndFee) {
            if (leaseAllInfo.priceAndFee.saleMethod === SALE_METHOD_PRIVATE_TREATY) {
                salesType = SALE_METHOD_PRIVATE_TREATY;
            }
        }

        return (
            <div>
                {!!leaseAllInfo.priceAndFee && (
                    <AgreementSection
                        title="Price and Fee"
                        step={getSalesStepNo(leaseAllInfo.location, 'priceAndFee')}
                        leaseAllInfo={this.props.leaseAllInfo}
                    >
                        <div className="agreement-section-text">
                            <div>
                                <p className="name">
                                    Licensee’s Recommendation as to the Most Suitable Method of Sale:{' '}
                                    <strong> {salesType}</strong>
                                </p>
                            </div>
                            <div>
                                {leaseAllInfo.priceAndFee.isRange ? (
                                    <p className="name">
                                        Opinion price:{' '}
                                        <strong>${formatCurrency(leaseAllInfo.priceAndFee.opinionPriceFrom)}</strong>{' '}
                                        To: <strong>${formatCurrency(leaseAllInfo.priceAndFee.opinionPriceTo)}</strong>
                                    </p>
                                ) : (
                                    <p className="name">
                                        Price:<strong>${formatCurrency(leaseAllInfo.priceAndFee.exactPrice)}</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.priceAndFee.saleMethod === SALE_METHOD_PRIVATE_TREATY && (
                                    <p className="name">
                                        Property is to be offered at:{' '}
                                        <strong>
                                            {isNaN(leaseAllInfo.priceAndFee.startingPrice)
                                                ? leaseAllInfo.priceAndFee.startingPrice
                                                : formatCurrencyWithSeparator(
                                                      formatCurrency(leaseAllInfo.priceAndFee.startingPrice)
                                                  )}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.priceAndFee.saleMethod === SALE_METHOD_AUCTION && (
                                    <p className="name">
                                        Auction date:{' '}
                                        <strong>
                                            {leaseAllInfo.priceAndFee.auctionDateToBeConfirmed
                                                ? 'To be confirmed'
                                                : leaseAllInfo.priceAndFee.auctionDateFormatted}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.priceAndFee.saleMethod === SALE_METHOD_AUCTION && (
                                    <p className="name">
                                        Reserve price:{' '}
                                        <strong>
                                            {isNaN(leaseAllInfo.priceAndFee.startingPrice)
                                                ? leaseAllInfo.priceAndFee.startingPrice
                                                : formatCurrencyWithSeparator(
                                                      formatCurrency(leaseAllInfo.priceAndFee.startingPrice)
                                                  )}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.priceAndFee && (
                                    <p className="name">
                                        Commission:{' '}
                                        <strong>
                                            {leaseAllInfo.priceAndFee.commissionType === COMMISSION_TYPE_PERCENTAGE
                                                ? `${leaseAllInfo.priceAndFee.commissionPercent}%`
                                                : `$${leaseAllInfo.priceAndFee.commissionFee}`}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.priceAndFee && leaseAllInfo.priceAndFee.commissionComment && (
                                    <p className="name">
                                        Commission notes: <strong>{leaseAllInfo.priceAndFee.commissionComment}</strong>
                                    </p>
                                )}
                            </div>
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementSalesPriceFee;
