import * as Lease from '../../reducers/lease';
import * as Dashboard from '../../reducers/dashboard';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import { START_RENEWAL_SUCCESS } from '../../reducers/renewal';
import { INITIALIZE_NSW_LEASE_AGREEMENT_FORM, INITIALIZE_QLD_LEASE_AGREEMENT_FORM } from '../lease';
import produce from 'immer';
import { SUB_LEASE_TYPE_LONG_TERM_RENEWAL } from '../../config';
import { isLongTermRenewalSubLease } from '../../utils/agreementUtils';

const tenantDefault = {
    firstName: '',
    middleName: '',
    secondName: '',
    phone: '',
    email: '',
    extraContacts: [],
    isPrimaryTenant: false,
    ANC: '',
    postcode: '',
    address: ''
};

const nswInitState = {
    isLeaseAddress: false,
    address: '',
    tenants: [tenantDefault],
    isServicesOfNoticesAddress: false,
    isServicesOfNoticesEmail: true,
    servicesOfNoticesEmails: []
};

const qldInitState = {
    ...nswInitState,
    isServicesOfNoticesAddress: false,
    isServicesOfNoticesEmail: true
};

const vicInitState = {
    ...nswInitState,
    isServicesOfNoticesAddress: false,
    isServicesOfNoticesEmail: true
};

export default produce((draftState = nswInitState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS: {
            if (action.result.data.lease.tenant) {
                draftState = action.result.data.lease.tenant;
                // delete all the id's that came from propertyMe creation. Otherwise we will try to update the tenants in the backend, but the lease has not been saved in the backend yet
                draftState.tenants.map(tenant => {
                    if (tenant.id) {
                        delete tenant.id;
                    }
                });
                delete draftState.id;
            } else {
                const location = action.result.data.lease.location;
                if (location === 'QLD') {
                    draftState = qldInitState;
                } else if (location === 'VIC') {
                    draftState = vicInitState;
                } else {
                    draftState = nswInitState;
                }
            }
            break;
        }
        case INITIALIZE_NSW_LEASE_AGREEMENT_FORM: {
            draftState = nswInitState;
            break;
        }
        case INITIALIZE_QLD_LEASE_AGREEMENT_FORM: {
            draftState = qldInitState;
            break;
        }
        case Lease.LEASE_TENANT_SUCCESS: {
            draftState = action.result.data.lease.tenant;
            break;
        }
        case Lease.UPDATE_TENANT_SUCCESS: {
            draftState = action.result.data.lease.tenant;
            break;
        }
        case Lease.LEASE_TENANT_DETAILS_SUCCESS: {
            draftState = action.result.data.tenant;
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = action.payload.agreementInfo.tenant;
            break;
        }
        case Lease.SEND_REMOVE_OCCUPANT_SUCCESS: {
            draftState = action.result.data.lease.tenant;
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = Object.assign({}, nswInitState, action.lease.tenant);
            break;
        }
        case Dashboard.OPEN_EDIT_TENANTS_MODAL: {
            if (action.payload) {
                draftState = action.payload.agreementInfo.tenant;
            }
            break;
        }
        case START_RENEWAL_SUCCESS: {
            const response = action.result.data;
            if (response.lease && isLongTermRenewalSubLease(response.lease.subLeaseType)) {
                draftState = response.lease.tenant;
            }
            break;
        }
    }

    return draftState;
});
