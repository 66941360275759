import React from 'react';
import cx from 'classnames';
import { WebViewerInstance } from '@pdftron/webviewer';
import {
    Root as AccordionRoot,
    Item as AccordionItem,
    Trigger as AccordionTrigger,
    Content as AccordionContent
} from '@radix-ui/react-accordion';

import Client from './Client';
import Sender from './Sender';
import UploadedDocumentInfoCard from './UploadedDocumentInfoCard';
import Icon, { Icons } from '../../../common/components/Icon';
import { AccountType } from '../../../constants/constants';
import { removeFileExtension } from '../../../utils/documentUtils.js';
import { DOCUMENT_DELIVERY_TYPE_EMAIL } from '../../../config';
import {
    UploadADoc,
    Client as ClientType,
    CustomPlaceholder as CustomPlaceholderType
} from '../../../types/UploadADoc';
import { Point } from '../types';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { DOCUMENTS_DROPDOWN } from '../../../constants/featureFlags';
import Button from '../../../common/components/Button.js';
import { UploadedDocumentState } from './useUploadedDocumentState';
import CustomPlaceholderAccordionContent from './CustomPlaceholderAccordionContent';
import CustomPlaceholderModal from './CustomPlaceholderModal';
import { deleteAnnotationsForCustomPlaceholder } from '../utils';

import styles from './Sidebar.module.scss';

export enum SidebarAccordionItems {
    CLIENTS = 'clients',
    DOCUMENTS = 'documents',
    CUSTOM_PLACEHOLDERS = 'customPlaceholders'
}
type SidebarProps = {
    className?: string;
    clients: (ClientType | { name: string; email: string; phone: string })[];
    customDoc: UploadADoc;
    uploadedDocumentStateItems: UploadedDocumentState[];
    selectedUploadedDocId?: string;
    onClickDocument: (documentId: string) => void;
    agencyDetails: {
        name: string;
        imageUrl?: string;
    };
    selectedWebViewerInstance?: WebViewerInstance;
    dropPointRef: React.MutableRefObject<Point | undefined>;
    isOnboarding: boolean;
    customPlaceholders?: CustomPlaceholderType[];
    defaultOpenItems?: SidebarAccordionItems[];
    hideCustomPlaceholderTitles: boolean;
    toggleHideCustomPlaceholderTitles: () => void;
    setHoveredCustomPlaceholderId: (customPlaceholderId?: string) => void;
};

const Sidebar: React.FC<SidebarProps> = ({
    className,
    clients,
    customDoc,
    uploadedDocumentStateItems,
    selectedUploadedDocId,
    onClickDocument,
    agencyDetails,
    selectedWebViewerInstance,
    dropPointRef,
    isOnboarding,
    customPlaceholders,
    defaultOpenItems,
    hideCustomPlaceholderTitles,
    toggleHideCustomPlaceholderTitles,
    setHoveredCustomPlaceholderId
}) => {
    const isDocumentsDropdownActive = useFeatureFlag(DOCUMENTS_DROPDOWN);
    const [isCustomPlaceholdersModalOpen, setIsCustomPlaceholdersModalOpen] = React.useState(false);

    const [openItems, setOpenItems] = React.useState<SidebarAccordionItems[]>([
        SidebarAccordionItems.CLIENTS,
        SidebarAccordionItems.DOCUMENTS
    ]);

    React.useEffect(() => {
        if (defaultOpenItems) {
            setOpenItems(defaultOpenItems);
        }
    }, [defaultOpenItems]);

    return (
        <>
            <AccordionRoot
                asChild
                type="multiple"
                value={openItems}
                onValueChange={items => setOpenItems(items as SidebarAccordionItems[])}
            >
                <div className={cx(styles.sidebar, className)}>
                    <Sender
                        name={customDoc.agent?.fullName || 'Your name here'}
                        accountType={customDoc.agent?.accountType || AccountType.GENERAL_ACCOUNT_TYPE}
                        webViewerInstance={selectedWebViewerInstance}
                        agencyDetails={agencyDetails}
                        dropPointRef={dropPointRef}
                        agentName={customDoc.agent?.fullName}
                        isOnboarding={isOnboarding}
                    />
                    <AccordionItem
                        value={SidebarAccordionItems.CLIENTS}
                        className={cx(styles.accordionSection, styles.clientSection)}
                    >
                        <AccordionTrigger className={styles.sectionHeader}>
                            <hr className={styles.divider} />
                            <div className={styles.sectionHeaderContent}>
                                <div className={styles.sectionCount}>{clients.length}</div>
                                <span>Signers</span>
                                <Icon className={styles.sectionHeaderIcon} icon={Icons.CHEVRON_DOWN} />
                            </div>
                            <hr className={styles.divider} />
                        </AccordionTrigger>
                        <AccordionContent className={styles.clients}>
                            {clients?.map((client, index) => (
                                <Client
                                    key={(client as ClientType).id || client.name}
                                    details={{
                                        name: client.name,
                                        contact:
                                            customDoc.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL
                                                ? client.email
                                                : client.phone
                                    }}
                                    clientIndex={index}
                                    webViewerInstance={selectedWebViewerInstance}
                                    dropPointRef={dropPointRef}
                                />
                            ))}
                        </AccordionContent>
                    </AccordionItem>
                    {!isDocumentsDropdownActive && (
                        <>
                            <AccordionItem
                                value={SidebarAccordionItems.DOCUMENTS}
                                className={cx(styles.accordionSection, styles.documentSection)}
                            >
                                <AccordionTrigger className={styles.sectionHeader}>
                                    <hr className={styles.divider} />
                                    <div className={styles.sectionHeaderContent}>
                                        <div className={styles.sectionCount}>{customDoc.uploadedDocuments.length}</div>
                                        <span>Documents</span>
                                        <Icon className={styles.sectionHeaderIcon} icon={Icons.CHEVRON_DOWN} />
                                    </div>
                                    <hr className={styles.divider} />
                                </AccordionTrigger>
                                <AccordionContent className={styles.uploadedDocuments}>
                                    {customDoc.uploadedDocuments?.map(uploadedDocument => {
                                        const uploadedDocumentState = uploadedDocumentStateItems.find(
                                            item => item.uploadedDocumentId === uploadedDocument.id
                                        );

                                        return (
                                            <Button
                                                key={uploadedDocument.id}
                                                onClick={() => onClickDocument(uploadedDocument.id)}
                                            >
                                                <UploadedDocumentInfoCard
                                                    displayName={removeFileExtension(
                                                        uploadedDocument.document.documentName
                                                    )}
                                                    isSelected={uploadedDocument.id === selectedUploadedDocId}
                                                    clientSignatureCounts={
                                                        uploadedDocumentState?.clientAnnotationsCounts
                                                            .signatureAnnotationCounts
                                                    }
                                                    agentSignatureCount={
                                                        uploadedDocumentState?.agentSignatureAnnotationCount
                                                    }
                                                />
                                            </Button>
                                        );
                                    })}
                                </AccordionContent>
                            </AccordionItem>
                        </>
                    )}
                    <>
                        <AccordionItem
                            value={SidebarAccordionItems.CUSTOM_PLACEHOLDERS}
                            className={cx(styles.accordionSection, styles.customPlaceholderSection)}
                        >
                            <AccordionTrigger className={styles.sectionHeader}>
                                <hr className={styles.divider} />
                                <div className={styles.sectionHeaderContent}>
                                    <div className={styles.sectionCount}>{customPlaceholders?.length || 0}</div>
                                    <span>Custom info placeholders</span>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setIsCustomPlaceholdersModalOpen(true);
                                        }}
                                    >
                                        Add/Edit
                                    </Button>
                                    <Icon className={styles.sectionHeaderIcon} icon={Icons.CHEVRON_DOWN} />
                                </div>
                                <hr className={styles.divider} />
                            </AccordionTrigger>
                            <AccordionContent className={styles.customPlaceholders}>
                                <CustomPlaceholderAccordionContent
                                    customPlaceholderData={customPlaceholders}
                                    webViewerInstance={selectedWebViewerInstance}
                                    dropPointRef={dropPointRef}
                                    hideCustomPlaceholderTitles={hideCustomPlaceholderTitles}
                                    toggleHideCustomPlaceholderTitles={toggleHideCustomPlaceholderTitles}
                                    setHoveredCustomPlaceholderId={setHoveredCustomPlaceholderId}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </>
                </div>
            </AccordionRoot>
            <CustomPlaceholderModal
                isOpen={isCustomPlaceholdersModalOpen}
                close={() => setIsCustomPlaceholdersModalOpen(false)}
                deleteAnnotationsForCustomPlaceholder={customPlaceholderId =>
                    deleteAnnotationsForCustomPlaceholder(customPlaceholderId, uploadedDocumentStateItems)
                }
            />
        </>
    );
};

export default Sidebar;
