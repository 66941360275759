import produce from 'immer';

export const ADD_NEW_TOAST = 'ADD_NEW_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

const initState = {
    toastList: []
};

export default function toast(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case ADD_NEW_TOAST: {
            return produce(state, draftState => {
                draftState.toastList.push(action.toast);
            });
        }

        case REMOVE_TOAST: {
            return produce(state, draftState => {
                if (action.id) {
                    const index = draftState.toastList.findIndex(item => item.id === action.id);
                    if (index !== -1) {
                        draftState.toastList.splice(index, 1);
                    }
                }
            });
        }
    }

    return newState;
}
