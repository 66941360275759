import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalDialog from '../../common/components/ModalDialog';
import Button from '../../common/components/Button';
import '../../sass/warningMessageModal.scss';
import { closeNoSignatureModal } from '../../actions/user';
import { closeAgreementInfoModal } from '../../actions/dashboard';
import { closeAllDocumentModals } from '../../actions/document';
import appHistory from './../../AppHistory';
import { getIsNoSignatureModalOpen } from '../../selectors/signatures';
import { AGENCY_ACCOUNT_TYPE } from '../../constants/constants';
import { isAgencyUserAccount, isBusinessUserAccount } from '../../utils/userUtils';

const ModalWarningMessage = ({ titleTxt = 'WARNING', message = '', link, accountType = AGENCY_ACCOUNT_TYPE }) => {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(getIsNoSignatureModalOpen);

    const handleClickLink = () => {
        dispatch(closeAgreementInfoModal());
        dispatch(closeAllDocumentModals());
        dispatch(closeNoSignatureModal());
        appHistory.push(link);
    };

    return (
        <ModalDialog
            isOpen={modalIsOpen}
            closeModal={() => dispatch(closeNoSignatureModal())}
            className="warning-message-modal-dialog"
            contentLabel="Modal"
            onRequestClose={() => dispatch(closeNoSignatureModal())}
            title={titleTxt}
            zIndex={20}
        >
            <div className="warning-message-block">
                <div className="warning-message">{message}</div>
                <div className="link">
                    <Button primary onClick={() => handleClickLink()}>
                        {isAgencyUserAccount(accountType) && <span>Add Agent Signature</span>}
                        {isBusinessUserAccount(accountType) && <span>Add Signature</span>}
                    </Button>
                </div>
            </div>
        </ModalDialog>
    );
};

export default memo(ModalWarningMessage);
