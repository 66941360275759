import React, { useEffect } from 'react';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { SMARTLOOK } from '../../constants/featureFlags';
import { getUserInfo } from '../../selectors/user';
import { isBusinessUserAccount } from '../../utils/userUtils';
import { useSelector } from 'react-redux';

export function SmartlookProvider({ children }) {
    const user = useSelector(getUserInfo);
    const isSmartLookEnabled = useFeatureFlag(SMARTLOOK);

    useEffect(() => {
        // We only want this for FLK B for now
        if (window.smartlook || !isSmartLookEnabled || !user?.accountType || !isBusinessUserAccount(user.accountType)) {
            return;
        }

        // Everything below here is created by smartlook, please don't change this
        // https://web.developer.smartlook.com/docs/getting-started
        window.smartlook = function () {
            window.smartlook.api.push(arguments);
        };
        window.smartlook.api = [];

        var h = document.getElementsByTagName('head')[0];
        var c = document.createElement('script');
        c.async = true;
        c.type = 'text/javascript';
        c.charset = 'utf-8';
        c.src = 'https://web-sdk.smartlook.com/recorder.js';
        h.appendChild(c);

        // eslint-disable-next-line no-undef
        window.smartlook('init', SMARTLOOK_TRACKING_CODE, { region: 'eu' });
        window.smartlook('identify', user.id); // This is how we add the user id to the recording so they can be identified
    }, [isSmartLookEnabled, user]);

    return <>{children}</>;
}
