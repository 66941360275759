import React, { memo, useEffect, useState } from 'react';
import appHistory from '../AppHistory';
import { PreLoader } from '../common/components/PreLoader';
import axios from 'axios';
import { useRollbarProvider } from '../components/providers/RollbarProvider';
import { useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { settingEmailAuthStep } from '../actions/authorization';
import '../sass/emailAuth.scss';
import logoPng from '../../assets/images/logo.png';
import { isBusinessUserAccount } from '../utils/userUtils';
import { DOCUMENT_CUSTOM, LEASE_TYPE_RESIDENTIAL } from '../config';
import { LEASE_STATUS_DRAFT } from '../actions/dashboard';
import * as Login from '../reducers/login';
import { toSaveToken } from '../utils/toSaveToken';
import { AGENCY_ACCOUNT_TYPE, GENERAL_ACCOUNT_TYPE } from '../constants/constants';

const EmailAuth = () => {
    const { token } = useParams();
    const Rollbar = useRollbarProvider();
    const dispatch = useDispatch();
    let [response, setResponse] = useState({});

    useEffect(() => {
        document.title = 'Email auth';
        getEmailAuth();
    }, []);

    useEffect(() => {
        const { responseCode, user } = response;
        if (responseCode) {
            if (responseCode === 'REGISTRATION_NOT_COMPLETE') {
                dispatch(settingEmailAuthStep(response));
                dispatch({
                    type: Login.VERIFY_SUCCESS,
                    result: response
                });
                if (isBusinessUserAccount(user.accountType)) {
                    toSaveToken(user, false, GENERAL_ACCOUNT_TYPE);
                    appHistory.push('/signup/business');
                } else {
                    toSaveToken(user, false, AGENCY_ACCOUNT_TYPE);
                    appHistory.push('/signup');
                }
            } else {
                dispatch({
                    type: Login.VERIFY_SUCCESS,
                    result: response
                });
                if (isBusinessUserAccount(user.accountType)) {
                    toSaveToken(user, false, GENERAL_ACCOUNT_TYPE);
                    return appHistory.push(`/dashboard/documents/${DOCUMENT_CUSTOM}/${LEASE_STATUS_DRAFT}`);
                } else {
                    toSaveToken(user, false, AGENCY_ACCOUNT_TYPE);
                    appHistory.push(`/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${LEASE_STATUS_DRAFT}`);
                }
            }
        }
    }, [response]);

    const getEmailAuth = () => {
        return axios
            .get(`/auth/verify-email/${token}`)
            .then(result => {
                setResponse(result.data);
            })
            .catch(error => {
                confirmAlert({
                    title: '',
                    message: `This link is not valid please contact FLK if you are trying to register.`,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
                Rollbar.error(
                    'Failed to get email auth from URL ',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
            });
    };

    return (
        <div className="email-auth">
            <img src={logoPng} className="email-auth-logo" alt="logo" />
            <div className="email-auth-preloader">
                <PreLoader className="email-auth-preloader__spinner" />
            </div>
        </div>
    );
};

export default memo(EmailAuth);
