import React, { memo, useImperativeHandle, useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getLeaseType, getStep, getPets } from '../../../../selectors/lease';
import * as Lease from '../../../../reducers/lease';
import axios from 'axios';
import { FormTextRegular } from '../../../../components/form/FormText';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { has } from 'lodash';
import { YES_LABEL, NO_LABEL, NA_LABEL } from '../../../../config';
const PETS_FORM = 'petsForm';

const Pets = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const leaseType = useSelector(getLeaseType);
    const reduxPets = useSelector(getPets);

    let [formState, setFormState] = useState(reduxPets);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PETS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updatePets = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pets`, data);
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePets(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_PETS_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pets')) {
                    return error.response.data.errors.pets;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };
    const allowPets = (push, values) => {
        if (values.isApprovedPets !== YES_LABEL) {
            push('petsList', {
                type: ''
            });
        }
    };

    const clearPets = () => {
        setFormState({
            isApprovedPets: NO_LABEL,
            petsList: []
        });
    };

    return (
        <div className="outgoings">
            <Form
                onSubmit={submitForm}
                initialValues={formState}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    values,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form id={PETS_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormRadioGroup
                                label="A Pet Is Approved"
                                name="isApprovedPets"
                                className="full-width"
                                data={[
                                    {
                                        label: YES_LABEL,
                                        value: YES_LABEL,
                                        onClick: () => allowPets(push, values)
                                    },
                                    {
                                        label: NO_LABEL,
                                        value: NO_LABEL,
                                        onClick: clearPets
                                    }
                                ]}
                            />
                            {values.isApprovedPets === YES_LABEL && (
                                <React.Fragment>
                                    <FieldArray name="petsList" initialValue={formState.petsList}>
                                        {({ fields }) => (
                                            <React.Fragment>
                                                {fields.map((name, index) => {
                                                    return (
                                                        <div key={index} className="item-condition">
                                                            <div className="clauses">
                                                                <div className="clauses-header">
                                                                    <h3>Pet {index + 1}</h3>
                                                                    {index > 0 && (
                                                                        <div
                                                                            className="toggle"
                                                                            onClick={() => fields.remove(index)}
                                                                        >
                                                                            <span className="span-remove">Remove</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="formBox-column">
                                                                    <FormTextRegular
                                                                        name={`petsList[${index}].type`}
                                                                        label="Type of pet"
                                                                        required
                                                                    />
                                                                    <FormTextRegular
                                                                        name={`petsList[${index}].breed`}
                                                                        label="Breed of pet"
                                                                    />
                                                                    <FormTextRegular
                                                                        name={`petsList[${index}].age`}
                                                                        label="Age of pet"
                                                                    />
                                                                    <FormTextRegular
                                                                        name={`petsList[${index}].sex`}
                                                                        label="Sex of pet"
                                                                    />
                                                                    <FormTextRegular
                                                                        name={`petsList[${index}].name`}
                                                                        label="Name of pet"
                                                                    />
                                                                    <FormRadioGroup
                                                                        label="Is the Pet desexed?"
                                                                        name={`petsList[${index}].isDesexed`}
                                                                        className="isDesexed"
                                                                        data={[
                                                                            {
                                                                                label: YES_LABEL,
                                                                                value: YES_LABEL
                                                                            },
                                                                            {
                                                                                label: NO_LABEL,
                                                                                value: NO_LABEL
                                                                            }
                                                                        ]}
                                                                    />
                                                                    <FormRadioGroup
                                                                        label="Is the Pet registered?"
                                                                        name={`petsList[${index}].isRegistered`}
                                                                        className="isRegistered"
                                                                        data={[
                                                                            {
                                                                                label: YES_LABEL,
                                                                                value: YES_LABEL
                                                                            },
                                                                            {
                                                                                label: NO_LABEL,
                                                                                value: NO_LABEL
                                                                            },
                                                                            {
                                                                                label: NA_LABEL,
                                                                                value: NA_LABEL
                                                                            }
                                                                        ]}
                                                                    />
                                                                    <FormRadioGroup
                                                                        label="Photo provided?"
                                                                        name={`petsList[${index}].isPhotoProvided`}
                                                                        className="isPhotoProvided"
                                                                        data={[
                                                                            {
                                                                                label: YES_LABEL,
                                                                                value: YES_LABEL
                                                                            },
                                                                            {
                                                                                label: NO_LABEL,
                                                                                value: NO_LABEL
                                                                            }
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </React.Fragment>
                                        )}
                                    </FieldArray>
                                    <div className="button add-item-button-container">
                                        <button
                                            className="mobile add-item"
                                            type="button"
                                            onClick={() => {
                                                push('petsList', {
                                                    service: '',
                                                    apportionment: ''
                                                });
                                            }}
                                        >
                                            Add pet
                                        </button>
                                    </div>
                                </React.Fragment>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Pets);
