import React from 'react';
import * as agreementUtils from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getAgencyTimezoneFromUser, formatDateDay } from '../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';
import { formatPeriod } from '../../../../../config';
import { formatCurrency } from '../../../../../utils/formUtils';

function RentIncreaseSummary({ rent }) {
    const loggedInUser = useSelector(getUserInfo);
    return (
        <div className="margin-bottom-1rem">
            <p className="text text-gray">Weekly rent: ${formatCurrency(rent.rentIncrease.newRentAmount, true)}</p>
            <p className="text">
                The tenant will pay: $
                {agreementUtils.getRentForPeriod(
                    parseFloat(rent.rentIncrease.newRentAmount),
                    rent.rentIncrease.newRentPayablePeriod
                )}{' '}
                per {formatPeriod(rent.rentIncrease.newRentPayablePeriod)}
            </p>
            <p className="text">
                Rent will be increased on{' '}
                {formatDateDay(rent.rentIncrease.newRentDateStart, getAgencyTimezoneFromUser(loggedInUser))}
            </p>
        </div>
    );
}

function AgreementResidentialRentIncrease({ leaseAllInfo }) {
    const loggedInUser = useSelector(getUserInfo);
    return (
        <>
            {leaseAllInfo.rent.isRentIncrease &&
                leaseAllInfo.rent.rentIncreaseList.map((rentIncreaseListItem, index) => {
                    return (
                        <AgreementSection
                            key={index}
                            title={`Rent increase ${formatDateDay(
                                rentIncreaseListItem.rentIncrease.newRentDateStart,
                                getAgencyTimezoneFromUser(loggedInUser)
                            )}`}
                            step={getResTenStepNo(leaseAllInfo.location, 'rent', leaseAllInfo.subLeaseType)}
                            leaseAllInfo={leaseAllInfo}
                        >
                            {!!leaseAllInfo.rent && (
                                <div className="data">
                                    <RentIncreaseSummary rent={rentIncreaseListItem} />
                                </div>
                            )}
                        </AgreementSection>
                    );
                })}
        </>
    );
}

export default AgreementResidentialRentIncrease;
