import React, { Component } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../../utils/agreementUtils';
import { STEP_INSURANCE } from '../../../../../lease/steps';

class AgreementResidentialInsurance extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.insurance && (
                    <AgreementSection
                        title="Insurance"
                        step={getResTenStepNo(leaseAllInfo.location, STEP_INSURANCE, leaseAllInfo.subLeaseType)}
                        leaseAllInfo={leaseAllInfo}
                    >
                        <div>
                            {leaseAllInfo.insurance.isLandlordResponsible && (
                                <p className="text">
                                    The Landlord has responsibility for insurance of the building and premises
                                </p>
                            )}
                            {leaseAllInfo.insurance.isTenantResponsible && (
                                <p className="text">
                                    The Tenant has responsibility for insurance of contents of the premises (for
                                    property other than that of the Landlord)
                                </p>
                            )}
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementResidentialInsurance;
