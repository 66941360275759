import React, { Component } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../../utils/agreementUtils';
import { STEP_INSTRUCTIONS } from '../../../../../lease/steps';

class AgreementResidentialInstructions extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.instructions && (
                    <AgreementSection
                        title="Manuals or Written Instructions for Domestic Facilities"
                        step={getResTenStepNo(leaseAllInfo.location, STEP_INSTRUCTIONS, leaseAllInfo.subLeaseType)}
                        leaseAllInfo={leaseAllInfo}
                    >
                        <div>
                            {leaseAllInfo.instructions.instructionsList.map((instruction, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <p className="text">Facility: {instruction.facility}</p>
                                        {instruction.name && <p className="text">Name: {instruction.name}</p>}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementResidentialInstructions;
