import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appHistory from './../../../AppHistory';
import * as dashboard from '../../../actions/dashboard';
import { getAgencyPropertyMeIntegration, getAgencyPropertyTreeIntegration } from '../../../selectors/agency';
import Button from '../../../common/components/Button';
import {
    openFlkAKeyForm,
    openFlkAKeyCheckInModal,
    resetSelectKey,
    changeFlkAKeyAddressSearchFilter
} from '../../../actions/flkAKey';
import { syncAccountIntegration } from '../../../actions/account';
import '../../../sass/statusToolbar.scss';
import { getAddressFilter } from '../../../selectors/flkAKey';
import { PreLoader } from '../../../common/components/PreLoader';
import classNames from 'classnames';
import { FLK_A_KEY_STATUS_DRAFT } from '../../../actions/dashboard';

const FlkAKeyStatusToolbar = props => {
    const dispatch = useDispatch();
    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const addressFilter = useSelector(getAddressFilter);

    const changeDocumentStatus = status => {
        dispatch(resetSelectKey());
        appHistory.push(`/dashboard/flk-a-key/${status}`);
    };

    const handleOpenFlkAKeyForm = () => {
        appHistory.push(`/dashboard/flk-a-key/${dashboard.FLK_A_KEY_STATUS_CHECKED_OUT}`);
        dispatch(openFlkAKeyForm());
        if (propertyMeIntegration || propertyTreeIntegration) {
            dispatch(syncAccountIntegration());
        }
    };

    const handleOpenFlkAKeyCheckInModal = () => {
        dispatch(openFlkAKeyCheckInModal());
    };

    const changeAddressFilter = e => {
        dispatch(changeFlkAKeyAddressSearchFilter(e.target.value || ''));
    };

    return (
        <div className="tb-status">
            <div className="menu-block">
                <div
                    className={classNames('item-menu drafts', {
                        active: props.status === dashboard.FLK_A_KEY_STATUS_DRAFT
                    })}
                    onClick={() => changeDocumentStatus(dashboard.FLK_A_KEY_STATUS_DRAFT)}
                >
                    Drafts
                </div>
                <div
                    className={classNames('item-menu checked-out', {
                        active: props.status === dashboard.FLK_A_KEY_STATUS_CHECKED_OUT
                    })}
                    onClick={() => changeDocumentStatus(dashboard.FLK_A_KEY_STATUS_CHECKED_OUT)}
                >
                    Checked out
                </div>
                <div
                    className={classNames('item-menu overdue', {
                        active: props.status === dashboard.FLK_A_KEY_STATUS_OVERDUE
                    })}
                    onClick={() => changeDocumentStatus(dashboard.FLK_A_KEY_STATUS_OVERDUE)}
                >
                    Overdue
                    {/*<PopupNotificationCount*/}
                    {/*    notificationsCount={10}*/}
                    {/*    showWhenNone={true}*/}
                    {/*/>*/}
                </div>
                <div
                    className={classNames('item-menu last-child returned', {
                        active: props.status === dashboard.FLK_A_KEY_STATUS_RETURNED
                    })}
                    onClick={() => changeDocumentStatus(dashboard.FLK_A_KEY_STATUS_RETURNED)}
                >
                    Returned
                </div>
                <div className="search-group">
                    <input
                        className="search-group-input"
                        type="text"
                        placeHolder="Address search"
                        value={addressFilter}
                        onChange={changeAddressFilter}
                    />
                </div>
                {props.isPending && <PreLoader className="flk-a-key-preloader" />}
                <div className="action-button-group">
                    <Button primary height="40" onClick={handleOpenFlkAKeyForm}>
                        Check out
                    </Button>
                    <Button primary height="40" onClick={handleOpenFlkAKeyCheckInModal}>
                        Check in
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default memo(FlkAKeyStatusToolbar);
