import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { FormTextRegular } from '../FormText';
import { getError } from '../../../utils/formUtils';
import { ArrowBackIosSharp } from '@material-ui/icons';

import '../../../sass/responsive/responsiveNumber.scss';

const ButtonGroup = ({
    input,
    label,
    className,
    disabled,
    postFix,
    validateOnTouch,
    meta,
    options,
    otherLabel,
    required,
    form
}) => {
    const [morePopup, setMorePopup] = useState(false);

    const updateMorePopup = (value = input.value) => {
        if (options.includes(value)) {
            setMorePopup(false);
        } else if (value) {
            setMorePopup(true);
            form.change(`tempDataText-${input.name}`, value);
        }
    };

    useEffect(() => {
        updateMorePopup();
    }, []);

    return (
        <div className={`${className} FormInput number-input-group ${disabled ? 'disabled' : ''}`}>
            <label className={`${getError(meta, validateOnTouch) ? 'error' : ''}`}>
                {label}
                {required ? '*' : ''}
            </label>
            <div className={`number-input-group__fields-wrapper ${morePopup ? 'show-more' : ''}`}>
                <div className="button-group">
                    {options.map((option, index) => {
                        return (
                            <button
                                key={index}
                                onClick={() => {
                                    input.onChange(option);
                                    setMorePopup(false);
                                }}
                                className={`${input.value === option ? 'active' : ''}`}
                            >
                                {`${option}${postFix ? postFix : ''}`}
                            </button>
                        );
                    })}
                    <button
                        onClick={() => {
                            input.onChange(null);
                            form.change(`tempDataText-${input.name}`, '');
                            setMorePopup(true);
                        }}
                        className={`${morePopup ? 'active' : ''}`}
                    >
                        {otherLabel}
                    </button>
                </div>
                <div className={`type-input ${getError(meta, validateOnTouch) ? 'error' : ''}`}>
                    <button
                        className="back"
                        onClick={() => {
                            input.onChange(null);
                            form.change(`tempDataText-${input.name}`, '');
                            setMorePopup(false);
                        }}
                    >
                        <ArrowBackIosSharp />
                    </button>
                    <FormTextRegular
                        name={`tempDataText-${input.name}`}
                        onChange={e => {
                            input.onChange(e.target.value ? e.target.value : null);
                        }}
                        label=""
                        value={input.value}
                    />
                    {postFix && <span>{postFix}</span>}
                </div>
            </div>
            {getError(meta, validateOnTouch) && <p className={'FormError'}>{getError(meta, validateOnTouch)}</p>}
        </div>
    );
};

export const FormResponsiveText = ({
    name,
    value,
    label,
    required,
    disabled,
    onChange,
    options,
    otherLabel,
    postFix,
    form,
    ...additionalAttributes
}) => {
    return (
        <React.Fragment>
            <div className="for-lg-modal FormInput responsive-numbers-desktop">
                <FormTextRegular
                    name={name}
                    onChange={onChange}
                    label={label}
                    disabled={disabled}
                    value={value}
                    required={required}
                    {...additionalAttributes}
                />
                {postFix && <span className="postFix">{postFix}</span>}
            </div>
            <div className="for-sm-modal FormInput responsive-numbers">
                <Field
                    type="text"
                    name={name}
                    component={ButtonGroup}
                    onChange={onChange}
                    label={label}
                    disabled={disabled}
                    value={value}
                    options={options}
                    postFix={postFix}
                    otherLabel={otherLabel}
                    form={form}
                    required={required}
                    {...additionalAttributes}
                />
            </div>
        </React.Fragment>
    );
};
