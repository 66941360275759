import * as React from 'react';
import { connect } from 'react-redux';
import '../../../sass/settings.scss';
import { ReportsView } from '../../../components/settings/reports/ReportsView';
import {
    getActivityReportSettings,
    getFollowUpReportSettingsEmailReceivers
} from '../../../selectors/settings/account';

export default connect(state => ({
    receiverEmails: getFollowUpReportSettingsEmailReceivers(state),
    initialActivityReportSettings: getActivityReportSettings(state)
}))(ReportsView);
