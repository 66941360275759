import React from 'react';
import {
    getPmStepNo,
    isLeaseLocationNSW,
    isLeaseLocationQLD,
    isLeaseLocationSA
} from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { formatDateDay, formatDateDayWithTh, getAgencyTimezoneFromUser } from '../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { TERM_TYPE_SINGLE } from '../../../../../config';

const AgreementPmTerm = ({ leaseAllInfo }) => {
    const loggedInUser = useSelector(getUserInfo);
    return (
        <div>
            {leaseAllInfo.pmAgreementTerm && (
                <AgreementSection
                    title={isLeaseLocationSA(leaseAllInfo.location) ? 'Appointment' : 'Agreement Term'}
                    step={getPmStepNo(leaseAllInfo.location, 'pmAgreementTerm')}
                    leaseAllInfo={leaseAllInfo}
                >
                    {isLeaseLocationNSW(leaseAllInfo.location) && (
                        <div className="agreement-section-text">
                            <div>
                                {leaseAllInfo.pmAgreementTerm.startDate && (
                                    <p className="name">
                                        Start date:{' '}
                                        <strong>
                                            {formatDateDay(
                                                leaseAllInfo.pmAgreementTerm.startDate,
                                                getAgencyTimezoneFromUser(loggedInUser)
                                            )}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmAgreementTerm.noticePeriod && (
                                    <p className="name">
                                        Notice Period: <strong>{leaseAllInfo.pmAgreementTerm.noticePeriod}</strong>
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    {isLeaseLocationQLD(leaseAllInfo.location) && (
                        <div className="agreement-section-text">
                            <div>
                                {leaseAllInfo.pmAgreementTerm.serviceType && (
                                    <p className="name">
                                        The agent can perform the following service/s:&nbsp;
                                        <strong>{leaseAllInfo.pmAgreementTerm.serviceType}</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmAgreementTerm.termType && (
                                    <p className="name">
                                        The agent has the following term:&nbsp;
                                        <strong>{leaseAllInfo.pmAgreementTerm.termType}</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmAgreementTerm.startDate && (
                                    <p className="name">
                                        Start date:&nbsp;
                                        <strong>
                                            {formatDateDay(
                                                leaseAllInfo.pmAgreementTerm.startDate,
                                                getAgencyTimezoneFromUser(loggedInUser)
                                            )}
                                        </strong>
                                    </p>
                                )}
                                {leaseAllInfo.pmAgreementTerm.termType === TERM_TYPE_SINGLE &&
                                    leaseAllInfo.pmAgreementTerm.endDate && (
                                        <p className="name">
                                            End date:&nbsp;
                                            <strong>
                                                {formatDateDay(
                                                    leaseAllInfo.pmAgreementTerm.endDate,
                                                    getAgencyTimezoneFromUser(loggedInUser)
                                                )}
                                            </strong>
                                        </p>
                                    )}
                            </div>
                            <div>
                                {leaseAllInfo.pmAgreementTerm.propertyAvailabilityDate && (
                                    <p className="name">
                                        Property Availability Date:&nbsp;
                                        <strong>
                                            {formatDateDay(
                                                leaseAllInfo.pmAgreementTerm.propertyAvailabilityDate,
                                                getAgencyTimezoneFromUser(loggedInUser)
                                            )}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <h3>Notice to Leave:</h3>
                            <div>
                                <p className="name">
                                    Has the property been leased out in the past 12 months under a General Tenancy
                                    Agreement or a rooming agreement as of today's date?:&nbsp;
                                    <strong>
                                        {leaseAllInfo.pmRentAndPayment?.isPropertyBeenLeaseOutUnderGTAFormatted}
                                    </strong>
                                </p>
                                <p className="name">
                                    Notice to leave for Sale Contract:&nbsp;
                                    <strong>
                                        {leaseAllInfo.pmRentAndPayment?.decidedToSellPropertyFormatted}
                                    </strong>
                                </p>
                                <p className="name">
                                    Notice to leave for change of use:&nbsp;
                                    <strong>
                                        {leaseAllInfo.pmRentAndPayment?.decidedToChangeUsageFormatted}
                                    </strong>
                                </p>
                                <p className="name">
                                    Notice to leave for owner occupation:&nbsp;
                                    <strong>
                                        {leaseAllInfo.pmRentAndPayment?.decidedToLiveInPropertyFormatted}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    )}
                    {isLeaseLocationSA(leaseAllInfo.location) && leaseAllInfo.pmAgreementTerm && (
                        <div className="agreement-section-text">
                            <div className="propertyDetails">
                                <p className="name">Appointment:&nbsp;</p>
                                <strong>{leaseAllInfo.pmAgreementTerm.applicableAppointment}</strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Available from:&nbsp;</p>
                                <strong>
                                    {formatDateDayWithTh(
                                        leaseAllInfo.pmAgreementTerm.availableFrom,
                                        getAgencyTimezoneFromUser(loggedInUser)
                                    )}
                                </strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Preference for lease term:&nbsp;</p>
                                <strong>{leaseAllInfo.pmAgreementTerm.preferenceForLeaseTerm}</strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Tenancy periods offered:&nbsp;</p>
                                <strong>{leaseAllInfo.pmAgreementTerm.tenancyPeriodText}</strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Term:&nbsp;</p>
                                <strong>
                                    {leaseAllInfo.pmAgreementTerm.initialAppointmentTerm === 'Other'
                                        ? `Other - ${leaseAllInfo.pmAgreementTerm.otherInitialAppointmentTerm}`
                                        : leaseAllInfo.pmAgreementTerm.initialAppointmentTerm}
                                </strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Start date:&nbsp;</p>
                                <strong>
                                    {formatDateDayWithTh(
                                        leaseAllInfo.pmAgreementTerm.startDate,
                                        getAgencyTimezoneFromUser(loggedInUser)
                                    )}
                                </strong>
                            </div>
                        </div>
                    )}
                </AgreementSection>
            )}
        </div>
    );
};

export default AgreementPmTerm;
