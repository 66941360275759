import { combineReducers } from 'redux';
import authorization from './authorization';
import login, { LOGOUT_SUCCESS } from './login';
import user from './user';
import banner from './banner/banner';
import join from './joinTeam';
import lease from './lease';
import document from './document';
import dashboard from './dashboard';
import renewal from './renewal';
import account from './account';
import notification from './notification';
import signatures from './signatures';
import specialConditions from './specialConditions';
import contacts from './emergencyContacts';
import { completion } from './completion';
import leaseWithRequest from './leaseWithRequest';
import subscription from './subscription';
import resetPassword from './resetPassword';
import statistics from './statistics';
import connections from './connections';
import resendLease from './resendLease';
import terminateLease from './terminateLease';
import rentIncrease from './rentIncrease';
import agency from './agency';
import serverRequests from './serverRequests';
import signLeaseGame from './promotion/signLeaseGame';
import addressDetails from './addressDetails';
import resendLandlordInformationStatement from './resendLandlordInformationStatement';
import flkAKey from './flkAKey';
import confirmModals from './confirmModals';
import help from './help';
import toast from './toast';
import emailTemplates from './emailTemplate';

const appReducer = combineReducers({
    authorization: authorization,
    login: login,
    user: user,
    agency: agency,
    account: account,
    join: join,
    lease: lease,
    document: document,
    dashboard: dashboard,
    renewal: renewal,
    notification: notification,
    signatures: signatures,
    specialConditions: specialConditions,
    contacts: contacts,
    completion: completion,
    leaseWithRequest: leaseWithRequest,
    subscription: subscription,
    resetPassword: resetPassword,
    signLeaseGame: signLeaseGame,
    statistics: statistics,
    connections: connections,
    resendLease: resendLease,
    terminateLease: terminateLease,
    rentIncrease: rentIncrease,
    serverRequests: serverRequests,
    addressDetails: addressDetails,
    resendLandlordInformationStatement: resendLandlordInformationStatement,
    flkAKey: flkAKey,
    confirmModals: confirmModals,
    help: help,
    toast: toast,
    banner: banner,
    emailTemplates: emailTemplates,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
        const { login } = state;
        state = { login };
    }
    return appReducer(state, action);
};

export default rootReducer;
