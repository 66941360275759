import * as Accordion from '@radix-ui/react-accordion';
import cx from 'classnames';
import React from 'react';

import Button from '../../../../../common/components/Button.js';
import Icon, { Icons } from '../../../../../common/components/Icon';
import Card, { CardShadow, CardStyles } from '../../../../../common/components/cards/Card';

import styles from './AccordionItem.module.scss';

type AccordionItemProps = React.PropsWithChildren<{
    collapsedContent: React.ReactNode;
    expandedHeader?: React.ReactNode;
    value: string;
    isOpen: boolean;
    className?: string;
    isExpandDisabled?: boolean;
}>;

const AccordionItem = React.forwardRef<HTMLDivElement, AccordionItemProps>(
    ({ collapsedContent, expandedHeader, value, isOpen, className, isExpandDisabled = false, children }, ref) => {
        return (
            <Card
                style={CardStyles.SQUARE}
                shadow={CardShadow.SMALL}
                className={cx(styles.collapsible, className, { [styles.open]: isOpen })}
                animate={false}
                ref={ref}
            >
                <Accordion.Item value={value}>
                    <Accordion.Trigger asChild>
                        <Button type="button" className={styles.trigger} disabled={isExpandDisabled}>
                            {expandedHeader && isOpen ? (
                                <div className={styles.expandedHeader}>{expandedHeader}</div>
                            ) : (
                                <div aria-hidden={isOpen} className={styles.collapsedContent}>
                                    {collapsedContent}
                                </div>
                            )}
                            <Icon icon={Icons.CHEVRON_DOWN} className={styles.triggerIcon} />
                        </Button>
                    </Accordion.Trigger>
                    <Accordion.Content className={styles.expandedContent}>{children}</Accordion.Content>
                </Accordion.Item>
            </Card>
        );
    }
);

export default AccordionItem;
