import { DOCUMENT_CUSTOM } from '../../../../../config';
import React from 'react';
import * as PropTypes from 'prop-types';

export default function Signer({ doc }) {
    if (doc.docType === DOCUMENT_CUSTOM) {
        return doc?.client?.clientsListText || '-';
    } else {
        return doc.to.clientsListText;
    }
}

Signer.propTypes = { doc: PropTypes.any };
