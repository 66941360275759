import React, { Component } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../../utils/agreementUtils';
import { STEP_DISCLOSURE } from '../../../../../lease/steps';

class AgreementResidentialDisclosure extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.disclosure && (
                    <AgreementSection
                        title="Disclosure of Sale of Premises to Prospective Tenant"
                        step={getResTenStepNo(leaseAllInfo.location, STEP_DISCLOSURE, leaseAllInfo.subLeaseType)}
                        leaseAllInfo={leaseAllInfo}
                    >
                        <div>
                            <p className="text">
                                The Landlord has a present intention to sell the Property:{' '}
                                {leaseAllInfo.disclosure.hasIntentionToSell}
                            </p>
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementResidentialDisclosure;
