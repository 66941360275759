import React from 'react';

import Pagination from 'react-js-pagination';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';

class AgencyTable extends React.Component {
    render() {
        let { agencies, pagination, loggedInUser } = this.props;

        return (
            <div>
                <div className="agencies-block">
                    <div className="name">
                        <p>Agencies</p>
                    </div>
                    <div className="table-sort data-table">
                        <Table>
                            <Thead>
                                <Th column="name">
                                    <b>Name</b>
                                </Th>
                                <Th column="numOfLeaseAgreementsStarted" className="center">
                                    <b>Leases started</b>
                                </Th>
                                <Th column="numOfLeaseAgreements" className="center">
                                    <b>Leases signed</b>
                                </Th>
                                <Th column="numOfLeaseAgreementsInLast7Days" className="center">
                                    <b>
                                        Leases signed <br />
                                        in last 7 days
                                    </b>
                                </Th>
                                <Th column="created">
                                    <b>Created date</b>
                                </Th>
                            </Thead>
                            {agencies.map((item, index) => (
                                <Tr key={index}>
                                    <Td column="name">
                                        <b className="capitalize">{item.agencyName}</b>
                                    </Td>
                                    <Td column="numOfLeaseAgreementsStarted" className="center">
                                        {item.numOfLeaseAgreementsStarted}
                                    </Td>
                                    <Td column="numOfLeaseAgreements" className="center">
                                        {item.numOfLeaseAgreements}
                                    </Td>
                                    <Td column="numOfLeaseAgreementsInLast7Days" className="center">
                                        {item.numOfLeaseAgreementsInLast7Days}
                                    </Td>
                                    <Td column="created">
                                        {formatDateStandard(
                                            new Date(item.created),
                                            getAgencyTimezoneFromUser(loggedInUser)
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                        </Table>
                    </div>
                </div>
                <div className="wrapper-pagination">
                    <Pagination
                        hideDisabled
                        activePage={pagination.page ? pagination.page : 1}
                        itemsCountPerPage={pagination.limit ? pagination.limit : 0}
                        totalItemsCount={pagination.total ? pagination.total : 0}
                        pageRangeDisplayed={3}
                        onChange={e => this.props.changePage(e)}
                        lastPageText={<div className="last-icon" />}
                        firstPageText={<div className="first-icon" />}
                        nextPageText="..."
                        prevPageText="..."
                    />
                    {pagination.limit < pagination.total ? (
                        <p className="pagination-text">
                            Page {pagination.page} of {pagination.pages}
                        </p>
                    ) : (
                        <p />
                    )}
                </div>
            </div>
        );
    }
}

export default AgencyTable;
