import React, { memo, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import cookie from 'react-cookie';
import { useDispatch } from 'react-redux';
import appHistory from '../../AppHistory.js';
import { PreLoader } from '../../common/components/PreLoader.js';
import { setLoginRedirect } from '../../actions/login.js';
import { openDocumentForm } from '../../actions/document.js';
import { DOCUMENT_CUSTOM } from '../../config';
import { LEASE_STATUS_DRAFT } from '../../actions/dashboard.js';
import { useRollbarProvider } from '../../components/providers/RollbarProvider.js';
import useOpenCreationModal from '../../hooks/useOpenCreationModal';

import logoPng from '../../../assets/images/logo.png';

import styles from './ExternalCustomDocumentLandingPage.module.scss';

const ExternalCustomDocumentLandingPage = () => {
    const { tempDocId } = useParams<{ tempDocId: string }>();
    const location = useLocation();
    const dispatch = useDispatch();
    const Rollbar = useRollbarProvider();
    const { openDocumentCreationModal } = useOpenCreationModal();

    useEffect(() => {
        document.title = 'External Link Verification';
        goToDashboard(tempDocId);
    }, []);

    function goToDashboard(tempDocId: string) {
        if (!cookie.load('userFromLogin')) {
            dispatch(setLoginRedirect(location.pathname));
            appHistory.push('/');
        } else {
            axios
                .post(`api/document/temporary-custom-document/${tempDocId}`)
                .then(response => {
                    appHistory.push(`/dashboard/documents/${DOCUMENT_CUSTOM}/${LEASE_STATUS_DRAFT}`);
                    dispatch(openDocumentForm(DOCUMENT_CUSTOM, response.data.doc));
                })
                .catch(error => {
                    appHistory.push(
                        `/dashboard/documents/${DOCUMENT_CUSTOM}/${LEASE_STATUS_DRAFT}?create=document_custom`
                    );
                    openDocumentCreationModal(DOCUMENT_CUSTOM);
                    Rollbar.error(
                        'Failed to get temporary document',
                        {
                            error_message: error.message,
                            status: 'error',
                            env: ENVIRONMENT
                        },
                        error
                    );
                });
        }
    }

    return (
        <div className={styles.externalLinkVerification}>
            <img src={logoPng} className={styles.logo} alt="logo" />
            <PreLoader className={styles.preloader} />
        </div>
    );
};

export default memo(ExternalCustomDocumentLandingPage);
