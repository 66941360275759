import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { find, findLastIndex } from 'lodash';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';

import '../../../../sass/completionProgress.scss';
import { capitalize } from '../../../../utils/generalUtils';
import { CancelSharp } from '@material-ui/icons';

const CompletionProgress = props => {
    if (!props.progress || props.progress.length < 1) {
        return false;
    }
    const getCurrentStep = () => {
        const currentStep = find(props.progress, step => !step.completed);
        if (currentStep) {
            return currentStep;
        }
        return false;
    };

    const isCurrentStepTheLast = () => {
        return findLastIndex(props.progress, step => step.completed) + 1 === props.progress.length;
    };

    const getClassStep = () => {
        let result = 'progress ';
        if (isCurrentStepTheLast()) {
            return (result += 'progress--complete');
        }
        return (result += `progress-${props.progress.length}steps--${findLastIndex(
            props.progress,
            step => step.completed
        ) + 2}`);
    };

    return (
        <div className="progress-container">
            <div className={getClassStep()}>
                <div className="progress__bg" />
                <div className="progress_indicator_container">
                    {props.progress.map((item, index) => {
                        const showError = !item.completed && !!item.error;
                        return (
                            <div
                                key={index}
                                className={`progress__step progress__step-${props.progress.length}steps--${index +
                                    1} ${item.completed && 'progress__step--complete'} ${item.step ===
                                    getCurrentStep().step &&
                                    !getCurrentStep().error &&
                                    'progress__step--active'} ${showError && 'progress__step--error'}`}
                            >
                                <div className={`progress__indicator`}>
                                    {item.completed && <CheckCircleSharpIcon />}
                                    {showError && <CancelSharp />}
                                </div>
                                <div className="progress__label">{capitalize(item.step.split('_').join(' '))}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

CompletionProgress.propTypes = {
    progress: PropTypes.array
};

export default memo(CompletionProgress);
