import React, { PureComponent } from 'react';
import { debounce, size } from 'lodash';
import Downshift from 'downshift';
import axios from 'axios';
import classnames from 'classnames';
import { PreLoader } from '../../common/components/PreLoader';
import '../../sass/integrationAddressInput.scss';
import { LEASE_TYPE_RESIDENTIAL_DISPLAY, SA_STATE, SUB_LEASE_TYPE_SA_RENEWALS } from '../../config';
import { LEASE_STATUS_COMPLETE } from '../../actions/dashboard';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../utils/dateUtils';

const DEBOUNCE_WAIT = 1000;

const integrationAddressSearchUrl = '/api/integrations/search';

function itemToString(item) {
    if (typeof item === 'string') {
        return item;
    }

    // This means this is a completed lease. So we get address from it.
    if (item.leaseType && item.address) {
        return item.address;
    }

    return item ? item.AddressText || item.addressText : '';
}

function renderPropertyItems(properties, getItemProps, highlightedIndex, loggedInUser) {
    return properties.map((property, index) => {
        if (property.leaseType) {
            return (
                <li
                    key={index}
                    {...getItemProps({
                        key: property.Id || property.id,
                        item: property,
                        className: classnames({ highlighted: highlightedIndex === index }, 'flkitover')
                    })}
                >
                    <div className="lease-details">
                        <div>
                            <p>{property.address}</p>
                            <small>
                                <i>Residential Tenancy</i>
                                <br />
                                <i>
                                    {formatDateStandard(
                                        property.term.startDate,
                                        getAgencyTimezoneFromUser(loggedInUser)
                                    )}
                                    -
                                    {formatDateStandard(property.term.endDate, getAgencyTimezoneFromUser(loggedInUser))}
                                </i>
                            </small>
                        </div>
                    </div>
                </li>
            );
        }
        return (
            <li
                key={index}
                {...getItemProps({
                    key: property.Id || property.id,
                    item: property,
                    className: classnames({ highlighted: highlightedIndex === index }, property.integration)
                })}
            >
                {property.addressText || property.AddressText}
            </li>
        );
    });
}

class IntegrationAddressInput extends PureComponent {
    state = {
        inputValue: '',
        searching: false,
        properties: [],
        selectedItem: undefined,
        error: null
    };

    handleStateChange = changes => {
        if ('inputValue' in changes || 'selectedItem' in changes) {
            const { onChange, onSelect } = this.props;
            const { selectedItem: currentSelectedItem } = this.state;
            let { inputValue, selectedItem } = changes;

            // When we copy paste full address and select, inputValue is empty because it did not change.
            // Therefor we should assign selected address
            if (!inputValue && selectedItem) {
                inputValue = selectedItem.AddressText;
            }

            const nextState = { inputValue, selectedItem };

            if (size(inputValue) < 3) {
                this.setState({
                    ...nextState,
                    properties: [],
                    searching: false,
                    error: null
                });
            } else {
                this.setState(
                    {
                        ...nextState,
                        searching: true,
                        error: null
                    },
                    () => this.fetchSearchQuery(inputValue)
                );
            }

            onChange && onChange(inputValue);
            if (currentSelectedItem !== selectedItem) {
                onSelect && onSelect(selectedItem);
            }
        }
    };

    fetchSearchQuery = debounce(address => {
        const params = {
            address,
            allowEmptyTenancy: this.props.allowEmptyTenancy
        };

        //This is Only for SA RENEWALS
        if (this.props.leaseType === SUB_LEASE_TYPE_SA_RENEWALS) {
            params.location = SA_STATE;
            params.leaseType = LEASE_TYPE_RESIDENTIAL_DISPLAY;
            params.status = LEASE_STATUS_COMPLETE;
        } else {
            params.leaseType = this.props.leaseType;
        }

        this.setState({ search: address }, () => {
            axios
                .get(integrationAddressSearchUrl, {
                    params
                })
                .then(res => res.data || [])
                .then(properties =>
                    this.setState(state => {
                        if (state.inputValue === address) {
                            return {
                                error: null,
                                searching: false,
                                properties
                            };
                        }
                    })
                )
                .catch(err => {
                    this.setState({
                        error: new Error(
                            'Unable to search PropertyMe/PropertyTree, make sure you account is connected.'
                        )
                    });
                });
        });
    }, DEBOUNCE_WAIT);

    render() {
        const { placeholder, disabled, onChange, onSelect, loggedInUser, ...inputProps } = this.props;
        const { properties, searching, inputValue, error } = this.state;

        const hasProperties = size(properties) > 0;

        return (
            <Downshift itemToString={itemToString} onStateChange={this.handleStateChange} selectedItem={inputValue}>
                {({
                    getInputProps,
                    inputValue,
                    isOpen,
                    getMenuProps,
                    getItemProps,
                    getLabelProps,
                    highlightedIndex
                }) => (
                    // We will mimic the FormInput as it doesn't play well with downshift
                    <div className="FormInput integration-address">
                        <div
                            className={classnames('form-group', 'integration-address-input', {
                                on: size(inputValue)
                            })}
                        >
                            <label {...getLabelProps({ className: disabled ? 'disabled' : '' })}>{placeholder}</label>
                            <input
                                {...getInputProps({
                                    ...inputProps,
                                    type: 'search',
                                    disabled
                                })}
                            />
                            {searching ? <PreLoader /> : null}
                        </div>
                        {isOpen && hasProperties && (
                            <ul {...getMenuProps({ className: 'integration-address-list' })}>
                                {error ? (
                                    <li className="error-message">{error.message}</li>
                                ) : (
                                    renderPropertyItems(properties, getItemProps, highlightedIndex, loggedInUser)
                                )}
                            </ul>
                        )}
                    </div>
                )}
            </Downshift>
        );
    }
}

export default IntegrationAddressInput;
