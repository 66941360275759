import React from 'react';
import { getPmStepNo } from '../../../../../../utils/agreementUtils.js';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection.js';
import { LeaseAllInfo } from '../../../../../types.js';
import { formatCurrencyWithCurrencySign } from '../../../../../../utils/formUtils.js';

interface Props {
    leaseAllInfo: LeaseAllInfo;
}

const AgreementPmMarketing: React.FC<Props> = ({ leaseAllInfo }) => {
    return (
        <AgreementSection
            title="Advertising & Marketing"
            step={getPmStepNo(leaseAllInfo.location, 'pmMarketingCosts')}
            leaseAllInfo={leaseAllInfo}
        >
            {leaseAllInfo.pmMarketingCosts && (
                <div className="agreement-section-text">
                    <p></p>
                    <div className="propertyDetails">
                        <p className="name">
                            Provide marketing schedule:&nbsp;
                            <strong>{leaseAllInfo.pmMarketingCosts?.isProvideMarketingSchedule ? 'Yes' : 'No'}</strong>
                        </p>
                    </div>
                    {leaseAllInfo.pmMarketingCosts?.isProvideMarketingSchedule ? (
                        <>
                            <div className="propertyDetails">
                                <p className="name">
                                    Marketing is payable:&nbsp;
                                    <strong>{leaseAllInfo.pmMarketingCosts.marketingPayable}</strong>
                                </p>
                            </div>
                            <br />
                            <div className="propertyDetails">
                                <p className="name">
                                    Total Marketing Expenditure:&nbsp;
                                    <strong>
                                        {formatCurrencyWithCurrencySign(
                                            leaseAllInfo.pmMarketingCosts.totalMarketingCost,
                                            true
                                        )}
                                    </strong>
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="propertyDetails">
                                <p className="name">
                                    Media advertising:&nbsp;
                                    <strong>
                                        {leaseAllInfo.pmMarketingCosts?.mediaAdvertising
                                            ? formatCurrencyWithCurrencySign(
                                                  leaseAllInfo.pmMarketingCosts.mediaAdvertising,
                                                  true
                                              )
                                            : 'Not Stated'}
                                    </strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Signboard:&nbsp;
                                    <strong>
                                        {leaseAllInfo.pmMarketingCosts?.signboard
                                            ? formatCurrencyWithCurrencySign(
                                                  leaseAllInfo.pmMarketingCosts.signboard,
                                                  true
                                              )
                                            : 'Not Stated'}
                                    </strong>
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Internet:&nbsp;
                                    <strong>
                                        {leaseAllInfo.pmMarketingCosts?.internet
                                            ? formatCurrencyWithCurrencySign(
                                                  leaseAllInfo.pmMarketingCosts.internet,
                                                  true
                                              )
                                            : 'Not Stated'}
                                    </strong>
                                </p>
                            </div>
                            <br />
                            {leaseAllInfo.pmMarketingCosts.marketingPlans.length > 0 && <p>Itemised Campaign:</p>}
                            {leaseAllInfo.pmMarketingCosts.marketingPlans.map((fee: any, index: any) => {
                                return (
                                    <div key={index} className="propertyDetails">
                                        <p className="name">
                                            {fee.description}:&nbsp;
                                            <strong>{formatCurrencyWithCurrencySign(fee.cost, true)}</strong>
                                        </p>
                                    </div>
                                );
                            })}
                            <br />
                            <div className="propertyDetails">
                                <p className="name">
                                    Total Marketing Expenditure:&nbsp;
                                    <strong>${leaseAllInfo.pmMarketingCosts.marketingEstimate}</strong>
                                </p>
                            </div>
                        </>
                    )}
                </div>
            )}
        </AgreementSection>
    );
};

export default AgreementPmMarketing;
