import { isEmpty, pullAt, startCase } from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CancelIcon } from '../../../assets/images/icons/cancel.svg';
import { ReactComponent as UploadedDocumentIcon } from '../../../assets/images/icons/uploadedDocument.svg';
import { setDirtyStep } from '../../actions/lease';
import Button from '../../common/components/Button';
import { Tag, TagSize } from '../../common/components/tag/Tag';
import { viewDefaultDocument } from '../../config';
import { getDirtyStep, getStep } from '../../selectors/lease';
import { DocumentDrop } from './DocumentDrop';
import styles from './DocumentUpload.module.scss';

const DocumentUploadDocument = ({
    documentName,
    documentSize,
    documentCategory,
    hideCategory,
    onViewDocument,
    disabled,
    onRemoveDocument
}) => {
    return (
        <div className={styles.uploadedDocument}>
            <div className={styles.iconAndButtonContainer}>
                <UploadedDocumentIcon className={styles.icon} />
                {!disabled && onRemoveDocument && (
                    <Button className={styles.deleteButton} onClick={onRemoveDocument}>
                        <CancelIcon />
                    </Button>
                )}
            </div>
            {!hideCategory && documentCategory && <Tag text={startCase(documentCategory)} size={TagSize.Small} />}
            <div className={styles.documentTitle}>
                {onViewDocument ? (
                    <Button link className={styles.link} onClick={onViewDocument}>
                        {documentName}
                    </Button>
                ) : (
                    documentName
                )}
            </div>
            {documentSize && <span className={styles.documentSize}>{documentSize}</span>}
        </div>
    );
};

DocumentUploadDocument.propTypes = {
    documentName: PropTypes.string.isRequired,
    documentSize: PropTypes.string,
    documentCategory: PropTypes.string,
    onViewDocument: PropTypes.func,
    disabled: PropTypes.bool,
    onRemoveDocument: PropTypes.func
};

export function DocumentUpload({
    dropzoneClassName,
    uploading,
    multiple = true,
    lease,
    updateDocuments,
    additionalOptionsForDocument,
    documents = [],
    defaultDocuments = [],
    backendError,
    documentUploadProgress,
    hideFileCategory,
    attachedLabel = 'Attached Files',
    hideAttachedLabel,
    viewDocument,
    disabled,
    maxFileSize = 6, // 6MB
    maxTotalSize = 6, // 6MB
    acceptedFileTypes,
    flattenPdfs,
    rejectEncryptedPdfs,
    leaseType,
    category
}) {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const removeFileToUpload = index => {
        let tempFiles = [...documents];
        pullAt(tempFiles, index);

        if (dirtyStep !== step) {
            dispatch(setDirtyStep(step));
        }

        updateDocuments(tempFiles);
    };

    return (
        <React.Fragment>
            {!disabled && (
                <DocumentDrop
                    className={dropzoneClassName}
                    acceptedFileTypes={acceptedFileTypes}
                    allowMultiple={multiple}
                    documents={documents}
                    updateDocuments={updatedDocuments => {
                        if (step !== dirtyStep) {
                            dispatch(setDirtyStep(step));
                        }
                        updateDocuments(updatedDocuments);
                    }}
                    maxFileSize={maxFileSize}
                    maxTotalSize={maxTotalSize}
                    additionalOptionsForDocument={additionalOptionsForDocument}
                    flattenPdfs={flattenPdfs}
                    rejectEncryptedPdfs={rejectEncryptedPdfs}
                    documentLeaseType={leaseType}
                    documentCategory={category}
                />
            )}
            <div className="has-error"> {backendError}</div>
            <div className="files-to-upload-container agreement-section-documents">
                {uploading && (
                    <div className="dropzone-spinner">
                        <div className="doc-upload-spinner" />
                        <span className="progress-value">{documentUploadProgress}%</span>
                        <label className="progress-line" style={{ width: `${documentUploadProgress}%` }} />
                    </div>
                )}
                {!hideAttachedLabel && (!isEmpty(defaultDocuments) || !isEmpty(documents)) && <h4>{attachedLabel}</h4>}
                <ul className={styles.uploadedDocumentList}>
                    {!isEmpty(defaultDocuments) &&
                        defaultDocuments.map((file, index) => (
                            <li key={index}>
                                <DocumentUploadDocument
                                    documentName={file.documentName}
                                    documentCategory={file.category}
                                    onViewDocument={() => viewDefaultDocument(lease.location, file.fileName)}
                                />
                            </li>
                        ))}
                    {!isEmpty(documents) &&
                        documents.map((file, index) => (
                            <li key={index}>
                                <DocumentUploadDocument
                                    documentName={file.documentName}
                                    documentSize={file.prettyBytes}
                                    documentCategory={file.category}
                                    hideCategory={hideFileCategory}
                                    onViewDocument={file.id ? () => viewDocument(file.id) : undefined}
                                    disabled={disabled}
                                    onRemoveDocument={() => removeFileToUpload(index)}
                                />
                            </li>
                        ))}
                </ul>
            </div>
        </React.Fragment>
    );
}

DocumentUpload.propTypes = {
    dropzoneClassName: PropTypes.string,
    uploading: PropTypes.bool,
    multiple: PropTypes.bool,
    lease: PropTypes.object,
    updateDocuments: PropTypes.func,
    useSelectedOption: PropTypes.bool,
    agencyDefault: PropTypes.bool,
    selectedOption: PropTypes.object,
    documents: PropTypes.array,
    defaultDocuments: PropTypes.array,
    backendError: PropTypes.any,
    documentUploadProgress: PropTypes.number,
    hideFileCategory: PropTypes.bool,
    attachedLabel: PropTypes.string,
    hideAttachedLabel: PropTypes.bool,
    viewDocument: PropTypes.func,
    disabled: PropTypes.bool,
    maxFileSize: PropTypes.number,
    maxTotalSize: PropTypes.number,
    leaseType: PropTypes.string,
    category: PropTypes.string
};

export default DocumentUpload;
