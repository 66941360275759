import React from 'react';
import * as dashboard from '../../../actions/dashboard';
import AgreementMainCompletion from './AgreementMainComponents/AgreementMainCompletion';
import AgreementResidentialCommon from './AgreementMainComponents/residential/AgreementResidentialCommon';
import AgreementSalesCommon from './AgreementMainComponents/sales/AgreementSalesCommon';
import AgreementPmCommon from './AgreementMainComponents/pm/AgreementPmCommon';
import AgreementCommercialLeaseCommon from './AgreementMainComponents/commercialLease/AgreementCommercialLeaseCommon';
import {
    isLeaseTypeCommercialLease,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales
} from '../../../utils/agreementUtils';

function InfoCommonComponent({ leaseAllInfo }) {
    const { leaseType } = leaseAllInfo;

    let content = <></>;
    if (isLeaseTypeResidentialTenancy(leaseType)) {
        content = <AgreementResidentialCommon leaseAllInfo={leaseAllInfo} />;
    } else if (isLeaseTypePm(leaseType)) {
        content = <AgreementPmCommon leaseAllInfo={leaseAllInfo} />;
    } else if (isLeaseTypeCommercialLease(leaseType)) {
        content = <AgreementCommercialLeaseCommon leaseAllInfo={leaseAllInfo} />;
    } else if (isLeaseTypeSales(leaseType)) {
        content = <AgreementSalesCommon leaseAllInfo={leaseAllInfo} />;
    }

    const isCompletionInProgress =
        leaseAllInfo.completion &&
        ['started', 'processing', 'error'].find(status => status === leaseAllInfo.completion.status);
    const isAwaitingCompletion = leaseAllInfo.status === dashboard.LEASE_STATUS_AWAITING_COMPLETION;

    if (isAwaitingCompletion && isCompletionInProgress) {
        content = <AgreementMainCompletion leaseAllInfo={leaseAllInfo} leaseType={leaseType} />;
    }
    return content;
}

export default function AgreementMain({ leaseAllInfo }) {
    const { leaseType } = leaseAllInfo;

    return (
        <main>
            <div className="main-header for-sm-modal">
                <div className="main-header__info">
                    <label>Address:</label>
                    {leaseAllInfo.address}
                </div>
                <div className="main-header__info">
                    <label>Agent:</label>
                    {leaseAllInfo?.agent?.fullName || '-'}
                </div>
                {isLeaseTypePm(leaseType) && leaseAllInfo.status === dashboard.LEASE_STATUS_DRAFT && (
                    <div className="main-header__info">
                        <label>Landlord:</label>
                        <div>
                            {leaseAllInfo.pmLandlord &&
                                leaseAllInfo.pmLandlord.persons.map((landlord, index) => {
                                    return (
                                        <div key={index} className="item">
                                            <p>{landlord.fullName}</p>
                                            <p>{landlord.email}</p>
                                            <p>{landlord.mobile}</p>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
            <InfoCommonComponent leaseAllInfo={leaseAllInfo} />
        </main>
    );
}
