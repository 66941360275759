import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { openNoSignatureModal } from '../../../../actions/user';
import '../../../../sass/dashboardContentTable.scss';
import '../../../../sass/documentListButton.scss';
import { HIDE_MESSAGE_TIME } from '../../../../config';

const EspLetterListButton = ({ doc, agentSignature, revisedEsp }) => {
    const dispatch = useDispatch();

    const [isEmailingrevisedEsp, setIsEmailingrevisedEsp] = useState(false);
    const [isEmailingrevisedEspSuccess, setIsEmailingrevisedEspSuccess] = useState(false);
    const [isEmailingrevisedEspFail, setIsEmailingrevisedEspFail] = useState(false);

    const emailPDF = (id, selectedrevisedEsp) => {
        setIsEmailingrevisedEsp(true);
        axios
            .post(`api/agency/lease/${id}/pdf/esp-letter`, {
                revisedEsp: selectedrevisedEsp
            })
            .then(() => {
                setIsEmailingrevisedEsp(false);
                setIsEmailingrevisedEspSuccess(true);
                setIsEmailingrevisedEspFail(false);
                setTimeout(() => {
                    setIsEmailingrevisedEspSuccess(false);
                }, HIDE_MESSAGE_TIME);
            })
            .catch(() => {
                setIsEmailingrevisedEsp(false);
                setIsEmailingrevisedEspSuccess(false);
                setIsEmailingrevisedEspFail(true);
                setTimeout(() => {
                    setIsEmailingrevisedEspFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    const viewPdf = (docId, selectedrevisedEsp) => {
        if (!isEmpty(agentSignature)) {
            if (selectedrevisedEsp) {
                window.open(`/reader/lease/${docId}/esp-letter/${selectedrevisedEsp.id}`, '_blank');
            }
        } else {
            dispatch(openNoSignatureModal());
        }
    };

    return (
        <div className="document-buttons-container">
            <div className="document-buttons">
                <button
                    disabled={isEmailingrevisedEsp}
                    className="btn-email"
                    onClick={() => emailPDF(doc.id, revisedEsp)}
                >
                    Resend
                    {isEmailingrevisedEsp && <span className="savestatus-spinner-await-completion" />}
                </button>
                <button className="btn-email" onClick={() => viewPdf(doc.id, revisedEsp)}>
                    View PDF
                </button>
            </div>
            {isEmailingrevisedEspSuccess && (
                <p className="button-result savestatus-saved">
                    Email sent successfully
                    <span className="icon savestatus-icon" />
                </p>
            )}
            {isEmailingrevisedEspFail && (
                <p className="button-result savestatus-failed">
                    Email failed <span className="icon savestatus-icon" />
                </p>
            )}
        </div>
    );
};

export default EspLetterListButton;
