import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { getLeaseTenantLinks } from '../../../selectors/lease';
import LinksToolTip from '../../../common/components/tooltips/Links';
import { updateTenantLinks } from '../../../actions/document';
import Links from '../../shared/Links';

import '../../../sass/tenantLinks.scss';

export function TenantLinks({ lease }) {
    const dispatch = useDispatch();

    const initialLinks = useSelector(getLeaseTenantLinks);

    const updateLinks = links => {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/agency/lease/${lease.id}/tenant-links`, links)
                .then(response => {
                    dispatch(updateTenantLinks(links, lease));
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    return (
        <div className="tenant-links">
            <h3 className="section-title">
                Add Relevant Links <LinksToolTip leaseType={lease.leaseType} location={lease.location} />
            </h3>
            {initialLinks && <Links updateLinks={updateLinks} initialLinks={initialLinks} agencyDefault={false} />}
        </div>
    );
}

export default TenantLinks;
