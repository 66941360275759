import React, { Component, MouseEvent } from 'react';

import SelectItem, { Option } from './SelectItem';

type Props = {
    focusIndex: number;
    ItemRenderer?: React.ComponentType<any>;
    options: Option[];
    selected: any[];
    onSelectedChanged: (selected: any) => void;
    onClick: (event: MouseEvent, index: number) => void;
    disabled?: boolean;
};

class SelectList extends Component<Props> {
    handleSelectionChanged = (option: Option, checked: boolean) => {
        const { selected, onSelectedChanged, disabled } = this.props;

        if (disabled) {
            return;
        }

        if (checked) {
            onSelectedChanged([...selected, option.value]);
        } else {
            const index = selected.findIndex(item => item === option.value);
            if (index !== -1) {
                const removed = [...selected.slice(0, index), ...selected.slice(index + 1)];
                onSelectedChanged(removed);
            }
        }
    };

    renderItems() {
        const { ItemRenderer, options, selected, focusIndex, onClick, disabled } = this.props;

        return options.map((o, i) => (
            // eslint-disable-next-line no-prototype-builtins
            <li style={styles.listItem} key={o.hasOwnProperty('key') ? o.key : i}>
                <SelectItem
                    focused={focusIndex === i}
                    option={o}
                    onSelectionChanged={c => this.handleSelectionChanged(o, c)}
                    checked={selected.some(item => item === o.value)}
                    onClick={e => onClick(e, i)}
                    ItemRenderer={ItemRenderer}
                    disabled={o.disabled || disabled}
                />
            </li>
        ));
    }

    render() {
        return (
            <ul className="select-list" style={styles.list}>
                {this.renderItems()}
            </ul>
        );
    }
}

const styles = {
    list: {
        margin: 0,
        paddingLeft: 0
    },
    listItem: {
        listStyle: 'none'
    }
};

export default SelectList;
