import ReactTooltip from 'react-tooltip';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import * as PropTypes from 'prop-types';
import React from 'react';

function DeleteButton(props) {
    return (
        <div className={`btn ${props.className}`} data-test={props.dataTest}>
            <ReactTooltip id="delete" class="tooltip" place="top" effect="solid" globalEventOff="wheel">
                <p>{props.toolTip}</p>
            </ReactTooltip>
            <span>
                <DeleteSharpIcon data-tip={true} data-for="delete" onClick={props.onClick} />
            </span>
        </div>
    );
}

DeleteButton.propTypes = {
    onClick: PropTypes.func,
    toolTip: PropTypes.string
};

export default DeleteButton;
