import React, { memo } from 'react';
import DataString from './DataString';

const EmergencyContactDetails = ({ item }) => {
    const getContactType = contactType => {
        switch (contactType) {
            case 'Email':
                return 'E';
            default:
                return '';
        }
    };
    return (
        <div className="data data-fix-width">
            <p className="name">{item.tradeType}</p>
            <DataString text={[item.firstName, item.secondName].filter(i => i).join(' ')} />
            <DataString title={item.company} />
            <DataString title="Ph" text={item.phone} />
            {item.email && <DataString title="E" text={item.email} />}
            {item.extraContacts.map(extraContact => (
                <DataString
                    title={getContactType(extraContact.contactType)}
                    text={extraContact.contactValue}
                    key={extraContact._id}
                />
            ))}
        </div>
    );
};

export default memo(EmergencyContactDetails);
