import React from 'react';
import { isLeaseLocationQLD, isLeaseTypeSales } from '../../../../../utils/agreementUtils';
import { getLabel } from '../../../../../utils/labelUtils';

import { FormTextRegular } from './../../../../form/FormText';

export const RebateGroup = ({ index, removeRebate, maxLength, location, leaseType }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Rebate {index + 1}</h3>
                    <div className="toggle" onClick={() => removeRebate(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    {isLeaseTypeSales(leaseType) && isLeaseLocationQLD(location) && (
                        <FormTextRegular
                            name={`rebateList[${index}].service`}
                            label="Service"
                            maxLength={maxLength}
                            required
                        />
                    )}
                    <FormTextRegular
                        name={`rebateList[${index}].source`}
                        label="Source"
                        maxLength={maxLength}
                        required
                    />
                    {!(isLeaseTypeSales(leaseType) && isLeaseLocationQLD(location)) && (
                        <FormTextRegular
                            name={`rebateList[${index}].description`}
                            label="Description"
                            maxLength={maxLength}
                            required
                        />
                    )}
                    <FormTextRegular
                        name={`rebateList[${index}].amount`}
                        label={getLabel('rebateAmount', location, leaseType)}
                        required
                    />
                </div>
            </div>
        </div>
    );
};
