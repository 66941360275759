import * as React from 'react';
import { Form } from 'react-final-form';
import { SA_STATE, SUB_LEASE_TYPE_LONG_TERM_RENEWAL } from '../../../config';
import SaRenewalSelect from '../../lease/createNewLease/SaRenewalSelect';

function SaRenewalStartContent({ closeModal, startRenewal, agreementInfo, isStartingRenewal }) {
    return (
        <Form onSubmit={() => {}} initialValues={{ subLeaseType: SUB_LEASE_TYPE_LONG_TERM_RENEWAL }}>
            {({ handleSubmit, values }) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <SaRenewalSelect addressValue={agreementInfo.address} values={values} />
                        <div className="renewal-footer">
                            <div onClick={closeModal}>Cancel</div>
                            <button
                                type="button"
                                className="button button-blue"
                                onClick={() => startRenewal(values)}
                                disabled={isStartingRenewal}
                            >
                                {isStartingRenewal && <span className="blue-spinner-inline" />}
                                Continue
                            </button>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
}

function RenewalStartContent({
    closeModal,
    startRenewal,
    cancelRenewal,
    agreementInfo,
    isStartingRenewal,
    isCancellingRenewal
}) {
    return (
        <>
            <p className="description">{agreementInfo.address}</p>
            <p className="text">Is Tenant renewing this agreement?</p>
            <button type="button" className="button button-white" onClick={() => cancelRenewal()}>
                {isCancellingRenewal && <span className="blue-spinner-inline" />}
                No
            </button>
            <button
                type="button"
                className="button button-blue"
                onClick={() => startRenewal({ isTenantRenewing: true })}
                disabled={isStartingRenewal}
            >
                {isStartingRenewal && <span className="blue-spinner-inline" />}
                Yes
            </button>
            <div className="cancel" onClick={closeModal}>
                Cancel
            </div>
        </>
    );
}

function LeaseRenewalStart(props) {
    return (
        <div className="renewal-start">
            <h2 className="title">Lease agreement renewal for</h2>
            {props.agreementInfo.location === SA_STATE ? (
                <SaRenewalStartContent {...props} />
            ) : (
                <RenewalStartContent {...props} />
            )}
        </div>
    );
}

export default LeaseRenewalStart;
