import update from 'immutability-helper';
import { processServerErrors } from './lease';

export const CLOSE_MODAL_SPECIAL_CONDITION = 'users.account.modal.specialcondition.close';
export const OPEN_MODAL_SPECIAL_CONDITION = 'users.account.modal.specialcondition.open';

export const GET_SPECIAL_CONDITIONS_LIST_REQUEST = 'users.account.specialconditions.list.request';
export const GET_SPECIAL_CONDITIONS_LIST_SUCCESS = 'users.account.specialconditions.list.success';
export const GET_SPECIAL_CONDITIONS_LIST_FAIL = 'users.account.specialconditions.list.fail';

export const ADD_SPECIAL_CONDITION_REQUEST = 'users.account.specialconditions.add.request';
export const ADD_SPECIAL_CONDITION_SUCCESS = 'users.account.specialconditions.add.success';
export const ADD_SPECIAL_CONDITION_FAIL = 'users.account.specialconditions.add.fail';

export const UPDATE_SPECIAL_CONDITION_REQUEST = 'users.account.specialconditions.update.request';
export const UPDATE_SPECIAL_CONDITION_SUCCESS = 'users.account.specialconditions.update.success';
export const UPDATE_SPECIAL_CONDITION_FAIL = 'users.account.specialconditions.update.fail';

export const DELETE_SPECIAL_CONDITION_REQUEST = 'users.account.specialconditions.delete.request';
export const DELETE_SPECIAL_CONDITION_SUCCESS = 'users.account.specialconditions.delete.success';
export const DELETE_SPECIAL_CONDITION_FAIL = 'users.account.specialconditions.delete.fail';

export const CLEAR_SPECIAL_CONDITIONS_TEMPLATES_TO_UPDATE = 'CLEAR_SPECIAL_CONDITIONS_TEMPLATES_TO_UPDATE';

const initState = {
    specialConditionsList: [],
    specialConditionsTemplatesToUpdate: {},
    validationErrors: null,
    isNeedToCloseModal: false,
    isOpenModalSpecialCondition: false,
    modalSpecialCondition: {
        type: null,
        values: {}
    }
};

export default function specialCondition(state = initState, action) {
    switch (action.type) {
        case GET_SPECIAL_CONDITIONS_LIST_SUCCESS: {
            return {
                ...state,
                specialConditionsList: action.result.data.specialConditionsList
            };
        }

        case ADD_SPECIAL_CONDITION_SUCCESS: {
            return {
                ...state,
                specialConditionsList: [...state.specialConditionsList, action.template],
                isOpenModalSpecialCondition: false
            };
        }

        case ADD_SPECIAL_CONDITION_FAIL: {
            return {
                ...state,
                validationErrors: Object.assign(
                    {},
                    state.validationErrors,
                    processServerErrors(action.error.response.data.errors)
                )
            };
        }

        case UPDATE_SPECIAL_CONDITION_SUCCESS: {
            let updatedTemplate = action.template;
            let templatesToUpdate = action.templatesToUpdate;
            let updatedIndex = state.specialConditionsList.findIndex(template => template.id === updatedTemplate.id);

            return update(state, {
                specialConditionsList: {
                    [updatedIndex]: { $set: updatedTemplate }
                },
                isOpenModalSpecialCondition: { $set: false },
                specialConditionsTemplatesToUpdate: { $set: templatesToUpdate }
            });
        }

        case DELETE_SPECIAL_CONDITION_SUCCESS: {
            return {
                ...state,
                specialConditionsList: action.result.data.specialConditionsList,
                isOpenModalSpecialCondition: false,
                templatesToUpdate: action.result.data.templatesToUpdate
            };
        }

        case CLEAR_SPECIAL_CONDITIONS_TEMPLATES_TO_UPDATE: {
            return {
                ...state,
                specialConditionsTemplatesToUpdate: {}
            };
        }

        case OPEN_MODAL_SPECIAL_CONDITION: {
            const { type, values } = action.data;
            return {
                ...state,
                isOpenModalSpecialCondition: true,
                modalSpecialCondition: {
                    ...state.modalSpecialCondition,
                    type,
                    values: type === 'Create' ? {} : values
                },
                validationErrors: null
            };
        }

        case CLOSE_MODAL_SPECIAL_CONDITION: {
            return {
                ...state,
                isOpenModalSpecialCondition: false,
                modalSpecialCondition: {
                    type: null,
                    values: {}
                },
                validationErrors: null
            };
        }

        default: {
            return state;
        }
    }
}
