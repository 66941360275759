import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import '../../../../sass/integrations.scss';
import styles from './InspectionManagerIntegration.module.scss';

import Button from '@app/common/components/Button';
import { CheckboxCheck } from '@app/components/form/FormCheckboxCheck';
import { FormTextRegular } from '@app/components/form/FormText';
import { PreLoader } from '@app/common/components/PreLoader';

import { HIDE_MESSAGE_TIME } from '@app/config';
import useAwake from '@app/hooks/useAwake';
import { updateInspectionManagerIntegration } from '@app/actions/user';
import { getUserAgency, getAgencyIntegrations } from '@app/selectors/agency';

import SaveChangesButton from './SaveChangesButton';
import { AgencyIntegrationType } from './types';

export function InspectionManagerIntegration() {
    const dispatch = useDispatch();
    const agency = useSelector<unknown, { id?: number }>(getUserAgency);
    const integrations = useSelector<unknown, AgencyIntegrationType>(getAgencyIntegrations);
    const inspectionManager = integrations?.inspectionManager;

    const [isConnectionInstructionOpen, toggleConnectionInstruction] = useState(false);

    const { isAwake, wakeup } = useAwake(HIDE_MESSAGE_TIME);

    const onSubmit = useCallback(
        values => {
            dispatch(
                updateInspectionManagerIntegration({
                    agencyId: agency?.id,
                    data: values,
                    // Start waking up only if the actual API call finishes
                    onEnd: () => wakeup()
                })
            );
        },
        [dispatch, wakeup, agency]
    );

    const disconnectInspectionManager = useCallback(() => {
        dispatch(
            updateInspectionManagerIntegration({
                agencyId: agency?.id,
                data: { deleteMe: true, connected: true },
                options: { disconnecting: true }
            })
        );
    }, [dispatch, agency]);

    const isAgencyLoading = !agency;

    const isLoading = !!inspectionManager?.loading;
    const isDisconnecting = !!inspectionManager?.disconnecting;
    const isConnected = !!inspectionManager?.connected;
    const hasError = !!inspectionManager?.hasError;
    const isDisabled = isLoading || isDisconnecting;

    return (
        <Form initialValues={inspectionManager} onSubmit={onSubmit}>
            {({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="panel inspection-manager">
                            <h2 className="panel-header">Inspection Manager (IM)</h2>
                            {isAgencyLoading && <PreLoader />}
                            <div className="panel-body column-box flex-direction-column gap-1">
                                <div className="margin-bottom-1rem">
                                    Inspection manager (IM) integration will allow you to link created leases with an
                                    ingoing inspection report.
                                </div>
                                <div>
                                    <CheckboxCheck
                                        disabled={isDisabled}
                                        name={`shouldRetrieveConditionReport`}
                                        label="Tick this box and FLK + IM will automatically link a new leases with the ingoing condition report found in IM"
                                    />
                                    {isConnected ? (
                                        <div className={styles.vstack}>
                                            <Button
                                                loading={isDisconnecting}
                                                type="button"
                                                onClick={disconnectInspectionManager}
                                                primary
                                                disabled={isLoading}
                                                className="integrations-button-primary"
                                            >
                                                {isDisconnecting ? 'Disconnecting' : 'Disconnect'}
                                            </Button>
                                            <br />
                                            <span className="savestatus-saved">
                                                Connected <span className="icon savestatus-icon" />
                                            </span>
                                        </div>
                                    ) : (
                                        <div className={styles.vstack}>
                                            <FormTextRegular
                                                name={'apiKey'}
                                                label="Inspection Manager API Key *"
                                                disabled={isDisabled}
                                            />
                                            <div>
                                                <div className="question">
                                                    <span />
                                                    <a
                                                        onClick={() => toggleConnectionInstruction(s => !s)}
                                                        className="special-link"
                                                    >
                                                        Connection instructions
                                                    </a>
                                                </div>
                                                {isConnectionInstructionOpen && (
                                                    <div>
                                                        <ol>
                                                            <li>
                                                                Contact Inspection Manager support team to acquire key
                                                            </li>
                                                            <li>
                                                                Please copy the key and paste it in the field above and
                                                                then click save.
                                                            </li>
                                                        </ol>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    Note - this integration currently only works when you have PropertyMe or
                                    PropertyTree integration turned on.
                                </div>
                            </div>
                            <div className={'panel-footer'}>
                                <div />
                                <SaveChangesButton
                                    hasError={hasError}
                                    isLoading={isLoading}
                                    isThrottled={isAwake}
                                    disabled={isDisconnecting}
                                    failMessage="Invalid API Key"
                                />
                            </div>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
}
