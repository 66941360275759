import axios from 'axios';
import * as Account from '../reducers/account';
import * as User from '../reducers/user';
import { kebabCase } from 'lodash';
import { GET_TARIFF_PLAN_SUCCESS } from '../reducers/account';

export function getAgencyInfo(agencyId) {
    return {
        types: [Account.GET_AGENCY_INFO_REQUEST, Account.GET_AGENCY_INFO_SUCCESS, Account.GET_AGENCY_INFO_FAIL],
        promise: axios.get(`/api/agency/${agencyId}`)
    };
}

export function updateAgency(agencyId, data) {
    return () => axios.put(`/api/agency/${agencyId}`, data);
}
export function updateIntegration(agencyId, data) {
    return () => axios.post(`/api/agency/${agencyId}/integration`, data);
}

export function updateAgencyInfo(data) {
    return {
        type: Account.UPDATE_AGENCY_INFO_SUCCESS,
        payload: data
    };
}

export function updateSalesAgencyInfo(data) {
    return {
        type: Account.UPDATE_SALES_AGENCY_INFO_SUCCESS,
        payload: data
    };
}

export function deleteAccountIntegration(type) {
    return {
        types: [
            User.DELETE_ACCOUNT_INTEGRATION_REQUEST,
            User.DELETE_ACCOUNT_INTEGRATION_SUCCESS,
            User.DELETE_ACCOUNT_INTEGRATION_FAIL
        ],
        promise: axios.delete(`/api/integrations/${kebabCase(type)}`),
        integrationType: type
    };
}

export function syncAccountIntegration() {
    return {
        types: [
            User.SYNC_ACCOUNT_INTEGRATION_REQUEST,
            User.SYNC_ACCOUNT_INTEGRATION_SUCCESS,
            User.SYNC_ACCOUNT_INTEGRATION_FAIL
        ],
        promise: axios.get(`/api/integrations/sync`)
    };
}

export function addAgencyLogo(agencyId, logo) {
    return {
        types: [Account.ADD_AGENCY_LOGO_REQUEST, Account.ADD_AGENCY_LOGO_SUCCESS, Account.ADD_AGENCY_LOGO_FAIL],
        promise: axios.post(`/api/agency/${agencyId}/logo`, logo)
    };
}

export function setTariffPlan(agencyId, stripeId) {
    return {
        types: [Account.SET_TARIFF_PLAN_REQUEST, Account.SET_TARIFF_PLAN_SUCCESS, Account.SET_TARIFF_PLAN_FAIL],
        promise: axios.post(`/api/agency/${agencyId}/tariff-plan`, { stripeId: stripeId })
    };
}

export function updateTariffPlan(agencyId, data) {
    return {
        types: [
            Account.UPDATE_TARIFF_PLAN_REQUEST,
            Account.UPDATE_TARIFF_PLAN_SUCCESS,
            Account.UPDATE_TARIFF_PLAN_FAIL
        ],
        promise: axios.patch(`/api/agency/${agencyId}/tariff-plan`, { data })
    };
}

export function fetchTariffPlan(agencyId) {
    return {
        types: [Account.GET_TARIFF_PLAN_REQUEST, Account.GET_TARIFF_PLAN_SUCCESS, Account.GET_TARIFF_PLAN_FAIL],
        promise: axios.get(`/api/agency/${agencyId}/tariff-plan`)
    };
}

export function setTariffPlanRedux(data) {
    return {
        type: GET_TARIFF_PLAN_SUCCESS,
        result: data
    };
}

export function getDefaultPaymentMethod(agencyId) {
    return {
        types: [
            Account.GET_DEFAULT_PAYMENT_METHOD_REQUEST,
            Account.GET_DEFAULT_PAYMENT_METHOD_SUCCESS,
            Account.GET_DEFAULT_PAYMENT_METHOD_FAIL
        ],
        promise: axios.get(`/api/agency/${agencyId}/default-payment-method`)
    };
}

export function cancelTariffPlan(agencyId) {
    return {
        types: [
            Account.CANCEL_TARIFF_PLAN_REQUEST,
            Account.CANCEL_TARIFF_PLAN_SUCCESS,
            Account.CANCEL_TARIFF_PLAN_FAIL
        ],
        promise: axios.delete(`/api/agency/${agencyId}/tariff-plan`)
    };
}

export function updateLicenseDetails(agencyId, licenseDetails) {
    return {
        types: [
            Account.UPDATE_AGENCY_LICENSE_DETAILS_REQUEST,
            Account.UPDATE_AGENCY_LICENSE_DETAILS_SUCCESS,
            Account.UPDATE_AGENCY_LICENSE_DETAILS_FAIL
        ],
        promise: axios.put(`/api/agency/${agencyId}/license-details`, licenseDetails)
    };
}

export function updateAgencyDetails(agencyId, defaultDetails) {
    return {
        types: [
            Account.UPDATE_AGENCY_DEFAULT_DETAILS_REQUEST,
            Account.UPDATE_AGENCY_DEFAULT_DETAILS_SUCCESS,
            Account.UPDATE_AGENCY_DEFAULT_DETAILS_FAIL
        ],
        promise: axios.put(`/api/agency/${agencyId}/details`, defaultDetails)
    };
}

export function updateSalesAgencyDetails(agencyId, defaultDetails) {
    return {
        types: [
            Account.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_REQUEST,
            Account.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_SUCCESS,
            Account.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_FAIL
        ],
        promise: axios.put(`/api/agency/${agencyId}/sales-details`, defaultDetails)
    };
}

export function updateSalesLicenseDetails(agencyId, licenseDetails) {
    return {
        types: [
            Account.UPDATE_SALES_AGENCY_LICENSE_DETAILS_REQUEST,
            Account.UPDATE_SALES_AGENCY_LICENSE_DETAILS_SUCCESS,
            Account.UPDATE_SALES_AGENCY_LICENSE_DETAILS_FAIL
        ],
        promise: axios.put(`/api/agency/${agencyId}/sales-license-details`, licenseDetails)
    };
}

export function createSetupIntent(agencyId) {
    return axios.get(`/api/agency/${agencyId}/create-setup-intent`);
}

export function clearTemplatesToUpdate() {
    return {
        type: Account.CLEAR_TEMPLATES_TO_UPDATE
    };
}
export function setAmountOfDocumentsSentThisMonth(amountOfDocumentsSentThisMonth) {
    return {
        type: Account.SET_AMOUNT_OF_DOCUMENTS_SENT_THIS_MONTH,
        payload: { amountOfDocumentsSentThisMonth }
    };
}
