import React, { memo } from 'react';

const emptyClientList = ['Client 01', 'Client 02', 'Client 03', 'Client 04'];

function SignaturePlaceholdersSummary({ values, annotationsCount, isTemplate }) {
    return (
        <div>
            <h4>Signatures placeholders saved</h4>
            <div className="table-sort">
                <table>
                    <thead>
                        <tr>
                            <th>Client name</th>
                            <th>Amount of signature placeholders</th>
                        </tr>
                    </thead>
                    <tbody>
                        {values.isBulkCreation && (
                            <tr>
                                <td>Client 01</td>
                                <td>
                                    <span className={'count'}>
                                        {annotationsCount && annotationsCount[0] ? annotationsCount[0] : 0}
                                    </span>
                                </td>
                            </tr>
                        )}
                        {!values.isBulkCreation &&
                            !isTemplate &&
                            values.clients.map((client, index) => (
                                <tr key={index}>
                                    <td>{client.name ? client.name : `Client 0${index + 1}`}</td>
                                    <td>
                                        <span className={'count'}>
                                            {annotationsCount && annotationsCount[index] ? annotationsCount[index] : 0}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        {!values.isBulkCreation &&
                            isTemplate &&
                            emptyClientList.map((client, index) => (
                                <tr key={index}>
                                    <td>
                                        {values.clients[index] && values.clients[index].name
                                            ? values.clients[index].name
                                            : client}
                                    </td>
                                    <td>
                                        <span className={'count'}>
                                            {annotationsCount && annotationsCount[index] ? annotationsCount[index] : 0}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default memo(SignaturePlaceholdersSummary);
