import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import { QLD_STATE } from '../../config';
export const UPDATE_CONTACT_FORM = 'dashboard.lease.contact.updateForm';
export const CONTACT_ADD_PERSON = 'dashboard.lease.contact.addperson';
export const CONTACT_REMOVE_PERSON = 'dashboard.lease.contact.removeperson';
import { isLeaseTypePm } from '../../utils/agreementUtils';
import produce from 'immer';

const initState = {
    limitOnCostsOfRepairs: '$',
    contacts: [],
    businessHoursContacts: []
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        // TODO LE needed for PM ?
        case UPDATE_CONTACT_FORM: {
            draftState = action.data;
            break;
        }
        case CONTACT_ADD_PERSON: {
            draftState.contacts = [...draftState.contacts, action.contact];
            break;
        }
        case Lease.CREATE_LEASE_SUCCESS: {
            draftState = {
                contacts: action.result.data.lease.emergencyContacts
            };
            break;
        }
        case Lease.CREATE_PM_LEASE_SUCCESS: {
            if (action.result.data.lease.pmEmergencyContacts && action.result.data.lease.location === QLD_STATE) {
                draftState = action.result.data.lease.pmEmergencyContacts;
            }
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            if (isLeaseTypePm(action.payload.agreementInfo.leaseType)) {
                draftState = Object.assign({}, initState, action.payload.agreementInfo.pmEmergencyContacts);
            } else {
                draftState = Object.assign({}, initState, {
                    contacts: action.payload.agreementInfo.emergencyContacts
                });
            }
            break;
        }
        case CONTACT_REMOVE_PERSON: {
            draftState.contacts = [
                ...draftState.contacts.slice(0, action.index),
                ...draftState.contacts.slice(action.index + 1)
            ];
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            if (isLeaseTypePm(action.lease.leaseType)) {
                draftState = Object.assign({}, initState, action.lease.pmEmergencyContacts);
            } else {
                draftState = Object.assign({}, initState, {
                    contacts: action.lease.emergencyContacts
                });
            }
            break;
        }

        case Lease.LEASE_CONTACTS_SUCCESS: {
            if (isLeaseTypePm(action.result.data.lease.leaseType)) {
                draftState = action.result.data.lease.pmEmergencyContacts;
            } else {
                draftState.contacts = action.result.data.lease.emergencyContacts;
            }
            break;
        }
    }

    return draftState;
});
