import flatten from 'lodash/flatten';
import values from 'lodash/values';
import size from 'lodash/size';
import { createSelector } from 'reselect';
import { getDashboard } from '.';

export const getAgreementList = createSelector([getDashboard], dashboard => dashboard.agreementList);

export const getPageNav = createSelector([getDashboard], ({ pageNav }) => pageNav);
export const getSortOptions = createSelector([getDashboard], ({ sortOptions }) => sortOptions);

export const getHasAgreements = createSelector([getAgreementList], agreements => {
    return agreements ? size(flatten(values(agreements))) > 0 : false;
});

export const getIsPending = createSelector([getDashboard], ({ isPending }) => isPending);

export const getHasInitialAgreementListRequested = createSelector(
    [getDashboard],
    ({ initialAgreementListRequested }) => initialAgreementListRequested
);

export const getRefreshPage = createSelector([getDashboard], dashboard => dashboard.refreshPage);
export const getForceRefreshPage = createSelector([getDashboard], dashboard => dashboard.forceRefreshPage);
export const getSocketUpdateDetails = createSelector([getDashboard], dashboard => dashboard.socketUpdateDetails);
