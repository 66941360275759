import {
    GET_AGENCY_FULL_TEAM_SUCCESS,
    GET_AGENCY_FULL_TEAM_FAIL,
    GET_AGENCY_FULL_TEAM_REQUEST
} from '../actions/types';

const initState = {
    team: [],
    deletedUsers: [],
    isRequestingTeam: false
};

export default function login(state = initState, action) {
    switch (action.type) {
        case GET_AGENCY_FULL_TEAM_REQUEST:
            return {
                ...state,
                isRequestingTeam: true
            };
        case GET_AGENCY_FULL_TEAM_SUCCESS:
            return {
                ...state,
                team: action.result.data.team,
                deletedUsers: action.result.data.deletedUsers || [],
                isRequestingTeam: false
            };
        case GET_AGENCY_FULL_TEAM_FAIL: {
            return {
                ...state,
                team: [],
                isRequestingTeam: false
            };
        }
    }

    return state;
}
