import React from 'react';
import { getPmStepNo, getPmStepTitle } from '../../../../../../utils/agreementUtils.js';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection.js';
import { LeaseAllInfo } from '../../../../../types';
import { formatCurrencyWithCurrencySign } from '../../../../../../utils/formUtils.js';

interface AgreementPmFeeProps {
    leaseAllInfo: LeaseAllInfo;
}

const AgreementPmFee: React.FC<AgreementPmFeeProps> = ({ leaseAllInfo }) => {
    const { pmFees, location } = leaseAllInfo;
    if (!pmFees) {
        return null;
    }
    return (
        <React.Fragment>
            <AgreementSection
                title={getPmStepTitle(location, 'pmFees')}
                step={getPmStepNo(location, 'pmFees')}
                leaseAllInfo={leaseAllInfo}
            >
                <div className="agreement-section-text">
                    <div className="propertyDetails">
                        <p className="item">Management fee:</p>
                        {pmFees.managementFee && pmFees.managementFeeIncGST ? (
                            <strong className="item">
                                {pmFees.managementFee}%&nbsp;(
                                {pmFees.managementFeeIncGST})
                            </strong>
                        ) : (
                            'Nil'
                        )}
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Letting/re-letting fee:</p>
                        {pmFees.lettingFee && pmFees.lettingFeeIncGST ? (
                            <strong className="item">
                                {pmFees.lettingFee}&nbsp;(
                                {pmFees.lettingFeeIncGST})
                            </strong>
                        ) : (
                            'Nil'
                        )}
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Lease renewal fee:</p>
                        {pmFees.leaseRenewalFee && pmFees.leaseRenewalFeeIncGST ? (
                            <strong className="item">
                                {pmFees.leaseRenewalFee}&nbsp;(
                                {pmFees.leaseRenewalFeeIncGST})
                            </strong>
                        ) : (
                            'Nil'
                        )}
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Rent review:</p>
                        {pmFees.rentReview && pmFees.rentReviewIncGST ? (
                            <strong className="item">
                                {pmFees.rentReview}&nbsp;(
                                {pmFees.rentReviewIncGST})
                            </strong>
                        ) : (
                            'Nil'
                        )}
                    </div>
                    <p className="margin-top-2rem">Other fees (inclusive of GST)</p>
                    <div className="propertyDetails">
                        <p className="item">Outgoing account payment fee:</p>
                        <strong className="item">{pmFees.outgoingFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Landlord insurance claim:</p>
                        <strong className="item">{pmFees.insuranceClaimFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Lease preparation fee:</p>
                        <strong className="item">{pmFees.leasePreparationFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Administration fee:</p>
                        <strong className="item">{pmFees.administrationFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Ingoing/outgoing Inspection fee:</p>
                        <strong className="item">{pmFees.ingoingOutgoingInspectionFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Periodic inspection fee:</p>
                        <strong className="item">{pmFees.periodicInspectionFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Furniture inventory:</p>
                        <strong className="item">{pmFees.furnitureInventoryFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Tribunal hearing:</p>
                        <strong className="item">{pmFees.tribunalHearingsFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Maintenance organisation:</p>
                        <strong className="item">{pmFees.maintenanceOrganisationFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Document preparation:</p>
                        <strong className="item">{pmFees.documentPreparationFee || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Legal fees for debt recovery:</p>
                        <strong className="item">{pmFees.debtRecoveryFees || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Annual reports:</p>
                        <strong className="item">{pmFees.reportFees || 'Nil'}</strong>
                    </div>
                    <div className="propertyDetails">
                        <p className="item">Oversee refurbishment:</p>
                        <strong className="item">{pmFees.overseeRefurbishmentFee || 'Nil'}</strong>
                    </div>
                    {pmFees.additionalFeesList.length > 0 && <p className="margin-top-2rem">Custom fees</p>}

                    {pmFees.additionalFeesList.map((fee: any, index: number) => {
                        return (
                            <div key={index} className="propertyDetails">
                                <p className="item">{fee.description}:</p>
                                <strong className="item">
                                    {fee.amount}&nbsp;({fee.incGST})
                                </strong>
                            </div>
                        );
                    })}
                </div>
            </AgreementSection>
        </React.Fragment>
    );
};

export default AgreementPmFee;
