import * as React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getPmStepNo } from '../../../../../utils/agreementUtils';

const AgreementPmTenantPayments = ({ leaseAllInfo }) => {
    return (
        <div>
            {!!leaseAllInfo.tenantPayments && (
                <AgreementSection
                    title="Tenant Payments"
                    step={getPmStepNo(leaseAllInfo.location, 'tenantPayments')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <div>
                            {leaseAllInfo.tenantPayments.electricity && (
                                <p className="name">
                                    Electricity
                                    <strong>
                                        {leaseAllInfo.tenantPayments.electricityProportion
                                            ? `: ${leaseAllInfo.tenantPayments.electricityProportion}%`
                                            : ''}
                                    </strong>
                                </p>
                            )}
                            {leaseAllInfo.tenantPayments.gas && (
                                <p className="name">
                                    Gas
                                    <strong>
                                        {leaseAllInfo.tenantPayments.gasProportion
                                            ? `: ${leaseAllInfo.tenantPayments.gasProportion}%`
                                            : ''}
                                    </strong>
                                </p>
                            )}
                            {leaseAllInfo.tenantPayments.telephone && (
                                <p className="name">
                                    Telephone
                                    <strong>
                                        {leaseAllInfo.tenantPayments.telephoneProportion
                                            ? `: ${leaseAllInfo.tenantPayments.telephoneProportion}%`
                                            : ''}
                                    </strong>
                                </p>
                            )}
                            {leaseAllInfo.tenantPayments.serviceList.map((service, index) => {
                                return (
                                    <p key={index} className="name">
                                        {service.description}
                                        <strong>{service.proportion ? `: ${service.proportion}%` : ''}</strong>
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                </AgreementSection>
            )}
        </div>
    );
};

export default AgreementPmTenantPayments;
