import React from 'react';
import { cloneDeep } from 'lodash';

import Button from '../../../common/components/Button';
import RenewalRentForm from './forms/RenewalRentForm';
import appHistory from '../../../AppHistory';
import { LEASE_STATUS_DRAFT } from '../../../actions/dashboard';
import { LEASE_TYPE_RESIDENTIAL } from '../../../config';
import { useOpenAgreement } from '../../../hooks/useOpenAgreement';

function LeaseRenewalForm({
    closeModal,
    changeRenewalForm,
    renewal,
    agreementInfo,
    validationErrors,
    lease,
    finishRenewal
}) {
    const { openModalInfoAgreement } = useOpenAgreement();

    function submitRenewalForm() {
        let newRenewal = cloneDeep(renewal);
        newRenewal.rent.amount = renewal.rent.unRoundedAmount ? renewal.rent.unRoundedAmount : renewal.rent.amount;
        newRenewal.rent.newRentAmount = renewal.rent.unRoundedNewAmount
            ? newRenewal.rent.unRoundedNewAmount
            : newRenewal.rent.newRentAmount;

        if (newRenewal.rent.isRentIncrease) {
            newRenewal.rent.rentIncreaseList = [{ rentIncrease: newRenewal.rent.rentIncreaseList[0] }];
        } else {
            newRenewal.rent.rentIncreaseList = [];
        }
        //Url change will wait until promise fulfilled
        finishRenewal(lease.id, newRenewal).then(response => {
            appHistory.push(`/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${LEASE_STATUS_DRAFT}`);
            openModalInfoAgreement(response.data.lease, LEASE_TYPE_RESIDENTIAL);
        });
    }
    return (
        <div className="modal-content">
            <div className="modal-header">
                <div>
                    <h2 className="title">Lease agreement renewal for</h2>
                    <p className="description">{agreementInfo.address}</p>
                </div>
                <div className="close" onClick={closeModal} />
            </div>
            <div className="modal-body">
                <div className="lease-renewal-content">
                    <RenewalRentForm
                        renewal={renewal}
                        validationErrors={validationErrors}
                        lease={lease}
                        changeRenewalForm={changeRenewalForm}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <Button primary onClick={submitRenewalForm}>
                    Create new draft
                    <i className="icon-doc" />
                </Button>
            </div>
        </div>
    );
}

export default LeaseRenewalForm;
