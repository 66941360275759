import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmAgreementTerm } from '../../../../selectors/lease/pmLease';

import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import '../../../../sass/management/agreementTerm.scss';
import { FormTextRegular } from '../../../../components/form/FormText';
import FormCheckboxGroup from '../../../../components/form/FormCheckboxGroup';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';

const PM_AGREEMENT_TERM_FORM = 'pmAgreementTermForm';
const initState = {
    availableFrom: '',
    startDate: '',
    endDate: '',
    propertyAvailabilityDate: '',
    noticePeriod: 30,
    applicableAppointment: '',
    tenancyPeriods: {
        isFixed: false,
        isPeriodic: false,
        isExistingTenancy: false
    }
};

const AgreementTerm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmAgreementTerm = useSelector(getPmAgreementTerm);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmAgreementTerm, setPmAgreementTerm] = useState(reduxPmAgreementTerm || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_AGREEMENT_TERM_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmAgreementTerm(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmAgreementTerm')) {
                    return error.response.data.errors.pmAgreementTerm;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmAgreementTerm = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-agreement-term`, data);
    };

    useEffect(() => {
        if (pmAgreementTerm.startDate && pmAgreementTerm.availableFrom) {
            setPmAgreementTerm({
                ...pmAgreementTerm,
                startDate: new Date(pmAgreementTerm.startDate),
                availableFrom: new Date(pmAgreementTerm.availableFrom)
            });
        } else {
            setPmAgreementTerm({ ...pmAgreementTerm, startDate: new Date() });
        }
    }, []);

    return (
        <div className="agreementTerm">
            <Form onSubmit={submitForm} initialValues={pmAgreementTerm}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off" id={PM_AGREEMENT_TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <h3>Appointment</h3>
                            <br />
                            <FormRadioGroup
                                label="Select the applicable appointment"
                                required
                                radioGroupClass="wrapped-radio-group"
                                name="applicableAppointment"
                                value={values.applicableAppointment}
                                data={[
                                    {
                                        label: 'The Agent is appointed as the sole exclusive agent to manage and lease out the Property',
                                        value: 'The Agent is appointed as the sole exclusive agent to manage and lease out the Property'
                                    },
                                    {
                                        label: 'The Agent is appointed only to lease the Property',
                                        value: 'The Agent is appointed only to lease the Property'
                                    }
                                ]}
                            />
                            {/* <FormCheckboxGroup
                                label="Select the applicable appointment*"
                                groupName="applicableAppointments"
                                checkboxes={[
                                    {
                                        name: 'isAgentAppointedToManageProperty',
                                        label:
                                            'The Agent is appointed to manage the Property and in each case as Sole Manager or Letting Agent'
                                    },
                                    {
                                        name: 'isAgentAppointedToLetProperty',
                                        label: 'The Agent is appointed to let the Property as required'
                                    }
                                ]}
                                className="margin-bottom-1rem"
                            /> */}
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Available from"
                                    name="availableFrom"
                                    selected={values.availableFrom}
                                    required
                                />
                            </div>
                            <FormTextRegular
                                name="preferenceForLeaseTerm"
                                label="Landlord’s preference for lease term"
                                required
                            />
                            <FormCheckboxGroup
                                label="Authority to offer the following tenancy periods to potential or existing tenants*"
                                groupName="tenancyPeriods"
                                selectAll
                                form={form}
                                checkboxes={[
                                    {
                                        name: 'isFixed',
                                        label: 'Fixed'
                                    },
                                    {
                                        name: 'isPeriodic',
                                        label: 'Periodic'
                                    },
                                    {
                                        name: 'isExistingTenancy',
                                        label: 'Existing tenancy'
                                    }
                                ]}
                                className="margin-bottom-1rem"
                            />
                            <h3 className="margin-bottom-1rem">Term</h3>
                            <FormRadioGroup
                                label="Initial appointment term of"
                                required
                                radioGroupClass="wrapped-radio-group"
                                name="initialAppointmentTerm"
                                value={values.initialAppointmentTerm}
                                data={[
                                    {
                                        label: '12 months from commencement',
                                        value: '12 months from commencement'
                                    },
                                    {
                                        label: 'Other',
                                        value: 'Other'
                                    }
                                ]}
                            />
                            {values.initialAppointmentTerm === 'Other' && (
                                <FormTextRegular
                                    name="otherInitialAppointmentTerm"
                                    label="If Other, state term"
                                    required
                                />
                            )}
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Start date"
                                    name="startDate"
                                    selected={values.startDate}
                                    required
                                />
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(AgreementTerm);
