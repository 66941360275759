import React, { useState, useEffect } from 'react';
import { cloneDeep, isEmpty, pullAt } from 'lodash';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';

import PhoneIphoneSharp from '@material-ui/icons/PhoneIphoneSharp';
import Email from '@material-ui/icons/EmailSharp';

import { FormTextRegular } from '../../components/form/FormText';
import Button from '../../common/components/Button';
import { formatUrl } from '../../config';
import { CheckboxCheck } from '../../components/form/FormCheckboxCheck';

const Links = ({ updateLinks, initialLinks, agencyDefault, leaseType, hideMobile }) => {
    const [valid, setValid] = useState(false);
    const [serverError, setServerError] = useState('');
    const [tenantLinks, setTenantLinks] = useState(initialLinks);
    const [newTenantLink, setNewTenantLink] = useState({
        title: undefined,
        url: undefined,
        includeInMobile: false,
        includeInEmail: false
    });

    useEffect(() => {
        setTenantLinks(initialLinks);
    }, [initialLinks, leaseType]);

    const validate = values => {
        if (isEmpty(values.title)) {
            setValid(false);
            return;
        }
        if (isEmpty(values.url)) {
            setValid(false);
            return;
        }

        if (!values.includeInEmail && !values.includeInMobile) {
            setValid(false);
            return;
        }

        setValid(true);
    };

    const clearForm = () => {
        setNewTenantLink({});
    };

    const onUpdateLinks = links => {
        updateLinks(links)
            .then(() => {
                clearForm();
                setTenantLinks(links);
            })
            .catch(() => {
                setServerError('Something went wrong, please try again');
            });
    };

    const addNewLink = values => {
        setServerError('');
        let newLinks = cloneDeep(tenantLinks);
        values.agencyDefault = agencyDefault;
        values.leaseType = leaseType;
        if (!newLinks.links) {
            newLinks.links = [];
        }
        newLinks.links.unshift(values);
        onUpdateLinks(newLinks);
    };

    const removeLink = index => {
        setServerError('');
        let newLinks = cloneDeep(tenantLinks);
        pullAt(newLinks.links, index);
        onUpdateLinks(newLinks);
    };

    const handleFormChange = values => {
        validate(values);
    };

    const handleIncludeMobileChange = (value, values) => {
        setNewTenantLink({ ...values, includeInMobile: value });
        validate(values);
    };
    const handleIncludeEmailChange = (value, values) => {
        setNewTenantLink({ ...values, includeInEmail: value });
        validate(values);
    };

    return (
        <Form onSubmit={() => {}} initialValues={newTenantLink}>
            {({ values }) => {
                return (
                    <React.Fragment>
                        <form>
                            <FormSpy onChange={state => handleFormChange(state.values)} />
                            <FormTextRegular name="title" label="Title" />
                            <FormTextRegular name="url" label="URL" />
                            {!hideMobile && (
                                <div className="checkbox-with-icon">
                                    <CheckboxCheck
                                        name={`includeInMobile`}
                                        label="Include in Mobile"
                                        value={values.includeInMobile}
                                        onClick={value => handleIncludeMobileChange(value, values)}
                                    />
                                    <PhoneIphoneSharp />
                                </div>
                            )}
                            <div className="checkbox-with-icon">
                                <CheckboxCheck
                                    name={`includeInEmail`}
                                    label="Include in Email"
                                    value={values.includeInEmail}
                                    onClick={value => handleIncludeEmailChange(value, values)}
                                />
                                <Email />
                            </div>
                        </form>
                        <div className="has-error"> {serverError}</div>
                        <Button onClick={() => addNewLink(values)} primary disabled={!valid}>
                            Add link
                        </Button>
                        <h4>Attached links</h4>
                        <ul className="links-list">
                            {tenantLinks &&
                                tenantLinks.links.map((link, index) => (
                                    <li className="link-box" key={index}>
                                        <div className="link-box-left">
                                            <div className="link-url-wrapper">
                                                <div>{link.title}</div>
                                                <a target="_blank" rel="noopener noreferrer" href={formatUrl(link.url)}>
                                                    {link.url}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="link-box-right">
                                            <div className="link-icons">
                                                {link.includeInMobile && <PhoneIphoneSharp />}
                                                {link.includeInEmail && <Email />}
                                                <button
                                                    className="btn-delete"
                                                    type="button"
                                                    onClick={() => removeLink(index)}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </React.Fragment>
                );
            }}
        </Form>
    );
};

Links.propTypes = {
    updateLinks: PropTypes.func,
    postUpdateDispatch: PropTypes.func,
    initialLinks: PropTypes.object,
    agencyDefault: PropTypes.bool
};

export default Links;
