import React, { memo } from 'react';
import {
    ADDITIONAL_SWIMMINGPOOL_MAINTAIN_OWNER,
    ADDITIONAL_SWIMMINGPOOL_MAINTAIN_TENANT
} from '../../../../../reducers/lease/additional/state';
import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../../config';
import { getResTenLabel } from '../../../../../utils/labelUtils';
import { FormRadioGroup } from '../../../../form/FormRadioGroup';
import { FormTextRegular } from '../../../../form/FormText';

const SwimmingPool = ({ values, hasSwimingPoolMaintenance, hasSwimmingPoolMaintenanceDuration, location }) => {
    return (
        <React.Fragment>
            <FormRadioGroup
                label="Is there a swimming pool?"
                name={'swimmingPool'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'swimming-pool-yes',
                        id: 'swimming-pool-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'swimming-pool-no',
                        id: 'swimming-pool-no',
                        value: NO_VALUE
                    }
                ]}
            />
            {values.swimmingPool === YES_VALUE && (
                <p className="text-warning">
                    <span className="icon icon-warning" /> You will be required to upload the 'Swimming pool compliance
                    certificate' in the documents section.
                </p>
            )}
            {values.swimmingPool === YES_VALUE && hasSwimingPoolMaintenance && (
                <div className="additional-group">
                    <div className="swimmingPool">
                        <div className="formBox-column">
                            <FormRadioGroup
                                label="Who is responsible for pool maintenance and costs?"
                                name={'swimingPoolMaintenance'}
                                className="additional-term-item"
                                data={[
                                    {
                                        label: getResTenLabel('tenant', location),
                                        className: 'maintenance-responsible-tenant',
                                        value: ADDITIONAL_SWIMMINGPOOL_MAINTAIN_TENANT
                                    },
                                    {
                                        label: getResTenLabel('landlordPoolMaintenance', location),
                                        className: 'maintenance-responsible-owner',
                                        value: ADDITIONAL_SWIMMINGPOOL_MAINTAIN_OWNER
                                    }
                                ]}
                            />
                        </div>
                        {values.swimingPoolMaintenance === ADDITIONAL_SWIMMINGPOOL_MAINTAIN_OWNER &&
                            hasSwimmingPoolMaintenanceDuration && (
                                <div className="formBox-column">
                                    <FormTextRegular
                                        name="swimmingPoolMaintenanceDuration"
                                        label="Frequency of regularly scheduled maintenance:"
                                    />
                                    <p className="text-warning">
                                        <span className="icon icon-warning" />
                                        <b>This will display as below in your agreement:</b>
                                    </p>
                                    <p className="text-gray">
                                        The contractor will attend to the maintenance of the pool{' '}
                                        <b>{values.swimmingPoolMaintenanceDuration}</b>
                                    </p>
                                </div>
                            )}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default memo(SwimmingPool);
