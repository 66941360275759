import { VerifyEmail } from '@app/components/signup';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

type VerifyEmailStepProps = {
    userId: string;
    email: string;
};

const VerifyEmailStep: React.FC<VerifyEmailStepProps> = ({ userId, email }) => {
    const resendVerificationEmailMutation = useMutation({
        mutationFn: () => axios.post('/auth/resend-verification-email', { userId }),
        onSuccess: () => {
            confirmAlert({
                title: '',
                message: `Verification email has been sent to ${email}`,
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ]
            });
        }
    });

    return <VerifyEmail emailAddress={email} onResendEmail={resendVerificationEmailMutation.mutate} />;
};

export default VerifyEmailStep;
