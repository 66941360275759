import React from 'react';
import cx from 'classnames';
import styles from './Tag.module.scss';

enum BorderRadius {
    Default,
    Square
}

enum Color {
    Default,
    Grey,
    Green,
    Purple,
    Orange,
    Yellow,
    Blue,
    Red,
    Pink,
    Mint,
    Aqua,
    Error,
    Warning,
    White,
    InputError
}

enum Size {
    Default,
    X_Small,
    Small,
    Large
}

enum Border {
    None,
    Default
}

type TagContentProps = {
    text: string;
    textClassName?: string;
    startIcon?: React.ReactElement;
    onClickStartIcon?: () => void;
    endIcon?: React.ReactElement;
    onClickEndIcon?: () => void;
    disabled?: boolean;
};

const TagContent: React.FC<TagContentProps> = ({
    text,
    startIcon,
    onClickStartIcon,
    endIcon,
    onClickEndIcon,
    textClassName,
    disabled
}) => {
    const clonedStartIcon = startIcon
        ? React.cloneElement(startIcon, {
              className: cx(startIcon.props.className, styles.icon)
          })
        : undefined;

    const clonedEndIcon = endIcon
        ? React.cloneElement(endIcon, {
              className: cx(endIcon.props.className, styles.icon)
          })
        : undefined;

    return (
        <>
            {onClickStartIcon ? (
                <button type="button" className={styles.iconButton} onClick={onClickStartIcon} disabled={disabled}>
                    {clonedStartIcon}
                </button>
            ) : (
                clonedStartIcon
            )}
            <span className={cx(styles.text, textClassName)}>{text}</span>
            {onClickEndIcon ? (
                <button type="button" className={styles.iconButton} onClick={onClickEndIcon} disabled={disabled}>
                    {clonedEndIcon}
                </button>
            ) : (
                clonedEndIcon
            )}
        </>
    );
};

// We cannot have a clickable tag with clickable elements inside it
type ClickableTagProps = Omit<TagContentProps, 'onClickStartIcon' | 'onClickEndIcon'> & {
    onClick: () => void;
};

type TagProps = (ClickableTagProps | TagContentProps) & {
    borderRadius?: BorderRadius;
    color?: Color;
    size?: Size;
    border?: Border;
    className?: string;
    onClick?: () => void;
};

const Tag: React.FC<TagProps> = ({
    className,
    onClick,
    borderRadius = BorderRadius.Default,
    color = Color.Default,
    size = Size.Default,
    border = Border.Default,
    disabled,
    ...rest
}) => {
    const composedClassName = cx(styles.tag, className, {
        [styles.squareTag]: borderRadius === BorderRadius.Square,
        [styles.greyTag]: color === Color.Grey,
        [styles.greenTag]: color === Color.Green,
        [styles.purpleTag]: color === Color.Purple,
        [styles.orangeTag]: color === Color.Orange,
        [styles.yellowTag]: color === Color.Yellow,
        [styles.blueTag]: color === Color.Blue,
        [styles.redTag]: color === Color.Red,
        [styles.pinkTag]: color === Color.Pink,
        [styles.mintTag]: color === Color.Mint,
        [styles.aquaTag]: color === Color.Aqua,
        [styles.errorTag]: color === Color.Error,
        [styles.whiteTag]: color === Color.White,
        [styles.inputErrorTag]: color === Color.InputError,
        [styles.xSmallTag]: size === Size.X_Small,
        [styles.smallTag]: size === Size.Small,
        [styles.largeTag]: size === Size.Large,
        [styles.warningTag]: color === Color.Warning,
        [styles.noBorder]: border === Border.None,
        [styles.disabled]: disabled
    });

    // If we have clickable tag props, render inside a button, otherwise render in a div
    return onClick ? (
        <button className={composedClassName} onClick={onClick} type="button" disabled={disabled}>
            <TagContent {...rest} disabled={disabled} />
        </button>
    ) : (
        <div className={composedClassName}>
            <TagContent {...rest} disabled={disabled} />
        </div>
    );
};

export { Tag, BorderRadius as TagBorderRadius, Color as TagColor, Size as TagSize, Border as TagBorder };
