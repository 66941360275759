import React, { Component } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../../utils/agreementUtils';
import { STEP_OUTGOINGS } from '../../../../../lease/steps';
import { YES_LABEL } from '../../../../../../config';

class AgreementResidentialOutgoings extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.outgoings && (
                    <AgreementSection
                        title="Outgoings"
                        step={getResTenStepNo(leaseAllInfo.location, STEP_OUTGOINGS, leaseAllInfo.subLeaseType)}
                        leaseAllInfo={leaseAllInfo}
                    >
                        <div>
                            <p className="text">
                                The tenants are required to pay water chargers and usage as details below
                            </p>
                            {leaseAllInfo.outgoings.isWaterUsageCostAdjusted === true && (
                                <p className="text">All water usage costs adjusted for the period of tenancy</p>
                            )}
                            {leaseAllInfo.outgoings.isWaterUsageCostExcess === true && (
                                <p className="text">
                                    All water usage costs in excess of {leaseAllInfo.outgoings.klPerAnnum}kl per annum,
                                    with such allowance to be adjusted for the period of the tenancy
                                </p>
                            )}
                            {leaseAllInfo.outgoings.isWaterSupplyCostAdjusted === true && (
                                <p className="text">All water supply charges adjusted for the period of the tenancy</p>
                            )}
                            {leaseAllInfo.outgoings.isNoCharges === true && <p className="text">No charge for water</p>}
                            {leaseAllInfo.outgoings.isOthers === true && (
                                <p className="text">{leaseAllInfo.outgoings.otherDetails}</p>
                            )}
                            {leaseAllInfo.outgoings.servicesList.length > 0 && <b>Service / Apportionment</b>}
                            {leaseAllInfo.outgoings.servicesList.map((service, index) => {
                                return (
                                    <p key={index} className="text">
                                        Service: {service.service}
                                        <br />
                                        Apportionment: {service.apportionment}
                                    </p>
                                );
                            })}
                            <br />
                            <p>
                                <b>Details of embedded electricity network</b>
                            </p>
                            <p className="text">
                                Is electricity supplied to the property via an embedded network?{' '}
                                <b>{leaseAllInfo.outgoings.isElectricitySupplied}</b>
                            </p>
                            <br />
                            {leaseAllInfo.outgoings.isElectricitySupplied === YES_LABEL && (
                                <>
                                    <p className="text">
                                        Details of the retailer of the embedded network (please include the contact
                                        information, ABN, website and any applicable electricity tariffs):
                                        <br />
                                        {leaseAllInfo.outgoings.retailerDetails}
                                    </p>
                                    <br />
                                    <p className="text">
                                        Information about the nature and benefits of participating in an embedded
                                        network (and any potential consequences):
                                        <br />
                                        {leaseAllInfo.outgoings.natureAndBenefits}
                                    </p>
                                    <br />
                                    <p className="text">
                                        Information about metering arrangements in relation to the embedded network and
                                        any potential costs of participating in the embedded network:
                                        <br />
                                        {leaseAllInfo.outgoings.meteringArrangements}
                                    </p>
                                    <br />
                                    <p className="text">
                                        Cost apportionments per kilowatt hour for any bundled utilities arising from
                                        participating in the embedded network:
                                        <br />
                                        {leaseAllInfo.outgoings.costApportionments}
                                    </p>
                                </>
                            )}
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementResidentialOutgoings;
