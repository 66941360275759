import axios from 'axios';

import {
    UPDATE_CONNECTIONS_REQUEST,
    UPDATE_CONNECTIONS_SUCCESS,
    UPDATE_CONNECTIONS_FAIL,
    GET_CONNECTIONS_REQUEST,
    GET_CONNECTIONS_SUCCESS,
    GET_CONNECTIONS_FAIL,
    GET_CONNECTIONS_COMPANIES_REQUEST,
    GET_CONNECTIONS_COMPANIES_SUCCESS,
    GET_CONNECTIONS_COMPANIES_FAIL,
    CLEAR_CONNECTIONS_VALIDATION_ERRORS
} from './types';

export function updateConnections(data) {
    return {
        types: [UPDATE_CONNECTIONS_REQUEST, UPDATE_CONNECTIONS_SUCCESS, UPDATE_CONNECTIONS_FAIL],
        promise: axios.post('/api/agency/account/connections', data)
    };
}

export function getConnections() {
    return {
        types: [GET_CONNECTIONS_REQUEST, GET_CONNECTIONS_SUCCESS, GET_CONNECTIONS_FAIL],
        promise: axios.get('/api/agency/account/connections')
    };
}

export function getConnectionCompanies() {
    return {
        types: [GET_CONNECTIONS_COMPANIES_REQUEST, GET_CONNECTIONS_COMPANIES_SUCCESS, GET_CONNECTIONS_COMPANIES_FAIL],
        promise: axios.get('/api/connections/companies')
    };
}

export function clearValidationErrors() {
    return {
        type: CLEAR_CONNECTIONS_VALIDATION_ERRORS
    };
}
