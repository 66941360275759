import React from 'react';

export default function AgencySupportText() {
    return (
        <div className="settings">
            <div className="customer-support">
                <h1>Support</h1>
                <p>
                    Having trouble with something? we’re here to help!
                    <br />
                    <br />
                    Click on the below link to be directed to our help centre for assistance navigating your dashboard,{' '}
                    <br />
                    frequently asked questions, and training videos.
                    <br />
                    <br />
                    <a href="https://help.flkitover.com/" rel="noreferrer" target="_blank" className="btn-primary">
                        Help centre
                    </a>
                </p>
                <br />
                <p>
                    Alternatively, you can get in touch with a friendly team member through our live chat by clicking
                    the <br />
                    bubble on the bottom right of your dashboard.
                </p>
                <br />
                <p>
                    If you’re matter is urgent, please contact our support team on 0481 611 212 or send your request to{' '}
                    <br />
                    <a href="mailto:support@flkitover.com?subject=Support request - FLK Real Estate ">
                        support@flkitover.com
                    </a>
                </p>
            </div>
        </div>
    );
}
