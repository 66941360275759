import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { union } from 'lodash';
import DocumentUpload from '../../../containers/shared/DocumentUpload';
import {
    getDefaultDocuments,
    getDefaultDocumentsByLeaseType,
    getDocumentTemplatesToUpdate
} from '../../../selectors/settings/account';
import { getUserAgency } from '../../../selectors/agency';
import { UPDATE_DEFAULT_DOCUMENTS_SUCCESS } from '../../../reducers/account';
import axios from 'axios';
import { formatDocumentsForApi } from '../../../utils/formUtils';
import { useEffect, useState } from 'react';
import Panel from '../../../common/components/Panel';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmUpdateTemplates from '../../../containers/settings/UpdateTemplatesConfirmation';
import { clearDocumentTemplatesToUpdate } from '../../../actions/document';
import { viewUploadedAgencyDocument } from '../../../config';

export function DefaultDocuments({ leaseType }) {
    const dispatch = useDispatch();
    const defaultDocuments = useSelector(getDefaultDocuments);
    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
    const templatesToUpdate = useSelector(getDocumentTemplatesToUpdate);
    const defaultDocumentsForLeaseType = useSelector(state => getDefaultDocumentsByLeaseType(state, leaseType));
    const agency = useSelector(getUserAgency);
    const [backendError, setBackendError] = useState(null);
    const [busy, setBusy] = useState(null);

    const clearTemplates = () => {
        dispatch(clearDocumentTemplatesToUpdate());
    };

    useEffect(() => {
        if (templatesToUpdate && templatesToUpdate.templates && templatesToUpdate.templates.length > 0) {
            confirmAlert({
                customUI: ConfirmUpdateTemplates(templatesToUpdate, clearTemplates, () => {}, 'DOCUMENTS'),
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templatesToUpdate]);

    const updateDefaultDocuments = function(data) {
        setBackendError(null);
        setDocumentUploadProgress(0);
        const docsNotChanged = defaultDocuments.filter(item => item.leaseType !== leaseType);
        setBusy(true);
        const docsForUpdate = union(data, docsNotChanged);
        let documents = formatDocumentsForApi([...docsForUpdate]);
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                setDocumentUploadProgress(Math.floor((loaded * 100) / total));
            }
        };
        axios
            .post(`api/agency/${agency.id}/${leaseType}/default-agency-documents`, documents, options)
            .then(result => {
                setBusy(false);
                dispatch({
                    type: UPDATE_DEFAULT_DOCUMENTS_SUCCESS,
                    result
                });
            })
            .catch(error => {
                setBusy(false);
                if (error.response.data.errors && error.response.data.errors.documents) {
                    setBackendError(error.response.data.errors.documents);
                } else {
                    setBackendError('Something went wrong, please try again');
                }
            });
    };

    return (
        <Panel title="Default documents">
            <p className="text-gray">
                Using the tool below, please upload any documents you wish to attach to your agreements by default.
            </p>
            <DocumentUpload
                uploading={busy}
                agencyId={agency.id}
                viewDocument={docId => viewUploadedAgencyDocument(agency.id, docId)}
                updateDocuments={updateDefaultDocuments}
                documents={defaultDocumentsForLeaseType}
                backendError={backendError}
                documentUploadProgress={documentUploadProgress}
                leaseType={leaseType}
                additionalOptionsForDocument={{ agencyDefault: true }}
                maxFileSize={100}
                maxTotalSize={100}
            />
        </Panel>
    );
}

export default DefaultDocuments;
