import React, { memo } from 'react';
import '../../../sass/dashboardContentTable.scss';
import EntryNoticeActive from './tables/entryNotice/EntryNoticeActive';
import EntryNoticeCancelled from './tables/entryNotice/EntryNoticeCancelled';
import EntryNoticeDraft from './tables/entryNotice/EntryNoticeDraft';
import { DOC_STATUS_CANCELLED, LEASE_STATUS_DRAFT } from '../../../actions/dashboard';

const EntryNoticeListings = ({ documentList, docType, agentSignature, docStatus }) => {
    switch (docStatus) {
        case LEASE_STATUS_DRAFT: {
            return (
                <div className="table-sort">
                    <EntryNoticeDraft documentList={documentList} docType={docType} agentSignature={agentSignature} />
                </div>
            );
        }
        case DOC_STATUS_CANCELLED: {
            return (
                <div className="table-sort">
                    <EntryNoticeCancelled
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
        default: {
            return (
                <div className="table-sort">
                    <EntryNoticeActive documentList={documentList} docType={docType} agentSignature={agentSignature} />
                </div>
            );
        }
    }
};

export default memo(EntryNoticeListings);
