import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Table, Th, Tr, Td, Thead } from 'reactable';

import '../../sass/invoice.scss';

class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    Date: '21 Feb 2017',
                    Invoice: '#A-1232-00 Bright residential',
                    Period: '21 Feb – 30 Mar 2017',
                    Tax: '$81',
                    Ammount: '$801',
                    Paid: 'no'
                },
                {
                    Date: '22 Feb 2017',
                    Invoice: '#B-1232-00 Bright residential',
                    Period: '21 Feb – 24 Mar 2017',
                    Tax: '$82',
                    Ammount: '$802',
                    Paid: 'yes'
                },
                {
                    Date: '23 Feb 2017',
                    Invoice: '#C-1232-00 Bright residential',
                    Period: '21 Feb – 05 Mar 2017',
                    Tax: '$82',
                    Ammount: '$803',
                    Paid: 'yes'
                },
                {
                    Date: '24 Feb 2017',
                    Invoice: '#D-1232-00 Bright residential',
                    Period: '21 Feb – 31 Mar 2017',
                    Tax: '$83',
                    Ammount: '$804',
                    Paid: 'yes'
                },
                {
                    Date: '25 Feb 2017',
                    Invoice: '#G-1232-00 Bright residential',
                    Period: '21 Feb – 31 Mar 2017',
                    Tax: '$84',
                    Ammount: '$805',
                    Paid: 'yes'
                },
                {
                    Date: '26 Feb 2017',
                    Invoice: '#R-1232-00 Bright residential',
                    Period: '21 Feb – 31 Mar 2017',
                    Tax: '$85',
                    Ammount: '$806',
                    Paid: 'yes'
                },
                {
                    Date: '27 Feb 2017',
                    Invoice: '#h-1232-00 Bright residential',
                    Period: '21 Feb – 31 Mar 2017',
                    Tax: '$86',
                    Ammount: '$807',
                    Paid: 'yes'
                }
            ]
        };
    }
    render() {
        const { data } = this.state;

        const contentTableTr = data.map((step, key) => {
            let paid = <span className="icon-no" />;
            if (step.Paid == 'yes') {
                paid = <span className="icon-yes" />;
            }
            return (
                <Tr key={key}>
                    <Th column="Date">{step.Date}</Th>
                    <Th column="Invoice">
                        <Link to="/">{step.Invoice}</Link>
                    </Th>
                    <Th column="Period">{step.Period}</Th>
                    <Th column="Tax">{step.Tax}</Th>
                    <Th column="Ammount">{step.Ammount}</Th>
                    <Th column="Paid">{paid}</Th>
                </Tr>
            );
        });

        const contentTable = (
            <Table
                sortable={[
                    {
                        column: 'Date',
                        sortFunction: function(a, b) {
                            return new Date(a).getTime() - new Date(b).getTime();
                        }
                    }
                ]}
                defaultSort={{ column: 'Date', direction: 'desc' }}
            >
                <Thead>
                    <Th column="Date">
                        <p>Date</p> <span className="icon" />
                    </Th>
                    <Th column="Invoice">Invoice</Th>
                    <Th column="Period">Period</Th>
                    <Th column="Tax">Tax</Th>
                    <Th column="Ammount">Ammount</Th>
                    <Th column="Paid">Paid</Th>
                </Thead>
                {contentTableTr}
            </Table>
        );

        return (
            <div>
                <div className="invoice-block">
                    <div className="name">Invoices</div>
                    <div className="table">{contentTable}</div>
                </div>
            </div>
        );
    }
}

export default connect(state => ({}), {})(Invoice);
