import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { updateLeaseInfo } from '../../actions/lease';

import Button from '../../common/components/Button';
import ModalDialog from '../../common/components/ModalDialog';
import { getIsOpenCancelFutureAlertsConfirmModal, getConfirmModalLease } from '../../selectors/confirmModals';
import { closeCancelFutureAlertsConfirmModal } from '../../actions/confirmModals';

import styles from './ConfirmModal.module.scss';

function CancelFutureAlertsConfirmModal() {
    const dispatch = useDispatch();

    const isOpen = useSelector(getIsOpenCancelFutureAlertsConfirmModal);
    const lease = useSelector(getConfirmModalLease);

    function closeModal() {
        dispatch(closeCancelFutureAlertsConfirmModal());
    }
    const cancelFutureAlerts = useMutation(
        () => {
            return axios.post(`/api/agency/lease/${lease.id}/cancel-renewal-notice-to-leave-future-alerts`);
        },
        {
            // We should do this in here. We can't access updated data inside handleSubmit function
            onSuccess: data => {
                dispatch(updateLeaseInfo(data.data.lease));
            }
        }
    );
    return (
        <ModalDialog
            isOpen={isOpen}
            title="Cancel all future scheduled alert(s)"
            closeModal={() => closeModal()}
            shouldCloseOnOverlayClick={false}
            className={styles.confirmModal}
        >
            This will cancel all future scheduled alert(s) related to this lease as listed below:
            <ul className={styles.itemList}>
                {lease.renewalNoticeToLeaveDetails.reminderEmailDateFormatted && (
                    <li>
                        The Reminder to renew lease was scheduled to send on{' '}
                        {lease.renewalNoticeToLeaveDetails.reminderEmailDateFormatted}
                    </li>
                )}
                {lease.renewalNoticeToLeaveDetails.noticeToLeaveDateFormatted && (
                    <li>
                        Tenant Notice to Leave was scheduled to send on{' '}
                        {lease.renewalNoticeToLeaveDetails.noticeToLeaveDateFormatted}
                    </li>
                )}
            </ul>
            Do you wish to proceed?
            <div className={styles.footerButtons}>
                <Button
                    type="button"
                    onClick={async () => {
                        cancelFutureAlerts.reset();
                        await cancelFutureAlerts.mutateAsync();
                        closeModal();
                    }}
                    primary
                    className={styles.actionButton}
                    loading={cancelFutureAlerts.isLoading}
                >
                    Proceed
                </Button>
                <Button
                    type="button"
                    onClick={() => {
                        closeModal();
                    }}
                    secondary
                    className={styles.actionButton}
                    loading={cancelFutureAlerts.isLoading}
                >
                    Cancel
                </Button>
            </div>
        </ModalDialog>
    );
}

export default CancelFutureAlertsConfirmModal;
