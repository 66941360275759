import React, { memo } from 'react';
import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../../config';
import { FormRadioGroup } from '../../../../form/FormRadioGroup';
import DatePickerInAgencyTimeZone from '../../../../form/DatePickerInAgencyTimeZone';
import { getAgencyTimezoneDate } from '../../../../../utils/dateUtils';

const LeaseRenewal = ({ values, loggedInUser }) => {
    return (
        <div className="leaseRenewal">
            <FormRadioGroup
                label="LEASE RENEWAL: Use original condition report?"
                name={'isLeaseRenewalEnabled'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'is-lease-renewal-enabled-yes',
                        id: 'is-lease-renewal-enabled-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'is-lease-renewal-enabled-no',
                        id: 'is-lease-renewal-enabled-no',
                        value: NO_VALUE
                    }
                ]}
            />
            {values.isLeaseRenewalEnabled === YES_VALUE && (
                <div className="additional-group calendar">
                    <div className="formBox-column">
                        <DatePickerInAgencyTimeZone
                            label="Condition report from:"
                            maxValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                            name="leaseRenewalDate"
                            selected={values.leaseRenewalDate}
                            popperPlacement="top-start"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(LeaseRenewal);
