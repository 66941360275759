import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, matchPath, useLocation } from 'react-router';
import { openLeaseAgreementForm } from '../actions/lease';
import Layout from './Layout';
import UpdateBanner from '../components/banners/UpdateBanner';

import '../sass/dashboard.scss';
import {
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    isMobileWidth,
    createViaUrlEnabledTypes,
    DOCUMENT_CUSTOM,
    LEASE_TYPE_SALES,
    NSW_STATE
} from '../config';
import AgreementList from './dashboard/AgreementList';
import DocumentList from '../containers/dashboard/DocumentList';
import FlkAKeyList from '../containers/dashboard/FlkAKeyList';
import { LEASE_STATUS_DRAFT } from '../actions/dashboard';
import { getMobileViewEnabled } from '../selectors/dashboard';
import { useHistory } from 'react-router-dom';
import Agreement from './dashboard/Agreement';
import Document from './dashboard/Document';
import FlkAKey from './dashboard/FlkAKey';
import { useAccountProvider } from '../components/providers/AccountProvider';
import { isAgencyUserAccount, isBusinessUserAccount } from '../utils/userUtils';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { BUSINESS_GLOBAL_BANNER, FLK_B_MOBILE_RESPONSIVE } from '../constants/featureFlags';
import SubscriptionBanner from '../components/banners/SubscriptionBanner';
import DocumentBanner from '../components/banners/DocumentBanner';
import useQueryParams from '../hooks/useQueryParams';
import useOpenCreationModal from '../hooks/useOpenCreationModal';
import { getUserInfo, getAgencyDefaultLocation } from '../selectors/user';
import { SALES_SUBAPP_PATH, PM_SUBAPP_PATH } from '../constants/constants';
import { openSubapp } from '../utils/openSubapp';

const DashboardLayout = () => {
    const dispatch = useDispatch();
    const query = useQueryParams();
    const createLeaseTypeFromUrl = query.get('create');
    const history = useHistory();
    const location = useLocation();
    const mobileViewEnabled = useSelector(getMobileViewEnabled);
    const loggedInUser = useSelector(getUserInfo);
    const agencyLocation = useSelector(getAgencyDefaultLocation);
    const accountType = useAccountProvider();

    const isFlkBMobileResponsivenessEnabled = useFeatureFlag(FLK_B_MOBILE_RESPONSIVE);
    const isBusinessGlobalBannerEnabled = useFeatureFlag(BUSINESS_GLOBAL_BANNER);

    const { openLeaseAgreementCreationModal, isLeaseTypeEnabled } = useOpenCreationModal();

    useEffect(() => {
        document.title = 'Dashboard';
    }, []);

    useEffect(() => {
        if (
            createLeaseTypeFromUrl &&
            createViaUrlEnabledTypes.includes(createLeaseTypeFromUrl) &&
            isLeaseTypeEnabled(createLeaseTypeFromUrl) &&
            agencyLocation
        ) {
            if (createLeaseTypeFromUrl === LEASE_TYPE_SALES && agencyLocation === NSW_STATE) {
                openSubapp(`${SALES_SUBAPP_PATH}${location.search}`);
            } else if (createLeaseTypeFromUrl === LEASE_TYPE_PROPERTY_MANAGEMENT && agencyLocation === NSW_STATE) {
                openSubapp(`${PM_SUBAPP_PATH}${location.search}`);
            } else {
                openLeaseAgreementCreationModal(createLeaseTypeFromUrl, true);
            }
        }
    }, [createLeaseTypeFromUrl, loggedInUser, agencyLocation]);

    const openLeaseAgreement = type => {
        dispatch(openLeaseAgreementForm(type));
    };

    const agreementRedirect = data => {
        const leaseType = data.match.params.leaseType || LEASE_TYPE_RESIDENTIAL;
        const leaseStatus = data.match.params.leaseStatus || LEASE_STATUS_DRAFT;
        return <Redirect to={`/dashboard/agreements/${leaseType}/${leaseStatus}`} />;
    };

    const getDashboardRoutes = () => {
        return (
            <Switch>
                <Route exact path="/" component={AgreementList} />
                <Route path="/dashboard/agreement/:leaseId" component={Agreement} />
                <Route path="/dashboard/document/:docId" component={Document} />
                <Route exact path="/dashboard/flk-a-key/:keyStatus/:keyId" component={FlkAKey} />
                <Route exact path="/dashboard/documents/:docType/:docStatus" component={DocumentList} />
                <Route exact path="/dashboard/flk-a-key/:keyStatus" component={FlkAKeyList} />
                <Route exact path="/dashboard/:leaseType" component={agreementRedirect} />
                <Route exact path="/dashboard/:leaseType/:leaseStatus" component={agreementRedirect} />
                <Route exact path="/dashboard/agreements/:leaseType" component={agreementRedirect} />
                <Route exact path="/dashboard/agreements/:leaseType/:leaseStatus" component={AgreementList} />
            </Switch>
        );
    };

    const openMobileFormView = () => {
        if (matchPath(location.pathname, { path: `/dashboard/documents/:docType/:docStatus` })) {
            openCreateUploadADoc();
        } else if (matchPath(location.pathname, { path: `/dashboard/agreements/${LEASE_TYPE_PROPERTY_MANAGEMENT}` })) {
            openPmLeaseAgreement();
        }
    };
    const openCreateUploadADoc = () => {
        history.push(`/dashboard/documents/${DOCUMENT_CUSTOM}/draft?create=document_custom`);
    };

    // For mobile only create button
    const openPmLeaseAgreement = () => {
        history.push(`/dashboard/agreements/${LEASE_TYPE_PROPERTY_MANAGEMENT}/${LEASE_STATUS_DRAFT}`);
        openLeaseAgreement(LEASE_TYPE_PROPERTY_MANAGEMENT);
    };

    return (
        <Layout>
            <div className="content">
                {isBusinessGlobalBannerEnabled && isBusinessUserAccount(accountType) && <DocumentBanner />}
                <UpdateBanner />
                <SubscriptionBanner />
                {getDashboardRoutes()}
                {mobileViewEnabled &&
                    isMobileWidth() &&
                    (isAgencyUserAccount(accountType) ||
                        (isBusinessUserAccount(accountType) && isFlkBMobileResponsivenessEnabled)) && (
                        <div className="button-create">
                            <button type="button" className="button" onClick={openMobileFormView} />
                        </div>
                    )}
            </div>
        </Layout>
    );
};
export default memo(DashboardLayout);
