import axios from 'axios';
import { GET_EMAIL_TEMPLATE, UPDATE_EMAIL_TEMPLATE } from '../reducers/emailTemplate';

export function getAllEmailTemplates() {
    return {
        types: [GET_EMAIL_TEMPLATE.REQUEST, GET_EMAIL_TEMPLATE.SUCCESS, GET_EMAIL_TEMPLATE.FAIL],
        promise: axios.get(`/api/email-template`)
    };
}

export function updateEmailTemplate(data) {
    return {
        types: [UPDATE_EMAIL_TEMPLATE.REQUEST, UPDATE_EMAIL_TEMPLATE.SUCCESS, UPDATE_EMAIL_TEMPLATE.FAIL],
        promise: axios.put(`/api/email-template`, data)
    };
}
