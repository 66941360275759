import * as React from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import Button from '../../common/components/Button';
import ModalDialog from './../../common/components/ModalDialog';
import { isAddressDetailsTouched } from '../../utils/formUtils';
import '../../sass/editAddressModal.scss';

import { closeEditAddressModal, updateAddress } from '../../actions/dashboard';

import FormAddressDetailsAutocomplete from '../../components/form/FormAddressDetailsAutocomplete';

class EditAddressModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addressToEdit: {},
            addressSet: false
        };
    }

    closeModal = () => {
        this.props.closeModal();
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ addressSet: false, addressToEdit: { addressToEditString: nextProps.addressToEdit } });
    }

    updateAddress = () => {
        this.props.updateAddress(this.props.leaseId, this.state.addressToEdit);
    };

    changeForm = form => {
        if (isAddressDetailsTouched(form)) {
            let unitDisplay = '';
            if (form.values.subpremise) {
                unitDisplay = `${form.values.subpremise}/`;
            }
            // eslint-disable-next-line prettier/prettier
            form.values.addressToEditString = `${unitDisplay}${form.values.street_number || ''} ${form.values.route || ''}, ${form.values.locality || ''} ${form.values.administrative_area_level_1 || ''} ${form.values.postal_code || ''}`;
        }
        this.setState({
            addressToEdit: form.values
        });
    };

    handleAddressChange(setValue, address) {
        this.setState({
            addressSet: false
        });
        setValue('addressToEditString', address);
    }

    setAddressDetails(address, details) {
        this.setState({
            addressSet: true,
            addressToEdit: { addressToEditString: address, ...details }
        });
    }

    render() {
        let { isOpen } = this.props;
        let { addressToEdit } = this.state;

        return (
            <ModalDialog
                title="Edit Address details"
                isOpen={isOpen}
                closeModal={this.closeModal}
                className="edit-address-modal-dialog"
                zIndex={7}
            >
                <Form onSubmit={() => {}} initialValues={addressToEdit}>
                    {({ submitForm, form, values }) => {
                        return (
                            <form onSubmit={submitForm} autoComplete="off">
                                <FormSpy onChange={state => this.changeForm(state)} />
                                <FormAddressDetailsAutocomplete
                                    name="addressToEditString"
                                    editMode={this.props.addressDetails.editMode}
                                    detailsConfirmed={this.props.addressDetails.detailsConfirmed}
                                    value={values.addressToEditString}
                                    showAddressBelow={true}
                                    label={this.props.cloneLeaseId ? 'Address for clone' : 'Address'}
                                    onChange={address => this.handleAddressChange(form.change, address)}
                                    setAddressDetails={(address, details) => this.setAddressDetails(address, details)}
                                />
                            </form>
                        );
                    }}
                </Form>
                <Button primary onClick={this.updateAddress} disabled={!this.state.addressSet}>
                    Confirm address details
                </Button>
            </ModalDialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        isOpen: state.dashboard.isEditAddressModalOpen,
        addressToEdit: state.dashboard.addressToEdit,
        leaseId: state.dashboard.leaseIdToEdit,
        addressDetails: state.addressDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => {
            dispatch(closeEditAddressModal());
        },
        updateAddress: (leaseId, addressData) => {
            dispatch(updateAddress(leaseId, addressData));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddressModal);
