import { connect } from 'react-redux';
import { AgreementSignatures } from '../../../components/agreementSignatures';
import { getCommercialLeaseSignatures } from '../../../selectors/dashboard/agreementSignatures';
import { getLeaseType } from '../../../selectors/lease';

export default connect(state => ({
    signatures: getCommercialLeaseSignatures(state),
    leaseType: getLeaseType(state),
    showWitnessSignature: true
}))(AgreementSignatures);
