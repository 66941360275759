import React from 'react';
import cx from 'classnames';
import { useImmer } from 'use-immer';
import * as Accordion from '@radix-ui/react-accordion';

import { DndContext, DragEndEvent, DragStartEvent, UniqueIdentifier, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

import Icon, { Icons } from '../../../../../common/components/Icon';
import Button from '../../../../../common/components/Button.js';
import { QuestionUiModel } from '../BuildADoc.model';
import Clause from './Clause';

import styles from './ClausesAccordion.module.scss';

type ClausesProps = {
    clauseFields: QuestionUiModel[];
    onReorderClauseFields: (activeIndex: number, overIndex: number) => void;
    onAddClauseField: () => void;
    onRemoveClauseField: (index: number) => void;
    sectionDisplayNameLowercase: string;
    isReadOnly?: boolean;
};

const ClausesAccordion: React.FC<ClausesProps> = ({
    clauseFields,
    onAddClauseField,
    onRemoveClauseField,
    onReorderClauseFields,
    sectionDisplayNameLowercase,
    isReadOnly
}) => {
    const lastClauseRef = React.useRef<HTMLDivElement>(null);
    // We use this ref to create a unique id each time a clause is added
    const uniqueClauseId = React.useRef(0);

    // We use the index of the clause when added as part of the ID, as the name will change when re-ordering
    // Note: There is a dnd-kit bug with falsy ID's that prevents us from using numbers as IDs:
    // https://github.com/clauderic/dnd-kit/issues/858
    const [clausesOpenState, setClausesOpenState] = useImmer(
        clauseFields.map((_, index) => ({
            isOpen: index === clauseFields.length - 1,
            id: `clause-${uniqueClauseId.current++}`
        }))
    );

    const [isReordering, setIsReordering] = React.useState(false);

    const [draggingClauseId, setDraggingClauseId] = React.useState<UniqueIdentifier>();

    const addClause = () => {
        onAddClauseField();

        // Increment uniqueClauseId to guarantee uniqueness
        uniqueClauseId.current++;
        setClausesOpenState(draftClauses =>
            draftClauses
                .map(clause => ({ ...clause, isOpen: false }))
                .concat({ id: `clause-${uniqueClauseId.current++}`, isOpen: true })
        );

        // Scroll into view after the new clause is rendered
        requestAnimationFrame(() => {
            lastClauseRef.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        });
    };

    const removeClause = (index: number) => {
        onRemoveClauseField(index);

        setClausesOpenState(draftClauses => {
            draftClauses.splice(index, 1);
        });
    };

    const reorderClauses = (draggingClauseId: UniqueIdentifier, overClauseId: UniqueIdentifier) => {
        const activeIndex = clausesOpenState.findIndex(clause => clause.id === draggingClauseId);
        const overIndex = clausesOpenState.findIndex(clause => clause.id === overClauseId);

        onReorderClauseFields(activeIndex, overIndex);
        setClausesOpenState(draft => arrayMove(draft, activeIndex, overIndex));
    };

    const handleDragStart = (dragEvent: DragStartEvent) => {
        setDraggingClauseId(dragEvent.active.id);
    };

    const handleDragEnd = (dragEvent: DragEndEvent) => {
        const { active, over } = dragEvent;

        if (over && active.id !== over.id) {
            reorderClauses(active.id, over.id);
        }

        setDraggingClauseId(undefined);
    };

    return (
        <>
            <Button
                quaternary
                className={cx(styles.reorderButton, { [styles.enabled]: isReordering })}
                disabled={clauseFields.length === 1 || isReadOnly}
                type="button"
                onClick={() => setIsReordering(oldValue => !oldValue)}
                startIcon={<Icon icon={Icons.DRAG} />}
            >
                {isReordering ? 'Done' : 'Re-order'}
            </Button>
            {/* TODO: Wrap this as its own component */}
            <Accordion.Root
                className={styles.accordion}
                type="multiple"
                value={isReordering ? [] : clausesOpenState.filter(clause => clause.isOpen).map(clause => clause.id)}
                onValueChange={openClauses => {
                    setClausesOpenState(draft =>
                        draft.map((clause, index) => {
                            const clauseId = clausesOpenState[index].id;
                            return { ...clause, isOpen: openClauses.includes(clauseId) };
                        })
                    );
                }}
            >
                <DndContext
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={clausesOpenState.map(clause => clause.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        {clauseFields.map((clauseField, index) => {
                            const { id, isOpen } = clausesOpenState[index];
                            const name = `questions[${index}]`;

                            return (
                                <Clause
                                    sectionDisplayNameLowercase={sectionDisplayNameLowercase}
                                    isDragging={draggingClauseId === id}
                                    name={name}
                                    key={id}
                                    id={id}
                                    isOpen={isOpen}
                                    shouldShowRemoveButton={clauseFields.length > 1}
                                    onRemoveClause={() => removeClause(index)}
                                    isReordering={isReordering}
                                    ref={index === clauseFields.length - 1 ? lastClauseRef : undefined}
                                    isReadOnly={isReadOnly}
                                    {...clauseField}
                                />
                            );
                        })}
                    </SortableContext>
                </DndContext>
            </Accordion.Root>
            <Button
                className={styles.addClauseButton}
                type="button"
                onClick={addClause}
                disabled={isReordering || isReadOnly}
                tertiary
            >
                <Icon icon={Icons.PLUS} className={styles.addClauseIcon} />
                Add another {sectionDisplayNameLowercase}
            </Button>
        </>
    );
};

export default ClausesAccordion;
