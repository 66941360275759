import React, { Component } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../../utils/agreementUtils';
import { STEP_EXCLUSIONS } from '../../../../../lease/steps';

class AgreementResidentialExclusions extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.exclusions && (
                    <AgreementSection
                        title="Premises Exclusions"
                        step={getResTenStepNo(leaseAllInfo.location, STEP_EXCLUSIONS, leaseAllInfo.subLeaseType)}
                        leaseAllInfo={leaseAllInfo}
                    >
                        <div>
                            {leaseAllInfo.exclusions.exclusions && (
                                <p className="text">List of exclusions: {leaseAllInfo.exclusions.exclusions}.</p>
                            )}
                            <p className="text">Do the Premises meet Minimum Housing Standards: {leaseAllInfo.exclusions.meetsMinStandards}.</p>
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementResidentialExclusions;
