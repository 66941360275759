import React, { memo, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import '../../../sass/materialFacts.scss';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import { OtherMaterialFactsGroup } from '../../../components/lease/mainScreen/common/OtherMaterialFactsGroup';
import { initState as materialFactsDefault } from '../../../reducers/lease/materialFacts/state';
import { forwardRef } from 'react';
import { getDirtyStep, getLeaseType, getLocation, getMaterialFacts, getStep } from '../../../selectors/lease';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import axios from 'axios';
import * as Lease from '../../../reducers/lease';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { has } from 'lodash';

const MATERIAL_FACTS_FORM = 'materialFactsForm';

const MaterialFacts = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxMaterialFacts = useSelector(getMaterialFacts);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    let [materialFacts, setMaterialFacts] = useState(reduxMaterialFacts);
    let [showWarning, setShowWarning] = useState(false);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(MATERIAL_FACTS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const addOtherMaterialFact = push => {
        push('other', {
            fact: '',
            answer: 'yes'
        });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateMaterialFacts = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/material-facts`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateMaterialFacts(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_MATERIAL_FACTS_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.materialFacts')) {
                    return error.response.data.errors.materialFacts;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="materialFacts">
            <p className="help-text">
                Please select any material facts you would like to disclose and have included in the lease.
            </p>
            <Form
                onSubmit={submitForm}
                initialValues={materialFacts}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form id={MATERIAL_FACTS_FORM} onSubmit={handleSubmit}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            {Object.keys(materialFactsDefault).map((key, index) => {
                                if (!(key === 'other' || key === 'otherDisclosures')) {
                                    return (
                                        <React.Fragment key={index}>
                                            <CheckboxCheck
                                                name={`${key}.enabled`}
                                                label={materialFactsDefault[key].fact}
                                                className={`${key}`}
                                                labelClassName="align-justify"
                                                inputDataTest={'material-checkbox'}
                                            />
                                            {values[key] && values[key].enabled && (
                                                <FormRadioGroup
                                                    label=""
                                                    name={`${key}.answer`}
                                                    data={[
                                                        {
                                                            label: 'Yes',
                                                            className: `${key}-answer-yes`,
                                                            value: 'yes',
                                                            id: `material${key}-answer-yes`
                                                        },
                                                        {
                                                            label: 'No',
                                                            className: `${key}-answer-no`,
                                                            value: 'no',
                                                            id: `material${key}-answer-no`
                                                        }
                                                    ]}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                }
                            })}
                            <FieldArray name="other" initialValue={values.other}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <OtherMaterialFactsGroup
                                                    index={index}
                                                    key={index}
                                                    removeOtherMaterialFact={() => fields.remove(index)}
                                                />
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button">
                                <button type="button" onClick={() => addOtherMaterialFact(push)}>
                                    Add a custom material fact
                                </button>
                            </div>
                            <h4>OTHER DISCLOSURES</h4>
                            <p className="help-text">
                                Disclosure of sale, mortgagee actions A landlord or landlord’s agent must disclose the
                                following to the tenant before the tenant enters into the residential tenancy agreement—
                            </p>
                            {Object.keys(materialFactsDefault.otherDisclosures).map((key, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <CheckboxCheck
                                            name={`otherDisclosures.${key}.enabled`}
                                            label={materialFactsDefault.otherDisclosures[key].fact}
                                            className={`${key}`}
                                            labelClassName="align-justify"
                                            inputClassName={`${key}`}
                                            inputDataTest={'material-checkbox'}
                                        />
                                        {values.otherDisclosures && values.otherDisclosures[key].enabled && (
                                            <FormRadioGroup
                                                label=""
                                                name={`otherDisclosures.${key}.answer`}
                                                data={[
                                                    {
                                                        label: 'Yes',
                                                        className: `${key}-answer-yes`,
                                                        value: 'yes',
                                                        id: `otherDisclosures${key}answer-yes`
                                                    },
                                                    {
                                                        label: 'No',
                                                        className: `${key}-answer-no`,
                                                        value: 'no',
                                                        id: `otherDisclosures${key}answer-no`
                                                    }
                                                ]}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(MaterialFacts);
