import React from 'react';
import { isEmpty, map } from 'lodash';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo, getSalesStepTitle, isLeaseLocationNSW, isLeaseLocationQLD } from '../../../../../utils/agreementUtils';

export default function AgreementSalesRebates({ leaseAllInfo }) {
    const { disclosureOfRebates, location } = leaseAllInfo;
    const partName = 'disclosureOfRebates';

    if (!disclosureOfRebates) {
        return <></>;
    }
    return (
        <AgreementSection
            title={
                isLeaseLocationNSW(location)
                    ? 'Disclosure of rebates, discounts, commissions or other benefits'
                    : getSalesStepTitle(location, partName)
            }
            step={getSalesStepNo(location, partName)}
            leaseAllInfo={leaseAllInfo}
        >
            <div className="agreement-section-text">
                {isEmpty(disclosureOfRebates.rebateList) ? (
                    <p>No rebates exist for this Sale.</p>
                ) : (
                    <div>
                        <ul>
                            {map(disclosureOfRebates.rebateList, (value, index) => {
                                return (
                                    <div className="agreement-section-text__item" key={index}>
                                        {isLeaseLocationQLD(location) ? (
                                            <>
                                                Service: <strong>{value.service}</strong> Source:{' '}
                                                <strong>{value.source}</strong> Amount: <strong>{value.amount}</strong>
                                            </>
                                        ) : (
                                            <>
                                                Source: <strong>{value.source}</strong> Description:{' '}
                                                <strong>{value.description}</strong> Amount:
                                                <strong>{value.amount}</strong>
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </AgreementSection>
    );
}
