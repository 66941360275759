import React from 'react';
import * as PropTypes from 'prop-types';
import { getHeaderTitle } from '../../../../../../utils/formUtils';
import * as dashboard from '../../../../../../actions/dashboard';
import SavingIndicator from '../../../../../../components/dashboard/components/SavingIndicator';
import EditableDocumentTitle from '../../../../../../components/form/EditableDocumentTitle';

export const DocumentHeaderLeftPublic = ({ doc, handleUpdateDocumentTitle, isReadOnlyTemplate }) => {

    const headerTitle = getHeaderTitle(doc.docType);


    return (
        <div className="header-left">
            <div className="header-left-wrapper">
                <div>
                    <div className="header-title">
                        <h1>{headerTitle}</h1>
                        <SavingIndicator />
                    </div>
                    <React.Fragment>
                        {doc.status === dashboard.LEASE_STATUS_DRAFT && (
                            <div className="editable">
                                <EditableDocumentTitle
                                    originalDocumentTitle={doc.documentTitle}
                                    saveContent={handleUpdateDocumentTitle}
                                    disabled={isReadOnlyTemplate}
                                />
                            </div>
                        )}
                        {doc.status !== dashboard.LEASE_STATUS_DRAFT && (
                            <div className="header-container address-info for-lg-modal">
                                <label>
                                    <p>
                                        <b>Title: </b>
                                    </p>
                                </label>
                                <p>{doc.documentTitle}</p>
                            </div>
                        )}
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
};

DocumentHeaderLeftPublic.propTypes = {
    doc: PropTypes.object.isRequired
};

