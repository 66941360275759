import React, { memo } from 'react';
import { FormTextRegular } from '../../../../components/form/FormText';
import { FormTextMultiline } from '../../../../components/form/FormText';

function MarketingAndAdvertising() {
    return (
        <>
            <FormTextMultiline
                name="specialInstructions"
                label="Special Instructions for advertisement and promotion of the property"
                required={true}
            />
            <FormTextRegular name="authorisedAdvertisingAmount" label="Authorised Advertising Amount" required />
            <FormTextRegular name="whenPayable" label="When Payable" required />
        </>
    );
}

export default memo(MarketingAndAdvertising);
