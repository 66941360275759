import React from 'react';
import { FormTextRegular } from '../form/FormText';
import { FormEmail } from '../form/FormEmail';

const DocumentTenantPersonGroup = ({ index, removeTenant, showHeader, onChangeEmail, disabled }) => {
    let header;
    if (showHeader) {
        header = <h3>Tenant {index + 1}</h3>;
    } else {
        header = (
            <span className="removePerson">
                <h3>Tenant {index + 1}</h3>
                <button type="button" onClick={() => removeTenant(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="tenant">
            {header}
            <div className="formBox">
                <div className="formBox-column">
                    <div className="nowrap large">
                        <FormTextRegular
                            name={`tenants[${index}].firstName`}
                            label="First name"
                            required
                            disabled={disabled}
                        />
                        <FormTextRegular
                            name={`tenants[${index}].middleName`}
                            label="Middle name"
                            disabled={disabled}
                        />
                    </div>
                    <FormTextRegular
                        name={`tenants[${index}].secondName`}
                        label="Last name"
                        required
                        disabled={disabled}
                    />
                    <FormEmail
                        name={`tenants[${index}].email`}
                        label="Email"
                        required
                        onChange={value => onChangeEmail(value, index)}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default DocumentTenantPersonGroup;
