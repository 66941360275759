import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { Select } from './Select';
import { getError, handleInputValueChange } from '../../utils/formUtils';
import cx from 'classnames';
import Suggestion from '../suggestions/Suggestion';
import { isEmpty } from 'lodash';

export const SelectField = React.forwardRef(
    (
        {
            selectClassName,
            onChange,
            name,
            options,
            disabled,
            required,
            // Default option - { value, label } object
            defaultValue,
            containerClassName,
            labelClassName,
            label,
            // This prop adds a visual and accessibility hidden label for alignment purposes
            addLabelSpacing,
            validateOnTouch,
            dataTest,
            suggestionsList,
            removeSuggestion,
            suggestionIcon,
            ...selectProps
        },
        ref
    ) => {
        // because we parse values we need to put them back in order
        // for react-select to understand which item was selected
        function formatValue(formValue) {
            if (options) {
                return options.find(item => item.value === formValue);
            }
        }

        // this parseValue function will be used to transform
        // the value as react-select emits the item and not
        // the native onChange event object
        function parseValue(selectValue) {
            if (selectValue) {
                return selectValue.value;
            }
        }

        // Filter the suggestions list to only include suggestions that are also select options
        const filteredSuggestions = useMemo(
            () =>
                suggestionsList?.filter(suggestion =>
                    options.some(({ label }) => label.toLowerCase() === suggestion.toLowerCase())
                ),
            [suggestionsList, options]
        );

        return (
            <Field
                name={name}
                parse={parseValue}
                format={formatValue}
                initialValue={defaultValue?.value}
                suggestionsList={filteredSuggestions}
                removeSuggestion={removeSuggestion}
                suggestionIcon={suggestionIcon}
            >
                {({ input, meta }) => {
                    const error = getError(meta, validateOnTouch);

                    return (
                        <div className={cx('FormInput', error ? 'FormInput--error' : undefined, containerClassName)}>
                            <div className="form-group form-select-group on" data-test={dataTest}>
                                {(label || addLabelSpacing) && (
                                    <label
                                        aria-hidden={addLabelSpacing}
                                        className={cx(labelClassName, {
                                            FormError: error,
                                            'visually-hidden': addLabelSpacing,
                                            disabled: disabled
                                        })}
                                    >
                                        {label || <React.Fragment>&nbsp;</React.Fragment>}
                                        {required ? ' *' : null}
                                    </label>
                                )}
                                {!isEmpty(filteredSuggestions) && (
                                    <div className="with-suggestions">
                                        {filteredSuggestions.map((suggestion, index) => (
                                            <Suggestion
                                                className={'form-suggestions'}
                                                key={index}
                                                text={suggestion}
                                                icon={suggestionIcon}
                                                onClick={() => {
                                                    // Find the corresponding option for the clicked suggestion
                                                    const selectedOption = options.find(
                                                        ({ label }) => label.toLowerCase() === suggestion.toLowerCase()
                                                    );

                                                    if (selectedOption) {
                                                        // Update the input value with the selected option
                                                        handleInputValueChange(input, onChange, selectedOption);

                                                        // Remove the suggestion from the list
                                                        removeSuggestion(index);
                                                    }
                                                }}
                                                onClickEndIcon={e => {
                                                    e.stopPropagation();
                                                    removeSuggestion(index);
                                                }}
                                            />
                                        ))}
                                    </div>
                                )}
                                <Select
                                    defaultValue={defaultValue || null}
                                    ref={ref}
                                    className={cx('form-select', selectClassName)}
                                    dataTest={dataTest}
                                    classNamePrefix={'react-select'}
                                    isSearchable={input.searchable || false}
                                    isClearable={input.clearable || false}
                                    {...selectProps}
                                    {...input}
                                    isDisabled={disabled}
                                    options={options}
                                    onChange={item => handleInputValueChange(input, onChange, item)}
                                />
                                {error && <span className={'FormError'}>{error}</span>}
                            </div>
                        </div>
                    );
                }}
            </Field>
        );
    }
);
