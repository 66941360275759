import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import '../../sass/createNewLeaseTabs.scss';

class CreateNewLeaseTabs extends React.Component {
    render() {
        const { active, setActive } = this.props;

        return (
            <ul className="create-new-lease-tabs">
                <li
                    className={`apartment ${cx({ active: active === 'apartment' }, { inactive: active === 'house' })}`}
                    onClick={() => setActive('apartment')}
                >
                    <span className="icon" /> Apartment
                </li>
                <li
                    className={`house ${cx({ active: active === 'house' }, { inactive: active === 'apartment' })}`}
                    onClick={() => setActive('house')}
                >
                    <span className="icon" /> House
                </li>
            </ul>
        );
    }
}

CreateNewLeaseTabs.propTypes = {
    active: PropTypes.string,
    setActive: PropTypes.func
};

export default CreateNewLeaseTabs;
