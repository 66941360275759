import { createSelector } from 'reselect';
import { getLeaseData, getLease } from './';

export const getCommon = createSelector([getLease], lease => lease.common);

export const getValidationErrors = createSelector([getCommon], common => common.validationErrors);

export const getTenantPayments = createSelector([getLeaseData], lease => lease.tenantPayments);

export const getPoolSafetyAndSmokeAlarms = createSelector([getLeaseData], lease => lease.poolSafetyAndSmokeAlarms);

export const getWarrantyAndMaintenance = createSelector([getLeaseData], lease => lease.warrantyAndMaintenance);

export const getPmLandlord = createSelector([getLeaseData], lease => lease.pmLandlord);

export const getPmAgreementTerm = createSelector([getLeaseData], lease => lease.pmAgreementTerm);

export const getPmDetailsRepairs = createSelector([getLeaseData], lease => lease.pmDetailsRepairs);

export const getPmAuthorityPeriodDetails = createSelector([getLeaseData], lease => lease.pmAuthorityPeriodDetails);

export const getPmRentAndTerm = createSelector([getLeaseData], lease => lease.pmRentAndTerm);

export const getPmFees = createSelector([getLeaseData], lease => lease.pmFees);

export const getPmMarketingCosts = createSelector([getLeaseData], lease => lease.pmMarketingCosts);

export const getPmAdditionalCosts = createSelector([getLeaseData], lease => lease.pmAdditionalCosts);

export const getPmExpensesAndCharges = createSelector([getLeaseData], lease => lease.pmExpensesAndCharges);

export const getPmOtherServices = createSelector([getLeaseData], lease => lease.pmOtherServices);

export const getLicensee = createSelector([getLeaseData], lease => lease.licensee);

export const getMarketingAndAdvertising = createSelector([getLeaseData], lease => lease.marketingAndAdvertising);

export const getInspectionDays = createSelector([getLeaseData], lease => lease.inspectionDays);

export const getPmDuties = createSelector([getLeaseData], lease => lease.pmDuties);

export const getPmServicesSchedule = createSelector([getLeaseData], lease => lease.pmServicesSchedule);

export const getPmOutgoings = createSelector([getLeaseData], lease => lease.pmOutgoings);

export const getDisclosureOfRebates = createSelector([getLeaseData], lease => lease.disclosureOfRebates);

export const getPmRentAndPayment = createSelector([getLeaseData], lease => lease.pmRentAndPayment);

export const getPmEmergencyContacts = createSelector([getLeaseData], lease => lease.pmEmergencyContacts);

export const getThirdPartyPayment = createSelector([getLeaseData], lease => lease.thirdPartyPayment);

export const getPmRepairs = createSelector([getLeaseData], lease => lease.pmRepairs);

export const getInsurance = createSelector([getLeaseData], lease => lease.insurance);
