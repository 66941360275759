import React from 'react';
import { useDispatch } from 'react-redux';
import '../../../../../sass/dashboardContentTable.scss';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import AgentLogo from '../../../../AgentLogo';
import { openDocumentForm } from '../../../../../actions/document';

import FlowDocumentsMoreOptionBlock from '../../cells/FlowDocumentsMoreOptionBlock';
import { DOCUMENT_EDIT_MODE, DOCUMENT_READ_ONLY_MODE, DOCUMENT_RENT_REDUCTION } from '../../../../../config';
import * as dashboard from '../../../../../actions/dashboard';

const RentReduction = ({ documentList, agentSignature }) => {
    const dispatch = useDispatch();

    function openDocumentFormView(event, doc) {
        event.stopPropagation();
        if (doc.status === dashboard.LEASE_STATUS_DRAFT) {
            dispatch(openDocumentForm(DOCUMENT_RENT_REDUCTION, doc, DOCUMENT_EDIT_MODE));
        } else {
            dispatch(openDocumentForm(DOCUMENT_RENT_REDUCTION, doc, DOCUMENT_READ_ONLY_MODE));
        }
    }

    return (
        <Table className="rent-reduction-table">
            <Thead>
                <Th column="agent">Agent</Th>
                <Th column="address">Address</Th>
                <Th column="tenantName">Tenant name</Th>
                <Th column="landLordName">Landlord Name</Th>
                <Th column="currentRent">Current rent</Th>
                <Th column="rentCredit">Rent credit</Th>
                <Th column="newRent">Rent due</Th>
                <Th column="status">Status</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {documentList &&
                documentList.map((doc, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openDocumentFormView(event, doc);
                            }}
                        >
                            <Td column="agent">
                                <AgentLogo agent={doc.agent} />
                            </Td>
                            <Td column="address">
                                <b>{doc.rentReductionAgreement.address}</b>
                            </Td>
                            <Td column="tenantName">
                                <b>{doc.rentReductionAgreement.tenantName}</b>
                            </Td>
                            <Td column="landLordName">
                                <b>{doc.rentReductionAgreement.landLordName}</b>
                            </Td>
                            <Td column="currentRent">
                                <b>{doc.rentReductionAgreement.currentRent}</b>
                            </Td>
                            <Td column="rentCredit">
                                <b>{doc.rentReductionAgreement.reduction}</b>
                            </Td>
                            <Td column="newRent">
                                <b>{doc.rentReductionAgreement.newRent}</b>
                            </Td>
                            <Td column="status">
                                <span
                                    className={
                                        doc.completion && doc.completion.status === 'error'
                                            ? 'icon-document-error'
                                            : 'icon-document-sent'
                                    }
                                >
                                    {doc.completion && doc.completion.status === 'error' ? 'Error' : 'Sent'}
                                </span>
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <FlowDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        leaseType={doc.docType}
                                        doc={doc}
                                        agentSignature={agentSignature}
                                    />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default RentReduction;
