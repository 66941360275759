import React, { PureComponent } from 'react';
import cookie from 'react-cookie';
import PropTypes from 'prop-types';
import Button from '../../../common/components/Button';
import { PreLoader } from '../../../common/components/PreLoader';
import '../../../sass/integrations.scss';
import { CheckboxCheck } from '../../form/FormCheckboxCheck';
import { Form } from 'react-final-form';

const ACTION_TYPE = 'INTEGRATION_AUTH_CONNECTION';
const INTEGRATION_ID = 'managed';

class ManagedIntegration extends PureComponent {
    static propTypes = {
        getUserInfo: PropTypes.func.isRequired,
        syncAccountIntegration: PropTypes.func.isRequired,
        deleteAccountIntegration: PropTypes.func.isRequired,
        agencyIntegration: PropTypes.object,
        isFetching: PropTypes.bool,
        syncIntegrations: PropTypes.oneOfType([PropTypes.instanceOf(Error), PropTypes.bool])
    };

    state = {
        authWindow: null,
        shouldPushFinishedDocuments: false
    };

    componentDidMount() {
        window.addEventListener('message', this.handlePostMessage, false);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handlePostMessage);
    }

    handlePostMessage = event => {
        const { getUserInfo, syncAccountIntegration } = this.props;
        const { authWindow } = this.state;
        const { data } = event;

        if (!authWindow || !data) {
            return;
        }

        const { action, success, integration } = data;
        if (action !== ACTION_TYPE || integration !== INTEGRATION_ID) {
            return;
        }

        // origin check
        if (event.origin !== SERVICE_URL) {
            throw new Error('Invalid origin.');
        }

        // successful connections only
        if (!success) {
            alert('Failed to connect to Managed, please try again or contact support.');
        }

        authWindow.close();

        getUserInfo();
        syncAccountIntegration();

        // clean up
        this.setState({
            authWindow: null
        });
    };

    handleConnectWithManaged = () => {
        this.setState(() => ({
            authWindow: window.open(
                `${SERVICE_URL}/api/integrations/managed/login?access_token=${cookie.load(
                    'accessToken'
                )}&shouldPushFinishedDocuments=${this.state.shouldPushFinishedDocuments ? 1 : 0}`,
                'Connecting to Managed',
                `width=640,height=640,left=${(window.screen.width - 640) / 2},top=60`
            )
        }));
    };

    handleSubmit = values => {
        if (!this.props.agencyIntegration) {
            this.setState({ shouldPushFinishedDocuments: values.shouldPushFinishedDocuments }, () => {
                this.handleConnectWithManaged();
            });
        }
    };

    handleDisconnectClick = () => {
        const { deleteAccountIntegration } = this.props;
        deleteAccountIntegration('managed');
    };

    render() {
        const { agencyIntegration, isFetching, syncIntegrations } = this.props;

        return (
            <Form initialValues={agencyIntegration} onSubmit={this.handleSubmit}>
                {({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="panel">
                                <h2 className="panel-header">Managed</h2>
                                <div className="panel-body">
                                    {isFetching && <PreLoader />}

                                    {!isFetching && agencyIntegration && (
                                        <div>
                                            <div>
                                                <span className="savestatus-saved">
                                                    <CheckboxCheck
                                                        name={`shouldPushFinishedDocuments`}
                                                        label="Automatically store completed agreements and documents in Managed"
                                                        value={this.state.shouldPushFinishedDocuments}
                                                        disabled={true}
                                                    />
                                                    Connected <span className="icon savestatus-icon" />
                                                </span>
                                            </div>
                                            {syncIntegrations && (
                                                <div>
                                                    <span className="syncing-integration">Syncing properties...</span>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {!isFetching && !agencyIntegration && (
                                        <div className="should-push-finished-documents">
                                            <CheckboxCheck
                                                name={`shouldPushFinishedDocuments`}
                                                label="Automatically store completed agreements and documents in Managed"
                                                value={this.state.shouldPushFinishedDocuments}
                                            />
                                            <Button type="submit" primary className="integrations-button-primary">
                                                Connect with Managed
                                            </Button>
                                        </div>
                                    )}
                                </div>

                                {agencyIntegration && (
                                    <div className="panel-footer">
                                        <div />
                                        <Button onClick={this.handleDisconnectClick}>Disconnect account</Button>
                                    </div>
                                )}
                            </div>
                        </form>
                    );
                }}
            </Form>
        );
    }
}

export default ManagedIntegration;
