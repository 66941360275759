import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import '../../sass/panel.scss';

export default class Panel extends React.Component {
    render() {
        const { title, className, leftButton, rightButton } = this.props;

        return (
            <div className={cx('panel', className)}>
                {!!title && <div className="panel-header">{title}</div>}
                <div className="panel-body">{this.props.children}</div>
                {(!!leftButton || !!rightButton) && (
                    <div className="panel-footer">
                        <div>{leftButton}</div>
                        <div>{rightButton}</div>
                    </div>
                )}
            </div>
        );
    }
}

Panel.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    leftButton: PropTypes.element,
    rightButton: PropTypes.element,
    className: PropTypes.string
};
