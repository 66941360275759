import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getUserInfo } from '../../../selectors/user';
import { getLicensee } from '../../../selectors/lease/pmLease';

import { QLD_STATE } from '../../../config';
import { isLeaseTypePm, isTemplate, isLeaseTypeSales, isLeaseTypeCommercialLease } from '../../../utils/agreementUtils';

import '../../../sass/licensee.scss';
import { isPhone } from '../../../components/Validate';

import PmLicensee from '../pm/PmLicensee';
import SalesLicensee from '../sales/SalesLicensee';
import CommercialLeaseLicensee from '../commercialLease/CommercialLeaseLicensee';

const LICENSEE_FORM = 'licenseeForm';
const initState = {
    licenseeType: '',
    salesLicenseeTypes: {},
    firstName: '',
    secondName: '',
    agencyName: '',
    companyName: '',
    abn: '',
    acn: '',
    licenceNo: '',
    licenceExpiry: '',
    address: '',
    mobile: '',
    phone: '',
    fax: '',
    email: '',
    isConjunctAgent: false,
    isRegisteredForGst: true
};

const Licensee = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxLicensee = useSelector(getLicensee);
    const loggedInUser = useSelector(getUserInfo);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [licensee, setLicensee] = useState(reduxLicensee || initState);
    const [isConjunct, setIsConjunct] = useState(licensee.isConjunctAgent || false);
    const [isRegisteredForGst, setisRegisteredForGst] = useState(licensee.isRegisteredForGst || false);
    const [error, setError] = useState('');

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(LICENSEE_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatelicensee(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.licensee')) {
                    return error.response.data.errors.licensee;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatelicensee = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/licensee`, data);
    };

    useEffect(() => {
        const newLicensee = cloneDeep(licensee);
        if (newLicensee.licenceExpiry) {
            newLicensee.licenceExpiry = new Date(licensee.licenceExpiry);
        }
        if (isLeaseTypePm(leaseType) && location === QLD_STATE && !newLicensee.licenseeType) {
            newLicensee.licenseeType = 'Real estate agent';
        }
        if (!isTemplate(leaseType) && !isPhone(licensee.phone)) {
            setError('Invalid phone number');
        }
        setLicensee(newLicensee);
    }, []);

    const toggleConjunctAgent = value => {
        setIsConjunct(value);
    };

    const toggleRegisteredForGst = value => {
        setisRegisteredForGst(value);
    };

    const changePhone = (value, values) => {
        const data = cloneDeep(values);
        setError('');
        if (!isTemplate(leaseType) && !isPhone(value)) {
            setError('Invalid phone number');
        }
        data.phone = value;
        setLicensee(data);
    };

    return (
        <div className="form-container licensee">
            <Form
                onSubmit={submitForm}
                mutators={{
                    ...arrayMutators
                }}
                initialValues={licensee}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={LICENSEE_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="formBox">
                                {isLeaseTypePm(leaseType) && (
                                    <PmLicensee
                                        values={values}
                                        toggleRegisteredForGst={toggleRegisteredForGst}
                                        changePhone={changePhone}
                                        error={error}
                                        licensee={licensee}
                                        loggedInUser={loggedInUser}
                                        location={location}
                                    />
                                )}
                                {isLeaseTypeSales(leaseType) && (
                                    <SalesLicensee
                                        values={values}
                                        toggleRegisteredForGst={toggleRegisteredForGst}
                                        toggleConjunctAgent={toggleConjunctAgent}
                                        isConjunct={isConjunct}
                                        changePhone={changePhone}
                                        error={error}
                                        licensee={licensee}
                                        location={location}
                                        loggedInUser={loggedInUser}
                                    />
                                )}
                                {isLeaseTypeCommercialLease(leaseType) && (
                                    <CommercialLeaseLicensee push={push} licensee={licensee} />
                                )}
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Licensee);
