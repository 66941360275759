import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import produce from 'immer';

const initState = {
    maxOccupants: 0,
    occupantsList: []
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS: {
            draftState = {
                maxOccupants: 0,
                occupantsList: []
            };
            break;
        }
        case Lease.LEASE_TENANT_SUCCESS: {
            draftState = action.result.data.lease.maxOccupants;
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = action.payload.agreementInfo.maxOccupants
                ? action.payload.agreementInfo.maxOccupants
                : initState;
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = action.lease.maxOccupants;
            break;
        }

        case Lease.LEASE_OCCUPANTS_SUCCESS: {
            draftState = action.result.data.lease.maxOccupants;
        }
    }

    return draftState;
});
