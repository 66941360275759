import React from 'react';
import { DOCUMENT_COMPLETION_ERROR, DOCUMENT_COMPLETION_FINISHED } from '../../../../config';

const SentStatus = ({ doc }) => {
    let statusClass = 'icon-document-draft';
    let statusText = 'Pending';
    if (doc.completion.status === DOCUMENT_COMPLETION_ERROR) {
        statusClass = 'icon-document-error';
        statusText = 'Error';
    } else if (doc.completion.status === DOCUMENT_COMPLETION_FINISHED) {
        statusClass = 'icon-document-sent';
        statusText = 'Sent';
    }
    return <span className={statusClass}>{statusText}</span>;
};
export default SentStatus;
