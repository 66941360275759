import { AvailableCountries } from '../constants/constants';

export const getPriceDescription = (country: AvailableCountries | null) => {
    if (country === AvailableCountries.NZ) {
        return '*All prices are in NZD (no GST applicable).';
    } else if (country === AvailableCountries.AU) {
        return '*All prices shown are in AUD including GST.';
    }
    return '*All prices are inclusive of GST.';
};

export const getCountryLabel = (country: AvailableCountries) => {
    switch (country) {
        case AvailableCountries.AU:
            return 'Australia';
        case AvailableCountries.NZ:
            return 'New Zealand';
        default:
            return '';
    }
};

export const getCountryMobileNumberPrefix = (country?: string) => {
    if (country === AvailableCountries.NZ) {
        return '+64';
    }

    return '+61';
};

export const getCountryEmoji = (country: AvailableCountries) => {
    switch (country) {
        case AvailableCountries.AU:
            return '🇦🇺';
        case AvailableCountries.NZ:
            return '🇳🇿';
    }
};

export const getLabelForCountry = (country: AvailableCountries | null, key: string) => {
    const isNewZealand = country === AvailableCountries.NZ;
    switch (key) {
        case 'listNow':
            return isNewZealand ? 'List Now' : 'Campaign Flow';
        default:
            return '';
    }
};
