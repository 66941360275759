import { useState } from 'react';

/**
 * Used to show a "Component" temporarily while isAwake is true, hide that component when isAwake is false
 * 
 * @param duration used to set how long the isAwake will remain true
 * @returns 
 */
export const useAwake = (duration: number) => {
    const [isAwake, setIsAwake] = useState(false);
    const wakeup = () => {
        setIsAwake(true);
        setTimeout(() => {
            setIsAwake(false);
        }, duration);
    };
    return {
        wakeup,
        isAwake
    };
}

export default useAwake