import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, findIndex } from 'lodash';

import ModalDialog from '../../common/components/ModalDialog';
import Button from '../../common/components/Button';

import { setTariffPlan } from '../../actions/account';
import { closeConfirmPlanUpgradeModal } from '../../actions/subscription';
import { loadPlanList } from '../../actions/authorization';
import { upgradeSubscriptionModalStates as modalStates } from '../../config';

import { isUserRoleAdmin, getAgencyId } from '../../selectors/user';
import { getIsConfirmPlanUpgradeModalOpen, getActivePlan, getModalType } from '../../selectors/subscription';
import { getPlans } from '../../selectors/authorization';
import { getSetTariffPlanRequestPending } from '../../selectors/account';

import '../../sass/modalSignature.scss';
import useCountry from '../../hooks/useCountry';

function ConfirmPlanUpgradeModal() {
    const dispatch = useDispatch();
    const { country } = useCountry();

    const isOpen = useSelector(getIsConfirmPlanUpgradeModalOpen);
    const activePlan = useSelector(getActivePlan);
    const modalType = useSelector(getModalType);
    const plans = useSelector(getPlans);
    const setTariffPlanRequestPending = useSelector(getSetTariffPlanRequestPending);
    const agencyId = useSelector(getAgencyId);
    const isAdmin = useSelector(isUserRoleAdmin);

    const [upgradeToPlan, setUpgradeToPlan] = useState({});

    useEffect(() => {
        if (country) {
            dispatch(loadPlanList(country));
        }
    }, [country]);

    useEffect(() => {
        if (isEmpty(plans) || isEmpty(activePlan)) {
            setUpgradeToPlan({});
        } else {
            getNextPlan(plans, activePlan);
        }
    }, [plans, activePlan]);

    const getNextPlan = (plans, activePlan) => {
        let activePlanIndex = findIndex(plans, { stripeId: activePlan.stripeId });

        let nextPlan = null;
        if (activePlanIndex + 1 <= plans.length - 1) {
            nextPlan = plans[activePlanIndex + 1];
        }

        setUpgradeToPlan(nextPlan);
    };

    const upgradePlan = () => {
        dispatch(setTariffPlan(agencyId, upgradeToPlan.stripeId));
    };

    const closeModal = () => {
        dispatch(closeConfirmPlanUpgradeModal());
    };

    const renderRightButton = () => {
        if (isAdmin && modalType === modalStates.CONFIRM_UPGRADE) {
            return (
                <Button
                    primary
                    className="btn-upgrade"
                    height={40}
                    onClick={upgradePlan}
                    disabled={isEmpty(upgradeToPlan) || setTariffPlanRequestPending}
                >
                    Upgrade
                </Button>
            );
        }

        return (
            <Button
                primary
                className="btn-close"
                height={40}
                onClick={closeModal}
                disabled={setTariffPlanRequestPending}
            >
                {modalType === modalStates.CONFIRM_UPGRADE ? 'OK' : 'Close'}
            </Button>
        );
    };

    const renderLeftButton = () => {
        if (isAdmin && modalType === modalStates.CONFIRM_UPGRADE) {
            return (
                <Button
                    primary
                    className="btn-close"
                    height={40}
                    onClick={closeModal}
                    disabled={setTariffPlanRequestPending}
                >
                    Cancel
                </Button>
            );
        }

        return null;
    };

    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={closeModal}
            rightButton={renderRightButton()}
            leftButton={renderLeftButton()}
            showModalHeader={false}
            shouldCloseOnOverlayClick={false}
            className="small-modal-dialog"
        >
            <p>
                {modalType === modalStates.CONFIRM_UPGRADE &&
                    isAdmin &&
                    `You have reached your maximum number of agreements. Please confirm to upgrade your subscription to ${upgradeToPlan?.title} plan.`}

                {modalType === modalStates.CONFIRM_UPGRADE &&
                    !isAdmin &&
                    `You have reached your maximum number of agreements. Please contact your admin to upgrade your subscription to ${upgradeToPlan?.title} plan.`}

                {modalType === modalStates.UPGRADE_SUCCESSFUL &&
                    'Your package has been upgraded successfully. You can now send the agreement for signing.'}

                {modalType === modalStates.UPGRADE_FAILED &&
                    'Unfortunately, your subscription could not be upgraded. Please check your Profile Settings to try again'}

                {modalType === modalStates.CANCELLED_PLAN &&
                    'The agreement cannot be sent for signing because your subscription has been cancelled. Go to Settings to renew your subscription or contact an administrator.'}
            </p>
        </ModalDialog>
    );
}

export default ConfirmPlanUpgradeModal;
