import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

import PopupFlkAKeyMore from '../../../popups/PopupFlkAKeyMore';
import { getUserInfo, isUserRoleAdmin } from '../../../../selectors/user';
import { useRollbarProvider } from '../../../providers/RollbarProvider';
import {
    changeFlkAKeyFormEditable,
    openKeyForm,
    removeKeyInList,
    closeFlkAKeyForm,
    openFlkAKeyReminderModal
} from '../../../../actions/flkAKey';
import { isAccountEnabled } from '../../../../utils/userUtils';

const FlkAKeyMoreOptionBlock = ({ flkAKey }) => {
    const dispatch = useDispatch();
    const Rollbar = useRollbarProvider();

    const [morePopup, setMorePopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const loggedInUser = useSelector(getUserInfo);
    const isUserAdmin = useSelector(isUserRoleAdmin);

    function toggleMorePopup(e) {
        e.stopPropagation();
        setMorePopup(!morePopup);
    }

    function closeMorePopupList() {
        setMorePopup(false);
    }

    function checkInKeyRequest(keyId) {
        return axios.post(`/api/flk-a-key/${keyId}/check-in`);
    }

    function checkInKey() {
        confirmAlert({
            title: '',
            message: `Are you sure you want to check in key number ${flkAKey.key} for ${flkAKey.address}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        return checkInKeyRequest(flkAKey.id)
                            .then(() => {
                                dispatch(removeKeyInList(flkAKey));
                                dispatch(closeFlkAKeyForm());
                            })
                            .catch(error => {
                                Rollbar.error(
                                    'Failed to check in key',
                                    {
                                        error_message: error.message,
                                        status: 'error',
                                        env: ENVIRONMENT
                                    },
                                    error
                                );
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function edit(e) {
        e.stopPropagation();
        dispatch(openKeyForm(flkAKey));
        dispatch(changeFlkAKeyFormEditable(true));
    }

    function checkIn(e) {
        e.stopPropagation();
        closeMorePopupList();
        checkInKey();
    }

    function checkOutKeyRequest(data) {
        return axios.post('/api/flk-a-key/create-draft', data);
    }

    const deleteDocumentRequest = useMutation(
        keyId => {
            return axios.delete(`/api/flk-a-key/${keyId}`);
        },
        {
            onSuccess: res => {
                setIsLoading(false);
                closeMorePopupList();
                dispatch(removeKeyInList(flkAKey));
                dispatch(closeFlkAKeyForm());
            }
        }
    );

    const cloneDocumentRequest = useMutation(
        data => {
            return axios.post('/api/flk-a-key/create-draft', data);
        },
        {
            onSuccess: res => {
                setIsLoading(false);
                closeMorePopupList();
                dispatch(openKeyForm(res.data.flkAKey));
            }
        }
    );

    function checkOut(e) {
        e.stopPropagation();
        confirmAlert({
            title: '',
            message: `Are you sure you want to check out key number ${flkAKey.key} for ${flkAKey.address}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsLoading(true);
                        setMorePopup(true);
                        const data = {
                            key: flkAKey.key,
                            address: flkAKey.address,
                            addressDetails: flkAKey.addressDetails,
                            integrationPropertyId: flkAKey?.integration?.property?.Id || null,
                            images: flkAKey.images || []
                        };
                        return checkOutKeyRequest(data)
                            .then(response => {
                                dispatch(openKeyForm(response.data.flkAKey));
                                dispatch(changeFlkAKeyFormEditable(true));
                            })
                            .catch(error => {
                                Rollbar.error(
                                    'Failed to check out key',
                                    {
                                        error_message: error.message,
                                        status: 'error',
                                        env: ENVIRONMENT
                                    },
                                    error
                                );
                            })
                            .finally(() => {
                                setIsLoading(false);
                                closeMorePopupList();
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function cloneDocument() {
        setIsLoading(true);
        const data = cloneDeep(flkAKey);
        delete data.id;
        cloneDocumentRequest.reset();
        cloneDocumentRequest.mutate(data);
    }

    function deleteDocument() {
        const message =
            flkAKey.address && flkAKey.key
                ? `Are you sure you want to delete key number ${flkAKey.key} for ${flkAKey.address}?`
                : 'Are you sure you want to delete this record?';
        confirmAlert({
            title: '',
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsLoading(true);
                        deleteDocumentRequest.reset();
                        deleteDocumentRequest.mutate(flkAKey.id);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    function sendReminder(e) {
        e.stopPropagation();
        closeMorePopupList();
        dispatch(openFlkAKeyReminderModal(flkAKey));
    }

    return (
        <div className="more-action-wrapper" style={{ position: 'relative' }}>
            {isAccountEnabled(loggedInUser) && <div className="more-action" onClick={toggleMorePopup} />}
            {morePopup && (
                <PopupFlkAKeyMore
                    keyStatus={flkAKey?.status || ''}
                    morePopupList={morePopup}
                    closeMorePopupList={closeMorePopupList}
                    edit={edit}
                    checkIn={checkIn}
                    checkOut={checkOut}
                    sendReminder={sendReminder}
                    isUserAdmin={isUserAdmin}
                    closePopup={closeMorePopupList}
                    isLoading={isLoading}
                    deleteDocument={deleteDocument}
                    cloneDocument={cloneDocument}
                />
            )}
        </div>
    );
};

export default memo(FlkAKeyMoreOptionBlock);
