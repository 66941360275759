import React from 'react';
import { SelectField } from './FormSelect.js';
import useSuggestions from '../../hooks/useSuggestions';

type SuggestionSelectFieldProps = React.PropsWithChildren<{
    suggestionsFieldName: string;
}>;

const SuggestionSelectField: React.FC<SuggestionSelectFieldProps> = ({ suggestionsFieldName, ...rest }) => {
    const { isSuggestionsActive, suggestionsList, suggestionIcon, removeSuggestionFromRedux } =
        useSuggestions(suggestionsFieldName);

    return isSuggestionsActive ? (
        <SelectField
            suggestionsList={suggestionsList}
            removeSuggestion={removeSuggestionFromRedux}
            suggestionIcon={suggestionIcon}
            {...rest}
        />
    ) : (
        <SelectField {...rest} />
    );
};

export default SuggestionSelectField;
