import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { emailStandAloneTerminationPDF } from '../../../../actions/document';
import { HIDE_MESSAGE_TIME } from '../../../../config';

import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document';
import { getUserInfo } from '../../../../selectors/user';

import CommonFooter from '../shared/CommonFooter';

const TerminationNoticeWithoutLeaseFooter = ({
    previewPdf,
    viewPdf,
    form,
    isSendingTerminateLease,
    isSendingTerminateLeaseSuccess,
    isSendingTerminateLeaseFail,
    isSaveDraft
}) => {
    const currentDocument = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);

    const [isEmailingTerminateLease, setIsEmailingTerminateLease] = useState(false);
    const [isEmailingTerminateLeaseSuccess, setIsEmailingTerminateLeaseSuccess] = useState(false);
    const [isEmailingTerminateLeaseFail, setIsEmailingTerminateLeaseFail] = useState(false);

    let emailTermination = useCallback(docId => emailStandAloneTerminationPDF(docId), []);

    const emailPDF = (docId, leaseId) => {
        setIsEmailingTerminateLease(true);
        emailTermination(docId, leaseId)
            .then(() => {
                setIsEmailingTerminateLease(false);
                setIsEmailingTerminateLeaseSuccess(true);
                setIsEmailingTerminateLeaseFail(false);
                setTimeout(() => {
                    setIsEmailingTerminateLeaseSuccess(false);
                }, HIDE_MESSAGE_TIME);
            })
            .catch(() => {
                setIsEmailingTerminateLease(false);
                setIsEmailingTerminateLeaseSuccess(false);
                setIsEmailingTerminateLeaseFail(true);
                setTimeout(() => {
                    setIsEmailingTerminateLeaseFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    return (
        <CommonFooter
            isDoc={true}
            currentDocument={currentDocument}
            documentEditMode={documentEditMode}
            resend={emailPDF}
            viewPdf={viewPdf}
            previewPdf={previewPdf}
            isResending={isEmailingTerminateLease}
            isResendingSuccess={isEmailingTerminateLeaseSuccess}
            isResendingFail={isEmailingTerminateLeaseFail}
            isSendingSuccess={isSendingTerminateLeaseSuccess}
            isSendingFail={isSendingTerminateLeaseFail}
            isSending={isSendingTerminateLease}
            form={form}
            isSaveDraft={isSaveDraft}
        />
    );
};
export default TerminationNoticeWithoutLeaseFooter;
