import * as PropTypes from 'prop-types';
import * as React from 'react';
import { memo } from 'react';

const SpecialCondition = ({ item }) => {
    return (
        <li className="agreement-section-text__item">
            <div className="agreement-section-text__item--text">
                <strong>Title:</strong> {item.clause}
            </div>
            <div className="agreement-section-text__item--text">
                <strong>Description:</strong> {item.comment}
            </div>
        </li>
    );
};

SpecialCondition.propTypes = { item: PropTypes.any };

export default memo(SpecialCondition);
