import React from "react";
import * as RadixTabs from "@radix-ui/react-tabs";
import cx from "classnames";
import styles from "./Tabs.module.scss";

type TabProps = {
    tabs: {
        id: string;
        title: string;
        component: React.ReactNode;
    }[];
    initialTabId: string;
    tabSectionClassName?: string;
    tabSectionStartContent?: React.ReactNode;
    tabSectionEndContent?: React.ReactNode;
    tabContentClassName?: string;
    containerClassName?: string;
    onSelectTab?: (tabId: string) => void;
};

const Tabs: React.FC<TabProps> = ({
    tabs,
    initialTabId,
    tabSectionClassName,
    tabSectionStartContent,
    tabSectionEndContent,
    tabContentClassName,
    containerClassName,
    onSelectTab
}) => {
    return (
        <RadixTabs.Root
            className={containerClassName}
            defaultValue={initialTabId}
            onValueChange={
                onSelectTab ? value => onSelectTab(value) : undefined
            }
        >
            <div className={tabSectionClassName}>
                {tabSectionStartContent}
                <RadixTabs.List className={styles.tabList}>
                    {tabs.map(tab => (
                        <RadixTabs.Trigger
                            value={tab.id}
                            className={styles.tabTrigger}
                        >
                            {tab.title}
                        </RadixTabs.Trigger>
                    ))}
                </RadixTabs.List>
                {tabSectionEndContent}
            </div>
            {tabs.map(tab => (
                <RadixTabs.Content
                    className={tabContentClassName}
                    value={tab.id}
                >
                    {tab.component}
                </RadixTabs.Content>
            ))}
        </RadixTabs.Root>
    );
};

export default Tabs;
