import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import {
    getPmStepNo,
    getPmStepTitle,
    isLeaseLocationVIC,
    isLeaseLocationSA
} from '../../../../../utils/agreementUtils';

export default function AgreementPmTermAndRent({ leaseAllInfo }) {
    const { pmRentAndTerm, location } = leaseAllInfo;
    if (!pmRentAndTerm) {
        return null;
    }
    return (
        <div>
            <AgreementSection
                title={getPmStepTitle(location, 'pmRentAndTerm')}
                step={getPmStepNo(location, 'pmRentAndTerm')}
                leaseAllInfo={leaseAllInfo}
            >
                <div className="data">
                    {!isLeaseLocationVIC(location) && !isLeaseLocationSA(location) && (
                        <React.Fragment>
                            <p className="text">
                                Length of term is between
                                <span className="bold"> {pmRentAndTerm.lengthOfTerm?.notLessThan} </span>
                                {' - '}
                                <span className="bold">{pmRentAndTerm.lengthOfTerm?.notMoreThan}</span> weeks.
                            </p>
                            <div>
                                {!!pmRentAndTerm.useMarketRate && <h3>Rent and bond use market rate</h3>}
                                {!pmRentAndTerm.useMarketRate && (
                                    <div>
                                        <p className="text">
                                            The market rent is $ <span className="bold">{pmRentAndTerm.rent} </span>
                                            {' per '}
                                            <span className="bold">{pmRentAndTerm?.payablePer}</span>
                                        </p>
                                        <p className="text">
                                            Number of weeks for rental bond{' '}
                                            <span className="bold">{pmRentAndTerm?.numberOfWeeksForRentalBond}</span>
                                        </p>
                                    </div>
                                )}
                                {!!pmRentAndTerm.isPropertyFurnished && (
                                    <p>
                                        The property is furnished and
                                        <b>{pmRentAndTerm.agentPrepareInventoryList ? ' Agent ' : ' Landlord '}</b>
                                        will prepare the inventory list for the property
                                    </p>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                    {isLeaseLocationVIC(location) && (
                        <React.Fragment>
                            <h4>The following fees and charges are based on the following estimates:</h4>
                            <p className="text">
                                Rent amount per week : <span className="bold">${pmRentAndTerm.rent}</span>
                            </p>
                            <p className="text">
                                Rent amount per month :{' '}
                                <span className="bold">${pmRentAndTerm.rentAmountPerMonth}</span>
                            </p>
                        </React.Fragment>
                    )}
                    {isLeaseLocationSA(location) && (
                        <React.Fragment>
                            <p className="text">
                                Rental estimate:&nbsp;<span className="bold">{pmRentAndTerm.rentFormatted}</span>
                            </p>
                            <p className="text">
                                Advertised rental price:&nbsp;
                                <span className="bold">{pmRentAndTerm.advertisedRentFormatted}</span>
                            </p>
                        </React.Fragment>
                    )}
                </div>
            </AgreementSection>
        </div>
    );
}
