import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PreLoader } from '../../../common/components/PreLoader';
import '../../../sass/integrations.scss';
import { Form } from 'react-final-form';
import { FormMaskedText } from '../../form/FormText';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { connect } from 'react-redux';
import axios from 'axios';
import SaveButtonIcon from '../../../containers/settings/SaveButtonIcon';

class SherlockIntegration extends PureComponent {
    static propTypes = {
        isFetching: PropTypes.bool,
        updateUserInfo: PropTypes.func.isRequired,
        updateUserStore: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            updateUserPending: false,
            updateUserSuccess: false,
            updateUserFailed: false,
            needHelp: false
        };
    }

    updateUser = data => {
        return axios.patch(`/api/user`, data);
    };

    submit = values => {
        this.setState({ updateUserPending: true });

        const sherlock = { sherlock: values.sherlock };
        return this.updateUser(sherlock)
            .then(data => {
                this.setState({
                    updateUserSuccess: true,
                    updateUserPending: false,
                    updateUserFailed: false
                });
                this.props.updateUserStore(data);
            })
            .catch(error => {
                this.setState({
                    updateUserSuccess: false,
                    updateUserFailed: true,
                    updateUserPending: false
                });

                return error.response ? error.response.data.errors : {};
            });
    };

    setNeedHelp = () => {
        this.setState({ needHelp: true });
    };

    getIsIdle = () => {
        return !this.state.updateUserSuccess && !this.state.updateUserPending && !this.state.updateUserFailed;
    };

    render() {
        const { isFetching, loggedInUser, sherlockModule } = this.props;

        const isIdle = this.getIsIdle();

        const sherlockActive = sherlockModule && sherlockModule.active;

        return (
            <Form
                initialValues={loggedInUser}
                mutators={{
                    ...arrayMutators
                }}
                onSubmit={this.submit}
            >
                {({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="panel sherlock">
                                <h2 className="panel-header">Sherlock / Sherlock PRO</h2>
                                {isFetching && <PreLoader />}

                                <div className="panel-body">
                                    {sherlockActive && !isFetching && (
                                        <div>
                                            <FieldArray name="sherlock">
                                                {({ fields }) => (
                                                    <div>
                                                        {fields.map((name, index) => (
                                                            <div key={name} className="input-delete-container">
                                                                <FormMaskedText
                                                                    name={`${name}.id`}
                                                                    label="Sherlock identifier"
                                                                    mask="11111111111111111111111"
                                                                    required={true}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    className={'btn-delete'}
                                                                    onClick={() => fields.remove(index)}
                                                                />
                                                            </div>
                                                        ))}
                                                        <a
                                                            type="button"
                                                            className={'btn-add'}
                                                            onClick={() => fields.push({ id: '' })}
                                                        >
                                                            Add identifier
                                                        </a>
                                                    </div>
                                                )}
                                            </FieldArray>

                                            <br />

                                            <div className="question">
                                                <span />
                                                <a onClick={this.setNeedHelp}>Connection instructions</a>
                                            </div>
                                            <br />

                                            {this.state.needHelp && (
                                                <div>
                                                    <p>This is where the numbers go that you will find in Sherlock.</p>
                                                    <br />
                                                    <p>Please refer to Sherlock Solutions on how to set this up.</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {!sherlockActive && <div>Please let us know if you are interested!</div>}
                                </div>
                                <div className="panel-footer">
                                    <div />
                                    {sherlockActive && (
                                        <div>
                                            <SaveButtonIcon
                                                isSaving={this.state.updateUserPending}
                                                success={this.state.updateUserSuccess}
                                                failure={this.state.updateUserFailed}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        );
    }
}

export default connect(state => ({
    loggedInUser: state.user.userInfo,
    user: state.user,
    validationErrors: state.account.validationErrors
}))(SherlockIntegration);
