import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import MaskData from 'maskdata';
import React, { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Dropzone from 'react-dropzone';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import {
    addProfilePicture,
    deleteProfilePicture,
    getAgentCodeRequirementAndLabel,
    updateUserCredentials
} from '../../actions/user';
import { Icons } from '../../common/components/Icon';
import ModalDialog from '../../common/components/ModalDialog';
import { FormPhone } from '../../components/form/FormPhone';
import { SelectField } from '../../components/form/FormSelect';
import { FormText, FormTextRegular } from '../../components/form/FormText';
import { useAccountProvider } from '../../components/providers/AccountProvider';
import SecuritySetupCard from '../../components/security/SecuritySetupCard';
import * as validators from '../../components/Validate';
import { imageCompressionOptions, TWO_FACTOR_AUTHENTICATION_SMS, VERIFICATION_CODE_LIMIT_MESSAGE } from '../../config';
import { GENERAL_ACCOUNT_TYPE } from '../../constants/constants';
import useCountry from '../../hooks/useCountry';
import '../../sass/profile.scss';
import '../../sass/uploadLogo.scss';
import { getMfaError, getUser, getUserInfo, getValidationErrors } from '../../selectors/user';
import { listOfTimezones } from '../../utils/dateUtils';
import { getGeneralAppLabel } from '../../utils/labelUtils';
import { isAgencyUserAccount } from '../../utils/userUtils';
import AgentCode from './account/AgentCode';
import SaveButtonIcon from './SaveButtonIcon';

const fileTypes = ['.png', '.jpg', '.jpeg', '.gif'];
const FILE_SIZE_ERROR = 'File is larger than 2000000 bytes';

const Profile = () => {
    const profileImg = useRef(null);
    const dropzone = useRef(null);
    const { country } = useCountry();

    const dispatch = useDispatch();

    const user = useSelector(getUserInfo);
    const userState = useSelector(getUser);
    const validationErrors = useSelector(getValidationErrors);
    const mfaError = useSelector(getMfaError);
    const accountType = useAccountProvider();

    const [preview, setPreview] = useState(null);
    const [file, setFile] = useState(null);
    const [isShowPopUp, setIsShowPopUp] = useState(false);
    const [formData, setFormData] = useState({
        firstName: user.firstName,
        secondName: user.secondName,
        phone: user.phone,
        contactNumber: user.contactNumber,
        image: user.image
    });
    const [dropZoneError, setDropZoneError] = useState('');
    const [open2FaModel, setOpen2FaModel] = useState(false);
    const [savedValues, setSavedValues] = useState(null);

    const [error, setError] = useState('');

    const { seconds, restart } = useTimer({ expiryTimestamp: new Date() });

    const onDropRejected = errors => {
        if (errors[0].errors[0].message === FILE_SIZE_ERROR) {
            setDropZoneError('File is larger than 2 MB');
        } else {
            setDropZoneError(errors[0].errors[0].message);
        }
    };

    useEffect(() => {
        document.addEventListener('click', documentClick);
    }, []);

    useEffect(() => {
        dispatch(getAgentCodeRequirementAndLabel());
    }, [dispatch]);

    useEffect(() => {
        setUserInfo(user);
    }, [user]);

    const onDrop = files => {
        if (files.length > 0) {
            imageCompression(files[0], imageCompressionOptions)
                .then(compressedFile => {
                    setDropZoneError('');
                    const reader = new FileReader();
                    reader.onload = event => {
                        setPreview(event.target.result);
                        setFile(compressedFile);
                    };
                    reader.readAsDataURL(compressedFile);
                })
                .catch(error => {
                    setDropZoneError('Something went wrong. Please try again');
                });
        }
    };

    const setUserInfo = userData => {
        const formData = {
            firstName: userData.firstName,
            secondName: userData.secondName,
            phone: userData.phone,
            contactNumber: userData.contactNumber,
            image: userData.image
        };
        if (accountType === GENERAL_ACCOUNT_TYPE) {
            formData.timezone = userData.timezone;
        }
        setFormData(formData);
    };

    const showPopUp = () => {
        setIsShowPopUp(true);
    };

    const hidePopUp = () => {
        setIsShowPopUp(false);
    };

    const documentClick = event => {
        if (profileImg && profileImg.current && !profileImg.current.contains(event.target)) {
            hidePopUp();
        }
    };

    const deletePhoto = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure want to delete profile picture?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setPreview('');
                        setIsShowPopUp(false);
                        setFormData({
                            ...formData,
                            image: ''
                        });
                        user.image = undefined;
                        dispatch(deleteProfilePicture());
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const openDrop = () => {
        dropzone.current.open();
        hidePopUp();
    };

    const addProfilePictureData = () => {
        let data = new FormData();
        if (file) {
            data.append('logo', file);

            dispatch(addProfilePicture(data));
            user.image = preview;
        }
    };

    const submit = values => {
        dispatch(updateUserCredentials(values));
        addProfilePictureData();
    };

    const send2faCode = useMutation(
        data => {
            setError('');
            return axios.post(`/api/user/send-2fa-code`, data);
        },
        {
            onSuccess: () => {
                const time = new Date();
                time.setSeconds(time.getSeconds() + 30);
                restart(time);
            },
            onError: error => {
                if (error?.response?.data?.error === VERIFICATION_CODE_LIMIT_MESSAGE) {
                    setError(VERIFICATION_CODE_LIMIT_MESSAGE);
                }
            }
        }
    );

    const checkPhoneNumberAndSubmit = values => {
        if (
            user?.multiFactorAuthenticationMethods?.includes(TWO_FACTOR_AUTHENTICATION_SMS) &&
            values.phone !== user.phone
        ) {
            setSavedValues(values);
            send2faCode.mutate({ method: TWO_FACTOR_AUTHENTICATION_SMS });
            setOpen2FaModel(true);
        } else {
            submit(values);
        }
    };

    const isPending = userState.ADD_PROFILE_PICTURE_REQUEST_PENDING || userState.UPDATE_USER_INFO_REQUEST_PENDING;

    const savedSuccessfully = userState.ADD_PROFILE_PICTURE_SUCCESS || userState.UPDATE_USER_INFO_SUCCESS;

    const saveFailed = userState.ADD_PROFILE_PICTURE_FAIL || userState.UPDATE_USER_INFO_FAIL;

    return (
        <div className="settings">
            <div className="change-profile">
                <h2>Profile settings</h2>
                <Form
                    initialValues={formData}
                    onSubmit={values => checkPhoneNumberAndSubmit(values)}
                    validate={({ firstName, secondName, phone, contactNumber, timezone }) => {
                        const formValues = {
                            firstName: !firstName
                                ? 'First Name is required'
                                : undefined || !validators.isCorrectNameOrSurname(firstName)
                                ? 'First Name is not valid'
                                : undefined,
                            secondName: !secondName
                                ? 'Last Name is required'
                                : undefined || !validators.isCorrectNameOrSurname(secondName)
                                ? 'Last Name is not valid'
                                : undefined,
                            phone: !phone
                                ? 'Phone is required'
                                : undefined || !validators.isMobilePhone(phone)
                                ? 'Phone is not valid'
                                : undefined,
                            contactNumber: !contactNumber ? 'Contact Number is required' : undefined
                        };
                        if (accountType === GENERAL_ACCOUNT_TYPE) {
                            formValues.timezone = !timezone ? 'Timezone is required' : undefined;
                        }
                        return formValues;
                    }}
                >
                    {({ handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="upload-logo">
                                    <Dropzone
                                        className="dropzone"
                                        maxSize={2000000}
                                        accept={fileTypes.join(', ')}
                                        ref={dropzone}
                                        onDrop={files => onDrop(files)}
                                        onDropRejected={errors => onDropRejected(errors)}
                                        multiple={false}
                                    >
                                        {({ getRootProps, getInputProps }) => {
                                            return (
                                                <React.Fragment>
                                                    <div className="dropzone" {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {!preview && !formData.image && (
                                                            <div className="no-photo">
                                                                <span className="name-letters">
                                                                    {formData.firstName
                                                                        ? formData.firstName.substr(0, 1)
                                                                        : ''}
                                                                    {formData.firstName
                                                                        ? formData.secondName.substr(0, 1)
                                                                        : ''}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            );
                                        }}
                                    </Dropzone>
                                    <div className="profile-img" ref={profileImg}>
                                        {!!formData.image && !preview && (
                                            <div
                                                className="img-preview profile-img-with-background"
                                                style={{ backgroundImage: `url(${formData.image})` }}
                                                onClick={showPopUp}
                                            />
                                        )}
                                        {!!preview && (
                                            <div
                                                className="img-preview"
                                                style={{ backgroundImage: `url(${preview})` }}
                                                onClick={showPopUp}
                                            />
                                        )}
                                        <div className={isShowPopUp ? 'profile-pop-up active' : 'profile-pop-up'}>
                                            <ul>
                                                <li onClick={openDrop}>
                                                    <i className="icon-upload" />
                                                    Upload new
                                                </li>
                                                <li onClick={deletePhoto}>
                                                    <i className="icon-remove" />
                                                    Remove current
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {!preview && (
                                        <div className="img-info">
                                            <p className="load-img" onClick={() => openDrop()}>
                                                <i className="icon-upload" />
                                                Upload photo
                                            </p>
                                        </div>
                                    )}
                                    {dropZoneError && <span className="error-message">{dropZoneError}</span>}
                                </div>
                                <div className="form-content">
                                    <FormText name="firstName" label="First Name" />
                                    <FormText name="secondName" label="Last Name" />
                                    <FormPhone
                                        className={'profile-phone-input'}
                                        name="phone"
                                        label="Mobile"
                                        icon={
                                            user?.multiFactorAuthenticationMethods?.includes(
                                                TWO_FACTOR_AUTHENTICATION_SMS
                                            )
                                                ? Icons.LOCK
                                                : undefined
                                        }
                                    />
                                    {validationErrors &&
                                        validationErrors.phone &&
                                        typeof validationErrors.phone === 'string' && (
                                            <p className="FormInput">
                                                <span className="FormError"> {validationErrors.phone} </span>
                                            </p>
                                        )}
                                    <FormTextRegular
                                        name="contactNumber"
                                        label={getGeneralAppLabel('clientVisible', accountType)}
                                    />
                                    {accountType === GENERAL_ACCOUNT_TYPE && (
                                        <div>
                                            <p>Timezone:</p>
                                            <SelectField
                                                name={'timezone'}
                                                options={listOfTimezones(country)}
                                                selectClassName="sel-4"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="footer">
                                    {mfaError && <p className="text-error">{mfaError}</p>}
                                    <SaveButtonIcon
                                        isSaving={isPending}
                                        success={savedSuccessfully}
                                        failure={saveFailed}
                                    />
                                </div>
                            </form>
                        );
                    }}
                </Form>
            </div>
            {isAgencyUserAccount(user.accountType) && userState.agentCode?.required && <AgentCode />}
            <ModalDialog
                isOpen={open2FaModel}
                showModalHeader={false}
                shouldCloseOnOverlayClick={false}
                bodyClassName="padding-0"
            >
                <SecuritySetupCard
                    title="Change my mobile number"
                    description="To change your mobile number, you’ll need to verify it’s you..."
                    label={`Enter the code send to ${
                        user.phone &&
                        MaskData.maskPhone(user.phone, {
                            maskWith: '*',
                            unmaskedStartDigits: 0,
                            unmaskedEndDigits: 4
                        })
                    }`}
                    buttonText="Send code again"
                    wrongDataText="Wrong mobile number?"
                    headerIcon={Icons.SMS}
                    goBack={() => setOpen2FaModel(false)}
                    submit={(method, code) => {
                        submit({ ...savedValues, code });
                        setOpen2FaModel(false);
                    }}
                    method={TWO_FACTOR_AUTHENTICATION_SMS}
                    resend={() => send2faCode.mutate({ method: TWO_FACTOR_AUTHENTICATION_SMS })}
                    resendRemainingTime={seconds}
                    isSending2faCode={send2faCode.isLoading}
                    loading={isPending}
                    error={error}
                />
            </ModalDialog>
        </div>
    );
};

export default Profile;
