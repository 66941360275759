import * as LeaseWithRequest from '../leaseWithRequest';
import * as Lease from '../lease';
import { salesOccupation } from '../../config';

export const UPDATE_SALES_INCLUSIONS_FORM = 'UPDATE_SALES_INCLUSIONS_FORM';

const initState = {
    inclusions: '',
    exclusions: '',
    easementsEtc: '',
    termsAndConds: '',
    description: '',
    occupationType: salesOccupation ? salesOccupation[0].value : null
};

export default function inclusionsReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_SALES_INCLUSIONS_FORM: {
            return { ...state, ...action.data };
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            return { ...initState, ...action.lease.inclusions };
        }
        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            if (action.result.data.lease.inclusions) {
                return Object.assign({}, initState, action.result.data.lease.inclusions);
            } else {
                return Object.assign({}, initState);
            }
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            return { ...initState, ...action.payload.agreementInfo.inclusions };
        }
        case Lease.LEASE_SALES_INCLUSIONS_SUCCESS: {
            return { ...state, ...action.result.data.lease.inclusions };
        }
        default: {
            return state;
        }
    }
}
