import React, { memo } from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import '../../../sass/react-tabs.scss';

import ContactList from './ContactList';
import { DOCUMENT_CREATE_A_FLK, DOCUMENT_CUSTOM, FLK_A_PDF_DISPLAY } from '../../../config';

const ContactListTabs = () => {
    return (
        <div className="settings template-tabs">
            <Tabs>
                <TabList>
                    <Tab>
                        <span>{FLK_A_PDF_DISPLAY} Contact List</span>
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="wrapper">
                        <ContactList contactType={DOCUMENT_CUSTOM} />
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default memo(ContactListTabs);
