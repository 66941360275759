import { createSelector } from 'reselect';
export const getConfirmModals = state => state.confirmModals;

export const getConfirmModalLease = createSelector([getConfirmModals], confirmModals => confirmModals.lease);

export const getIsOpenRetractLeaseConfirmModal = createSelector(
    [getConfirmModals],
    confirmModals => confirmModals.isOpenRetractLeaseConfirmModal
);

export const getIsOpenCancelFutureAlertsConfirmModal = createSelector(
    [getConfirmModals],
    confirmModals => confirmModals.isOpenCancelFutureAlertsConfirmModal
);

export const getIsOpenNoLicenseeNumberModal = createSelector(
    [getConfirmModals],
    confirmModals => confirmModals.isNoLicenseeNumberModalOpen
);
