import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import DocumentEditAddressModal from '../DocumentEditAddressModal';
import EditButton from '../../../common/components/EditButton';
import { IntegrationConnection } from '../../../components/integration/IntegrationConnection';
import AuditTrailOpenButton from '../../../common/components/AuditTrailOpenButton';
import EditableAgentContent from '../../../components/form/EditableAgentContent';

import { getAgencyIntegrations } from '../../../selectors/agency';
import { getIsRequestingTeam, getTeam } from '../../../selectors/agency';

import { getAllTeamMembers } from '../../../actions/agency';
import { changeDocumentAgent, FLK_A_KEY_STATUS_DRAFT, FLK_A_KEY_STATUS_CHECKED_OUT } from '../../../actions/dashboard';

import '../../../sass/documentHeader.scss';
import FlkAKeyMoreOptionBlock from '../../../components/dashboard/flkAKeyList/cells/FlkAKeyMoreOptionBlock';
import { addOrReplaceKeyInList } from '../../../actions/flkAKey';

const FlkAKeyHeader = ({
    disabled,
    confirmDetails,
    address,
    addressError,
    handleAddressChange,
    setAddressDetails,
    agentName,
    parentSelector,
    integration,
    linkIntegrationProperty,
    unLinkIntegrationProperty,
    currentKey,
    viewOnlyIntegration,
    hideData,
    blockEditAgent
}) => {
    const dispatch = useDispatch();
    const team = useSelector(getTeam);
    const isRequestingTeam = useSelector(getIsRequestingTeam);

    const [agent, setAgent] = useState({
        value: currentKey?.agent?.id || '',
        label: currentKey?.agent?.fullName || ''
    });
    const [isOpenEditAddress, setIsOpenEditAddress] = useState(false);
    const agencyIntegrations = useSelector(getAgencyIntegrations);
    const integrationObject = {
        ...integration,
        type:
            integration && integration.type
                ? integration.type
                : agencyIntegrations
                ? agencyIntegrations.activeIntegration
                : ''
    };

    /**
     * Update agent when lease all info is grabbed
     */
    useEffect(() => {
        if (currentKey?.agent?.fullName) {
            setAgent({
                value: currentKey.agent.id,
                label: currentKey.agent.fullName
            });
        }
        /**
         * If we dont have team yet, fetch team as it is used for the agent switch of an agreement
         */
        if (!isRequestingTeam && team.length === 0) {
            dispatch(getAllTeamMembers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentKey]);

    const handleChangedAgent = data => {
        setAgent(data.agent);
        return axios.put(`/api/flk-a-key/${currentKey.id}/agent`, { agent: data.agent.value }).then(resp => {
            dispatch(addOrReplaceKeyInList(resp.data.flkAKey));
        });
    };

    return (
        <div className="document-header">
            {currentKey && currentKey.status && (
                <div className={`key-status ${currentKey.status}`}>{currentKey.status.replace(/_/g, ' ')}</div>
            )}
            {currentKey && (
                <div className="action">
                    <FlkAKeyMoreOptionBlock flkAKey={currentKey} />
                    <span className="arrow-right" />
                </div>
            )}
            {!hideData && (
                <React.Fragment>
                    <DocumentEditAddressModal
                        parentSelector={parentSelector}
                        isOpen={isOpenEditAddress}
                        closeModal={() => setIsOpenEditAddress(false)}
                        confirmDetails={confirmDetails}
                        address={address}
                        handleAddressChange={handleAddressChange}
                        setAddressDetails={setAddressDetails}
                    />
                    <div>
                        <div
                            onClick={() => setIsOpenEditAddress(true)}
                            className={`address-info header-container ${disabled ? 'disabled' : ''}`}
                        >
                            <label className="select-label" htmlFor="select-address">
                                <p>
                                    <b>Address:&nbsp;</b>
                                </p>
                            </label>
                            <div className="edit-group">
                                <p className="header-address">{address}</p>
                                {!disabled && <EditButton id="edit-address" />}
                            </div>
                        </div>
                        {addressError && <p className="error-message">{addressError}</p>}
                        {disabled && (
                            <div className="address-info header-container disabled">
                                <label className="select-label" htmlFor="select-address">
                                    <p>
                                        <b>Staff Assigned:&nbsp;</b>
                                    </p>
                                </label>
                                <div className="edit-group">
                                    <p className="header-address">{agentName}</p>
                                </div>
                            </div>
                        )}
                        {!disabled && (
                                <div className="editable">
                                    {agent.value && (
                                        <EditableAgentContent
                                            type="input"
                                            agent={agent}
                                            paramName="agent"
                                            editContent={handleChangedAgent}
                                            label="Staff Assigned"
                                        />
                                    )}
                                </div>
                            )}
                        <IntegrationConnection
                            integration={integrationObject}
                            agencyIntegrations={agencyIntegrations}
                            isKey={true}
                            viewOnly={viewOnlyIntegration}
                            linkIntegrationProperty={linkIntegrationProperty}
                            unLinkIntegrationProperty={unLinkIntegrationProperty}
                            currentKey={currentKey}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default FlkAKeyHeader;
