import React, { Component } from 'react';
import { getPmStepNo, isLeaseLocationSA } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getLabel } from '../../../../../utils/labelUtils';
import { upperFirst } from 'lodash';

export default class AgreementPmDuties extends Component {
    render() {
        const { pmDuties, location, leaseType } = this.props.leaseAllInfo;
        let selectedDuties = [];
        if (pmDuties) {
            if (pmDuties.undertakeInitial) {
                selectedDuties.push(
                    'Undertake initial, periodic and other inspectionsof the Property, including with prospective tenants'
                );
            }
            if (pmDuties.obtainReferences) {
                selectedDuties.push(
                    'Obtain references from prospective tenants, but not undertake additional searches subject to the instructions in Part D of this Agreement'
                );
            }
            if (pmDuties.executeResidentialTenancyAgreement) {
                selectedDuties.push(
                    'Execute a Residential Tenancy Agreement with a prospective Tenant by signing “for and on behalf of” the Landlord and causing the Landlord to be legally bound to the Tenant'
                );
            }
            if (pmDuties.effectRepairs) {
                selectedDuties.push(
                    'Effect repairs to and maintain the Property, or engage a tradesperson todo so, in accordance with Item G of this Agreement'
                );
            }
            if (pmDuties.payDisbursements) {
                selectedDuties.push(
                    'Pay disbursements and expenses incurred in connection with the Agent’s management of the Property, as set out in Item C'
                );
            }
            if (pmDuties.collectRent) {
                selectedDuties.push('Collect rent');
            }
            if (pmDuties.receive) {
                selectedDuties.push('Receive, claim and disburse rental bond money');
            }
            if (pmDuties.payAccounts) {
                selectedDuties.push(
                    'Pay accounts for amounts owing in relation to the Property as set out in Item I of the Particulars'
                );
            }
            if (pmDuties.serveTerminationNotices) {
                selectedDuties.push(
                    'Serve Termination Notices as required. Any Termination Notice served by the Agent may bring about the early termination of the Residential Tenancy Agreement'
                );
            }
            if (pmDuties.undertakeTheNecessarySteps) {
                selectedDuties.push(
                    'Undertake the necessary steps to obtain vacant possession and recover any money owing to the Landlord'
                );
            }
            if (pmDuties.representTheLandlord) {
                selectedDuties.push('Represent the Landlord at the Tribunal');
            }
            if (pmDuties.advertiseTheProperty) {
                selectedDuties.push('Advertise the Property for letting');
            }
            if (pmDuties.reviewTheRent) {
                selectedDuties.push('Review the rent at the end of the tenancy');
            }
            if (pmDuties.theRecovery) {
                selectedDuties.push('The recovery of monies due');
            }
            if (pmDuties.toTerminateTheTenancy) {
                selectedDuties.push(
                    'To terminate the Tenancy and or seek orders of possession and or specific performance'
                );
            }
        }
        return (
            <AgreementSection
                title={getLabel('duties', location, leaseType)}
                className="section-payment"
                step={getPmStepNo(this.props.leaseAllInfo.location, 'pmDuties')}
                leaseAllInfo={this.props.leaseAllInfo}
            >
                <div className="data-container column">
                    <p className="text">
                        <span className="bold">{selectedDuties.join(', ')}</span>
                    </p>
                </div>
                {isLeaseLocationSA(location) && pmDuties && (
                    <div className="listView">
                        {pmDuties.isWaterUsageCostAdjusted && (
                            <p className="name">All water usage costs adjusted for the period of tenancy</p>
                        )}
                        {pmDuties.isWaterUsageCostExcess && (
                            <p className="name">
                                All water usage costs in excess of stated <b>{pmDuties.klPerAnnum}</b>KL per annum, with
                                such allowance to be adjusted for the period of tenancy
                            </p>
                        )}
                        {pmDuties.isWaterSupplyCostAdjusted && (
                            <p className="name">All water supply charges adjusted for the period of tenancy</p>
                        )}
                        {pmDuties.isNoCharges && <p className="name">No Charge for water</p>}
                        {pmDuties.isOthers && <p className="name">Other, {pmDuties.otherDetails}</p>}
                        <div className="propertyDetails">
                            <p className="name">
                                Is there a service that the Property is not individually metered for and the Tenant
                                needs to pay a portion of the costs?&nbsp;
                            </p>
                            <strong>{upperFirst(pmDuties.isPropertyMeteredIndividual)}</strong>
                        </div>
                        {pmDuties.serviceList.length > 0 && (
                            <p>Tenant to pay the following apportionment of the cost of the service(s):&nbsp;</p>
                        )}
                        {pmDuties.serviceList.map((s, index) => {
                            return (
                                <div key={index} className="propertyDetails">
                                    <p className="name">
                                        {`Service: ${s.service}`} :&nbsp;
                                        <strong>{`Apportionment: ${s.apportionment}%`}</strong>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </AgreementSection>
        );
    }
}
