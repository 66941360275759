import React, { memo, useCallback, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { confirmAlert } from 'react-confirm-alert';
import cx from 'classnames';
import { isEmpty, uniq, remove, cloneDeep } from 'lodash';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { openNoSignatureModal } from '../../../../actions/user';
import { formatDocumentsForApi } from '../../../../utils/formUtils';
import EditSenderConfirmation from '../../../../components/modals/document/EditSenderConfirmation';
import { getAgencyTimezoneDate } from '../../../../utils/dateUtils.js';
import BreachReasons, { getBreachNoticeDescription } from './BreachReasons';

import { closeBreachAgreementForm, addOrReplaceDocumentInList } from '../../../../actions/document';
import {
    breachAgreementClauseOptions,
    HIDE_MESSAGE_TIME,
    DELIVERY_TO_AGENT_AND_TENANT,
    DELIVERY_TYPE_EMAIL,
    FORM_SUBMIT_TYPE_SEND,
    CLOSE_MODAL_MESSAGE,
    viewUploadedDocumentFromDocument,
    BREACH_NOTICE
} from '../../../../config';

import DocumentTenantPersonGroup from '../../../../components/document/DocumentTenantPersonGroup';
import { FormTextCurrency, FormTextMultiline, FormNumber } from '../../../../components/form/FormText';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import { SelectField } from '../../../../components/form/FormSelect';
import ModalDialog from '../../../../common/components/ModalDialog';
import * as validators from '../../../../components/Validate';

import { getAgentSignature } from '../../../../selectors/completion';
import { getUserInfo } from '../../../../selectors/user';

import '../../../../sass/documentModalDialog.scss';
import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document';
import { differenceInDays, parseISO } from 'date-fns';
import { isDocumentEditMode, isDocumentReadOnlyMode } from '../../../../utils/generalUtils';
import LocationAndAddressSelection from '../../LocationAndAddressSelection';
import DocumentHeader from '../../DocumentHeader';
import { getIsOpenBreachAgreementModal } from '../../../../selectors/document/breachAgreement';
import DocumentUpload from '../../../shared/DocumentUpload';
import BreachNoticeWithoutLeaseFooter from './BreachNoticeWithoutLeaseFooter';
import DeliveryTypeComponent from '../../shared/DeliveryTypeComponent';
import { CloseSharp } from '@material-ui/icons';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../../../../utils/agreementUtils';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import Icon, { Icons } from '../../../../common/components/Icon';
import ReactTooltip from 'react-tooltip';
import { YES_VALUE, NO_VALUE } from '../../../../config';
import * as dashboard from '../../../../actions/dashboard.js';
import { useLeaseTypeEnabled } from '@app/hooks/useLeaseTypeEnabled';

const UPLOAD_DOCUMENTS_API = 'api/document/upload-breach-documents';
const BREACH_API = 'api/document/breach-notice';

const otherClause = 'Other';
const reactTooltipBackgroundColour = '#FFFFFF';
const MAX_BREACH_REASON_CHARACTERS = 570;
const MAX_BREACH_REASON_NUMBER_OF_LINES = 5;

const BreachNoticeWithoutLeaseForm = () => {
    const dispatch = useDispatch();
    const { allowedStates } = useLeaseTypeEnabled();
    const allowedStatesBreachNotice = allowedStates(BREACH_NOTICE);

    const formValuesRef = React.useRef(null);
    const isOpenBreachAgreementModal = useSelector(getIsOpenBreachAgreementModal);
    const loggedInUser = useSelector(getUserInfo);
    const agentSignature = useSelector(getAgentSignature);
    const currentDocument = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);

    const [currentDocumentId, setCurrentDocumentId] = useState(currentDocument ? currentDocument.id : null);
    const [location, setLocation] = useState(null);
    const [locationAndAddressSelected, setLocationAndAddressSelected] = useState(false);
    const [addEmailFieldError, setAddEmailFieldError] = useState('');
    const [formData, setFormData] = useState({});
    const [isSendingBreachNotice, setIsSendingBreachNotice] = useState(false);
    const [isSendingBreachNoticeSuccess, setIsSendingBreachNoticeSuccess] = useState(false);
    const [isSendingBreachNoticeFail, setIsSendingBreachNoticeFail] = useState(false);
    const [integrationPropertyId, setIntegrationPropertyId] = useState(null);
    const [integration, setIntegration] = useState(null);
    const [addressError, setAddressError] = useState('');
    const [documentsList, setDocumentsList] = useState([]);
    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
    const [busy, setBusy] = useState(null);
    const [backendError, setBackendError] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [isSaveDraft, setIsSaveDraft] = useState(false);
    const [showEditSenderConfirmationModel, setShowEditSenderConfirmationModel] = useState(false);
    const [showLoadingIcon, setShowLoadingIcon] = useState(false);
    const [breachReasonError, setBreachReasonError] = useState(false);
    const [breachReasonErrorMessage, setBreachReasonErrorMessage] = useState('');

    const [agent, setAgent] = useState({
        value: currentDocument && currentDocument.agent ? currentDocument.agent.id : '',
        label: currentDocument && currentDocument.agent ? currentDocument.agent.fullName : ''
    });

    const handleFormDirtyChange = form => {
        if (form.getState().dirty && !isDirty) {
            setIsDirty(true);
        }
    };

    const formRef = React.useRef();

    const submitConfirmation = () => {
        setIsSaveDraft(false);
        setShowEditSenderConfirmationModel(false);
        setIsSendingBreachNotice(true);
        formValuesRef.current.isConfirmed = true;
        formRef.current.dispatchEvent(new Event('submit'));
    };

    useEffect(() => {
        if (currentDocument && isOpenBreachAgreementModal) {
            window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
        }
    }, [isOpenBreachAgreementModal]);

    useEffect(() => {
        if (currentDocument && currentDocument.integration && currentDocument.integration.id) {
            setIntegrationPropertyId(currentDocument.integration.id);
            setIntegration(currentDocument.integration);
        } else {
            setIntegrationPropertyId(null);
            setIntegration(null);
        }
        if (currentDocument) {
            setLocation(currentDocument.location);
            setLocationAndAddressSelected(true);
            setDocumentsList(currentDocument.details.documents);
            setCurrentDocumentId(currentDocument.id);
        }
        setFormData(
            currentDocument
                ? {
                      leaseCompletionDate:
                          currentDocument && currentDocument.details.leaseCompletionDate
                              ? parseISO(currentDocument.details.leaseCompletionDate)
                              : null,
                      address: currentDocument.address,
                      location: currentDocument.location,
                      tenants: currentDocument.tenant.tenants ? currentDocument.tenant.tenants : [],
                      agentName: currentDocument.agent.fullName,
                      addressDetails: currentDocument.addressDetails,
                      deliveryType: currentDocument.details.deliveryType,
                      emailTo:
                          currentDocument.details.deliveryType === DELIVERY_TYPE_EMAIL
                              ? DELIVERY_TO_AGENT_AND_TENANT
                              : null,
                      deliveryTypeDetails: currentDocument.details.deliveryTypeDetails,
                      emailAddresses: currentDocument.details.emailAddresses,
                      ccEmailAddresses: currentDocument.details.ccEmailAddresses,
                      detailsOfClause: currentDocument.details.detailsOfClause,
                      detailsOfBreach: currentDocument.details.detailsOfBreach,
                      isRelatedToRentInArrears: currentDocument.details.isRelatedToRentInArrears
                          ? currentDocument.details.isRelatedToRentInArrears
                          : NO_VALUE,
                      rentPaidDate: currentDocument.details.rentPaidDate
                          ? parseISO(currentDocument.details.rentPaidDate)
                          : null,
                      rentOwingAmount: currentDocument.details.rentOwingAmount,
                      dateOfNotice:
                          currentDocument.details.dateOfNotice &&
                          currentDocument.status !== dashboard.LEASE_STATUS_DRAFT
                              ? parseISO(currentDocument.details.dateOfNotice)
                              : parseISO(new Date().toISOString()),
                      numberOfRentOverdueDays: currentDocument.details.numberOfRentOverdueDays,
                      remediedBreachDate: currentDocument.details.remediedBreachDate
                          ? parseISO(currentDocument.details.remediedBreachDate)
                          : null,
                      toNonPaymentOfRent: currentDocument.details?.toNonPaymentOfRent ?? false,
                      toUsingPremisesForIllegalPurpose:
                          currentDocument.details?.toUsingPremisesForIllegalPurpose ?? false,
                      toKeepingPetWithoutPermission: currentDocument.details?.toKeepingPetWithoutPermission ?? false,
                      toMoreTenantsInProperty: currentDocument.details?.toMoreTenantsInProperty ?? false,
                      toNotKeepingThePropertyInGoodCondition: currentDocument.details?.toMoreTenantsInProperty ?? false,
                      toLocalGovLawsNotMet: currentDocument.details?.toLocalGovLawsNotMet ?? false,
                      toUseOfPropertyCausesANuisance: currentDocument.details?.toUseOfPropertyCausesANuisance ?? false,
                      toOngoingNeighbourDisputes: currentDocument.details?.toOngoingNeighbourDisputes ?? false,
                      toOther: currentDocument.details.toOther ?? false,
                      disabled: currentDocument.completion && isDocumentReadOnlyMode(documentEditMode)
                  }
                : getResetFormData()
        );
        if (!currentDocument) {
            setLocation(loggedInUser?.agency?.details?.location ? loggedInUser.agency.details.location : null);
            setLocationAndAddressSelected(false);
        }
    }, [isOpenBreachAgreementModal, currentDocument]);

    const closeModal = () => {
        getResetFormData();
        setIsDirty(false);
        dispatch(closeBreachAgreementForm());
        if (currentDocument) {
            window.history.pushState(
                {},
                '',
                `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`
            );
        }
    };

    const handleCloseModal = () => {
        if (isDirty) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const values = formValuesRef.current;
                            saveDraft(values).then(response => {
                                setIsSendingBreachNotice(false);
                                dispatch(addOrReplaceDocumentInList(response.data.breach));
                            });
                            closeModal();
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            closeModal();
                        }
                    },
                    {
                        className: 'close close-modal',
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            closeModal();
        }
    };

    const updateDocumentsList = data => {
        setDocumentsList(data);
        setIsDirty(true);
    };

    const getResetFormData = () => {
        return {
            location: location,
            agentName: loggedInUser.fullName,
            address: '',
            addressDetails: '',
            tenants: [
                {
                    firstName: '',
                    middleName: '',
                    secondName: '',
                    email: ''
                }
            ],
            emailAddresses: [],
            isRelatedToRentInArrears: NO_VALUE,
            rentPaidDate: '',
            rentOwingAmount: '',
            remediedBreachDate: '',
            numberOfRentOverdueDays: ''
        };
    };

    const changeSelectedClause = useCallback(
        (item, values) => {
            if (item) {
                let data = cloneDeep(values);
                let clauseText = (isLeaseLocationQLD(location) ? data.detailsOfBreach : data.detailsOfClause) || '';
                if (item.label !== otherClause) {
                    clauseText = clauseText + `${item.label}\n`;
                    clauseText = clauseText + `${item.text}\n\n`;
                }
                isLeaseLocationQLD(location)
                    ? (data.detailsOfBreach = clauseText)
                    : (data.detailsOfClause = clauseText);
                setFormData(data);
            }
        },
        [setFormData, location]
    );

    const getNumberOfLines = text => {
        return text?.split('\n').length;
    };

    const scrollToBreachDetails = errorMessage => {
        const detailsOfBreach = formRef.current.elements['detailsOfBreach'];
        detailsOfBreach.scrollIntoView({ behavior: 'smooth', block: 'end' });
        detailsOfBreach.focus();
        setBreachReasonError(true);
        setBreachReasonErrorMessage(errorMessage);
    };

    const onSelectBreachReason = (item, values, selected) => {
        if (item && isLeaseLocationQLD(location)) {
            let data = cloneDeep(values);
            let clauseText = data.detailsOfBreach || '';
            const { description } = getBreachNoticeDescription(item);
            const reasonCharacterCount = clauseText.length + description.length;
            const noOfLines = getNumberOfLines(clauseText);
            data[item] = selected;
            detailsOfBreachFocusOutErrorHandler();
            if (selected && noOfLines >= MAX_BREACH_REASON_NUMBER_OF_LINES) {
                data[item] = false;
                scrollToBreachDetails('Exceeded spacing limit - consider fitting more onto the same line');
            } else if (selected && reasonCharacterCount >= MAX_BREACH_REASON_CHARACTERS) {
                data[item] = false;
                scrollToBreachDetails('Exceeded 570 character limit');
            }
            if (
                selected &&
                reasonCharacterCount < MAX_BREACH_REASON_CHARACTERS &&
                noOfLines < MAX_BREACH_REASON_NUMBER_OF_LINES
            ) {
                if (description !== otherClause) {
                    clauseText += clauseText !== '' ? ` ${description}` : `${description}`;
                }
            } else {
                clauseText = clauseText.replace(`${description}`, '').trimStart();
            }
            data.detailsOfBreach = clauseText;
            setFormData(data);
        }
    };
    const prepareValuesForSave = values => {
        let data = cloneDeep(values);
        if (data.deliveryType !== DELIVERY_TYPE_EMAIL || data.emailTo !== DELIVERY_TO_AGENT_AND_TENANT) {
            data.emailAddresses = [loggedInUser.email];
            data.ccEmailAddresses = [];
        }
        data.integrationPropertyId = integrationPropertyId;
        return data;
    };

    const uploadDocuments = () => {
        setBusy(true);
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                setDocumentUploadProgress(Math.floor((loaded * 100) / total));
            }
        };
        let documents = formatDocumentsForApi([...documentsList]);
        return axios.post(UPLOAD_DOCUMENTS_API, documents, options);
    };

    const previewPdf = values => {
        if (!isEmpty(agentSignature)) {
            setIsSendingBreachNotice(true);
            setShowLoadingIcon(false);
            setIsSendingBreachNoticeFail(false);
            setIsSendingBreachNoticeSuccess(false);
            setIsSaveDraft(true);
            const preview = true;
            saveDraft(values, preview)
                .then(response => {
                    setIsSendingBreachNotice(false);
                    setShowLoadingIcon(true);
                    viewPdf(response.data.breach.id);
                    dispatch(addOrReplaceDocumentInList(response.data.breach));
                    setCurrentDocumentId(response.data.breach.id);
                })
                .catch(error => {
                    setIsSendingBreachNotice(false);
                    setIsSendingBreachNoticeSuccess(false);
                    if (error.response.data.errors && error.response.data.errors.documents) {
                        setBackendError(error.response.data.errors.documents.error);
                    } else {
                        setBackendError('Something went wrong, please try again');
                    }
                });
        } else {
            closeModal();
            dispatch(openNoSignatureModal());
        }
    };

    const viewPdf = docId => {
        window.open(`/reader/document/breach-notice/${docId}`, '_blank');
    };

    const handleSubmit = values => {
        setIsSendingBreachNotice(true);
        setIsSendingBreachNoticeFail(false);
        setIsSendingBreachNoticeSuccess(false);
        if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
            if (!values.isConfirmed && agent.value && loggedInUser.id !== agent.value) {
                setShowEditSenderConfirmationModel(true);
                return;
            }
            setIsSaveDraft(false);
            return sendDocument(values);
        } else {
            setIsSaveDraft(true);
            setShowLoadingIcon(false);
            return saveDraft(values)
                .then(response => {
                    setIsSendingBreachNotice(false);
                    setIsSendingBreachNoticeSuccess(true);
                    setShowLoadingIcon(true);
                    dispatch(addOrReplaceDocumentInList(response.data.breach));
                    setCurrentDocumentId(response.data.breach.id);
                    setIsDirty(false);
                })
                .catch(error => {
                    setIsSendingBreachNotice(false);
                    setIsSendingBreachNoticeSuccess(false);
                    if (error.response.data.errors && error.response.data.errors.documents) {
                        setBackendError(error.response.data.errors.documents.error);
                    } else {
                        setBackendError('Something went wrong, please try again');
                    }
                });
        }
    };

    const closeEditSenderConfirmationModel = () => {
        setIsSendingBreachNotice(false);
        setShowEditSenderConfirmationModel(false);
    };

    const saveDraft = (values, preview = false) => {
        const data = prepareValuesForSave(values);
        if (documentsList && documentsList.length > 0) {
            if (preview) {
                return axios.post(`${BREACH_API}${currentDocumentId ? `/${currentDocumentId}/save` : ''}`, data);
            } else {
                setBusy(true);
                return uploadDocuments()
                    .then(response => {
                        setBusy(false);
                        data.documents = response.data.documents;
                        return axios.post(
                            `${BREACH_API}${currentDocumentId ? `/${currentDocumentId}/save` : ''}`,
                            data
                        );
                    })
                    .catch(error => {
                        setBusy(false);
                        throw error;
                    });
            }
        } else {
            data.documents = [];
            return axios.post(`${BREACH_API}${currentDocumentId ? `/${currentDocumentId}/save` : ''}`, data);
        }
    };

    const sendBreachData = data => {
        return axios
            .post(`${BREACH_API}${currentDocumentId ? `/${currentDocumentId}/send` : '/send'}`, data)
            .then(() => {
                setIsSendingBreachNotice(false);
                setIsSendingBreachNoticeFail(false);
                setIsSendingBreachNoticeSuccess(true);
                setIsDirty(false);
                setTimeout(() => {
                    closeModal();
                }, HIDE_MESSAGE_TIME);
            });
    };

    const sendDocument = values => {
        let data = prepareValuesForSave(values);
        setDocumentUploadProgress(0);
        setBackendError(null);
        if (validateEmailList(data)) {
            if (!isEmpty(agentSignature)) {
                if (documentsList && documentsList.length > 0) {
                    return uploadDocuments()
                        .then(response => {
                            setBusy(false);
                            data.documents = response.data.documents;
                            return sendBreachData(data);
                        })
                        .catch(error => {
                            setBusy(false);
                            setIsSendingBreachNotice(false);
                            setIsSendingBreachNoticeSuccess(false);
                            let errors = {};
                            if (error.response.data.errors && error.response.data.errors.documents) {
                                setBackendError(error.response.data.errors.documents.error);
                            }
                            if (error.response.data.errors.details) {
                                errors = error.response.data.errors.details;
                                setIsSendingBreachNoticeFail(true);
                            }
                            if (error.response.data.errors.tenant) {
                                errors.tenants = error.response.data.errors.tenant.tenants;
                                setIsSendingBreachNoticeFail(true);
                            }
                            return errors;
                        });
                } else {
                    data.documents = [];
                    return sendBreachData(data).catch(error => {
                        setIsSendingBreachNotice(false);
                        setIsSendingBreachNoticeSuccess(false);
                        setIsSendingBreachNoticeFail(true);
                        setBusy(false);
                        setIsSendingBreachNotice(false);
                        setIsSendingBreachNoticeSuccess(false);
                        let errors = {};
                        if (error.response.data.errors && error.response.data.errors.documents) {
                            setBackendError(error.response.data.errors.documents.error);
                        }
                        if (error.response.data.errors.details) {
                            errors = error.response.data.errors.details;
                            setIsSendingBreachNoticeFail(true);
                        }
                        if (error.response.data.errors.tenant) {
                            errors.tenants = error.response.data.errors.tenant.tenants;
                            setIsSendingBreachNoticeFail(true);
                        }
                        return errors;
                    });
                }
            } else {
                setIsSendingBreachNotice(false);
                closeModal();
                dispatch(openNoSignatureModal());
            }
        } else {
            setIsSendingBreachNotice(false);
            setIsSendingBreachNoticeSuccess(false);
            setIsSendingBreachNoticeFail(false);
        }
    };

    // Change tenant details functions
    const addTenantPerson = values => {
        let data = cloneDeep(values);
        const newTenant = { firstName: '', middleName: '', secondName: '', email: '' };
        if (data.tenants) {
            data.tenants.push(newTenant);
        } else {
            data.tenants = [newTenant];
        }
        setFormData(data);
    };
    const removeTenantPerson = (index, values, disabled) => {
        if (!disabled) {
            let data = cloneDeep(values);
            let emailAddresses = remove(data.emailAddresses, function (n) {
                return n !== data.tenants[index].email;
            });
            data.tenants = [...data.tenants.slice(0, index), ...data.tenants.slice(index + 1)];
            data.emailAddresses = emailAddresses;
            setFormData(data);
        }
    };
    const onChangeEmail = (value, index, values, setValue) => {
        // change email list only email to is agentAndTenant
        if (values.emailTo === DELIVERY_TO_AGENT_AND_TENANT) {
            // set changed value to form values
            values.tenants[index].email = value.target.value;
            emailAgentAndTenantHandler(values, setValue);
        }
    };

    // Delivery email change functions
    const emailAgentAndTenantHandler = (values, setValue) => {
        values.emailTo = DELIVERY_TO_AGENT_AND_TENANT;
        let emailAddresses = [];
        values.tenants.forEach(tenant => {
            if (tenant.email && validateEmail(tenant.email)) {
                emailAddresses.push(tenant.email);
            }
        });
        values.emailAddresses = uniq(emailAddresses);
        values.ccEmailAddresses = [loggedInUser.email];
        values.dateOfNotice = parseISO(new Date().toISOString());
        setValue('emailAddresses', values.emailAddresses);
        setValue('ccEmailAddresses', values.ccEmailAddresses);
        setFormData(values);
    };

    const validateEmail = value => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        }
        setAddEmailFieldError(undefined);
        return true;
    };
    const validateEmailList = values => {
        if (values.deliveryType === DELIVERY_TYPE_EMAIL) {
            if (values.emailAddresses.length === 0) {
                setAddEmailFieldError('Please enter at least one email address');
                return false;
            }
        }
        setAddEmailFieldError('');
        return true;
    };

    // Address and integration functions
    const handleAddressChange = (setValue, address) => {
        setValue('address', address);
    };
    const setUpdateAddressDetails = (address, details, values) => {
        if (!address) {
            setAddressError('Address is required');
        } else {
            setAddressError('');
        }
        let data = cloneDeep(values);
        data.address = address;
        data.location = location;
        data.addressDetails = details;
        setFormData(data);
        setIntegrationPropertyId(null);
        setIntegration(null);
    };
    const confirmDetails = () => {
        const values = formValuesRef.current;
        setIsSendingBreachNotice(true);
        saveDraft(values).then(response => {
            setIsSendingBreachNotice(false);
            setCurrentDocumentId(response.data.breach.id);
            setIsDirty(false);
            dispatch(addOrReplaceDocumentInList(response.data.breach));
            setLocationAndAddressSelected(true);
        });
    };
    const setAddressDetails = (address, details, values) => {
        let data = cloneDeep(values);
        data.address = address;
        data.location = location;
        data.addressDetails = details;
        data.dateOfNotice = parseISO(new Date().toISOString());
        data.isRelatedToRentInArrears = NO_VALUE;
        setIntegrationPropertyId(null);
        setIntegration(null);
        setFormData(data);
    };
    const setIntegrationProperty = propertyDetails => {
        setAgent({
            value: loggedInUser.id,
            label: loggedInUser.fullName
        });
        if (propertyDetails) {
            setFormData({
                location: location,
                address: propertyDetails.address,
                agentName: loggedInUser.fullName,
                tenants: propertyDetails.tenants,
                dateOfNotice: parseISO(new Date().toISOString()),
                detailsOfBreach: ''
            });
            setIntegrationPropertyId(propertyDetails.id);
            setIntegration(propertyDetails);
        } else {
            setFormData(getResetFormData());
        }
    };

    const calculateRentOverDueNumberOfDays = (date, values, isDateOfNotice) => {
        let data = cloneDeep(values);
        let numberOfDays = 0;
        let dateOfNotice = new Date(data.dateOfNotice);
        let rentPaidDate = new Date(data.rentPaidDate);
        if (isDateOfNotice) {
            dateOfNotice = new Date(date);
            data.dateOfNotice = date;
        } else {
            rentPaidDate = new Date(date);
            data.rentPaidDate = date;
        }

        numberOfDays = differenceInDays(dateOfNotice, rentPaidDate);
        data.numberOfRentOverdueDays = Math.abs(numberOfDays);
        setFormData(data);
    };

    const detailsOfBreachErrorHandler = (e, values) => {
        const text = e.target.value;
        const formData = cloneDeep(values);
        if (getNumberOfLines(text) >= MAX_BREACH_REASON_NUMBER_OF_LINES) {
            const lines = text.split('\n');
            lines.length = MAX_BREACH_REASON_NUMBER_OF_LINES;
            formData.detailsOfBreach = lines.join('\n');
            setFormData(formData);
            setBreachReasonError(true);
            setBreachReasonErrorMessage('Exceeded spacing limit - consider fitting more onto the same line');
        } else if (text.length >= MAX_BREACH_REASON_CHARACTERS) {
            setBreachReasonError(true);
            setBreachReasonErrorMessage('Exceeded 570 character limit');
        } else {
            detailsOfBreachFocusOutErrorHandler();
            formData.detailsOfBreach = text;
            setFormData(formData);
        }
    };

    const detailsOfBreachFocusOutErrorHandler = () => {
        setBreachReasonError(false);
        setBreachReasonErrorMessage('');
    };

    return (
        <React.Fragment>
            <ModalDialog
                title={locationAndAddressSelected ? 'Breach Notice' : 'Create new Breach Notice for'}
                isOpen={isOpenBreachAgreementModal}
                closeModal={handleCloseModal}
                shouldCloseOnOverlayClick={false}
                className={`document-centre-modal document-modal-dialog tenant ${
                    locationAndAddressSelected && location ? 'with-header' : 'without-header'
                }`}
                hideCloseButton={isSendingBreachNotice}
                // Quick fix for search results being cut off by modal
                allowOverflow={!locationAndAddressSelected}
            >
                <Form initialValues={formData} onSubmit={handleSubmit}>
                    {({ handleSubmit, values, form }) => {
                        // set the values to the ref so that the close modal can access these values
                        formValuesRef.current = values;
                        return (
                            <form onSubmit={handleSubmit} noValidate id="create-breach-form" ref={formRef}>
                                <FormSpy subscription={{ values: true }} onChange={() => handleFormDirtyChange(form)} />
                                {locationAndAddressSelected && location && (
                                    <div className="breach-notice">
                                        <DocumentHeader
                                            parentSelector="create-breach-form"
                                            confirmDetails={confirmDetails}
                                            address={values.address}
                                            addressError={addressError}
                                            handleAddressChange={address => handleAddressChange(form.change, address)}
                                            setAddressDetails={(address, details) =>
                                                setUpdateAddressDetails(address, details, values)
                                            }
                                            disabled={isDocumentReadOnlyMode(documentEditMode) || integrationPropertyId}
                                            agentName={values.agentName}
                                            integration={integration}
                                            viewOnlyIntegration={true}
                                            doc={currentDocument}
                                            hideMoreOptions={currentDocument && !currentDocument.completion}
                                            setAssignedAgent={setAgent}
                                            defaultIsIntegrationSearch={false}
                                        />
                                        <div>
                                            {isLeaseLocationNSW(location) && (
                                                <div className="calender">
                                                    <DatePickerInAgencyTimeZone
                                                        label="Signed date of the current lease"
                                                        name="leaseCompletionDate"
                                                        selected={values.leaseCompletionDate}
                                                        disabled={values.disabled}
                                                    />
                                                </div>
                                            )}
                                            <div className="tenants">
                                                {formData &&
                                                    formData.tenants &&
                                                    formData.tenants.map((tenant, index) => (
                                                        <DocumentTenantPersonGroup
                                                            key={index}
                                                            index={index}
                                                            removeTenant={index =>
                                                                removeTenantPerson(index, values, values.disabled)
                                                            }
                                                            showHeader={index === 0}
                                                            onChangeEmail={(value, index) =>
                                                                onChangeEmail(value, index, values, form.change)
                                                            }
                                                            disabled={values.disabled}
                                                        />
                                                    ))}
                                                {(currentDocument?.status === dashboard.LEASE_STATUS_DRAFT ||
                                                    isDocumentEditMode(documentEditMode)) && (
                                                    <div className="button">
                                                        <button
                                                            className="add-tenant"
                                                            type="button"
                                                            onClick={() => addTenantPerson(values)}
                                                        >
                                                            Add tenant
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            {isLeaseLocationNSW(location) && (
                                                <>
                                                    <h4> Clause(s) Breached:</h4>
                                                    <SelectField
                                                        label="The following clause(s) of the lease has/have been breached:"
                                                        name="selectedClauses"
                                                        options={breachAgreementClauseOptions[location]}
                                                        disabled={values.disabled}
                                                        onChange={items => changeSelectedClause(items, values)}
                                                    />
                                                    <FormTextMultiline
                                                        name="detailsOfClause"
                                                        label="Details of Clause(s)"
                                                        disabled={values.disabled}
                                                    />
                                                    <FormTextMultiline
                                                        name="detailsOfBreach"
                                                        label="Details of the breach(es) include but are not limited to:"
                                                        disabled={values.disabled}
                                                        isChatGpt={true}
                                                        gpt_fieldName="detailsOfBreach"
                                                        gpt_isDocument={true}
                                                        gpt_documentId={currentDocumentId}
                                                    />
                                                </>
                                            )}
                                            {isLeaseLocationQLD(location) && (
                                                <>
                                                    <h4>Breach Details</h4>
                                                    <BreachReasons
                                                        values={values}
                                                        disabled={values.disabled}
                                                        onSelectBreachReason={onSelectBreachReason}
                                                    />
                                                    <FormTextMultiline
                                                        name="detailsOfBreach"
                                                        label={
                                                            breachReasonError ? (
                                                                <span className="errorMessage">
                                                                    {breachReasonErrorMessage}
                                                                </span>
                                                            ) : (
                                                                'Details of the breach (570 character limit)*'
                                                            )
                                                        }
                                                        staticText={
                                                            <p className="breachReasonTextNote">
                                                                <strong>Note:</strong> FLK it over has added details of
                                                                the general breach below for your consideration, please
                                                                ensure it matches the clauses of the RTA signed by the
                                                                tenant. We also recommend you add specific details
                                                                relating to this breach notice.
                                                            </p>
                                                        }
                                                        disabled={values.disabled}
                                                        maxLength={570}
                                                        onFocus={e => detailsOfBreachErrorHandler(e, values)}
                                                        onBlur={detailsOfBreachFocusOutErrorHandler}
                                                        onKeyUp={e => detailsOfBreachErrorHandler(e, values)}
                                                        inputClassName={cx(
                                                            'formInput',
                                                            'textArea',
                                                            'breachReasonText',
                                                            {
                                                                breachReasonError: breachReasonError
                                                            }
                                                        )}
                                                    />
                                                    <FormRadioGroup
                                                        label="Is this notice related to rent in arrears?"
                                                        name="isRelatedToRentInArrears"
                                                        data={[
                                                            {
                                                                label: 'Yes',
                                                                value: YES_VALUE
                                                            },
                                                            {
                                                                label: 'No',
                                                                value: NO_VALUE
                                                            }
                                                        ]}
                                                        disabled={values.disabled}
                                                        value={values.isRelatedToRentInArrears}
                                                    />
                                                    {values.isRelatedToRentInArrears === YES_VALUE && (
                                                        <>
                                                            <div className="calender">
                                                                <DatePickerInAgencyTimeZone
                                                                    label="Date rent was paid to"
                                                                    name="rentPaidDate"
                                                                    selected={values.rentPaidDate}
                                                                    disabled={values.disabled}
                                                                    required
                                                                    onChange={date =>
                                                                        calculateRentOverDueNumberOfDays(
                                                                            date,
                                                                            values,
                                                                            false
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="formBox">
                                                                <div className="formBox-column">
                                                                    <div className="nowrap large">
                                                                        <FormNumber
                                                                            name="numberOfRentOverdueDays"
                                                                            label="Number of days rent is overdue"
                                                                            required
                                                                            disabled={values.disabled}
                                                                            containerClassName="text-input-rent-overdue-days"
                                                                            onChange={e => {
                                                                                const tempVal = Math.abs(
                                                                                    e.target.value
                                                                                );
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    numberOfRentOverdueDays: tempVal
                                                                                });
                                                                            }}
                                                                        />
                                                                        <FormTextCurrency
                                                                            name="rentOwingAmount"
                                                                            label="Amount of rent owing on the notice issue date"
                                                                            disabled={values.disabled}
                                                                            required
                                                                            precision="2"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="calender">
                                                        <DatePickerInAgencyTimeZone
                                                            label="Date the breach must be remedied by"
                                                            name="remediedBreachDate"
                                                            selected={values.remediedBreachDate}
                                                            disabled={values.disabled}
                                                            minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                                                            required
                                                            toolTipIcon={
                                                                <Icon
                                                                    icon={Icons.HELP}
                                                                    data-for="remediedBreachDateHelp"
                                                                    className="icon"
                                                                    data-tip={true}
                                                                />
                                                            }
                                                        />
                                                        <ReactTooltip
                                                            place="right"
                                                            effect="solid"
                                                            id="remediedBreachDateHelp"
                                                            globalEventOff="wheel"
                                                            className="rentOwingAmountToolTip"
                                                            backgroundColor={reactTooltipBackgroundColour}
                                                        >
                                                            <div>
                                                                <h3 className="heading">Minimum notice periods</h3>
                                                                <h4>Residential tenancy</h4>
                                                                <p className="description">
                                                                    Unpaid rent: 7 days if rent has been unpaid for 7
                                                                    days
                                                                </p>
                                                                <p className="description">General breach: 7 days</p>
                                                                <h4>Moveable dwelling tenancy</h4>
                                                                <p className="description">
                                                                    Unpaid rent: 5 days if rent has days
                                                                </p>
                                                                <p className="description">General breach: 7 days</p>
                                                            </div>
                                                        </ReactTooltip>
                                                    </div>
                                                </>
                                            )}
                                            <h4>Add attachment</h4>
                                            <DocumentUpload
                                                dropzoneClassName="breach-notice__document-upload"
                                                uploading={busy}
                                                updateDocuments={updateDocumentsList}
                                                useSelectedOption={false}
                                                agencyDefault={false}
                                                documents={documentsList}
                                                backendError={backendError}
                                                documentUploadProgress={documentUploadProgress}
                                                hideFileCategory={true}
                                                disabled={values.disabled}
                                                viewDocument={docId =>
                                                    viewUploadedDocumentFromDocument(currentDocument.id, docId)
                                                }
                                            />
                                            <DeliveryTypeComponent
                                                emailAgentAndTenantHandler={emailAgentAndTenantHandler}
                                                addEmailFieldError={addEmailFieldError}
                                                values={values}
                                                form={form}
                                            />
                                            {isLeaseLocationQLD(location) && (
                                                <div className="calender">
                                                    <DatePickerInAgencyTimeZone
                                                        label="Date of the notice"
                                                        name="dateOfNotice"
                                                        selected={values.dateOfNotice}
                                                        minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                                                        disabled={
                                                            values.disabled ||
                                                            values.deliveryType === DELIVERY_TYPE_EMAIL
                                                        }
                                                        onChange={date =>
                                                            calculateRentOverDueNumberOfDays(date, values, true)
                                                        }
                                                        required
                                                    />
                                                </div>
                                            )}
                                            <br />
                                            <BreachNoticeWithoutLeaseFooter
                                                isSendingBreachNotice={isSendingBreachNotice}
                                                isSendingBreachNoticeSuccess={isSendingBreachNoticeSuccess}
                                                isSendingBreachNoticeFail={isSendingBreachNoticeFail}
                                                currentDocument={currentDocument}
                                                documentEditMode={documentEditMode}
                                                previewPdf={() => previewPdf(values)}
                                                viewPdf={viewPdf}
                                                form={form}
                                                isSaveDraft={isSaveDraft}
                                                showLoadingIcon={showLoadingIcon}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!locationAndAddressSelected && (
                                    <LocationAndAddressSelection
                                        location={location}
                                        allowedStates={allowedStatesBreachNotice}
                                        setLocation={setLocation}
                                        closeModal={closeModal}
                                        confirmDetails={confirmDetails}
                                        address={values.address}
                                        handleAddressChange={address => handleAddressChange(form.change, address)}
                                        setAddressDetails={(address, details) =>
                                            setAddressDetails(address, details, values)
                                        }
                                        setIntegrationProperty={property => setIntegrationProperty(property)}
                                        isCreating={isSendingBreachNotice}
                                        defaultIsIntegrationSearch={isLeaseLocationNSW(location)}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Form>
                <EditSenderConfirmation
                    isOpen={showEditSenderConfirmationModel}
                    close={closeEditSenderConfirmationModel}
                    primaryHandler={submitConfirmation}
                    secondaryHandler={closeEditSenderConfirmationModel}
                    currentAgentName={agent.label}
                    loggedUserInfo={{ firstName: loggedInUser?.firstName, fullName: loggedInUser?.fullName }}
                    isLoading={false}
                    docType={BREACH_NOTICE}
                />
            </ModalDialog>
        </React.Fragment>
    );
};

export default memo(BreachNoticeWithoutLeaseForm);
