import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormTextRegular } from './form/FormText.js';
import * as validators from '../components/Validate';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { isMobileWidth } from '../config';
import { addEmail, deleteEmail, setEmail } from '../actions/completion.js';
import { FormRadioGroup } from './form/FormRadioGroup.js';
import { AddSharp } from '@material-ui/icons';
import { getMobileViewEnabled } from '../selectors/dashboard/index.js';
import { getCompletionForm } from '../selectors/completion/index.js';
import { EMAIL_LIST_TYPES } from '../constants/constants';
import styles from './CompletionEmailNotifications.module.scss';
import { FormApi } from 'final-form';

interface form {
    renewalAlert: string;
    renewalDays: number;
    toNotificationList: [];
    ccNotificationList: [];
    bccNotificationList: [];
}

type FormValues = Record<string, any>;

interface Props {
    form: FormApi<FormValues>;
    values: FormValues;
    emails: string[];
    ccEmails: string[];
    bccEmails: string[];
}

export default function CompletionEmailNotifications({
    form,
    values,
    emails,
    ccEmails,
    bccEmails
}: Props): JSX.Element {
    const dispatch = useDispatch();

    const mobileViewEnabled = useSelector(getMobileViewEnabled);

    const formValues: form = useSelector(getCompletionForm);

    const [errors, setErrors] = useState({ toEmail: '', ccEmail: '', bccEmail: '' });
    const [showAddEmail, setShowAddEmail] = useState(false);

    useEffect(() => {
        dispatch(setEmail(EMAIL_LIST_TYPES.toEmail, emails));
        dispatch(setEmail(EMAIL_LIST_TYPES.ccEmail, [...new Set(ccEmails)]));
        dispatch(setEmail(EMAIL_LIST_TYPES.bccEmail, bccEmails));
    }, [JSON.stringify(emails), JSON.stringify(ccEmails), JSON.stringify(bccEmails)]);

    const validate = (value: string, fieldName: string, showEmptyMessage?: boolean) => {
        if (isEmpty(value)) {
            if (showEmptyMessage) {
                setErrors({
                    ...errors,
                    [fieldName]: 'Entered email address cannot be empty.'
                });
            }
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setErrors({
                ...errors,
                [fieldName]: 'Entered email address is not valid.'
            });
            return false;
        }

        return true;
    };

    // handle add email
    const addNewEmail = (
        event: any,
        fieldName: string,
        setValue: (x: string, y: string) => void,
        isClickEvent?: boolean
    ) => {
        const value = isClickEvent ? event : event.target.value;
        // Only if the user pressed enter
        if (isClickEvent || event.keyCode === 13) {
            setErrors({ toEmail: '', ccEmail: '', bccEmail: '' });
            // Check that the field is valid before adding it to the list
            const isValid = validate(value, fieldName, true);
            if (isValid) {
                setValue('email', '');
                setShowAddEmail(false);
                // Add email to the list and wipe out the value of the field
                dispatch(addEmail(EMAIL_LIST_TYPES[fieldName], value));
            }
        }
    };

    const handleBlur = (event: React.FocusEvent, fieldName: string, setValue: (x: string, y: string) => void) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        setErrors({ ...errors, [fieldName]: '' });
        setValue(fieldName, '');
        // Check that the field is valid before adding it to the list
        const isValid = validate(value, fieldName);
        if (!isValid) {
            setValue(fieldName, value);
            return;
        }
        // Add email to the list and wipe out the value of the field
        dispatch(addEmail(EMAIL_LIST_TYPES[fieldName], value));
    };

    // handle press enter
    const pressEnter = (event: React.KeyboardEvent, fieldName: string, setValue: (x: string, y: string) => void) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        // Only if the user pressed enter
        if (event.keyCode === 13) {
            setErrors({ ...errors, [fieldName]: '' });
            setValue(fieldName, '');
            // Check that the field is valid before adding it to the list
            const isValid = validate(value, fieldName);
            if (!isValid) {
                setValue(fieldName, value);
                return;
            }
            // Add email to the list and wipe out the value of the field
            dispatch(addEmail(EMAIL_LIST_TYPES[fieldName], value));
        }
    };

    const { toNotificationList, ccNotificationList, bccNotificationList } = formValues;

    return (
        <div className={styles.emailsContainer}>
            <h2>Email finalised document to</h2>

            {!(mobileViewEnabled && isMobileWidth() && toNotificationList.length === 0) && (
                <p className="emails-title sub">To:</p>
            )}
            <ul className="emails-list">
                {toNotificationList.map((email, index) => (
                    <li key={index}>
                        {email}
                        <button
                            className="btn-delete"
                            type="button"
                            onClick={() => dispatch(deleteEmail(EMAIL_LIST_TYPES.toEmail, index))}
                        />
                    </li>
                ))}
            </ul>
            <div className="for-lg-modal">
                <FormTextRegular
                    name="toEmail"
                    label="Enter email and press enter"
                    autoComplete="none"
                    onKeyDown={(e: React.KeyboardEvent<Element>) => pressEnter(e, 'toEmail', form.change)}
                    onBlur={(e: React.FocusEvent<Element, Element>) => handleBlur(e, 'toEmail', form.change)}
                />
                {errors.toEmail && <p className="has-error for-lg-modal">{errors.toEmail}</p>}
            </div>

            {!(mobileViewEnabled && isMobileWidth() && ccNotificationList.length === 0) && (
                <p className="emails-title sub">CC:</p>
            )}
            <ul className="emails-list">
                {ccNotificationList.map((email: string, index: number) => (
                    <li key={index}>
                        {email}
                        <button
                            className="btn-delete"
                            type="button"
                            onClick={() => dispatch(deleteEmail(EMAIL_LIST_TYPES.ccEmail, index))}
                        />
                    </li>
                ))}
            </ul>
            <div className="for-lg-modal">
                <FormTextRegular
                    name="ccEmail"
                    label="Enter email and press enter"
                    autoComplete="none"
                    onKeyDown={(e: React.KeyboardEvent<Element>) => pressEnter(e, 'ccEmail', form.change)}
                    onBlur={(e: React.FocusEvent<Element, Element>) => handleBlur(e, 'ccEmail', form.change)}
                />
                {errors.ccEmail && <p className="has-error for-lg-modal">{errors.ccEmail}</p>}
            </div>

            {!(mobileViewEnabled && isMobileWidth() && bccNotificationList.length === 0) && (
                <p className="emails-title sub">BCC:</p>
            )}
            <ul className="emails-list">
                {bccNotificationList.map((email: string, index: number) => (
                    <li key={index}>
                        {email}
                        <button
                            className="btn-delete"
                            type="button"
                            onClick={() => dispatch(deleteEmail(EMAIL_LIST_TYPES.bccEmail, index))}
                        />
                    </li>
                ))}
            </ul>
            <div className="for-lg-modal">
                <FormTextRegular
                    name="bccEmail"
                    label="Enter email and press enter"
                    autoComplete="none"
                    onKeyDown={(e: React.KeyboardEvent<Element>) => pressEnter(e, 'bccEmail', form.change)}
                    onBlur={(e: React.FocusEvent<Element, Element>) => handleBlur(e, 'bccEmail', form.change)}
                />
                {errors.bccEmail && <p className="has-error for-lg-modal">{errors.bccEmail}</p>}
            </div>

            <div className="new-email-adding-container for-sm-modal">
                {showAddEmail && (
                    <React.Fragment>
                        <div className="btn-group">
                            <FormRadioGroup
                                name="emailType"
                                data={[
                                    { value: 'toEmail', label: 'To' },
                                    { value: 'ccEmail', label: 'CC' },
                                    { value: 'bccEmail', label: 'BCC' }
                                ]}
                                value={values.emailType}
                                radioGroupClass="wrapped-radio-group"
                            />
                        </div>
                        <FormTextRegular
                            name="email"
                            label="Enter email address"
                            onKeyDown={(event: React.KeyboardEvent) =>
                                addNewEmail(event, values.emailType, form.change)
                            }
                        />
                    </React.Fragment>
                )}
                {errors.toEmail && <p className="has-error">{errors.toEmail}</p>}
                {errors.ccEmail && <p className="has-error">{errors.ccEmail}</p>}
                {errors.bccEmail && <p className="has-error">{errors.bccEmail}</p>}
                <button
                    className={`mobile ${showAddEmail ? 'filled' : ''} outline add`}
                    type="button"
                    onClick={() => {
                        if (showAddEmail) {
                            addNewEmail(values.email, values.emailType, form.change, true);
                        } else {
                            setShowAddEmail(true);
                        }
                    }}
                    disabled={showAddEmail && !values.email}
                >
                    <AddSharp /> Add new email
                </button>
            </div>
        </div>
    );
}
