import React from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import { InfoSharp } from '@material-ui/icons';
import { getError } from '../../utils/formUtils';

import '../../sass/form-chekbox.scss';
import styles from './formCheckbox.module.scss';
import Tooltip from '../../common/components/tooltips/Tooltip';

const CheckboxInput = ({
    input,
    dataTest,
    inputDataTest,
    className,
    inputClassName,
    labelClassName,
    containerClassName,
    topAlignInput,
    text,
    onClick,
    disabled,
    validateOnTouch,
    meta,
    children,
    toolTip
}) => {
    return (
        <div
            className={cx('FormInput', 'FormInputCheck', containerClassName)}
            tabIndex={disabled ? -1 : 0}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
        >
            <div
                data-test={dataTest}
                className={cx(styles.checkboxContainer, className, {
                    [styles.active]: input.checked,
                    [styles.disabled]: disabled,
                    [styles.topAlignedInput]: topAlignInput
                })}
                onClick={() => {
                    if (!disabled) {
                        input.onChange(!input.checked);
                        if (onClick) {
                            onClick(!input.checked);
                        }
                    }
                }}
            >
                <div
                    data-test={inputDataTest}
                    className={cx(styles.inputCheckbox, inputClassName, {
                        [styles.active]: input.checked,
                        [styles.disabled]: disabled,
                        [styles.topAligned]: topAlignInput
                    })}
                />
                <div className={cx(styles.checkboxLabel, labelClassName, { [styles.disabled]: disabled })}>
                    {text || children}
                </div>
                {toolTip && (
                    <InfoSharp className="tooltip-info-icon text-gray" data-tip={toolTip} data-for={input.name} />
                )}
                <Tooltip id={input.name} className="tooltip" place="bottom" html />
            </div>
            {getError(meta, validateOnTouch) && <p className={'FormError'}>{getError(meta, validateOnTouch)}</p>}
        </div>
    );
};

export const CheckboxCheck = ({
    name,
    dataTest,
    inputDataTest,
    className,
    containerClassName,
    inputClassName,
    labelClassName,
    topAlignInput,
    label,
    onClick,
    disabled,
    validateOnTouch = false,
    children,
    toolTip
}) => {
    return (
        <Field
            name={name}
            dataTest={dataTest}
            inputDataTest={inputDataTest}
            containerClassName={containerClassName}
            className={className}
            topAlignInput={topAlignInput}
            inputClassName={inputClassName}
            labelClassName={labelClassName}
            component={CheckboxInput}
            type={'checkbox'}
            onClick={onClick}
            text={label}
            disabled={disabled}
            validateOnTouch={validateOnTouch}
            toolTip={toolTip}
        >
            {children}
        </Field>
    );
};
