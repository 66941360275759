import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PreLoader } from '../../../common/components/PreLoader';
import '../../../sass/integrations.scss';
import { Form } from 'react-final-form';
import { FormTextRegular } from '../../form/FormText';

import { connect } from 'react-redux';
import { CheckboxCheck } from '../../form/FormCheckboxCheck';
import SaveButtonIcon from '../../../containers/settings/SaveButtonIcon';

class PropertyTreeIntegration extends PureComponent {
    static propTypes = {
        getUserInfo: PropTypes.func.isRequired,
        agencyIntegration: PropTypes.object,
        isFetching: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            updateAgencyPending: false,
            updateAgencySuccess: false,
            updateAgencyFailed: false,
            needHelp: false,
        };
    }

    submit(agencyId, values) {
        this.setState({ updateAgencyPending: true });

        this.props
            .updateAgency(agencyId, values)
            .then(() => {
                this.setState({
                    updateAgencySuccess: true,
                    updateAgencyPending: false,
                    updateAgencyFailed: false
                });
            })
            .catch(() => {
                this.setState({
                    updateAgencySuccess: false,
                    updateAgencyFailed: true,
                    updateAgencyPending: false
                });
            });
    }

    setNeedHelp = () => {
        this.setState({ needHelp: true });
    };

    getIsIdle = () => {
        return !this.state.updateAgencySuccess && !this.state.updateAgencyPending && !this.state.updateAgencyFailed;
    };

    handleShouldPushFinishedDocuments = value => {
        this.props
            .updateIntegration(this.props.agency.id, { shouldPushFinishedDocuments: value })
            .then(() => {
                this.setState({
                    updateAgencySuccess: true,
                    updateAgencyPending: false,
                    updateAgencyFailed: false
                });
            })
            .catch(() => {
                this.setState({
                    updateAgencySuccess: false,
                    updateAgencyFailed: true,
                    updateAgencyPending: false
                });
            });
    };

    render() {
        const { agencyIntegration, isFetching } = this.props;

        const { agency } = this.props;
        const isIdle = this.getIsIdle();

        return (
            <Form
                initialValues={agencyIntegration}
                onSubmit={values => this.submit(agency.id, values)}
                validate={() => {
                    return this.props.validationErrors ? this.props.validationErrors : {};
                }}
            >
                {({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="panel property-tree">
                                <h2 className="panel-header">PropertyTree</h2>
                                {isFetching && <PreLoader />}

                                <div className="panel-body">
                                    {!isFetching && (
                                        <CheckboxCheck
                                            name={`shouldPushFinishedDocuments`}
                                            label="Automatically store completed agreements and documents in PropertyTree"
                                            onClick={this.handleShouldPushFinishedDocuments}
                                        />
                                    )}
                                    {!isFetching && agencyIntegration && agencyIntegration.enabled && (
                                        <div>
                                            <span className="savestatus-saved">
                                                Connected <span className="icon savestatus-icon" />
                                            </span>
                                        </div>
                                    )}

                                    {!isFetching &&
                                        (!agencyIntegration || (agencyIntegration && !agencyIntegration.enabled)) && (
                                            <div>
                                                <FormTextRegular
                                                    name={'propertyTreeCompanyName'}
                                                    label="Property Tree Trading Name *"
                                                />
                                                <br />

                                                <div className="question">
                                                    <span />
                                                    <a onClick={this.setNeedHelp} className="special-link">
                                                        Connection instructions
                                                    </a>
                                                </div>
                                                <br />

                                                {this.state.needHelp && (
                                                    <div>
                                                        <ol>
                                                            <li>Open Property Tree</li>
                                                            <li>Go to configuration on the left hand menu.</li>
                                                            <li>
                                                                Click Company & Trust Details at the top left. Under
                                                                Company Details you can find your trading name.
                                                            </li>
                                                            <li>
                                                                Please copy the <strong>exact trading name</strong> and
                                                                paste it in the field above and then click save.
                                                            </li>
                                                            <li>After that is saved please go back to PropertyTree</li>
                                                            <li>Click on configuration again.</li>
                                                            <li>
                                                                Under the integrations title, click on Partner Gateway,
                                                                then find FLKitover and click Enable.
                                                            </li>
                                                            <li>
                                                                You will then be required tick the checkbox and accept
                                                                the Legal Terms, to proceed with the activation.
                                                            </li>
                                                        </ol>
                                                    </div>
                                                )}
                                                <br />
                                                <i>Enabling the integration might take up to 24 hours.</i>
                                            </div>
                                        )}
                                </div>
                                <div className="panel-footer">
                                    <div />
                                    <div>
                                        {(!agencyIntegration || !agencyIntegration.enabled) && (
                                            <SaveButtonIcon
                                                isSaving={this.state.updateAgencyPending}
                                                success={this.state.updateAgencySuccess}
                                                failure={this.state.updateAgencyFailed}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        );
    }
}

export default connect(state => ({
    loggedInUser: state.user.userInfo,
    agency: state.user.agency,
    validationErrors: state.account.validationErrors
}))(PropertyTreeIntegration);
