import React from 'react';
import '../../../sass/dashboardContentTable.scss';
import CreateFlk from './tables/createFlk/CreateFlk';
import * as dashboard from '../../../actions/dashboard';
import CreateFlkSentForSigning from './tables/createFlk/CreateFlkSentForSigning';
import CreateFlkAwaitingCompletion from './tables/createFlk/CreateFlkAwaitingCompletion';
import CreateFlkCompleted from './tables/createFlk/CreateFlkCompleted';

const CreateFlkListings = ({ documentList, docType, agentSignature, docStatus }) => {
    let contentTable;
    switch (docStatus) {
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            contentTable = (
                <CreateFlkSentForSigning
                    documentList={documentList}
                    docType={docType}
                    agentSignature={agentSignature}
                />
            );
            break;
        }
        case dashboard.LEASE_STATUS_AWAITING_COMPLETION: {
            contentTable = (
                <CreateFlkAwaitingCompletion
                    documentList={documentList}
                    docType={docType}
                    agentSignature={agentSignature}
                />
            );
            break;
        }
        case dashboard.LEASE_STATUS_COMPLETE: {
            contentTable = (
                <CreateFlkCompleted documentList={documentList} docType={docType} agentSignature={agentSignature} />
            );
            break;
        }

        default:
            contentTable = <CreateFlk documentList={documentList} docType={docType} agentSignature={agentSignature} />;
    }

    return <div className="table-sort">{contentTable}</div>;
};

export default CreateFlkListings;
