import React, { Fragment, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import * as SignatureTypes from './propTypes';
import classnames from 'classnames/bind';
import size from 'lodash/size';
import Tooltip from 'react-tooltip';

import '../../sass/agreement-signatures-new.scss';
import '../../sass/lightTooltip.scss';

const cx = classnames.bind({
    display: 'agreement-signature__signatures-display',
    image: 'agreement-signature__signature-image',
    square: 'agreement-signature__signature-image--square',
    initials: 'agreement-signature__initials-grid'
});

export const SignaturesDisplay = ({ className, initials, signature, noSignatureRequired, ...divProps }) => {
    const hasSigned = !!size(signature);
    const hasInitialed = !!size(initials);
    return (
        <div {...divProps} className={cx('display', className)}>
            <div>
                <h5>Signature</h5>
                {hasSigned ? (
                    <div
                        className={cx('image')}
                        style={{ backgroundImage: `url(${signature.base64SignatureImage})` }}
                    />
                ) : (
                    <p className={cx('awaiting')}>
                        {noSignatureRequired ? 'No signature required.' : 'Awaiting signature.'}
                    </p>
                )}
            </div>
            {hasInitialed ? (
                <div>
                    <h5>Initials</h5>
                    <div className={cx('initials')}>
                        {initials.map(initial => {
                            if (initial.base64SignatureImage) {
                                return (
                                    <Fragment key={initial.id}>
                                        <div
                                            {...(initial.tooltip
                                                ? {
                                                      'data-tip': true,
                                                      'data-for': initial.id
                                                  }
                                                : {})}
                                            className={cx('image', 'square')}
                                            style={{
                                                backgroundImage: `url(${initial.base64SignatureImage})`
                                            }}
                                        />
                                        {initial.tooltip && (
                                            <Tooltip id={initial.id} place="top" effect="solid">
                                                {initial.tooltip}
                                            </Tooltip>
                                        )}
                                    </Fragment>
                                );
                            }
                            return;
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

SignaturesDisplay.propTypes = {
    signature: SignatureTypes.signature.isRequired,
    initials: PropTypes.arrayOf(PropTypes.shape(SignatureTypes.signature)),
    className: PropTypes.string,
    noSignatureRequired: PropTypes.bool
};

SignaturesDisplay.defaultProps = {
    className: '',
    initials: []
};
