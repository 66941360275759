import axios from 'axios';

import * as Signatures from './../reducers/signatures';
import {
    REQUEST_SIGNATURE_URL_FAIL,
    REQUEST_SIGNATURE_URL_REQUEST,
    REQUEST_SIGNATURE_URL_SUCCESS
} from './../reducers/signatures';

const SIGNATURE_URL = '/api/agency/account/signatures';

/**
 * Add signature.
 * @param {object} signature
 * @returns {{type, signature: *}}
 */
export function addSignature(data) {
    return {
        types: [Signatures.ADD_SIGNATURE_REQUEST, Signatures.ADD_SIGNATURE_SUCCESS, Signatures.ADD_SIGNATURE_FAIL],
        promise: axios.post(SIGNATURE_URL, data)
    };
}

/**
 * Update signature.
 *
 * @param {object} signature
 *
 * @returns {{type, signature: *}}
 */
export function updateSignature(signature) {
    return {
        types: [
            Signatures.UPDATE_SIGNATURE_REQUEST,
            Signatures.UPDATE_SIGNATURE_SUCCESS,
            Signatures.UPDATE_SIGNATURE_FAIL
        ],
        promise: axios.put(SIGNATURE_URL + `/${signature.id}`, signature)
    };
}

export function deleteSignature(signature) {
    return {
        types: [
            Signatures.DELETE_SIGNATURE_REQUEST,
            Signatures.DELETE_SIGNATURE_SUCCESS,
            Signatures.DELETE_SIGNATURE_FAIL
        ],
        promise: axios.delete(SIGNATURE_URL + `/${signature.id}`)
    };
}

export function getSignatureList() {
    return {
        types: [
            Signatures.GET_SIGNATURE_LIST_REQUEST,
            Signatures.GET_SIGNATURE_LIST_SUCCESS,
            Signatures.GET_SIGNATURE_LIST_FAIL
        ],
        promise: axios.get(SIGNATURE_URL)
    };
}

export function closeModalSignature() {
    return {
        type: Signatures.CLOSE_MODAL_SIGNATURE
    };
}

export function openModalSignature(type, values) {
    return {
        type: Signatures.OPEN_MODAL_SIGNATURE,
        data: { type, values }
    };
}

export function isTokenValid(token) {
    return {
        types: [
            Signatures.CHECK_TOKEN_VALIDITY_REQUEST,
            Signatures.CHECK_TOKEN_VALIDITY_SUCCESS,
            Signatures.CHECK_TOKEN_VALIDITY_FAIL
        ],
        promise: axios.get(`${SIGNATURE_URL}/sign/${token}`)
    };
}

export function setInvalidToken() {
    return {
        type: Signatures.CHECK_TOKEN_VALIDITY_FAIL
    };
}

export function requestSignatureUpdate() {
    return {
        types: [
            Signatures.REQUEST_SIGNATURE_ADD_REQUEST,
            Signatures.REQUEST_SIGNATURE_ADD_SUCCESS,
            Signatures.REQUEST_SIGNATURE_ADD_FAIL
        ],
        promise: axios.post(`${SIGNATURE_URL}/sign/request`)
    };
}

export function requestSignatureClear() {
    return {
        type: Signatures.REQUEST_SIGNATURE_ADD_CLEAR
    };
}

export function requestSignatureUpdateURL() {
    return {
        types: [
            Signatures.REQUEST_SIGNATURE_URL_REQUEST,
            Signatures.REQUEST_SIGNATURE_URL_SUCCESS,
            Signatures.REQUEST_SIGNATURE_URL_FAIL
        ],
        promise: axios.get(`${SIGNATURE_URL}/sign/request/url`)
    };
}

export function saveAgentSignature(signature) {
    return {
        type: Signatures.SAVE_AGENT_SIGNATURE,
        payload: {
            signature: signature
        }
    };
}

export function saveSignature(signature) {
    return {
        types: [Signatures.ADD_SIGNATURE_REQUEST, Signatures.ADD_SIGNATURE_SUCCESS, Signatures.ADD_SIGNATURE_FAIL],
        promise: axios.post(SIGNATURE_URL, signature)
    };
}
