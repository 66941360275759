import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import React, { memo, useEffect, useState } from 'react';
import cookie from 'react-cookie';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logIn, setLoginRedirect } from '../actions/login';
import appHistory from './../AppHistory';

import logoPng from '../../assets/images/logo.png';
import * as validators from '../components/Validate';
import { CheckboxCheck } from '../components/form/FormCheckboxCheck';
import { FormEmail } from '../components/form/FormEmail';
import { FormPassword } from '../components/form/FormPassword';
import { toSaveToken } from '../utils/toSaveToken';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useTimer } from 'react-timer-hook';
import { useIntercom } from 'react-use-intercom';
import { LEASE_STATUS_DRAFT } from '../actions/dashboard';
import Button from '../common/components/Button';
import ContactUs from '../components/ContactUs.tsx';
import { INVALID_PASSWORD_MESSAGE, isValidPassword } from '../components/Validate';
import { useAccountProvider } from '../components/providers/AccountProvider';
import { useUpdate } from '../components/providers/UpdateProvider';
import {
    LEASE_TYPE_RESIDENTIAL,
    TWO_FACTOR_AUTHENTICATION_APP,
    TWO_FACTOR_AUTHENTICATION_SMS,
    VERIFICATION_CODE_LIMIT_MESSAGE,
    isMobileWidth
} from '../config';
import {
    AGENCY_ACCOUNT_TYPE,
    ALL,
    MFA_AUTHENTICATION_FAILED,
    MFA_CODE_EXPIRE_MESSAGE,
    STATUS_CODE_TOO_MANY_REQUEST,
    STATUS_CODE_UNAUTHORIZED,
    VERIFICATION_CODE_REQUIRED_MESSAGE
} from '../constants/constants';
import { FLK_B_MOBILE_RESPONSIVE } from '../constants/featureFlags';
import '../sass/logIn.scss';
import { getAgencyId } from '../selectors/authorization';
import {
    getDirectLeaseId,
    getIsPending,
    getIsUserLoggedIn,
    getLoggedInUser,
    getLoginRedirect,
    getNeedMfaVerification,
    getPreviousAccountType,
    getRememberMe,
    getValidationError
} from '../selectors/login';
import { initLiveSession } from '../utils/generalUtils';
import pushDetailsToHubspot from '../utils/hubspotUtils';
import { isAgencyUserAccount, isBusinessUserAccount } from '../utils/userUtils';
import AppMfa from './login/AppMfa.tsx';
import SmsMfa from './login/SmsMfa.tsx';
import useCountry from '../hooks/useCountry.ts';

const LogIn = () => {
    const dispatch = useDispatch();
    const { boot, update } = useIntercom();
    const { setCountry } = useCountry();

    const isUserLoggedin = useSelector(getIsUserLoggedIn);
    const loginRedirect = useSelector(getLoginRedirect);
    const previousAccountType = useSelector(getPreviousAccountType);
    const userFromLogin = useSelector(getLoggedInUser);
    const needMfaVerification = useSelector(getNeedMfaVerification);

    const isPending = useSelector(getIsPending);
    const validationError = useSelector(getValidationError);
    const rememberMe = useSelector(getRememberMe);

    const agencyIdAuth = useSelector(getAgencyId);

    const directLeaseId = useSelector(getDirectLeaseId);

    const [error, setError] = useState(false);
    const [login, setLogin] = useState({
        email: '',
        password: '',
        rememberMe: true,
        rememberTrustDevice: true
    });

    const [mfaMethod, setMfaMethod] = useState(null);
    const [showContactUs, setShowContactUs] = useState(false);

    const { seconds, restart } = useTimer({ expiryTimestamp: new Date() });

    const hasUpdate = useUpdate();

    const accountType = useAccountProvider();

    const isFlkBMobileResponsivenessEnabled = useFeatureFlag(FLK_B_MOBILE_RESPONSIVE);

    const send2faCode = useMutation(
        data => {
            setError('');
            return axios.post(`/auth/resend-2fa-code`, data);
        },
        {
            onSuccess: () => {
                const time = new Date();
                time.setSeconds(time.getSeconds() + 30);
                restart(time);
            },
            onError: error => {
                if (error?.response?.data?.error === VERIFICATION_CODE_LIMIT_MESSAGE) {
                    setError(VERIFICATION_CODE_LIMIT_MESSAGE);
                }
            }
        }
    );

    useEffect(() => {
        if (userFromLogin?.multiFactorAuthenticationMethods?.length > 0) {
            if (userFromLogin.multiFactorAuthenticationMethods.includes(TWO_FACTOR_AUTHENTICATION_APP)) {
                setMfaMethod(TWO_FACTOR_AUTHENTICATION_APP);
            } else if (userFromLogin.multiFactorAuthenticationMethods.includes(TWO_FACTOR_AUTHENTICATION_SMS)) {
                setMfaMethod(TWO_FACTOR_AUTHENTICATION_SMS);
            }
        }
    }, [userFromLogin]);

    useEffect(() => {
        document.title = 'Log in';
        if (!isMobileWidth()) {
            boot({ customAttributes: { page: document.title } });
        }

        initLiveSession();

        // if user arrives here and was already logged in
        if (cookie.load('userFromLogin') && !agencyIdAuth) {
            if ((isAgencyUserAccount(accountType) || isFlkBMobileResponsivenessEnabled) && isMobileWidth()) {
                return appHistory.push('/welcome');
            }
            if (isBusinessUserAccount(accountType)) {
                return appHistory.push(`/dashboard/documents/${ALL}/${LEASE_STATUS_DRAFT}`);
            } else {
                return appHistory.push(`/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${LEASE_STATUS_DRAFT}`);
            }
        }

        // if user's login was successful
        if (isUserLoggedin === true && userFromLogin) {
            toSaveToken(
                userFromLogin,
                rememberMe,
                userFromLogin.accountType ? userFromLogin.accountType : AGENCY_ACCOUNT_TYPE
            );

            if (loginRedirect && (userFromLogin.accountType === previousAccountType || !previousAccountType)) {
                appHistory.push(loginRedirect);
                // Reset the login redirect once we've used it
                dispatch(setLoginRedirect(''));
            } else if (
                !userFromLogin.accountType ||
                ((isAgencyUserAccount(userFromLogin.accountType) || isFlkBMobileResponsivenessEnabled) &&
                    isMobileWidth())
            ) {
                appHistory.push('/welcome');
            } else {
                if (directLeaseId) {
                    appHistory.push(`/dashboard/agreement/${directLeaseId}`);
                } else {
                    if (userFromLogin.accountType && isBusinessUserAccount(userFromLogin.accountType)) {
                        return appHistory.push(`/dashboard/documents/${ALL}/${LEASE_STATUS_DRAFT}`);
                    } else {
                        return appHistory.push(`/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${LEASE_STATUS_DRAFT}`);
                    }
                }
            }
        } else {
            // something was wrong in login process, remove cookies
            cookie.remove('rememberMe');
            cookie.remove('accountType');
            cookie.remove('userFromLogin');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agencyIdAuth, boot, isUserLoggedin, rememberMe, dispatch, loginRedirect]);

    const onLogIn = data => {
        setError(false);
        setLogin(data);
        if (!isMobileWidth()) {
            update({ customAttributes: { email: data.email } });
            pushDetailsToHubspot(data.email);
        }
        return dispatch(logIn({ ...data, method: mfaMethod }, hasUpdate))
            .then(result => {
                if (result?.data?.user?.agency?.details?.country) {
                    setCountry(result.data.user.agency.details.country);
                }
            })
            .catch(error => {
                if (error.response && error.response.data.errors) {
                    return error.response.data.errors;
                } else if (
                    [MFA_AUTHENTICATION_FAILED, MFA_CODE_EXPIRE_MESSAGE, VERIFICATION_CODE_REQUIRED_MESSAGE].includes(
                        error.response.data.error
                    )
                ) {
                    return { code: error.response.data.error };
                } else if (
                    [STATUS_CODE_UNAUTHORIZED, STATUS_CODE_TOO_MANY_REQUEST].includes(error.response.status) &&
                    error.response.data.error
                ) {
                    setError(error.response.data.error);
                } else {
                    setError('Something went wrong, please try again or contact support.');
                }
            });
    };

    let buttonClass = isPending ? 'loader-button' : 'button';

    return (
        <div className="signIn">
            <div className="content">
                <div className="left">
                    {showContactUs && (
                        <Button className="back-button" type="button" onClick={() => setShowContactUs(false)}>
                            Back
                        </Button>
                    )}
                    <Link to="/" className="logo">
                        <img src={logoPng} className="logo-big" alt="logo" />
                    </Link>
                    <div className="log-in">
                        <Form
                            initialValues={login}
                            onSubmit={onLogIn}
                            validate={({ password, email }) => {
                                return {
                                    email:
                                        email && email.length > 4 && !validators.isCorrectEmail(email)
                                            ? 'Wrong email format'
                                            : undefined,
                                    password:
                                        password && password.length > 1 && !isValidPassword(password)
                                            ? INVALID_PASSWORD_MESSAGE
                                            : undefined
                                };
                            }}
                        >
                            {({ handleSubmit, submitting }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate>
                                        {/* TODO - rendering steps */}
                                        {!isUserLoggedin && !needMfaVerification && (
                                            <>
                                                <FormEmail
                                                    name="email"
                                                    label="Your email"
                                                    alwaysShowOn={true}
                                                    validateOnTouch={true}
                                                />
                                                <FormPassword
                                                    name="password"
                                                    label="Password"
                                                    alwaysShowOn={true}
                                                    validateOnTouch={true}
                                                />
                                                <div className="form-checkbox">
                                                    <CheckboxCheck name="rememberMe" label="Remember me" />
                                                </div>
                                                <p className="text-error">{validationError}</p>
                                                <div className={buttonClass}>
                                                    <button type="submit" disabled={submitting}>
                                                        <div className="loader" />
                                                        Log in
                                                    </button>
                                                </div>
                                                {error && <div className="error-message">{error}</div>}
                                                <div className="reset-password">
                                                    <Link to="request-reset-password">Forgot your password?</Link>
                                                </div>
                                            </>
                                        )}
                                        {!isUserLoggedin && needMfaVerification && (
                                            <>
                                                {showContactUs ? (
                                                    <ContactUs />
                                                ) : (
                                                    <>
                                                        {mfaMethod === TWO_FACTOR_AUTHENTICATION_APP && (
                                                            <AppMfa
                                                                submitting={submitting}
                                                                error={error}
                                                                setError={setError}
                                                                setMfaMethod={setMfaMethod}
                                                                setShowContactUs={setShowContactUs}
                                                                send2faCode={send2faCode}
                                                            />
                                                        )}
                                                        {mfaMethod === TWO_FACTOR_AUTHENTICATION_SMS && (
                                                            <SmsMfa
                                                                submitting={submitting}
                                                                error={error}
                                                                setError={setError}
                                                                setMfaMethod={setMfaMethod}
                                                                setShowContactUs={setShowContactUs}
                                                                send2faCode={send2faCode}
                                                                resendRemainingTime={seconds}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </form>
                                );
                            }}
                        </Form>
                    </div>
                </div>
                <div className="right">
                    <div className="get-account">
                        <div className="account-text">
                            <p>Don’t have an account?</p>
                        </div>
                        <div>
                            <Link to="/signup/landing" className="signup">
                                Sign Up
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(LogIn);
