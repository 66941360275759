import React, { Component } from 'react';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import '../../../../../sass/auditTrail.scss';
import AuditTrailOpenButton from '../../../../../common/components/AuditTrailOpenButton';

class AgreementMainAuditTrail extends Component {
    render() {
        return (
            <AgreementSection title="Audit trail">
                <AuditTrailOpenButton id={this.props.id} isDocument={this.props.isDocument} showText={true} />
            </AgreementSection>
        );
    }
}

export default AgreementMainAuditTrail;
