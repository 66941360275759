import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import * as Lease from '../../reducers/lease';
import produce from 'immer';
import { TERM_TYPE_SINGLE } from '../../config';

const initState = {
    startDate: null,
    termType: TERM_TYPE_SINGLE
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            const { lease } = action.result.data;
            if (lease.term) {
                draftState = { ...initState, ...lease.term };
            } else {
                draftState = { ...initState };
            }
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = { ...initState, ...action.lease.term };
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = { ...initState, ...action.payload.agreementInfo.term };
            break;
        }
        case Lease.LEASE_AGREEMENT_TERM_SUCCESS: {
            draftState = { ...initState, ...action.result.data.lease.term };
            break;
        }
    }
    return draftState;
});
