import { LEASE_CONNECTIONS_SUCCESS, processServerErrors } from './lease';
import { has, isEmpty } from 'lodash';

import {
    UPDATE_CONNECTIONS_REQUEST,
    UPDATE_CONNECTIONS_SUCCESS,
    UPDATE_CONNECTIONS_FAIL,
    GET_CONNECTIONS_SUCCESS,
    GET_CONNECTIONS_COMPANIES_SUCCESS,
    CLEAR_CONNECTIONS_VALIDATION_ERRORS
} from '../actions/types';

const initState = {
    connections: {
        connectionsEnabled: false,
        connectionCompany: null,
        services: {}
    },
    partnerCode: null,
    validationErrors: null,
    isUpdating: false,
    updateSuccessful: true,
    connectionCompanies: []
};

export default function login(state = initState, action) {
    switch (action.type) {
        case UPDATE_CONNECTIONS_REQUEST:
            return {
                ...state,
                isUpdating: true
            };
        case UPDATE_CONNECTIONS_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                updateSuccessful: true,
                connections: {
                    connectionsEnabled: action.result.data.agency.connections.connectionsEnabled,
                    connectionCompany: has(action, 'result.data.agency.connections.connectionCompany')
                        ? action.result.data.agency.connections.connectionCompany
                        : null,
                    partnerCode: action.result.data.agency.connections.partnerCode,
                    services: action.result.data.agency.connections.services
                }
            };
        case UPDATE_CONNECTIONS_FAIL: {
            const { status, data } = action.error.response;

            let newState = {
                ...state,
                isUpdating: false,
                updateSuccessful: false
            };

            if (status === 400) {
                newState.validationErrors = processServerErrors(data.errors);
            }

            return newState;
        }
        case GET_CONNECTIONS_SUCCESS: {
            if (isEmpty(action.result.data.connections)) {
                return {
                    ...state,
                    connections: {
                        connectionsEnabled: false,
                        connectionCompany: null,
                        partnerCode: null,
                        services: {}
                    }
                };
            }

            return {
                ...state,
                connections: {
                    connectionsEnabled: action.result.data.connections.connectionsEnabled,
                    connectionCompany: has(action, 'result.data.connections.connectionCompany')
                        ? action.result.data.connections.connectionCompany
                        : null,
                    partnerCode: action.result.data.connections.partnerCode,
                    services: action.result.data.connections.services
                }
            };
        }
        case GET_CONNECTIONS_COMPANIES_SUCCESS:
            return {
                ...state,
                connectionCompanies: action.result.data.connectionCompanies
            };

        case CLEAR_CONNECTIONS_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: null
            };

        case LEASE_CONNECTIONS_SUCCESS: {
            return {
                ...state,
                connections: action.result.data.lease.connections
            };
        }
    }

    return state;
}
