import { connect } from 'react-redux';
import { getAgreementInfo } from '../../selectors/dashboard/agreementInfo';
import { getUserInfo } from '../../selectors/user';
import TerminateLeaseListModal from '../../components/modals/TerminateLeaseListModal';
import { getIsTerminateLeaseListModalOpen } from '../../selectors/termination';

export default connect(state => ({
    isOpen: getIsTerminateLeaseListModalOpen(state),
    agreementInfo: getAgreementInfo(state),
    loggedInUser: getUserInfo(state)
}))(TerminateLeaseListModal);
