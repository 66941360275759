import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import produce from 'immer';

const initState = {
    isWaterUsageCostAdjusted: false,
    isWaterUsageCostExcess: false,
    isWaterSupplyCostAdjusted: false,
    isNoCharges: false,
    isOthers: false,
    servicesList: []
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS: {
            draftState = initState;
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = action.payload.agreementInfo.outgoings || initState;
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = action.lease.outgoings || initState;
            break;
        }

        case Lease.LEASE_OUTGOINGS_SUCCESS: {
            draftState = action.result.data.lease.outgoings;
            break;
        }
    }
    return draftState;
});
