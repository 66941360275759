import { processServerErrors } from './lease';
import { subscriptionStatus } from '../config';
import produce from 'immer';

export const AGENCY_DETAILS = 'AGENCY_DETAILS';
export const SALES_AGENCY_DETAILS = 'SALES_AGENCY_DETAILS';

export const UPDATE_AGENCY_REQUEST = 'UPDATE_AGENCY_REQUEST';
export const UPDATE_AGENCY_SUCCESS = 'UPDATE_AGENCY_SUCCESS';
export const UPDATE_AGENCY_FAIL = 'UPDATE_AGENCY_FAIL';

export const UPDATE_AGENCY_INFO_REQUEST = 'UPDATE_AGENCY_INFO_REQUEST';
export const UPDATE_AGENCY_INFO_SUCCESS = 'UPDATE_AGENCY_INFO_SUCCESS';
export const UPDATE_AGENCY_INFO_FAIL = 'UPDATE_AGENCY_INFO_FAIL';

export const UPDATE_SALES_AGENCY_INFO_REQUEST = 'UPDATE_SALES_AGENCY_INFO_REQUEST';
export const UPDATE_SALES_AGENCY_INFO_SUCCESS = 'UPDATE_SALES_AGENCY_INFO_SUCCESS';
export const UPDATE_SALES_AGENCY_INFO_FAIL = 'UPDATE_SALES_AGENCY_INFO_FAIL';

export const UPDATE_TEMPLATES_REQUEST = 'UPDATE_TEMPLATES_REQUEST';
export const UPDATE_TEMPLATES_SUCCESS = 'UPDATE_TEMPLATES_SUCCESS';
export const UPDATE_TEMPLATES_FAILURE = 'UPDATE_TEMPLATES_FAILURE';

export const GET_AGENCY_INFO_REQUEST = 'GET_AGENCY_INFO_REQUEST';
export const GET_AGENCY_INFO_SUCCESS = 'GET_AGENCY_INFO_SUCCESS';
export const GET_AGENCY_INFO_FAIL = 'GET_AGENCY_INFO_FAIL';

export const ADD_AGENCY_LOGO_REQUEST = 'ADD_AGENCY_LOGO_REQUEST';
export const ADD_AGENCY_LOGO_SUCCESS = 'ADD_AGENCY_LOGO_SUCCESS';
export const ADD_AGENCY_LOGO_FAIL = 'ADD_AGENCY_LOGO_FAIL';

export const UPDATE_AGENCY_LICENSE_DETAILS_REQUEST = 'UPDATE_AGENCY_LICENSE_DETAILS_REQUEST';
export const UPDATE_AGENCY_LICENSE_DETAILS_SUCCESS = 'UPDATE_AGENCY_LICENSE_DETAILS_SUCCESS';
export const UPDATE_AGENCY_LICENSE_DETAILS_FAIL = 'UPDATE_AGENCY_LICENSE_DETAILS_FAIL';

export const UPDATE_AGENCY_DEFAULT_DETAILS_REQUEST = 'UPDATE_AGENCY_DEFAULT_DETAILS_REQUEST';
export const UPDATE_AGENCY_DEFAULT_DETAILS_SUCCESS = 'UPDATE_AGENCY_DEFAULT_DETAILS_SUCCESS';
export const UPDATE_AGENCY_DEFAULT_DETAILS_FAIL = 'UPDATE_AGENCY_DEFAULT_DETAILS_FAIL';

export const UPDATE_SALES_AGENCY_DEFAULT_DETAILS_REQUEST = 'UPDATE_SALES_AGENCY_DEFAULT_DETAILS_REQUEST';
export const UPDATE_SALES_AGENCY_DEFAULT_DETAILS_SUCCESS = 'UPDATE_SALES_AGENCY_DEFAULT_DETAILS_SUCCESS';
export const UPDATE_SALES_AGENCY_DEFAULT_DETAILS_FAIL = 'UPDATE_SALES_AGENCY_DEFAULT_DETAILS_FAIL';

export const UPDATE_SALES_AGENCY_LICENSE_DETAILS_REQUEST = 'UPDATE_SALES_AGENCY_LICENSE_DETAILS_REQUEST';
export const UPDATE_SALES_AGENCY_LICENSE_DETAILS_SUCCESS = 'UPDATE_SALES_AGENCY_LICENSE_DETAILS_SUCCESS';
export const UPDATE_SALES_AGENCY_LICENSE_DETAILS_FAIL = 'UPDATE_SALES_AGENCY_LICENSE_DETAILS_FAIL';

export const SET_TARIFF_PLAN_REQUEST = 'SET_TARIFF_PLAN_REQUEST';
export const SET_TARIFF_PLAN_SUCCESS = 'SET_TARIFF_PLAN_SUCCESS';
export const SET_TARIFF_PLAN_FAIL = 'SET_TARIFF_PLAN_FAIL';

export const UPDATE_TARIFF_PLAN_REQUEST = 'UPDATE_TARIFF_PLAN_REQUEST';
export const UPDATE_TARIFF_PLAN_SUCCESS = 'UPDATE_TARIFF_PLAN_SUCCESS';
export const UPDATE_TARIFF_PLAN_FAIL = 'UPDATE_TARIFF_PLAN_FAIL';

export const GET_TARIFF_PLAN_REQUEST = 'GET_TARIFF_PLAN_REQUEST';
export const GET_TARIFF_PLAN_SUCCESS = 'GET_TARIFF_PLAN_SUCCESS';
export const GET_TARIFF_PLAN_FAIL = 'GET_TARIFF_PLAN_FAIL';

export const ADD_AMOUNT_OF_AGREEMENTS_SENT_THIS_MONTH = 'ADD_AMOUNT_OF_AGREEMENTS_SENT_THIS_MONTH';

export const CANCEL_TARIFF_PLAN_REQUEST = 'CANCEL_TARIFF_PLAN_REQUEST';
export const CANCEL_TARIFF_PLAN_SUCCESS = 'CANCEL_TARIFF_PLAN_SUCCESS';
export const CANCEL_TARIFF_PLAN_FAIL = 'CANCEL_TARIFF_PLAN_FAIL';

export const UPDATE_DEFAULT_PAYMENT_REQUEST = 'UPDATE_PAYMENT_EFT__REQUEST';
export const UPDATE_DEFAULT_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_EFT_SUCCESS';
export const UPDATE_DEFAULT_PAYMENT_FAIL = 'UPDATE_PAYMENT_EFT_FAIL';

export const CLEAR_TEMPLATES_TO_UPDATE = 'CLEAR_TEMPLATES_TO_UPDATE';

export const GET_DEFAULT_PAYMENT_METHOD_REQUEST = 'GET_PAYMENT_METHODS_REQUEST';
export const GET_DEFAULT_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_DEFAULT_PAYMENT_METHOD_FAIL = 'GET_PAYMENT_METHODS_FAIL';

export const UPDATE_DEFAULT_PM_FEES_REQUEST = 'UPDATE_PM_FEES_REQUEST';
export const UPDATE_DEFAULT_PM_FEES_SUCCESS = 'UPDATE_PM_FEES_SUCCESS';
export const UPDATE_DEFAULT_PM_FEES_FAIL = 'UPDATE_PM_FEES_FAIL';

export const UPDATE_DEFAULT_DOCUMENTS_SUCCESS = 'UPDATE_DEFAULT_DOCUMENTS_SUCCESS';
export const UPDATE_DEFAULT_TENANT_LINKS_SUCCESS = 'UPDATE_DEFAULT_TENANT_LINKS_SUCCESS';

export const SET_AMOUNT_OF_DOCUMENTS_SENT_THIS_MONTH = 'SET_AMOUNT_OF_DOCUMENTS_SENT_THIS_MONTH';

import {
    UPDATE_PAYMENT_DETAILS_SUCCESS,
    UPDATE_FOLLOW_UP_REPORT_EMAILS_SUCCESS,
    UPDATE_ACTIVITY_REPORT_RECEIVER_SUCCESS
} from '../actions/types';

import { CLEAR_DOCUMENT_TEMPLATES_TO_UPDATE, CLEAR_LINK_TEMPLATES_TO_UPDATE } from '../actions/document';

const initState = {
    agency: {
        paymentMethod: {
            DirectDebit: {
                enabled: false
            },
            EFT: {
                enabled: false
            },
            BPAY: {
                enabled: false
            },
            Cheque: {
                enabled: false
            },
            Cash: {
                enabled: false
            }
        },
        agencyDetailsTemplatesToUpdate: {},
        licenseTemplatesToUpdate: {},
        paymentTemplatesToUpdate: {},
        defaultPaymentMethod: [],
        defaultDocuments: [],
        documentTemplatesToUpdate: {},
        defaultTenantLinks: {},
        linksTemplatesToUpdate: {},
        followUpReportSettings: {
            followUpReportEmails: []
        },
        activityReportSettings: {
            activityReportReceivers: []
        },
        identificationImages: {
            propertyManagementIdentificationRequired: true,
            salesIdentificationRequired: true
        }
    },
    validationErrors: null,
    setTariffPlanRequestPending: false,
    setTariffPlanSuccess: true,
    setTariffPlanFail: false,
    setTariffPlanErrorMessage: '',
    cancelTariffPlanRequestPending: false,
    cancelTariffSuccess: true,
    cancelTariffPlanFail: false,
    updateAgencyInfoSuccess: false,
    updateAgencyInfoFailed: false,
    updateAgencyInfoLoading: false,
    UPDATE_AGENCY_DEFAULT_DETAILS_PENDING: false,
    UPDATE_AGENCY_DEFAULT_DETAILS_SUCCESS: false,
    UPDATE_AGENCY_DEFAULT_DETAILS_FAIL: false,
    UPDATE_SALES_AGENCY_DEFAULT_DETAILS_PENDING: false,
    UPDATE_SALES_AGENCY_DEFAULT_DETAILS_SUCCESS: false,
    UPDATE_SALES_AGENCY_DEFAULT_DETAILS_FAIL: false,
    amountOfDocumentsSentThisMonth: null
};

export default function login(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case UPDATE_AGENCY_INFO_REQUEST: {
            newState.updateAgencyInfoSuccess = false;
            newState.updateAgencyInfoFailed = false;
            newState.updateAgencyInfoLoading = true;
            break;
        }
        case UPDATE_AGENCY_INFO_SUCCESS: {
            newState.updateAgencyInfoSuccess = true;
            newState.updateAgencyInfoLoading = false;
            return produce(state, draftState => {
                draftState.agencyDetailsTemplatesToUpdate = {
                    ...state.agencyDetailsTemplatesToUpdate,
                    ...action.payload.templatesToUpdate
                };
                draftState.agency = { ...state.agency, ...action.payload.agency };
            });
        }
        case UPDATE_AGENCY_INFO_FAIL: {
            newState.updateAgencyInfoSuccess = false;
            newState.updateAgencyInfoLoading = false;
            state.updateAgencyInfoFailed = true;
            break;
        }
        case UPDATE_AGENCY_SUCCESS: {
            newState.agency = { ...state.agency, ...action.result.data.agency };
            newState.validationErrors = null;
            break;
        }

        case UPDATE_AGENCY_FAIL: {
            const { status, data } = action.error.response;
            if (status === 400) {
                newState.validationErrors = processServerErrors(data.errors);
            }
            break;
        }
        case UPDATE_DEFAULT_PAYMENT_REQUEST: {
            newState.UPDATE_PAYMENT_EFT_REQUEST_PENDING = true;
            newState.UPDATE_PAYMENT_EFT_REQUEST_SUCCESS = false;
            newState.UPDATE_PAYMENT_EFT_REQUEST_FAIL = false;
            break;
        }

        case CLEAR_TEMPLATES_TO_UPDATE: {
            newState.agencyDetailsTemplatesToUpdate = {};
            newState.licenseTemplatesToUpdate = {};
            newState.paymentTemplatesToUpdate = {};
            break;
        }

        case UPDATE_TEMPLATES_SUCCESS: {
            newState.agencyDetailsTemplatesToUpdate = {};
            newState.licenseTemplatesToUpdate = {};
            newState.paymentTemplatesToUpdate = {};
            break;
        }

        case UPDATE_DEFAULT_PAYMENT_SUCCESS: {
            newState.paymentTemplatesToUpdate = {
                ...state.paymentTemplatesToUpdate,
                ...action.result.data.templatesToUpdate
            };
            newState.agency = { ...state.agency, ...action.result.data.agency };
            newState.validationErrors = null;
            newState.UPDATE_PAYMENT_EFT_REQUEST_PENDING = false;
            newState.UPDATE_PAYMENT_EFT_REQUEST_SUCCESS = true;
            newState.UPDATE_PAYMENT_EFT_REQUEST_FAIL = false;
            break;
        }
        case UPDATE_DEFAULT_PAYMENT_FAIL: {
            newState.paymentTemplatesToUpdate = {};
            const { status, data } = action.error.response;
            if (status === 400) {
                newState.validationErrors = processServerErrors(data.errors);
                newState.UPDATE_PAYMENT_EFT_REQUEST_PENDING = false;
                newState.UPDATE_PAYMENT_EFT_REQUEST_SUCCESS = false;
                newState.UPDATE_PAYMENT_EFT_REQUEST_FAIL = true;
            } else {
                if (status === 403) {
                    newState.error =
                        "You don't have permissions to update this, please contact an admin in your team to make this change.";
                }

                newState.UPDATE_PAYMENT_EFT_REQUEST_PENDING = false;
                newState.UPDATE_PAYMENT_EFT_REQUEST_SUCCESS = false;
                newState.UPDATE_PAYMENT_EFT_REQUEST_FAIL = true;
            }

            break;
        }

        case UPDATE_DEFAULT_PM_FEES_REQUEST: {
            newState.UPDATE_PM_FEES_REQUEST_PENDING = true;
            newState.UPDATE_PM_FEES_REQUEST_SUCCESS = false;
            newState.UPDATE_PM_FEES_REQUEST_FAIL = false;
            break;
        }

        case UPDATE_DEFAULT_PM_FEES_SUCCESS: {
            newState.agency = { ...state.agency, ...action.result.data.agency };
            newState.validationErrors = null;
            newState.UPDATE_PM_FEES_REQUEST_PENDING = false;
            newState.UPDATE_PM_FEES_REQUEST_SUCCESS = true;
            newState.UPDATE_PM_FEES_REQUEST_FAIL = false;
            break;
        }
        case UPDATE_DEFAULT_PM_FEES_FAIL: {
            const { status, data } = action.error.response;
            if (status === 400) {
                newState.validationErrors = processServerErrors(data.errors);
                newState.UPDATE_PM_FEES_REQUEST_PENDING = false;
                newState.UPDATE_PM_FEES_REQUEST_SUCCESS = false;
                newState.UPDATE_PM_FEES_REQUEST_FAIL = true;
            }
            break;
        }
        case UPDATE_SALES_AGENCY_INFO_SUCCESS: {
            return produce(state, draftState => {
                draftState.agencyDetailsTemplatesToUpdate = {
                    ...state.agencyDetailsTemplatesToUpdate,
                    ...action.payload.templatesToUpdate
                };
                draftState.agency = { ...state.agency, ...action.payload.agency };
            });
        }

        case GET_AGENCY_INFO_REQUEST: {
            break;
        }

        case GET_AGENCY_INFO_SUCCESS: {
            newState.agency = { ...state.agency, ...action.result.data.agency };
            newState.validationErrors = null;

            break;
        }

        case UPDATE_FOLLOW_UP_REPORT_EMAILS_SUCCESS: {
            newState.agency = { ...state.agency, ...action.result.data.agency };
            newState.validationErrors = null;

            break;
        }

        case UPDATE_ACTIVITY_REPORT_RECEIVER_SUCCESS: {
            newState.agency = { ...state.agency, ...action.result.data.agency };
            newState.validationErrors = null;

            break;
        }

        case GET_AGENCY_INFO_FAIL: {
            if (action.error.response) {
                const status = action.error.response.status;
                const data = action.error.response.data;
                if (status === 400) {
                    newState.validationErrors = processServerErrors(data.errors);
                }
            }
            break;
        }

        case ADD_AGENCY_LOGO_REQUEST: {
            newState.ADD_AGENCY_LOGO_REQUEST_PENDING = true;
            newState.ADD_AGENCY_LOGO_SUCCESS = false;
            newState.ADD_AGENCY_LOGO_FAIL = false;
            break;
        }
        case ADD_AGENCY_LOGO_SUCCESS: {
            newState.agency = {
                ...state.agency,
                logo: action.result.data.logo
            };
            newState.ADD_AGENCY_LOGO_REQUEST_PENDING = false;
            newState.ADD_AGENCY_LOGO_SUCCESS = true;
            newState.ADD_AGENCY_LOGO_FAIL = false;
            break;
        }
        case ADD_AGENCY_LOGO_FAIL: {
            newState.ADD_AGENCY_LOGO_REQUEST_PENDING = false;
            newState.ADD_AGENCY_LOGO_SUCCESS = false;
            newState.ADD_AGENCY_LOGO_FAIL = true;
            break;
        }
        case UPDATE_AGENCY_LICENSE_DETAILS_REQUEST: {
            newState.UPDATE_AGENCY_LICENSE_DETAILS_REQUEST_PENDING = true;
            newState.UPDATE_AGENCY_LICENSE_DETAILS_SUCCESS = false;
            newState.UPDATE_AGENCY_LICENSE_DETAILS_FAIL = false;
            break;
        }
        case UPDATE_AGENCY_LICENSE_DETAILS_SUCCESS: {
            newState.licenseTemplatesToUpdate = {
                ...state.licenseTemplatesToUpdate,
                ...action.result.data.templatesToUpdate
            };
            newState.UPDATE_AGENCY_LICENSE_DETAILS_REQUEST_PENDING = false;
            newState.UPDATE_AGENCY_LICENSE_DETAILS_SUCCESS = true;
            newState.UPDATE_AGENCY_LICENSE_DETAILS_FAIL = false;
            newState.validationErrors = null;
            break;
        }
        case UPDATE_AGENCY_LICENSE_DETAILS_FAIL: {
            newState.licenseTemplatesToUpdate = {};
            const { status, data } = action.error.response;
            if (status === 400) {
                newState.validationErrors = processServerErrors(data.errors);
            }
            newState.UPDATE_AGENCY_LICENSE_DETAILS_REQUEST_PENDING = false;
            newState.UPDATE_AGENCY_LICENSE_DETAILS_SUCCESS = false;
            newState.UPDATE_AGENCY_LICENSE_DETAILS_FAIL = true;
            break;
        }

        case UPDATE_AGENCY_DEFAULT_DETAILS_REQUEST: {
            newState.UPDATE_AGENCY_DEFAULT_DETAILS_PENDING = true;
            newState.UPDATE_AGENCY_DEFAULT_DETAILS_SUCCESS = false;
            newState.UPDATE_AGENCY_DEFAULT_DETAILS_FAIL = false;
            break;
        }

        case UPDATE_AGENCY_DEFAULT_DETAILS_SUCCESS: {
            newState.UPDATE_AGENCY_DEFAULT_DETAILS_PENDING = false;
            newState.UPDATE_AGENCY_DEFAULT_DETAILS_SUCCESS = true;
            newState.UPDATE_AGENCY_DEFAULT_DETAILS_FAIL = false;
            break;
        }

        case UPDATE_AGENCY_DEFAULT_DETAILS_FAIL: {
            newState.UPDATE_AGENCY_DEFAULT_DETAILS_PENDING = false;
            newState.UPDATE_AGENCY_DEFAULT_DETAILS_SUCCESS = false;
            newState.UPDATE_AGENCY_DEFAULT_DETAILS_FAIL = true;
            break;
        }
        case UPDATE_SALES_AGENCY_DEFAULT_DETAILS_REQUEST: {
            newState.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_PENDING = true;
            newState.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_SUCCESS = false;
            newState.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_FAIL = false;
            break;
        }

        case UPDATE_SALES_AGENCY_DEFAULT_DETAILS_SUCCESS: {
            newState.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_PENDING = false;
            newState.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_SUCCESS = true;
            newState.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_FAIL = false;
            break;
        }

        case UPDATE_SALES_AGENCY_DEFAULT_DETAILS_FAIL: {
            newState.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_PENDING = false;
            newState.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_SUCCESS = false;
            newState.UPDATE_SALES_AGENCY_DEFAULT_DETAILS_FAIL = true;
            break;
        }

        case UPDATE_SALES_AGENCY_LICENSE_DETAILS_REQUEST: {
            newState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_REQUEST_PENDING = true;
            newState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_SUCCESS = false;
            newState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_FAIL = false;
            break;
        }
        case UPDATE_SALES_AGENCY_LICENSE_DETAILS_SUCCESS: {
            newState.licenseTemplatesToUpdate = {
                ...state.licenseTemplatesToUpdate,
                ...action.result.data.templatesToUpdate
            };
            newState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_REQUEST_PENDING = false;
            newState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_SUCCESS = true;
            newState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_FAIL = false;
            newState.validationErrors = null;
            break;
        }
        case UPDATE_SALES_AGENCY_LICENSE_DETAILS_FAIL: {
            const { status, data } = action.error.response;
            if (status === 400) {
                newState.validationErrors = processServerErrors(data.errors);
            }
            newState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_REQUEST_PENDING = false;
            newState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_SUCCESS = false;
            newState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_FAIL = true;
            break;
        }
        case SET_TARIFF_PLAN_REQUEST: {
            newState.setTariffPlanRequestPending = true;
            break;
        }
        case SET_TARIFF_PLAN_SUCCESS: {
            newState.setTariffPlanErrorMessage = '';
            newState.setTariffPlanRequestPending = false;
            newState.setTariffPlanSuccess = true;
            newState.setTariffPlanFail = false;
            newState.agency = { ...state.agency, ...action.result.data.agency };
            break;
        }

        case UPDATE_TARIFF_PLAN_SUCCESS: {
            newState.agency = { ...state.agency, ...action.result.data.agency };
            break;
        }
        case SET_TARIFF_PLAN_FAIL: {
            const { status, data } = action.error.response;
            if (status === 400) {
                newState.setTariffPlanErrorMessage = data.error;
            }
            newState.setTariffPlanRequestPending = false;
            newState.setTariffPlanSuccess = false;
            newState.setTariffPlanFail = true;
            break;
        }

        case GET_TARIFF_PLAN_REQUEST: {
            newState.getTariffPlanRequestPending = true;
            break;
        }
        case GET_TARIFF_PLAN_SUCCESS: {
            newState.getTariffPlanRequestPending = false;
            newState.getTariffPlanSuccess = true;
            newState.getTariffPlanFail = false;
            newState.agency.tariffPlan = action.result.data.tariffPlan;
            newState.amountOfAgreementsSentThisMonth = action.result.data.amountOfAgreementsSentThisMonth;
            newState.amountOfBulkDocumentsSentThisMonth = action.result.data.amountOfBulkDocumentsSentThisMonth;
            newState.amountOfDocumentsSentThisMonth = action.result.data.amountOfDocumentsSentThisMonth;
            break;
        }

        case ADD_AMOUNT_OF_AGREEMENTS_SENT_THIS_MONTH: {
            newState.amountOfAgreementsSentThisMonth += 1;
            break;
        }

        case GET_TARIFF_PLAN_FAIL: {
            newState.getTariffPlanRequestPending = false;
            newState.getTariffPlanSuccess = false;
            newState.getTariffPlanFail = true;
            break;
        }

        case GET_DEFAULT_PAYMENT_METHOD_REQUEST: {
            newState.getDefaultPaymentMethodRequestPending = true;
            break;
        }

        case GET_DEFAULT_PAYMENT_METHOD_SUCCESS: {
            newState.getDefaultPaymentMethodRequestPending = false;
            newState.getDefaultPaymentMethodSuccess = true;
            newState.getDefaultPaymentMethodFail = false;
            newState.agency.defaultPaymentMethod = action.result.data.defaultPaymentMethod;
            break;
        }

        case GET_DEFAULT_PAYMENT_METHOD_FAIL: {
            newState.getDefaultPaymentMethodRequestPending = false;
            newState.getDefaultPaymentMethodSuccess = false;
            newState.getDefaultPaymentMethodFail = true;
            newState.agency.defaultPaymentMethod = false;
            break;
        }

        case CANCEL_TARIFF_PLAN_REQUEST: {
            newState.cancelTariffPlanRequestPending = true;

            break;
        }
        case CANCEL_TARIFF_PLAN_SUCCESS: {
            newState.cancelTariffPlanRequestPending = false;
            newState.cancelTariffSuccess = true;
            newState.cancelTariffPlanFail = false;

            //When user is on normal subscription plan - let the subscription period run out, by still being active
            if (state.agency.tariffPlan.status === subscriptionStatus.ACTIVE) {
                newState.agency = {
                    ...state.agency,
                    tariffPlan: { ...state.agency.tariffPlan, active: true, status: subscriptionStatus.CANCELLING }
                };
                break;
                //When user is on trial subscription plan - end subscription immediately, by setting active to false
            } else {
                newState.agency = {
                    ...state.agency,
                    tariffPlan: { ...state.agency.tariffPlan, active: false, status: subscriptionStatus.INACTIVE }
                };
                break;
            }
        }
        case CANCEL_TARIFF_PLAN_FAIL: {
            newState.cancelTariffPlanRequestPending = false;
            newState.cancelTariffSuccess = false;
            newState.cancelTariffPlanFail = true;
            break;
        }
        case UPDATE_PAYMENT_DETAILS_SUCCESS: {
            newState.agency = {
                ...state.agency,
                card: action.result.data.card
            };
            break;
        }

        case UPDATE_DEFAULT_DOCUMENTS_SUCCESS: {
            return produce(state, draftState => {
                draftState.agency.defaultDocuments = action.result.data.agency.defaultDocuments;
                draftState.agency.documentTemplatesToUpdate = action.result.data.templatesToUpdate;
            });
        }

        case CLEAR_DOCUMENT_TEMPLATES_TO_UPDATE: {
            return produce(state, draftState => {
                draftState.agency.documentTemplatesToUpdate = {};
            });
        }

        case UPDATE_DEFAULT_TENANT_LINKS_SUCCESS: {
            return produce(state, draftState => {
                draftState.agency.defaultTenantLinks = action.payload.data;
                draftState.agency.linksTemplatesToUpdate = action.payload.templatesToUpdate;
            });
        }

        case CLEAR_LINK_TEMPLATES_TO_UPDATE: {
            return produce(state, draftState => {
                draftState.agency.linksTemplatesToUpdate = {};
            });
        }

        case SET_AMOUNT_OF_DOCUMENTS_SENT_THIS_MONTH: {
            return produce(state, draftState => {
                draftState.amountOfDocumentsSentThisMonth = action.payload.amountOfDocumentsSentThisMonth;
            });
        }
    }

    return newState;
}
