import React from 'react';

import { FormTextRegular, FormNumber } from '../../../../form/FormText';
import { FormResponsiveNumbers } from '../../../../form/responsive/FormResponsiveNumbers';

export const TenantPaymentGroup = ({ index, removeOtherService, form }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Other Service {index + 1}</h3>
                    <div className="toggle" onClick={() => removeOtherService(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular name={`serviceList[${index}].description`} label="Service description" required />
                    <FormResponsiveNumbers
                        name={`serviceList[${index}].proportion`}
                        label="Proportion"
                        options={[25, 50, 100]}
                        otherLabel="Other"
                        postFix="%"
                        form={form}
                    />
                </div>
            </div>
        </div>
    );
};
