import React, { Component } from 'react';
import { isEmpty, map } from 'lodash';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { services as servicesList } from '../../../../../config';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';

class AgreementResidentialConnections extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { connections, leaseAllInfo } = this.props;

        return (
            <AgreementSection
                title="Connections"
                step={getResTenStepNo(leaseAllInfo.location, 'connections', leaseAllInfo.subLeaseType)}
                leaseAllInfo={leaseAllInfo}
            >
                <div className="agreement-section-list">
                    {isEmpty(connections) || !connections.connectionsEnabled ? (
                        <p>Connections have been disabled for this lease.</p>
                    ) : (
                        <div>
                            <p>Connections have been enabled for:</p>
                            <ul>
                                {map(servicesList, (value, key) => {
                                    if (connections.services[value.inputName]) {
                                        return <li key={value.inputName}>{value.title}</li>;
                                    }
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            </AgreementSection>
        );
    }
}

export default AgreementResidentialConnections;
