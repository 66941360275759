import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLeaseInfo } from '../../../../../../selectors/lease';
import { SCHEDULE_TYPE_NOW, SCHEDULE_TYPE_LATER } from '../../../../../../config';

import Button from '../../../../../../common/components/Button';
import { ScheduledSummary, EmptySummary, SendTogetherSummary } from './components/SummaryComponents';
import { LEASE_STATUS_SENT_SIGNING } from '../../../../../../actions/dashboard';
import MoreOptionsButton from './components/MoreOptionsButton';

import styles from './RenewalFlowSummary.module.scss';
import { openCancelFutureAlertsConfirmModal } from '../../../../../../actions/confirmModals';
import { isEmpty } from 'lodash';

function RenewalFlowSummary({ values, summaryView, setIsOpen, disabled }) {
    const dispatch = useDispatch();

    const lease = useSelector(getLeaseInfo);

    function cancelRenewalFutureAlerts(setShowActionList) {
        setShowActionList(false);
        dispatch(openCancelFutureAlertsConfirmModal(lease));
    }

    return (
        <div className={styles.formSummary}>
            {!isEmpty(lease) && values.vacantPossessionDate && values.scheduleType === SCHEDULE_TYPE_LATER && (
                <ScheduledSummary values={values} summaryView={summaryView} />
            )}
            {!values.vacantPossessionDate && values.scheduleType === SCHEDULE_TYPE_LATER && <EmptySummary />}
            {summaryView && values.scheduleType === SCHEDULE_TYPE_NOW && <SendTogetherSummary />}

            {!disabled && summaryView && (
                <Button
                    onClick={() => setIsOpen(true)}
                    type="button"
                    secondary
                    newSecondary
                    className={styles.editButton}
                >
                    Edit
                </Button>
            )}
            {summaryView &&
                lease.status === LEASE_STATUS_SENT_SIGNING &&
                lease.renewalNoticeToLeaveDetails &&
                lease.renewalNoticeToLeaveDetails.scheduleType === SCHEDULE_TYPE_LATER &&
                !lease.renewalNoticeToLeaveDetails.isCancelled && (
                    <MoreOptionsButton
                        label="Edit"
                        itemList={[
                            {
                                label: 'Cancel future alerts',
                                className: 'red',
                                onClick: cancelRenewalFutureAlerts
                            }
                        ]}
                    />
                )}
            {summaryView && lease?.renewalNoticeToLeaveDetails?.isCancelled && (
                <Button onClick={() => {}} type="button" suggestion error className={styles.editButton}>
                    Alerts cancelled
                </Button>
            )}
        </div>
    );
}

export default RenewalFlowSummary;
