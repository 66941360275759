import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import { Table, Th, Tr, Td, Thead } from 'reactable';
import ModalDialog from '../../common/components/ModalDialog';

import EspLetterListButton from '../dashboard/documentList/components/EspLetterListButton';
import { getAgentSignature } from '../../selectors/completion';

import '../../sass/terminateLeaseListModal.scss';

const EspLetterListModal = ({ isOpen, closeEspLetterListModal, agreementInfo }) => {
    const agentSignature = useSelector(getAgentSignature);
    const revisedEspList = agreementInfo && agreementInfo.revisedEspList ? cloneDeep(agreementInfo.revisedEspList) : [];
    const reversedRevisedEspList = revisedEspList.reverse();
    const closeModal = () => {
        closeEspLetterListModal();
    };

    return (
        <ModalDialog
            title="Revised ESP List"
            isOpen={isOpen}
            closeModal={closeModal}
            className="terminate-list-modal-dialog"
        >
            <p>Address: {agreementInfo.address}</p>

            <div className="table-sort">
                <Table>
                    <Thead>
                        <Th column="dateTime">DATE OF NOTICE</Th>
                        <Th column="revisedEsp">REVISED ESP</Th>
                        <Th column="servedBy">NOTICE SERVED BY</Th>
                        <Th column="pdf">&nbsp;</Th>
                    </Thead>
                    {reversedRevisedEspList.map((revisedEsp, key) => {
                        return (
                            <Tr key={key}>
                                <Td column="dateTime">{revisedEsp.dateOfServiceFormatted}</Td>
                                <Td column="revisedEsp">{revisedEsp.revisedEsp}</Td>
                                <Td column="servedBy">{revisedEsp.nameOfpersonServing}</Td>
                                <Td column="pdf">
                                    <EspLetterListButton
                                        revisedEsp={revisedEsp}
                                        doc={agreementInfo}
                                        agentSignature={agentSignature}
                                    />
                                </Td>
                            </Tr>
                        );
                    })}
                </Table>
            </div>
        </ModalDialog>
    );
};

export default EspLetterListModal;
