import React, { memo, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmDetailsRepairs } from '../../../../selectors/lease/pmLease';

import { FormTextCurrency, FormTextRegular } from '../../../../components/form/FormText';
import '../../../../sass/management/detailsRepairs.scss';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { booleanToString } from '../../../../utils/agreementUtils';
import {
    SMOKE_ALARM_TYPE_NON_COMPLIANT,
    SMOKE_ALARM_TYPE_COMPLIANT,
    SMOKE_ALARM_TYPE_UNKNOWN
} from '../../../../config';

const PM_DETAIL_REPAIRS_FORM = 'pmDetailsRepairsForm';
const initState = {
    description: '',
    smokeAlarmsMaintainedBy: 'Landlord',
    conditionOfExterior: '',
    conditionOfInterior: '',
    fittingsAndFixtures: '',
    smokeAlarmsCompliantType: SMOKE_ALARM_TYPE_COMPLIANT,
    fittings: '',
    improvements: '',
    detailsOfWorkToBeCompleted: '',
    smokeAlarmCompanyName: '',
    smokeAlarmAmount: '',
    preferedInsurer: '',
    nominateStrataDetails: false,
    strataPlanNo: '',
    lotNo: '',
    strataManagingAgent: '',
    byLawsprovided: false
};

const DetailsRepairs = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmDetailsRepairs = useSelector(getPmDetailsRepairs);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmDetailsRepairs, setPmDetailsRepairs] = useState(reduxPmDetailsRepairs || initState);
    const [isLandLordInsuranceInstructionsDisabled, setIsLandLordInsuranceInstructionsDisabled] = useState(
        pmDetailsRepairs.landlordInsuranceInstructions || pmDetailsRepairs.preferredInsurer ? false : true
    );

    useEffect(() => {
        const clonedDetailsRepairs = cloneDeep(pmDetailsRepairs);
        clonedDetailsRepairs.smokeAlarms = booleanToString(clonedDetailsRepairs.smokeAlarms);
        clonedDetailsRepairs.landlordInsuranceInstructions =
            typeof pmDetailsRepairs.landlordInsuranceInstructions === 'undefined' && !pmDetailsRepairs.preferredInsurer
                ? undefined
                : pmDetailsRepairs.landlordInsuranceInstructions === true || pmDetailsRepairs.preferredInsurer
                ? 'yes'
                : 'no';
        setPmDetailsRepairs(clonedDetailsRepairs);
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_DETAIL_REPAIRS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmDetailRepairs(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmDetailsRepairs')) {
                    return error.response.data.errors.pmDetailsRepairs;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmDetailRepairs = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-details-repairs`, data);
    };

    const handleChangeLandLordInsuranceInstructionsYes = form => {
        setIsLandLordInsuranceInstructionsDisabled(false);
        form.change('landlordInsuranceInstructions', 'yes');
        form.change('preferredInsurer', '');
    };

    const handleChangeLandLordInsuranceInstructionsNo = form => {
        setIsLandLordInsuranceInstructionsDisabled(true);
        form.change('landlordInsuranceInstructions', 'no');
        form.change('preferredInsurer', '');
    };

    return (
        <div className="detailsRepairs">
            <Form onSubmit={submitForm} initialValues={pmDetailsRepairs}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_DETAIL_REPAIRS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <FormTextRegular name="description" label="Description Of Property" required />
                                <FormTextRegular name="conditionOfExterior" label="Condition Of Exterior" required />
                                <FormTextRegular name="conditionOfInterior" label="Condition Of Interior" required />
                                <FormTextRegular name="fittingsAndFixtures" label="Fittings And Fixtures" required />
                                <FormRadioGroup
                                    label="Smoke Alarms (residential)"
                                    name="smokeAlarmsCompliantType"
                                    value={values.smokeAlarmsCompliantType}
                                    data={[
                                        {
                                            label: SMOKE_ALARM_TYPE_COMPLIANT,
                                            value: SMOKE_ALARM_TYPE_COMPLIANT
                                        },
                                        {
                                            label: SMOKE_ALARM_TYPE_NON_COMPLIANT,
                                            value: SMOKE_ALARM_TYPE_NON_COMPLIANT
                                        },
                                        {
                                            label: SMOKE_ALARM_TYPE_UNKNOWN,
                                            value: SMOKE_ALARM_TYPE_UNKNOWN
                                        }
                                    ]}
                                />
                                <FormRadioGroup
                                    label="Smoke alarms will be maintained By:"
                                    name={'smokeAlarmsMaintainedBy'}
                                    value={values.smokeAlarmsMaintainedBy}
                                    radioGroupClass="wrapped-radio-group"
                                    data={[
                                        {
                                            label: 'Landlord',
                                            value: 'Landlord'
                                        },
                                        {
                                            label: 'Agent',
                                            value: 'Agent'
                                        },
                                        {
                                            label: 'Strata',
                                            value: 'Strata'
                                        },
                                        {
                                            label: 'Company utilized by the agent for Smoke Alarms',
                                            value: 'Company'
                                        }
                                    ]}
                                />
                                {values.smokeAlarmsMaintainedBy && values.smokeAlarmsMaintainedBy === 'Company' && (
                                    <div>
                                        <FormTextRegular name="smokeAlarmCompanyName" label="Company Name" required />
                                        <FormTextCurrency
                                            name="smokeAlarmAmount"
                                            label="Annual costs"
                                            precision="2"
                                            required
                                        />
                                    </div>
                                )}
                                <FormRadioGroup
                                    label="Obtain instructions on landlord insurance?"
                                    name={'landlordInsuranceInstructions'}
                                    value={values.landlordInsuranceInstructions}
                                    data={[
                                        {
                                            label: 'Yes',
                                            value: 'yes',
                                            onClick: () => handleChangeLandLordInsuranceInstructionsYes(form)
                                        },
                                        {
                                            label: 'No',
                                            value: 'no',
                                            onClick: () => handleChangeLandLordInsuranceInstructionsNo(form)
                                        }
                                    ]}
                                />
                                <FormTextRegular
                                    name="preferredInsurer"
                                    label="Preferred Insurance Company"
                                    disabled={isLandLordInsuranceInstructionsDisabled}
                                />
                                <FormTextRegular
                                    name="fittings"
                                    label="Fittings (not being fixed to the property) which are provided with the property"
                                    required
                                />
                                <FormTextRegular name="improvements" label="Improvements" required />
                                <FormTextRegular
                                    name="detailsOfWorkToBeCompleted"
                                    label="Details Of Work To Be Completed By The Landlord On The Property"
                                    required
                                />
                            </div>
                            <CheckboxCheck name="nominateStrataDetails" label="Nominate strata details" />
                            {values.nominateStrataDetails && (
                                <div className="form-section">
                                    <div className="form-section__body">
                                        <div className="full-width">
                                            <FormTextRegular name="strataPlanNo" label="Strata Plan No" />
                                        </div>
                                        <div className="full-width">
                                            <FormTextRegular name="lotNo" label="Lot No" />
                                        </div>
                                        <div className="full-width">
                                            <FormTextRegular name="strataManagingAgent" label="Strata Managing Agent" />
                                        </div>
                                        <div className="full-width">
                                            <CheckboxCheck name="byLawsprovided" label="By Laws provided" />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(DetailsRepairs);
