import { NoteAddSharp, PictureAsPdfSharp } from '@material-ui/icons';
import React, { memo } from 'react';

import '../../../../src/sass/customIcons.scss';

const AcknowledgementPageIcon = () => {
    return (
        <div className="custom-icon-container">
            <PictureAsPdfSharp />
            <span className="custom-icon-divider">+</span>
            <NoteAddSharp />
        </div>
    );
};

export default memo(AcknowledgementPageIcon);
