import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { isEmpty, has } from 'lodash';

import * as dashboard from '../../../actions/dashboard';
import {
    resetLeasePdfEmailSentSuccess,
    resetOwnerCopyEmail,
    resetOwnerCompleteCopy,
    resetLeaseForManualSigningEmail
} from '../../../actions/lease';
import { getAgentSignature } from '../../../actions/completion';

import RenderSendForSigningForm from './footerComponents/RenderSendForSigningForm';
import AwaitingCompletion from './footerComponents/AwaitingCompletion';
import Complete from './footerComponents/Complete';
import { CANCEL_SIGNING_MESSAGE, getResTenLabel } from '../../../utils/labelUtils';
import Button from '../../../common/components/Button';
import ButtonGroup from '../../../common/components/ButtonGroup';
import ReactTooltip from 'react-tooltip';

import {
    NSW_STATE,
    LANDLORD_INFORMATION_STATEMENT_STATUS_SEND_FAIL,
    LANDLORD_INFORMATION_STATEMENT_STATUS_SENT,
    LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED,
    HIDE_MESSAGE_TIME
} from '../../../config';
import {
    isLeaseTypeSales,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLongTermRenewalSubLease
} from '../../../utils/agreementUtils';
import { formatDateTime, getAgencyTimezoneFromUser } from '../../../utils/dateUtils';

import { getUserAgency } from '../../../selectors/agency';
import { getUserInfo } from '../../../selectors/user';
import {
    getIsSendingCompletedOwnerCopyEmail,
    getIsSendingCompletedOwnerCopyEmailSentFail,
    getIsSendingCompletedOwnerCopyEmailSentSuccess,
    getIsSendingLeasePdfEmail,
    getLeaseType,
    getIsSendingOwnerCopyEmail,
    getLeasePdfEmailSentSuccess,
    getLeasePdfEmailSentFail,
    getSendingOwnerCopyEmailSentSuccess,
    getSendingOwnerCopyEmailSentFail,
    getIsSendingLeaseManualSigningEmail,
    getLeaseManualSigningEmailSentSuccess,
    getLeaseManualSigningEmailSentFail
} from '../../../selectors/lease';
import { getIsTerminateLeaseModalOpen } from '../../../selectors/termination';

import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../sass/agreementFooter.scss';
import '../../../sass/reactConfirmAlert.scss';
import CompletionProgress from './footerComponents/CompletionProgress';
import { openTerminateLeaseListModal, openTerminateLeaseModal } from '../../../actions/terminateLease';
import { getAgentSignature as getAgentSignatureFromRedux } from '../../../selectors/completion';
import { openNoSignatureModal } from '../../../actions/user';

const Section48PdfButton = ({ leaseAllInfo }) => {
    const downloadSection48PDF = () => {
        const leaseId = leaseAllInfo.id;
        window.open(`/reader/agreement/${leaseId}/section48`, '_blank');
    };

    if (leaseAllInfo.section48pdf) {
        return (
            <ButtonGroup
                title="Section 48(1) document"
                singleButton={true}
                buttonListClass="justify-end"
                buttonList={[
                    {
                        onClick: downloadSection48PDF,
                        disabled: false,
                        className: 'logo-button',
                        label: 'View PDF',
                        icon: 'view'
                    }
                ]}
            />
        );
    } else {
        return null;
    }
};

const AgreementFooter = ({
    leaseAllInfo,
    setSigning,
    closeSigning,
    openLeaseRenewalModal,
    emailLeasePDF,
    emailLeaseForManualSigning,
    emailOwnerCopyToAgent,
    sendCompletedOwnerCopyToAgent,
    scrollToTop,
    scrollToBottom
}) => {
    const dispatch = useDispatch();
    const [saveStatusTimeout, setSaveStatusTimeout] = useState(null);
    const [action, setAction] = useState([]);

    const isSendingLeasePdfEmail = useSelector(getIsSendingLeasePdfEmail);
    const isSendingOwnerCopyEmail = useSelector(getIsSendingOwnerCopyEmail);
    const isSendingCompletedOwnerCopyEmail = useSelector(getIsSendingCompletedOwnerCopyEmail);
    const sendingCompletedOwnerCopyEmailSentSuccess = useSelector(getIsSendingCompletedOwnerCopyEmailSentSuccess);
    const sendingCompletedOwnerCopyEmailSentFail = useSelector(getIsSendingCompletedOwnerCopyEmailSentFail);
    const leasePdfEmailSentSuccess = useSelector(getLeasePdfEmailSentSuccess);
    const leasePdfEmailSentFail = useSelector(getLeasePdfEmailSentFail);
    const sendingOwnerCopyEmailSentSuccess = useSelector(getSendingOwnerCopyEmailSentSuccess);
    const sendingOwnerCopyEmailSentFail = useSelector(getSendingOwnerCopyEmailSentFail);
    const isSendingLeaseManualSigningEmail = useSelector(getIsSendingLeaseManualSigningEmail);
    const leaseManualSigningEmailSentSuccess = useSelector(getLeaseManualSigningEmailSentSuccess);
    const leaseManualSigningEmailSentFail = useSelector(getLeaseManualSigningEmailSentFail);
    const leaseType = useSelector(getLeaseType);
    const isTerminateLeaseModalOpen = useSelector(getIsTerminateLeaseModalOpen);
    const loggedInUser = useSelector(getUserInfo);
    const agency = useSelector(getUserAgency);
    const agentSignature = useSelector(getAgentSignatureFromRedux);

    useEffect(() => {
        if (saveStatusTimeout) {
            clearTimeout(saveStatusTimeout);
        }
        if (
            leaseAllInfo.status === dashboard.LEASE_STATUS_COMPLETE ||
            leaseAllInfo.status === dashboard.LEASE_STATUS_ARCHIVED
        ) {
            if (!isEmpty(loggedInUser)) {
                dispatch(getAgentSignature(loggedInUser.id));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            leasePdfEmailSentSuccess ||
            leasePdfEmailSentFail ||
            leaseManualSigningEmailSentSuccess ||
            leaseManualSigningEmailSentFail ||
            sendingOwnerCopyEmailSentSuccess ||
            sendingOwnerCopyEmailSentFail ||
            isSendingCompletedOwnerCopyEmail ||
            isTerminateLeaseModalOpen
        ) {
            let newAction = action;
            // We need a way to differentiate which action has just completed
            if (leasePdfEmailSentSuccess || leasePdfEmailSentFail) {
                newAction.push('sendingLease');
            }
            if (leaseManualSigningEmailSentSuccess || leaseManualSigningEmailSentFail) {
                newAction.push('sendingLeaseManual');
            }
            if (sendingOwnerCopyEmailSentFail || sendingOwnerCopyEmailSentSuccess) {
                newAction.push('sendingOwnerCopy');
            }

            if (isSendingCompletedOwnerCopyEmail) {
                newAction.push('sendingCompletedOwnerCopy');
            }

            setAction(newAction);
            if (action.length > 0 && !saveStatusTimeout) {
                setSaveStatusTimeout(
                    setTimeout(() => {
                        setSaveStatusTimeout(null);
                        setAction([]);
                        dispatch(resetLeasePdfEmailSentSuccess());
                        dispatch(resetLeaseForManualSigningEmail());
                        dispatch(resetOwnerCopyEmail());
                        dispatch(resetOwnerCompleteCopy());
                    }, HIDE_MESSAGE_TIME)
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        leasePdfEmailSentSuccess,
        leasePdfEmailSentFail,
        leaseManualSigningEmailSentSuccess,
        leaseManualSigningEmailSentFail,
        sendingOwnerCopyEmailSentSuccess,
        sendingOwnerCopyEmailSentFail,
        isSendingCompletedOwnerCopyEmail,
        isTerminateLeaseModalOpen
    ]);

    /**
     * Cancel signing of lease agreement request.
     * After canceling lease agreement will be moved to the DRAFT status.
     * Also will be removed all signs
     */
    const closeSigningFunction = () => {
        confirmAlert({
            title: '',
            message: CANCEL_SIGNING_MESSAGE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const leaseId = leaseAllInfo.id;
                        closeSigning(leaseId);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const emailLeasePDFFunction = () => {
        const leaseId = leaseAllInfo.id;
        let data = {
            emailAddress: [loggedInUser.email]
        };

        emailLeasePDF(leaseId, data);
    };

    const downloadLeasePDF = () => {
        const leaseId = leaseAllInfo.id;
        window.open(`/reader/agreement/${leaseId}`, '_blank');
    };

    const downloadOwnerPDF = () => {
        const leaseId = leaseAllInfo.id;
        window.open(`/reader/agreement/${leaseId}/owner`, '_blank');
    };

    const sendCompletedOwnerCopyToAgentFunction = () => {
        const leaseId = leaseAllInfo.id;
        let data = {
            emailAddress: [loggedInUser.email]
        };

        sendCompletedOwnerCopyToAgent(leaseId, data);
    };

    const emailOwnerCopyToAgentFunction = () => {
        const leaseId = leaseAllInfo.id;
        let data = {
            emailAddress: [loggedInUser.email]
        };

        emailOwnerCopyToAgent(leaseId, data);
    };

    const emailLeaseForManualSigningFunction = () => {
        const leaseId = leaseAllInfo.id;
        let data = {
            emailAddress: [loggedInUser.email]
        };

        emailLeaseForManualSigning(leaseId, data);
    };

    const isSubscriptionCancelled = () => {
        return has(agency, 'tariffPlan.active') && !agency.tariffPlan.active;
    };

    const leaseTypeFromLease = leaseType ? leaseType : leaseAllInfo.leaseType;

    let landlordAcknowledged = true;
    let canPreviewLandlordPdf = false;
    if (leaseAllInfo.location === NSW_STATE && leaseAllInfo.landlord) {
        // landlordAcknowledged = true if there is no landlord acknowledgement status that has failed or has been sent but not yet confirmed
        leaseAllInfo.landlord.persons.forEach(landlord => {
            if (
                landlord.landlordInformationStatementStatus === LANDLORD_INFORMATION_STATEMENT_STATUS_SEND_FAIL ||
                landlord.landlordInformationStatementStatus === LANDLORD_INFORMATION_STATEMENT_STATUS_SENT
            ) {
                landlordAcknowledged = false;
            }
            if (landlord.landlordInformationStatementStatus === LANDLORD_INFORMATION_STATEMENT_STATUS_CONFIRMED) {
                canPreviewLandlordPdf = true;
            }
        });
    }

    let footer = <div />;
    let footerStyle = 'footer hide';

    const sentForSigningDate = formatDateTime(leaseAllInfo.sentForSigning, getAgencyTimezoneFromUser(loggedInUser));

    if (true === leaseAllInfo.awaitingRenewal) {
        footer = (
            <div className="section space-between">
                <button className="btn-renew-agreement" onClick={openLeaseRenewalModal}>
                    Renew agreement
                </button>
            </div>
        );
    }

    const initiateTermination = () => {
        if (!isEmpty(agentSignature)) {
            dispatch(openTerminateLeaseModal(leaseAllInfo, loggedInUser));
        } else {
            dispatch(openNoSignatureModal());
        }
    };

    const openTerminationListModal = () => {
        dispatch(openTerminateLeaseListModal());
    };

    switch (leaseAllInfo.status) {
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            footerStyle = 'footer footer-new';
            footer = (
                <div>
                    <div className="section space-between">
                        <h6 className="button-group-header">Documents</h6>
                        <ButtonGroup
                            title={`Draft PDF`}
                            singleButton={true}
                            buttonListClass="justify-end"
                            buttonList={[
                                {
                                    onClick: downloadLeasePDF,
                                    disabled: false,
                                    className: 'logo-button',
                                    label: 'View PDF',
                                    icon: 'view'
                                }
                            ]}
                        />
                        <Section48PdfButton leaseAllInfo={leaseAllInfo} />
                    </div>
                    {leaseAllInfo?.renewalNoticeToLeaveDetails?.isRenewalToLeaveFlow && (
                        <div className="section space-between">
                            <h6 className="button-group-header">Termination</h6>
                            <ButtonGroup
                                title="Termination notice"
                                buttonList={[
                                    {
                                        onClick: openTerminationListModal,
                                        disabled: false,
                                        className: 'logo-button',
                                        label: 'Open',
                                        icon: 'open'
                                    },
                                    {
                                        onClick: initiateTermination,
                                        disabled: false,
                                        className: 'filled-button',
                                        label: 'Initiate Termination'
                                    }
                                ]}
                            />
                        </div>
                    )}
                    <div className="section space-between">
                        <div className="footer-re-signing">
                            <div className={footerStyle}>
                                <ReactTooltip
                                    id="cancel-signing"
                                    class="tooltip"
                                    place="bottom"
                                    effect="solid"
                                    globalEventOff="wheel"
                                >
                                    <p>This will cancel signing and move the agreement to drafts</p>
                                </ReactTooltip>
                                <Button
                                    className="cancel-button"
                                    data-tip
                                    data-for="cancel-signing"
                                    secondary
                                    onClick={closeSigningFunction}
                                >
                                    Move to drafts
                                </Button>
                                <div className="footer-help-text">Sent for signing {sentForSigningDate}</div>
                            </div>
                            <RenderSendForSigningForm setSigning={setSigning} leaseAllInfo={leaseAllInfo} />
                        </div>
                        <div className="footer footer-draft">
                            <CompletionProgress progress={leaseAllInfo?.sendForSigningProgress ?? []} />
                        </div>
                    </div>
                </div>
            );
            break;
        }

        case dashboard.LEASE_STATUS_DRAFT: {
            footer = (
                <div className="footer__inner">
                    <div className="section space-between docs-row">
                        <h6>Documents</h6>
                        <div>
                            {saveStatusTimeout && sendingOwnerCopyEmailSentSuccess && (
                                <span className="savestatus-saved">
                                    Email Sent Successfully For Owner Copy
                                    <span className="icon savestatus-icon" />
                                </span>
                            )}

                            {saveStatusTimeout &&
                                sendingOwnerCopyEmailSentFail &&
                                action.indexOf('sendingOwnerCopy') > -1 && (
                                    <span className="savestatus-failed">
                                        Email Owner Copy Failed <span className="icon savestatus-icon" />
                                    </span>
                                )}
                            {isLeaseTypeResidentialTenancy(leaseTypeFromLease) &&
                                !isLongTermRenewalSubLease(leaseAllInfo.subLeaseType) && (
                                    <ButtonGroup
                                        title={`Owner Draft`}
                                        toolTip={`The lease will be emailed to the agent or opened with the ${getResTenLabel(
                                            'tenantsLower',
                                            leaseAllInfo.location
                                        )} contact details removed.`}
                                        buttonList={[
                                            {
                                                onClick: downloadOwnerPDF,
                                                disabled: false,
                                                className: 'logo-button',
                                                label: 'View PDF',
                                                icon: 'view'
                                            },
                                            {
                                                onClick: emailOwnerCopyToAgentFunction,
                                                disabled: isSendingOwnerCopyEmail,
                                                className: 'logo-button',
                                                label: 'Email me PDF',
                                                icon: 'send',
                                                loading: isSendingOwnerCopyEmail
                                            }
                                        ]}
                                    />
                                )}
                            {saveStatusTimeout && leaseManualSigningEmailSentSuccess && (
                                <span className="savestatus-saved">
                                    Email Sent Successfully For Manual Signing <span className="icon savestatus-icon" />
                                </span>
                            )}

                            {saveStatusTimeout &&
                                leaseManualSigningEmailSentFail &&
                                action.indexOf('sendingLeaseManual') > -1 && (
                                    <span className="savestatus-failed">
                                        Email Send Failed For Manual Signing
                                        <span className="icon savestatus-icon" />
                                    </span>
                                )}
                            <ButtonGroup
                                title={`Manual Signing`}
                                toolTip={
                                    isLeaseTypeSales(leaseTypeFromLease)
                                        ? 'The sales agreement will be emailed to you for review/manual signing.'
                                        : isLeaseTypePm(leaseTypeFromLease)
                                        ? 'The property management agreement will be emailed to you for review/manual signing.'
                                        : 'The lease will be emailed to the agent or opened for manual signing.'
                                }
                                buttonList={[
                                    {
                                        onClick: downloadLeasePDF,
                                        disabled: false,
                                        className: 'logo-button',
                                        label: 'View PDF',
                                        icon: 'view'
                                    },
                                    {
                                        onClick: emailLeaseForManualSigningFunction,
                                        disabled: isSendingLeaseManualSigningEmail,
                                        className: 'logo-button',
                                        label: 'Email me PDF',
                                        icon: 'send',
                                        loading: isSendingLeaseManualSigningEmail
                                    }
                                ]}
                            />
                            <span className="docs-info for-sm-modal">
                                The property management agreement will be emailed to you for review/manual signing.
                            </span>
                        </div>
                    </div>
                    <div className="section space-between submit-row">
                        <div className="subscription-warning">
                            {isSubscriptionCancelled() && (
                                <p className="text-danger">
                                    There could be an issue, please ask your admin to check your subscription status
                                </p>
                            )}
                        </div>
                        <div className="footer footer-draft">
                            <RenderSendForSigningForm setSigning={setSigning} leaseAllInfo={leaseAllInfo} />
                            <CompletionProgress progress={leaseAllInfo?.sendForSigningProgress ?? []} />
                        </div>
                    </div>
                </div>
            );
            break;
        }

        case dashboard.LEASE_STATUS_AWAITING_COMPLETION: {
            footer = leaseAllInfo && leaseAllInfo.hydrated && (
                <div className="footer__inner">
                    <AwaitingCompletion
                        leaseAllInfo={leaseAllInfo}
                        saveStatusTimeout={saveStatusTimeout}
                        leasePdfEmailSentSuccess={leasePdfEmailSentSuccess}
                        leasePdfEmailSentFail={leasePdfEmailSentFail}
                        isSendingLeasePdfEmail={isSendingLeasePdfEmail}
                        scrollToTop={scrollToTop}
                        scrollToBottom={scrollToBottom}
                        action={action}
                        downloadLeasePDF={() => downloadLeasePDF()}
                        landlordAcknowledged={landlordAcknowledged}
                        emailLeasePDF={() => emailLeasePDFFunction()}
                        Section48PdfButton={Section48PdfButton}
                    />
                </div>
            );
            break;
        }
        case dashboard.LEASE_STATUS_CANCELLED:
        case dashboard.LEASE_STATUS_ARCHIVED:
        case dashboard.LEASE_STATUS_COMPLETE: {
            footer = leaseAllInfo && leaseAllInfo.hydrated && (
                <Complete
                    saveStatusTimeout={saveStatusTimeout}
                    action={action}
                    leaseAllInfo={leaseAllInfo}
                    leasePdfEmailSentSuccess={leasePdfEmailSentSuccess}
                    leasePdfEmailSentFail={leasePdfEmailSentFail}
                    terminateLeaseModalOpen={isTerminateLeaseModalOpen}
                    emailLeasePDF={() => emailLeasePDFFunction()}
                    leaseType={leaseTypeFromLease}
                    downloadLeasePDF={() => downloadLeasePDF()}
                    sendCompletedOwnerCopyToAgent={() => sendCompletedOwnerCopyToAgentFunction()}
                    canPreviewLandlordPdf={canPreviewLandlordPdf}
                    Section48PdfButton={Section48PdfButton}
                />
            );
            break;
        }
        case dashboard.LEASE_STATUS_AWAITING_RENEWAL: {
            footer = (
                <div className="section space-between">
                    {saveStatusTimeout && leasePdfEmailSentSuccess && action.indexOf('sendingLease') > -1 && (
                        <span className="savestatus-saved">
                            Email Sent Successfully
                            <span className="icon savestatus-icon" />
                        </span>
                    )}

                    {saveStatusTimeout && leasePdfEmailSentFail && action.indexOf('sendingLease') > -1 && (
                        <span className="savestatus-failed">
                            Email Send Failed
                            <span className="icon savestatus-icon" />
                        </span>
                    )}
                    {isLeaseTypeResidentialTenancy(leaseTypeFromLease) && (
                        <React.Fragment>
                            <h6 className="button-group-header">Documents</h6>
                            <ButtonGroup
                                title={`Copy of ${
                                    isLeaseTypeResidentialTenancy(leaseTypeFromLease) ? 'lease' : 'agreement'
                                }`}
                                buttonList={[
                                    {
                                        onClick: downloadLeasePDF,
                                        disabled: false,
                                        className: 'logo-button',
                                        label: 'View PDF',
                                        icon: 'view'
                                    },
                                    {
                                        onClick: emailLeasePDFFunction,
                                        disabled: isSendingLeasePdfEmail,
                                        className: 'logo-button',
                                        label: 'Send me PDF',
                                        icon: 'send',
                                        loading: isSendingLeasePdfEmail
                                    }
                                ]}
                            />
                            {saveStatusTimeout && sendingCompletedOwnerCopyEmailSentSuccess && (
                                <span className="savestatus-saved">
                                    Owner Version Email Sent Successfully <span className="icon savestatus-icon" />
                                </span>
                            )}

                            {saveStatusTimeout &&
                                sendingCompletedOwnerCopyEmailSentFail &&
                                action.indexOf('sendingCompletedOwnerCopy') > -1 && (
                                    <span className="savestatus-failed">
                                        Owner Version Email Send Failed
                                        <span className="icon savestatus-icon" />
                                    </span>
                                )}
                            {leaseAllInfo.ownerCopyPdf && (
                                <ButtonGroup
                                    title="Owner copy of lease"
                                    buttonList={[
                                        {
                                            onClick: downloadOwnerPDF,
                                            disabled: false,
                                            className: 'logo-button',
                                            label: 'View PDF',
                                            icon: 'view'
                                        },
                                        {
                                            onClick: sendCompletedOwnerCopyToAgentFunction,
                                            disabled: isSendingCompletedOwnerCopyEmail,
                                            className: 'logo-button',
                                            label: 'Send me PDF',
                                            icon: 'send',
                                            loading: isSendingCompletedOwnerCopyEmail
                                        }
                                    ]}
                                />
                            )}
                            <Section48PdfButton leaseAllInfo={leaseAllInfo} />
                        </React.Fragment>
                    )}
                </div>
            );
            break;
        }
    }

    return <footer>{footer}</footer>;
};

export default memo(AgreementFooter);
