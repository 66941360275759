import validator from 'validator';
import { isNil, isEmpty } from 'lodash';

/**
 * Function checks if email field has correct format.
 * @param {string} field - value of form's field attribute.
 * @return {boolean}
 */
export const isCorrectEmail = (field: string): boolean => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(field);
};

export const isCorrectACN = (ACN: string) => {
    if (ACN) {
        return /\b[0-9]{3}\b \b[0-9]{3}\b \b[0-9]{3}\b/.test(ACN); //look for match to pattern like: 000 000 000
    } else {
        return true;
    }
};

export const isCorrectABN = (ABN: string) => {
    if (ABN) {
        return /\b[0-9]{2}\b \b[0-9]{3}\b \b[0-9]{3}\b \b[0-9]{3}\b/.test(ABN); //look for match to pattern like: 00 000 000 000
    } else {
        return true;
    }
};

export const isOnlyNumeric = (licenceNumber: string) => {
    return /^[0-9]+$/.test(licenceNumber); //only digits
};

export const isCorrectNameOrSurname = (data: string) => {
    return /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, '])*$/.test(data);
};

export const isValidPassword = (password: string) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,.?]{6,}$/.test(password);
};

export const INVALID_PASSWORD_MESSAGE =
    'Your password needs to be longer than 6 characters and contain at least a lowercase, an uppercase and a number';

export const isMobilePhone = (phone: string, alwaysValidate = false) => {
    if (!phone) {
        return true;
    }
    if (phone.length > 5 || alwaysValidate) {
        try {
            // Replace non digit characters with empty string
            const canonizedPhoneNumber = '+' + phone.replace(/[\D]/g, '');
            return validator.isMobilePhone(canonizedPhoneNumber);
        } catch (error) {
            return false;
        }
    }
    return true;
};

export const isPhone = (phone: string) => {
    if (phone === '') {
        return true;
    }
    const canonizedPhoneNumber = phone.replace(/ /g, '');
    const pattern = /^\+61[0-8]\d{8}$/g;
    return pattern.test(canonizedPhoneNumber);
};

export const isEmptyString = (value: any) => {
    return isNil(value) || isEmpty(value);
};
