import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import appHistory from '../../AppHistory';
import '../../sass/layout.scss';
import '../../sass/_welcome.scss';
import { PreLoader } from '../../common/components/PreLoader';
import { getIsPending } from '../../selectors/dashboard/agreementList';
import axios from 'axios';
import { useRollbarProvider } from '../../components/providers/RollbarProvider';
import { openKeyForm } from '../../actions/flkAKey';
import { DEFAULT_PAGE, DEFAULT_RECORDS_PER_PAGE, DOCUMENT_READ_ONLY_MODE } from '../../config';
import { getSavedTeamMemberSelection, getUserInfo } from '../../selectors/user';
import { loadFlkAKeyList } from '../../actions/dashboard';
import { confirmAlert } from 'react-confirm-alert';

const FlkAKey = () => {
    const dispatch = useDispatch();
    const { keyId } = useParams();
    const history = useHistory();
    const Rollbar = useRollbarProvider();

    const isPending = useSelector(getIsPending);
    const savedTeamMemberSelection = useSelector(getSavedTeamMemberSelection);
    const loggedInUser = useSelector(getUserInfo);

    let [flkAKey, setFlkAKey] = useState(false);

    useEffect(() => {
        getFlkAKey();
    }, []);

    useEffect(() => {
        if (!isPending && flkAKey && loggedInUser && loggedInUser.agency) {
            if (flkAKey.agency.id === loggedInUser.agency.id) {
                history.push(`/dashboard/flk-a-key/${flkAKey.status}`);
                dispatch(openKeyForm(flkAKey, DOCUMENT_READ_ONLY_MODE));
            } else {
                history.push(`/dashboard/flk-a-key/${flkAKey.status}`);
                confirmAlert({
                    title: '',
                    message: `This Key is linked to ${flkAKey.agency.details.agencyName}, you are currently logged into ${loggedInUser.agency.details.agencyName} please switch to the correct account.`,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {
                                window.history.pushState({}, '', `/dashboard/flk-a-key/${flkAKey.status}`);
                            }
                        }
                    ]
                });
            }
        }
    }, [isPending, loggedInUser]);

    const getFlkAKey = () => {
        return axios
            .get(`/api/flk-a-key/${keyId}`)
            .then(result => {
                const flkAKey = result.data.flkAKey;
                setFlkAKey(flkAKey);
                dispatch(
                    loadFlkAKeyList(document.status, DEFAULT_PAGE, DEFAULT_RECORDS_PER_PAGE, savedTeamMemberSelection)
                );
            })
            .catch(error => {
                Rollbar.error(
                    'Failed to get Key from direct URL ',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
                appHistory.push('/');
            });
    };

    return (
        <div className="preloader-centred">
            <PreLoader />
        </div>
    );
};

export default memo(FlkAKey);
