import React, { useState } from 'react';
import axios from 'axios';
import ModalDialog from '../../../common/components/ModalDialog';
import Button from '../../../common/components/Button';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import '../../../sass/editModal.scss';
import { DEADLINE_OPTIONS } from '../../../utils/formUtils';
import loadImage from '../../../../assets/images/icons/load-img.jpg';
import Dropzone from 'react-dropzone';
import { checkCircleIcon, removeCircleIcon } from '../../../common/components/SVGIcons';
import { FormTextRegular } from '../../../components/form/FormText';
import '../../../sass/modals/uploadCsv.scss';

const acceptedFileTypes = ['.csv'];
const maxFileSize = 6;
const maxFileSizeinBytes = maxFileSize * 1024 * 1024;
const FILE_SIZE_ERROR = `File is larger than ${maxFileSizeinBytes} bytes`;

const UploadCsvModal = ({ isOpen, closeUploadModal, addNewContactList, contactType }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [document, setDocument] = useState(null);
    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
    const [backendError, setBackendError] = useState(null);
    const [error, setError] = useState('');
    const [dropZoneError, setDropZoneError] = useState('');

    const submitValues = values => {
        setIsSubmitting(true);
        setError('');
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                setDocumentUploadProgress(Math.floor((loaded * 100) / total));
            }
        };
        let newDocuments = new FormData();
        newDocuments.append(`document.file`, document.file);
        return axios
            .post(`api/contacts/upload-csv`, newDocuments, options)
            .then(response => {
                setIsSubmitting(false);
                return axios
                    .post(`api/contacts/add-contacts-list`, {
                        contactListName: values.contactListName,
                        csvFile: response.data.document,
                        contactType
                    })
                    .then(response => {
                        addNewContactList(response.data.contactList);
                        closeModal();
                    })
                    .catch(err => {
                        setIsSubmitting(false);
                        const errorObject = err.response.data.error;
                        if (errorObject.contactListName) {
                            return errorObject;
                        }
                        // Display cvs validation errors
                        if (errorObject.csvError) {
                            setError(
                                Array.isArray(errorObject.csvError)
                                    ? errorObject.csvError.join('\n')
                                    : errorObject.csvError
                            );
                        }
                        return false;
                    });
            })
            .catch(err => {
                setIsSubmitting(false);
                setBackendError(true);
                setError('Something went wrong. Please try again.');
                return false;
            });
    };
    const closeModal = () => {
        setError('');
        setDropZoneError('');
        setIsSubmitting(false);
        setDocumentUploadProgress(0);
        setDocument(null);
        closeUploadModal();
    };

    const onDropRejected = errors => {
        if (errors[0].errors[0].message === FILE_SIZE_ERROR) {
            setDropZoneError(`File is larger than ${maxFileSize} MB`);
        } else {
            setDropZoneError(errors[0].errors[0].message);
        }
    };

    const onDrop = files => {
        setDropZoneError('');
        setDocument({
            documentName: files[0].name,
            file: files[0]
        });
        setError('');
    };

    return (
        <ModalDialog
            title="Upload contacts"
            isOpen={isOpen}
            closeModal={closeModal}
            shouldCloseOnOverlayClick={false}
            className="upload-contacts-modal"
        >
            <div>
                <Form onSubmit={submitValues} initialValues={{ deadline: DEADLINE_OPTIONS[2].value }}>
                    {({ handleSubmit, errors, values }) => {
                        return (
                            <form id="upload-contacts-form" onSubmit={handleSubmit} noValidate>
                                <div className={'instructions'}>
                                    <p className={'subtitle-1'}>
                                        <strong>
                                            To use the contact list feature please follow the below instructions
                                        </strong>
                                    </p>

                                    <ol>
                                        <li>Download our sample CSV file</li>
                                        <li>Import your details and name your file (eg landlord contact list)</li>
                                        <li>
                                            Ensure the mobile numbers are in the format on the sample (there are two
                                            options)
                                        </li>
                                        <li>Upload that file back into FLK</li>
                                    </ol>

                                    <p className={'subtitle-2'}>
                                        Now that you have imported your contacts you can use it in two ways:
                                    </p>
                                    <ul>
                                        <li>To send bulk communication to EVERYONE on the list</li>
                                        <li>Or you can search individuals on the list to send documents to.</li>
                                    </ul>
                                </div>
                                <FormTextRegular name="contactListName" label="Name of contact list" />
                                <div className="dropzone-container">
                                    <Dropzone
                                        className="dropzone"
                                        multiple={false}
                                        maxSize={maxFileSizeinBytes}
                                        accept={acceptedFileTypes.join(', ')}
                                        onDrop={files => onDrop(files)}
                                        onDropRejected={errors => onDropRejected(errors)}
                                        activeClassName="active"
                                    >
                                        {({ getRootProps, getInputProps }) => {
                                            return (
                                                <div className="dropzone" {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {dropZoneError && (
                                                        <span className="error-message">{dropZoneError}</span>
                                                    )}
                                                    <img className="load-img for-lg-modal" src={loadImage} alt="load" />
                                                    <div className="help-text">
                                                        <p className="for-lg-modal">
                                                            CSV max file size {maxFileSize} Mb.
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    </Dropzone>
                                </div>
                                <div className="files-to-upload-container agreement-section-documents">
                                    {isSubmitting && (
                                        <div className="dropzone-spinner">
                                            <div className="doc-upload-spinner" />
                                            <span className="progress-value for-lg-modal">
                                                {documentUploadProgress}%
                                            </span>
                                            <label
                                                className="progress-line for-lg-modal"
                                                style={{ width: `${documentUploadProgress}%` }}
                                            />
                                        </div>
                                    )}
                                    <br />
                                    <ul className="file-to-upload-list">
                                        {document && (
                                            <li
                                                className={`${backendError ? 'error ' : ''}
                                                ${documentUploadProgress === 100 && !isSubmitting ? 'success' : ''}`}
                                            >
                                                <p>
                                                    <label className="document-info">{document.documentName}</label>
                                                </p>
                                                <div className="upload-icon check">{checkCircleIcon}</div>
                                                <div className="upload-icon remove">{removeCircleIcon}</div>
                                                <button
                                                    className="btn-delete"
                                                    type="button"
                                                    onClick={() => {
                                                        setDocument(null);
                                                        setError('');
                                                    }}
                                                />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                {error && (
                                    <pre className="FormInput">
                                        <span className="FormError">{error}</span>
                                    </pre>
                                )}
                                <div className="btn-group">
                                    <Button type="submit" primary disabled={isSubmitting || !document}>
                                        {isSubmitting && <span className="savestatus-spinner-white" />}
                                        Upload
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            </div>
        </ModalDialog>
    );
};

export default UploadCsvModal;
