import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { editPermissions } from '../../utils/userPermissions';
import {
    DOCUMENT_RENT_REDUCTION,
    LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
    LEASE_TYPE_RESIDENTIAL_DISPLAY,
    LEASE_TYPE_SALES_DISPLAY,
    LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY,
    LEASE_TYPE_HOLIDAY_LETTING_DISPLAY,
    tabs
} from '../../config';
import '../../sass/specialConditions.scss';
import '../../sass/tables.scss';
import { confirmAlert } from 'react-confirm-alert';
import { formatDateShortMonth, getAgencyTimezoneFromUser } from '../../utils/dateUtils';
import Button from '../../common/components/Button';
import * as specialConditions from '../../actions/specialConditions';
import ModalSpecialCondition from '../../components/modals/ModalSpecialCondition';
import DeleteButton from '../../common/components/DeleteButton';
import EditButton from '../../common/components/EditButton';
import ConfirmUpdateTemplates from '../../containers/settings/UpdateTemplatesConfirmation';
import { getUserInfo } from '../../selectors/user';
import {
    getSpecialConditionsTemplateList,
    getValidationErrors,
    getIsOpenModalSpecialCondition,
    getModalSpecialCondition,
    getSpecialConditionsTemplatesToUpdate
} from '../../selectors/specialConditions';
import { clearSpecialConditionsTemplatesToUpdate } from '../../actions/specialConditions';
import { TEMPLATE_SCREEN_V2 } from '../../constants/featureFlags';

const SpecialConditions = props => {
    const dispatch = useDispatch();

    const loggedInUser = useSelector(getUserInfo);
    const specialConditionsTemplateList = useSelector(getSpecialConditionsTemplateList);
    const validationErrors = useSelector(getValidationErrors);
    const templatesToUpdate = useSelector(getSpecialConditionsTemplatesToUpdate);
    const isOpenModalSpecialCondition = useSelector(getIsOpenModalSpecialCondition);
    const modalSpecialCondition = useSelector(getModalSpecialCondition);
    const isTemplateScreenV2Active = useFeatureFlag(TEMPLATE_SCREEN_V2);

    const clearTemplates = () => {
        dispatch(clearSpecialConditionsTemplatesToUpdate());
    };

    useEffect(() => {
        dispatch(specialConditions.getSpecialConditionsTemplateList());
    }, [dispatch]);

    useEffect(() => {
        if (templatesToUpdate && templatesToUpdate.templates && templatesToUpdate.templates.length > 0) {
            confirmAlert({
                customUI: ConfirmUpdateTemplates(templatesToUpdate, clearTemplates, () => {}, 'SPECIAL CONDITIONS'),
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templatesToUpdate, specialConditionsTemplateList]);

    const deleteTemplate = template => {
        confirmAlert({
            title: '',
            message: `Are you sure want to delete ${template.name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(specialConditions.deleteSpecialCondition(template));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const openModalSpecialCondition = (type, values) => {
        dispatch(specialConditions.openModalSpecialCondition(type, values));
    };

    const closeModalSpecialCondition = () => {
        dispatch(specialConditions.closeModalSpecialCondition());
    };

    const deleteModalTemplate = template => {
        dispatch(specialConditions.deleteSpecialCondition(template));
    };

    let userRole = loggedInUser.role;

    function formatForDisplay(agreementType) {
        switch (agreementType) {
            case LEASE_TYPE_RESIDENTIAL_DISPLAY:
                return 'Residential Tenancy';
            case LEASE_TYPE_SALES_DISPLAY:
                return 'Sales';
            case LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY:
                return 'Property Management';
            case DOCUMENT_RENT_REDUCTION:
                return 'Rent relief';
            case LEASE_TYPE_COMMERCIAL_LEASE_DISPLAY:
                return 'Commercial Lease';
            case LEASE_TYPE_HOLIDAY_LETTING_DISPLAY:
                return 'Holiday letting';
        }
    }

    const agencyTimezone = getAgencyTimezoneFromUser(loggedInUser);

    return (
        <div className="settings">
            <div className="wrapper">
                {!isTemplateScreenV2Active && !specialConditionsTemplateList.length && (
                    <div className="add-signature-block">
                        <p>You don't have templates yet.</p>
                        {editPermissions(userRole, tabs.TAB_TEMPLATES) && (
                            <Button primary onClick={() => openModalSpecialCondition('Create')}>
                                Add template
                            </Button>
                        )}
                    </div>
                )}

                {!!specialConditionsTemplateList.length && (
                    <div className="specialconditions table-default table-sort">
                        <div className="table-head">
                            <h2>Special Conditions</h2>
                            {!isTemplateScreenV2Active && editPermissions(userRole, tabs.TAB_TEMPLATES) && (
                                <a className="btn-add" onClick={() => openModalSpecialCondition('Create')}>
                                    Add Template
                                </a>
                            )}
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Template</th>
                                    <th>Type</th>
                                    <th>Created by</th>
                                    <th>Updated</th>
                                    <th className="actions">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {specialConditionsTemplateList.map((template, index) => (
                                    <tr key={index}>
                                        <td>{template.name}</td>
                                        <td>
                                            {template.agreementType
                                                ? formatForDisplay(template.agreementType)
                                                : 'Undefined'}
                                        </td>
                                        <td>{template.fullName}</td>
                                        <td>{formatDateShortMonth(new Date(template.updated), agencyTimezone)}</td>
                                        {/* Commented out so in the future we can put it back when we allow one page clauses again, should be possible now */}
                                        {/*<td>*/}
                                        {/*    {template.onePageVersion && 'One page'}*/}
                                        {/*    {!template.onePageVersion && 'Clauses list'}*/}
                                        {/*</td>*/}
                                        <td className="actions">
                                            <div className="flex">
                                                {editPermissions(userRole, tabs.TAB_TEMPLATES) && (
                                                    <EditButton
                                                        dataTest={`${template.name}-edit-btn`}
                                                        toolTip="Edit template"
                                                        onClick={() => {
                                                            openModalSpecialCondition('Edit', template);
                                                        }}
                                                    />
                                                )}
                                                {editPermissions(userRole, tabs.TAB_TEMPLATES) && (
                                                    <DeleteButton
                                                        dataTest={`${template.name}-delete-btn`}
                                                        toolTip="Delete template"
                                                        onClick={() => {
                                                            deleteTemplate(template);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <ModalSpecialCondition
                    isOpen={isOpenModalSpecialCondition}
                    type={modalSpecialCondition.type}
                    closeModal={closeModalSpecialCondition}
                    deleteTemplate={deleteModalTemplate}
                    specialCondition={modalSpecialCondition.values}
                    validationErrors={validationErrors}
                />
            </div>
        </div>
    );
};

export default SpecialConditions;
