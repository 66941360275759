import * as React from 'react';
import ProrTypes from 'prop-types';
import cx from 'classnames';
import '../../../sass/requestsToChange.scss';
import { differenceInDays, differenceInHours } from 'date-fns';
import { REQUEST_TO_CHANGE_STATUS_RESOLVED, REQUEST_TO_CHANGE_STATUS_UNRESOLVED } from '../../../config';

export default class RequestsToChangeItem extends React.Component {
    getTimeLeft(created) {
        const now = new Date();
        const days = differenceInDays(now, new Date(created));
        if (days === 0) {
            const hours = differenceInHours(now, new Date(created));
            return hours + (hours === 1 ? ' hour ago' : ' hours ago');
        }

        return days + (days === 1 ? ' day ago' : ' days ago');
    }

    render() {
        const { isActive, request, setLeaseAgreement, title } = this.props;

        return (
            <div className={cx('item', { active: isActive })} onClick={setLeaseAgreement}>
                <div className="row">
                    <span className="title">{title}</span>
                    {request.status === REQUEST_TO_CHANGE_STATUS_RESOLVED && (
                        <button
                            type="button"
                            className="resolved"
                            onClick={event => this.unresolveRequest(event, request.id)}
                        >
                            Resolved
                        </button>
                    )}
                    {request.status === REQUEST_TO_CHANGE_STATUS_UNRESOLVED && (
                        <button type="button" onClick={event => this.resolveRequest(event, request.id)}>
                            Resolve
                        </button>
                    )}
                </div>
                <div className="message">{request.requestMessage}</div>
                <div className="row">
                    <span>{request.tenantName}</span>
                    <span className="time-left">{this.getTimeLeft(request.created)}</span>
                </div>
            </div>
        );
    }

    resolveRequest(event, requestId) {
        event.stopPropagation();
        this.props.resolveRequest(requestId);
    }

    unresolveRequest(event, requestId) {
        event.stopPropagation();
        this.props.unresolveRequest(requestId);
    }
}

RequestsToChangeItem.propTypes = {
    title: ProrTypes.string,
    isActive: ProrTypes.bool,
    request: ProrTypes.object,
    setLeaseAgreement: ProrTypes.func,
    resolveRequest: ProrTypes.func
};
