import React, { useEffect, useState, useRef } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import useOnClickOutside from 'use-onclickoutside';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';
import { FormTextRegular, FormNumber } from './FormText';
import { Field } from 'react-final-form';
import '../../sass/addressDetailsAutoComplete.scss';
import { getError } from '../../utils/formUtils';
import { extractAddressComponents } from '../../utils/addressUtils';
import WarningIcon from '@material-ui/icons/WarningSharp';
import { LEASE_TYPE_RESIDENTIAL_DISPLAY, SA_STATE, SUB_LEASE_TYPE_SA_RENEWALS, countryCodes } from '../../config';
import { LEASE_STATUS_COMPLETE } from '../../actions/dashboard';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../utils/dateUtils';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../selectors/user';
import logoImage from '../../../assets/images/logo.svg';
import cx from 'classnames';

const defaultStyles = {
    input: {
        padding: '5px 0 11px'
    }
};

const defaultOptions = {
    types: ['address'],
    componentRestrictions: { country: countryCodes.AU }
};

const defaultClassNames = {
    autocompleteContainer: 'addresses '
};

const PlacesInput = ({
    inputProps,
    onSelect,
    label,
    meta,
    disabled,
    address,
    leaseType,
    onChangeAddress,
    handleClickOutside,
    addressConfirmed,
    enabledCountries
}) => {
    const ref = useRef(null);
    useOnClickOutside(ref, handleClickOutside);
    const loggedInUser = useSelector(getUserInfo);

    const mappedCompletedLeaseResult = useQuery(
        // Get lease when we change address
        ['mappedCompletedLeaseResult', address],
        async () => {
            const queryStrings = {
                address: address,
                location: SA_STATE,
                leaseType: LEASE_TYPE_RESIDENTIAL_DISPLAY,
                status: LEASE_STATUS_COMPLETE
            };
            const stringified = queryString.stringify(queryStrings);
            const { data } = await axios.get(`/api/agency/lease/search?${stringified}`);
            return data;
        },
        {
            // The query will not execute until the address exists
            // This is only for sa renewals
            enabled: leaseType === SUB_LEASE_TYPE_SA_RENEWALS && !!address && address.length > 3,
            retry: 2
        }
    );

    const searchOptions = defaultOptions;
    if (enabledCountries) {
        searchOptions.componentRestrictions = { country: enabledCountries };
    }

    return (
        <div ref={ref} className={'FormInput address-details-auto-complete-input'}>
            <div className={address ? 'form-group regular on' : 'form-group regular'}>
                <label className={getError(meta) ? `FormError` : ''}>{label}</label>
                <PlacesAutocomplete
                    value={address}
                    styles={defaultStyles}
                    classNames={defaultClassNames}
                    searchOptions={searchOptions}
                    onChange={onChangeAddress}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                        const isLoading =
                            loading ||
                            (leaseType === SUB_LEASE_TYPE_SA_RENEWALS &&
                                !!address &&
                                address.length > 3 &&
                                mappedCompletedLeaseResult.isLoading);
                        return (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Search Places ...',
                                        className: cx('location-search-input', { FormError: getError(meta) }),
                                        ...inputProps,
                                        disabled: disabled
                                    })}
                                />
                                <div className="addresses" data-test="addresses">
                                    {isLoading && <div className="suggestion-item">Loading...</div>}
                                    {/*
                                        In here we display completed leases For long term renewals. (Agent can start from a completed lease)
                                        Use suggestions array to hide leases after select or click outside
                                            - There are saved leases in the mappedCompletedLeaseResult object. we can't remove them
                                              Therefor we check suggestions array. it will be empty when we click outside or select an address
                                            - TODO find a way to clear mappedCompletedLeaseResult data
                                    */}
                                    {!isLoading &&
                                        !addressConfirmed &&
                                        !!suggestions?.length &&
                                        mappedCompletedLeaseResult?.data?.result.map((lease, index) => {
                                            const tenants = lease.tenant?.tenants || [];
                                            const primaryTenant = tenants.find(t => t.isPrimaryTenant) || tenants[0];
                                            return (
                                                <div
                                                    key={index}
                                                    className="suggestion-item"
                                                    {...getSuggestionItemProps(lease)}
                                                    onClick={() => onSelect(lease.address, lease)}
                                                >
                                                    <div className="address-details">
                                                        <div>
                                                            <p>{`${lease.address} (${primaryTenant.secondName}, ${primaryTenant.firstName})`}</p>
                                                            <small>
                                                                <i>Residential Tenancy</i>
                                                                <br />
                                                                <i>
                                                                    {formatDateStandard(
                                                                        lease.term.startDate,
                                                                        getAgencyTimezoneFromUser(loggedInUser)
                                                                    )}
                                                                    -
                                                                    {formatDateStandard(
                                                                        lease.term.endDate,
                                                                        getAgencyTimezoneFromUser(loggedInUser)
                                                                    )}
                                                                </i>
                                                            </small>
                                                        </div>
                                                        <img src={logoImage} alt="FLK" />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {/* This is the addresses coming from google search */}
                                    {!isLoading &&
                                        !addressConfirmed &&
                                        suggestions.map((suggestion, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="suggestion-item"
                                                    {...getSuggestionItemProps(suggestion)}
                                                    onClick={() => onSelect(suggestion.description)}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    }}
                </PlacesAutocomplete>
                {getError(meta) && <p className={'FormError'}>{getError(meta)}</p>}
            </div>
        </div>
    );
};

function FormAddressDetailsAutocomplete(props) {
    const [detailsExpanded, setDetailsExpanded] = useState(false);
    const [differentFromGoogle, setDifferentFromGoogle] = useState(false);
    const [noResultsFromGoogle, setNoResultsFromGoogle] = useState(false);
    const [addressConfirmed, setAddressConfirmed] = useState(false);
    const [shouldExecuteOnBlur, setShouldExecuteOnBlur] = useState(true);
    const [address, setAddress] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [geoResult, setGeoResult] = useState('');

    useEffect(() => {
        if (props.value) {
            handleSelectedAddress(props.value);
        }
    }, []);

    useEffect(() => {
        if (props.editedNewAddress && props.detailsConfirmed) {
            setSelectedAddress(props.editedNewAddress);
            setGeoResult(props.editAddressFormData);
            setAddressConfirmed(true);
            props.setAddressDetails(props.editedNewAddress, props.editAddressFormData);
            setAddress(props.editedNewAddress);
            clearAddressDetailsStore();
        }
    }, [props.editedNewAddress, props.detailsConfirmed, props.editAddressFormData]);

    const handleSelectedAddress = (selectedAddress, renewedFrom) => {
        // When handling a selected address, we remove the error messages
        setDifferentFromGoogle(false);
        setNoResultsFromGoogle(false);

        // Don't do anything if the address is a template
        if (selectedAddress.includes('TEMPLATE')) {
            return true;
        }
        if (renewedFrom) {
            const leaseGeoResult = {
                unit: renewedFrom.addressDetails.unit,
                street_number: renewedFrom.addressDetails.streetNumber,
                route: renewedFrom.addressDetails.streetName,
                locality: renewedFrom.addressDetails.locality,
                postal_code: renewedFrom.addressDetails.postCode,
                political: renewedFrom.addressDetails.googleCountry,
                administrative_area_level_1: renewedFrom.addressDetails.state,
                administrative_area_level_2: renewedFrom.addressDetails.city,
                country: renewedFrom.addressDetails.country
            };
            setAddress(renewedFrom.address);
            setSelectedAddress(renewedFrom.address);
            setGeoResult(leaseGeoResult);
            props.setAddressDetails(renewedFrom.address, leaseGeoResult);
            setAddressConfirmed(true);
            if (props.setRenewedFrom) {
                props.setRenewedFrom(renewedFrom);
            }
        } else {
            geocodeByAddress(selectedAddress)
                .then(results => {
                    let geoResult = { subpremise: '', street_number: '' };
                    for (let i = 0; i < results.length; i++) {
                        if (results[i].types.includes('subpremise')) {
                            geoResult = extractAddressComponents(results[i], geoResult);
                            break;
                        } else {
                            geoResult = extractAddressComponents(results[i], geoResult);
                        }
                    }
                    const subpremise = geoResult.subpremise ? `${geoResult.subpremise}/` : '';
                    const subpremiseForCompare = geoResult.subpremise
                        ? `${geoResult.subpremise.replace(/\D+/g, '')}/`
                        : '';

                    // generate the address as Google would come back from autocomplete
                    const compareAddress = `${subpremiseForCompare}${geoResult.street_number} ${geoResult.route}, ${geoResult.locality} ${geoResult.administrative_area_level_1}, ${geoResult.country}`;
                    // generate address as Google could come back from autocomplete with a postal
                    const compareAddressPostalcode = `${subpremiseForCompare}${geoResult.street_number} ${geoResult.route}, ${geoResult.locality} ${geoResult.administrative_area_level_1} ${geoResult.postal_code}`;

                    // eslint-disable-next-line prettier/prettier
                    const formattedAddress = `${subpremise}${geoResult.street_number || ''} ${geoResult.route || ''}, ${geoResult.locality || ''} ${geoResult.administrative_area_level_1 || ''} ${geoResult.postal_code || ''}`;

                    setSelectedAddress(formattedAddress);
                    setAddress(formattedAddress);
                    setGeoResult(geoResult);
                    props.setAddressDetails(formattedAddress, geoResult);

                    if (!props.detailsConfirmed) {
                        if (compareAddress !== selectedAddress && compareAddressPostalcode !== selectedAddress) {
                            setDifferentFromGoogle(true);

                            // The below code either opens up the modal (in creation screen) or it expands the address edit details
                            if (!props.editMode) {
                                displayDetailsModal(formattedAddress, geoResult);
                            } else {
                                setDetailsExpanded(true);
                            }
                        } else {
                            // no issue with what was selected, so address is confirmed
                            setAddressConfirmed(true);
                        }
                    }
                })
                .catch(error => {
                    // If there are no results back from Google, we show an error message and ask to try again or manually fill in the address
                    if (error === 'ZERO_RESULTS') {
                        if (props.value) {
                            setDetailsExpanded(false);
                            setNoResultsFromGoogle(true);
                        } else {
                            const data = {
                                differentFromGoogle: false,
                                noResultsFromGoogle: true,
                                selectedAddress: selectedAddress,
                                geoResult: geoResult
                            };
                            props.openAddressDetailsModal(data);
                        }
                    }
                });
        }
        setShouldExecuteOnBlur(false);
    };

    /**
     * This can be called from with the for or onSelect
     * @param selectedAddress
     * @param geoResultValue
     */
    function displayDetailsModal(selectedAddress, geoResultValue) {
        const data = {
            differentFromGoogle: differentFromGoogle,
            noResultsFromGoogle: noResultsFromGoogle,
            selectedAddress: selectedAddress,
            geoResult: geoResultValue
        };
        props.openAddressDetailsModal(data);
    }
    function clearAddressDetailsStore() {
        props.clearAddressDetailsStore();
    }

    function expandDetailsEvent(event) {
        if (event) {
            event.preventDefault();
        }
        //We are in edit mode
        if (props.value && props.showAddressBelow) {
            setDetailsExpanded(!detailsExpanded);
        } else {
            const data = {
                differentFromGoogle: false,
                noResultsFromGoogle: false,
                selectedAddress: selectedAddress,
                geoResult: geoResult
            };
            props.openAddressDetailsModal(data);
        }
    }

    function handleChangeAddress(address) {
        setAddressConfirmed(false);
        setShouldExecuteOnBlur(true);
        setAddress(address);
        if (props.setRenewedFrom) {
            props.setRenewedFrom(null);
        }
    }

    let { name, label, styles, onChange, placeholder, rootClasses, disabled, leaseType, hideEditAddress } = props;

    const inputProps = {
        name: name,
        placeholder
    };

    function onChangeAddress(address) {
        if (typeof onChange !== 'undefined') {
            onChange(address);
        }
        handleChangeAddress(address);
    }

    function handleClickOutside() {
        // If the address is not yet confirmed and should execute on blur, that is currently set to false after a value from the autocomplete is selected
        if (addressConfirmed === false && shouldExecuteOnBlur === true) {
            handleSelectedAddress(address);
        }
    }

    return (
        <div>
            <div className="address-details-auto-complete">
                <Field
                    rootClasses={rootClasses}
                    styles={styles}
                    label={label}
                    inputProps={inputProps}
                    name={name}
                    type={'text'}
                    component={PlacesInput}
                    disabled={disabled}
                    onSelect={handleSelectedAddress}
                    address={address}
                    leaseType={leaseType}
                    onChangeAddress={onChangeAddress}
                    handleClickOutside={handleClickOutside}
                    addressConfirmed={addressConfirmed}
                    enabledCountries={props.enabledCountries}
                />
                {!disabled && !hideEditAddress && (
                    <div className="address-details-auto-complete-input_button_container">
                        <button
                            className="address-details-auto-complete-input_button"
                            onClick={e => {
                                expandDetailsEvent(e);
                            }}
                        >
                            {props.value && props.showAddressBelow && (
                                <i className={detailsExpanded ? 'icon-contract' : 'icon-expand'} />
                            )}
                            {detailsExpanded ? 'Close' : 'Edit address'}
                        </button>
                    </div>
                )}
            </div>
            {noResultsFromGoogle && (
                <div className="address-details-auto-complete-details-message text-danger">
                    <WarningIcon>warning</WarningIcon>
                    <b>Google did not return any results for this address, please try again</b>
                </div>
            )}
            {detailsExpanded && props.showAddressBelow && (
                // These field names comply with Google places auto look up
                <div className="address-details-auto-complete-details">
                    {differentFromGoogle && (
                        <div className="address-details-auto-complete-details-message text-danger">
                            <WarningIcon>warning</WarningIcon>
                            <b>
                                The address that was returned from google was slightly different to the one you
                                selected, please confirm the details below and make any required edits prior to
                                confirming
                            </b>
                        </div>
                    )}
                    <b>Please use below fields to update address if not correct:</b>
                    <FormTextRegular name="subpremise" label="Unit" />
                    <FormTextRegular name="street_number" label="Number" id="streetNumber" />
                    <FormTextRegular name="route" label="Street" />
                    <FormTextRegular name="locality" label="Suburb" />
                    <FormNumber name="postal_code" label="Post code" />
                    <FormTextRegular name="administrative_area_level_1" label="State" />
                </div>
            )}
        </div>
    );
}

export default FormAddressDetailsAutocomplete;
