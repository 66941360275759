import { createSelector } from 'reselect';

export const getSubscription = state => state.subscription;

export const getIsConfirmPlanUpgradeModalOpen = createSelector(
    [getSubscription],
    subscription => subscription.isConfirmPlanUpgradeModalOpen
);
export const getActivePlan = createSelector([getSubscription], subscription => subscription.activePlan);
export const getModalType = createSelector([getSubscription], subscription => subscription.modalType);

export const getIsUpdatingPaymentDetails = createSelector(
    [getSubscription],
    subscription => subscription.isUpdatingPaymentDetails
);
