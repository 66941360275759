import React from 'react';
import '../../../../../sass/dashboardContentTable.scss';

import { Table, Th, Tr, Thead, Td } from 'reactable';
import AgentLogo from '../../../../AgentLogo';
import BasicDocumentsMoreOptionBlock from '../../cells/BasicDocumentsMoreOptionBlock';
import { useDispatch } from 'react-redux';
import { openDocumentForm } from '../../../../../actions/document';
import { DOCUMENT_TERMINATION_NOTICE, DOCUMENT_READ_ONLY_MODE } from '../../../../../config';
import ReasonForTermination from './ReasonForTermination';

const TerminationNoticeDraft = ({ documentList }) => {
    const dispatch = useDispatch();

    function openDocumentFormView(event, doc) {
        event.stopPropagation();
        dispatch(openDocumentForm(DOCUMENT_TERMINATION_NOTICE, doc, DOCUMENT_READ_ONLY_MODE));
    }
    return (
        <Table>
            <Thead>
                <Th column="agentLogo">AGENT</Th>
                <Th column="address">ADDRESS</Th>
                <Th column="reasonForTermination">REASON FOR TERMINATION</Th>
                <Th column="vacantPossessionDate">VACANT POSSESSION DATE</Th>
                <Th column="status">Status</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {documentList &&
                documentList.map((doc, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openDocumentFormView(event, doc);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={doc.agent} />
                            </Td>
                            <Td column="address">
                                <b>{doc.address}</b>
                            </Td>
                            <Td column="reasonForTermination">
                                <ReasonForTermination doc={doc} />
                            </Td>
                            <Td column="vacantPossessionDate">
                                <b>{doc.terminationWithoutLease.vacantPossessionDateFormatted}</b>
                            </Td>
                            <Td column="status">
                                <React.Fragment>
                                    <span className="icon-document-draft">Draft</span>
                                </React.Fragment>
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <BasicDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        docType={doc.docType}
                                        doc={doc}
                                    />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default TerminationNoticeDraft;
