import React from 'react';
import ReactTooltip from 'react-tooltip';
import { SUB_LEASE_TYPE_LONG_TERM_RENEWAL, SUB_LEASE_TYPE_SHORT_TERM_RENEWAL } from '../../../config';
import { FormRadioGroupButton } from '../../form/FormRadioGroupButton';

function SaRenewalSelect({ addressValue, values }) {
    return (
        <>
            <ReactTooltip id="short-term-tool-tip" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                <div>
                    <p>
                        This is a short fixed term tenancy agreement (Form 1). We are working on this feature and it
                        will be available on FLK very soon.
                    </p>
                </div>
            </ReactTooltip>
            <ReactTooltip id="long-term-tool-tip" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                <div>
                    <p>This will take you to complete the Notice of Lease Extension</p>
                </div>
            </ReactTooltip>
            <p className="text-align-center description">{addressValue}</p>
            <br />
            <br />
            <div>
                <p>The tenant will be extending their agreement for 90 days +</p>
            </div>
            {/* 
            we will remove this entire part until SA short term implementation 
            <FormRadioGroupButton
                label="How long will the Tenant be extending their agreement for?"
                name={'subLeaseType'}
                value={values.subLeaseType}
                className="align-center"
                data={[
                    // we hide this for now. we will display this when we have short term renewal
                    {
                        label: 'Less than 90 days',
                        value: SUB_LEASE_TYPE_SHORT_TERM_RENEWAL,
                        className: 'short-term-renewal',
                        disabled: true,
                        toolTipId: 'short-term-tool-tip'
                    },
                    {
                        label: '90 days +',
                        value: SUB_LEASE_TYPE_LONG_TERM_RENEWAL,
                        className: 'long-term-renewal',
                        toolTipId: 'long-term-tool-tip'
                    }
                ]}
            /> */}
            <br />
            <br />
            <br />
        </>
    );
}

export default SaRenewalSelect;
