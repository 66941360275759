import React from 'react';
import { Table, Th, Tr, Thead, Td } from 'reactable';
import { useDispatch } from 'react-redux';
import AgentLogo from '../../../../AgentLogo.js';
import BasicDocumentsMoreOptionBlock from '../../cells/BasicDocumentsMoreOptionBlock.js';
import { openDocumentForm } from '../../../../../actions/document.js';
import { DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE, DOCUMENT_READ_ONLY_MODE } from '../../../../../config';
import { DEFINE_ME } from '../../../../../types/utilityTypes.js';

import '../../../../../sass/dashboardContentTable.scss';

interface EstimatedSellingPriceNoticeCancelledProps {
    documentList: DEFINE_ME;
}

const EstimatedSellingPriceNoticeCancelled: React.FC<EstimatedSellingPriceNoticeCancelledProps> = ({ documentList }) => {
    const dispatch = useDispatch();

    function openDocumentFormView(event: React.MouseEvent<HTMLTableRowElement>, doc: DEFINE_ME) {
        event.stopPropagation();
        dispatch(openDocumentForm(DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE, doc, DOCUMENT_READ_ONLY_MODE));
    }
    return (
        <Table className="pm-draft-tb">
            <Thead>
                <Th column="agentLogo">AGENT</Th>
                <Th column="address">ADDRESS</Th>
                <Th column="principals">PRINCIPALS</Th>
                <Th column="dateOfNotice">DATE SENT</Th>
                <Th column="reasonForCancel">REASON FOR CANCEL </Th>
                <Th column="sendEmail"> </Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {documentList &&
                documentList.map((doc, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openDocumentFormView(event, doc);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={doc.agent} />
                            </Td>
                            <Td column="address">
                                <b>{doc.address}</b>
                            </Td>
                            <Td column="principals">
                                <b>{doc.details ? doc.details.vendorListText : ''}</b>
                            </Td>
                            <Td column="dateOfNotice">
                                <b>{doc.sentForSigningDateFormatted}</b>
                            </Td>
                            <Td column="reasonForCancel">
                                <b>{doc.cancelReason}</b>
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <BasicDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        docType={doc.docType}
                                        doc={doc}
                                    />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default EstimatedSellingPriceNoticeCancelled;
