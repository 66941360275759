import { createSelector } from 'reselect';
import { getDocumentList } from '../dashboard/documentList';
import { find } from 'lodash';

export const getDocument = state => state.document;

export const getDocsListChange = createSelector([getDocument], document => document.docsListChange);
export const getCurrentDocument = createSelector([getDocument], document => document.currentDocument);
export const getDocumentEditMode = createSelector([getDocument], document => document.editMode);
export const getIsGlobalTemplate = createSelector([getDocument], document => document.isGlobalTemplate);
export const getIsCancelReasonModalOpen = createSelector([getDocument], document => document.isOpenCancelReasonModal);
export const getDocumentToCancel = createSelector([getDocument], document => document.documentToCancel);
export const getIsCancelClone = createSelector([getDocument], document => document.cancelClone);
export const getIsSendButtonDisabledForApproval = createSelector(
    [getDocument],
    document => document.isSendButtonDisabledForApproval
);
export const getBulkStatusEditMode = createSelector([getDocument], document => document.bulkStatusEditMode);
export const getSendingDecline = createSelector([getDocument], document => document.sendingDecline);
export const getSendingApproval = createSelector([getDocument], document => document.sendingApproval);
export const getPdfGeneratedState = createSelector([getDocument], document => document.pdfGeneratedState);

// ONLY if files are uploaded from the dashboard this is used
export const getFiles = createSelector([getDocument], document => document.files);

export const getCurrentDocumentAgentName = createSelector([getDocumentList, getDocument], (documentList, document) => {
    const currentDocument = find(
        documentList?.[document.currentDocument?.docType]?.[document.currentDocument?.status],
        { id: document.currentDocument?.id }
    );
    if (currentDocument) {
        return currentDocument.agent?.fullName || '';
    }
    return '';
});
