import React from 'react';
import axios from 'axios';
import ThumbDisplay from '../../../components/dashboard/image/ThumbDisplay';
import { SELECT_OPTION_OTHER } from '../../../config';

import '../../../sass/documentModalDialog.scss';
import '../../../sass/flkAKeyForm.scss';

const FlkAKeyHistoryDetails = ({ currentHistoryDetails, callback }) => {
    let watchlistNames = [];
    const contactType =
        currentHistoryDetails.contactType === SELECT_OPTION_OTHER
            ? currentHistoryDetails.otherContactType
            : currentHistoryDetails.contactType;
    const reasonForLoan =
        currentHistoryDetails.reasonForLoan === SELECT_OPTION_OTHER
            ? currentHistoryDetails.otherReasonForLoan
            : currentHistoryDetails.reasonForLoan;
    if (currentHistoryDetails.watchlist && Array.isArray(currentHistoryDetails.watchlist.staff)) {
        watchlistNames = currentHistoryDetails.watchlist.staff.map(staff => staff.name);
    }

    function checkOutKeyRequest(data) {
        return axios.post('/api/flk-a-key/create-draft', data);
    }

    return (
        <div className="flk-a-key-summary">
            <table>
                <tr>
                    <td>Key Number</td>
                    <td>{currentHistoryDetails.key}</td>
                </tr>
                <tr>
                    <td>Checked Out</td>
                    <td>{currentHistoryDetails.loggedDateFormatted}</td>
                </tr>
                <tr>
                    <td>Checked In</td>
                    <td>{currentHistoryDetails.returnedDateFormatted}</td>
                </tr>
                <tr>
                    <td>Company</td>
                    <td>{currentHistoryDetails.company}</td>
                </tr>
                <tr>
                    <td>Contact Type</td>
                    <td>{contactType}</td>
                </tr>
                <tr>
                    <td>Contact</td>
                    <td>{currentHistoryDetails.name}</td>
                </tr>
                <tr>
                    <td>Mobile Number</td>
                    <td>{currentHistoryDetails.mobile}</td>
                </tr>
                <tr>
                    <td>Return Due Date</td>
                    <td>{currentHistoryDetails.dueDateFormatted}</td>
                </tr>
                <tr>
                    <td>Reason for Check out</td>
                    <td>{reasonForLoan}</td>
                </tr>
                <tr>
                    <td>Notes</td>
                    <td>{currentHistoryDetails.notes}</td>
                </tr>
                <tr>
                    <td>Staff Assigned</td>
                    <td>{currentHistoryDetails.agent.fullName}</td>
                </tr>
                <tr>
                    <td>WatchList</td>
                    <td>
                        <pre>{watchlistNames.join('\n')}</pre>
                    </td>
                </tr>
            </table>
            {Array.isArray(currentHistoryDetails.compressedImageUrls) &&
                currentHistoryDetails.compressedImageUrls.length > 0 && (
                    <div className="photo-group">
                        <h2>Photos</h2>
                        <div className="qr-and-image">
                            <div className="image-group">
                                {currentHistoryDetails.compressedImageUrls.map((image, index) => (
                                    <ThumbDisplay key={index} imageSrc={image} imageTitle={`key image ${index + 1}`} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default FlkAKeyHistoryDetails;
