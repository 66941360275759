import cx from 'classnames';
import { isArray } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import appHistory from '@app/AppHistory.js';
import { loadPlanListForLanding, returnToPreviousStep, selectPlan } from '@app/actions/authorization.js';
import Icon, { Icons } from '@app/common/components/Icon';
import { Subscription, SubscriptionCardGroup } from '@app/common/components/cards/SubscriptionCard';
import { SignUpStep, isMobileWidth } from '@app/config';
import { AccountType, AvailableCountries, DISCLAIMER_TEXT } from '@app/constants/constants';
import useCountry from '@app/hooks/useCountry';
import useQueryParams from '@app/hooks/useQueryParams';
import {
    getIsPending,
    getIsRegistrationComplete,
    getRegistrationDetails,
    getRememberMe,
    getSelectedSubscription,
    getStep,
    getSubscriptions,
    getHiddenSubscriptions
} from '@app/selectors/authorization.js';
import { matchPath, useHistory } from 'react-router';
import { getIsUserLoggedIn, getLoggedInUser } from '../../selectors/login/index.js';
import { getUserInfo } from '../../selectors/user.js';
import pushDetailsToHubspot from '../../utils/hubspotUtils.js';
import { toSaveToken } from '../../utils/toSaveToken.js';
import { extractUserAndAgencyInfo } from '../../utils/vendorUtils.js';
import SelectionSummary from './SelectionSummary';
import SignUpButtons from './SignUpButtons';
import { SignUpBaseLayout } from './components';
import AccountStep from './components/AccountStep';
import DetailsStep from './components/DetailsStep';
import { TrialMessage } from './components/SignUpLayout';
import VerifyEmailStep from './components/VerifyEmailStep';

import styles from './SignUpV2.module.scss';

interface SignUpState {
    country?: AvailableCountries;
    accountType?: AccountType;
}

enum Actions {
    SET_COUNTRY = 'SET_COUNTRY',
    SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE'
}

type ActionType =
    | { type: Actions.SET_COUNTRY; payload: AvailableCountries }
    | { type: Actions.SET_ACCOUNT_TYPE; payload: AccountType };

function reducer(state: SignUpState, action: ActionType): SignUpState {
    switch (action.type) {
        case 'SET_COUNTRY':
            if (action.payload === AvailableCountries.NZ) {
                return { country: action.payload, accountType: AccountType.GENERAL_ACCOUNT_TYPE };
            }
            return { country: action.payload };
        case 'SET_ACCOUNT_TYPE':
            return { ...state, accountType: action.payload };
        default:
            return state;
    }
}

const getTitleText = (step: SignUpStep, accountType?: AccountType) => {
    if (step === SignUpStep.Pricing) {
        if (!accountType || accountType === AccountType.GENERAL_ACCOUNT_TYPE) {
            return 'Create, send and sign documents that dazzle';
        } else if (accountType === AccountType.AGENCY_ACCOUNT_TYPE) {
            return 'Next-gen signing for real estate starts here';
        }
    } else if (step === SignUpStep.Account || step === SignUpStep.Details) {
        return 'We will use your details to auto-fill any documents you create';
    }
};

const getDisclaimer = (country?: AvailableCountries, accountType?: AccountType) => {
    if (!country || !accountType) {
        return DISCLAIMER_TEXT.DEFAULT;
    }
    if (country === AvailableCountries.AU) {
        if (accountType === AccountType.AGENCY_ACCOUNT_TYPE) {
            return DISCLAIMER_TEXT.AU.REAL_ESTATE;
        } else if (accountType === AccountType.GENERAL_ACCOUNT_TYPE) {
            return DISCLAIMER_TEXT.AU.BUSINESS;
        }
    } else if (country === AvailableCountries.NZ) {
        return DISCLAIMER_TEXT.NZ.BUSINESS;
    }
};

const getDocumentsLabel = (accountType: AccountType) => {
    switch (accountType) {
        case AccountType.AGENCY_ACCOUNT_TYPE:
            return 'agreements';
        case AccountType.GENERAL_ACCOUNT_TYPE:
            return 'documents';
    }
};

const countryOptions = [
    { value: AvailableCountries.AU, label: 'Australia', icon: <Icon icon={Icons.FLAG_AU} /> },
    { value: AvailableCountries.NZ, label: 'New Zealand', icon: <Icon icon={Icons.FLAG_NZ} /> }
];

const accountTypeOptions = [
    { value: AccountType.AGENCY_ACCOUNT_TYPE, label: 'Real Estate', icon: <Icon icon={Icons.PRODUCT_REAL_ESTATE} /> },
    { value: AccountType.GENERAL_ACCOUNT_TYPE, label: 'Other Business', icon: <Icon icon={Icons.PRODUCT_BUSINESS} /> }
];

const SignUpV2: React.FC = () => {
    const { boot, show, update } = useIntercom();
    const { setCountry, countryFromLocale } = useCountry();
    const dispatch = useDispatch();
    const history = useHistory();

    const subscriptions: Subscription[] | undefined = useSelector(getSubscriptions);
    const hiddenSubscriptions: Subscription[] | undefined = useSelector(getHiddenSubscriptions);
    const step: SignUpStep = useSelector(getStep); // 1-indexed
    const selectedSubscription: Subscription | undefined = useSelector(getSelectedSubscription);
    const isPending: boolean = useSelector(getIsPending);
    const registrationDetails: {
        details?: {
            agencyName?: string;
            companyName?: string;
            email?: string;
            address?: string;
            phone?: string;
            location?: string;
        };
        user?: {
            id?: string;
            firstName?: string;
            secondName?: string;
            email?: string;
            phone?: string;
            emailVerified?: boolean;
        };
    } = useSelector(getRegistrationDetails);
    const [signUpState, signUpDispatch] = React.useReducer(reducer, {});
    const userInfo: { email: string; id: string } = useSelector(getUserInfo);
    const isUserLoggedin = useSelector(getIsUserLoggedIn);
    const [emailVerificationIncomplete, setEmailVerificationIncomplete] = React.useState(false);
    const userFromLogin = useSelector(getLoggedInUser);
    const rememberMe = useSelector(getRememberMe);
    const isRegistrationComplete = useSelector(getIsRegistrationComplete);
    const queryParams = useQueryParams();

    React.useEffect(() => {
        if (signUpState.country) {
            return;
        }
        if (matchPath(appHistory.location.pathname, '/signup/au')) {
            signUpDispatch({ type: Actions.SET_COUNTRY, payload: AvailableCountries.AU });
            setCountry(AvailableCountries.AU);
        } else if (matchPath(appHistory.location.pathname, '/signup/nz')) {
            signUpDispatch({ type: Actions.SET_COUNTRY, payload: AvailableCountries.NZ });
            setCountry(AvailableCountries.NZ);
        } else if (matchPath(appHistory.location.pathname, '/signup/business')) {
            signUpDispatch({ type: Actions.SET_COUNTRY, payload: AvailableCountries.AU });
            signUpDispatch({ type: Actions.SET_ACCOUNT_TYPE, payload: AccountType.GENERAL_ACCOUNT_TYPE });
            setCountry(AvailableCountries.AU);
        } else if (matchPath(appHistory.location.pathname, { path: '/signup', exact: true })) {
            signUpDispatch({ type: Actions.SET_COUNTRY, payload: AvailableCountries.AU });
            signUpDispatch({ type: Actions.SET_ACCOUNT_TYPE, payload: AccountType.AGENCY_ACCOUNT_TYPE });
            setCountry(AvailableCountries.AU);
        }
    }, [setCountry, countryFromLocale, signUpState.country]);

    React.useEffect(() => {
        if (signUpState.country && signUpState.accountType) {
            // load plans for country and account type
            dispatch(loadPlanListForLanding(signUpState.accountType, signUpState.country, true));
        }
    }, [signUpState.country, signUpState.accountType, dispatch]);

    // Use planId from query params if it exists.
    React.useEffect(() => {
        const planIdFromQuery = queryParams.get('plan');
        // Do not attempt to use planId if it does not exist or we have not loaded plans or selected country and account type
        if (
            (!subscriptions?.length && !hiddenSubscriptions?.length) ||
            !signUpState.country ||
            !signUpState.accountType ||
            !planIdFromQuery
        ) {
            return;
        }

        if (
            subscriptions?.some(s => s.id === planIdFromQuery) ||
            hiddenSubscriptions?.some(s => s.id === planIdFromQuery)
        ) {
            if (step === SignUpStep.Pricing) {
                dispatch(selectPlan({ stripeId: planIdFromQuery }));
            }
        } else {
            // Remove plan from query params if it is invalid
            queryParams.delete('plan');
            history.replace({
                pathname: location.pathname,
                search: `?${queryParams.toString()}`
            });
        }
    }, [
        queryParams,
        subscriptions,
        dispatch,
        history,
        step,
        signUpState.country,
        signUpState.accountType,
        hiddenSubscriptions
    ]);

    // This effect has been copied from the original sign up page
    // It appears to save a cookie if the user is logged in,
    // and redirect them to the app if they have completed registration
    React.useEffect(() => {
        if (isUserLoggedin) {
            toSaveToken(userFromLogin, rememberMe, signUpState.accountType);
        }
        // Check if email is verified and redirect to the correct page
        if (isRegistrationComplete) {
            if (registrationDetails?.user?.emailVerified) {
                if (signUpState.accountType === AccountType.AGENCY_ACCOUNT_TYPE) {
                    appHistory.push('/dashboard/agreements/residential_tenancy/draft');
                } else {
                    appHistory.push('/dashboard/documents/all/draft');
                }
            } else {
                setEmailVerificationIncomplete(true);
            }
        }
    }, [
        isRegistrationComplete,
        isUserLoggedin,
        registrationDetails,
        rememberMe,
        signUpState.accountType,
        userFromLogin
    ]);

    // Boot intercom with custom attributes
    React.useEffect(() => {
        if (registrationDetails && !isMobileWidth(899)) {
            const user = extractUserAndAgencyInfo(registrationDetails.user, registrationDetails);
            boot({
                customAttributes: {
                    step,
                    ...user,
                    page: 'sign up, step ' + step
                }
            });
            update({
                customAttributes: {
                    step,
                    ...user,
                    page: 'sign up, step ' + step
                }
            });
            pushDetailsToHubspot(user.email);
        } else if (!isMobileWidth()) {
            boot({ customAttributes: { step, page: 'sign up, step ' + step } });
        }
    }, [boot, update, registrationDetails, step]);

    const isFlkRE = signUpState.accountType === AccountType.AGENCY_ACCOUNT_TYPE;
    const disclaimer = getDisclaimer(signUpState.country, signUpState.accountType);
    const showVerifyEmail = step === SignUpStep.VerifyEmail || emailVerificationIncomplete;

    return (
        <SignUpBaseLayout onContactUs={() => show()} accountType={signUpState.accountType}>
            {showVerifyEmail ? (
                <VerifyEmailStep
                    userId={registrationDetails?.user?.id || userInfo.id}
                    email={registrationDetails?.user?.email || userInfo.email}
                />
            ) : (
                <div
                    className={cx(styles.container, {
                        [styles.pricingStep]: step === SignUpStep.Pricing,
                        [styles.formStep]: step === SignUpStep.Account || step === SignUpStep.Details
                    })}
                >
                    <div className={styles.sideContentTop}>
                        <h1 className={styles.title}>{getTitleText(step, signUpState.accountType)}</h1>
                        <div className={styles.subTitle}>
                            <div className={styles.uniqueSellingPoint}>
                                <span className={styles.emoji}>💳</span>
                                <span>No credit card required*</span>
                            </div>
                            <div className={styles.uniqueSellingPoint}>
                                <TrialMessage accountType={signUpState.accountType} />
                            </div>
                        </div>
                        {step === SignUpStep.Pricing && (
                            <>
                                <div className={styles.locationQuestion}>
                                    <h2 className={styles.questionText}>Where are you based?</h2>
                                    <SignUpButtons<AvailableCountries>
                                        options={countryOptions}
                                        selectedValue={signUpState.country}
                                        onSelect={value => {
                                            signUpDispatch({
                                                type: Actions.SET_COUNTRY,
                                                payload: value as AvailableCountries
                                            });
                                            setCountry(value as AvailableCountries);
                                        }}
                                    />
                                </div>
                                {signUpState.country === AvailableCountries.AU && (
                                    <div className={styles.accountTypeQuestion}>
                                        <h2 className={styles.questionText}>Select your industry</h2>
                                        <SignUpButtons<AccountType>
                                            options={accountTypeOptions}
                                            selectedValue={signUpState.accountType}
                                            onSelect={value =>
                                                signUpDispatch({ type: Actions.SET_ACCOUNT_TYPE, payload: value })
                                            }
                                        />
                                    </div>
                                )}
                                {signUpState.country && signUpState.accountType && (
                                    <div className={styles.planQuestion}>
                                        <h2 className={styles.questionText}>
                                            How many {getDocumentsLabel(signUpState.accountType)} do you plan to send?
                                        </h2>
                                        <Icon icon={Icons.CHEVRON_RIGHT} className={styles.icon} />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className={styles.mainContent}>
                        {step === SignUpStep.Pricing &&
                            subscriptions &&
                            signUpState.accountType &&
                            signUpState.country && (
                                <SubscriptionCardGroup
                                    subscriptions={subscriptions}
                                    handleStartTrial={planId => dispatch(selectPlan({ stripeId: planId }))}
                                />
                            )}
                        {step === SignUpStep.Account &&
                            signUpState.accountType &&
                            signUpState.country &&
                            selectedSubscription && (
                                <AccountStep
                                    accountType={signUpState.accountType}
                                    isPending={isPending}
                                    initialValues={{
                                        firstName: registrationDetails?.user?.firstName,
                                        secondName: registrationDetails?.user?.secondName,
                                        email: registrationDetails?.user?.email,
                                        phone: registrationDetails?.user?.phone
                                    }}
                                    selectedSubscription={selectedSubscription}
                                    updateIntercom={update}
                                    country={signUpState.country}
                                />
                            )}
                        {step === SignUpStep.Details &&
                            signUpState.accountType &&
                            signUpState.country &&
                            selectedSubscription && (
                                <DetailsStep
                                    accountType={signUpState.accountType}
                                    isPending={isPending}
                                    country={signUpState.country}
                                    selectedSubscription={selectedSubscription}
                                    initialValues={
                                        signUpState.accountType === AccountType.AGENCY_ACCOUNT_TYPE
                                            ? {
                                                  agencyName: registrationDetails?.details?.agencyName,
                                                  companyName: registrationDetails?.details?.companyName,
                                                  email: registrationDetails?.details?.email,
                                                  address: registrationDetails?.details?.address,
                                                  phone: registrationDetails?.details?.phone,
                                                  location: registrationDetails?.details?.location
                                              }
                                            : {
                                                  companyName: registrationDetails?.details?.companyName,
                                                  email: registrationDetails?.details?.email,
                                                  address: registrationDetails?.details?.address,
                                                  phone: registrationDetails?.details?.phone
                                              }
                                    }
                                />
                            )}
                    </div>
                    <div className={styles.sideContentBottom}>
                        {step !== SignUpStep.Pricing && signUpState.country && signUpState.accountType && (
                            <SelectionSummary
                                country={signUpState.country}
                                accountType={signUpState.accountType}
                                subscription={selectedSubscription}
                                isEditable={step === SignUpStep.Account}
                                onEdit={() => {
                                    dispatch(returnToPreviousStep());
                                    // Handle case where we have a link directly to a plan
                                    if (queryParams.get('plan')) {
                                        queryParams.delete('plan');
                                        history.push({
                                            pathname: location.pathname,
                                            search: `?${queryParams.toString()}`
                                        });
                                    }
                                }}
                            />
                        )}
                        {isFlkRE && (
                            <div className={styles.additionalSellingPoints}>
                                <div className={styles.uniqueSellingPoint}>
                                    <span className={styles.emoji}>🤸‍♂️</span>
                                    <span>Flexible month-to-month subscriptions</span>
                                </div>
                                <div className={styles.uniqueSellingPoint}>
                                    <span className={styles.emoji}>🔑</span>
                                    <span>Choose your package based on your leasing activity</span>
                                </div>
                                <div className={styles.uniqueSellingPoint}>
                                    <span className={styles.emoji}>🏠</span>
                                    <span>Unlimited use of all other documents included in every plan†</span>
                                </div>
                            </div>
                        )}
                        {disclaimer && (
                            <div className={cx(styles.disclaimer)}>
                                {isArray(disclaimer) ? disclaimer.join(' ') : disclaimer}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </SignUpBaseLayout>
    );
};

export default SignUpV2;
