export const UPDATE_ADVERTISING_FORM = 'UPDATE_ADVERTISING_FORM';

const initState = {
    isAgencyDatabase: false,
    isSingboard: false,
    isListWebsites: false
};

export default function advertisingReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_ADVERTISING_FORM: {
            return { ...state, ...action.data };
        }

        default: {
            return state;
        }
    }
}
