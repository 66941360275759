import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import * as Lease from '../../reducers/lease';
import produce from 'immer';

const initState = {
    appliances: ''
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            const { lease } = action.result.data;
            if (lease.propertyDescription) {
                draftState = { ...initState, ...lease.propertyDescription };
            } else {
                draftState = { ...initState };
            }
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = { ...initState, ...action.lease.propertyDescription };
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = { ...initState, ...action.payload.agreementInfo.propertyDescription };
            break;
        }
        case Lease.LEASE_PROPERTY_DESCRIPTION_SUCCESS: {
            draftState = { ...initState, ...action.result.data.lease.propertyDescription };
            break;
        }
    }
    return draftState;
});
