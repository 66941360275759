import * as React from 'react';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import styles from './DocumentDrop.module.scss';
import { LargeButton } from '../../components/dashboard/documentList/components/LargeButton';
import { ReactComponent as DropPdfImage } from '../../../assets/images/icons/drop-pdf.svg';
import { loadFileAsArrayBuffer } from './DocumentUploadSecurityHelper';
import { PDFDocument } from 'pdf-lib';
import ModalPdfContainsSecurity from '../../components/modals/ModalPdfContainsSecurity.tsx';
import { LEASE_TYPE_RESIDENTIAL_DISPLAY } from '../../config';
import Tooltip from '../../common/components/tooltips/Tooltip';
import useComponentId from '../../hooks/useComponentId';
import { useRollbarProvider } from '../../components/providers/RollbarProvider';
import axios from 'axios';
import { formatDocumentsForApi } from '../../utils/formUtils.js';

export function DocumentDrop({
    className,
    dropzoneClassName,
    updateDocuments,
    dropzoneTitle,
    flattenPdfs,
    disabled,
    disabledTooltipText,
    documentLeaseType = LEASE_TYPE_RESIDENTIAL_DISPLAY, // Legacy default lease type
    documentCategory = 'other',
    additionalOptionsForDocument = {},
    allowMultiple = true,
    documents = [],
    acceptedFileTypes = ['.pdf'],
    rejectEncryptedPdfs,
    maxFileSize = 6,
    maxTotalSize = 6
}) {
    const Rollbar = useRollbarProvider();
    const maxFileSizeInBytes = maxFileSize * 1024 * 1024;
    const maxTotalSizeInBytes = maxTotalSize * 1024 * 1024;

    const FILE_SIZE_ERROR_CODE = 'file-too-large'; // from react-dropzone
    const FILE_SIZE_ERROR_MESSAGE = `File must not be larger than ${maxFileSize} MB`;
    const TOTAL_FILE_SIZE_ERROR_MESSAGE = `Total upload must not be larger than ${maxTotalSize} MB`;

    const [dropZoneError, setDropZoneError] = useState('');
    const [pdfSecurityModalOpen, setPdfSecurityModalOpen] = useState(false);
    const [encryptedPdfFilenames, setEncryptedPdfFilenames] = useState([]);
    const [isCheckingEncryption, setIsCheckingEncryption] = useState(false);

    const tooltipId = useComponentId().toString();

    const onDropRejected = errors => {
        if (errors[0].errors[0].code === FILE_SIZE_ERROR_CODE) {
            setDropZoneError(FILE_SIZE_ERROR_MESSAGE);
        } else {
            setDropZoneError(errors[0].errors[0].message);
        }
    };

    const getFileObjectForUpload = file => ({
        documentName: file.name,
        file: file,
        category: documentCategory,
        leaseType: documentLeaseType,
        ...additionalOptionsForDocument
    });

    const onDrop = async newFiles => {
        setDropZoneError('');

        const existingFiles = !isEmpty(documents) && allowMultiple ? [...documents] : [];
        const filesToUpload = [];
        const encryptedPdfs = [];

        const existingFilesCumulativeSize = existingFiles.reduce(
            (cumulativeSize, file) => cumulativeSize + (file.size || file.document?.size),
            0
        );
        const newFilesCumulativeSize = newFiles.reduce((cumulativeSize, file) => cumulativeSize + file.size, 0);

        if (existingFilesCumulativeSize + newFilesCumulativeSize > maxTotalSizeInBytes) {
            setDropZoneError(TOTAL_FILE_SIZE_ERROR_MESSAGE);
            return;
        }

        for (const file of newFiles) {
            let fileToUpload = file;

            if (file.type === 'application/pdf') {
                try {
                    const fileBytes = await loadFileAsArrayBuffer(file);
                    try {
                        const pdfDoc = await PDFDocument.load(fileBytes, { ignoreEncryption: true });
                        if (rejectEncryptedPdfs && pdfDoc.isEncrypted) {
                            setIsCheckingEncryption(true);
                            const response = await axios.post(
                                '/api/document/get-encryption-type',
                                formatDocumentsForApi([getFileObjectForUpload(file)])
                            );
                            setIsCheckingEncryption(false);
                            if (!response.data?.canRemoveSecurityWithoutPassword) {
                                encryptedPdfs.push(file.path);
                                continue;
                            }
                        }

                        if (flattenPdfs) {
                            pdfDoc.getForm().flatten();
                            const pdfBytes = await pdfDoc.save();
                            const newFileToUpload = new File([pdfBytes], fileToUpload.name, {
                                type: 'application/pdf'
                            });
                            fileToUpload = newFileToUpload;
                        }

                        filesToUpload.push(getFileObjectForUpload(fileToUpload));
                    } catch (error) {
                        setDropZoneError('Unable to upload file(s)');
                        Rollbar.error(
                            'Unable to upload file(s)',
                            {
                                error_message: error.message,
                                status: 'error',
                                env: ENVIRONMENT
                            },
                            error
                        );
                    }
                } catch (error) {
                    setDropZoneError('Unable to upload file(s)');
                    Rollbar.error(
                        'Unable to upload file(s)',
                        {
                            error_message: error.message,
                            status: 'error',
                            env: ENVIRONMENT
                        },
                        error
                    );
                }
            } else {
                filesToUpload.push(getFileObjectForUpload(fileToUpload));
            }
        }

        const allFiles = !isEmpty(documents) && allowMultiple ? [...documents, ...filesToUpload] : filesToUpload;

        updateDocuments(allFiles, filesToUpload);

        if (encryptedPdfs.length) {
            setEncryptedPdfFilenames(encryptedPdfs);
            setPdfSecurityModalOpen(true);
        }
    };

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        maxSize: maxFileSizeInBytes,
        accept: { 'application/pdf': acceptedFileTypes },
        onDrop: files => onDrop(files),
        onDropRejected: errors => onDropRejected(errors),
        multiple: allowMultiple,
        disabled
    });

    return (
        <React.Fragment>
            <div
                className={cx(styles.documentDrop, className, { [styles.disabled]: disabled })}
                {...getRootProps()}
                data-tip={disabled ? disabledTooltipText : undefined}
                data-for={tooltipId}
            >
                <input {...getInputProps()} data-test="document-uploader-input" />
                <LargeButton
                    disabled={disabled}
                    isLoading={isCheckingEncryption}
                    loadingText={isCheckingEncryption ? 'Checking PDF encryption...' : ''}
                    className={cx(
                        dropzoneClassName,
                        styles.largeButton,
                        { [styles.dragAccept]: isDragAccept },
                        { [styles.dragReject]: isDragReject }
                    )}
                    icon={<DropPdfImage className={styles.dropPdf} />}
                    title={dropzoneTitle}
                    label={'Drag and drop a PDF here or click to select a file'}
                    error={!isDragAccept && dropZoneError}
                />
            </div>
            <ModalPdfContainsSecurity
                isOpen={pdfSecurityModalOpen}
                close={() => setPdfSecurityModalOpen(false)}
                encryptedPdfFilenames={encryptedPdfFilenames}
            />
            <Tooltip place="bottom" effect="solid" id={tooltipId} />
        </React.Fragment>
    );
}

DocumentDrop.propTypes = {
    updateDocuments: PropTypes.func.isRequired,
    dropzoneTitle: PropTypes.string,
    className: PropTypes.string,
    dropzoneClassName: PropTypes.string,
    allowMultiple: PropTypes.bool,
    rejectEncryptedPdfs: PropTypes.bool,
    flattenPdfs: PropTypes.bool,
    acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
    documents: PropTypes.array,
    maxFileSize: PropTypes.number,
    maxTotalSize: PropTypes.number,
    additionalOptionsForDocument: PropTypes.object,
    documentCategory: PropTypes.string,
    documentLeaseType: PropTypes.string
};

export default DocumentDrop;
