import React, { memo } from 'react';

import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { FormTextMultiline } from '../../../../components/form/FormText';
import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../config';
import AllowAnimals from '../../../../components/lease/mainScreen/common/additionalTerms/AllowAnimals';
import SwimmingPool from '../../../../components/lease/mainScreen/common/additionalTerms/SwimmingPool';
import DefenceClause from '../../../../components/lease/mainScreen/common/additionalTerms/DefenceClause';

const VicAdditional = ({
    values,
    addPet,
    allowPets,
    setNoOwnerRights,
    clearPets,
    push,
    additional,
    form,
    location
}) => {
    return (
        <React.Fragment>
            <FormRadioGroup
                label="Do owners corporation rules apply to the premises?"
                name={'ownersCorporation'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'owners-corporation-enabled-yes',
                        id: 'owners-corporation-enabled-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'owners-corporation-enabled-no',
                        id: 'owners-corporation-enabled-no',
                        value: NO_VALUE
                    }
                ]}
            />
            {values.ownersCorporation === YES_VALUE && (
                <p className="text-warning">
                    <span className="icon icon-warning" /> You will be required to upload a copy of the rules in the
                    documents section.
                </p>
            )}
            <AllowAnimals
                values={values}
                allowPets={allowPets}
                clearPets={clearPets}
                addPet={addPet}
                push={push}
                additional={additional}
                location={location}
            />
            <SwimmingPool
                values={values}
                hasSwimingPoolMaintenance={true}
                location={location}
                hasSwimmingPoolMaintenanceDuration={true}
            />
            <DefenceClause />
            <FormRadioGroup
                label="Condition report"
                name={'conditionReportProvided'}
                className="additional-term-item additional-term-item--condition-report"
                radioGroupClass="wrapped-radio-group"
                data={[
                    {
                        label: 'The condition report has been provided.',
                        className: 'is-condition-report-enabled-yes',
                        id: 'is-condition-report-enabled-yes',
                        value: YES_VALUE
                    },
                    {
                        label:
                            'The condition report will be provided to the renter on or before the date the agreement starts.',
                        className: 'is-condition-report-enabled-no',
                        id: 'is-condition-report-enabled-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <b>Rental Provider Disclosures</b>
            <FormRadioGroup
                label="The property is on the market for sale, or in the process of being sold."
                name={'isPropertyOnTheMarket'}
                className="additional-term-item additional-term-item--isPropertyOnTheMarket"
                radioGroupClass="wrapped-radio-group"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'isPropertyOnTheMarket-yes',
                        id: 'isPropertyOnTheMarket-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'isPropertyOnTheMarket-no',
                        id: 'isPropertyOnTheMarket-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <FormRadioGroup
                label="The property is to be repossessed under a mortgage."
                name={'isPropertyRepossessedUnderMortgage'}
                className="additional-term-item additional-term-item--isPropertyRepossessedUnderMortgage"
                radioGroupClass="wrapped-radio-group"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'isPropertyRepossessedUnderMortgage-yes',
                        id: 'isPropertyRepossessedUnderMortgage-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'isPropertyRepossessedUnderMortgage-no',
                        id: 'isPropertyRepossessedUnderMortgage-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <FormRadioGroup
                label="The rental provider is not the owner of the property."
                name={'isNotTheOwner'}
                className="additional-term-item additional-term-item--isNotTheOwner"
                radioGroupClass="wrapped-radio-group"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'isNotTheOwner-yes',
                        id: 'isNotTheOwner-yes',
                        onClick: () => setNoOwnerRights(values, form.change),
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'isNotTheOwner-no',
                        id: 'isNotTheOwner-no',
                        value: NO_VALUE
                    }
                ]}
            />
            {values.isNotTheOwner === YES_VALUE && (
                <div className="notTheOwnerRights">
                    <div className="formBox-column">
                        <FormTextMultiline name="notTheOwnerRights" label="Leasing rights:" />
                    </div>
                </div>
            )}
            <FormRadioGroup
                label="Electricity is supplied to the property from an embedded electricity network."
                name={'isElectricitySupplied'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'is-electricity-supplied-yes',
                        id: 'is-electricity-supplied-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'is-electricity-supplied-no',
                        id: 'is-electricity-supplied-no',
                        value: NO_VALUE
                    }
                ]}
            />
            {values.isElectricitySupplied === YES_VALUE && (
                <div className="detailsOfNetwork">
                    <div className="formBox-column">
                        <FormTextMultiline name="detailsOfNetwork" label="The details of this network are:" />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default memo(VicAdditional);
