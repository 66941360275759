import React from 'react';
import axios from 'axios';
import CommonFooter from '../shared/CommonFooter.js';
import { useMutation } from '@tanstack/react-query';
import { DEFINE_ME } from '../../../../types/utilityTypes.js';

type EntryNoticeWithoutLeaseFooterProps = {
    isSendingEntryNotice: boolean;
    isSendingEntryNoticeSuccess: boolean;
    isSendingEntryNoticeFail: boolean;
    currentDocument: DEFINE_ME;
    documentEditMode: DEFINE_ME;
    previewPdf: DEFINE_ME;
    viewPdf: DEFINE_ME;
    form: DEFINE_ME;
    isSaveDraft: boolean;
};

const EntryNoticeWithoutLeaseFooter: React.FC<EntryNoticeWithoutLeaseFooterProps> = ({
    isSendingEntryNotice,
    isSendingEntryNoticeSuccess,
    isSendingEntryNoticeFail,
    currentDocument,
    documentEditMode,
    previewPdf,
    viewPdf,
    form,
    isSaveDraft
}) => {
    const resendPDFMutation = useMutation((docId: string) => {
        return axios.post(`api/document/entry-notice/${docId}/resend`);
    });

    const resendPDF = (docId: string) => {
        resendPDFMutation.reset();
        resendPDFMutation.mutate(docId);
    };

    return (
        <CommonFooter
            isDoc={true}
            currentDocument={currentDocument}
            documentEditMode={documentEditMode}
            resend={resendPDF}
            viewPdf={viewPdf}
            previewPdf={previewPdf}
            isResending={resendPDFMutation.isLoading}
            isResendingSuccess={resendPDFMutation.isSuccess}
            isResendingFail={resendPDFMutation.isError}
            isSendingSuccess={isSendingEntryNoticeSuccess}
            isSendingFail={isSendingEntryNoticeFail}
            isSending={isSendingEntryNotice}
            form={form}
            isSaveDraft={isSaveDraft}
            hideResend={true}
        />
    );
};
export default EntryNoticeWithoutLeaseFooter;
