import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import { COMMISSION_TYPE_PERCENTAGE } from '../../config';
import { isLeaseLocationQLD } from '../../utils/agreementUtils';

export const UPDATE_PRICE_AND_FEE_FORM = 'UPDATE_PRICE_AND_FEE_FORM';

const initState = {
    opinionPriceFrom: '',
    opinionPriceTo: '',
    saleMethod: null,
    commissionPercent: '',
    commissionType: COMMISSION_TYPE_PERCENTAGE,
    commissionFee: '',
    commissionFrom: '',
    commissionTo: '',
    commissionComment: '',
    startingPrice: '$',
    auctionDate: '',
    auctionDateToBeConfirmed: false,
    isRange: true,
    exactPrice: ''
};

const qldInitState = {
    isRange: false,
    isElectronicListingRange: true
};

function _getInitState(location) {
    if (isLeaseLocationQLD(location)) {
        return qldInitState;
    }
    return initState;
}

export default function priceAndFeeReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_PRICE_AND_FEE_FORM: {
            return { ...initState, ...action.data };
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            const locationInitState = _getInitState(action.payload.agreementInfo.location);
            return Object.assign({}, locationInitState, action.payload.agreementInfo.priceAndFee);
        }

        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            const locationInitState = _getInitState(action.result.data.lease.location);
            if (action.result.data.lease.priceAndFee) {
                return Object.assign({}, locationInitState, action.result.data.lease.priceAndFee);
            } else {
                return Object.assign({}, locationInitState);
            }
        }

        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            const locationInitState = _getInitState(action.lease.location);
            return { ...locationInitState, ...action.lease.priceAndFee };
        }

        case Lease.LEASE_PRICE_AND_FEE_SUCCESS: {
            return { ...state, ...action.result.data.lease.priceAndFee };
        }

        default: {
            return state;
        }
    }
}
