import { createSelector } from 'reselect';
import { getDocument } from './index';

export const getTerminationData = createSelector([getDocument], document => document.terminationData);

export const getIsOpenTerminationNoticeForm = createSelector(
    [getDocument],
    document => document.isOpenTerminationNoticeForm
);

export const getTerminationAddressDetails = createSelector([getTerminationData], termination => {
    return termination && termination.addressDetails ? termination.addressDetails : '';
});
