import React, { memo } from 'react';
import '../../../sass/dashboardContentTable.scss';
import TerminationNotice from './tables/terminations/TerminationNotice';
import TerminationNoticeDraft from './tables/terminations/TerminationNoticeDraft';
import TerminationNoticeCancelled from './tables/terminations/TerminationNoticeCancelled';
import { DOC_STATUS_CANCELLED, LEASE_STATUS_DRAFT } from '../../../actions/dashboard';

const TerminationListings = ({ documentList, docType, agentSignature, docStatus }) => {
    switch (docStatus) {
        case LEASE_STATUS_DRAFT: {
            return (
                <div className="table-sort">
                    <TerminationNoticeDraft
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
        case DOC_STATUS_CANCELLED: {
            return (
                <div className="table-sort">
                    <TerminationNoticeCancelled
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
        default: {
            return (
                <div className="table-sort">
                    <TerminationNotice documentList={documentList} docType={docType} agentSignature={agentSignature} />
                </div>
            );
        }
    }
};

export default memo(TerminationListings);
