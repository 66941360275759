import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Form } from 'react-final-form';
import { forEach, keys, pickBy } from 'lodash';
import axios from 'axios';

import { Select } from '../form/Select';
import { FormEmail } from '../form/FormEmail';
import { FormPhone } from '../form/FormPhone';
import { FormText } from '../form/FormText';
import { CheckboxCheck } from '../form/FormCheckboxCheck';
import '../../sass/modalTeam.scss';
import { userRoles } from '../../config';
import {
    isAdmin,
    getRole,
    getTeamWithoutUser,
    getTeamWithUserAdded,
    isBusinessUserAccount
} from '../../utils/userUtils';
import UserPermissions from '../settings/team/UserPermissions.tsx';
import { addUserTeam, updateUserInfo } from '../../actions/joinTeam';
import { useDispatch, useSelector } from 'react-redux';
import { getIsSuperAdmin } from '../../selectors/user';
import { useAccountProvider } from '../providers/AccountProvider';
import { GENERAL_ACCOUNT_TYPE } from '../../constants/constants';
import DeleteUserConfirmModal from './DeleteUserConfirmModal';
import useToast from '../../hooks/useToast.ts';
import { ToastTypes } from '../../common/components/Toast.tsx';

const transformPermissionArray = permissionArray => {
    let newPermissions = {};
    forEach(permissionArray, value => {
        newPermissions[value] = true;
    });

    return newPermissions;
};

/**
 *
 * @param team
 * @param userRemoved
 * @param userAdded
 * @returns {*}
 */
const getFilteredTeam = (team, userRemoved, userAdded) => {
    return getTeamWithUserAdded(getTeamWithoutUser(team, userRemoved), userAdded);
};

const EDIT_TYPE = 'edit';

function ModalTeam({
    isShowModalTeam,
    type,
    closeModalTeam,
    user,
    blockUser,
    unblockUser,
    team,
    userInfo,
    agencyId,
    loggedInUser
}) {
    const dispatch = useDispatch();

    const { addNewToast } = useToast();

    const [initialValues, setInitialValues] = useState({});
    const [hasErrors, setHasErrors] = useState(false);
    const [isOpenDeleteUserModal, setIsOpenDeleteUserModal] = useState(false);
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const accountType = useAccountProvider();

    useEffect(() => {
        if (isShowModalTeam) {
            setInitialValues({
                ...user,
                isAdmin: user ? isAdmin(user.role) : false,
                hasPermissionTo: user ? transformPermissionArray(user.hasPermissionTo) : {},
                selectAll: false
            });
            setHasErrors(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowModalTeam]);

    function handleAddUserToTeam(data) {
        return axios
            .post(`/api/agency/team/invite`, data)
            .then(response => {
                setHasErrors(false);
                dispatch(addUserTeam(response.data));
                return Promise.resolve();
            })
            .catch(error => {
                if (
                    error?.response?.status === 400 &&
                    error?.response?.data?.error === 'Invite limit has been reached'
                ) {
                    addNewToast(
                        'You have reached the limit of 10 invitations for your account. Please contact support if you need to send more invites.',
                        ToastTypes.ERROR
                    );
                    return;
                }
                setHasErrors(true);
                const response = error.response;
                if (response) {
                    return response.data.errors;
                } else {
                    return error;
                }
            });
    }

    function handleAddExistingUserTeam(data) {
        return axios
            .post(`/api/agency/team/invite/existing`, data)
            .then(response => {
                setHasErrors(false);
                dispatch(addUserTeam(response.data));
                return Promise.resolve();
            })
            .catch(error => {
                setHasErrors(true);

                const response = error.response;
                if (response && response.data.errors) {
                    return response.data.errors;
                } else if (response && response.data.error) {
                    return {
                        email: response.data.error
                    };
                } else {
                    return error;
                }
            });
    }

    function handleUpdateUserInfo(userId, data) {
        return axios
            .put(`/api/agency/team/${userId}`, data)
            .then(() => {
                setHasErrors(false);
                dispatch(updateUserInfo());
                return Promise.resolve();
            })
            .catch(error => {
                setHasErrors(true);
                const response = error.response;
                return response.data.errors;
            });
    }

    function handleSubmit(values) {
        let data = {
            email: values.email,
            phone: values.phone,
            firstName: values.firstName,
            secondName: values.secondName,
            role: values.role,
            hasPermissionTo: keys(pickBy(values.hasPermissionTo))
        };

        switch (type) {
            case 'add': {
                if (accountType === GENERAL_ACCOUNT_TYPE) {
                    data.timezone = userInfo.timezone;
                }
                return handleAddUserToTeam(data, agencyId);
            }
            case 'add-existing': {
                return handleAddExistingUserTeam(data, agencyId);
            }
            case 'edit': {
                return handleUpdateUserInfo(user.id, { ...data, addToAll: values.addToAll });
            }
        }
    }

    // If user is Admin, they should not be able to delete/block agency-principal users.
    function canAdminDeleteUser(values) {
        return !(userInfo.role === userRoles.ROLE_AGENCY_ADMIN && values.role === userRoles.ROLE_AGENCY_PRINCIPAL);
    }

    function renderRole(values, setValue) {
        if (isSuperAdmin) {
            return (
                <div className="role">
                    <p className="help-text">Role</p>
                    <div className="dropdown">
                        <Select
                            name="role"
                            id="role"
                            isSearchable={false}
                            options={[
                                { value: userRoles.ROLE_AGENCY_ADMIN, label: getRole(userRoles.ROLE_AGENCY_ADMIN) },
                                {
                                    value: userRoles.ROLE_AGENCY_PRINCIPAL,
                                    label: getRole(userRoles.ROLE_AGENCY_PRINCIPAL)
                                },
                                {
                                    value: userRoles.ROLE_AGENCY_TEAMMATE,
                                    label: getRole(userRoles.ROLE_AGENCY_TEAMMATE)
                                }
                            ]}
                            onChange={selectedValue => setValue('role', selectedValue.value)}
                            defaultValue={{ value: values.role, label: getRole(values.role) }}
                        />
                    </div>
                    <br />
                    <br />
                </div>
            );
        }
        if (isAdmin && values.role !== userRoles.ROLE_AGENCY_PRINCIPAL) {
            return (
                <div className="role">
                    <p className="help-text">Role</p>
                    <div>
                        <CheckboxCheck
                            name="isAdmin"
                            onClick={value => {
                                setValue('role', value ? userRoles.ROLE_AGENCY_ADMIN : userRoles.ROLE_AGENCY_TEAMMATE);
                            }}
                            label="Admin"
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className="role">
                <p>
                    <span className="help-text">Role: </span>
                    <span>{getRole(values.role)}</span>
                </p>
                <br />
                <br />
            </div>
        );
    }

    return (
        <div>
            <Modal
                parentSelector={() => document.getElementById('main-layout')}
                ariaHideApp={false}
                isOpen={isShowModalTeam}
                style={customstyle}
                className="modal-team"
                contentLabel="Modal"
                onRequestClose={closeModalTeam}
            >
                <div className="content-modal">
                    <div onClick={closeModalTeam} className="close" />
                    <div className="name">
                        <p>{type.replace(/-/, ' ')} user</p>
                    </div>
                    <div className="form">
                        <Form
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validate={values => {
                                const errors = {};
                                if (type !== 'add-existing') {
                                    if (!values.firstName) {
                                        errors.firstName = 'Required';
                                    }
                                    if (!values.secondName) {
                                        errors.secondName = 'Required';
                                    }
                                    if (!values.phone) {
                                        errors.phone = 'Required';
                                    }
                                }

                                if (!values.email) {
                                    errors.email = 'Required';
                                }

                                return errors;
                            }}
                        >
                            {({ handleSubmit, values, form }) => {
                                return (
                                    <div className="team-modal">
                                        <form onSubmit={handleSubmit} noValidate>
                                            {type !== 'add-existing' && (
                                                <React.Fragment>
                                                    <FormText
                                                        name="firstName"
                                                        label="First Name"
                                                        validateOnTouch={true}
                                                    />
                                                    <FormText
                                                        name="secondName"
                                                        label="Last Name"
                                                        validateOnTouch={true}
                                                    />
                                                    {type === EDIT_TYPE ? (
                                                        <div className="formInput">
                                                            <p className="help-text">Email</p>
                                                            <p>{values.email}</p>
                                                        </div>
                                                    ) : (
                                                        <FormEmail name="email" label="Email" validateOnTouch={true} />
                                                    )}

                                                    {type === EDIT_TYPE ? (
                                                        <div className="formInput">
                                                            <p className="help-text">Phone</p>
                                                            <p>{values.phone}</p>
                                                        </div>
                                                    ) : (
                                                        <FormPhone name="phone" label="Phone" validateOnTouch={true} />
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {type === 'add-existing' && (
                                                <div>
                                                    <FormEmail name="email" label="Email" validateOnTouch={true} />
                                                    <p>
                                                        Please fill in the email address of the user that you want to
                                                        invite, this user will receive an email that they will need to
                                                        accept before having access to your{' '}
                                                        {isBusinessUserAccount(accountType) ? 'office' : 'agency'}.
                                                    </p>
                                                </div>
                                            )}
                                            {type !== 'add-existing' && isAdmin && (
                                                <React.Fragment>
                                                    {renderRole(values, form.change)}
                                                    <UserPermissions
                                                        user={user}
                                                        team={getFilteredTeam(team, user, userInfo)}
                                                        loggedInUser={loggedInUser}
                                                        formChange={form.change}
                                                    />
                                                </React.Fragment>
                                            )}
                                            {type === 'edit' && isAdmin && canAdminDeleteUser(values) && (
                                                <div className="edit-buttons">
                                                    <button
                                                        type="button"
                                                        className="btn-edit-user"
                                                        onClick={() => setIsOpenDeleteUserModal(true)}
                                                    >
                                                        <i className="icon-remove" />
                                                        Remove user
                                                    </button>
                                                    {user.status !== 'blocked' && (
                                                        <button
                                                            type="button"
                                                            className="btn-edit-user"
                                                            onClick={() => blockUser(user.id)}
                                                        >
                                                            <i className="icon-close" />
                                                            Block user
                                                        </button>
                                                    )}
                                                    {user.status === 'blocked' && (
                                                        <button
                                                            type="button"
                                                            className="btn-unblock-user"
                                                            onClick={() => unblockUser(user.id)}
                                                        >
                                                            Unblock user
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                            <div className="button">
                                                {['add', 'add-existing'].includes(type) && (
                                                    <button type="submit" className={'btn-send'}>
                                                        Invite
                                                    </button>
                                                )}
                                                {!['add', 'add-existing'].includes(type) && (
                                                    <button type="submit" className={'btn-save'}>
                                                        Save changes
                                                    </button>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                );
                            }}
                        </Form>
                    </div>
                </div>
                {isOpenDeleteUserModal && (
                    <DeleteUserConfirmModal
                        isOpen={isOpenDeleteUserModal}
                        setIsOpenDeleteUserModal={setIsOpenDeleteUserModal}
                        team={team}
                        user={user}
                    />
                )}
            </Modal>
        </div>
    );
}

const customstyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(148, 157, 176, 0.7)',
        zIndex: 5,
        overflow: 'hidden',
        overflowY: 'auto'
    }
};

export default ModalTeam;
