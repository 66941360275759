import React, { Component } from 'react';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import DataString from '../bloks/DataString';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';

export default class AgreementResidentialTenantsAgent extends Component {
    render() {
        const { tenantsAgent, leaseAllInfo } = this.props;

        return (
            <AgreementSection
                title="Tenant's Agent"
                step={getResTenStepNo(leaseAllInfo.location, 'tenantsAgent', leaseAllInfo.subLeaseType)}
                leaseAllInfo={this.props.leaseAllInfo}
            >
                {!tenantsAgent.doNotHaveAgent && (
                    <div className="data">
                        <p className="name">
                            {tenantsAgent.firstName} {tenantsAgent.secondName}
                        </p>
                        <div className="data-string">
                            <span className="data-contact">Ph</span>
                            <p className="text">{tenantsAgent.phone}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">E</span>
                            <p className="text">{tenantsAgent.email}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">A</span>
                            <p className="text">{tenantsAgent.address}</p>
                        </div>
                    </div>
                )}
            </AgreementSection>
        );
    }
}
