import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getInspectionDays } from '../../../selectors/lease/pmLease';

import '../../../sass/inspectionDays.scss';
import { FormTextMultiline } from '../../../components/form/FormText';

const INSPECTION_DAYS_FORM = 'inspectionDaysForm';
const initState = {
    openForInspectionText: ''
};

const InspectionDays = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxInspectionDays = useSelector(getInspectionDays);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [inspectionDays, setInspectionDays] = useState(reduxInspectionDays || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(INSPECTION_DAYS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateInspectionDays(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.inspectionDays')) {
                    return error.response.data.errors.inspectionDays;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateInspectionDays = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/inspection-days`, data);
    };
    return (
        <div className="form-container inspection-days">
            <Form onSubmit={submitForm} initialValues={inspectionDays}>
                {({ handleSubmit, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={INSPECTION_DAYS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormTextMultiline name="openForInspectionText" label="Inspection of property" />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(InspectionDays);
