import React from 'react';
import cx from 'classnames';

type DataItemProps = {
    value: any;
    isBold?: Boolean;
};

type SummaryItemProps = {
    items: DataItemProps[]
};

const SummaryItem: React.FC<SummaryItemProps> = ({ items }) => {
    return (
        <div className="client-item">
            {Array.isArray(items) && items.map((item, index) => {
                return (<p className={cx({ strong: item.isBold })} key={index}>{item.value}</p>);
            })}
        </div>
    );
};
export default SummaryItem;
