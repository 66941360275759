import React, { useState } from 'react';
import Button from '../../../../../../../common/components/Button';
import useOnClickOutside from 'use-onclickoutside';
import { PreLoaderSmall } from '../../../../../../../common/components/PreLoaderSmall';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';

import cx from 'classnames';
import styles from './MoreOptionsButton.module.scss';

function MoreOptionsButton({ label, itemList }) {
    const handleClickOutside = event => {
        // close the list when list is open and clicked outside the list
        // ignore this if user click more-action-button button
        if (!event.path[0].className.includes('more-action-button') && showActionList) {
            setShowActionList(false);
        }
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    const [showActionList, setShowActionList] = useState(false);

    return (
        <div className={styles.moreOptionButtons}>
            <Button
                className={styles.mainButton}
                type="button"
                secondary
                newSecondary
                height={40}
                onClick={() => setShowActionList(!showActionList)}
            >
                {label}
                <ExpandMoreSharpIcon />
            </Button>
            {showActionList && (
                <div className={styles.actionList} ref={ref}>
                    {itemList.map((item, index) => {
                        return (
                            <Button
                                key={index}
                                type="button"
                                onClick={() => {
                                    item.onClick(setShowActionList);
                                }}
                                disabled={item.disabled || item.isLoading}
                                secondary
                                height={35}
                                className={cx(styles.actionButton, styles[item.className])}
                            >
                                {item.isLoading && <PreLoaderSmall />}
                                {item.label}
                            </Button>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default MoreOptionsButton;
