import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { editPermissions } from '../../../utils/userPermissions';
import { tabs } from '../../../config';
import Panel from '../../../common/components/Panel';
import { FormTextRegular } from '../../../components/form/FormText';
import * as Account from '../../../reducers/account';
import { getUserInfo } from '../../../selectors/user';
import { getAgency } from '../../../selectors/settings/account';
import { getAccount, getLicenceTemplatesToUpdate } from '../../../selectors/settings/account';
import { getValidationErrors } from '../../../selectors/settings/account';
import { useEffect, useState } from 'react';
import { clearTemplatesToUpdate } from '../../../actions/account';
import { updateUsedTemplates } from '../../../actions/template';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmUpdateTemplates from '../../../containers/settings/UpdateTemplatesConfirmation';
const LEASE_PATH = 'licensee';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { getAgencyTimezoneDate } from '../../../utils/dateUtils';
import { getResTenLabel } from '../../../utils/labelUtils';
import { cloneDeep } from 'lodash';
import SaveButtonIcon from '../SaveButtonIcon';

const AgencyLicenseDetailsForm = props => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getUserInfo);
    const defaultLicenseTemplatesToUpdate = useSelector(getLicenceTemplatesToUpdate);
    const agencySelected = useSelector(getAgency);
    const [agency, setAgency] = useState(agencySelected);
    const accountState = useSelector(getAccount);
    const validationErrors = useSelector(getValidationErrors);

    const clearTemplates = () => {
        dispatch(clearTemplatesToUpdate());
    };

    const updateTemplates = () => {
        defaultLicenseTemplatesToUpdate.leasePath = LEASE_PATH;
        dispatch(updateUsedTemplates(defaultLicenseTemplatesToUpdate));
    };

    useEffect(() => {
        const clonedData = cloneDeep(agencySelected);
        if (
            props.formType === Account.AGENCY_DETAILS &&
            clonedData.licenseDetails &&
            clonedData.licenseDetails.licenceExpiry
        ) {
            clonedData.licenseDetails.licenceExpiry = new Date(clonedData.licenseDetails.licenceExpiry);
        } else if (clonedData.salesLicenseDetails && clonedData.salesLicenseDetails.licenceExpiry) {
            clonedData.salesLicenseDetails.licenceExpiry = new Date(clonedData.salesLicenseDetails.licenceExpiry);
        }
        setAgency(clonedData);
    }, [agencySelected, props.formType]);

    useEffect(() => {
        if (
            defaultLicenseTemplatesToUpdate &&
            defaultLicenseTemplatesToUpdate.templates &&
            defaultLicenseTemplatesToUpdate.templates.length > 0
        ) {
            confirmAlert({
                customUI: ConfirmUpdateTemplates(
                    defaultLicenseTemplatesToUpdate,
                    clearTemplates,
                    updateTemplates,
                    'LICENSE DETAILS'
                ),
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        }
    }, [defaultLicenseTemplatesToUpdate]);

    const submitAgencyLicenseDetails = (agencyId, values, form) => {
        //Set the dirty Fields
        values.dirtyFields = { ...form.getState().dirtyFields, leasePath: LEASE_PATH };
        props.updateLicenseDetails(agencyId, values);
    };

    const getIsPending = formType => {
        switch (formType) {
            case Account.AGENCY_DETAILS:
                return accountState.UPDATE_AGENCY_LICENSE_DETAILS_REQUEST_PENDING;
            case Account.SALES_AGENCY_DETAILS:
                return accountState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_REQUEST_PENDING;
            default:
                return false;
        }
    };

    const getIsSaved = formType => {
        switch (formType) {
            case Account.AGENCY_DETAILS:
                return accountState.UPDATE_AGENCY_LICENSE_DETAILS_SUCCESS;
            case Account.SALES_AGENCY_DETAILS:
                return accountState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_SUCCESS;
            default:
                return false;
        }
    };

    const getIsSaveFailed = formType => {
        switch (formType) {
            case Account.AGENCY_DETAILS:
                return accountState.UPDATE_AGENCY_LICENSE_DETAILS_FAIL;
            case Account.SALES_AGENCY_DETAILS:
                return accountState.UPDATE_SALES_AGENCY_LICENSE_DETAILS_FAIL;
            default:
                return false;
        }
    };

    const getSelectedDate = values => {
        if (props.formType === Account.AGENCY_DETAILS) {
            return values.licenseDetails && values.licenseDetails.licenceExpiry
                ? values.licenseDetails.licenceExpiry
                : null;
        }
        return values.salesLicenseDetails && values.salesLicenseDetails.licenceExpiry
            ? values.salesLicenseDetails.licenceExpiry
            : null;
    };

    let userRole = loggedInUser.role;
    const formFieldPrefix = props.formType === Account.AGENCY_DETAILS ? 'licenseDetails.' : 'salesLicenseDetails.';
    const isPending = getIsPending(props.formType);
    const savedSuccessfully = getIsSaved(props.formType);
    const saveFailed = getIsSaveFailed(props.formType);

    return (
        <Form
            initialValues={agency}
            onSubmit={() => {}}
            validate={() => {
                let errors = validationErrors;
                if (!errors) {
                    return;
                }
                return validationErrors;
            }}
        >
            {({ handleSubmit, values, form }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Panel
                            title="License Details"
                            rightButton={
                                <div>
                                    {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                        <SaveButtonIcon
                                            isSaving={isPending}
                                            success={savedSuccessfully}
                                            failure={saveFailed}
                                            onClick={() =>
                                                submitAgencyLicenseDetails(
                                                    agency.id,
                                                    props.formType === Account.AGENCY_DETAILS
                                                        ? values.licenseDetails
                                                        : values.salesLicenseDetails,
                                                    form
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            }
                        >
                            <FormTextRegular
                                name={formFieldPrefix + 'corporationLicenseNumber'}
                                label={getResTenLabel('licenceNumber', props.defaultState)}
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormTextRegular
                                name={formFieldPrefix + 'licenseInCharge'}
                                label="Licensee in charge (name)"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormTextRegular
                                name={formFieldPrefix + 'licenseNumber'}
                                label="Licensee number"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <DatePickerInAgencyTimeZone
                                label="Licence Expiry (QLD only)"
                                name={formFieldPrefix + 'licenceExpiry'}
                                selected={getSelectedDate(values)}
                                minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                            />
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};

export default AgencyLicenseDetailsForm;
