import React, { Component } from 'react';
import { getPmStepNo, getPmStepTitle } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import ThumbDisplay from '../../../image/ThumbDisplay';
import { getLabel } from '../../../../../utils/labelUtils';
import { LEASE_TYPE_PROPERTY_MANAGEMENT } from '../../../../../config';

export default class AgreementPmOutgoings extends Component {
    render() {
        const { pmOutgoings, pmLandlord } = this.props.leaseAllInfo;

        if (!pmOutgoings) {
            return null;
        }
        let primaryLandlord = pmLandlord.persons.find((landlord) => landlord.isPrimaryLandlord);
        const selectedOutgoings = Object.keys(pmOutgoings)
            .map((key) => {
                const { outGoingDescription, enabled } = pmOutgoings[key];
                return {
                    outGoingDescription,
                    enabled,
                };
            })
            .filter((i) => i.enabled === true)
            .concat(pmOutgoings.otherOutgoings);

        return (
            <AgreementSection
                title={getPmStepTitle(this.props.leaseAllInfo.location, 'pmOutgoings')}
                className="section-payment"
                step={getPmStepNo(this.props.leaseAllInfo.location, 'pmOutgoings')}
                leaseAllInfo={this.props.leaseAllInfo}
            >
                <div className="data-container column">
                    <p className="text">{`${getLabel(
                        'landlord',
                        this.props.leaseAllInfo.location,
                        LEASE_TYPE_PROPERTY_MANAGEMENT
                    )} would like the agent to pay the following outgoings:`}</p>
                    {Array.isArray(primaryLandlord?.pmOutGoingsAnswers?.questions) &&
                        primaryLandlord.pmOutGoingsAnswers.questions.map((answer, index) => {
                            if (answer.answer === 'true') {
                                return (
                                    <div key={index}>
                                        <p>{answer.questionText}</p>
                                        <p>
                                            Instructions: <b>{answer.details ? answer.details : 'Nil'}</b>
                                        </p>
                                        <div>
                                            {answer.image && (
                                                <ThumbDisplay
                                                    imageSrc={answer.image}
                                                    imageTitle={answer.questionText}
                                                />
                                            )}
                                        </div>
                                        <br />
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index}>
                                        <p>
                                            <strike>{answer.questionText}</strike>
                                        </p>
                                        <br />
                                    </div>
                                );
                            }
                        })}
                    {primaryLandlord &&
                        !primaryLandlord.pmOutGoingsAnswers &&
                        selectedOutgoings.map((outgoing, index) => {
                            return <p key={index}>{outgoing.outGoingDescription}</p>;
                        })}
                </div>
            </AgreementSection>
        );
    }
}
