import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import * as PropTypes from 'prop-types';
import MessageNotSent from '../../../../../../assets/images/icons/send/MessageNotSent.svg';
import MessageSendingFailed from '../../../../../../assets/images/icons/send/MessageSendingFailed.svg';
import { memo } from 'react';
import { getResponse } from '../../../../../utils/renewalUtils';

const RenewalIntentionIndicator = ({ renewalIntention, prefix }) => {
    return (
        <div className="renewal-intention-container">
            {!renewalIntention && (
                <React.Fragment>
                    <span
                        className={`renewal-status renewal-status-not-sent`}
                        data-tip={true}
                        data-for="explanation-not-sent"
                    >
                        <img src={MessageNotSent} alt="Not sent" />
                    </span>
                    <ReactTooltip
                        id="explanation-not-sent"
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>No renewal intention was sent</p>
                    </ReactTooltip>
                </React.Fragment>
            )}
            {renewalIntention && !renewalIntention.responseReceivedAt && (
                <React.Fragment>
                    <span
                        className={`renewal-status renewal-status-light renewal-status-pending`}
                        data-tip={true}
                        data-for={`explanation-pending-${prefix}-${renewalIntention.id}`}
                    />
                    <ReactTooltip
                        id={`explanation-pending-${prefix}-${renewalIntention.id}`}
                        class="tooltip"
                        place="top"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        <p>{getResponse(renewalIntention)}</p>
                    </ReactTooltip>
                </React.Fragment>
            )}
            {renewalIntention &&
                renewalIntention.responseReceivedAt &&
                renewalIntention.isTenantRenewing === 'no-response' && (
                    <React.Fragment>
                        <span
                            className={`renewal-status renewal-status-no-response`}
                            data-tip={true}
                            data-for={`explanation-no-response-${prefix}-${renewalIntention.id}`}
                        >
                            <img src={MessageSendingFailed} alt="Message sending failed" />
                        </span>
                        <ReactTooltip
                            id={`explanation-no-response-${prefix}-${renewalIntention.id}`}
                            class="tooltip"
                            place="top"
                            effect="solid"
                            globalEventOff="wheel"
                        >
                            <p>{getResponse(renewalIntention)}</p>
                        </ReactTooltip>
                    </React.Fragment>
                )}
            {renewalIntention &&
                renewalIntention.responseReceivedAt &&
                renewalIntention.isTenantRenewing !== 'no-response' && (
                    <React.Fragment>
                        {
                            <React.Fragment>
                                <span
                                    className={`renewal-status renewal-status-light renewal-status-${renewalIntention.isTenantRenewing}`}
                                    data-tip={true}
                                    data-for={`explanation-${renewalIntention.isTenantRenewing}-${prefix}-${renewalIntention.id}`}
                                />

                                {renewalIntention.isTenantRenewing === 'no' && (
                                    <ReactTooltip
                                        id={`explanation-no-${prefix}-${renewalIntention.id}`}
                                        class="tooltip"
                                        place="top"
                                        effect="solid"
                                        globalEventOff="wheel"
                                    >
                                        <p>{getResponse(renewalIntention)}</p>
                                    </ReactTooltip>
                                )}

                                {renewalIntention.isTenantRenewing === 'yes' && (
                                    <ReactTooltip
                                        id={`explanation-yes-${prefix}-${renewalIntention.id}`}
                                        class="tooltip"
                                        place="top"
                                        effect="solid"
                                        globalEventOff="wheel"
                                    >
                                        <p>{getResponse(renewalIntention)}</p>
                                    </ReactTooltip>
                                )}

                                {renewalIntention.isTenantRenewing === 'unsure' && (
                                    <ReactTooltip
                                        id={`explanation-unsure-${prefix}-${renewalIntention.id}`}
                                        class="tooltip"
                                        place="top"
                                        effect="solid"
                                        globalEventOff="wheel"
                                    >
                                        <p>{getResponse(renewalIntention)}</p>
                                    </ReactTooltip>
                                )}
                            </React.Fragment>
                        }
                    </React.Fragment>
                )}
        </div>
    );
};

RenewalIntentionIndicator.propTypes = { renewalIntention: PropTypes.object };

export default memo(RenewalIntentionIndicator);
