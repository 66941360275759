import React from 'react';
import { FormMaskedText, FormTextRegular } from '../../../components/form/FormText';
import { FormPhone } from '../../../components/form/FormPhone';
import { FormButtonSelect } from '../../../components/form/responsive/FormButtonSelect';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import ExtraContacts from '../../../components/lease/extraContacts/ExtraContacts';
import { leseeCorporationRolesList } from '../../../config';

function LesseeInputFields({ index, person, values }) {
    if (values.isCorporation) {
        return (
            <React.Fragment>
                <FormTextRegular name={`persons[${index}].name`} label={'Full Name'} required />
                <React.Fragment>
                    <FormButtonSelect
                        name={`persons[${index}].role`}
                        selectClassName={`person-${index}-role`}
                        options={leseeCorporationRolesList}
                        value={person.role}
                        label="Role"
                        required
                        dataTest={`persons[${index}]-role`}
                    />
                    {person.role === 'Other' && (
                        <FormTextRegular name={`persons[${index}].otherRole`} label="Role name" required />
                    )}
                </React.Fragment>
                <FormPhone
                    dataTest={`persons[${index}]-phone`}
                    name={`persons[${index}].phone`}
                    label="Phone"
                    required
                />
                <FormTextRegular name={`persons[${index}].email`} label="Email" required />
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <div className="nowrap fields-full-width-for-mobile">
                <FormTextRegular name={`persons[${index}].firstName`} label="First Name" required />
                <FormTextRegular name={`persons[${index}].secondName`} label="Last Name" required />
            </div>
            <FormTextRegular name={`persons[${index}].address`} label="Address" required />
            <FormPhone dataTest={`persons[${index}]-phone`} name={`persons[${index}].phone`} label="Phone" required />
            <FormTextRegular name={`persons[${index}].email`} label="Email" required />
            <FormMaskedText
                dataTest={`persons[${index}]-abn`}
                name={`persons[${index}].abn`}
                label="ABN"
                mask="11 111 111 111"
            />
            <FormMaskedText
                dataTest={`persons[${index}]-acn`}
                name={`persons[${index}].acn`}
                label="ACN"
                mask="111 111 111"
            />
            <CheckboxCheck name={`persons[${index}].isRegisteredForGST`} label="Registered for GST" />
        </React.Fragment>
    );
}

export default function LesseeFields({ index, person, values, push }) {
    const { extraContacts } = person;
    return (
        <>
            <LesseeInputFields index={index} person={person} values={values} push={push} />
            <ExtraContacts
                name={`persons[${index}].extraContacts`}
                initialValue={extraContacts}
                push={push}
                index={index}
                blockType="persons"
            />
        </>
    );
}
