import React, { Component } from 'react';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { LEASE_STATUS_DRAFT } from '../../../../../actions/dashboard';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';
import { getResTenLabel } from '../../../../../utils/labelUtils';

class AgreementResidentialInclusionsOccupants extends Component {
    render() {
        const { leaseAllInfoInclusions, leaseAllInfoOccupants, leaseStatus, leaseAllInfo } = this.props;

        return (
            <div>
                {(!!leaseAllInfoInclusions || leaseStatus === LEASE_STATUS_DRAFT) && (
                    <AgreementSection
                        title="Inclusions"
                        step={getResTenStepNo(leaseAllInfo.location, 'inclusions', leaseAllInfo.subLeaseType)}
                        leaseAllInfo={this.props.leaseAllInfo}
                    >
                        <div>
                            {leaseAllInfoInclusions && leaseAllInfoInclusions.inclusions && (
                                <p className="text">List of inclusions: {leaseAllInfoInclusions.inclusions}.</p>
                            )}
                            <p className="text">
                                This property is:
                                <span className="bold">
                                    {leaseAllInfoInclusions && leaseAllInfoInclusions.isFurnished
                                        ? ' furnished'
                                        : ' not furnished'}
                                </span>
                            </p>
                        </div>
                    </AgreementSection>
                )}
                <AgreementSection
                    title={getResTenLabel('maximumNumberOfOccupants', leaseAllInfo.location)}
                    step={getResTenStepNo(leaseAllInfo.location, 'maxOccupants')}
                    leaseAllInfo={this.props.leaseAllInfo}
                >
                    {leaseAllInfoOccupants && (
                        <div className="text">
                            No more than <span className="bold">{leaseAllInfoOccupants.maxOccupants}</span>{' '}
                            {leaseAllInfoOccupants.maxOccupants > 1 ? 'persons ' : 'person '}
                            may ordinarily live in the premises at any one time.
                            <ol className="name-list">
                                {leaseAllInfoOccupants.occupantsList.map((occupant, index) => {
                                    return <li key={index}>{occupant.name}</li>;
                                })}
                            </ol>
                        </div>
                    )}
                </AgreementSection>
            </div>
        );
    }
}

export default AgreementResidentialInclusionsOccupants;
