import React from "react";
import ReactSelectAsync, {
    Props as ReactSelectAsyncProps
} from "react-select/async";
import cx from "classnames";

// Augment ReactSelectAsyncProps
// The generic types are used for ReactSelectAsyncProps internal props
type AsyncSelectFieldProps<OptionType, isMulti extends boolean> = {
    selectClassName?: string;
    label?: string;
} & ReactSelectAsyncProps<OptionType, isMulti>;

export const AsyncSelect = React.forwardRef(
    // Here we specify some basic types that fulfil the ReactSelectAsyncProps criteria
    // Specifying types like this allows us to have more informative generic types when using the async select component
    <OptionType extends object, isMulti extends boolean>(
        {
            selectClassName,
            containerClassName,
            label,
            ...selectProps
        }: AsyncSelectFieldProps<OptionType, isMulti>,
        ref
    ) => {
        return (
            <div className={cx("FormInput", containerClassName)} data-for={selectProps['data-for']} data-tip={selectProps['data-tip']}>
                <div className="form-group">
                    {label && <label>{label}</label>}
                    <ReactSelectAsync
                        ref={ref}
                        className={cx("form-select", selectClassName)}
                        classNamePrefix={"react-select"}
                        {...selectProps}
                    />
                </div>
            </div >
        );
    }
);
