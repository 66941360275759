import React, { useState } from 'react';
import styles from './BreachReasons.module.scss';
import Button from '../../../../common/components/Button.js';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck.js';
import { FormTextHidden } from '../../../../components/form/FormText.js';

import cx from 'classnames';
import Icon, { Icons } from '../../../../common/components/Icon';

type BreachReasonsProps = {
    values: object;
    disabled?: boolean;    
    onSelectBreachReason: (item: string, values: object, selected: boolean) => void;
};

export const getBreachNoticeDescription = (value: string) => {
    switch (value) {
        case "toNonPaymentOfRent": return {
            title: 'Non-payment of rent',
            description: 'Non-payment of Rent – Failure to make rental payments in breach of clause 8 of the Standard Terms in the General Tenancy Agreement and Sections 83 and 85 of the Residential Tenancies and Rooming Accommodation Act (QLD) 2008.'
        };
        case "toUsingPremisesForIllegalPurpose": return {
            title: 'Using premises for illegal purpose',
            description: 'Using premises for illegal purpose – Using the premises for illegal purposes in breach of clause 21 of the Standard Terms of the General Tenancy Agreement and Sections 10 and 184 of the Residential Tenancies and Rooming Accommodation Act (QLD) 2008.'
        };
        case "toKeepingPetWithoutPermission": return {
            title: 'Keeping a pet without permission',
            description: 'Keeping a pet without permission – Keeping a pet without the permission of the Lessor in breach of clause 33A of the Standard Terms of the General Tenancy Agreement and Section 184B of the Residential Tenancies and Rooming Accommodation Act 2008 QLD.'
        };
        case "toMoreTenantsInProperty": return {
            title: 'More tenants in property than stated on agreement',
            description: 'More tenants in property than stated on agreement – Additional tenants occupying the premises without the consent of the Lessor in breach of clause 7 of the Special Terms of the General Tenancy Agreement.'
        };
        case "toNotKeepingThePropertyInGoodCondition": return {
            title: 'Not keeping the property in good condition',
            description: 'Not keeping the property in good condition – Not keeping the property in good condition in breach of clause 26 of the Standard Terms of the General Tenancy Agreement and Section 188 of the Residential Tenancies and Rooming Accommodation Act (QLD) 2008.'
        };
        case "toLocalGovLawsNotMet": return {
            title: 'Local government laws not met (i.e. hazardous material etc)',
            description: 'Local government laws not met (i.e. hazardous material etc) – Breach of Local Government Laws.'
        };
        case "toUseOfPropertyCausesANuisance": return {
            title: 'Use of property causes a nuisance to neighbours',
            description: 'Use of property causes a nuisance to neighbours – Use of property causing a nuisance to neighbours in breach of Sections 10 and 184 of the Residential Tenancies and Rooming Accommodation Act (QLD) 2008 and the Clause 21 of the Standard Terms of the General Tenancy Agreement.'
        };
        case "toOngoingNeighbourDisputes": return {
            title: 'Ongoing neighbour disputes',
            description: 'Ongoing neighbour disputes - Ongoing neighbour disputes in breach of clause 21 of the Standard Terms of the General Tenancy Agreement and Section 10 and 184 of the Residential Tenancies and Rooming Accommodation Act (QLD) 2008.'
        };
        case "toOther": return { 
            title:'Other',
            description: 'Other'
        };
        default: return '';
    };
}

const BreachReasons = ({ values, disabled, onSelectBreachReason }: BreachReasonsProps) => {
    const [showAll, setShowAll] = useState(false);

    return (
        <div className={styles.breachReasons}>
            <div className={styles.selectGroup}>
                <Button
                    className={cx(styles.selectButton, { [styles.inactive]: !showAll})}
                    onClick={() => setShowAll(!showAll)}
                    endIcon={
                        <Icon className={cx(styles.icon, { [styles.expanded]: showAll })} icon={Icons.CHEVRON_DOWN} />
                    }
                    secondary
                    disabled={disabled}
                >
                    Select breach*
                </Button>
                <FormTextHidden name="reasonForBreachErrorField" showError />
            </div>
            <div className={styles.checkboxGroup}>
                {(showAll || values.toNonPaymentOfRent) && (
                    <CheckboxCheck
                        containerClassName={cx(styles.checkBoxContainer, styles.margin16)}
                        name="toNonPaymentOfRent"
                        label={<p>{getBreachNoticeDescription("toNonPaymentOfRent").title}</p>}
                        className={cx(styles.checkbox, {[styles.active]: values.topNonPaymentOfRent})}
                        disabled={disabled}
                        onClick={() => onSelectBreachReason("toNonPaymentOfRent", values, !values.toNonPaymentOfRent)}
                    />
                )}
                {(showAll || values.toUsingPremisesForIllegalPurpose) && (
                    <CheckboxCheck
                        containerClassName={cx(styles.checkBoxContainer, styles.margin16)}
                        name="toUsingPremisesForIllegalPurpose"
                        label={<p>{getBreachNoticeDescription("toUsingPremisesForIllegalPurpose").title}</p>}
                        className={cx(styles.checkbox, {[styles.active]: values.toUsingPremisesForIllegalPurpose})}
                        disabled={disabled}
                        onClick={() => onSelectBreachReason("toUsingPremisesForIllegalPurpose", values, !values.toUsingPremisesForIllegalPurpose)}
                    />
                )}
                {(showAll || values.toKeepingPetWithoutPermission) && (
                    <CheckboxCheck
                        containerClassName={cx(styles.checkBoxContainer, styles.margin16)}
                        name="toKeepingPetWithoutPermission"
                        label={<p>{getBreachNoticeDescription("toKeepingPetWithoutPermission").title}</p>}
                        className={cx(styles.checkbox, {[styles.active]: values.toKeepingPetWithoutPermission})}
                        disabled={disabled}
                        onClick={() => onSelectBreachReason("toKeepingPetWithoutPermission", values, !values.toKeepingPetWithoutPermission)}
                    />
                )}
                {(showAll || values.toMoreTenantsInProperty) && (
                    <CheckboxCheck
                        containerClassName={cx(styles.checkBoxContainer, styles.margin16)}
                        name="toMoreTenantsInProperty"
                        label={<p>{getBreachNoticeDescription("toMoreTenantsInProperty").title}</p>}
                        className={cx(styles.checkbox, {[styles.active]: values.toMoreTenantsInProperty})}
                        disabled={disabled}
                        onClick={() => onSelectBreachReason("toMoreTenantsInProperty", values, !values.toMoreTenantsInProperty)}
                    />
                )}
                {(showAll || values.toNotKeepingThePropertyInGoodCondition) && (
                    <CheckboxCheck
                        containerClassName={cx(styles.checkBoxContainer, styles.margin16)}
                        name="toNotKeepingThePropertyInGoodCondition"
                        label={<p>{getBreachNoticeDescription("toNotKeepingThePropertyInGoodCondition").title}</p>}
                        className={cx(styles.checkbox, {[styles.active]: values.toNotKeepingThePropertyInGoodCondition})}
                        disabled={disabled}
                        onClick={() => onSelectBreachReason("toNotKeepingThePropertyInGoodCondition", values, !values.toNotKeepingThePropertyInGoodCondition)}
                    />
                )}
                {(showAll || values.toLocalGovLawsNotMet) && (
                    <CheckboxCheck
                        containerClassName={cx(styles.checkBoxContainer, styles.margin16)}
                        name="toLocalGovLawsNotMet"
                        label={<p>{getBreachNoticeDescription("toLocalGovLawsNotMet").title}</p>}
                        className={cx(styles.checkbox, {[styles.active]: values.toLocalGovLawsNotMet})}
                        disabled={disabled}
                        onClick={() => onSelectBreachReason("toLocalGovLawsNotMet", values, !values.toLocalGovLawsNotMet)}
                    />
                )}
                {(showAll || values.toUseOfPropertyCausesANuisance) && (
                    <CheckboxCheck
                        containerClassName={cx(styles.checkBoxContainer, styles.margin16)}
                        name="toUseOfPropertyCausesANuisance"
                        label={<p>{getBreachNoticeDescription("toUseOfPropertyCausesANuisance").title}</p>}
                        className={cx(styles.checkbox, {[styles.active]: values.toUseOfPropertyCausesANuisance})}
                        disabled={disabled}
                        onClick={() => onSelectBreachReason("toUseOfPropertyCausesANuisance", values, !values.toUseOfPropertyCausesANuisance)}
                    />
                )}
                {(showAll || values.toOngoingNeighbourDisputes) && (
                    <CheckboxCheck
                        containerClassName={cx(styles.checkBoxContainer, styles.margin16)}
                        name="toOngoingNeighbourDisputes"
                        label={<p>{getBreachNoticeDescription("toOngoingNeighbourDisputes").title}</p>}
                        className={cx(styles.checkbox, {[styles.active]: values.toOngoingNeighbourDisputes})}
                        disabled={disabled}
                        onClick={() => onSelectBreachReason("toOngoingNeighbourDisputes", values, !values.toOngoingNeighbourDisputes)}
                    />
                )}
                {(showAll || values.toOther) && (
                    <CheckboxCheck
                        containerClassName={cx(styles.checkBoxContainer, styles.margin16)}
                        name="toOther"
                        label={<p>{getBreachNoticeDescription("toOther").title}</p>}
                        className={cx(styles.checkbox, {[styles.active]: values.toOther})}
                        disabled={disabled}
                        onClick={() => onSelectBreachReason("toOther", values, !values.toOther)}
                    />
                )}
            </div>
        </div>
    );
};

export default BreachReasons;
