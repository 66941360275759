import React from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import Button from "../Button";
import { Lens as Circle, ChevronLeft, ChevronRight } from "@material-ui/icons";
import cx from "classnames";
import styles from "./Carousel.module.scss";

const DEFAULT_CAROUSEL_OPTIONS: Partial<EmblaOptionsType> = {
    align: "center",
    inViewThreshold: 1
};

type CarouselProps = {
    /** Carousel label for screenreader users (e.g. subscription plan carousel)*/
    ariaLabel: string;
    children: JSX.Element[];
    /** Classname for the carousel contaienr */
    className?: string;
    /** Classname for the slide container. Useful for setting the width of slides with grid-auto-columns. Look at AgencySignUp.module.scss for an example */
    slideContainerClassName?: string;
    /** Adds navigation dots below the slides when true. */
    navigationDots?: boolean;
    /** Adds back/forward buttons below the slides when true */
    navigationButtons?: boolean;
    carouselOptions: Partial<EmblaOptionsType>;
};

/** Carousel component with optional navigation buttons. */
const Carousel: React.FC<CarouselProps> = ({
    ariaLabel,
    children,
    className,
    navigationDots,
    navigationButtons,
    slideContainerClassName,
    carouselOptions
}) => {
    const [carouselRef, carousel] = useEmblaCarousel({
        ...DEFAULT_CAROUSEL_OPTIONS,
        ...carouselOptions
    });

    const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [isDragging, setIsDragging] = React.useState(false);

    const allSlidesInView = () => carousel?.slidesNotInView().length === 0;

    const onSelect = React.useCallback(() => {
        if (!carousel) return;
        setSelectedIndex(carousel.selectedScrollSnap());
    }, [carousel, setSelectedIndex]);

    React.useEffect(() => {
        const onPointerDown = () => {
            if (!allSlidesInView()) {
                setIsDragging(true);
            }
        };

        const onPointerUp = () => {
            setIsDragging(false);
        };

        if (!carousel) return;
        carousel.on("pointerDown", onPointerDown);
        carousel.on("pointerUp", onPointerUp);

        return () => {
            carousel.off("pointerDown", onPointerDown);
            carousel.off("pointerUp", onPointerUp);
        };
    });

    React.useEffect(() => {
        if (!carousel) return;
        carousel.reInit();
        setScrollSnaps(carousel.scrollSnapList());
        carousel.on("select", onSelect);

        // Unsubscribe from carousel event
        return () => {
            carousel.off("select", onSelect);
        };
    }, [carousel, setScrollSnaps, onSelect, children.length]);

    return (
        <div
            className={cx(styles.carouselContainer, className, {
                [styles.dragging]: isDragging
            })}
            role="region"
            aria-label={ariaLabel}
        >
            <div className={styles.carousel} ref={carouselRef}>
                <div
                    className={cx(
                        styles.slidesContainer,
                        slideContainerClassName
                    )}
                >
                    {children.map((child, index) => (
                        <div
                            key={index}
                            className={styles.slide}
                            role="group"
                            aria-label={`slide ${index + 1} of ${
                                children.length
                            }`}
                            aria-hidden={`${index !== selectedIndex}`}
                        >
                            {child}
                        </div>
                    ))}
                </div>
            </div>
            {navigationDots && (
                <div className={styles.navigationDots}>
                    {scrollSnaps.map((_, index) => (
                        <Button
                            key={index}
                            aria-current={`index === selectedIndex`}
                            className={cx(styles.navigationDot, {
                                [styles.selected]: index === selectedIndex
                            })}
                            onClick={() => carousel?.scrollTo(index)}
                        >
                            <Circle className={styles.indicator} />
                        </Button>
                    ))}
                </div>
            )}
            {navigationButtons && !allSlidesInView() && (
                <div className={styles.navigationButtons}>
                    <Button
                        onClick={() => carousel?.scrollPrev()}
                        disabled={selectedIndex === 0}
                        className={styles.navigationButton}
                    >
                        <ChevronLeft className={styles.navigationIcon} />
                    </Button>
                    <Button
                        onClick={() => carousel?.scrollNext()}
                        disabled={selectedIndex + 1 === scrollSnaps.length}
                        className={styles.navigationButton}
                    >
                        <ChevronRight className={styles.navigationIcon} />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Carousel;
