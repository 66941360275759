import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { ContactServiceExtraContacts } from '../../../components/lease/mainScreen/common/ContactServiceExtraContacts';

import styles from './ExtraContacts.module.scss';
import cx from 'classnames';

function ExtraContacts({ initialValue, push, blockType, index, name, disabled }) {
    const [addModal, setAddModal] = useState(false);

    const addMethod = type => {
        let extraContact = {
            contactValue: '',
            contactType: type
        };
        push(name, extraContact);
        setAddModal(!addModal);
    };
    return (
        <>
            <FieldArray name={name} initialValue={initialValue}>
                {({ fields }) => (
                    <React.Fragment>
                        {fields.map((name, fieldsIndex) => {
                            return (
                                <ContactServiceExtraContacts
                                    key={fieldsIndex}
                                    index={index}
                                    indexKey={fieldsIndex}
                                    blockType={blockType}
                                    extraContact={fields.value[fieldsIndex]}
                                    removeContact={() => fields.remove(fieldsIndex)}
                                    disabled={disabled}
                                />
                            );
                        })}
                    </React.Fragment>
                )}
            </FieldArray>
            {!disabled && (
                <div className={styles.addMoreButtonGroup}>
                    <button className={styles.actionButton} type="button" onClick={() => setAddModal(!addModal)}>
                        Add more contact details
                    </button>
                    <div className={cx(styles.addModal, { [styles.hide]: !addModal })}>
                        <div className={styles.chooseItems}>
                            <div className={styles.choose} onClick={() => addMethod('Other')}>
                                Other
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ExtraContacts;
