import React, { memo, useState } from 'react';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import cx from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import * as dashboard from '../../actions/dashboard';
import '../../sass/popupLeaseMore.scss';
import { EventSharp } from '@material-ui/icons';

const PopupFlkAKeyMore = props => {
    const handleClickOutside = () => {
        props.closeMorePopupList();
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    const { keyStatus, morePopupList } = props;

    const cloneDocument = e => {
        e.stopPropagation();
        props.cloneDocument();
    };

    const deleteDocument = e => {
        e.stopPropagation();
        props.deleteDocument();
    };

    return (
        <div ref={ref} className={cx(morePopupList ? 'popup-draft-create create-list' : 'popup-draft-create hide')}>
            {keyStatus === dashboard.FLK_A_KEY_STATUS_CHECKED_OUT && (
                <div>
                    <div>
                        <div className="icon-block" onClick={e => props.edit(e)}>
                            <EditSharpIcon className="icon icon-edit-tenant" />
                            <p className="popup-draft-p">Edit mode</p>
                        </div>
                    </div>
                    <div className="icon-block" onClick={e => props.checkIn(e)}>
                        <i className="icon icon-check-in" />
                        <p className="popup-draft-p">Check in</p>
                    </div>
                </div>
            )}
            {keyStatus === dashboard.FLK_A_KEY_STATUS_OVERDUE && (
                <div>
                    <div>
                        <div className="icon-block" onClick={e => props.edit(e)}>
                            <EditSharpIcon className="icon icon-edit-tenant" />
                            <p className="popup-draft-p">Edit mode</p>
                        </div>
                    </div>
                    <div className="icon-block" onClick={e => props.checkIn(e)}>
                        <i className="icon icon-check-in" />
                        <p className="popup-draft-p">Check in</p>
                    </div>
                    <div className="icon-block" onClick={e => props.sendReminder(e)}>
                        <EventSharp className="icon" />
                        <p className="popup-draft-p">Request update</p>
                    </div>
                </div>
            )}
            {keyStatus === dashboard.FLK_A_KEY_STATUS_RETURNED && (
                <div>
                    <div className="icon-block" onClick={e => props.checkOut(e)}>
                        {props.isLoading && <span className="blue-spinner-inline" />}
                        {!props.isLoading && <i className="icon icon-check-in" />}
                        <p className="popup-draft-p">Check out</p>
                    </div>
                </div>
            )}
            {keyStatus === dashboard.FLK_A_KEY_STATUS_DRAFT && (
                <div>
                    <div className="icon-block" onClick={e => cloneDocument(e)}>
                        <i className="icon icon-duplicate" />
                        <p className="popup-draft-p">Clone document</p>
                        {props.isLoading && <span className="blue-spinner-inline" />}
                    </div>
                    <div className="icon-block" onClick={e => deleteDocument(e)}>
                        <i className="icon icon-cancel-sign" />
                        <p className="popup-draft-p">Delete document</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(PopupFlkAKeyMore);
