import { OPEN_RENT_INCREASE_LEASE_LIST_MODAL, CLOSE_RENT_INCREASE_LEASE_LIST_MODAL } from '../actions/types';

import produce from 'immer';

const initState = {
    isRentIncreaseLeaseListModalOpen: false
};

export default function search(state = initState, action) {
    switch (action.type) {
        case OPEN_RENT_INCREASE_LEASE_LIST_MODAL: {
            return produce(state, draftState => {
                draftState.isRentIncreaseLeaseListModalOpen = true;
            });
        }

        case CLOSE_RENT_INCREASE_LEASE_LIST_MODAL: {
            return produce(state, draftState => {
                draftState.isRentIncreaseLeaseListModalOpen = false;
            });
        }
    }

    return state;
}
