import React from 'react';
import { FormTextRegular } from '../../../../form/FormText';

export const FeeTypeGroup = ({ index, removeFee }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Fee Type {index + 1}</h3>
                    <div className="toggle" onClick={() => removeFee(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular name={`additionalFeesList[${index}].description`} label="Description" required />
                    <div className="fee-item">
                        <div className="nowrap fields-full-width-for-mobile">
                            <FormTextRegular name={`additionalFeesList[${index}].amount`} label="Amount" required />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
