import React, { Component } from 'react';
import AwaitingCompletion from '../awaitingCompletion/AwaitingCompletion';
import AgreementSignatures from '../../../../containers/dashboard/AgreementSignatures';
import SalesAgreementSignatures from '../../../../containers/dashboard/SalesAgreementSignatures';
import SignorsTab from './SignorsTab';
import {
    LEASE_TYPE_RESIDENTIAL_DISPLAY,
    LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY,
    LEASE_TYPE_SALES_DISPLAY,
    NSW_STATE
} from '../../../../config';
import AgreementPmClientInstructions from './pm/AgreementPmClientInstructions';
import AgreementSalesClientInstructions from './sales/AgreementSalesClientInstructions';
import AgreementResidentialComplianceChecklist from './residential/AgreementResidentialComplianceChecklist.tsx';

import { AgreementResidentialPropertyTreeSync } from './residential/AgreementResidentialPropertyTreeSync';
import { IntegrationType } from '../../../../constants/constants';
import { RTA_PROPERTY_TREE_SYNC } from '../../../../constants/featureFlags';
import { LEASE_STATUS_AWAITING_COMPLETION } from '../../../../actions/dashboard';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

const AgreementMainCompletion = ({ leaseType, leaseAllInfo }) => {
    const rtaPropertyTreeSyncEnabled = useFeatureFlag(RTA_PROPERTY_TREE_SYNC);
    const showPropertyTreeIntegration =
        rtaPropertyTreeSyncEnabled &&
        leaseType === LEASE_TYPE_RESIDENTIAL_DISPLAY &&
        leaseAllInfo.status === LEASE_STATUS_AWAITING_COMPLETION &&
        leaseAllInfo?.integration?.active &&
        leaseAllInfo?.integration?.type === IntegrationType.PROPERTY_TREE;

    return (
        <div>
            {leaseType === LEASE_TYPE_RESIDENTIAL_DISPLAY && (
                <>
                    <AgreementSignatures location={leaseAllInfo.location} />
                    <AgreementResidentialComplianceChecklist leaseAllInfo={leaseAllInfo} />
                </>
            )}
            {leaseType === LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY && leaseAllInfo.location === NSW_STATE && (
                <AgreementPmClientInstructions leaseAllInfo={leaseAllInfo} />
            )}
            {leaseType === LEASE_TYPE_PROPERTY_MANAGEMENT_DISPLAY && <SignorsTab leaseAllInfo={leaseAllInfo} />}

            {leaseType === LEASE_TYPE_SALES_DISPLAY && <SalesAgreementSignatures />}
            {leaseType === LEASE_TYPE_SALES_DISPLAY && leaseAllInfo.location === NSW_STATE && (
                <AgreementSalesClientInstructions leaseAllInfo={leaseAllInfo} />
            )}
            {leaseAllInfo.hydrated && <AwaitingCompletion leaseAllInfo={leaseAllInfo} />}
            {showPropertyTreeIntegration && <AgreementResidentialPropertyTreeSync leaseId={leaseAllInfo.id} />}
        </div>
    );
};

export default AgreementMainCompletion;
