import { createSelector } from 'reselect';

const getSubscriptionFromPlan = plan =>
    plan
        ? {
              id: plan.stripeId,
              duration: 'monthly',
              size: plan.title,
              price: plan.price,
              hasFreeTrial: plan.hasFreeTrial,
              constraintText: plan.constraintText,
              additionalConstraintText: plan.additionalConstraintText,
              featureCategories: plan.featureCategories,
              taxDescription: plan.taxDescription,
              currency: plan.currency
          }
        : undefined;

export const getAuthorization = state => state.authorization;

export const getAccessToken = createSelector([getAuthorization], auth => auth.accessToken);
export const getRefreshToken = createSelector([getAuthorization], auth => auth.refreshToken);
export const getAgencyId = createSelector([getAuthorization], auth => auth.agencyId);
export const getCouponInfo = createSelector([getAuthorization], auth => auth.couponInfo);
export const getIsRegistrationComplete = createSelector([getAuthorization], auth => auth.isRegistrationComplete);
export const getPlans = createSelector([getAuthorization], auth => auth.plans);
export const getSubscriptions = createSelector([getPlans], plans => plans.map(getSubscriptionFromPlan));
export const getHiddenPlans = createSelector([getAuthorization], auth => auth.hiddenPlans);
export const getHiddenSubscriptions = createSelector([getHiddenPlans], hiddenPlans =>
    hiddenPlans.map(getSubscriptionFromPlan)
);
export const getSelectedPlan = createSelector([getAuthorization], auth => auth.selectedPlan);
export const getSelectedSubscription = createSelector(
    [getSelectedPlan, getPlans, getHiddenPlans],
    (selectedPlan, plans, hiddenPlans) =>
        getSubscriptionFromPlan(
            plans?.find(p => p.stripeId === selectedPlan.stripeId) ||
                hiddenPlans?.find(p => p.stripeId === selectedPlan.stripeId)
        )
);

export const getStep = createSelector([getAuthorization], auth => auth.step);
export const getErrorCreateUser = createSelector([getAuthorization], auth => auth.errorCreateUser);
export const getErrorMessage = createSelector([getAuthorization], auth => auth.errorMessage);
export const getIsPending = createSelector([getAuthorization], auth => auth.isPending);
export const getValidationErrors = createSelector([getAuthorization], auth => auth.validationErrors);
export const getRememberMe = createSelector([getAuthorization], auth => auth.rememberMe);
export const getRegistrationDetails = createSelector([getAuthorization], auth => auth.registrationDetails);
