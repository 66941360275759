import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { editPermissions } from '../../../utils/userPermissions';
import { tabs } from '../../../config';

import { updateAgencyInfo } from '../../../actions/account';

import { FormTextRegular } from '../../form/FormText';

import Panel from '../../../common/components/Panel';
import { FormMaskedText } from '../../form/FormText';
import axios from 'axios';
import SaveButtonIcon from '../../../containers/settings/SaveButtonIcon';
import useCountry from '../../../hooks/useCountry.ts';
import { getCountryLabel } from '../../../utils/countryUtils.ts';

const BillingDetails = () => {
    const dispatch = useDispatch();
    const { country, isAustralia } = useCountry();
    let [isPending, setIsPending] = useState(false);
    let [savedSuccessfully, setSavedSuccessfully] = useState(false);
    let [saveFailed, setSaveFailed] = useState(false);
    const userRole = useSelector(state => state.user.userInfo.role, shallowEqual);
    const agency = useSelector(state => state.account.agency, shallowEqual);

    const updateAgencyBillingDetails = (agencyId, data) => {
        return axios.put(`/api/agency/${agencyId}/billing-details`, data);
    };

    const handleSubmit = (agencyId, values) => {
        setIsPending(true);
        setSaveFailed(false);
        return updateAgencyBillingDetails(agencyId, values)
            .then(response => {
                setIsPending(false);
                setSaveFailed(false);
                setSavedSuccessfully(true);
                dispatch(updateAgencyInfo(response.data));
            })
            .catch(error => {
                setIsPending(false);
                setSavedSuccessfully(false);
                setSaveFailed(true);
                const response = error.response;
                return response.data.errors ? response.data.errors.billingDetails : response.data.errors;
            });
    };

    return (
        <Form
            initialValues={{ ...agency.billingDetails, disabledCountry: getCountryLabel(country) }}
            onSubmit={values => handleSubmit(agency.id, values)}
        >
            {({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Panel
                            title="Billing Details"
                            rightButton={
                                <div>
                                    {editPermissions(userRole, tabs.TAB_SUBSCRIPTION) && (
                                        <SaveButtonIcon
                                            isSaving={isPending}
                                            success={savedSuccessfully}
                                            failure={saveFailed}
                                        />
                                    )}
                                </div>
                            }
                        >
                            <FormTextRegular
                                name={'email'}
                                label="Billing email address *"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormTextRegular
                                name={'addressLine1'}
                                label="Address line 1 *"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormTextRegular
                                name={'addressLine2'}
                                label="Address line 2"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormTextRegular
                                name={'city'}
                                label="City *"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormTextRegular
                                name={'postalCode'}
                                label="Postal code *"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            {isAustralia && (
                                <>
                                    <FormTextRegular
                                        name={'state'}
                                        label="State *"
                                        disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                                    />
                                    <FormMaskedText
                                        name={'ABN'}
                                        label="ABN"
                                        mask="11 111 111 111"
                                        disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                                    />
                                </>
                            )}
                            {/* WE don't save this in the database, just for display purposes. Country is assigned to stripe subscription and users can't change it */}
                            <FormTextRegular name="disabledCountry" label="Country *" disabled={true} />
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};
export default BillingDetails;
