import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as joinTeam from '../../actions/joinTeam';
import * as user from '../../actions/user';
import ModalTeam from '../../components/modals/ModalTeam';
import '../../sass/team.scss';
import Users from '../../components/settings/team/Users';
import Invitations from '../../components/settings/team/Invitations';
import { isAdmin } from '../../utils/userUtils';
import axios from 'axios';
import { SelectField } from '../../components/form/FormSelect';
import { Form } from 'react-final-form';
import Button from '../../common/components/Button';
import Agencies from '../../components/settings/team/Agencies';

class Team extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalTeamType: 'add',
            modalInitialValues: {},
            editingTeamUserSuccess: false,
            isEditingTeamUser: false,
            hasErrors: false,
            refreshSuccess: false,
            agencies: []
        };
    }

    componentDidMount() {
        this.props.getAgencyTeam();
        this.getAgencies();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isEditingTeamUser && prevProps.isEditingTeamUser && this.props.editingTeamUserSuccess) {
            this.props.getAgencyTeam();
        }

        if (prevProps.isInvitingUser && this.props.hasInvitedUser) {
            this.props.getAgencyTeam();
        }
    }

    getAgencies() {
        return axios
            .get(`/api/agency`)
            .then(response => {
                const agencies = [];
                response.data.agency.map(agency => {
                    if (!this.props.userAgencies.map(a => a.id).includes(agency.id)) {
                        agencies.push({
                            value: agency.id,
                            label: `${agency.details.agencyName} (${agency.details.address}) - ${agency.created}`,
                            disabled: false
                        });
                    }
                });
                this.setState({ agencies });
                this.props.setIsSuperAdmin(true);
                return Promise.resolve();
            })
            .catch(() => {
                this.props.setIsSuperAdmin(false);
            });
    }

    resetMessages() {
        this.setState({
            hasErrors: false,
            refreshSuccess: false
        });
    }

    refreshInvitation(invitationId) {
        this.resetMessages();
        return axios
            .post(`/api/agency/team/invite/${invitationId}/refresh`)
            .then(response => {
                // replace invitation with new invitation
                this.props.replaceInvitation(invitationId, response.data.invite);

                this.setState({
                    refreshSuccess: true
                });
                return Promise.resolve();
            })
            .catch(error => {
                this.setState({
                    hasErrors: true
                });
            });
    }

    cancelInvitation(invitationId) {
        this.resetMessages();
        return axios
            .delete(`/api/agency/team/invite/${invitationId}`)
            .then(() => {
                // replace invitation with new invitation
                this.props.replaceInvitation(invitationId, null);
                return Promise.resolve();
            })
            .catch(error => {
                this.setState({
                    hasErrors: true
                });
            });
    }

    showModal(type, values) {
        this.props.openModalTeam();
        this.setState({
            modalTeamType: type,
            modalInitialValues: values
        });
    }

    handleSubmit(values) {
        return axios
            .post(`/api/agency/${values.agency}/add-user`)
            .then(response => {
                this.props.addAgencyToAgencies(response.data.agency);
                const agencies = [...this.state.agencies];
                agencies.splice(
                    agencies.findIndex(agency => agency.value === values.agency),
                    1
                );
                this.setState({ agencies });

                return Promise.resolve();
            })
            .catch(() => {
                this.setState({
                    hasErrors: true
                });
            });
    }

    deleteUserFromAgency = agencyId => {
        return axios
            .post(`/api/agency/${agencyId}/remove-user`)
            .then(() => {
                this.props.removeAgencyFromAgencies(agencyId);
                return Promise.resolve();
            })
            .catch(error => {
                this.setState({
                    hasErrors: true
                });
            });
    };

    render() {
        const fullTeam = this.props.team;
        const invitations = this.props.invitations;
        const isSuperAdmin = this.props.isSuperAdmin;
        const team = fullTeam.filter(item => item.status !== 'invite');
        const agencies = this.state.agencies;

        const { modalTeamType, modalInitialValues } = this.state;

        return (
            <div className="settings">
                {isSuperAdmin && (
                    <React.Fragment>
                        <Form onSubmit={values => this.handleSubmit(values)}>
                            {({ handleSubmit }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <SelectField
                                            isSearchable={true}
                                            isClearable={true}
                                            name={'agency'}
                                            options={agencies}
                                        />
                                        <Button primary>Add to agency</Button>
                                    </form>
                                );
                            }}
                        </Form>
                        <Agencies agencies={this.props.userAgencies} deleteUserFromAgency={this.deleteUserFromAgency} />
                    </React.Fragment>
                )}
                <Users
                    team={team}
                    loggedInUser={this.props.userInfo}
                    showModal={(type, values) => this.showModal(type, values)}
                />
                <Invitations
                    invitations={invitations}
                    refreshInvitation={invitationId => this.refreshInvitation(invitationId)}
                    cancelInvitation={invitationId => this.cancelInvitation(invitationId)}
                    loggedInUser={this.props.userInfo}
                />
                {team && team.length > 0 && (
                    <ModalTeam
                        type={modalTeamType}
                        user={modalInitialValues}
                        loggedInUser={this.props.userInfo}
                        {...this.props}
                        isAdmin={isAdmin(this.props.userInfo.role)}
                    />
                )}

                <br />
                <br />
                {this.state.hasErrors && <div className="has-error">Something went wrong, please try again</div>}
                {this.state.refreshSuccess && (
                    <div className="success">
                        <span className="savestatus-saved">
                            <span className="icon savestatus-icon" />
                            Invite was successfully resent.
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    state => ({
        agencyId: state.user.agencyId,
        team: state.join.team,
        invitations: state.join.invitations,
        isShowModalTeam: state.join.isShowModalTeam,
        isEditingTeamUser: state.join.isEditingTeamUser,
        editingTeamUserSuccess: state.join.editingTeamUserSuccess,
        userInfo: state.user.userInfo,
        canUserViewStatistics: state.statistics.canUserViewStatistics,
        agencies: state.agencies,
        userAgencies: state.user.agencies,
        isSuperAdmin: state.user.isSuperAdmin
    }),
    {
        blockUser: joinTeam.blockUser,
        unblockUser: joinTeam.unblockUser,
        getAgencyTeam: joinTeam.getAgencyTeam,
        openModalTeam: joinTeam.openModalTeam,
        closeModalTeam: joinTeam.closeModalTeam,
        cancelInvitation: joinTeam.cancelInvitation,
        replaceInvitation: joinTeam.replaceInvitation,
        setIsSuperAdmin: user.setIsSuperAdmin,
        removeAgencyFromAgencies: user.removeAgencyFromAgencies,
        addAgencyToAgencies: user.addAgencyToAgencies
    }
)(Team);
