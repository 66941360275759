import React, { memo } from 'react';

import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../../config';
import { FormRadioGroup } from '../../../../form/FormRadioGroup';

const DefenceClause = () => {
    return (
        <FormRadioGroup
            label="Defence clause enabled?"
            name={'isDefenceClauseEnabled'}
            className="additional-term-item"
            data={[
                {
                    label: YES_LABEL,
                    className: 'is-defence-clause-enabled-yes',
                    id: 'is-defence-clause-enabled-yes',
                    value: YES_VALUE
                },
                {
                    label: NO_LABEL,
                    className: 'is-defence-clause-enabled-no',
                    id: 'is-defence-clause-enabled-no',
                    value: NO_VALUE
                }
            ]}
        />
    );
};

export default memo(DefenceClause);
