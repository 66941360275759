import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import ModalDialog from '../../../common/components/ModalDialog';
import LockOpenSharpIcon from '@material-ui/icons/LockOpenSharp';
import FlkAKeyHistoryDetails from './FlkAKeyHistoryDetails';
import { confirmAlert } from 'react-confirm-alert';
import { useRollbarProvider } from '../../../components/providers/RollbarProvider';
import OpenReminderHistoryButton from './OpenReminderHistoryButton';
import '../../../sass/dashboard/keyReminder.scss';
import '../../../sass/flkAKeyForm.scss';
import { FLK_A_KEY_STATUS_CHECKED_OUT, FLK_A_KEY_STATUS_OVERDUE } from '../../../actions/dashboard';
import { cloneDeep, findIndex } from 'lodash';

const NOT_RETURNED = 'Not returned';
const NO_RESPONSE = 'no-response';

const FlkAKeyHistoryDetailsModal = ({ currentHistoryDetails, isOpenModal, closeModal }) => {
    return (
        <ModalDialog
            title="Key history details"
            isOpen={isOpenModal}
            closeModal={() => closeModal()}
            shouldCloseOnOverlayClick={false}
            className="document-centre-modal"
        >
            {currentHistoryDetails && (
                <FlkAKeyHistoryDetails
                    currentHistoryDetails={currentHistoryDetails}
                    callback={() => closeModal(true)}
                />
            )}
        </ModalDialog>
    );
};

const FlkAKeyHistoryCheckOut = ({ replaceItemInHistoryList, item }) => {
    const Rollbar = useRollbarProvider();

    const [isCheckingIn, setIsCheckingIn] = useState(false);

    function checkInKeyRequest(keyId) {
        return axios.post(`/api/flk-a-key/${keyId}/check-in`);
    }

    function checkIn(e, item) {
        e.stopPropagation();
        confirmAlert({
            title: '',
            message: `Are you sure you want to check in key number ${item.key} for ${item.address}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsCheckingIn(true);
                        return checkInKeyRequest(item.id)
                            .then(response => {
                                setIsCheckingIn(false);
                                replaceItemInHistoryList(response.data.flkAKey);
                            })
                            .catch(error => {
                                setIsCheckingIn(false);
                                Rollbar.error(
                                    'Failed to check in key',
                                    {
                                        error_message: error.message,
                                        status: 'error',
                                        env: ENVIRONMENT
                                    },
                                    error
                                );
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }
    return (
        <React.Fragment>
            {isCheckingIn && <div className="blue-spinner-inline" />}
            {!isCheckingIn && <LockOpenSharpIcon onClick={e => checkIn(e, item)} />}
        </React.Fragment>
    );
};

const FlkAKeyCheckOutHistoryModal = ({ viewCheckOutHistory, currentKey, closeModal }) => {
    const [isOpenHistoryDetails, setIsOpenHistoryDetails] = useState(false);
    const [currentHistoryDetails, setCurrentHistoryDetails] = useState(false);
    const [historyList, setHistoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (currentKey) {
            setIsLoading(true);
            axios.get(`/api/flk-a-key/history?key=${currentKey.key}&address=${currentKey.address}`).then(response => {
                setHistoryList(response.data.historyList);
                setIsLoading(false);
            });
        }
    }, [currentKey]);

    const replaceItemInHistoryList = flkaKey => {
        const newHistoryList = cloneDeep(historyList);
        const index = findIndex(newHistoryList, {
            id: flkaKey.id
        });
        if (index > -1) {
            newHistoryList.splice(index, 1, flkaKey);
        }
        setHistoryList(newHistoryList);
    };

    const dateSortFunction = (a, b) => {
        if (a === NOT_RETURNED || b === NOT_RETURNED) {
            return 0;
        }
        // Display date format is not supported by javascript
        // Therefor we have to re arrange the date and month
        const date1 = a.split('/');
        const date2 = b.split('/');
        return new Date(`${date1[1]}/${date1[0]}/${date1[2]}`) - new Date(`${date2[1]}/${date2[0]}/${date2[2]}`);
    };

    const stringSortFunction = (a, b) => {
        return a > b ? 1 : -1;
    };

    const openHistoryDetails = details => {
        setCurrentHistoryDetails(details);
        setIsOpenHistoryDetails(true);
    };

    return (
        <ModalDialog
            title="Check out history"
            isOpen={viewCheckOutHistory}
            closeModal={closeModal}
            shouldCloseOnOverlayClick={false}
            className="document-centre-modal larger"
        >
            <div className="wrapper flex-direction-column key-history">
                <div className="flk-a-key-summary small">
                    <table>
                        <tr>
                            <td>Key Number</td>
                            <td>{currentKey.key}</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>{currentKey.address}</td>
                        </tr>
                    </table>
                </div>
                <div className="templates-block table-sort table">
                    <Table
                        sortable={[
                            {
                                column: 'checked_out',
                                sortFunction: dateSortFunction
                            },
                            {
                                column: 'checked_in',
                                sortFunction: dateSortFunction
                            },
                            {
                                column: 'company',
                                sortFunction: stringSortFunction
                            },
                            {
                                column: 'contact',
                                sortFunction: stringSortFunction
                            },
                            {
                                column: 'dueDate',
                                sortFunction: dateSortFunction
                            }
                        ]}
                        defaultSort={{ column: 'checked_out', direction: 'desc' }}
                    >
                        <Thead>
                            <Th column="checked_out" className="sort">
                                Checked out
                            </Th>
                            <Th column="checked_in" className="sort">
                                Checked in
                            </Th>
                            <Th column="company" className="sort">
                                Company
                            </Th>
                            <Th column="contact" className="sort">
                                Contact
                            </Th>
                            <Th column="dueDate" className="sort">
                                Return Due Date
                            </Th>
                            <Th column="reminders" className="sort">
                                Reminders
                            </Th>
                            <Th column="checkIn" className="sort">
                                Check In
                            </Th>
                        </Thead>
                        {Array.isArray(historyList) &&
                            historyList.map((item, index) => (
                                <Tr key={index} onClick={() => openHistoryDetails(item)}>
                                    <Td column="checked_out">{item.loggedDateFormatted}</Td>
                                    <Td column="checked_in">{item.returnedDateFormatted}</Td>
                                    <Td column="company">{item.company}</Td>
                                    <Td column="contact">{item.name}</Td>
                                    <Td column="dueDate">{item.dueDateFormatted}</Td>
                                    <Td column="reminders" className="actions">
                                        <OpenReminderHistoryButton flkAKey={item} />
                                    </Td>
                                    {[FLK_A_KEY_STATUS_CHECKED_OUT, FLK_A_KEY_STATUS_OVERDUE].includes(item.status) && (
                                        <Td column="checkIn" className="actions">
                                            <FlkAKeyHistoryCheckOut
                                                item={item}
                                                replaceItemInHistoryList={replaceItemInHistoryList}
                                            />
                                        </Td>
                                    )}
                                </Tr>
                            ))}
                    </Table>
                    {isLoading && <div className="blue-spinner" />}
                </div>
            </div>
            <FlkAKeyHistoryDetailsModal
                isOpenModal={isOpenHistoryDetails}
                closeModal={closeHistoryModal => {
                    setIsOpenHistoryDetails(false);
                    if (closeHistoryModal) {
                        closeModal();
                    }
                }}
                currentHistoryDetails={currentHistoryDetails}
            />
        </ModalDialog>
    );
};

export default FlkAKeyCheckOutHistoryModal;
