import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import { START_RENEWAL_SUCCESS } from '../../reducers/renewal';
import produce from 'immer';
import { isLongTermRenewalSubLease } from '../../utils/agreementUtils';

const initState = {
    amount: '',
    payablePeriod: 'weekly',
    /**
     * set this to null as we don't want to assign today by default, logic is in component to decide whether it should be today or not
     */
    dateStart: null,
    isBond: false,
    bondAlreadyHeld: false,
    rentPerMonth: 'weekly',
    text: '',
    isRentIncrease: false,
    newRentAmount: '',
    newRentPayablePeriod: 'weekly',
    newRentDateStart: new Date(),
    bondDate: '',
    tenantsAmount: [],
    bondEquallyDivide: true,
    bondPayableTo: 'bondPayableToFairTrading',
    rentIncreaseList: [],
    payableInAdvanced: 'weekly',
    isIncludesGst: true
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS:
        case Lease.CREATE_COMMERCIAL_LEASE_SUCCESS: {
            const { lease } = action.result.data;

            if (lease.rent) {
                draftState = lease.rent;
            } else {
                draftState = initState;
            }
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = action.payload.agreementInfo.rent;
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = action.lease.rent;
            break;
        }

        case Lease.LEASE_RENT_SUCCESS: {
            draftState = action.result.data.lease.rent;
            break;
        }
        case START_RENEWAL_SUCCESS: {
            const response = action.result.data;
            if (response.lease && isLongTermRenewalSubLease(response.lease.subLeaseType)) {
                draftState = response.lease.rent;
            }
            break;
        }
    }

    return draftState;
});
