import React from 'react';
import '../../../../../sass/dashboardContentTable.scss';

import { Table, Th, Tr, Thead, Td } from 'reactable';
import AgentLogo from '../../../../AgentLogo';
import BasicDocumentsMoreOptionBlock from '../../cells/BasicDocumentsMoreOptionBlock';
import { useDispatch } from 'react-redux';
import { openDocumentForm } from '../../../../../actions/document';
import { ENTRY_NOTICE, DOCUMENT_EDIT_MODE } from '../../../../../config';

const EntryNoticeDraft = ({ documentList }) => {
    const dispatch = useDispatch();

    function openDocumentFormView(event, doc) {
        event.stopPropagation();
        dispatch(openDocumentForm(ENTRY_NOTICE, doc, DOCUMENT_EDIT_MODE));
    }
    return (
        <Table>
            <Thead>
                <Th column="agentLogo">AGENT</Th>
                <Th column="address">ADDRESS</Th>
                <Th column="tenants">TENANTS</Th>
                <Th column="status">Status</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {documentList &&
                documentList.map((doc, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openDocumentFormView(event, doc);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={doc.agent} />
                            </Td>
                            <Td column="address">
                                <b>{doc.address}</b>
                            </Td>
                            <Td column="tenants">
                                <b>{doc.tenant ? doc.tenant.tenantsListText : ''}</b>
                            </Td>
                            <Td column="status">
                                <span className="icon-document-draft">Draft</span>
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <BasicDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        docType={doc.docType}
                                        doc={doc}
                                    />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default EntryNoticeDraft;
