import React, { useState } from 'react';
import styles from './EntryReasons.module.scss';
import Button from '../../../../common/components/Button.js';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck.js';
import { FormTextHidden } from '../../../../components/form/FormText.js';
import { isLeaseLocationQLD } from '../../../../utils/agreementUtils.js';

import cx from 'classnames';
import Icon, { Icons } from '../../../../common/components/Icon';

export type EntryReasonsType = {
    toInspectPremises?: boolean;
    toInspectRepairs?: boolean;
    toComplianceWithLandlord?: boolean;
    toInspectSmokeAlarms?: boolean;
    toValueProperty?: boolean;
    toTakePhotograph?: boolean;
    toShowProspectiveTenant?: boolean;
    toShowProspectivePurchaser?: boolean;
    toInspectShortTenancyMoveableDwelling?: boolean;
    toCarryOutRoutineRepairs?: boolean;
    toComplyWithFireAndSafety?: boolean;
    toInstallSmokeAlarm?: boolean;
    toComplyWithElectricalSafety?: boolean;
    toCheckIfPropertyIsAbandoned?: boolean;
    toCheckRemediedBreach?: boolean;
};

type EntryReasonsProps = {
    values: EntryReasonsType;
    disabled?: boolean;
    location: string;
};

const EntryReasons = ({ values, disabled, location }: EntryReasonsProps) => {
    const [showAll, setShowAll] = useState(false);

    // Define the lists for QLD and NSW
    const reasonsNSW = [
        {
            name: 'toInspectPremises',
            label: 'To inspect the premises - not more than 4 times in any 12 month period',
            note: 'Note: 7 days notice is required.'
        },
        {
            name: 'toInspectRepairs',
            label: 'To carry out or assess the need for, maintenance or necessary repairs (other than urgent repairs) of the Property.',
            note: 'Note: not less than 2 days notice is required for each attendance.'
        },
        {
            name: 'toComplianceWithLandlord',
            label: "To carry out, inspect or assess the need for work for the purpose of compliance with the landlord's statutory obligations relating to the health or safety of the premises.",
            note: 'Note: not less than 2 days notice is required for each attendance.'
        },
        {
            name: 'toInspectSmokeAlarms',
            label: 'To carry out, inspect or assess the need for repairs to, or replacement of, a smoke alarm installed at the premises.',
            note: 'Note: notice must be given in accordance with the Regulations.'
        },
        {
            name: 'toValueProperty',
            label: 'To value the Property, not more than once in any period of 12 months.',
            note: 'Note: 7 days notice is required.'
        },
        {
            name: 'toTakePhotograph',
            label: 'To take photographs or make a visual recording of the interior of the premises, for the purposes of advertising the premises for sale or lease.',
            note: 'Note: Entry is allowed once within a period of 28 days preceding the commencement of marketing the premises provided the tenant is given reasonable notice and an opportunity to move their possessions out of the photograph / recording.'
        },
        {
            name: 'toShowProspectiveTenant',
            label: 'To show the premises to a prospective tenant.',
            note: 'Note: a reasonable number of times within a 14 days preceding the termination of the agreement.'
        },
        {
            name: 'toShowProspectivePurchaser',
            label: 'To show the premise to a prospective purchaser.',
            note: 'Note: not less than 48 hours notice must be given for each inspection time (which must not be more than 2 in any period of a week.)'
        }
    ];

    const reasonsQLD = [
        {
            name: 'toInspectPremises',
            label: 'Inspect the property.',
            note: 'Note: 7 days notice is required.'
        },
        {
            name: 'toInspectShortTenancyMoveableDwelling',
            label: 'Inspect the property – short tenancy moveable dwelling.',
            note: 'Note: 24 hours notice is required.'
        },
        {
            name: 'toCarryOutRoutineRepairs',
            label: 'Carry out routine repairs or maintenance.',
            note: 'Note: 24 hours notice is required.'
        },
        {
            name: 'toInspectRepairs',
            label: 'Inspect completed repairs or maintenance.',
            note: 'Note: 24 hours notice is required.'
        },
        {
            name: 'toComplyWithFireAndSafety',
            label: 'Comply with the Fire and Emergency Services (Domestic Smoke Alarms) Amendment Act 2016 (Qld) in relation to smoke alarms.',
            note: 'Note: 24 hours notice is required.'
        },
        {
            name: 'toInstallSmokeAlarm',
            label: 'To install, maintain or replace a smoke alarm.',
            note: 'Note: 24 hours notice is required.'
        },
        {
            name: 'toComplyWithElectricalSafety',
            label: 'Comply with the Electrical Safety Act 2002 in relation to approved safety switches.',
            note: 'Note: 24 hours notice is required.'
        },
        {
            name: 'toShowProspectiveTenant',
            label: 'Show the property to a prospective purchaser or tenant.',
            note: 'Note: 24 hours notice is required.'
        },
        {
            name: 'toValueProperty',
            label: 'Allow a valuation of the property to be carried out.',
            note: 'Note: 24 hours notice is required.'
        },
        {
            name: 'toCheckIfPropertyIsAbandoned',
            label: 'The property owner/manager believes, on reasonable grounds, that the property has been abandoned.',
            note: 'Note: 24 hours notice is required.'
        },
        {
            name: 'toCheckRemediedBreach',
            label: 'Check the tenant has remedied a significant breach, if a Notice to remedy breach (Form 11) has expired.',
            note: 'Note: 24 hours notice is required.'
        }
    ];

    // Choose the correct list based on location
    const reasons = isLeaseLocationQLD(location) ? reasonsQLD : reasonsNSW;

    return (
        <div className={styles.entryReasons}>
            <div className={styles.selectGroup}>
                <Button
                    className={styles.selectButton}
                    onClick={() => setShowAll(!showAll)}
                    endIcon={
                        <Icon className={cx(styles.icon, { [styles.expanded]: showAll })} icon={Icons.CHEVRON_DOWN} />
                    }
                    secondary
                    disabled={disabled}
                >
                    Select reason for entry*
                </Button>
                <FormTextHidden name="reasonForEntryErrorField" showError />
            </div>
            <div className={styles.checkboxGroup}>
                {reasons.map(
                    reason =>
                        (showAll || values[reason.name as keyof EntryReasonsType]) && (
                            <CheckboxCheck
                                key={reason.name}
                                name={reason.name}
                                label={
                                    <p>
                                        {reason.label} <strong>{reason.note}</strong>
                                    </p>
                                }
                                className={cx(styles.checkbox, {
                                    [styles.active]: values[reason.name as keyof EntryReasonsType]
                                })}
                                disabled={disabled}
                            />
                        )
                )}
            </div>
        </div>
    );
};

export default EntryReasons;
