import React, { memo } from 'react';
import '../../../sass/dashboardContentTable.scss';
import EstimatedSellingPriceNoticeActive from './tables/estimatedSellingPriceNotice/EstimatedSellingPriceNoticeActive';
import EstimatedSellingPriceNoticeCancelled from './tables/estimatedSellingPriceNotice/EstimatedSellingPriceNoticeCancelled';
import EstimatedSellingPriceNoticeDraft from './tables/estimatedSellingPriceNotice/EstimatedSellingPriceNoticeDraft';
import { DOC_STATUS_CANCELLED, LEASE_STATUS_DRAFT } from '../../../actions/dashboard.js';
import { DEFINE_ME } from '../../../types/utilityTypes.js';

interface EstimatedSellingPriceNoticeListingsProps {
    documentList: DEFINE_ME;
    docType: string;
    agentSignature: DEFINE_ME;
    docStatus: string;
}

const EstimatedSellingPriceNoticeListings: React.FC<EstimatedSellingPriceNoticeListingsProps> = ({
    documentList,
    docType,
    agentSignature,
    docStatus
}) => {
    switch (docStatus) {
        case LEASE_STATUS_DRAFT: {
            return (
                <div className="table-sort">
                    <EstimatedSellingPriceNoticeDraft
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
        case DOC_STATUS_CANCELLED: {
            return (
                <div className="table-sort">
                    <EstimatedSellingPriceNoticeCancelled
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
        default: {
            return (
                <div className="table-sort">
                    <EstimatedSellingPriceNoticeActive
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
    }
};

export default memo(EstimatedSellingPriceNoticeListings);
