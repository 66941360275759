import React from 'react';
import { Field } from 'react-final-form';
import { getError } from '../../../utils/formUtils';

import '../../../sass/responsive/singleCheckbox.scss';

const yesNoInput = ({
    input,
    className,
    text,
    onClick,
    disabled,
    validateOnTouch,
    meta,
    reverse,
    hideDefaultValue
}) => {
    let disabledClassName = '';
    if (disabled) {
        disabledClassName = ' disabled';
    }
    return (
        <div>
            <div 
                className={`FormInput FormInputSingleCheck ${reverse ? 'reverse' : ''}`} 
                style={{ marginBottom: 0 }}
            >
                <label className="label"> {text} </label>
                <div className="button-group">
                    <div
                        className={
                            input.checked
                                ? `checkbox-container active ${disabledClassName} ${className}`
                                : `checkbox-container ${disabledClassName} ${className}`
                        }
                        onClick={() => {
                            if (!disabled) {
                                input.onChange(true);
                                if (onClick) {
                                    onClick(true);
                                }
                            }
                        }}
                    >
                        <div className={`checkbox-label ${disabledClassName}`}>Yes</div>
                    </div>
                    <div
                        className={
                            !input.checked && !hideDefaultValue
                                ? `checkbox-container active ${disabledClassName} ${className}`
                                : `checkbox-container ${disabledClassName} ${className}`
                        }
                        onClick={() => {
                            if (!disabled) {
                                input.onChange(false);
                                if (onClick) {
                                    onClick(false);
                                }
                            }
                        }}
                    >
                        <div className={`checkbox-label ${disabledClassName}`}>No</div>
                    </div>
                </div>
            </div>
            {getError(meta, validateOnTouch) && <p className={'FormError'}>{getError(meta, validateOnTouch)}</p>}
        </div>
    );
};

const doneInput = ({ input, className, text, onClick, disabled, validateOnTouch, meta, reverse }) => {
    let disabledClassName = '';
    if (disabled) {
        disabledClassName = ' disabled';
    }
    return (
        <div className={`FormInput FormInputSingleCheck ${reverse ? 'reverse' : ''}`}>
            <label className="label"> {text} </label>
            <div className="button-group">
                <div
                    className={
                        input.checked
                            ? `checkbox-container active ${disabledClassName} ${className}`
                            : `checkbox-container ${disabledClassName} ${className}`
                    }
                    onClick={() => {
                        if (!disabled) {
                            input.onChange(!input.checked);
                            if (onClick) {
                                onClick(!input.checked);
                            }
                        }
                    }}
                >
                    <div className={`checkbox-label ${disabledClassName}`}>Done</div>
                </div>
            </div>
            {getError(meta, validateOnTouch) && <p className={'FormError'}>{getError(meta, validateOnTouch)}</p>}
        </div>
    );
};

export const FormYesNoCheckbox = ({
    name,
    className,
    text,
    onClick,
    disabled,
    validateOnTouch = false,
    reverse,
    hideDefaultValue,
    ...restProps
}) => {
    return (
        <React.Fragment>
            <div className={`FormInput yes-no-checkbox inline-label`} {...restProps}>
                <Field
                    name={name}
                    className={`grouped ${className}`}
                    component={yesNoInput}
                    type={'checkbox'}
                    onClick={onClick}
                    text={text}
                    disabled={disabled}
                    validateOnTouch={validateOnTouch}
                    reverse={reverse}
                    hideDefaultValue={hideDefaultValue}
                />
            </div>
        </React.Fragment>
    );
};

export const FormDoneCheckbox = ({
    name,
    className,
    text,
    onClick,
    disabled,
    validateOnTouch = false,
    reverse,
    ...restProps
}) => {
    return (
        <React.Fragment>
            <div className={`FormInput yes-no-checkbox inline-label`} {...restProps}>
                <Field
                    name={name}
                    className={`grouped ${className}`}
                    component={doneInput}
                    type={'checkbox'}
                    onClick={onClick}
                    text={text}
                    disabled={disabled}
                    validateOnTouch={validateOnTouch}
                    reverse
                />
            </div>
        </React.Fragment>
    );
};
