import * as React from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import debounce from 'lodash/debounce';

import * as lease from '../../../actions/lease';
import { FormTextRegular } from '../../../components/form/FormText';

import '../../../sass/additional.scss';

class Address extends React.Component {
    constructor(props) {
        super(props);
        this.handleFormChange = debounce(this.handleFormChange.bind(this), 200);
        this.state = {
            address: props.address
        };
    }

    handleFormChange(name, values) {
        this.setState({
            address: values.address
        });

        this.props.handleFormChange(name, values.address);
    }

    render() {
        return (
            <div className="additional">
                <Form
                    onSubmit={() => {}}
                    initialValues={this.state}
                    validate={props => {
                        let errors = this.props.validationErrors;
                        if (!errors) {
                            return;
                        }
                        let touched = !errors.address ? {} : errors.address;
                        return {
                            values: props.values,
                            touched: touched,
                            errors: touched
                        };
                    }}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <FormSpy onChange={state => this.handleFormChange('address', state.values)} />
                                <FormTextRegular name="address" label="Address" />
                            </form>
                        );
                    }}
                </Form>
            </div>
        );
    }
}

export default connect(
    state => ({
        address: state.lease.common.address,
        validationErrors: state.lease.common.validationErrors
    }),
    {
        handleFormChange: lease.handleFormChange
    }
)(Address);
