import produce from 'immer';

export const GET_ALL_NOTIFICATIONS_REQUEST = 'GET_ALL_NOTIFICATIONS_REQUEST';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS';
export const GET_ALL_NOTIFICATIONS_FAIL = 'GET_ALL_NOTIFICATIONS_FAIL';

export const UPDATE_OR_ADD_NOTIFICATION = 'UPDATE_OR_ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_RESOLVED_NOTIFICATIONS = 'CLEAR_RESOLVED_NOTIFICATIONS';
import { REQUEST_TO_CHANGE_STATUS_RESOLVED } from '../config';

const initState = {
    notificationsCount: null,
    notifications: []
};

const countActiveNotifications = notifications => {
    return notifications.filter(item => item.status !== REQUEST_TO_CHANGE_STATUS_RESOLVED).length;
};

export default function notification(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case GET_ALL_NOTIFICATIONS_SUCCESS: {
            return produce(state, draftState => {
                draftState.notifications = action.result.data.notificationList.notifications;
                draftState.notificationsCount = action.result.data.notificationList.notifications.length;
            });
        }

        case UPDATE_OR_ADD_NOTIFICATION: {
            return produce(state, draftState => {
                if (action.data.notification) {
                    const index = draftState.notifications.findIndex(item => item.id === action.data.notification.id);
                    if (index !== -1) {
                        draftState.notifications[index] = action.data.notification;
                    } else {
                        draftState.notifications.push(action.data.notification);
                    }
                }
                draftState.notificationsCount = countActiveNotifications(draftState.notifications);
            });
        }

        case REMOVE_NOTIFICATION: {
            return produce(state, draftState => {
                if (action.data.notification.id) {
                    const index = draftState.notifications.findIndex(item => item.id === action.data.notification.id);
                    if (index !== -1) {
                        delete draftState.notifications[index];
                    }
                }
                draftState.notificationsCount = countActiveNotifications(draftState.notifications);
            });
        }

        case CLEAR_RESOLVED_NOTIFICATIONS: {
            return produce(state, draftState => {
                draftState.notifications = draftState.notifications.filter(
                    request => request.status !== REQUEST_TO_CHANGE_STATUS_RESOLVED
                );
            });
        }
    }

    return newState;
}
