import React from 'react';
import GestureSharpIcon from '@material-ui/icons/GestureSharp';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import EmbeddedSignatureIcon from '../../../../components/ui/icons/EmbeddedSignatureIcon';
import AcknowledgementPageIcon from '../../../../components/ui/icons/AcknowledgementPageIcon';
import {
    EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE,
    EXECUTION_TYPE_EMBEDDED_SIGNATURE
} from '../../../../constants/constants';
import '../../../../sass/documentSummary.scss';
import {
    DOCUMENT_DELIVERY_TYPE_EMAIL,
    DOCUMENT_DELIVERY_TYPE_SMS,
    DOCUMENT_DELIVERY_TYPE_QR_CODE
} from '../../../../config';
import CompletionEmailNotifications from '../../../../components/CompletionEmailNotifications.tsx';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { DOC_COMPLETION_EMAIL_SEND_LIST } from '../../../../constants/featureFlags';
import { LEASE_STATUS_AWAITING_COMPLETION } from '../../../../actions/dashboard';
import LinkedPartnersSummary from './LinkedPartnersSummary.tsx';
import Icon, { Icons } from '../../../../common/components/Icon.tsx';

const FlkAPdfSummary = ({ form, values, currentDocument, SIGN, ACKNOWLEDGE, renderUploadedDocuments }) => {
    const isCompletionSendListActive = useFeatureFlag(DOC_COMPLETION_EMAIL_SEND_LIST);
    return (
        <div className="summary-view">
            <div className="summary-item">
                <h2>Delivery type</h2>
                {currentDocument.deliveryType === DOCUMENT_DELIVERY_TYPE_SMS && (
                    <div className="icon-group">
                        <Icon icon={Icons.SMS_DELIVERY_V2} /> SMS
                    </div>
                )}
                {currentDocument.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL && (
                    <div className="icon-group">
                        <Icon icon={Icons.EMAIL_DELIVERY_V2} /> EMAIL
                    </div>
                )}
                {currentDocument.deliveryType === DOCUMENT_DELIVERY_TYPE_QR_CODE && (
                    <div className="icon-group">
                        <Icon icon={Icons.QR_CODE} /> QR CODE / LINK
                    </div>
                )}
            </div>
            <div className="summary-item">
                <h2>Acknowledgement type</h2>
                {currentDocument.confirmationType === SIGN && (
                    <div className="icon-group">
                        <GestureSharpIcon /> SIGN
                    </div>
                )}
                {currentDocument.confirmationType === ACKNOWLEDGE && (
                    <div className="icon-group">
                        <CheckSharpIcon /> ACKNOWLEDGE
                    </div>
                )}
            </div>
            <div className="client-summary summary-item">
                <h2>Recipients</h2>
                <div className="client-item-group">
                    {currentDocument.client.clients.map((client, index) => {
                        return (
                            <div key={index} className="client-item">
                                <p className="strong">{client.name}</p>
                                <p>{client.email}</p>
                                <p>{client.mobile}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="summary-item">
                <h2>Document instructions</h2>
                <p>{currentDocument.descriptionText}</p>
            </div>
            <div className="summary-item">
                <h2>Documents</h2>
                {renderUploadedDocuments()}
            </div>
            {currentDocument.customPlaceholders.length > 0 && (
                <div className="summary-item">
                    <h2>Custom info placeholders</h2>
                    {currentDocument.customPlaceholders.map((placeholder, index) => {
                        if (placeholder.value !== '') {
                            return (
                                <div key={index} className="summary-item__custom-info-placeholder">
                                    <p className="strong summary-item__custom-info-placeholder__item">
                                        {placeholder.label}
                                    </p>
                                    <p className="summary-item__custom-info-placeholder__item">{placeholder.value}</p>
                                </div>
                            );
                        }
                    })}
                </div>
            )}
            <div className="summary-item">
                <h2>Recipient acknowledgement statements</h2>
                <p>{currentDocument.acknowledgementText}</p>
            </div>
            {currentDocument?.listNow?.enabled && <LinkedPartnersSummary currentDocument={currentDocument} />}
            <div className="summary-item">
                <h2>Execution type</h2>
                {currentDocument.executionType === EXECUTION_TYPE_EMBEDDED_SIGNATURE && (
                    <div className="icon-group">
                        <EmbeddedSignatureIcon /> {EXECUTION_TYPE_EMBEDDED_SIGNATURE}
                    </div>
                )}
                {currentDocument.executionType === EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE && (
                    <div className="icon-group">
                        <AcknowledgementPageIcon /> {EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE}
                    </div>
                )}
            </div>

            {isCompletionSendListActive && currentDocument.status === LEASE_STATUS_AWAITING_COMPLETION && (
                <div className="summary-item">
                    <CompletionEmailNotifications
                        form={form}
                        values={values}
                        emails={currentDocument.client.clientsEmailList}
                        ccEmails={[currentDocument.agent.email]}
                        bccEmails={[]}
                    />
                </div>
            )}
        </div>
    );
};
export default FlkAPdfSummary;
