import React, { Component } from 'react';
import { getPmStepNo } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';

class AgreementPmExpensesAndCharges extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.pmExpensesAndCharges && (
                    <AgreementSection
                        title="Expenses & Charges"
                        step={getPmStepNo(leaseAllInfo.location, 'pmExpensesAndCharges')}
                        leaseAllInfo={this.props.leaseAllInfo}
                    >
                        <div className="agreement-section-text">
                            <div>
                                {leaseAllInfo.pmExpensesAndCharges && (
                                    <p className="name">
                                        Advertising: <strong>{leaseAllInfo.pmExpensesAndCharges.advertising}</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmExpensesAndCharges && (
                                    <p className="name">
                                        Limit On Costs Of Repairs:{' '}
                                        <strong>{leaseAllInfo.pmExpensesAndCharges.limitOnCostsOfRepairs}</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmExpensesAndCharges && (
                                    <p className="name">
                                        Photography: <strong>{leaseAllInfo.pmExpensesAndCharges.photography}</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmExpensesAndCharges && (
                                    <p className="name">
                                        Bank Charges: <strong>{leaseAllInfo.pmExpensesAndCharges.bankCharges}</strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmExpensesAndCharges && (
                                    <p className="name">
                                        Financial Year Summary:{' '}
                                        <strong>{leaseAllInfo.pmExpensesAndCharges.financialYearSummary}</strong>
                                    </p>
                                )}
                            </div>
                            {leaseAllInfo.pmExpensesAndCharges.chargeList.map((charge, index) => {
                                return (
                                    <div key={index}>
                                        <p className="name">
                                            {charge.description}: <strong>{charge.amount}</strong>
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementPmExpensesAndCharges;
