import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import * as Dashboard from '../../reducers/dashboard';
import produce from 'immer';
import { LEASE_SECTION_UPDATE_SUCCESS } from '../../reducers/lease';

import { OPEN_EDIT_LANDLORDS_MODAL } from '../dashboard';

const initState = {};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS:
        case Lease.CREATE_SALES_LEASE_SUCCESS:
        case Lease.CREATE_PM_LEASE_SUCCESS:
        case Lease.CREATE_COMMERCIAL_LEASE_SUCCESS: {
            draftState = action.result.data.lease;
            break;
        }
        case OPEN_EDIT_LANDLORDS_MODAL:
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = action.payload.agreementInfo;
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = action.lease;
            break;
        }
        case LEASE_SECTION_UPDATE_SUCCESS: {
            draftState = action.result.data.lease;
            break;
        }

        case Lease.LEASE_LANDLORD_DETAILS_SUCCESS: {
            draftState.pmLandlord = action.result.data.pmLandlord;
        }
    }
    return draftState;
});
