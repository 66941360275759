import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import * as lease from '../../../actions/lease';
import * as template from '../../../actions/template';
import * as templateActions from '../../../actions/template';
import * as dashboard from '../../../actions/dashboard';
import { getTemplates } from './../../../actions/template';
import { steps } from '../../../components/lease/steps';
import HeaderTemplate from '../../../components/lease/template/HeaderTemplate';
import SideBarLease from '../../../components/lease/SideBarLease';
import FooterTemplate from '../../../components/lease/template/FooterTemplate';
import CreateNewTemplate from '../../../components/lease/template/CreateNewTemplate';
import RequestsToChange from '../RequestsToChange';
import { CloseSharp } from '@material-ui/icons';
import { isUserRoleAdmin } from '../../../selectors/user';
import {
    isTemplate,
    getUpdateObject,
    isPropertyManagementTemplate,
    isSalesTemplate
} from '../../../utils/agreementUtils';
import '../../../sass/createLease.scss';
import * as agreementUtils from '../../../utils/agreementUtils';
import { CLOSE_MODAL_MESSAGE, DEFAULT_SUB_LEASE_TYPE } from '../../../config';

class AgreementTemplateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.childStepRef = React.createRef();
    }

    isLeaseComplete = () => {
        return agreementUtils.isCompleted(this.props.leaseAllInfo, this.props.leaseType, this.props.location);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return isTemplate(nextProps.leaseType);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { isEditingTemplateFinish } = nextProps;
        if (false === isEditingTemplateFinish && true === nextProps.isEditingTemplateFinish) {
            return { closeModal: true };
        } else return null;
    }
    componentDidUpdate(prevProps) {
        if (prevProps.isEditingTemplateFinish !== this.props.isEditingTemplateFinish) {
            this.props.closeAgreementTemplateForm();
        }
    }

    createDocument(data) {
        if (isPropertyManagementTemplate(data.leaseType)) {
            this.props.createPmTemplate(data);
        } else if (isSalesTemplate(data.leaseType)) {
            this.props.createSalesTemplate(data);
        }
    }

    finishEditing() {
        const { templatesPagination, templatesSorting } = this.props;
        this.props.getTemplates(
            this.props.leaseType,
            templatesPagination.page,
            templatesPagination.limit,
            templatesSorting.field,
            templatesSorting.direction
        );
        this.submitCurrentStep().then(() => {
            this.updateAgreement();
        });
    }

    closeAgreementFormRefreshList = () => {
        const {
            templatesPagination,
            templatesSorting,
            dirtyStep,
            leaseType,
            leaseAllInfo,
            step,
            leaseId,
            location,
            setDocuments
        } = this.props;
        const leaseTypeVal = leaseType ? leaseType : leaseAllInfo.leaseType;
        this.props.getTemplates(
            this.props.leaseType,
            templatesPagination.page,
            templatesPagination.limit,
            templatesSorting.field,
            templatesSorting.direction
        );
        if (dirtyStep) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            // Special case for documents
                            if (
                                agreementUtils.getStep(
                                    steps,
                                    leaseTypeVal,
                                    location,
                                    step - 2,
                                    leaseAllInfo.subLeaseType
                                ).part === 'documents'
                            ) {
                                setDocuments(leaseId, this.props.documents, null, true);
                                this.props.closeAgreementTemplateForm();
                            } else {
                                this.childStepRef.current.bypassFormValidation = true;
                                this.childStepRef.current.callbackAfterSubmit = () => {
                                    this.props.setNavigationState();
                                    this.props.closeAgreementTemplateForm();
                                };
                                this.childStepRef.current.submitStep();
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            this.props.closeAgreementTemplateForm();
                        }
                    },
                    {
                        className: 'close close-modal',
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            this.props.closeAgreementTemplateForm();
        }
    };

    updateAgreement() {
        const { updateAgreementTemplate, leaseType, location } = this.props;
        const data = getUpdateObject(this.props);
        updateAgreementTemplate(this.props.leaseId, data);
    }

    nextStep() {
        this.createLeaseRef.scrollTop = 0; // This scrolls back to the top when you go to the next step
        const { step, leaseId, leaseType, location, leaseAllInfo } = this.props;
        const part = agreementUtils.getStep(steps, leaseType, location, step - 2, leaseAllInfo.subLeaseType).part;
        /**
         * Submit a part of the lease agreement.
         *
         * Example:
         * this.props.setLandlord(leaseId, this.props.landlord);
         */
        let action = this.props[`set${part[0].toUpperCase() + part.slice(1)}`];
        if (action && part === 'documents') {
            action(leaseId, this.props[part], { leaseType, location });
        } else {
            // Call submit step via childStepRef
            this.props.setMoveToNextStepEditingMode().then(() => {
                this.childStepRef.current.submitStep();
            });

            this.childStepRef.current.callbackAfterSubmit = () => {
                this.props.setMoveToNextStepEditingMode().then(() => {
                    this.props.setNavigationState();
                });
            };
            this.childStepRef.current.submitStep();
        }
    }

    goToStep = (...args) => {
        this.createLeaseRef.scrollTop = 0;
        return this.props.goToStep(...args);
    };

    renderCurrentStepWithForwardRef = () => {
        const { leaseType, leaseAllInfo, step, leaseId, location } = this.props;
        const leaseTypeVal = leaseType ? leaseType : leaseAllInfo.leaseType;
        return React.createElement(
            agreementUtils.getStep(steps, leaseTypeVal, location, step - 2, leaseAllInfo.subLeaseType).content,
            {
                leaseId,
                ref: this.childStepRef
            }
        );
    };
    submitCurrentStep = () => {
        return new Promise((resolve, reject) => {
            if (this.childStepRef && this.childStepRef.current) {
                this.childStepRef.current.callbackAfterSubmit = () => {
                    this.props.setNavigationState();
                };
                this.childStepRef.current.submitStep();
            }
            resolve();
        });
    };
    saveStep = (step, currentStep) => {
        this.props.setSideMenuStep(step).then(() => {
            if (this.childStepRef.current) {
                this.childStepRef.current.bypassFormValidation = true;
                this.childStepRef.current.callbackAfterSubmit = () => {
                    this.props.setNavigationState();
                };
                this.childStepRef.current.submitStep();
                if (this.createLeaseRef) {
                    this.createLeaseRef.scrollTop = 0;
                }
            } else {
                /**
                 * If the current step is documents we have a different way of passing the data through
                 */
                if (currentStep.part === 'documents') {
                    this.props.setDocuments(this.props.leaseId, this.props.documents, null, true);
                } else {
                    this.goToStep(step);
                }
            }
        });
    };
    render() {
        const {
            isOpenAgreementTemplateForm,
            closeAgreementTemplateForm,
            step,
            goBack,
            leaseAllInfo,
            validationErrors,
            isOpenRequestsToChangeForm,
            requests,
            activeRequest,
            setActiveRequest,
            leaseType,
            isSubmitButtonDisabled,
            isSubmittingLease,
            location
        } = this.props;
        const leaseTypeVal = leaseType ? leaseType : leaseAllInfo.leaseType;
        return (
            <Modal
                parentSelector={() => document.getElementById('main-layout')}
                ariaHideApp={false}
                isOpen={isOpenAgreementTemplateForm}
                style={customstyle}
                className="modal-create-lease"
                contentLabel="Modal"
            >
                <div className="create-lease" ref={node => (this.createLeaseRef = node)}>
                    {step === 1 && !isOpenRequestsToChangeForm && (
                        <CreateNewTemplate
                            leaseType={leaseTypeVal}
                            createDocument={this.createDocument.bind(this)}
                            closeAgreementTemplateForm={closeAgreementTemplateForm}
                        />
                    )}
                    {step > 1 && (
                        <div className="content-create">
                            <HeaderTemplate
                                closeModal={this.closeAgreementFormRefreshList.bind(this)}
                                leaseAllInfo={leaseAllInfo}
                            />
                            <div className="content-lease">
                                <SideBarLease
                                    goToStep={this.goToStep}
                                    saveStep={this.saveStep}
                                    step={step}
                                    items={
                                        steps[leaseTypeVal.replace(/ /g, '_').toLowerCase()][
                                            leaseAllInfo.subLeaseType || DEFAULT_SUB_LEASE_TYPE
                                        ][location]
                                    }
                                    validationErrors={validationErrors}
                                    lease={leaseAllInfo}
                                    isLeaseComplete={this.isLeaseComplete}
                                    requests={requests}
                                    activeRequest={activeRequest}
                                    setActiveRequest={setActiveRequest}
                                    isOpenRequestsToChangeForm={isOpenRequestsToChangeForm}
                                />
                                <main className="main">
                                    {agreementUtils.getStep(
                                        steps,
                                        leaseTypeVal,
                                        location,
                                        step - 2,
                                        leaseAllInfo.subLeaseType
                                    ).forwardRef
                                        ? this.renderCurrentStepWithForwardRef()
                                        : agreementUtils.getStep(
                                              steps,
                                              leaseTypeVal,
                                              location,
                                              step - 2,
                                              leaseAllInfo.subLeaseType
                                          ).content}
                                </main>
                            </div>
                            <FooterTemplate
                                goBack={goBack}
                                nextStep={this.nextStep.bind(this)}
                                finishEditing={this.finishEditing.bind(this)}
                                isSubmitButtonDisabled={isSubmitButtonDisabled}
                                isSubmittingLease={isSubmittingLease}
                                isServerBusy={this.props.isServerBusy}
                                step={step}
                                leaseType={leaseType}
                                subLeaseType={leaseAllInfo.subLeaseType}
                                location={location}
                            />
                        </div>
                    )}
                </div>
                {isOpenRequestsToChangeForm && (
                    <RequestsToChange
                        steps={
                            steps[agreementUtils.getLeaseTypeForFrontEnd(leaseTypeVal)][leaseAllInfo.subLeaseType][
                                location
                            ]
                        }
                    />
                )}
            </Modal>
        );
    }
}

export default connect(
    state => ({
        address: state.lease.common.address,
        step: state.lease.common.step,
        leaseId: state.lease.common.id,
        leaseType: state.lease.common.leaseType,
        location: state.lease.common.location,
        agency: state.user.agency,
        user: state.user,
        agent: state.lease.common.agent,
        documents: state.lease.documents,
        leaseAllInfo: state.lease.common.leaseAllInfo,
        validationErrors: state.lease.common.validationErrors,
        isOpenAgreementTemplateForm: state.lease.common.isOpenAgreementTemplateForm,
        isEditAgreementTemplateForm: state.lease.common.isEditAgreementTemplateForm,
        isOpenRequestsToChangeForm: state.lease.common.isOpenRequestsToChangeForm,
        requests: state.lease.common.requests,
        activeRequest: state.lease.common.activeRequest,
        isSubmitButtonDisabled: state.lease.common.isSubmitButtonDisabled,
        isEditingTemplateFinish: state.lease.common.isEditingTemplateFinish,
        isSubmittingLease: state.lease.common.isSubmittingLease,
        isServerBusy: state.serverRequests.isServerBusy,
        isUserAdmin: isUserRoleAdmin(state),
        templatesPagination: state.dashboard.templatesPagination,
        templatesSorting: state.dashboard.templatesSorting,
        dirtyStep: state.lease.common.dirtyStep
    }),
    {
        changeAgreementAgent: dashboard.changeAgreementAgent,
        goBack: lease.goBack,
        goToStep: lease.goToStep,
        createPmTemplate: templateActions.createPmTemplate,
        createSalesTemplate: templateActions.createSalesTemplate,
        setDocuments: lease.setDocuments,
        setConnections: lease.setConnections,
        getTemplates: getTemplates,
        closeAgreementTemplateForm: template.closeAgreementTemplateForm,
        updateAgreementTemplate: template.updateAgreementTemplate,
        editAddress: lease.editAddress,
        editAgent: lease.editAgent,
        setActiveRequest: lease.setActiveRequest,
        openAgreementInfoModal: dashboard.openAgreementInfoModal,
        closeAgreementInfoModal: dashboard.closeAgreementInfoModal,
        cloneAgreement: dashboard.cloneAgreement,
        openCloneResTenForm: lease.openCloneResTenForm,
        deleteAgreement: dashboard.deleteAgreement,
        closeSigning: dashboard.closeSigning,
        showModalInfoAgreement: dashboard.openAgreementInfoModal,
        moveToArchive: lease.moveToArchive,
        showEditTenants: dashboard.openEditTenantsModal,
        setMoveToNextStepEditingMode: lease.setMoveToNextStepEditingMode,
        setSideMenuStep: lease.setSideMenuStep,
        setNavigationState: lease.setNavigationState
    }
)(AgreementTemplateForm);

const customstyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(148, 157, 176, 0.7)',
        zIndex: 5,
        display: 'flex',
        overflow: 'auto',
        justifyContent: 'center',
        padding: '50px 0'
    }
};
