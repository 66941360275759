import React from 'react';
import '../../../sass/dashboardContentTable.scss';
import RentReduction from './tables/rentReduction/RentReduction';
import * as dashboard from '../../../actions/dashboard';
import RentReductionSentForSigning from './tables/rentReduction/RentReductionSentForSigning';

const RentReductionListings = ({ documentList, docType, agentSignature, docStatus }) => {
    let contentTable;

    switch (docStatus) {
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            contentTable = (
                <RentReductionSentForSigning
                    documentList={documentList}
                    docType={docType}
                    agentSignature={agentSignature}
                />
            );
            break;
        }

        default:
            contentTable = (
                <RentReduction documentList={documentList} docType={docType} agentSignature={agentSignature} />
            );
    }

    return <div className="table-sort">{contentTable}</div>;
};

export default RentReductionListings;
