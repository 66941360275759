import update from 'immutability-helper';
import { processServerErrors } from './lease';
import { CLOSE_NO_SIGNATURE_MODAL, OPEN_NO_SIGNATURE_MODAL } from '../actions/types';

export const SAVE_AGENT_SIGNATURE = 'users.account.signatures.agent.save';
export const CLOSE_MODAL_SIGNATURE = 'users.account.modal.signature.close';
export const OPEN_MODAL_SIGNATURE = 'users.account.modal.signature.open';

export const ADD_SIGNATURE_REQUEST = 'users.account.add.signature.request';
export const ADD_SIGNATURE_SUCCESS = 'users.account.add.signature.success';
export const ADD_SIGNATURE_FAIL = 'users.account.add.signature.fail';

export const UPDATE_SIGNATURE_REQUEST = 'users.account.update.signature.request';
export const UPDATE_SIGNATURE_SUCCESS = 'users.account.update.signature.success';
export const UPDATE_SIGNATURE_FAIL = 'users.account.update.signature.fail';

export const DELETE_SIGNATURE_REQUEST = 'users.account.delete.signature.request';
export const DELETE_SIGNATURE_SUCCESS = 'users.account.delete.signature.success';
export const DELETE_SIGNATURE_FAIL = 'users.account.delete.signature.fail';

export const GET_SIGNATURE_LIST_REQUEST = 'users.account.signatures.list.request';
export const GET_SIGNATURE_LIST_SUCCESS = 'users.account.signatures.list.success';
export const GET_SIGNATURE_LIST_FAIL = 'users.account.signatures.list.fail';

export const CHECK_TOKEN_VALIDITY_REQUEST = 'users.account.signatures.token.request';
export const CHECK_TOKEN_VALIDITY_SUCCESS = 'users.account.signatures.token.success';
export const CHECK_TOKEN_VALIDITY_FAIL = 'users.account.signatures.token.fail';

export const REQUEST_SIGNATURE_ADD_REQUEST = 'users.account.signatures.request.sent.request';
export const REQUEST_SIGNATURE_ADD_SUCCESS = 'users.account.signatures.request.sent.success';
export const REQUEST_SIGNATURE_ADD_FAIL = 'users.account.signatures.request.sent.fail';
export const REQUEST_SIGNATURE_ADD_CLEAR = 'users.account.signatures.request.sent.clear';

export const REQUEST_SIGNATURE_URL_REQUEST = 'users.account.signatures.request.url.request';
export const REQUEST_SIGNATURE_URL_SUCCESS = 'users.account.signatures.request.url.success';
export const REQUEST_SIGNATURE_URL_FAIL = 'users.account.signatures.request.url.fail';

const initState = {
    signaturesList: [],
    validationErrors: null,
    isSendingRequest: false,
    signatureAddSuccess: false,
    isCheckingToken: null,
    isTokenValid: false,
    isSendingRequestToAddSignature: false,
    requestToAddSignatureSent: false,
    requestToAddSignatureFailed: false,
    agentSignature: null,
    isSendingRequestToDeleteSignature: false,
    deleteSignatureSuccess: false,
    isNoSignatureModalOpen: false,
    signatureModalMessage: '',
    deleteSignatureFail: false,
    isSendingRequestSignatureURL: false,
    requestSignatureURLSuccess: false,
    requestSignatureURLFailed: false,
    signatureURL: ''
};

export default function signatures(state = initState, action) {
    switch (action.type) {
        case CHECK_TOKEN_VALIDITY_REQUEST: {
            return {
                ...state,
                isCheckingToken: true
            };
        }
        case CHECK_TOKEN_VALIDITY_SUCCESS: {
            return {
                ...state,
                isCheckingToken: false,
                isTokenValid: true,
                shouldLogOut: false,
                agentSignature: action.result.data.agencySignature
            };
        }
        case CHECK_TOKEN_VALIDITY_FAIL: {
            let shouldLogOut = false;
            if (action.error && action.error.response && action.error.response.status === 403) {
                shouldLogOut = true;
            }
            return {
                ...state,
                isCheckingToken: false,
                isTokenValid: false,
                shouldLogOut
            };
        }
        case ADD_SIGNATURE_REQUEST: {
            return {
                ...state,
                isSendingRequest: true
            };
        }
        case ADD_SIGNATURE_SUCCESS: {
            return {
                ...state,
                isSendingRequest: false,
                signatureAddSuccess: true
            };
        }
        case ADD_SIGNATURE_FAIL: {
            return {
                ...state,
                validationErrors: Object.assign(
                    {},
                    state.validationErrors,
                    processServerErrors(action.error.response.data.errors)
                ),
                isSendingRequest: false,
                signatureAddSuccess: false
            };
        }
        case REQUEST_SIGNATURE_ADD_REQUEST: {
            return {
                ...state,
                isSendingRequestToAddSignature: true
            };
        }
        case REQUEST_SIGNATURE_ADD_SUCCESS: {
            return {
                ...state,
                isSendingRequestToAddSignature: false,
                requestToAddSignatureSent: true,
                requestToAddSignatureFailed: false
            };
        }
        case REQUEST_SIGNATURE_ADD_FAIL: {
            return {
                ...state,
                isSendingRequestToAddSignature: false,
                requestToAddSignatureSent: false,
                requestToAddSignatureFailed: true
            };
        }
        case REQUEST_SIGNATURE_ADD_CLEAR: {
            return {
                ...state,
                requestToAddSignatureSent: false
            };
        }
        case REQUEST_SIGNATURE_URL_REQUEST: {
            return {
                ...state,
                isSendingRequestSignatureURL: true
            };
        }
        case REQUEST_SIGNATURE_URL_SUCCESS: {
            return {
                ...state,
                isSendingRequestSignatureURL: false,
                requestSignatureURLSuccess: true,
                requestSignatureURLFailed: false,
                signatureURL: action.result.data.url
            };
        }
        case REQUEST_SIGNATURE_URL_FAIL: {
            return {
                ...state,
                isSendingRequestSignatureURL: false,
                requestSignatureURLSuccess: false,
                requestSignatureURLFailed: true
            };
        }
        case UPDATE_SIGNATURE_SUCCESS: {
            let updatedSignature = action.result.data.agencySignature;
            let updatedIndex = state.signaturesList.findIndex(signature => signature.id === updatedSignature.id);

            return update(state, {
                signaturesList: {
                    [updatedIndex]: { $set: updatedSignature }
                },
                isOpenModalSignature: { $set: false }
            });
        }

        case GET_SIGNATURE_LIST_SUCCESS: {
            return {
                ...state,
                signaturesList: action.result.data.agencySignatureList
            };
        }

        case OPEN_MODAL_SIGNATURE: {
            const { type, values } = action.data;
            return {
                ...state,
                isOpenModalSignature: true,
                modalSignature: {
                    ...state.modalSignature,
                    type,
                    values
                }
            };
        }

        case CLOSE_MODAL_SIGNATURE: {
            return {
                ...state,
                isOpenModalSignature: false
            };
        }

        case DELETE_SIGNATURE_REQUEST: {
            return {
                ...state,
                isSendingRequestToDeleteSignature: true
            };
        }
        case DELETE_SIGNATURE_SUCCESS: {
            return {
                ...state,
                signaturesList: action.result.data.agencySignatureList,
                isSendingRequestToDeleteSignature: false,
                deleteSignatureSuccess: true,
                deleteSignatureFail: false
            };
        }
        case DELETE_SIGNATURE_FAIL: {
            return {
                ...state,
                isSendingRequestToDeleteSignature: false,
                deleteSignatureSuccess: false,
                deleteSignatureFail: true
            };
        }
        case SAVE_AGENT_SIGNATURE: {
            return {
                ...state,
                agentSignature: action.payload.signature
            };
        }
        case OPEN_NO_SIGNATURE_MODAL: {
            return {
                ...state,
                isNoSignatureModalOpen: true
            };
        }
        case CLOSE_NO_SIGNATURE_MODAL: {
            return {
                ...state,
                isNoSignatureModalOpen: false,
                signatureModalMessage: ''
            };
        }
        default: {
            return state;
        }
    }
}
