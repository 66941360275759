import { DndContext, DragEndEvent, DragStartEvent, UniqueIdentifier, closestCenter } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import * as Accordion from '@radix-ui/react-accordion';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';

import Button from '@app/common/components/Button.js';
import Icon, { Icons } from '@app/common/components/Icon';
import ReorderableAccordionItem from './ReorderableAccordionItem';

import styles from './ReorderableAccordion.module.scss';

type ReorderableAccordionProps<T extends Record<string, unknown>> = {
    itemData: T[];
    onAddItem: (callback?: () => void) => void;
    onDeleteItem: (index: number, callback?: () => void) => void | Promise<void>;
    itemDisplayNameLowercase: string;
    isReadOnly?: boolean;
    renderCollapsedView: (item: T, index: number) => React.ReactNode;
    renderExpandedView: (item: T, index: number) => React.ReactNode;
    renderExpandedHeader?: (item: T, index: number) => React.ReactNode;
    isReorderEnabled?: boolean;
    header?: React.ReactNode;
    canAddItem?: boolean;
    disabledAddItemTooltip?: string;
    onReorderItems: (activeIndex: number, overIndex: number) => void;
    collapseLastClient: boolean;
    setCollapseLastClient: (collapseLastClient: boolean) => void;
    deletingItemIndex?: number;
};

const ReorderableAccordion = <T extends Record<string, unknown>>({
    itemData,
    onAddItem,
    onDeleteItem,
    onReorderItems,
    itemDisplayNameLowercase,
    isReadOnly,
    renderCollapsedView,
    renderExpandedView,
    renderExpandedHeader,
    isReorderEnabled = true,
    header,
    canAddItem,
    disabledAddItemTooltip,
    collapseLastClient,
    setCollapseLastClient,
    deletingItemIndex
}: ReorderableAccordionProps<T>) => {
    const lastItemRef = React.useRef<HTMLDivElement>(null);
    // We use this ref to create a unique id each time a item is added
    const uniqueItemId = React.useRef(0);

    // We use the index of the item when added as part of the ID, as the name will change when re-ordering
    // Note: There is a dnd-kit bug with falsy ID's that prevents us from using numbers as IDs:
    // https://github.com/clauderic/dnd-kit/issues/858
    const [itemsOpenState, setItemsOpenState] = useImmer(
        itemData.map((_, index) => ({
            isOpen: index === itemData.length - 1,
            id: `item-${uniqueItemId.current++}`
        }))
    );

    const [isReordering, setIsReordering] = React.useState(false);
    const [draggingItemId, setDraggingItemId] = React.useState<UniqueIdentifier>();

    // If a user disabled sequential signing we also need to reset the
    // isReordering otherwise they get stuck and cannot edit the recipients
    useEffect(() => {
        if (!isReorderEnabled) {
            setIsReordering(false);
        }
    }, [isReorderEnabled]);

    // TODO: This is a hack to handle when items are added from outside the component.
    // It will only handle when one item is added at a time, and will not handle items being removed
    React.useEffect(() => {
        if (itemData.length > itemsOpenState.length) {
            setItemsOpenState(draftItems =>
                draftItems
                    .map(item => ({ ...item, isOpen: false }))
                    .concat({
                        id: `item-${++uniqueItemId.current}`,
                        isOpen: true
                    })
            );
        }
    }, [itemData, itemsOpenState, setItemsOpenState]);

    // Hack to collapse the last client from parent component
    // This is used to collapse the last client when a new client is added from contact search
    React.useEffect(() => {
        if (collapseLastClient) {
            setItemsOpenState(draftItems => {
                draftItems[draftItems.length - 1].isOpen = false;
                return draftItems;
            });
            setCollapseLastClient(false);
        }
    }, [collapseLastClient]);

    const handleAddItem = () => {
        onAddItem(() => {
            setItemsOpenState(draftItems =>
                draftItems
                    .map(item => ({ ...item, isOpen: false }))
                    .concat({ id: `item-${++uniqueItemId.current}`, isOpen: true })
            );
            // Scroll into view after the new item is rendered
            requestAnimationFrame(() => {
                lastItemRef.current?.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            });
        });
    };

    const handleRemoveItem = (index: number) => {
        onDeleteItem(index, () => {
            setItemsOpenState(draftItems => {
                draftItems.splice(index, 1);
            });
        });
    };

    const handleReorderItems = (draggingItemId: UniqueIdentifier, overItemId: UniqueIdentifier) => {
        const activeIndex = itemsOpenState.findIndex(item => item.id === draggingItemId);
        const overIndex = itemsOpenState.findIndex(item => item.id === overItemId);

        onReorderItems(activeIndex, overIndex);
        setItemsOpenState(draft => arrayMove(draft, activeIndex, overIndex));
    };

    const handleDragStart = (dragEvent: DragStartEvent) => {
        setDraggingItemId(dragEvent.active.id);
    };

    const handleDragEnd = (dragEvent: DragEndEvent) => {
        const { active, over } = dragEvent;

        if (over && active.id !== over.id) {
            handleReorderItems(active.id, over.id);
        }

        setDraggingItemId(undefined);
    };

    return (
        <>
            {(header || isReorderEnabled) && (
                <div className={styles.header}>
                    {header && header}
                    {isReorderEnabled && (
                        <Button
                            quaternary
                            className={cx(styles.reorderButton, { [styles.enabled]: isReordering })}
                            disabled={itemData.length === 1 || isReadOnly}
                            type="button"
                            onClick={() => setIsReordering(oldValue => !oldValue)}
                            startIcon={<Icon icon={Icons.DRAG} />}
                        >
                            {isReordering ? 'Done' : 'Re-order'}
                        </Button>
                    )}
                </div>
            )}
            <Accordion.Root
                className={styles.accordion}
                type="multiple"
                value={isReordering ? [] : itemsOpenState.filter(item => item.isOpen).map(item => item.id)}
                onValueChange={openItems => {
                    setItemsOpenState(draft =>
                        draft.map((item, index) => {
                            const itemId = itemsOpenState[index].id;
                            return { ...item, isOpen: openItems.includes(itemId) };
                        })
                    );
                }}
            >
                <DndContext
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext items={itemsOpenState.map(item => item.id)} strategy={verticalListSortingStrategy}>
                        {itemData.map((item, index) => {
                            const itemState = itemsOpenState[index];
                            if (!itemState) {
                                return null;
                            }

                            const { id, isOpen } = itemState;

                            return (
                                <ReorderableAccordionItem
                                    isDragging={draggingItemId === id}
                                    key={id}
                                    id={id}
                                    isOpen={isOpen}
                                    isDeletable={itemData.length > 1}
                                    onDelete={() => handleRemoveItem(index)}
                                    isDeleteLoading={deletingItemIndex === index}
                                    isDeleteDisabled={deletingItemIndex !== undefined && deletingItemIndex !== index}
                                    isReordering={isReordering}
                                    ref={index === itemData.length - 1 ? lastItemRef : undefined}
                                    number={index + 1}
                                    collapsedContent={renderCollapsedView(item, index)}
                                    expandedHeader={renderExpandedHeader ? renderExpandedHeader(item, index) : null}
                                >
                                    {renderExpandedView(item, index)}
                                </ReorderableAccordionItem>
                            );
                        })}
                    </SortableContext>
                </DndContext>
            </Accordion.Root>

            <Button
                className={styles.addItemButton}
                type="button"
                onClick={handleAddItem}
                disabled={isReordering || isReadOnly || !canAddItem}
                tooltip={disabledAddItemTooltip}
                tertiary
            >
                <Icon icon={Icons.PLUS} className={styles.addItemIcon} />
                Add another {itemDisplayNameLowercase}
            </Button>
        </>
    );
};

export default ReorderableAccordion;
