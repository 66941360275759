import React, { forwardRef, useState, useImperativeHandle, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { has } from 'lodash';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { updateSubmitTypeSuccess, setDirtyStep, formSubmitFail } from '../../../actions/lease';
import * as Lease from '../../../reducers/lease';

import { getLocation, getLessor, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';

import LessorFields from '../../../components/lease/mainScreen/common/LessorFields';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormMaskedText, FormTextRegular } from '../../../components/form/FormText';
import FormSection from '../../../components/form/FormSection';
import Line from '../../../common/components/Line';

const LESSOR_FORM = 'lessorForm';
const initState = {
    persons: [{ firstName: '', secondName: '' }]
};

const Lessor = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const reduxLessor = useSelector(getLessor);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [lessor, setLessor] = useState(initState);

    useEffect(() => {
        setLessor({
            ...initState,
            ...reduxLessor
        });
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(LESSOR_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const updateLessor = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/lessor`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;

        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateLessor(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.lessor')) {
                    return error.response.data.errors.lessor;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="lessor">
            <Form
                onSubmit={submitForm}
                initialValues={lessor}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push, removeBatch }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} id={LESSOR_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <CheckboxCheck
                                name={`isCorporation`}
                                className="is-corporation"
                                label="Lessor is a company"
                                onClick={value => {
                                    if (value) {
                                        let indexes = values?.persons?.map((_, i) => i) || [];
                                        removeBatch('persons', indexes);
                                    } else {
                                        push('persons', {});
                                    }
                                }}
                            />
                            {values.isCorporation && (
                                <>
                                    <div className="form-section">
                                        <p className="form-section__heading">Company</p>
                                        <div className="form-section__body">
                                            <FormTextRegular name={`corporationName`} label="Company name" required />
                                            <FormTextRegular
                                                name={`corporationAddress`}
                                                label="Company registered address"
                                                required
                                            />
                                            <FormMaskedText
                                                dataTest="lessor-abn"
                                                name="abn"
                                                label="ABN"
                                                mask="11 111 111 111"
                                                required
                                            />
                                            <FormMaskedText
                                                dataTest="lessor-acn"
                                                name="acn"
                                                label="ACN"
                                                mask="111 111 111"
                                            />
                                            <CheckboxCheck name="isRegisteredForGST" label="Registered for GST" />
                                        </div>
                                    </div>
                                    <Line />
                                </>
                            )}
                            {!values.isCorporation && (
                                <>
                                    <FieldArray name="persons" initialValue={lessor.persons}>
                                        {({ fields }) => (
                                            <React.Fragment>
                                                {fields.map((name, index) => {
                                                    return (
                                                        <>
                                                            <FormSection
                                                                key={index}
                                                                title={`Lessor ${index + 1}`}
                                                                showRemoveButton={index > 0}
                                                                removeButtonProps={{
                                                                    onClick: () => fields.remove(index),
                                                                    label: 'Remove'
                                                                }}
                                                            >
                                                                <LessorFields
                                                                    index={index}
                                                                    person={fields.value[index]}
                                                                    push={push}
                                                                    values={values}
                                                                />
                                                            </FormSection>
                                                        </>
                                                    );
                                                })}
                                            </React.Fragment>
                                        )}
                                    </FieldArray>
                                    <div className="form-section__button-group">
                                        <button
                                            type="button"
                                            className="action-button"
                                            onClick={() => {
                                                push('persons', {
                                                    email: ''
                                                });
                                            }}
                                        >
                                            Add more lessor
                                        </button>
                                    </div>
                                </>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Lessor);
