import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getTermDisplay, getAgencyTimezoneFromUser, formatDateDay } from '../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import {
    getResTenStepNo,
    isLeaseLocationQLD,
    isLeaseLocationSA,
    isLongTermRenewalSubLease
} from '../../../../../utils/agreementUtils';

function AgreementResidentialTerm({ leaseAllInfo }) {
    const loggedInUser = useSelector(getUserInfo);
    function renderTermData() {
        if (leaseAllInfo.term.periodic) {
            // return component for periodic term
            return (
                <p className="text">
                    The agreement starts on{' '}
                    <span className="bold">
                        {formatDateDay(leaseAllInfo.term.startDate, getAgencyTimezoneFromUser(loggedInUser))}
                    </span>
                </p>
            );
        } else {
            if (isLeaseLocationQLD(leaseAllInfo.location)) {
                // return component for QLD
                return (
                    <p className="text">
                        The term of agreement is <span className="bold">{leaseAllInfo.term.qty}</span>{' '}
                        <span className="bold period">
                            {leaseAllInfo.term.qty === 1 ? leaseAllInfo.term.period : `${leaseAllInfo.term.period}s`}
                        </span>
                        , agreement starts on{' '}
                        <span className="bold">
                            {formatDateDay(leaseAllInfo.term.startDate, getAgencyTimezoneFromUser(loggedInUser))}
                        </span>
                        <span>
                            {' '}
                            and ending on{' '}
                            <span className="bold">
                                {formatDateDay(leaseAllInfo.term.endDate, getAgencyTimezoneFromUser(loggedInUser))}
                            </span>
                            .
                        </span>
                    </p>
                );
            } else if (leaseAllInfo.term.startDate && leaseAllInfo.term.endDate) {
                // return component for non-QLD
                return (
                    <React.Fragment>
                        <p>
                            The term of the agreement is{' '}
                            {getTermDisplay(
                                leaseAllInfo.term.period,
                                parseISO(leaseAllInfo.term.startDate),
                                parseISO(leaseAllInfo.term.endDate),
                                loggedInUser
                            )}
                        </p>
                        {isLeaseLocationSA(leaseAllInfo.location) &&
                            isLongTermRenewalSubLease(leaseAllInfo.subLeaseType) && (
                                <p>
                                    Renewal Expiry Date: <strong>{leaseAllInfo.term.renewalExpiryDateFormatted}</strong>
                                </p>
                            )}
                    </React.Fragment>
                );
            }
        }
        return null;
    }
    return (
        <AgreementSection
            title="Term"
            step={getResTenStepNo(leaseAllInfo.location, 'term', leaseAllInfo.subLeaseType)}
            leaseAllInfo={leaseAllInfo}
        >
            {!!leaseAllInfo.term && <div className="data">{renderTermData()}</div>}
        </AgreementSection>
    );
}

export default AgreementResidentialTerm;
