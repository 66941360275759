import React from 'react';
import AgreementMainAuditTrail from '../../../../components/dashboard/InfoAgreementComponents/AgreementMainComponents/shared/AgreementMainAuditTrail';
import Button from '../../../../common/components/Button';

import {
    isDocumentCreateMode,
    isDocumentStatusActive,
    isDocumentStatusDraft,
    isDocumentStatusCancelled
} from '../../../../utils/generalUtils';
import ButtonGroup from '../../../../common/components/ButtonGroup';
import {
    FORM_SUBMIT_TYPE_SAVE,
    FORM_SUBMIT_TYPE_SEND,
    DOCUMENT_COMPLETION_FINISHED,
    DOCUMENT_COMPLETION_ERROR,
    DOCUMENT_TERMINATION_NOTICE
} from '../../../../config';

const CommonFooter = ({
    isDoc = true,
    currentDocument,
    documentEditMode,
    resend,
    viewPdf,
    previewPdf,
    isResending,
    isResendingSuccess,
    isResendingFail,
    isSendingSuccess,
    isSendingFail,
    isSending,
    form,
    isSaveDraft,
    hideResend,
    showLoadingIcon = true,
    dataTest
}) => {
    return (
        <React.Fragment>
            {!isDocumentCreateMode(documentEditMode) &&
                currentDocument &&
                currentDocument.auditTrail &&
                currentDocument.auditTrail.length > 0 && (
                    <AgreementMainAuditTrail leaseId={currentDocument.id} isDocument={true} />
                )}
            {currentDocument &&
                isDocumentStatusActive(currentDocument.status) &&
                currentDocument.completion &&
                currentDocument.completion.status === DOCUMENT_COMPLETION_ERROR && (
                    <React.Fragment>
                        <h6 className="button-group-header">Documents</h6>
                        <ButtonGroup
                            title="Copy of document"
                            buttonList={[
                                {
                                    onClick: () => viewPdf(currentDocument.id),
                                    disabled: false,
                                    className: 'logo-button',
                                    label: 'View PDF',
                                    icon: 'view'
                                }
                            ]}
                        />
                        <div className="button-row single">
                            {isSendingSuccess && (
                                <span className="savestatus-saved">
                                    <span className="icon savestatus-icon" />
                                    Document sent successfully
                                </span>
                            )}
                            {isSendingFail && (
                                <span className="savestatus-failed">
                                    <span className="icon savestatus-icon" />
                                    Document send failed
                                </span>
                            )}
                        </div>
                        <div className="button-row single">
                            <div className="button-footer-docs">
                                <Button
                                    primary
                                    className="btn-terminate"
                                    height={40}
                                    type="submit"
                                    // set submitType in here, then use that is handleSubmit to decide send or save
                                    onClick={() => form.change('submitType', FORM_SUBMIT_TYPE_SEND)}
                                    disabled={isSending}
                                >
                                    {isSending && <span className="savestatus-spinner-await-completion" />}
                                    Send
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            {(isDocumentCreateMode(documentEditMode) ||
                (currentDocument && !currentDocument.pdf && isDocumentStatusDraft(currentDocument.status)) ||
                !isDoc) && (
                <React.Fragment>
                    {isDoc && (
                        <div>
                            <h6 className="button-group-header">Documents</h6>
                            <ButtonGroup
                                title="Copy of document"
                                buttonList={[
                                    {
                                        onClick: previewPdf,
                                        className: 'logo-button',
                                        label: 'Preview PDF',
                                        icon: 'view',
                                        loading: isSending,
                                        disabled: isSending,
                                        dataTest: 'pdf-preview'
                                    }
                                ]}
                            />
                        </div>
                    )}
                    <div className="button-row single">
                        {isSendingSuccess && (
                            <span className="savestatus-saved">
                                <span className="icon savestatus-icon" />
                                Document {isSaveDraft ? 'saved' : 'sent'} successfully
                            </span>
                        )}
                        {isSendingFail && (
                            <span className="savestatus-failed">
                                <span className="icon savestatus-icon" />
                                Document {isSaveDraft ? 'save' : 'send'} failed
                            </span>
                        )}
                    </div>
                    <div className={`button-row ${isDoc ? '' : 'single'}`}>
                        {isDoc && (
                            <div className="button-footer-docs">
                                <Button
                                    secondary
                                    className="btn-terminate"
                                    height={40}
                                    type="submit"
                                    // set submitType in here, then use that is handleSubmit to decide send or save
                                    onClick={() => form.change('submitType', FORM_SUBMIT_TYPE_SAVE)}
                                    disabled={isSending}
                                >
                                    {isSending && <span className="savestatus-spinner-await-completion" />}
                                    Save
                                </Button>
                            </div>
                        )}
                        <div className="button-footer-docs">
                            <Button
                                primary
                                className="btn-terminate"
                                height={40}
                                type="submit"
                                // set submitType in here, then use that is handleSubmit to decide send or save
                                onClick={() => form.change('submitType', FORM_SUBMIT_TYPE_SEND)}
                                disabled={isSending}
                                data-test={dataTest}
                            >
                                {isSending && showLoadingIcon && (
                                    <span className="savestatus-spinner-await-completion" />
                                )}
                                Send
                            </Button>
                        </div>
                    </div>
                </React.Fragment>
            )}
            {currentDocument &&
                currentDocument.pdf &&
                !isDocumentCreateMode(documentEditMode) &&
                isDocumentStatusActive(currentDocument.status) &&
                (!currentDocument.completion || currentDocument.completion.status === DOCUMENT_COMPLETION_FINISHED) && (
                    <div className="section space-between">
                        <h6 className="button-group-header">Documents</h6>
                        <ButtonGroup
                            title="Copy of document"
                            buttonList={[
                                {
                                    onClick: () => viewPdf(currentDocument.id),
                                    disabled: false,
                                    className: 'logo-button',
                                    label: 'View PDF',
                                    icon: 'view'
                                }
                            ]}
                        />
                        <div className="button-row single">
                            {isResendingSuccess && (
                                <p className="button-result savestatus-saved">
                                    {currentDocument.docType === DOCUMENT_TERMINATION_NOTICE && (
                                        <React.Fragment>Sent succesfully</React.Fragment>
                                    )}
                                    {currentDocument.docType !== DOCUMENT_TERMINATION_NOTICE && (
                                        <React.Fragment>Resend successful</React.Fragment>
                                    )}
                                    <span className="icon savestatus-icon" />
                                </p>
                            )}
                            {isResendingFail && (
                                <p className="button-result savestatus-failed">
                                    {currentDocument.docType === DOCUMENT_TERMINATION_NOTICE && (
                                        <React.Fragment>Sending failed</React.Fragment>
                                    )}
                                    {currentDocument.docType !== DOCUMENT_TERMINATION_NOTICE && (
                                        <React.Fragment>Resend failed</React.Fragment>
                                    )}
                                </p>
                            )}
                        </div>
                        {!hideResend && (
                            <div className="button-row single">
                                <div className="button-footer-docs">
                                    <Button
                                        primary
                                        className="btn-terminate"
                                        disabled={isResending}
                                        type="button"
                                        onClick={() => resend(currentDocument.id, null)}
                                    >
                                        {currentDocument.docType === DOCUMENT_TERMINATION_NOTICE && (
                                            <React.Fragment>Send me PDF</React.Fragment>
                                        )}
                                        {currentDocument.docType !== DOCUMENT_TERMINATION_NOTICE && (
                                            <React.Fragment>Resend</React.Fragment>
                                        )}

                                        {isResending && <span className="savestatus-spinner-await-completion" />}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            {currentDocument &&
                !isDocumentCreateMode(documentEditMode) &&
                isDocumentStatusCancelled(currentDocument.status) && (
                    <div className="section space-between">
                        <h6 className="button-group-header">Documents</h6>
                        <ButtonGroup
                            title="Copy of document"
                            buttonList={[
                                {
                                    onClick: () => viewPdf(currentDocument.id),
                                    disabled: false,
                                    className: 'logo-button',
                                    label: 'View PDF',
                                    icon: 'view'
                                }
                            ]}
                        />
                    </div>
                )}
        </React.Fragment>
    );
};
export default CommonFooter;
