import * as React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';

import '../../sass/popupNotificationCount.scss';

class PopupNotificationCount extends React.Component {
    render() {
        const { notificationsCount, showWhenNone } = this.props;

        if (isNumber(notificationsCount) && (notificationsCount !== 0 || showWhenNone)) {
            return (
                <div className="notification-count">
                    <span>{notificationsCount}</span>
                </div>
            );
        }

        return null;
    }
}

PopupNotificationCount.propTypes = {
    notificationsCount: PropTypes.number,
    showWhenNone: PropTypes.bool
};

PopupNotificationCount.defaultProps = {
    showWhenNone: false
};

export default PopupNotificationCount;
