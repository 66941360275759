import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import { Form } from 'react-final-form';

import renewalSmsThread from '../../../assets/images/sms/renewal-sms-thread.png';
import { FormRadioGroup } from '../form/FormRadioGroup';
import RenewalIntentionIndicator from '../dashboard/agreementList/tables/TableRenewals/RenewalIntentionIndicator';
import ModalDialog from '../../common/components/ModalDialog';
import Button from '../../common/components/Button';

import '../../sass/intentionRenewalModal.scss';
import { getResTenLabel } from '../../utils/labelUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaseFromDashboard, isIntentionRenewalModalOpen } from '../../selectors/dashboard';
import { closeIntentionRenewalModal } from '../../actions/dashboard';
import { getLeaseAllInfo } from '../../selectors/lease';
import { PreLoader } from '../../common/components/PreLoader';
import { useRollbarProvider } from '../providers/RollbarProvider';

const IntentionRenewalModal = () => {
    const dispatch = useDispatch();
    const Rollbar = useRollbarProvider();

    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState('');
    const isOpen = useSelector(isIntentionRenewalModalOpen);
    const leaseAllInfo = useSelector(getLeaseAllInfo);
    const leaseFromDashboard = useSelector(getLeaseFromDashboard);
    const [lease, setLease] = useState(false);
    const [radioData, setRadioData] = useState([]);
    const [selectedTenantId, setSelectedTenantId] = useState(null);

    const closeModal = () => {
        dispatch(closeIntentionRenewalModal());
    };

    useEffect(() => {
        return () => {
            setError('');
        };
    }, [isOpen]);

    /**
     * The agreement can be passed in from the dashboard OR from the agreement summary
     * Decide which one to use in here and then use that for everything else
     */
    useEffect(() => {
        if ((leaseAllInfo && leaseAllInfo.tenant) || (leaseFromDashboard && leaseFromDashboard.tenant)) {
            const agreement = leaseAllInfo && leaseAllInfo.tenant ? leaseAllInfo : leaseFromDashboard;
            setLease(agreement);
            setRadioData(
                agreement.tenant.tenants.map(tenant => {
                    return {
                        label: `${tenant.fullName} (${tenant.phone})`,
                        value: tenant.id
                    };
                })
            );

            let selectedTenant = agreement.tenant.tenants.find(tenant => tenant.isPrimaryTenant);

            if (!selectedTenant) {
                selectedTenant = agreement.tenant.tenants[0];
            }
            setSelectedTenantId(selectedTenant.id);
        }
    }, [leaseAllInfo, leaseFromDashboard]);

    const handleInitiateRenewalIntention = values => {
        const tenant = values.tenant;
        const agreements = [{ id: lease.id, tenant }];
        setIsSending(true);
        axios
            .post(`api/renewal/send-renewal-intention`, { agreements })
            .then(closeModal)
            .catch(error => {
                setError('Something went wrong, please try it again');
                Rollbar.error(
                    'Failed to initiate a renewal intention',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
            })
            .finally(() => setIsSending(false));
    };

    const renderRightButton = () => {
        return (
            <div>
                <Button type="submit" primary className="btn-upgrade" height={50} disabled={isSending}>
                    Send
                </Button>
            </div>
        );
    };

    const renderLeftButton = () => {
        return (
            <Button secondary className="btn-close" height={50} onClick={closeModal} disabled={isSending}>
                Cancel
            </Button>
        );
    };

    return (
        <ModalDialog
            title="Send SMS renewal intention request"
            isOpen={isOpen}
            closeModal={closeModal}
            customBodyAndFooter={true}
            shouldCloseOnOverlayClick={false}
            className="modal-send-renewal-intention-modal-dialog"
            lease={lease}
        >
            {lease && lease.id && (
                <Form
                    initialValues={{
                        tenant: selectedTenantId
                    }}
                    onSubmit={handleInitiateRenewalIntention}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <form id="resend-landlord-information-statement-form" onSubmit={handleSubmit} noValidate>
                                <div className="modal-body">
                                    <div className="renewal-intent-sms-example">
                                        <div className="iphone-container">
                                            <img src={renewalSmsThread} alt="SMS example" />
                                        </div>
                                        <p className="expiry-info">{`* The ${getResTenLabel(
                                            'tenantLower',
                                            lease.location
                                        )} has 7 days to reply to the SMS`}</p>
                                    </div>
                                    {lease && lease.tenant && lease.tenant.tenants.length > 1 && (
                                        <p className="instructions">
                                            {`As we can’t get conflicting answers the sms can only be sent to one ${getResTenLabel(
                                                'tenantLower',
                                                lease.location
                                            )}
                                        please choose which ${getResTenLabel('tenantLower', lease.location)} below.`}
                                        </p>
                                    )}
                                    <div className="send-information-wrapper">
                                        <div className="send-information-email">
                                            <FormRadioGroup
                                                label=""
                                                name="tenant"
                                                radioGroupClass="wrapped-radio-group desktop-bordered"
                                                data={radioData}
                                                value={values?.tenant || ''}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <h4>Below is what happens depending on the reply:</h4>
                                        <ul>
                                            <li>
                                                <div className="left">
                                                    <RenewalIntentionIndicator
                                                        renewalIntention={{
                                                            isTenantRenewing: 'yes',
                                                            responseReceivedAt: true,
                                                            responseForNewLeaseTerm: '12 months'
                                                        }}
                                                    />
                                                </div>
                                                <div className="right">
                                                    <b>
                                                        They plan to renew <br />
                                                        {`Your button turns green and ${getResTenLabel(
                                                            'tenantLower',
                                                            lease.location
                                                        )} receives the following message:`}
                                                    </b>
                                                    <p className="quote">
                                                        So I can have the most informed conversation with your landlord,
                                                        can you please reply with your preferred lease term (eg 12
                                                        months)
                                                    </p>
                                                    <p className="quote">
                                                        <i>
                                                            <b>12 months</b>
                                                        </i>
                                                    </p>

                                                    <p className="quote">
                                                        {`Thank you, please note that this isn’t an offer of a lease, we will
                                                    be in touch once we get instruction from your ${getResTenLabel(
                                                        'landlordLower',
                                                        lease.location
                                                    )} - Test Town
                                                    Real Estate`}
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left">
                                                    <RenewalIntentionIndicator
                                                        renewalIntention={{
                                                            isTenantRenewing: 'unsure',
                                                            responseReceivedAt: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="right">
                                                    <b>
                                                        They are unsure <br />
                                                        {`Your button turns amber and ${getResTenLabel(
                                                            'tenantLower',
                                                            lease.location
                                                        )} receives the following message:`}
                                                    </b>
                                                    <p className="quote">
                                                        Ok, I’ll contact your shortly to discuss further - Test Town
                                                        Real Estate
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left">
                                                    <RenewalIntentionIndicator
                                                        renewalIntention={{
                                                            isTenantRenewing: 'no',
                                                            responseReceivedAt: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="right">
                                                    <b>
                                                        They plan to vacate <br />
                                                        {`Your button turns red and ${getResTenLabel(
                                                            'tenantLower',
                                                            lease.location
                                                        )} receives the following message:`}
                                                    </b>
                                                    <p className="quote">
                                                        Thank you, please note you will still need to formally notify us
                                                        as per the requirements of your lease - Test Town Real Estate
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {error && <div className="error-message">{error}</div>}
                                </div>
                                <div className="modal-footer">
                                    <div>{renderLeftButton()}</div>
                                    <div>{renderRightButton()}</div>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            )}
            {!(lease && lease.id) && <PreLoader />}
        </ModalDialog>
    );
};

export default memo(IntentionRenewalModal);
