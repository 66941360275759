import React from 'react';
import { FormEmail } from '../../../components/form/FormEmail';
import { FormTextRegular } from '../../../components/form/FormText';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';

const FlkAKeyStaffGroup = ({ index, removeStaff, values, disabled, saveStaffEdit, emailError }) => {
    let header = (
        <span className="removePerson">
            <h3>Watcher</h3>
            <button type="button" onClick={disabled ? () => {} : () => removeStaff(index)}>
                <span className="span-remove">Remove</span>
            </button>
        </span>
    );
    const canSaveStaff = staff => {
        return (
            staff.name &&
            staff.email &&
            (staff.subscribedToCheckoutNotifications ||
                staff.subscribedToReturnNotifications ||
                staff.subscribedToSmsReplyNotifications ||
                staff.subscribedToOverdueNotifications)
        );
    };

    return (
        <React.Fragment>
            {values.watchlist.staff[index].isEditMode && (
                <div className="tenant">
                    {header}
                    <div className="formBox">
                        <div className="formBox-column">
                            <div className="special-group-inner-container">
                                <FormTextRegular
                                    name={`watchlist.staff[${index}].name`}
                                    label="Name"
                                    disabled={disabled}
                                />
                                <p className="error-message">{emailError}</p>
                                <FormEmail name={`watchlist.staff[${index}].email`} label="Email" disabled={disabled} />
                                <p>
                                    <b>Opt into notifications</b>
                                </p>
                                <br />
                                <CheckboxCheck
                                    name={`watchlist.staff[${index}].subscribedToCheckoutNotifications`}
                                    label="Key Checkouts"
                                    disabled={disabled}
                                />
                                <CheckboxCheck
                                    name={`watchlist.staff[${index}].subscribedToReturnNotifications`}
                                    label="Key Returns"
                                    disabled={disabled}
                                />
                                <CheckboxCheck
                                    name={`watchlist.staff[${index}].subscribedToSmsReplyNotifications`}
                                    label="SMS reply received"
                                    disabled={disabled}
                                />
                                <CheckboxCheck
                                    name={`watchlist.staff[${index}].subscribedToOverdueNotifications`}
                                    label=" Key is overdue"
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </div>
                    <span className="staff-save">
                        <button
                            type="button"
                            onClick={() => saveStaffEdit(index)}
                            disabled={!canSaveStaff(values.watchlist.staff[index])}
                        >
                            <span className="span-remove">Close</span>
                        </button>
                    </span>
                </div>
            )}
        </React.Fragment>
    );
};

export default FlkAKeyStaffGroup;
