import {
    OPEN_ADDRESS_DETAILS_MODAL,
    CLOSE_ADDRESS_DETAILS_MODAL,
    CONFIRM_ADDRESS_DETAILS,
    CLEAR_ADDRESS_DETAILS
} from '../actions/types';
import { OPEN_EDIT_ADDRESS_MODAL, UPDATE_ADDRESS_REQUEST_SUCCESS } from './dashboard';
import { OPEN_LEASE_AGREEMENT_FORM } from './lease';

const initState = {
    detailsOpen: false,
    differentFromGoogle: false,
    noResultsFromGoogle: false,
    selectedAddress: null,
    selectedAddressEdited: null,
    editMode: false,
    detailsConfirmed: false,
    editAddressFormData: null
};

export default function search(state = initState, action) {
    switch (action.type) {
        case OPEN_ADDRESS_DETAILS_MODAL: {
            return {
                ...state,
                detailsOpen: true,
                detailsConfirmed: false,
                editMode: true,
                geoResult: action.payload.data.geoResult,
                selectedAddress: action.payload.data.selectedAddress,
                selectedAddressEdited: null,
                differentFromGoogle: action.payload.data.differentFromGoogle,
                noResultsFromGoogle: action.payload.data.noResultsFromGoogle
            };
        }
        case CLOSE_ADDRESS_DETAILS_MODAL: {
            return {
                ...state,
                detailsOpen: false,
                detailsConfirmed: false,
                editMode: false,
                differentFromGoogle: false,
                noResultsFromGoogle: false,
                selectedAddress: null,
                selectedAddressEdited: null
            };
        }

        case CONFIRM_ADDRESS_DETAILS: {
            return {
                ...state,
                detailsOpen: false,
                differentFromGoogle: false,
                noResultsFromGoogle: false,
                editMode: false,
                detailsConfirmed: true,
                selectedAddressEdited: action.payload.data.selectedAddress,
                editAddressFormData: action.payload.data.editAddressFormData
            };
        }

        case CLEAR_ADDRESS_DETAILS: {
            return {
                ...state,
                detailsOpen: false,
                editMode: false,
                selectedAddress: null,
                selectedAddressEdited: null,
                detailsConfirmed: false
            };
        }

        case UPDATE_ADDRESS_REQUEST_SUCCESS: {
            return {
                ...state,
                selectedAddressEdited: null,
                detailsOpen: false,
                detailsConfirmed: true,
                differentFromGoogle: false,
                noResultsFromGoogle: false,
                editMode: false
            };
        }

        case OPEN_EDIT_ADDRESS_MODAL: {
            return {
                ...state,
                editMode: true,
                detailsConfirmed: false
            };
        }

        case OPEN_LEASE_AGREEMENT_FORM: {
            return {
                ...state,
                detailsOpen: false,
                detailsConfirmed: false,
                editMode: false,
                selectedAddress: null,
                selectedAddressEdited: null
            };
        }
    }

    return state;
}
