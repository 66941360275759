import React from 'react';
import {
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_HOLIDAY_LETTING,
    LEASE_TYPE_SALES,
    LEASE_TYPE_AUCTION_AGREEMENTS
} from '../../../config';
import ResidentialTenancy from './ResidentialTenancy';
import Auction from './Auction';
import PropertyManagement from './PropertyManagement';
import SalesListings from './SalesListings';
import CommercialLease from './CommercialLease';
import HolidayLetting from './HolidayLetting';

function AgreementTable({ showModalInfoAgreement, leaseType, leaseStatus, agreementList }) {
    /**
     * @param {object} agreementInfo - lease agreement model to be shown.
     */

    function switchToLeaseType() {
        let table;
        switch (leaseType) {
            case LEASE_TYPE_RESIDENTIAL: {
                table = (
                    <ResidentialTenancy
                        leaseStatus={leaseStatus}
                        agreementList={agreementList}
                        leaseType={leaseType}
                        showModalInfoAgreement={showModalInfoAgreement}
                    />
                );
                break;
            } /*
            case LEASE_TYPE_PRIVATE_TREATY: {
                table = <PrivateTreaty
                    leaseStatus={leaseStatus}
                    agreementList={agreementList}
                    showModalInfoAgreement={(agreementInfo) => showModalInfoAgreement(agreementInfo)}
                />;
                break;
            }*/
            case LEASE_TYPE_SALES: {
                table = (
                    <SalesListings
                        leaseStatus={leaseStatus}
                        agreementList={agreementList}
                        leaseType={leaseType}
                        showModalInfoAgreement={showModalInfoAgreement}
                    />
                );
                break;
            }
            case LEASE_TYPE_AUCTION_AGREEMENTS: {
                table = (
                    <Auction
                        leaseStatus={leaseStatus}
                        agreementList={agreementList}
                        showModalInfoAgreement={showModalInfoAgreement}
                    />
                );
                break;
            }
            case LEASE_TYPE_PROPERTY_MANAGEMENT: {
                table = (
                    <PropertyManagement
                        leaseStatus={leaseStatus}
                        agreementList={agreementList}
                        showModalInfoAgreement={showModalInfoAgreement}
                    />
                );
                break;
            }
            case LEASE_TYPE_COMMERCIAL_LEASE: {
                table = (
                    <CommercialLease
                        leaseStatus={leaseStatus}
                        agreementList={agreementList}
                        showModalInfoAgreement={showModalInfoAgreement}
                    />
                );
                break;
            }
            case LEASE_TYPE_HOLIDAY_LETTING: {
                table = (
                    <HolidayLetting
                        leaseStatus={leaseStatus}
                        agreementList={agreementList}
                        showModalInfoAgreement={showModalInfoAgreement}
                    />
                );
                break;
            }
        }
        return table;
    }

    return <div className="tb-wrapper">{switchToLeaseType()}</div>;
}

export default AgreementTable;
