import React, { useEffect } from 'react';
import cx from 'classnames';
import { capitalize, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, SpinnerSizes } from '@flk/design-system/spinner';
import { Note, NoteType } from '@app/common/components/Note';
import { useIntercom } from 'react-use-intercom';

import {
    getIntegrationLeaseDetails,
    cleanIntegrationLeaseDataSync,
    enableIntegrationLeaseDataSync,
    updateIntegrationLeaseDataSync
} from '@app/actions/lease';
import { getAgreementInfo } from '@app/selectors/dashboard/agreementInfo';
import { getUserInfo } from '@app/selectors/user';
import { getIntegrationLeaseDataSync } from '@app/selectors/lease';

import { formatDateShortMonth, getAgencyTimezoneFromUser } from '@app/utils/dateUtils';
import { formatCurrencyWithCurrencySign, pluralize } from '@app/utils/formUtils';

import Button from '@app/common/components/Button';
import ToggleButtonV2 from '@app/common/components/ToggleButtonV2';
import Icon, { IconSize, Icons } from '@app/common/components/Icon';

import flkToPTreeImage from '../../../../../../../assets/images/flk-to-ptree.svg';

import styles from './AgreementResidentialPropertyTreeSync.module.scss';
import AttributeComparisonRow from './AttributeComparisonRow';

type AgreementResidentialPropertyTreeSyncProps = {
    leaseId: string;
};

type AgreementInfoType = {
    integration?: {
        loading: boolean;
        leaseDetails?: {
            prospect: boolean;
            term: {
                startDate: string;
                endDate: string;
            };
            rent: {
                amount: string;
                payablePeriod: string;
            };
        };
    };
};

export const AgreementResidentialPropertyTreeSync = ({ leaseId }: AgreementResidentialPropertyTreeSyncProps) => {
    const { show: openIntercom } = useIntercom();
    const dispatch = useDispatch();
    const agreementInfo = useSelector<unknown, AgreementInfoType>(getAgreementInfo);
    const loggedInUser = useSelector(getUserInfo);
    const agencyTimezone = getAgencyTimezoneFromUser(loggedInUser);

    const integrationDataSync: {
        enabled: boolean;
        fields: {
            field: string;
            name: string;
            selectedValue: string | number;
            isEqual?: boolean;
        }[];
    } = useSelector(getIntegrationLeaseDataSync);

    useEffect(() => {
        dispatch(getIntegrationLeaseDetails(leaseId));
    }, [dispatch, leaseId]);

    const formatDate = (d: string, fieldName = '') => {
        if (fieldName === 'term.endDate' && !d) return 'Periodic';
        if (!d) return '';
        return formatDateShortMonth(new Date(d), agencyTimezone);
    };

    const formatMoney = (amount: string) => {
        if (!amount) return '';
        return formatCurrencyWithCurrencySign(parseFloat(amount), true, 2);
    };

    // We don't want to show an error screen at the moment the call to API is not yet being called.
    // So we consider an undefined loading as `loading`
    const isLoading = agreementInfo?.integration?.loading || agreementInfo?.integration?.loading == undefined;
    const data = agreementInfo?.integration?.leaseDetails;

    const attributesToCompare: {
        field: string;
        label: string;
        name: string;
        formatter: (value: any, fieldName?: string) => unknown;
        skipSync?: boolean;
    }[] = [
        { field: 'term.startDate', label: 'Lease Start:', name: 'Lease Start Date', formatter: formatDate },
        { field: 'term.endDate', label: 'Lease End:', name: 'Lease End Date', formatter: formatDate },
        {
            field: 'rent.amount',
            label: 'Rent per Week*:',
            name: 'Rent per Week',
            formatter: formatMoney,
            skipSync: true
        },
        {
            field: 'rent.payablePeriod',
            label: 'Payable*:',
            name: 'Payable Period',
            formatter: capitalize,
            skipSync: true
        }
    ];

    const formatField = (field: string, value: string | number) => {
        const formatter = attributesToCompare.find(attr => attr.field == field)?.formatter;
        return formatter ? formatter(value, field) : value;
    };

    const comparisonData = attributesToCompare.map(attr => {
        const value1 = get(agreementInfo, attr.field);
        const value2 = get(data, attr.field);

        const value1Formatted = attr.formatter ? attr.formatter(value1, attr.field) : value1;
        const value2Formatted = attr.formatter ? attr.formatter(value2, attr.field) : value2;

        return {
            ...attr,
            value1,
            value2,
            value1Formatted,
            value2Formatted,
            isEqual: value1Formatted == value2Formatted
        };
    });

    const conflicts = comparisonData.filter(d => !d.isEqual);
    const hasConflicts = conflicts?.length > 0;
    const synchableConflicts = conflicts.filter(c => !c.skipSync);

    const toggleEnableSync = () => {
        if (!integrationDataSync.enabled) {
            const initialSyncData = comparisonData
                .filter(c => !c.skipSync)
                .map(d => ({
                    field: d.field,
                    name: d.name,
                    value1: d.value1,
                    value2: d.value2,
                    // FLK value is the selected value by default
                    selectedValue: d.value1,
                    skipSync: d.skipSync,
                    isEqual: d.isEqual
                }));
            dispatch(enableIntegrationLeaseDataSync(initialSyncData));
        } else {
            dispatch(cleanIntegrationLeaseDataSync());
        }
    };

    const getSelectedValue = (field: string) => {
        const fieldData = integrationDataSync.fields.find(d => d.field === field);
        return fieldData ? fieldData.selectedValue : '';
    };

    return (
        <div className={cx('section', styles.leaseIntegrationSection)}>
            <h2>Save time and rework by syncing attributes to Property Tree</h2>
            <div className={styles.leaseIntegrationSectionContent}>
                <span>
                    Made any changes? Review and select which attributes to sync across from this Agreement or keep in
                    Property Tree by clicking on 'Push Data to Property Tree' below.
                </span>
                {isLoading && (
                    <div className={styles.loadingContainer}>
                        <Spinner size={SpinnerSizes.MEDIUM} />
                        <div className={styles.loadingText}>Connecting to Property Tree...</div>
                    </div>
                )}
                {!isLoading && !data && (
                    <Note title="Unable to connect to Property Tree" type={NoteType.ERROR}>
                        We are unable to connect with Property Tree due to a technical issue. Please try again at a
                        later time or if the problem persists, contact{' '}
                        <Button className={styles.actionLink} onClick={() => openIntercom()}>
                            Customer Support
                        </Button>{' '}
                        for more information.
                    </Note>
                )}
                {!isLoading && data && (
                    <>
                        {data.prospect ? (
                            <Note
                                title="Property does not have a linked active tenancy in PropertyTree"
                                type={NoteType.WARNING2}
                            >
                                We noticed that this property does not have a linked active tenancy in PropertyTree.
                                This means we are not able to send back the PDF copy of the lease, or update any
                                conflicting attributes in PropertyTree. Please add a tenancy in PropertyTree first, or
                                convert the prospect into an active tenancy before moving this agreement to completed so
                                that we can sync the documents and data.
                            </Note>
                        ) : (
                            <>
                                {hasConflicts ? (
                                    <Note title="Conflicting data found on Property Tree..." type={NoteType.WARNING2}>
                                        We noticed there are conflicting attributes between this Agreement to the data
                                        currently on Property Tree. Avoid further conflicts by updating attributes from
                                        FLK it over now.
                                    </Note>
                                ) : (
                                    <Note
                                        title="Attributes currently match those found in Property Tree."
                                        type={NoteType.SUCCESS}
                                    />
                                )}
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={flkToPTreeImage} className={styles.flkToPTreeImage} alt="logo" />
                                </div>
                                <div className={styles.matchingSection}>
                                    <table className={styles.matchingTable}>
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <th>Attributes:</th>
                                                <th>This Agreement</th>
                                                <th>In Property Tree</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {comparisonData.map(cdata => (
                                                <AttributeComparisonRow
                                                    key={cdata.field}
                                                    cdata={cdata}
                                                    disabled={!integrationDataSync.enabled || cdata.isEqual}
                                                    value={getSelectedValue(cdata.field)}
                                                    onChange={(field, value) => {
                                                        dispatch(
                                                            updateIntegrationLeaseDataSync({
                                                                field,
                                                                value
                                                            })
                                                        );
                                                    }}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className={styles.matchingSummary}>
                                        <ToggleButtonV2
                                            label="Push Data to Property Tree"
                                            checked={integrationDataSync.enabled}
                                            onClick={toggleEnableSync}
                                            dataId={`rta-ptree-integration-switch-${leaseId}`}
                                        />
                                        {hasConflicts ? (
                                            <>
                                                <div style={{ flex: 1 }}>
                                                    {integrationDataSync.enabled ? (
                                                        <>
                                                            {integrationDataSync?.fields
                                                                ?.filter(fieldData => !fieldData?.isEqual)
                                                                ?.map((fieldData, index: number) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={styles.selectedValue}
                                                                        >
                                                                            {formatField(
                                                                                fieldData.field,
                                                                                fieldData.selectedValue
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            {comparisonData
                                                                .filter(d => d.isEqual && !d.skipSync)
                                                                ?.map((fieldData, index: number) => (
                                                                    <div key={index} className={styles.equalValue}>
                                                                        {fieldData.value1Formatted}
                                                                    </div>
                                                                ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {synchableConflicts.length > 0 && (
                                                                <div className={styles.conflictCount}>
                                                                    <Icon
                                                                        className={styles.errorIcon}
                                                                        icon={Icons.NOTE_ERROR}
                                                                        size={IconSize.SMALL}
                                                                    />{' '}
                                                                    <span>
                                                                        {synchableConflicts.length}{' '}
                                                                        {pluralize(
                                                                            synchableConflicts.length,
                                                                            'Conflict'
                                                                        )}{' '}
                                                                        found!
                                                                    </span>
                                                                </div>
                                                            )}
                                                            <ul>
                                                                {synchableConflicts.map(c => (
                                                                    <li key={`item-${c.field}`}>{c.name}</li>
                                                                ))}
                                                            </ul>
                                                        </>
                                                    )}
                                                </div>
                                                <div className={styles.matchingSummaryDisclaimer}>
                                                    *Rent-related attributes currently not supported. Any attribute
                                                    changes will have to be done manually in Property Tree.
                                                </div>
                                            </>
                                        ) : (
                                            <div style={{ flex: 1, marginTop: '1rem' }}>
                                                Everything is up to date! There is nothing pending from you to do here.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
