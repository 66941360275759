import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils';
import { LeaseAllInfo } from './types';

type Props = {
    leaseAllInfo: LeaseAllInfo,
}

export default function AgreementClPermittedUse({ leaseAllInfo }: Props) {
    const part = 'permittedUse';
    if (!leaseAllInfo?.permittedUse) {
        return <></>;
    }
    const { location, permittedUse } = leaseAllInfo;
    return (
        <div>
            <AgreementSection
                title={getClStepTitle(location, part)}
                step={getClStepNo(location, part)}
                leaseAllInfo={leaseAllInfo}
            >
                {permittedUse && (
                    <div className="data data-row">
                        <div className="data-string">
                            <span className="data-contact">The permitted use must only be used for:</span>
                        </div>
                        <p className="text">{permittedUse.usedFor}</p>
                    </div>
                )}
            </AgreementSection>
        </div>
    );
}
