import React, { useEffect } from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps } from 'react-final-form';
import * as RadixSelect from '@radix-ui/react-select';

import Icon, { IconSize, Icons } from '../../common/components/Icon';
import Card, { CardStyles } from '../../common/components/cards/Card';
import { getFormError } from '../../utils/finalFormUtils';

import styles from './FormDropdown.module.scss';

export enum DropdownTriggerVariant {
    RAISED,
    FLAT
}

export enum DropdownTriggerSize {
    SMALL,
    MEDIUM
}

export type DropdownOption = {
    value: string;
    label: string;
    disabled?: boolean;
    disabledMessage?: string;
};

type DropdownFieldProps = {
    name: string;
    label?: string;
    ariaLabel?: string;
    contentHeading?: string;
    options: DropdownOption[];
    placeholder?: React.ReactNode;
    alwaysShowPlaceholder?: boolean;
    disabled?: boolean;
    className?: string;
    triggerClassName?: string;
    optionsClassName?: string;
    required?: boolean;
    validateOnTouch?: boolean;
    defaultOpen?: boolean;
    onClose?: () => void;
    triggerVariant?: DropdownTriggerVariant;
    triggerIconSize: IconSize;
    showTriggerIcon?: boolean;
    triggerSize?: DropdownTriggerSize;
    dataTest?: string;
};

type DropdownProps = DropdownFieldProps & FieldRenderProps<string, HTMLElement>;

const Dropdown: React.FC<DropdownProps> = ({
    className,
    triggerClassName,
    optionsClassName,
    input,
    options,
    label,
    ariaLabel,
    contentHeading,
    disabled,
    required,
    meta,
    alwaysShowPlaceholder,
    validateOnTouch = true,
    placeholder = 'Select...',
    defaultOpen,
    onClose,
    triggerVariant = DropdownTriggerVariant.RAISED,
    triggerIconSize = IconSize.DEFAULT,
    triggerSize = DropdownTriggerSize.MEDIUM,
    showTriggerIcon = true,
    dataTest
}) => {
    const error = getFormError(meta, validateOnTouch);

    useEffect(() => {
        if (defaultOpen) {
            input.onFocus();
        }
    }, [defaultOpen, input]);

    return (
        <div className={cx(styles.dropdown, className, { [styles.disabled]: disabled, [styles.withError]: !!error })}>
            {label && (
                <label className={styles.label} htmlFor={input.name}>
                    {label + (required ? '*' : '')}
                </label>
            )}
            <RadixSelect.Root
                disabled={disabled}
                name={input.name}
                value={input.value || undefined}
                onValueChange={(value: any) => {
                    input.onChange(value);
                }}
                onOpenChange={(isOpen: boolean) => {
                    if (isOpen) {
                        input.onFocus();
                    } else {
                        // Ensure that input.onBlur() is called after input.onChange()
                        setTimeout(() => {
                            input.onBlur();
                        });
                        onClose?.();
                    }
                }}
                defaultOpen={defaultOpen}
                aria-label={ariaLabel}
            >
                <RadixSelect.Trigger
                    className={cx(styles.trigger, triggerClassName, {
                        [styles.raised]: triggerVariant === DropdownTriggerVariant.RAISED,
                        [styles.flat]: triggerVariant === DropdownTriggerVariant.FLAT,
                        [styles.small]: triggerSize === DropdownTriggerSize.SMALL
                    })}
                    data-test={dataTest}
                >
                    {alwaysShowPlaceholder ? (
                        <RadixSelect.Value>{placeholder}</RadixSelect.Value>
                    ) : (
                        <RadixSelect.Value placeholder={placeholder} />
                    )}

                    {showTriggerIcon && (
                        <RadixSelect.Icon className={styles.iconWrapper}>
                            <Icon icon={Icons.CHEVRON_DOWN} className={styles.icon} size={triggerIconSize} />
                        </RadixSelect.Icon>
                    )}
                </RadixSelect.Trigger>
                <RadixSelect.Portal className={styles.dropdownPortal}>
                    <RadixSelect.Content position="popper">
                        <Card style={CardStyles.SQUARE} className={cx(styles.options, optionsClassName)}>
                            {contentHeading && (
                                <>
                                    <div className={styles.contentHeading} aria-hidden>
                                        {contentHeading}
                                    </div>
                                </>
                            )}
                            {options.map(option => (
                                <>
                                    <RadixSelect.Item
                                        key={`${input.name}-${option.value}`}
                                        className={styles.option}
                                        value={option.value}
                                        disabled={option.disabled}
                                        data-test={`${dataTest}-${option.value}`}
                                    >
                                        <RadixSelect.ItemText>{option.label}</RadixSelect.ItemText>
                                        {option.disabledMessage && option.disabled && (
                                            <div className={styles.disabledMessage}>{option.disabledMessage}</div>
                                        )}
                                    </RadixSelect.Item>
                                </>
                            ))}
                        </Card>
                    </RadixSelect.Content>
                </RadixSelect.Portal>
            </RadixSelect.Root>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

const DropdownField: React.FC<DropdownFieldProps> = props => {
    return <Field component={Dropdown} {...props} />;
};

export default DropdownField;
