import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { subscriptionStatus } from '../../../config';

import Panel from '../../../common/components/Panel';
import Button from '../../../common/components/Button';
import ChangePaymentDetailsModal from '../../../components/settings/subscription/modals/ChangePaymentDetailsModal.tsx';
import { retryPayment } from '../../../actions/subscription';
import { capitalize } from '../../../utils/generalUtils';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';
import AccountBalanceSharpIcon from '@material-ui/icons/AccountBalanceSharp';
import { fetchTariffPlan } from '../../../actions/account';
import { getDefaultPaymentMethod } from '../../../actions/account';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { isBusinessUserAccount } from '../../../utils/userUtils';

const PAYMENT_METHOD_TYPE_DIRECT_DEBIT = 'au_becs_debit';
const PAYMENT_METHOD_TYPE_CARD = 'card';

// Create the Stripe object yourself...

function getStripe(agency) {
    if (isBusinessUserAccount(agency.accountType)) {
        return loadStripe(GENERAL_STRIPE_API_KEY);
    }
    return loadStripe(STRIPE_API_KEY);
}

class PaymentDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenModal: false,
            updatePaymentSuccess: false
        };

        this.props.getDefaultPaymentMethod(props.agencyId);
    }

    static getDerivedStateFromProps(nextProps) {
        // Close the modal if the payment details were updated successfully
        if (!nextProps.isUpdatingPaymentDetails && nextProps.updatedPaymentDetailsSuccess) {
            return {
                isOpenModal: false,
                updatePaymentSuccess: true
            };
        }
        // reset the isUpdatePaymentDetails state when it is being updated again
        if (nextProps.isUpdatingPaymentDetails) {
            return {
                updatePaymentSuccess: false
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        // If payment was successfully updated
        if (prevState.updatePaymentSuccess !== this.state.updatePaymentSuccess) {
            this.props.getDefaultPaymentMethod(this.props.agencyId);
        }

        // If the payment was retried, get the plan again
        if (prevProps.isRetryingPayment && !this.props.isRetryingPayment) {
            setTimeout(() => {
                this.props.getTariffPlan(this.props.agencyId);
            }, 7500);
        }
    }

    toggleUpdateModal = () => {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    };

    renderChangeButton = paymentMethod => {
        if (this.props.getDefaultPaymentMethodRequestPending) {
            return <React.Fragment />;
        }

        return (
            <Button onClick={this.toggleUpdateModal}>
                {paymentMethod && 'Change'} {!paymentMethod && 'Add'} payment method
                <i className="icon-card" />
            </Button>
        );
    };

    retryPayment = () => {
        const { agencyId } = this.props;

        if (!this.props.isRetryingPayment) {
            return this.props.retryPayment(agencyId);
        }
    };

    renderRetryButton = (paymentMethod, tariffPlan) => {
        if (
            this.props.getTariffPlanRequestPending ||
            !paymentMethod ||
            (paymentMethod && paymentMethod.type === PAYMENT_METHOD_TYPE_DIRECT_DEBIT) ||
            !tariffPlan ||
            (tariffPlan &&
                tariffPlan.status !== subscriptionStatus.PAST_DUE &&
                tariffPlan.status !== subscriptionStatus.UNPAID)
        ) {
            return null;
        }

        return (
            <Button onClick={this.retryPayment}>
                <AutorenewSharpIcon>Retry</AutorenewSharpIcon>
                Retry payment
            </Button>
        );
    };

    getDefaultPaymentMethod = () => {
        return this.props.defaultPaymentMethod;
    };

    getCardType = paymentMethod => {
        return paymentMethod.card ? paymentMethod.card.brand : '';
    };

    getCardString = paymentMethod => {
        if (paymentMethod.card) {
            if (paymentMethod.card.brand === 'other') {
                return `card ending in ${paymentMethod.card.last4}`;
            }
            return `${capitalize(this.getCardType(paymentMethod))} ending in ${paymentMethod.card.last4}`;
        }
        return '';
    };

    renderPaymentMethod = paymentMethod => {
        if (paymentMethod && paymentMethod.type === PAYMENT_METHOD_TYPE_CARD) {
            return (
                <React.Fragment>
                    <p className="text-gray">Card details</p>
                    <div className="card-details">
                        <div className={'image-container'}>
                            <div className={'card-icon card-' + this.getCardType(paymentMethod)} />
                        </div>
                        {paymentMethod && this.getCardString(paymentMethod)}
                    </div>
                </React.Fragment>
            );
        } else if (paymentMethod && paymentMethod.type === PAYMENT_METHOD_TYPE_DIRECT_DEBIT) {
            return (
                <React.Fragment>
                    <p className="text-gray">Direct debit details</p>
                    <div className="card-details">
                        <div className="image-container">
                            <div className="card-icon card-direct-debit">
                                <AccountBalanceSharpIcon>Direct Debit</AccountBalanceSharpIcon>
                            </div>
                        </div>
                        {paymentMethod &&
                            `BSB: ${paymentMethod.au_becs_debit.bsb_number} NR: *****${paymentMethod.au_becs_debit.last4}`}
                    </div>
                    <table className="description-table">
                        <tbody>
                            <tr>
                                <td>Account name</td>
                                <td>{paymentMethod.billing_details.name}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{paymentMethod.billing_details.email}</td>
                            </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <p className="text-gray">Card details</p>
                    <div className="card-details">
                        <div className={'image-container'}>
                            <div className={'card-icon'} />
                        </div>
                    </div>
                    <p>No payment details saved, please add a payment method.</p>
                </React.Fragment>
            );
        }
    };

    render() {
        let {
            agency: { tariffPlan }
        } = this.props;

        return (
            <Panel
                title="Payment details"
                rightButton={this.renderChangeButton(this.getDefaultPaymentMethod())}
                leftButton={this.renderRetryButton(this.getDefaultPaymentMethod(), tariffPlan)}
            >
                {this.renderPaymentMethod(this.getDefaultPaymentMethod())}
                {this.props.isRetryingPayment && <p>Retrying payment...</p>}
                {this.props.retriedPaymentSuccess && <p>Retrying the payment was successful</p>}
                {this.props.retriedPaymentFail && (
                    <p className="has-error">
                        <b>Retry payment failed.</b>
                        <br />
                        <br />
                        {this.props.retryPaymentError && <span>{this.props.retryPaymentError}</span>}
                    </p>
                )}
                {this.props.agency && this.props.agency.accountType && (
                    <Elements stripe={getStripe(this.props.agency)}>
                        <ElementsConsumer>
                            {({ stripe, elements }) => (
                                <ChangePaymentDetailsModal
                                    stripe={stripe}
                                    elements={elements}
                                    isOpen={this.state.isOpenModal}
                                    closeModal={this.toggleUpdateModal}
                                />
                            )}
                        </ElementsConsumer>
                    </Elements>
                )}
            </Panel>
        );
    }
}

PaymentDetails.propTypes = {
    agency: PropTypes.shape({
        card: PropTypes.shape({
            number: PropTypes.string
        })
    }),
    isUpdatingPaymentDetails: PropTypes.bool,
    updatedPaymentDetailsSuccess: PropTypes.bool,
    isRetryingPayment: PropTypes.bool,
    retriedPaymentSuccess: PropTypes.bool,
    retriedPaymentFail: PropTypes.bool,
    getDefaultPaymentMethodSuccess: PropTypes.bool,
    getDefaultPaymentMethodRequestPending: PropTypes.bool
};

PaymentDetails.defaultProps = {
    agency: {
        card: {
            number: ''
        }
    }
};

const mapStateToProps = state => {
    return {
        agencyId: state.user.agencyId,
        agency: state.account.agency,
        defaultPaymentMethod: state.account.agency.defaultPaymentMethod,
        isRetryingPayment: state.subscription.isRetryingPayment,
        getTariffPlanSuccess: state.account.getTariffPlanSuccess,
        getTariffPlanRequestPending: state.account.getTariffPlanRequestPending,
        getDefaultPaymentMethodSuccess: state.account.getDefaultPaymentMethodSuccess,
        getDefaultPaymentMethodRequestPending: state.account.getDefaultPaymentMethodRequestPending,
        retriedPaymentSuccess: state.subscription.retriedPaymentSuccess,
        retriedPaymentFail: state.subscription.retriedPaymentFail,
        isUpdatingPaymentDetails: state.subscription.isUpdatingPaymentDetails,
        updatedPaymentDetailsSuccess: state.subscription.updatedPaymentDetailsSuccess,
        retryPaymentError: state.subscription.retryPaymentError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        retryPayment: agencyId => {
            dispatch(retryPayment(agencyId));
        },
        getTariffPlan: agencyId => {
            dispatch(fetchTariffPlan(agencyId));
        },
        getDefaultPaymentMethod: agencyId => {
            dispatch(getDefaultPaymentMethod(agencyId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
