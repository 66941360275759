import { userRoles, routes, tabs } from '../config';
import { indexOf } from 'lodash';

// not allowed routes / remove side bar
const routesPermissions = {
    [userRoles.ROLE_AGENCY_PRINCIPAL]: [],
    [userRoles.ROLE_AGENCY_ADMIN]: [],
    [userRoles.ROLE_AGENCY_TEAMMATE]: [
        routes.ROUTE_TEAM,
        routes.ROUTE_SUBSCRIPTION,
        routes.ROUTE_AGENCY_REPORTS,
        routes.ROUTE_DOCUMENT_LINKS,
        routes.ROUTE_PAYMENTS,
        routes.ROUTE_CONTACTS,
        routes.ROUTE_REPORTS,
        routes.ROUTE_ALL_DOCUMENTS,
        routes.ROUTE_BULK_IMPORT,
        routes.ROUTE_EMAIL_STUDIO
    ]
};

// not allowed to edit settings Pages
const editableSettingPages = {
    [userRoles.ROLE_AGENCY_PRINCIPAL]: [],
    [userRoles.ROLE_AGENCY_ADMIN]: [],
    [userRoles.ROLE_AGENCY_TEAMMATE]: [
        tabs.TAB_AGENCY_DETAILS,
        tabs.TAB_EMERGENCY_CONTACTS,
        tabs.TAB_TEMPLATES,
        tabs.TAB_SUBSCRIPTION
    ]
};

/**
 * Function to determine if route is allowed for a specific user.
 * @param role
 * @param route
 * @returns {boolean}
 */
export function isRouteAllowed(role, route) {
    if (indexOf(routesPermissions[role], route) !== -1) {
        return false;
    }
    return true;
}

/**
 * Function to determent if pages should be editable or not for a specific user
 * @param role
 * @param page
 * @returns {boolean}
 */
export function editPermissions(role, page) {
    if (indexOf(editableSettingPages[role], page) !== -1) {
        return false;
    }
    return true;
}
