import React from 'react';

import '../../sass/support.scss';
import { isAgencyUserAccount } from '../../utils/userUtils';
import { useAccountProvider } from '../../components/providers/AccountProvider';
import AgencySupportText from './support/AgencySupportText';
import AccountSupportText from './support/AccountSupportText';

const Support = () => {
    const accountType = useAccountProvider();

    if (isAgencyUserAccount(accountType)) {
        return <AgencySupportText />;
    } else {
        return <AccountSupportText />;
    }
};

export default Support;
