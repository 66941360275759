import React, { forwardRef, useState, useImperativeHandle, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';

import { updateSubmitTypeSuccess, setDirtyStep, formSubmitFail, removeSuggestion } from '../../../actions/lease';
import { LandlordPersonGroup } from '../../../components/lease/mainScreen/common/LandlordPersonGroup';
import { LandlordPersonCorporationGroup } from '../../../components/lease/mainScreen/common/LandlordPersonCorporationGroup';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormTextRegular } from '../../../components/form/FormText';
import { FormPhone } from '../../../components/form/FormPhone';
import { NSW_STATE, QLD_STATE, VIC_STATE, SA_STATE } from '../../../config';
import { getUserAgency } from '../../../selectors/agency';
import { getAgencyContactNumber } from '../../../selectors/user';
import { getLocation, getLandlord, getStep, getDirtyStep, getLeaseType, getAddress } from '../../../selectors/lease';
import '../../../sass/landlord.scss';
import axios from 'axios';
import * as Lease from '../../../reducers/lease';
import { getResTenLabel } from '../../../utils/labelUtils';
import { has, isEmpty, pullAt } from 'lodash';
import * as validators from '../../../components/Validate';
import SaLandlordFields from './SA/LandlordFields';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { RTA_QLD_CORPORATION_LESSOR } from '../../../constants/featureFlags';
const LANDLORD_FORM = 'landlordForm';

const Landlord = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const isRtaQldCorporationLessor = useFeatureFlag(RTA_QLD_CORPORATION_LESSOR);
    const agency = useSelector(getUserAgency);
    const reduxLandlord = useSelector(getLandlord);
    const agencyContactNumber = useSelector(getAgencyContactNumber);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [landlord, setLandlord] = useState({ servicesOfNoticesEmails: [] });
    const [addEmailFieldError, setAddEmailFieldError] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // For SA state we can select address or email in electronic service. And we use boolean in DB.
        // Therefore we should convert this data
        let landlordConsentToElectronicService = reduxLandlord.landlordConsentToElectronicService;
        if (location === SA_STATE) {
            landlordConsentToElectronicService = reduxLandlord.landlordConsentToElectronicService ? '1' : '0';
        }
        setLandlord({
            ...reduxLandlord,
            noticeEmailAddress: reduxLandlord.useAgencyEmailAddress
                ? agency.details.email
                : reduxLandlord.noticeEmailAddress,
            phone: reduxLandlord.useAgentsPhone ? agencyContactNumber : reduxLandlord.phone,
            landlordConsentToElectronicService
        });
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(LANDLORD_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const updateLandlord = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/landlord`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;

        values.noticeEmailAddress = values.useAgencyEmailAddress ? agency.details.email : values.noticeEmailAddress;
        if (location !== SA_STATE) {
            values.noticeAddress = values.useAgencyAddress ? agency.details.address : values.noticeAddress;
        }
        values.phone = values.useAgentsPhone ? agencyContactNumber : values.phone;
        if (location === NSW_STATE && !values.landlordConsentToElectronicService) {
            values.noticeEmailAddress = '';
        }

        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateLandlord(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_LANDLORD_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.landlord')) {
                    if (error.response.data.errors.landlord.servicesOfNoticesEmails) {
                        setErrors({
                            servicesOfNoticesEmails: error.response.data.errors.landlord.servicesOfNoticesEmails
                        });
                    }
                    return error.response.data.errors.landlord;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const validate = value => {
        if (isEmpty(value)) {
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        }

        return true;
    };

    const addEmail = (email, values) => {
        setAddEmailFieldError('');
        let isValid = validate(email);
        if (!isValid) {
            return;
        }

        let emails = [...landlord.servicesOfNoticesEmails];
        emails.push(email);
        setLandlord({ ...values, servicesOfNoticesEmails: emails, servicesOfNoticesEmail: '' });
    };

    const removeEmail = (values, index) => {
        let emails = [...landlord.servicesOfNoticesEmails];

        pullAt(emails, index);

        setLandlord({ ...values, servicesOfNoticesEmails: emails });
    };

    const pressEnter = (event, values) => {
        let value = event.target.value;
        // Only if the user pressed enter
        if (event.keyCode === 13) {
            // Add email to the list and wipe out the value of the field
            addEmail(value, values);
        }
    };

    const handleBlur = (event, setValueCallback) => {
        const value = event.target.value;
        if (typeof setValueCallback === 'function') {
            setValueCallback(value);
        }
    };

    // Define a function to handle the removal of a landlord from the list
    const handleRemoveLandlord = (values, index) => {
        // Remove person from the list
        values.remove(index);
        // This will remove all the suggestions for the removed person
        dispatch(removeSuggestion(`landlords[${index}]`));
    };

    return (
        <div className="landlord">
            <Form
                onSubmit={submitForm}
                initialValues={landlord}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push, removeBatch }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} id={LANDLORD_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FieldArray name="persons" initialValue={landlord.persons}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <div className="persons" key={index}>
                                                    {!isRtaQldCorporationLessor && location === QLD_STATE ? (
                                                        <LandlordPersonGroup
                                                            key={index}
                                                            index={index}
                                                            person={fields.value[index]}
                                                            removePerson={() => handleRemoveLandlord(fields, index)}
                                                            location={location}
                                                        />
                                                    ) : (
                                                        <LandlordPersonCorporationGroup
                                                            key={index}
                                                            index={index}
                                                            person={fields.value[index]}
                                                            removePerson={() => handleRemoveLandlord(fields, index)}
                                                            location={location}
                                                            form={form}
                                                        />
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button">
                                <button
                                    type="button"
                                    onClick={() => {
                                        push('persons', {
                                            firstName: '',
                                            secondName: '',
                                            ACN: '',
                                            postcode: ''
                                        });
                                    }}
                                >
                                    {getResTenLabel('addLandlord', location)}
                                </button>
                            </div>
                            {[QLD_STATE, VIC_STATE].includes(location) && (
                                <div>
                                    <FormTextRegular
                                        name="noticeEmailAddress"
                                        label={getResTenLabel('noticeEmailAddress', location)}
                                        disabled={values.useAgencyEmailAddress}
                                    />
                                    <div className="form-checkbox">
                                        <CheckboxCheck
                                            name="useAgencyEmailAddress"
                                            label="Use agency email address for services of notices"
                                        />
                                    </div>
                                    <FormPhone
                                        className="landlord-phone"
                                        name="phone"
                                        label="Phone"
                                        disabled={values.useAgentsPhone}
                                    />
                                    <div className="form-checkbox">
                                        <CheckboxCheck name="useAgentsPhone" label="Use agency contact number" />
                                    </div>
                                </div>
                            )}
                            {location === NSW_STATE && (
                                <div>
                                    <div className="form-checkbox">
                                        <CheckboxCheck
                                            name="landlordConsentToElectronicService"
                                            label="Consent to the electronic service of notices and documents?"
                                        />
                                    </div>
                                    {values.landlordConsentToElectronicService && (
                                        <React.Fragment>
                                            <FormTextRegular
                                                dataTest={'landlord-noticeEmailAddress'}
                                                name="noticeEmailAddress"
                                                label="Email address for serving notices and documents:"
                                                disabled={values.useAgencyEmailAddress}
                                            />
                                            <div className="form-checkbox">
                                                <CheckboxCheck
                                                    name="useAgencyEmailAddress"
                                                    label="Use agency email for consent of electronic servicing of notices"
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                            {location === SA_STATE && (
                                <SaLandlordFields
                                    values={values}
                                    removeEmail={removeEmail}
                                    errors={errors}
                                    addEmailFieldError={addEmailFieldError}
                                    pressEnter={pressEnter}
                                    handleBlur={e =>
                                        handleBlur(e, value => {
                                            addEmail(value, values);
                                        })
                                    }
                                    push={push}
                                    removeBatch={removeBatch}
                                    landlord={landlord}
                                />
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Landlord);
