import { DOCUMENT_CUSTOM } from '../../../../../config';
import React from 'react';
import * as PropTypes from 'prop-types';
import { formatDateTime, getAgencyTimezoneFromUser } from '../../../../../utils/dateUtils';

export default function Signed({ doc, loggedInUser }) {
    if (doc.docType === DOCUMENT_CUSTOM) {
        return <b>{formatDateTime(doc.acknowledgedDate, getAgencyTimezoneFromUser(loggedInUser))}</b>;
    } else {
        return <b>{formatDateTime(doc.signedByClientDate, getAgencyTimezoneFromUser(loggedInUser))}</b>;
    }
}

Signed.propTypes = { doc: PropTypes.object, loggedInUser: PropTypes.object };
