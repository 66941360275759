import React from 'react';
import cx from 'classnames';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import AccordionItem from './AccordionItem';
import { FormTextRegular, FormTextMultiline } from '../../../../../components/form/FormText.js';
import { CheckboxCheck } from '../../../../../components/form/FormCheckboxCheck.js';
import {
    DETAILS_OPTION_NO_DETAILS,
    DETAILS_OPTION_OPTIONAL,
    DETAILS_OPTION_REQUIRED,
    defaultAnswerOptions
} from '../../../../../config';
import CheckboxGroup from './CheckboxGroup';
import RadioGroup from './RadioGroup';
import Button from '../../../../../common/components/Button.js';
import { QuestionUiModel } from '../BuildADoc.model';
import Icon, { Icons } from '../../../../../common/components/Icon';

import * as styles from './Clause.module.scss';
import { Tag, TagColor, TagSize } from '../../../../../common/components/tag/Tag';
import TextInputField from './TextInput';
import { Textarea } from './Textarea';
import { capitalize } from 'lodash';

type ClauseProps = QuestionUiModel & {
    name: string;
    onRemoveClause: () => void;
    isOpen: boolean;
    shouldShowRemoveButton: boolean;
    isDragging?: boolean;
    isReordering?: boolean;
    id: string;
    sectionDisplayNameLowercase: string;
    isReadOnly?: boolean;
};

const Clause = React.forwardRef<HTMLDivElement, ClauseProps>(
    (
        {
            name,
            number,
            title,
            shouldShowAnswerSection,
            shouldShowDetailsOption,
            shouldShowRemoveButton,
            onRemoveClause,
            isOpen,
            isDragging,
            isReordering,
            id,
            sectionDisplayNameLowercase,
            showPlaceholderText,
            isReadOnly,
            hasError
        },
        ref
    ) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
            id: id
        });

        return (
            <div
                className={styles.dragContainer}
                ref={setNodeRef}
                style={{ '--dragTransform': CSS.Translate.toString(transform), '--dragTransition': transition }}
            >
                {isReordering && (
                    <Button
                        type="button"
                        {...attributes}
                        {...listeners}
                        className={cx(styles.dragHandle, {
                            [styles.dragging]: isDragging
                        })}
                    >
                        <Icon icon={Icons.DRAG} />
                    </Button>
                )}
                <AccordionItem
                    ref={ref}
                    className={cx(styles.clause, { [styles.dragging]: isDragging })}
                    isOpen={!isReordering && isOpen}
                    key={id}
                    value={id}
                    isExpandDisabled={isReordering}
                    collapsedContent={
                        <div
                            className={cx(styles.summary, { [styles.error]: hasError })}
                            data-has-error={hasError && !isOpen}
                        >
                            <span className={styles.title}>{capitalize(sectionDisplayNameLowercase)}</span>
                            <Tag
                                color={hasError ? TagColor.Error : TagColor.Grey}
                                className={cx(styles.number, { [styles.singleDigit]: number < 10 })}
                                text={number.toString()}
                            />
                            <span className={styles.title}>{title || 'No title'}</span>
                            {hasError && (
                                <Tag
                                    className={styles.errorTag}
                                    text="Needs Attention"
                                    color={TagColor.Error}
                                    startIcon={<Icon icon={Icons.ERROR} />}
                                    size={TagSize.Small}
                                />
                            )}
                        </div>
                    }
                >
                    <div className={styles.body}>
                        <TextInputField
                            name={`${name}.title`}
                            label={`${capitalize(sectionDisplayNameLowercase)} title`}
                            labelClassName={styles.label}
                            isRequired
                            placeholder={
                                showPlaceholderText ? `Enter your ${sectionDisplayNameLowercase} title here` : undefined
                            }
                            validateOnTouch={!hasError}
                            disabled={isReadOnly}
                        />
                        <TextInputField
                            name={`${name}.subTitle`}
                            label="Subtitle"
                            labelClassName={styles.label}
                            placeholder={
                                showPlaceholderText
                                    ? `Enter a subtitle to give more context to the ${sectionDisplayNameLowercase} here`
                                    : undefined
                            }
                            validateOnTouch={!hasError}
                            disabled={isReadOnly}
                        />
                        <Textarea
                            name={`${name}.description`}
                            placeholder={
                                showPlaceholderText
                                    ? `Enter a description of your ${sectionDisplayNameLowercase} here`
                                    : undefined
                            }
                            label="Description"
                            labelClassName={styles.label}
                            required
                            validateOnTouch={!hasError}
                            disabled={isReadOnly}
                        />
                        <CheckboxCheck
                            name={`${name}.requireClientAction`}
                            label="Require client action"
                            disabled={isReadOnly}
                            validateOnTouch={!hasError}
                        />
                        {shouldShowAnswerSection && (
                            <>
                                <CheckboxGroup
                                    name={`${name}.answerOptionsSelected`}
                                    data={defaultAnswerOptions.map(option => ({
                                        value: option.name,
                                        label: option.answer
                                    }))}
                                    disabled={isReadOnly}
                                    validateOnTouch={!hasError}
                                />
                                {shouldShowDetailsOption && (
                                    <RadioGroup
                                        label="If yes, are details required?"
                                        name={`${name}.detailsOption`}
                                        data={[
                                            {
                                                label: 'Details optional',
                                                value: DETAILS_OPTION_OPTIONAL
                                            },
                                            {
                                                label: 'Details mandatory',
                                                value: DETAILS_OPTION_REQUIRED
                                            },
                                            {
                                                label: 'No details required',
                                                value: DETAILS_OPTION_NO_DETAILS
                                            }
                                        ]}
                                        disabled={isReadOnly}
                                        validateOnTouch={!hasError}
                                    />
                                )}
                                <CheckboxCheck
                                    name={`${name}.optional`}
                                    label={`A reply to this ${sectionDisplayNameLowercase} isn't mandatory`}
                                    disabled={isReadOnly}
                                />
                            </>
                        )}
                        {shouldShowRemoveButton && (
                            <Button
                                className={styles.deleteClauseButton}
                                quaternary
                                type="button"
                                onClick={() => onRemoveClause()}
                                startIcon={<Icon icon={Icons.TRASH} />}
                                disabled={isReadOnly}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </AccordionItem>
            </div>
        );
    }
);

export default Clause;
