import { has, isEmpty } from 'lodash';
import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
export const UPDATE_CONNECTIONS_FORM = 'UPDATE_CONNECTIONS_FORM';

const initState = {
    connectionsEnabled: false,
    connectionCompany: null,
    additionalEmails: [],
    services: {}
};

export default function connectionsReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_CONNECTIONS_FORM: {
            return {
                ...state,
                ...action.data
            };
        }
        case Lease.CREATE_LEASE_SUCCESS: {
            if (has(action, 'result.data.lease.connections') && !isEmpty(action.result.data.lease.connections)) {
                return {
                    connectionsEnabled: action.result.data.lease.connections.connectionsEnabled,
                    connectionCompany: has(action, 'result.data.lease.connections.connectionCompany._id')
                        ? action.result.data.lease.connections.connectionCompany._id
                        : action.result.data.lease.connections.connectionCompany,
                    propertyManager: has(action, 'result.data.lease.connections.propertyManager.id')
                        ? action.result.data.lease.connections.propertyManager.id
                        : action.result.data.lease.connections.propertyManager,
                    additionalEmails: action.result.data.lease.connections.additionalEmails,
                    services: action.result.data.lease.connections.services
                };
            }

            return {
                ...initState
            };
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            if (
                has(action, 'payload.agreementInfo.connections') &&
                !isEmpty(action.payload.agreementInfo.connections)
            ) {
                return {
                    connectionsEnabled: action.payload.agreementInfo.connections.connectionsEnabled,
                    connectionCompany: has(action, 'payload.agreementInfo.connections.connectionCompany._id')
                        ? action.payload.agreementInfo.connections.connectionCompany._id
                        : action.payload.agreementInfo.connections.connectionCompany,
                    propertyManager: has(action, 'payload.agreementInfo.connections.propertyManager.id')
                        ? action.payload.agreementInfo.connections.propertyManager.id
                        : action.payload.agreementInfo.connections.propertyManager,
                    additionalEmails: action.payload.agreementInfo.connections.additionalEmails,
                    services: action.payload.agreementInfo.connections.services
                };
            }

            return {
                ...initState
            };
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            return {
                ...action.lease.connections
            };
        }
        case Lease.LEASE_CONNECTIONS_SUCCESS: {
            if (has(action, 'result.data.lease.connections') && !isEmpty(action.result.data.lease.connections)) {
                return {
                    connectionsEnabled: action.result.data.lease.connections.connectionsEnabled,
                    connectionCompany: has(action, 'result.data.lease.connections.connectionCompany._id')
                        ? action.result.data.lease.connections.connectionCompany._id
                        : null,
                    propertyManager: has(action, 'result.data.lease.connections.propertyManager.id')
                        ? action.result.data.lease.connections.propertyManager.id
                        : null,
                    additionalEmails: action.result.data.lease.connections.additionalEmails,
                    services: action.result.data.lease.connections.services
                };
            }

            return {
                ...initState
            };
        }
    }

    return state;
}
