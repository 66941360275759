import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';
import { setCouponInfo } from '../../../actions/authorization.js';
import Button from '../../../common/components/Button.js';
import {
    AccountType,
    BUSINESS,
    LANDING,
    PUBLIC_URL,
    PUBLIC_URL_BUSINESS,
    PUBLIC_URL_REAL_ESTATE,
    DISCLAIMER_TEXT
} from '../../../constants/constants';
import { isAgencyUserAccount, isBusinessUserAccount } from '../../../utils/userUtils';
import { ReactComponent as FlkLogo } from '../.././../../assets/images/logoWhite.svg';
import styles from './SignUpLayout.module.scss';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { isArray } from 'lodash';
import { useDispatch } from 'react-redux';
import useQueryParams from '../../../hooks/useQueryParams';

type SignUpBaseLayoutProps = React.PropsWithChildren<{
    onContactUs?: () => void;
    /** Passing in this prop will hide everything inside the layout except for the div containing the children of the component */
    onlyShowChildrenForMobile?: boolean;
    layoutBodyClassName?: string;
    accountType?: AccountType;
}>;

const SignUpBaseLayout: React.FC<SignUpBaseLayoutProps> = ({
    onContactUs,
    onlyShowChildrenForMobile,
    layoutBodyClassName,
    children,
    accountType
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useQueryParams();
    const discountCode = query.get('discountCode');

    /**
     * Get coupon information based on the discount code that would be in the URL
     */
    useQuery({
        queryKey: ['getCouponInformation', accountType, discountCode],
        queryFn: async () => {
            const { data } = await axios.get(`/auth/coupon-info/${accountType}/${discountCode}`);
            dispatch(setCouponInfo(data));
            return data;
        },
        // Cache for 10 minutes
        cacheTime: 10 * 60 * 1000,
        staleTime: 10 * 60 * 1000,
        enabled: !!discountCode && !!accountType, // only execude query when we have discount code and account type
        retry: (failureCount, error) => {
            if (axios.isAxiosError(error) && error.response && error.response.status) {
                // if the response is 410 or 404, no need to retry.
                // 404 means discountcode was not found, potentially invalid
                // 410 means discountcode is no longer valid
                return ![410, 404].includes(error.response.status);
            }
            return true;
        }
    });

    function getLogoLink(currentLocation: string) {
        if (currentLocation.includes(LANDING)) {
            return PUBLIC_URL;
        } else if (currentLocation.includes(BUSINESS)) {
            return PUBLIC_URL_BUSINESS;
        }
        return PUBLIC_URL_REAL_ESTATE;
    }

    return (
        <div className={cx(styles.layout, onlyShowChildrenForMobile ? styles.layoutWithHeaderHidden : undefined)}>
            <header className={cx(styles.header, onlyShowChildrenForMobile ? styles.mobileHidden : undefined)}>
                <a className={styles.logoContainer} href={getLogoLink(window.location.href)}>
                    <FlkLogo className={styles.logo} />
                </a>
                <Button
                    className={cx(styles.headerButton, styles.loginButton)}
                    onClick={() => {
                        history.push('/');
                    }}
                    primary
                    dark
                >
                    Login
                </Button>
                {onContactUs && (
                    <Button className={styles.headerButton} onClick={onContactUs} tertiary dark>
                        Talk to us
                    </Button>
                )}
            </header>
            <div className={cx(styles.layoutBody, layoutBodyClassName)}>{children}</div>
        </div>
    );
};

type TrialMessageProps = {
    accountType?: AccountType;
};

export const TrialMessage: React.FC<TrialMessageProps> = ({ accountType }) => {
    if (isBusinessUserAccount(accountType)) {
        return (
            <>
                <span className={styles.emoji}>👍</span>14 day free trial or 10 documents
            </>
        );
    } else {
        return (
            <>
                <span className={styles.emoji}>👍</span>
                14 day free trial
            </>
        );
    }
};

type SignUpLayoutProps = SignUpBaseLayoutProps & {
    title: string;
    isLandingPageLayout?: boolean;
    childrenContainerClassName?: string;
    hideDisclaimer?: boolean;
    accountType?: AccountType;
};

const SignUpLayout: React.FC<SignUpLayoutProps> = ({
    title,
    onContactUs,
    onlyShowChildrenForMobile,
    isLandingPageLayout,
    childrenContainerClassName,
    hideDisclaimer,
    accountType,
    layoutBodyClassName,
    children
}) => {
    const isFlkRE = isAgencyUserAccount(accountType);

    const disclaimer = isFlkRE ? DISCLAIMER_TEXT.AU.REAL_ESTATE : DISCLAIMER_TEXT.AU.BUSINESS;

    return (
        <SignUpBaseLayout
            onContactUs={onContactUs}
            onlyShowChildrenForMobile={onlyShowChildrenForMobile}
            layoutBodyClassName={layoutBodyClassName}
            accountType={accountType}
        >
            <div className={cx(styles.container, isLandingPageLayout ? styles.landingPageLayout : null)}>
                <h1 className={cx(styles.title, onlyShowChildrenForMobile ? styles.mobileHidden : null)}>{title}</h1>
                <div
                    className={cx(
                        styles.uniqueSellingPointContainer,
                        onlyShowChildrenForMobile ? styles.mobileHidden : null
                    )}
                >
                    {isFlkRE && (
                        <div className={styles.uniqueSellingPoint}>
                            <TrialMessage accountType={accountType} />
                        </div>
                    )}
                    <div className={styles.uniqueSellingPoint}>
                        <span className={styles.emoji}>💳</span>
                        <span>{isFlkRE ? 'No credit card details required*' : 'No credit card'}</span>
                    </div>
                    {!isFlkRE && (
                        <div className={styles.uniqueSellingPoint}>
                            <TrialMessage accountType={accountType} />
                        </div>
                    )}
                    {isFlkRE && (
                        <>
                            <div className={styles.uniqueSellingPoint}>
                                <span className={styles.emoji}>🤸‍♂️</span>
                                <span>Flexible month-to-month subscriptions</span>
                            </div>
                            <div className={styles.uniqueSellingPoint}>
                                <span className={styles.emoji}>🔑</span>
                                <span>Choose your package based on your leasing activity</span>
                            </div>
                            <div className={styles.uniqueSellingPoint}>
                                <span className={styles.emoji}>🏠</span>
                                <span>Unlimited use of all other documents included in every plan†</span>
                            </div>
                        </>
                    )}
                </div>
                <div className={cx(styles.content, childrenContainerClassName)}>{children}</div>
                {!hideDisclaimer && (
                    <div className={cx(styles.disclaimer, onlyShowChildrenForMobile ? styles.mobileHidden : null)}>
                        {isArray(disclaimer) ? (
                            <>
                                {disclaimer.map((dt, idx) => (
                                    <p key={idx}>{dt}</p>
                                ))}
                            </>
                        ) : (
                            disclaimer
                        )}
                    </div>
                )}
            </div>
        </SignUpBaseLayout>
    );
};

export { SignUpBaseLayout, SignUpLayout };
