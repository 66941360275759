import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Panel from '../../../common/components/Panel';
import { Form } from 'react-final-form';
import { FormTextRegular } from '../../form/FormText';
import * as validators from '../../../components/Validate';
import { isEmpty, pullAt } from 'lodash';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { UPDATE_FOLLOW_UP_REPORT_EMAILS_SUCCESS } from '../../../actions/types';
import { getUserAgency } from '../../../selectors/agency';
import ReactTooltip from 'react-tooltip';
import { InfoSharp } from '@material-ui/icons';
import Button from '../../../common/components/Button';

export function FollowUp({ receiverEmails }) {
    const dispatch = useDispatch();
    const agency = useSelector(getUserAgency);
    const [followUpReportSettings, setFollowUpReportSettings] = useState({ followUpReportEmails: receiverEmails });
    const [failError, setFailError] = useState();
    const [addEmailFieldError, setAddEmailFieldError] = useState([]);

    useEffect(() => {
        setFollowUpReportSettings({ followUpReportEmails: receiverEmails });
    }, [receiverEmails]);

    const updateFollowUpReportEmails = function(agencyId, data) {
        axios
            .post(`api/agency/${agencyId}/follow-up-report-emails`, data)
            .then(result => {
                dispatch({
                    type: UPDATE_FOLLOW_UP_REPORT_EMAILS_SUCCESS,
                    result
                });
            })
            .catch(() => {
                setFailError('Failed to add reciever, admin access required, please try again');
            });
    };

    const removeEmail = index => {
        let initialEmails = receiverEmails;
        pullAt(initialEmails, index);
        setFollowUpReportSettings({ followUpReportEmails: initialEmails });
        updateFollowUpReportEmails(agency.id, initialEmails);
    };

    const keydownHandler = (event, form) => {
        let value = event.target.value;
        // Only if the user pressed enter
        if (event.keyCode === 13) {
            event.preventDefault();
            addEmail(value, form);
        }
    };

    const clickHandler = (values, form) => {
        addEmail(values.followUpReportEmail, form);
    };

    const validate = value => {
        if (isEmpty(value)) {
            setAddEmailFieldError('Email address required');
            return false;
        }

        if (!validators.isCorrectEmail(value)) {
            setAddEmailFieldError('Wrong email format');
            return false;
        } else {
            setAddEmailFieldError('');
        }

        return true;
    };

    const addEmail = (email, form) => {
        let initialEmails = receiverEmails;
        let isValid = validate(email);
        if (isValid) {
            initialEmails.push(email);
            setFollowUpReportSettings({ followUpReportEmails: initialEmails });
            updateFollowUpReportEmails(agency.id, initialEmails);
            form.change('followUpReportEmail', undefined);
        }
    };

    return (
        <div>
            <Panel title="Follow up report">
                <p className="text-gray">
                    This is a weekly report sent on Friday afternoon alerting you to any possible action that may be
                    required on documents that have been created or sent with action pending.
                </p>
                <p className="text-gray">
                    This is a great snapshot to make sure nothing is slipping through the cracks.
                </p>
                <p className="text-gray">
                    <br />
                    <span data-tip={true} data-for="followUpInfo">
                        More info
                        <InfoSharp className="tooltip-info-icon" />
                    </span>
                </p>
                <ReactTooltip id="followUpInfo" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                    It will breakdown the required work per staff member and include:
                    <ul>
                        <li>Residential tenancy agreements in sent for signing with links that have expired</li>
                        <li>Residential tenancy agreements in awaiting for completion</li>
                        <li>Property management agreements in sent for signing with links that have expired</li>
                        <li>Property management agreements in awaiting for completion</li>
                        <li>Sales agreements that are in sent for signing longer than 72 hours</li>
                        <li>Sales agreements that are in awaiting for completion</li>
                    </ul>
                </ReactTooltip>
                <br />
                <br />
                <Form onSubmit={() => {}}>
                    {({ values, form }) => {
                        return (
                            <form>
                                <FormTextRegular
                                    name="followUpReportEmail"
                                    label="Enter email and press enter"
                                    onKeyDown={e => keydownHandler(e, form)}
                                />
                                {addEmailFieldError && <p className="has-error">{addEmailFieldError}</p>}
                                {failError && <p className="has-error">{failError}</p>}
                                <Button primary type="button" onClick={() => clickHandler(values, form)}>
                                    Subscribe
                                </Button>
                            </form>
                        );
                    }}
                </Form>
                {followUpReportSettings.followUpReportEmails.length > 0 && (
                    <React.Fragment>
                        <h3>Subscribers</h3>
                        <ul className="emails-list">
                            {followUpReportSettings.followUpReportEmails.map((email, index) => (
                                <li className="column-box" key={index}>
                                    <div className="individual-email-follow-up" title={email}>
                                        {email}
                                    </div>
                                    <button className="btn-delete" type="button" onClick={() => removeEmail(index)} />
                                </li>
                            ))}
                        </ul>
                    </React.Fragment>
                )}
            </Panel>
        </div>
    );
}

export default FollowUp;
