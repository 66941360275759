import React, { Component } from 'react';
import { differenceInDays, differenceInHours } from 'date-fns';

import '../../sass/changeRequestItem.scss';

class ChangeRequestItem extends Component {
    getTimeLeft(created) {
        const now = new Date();
        const days = differenceInDays(now, new Date(created));
        if (days === 0) {
            const hours = differenceInHours(now, new Date(created));
            return hours + (hours === 1 ? ' hour ago' : ' hours ago');
        }

        return days + (days === 1 ? ' day ago' : ' days ago');
    }

    renderStatus() {
        const { changeRequest } = this.props;
        switch (changeRequest.status) {
            case 'new': {
                return (
                    <button className="resolve-button" onClick={e => this.props.resolveRequest(changeRequest)}>
                        resolve
                    </button>
                );
            }
            case 'resolved': {
                return (
                    <button className="resolved-button" onClick={e => this.props.unResolveRequest(changeRequest)}>
                        resolved
                    </button>
                );
            }
            case 'unresolved': {
                return (
                    <button className="unresolved-button" onClick={e => this.props.resolveRequest(changeRequest)}>
                        unresolved
                    </button>
                );
            }
        }

        return 'RESOLVED';
    }

    render() {
        const { changeRequest, selected } = this.props;
        let className = 'change-request-item';
        if (selected) {
            className += ' selected';
        }

        return (
            <div className={className}>
                <div className="header">
                    <span className="item-title">{changeRequest.leaseAgreementStep.stepName}</span>
                    <span className="resolve-status">{this.renderStatus()}</span>
                </div>
                <div className="main" onClick={e => this.props.select(changeRequest)}>
                    <span>{changeRequest.requestMessage}</span>
                </div>
                <div className="footer">
                    <span className="tenant-name">{changeRequest.tenantName}</span>
                    <span className="time-left">{this.getTimeLeft(changeRequest.created)}</span>
                </div>
            </div>
        );
    }
}

export default ChangeRequestItem;
