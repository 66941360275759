import React from 'react';
import { subscriptionStatus } from '../../../config';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../utils/dateUtils.js';
import { User } from '../../../types/User';
import { TariffPlan } from '../../../types/TariffPlan';

interface SubscriptionStatusMessageProps {
    tariffPlan: TariffPlan;
    loggedInUser: User;
}

function NoPlan() {
    return (
        <div>
            <p className="text-gray">
                You do not have an active subscription. You can select a new plan by clicking on Select plan. <br />
                <br /> Please make sure you have a valid payment method.
            </p>
        </div>
    );
}

const SubscriptionStatusMessage: React.FC<SubscriptionStatusMessageProps> = ({ tariffPlan, loggedInUser }) => {
    if (!tariffPlan) {
        return <NoPlan />;
    }

    if (tariffPlan.active) {
        if (tariffPlan.status === subscriptionStatus.CANCELLING) {
            return (
                <div>
                    <p className="text-gray">
                        Your subscription has been cancelled and will end on{' '}
                        {formatDateStandard(tariffPlan.currentPeriodEnd, getAgencyTimezoneFromUser(loggedInUser))}.
                    </p>
                    <p className="text-gray">
                        You can reactivate your existing plan by clicking on Reactivate subscription or select a new
                        plan by clicking on Change Plan.{' '}
                    </p>
                </div>
            );
        }

        if (tariffPlan.status === subscriptionStatus.PAST_DUE) {
            return (
                <div>
                    <p className="text-gray">
                        Your subscription is marked as past due. A payment has most likely been missed.
                        <br />
                        The payment will be retried automatically.
                    </p>
                </div>
            );
        }
    } else if (tariffPlan.status === subscriptionStatus.PAUSED) {
        return (
            <div>
                <p className="text-gray">
                    Your subscription is currently paused as you did not have a payment method when your trial ended.
                </p>
                <p className="text-gray">You can resume your subscription by clicking on Resume subscription below.</p>
            </div>
        );
    } else if ([subscriptionStatus.INCOMPLETE, subscriptionStatus.INCOMPLETE_EXPIRED].includes(tariffPlan.status)) {
        return (
            <div>
                <p className="text-gray">
                    Your subscription is inactive, this has most likely happened because your payment failed. <br />
                    <br /> Please update your payment method and contact us for help.
                </p>
            </div>
        );
    } else if ([subscriptionStatus.UNPAID, subscriptionStatus.PAST_DUE].includes(tariffPlan.status)) {
        return (
            <div>
                <p className="text-gray">
                    Your subscription has been deactivated. A payment has most likely been missed.
                    <br />
                    <br /> Please update your payment method and/or retry the payment.
                    <br /> If that doesn't work, please contact us.
                </p>
            </div>
        );
    } else if ([subscriptionStatus.CANCELED].includes(tariffPlan.status)) {
        return <NoPlan />;
    }
    return <></>;
};

export default SubscriptionStatusMessage;
