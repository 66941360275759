import axios from 'axios';

import * as Lease from '../../reducers/lease';

export function createCommercialLease(data) {
    return {
        types: [
            Lease.CREATE_COMMERCIAL_LEASE_REQUEST,
            Lease.CREATE_COMMERCIAL_LEASE_SUCCESS,
            Lease.CREATE_COMMERCIAL_LEASE_FAILURE
        ],
        promise: axios.post('/api/agency/lease', data)
    };
}
