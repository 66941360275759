import React from 'react';

import styles from './ListNow.module.scss';
import ToggleButtonV2, { LabelPosition } from '@app/common/components/ToggleButtonV2';
import FormAddressDetailsAutocomplete from '@app/components/form/FormAddressDetailsAutocomplete.js';
import { getAddressDetails } from '@app/selectors/lease/addresses.js';
import { clearAddressDetailsStore, openAddressDetailsModal } from '@app/actions/addressDetails.js';
import { FormTextCurrency } from '@app/components/form/FormText.js';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import { DEFINE_ME } from '@app/types/utilityTypes';
import { countryCodes } from '@app/config';
import useCountry from '@app/hooks/useCountry';
import { getLabelForCountry } from '@app/utils/countryUtils';

type AddressDetails = {
    unit?: string;
    street_number: string;
    route: string;
    locality: string;
    sublocality_level_1: string;
    administrative_area_level_1: string;
    administrative_area_level_2: string;
    postal_code: string;
    country: string;
};

const ListNow: React.FC = () => {
    const dispatch = useDispatch();
    const { country, isNewZealand } = useCountry();

    const form = useForm();
    const { values } = form.getState();

    const brandName = getLabelForCountry(country, 'listNow');

    const addressDetails: {
        editAddressFormData: DEFINE_ME;
        selectedAddressEdited: boolean;
        editMode: boolean;
        detailsConfirmed: boolean;
    } = useSelector(getAddressDetails);

    function setAddressDetails(address: string, details: AddressDetails) {
        form.change('listNow.address', address);
        form.change('listNow.addressDetails', {
            unit: details.unit,
            streetNumber: details.street_number,
            streetName: details.route,
            city: isNewZealand ? details.sublocality_level_1 : details.locality,
            state: details.administrative_area_level_1,
            postalCode: details.postal_code,
            country: details.country
        });
    }

    return (
        <div className={styles.listNow}>
            <ToggleButtonV2
                name="listNow.enabled"
                labelPosition={LabelPosition.Start}
                label={brandName}
                description={`By turning this on the client will be able to view ${brandName} payment options after the signing process (on phone and desktop)`}
            />
            {values?.listNow?.enabled && (
                <div>
                    <FormAddressDetailsAutocomplete
                        name="listNow.address"
                        value={values?.listNow?.address}
                        enabledCountries={isNewZealand ? countryCodes.NZ : countryCodes.AU}
                        editAddressFormData={addressDetails.editAddressFormData}
                        editedNewAddress={addressDetails.selectedAddressEdited}
                        editMode={addressDetails.editMode}
                        showAddressBelow={false}
                        hideEditAddress={true}
                        detailsConfirmed={addressDetails.detailsConfirmed}
                        label="Property Address*"
                        onChange={(address: string) => form.change('listNow.address', address)}
                        setAddressDetails={setAddressDetails}
                        openAddressDetailsModal={(data: DEFINE_ME) => dispatch(openAddressDetailsModal(data))}
                        clearAddressDetailsStore={() => dispatch(clearAddressDetailsStore())}
                    />
                    <FormTextCurrency name="listNow.budget" label="Marketing Budget" precision="2" required />
                </div>
            )}
        </div>
    );
};

export default ListNow;
