import React from 'react';
import Button from '../../../../common/components/Button.js';

import styles from './ConfirmAlert.module.scss';

type ConfirmAlertProps = {
    title: string;
    message: React.ReactNode;
    confirmText: string;
    cancelText: string;
    onConfirm: () => void;
    onCancel: () => void;
};

const ConfirmAlert: React.FC<ConfirmAlertProps> = ({
    title,
    message,
    confirmText,
    cancelText,
    onConfirm,
    onCancel
}) => {
    return (
        <div className={styles.confirmAlert}>
            <h4 className={styles.title}>{title}</h4>
            <div className={styles.body}>{message}</div>
            <div className={styles.footer}>
                <Button type="button" onClick={onCancel} tertiary>
                    {cancelText}
                </Button>
                <Button type="button" onClick={onConfirm} primary>
                    {confirmText}
                </Button>
            </div>
        </div>
    );
};

export default ConfirmAlert;
