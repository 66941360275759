import * as Renewal from './../reducers/renewal';
import axios from 'axios';

/**
 * Open the lease renewal modal.
 * @returns {{type}}
 */
export function openLeaseRenewalModal(lease) {
    return {
        type: Renewal.OPEN_RENEWAL_MODAL,
        lease
    };
}

/**
 * Close the lease renewal modal.
 * @returns {{type}}
 */
export function closeLeaseRenewalModal() {
    return {
        type: Renewal.CLOSE_RENEWAL_MODAL
    };
}

/**
 * Checking that we can start renewal precess.
 * @param {string} leaseId - Lease Agreement ID
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function checkRenewal(leaseId) {
    return {
        types: [Renewal.CHECK_RENEWAL_REQUEST, Renewal.CHECK_RENEWAL_SUCCESS, Renewal.CHECK_RENEWAL_FAIL],
        promise: axios.get(`/api/agency/lease/${leaseId}/renewal/check`)
    };
}

/**
 * Start renewal process of lease agreement.
 * @param {string} leaseId - Lease Agreement ID
 * @param {object} values - Tenant renewing this agreement? and subLeaseType
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function startRenewal(leaseId, values) {
    return {
        types: [Renewal.START_RENEWAL_REQUEST, Renewal.START_RENEWAL_SUCCESS, Renewal.START_RENEWAL_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/renewal/start`, values)
    };
}

/**
 * Reject renewal process of lease agreement.
 * @param {string} leaseId - Lease Agreement ID
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function rejectRenewal(leaseId) {
    return {
        types: [Renewal.CANCEL_RENEWAL_REQUEST, Renewal.CANCEL_RENEWAL_SUCCESS, Renewal.CANCEL_RENEWAL_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/renewal/reject`)
    };
}

/**
 * Reset renewal data.
 * @returns {{type}}
 */
export function resetStartRenewal() {
    return {
        type: Renewal.RESET_START_RENEWAL
    };
}

/**
 * Change data in the renewal form.
 * @param {object} data - form values
 * @returns {{type: *, data: *}}
 */
export function changeRenewalForm(data) {
    return {
        type: Renewal.UPDATE_RENEWAL_RENT_FORM,
        data: data
    };
}

/**
 * Finish renewal of lease agreement model.
 * @param {string} leaseId - Lease Agreement ID
 * @param {object} data - renewal form dara
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */

//Wait for response to complete and jump to draft
export function finishRenewal(leaseId, data) {
    return dispatch => {
        dispatch({
            type: Renewal.FINISH_RENEWAL_REQUEST
        });
        return new Promise((resolve, reject) => {
            return axios
                .post(`/api/agency/lease/${leaseId}/renewal/finish`, data)
                .then(result => {
                    dispatch({
                        type: Renewal.FINISH_RENEWAL_SUCCESS,
                        result
                    });
                    resolve(result);
                })
                .catch(error => {
                    dispatch({
                        type: Renewal.FINISH_RENEWAL_FAIL,
                        error
                    });
                    reject(error);
                });
        });
    };
}
