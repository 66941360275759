import React from 'react';
import * as Dashboard from '../../../../actions/dashboard';
import '../../../../sass/progressBlock.scss';

const ExpiredBlock = ({ signingExpire, deadline, status }) => {
    if (status !== Dashboard.LEASE_STATUS_SENT_SIGNING) {
        return <div />;
    }

    let className = '';
    let text = signingExpire + 'h';
    if (signingExpire > 72) {
        if (signingExpire % 24 === 0) {
            text = `${Math.floor(signingExpire / 24)}d`;
        } else {
            text = `${Math.floor(signingExpire / 24)}d ${signingExpire % 24}h`;
        }
    }
    const percent = (signingExpire * 100) / deadline;

    if (percent <= 0) {
        text = 'Expired';
        className = 'text-danger';
    } else if (percent > 0 && percent < 60) {
        className = 'text-warning';
    }

    return <div className={className}>{text}</div>;
};

export default ExpiredBlock;
