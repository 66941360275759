import { OPEN_RESEND_FOR_SIGNING_MODAL, CLOSE_RESEND_FOR_SIGNING_MODAL } from './types';

export function openResendForSigningModal(data) {
    return {
        type: OPEN_RESEND_FOR_SIGNING_MODAL,
        payload: {
            data
        }
    };
}

export function closeResendForSigningModal() {
    return {
        type: CLOSE_RESEND_FOR_SIGNING_MODAL
    };
}
