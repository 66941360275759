import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { isLeaseLocationNSW } from '../../../../../utils/agreementUtils';
import { getMobileViewEnabled } from '../../../../../selectors/dashboard';
import { isMobileWidth } from '../../../../../config';
import AgreementClLessor from './AgreementClLessor';
import AgreementClLessorAgent from './AgreementClLessorAgent';
import AgreementClLessee from './AgreementClLessee';
import AgreementClGuarantor from './AgreementClGuarantor';
import AgreementClPremises from './AgreementClPremises';
import AgreementClPermittedUse from './AgreementClPermittedUse';
import AgreementClTerm from './AgreementClTerm';
import AgreementClRent from './AgreementClRent';
import AgreementClSecurityDeposit from './AgreementClSecurityDeposit';
import AgreementClInformation from './AgreementClInformation';
import AgreementClSpecialConditions from './AgreementClSpecialConditions';
import AgreementMainDocuments from '../shared/AgreementMainDocuments';
import AgreementSignatures from '../../../../../containers/dashboard/commercialLease/AgreementSignatures';
import AgreementMainAuditTrail from '../shared/AgreementMainAuditTrail';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../../actions/dashboard';
import '../../../../../sass/infoAgreement.scss';
import { LeaseAllInfo } from './types';

type Props = {
    leaseAllInfo: LeaseAllInfo
}

export default function AgreementCommercialLeaseCommon({ leaseAllInfo }: Props) {
    const mobileViewEnabled = useSelector(getMobileViewEnabled);
    const [hideDetails] = useState(mobileViewEnabled && isMobileWidth());
    if (!leaseAllInfo) {
        return <div></div>;
    }
    return (
        <div>
            {!hideDetails && isLeaseLocationNSW(leaseAllInfo.location) && (
                <React.Fragment>
                    {[LEASE_STATUS_SENT_SIGNING , LEASE_STATUS_AWAITING_COMPLETION].includes(leaseAllInfo.status) && <AgreementSignatures location={leaseAllInfo.location} />}
                    <AgreementClLessor leaseAllInfo={leaseAllInfo} />
                    <AgreementClLessorAgent leaseAllInfo={leaseAllInfo} />
                    <AgreementClLessee leaseAllInfo={leaseAllInfo} />
                    <AgreementClGuarantor leaseAllInfo={leaseAllInfo} />
                    <AgreementClPremises leaseAllInfo={leaseAllInfo} />
                    <AgreementClPermittedUse leaseAllInfo={leaseAllInfo} />
                    <AgreementClTerm leaseAllInfo={leaseAllInfo} />
                    <AgreementClRent leaseAllInfo={leaseAllInfo} />
                    <AgreementClSecurityDeposit leaseAllInfo={leaseAllInfo} />
                    <AgreementClInformation leaseAllInfo={leaseAllInfo} />
                    <AgreementClSpecialConditions leaseAllInfo={leaseAllInfo} />
                    <AgreementMainDocuments leaseAllInfo={leaseAllInfo} />
                    {leaseAllInfo.auditTrail && leaseAllInfo.auditTrail.length > 0 && (
                        <AgreementMainAuditTrail auditTrail={leaseAllInfo.auditTrail} showText={true} />
                    )}
                </React.Fragment>
            )}
        </div>
    );
}
