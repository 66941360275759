import React from 'react';
import styles from './AwaitingCompletion.module.scss';
import ReactTooltip from 'react-tooltip';

const EmailPreview = ({ leaseInfo }) => {
    const formattedTenantNames = leaseInfo?.tenant?.tenants
        .map((tenant, i) => {
            return tenant.fullName;
        })
        .join(' & ');
    const bondDetails = leaseInfo?.rent?.isBond ? leaseInfo.rent.bondAmountInWords : '-';
    const arePetsAllowed = leaseInfo?.additionalTerms?.allowAnimals ? 'Yes' : 'No';

    return (
        <>
            <span data-tip={true} data-for={'view-sample-email-copy-tooltip'} className={styles.tooltipPreviewToggle}>
                View Sample Email Copy
            </span>
            <ReactTooltip
                id={'view-sample-email-copy-tooltip'}
                class={styles.fixedWidthTooltip}
                place="bottom"
                effect="solid"
                globalEventOff="wheel"
            >
                <div className={styles.previewHtml}>
                    {leaseInfo.landlord && leaseInfo.landlord.landLordFirstNames && (
                        <span className={styles.emailGreetings}>Dear {leaseInfo.landlord.landLordFirstNames},</span>
                    )}
                    <p>
                        We have recently executed a new lease for your investment property at {leaseInfo.address}.
                        Attached is a copy for your records.
                    </p>
                    <div className={styles.detailsOverview}>
                        <p>An overview of the details are:</p>
                        <div className={styles.detailsWrapper}>
                            <span>Tenants names:</span>
                            <span>{formattedTenantNames}</span>
                        </div>
                        <div className={styles.detailsWrapper}>
                            <span>Start Date:</span>
                            <span>{leaseInfo.term.startDateFormattedForEmail}</span>
                        </div>
                        <div className={styles.detailsWrapper}>
                            <span>Term:</span>
                            <span>{leaseInfo.term.termFormatted}</span>
                        </div>
                        <div className={styles.detailsWrapper}>
                            <span>End date:</span>
                            <span>{leaseInfo.term.endDateFormattedForEmail}</span>
                        </div>
                        <div className={styles.detailsWrapper}>
                            <span>Pets approved:</span>
                            <span>{arePetsAllowed}</span>
                        </div>
                        <div className={styles.detailsWrapper}>
                            <span>Bond:</span>
                            <span>{bondDetails}</span>
                        </div>
                        <div className={styles.detailsWrapper}>
                            <span>Rent:</span>
                            <span>{leaseInfo.rent.amount}</span>
                        </div>
                    </div>
                    <p>Thank you for the opportunity to continue to look after your property management needs.</p>
                    <div className={styles.emailFooter}>
                        <span className={styles.footerGreetings}>Regards,</span>
                        <span>{leaseInfo.agent.fullName}</span>
                        <span>{leaseInfo.agency.details.agencyName}</span>
                        <span>{leaseInfo.agent.email}</span>
                        <span>{leaseInfo.agent.contactNumber}</span>
                    </div>
                </div>
            </ReactTooltip>
        </>
    );
};

export default EmailPreview;
