import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNumber } from 'lodash';
import { getStatistics, getAllUsers, getAllAgencies } from '../../../actions/statistics';

import UserTable from './components/UserTable';
import AgencyTable from './components/AgencyTable';
import Chart from './components/Chart';

import '../../../sass/statistics.scss';
import {
    getLeaseAgreementCount,
    getUsers,
    getTotalUsersCount,
    getUsersPagination,
    getUsersSorting,
    getCanUserViewStatistics,
    getAgencies,
    getAgenciesPagination,
    getAgenciesSorting
} from '../../../selectors/statistics';
import { getUserInfo } from '../../../selectors/user';

const Statistics = () => {
    const dispatch = useDispatch();

    const AGREEMENTS_PER_MONTH_CHART_ID =
        ENVIRONMENT === 'production' ? 'a1b292cb-dbf8-4560-89c0-6d5b128fcb9a' : 'a338097e-4485-4ef9-bb0b-c149813b5a16';
    const AVG_RENT_PER_MONTH_10KM_SYDNEY_CHART_ID = 'a5bd32c0-c6ad-41ab-b700-0a8582d628a0';
    const AVG_RENT_PER_MONTH_NSW_CHART_ID = '716f2fa6-5382-4ad0-a714-b8ac319ced70';
    const AVG_RENT_PER_MONTH_QLD_CHART_ID = '3fff9d39-aa18-48bf-8542-b8cef2fc3b36';

    const leaseAgreementCount = useSelector(getLeaseAgreementCount);
    const userInfo = useSelector(getUserInfo);
    const users = useSelector(getUsers);
    const totalUsersCount = useSelector(getTotalUsersCount);
    const usersPagination = useSelector(getUsersPagination);
    const usersSorting = useSelector(getUsersSorting);
    const canUserViewStatistics = useSelector(getCanUserViewStatistics);
    const agencies = useSelector(getAgencies);
    const agenciesPagination = useSelector(getAgenciesPagination);
    const agenciesSorting = useSelector(getAgenciesSorting);

    const filter = {
        $and: [{ 'agency._id': { $oid: userInfo.agency.id } }]
    };

    useEffect(() => {
        dispatch(getStatistics());
        if (usersPagination && usersSorting) {
            dispatch(
                getAllUsers(usersPagination.page, usersPagination.limit, usersSorting.field, usersSorting.direction)
            );
            dispatch(
                getAllAgencies(usersPagination.page, usersPagination.limit, usersSorting.field, usersSorting.direction)
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const changeUsersPage = page => {
        dispatch(getAllUsers(page, usersPagination.limit, usersSorting.field, usersSorting.direction));
    };

    const changeAgencyPage = page => {
        dispatch(getAllAgencies(page, agenciesPagination.limit, agenciesSorting.field, agenciesSorting.direction));
    };

    return (
        <div className="settings">
            <div className="statistics-page">
                <h1>Statistics</h1>

                {canUserViewStatistics && (
                    <div className="statistics-section">
                        <div className="row">
                            <p>
                                <strong>Total number of users:</strong> {isNumber(totalUsersCount) && totalUsersCount}
                            </p>
                        </div>

                        <div className="row">
                            <p>
                                <strong>Total number of leases created:</strong>{' '}
                                {isNumber(leaseAgreementCount) && leaseAgreementCount}
                            </p>
                        </div>
                        <UserTable
                            users={users}
                            usersPagination={usersPagination}
                            changePage={changeUsersPage}
                            loggedInUser={userInfo}
                        />
                        <AgencyTable
                            agencies={agencies}
                            pagination={agenciesPagination}
                            changePage={changeAgencyPage}
                            loggedInUser={userInfo}
                        />
                    </div>
                )}
                <div className="charts">
                    <Chart
                        chartId={AGREEMENTS_PER_MONTH_CHART_ID}
                        id="agreementsPerMonth"
                        filter={filter}
                        environmentSpecific={true}
                    />

                    <Chart chartId={AVG_RENT_PER_MONTH_10KM_SYDNEY_CHART_ID} id="avgRent10KmSydney" />
                    <Chart chartId={AVG_RENT_PER_MONTH_NSW_CHART_ID} id="avgRentNSW" />
                    <Chart chartId={AVG_RENT_PER_MONTH_QLD_CHART_ID} id="avgRentQLD" />
                    <br />
                    <b>Disclaimer:</b>
                    <p className="disclaimer">
                        <i>
                            The information contained herein has been collated purely by Flk It Over based on data
                            obtained over a period of time from Flk It Over users. This information is not to be
                            construed as representative of the overall state of the market nor indicative of values. All
                            information is gathered from sources we consider reliable, however we cannot guarantee or
                            give any warranty as to the accuracy of the information provided.
                        </i>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default memo(Statistics);
