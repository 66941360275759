import React, { createContext, useContext, useEffect, useState } from 'react';
import { once } from 'lodash';
import Rollbar from 'rollbar';

const initializeRollbar = once(() => {
    return new Promise(resolve => {
        const rollbar = new Rollbar({
            accessToken: ROLLBAR_TOKEN,
            captureUncaught: true,
            captureUnhandledRejections: true
        });
        return resolve(rollbar);
    });
});

const RollbarContext = createContext(null);

export function RollbarProvider({ children }) {
    const [client, setClient] = useState();

    return (
        <RollbarContext.Provider value={{ client, _dontUseSetClient: setClient }}>{children}</RollbarContext.Provider>
    );
}

export function useRollbarProvider() {
    const { client, _dontUseSetClient } = useContext(RollbarContext);

    // kick-start on mount
    useEffect(() => {
        initializeRollbar().then(client => {
            _dontUseSetClient(client);
        });
    }, [_dontUseSetClient]);

    return client;
}
