import React, { useState } from 'react';
import styles from './SecuritySetupCard.module.scss';
import cx from 'classnames';
import parse from 'html-react-parser';
import Button from '../../common/components/Button';
import Icon, { Icons } from '../../common/components/Icon';
import Card, { CardStyles } from '../../common/components/cards/Card';
import { Form } from 'react-final-form';
import { FormTextRegular } from '../form/FormText';
import { isAgencyUserAccount } from '../../utils/userUtils';
import { useAccountProvider } from '../providers/AccountProvider';
import ContactUs from '../ContactUs.tsx';
interface SecuritySetupCardProps {
    title: string;
    description: string;
    label: string;
    headerIcon?: Icons;
    buttonText?: string;
    submit: (method: string, code: string) => void;
    resend: () => void;
    goBack: () => void;
    isDisable?: boolean;
    method: string;
    loading?: boolean;
    wrongDataText?: string;
    changeData?: () => void;
    resendRemainingTime: number;
    isSending2faCode: boolean;
    error?: string;
}

export default function SecuritySetupCard({
    title,
    description,
    label,
    headerIcon,
    buttonText,
    submit,
    resend,
    goBack,
    isDisable,
    method,
    loading,
    wrongDataText,
    changeData,
    resendRemainingTime,
    isSending2faCode,
    error
}: SecuritySetupCardProps): JSX.Element {
    const accountType = useAccountProvider();

    const [showContactUs, setShowContactUs] = useState(false);

    function clickWrongDataButton() {
        if (changeData) {
            changeData();
        } else {
            setShowContactUs(true);
        }
    }
    return (
        <Form onSubmit={data => submit(method, data.code)}>
            {({ handleSubmit, values }) => {
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card style={CardStyles.SQUARE} className={cx(styles.securitySetupCard)}>
                            <div className={styles.header}>
                                <div className={styles.titleGroup}>
                                    {headerIcon && <Icon className={styles.icon} icon={headerIcon} />}
                                    <p className={styles.title}>{title}</p>
                                </div>
                            </div>
                            {showContactUs ? (
                                <>
                                    <ContactUs />
                                    <div className={styles.footer}>
                                        <Button
                                            type="button"
                                            className={styles.button}
                                            onClick={() => setShowContactUs(false)}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={styles.body}>
                                        <p className={styles.description}>{parse(description)}</p>
                                        <FormTextRegular name="code" label={label} />
                                        {buttonText && (
                                            <Button
                                                type="button"
                                                disabled={!!resendRemainingTime}
                                                loading={isSending2faCode}
                                                secondary
                                                className={styles.resendButton}
                                                onClick={resend}
                                            >
                                                {buttonText}
                                                {resendRemainingTime ? ` in ${resendRemainingTime} seconds` : ''}
                                            </Button>
                                        )}
                                        {error && <p className={styles.error}>{error}</p>}
                                        {wrongDataText ? (
                                            <Button
                                                type="button"
                                                className={styles.button}
                                                onClick={clickWrongDataButton}
                                            >
                                                {wrongDataText}
                                            </Button>
                                        ) : (
                                            <p className={styles.helpText}>
                                                {isAgencyUserAccount(accountType) ? (
                                                    <>
                                                        Having trouble? Contact our support team on{' '}
                                                        <a
                                                            href="tel:0481611212"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            0481 611 212
                                                        </a>{' '}
                                                        or send your request to{' '}
                                                        <a
                                                            href="mailto:support@flkitover.com?subject=FLK Real Estate: Customer support issue"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            support@flkitover.com
                                                        </a>
                                                    </>
                                                ) : (
                                                    <>
                                                        Having trouble? Send your request to{' '}
                                                        <a
                                                            href="mailto:support@flkitover.com?subject=FLK Business: Customer support issue"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            support@flkitover.com
                                                        </a>
                                                    </>
                                                )}
                                            </p>
                                        )}
                                    </div>
                                    <div className={styles.footer}>
                                        <Button type="button" className={styles.button} onClick={goBack}>
                                            Back
                                        </Button>
                                        <Button
                                            type="submit"
                                            primary
                                            className={styles.nextButton}
                                            loading={loading}
                                            disabled={!values.code}
                                        >
                                            {isDisable ? 'Disable' : 'Done'}
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Card>
                    </form>
                );
            }}
        </Form>
    );
}
