import React, { useState } from 'react';
import { getPmStepNo } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { ModalViewPmAdditionalTerms } from '../../../../modals/pm/ModalViewPmAdditionalTerms';

const AgreementPmAdditionalTerms = props => {
    let [isViewPmAdditionTermsOpen, setIsViewPmAdditionTermsOpen] = useState(false);

    const showViewAdditionTermsModal = () => {
        setIsViewPmAdditionTermsOpen(true);
    };

    const closeViewAdditionTermsModal = () => {
        setIsViewPmAdditionTermsOpen(false);
    };

    const { leaseAllInfo } = props;

    return (
        <div>
            {leaseAllInfo.specialConditions && (
                <AgreementSection
                    title="Additional Terms"
                    step={getPmStepNo(leaseAllInfo.location, 'specialConditions')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <span className="special-link" onClick={showViewAdditionTermsModal}>
                        View additional terms
                    </span>
                    {leaseAllInfo && leaseAllInfo.specialConditions && (
                        <ModalViewPmAdditionalTerms
                            isOpen={isViewPmAdditionTermsOpen}
                            closeModal={closeViewAdditionTermsModal}
                            leaseAllInfo={leaseAllInfo}
                        />
                    )}
                </AgreementSection>
            )}
        </div>
    );
};

export default AgreementPmAdditionalTerms;
