import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { getFormError } from '../../../../../utils/finalFormUtils';
import { CHAT_GPT_INTEGRATION } from '../../../../../constants/featureFlags';

import cx from 'classnames';
import styles from './Textarea.module.scss';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

type TextareaProps = {
    name: string;
    ariaLabel?: string;
    label?: string;
    labelClassName?: string;
    required?: boolean;
    placeholder?: string;
    validateOnTouch?: boolean;
    disabled?: boolean;
    isChatGpt?: boolean;
    gpt_fieldName?: string;
    gpt_isLease?: boolean;
    gpt_isDocument?: boolean;
    gpt_documentId?: string;
    gpt_leaseId?: string;
};

type TextareaComponentProps = FieldRenderProps<string, HTMLElement> & TextareaProps;

const TextareaComponent: React.FC<TextareaComponentProps> = ({
    input,
    meta,
    label,
    required,
    labelClassName,
    placeholder,
    ariaLabel,
    validateOnTouch = true,
    disabled
}) => {
    const error = getFormError(meta, validateOnTouch);
    return (
        <div
            className={cx(styles.container, { [styles.withError]: !!error, [styles.disabled]: disabled })}
            data-has-error={!!error}
        >
            {label && (
                <label className={cx(styles.label, labelClassName)} htmlFor={input.name}>
                    {label + (required ? '*' : '')}
                </label>
            )}
            <div className={styles.inputContainer}>
                <textarea
                    disabled={disabled}
                    aria-label={ariaLabel}
                    required={required}
                    className={styles.input}
                    placeholder={placeholder}
                    {...input}
                />
            </div>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

const ChatGptTextAreaComponent: React.FC<TextareaComponentProps> = ({
    input,
    meta,
    label,
    required,
    labelClassName,
    placeholder,
    ariaLabel,
    validateOnTouch = true,
    disabled,
    gpt_fieldName,
    gpt_isLease,
    gpt_isDocument,
    gpt_documentId,
    gpt_leaseId
}) => {
    const error = getFormError(meta, validateOnTouch);
    const [inputText, setInputText] = useState('');

    const getCompletionText = useMutation(
        (data: { inputText: string }) => {
            return axios.post(`/api/open-ai/completion`, {
                ...data,
                fieldName: gpt_fieldName || input.name,
                isLease: gpt_isLease,
                isDocument: gpt_isDocument,
                documentId: gpt_documentId,
                leaseId: gpt_leaseId
            });
        },
        {
            onSuccess: response => {
                input.onChange(response.data.choices[0].message.content);
            }
        }
    );

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        input.onChange(event);
        setInputText(event.target.value);
    };

    const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        const key = event.keyCode || event.which;
        if (inputText.startsWith('//') && key === 13) {
            event.preventDefault();
            getCompletionText.mutate({ inputText: inputText.split('//')[1].trim() });
        }
    };

    return (
        <div
            className={cx(styles.container, { [styles.withError]: !!error, [styles.disabled]: disabled })}
            data-has-error={!!error}
        >
            {label && (
                <label className={cx(styles.label, labelClassName)} htmlFor={input.name}>
                    {label + (required ? '*' : '')}
                </label>
            )}
            <div className={styles.inputContainer}>
                <textarea
                    disabled={disabled || getCompletionText.isLoading}
                    aria-label={ariaLabel}
                    required={required}
                    className={styles.input}
                    placeholder={placeholder}
                    {...input}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDown}
                />
            </div>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

const Textarea: React.FC<TextareaProps> = ({ ...props }) => {
    const isChatGptEnabled = useFeatureFlag(CHAT_GPT_INTEGRATION);
    return (
        <Field
            component={props.isChatGpt && isChatGptEnabled ? ChatGptTextAreaComponent : TextareaComponent}
            {...props}
        />
    );
};

export { Textarea };
