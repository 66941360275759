import * as React from 'react';
import FormDatePicker from './FormDatePicker';
import { useSelector } from 'react-redux';
import { getAgencyTimezoneFromUser } from '../../utils/dateUtils';
import { getUserInfo } from '../../selectors/user';

const DatePickerInAgencyTimeZone = props => {
    const loggedInUser = useSelector(getUserInfo);
    const timeZone = getAgencyTimezoneFromUser(loggedInUser);

    return <FormDatePicker {...props} timeZone={timeZone} />;
};

export default DatePickerInAgencyTimeZone;
