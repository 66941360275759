import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';

import '../../../sass/fees.scss';
import { FormTextRegular } from '../../../components/form/FormText';

class Fees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // todo: implement save button changes from Profile.js once this component is implemented.
        };
    }
    componentDidMount() {}
    render() {
        /*const isPending = this.props.userState.SAVE_RESIDENTIAL_TENANCY_FEES_REQUEST_PENDING;
        const savedSuccessfully = this.props.userState.SAVE_RESIDENTIAL_TENANCY_FEES_SUCCESS;
        const saveFailed = this.props.userState.SAVE_RESIDENTIAL_TENANCY_FEES_FAIL;*/
        const isPending = false;
        const savedSuccessfully = false;
        const saveFailed = false;
        const enterForm = (
            <Form onSubmit={() => {}}>
                {({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="form-content">
                                <FormTextRegular name="comission" label="Agency commision (%)" />
                                <FormTextRegular name="expenses" label="Advertising per lease" />
                                <FormTextRegular name="adminFee" label="Admin fee" />
                                <FormTextRegular name="leasingFee" label="Leasing fee" />
                                <FormTextRegular name="leaseRenewalFee" label="Lease renewal fee" />
                                <FormTextRegular name="leasePreparationFee" label="Lease preparation fee" />
                            </div>
                            {!isPending && (
                                <div className="footer">
                                    <button type="submit">Save changes</button>
                                </div>
                            )}
                            {isPending && <div className="footer">Saving changes</div>}
                            {savedSuccessfully && <div>Saved</div>}
                            {saveFailed && <div>Save failed</div>}
                        </form>
                    );
                }}
            </Form>
        );
        return (
            <div className="fees">
                <div className="panel">
                    <h2>Default fees</h2>
                    {enterForm}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({}),
    {}
)(Fees);
