import {
    isLeaseTypeResidentialTenancy,
    isTerminationNoticeEnabled as getIsTerminationNoticeEnabled,
    isRentIncreaseEnabled as getIsRentIncreaseEnabled,
    isLeaseTypeSales,
    isLongTermRenewalSubLease,
    isLeaseLocationNSW
} from '../../../../utils/agreementUtils';
import { NSW_STATE, HIDE_MESSAGE_TIME } from '../../../../config';
import React, { memo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getAgentSignature, getIsGettingAgentSignature } from '../../../../selectors/completion';
import { useAgreementUpdate } from '../../../../hooks/useAgreementUpdate';
import {
    openRentIncreaseLeaseListModal,
    openTerminateLeaseListModal,
    openTerminateLeaseModal
} from '../../../../actions/terminateLease';
import RentIncreaseForm from '../../../../containers/dashboard/documents/rentIncrease/RentIncreaseForm';
import EspLetterModal from '../../../../components/modals/EspLetterModal';
import EspLetterListModal from '../../../../components/modals/EspLetterListModal';
import { openNoSignatureModal } from '../../../../actions/user';
import { getUserInfo } from '../../../../selectors/user';
import {
    getIsSendingCompletedOwnerCopyEmail,
    getIsSendingCompletedOwnerCopyEmailSentFail,
    getIsSendingCompletedOwnerCopyEmailSentSuccess,
    getIsSendingLeasePdfEmail
} from '../../../../selectors/lease';
import CompletionProgress from './CompletionProgress';
import ButtonGroup from '../../../../common/components/ButtonGroup';
import axios from 'axios';
import { updateLeaseInTerminations } from '../../../../actions/dashboard';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { NSW_ESTIMATED_SELLING_PRICE_NOTICE } from '../../../../constants/featureFlags';
const Complete = props => {
    const dispatch = useDispatch();

    const leaseAllInfo = props.leaseAllInfo;
    const leaseId = leaseAllInfo.id;

    const [progress, setProgress] = useState(leaseAllInfo.completion ? leaseAllInfo.completion.progress : []);
    const [noAgentSignature, setNoAgentSignature] = useState(false);
    const [isRentIncreaseModalOpen, setIsRentIncreaseModalOpen] = useState(false);
    const [isEspModalOpen, setIsEspModalOpen] = useState(false);
    const [isEspListModalOpen, setIsEspListModalOpen] = useState(false);
    const [isSendingLandlordPdf, setIsSendingLandlordPdf] = useState(false);
    const [isSendLandlordPdfSuccess, setIsSendLandlordPdfSuccess] = useState(false);
    const [isSendLandlordPdfFail, setIsSendLandlordPdfFail] = useState(false);
    const isNswEstimatedSellingPriceNoticeActive = useFeatureFlag(NSW_ESTIMATED_SELLING_PRICE_NOTICE);
    const agentSignature = useSelector(getAgentSignature);

    const loggedInUser = useSelector(getUserInfo);

    // FLK-2534
    // const rentIncreasePdfEmailSentSuccess = useSelector(getIsRentIncreasePdfEmailSentSuccess);
    // const rentIncreasePdfEmailSentFail = useSelector(getIsRentIncreasePdfEmailSentFail);

    const sendingCompletedOwnerCopyEmailSentSuccess = useSelector(getIsSendingCompletedOwnerCopyEmailSentSuccess);
    const sendingCompletedOwnerCopyEmailSentFail = useSelector(getIsSendingCompletedOwnerCopyEmailSentFail);
    const isSendingLeasePdfEmail = useSelector(getIsSendingLeasePdfEmail);
    const isGettingAgentSignature = useSelector(getIsGettingAgentSignature);

    const isSendingCompletedOwnerCopyEmail = useSelector(getIsSendingCompletedOwnerCopyEmail);

    const isTerminationNoticeEnabled = getIsTerminationNoticeEnabled(leaseAllInfo);
    const isRentIncreaseEnabled = getIsRentIncreaseEnabled(leaseAllInfo);

    const downloadLandlordPDF = () => {
        window.open(`/reader/landlordAcknowledgement/${leaseId}`, '_blank');
    };

    const initiateTermination = () => {
        if (!isEmpty(agentSignature)) {
            dispatch(openTerminateLeaseModal(leaseAllInfo, loggedInUser));
        } else {
            setNoAgentSignature(true);
            /**
             * @todo check if this should be in a .then promise
             */
            dispatch(openNoSignatureModal());
        }
    };

    const openTerminationListModal = () => {
        dispatch(openTerminateLeaseListModal());
    };

    const handleOpenRentIncreaseListModal = () => {
        dispatch(openRentIncreaseLeaseListModal());
    };

    const initiateRentIncrease = () => {
        if (!isEmpty(agentSignature)) {
            setIsRentIncreaseModalOpen(true);
        } else {
            setNoAgentSignature(true);
            dispatch(openNoSignatureModal());
        }
    };

    const initiateEspLetter = () => {
        if (!isEmpty(agentSignature)) {
            setIsEspModalOpen(true);
        } else {
            setNoAgentSignature(true);
            dispatch(openNoSignatureModal());
        }
    };

    const openEspListModal = () => {
        setIsEspListModalOpen(true);
    };

    const closeRentIncreaseModal = () => {
        setIsRentIncreaseModalOpen(false);
    };

    const downloadOwnerPDF = () => {
        window.open(`/reader/agreement/${leaseId}/owner`, '_blank');
    };
    const sendLandlordPDF = () => {
        setIsSendLandlordPdfSuccess(false);
        setIsSendLandlordPdfFail(false);
        setIsSendingLandlordPdf(true);
        axios
            .get(`/api/agency/lease/${leaseId}/send-landlord-pdf`)
            .then(() => {
                setIsSendLandlordPdfSuccess(true);
                setIsSendingLandlordPdf(false);
                setTimeout(() => {
                    setIsSendLandlordPdfSuccess(false);
                }, HIDE_MESSAGE_TIME);
            })
            .catch(() => {
                setIsSendLandlordPdfFail(true);
                setIsSendingLandlordPdf(false);
                setTimeout(() => {
                    setIsSendLandlordPdfFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    /**
     * Hook to listen to updates of agreements within an agency
     */
    useAgreementUpdate(leaseAllInfo.agency, item => {
        if (item.data && item.data.completion && item.data.completion.progress) {
            setProgress(item.data.completion.progress);
        }
        if (item.data && item.data.leaseTerminations && leaseId === item.data.id) {
            // Update leaseTerminations in redux
            dispatch(updateLeaseInTerminations(item.data.leaseTerminations));
        }
    });
    let leaseTypeLabel = isLeaseTypeResidentialTenancy(props.leaseType) ? 'lease' : 'agreement';
    if (isLongTermRenewalSubLease(leaseAllInfo.subLeaseType)) {
        leaseTypeLabel = 'Notice of Lease Extension';
    }
    return (
        <React.Fragment>
            <div className="section space-between">
                {leaseAllInfo && loggedInUser && (
                    <RentIncreaseForm
                        rentIncreaseWithoutLease={false}
                        isOpen={isRentIncreaseModalOpen}
                        closeRentIncreaseModal={closeRentIncreaseModal}
                        agreementInfo={leaseAllInfo}
                        loggedInUser={loggedInUser}
                    />
                )}

                {leaseAllInfo &&
                    loggedInUser &&
                    isLeaseTypeSales(props.leaseType) &&
                    isLeaseLocationNSW(leaseAllInfo.location) && (
                        <EspLetterModal
                            isOpen={isEspModalOpen}
                            closeEspLetterModal={() => setIsEspModalOpen(false)}
                            agreementInfo={leaseAllInfo}
                            loggedInUser={loggedInUser}
                        />
                    )}

                {leaseAllInfo && isLeaseTypeSales(props.leaseType) && (
                    <EspLetterListModal
                        isOpen={isEspListModalOpen}
                        closeEspLetterListModal={() => setIsEspListModalOpen(false)}
                        agreementInfo={leaseAllInfo}
                    />
                )}
                <h6 className="button-group-header">Documents</h6>
                {props.saveStatusTimeout &&
                    props.leasePdfEmailSentSuccess &&
                    props.action.indexOf('sendingLease') > -1 && (
                        <span className="savestatus-saved">
                            Sent {leaseTypeLabel} Email Successfully
                            <span className="icon savestatus-icon" />
                        </span>
                    )}

                {props.saveStatusTimeout && props.leasePdfEmailSentFail && props.action.indexOf('sendingLease') > -1 && (
                    <span className="savestatus-failed">
                        Send {leaseTypeLabel} Email Failed
                        <span className="icon savestatus-icon" />
                    </span>
                )}
                <ButtonGroup
                    title={`Copy of ${leaseTypeLabel}`}
                    buttonList={[
                        {
                            onClick: props.downloadLeasePDF,
                            disabled: isSendingLeasePdfEmail,
                            className: 'logo-button',
                            label: 'View PDF',
                            icon: 'view'
                        },
                        {
                            onClick: props.emailLeasePDF,
                            disabled: isSendingLeasePdfEmail,
                            className: 'logo-button',
                            label: 'Send me PDF',
                            icon: 'send',
                            loading: isSendingLeasePdfEmail
                        }
                    ]}
                />
                {sendingCompletedOwnerCopyEmailSentSuccess && (
                    <span className="savestatus-saved">
                        Owner Version Email Sent Successfully
                        <span className="icon savestatus-icon" />
                    </span>
                )}

                {sendingCompletedOwnerCopyEmailSentFail && (
                    <span className="savestatus-failed">
                        Owner Version Email Sent Failed
                        <span className="icon savestatus-icon" />
                    </span>
                )}
                {isLeaseTypeResidentialTenancy(props.leaseType) && leaseAllInfo.ownerCopyPdf && (
                    <ButtonGroup
                        title={`Owner copy of ${leaseTypeLabel}`}
                        buttonList={[
                            {
                                onClick: downloadOwnerPDF,
                                disabled: false,
                                className: 'logo-button',
                                label: 'View PDF',
                                icon: 'view'
                            },
                            {
                                onClick: props.sendCompletedOwnerCopyToAgent,
                                disabled: isSendingCompletedOwnerCopyEmail,
                                className: 'logo-button',
                                label: 'Send me PDF',
                                icon: 'send',
                                loading: isSendingCompletedOwnerCopyEmail
                            }
                        ]}
                    />
                )}
                {isSendLandlordPdfSuccess && (
                    <span className="savestatus-saved">
                        Landlords Acknowledgement Email Sent Successfully
                        <span className="icon savestatus-icon" />
                    </span>
                )}

                {isSendLandlordPdfFail && (
                    <span className="savestatus-failed">
                        Landlords Acknowledgement Email Sent Failed
                        <span className="icon savestatus-icon" />
                    </span>
                )}
                {isLeaseTypeResidentialTenancy(props.leaseType) &&
                    leaseAllInfo.location === NSW_STATE &&
                    props.canPreviewLandlordPdf && (
                        <ButtonGroup
                            title="Landlords acknowledgement"
                            buttonList={[
                                {
                                    onClick: downloadLandlordPDF,
                                    disabled: false,
                                    className: 'logo-button',
                                    label: 'View PDF',
                                    icon: 'view'
                                },
                                {
                                    onClick: sendLandlordPDF,
                                    loading: isSendingLandlordPdf,
                                    disabled: false,
                                    className: 'logo-button',
                                    label: 'Send me PDF',
                                    icon: 'send'
                                }
                            ]}
                        />
                    )}
                <props.Section48PdfButton leaseAllInfo={leaseAllInfo} />
                {isRentIncreaseEnabled && (
                    <React.Fragment>
                        <h6 className="button-group-header">Rent increase</h6>
                        <ButtonGroup
                            title="Rent increase"
                            buttonList={[
                                {
                                    onClick: handleOpenRentIncreaseListModal,
                                    disabled: false,
                                    className: 'logo-button',
                                    label: 'Open',
                                    icon: 'open'
                                },
                                {
                                    onClick: initiateRentIncrease,
                                    disabled: isRentIncreaseModalOpen || noAgentSignature || isGettingAgentSignature,
                                    className: 'filled-button',
                                    label: 'New Rent Increase'
                                }
                            ]}
                        />
                    </React.Fragment>
                )}
                {!isNswEstimatedSellingPriceNoticeActive && isLeaseTypeSales(props.leaseType) && isLeaseLocationNSW(leaseAllInfo.location) && (
                    <React.Fragment>
                        <h6 className="button-group-header">Revised ESP</h6>
                        <ButtonGroup
                            title="Revised ESP"
                            buttonList={[
                                {
                                    onClick: openEspListModal,
                                    disabled: false,
                                    className: 'logo-button',
                                    label: 'Open',
                                    icon: 'open'
                                },
                                {
                                    onClick: initiateEspLetter,
                                    disabled: isEspModalOpen || noAgentSignature || isGettingAgentSignature,
                                    className: 'filled-button',
                                    label: 'Prepare ESP Letter'
                                }
                            ]}
                        />
                    </React.Fragment>
                )}
                {isTerminationNoticeEnabled && (
                    <React.Fragment>
                        <h6 className="button-group-header">Termination</h6>
                        <ButtonGroup
                            title="Termination notice"
                            buttonList={[
                                {
                                    onClick: openTerminationListModal,
                                    disabled: false,
                                    className: 'logo-button',
                                    label: 'Open',
                                    icon: 'open'
                                },
                                {
                                    onClick: initiateTermination,
                                    disabled:
                                        props.terminateLeaseModalOpen || noAgentSignature || isGettingAgentSignature,
                                    className: 'filled-button',
                                    label: 'Initiate Termination'
                                }
                            ]}
                        />
                    </React.Fragment>
                )}
                <CompletionProgress progress={progress} />
            </div>
        </React.Fragment>
    );
};

Complete.propTypes = {
    saveStatusTimeout: PropTypes.any,
    rentIncreasePdfEmailSentSuccess: PropTypes.any,
    action: PropTypes.any,
    leaseAllInfo: PropTypes.any,
    leasePdfEmailSentSuccess: PropTypes.any,
    leasePdfEmailSentFail: PropTypes.any,
    terminateLeaseModalOpen: PropTypes.any,
    leaseType: PropTypes.any,
    emailLeasePDF: PropTypes.func,
    downloadLeasePDF: PropTypes.func,
    sendCompletedOwnerCopyToAgent: PropTypes.func,
    canPreviewLandlordPdf: PropTypes.bool
};

export default memo(Complete);
