import { createSelector } from 'reselect';

export const getKey = state => state.flkAKey;

export const getIsOpenFlkAKeyForm = createSelector([getKey], key => key.isOpenFlkAKeyForm);
export const getCurrentKey = createSelector([getKey], key => key.currentKey);
export const getKeyFormIsEditable = createSelector([getKey], key => key.isEditable);
export const getIsOpenFlkAKeyCheckInModal = createSelector([getKey], key => key.isOpenFlkAKeyCheckInModal);
export const getSelectAll = createSelector([getKey], key => key.selectAll);
export const getSelectedKeys = createSelector([getKey], key => key.selectedKeys);
export const getAddressFilter = createSelector([getKey], key => key.addressFilter);
export const getCurrentKeyImages = createSelector([getKey], key => key.images);
export const getCurrentKeyImageUrls = createSelector([getKey], key => key.imageUrls);
export const getIsReminderModalOpen = createSelector([getKey], key => key.isReminderModalOpen);
export const getReminderKey = createSelector([getKey], key => key.reminderKey);
export const getReloadKeyList = createSelector([getKey], key => key.reloadKeyList);
