import * as React from 'react';
import cx from 'classnames';

import Icon, { Icons } from '../../../../common/components/Icon';
import Button from '../../../../common/components/Button.js';

import styles from './AddPlaceholderButton.module.scss';

type AddPlaceholderButtonProps = {
    onClick: () => void;
    className?: string;
    text: string;
};

export const AddPlaceholderButton: React.FC<AddPlaceholderButtonProps> = ({ onClick, className, text }) => {
    return (
        <Button onClick={onClick} className={cx(className, styles.button)}>
            <Icon icon={Icons.PLUS} className={styles.icon} />
            {text}
        </Button>
    );
};
