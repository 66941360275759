import React, { memo } from 'react';

import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { FormTextRegular } from '../../../../components/form/FormText';
import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../config';

import CommunityScheme from '../../../../components/lease/mainScreen/common/additionalTerms/CommunityScheme';
import AllowAnimals from '../../../../components/lease/mainScreen/common/additionalTerms/AllowAnimals';
import SwimmingPool from '../../../../components/lease/mainScreen/common/additionalTerms/SwimmingPool';
import DefenceClause from '../../../../components/lease/mainScreen/common/additionalTerms/DefenceClause';
import SeparatedWaterUsage from '../../../../components/lease/mainScreen/common/additionalTerms/SeparatedWaterUsage';
import LeaseRenewal from '../../../../components/lease/mainScreen/common/additionalTerms/LeaseRenewal';
import GardeningClause from '../../../../components/lease/mainScreen/common/additionalTerms/GardeningClause';

const QldAdditional = ({ values, addPet, allowPets, clearPets, push, additional, location, loggedInUser }) => {
    return (
        <React.Fragment>
            {values.communityScheme !== null && <CommunityScheme />}
            {values.communityScheme === YES_VALUE && (
                <div className="additional-group">
                    <div className="formBox-column">
                        <FormRadioGroup
                            label="Is the owners corporation of the strata scheme responsible for the repair and replacement of smoke alarms in the residential premises?"
                            name={'isOwnerResponsibleForRepairSmokeAlarms'}
                            className="additional-term-item"
                            data={[
                                {
                                    label: YES_LABEL,
                                    className: 'is-owner-responsible-yes',
                                    id: 'is-owner-responsible-yes',
                                    value: YES_VALUE
                                },
                                {
                                    label: NO_LABEL,
                                    className: 'is-owner-responsible-no',
                                    id: 'is-owner-responsible-no',
                                    value: NO_VALUE
                                }
                            ]}
                        />
                    </div>
                </div>
            )}
            <AllowAnimals
                values={values}
                allowPets={allowPets}
                clearPets={clearPets}
                addPet={addPet}
                push={push}
                additional={additional}
                location={location}
            />
            <SwimmingPool values={values} hasSwimingPoolMaintenance={true} location={location} />
            <DefenceClause />
            <FormRadioGroup
                label="Is electricity supplied to the premises from an embedded network?"
                name={'isElectricitySupplied'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'is-electricity-supplied-yes',
                        id: 'is-electricity-supplied-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'is-electricity-supplied-no',
                        id: 'is-electricity-supplied-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <SeparatedWaterUsage />
            <FormRadioGroup
                label="Is gas supplied to the premises from an embedded network?"
                name={'isGasSupplied'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'is-gas-supplied-yes',
                        id: 'is-gas-supplied-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'is-gas-supplied-no',
                        id: 'is-gas-supplied-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <div>
                <div className="question">
                    <p>
                        Indicate whether the smoke alarms installed in the residential premises are hardwired or battery
                        operated:
                    </p>
                </div>
                <div className="">
                    <CheckboxCheck name="smokeAlarm.isHardwired" className="isHardwired" label="Hardwired" />
                    {values.smokeAlarm && values.smokeAlarm.isHardwired && (
                        <div className="additional-group">
                            <div className="formBox-column">
                                <FormRadioGroup
                                    label="If the smoke alarms are hardwired, are the back-up batteries in the smoke alarms of a kind the tenant can replace?"
                                    name={'smokeAlarm.hardwired.canReplaceBatteries'}
                                    className="additional-term-item"
                                    data={[
                                        {
                                            label: YES_LABEL,
                                            className: 'hardwired-can-replace-batteries-yes',
                                            id: 'hardwired-can-replace-batteries-yes',
                                            value: YES_VALUE
                                        },
                                        {
                                            label: NO_LABEL,
                                            className: 'hardwired-can-replace-batteries-no',
                                            id: 'hardwired-can-replace-batteries-no',
                                            value: NO_VALUE
                                        }
                                    ]}
                                />
                                {values.smokeAlarm && values.smokeAlarm.hardwired.canReplaceBatteries === YES_VALUE && (
                                    <div className="smoke-group">
                                        <FormRadioGroup
                                            label="If yes, specify the type of back-up battery that needs to be used if the back-up battery in the smoke alarm needs to be replaced:"
                                            name={'smokeAlarm.hardwired.batteryType'}
                                            className="additional-term-item"
                                            data={[
                                                {
                                                    label: '9v',
                                                    className: 'hardwired-battery-type-9v',
                                                    value: '9v'
                                                },
                                                {
                                                    label: 'Other',
                                                    className: 'hardwired-battery-type-other',
                                                    value: 'other'
                                                }
                                            ]}
                                        />
                                        {values.smokeAlarm && values.smokeAlarm.hardwired.batteryType === 'other' && (
                                            <FormTextRegular
                                                name="smokeAlarm.hardwired.customBatteryType"
                                                label="Battery Type"
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <CheckboxCheck
                        name="smokeAlarm.isBatteryOperated"
                        className="isBatteryOperated"
                        label="Battery Operated"
                    />
                    {values.smokeAlarm && values.smokeAlarm.isBatteryOperated && (
                        <div className="additional-group">
                            <div className="formBox-column">
                                <div>
                                    <FormRadioGroup
                                        label="If the smoke alarms are battery operated, are the batteries in the smoke alarms of a kind the tenant can replace?"
                                        name={'smokeAlarm.batteryOperated.canReplaceBatteries'}
                                        className="additional-term-item"
                                        data={[
                                            {
                                                label: YES_LABEL,
                                                className: 'battery-operated-can-replace-batteries-yes',
                                                id: 'battery-operated-can-replace-batteries-yes',
                                                value: YES_VALUE
                                            },
                                            {
                                                label: NO_LABEL,
                                                className: 'battery-operated-can-replace-batteries-no',
                                                id: 'battery-operated-can-replace-batteries-no',
                                                value: NO_VALUE
                                            }
                                        ]}
                                    />
                                    {values.smokeAlarm &&
                                        values.smokeAlarm.batteryOperated.canReplaceBatteries === YES_VALUE && (
                                            <div className="smoke-group">
                                                <FormRadioGroup
                                                    label="If yes, specify the type of battery that needs to be used if the battery in the smoke alarm needs to be replaced:"
                                                    name={'smokeAlarm.batteryOperated.batteryType'}
                                                    className="additional-term-item"
                                                    data={[
                                                        {
                                                            label: '9v',
                                                            className: 'battery-operated-battery-type-9v',
                                                            id: 'battery-operated-battery-type-9v',
                                                            value: '9v'
                                                        },
                                                        {
                                                            label: 'Other',
                                                            className: 'battery-operated-battery-type-other',
                                                            id: 'battery-operated-battery-type-other',
                                                            value: 'other'
                                                        }
                                                    ]}
                                                />
                                                {values.smokeAlarm &&
                                                    values.smokeAlarm.batteryOperated.batteryType === 'other' && (
                                                        <FormTextRegular
                                                            name="smokeAlarm.batteryOperated.customBatteryType"
                                                            label="Battery Type"
                                                        />
                                                    )}
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {values.isLeaseRenewalEnabled !== null && <LeaseRenewal values={values} loggedInUser={loggedInUser} />}
            <GardeningClause />
        </React.Fragment>
    );
};

export default memo(QldAdditional);
