import React, { useState } from 'react';
import ModalDialog from '../../../../common/components/ModalDialog';
import SignaturePlaceholdersSummary from './SignaturePlaceholdersSummary';

const UnevenSignaturesConfirmModal = ({
    values,
    isSubmitting,
    isSending,
    isOpen,
    closeModal,
    annotationsCount,
    isTemplate,
    onEdit,
    onSave
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    return (
        <ModalDialog
            showModalHeader={false}
            isOpen={isOpen}
            closeModal={() => {
                closeModal();
            }}
            className="no-padding-modal confirm-modal"
            zIndex={10}
        >
            <div className="react-confirm-alert">
                <div className="react-confirm-alert-body">
                    <p>
                        It looks like there is an uneven amount of signatures required for this document, are you sure
                        you want to continue?
                    </p>
                    <div className="clients-container grey_full_width_block__container">
                        <SignaturePlaceholdersSummary
                            values={values}
                            isTemplate={isTemplate}
                            annotationsCount={annotationsCount}
                        />
                    </div>

                    <div className="react-confirm-alert-button-group custom-button">
                        <button
                            onClick={() => {
                                setIsDisabled(true);
                                onEdit();
                            }}
                            disabled={isSubmitting || isSending || isDisabled}
                        >
                            {(isSubmitting || isDisabled) && <span className="savestatus-spinner-await-completion" />}
                            Edit PDF
                        </button>
                        <button
                            onClick={() => {
                                onSave();
                            }}
                            disabled={isSubmitting || isSending || isDisabled}
                        >
                            {isSending && <span className="savestatus-spinner-await-completion" />}
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </ModalDialog>
    );
};

export default UnevenSignaturesConfirmModal;
