import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils';
import { LeaseAllInfo } from './types';
import {
    OUTGOING_HANDLED_AS_FIXED_PERCENTAGE,
    OUTGOING_HANDLED_AS_ESTIMATES_PROVIDED
} from '../../../../../config';

type Props = {
    leaseAllInfo: LeaseAllInfo,
}

export default function AgreementClInformation({ leaseAllInfo }: Props) {
    const part = 'information';
    if (!leaseAllInfo?.information) {
        return <></>;
    }
    const { location, information } = leaseAllInfo;
    return (
        <div>
            <AgreementSection
                title={getClStepTitle(location, part)}
                step={getClStepNo(location, part)}
                leaseAllInfo={leaseAllInfo}
            >
                {information && (
                    <div className="data data-row">
                        {information.outgoingsHandledAs === OUTGOING_HANDLED_AS_FIXED_PERCENTAGE && (
                            <div className="data-string">
                                <span className="data-contact">
                                    The percentage of outgoings contributed is:
                                </span>
                                <p className="text">{information.outgoingsPercentage}</p>
                            </div>
                        )}
                        {information.outgoingsHandledAs === OUTGOING_HANDLED_AS_ESTIMATES_PROVIDED && (
                            <div className="data-string">
                                <span className="data-contact">The estimates provided for outgoings contributed is:</span>
                                <p className="text">{information.outgoingsEstimate}</p>
                            </div>
                        )}
                        <div className="data-string">
                            <span className="data-contact">Public Liability Insurance required is:</span>
                            <p className="text">{information.publicLiabilityInsurance}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">Default Interest is (%):</span>
                            <p className="text">{information.interest}</p>
                        </div>
                    </div>
                )}
            </AgreementSection>
        </div>
    );
}
