import React, { memo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import PopupBasicDocumentsMore from '../../../popups/PopupBasicDocumentsMore';
import { getUserInfo, isUserRoleAdmin } from '../../../../selectors/user';
import { openCancelReasonModal, openDocumentForm } from '../../../../actions/document';
import { DOCUMENT_CREATE_MODE } from '../../../../config';
import { removeAttributesForClone } from '../../../../utils/documentUtils';
import { LEASE_STATUS_DRAFT } from '../../../../actions/dashboard';
import { useHistory } from 'react-router-dom';
import { isAccountEnabled } from '../../../../utils/userUtils';

const BasicDocumentsMoreOptionBlock = props => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [morePopup, setMorePopup] = useState(false);

    const loggedInUser = useSelector(getUserInfo);
    const isUserAdmin = useSelector(isUserRoleAdmin);
    const docId = props.docId;

    const toggleMorePopup = e => {
        e.stopPropagation();
        setMorePopup(!morePopup);
    };
    const closeMorePopupList = () => {
        setMorePopup(false);
    };

    const showModalInfoAgreement = event => {
        event.stopPropagation();
        props.showModalInfoAgreement();
        closeMorePopupList();
    };
    const showEditTenants = event => {
        event.stopPropagation();
        props.showEditTenants();
        closeMorePopupList();
    };

    const cancelDocument = e => {
        e.stopPropagation();
        closeMorePopupList();
        dispatch(openCancelReasonModal(props.doc));
    };

    const cancelCloneDocument = e => {
        e.stopPropagation();
        closeMorePopupList();
        const clone = true;
        const data = removeAttributesForClone(props.doc);
        dispatch(openCancelReasonModal(data, clone));
    };

    const cloneDocument = e => {
        e.stopPropagation();
        closeMorePopupList();
        const data = removeAttributesForClone(props.doc);
        delete data.id;
        history.push(`/dashboard/documents/${data.docType}/${LEASE_STATUS_DRAFT}`);
        dispatch(openDocumentForm(data.docType, data, DOCUMENT_CREATE_MODE));
    };

    const { agreementStatus } = props;

    return (
        <div className="more-action-wrapper" style={{ position: 'relative' }}>
            {isAccountEnabled(loggedInUser) && <div className="more-action" onClick={e => toggleMorePopup(e)} />}

            {morePopup && (
                <PopupBasicDocumentsMore
                    agreementStatus={agreementStatus}
                    doc={props.doc}
                    agentSignature={props.agentSignature ? props.agentSignature : null}
                    morePopupListAgreements={morePopup}
                    closeMorePopupList={closeMorePopupList}
                    cancelDocument={cancelDocument}
                    cancelCloneDocument={cancelCloneDocument}
                    cloneDocument={cloneDocument}
                    showEditTenants={showEditTenants}
                    showModalInfoAgreement={showModalInfoAgreement}
                    isUserAdmin={isUserAdmin}
                    docType={props.docType}
                    leaseId={docId}
                    closePopup={closeMorePopupList}
                />
            )}
        </div>
    );
};

export default memo(BasicDocumentsMoreOptionBlock);
