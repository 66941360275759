import React from 'react';

import { FormTextRegular } from '../../../components/form/FormText';
import EmailList from '../../../components/form/EmailList';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import {
    DELIVERY_TYPE_EMAIL,
    DELIVERY_TYPE_OTHER,
    DELIVERY_TYPE_HAND,
    DELIVERY_TYPE_POST,
    DELIVERY_TO_AGENT,
    DELIVERY_TO_AGENT_AND_TENANT,
    DELIVERY_TO_AGENT_AND_VENDOR
} from '../../../config';
import { capitalize } from '../../../utils/generalUtils';

const DeliveryTypeComponent = ({
    // Callback function to handle the email addresses for agent and tenant
    emailAgentAndTenantHandler,

    // Callback function to handle email field error
    addEmailFieldError,

    // Form values
    values,

    // Form object from React Final Form
    form,

    // List of delivery options
    optionsList = [DELIVERY_TYPE_HAND, DELIVERY_TYPE_POST, DELIVERY_TYPE_EMAIL, DELIVERY_TYPE_OTHER],

    // Whether the user is an agent and vendor - used just by ESP letter and notice
    isAgentAndVendor,

    // Whether the component is read-only
    isReadOnly
}) => {
    /*
     * This component can be disabled in two ways:
     * 1. By passing the 'disabled' prop from the 'values' object, which comes from React Final Form.
     *    This method is used by older components that expect a 'disabled' prop in their model.
     * 2. Using the 'isReadOnly' prop, which is the simplest way to disable the component.
     *    This prop directly determines whether the component is read-only.
     */
    const disabled = values.disabled || isReadOnly;

    // This function is called when the user selects the email delivery option
    const setDeliveryType = (setValue, values) => {
        // Set value for emailTo field based on whether the user is an agent and vendor
        setValue('emailTo', isAgentAndVendor ? DELIVERY_TO_AGENT_AND_VENDOR : DELIVERY_TO_AGENT_AND_TENANT);
        if (emailAgentAndTenantHandler) {
            /**
             *  Workaround to set the deliver type to email when it is an agent and vendor
             *  As it as old shared component, its values can be handled by React-final-form and some by react state.
             *  This workaround keep the compatibility with the old shared component.
             */
            if (!isAgentAndVendor) {
                values.deliveryType = DELIVERY_TYPE_EMAIL;
            }

            emailAgentAndTenantHandler(values, setValue);
        }
    };

    const dataList = [];
    optionsList.forEach(option => {
        if (option === DELIVERY_TYPE_EMAIL) {
            dataList.push({
                label: 'Email',
                value: DELIVERY_TYPE_EMAIL,
                disabled,
                onClick: () => {
                    setDeliveryType(form.change, values);
                }
            });
        } else {
            dataList.push({
                label: capitalize(option.replace(/_/g, ' ')),
                value: option,
                disabled
            });
        }
    });

    return (
        <React.Fragment>
            <FormRadioGroup
                label="The notice will be delivered by:"
                name="deliveryType"
                radioGroupClass="wrapped-radio-group"
                data={dataList}
                required
                disabled={disabled}
            />
            {values.deliveryType && values.deliveryType === DELIVERY_TYPE_OTHER && (
                <FormTextRegular name="deliveryTypeDetails" label="Delivery type details" disabled={disabled} />
            )}
            {values.deliveryType && values.deliveryType === DELIVERY_TYPE_EMAIL && (
                <FormRadioGroup
                    label="Email letter to:"
                    name="emailTo"
                    data={[
                        {
                            label: 'Agent Only',
                            value: DELIVERY_TO_AGENT,
                            disabled
                        },
                        {
                            label: isAgentAndVendor ? 'Agent and Vendor' : 'Agent and Tenant',
                            value: isAgentAndVendor ? DELIVERY_TO_AGENT_AND_VENDOR : DELIVERY_TO_AGENT_AND_TENANT,
                            onClick: () => {
                                if (emailAgentAndTenantHandler) {
                                    emailAgentAndTenantHandler(values, form.change);
                                }
                            },
                            disabled
                        }
                    ]}
                    disabled={disabled}
                />
            )}
            {values.deliveryType &&
                values.deliveryType === 'email' &&
                (values.emailTo === DELIVERY_TO_AGENT_AND_TENANT ||
                    values.emailTo === DELIVERY_TO_AGENT_AND_VENDOR) && (
                    <div>
                        <p className="emails-title">To:</p>
                        <EmailList
                            name={'emailAddresses'}
                            inputField="emailAddressField"
                            value={values.emailAddresses}
                            form={form}
                            disabled={disabled}
                        />
                        <p className="emails-title">CC:</p>
                        <EmailList
                            name={'ccEmailAddresses'}
                            inputField="ccemailAddressField"
                            value={values.ccEmailAddresses}
                            form={form}
                            disabled={disabled}
                        />
                        {addEmailFieldError && <p className="has-error">{addEmailFieldError}</p>}
                    </div>
                )}
        </React.Fragment>
    );
};
export default DeliveryTypeComponent;
