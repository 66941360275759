import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentReport } from '../../../actions/statistics';

import AgentReportTable from './components/AgentReportTable';

import '../../../sass/agent-reports.scss';

import { getAgentReportData, getIsLoadingAgentReport } from '../../../selectors/statistics';
import { PreLoader } from '../../../common/components/PreLoader';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { Form } from 'react-final-form';
import Button from '../../../common/components/Button';
import { startOfMonth, lastDayOfMonth, parseISO, format } from 'date-fns';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import QuickFilters from './components/QuickFilters';
import { getUser } from '../../../actions/user';
import { getAgent, getUserInfo } from '../../../selectors/user';
import { getAgency } from '../../../selectors/settings/account';
import { Link } from 'react-router-dom';
import {
    BREACH_NOTICE,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_TERMINATION_NOTICE,
    LEASE_TYPE_RESIDENTIAL,
    STANDARD_DATE_AMERICAN_FORMAT
} from '../../../config';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TrendingUpIcon from '@material-ui/icons/TrendingUpSharp';
import AnnouncementSharpIcon from '@material-ui/icons/AnnouncementSharp';
import TerminationNotice from '../../../common/components/icons/TerminationNotice';

const AgentReport = () => {
    const dispatch = useDispatch();

    let { id } = useParams();

    const location = useLocation();
    let queryParams = queryString.parse(location.search);
    const history = useHistory();

    const agentReport = useSelector(getAgentReportData);
    const isLoadingAgentReport = useSelector(getIsLoadingAgentReport);
    const agent = useSelector(getAgent);
    const loggedInUser = useSelector(getUserInfo);
    const agency = useSelector(getAgency);

    const leaseAgreements = agentReport ? agentReport.filter(item => item.leaseType) : [];
    const rentIncrease = agentReport ? agentReport.filter(item => item.docType === DOCUMENT_RENT_INCREASE) : [];
    const termination = agentReport ? agentReport.filter(item => item.docType === DOCUMENT_TERMINATION_NOTICE) : [];
    const breach = agentReport ? agentReport.filter(item => item.docType === BREACH_NOTICE) : [];

    if (!id) {
        id = loggedInUser.id;
    }

    const [startDate, setStartDate] = useState(
        queryParams.startDate ? parseISO(queryParams.startDate) : startOfMonth(new Date())
    );
    const [endDate, setEndDate] = useState(
        queryParams.endDate ? parseISO(queryParams.endDate) : lastDayOfMonth(new Date())
    );

    useEffect(() => {
        if (id) {
            dispatch(getUser(id));
            dispatch(getAgentReport(id, { startDate, endDate }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    const submitForm = values => {
        updateFiltersAndGetReports(values);
    };

    /**
     * Update the form and execute search
     * @param values
     */
    const updateFiltersAndGetReports = values => {
        setStartDate(values.startDate);
        setEndDate(values.endDate);

        const queryParams = {
            startDate: format(values.startDate, STANDARD_DATE_AMERICAN_FORMAT),
            endDate: format(values.endDate, STANDARD_DATE_AMERICAN_FORMAT)
        };
        const stringified = queryString.stringify(queryParams);
        history.push({
            search: stringified
        });
        dispatch(getAgentReport(id, values));
    };

    const getPreviousUrl = () => {
        if (history.location.state && history.location.state.from === '/user/agency/reports') {
            return history.location.state.from + history.location.state.search;
        }

        return '/user/agency/reports';
    };

    return (
        <div className="settings">
            <div className="agent-reports-page">
                <h1>Agent report for {agent.fullName}</h1>
                <Link to={getPreviousUrl} className="special-link">
                    Back to agency reports
                </Link>
                <Form initialValues={{ startDate, endDate }} onSubmit={submitForm} className={'filter-agent-reports'}>
                    {({ handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="row filter-form">
                                    <DatePickerInAgencyTimeZone
                                        label="From"
                                        name="startDate"
                                        selected={values.startDate}
                                        className={'start-date'}
                                    />
                                    <DatePickerInAgencyTimeZone
                                        label="To"
                                        name="endDate"
                                        selected={values.endDate}
                                        className={'end-date'}
                                    />
                                    <Button type="submit" primary disabled={isLoadingAgentReport}>
                                        Generate
                                    </Button>
                                </div>
                                <QuickFilters
                                    updateFunction={updateFiltersAndGetReports}
                                    disabled={isLoadingAgentReport}
                                />
                            </form>
                        );
                    }}
                </Form>
                <div className="tab-group">
                    <Tabs>
                        <TabList>
                            <Tab>
                                <span className="icon">
                                    <i className={`icon-${LEASE_TYPE_RESIDENTIAL}`} />
                                </span>
                                <span>Lease agreements</span>
                            </Tab>
                            <Tab>
                                <span>
                                    <TrendingUpIcon className="icon" />
                                </span>
                                <span>Rent increases</span>
                            </Tab>
                            <Tab>
                                <div className="flex-center">
                                    <span className="svgIcon flex-center">
                                        <TerminationNotice />
                                    </span>
                                    <span>Termination notices</span>
                                </div>
                            </Tab>
                            <Tab>
                                <span>
                                    <AnnouncementSharpIcon className="icon" />
                                </span>
                                <span>Breach notices</span>
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <div>
                                {isLoadingAgentReport && <PreLoader />}
                                {isLoadingAgentReport === false &&
                                    leaseAgreements &&
                                    leaseAgreements.length > 0 &&
                                    agency &&
                                    agency.details && (
                                        <AgentReportTable
                                            reportType="lease"
                                            agentReport={leaseAgreements}
                                            agency={agency}
                                            loggedInUser={loggedInUser}
                                        />
                                    )}
                                {isLoadingAgentReport === false &&
                                    agentReport &&
                                    leaseAgreements &&
                                    leaseAgreements.length === 0 &&
                                    agency &&
                                    agency.details && <p>No activity in this period</p>}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div>
                                {isLoadingAgentReport && <PreLoader />}
                                {isLoadingAgentReport === false &&
                                    rentIncrease &&
                                    rentIncrease.length > 0 &&
                                    agency &&
                                    agency.details && (
                                        <AgentReportTable
                                            reportType={DOCUMENT_RENT_INCREASE}
                                            agentReport={rentIncrease}
                                            agency={agency}
                                            loggedInUser={loggedInUser}
                                        />
                                    )}
                                {isLoadingAgentReport === false &&
                                    rentIncrease &&
                                    rentIncrease.length === 0 &&
                                    agency &&
                                    agency.details && <p>No activity in this period</p>}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div>
                                {isLoadingAgentReport && <PreLoader />}
                                {isLoadingAgentReport === false &&
                                    termination &&
                                    termination.length > 0 &&
                                    agency &&
                                    agency.details && (
                                        <AgentReportTable
                                            reportType={DOCUMENT_TERMINATION_NOTICE}
                                            agentReport={termination}
                                            agency={agency}
                                            loggedInUser={loggedInUser}
                                        />
                                    )}
                                {isLoadingAgentReport === false &&
                                    termination &&
                                    termination.length === 0 &&
                                    agency &&
                                    agency.details && <p>No activity in this period</p>}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div>
                                {isLoadingAgentReport && <PreLoader />}
                                {isLoadingAgentReport === false &&
                                    breach &&
                                    breach.length > 0 &&
                                    agency &&
                                    agency.details && (
                                        <AgentReportTable
                                            reportType={BREACH_NOTICE}
                                            agentReport={breach}
                                            agency={agency}
                                            loggedInUser={loggedInUser}
                                        />
                                    )}
                                {isLoadingAgentReport === false &&
                                    breach &&
                                    breach.length === 0 &&
                                    agency &&
                                    agency.details && <p>No activity in this period</p>}
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default memo(AgentReport);
