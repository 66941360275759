import React from 'react';
import { OPTION_TYPE_AGREE_ONLY, OPTION_TYPE_YES_OR_NO } from '../../../../../config';

import { FormTextMultiline, FormTextRegular } from './../../../../form/FormText';
import DoneIcon from '@material-ui/icons/DoneSharp';
import yesNoIcon from '@material-ui/icons/ThumbsUpDownSharp';
import { FormRadioGroupButton } from '../../../../form/FormRadioGroupButton';

export const MaterialFactsGroup = ({ index, removeOtherMaterialFact, disabled, values }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Material Fact {index + 1}</h3>
                    <div className="toggle" onClick={() => removeOtherMaterialFact(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormRadioGroupButton
                        className={'option-group'}
                        label=""
                        name={`clauseList[${index}].optionType`}
                        data={[
                            {
                                label: 'Yes/No',
                                value: OPTION_TYPE_YES_OR_NO,
                                icon: yesNoIcon
                            },
                            {
                                label: 'Agree',
                                value: OPTION_TYPE_AGREE_ONLY,
                                icon: DoneIcon
                            }
                        ]}
                        validateOnTouch={false}
                        value={values.clauseList[index].optionType}
                    />
                    <FormTextRegular name={`clauseList[${index}].title`} label="Title" disabled={disabled} />
                    <FormTextMultiline
                        name={`clauseList[${index}].description`}
                        label="Description"
                        required
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};
