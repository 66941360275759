import {
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales,
    isLeaseTypeCommercialLease
} from '../../../utils/agreementUtils';
import LeaseIntegration from '../../../containers/dashboard/LeaseIntegration';
import React, { useEffect, useState } from 'react';

import * as PropTypes from 'prop-types';
import { getHeaderTitle } from '../../../utils/formUtils';
import * as dashboard from '../../../actions/dashboard';
import EditableContent from '../../form/EditableContent';
import EditableAgentContent from '../../form/EditableAgentContent';
import { changeAgreementAgent } from '../../../actions/dashboard';
import { getAddresses } from '../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getAddresses as addressesSelector } from '../../../selectors/lease/addresses';

import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import SavingIndicator from '../components/SavingIndicator';
import { getIsRequestingTeam, getTeam } from '../../../selectors/agency';
import { getAllTeamMembers } from '../../../actions/agency';

export const HeaderLeft = props => {
    const dispatch = useDispatch();
    const leaseAllInfo = props.leaseAllInfo;

    const team = useSelector(getTeam);
    const isRequestingTeam = useSelector(getIsRequestingTeam);

    const [agent, setAgent] = useState({
        value: leaseAllInfo.agent.id,
        label: leaseAllInfo.agent.fullName
    });

    /**
     * Update agent when lease all info is grabbed
     */
    useEffect(() => {
        if (leaseAllInfo.agent && leaseAllInfo.agent.fullName) {
            setAgent({
                value: leaseAllInfo.agent.id,
                label: leaseAllInfo.agent.fullName
            });
        }
        /**
         * If we dont have team yet, fetch team as it is used for the agent switch of an agreement
         */
        if (!isRequestingTeam && team.length === 0) {
            dispatch(getAllTeamMembers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaseAllInfo]);

    const addresses = useSelector(addressesSelector);

    let headerStatus;
    let staticAddress = (
        <div className="header-container address-info for-lg-modal">
            <label>
                <p>
                    <b>Address: </b>
                </p>
            </label>
            <p>{leaseAllInfo.address}</p>
        </div>
    );

    const downloadLeasePDF = () => {
        const leaseId = leaseAllInfo.id;
        window.open(`/reader/agreement/${leaseId}`, '_blank');
    };

    const dataChangedAgent = data => {
        setAgent(data.agent);
        return dispatch(changeAgreementAgent(leaseAllInfo.id, data.agent.value));
    };

    const onGetAddresses = data => {
        return dispatch(getAddresses(data));
    };

    let addressAgent = (
        <React.Fragment>
            {staticAddress}
            <div className="editable">
                {agent.value && (
                    <EditableAgentContent type="input" agent={agent} paramName="agent" editContent={dataChangedAgent} />
                )}
            </div>
        </React.Fragment>
    );

    const headerTitle = getHeaderTitle(leaseAllInfo.leaseType, leaseAllInfo.subLeaseType);

    switch (leaseAllInfo.status) {
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            headerStatus = <span className="status new">sent for signing</span>;
            break;
        }
        case dashboard.LEASE_STATUS_DRAFT: {
            addressAgent = (
                <div>
                    <div className="editable">
                        <EditableContent
                            type="input"
                            address={leaseAllInfo.address}
                            paramName="address"
                            leaseId={leaseAllInfo.id}
                            addresses={addresses}
                            getAddresses={onGetAddresses}
                        />
                    </div>
                    <div className="editable">
                        {agent.value && (
                            <EditableAgentContent
                                type="input"
                                agent={agent}
                                paramName="agent"
                                editContent={dataChangedAgent}
                            />
                        )}
                    </div>
                    {(isLeaseTypeSales(leaseAllInfo.leaseType) || isLeaseTypePm(leaseAllInfo.leaseType)) &&
                        !props.isCompletedLease && (
                            <div className="links for-lg-modal">
                                <a onClick={downloadLeasePDF} className="special-link">
                                    <PictureAsPdfSharpIcon /> Generate partially completed PDF
                                </a>
                            </div>
                        )}
                </div>
            );
            headerStatus = <span className="status draft">draft</span>;
            break;
        }
        case dashboard.LEASE_STATUS_AWAITING_COMPLETION:
            headerStatus = <span className="status completed">awaiting completion</span>;
            break;
        case dashboard.LEASE_STATUS_ARCHIVED:
        case dashboard.LEASE_STATUS_COMPLETE: {
            addressAgent = (
                <div>
                    {staticAddress}
                    <div className="editable">
                        {agent.value && (
                            <EditableAgentContent
                                type="input"
                                agent={agent}
                                paramName="agent"
                                editContent={dataChangedAgent}
                            />
                        )}
                    </div>
                </div>
            );
            headerStatus = <span className="status archived">complete</span>;
            break;
        }
        case dashboard.LEASE_STATUS_AWAITING_RENEWAL: {
            headerStatus = <span className="status awaiting_renewal">awaiting renewal</span>;
            break;
        }
        case dashboard.LEASE_STATUS_CANCELLED: {
            headerStatus = <span className="status cancelled">cancelled</span>;
            break;
        }
    }

    return (
        <div className="header-left">
            <div className="header-left-wrapper">
                <div>
                    <div className="header-title">
                        <h1>{headerTitle}</h1>
                        <div className="status-wrapper">{headerStatus}</div>
                        <SavingIndicator />
                    </div>
                    {addressAgent}
                    {(isLeaseTypeCommercialLease(leaseAllInfo.leaseType) ||
                        isLeaseTypeResidentialTenancy(leaseAllInfo.leaseType)) && <LeaseIntegration />}
                </div>
            </div>
        </div>
    );
};

HeaderLeft.propTypes = {
    leaseAllInfo: PropTypes.object.isRequired
};
