import { createSelector } from 'reselect';
import { getDashboard } from './';
import { isLeaseTypePm, isLeaseTypeSales, isLeaseTypeCommercialLease } from '../../utils/agreementUtils';
import { has } from 'lodash';

export const getAgreementInfo = createSelector([getDashboard], dashboard => dashboard.agreementInfo);

export const getTenant = createSelector([getAgreementInfo], info => info.tenant);

export const getTenants = createSelector([getTenant], tenant => tenant?.tenants);

export const getAdditionalTerms = createSelector([getAgreementInfo], info => info.additionalTerms);
export const getAuditTrail = createSelector([getAgreementInfo], info => info.auditTrail);

export const hasAdditionalTermCommunityScheme = createSelector(
    [getAdditionalTerms],
    terms => terms && terms.communityScheme === true
);

export const getSignatories = createSelector([getAgreementInfo], info => {
    if (isLeaseTypeSales(info.leaseType)) {
        return has(info, 'signatory.signatories') ? info.signatory.signatories : [];
    } else if (isLeaseTypePm(info.leaseType)) {
        return has(info, 'pmLandlord.persons') ? info.pmLandlord.persons : [];
    } else if (isLeaseTypeCommercialLease(info.leaseType)) {
        let signatories = [];
        if (has(info, 'lessee.persons')) {
            signatories = signatories.concat(info.lessee.persons);
        }
        if (has(info, 'guarantor.persons') && !info.guarantor.noGuarantor) {
            signatories = signatories.concat(info.guarantor.persons);
        }
        return signatories;
    } else {
        return has(info, 'tenant.tenants') ? info.tenant.tenants : [];
    }
});
