import { AgreementOrDocumentTypes, SubLeaseTypes } from '@app/types/Agreement';
import { ALL_AGREEMENT_FEATURE_FLAGS, ALL_AGREEMENT_FEATURE_FLAGS_LIST } from '@app/utils/featureFlagUtils';
import { getAvailableStates } from '@app/config';
import { useCachedFeatureFlags } from '@app/hooks/useCachedFeatureFlag';

export function useLeaseTypeEnabled() {
    const featureFlags = useCachedFeatureFlags(ALL_AGREEMENT_FEATURE_FLAGS_LIST);

    // Check's whether a leasetype and state is enabled by feature flag
    // returns true if a feature flag is not configured
    function isFeatureFlagEnabled(documentType: AgreementOrDocumentTypes | SubLeaseTypes, location: string): boolean {
        const key = `${documentType}${location}`;
        if (ALL_AGREEMENT_FEATURE_FLAGS.has(key)) {
            return !!featureFlags[ALL_AGREEMENT_FEATURE_FLAGS.get(key)];
        } else {
            return true;
        }
    }
    function allowedStates(documentType: AgreementOrDocumentTypes | SubLeaseTypes) {
        const states = getAvailableStates(documentType);
        const allowedStates = states.filter(state => isFeatureFlagEnabled(documentType, state));
        return allowedStates;
    }
    return {
        isFeatureFlagEnabled,
        allowedStates
    };
}
