import * as React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getPmStepNo } from '../../../../../utils/agreementUtils';
import {
    SMOKE_ALARM_MAINTAIN_COMPANY,
    SMOKE_ALARM_ARRANGED_PRIOR,
    SMOKE_ALARM_ALREADY_COMPLETED,
    SMOKE_ALARM_AS_SPECIFIED
} from '../../../../../config';

const AgreementPmPoolSafetyAndSmokeAlarms = ({ leaseAllInfo }) => {
    return (
        <div>
            {!!leaseAllInfo.poolSafetyAndSmokeAlarms && (
                <AgreementSection
                    title="Pool Safety & Smoke Alarms"
                    step={getPmStepNo(leaseAllInfo.location, 'poolSafetyAndSmokeAlarms')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <div>
                            {leaseAllInfo.poolSafetyAndSmokeAlarms && leaseAllInfo.poolSafetyAndSmokeAlarms.hasPool && (
                                <p className="name">
                                    The property has a pool and pool type is:{' '}
                                    <strong>{leaseAllInfo.poolSafetyAndSmokeAlarms.poolType}</strong>
                                </p>
                            )}
                            {leaseAllInfo.poolSafetyAndSmokeAlarms &&
                                !leaseAllInfo.poolSafetyAndSmokeAlarms.hasPool && (
                                    <p className="name"> The property does not have a pool </p>
                                )}
                        </div>
                        <div>
                            {leaseAllInfo.poolSafetyAndSmokeAlarms && (
                                <p className="name">
                                    Smoke Alarms: <strong>{leaseAllInfo.poolSafetyAndSmokeAlarms.smokeAlarms}</strong>
                                </p>
                            )}
                        </div>
                        <div>
                            {leaseAllInfo.poolSafetyAndSmokeAlarms && (
                                <React.Fragment>
                                    <p className="name">
                                        Service and maintenance of smoke alarms:{' '}
                                        {leaseAllInfo.poolSafetyAndSmokeAlarms.serviceAndMaintenanceOfSmokeAlarms ===
                                            SMOKE_ALARM_ARRANGED_PRIOR && (
                                            <strong>
                                                To be arranged by the agent prior to the commencement of the 1st lease.
                                            </strong>
                                        )}
                                        {leaseAllInfo.poolSafetyAndSmokeAlarms.serviceAndMaintenanceOfSmokeAlarms ===
                                            SMOKE_ALARM_ALREADY_COMPLETED && (
                                            <strong>
                                                As already completed at the commencement of the current lease.
                                            </strong>
                                        )}
                                        {leaseAllInfo.poolSafetyAndSmokeAlarms.serviceAndMaintenanceOfSmokeAlarms ===
                                            SMOKE_ALARM_AS_SPECIFIED && <strong>As specified</strong>}
                                    </p>
                                    {leaseAllInfo.poolSafetyAndSmokeAlarms.serviceAndMaintenanceOfSmokeAlarms ===
                                        SMOKE_ALARM_AS_SPECIFIED && (
                                        <React.Fragment>
                                            <p className="name">
                                                Last Tested & Cleaned:{' '}
                                                <strong>
                                                    {
                                                        leaseAllInfo.poolSafetyAndSmokeAlarms
                                                            .lastTestedAndCleanedFormatted
                                                    }
                                                </strong>
                                            </p>
                                            <p className="name">
                                                Last Battery Change:{' '}
                                                <strong>
                                                    {leaseAllInfo.poolSafetyAndSmokeAlarms.lastBatteryChangeFormatted}
                                                </strong>
                                            </p>
                                        </React.Fragment>
                                    )}
                                    <p className="name">
                                        Service Life Indicated by Manufacturer:{' '}
                                        <strong>
                                            {leaseAllInfo.poolSafetyAndSmokeAlarms.serviceLifeIndicatedByManufacturer}
                                        </strong>
                                    </p>
                                    <p className="name">
                                        Smoke alarms will be maintained By:{' '}
                                        <strong>{leaseAllInfo.poolSafetyAndSmokeAlarms.smokeAlarmsMaintainedByLabel}</strong>
                                    </p>
                                    {leaseAllInfo.poolSafetyAndSmokeAlarms.smokeAlarmsMaintainedBy ===
                                        SMOKE_ALARM_MAINTAIN_COMPANY && (
                                        <React.Fragment>
                                            <p className="name">
                                                Company Name:{' '}
                                                <strong>
                                                    {leaseAllInfo.poolSafetyAndSmokeAlarms.smokeAlarmCompanyName}
                                                </strong>
                                            </p>
                                            <p className="name">
                                                Amount:{' '}
                                                <strong>
                                                    {leaseAllInfo.poolSafetyAndSmokeAlarms.smokeAlarmAmount}
                                                </strong>
                                            </p>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </AgreementSection>
            )}
        </div>
    );
};

export default AgreementPmPoolSafetyAndSmokeAlarms;
