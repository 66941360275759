import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import { ClientSection, ClientSectionUiModel } from '../BuildADoc.model';
import Client from './Client';
import Button from '../../../../../common/components/Button.js';

import styles from './ClientsAccordion.module.scss';
import Icon, { Icons } from '../../../../../common/components/Icon';
import { useImmer } from 'use-immer';

type ClientsAccordionProps = {
    clientSectionData: ClientSectionUiModel;
    clientSectionType: ClientSection;
    onAddClient: () => void;
    onRemoveClient: (index: number) => void;
    onClearClient: (index: number) => void;
    onToggleCorporation: (index: number) => void;
    onToggleIsPrimary?: (value: boolean, index: number) => void;
    isReadOnly?: boolean;
};

const ClientsAccordion: React.FC<ClientsAccordionProps> = ({
    clientSectionData,
    clientSectionType,
    onAddClient,
    onRemoveClient,
    onClearClient,
    onToggleCorporation,
    isReadOnly,
    onToggleIsPrimary
}) => {
    const [clientsOpenState, setClientsOpenState] = useImmer(
        clientSectionData.clients.map((_, index) => ({
            isOpen: true,
            id: `${clientSectionType}-${index}`
        }))
    );

    const addClient = () => {
        onAddClient();
        setClientsOpenState(draftClients => {
            draftClients.push({ id: `${clientSectionType}-${draftClients.length}`, isOpen: true });
        });
    };

    const removeClient = (index: number) => {
        onRemoveClient(index);
        setClientsOpenState(draftClients => {
            draftClients.splice(index, 1);
        });
    };

    return (
        <>
            {/* TODO: Wrap this as its own component */}
            <Accordion.Root
                className={styles.accordion}
                type="multiple"
                value={clientsOpenState.filter(client => client.isOpen).map(client => client.id)}
                onValueChange={openClients =>
                    setClientsOpenState(draftClients => {
                        draftClients.forEach(client => {
                            client.isOpen = openClients.includes(client.id);
                        });
                    })
                }
            >
                {clientSectionData.clients.map((client, index) => {
                    const { id, isOpen } = clientsOpenState[index];
                    const baseName = `${clientSectionType}.clients[${index}]`;

                    return (
                        <Client
                            client={client}
                            clientSectionType={clientSectionType}
                            key={index}
                            id={id}
                            isOpen={isOpen}
                            namePrefix={baseName}
                            onRemoveClient={() => removeClient(index)}
                            onClearClient={() => onClearClient(index)}
                            onToggleCorporation={() => onToggleCorporation(index)}
                            onToggleIsPrimary={(value: boolean) => onToggleIsPrimary?.(value, index)}
                            isReadOnly={isReadOnly}
                        />
                    );
                })}
            </Accordion.Root>
            <Button
                className={styles.addClientButton}
                type="button"
                onClick={addClient}
                tertiary
                startIcon={<Icon icon={Icons.PLUS} className={styles.addClientIcon} />}
                disabled={isReadOnly}
            >
                Add another person
            </Button>
        </>
    );
};

export default ClientsAccordion;
