import { createSelector } from 'reselect';

export const getAccount = state => state.account;

export const getSetTariffPlanRequestPending = createSelector(
    [getAccount],
    account => account.setTariffPlanRequestPending
);

export const getDefaultPaymentMethod = createSelector([getAccount], account => account.agency.defaultPaymentMethod);
export const getTariffPlan = createSelector([getAccount], account => account.agency.tariffPlan);
export const getAccountType = createSelector([getAccount], account => account.agency.accountType);
