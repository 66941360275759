import React, { Component } from 'react';
import * as dashboard from '../../../actions/dashboard';
import '../../../sass/dashboardContentTable.scss';
import TableDraft from './tables/TableDraft';
import TableSentForSigning from './tables/TableSentForSigning';
import TableAwaiting from './tables/TableAwaiting';
import TableRenewals from './tables/TableRenewals';
import TableArchived from './tables/TableArchived';
import { LEASE_TYPE_RESIDENTIAL } from '../../../config';
import TableCancelled from './tables/TableCancelled';

class ResidentialTenancy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Open agreement details modal window.
     * @param {object} agreementInfo - lease agreement model to be shown.
     */
    showModalInfoAgreement(agreementInfo) {
        this.props.showModalInfoAgreement(agreementInfo);
    }

    render() {
        const { agreementList, leaseStatus } = this.props;
        let contentTable;
        switch (leaseStatus) {
            case dashboard.LEASE_STATUS_DRAFT: {
                contentTable = (
                    <TableDraft
                        agreementList={agreementList}
                        showModalInfoAgreement={lease => this.showModalInfoAgreement(lease)}
                        leaseStatus={leaseStatus}
                        leaseType={LEASE_TYPE_RESIDENTIAL}
                    />
                );
                break;
            }
            case dashboard.LEASE_STATUS_SENT_SIGNING: {
                contentTable = (
                    <TableSentForSigning
                        agreementList={agreementList}
                        showModalInfoAgreement={lease => this.showModalInfoAgreement(lease)}
                        leaseStatus={leaseStatus}
                        leaseType={LEASE_TYPE_RESIDENTIAL}
                    />
                );
                break;
            }
            case dashboard.LEASE_STATUS_AWAITING_COMPLETION: {
                contentTable = (
                    <TableAwaiting
                        agreementList={agreementList}
                        showModalInfoAgreement={lease => this.showModalInfoAgreement(lease)}
                        leaseStatus={leaseStatus}
                        leaseType={LEASE_TYPE_RESIDENTIAL}
                    />
                );
                break;
            }
            case dashboard.LEASE_STATUS_AWAITING_RENEWAL: {
                contentTable = (
                    <TableRenewals
                        agreementList={agreementList}
                        showModalInfoAgreement={lease => this.showModalInfoAgreement(lease)}
                        leaseStatus={leaseStatus}
                        leaseType={LEASE_TYPE_RESIDENTIAL}
                    />
                );
                break;
            }
            case dashboard.LEASE_STATUS_ARCHIVED: {
                contentTable = (
                    <TableArchived
                        agreementList={agreementList}
                        showModalInfoAgreement={lease => this.showModalInfoAgreement(lease)}
                        leaseStatus={leaseStatus}
                        leaseType={LEASE_TYPE_RESIDENTIAL}
                    />
                );
                break;
            }
            case dashboard.LEASE_STATUS_CANCELLED: {
                contentTable = (
                    <TableCancelled
                        agreementList={agreementList}
                        showModalInfoAgreement={lease => this.showModalInfoAgreement(lease)}
                        leaseStatus={leaseStatus}
                        leaseType={LEASE_TYPE_RESIDENTIAL}
                    />
                );
                break;
            }
        }

        return <div className="table-sort">{contentTable}</div>;
    }
}

export default ResidentialTenancy;
