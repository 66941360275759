import React, { Component } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../../utils/agreementUtils';
import { STEP_PETS } from '../../../../../lease/steps';
import { NO_LABEL, YES_LABEL } from '../../../../../../config';

class AgreementResidentialPets extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.pets && (
                    <AgreementSection
                        title="Pets"
                        step={getResTenStepNo(leaseAllInfo.location, STEP_PETS, leaseAllInfo.subLeaseType)}
                        leaseAllInfo={leaseAllInfo}
                    >
                        <div>
                            {leaseAllInfo.pets.isApprovedPets === YES_LABEL && (
                                <p className="text">A Pet Is Approved</p>
                            )}
                            {leaseAllInfo.pets.isApprovedPets === NO_LABEL && <p className="text">No Pets Approved</p>}
                            {leaseAllInfo.pets.isApprovedPets === YES_LABEL &&
                                leaseAllInfo.pets.petsList.map((pet, index) => <p key={index}>{pet.type}</p>)}
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementResidentialPets;
