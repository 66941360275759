import React from 'react';
import { FormButtonSelect } from '../../../../../components/form/responsive/FormButtonSelect';
import { gstList, qldGstList } from '../../../../../config';

import { FormTextRegular } from '../../../../form/FormText';
import { QLD_STATE } from '../../../../../config';

export const FeesGroup = ({ fee, index, removeFee, location }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>
                        {location === QLD_STATE ? 'Other' : 'Fee'} {index + 1}
                    </h3>
                    <div className="toggle" onClick={() => removeFee(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular name={`additionalFeesList[${index}].description`} label="Description" required />
                    <div className="fee-item">
                        <div className="nowrap fields-full-width-for-mobile">
                            <FormTextRegular name={`additionalFeesList[${index}].amount`} label="Amount" required />
                            <FormButtonSelect
                                label="GST"
                                name={`additionalFeesList[${index}].incGST`}
                                selectClassName="gst-select"
                                options={location === QLD_STATE ? qldGstList : gstList}
                                value={fee.incGST}
                            />
                        </div>
                        {location === QLD_STATE && (
                            <FormTextRegular
                                name={`additionalFeesList[${index}].payable`}
                                label="When payable"
                                required
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
