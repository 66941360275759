import React, { memo, useState } from 'react';
import Select from 'react-select';
import { has, find } from 'lodash';
import appHistory from './../../../AppHistory';
import * as dashboard from '../../../actions/dashboard';

import '../../../sass/statusToolbar.scss';
import PopupNotificationCount from '../../popups/PopupNotificationCount';

/**
 * Component to show status toolbar. It need to change lease agreement statuses.
 */
const DocumentStatusToolbar = props => {
    const options = [
        { value: dashboard.LEASE_STATUS_DRAFT, label: 'Drafts' },
        { value: dashboard.LEASE_STATUS_AWAITING_COMPLETION, label: 'Awaiting completion' }
    ];
    let [selectedOption, setSelectedOption] = useState(find(options, { value: props.leaseStatus }));

    /**
     * Change status of document to filter.
     * @param {string} status - status of agreement.
     * @return {void}
     */

    const changeDocumentStatus = status => {
        appHistory.push(`/dashboard/documents/${props.leaseType}/${status}`);
    };

    const onChangeDropdown = item => {
        setSelectedOption(item);
        changeDocumentStatus(item.value);
    };

    const getTotalCount = status => {
        let { pagination } = props;
        if (has(pagination, `${status}.totalItemsCount`)) {
            return pagination[status].totalItemsCount;
        }

        return 0;
    };

    const availableStatuses = dashboard.agreementStatuses[props.leaseType];
    return (
        <div className="tb-status">
            <Select
                className="tb-status__dropdown mobile-only"
                classNamePrefix="react-select"
                options={options}
                onChange={item => onChangeDropdown(item)}
                value={selectedOption}
                isSearchable={false}
            />
            <div className="menu-block">
                {availableStatuses.includes(dashboard.LEASE_STATUS_DRAFT) && (
                    <div
                        className={
                            props.leaseStatus === dashboard.LEASE_STATUS_DRAFT
                                ? 'item-menu drafts active'
                                : 'item-menu drafts'
                        }
                        onClick={() => changeDocumentStatus(dashboard.LEASE_STATUS_DRAFT)}
                    >
                        Drafts
                    </div>
                )}
                {availableStatuses.includes(dashboard.LEASE_STATUS_SENT_SIGNING) && (
                    <div
                        className={
                            props.leaseStatus === dashboard.LEASE_STATUS_SENT_SIGNING
                                ? 'item-menu new active'
                                : 'item-menu new'
                        }
                        onClick={() => changeDocumentStatus(dashboard.LEASE_STATUS_SENT_SIGNING)}
                    >
                        Sent for signing
                    </div>
                )}
                {availableStatuses.includes(dashboard.LEASE_STATUS_AWAITING_COMPLETION) && (
                    <div
                        className={
                            props.leaseStatus === dashboard.LEASE_STATUS_AWAITING_COMPLETION
                                ? 'item-menu completed active'
                                : 'item-menu completed'
                        }
                        onClick={() => changeDocumentStatus(dashboard.LEASE_STATUS_AWAITING_COMPLETION)}
                    >
                        Awaiting completion
                        <PopupNotificationCount
                            notificationsCount={getTotalCount(dashboard.LEASE_STATUS_AWAITING_COMPLETION)}
                            showWhenNone={true}
                        />
                    </div>
                )}
                {availableStatuses.includes(dashboard.LEASE_STATUS_AWAITING_RENEWAL) && (
                    <div
                        className={
                            props.leaseStatus === dashboard.LEASE_STATUS_AWAITING_RENEWAL
                                ? 'item-menu awaiting active'
                                : 'item-menu awaiting'
                        }
                        onClick={() => changeDocumentStatus(dashboard.LEASE_STATUS_AWAITING_RENEWAL)}
                    >
                        Renewals due
                    </div>
                )}
                {availableStatuses.includes(dashboard.LEASE_STATUS_COMPLETE) && (
                    <div
                        className={
                            props.leaseStatus === dashboard.LEASE_STATUS_COMPLETE
                                ? 'item-menu archived active'
                                : 'item-menu archived'
                        }
                        onClick={() => changeDocumentStatus(dashboard.LEASE_STATUS_COMPLETE)}
                    >
                        Complete
                    </div>
                )}
                {availableStatuses.includes(dashboard.DOC_STATUS_ACTIVE) && (
                    <div
                        className={
                            props.leaseStatus === dashboard.DOC_STATUS_ACTIVE
                                ? 'item-menu doc-active active'
                                : 'item-menu doc-active'
                        }
                        onClick={() => changeDocumentStatus(dashboard.DOC_STATUS_ACTIVE)}
                    >
                        Sent
                    </div>
                )}
                {availableStatuses.includes(dashboard.DOC_STATUS_CANCELLED) && (
                    <div
                        className={
                            props.leaseStatus === dashboard.DOC_STATUS_CANCELLED
                                ? 'item-menu doc-cancelled active'
                                : 'item-menu doc-cancelled'
                        }
                        onClick={() => changeDocumentStatus(dashboard.DOC_STATUS_CANCELLED)}
                    >
                        Cancelled
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(DocumentStatusToolbar);
