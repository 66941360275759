import cookie from 'react-cookie';

export function toSaveToken(userFromLogin, rememberMe, accountType) {
    if (rememberMe === 'false') {
        cookie.save('userFromLogin', userFromLogin ? userFromLogin.id : 'unknown', {
            Expire: 'Session',
            path: '/'
        });
        cookie.save('rememberMe', rememberMe, {
            Expire: 'Session',
            path: '/'
        });
        cookie.save('accountType', accountType, {
            Expire: 'Session',
            path: '/'
        });
    } else {
        cookie.save('userFromLogin', userFromLogin ? userFromLogin.id : 'unknown', {
            Expire: 'Session',
            path: '/'
        });
        cookie.save('rememberMe', rememberMe, {
            maxAge: 3600 * 840,
            path: '/'
        });
        cookie.save('accountType', accountType, {
            Expire: 'Session',
            path: '/'
        });
    }
}
