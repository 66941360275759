import React, { useEffect, useState } from 'react';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { EMAIL_STUDIO } from '../../constants/featureFlags';
import Button from '../../common/components/Button';
import { Form, FormSpy } from 'react-final-form';
import { FormTextMultiline, FormTextRegular } from '../../components/form/FormText';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAgency } from '../../selectors/agency';
import { getUserInfo } from '../../selectors/user';
import { getEmailTemplates, getEmailTemplteFields } from '../../selectors/emailTemplate';
import { getAllEmailTemplates, updateEmailTemplate } from '../../actions/emailTemplate';
import styles from './EmailStudio.module.scss';

type Template = {
    name: string;
    displayName: string;
    content: string;
    subject: string;
    requiredFields?: FieldNames;
    optionalFields?: FieldNames;
};

type FieldNames = {
    signingLink?: boolean;
    agentName?: boolean;
    recipientName?: boolean;
};

type EmailEditorProps = {
    selectedTemplate?: Template;
};

const EmailEditor: React.FC<EmailEditorProps> = ({ selectedTemplate }) => {
    const [contentPreview, setContentPreview] = useState<string>(selectedTemplate?.content || '');
    const dispatch = useDispatch();
    if (!selectedTemplate) {
        return null;
    }
    function submitForm(values) {
        // todo error hanlde this here
        const payload = {
            content: values.content,
            // todo change this to be editable in form
            subject: values.subject,
            templateName: selectedTemplate.name
        };
        dispatch(updateEmailTemplate(payload));
    }
    return (
        <>
            <div className={styles.emailStudioItem}>
                <h1>Editing Template</h1>
                <p>{selectedTemplate.displayName}</p>
                <Form
                    onSubmit={submitForm}
                    initialValues={{
                        content: selectedTemplate.content,
                        subject: selectedTemplate.subject
                    }}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <FormTextRegular name={'subject'} label="Subject" />
                                    <FormTextMultiline name={'content'} label="Email Content" />
                                    <Button type="Submit">Save</Button>
                                    <FormSpy subscription={{ values: true }}>
                                        {({ values }) => {
                                            setContentPreview(values.content);
                                            return null;
                                        }}
                                    </FormSpy>
                                </form>
                            </>
                        );
                    }}
                </Form>
            </div>
            <EmailPreview message={contentPreview} template={selectedTemplate} />
        </>
    );
};

type EmailPreviewProps = {
    message: string;
    template: Template;
};

const EmailPreview: React.FC<EmailPreviewProps> = ({ message, template }) => {
    const [displayedMessage, setDisplayedMessage] = useState<string>('');
    const agentInfo = useSelector(getUserInfo);
    const textElements = useSelector(getEmailTemplteFields);
    const agency = useSelector(getUserAgency);

    function getReplacedValue(placeholder: string) {
        const data = {
            agentName: agentInfo.fullName,
            agencyName: agentInfo.agency.details.agencyName
        };

        if (!template?.requiredFields?.[placeholder] && !template?.optionalFields?.[placeholder]) {
            return placeholder;
        }
        // check if we have the placeholder in the data ^
        if (data[placeholder]) {
            return data[placeholder];
        }
        // fallback to the value provided in the template
        if (textElements && textElements[placeholder] && textElements[placeholder].preview) {
            return textElements[placeholder].preview;
        }

        // otherwise this will just strip away the {{}} from the string
        return placeholder;
    }

    function parseMessage(message: string) {
        if (!message) {
            return '';
        }
        return message.replace(/\{\{(\w+)\}\}/g, (unused, placeholder) => getReplacedValue(placeholder));
    }

    useEffect(() => {
        setDisplayedMessage(parseMessage(message));
    }, [message]);

    // todo style this to look like an actual email
    return (
        <div className={styles.emailStudioItem}>
            <h1>Email Preview</h1>
            <div className={styles.previewContainer}>
                <div className={styles.imgWrap}>
                    <img src={agency.logo}/>
                </div>
                <p style={{ whiteSpace: 'pre-wrap' }}>{displayedMessage}</p>
            </div>
        </div>
    );
};

const StudioBody = () => {
    const dispatch = useDispatch();
    const [selectedTemplate, setSelectedTemplate] = useState<Template>();
    const templates: Template[] = useSelector(getEmailTemplates);

    useEffect(() => {
        // todo add cleanup action here to remove all this data from the redux store
        dispatch(getAllEmailTemplates());
    }, [dispatch]);

    // temp testing values, will be replaced with DB values and stored in redux
    const handleSelectTemplate = (template: Template) => {
        setSelectedTemplate(template);
    };
    // todo change this to a spinner
    return (
        <div className={styles.emailStudioContainer}>
            <div className={styles.emailStudioItem}>
                <h1>Available Templates</h1>
                <ul id="templatesContainer">
                    {templates &&
                        Object.keys(templates).map(templateKey => {
                            const template = templates[templateKey]
                            return (
                                <li key={template.name}>
                                    <Button onClick={() => handleSelectTemplate(template)}>
                                        {template.displayName}
                                    </Button>
                                </li>
                            );
                        })}
                </ul>
            </div>
            <EmailEditor selectedTemplate={selectedTemplate} />
        </div>
    );
};
const EmailStudio = () => {
    const emailFlag = useFeatureFlag(EMAIL_STUDIO);
    switch (emailFlag) {
        case 'Preview':
            return (
                <div>
                    <h1>Email Studio</h1>
                    <br />
                    <p>Interested in customising your email templates? Reach out to our support team</p>
                </div>
            );
        case 'Enabled':
            return (
                <div>
                    <h1>Email Studio</h1>
                    <StudioBody />
                </div>
            );
        default:
            return (
                <div>
                    <h1>Email Studio</h1>
                    <br />
                    <p>This feature is currently disabled, please check back later</p>
                </div>
            );
    }
};
export default EmailStudio;
