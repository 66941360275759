import flatten from 'lodash/flatten';
import values from 'lodash/values';
import size from 'lodash/size';
import { createSelector } from 'reselect';
import { getDashboard } from '.';

export const getFlkAKeyList = createSelector([getDashboard], dashboard => dashboard.flkAKeyList);

export const getPageNav = createSelector([getDashboard], ({ pageNav }) => pageNav);
export const getKeyPageNav = createSelector([getDashboard], dashboard => dashboard.keyPageNav);

export const getHasKeys = createSelector([getFlkAKeyList], keys => {
    return keys ? size(flatten(values(keys))) > 0 : false;
});

export const getIsPending = createSelector([getDashboard], ({ isPending }) => isPending);

export const getHasInitialKeyListRequested = createSelector(
    [getDashboard],
    ({ initialKeyListRequested }) => initialKeyListRequested
);

export const getRefreshPage = createSelector([getDashboard], dashboard => dashboard.refreshPage);
export const getSocketUpdateDetails = createSelector([getDashboard], dashboard => dashboard.socketUpdateDetails);
