import React from 'react';
import { getPmStepNo, getPmStepTitle } from '../../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';

export default function AgreementPmMarketingCosts({ leaseAllInfo }) {
    const { pmMarketingCosts, location } = leaseAllInfo;
    if (!pmMarketingCosts) {
        return null;
    }
    const additionalCostsList = pmMarketingCosts.additionalCostsList || [];
    return (
        <div>
            {pmMarketingCosts && (
                <AgreementSection
                    title={getPmStepTitle(location, 'pmMarketingCosts')}
                    step={getPmStepNo(location, 'pmMarketingCosts')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        {[
                            {
                                title: 'Advertising (internet)',
                                amount: pmMarketingCosts.advertisingCost
                            },
                            {
                                title: 'Photography',
                                amount: pmMarketingCosts.photographyCost
                            },
                            {
                                title: 'Video Tour',
                                amount: pmMarketingCosts.videoTourCost
                            },
                            {
                                title: 'For Lease Signboard',
                                amount: pmMarketingCosts.signboardCost
                            },
                            {
                                title: 'Open for Inspections',
                                amount: pmMarketingCosts.openForInspectionsCost
                            },
                            ...additionalCostsList
                        ].map((fee, index) => {
                            return (
                                <div key={index}>
                                    <p className="name">
                                        {fee.title}: <strong>{fee.amount || 'N/A'}</strong>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </AgreementSection>
            )}
        </div>
    );
}
