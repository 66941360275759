import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import { LEASE_TENANTS_AGENT_SUCCESS } from '../../reducers/lease';
import produce from 'immer';

const initState = {
    firstName: '',
    secondName: '',
    email: '',
    phone: '',
    address: '',
    doNotHaveAgent: true
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS: {
            draftState = initState;
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            draftState = Object.assign({}, initState, action.payload.agreementInfo.tenantsAgent);
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = Object.assign({}, initState, action.lease.tenantsAgent);
            break;
        }
        case LEASE_TENANTS_AGENT_SUCCESS: {
            draftState = action.result.data.lease.tenantsAgent;
        }
    }

    return draftState;
});
