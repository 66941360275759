import * as React from 'react';
import { memo } from 'react';
import { OPTION_TYPE_AGREE_ONLY, OPTION_TYPE_YES_OR_NO } from '../../../config';

function MaterialFact({ item }) {
    let optionType;
    if (item.optionType === OPTION_TYPE_YES_OR_NO) {
        optionType = 'Yes/No';
    } else if (item.optionType === OPTION_TYPE_AGREE_ONLY) {
        optionType = 'Agree';
    }
    return (
        <li className="agreement-section-text__item">
            <div className="agreement-section-text__item--text">
                <strong>Title:</strong> {item.title}
            </div>
            {optionType && (
                <div className="agreement-section-text__item--text">
                    <strong>Answer type:</strong> {optionType}
                </div>
            )}
            <div className="agreement-section-text__item--text">
                <strong>Description:</strong> {item.description}
            </div>
        </li>
    );
}

export default memo(MaterialFact);
