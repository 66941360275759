import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import { editPermissions } from '../../../utils/userPermissions';
import { NO_LABEL, tabs, YES_LABEL } from '../../../config';
import Panel from '../../../common/components/Panel';
import { getUserInfo } from '../../../selectors/user';
import { getAgency } from '../../../selectors/settings/account';
import { useEffect, useState } from 'react';
import { has } from 'lodash';
import axios from 'axios';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import SaveButtonIcon from '../SaveButtonIcon';

const DefaultAnswers = () => {
    const loggedInUser = useSelector(getUserInfo);
    const agencySelected = useSelector(getAgency);
    const [agency, setAgency] = useState(agencySelected);

    useEffect(() => {
        setAgency(agencySelected);
    }, [agencySelected]);

    const saveContactDetails = useMutation(data => {
        return axios.post(`/api/agency/${agency.id}/emergency-contact-default-details`, data);
    });

    const handleSubmit = async values => {
        saveContactDetails.reset();
        try {
            await saveContactDetails.mutateAsync(values);
        } catch (error) {
            let err = {};
            if (has(error, 'response.data.errors.emergencyContactDefaultDetails')) {
                err = error.response.data.errors.emergencyContactDefaultDetails;
            }
            return err;
        }
    };

    let userRole = loggedInUser.role;

    return (
        <Form initialValues={agency.emergencyContactDefaultDetails} onSubmit={handleSubmit}>
            {({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit} id="defaultEmergencyContactDetailsForm">
                        <Panel
                            title="DEFAULTS"
                            rightButton={
                                <div>
                                    {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                        <SaveButtonIcon isSaving={saveContactDetails.isLoading} success={saveContactDetails.isSuccess} failure={saveContactDetails.isError} />
                                    )}
                                </div>
                            }
                        >
                            <h3>Default answer for 18.2 (QLD Only)</h3>
                            <br />
                            <FormRadioGroup
                                label="Are the nominated repairers the tenant's first point of contact for notifying the need for emergency repairs?"
                                name="isNominatedRepairers"
                                data={[
                                    {
                                        label: YES_LABEL,
                                        value: YES_LABEL
                                    },
                                    {
                                        label: NO_LABEL,
                                        value: NO_LABEL
                                    }
                                ]}
                            />
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};

export default DefaultAnswers;
