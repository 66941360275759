import React, { memo } from 'react';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { FormTextRegular, FormTextCurrency } from '../../../../components/form/FormText';
import { MarketingPlanGroup } from '../../../../components/lease/mainScreen/common/MarketingPlanGroup';
import { FormTextMultiline } from '../../../../components/form/FormText';

function MarketingAndAdvertising({ values, handleIsPlanSelect, removeItem, addPlan, marketingAndAdvertising }) {
    const isAuthorisedAdvertisingAmountEmpty =
        !values.authorisedAdvertisingAmount || values.authorisedAdvertisingAmount === '$';
    return (
        <>
            <FormTextMultiline
                name="specialInstructions"
                label="Special Instructions for advertisement and promotion of the property"
            />
            {!values.isMarketingPlan && (
                <FormTextCurrency
                    name="authorisedAdvertisingAmount"
                    label="Authorised Advertising Amount"
                    precision="2"
                />
            )}
            <FormTextRegular name="whenPayable" label="When Payable" required={!isAuthorisedAdvertisingAmountEmpty} />
            <div>
                <CheckboxCheck
                    name="isMarketingPlan"
                    label="Build itemised marketing campaign"
                    onClick={isMarketingPlan => handleIsPlanSelect(values, isMarketingPlan)}
                />
            </div>
            {values.isMarketingPlan && (
                <div>
                    {marketingAndAdvertising.marketingPlans.map((person, index) => (
                        <MarketingPlanGroup index={index} key={index} removeItem={() => removeItem(values, index)} />
                    ))}
                    {values.isMarketingPlan && (
                        <FormTextCurrency
                            name="marketingEstimate"
                            label="Authorised Advertising Amount $"
                            disabled
                            precision="2"
                        />
                    )}
                    <div className="button">
                        <button type="button" onClick={() => addPlan(values)}>
                            Add Marketing Service
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default memo(MarketingAndAdvertising);
