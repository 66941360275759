import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmRentAndPayment } from '../../../../selectors/lease/pmLease';

import { PAYMENT_METHOD_EFT, isPaymentMethodAvailable } from '../../../../config';
import { FormTextRegular } from '../../../../components/form/FormText';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { isTemplate } from '../../../../utils/agreementUtils';
import '../../../../sass/management/rentAndTerm.scss';

const PM_RENT_PAYMENT_FORM = 'pmRentAndPaymentForm';

const initState = {
    paymentData: {
        EFT: {
            enabled: false,
            details: {
                accountName: '',
                accountNumber: '',
                bankName: '',
                BSB: '',
                reference: ''
            }
        },
        OwnerToEnter: {
            enabled: false
        }
    }
};

function RentTermLayout(props, ref) {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmRentAndPayment = useSelector(getPmRentAndPayment);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmRentAndPayment, setPmRentAndPayment] = useState(reduxPmRentAndPayment || initState);
    const [error, setError] = useState('');

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_RENT_PAYMENT_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        const payload = {
            ...values,
            bypassFormValidation: currentRef.bypassFormValidation
        };
        return updatePmRentAndPayment(payload)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmRentAndPayment')) {
                    return error.response.data.errors.pmRentAndPayment;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmRentAndPayment = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-rent-and-payment`, data);
    };

    const validateData = values => {
        let isValid = false;
        if (isTemplate(leaseType)) {
            isValid = true;
        } else {
            for (const key in values.paymentData) {
                // eslint-disable-next-line no-prototype-builtins
                if (values.paymentData.hasOwnProperty(key)) {
                    isValid = isValid || !!values.paymentData[key].enabled;
                }
            }
        }
        if (!isValid) {
            setError('At least one payment method must be selected');
        } else {
            setError('');
        }
    };

    const handleEFT = (value, values) => {
        let newState = cloneDeep(values);
        if (value) {
            newState.paymentData.EFT.enabled = true;
            newState.paymentData.OwnerToEnter.enabled = false;
        }
        setPmRentAndPayment(newState);
        validateData(newState);
    };

    const handleOwnerToEnter = (value, values) => {
        let newState = cloneDeep(values);
        if (value) {
            newState.paymentData.EFT.enabled = false;
            newState.paymentData.OwnerToEnter.enabled = true;
        }
        setPmRentAndPayment(newState);
        validateData(newState);
    };

    useEffect(() => {
        validateData(pmRentAndPayment);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pmRentAndPayment]);

    return (
        <div className="form-container rent rent-term payment">
            <Form onSubmit={submitForm} initialValues={pmRentAndPayment}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="mobile-multi-select-wrapper"
                            noValidate
                            id={PM_RENT_PAYMENT_FORM}
                        >
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <p className="FormInput">Payment method</p>
                                <p className="FormInput">
                                    The Agent is authorised to deposit the rental monies into the following account. If
                                    these accounts details change at any time during the term of this Authority, the
                                    Client must advise the Agent in writing and verify the details in a follow-up phone
                                    call. The Client acknowledges that email communications can be intercepted so it is
                                    their responsibility to ensure the Agent has the correct details. If there are more
                                    than one Client, all Clients must consent to the change of any bank account details
                                    collectively.
                                </p>
                                <p className="help-text">Select payment method and fill in the payment details:</p>
                                {isPaymentMethodAvailable(PAYMENT_METHOD_EFT, leaseType, location) && (
                                    <React.Fragment>
                                        <CheckboxCheck
                                            name="paymentData.EFT.enabled"
                                            label="EFT"
                                            className={'eft mobile-multi-select-enabled'}
                                            onClick={value => handleEFT(value, values)}
                                        />
                                        {values.paymentData.EFT.enabled && (
                                            <div className="details">
                                                <p className="name">Details for EFT</p>
                                                <FormTextRegular
                                                    name="paymentData.EFT.details.accountName"
                                                    label="Account name"
                                                    required
                                                />
                                                <FormTextRegular
                                                    name="paymentData.EFT.details.bankName"
                                                    label="Bank name"
                                                    required
                                                />
                                                <FormTextRegular
                                                    name="paymentData.EFT.details.BSB"
                                                    label="BSB"
                                                    required
                                                />
                                                <FormTextRegular
                                                    name="paymentData.EFT.details.accountNumber"
                                                    label="Account number"
                                                    required
                                                />
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                                {isPaymentMethodAvailable('OwnerToEnter', leaseType, location) && (
                                    <CheckboxCheck
                                        className={'mobile-multi-select-enabled'}
                                        name="paymentData.OwnerToEnter.enabled"
                                        label="Owner To Enter"
                                        onClick={value => handleOwnerToEnter(value, values)}
                                    />
                                )}
                                <p className="FormInput">
                                    <span className="FormError">{error}</span>
                                </p>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(RentTermLayout));
