import React, { useEffect, memo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import * as lease from '../../actions/lease';
import * as sales from '../../actions/sales/sales';
import * as pm from '../../actions/pm/pm';
import * as commercialLease from '../../actions/commercialLease/commercialLease';
import * as holidayLetting from '../../actions/holidayLetting/holidayLetting';
import * as dashboard from '../../actions/dashboard';
import { steps } from '../../components/lease/steps';
import HeaderLease from '../../components/lease/HeaderLease';
import SideBarLease from '../../components/lease/SideBarLease';
import FooterLease from '../../components/lease/FooterLease';
import CreateNewLease from '../../components/lease/CreateNewLease';
import RequestsToChange from './RequestsToChange';
import * as agreementUtils from '../../utils/agreementUtils';
import '../../sass/createLease.scss';
import { isUserRoleAdmin } from '../../selectors/user';
import {
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeCommercialLease,
    isLeaseTypeHolidayLetting,
    isLeaseTypeSales,
    getUpdateObject,
    mappingsToFrontEnd,
    mapLeaseStatusToCategory,
    isTemplate
} from '../../utils/agreementUtils';
import { ArrowBackIosSharp, CloseSharp } from '@material-ui/icons';
import { getAgreementInfo } from '../../selectors/dashboard/agreementInfo';
import { getIsServerBusy } from '../../selectors/serverRequests';
import { getUser } from '../../selectors/user';
import {
    getStep,
    getLeaseId,
    getLeaseType,
    getLocation,
    getAddress,
    getAgent,
    getLeaseAllInfo,
    getValidationErrors,
    getIsOpenLeaseAgreementForm,
    getIsEditLeaseAgreementForm,
    getIsOpenRequestsToChangeForm,
    getRequests,
    getActiveLeaseRequest,
    getIsSubmitButtonDisabled,
    getIsLeaseEditingFinished,
    getIsSubmittingLease,
    getLease,
    getDirtyStep
} from '../../selectors/lease';
import { getMobileViewEnabled } from '../../selectors/dashboard';
import { confirmAlert } from 'react-confirm-alert';
import { CLOSE_MODAL_MESSAGE, DEFAULT_SUB_LEASE_TYPE, isMobileWidth, MOBILE_WIDTH_SMALL } from '../../config';
import { setNavigationState } from '../../actions/lease';
const LeaseAgreementForm = () => {
    const createLeaseRef = useRef(null);
    const childStepRef = useRef();
    const leaseContentRef = useRef(null);
    const mainContentRef = useRef(null);
    const dispatch = useDispatch();
    const agreementInfo = useSelector(getAgreementInfo);
    const step = useSelector(getStep);
    const leaseId = useSelector(getLeaseId);
    const leaseType = useSelector(getLeaseType);
    const location = useSelector(getLocation);
    const address = useSelector(getAddress);
    const reduxLease = useSelector(getLease);
    const agent = useSelector(getAgent);
    const leaseAllInfo = useSelector(getLeaseAllInfo);
    const validationErrors = useSelector(getValidationErrors);
    const isOpenLeaseAgreementForm = useSelector(getIsOpenLeaseAgreementForm);
    const isEditLeaseAgreementForm = useSelector(getIsEditLeaseAgreementForm);
    const isOpenRequestsToChangeForm = useSelector(getIsOpenRequestsToChangeForm);
    const requests = useSelector(getRequests);
    const activeRequest = useSelector(getActiveLeaseRequest);
    const isSubmitButtonDisabled = useSelector(getIsSubmitButtonDisabled);
    const isLeaseEditingFinished = useSelector(getIsLeaseEditingFinished);
    const isSubmittingLease = useSelector(getIsSubmittingLease);
    const mobileViewEnabled = useSelector(getMobileViewEnabled);

    const user = useSelector(getUser);
    const isUserAdmin = useSelector(isUserRoleAdmin);
    const isServerBusy = useSelector(getIsServerBusy);
    const dirtyStep = useSelector(getDirtyStep);

    let [detailViewOpened, setDetailViewOpened] = useState(null);

    useEffect(() => {
        if (leaseAllInfo && leaseAllInfo.id && !isTemplate(leaseAllInfo.leaseType)) {
            window.history.pushState({}, '', `/dashboard/agreement/${leaseAllInfo.id}`);
        }
    }, [leaseAllInfo]);

    useEffect(() => {
        if (
            mobileViewEnabled &&
            isMobileWidth() &&
            leaseAllInfo &&
            leaseAllInfo.status === dashboard.LEASE_STATUS_DRAFT
        ) {
            setDetailViewOpened(false);
        } else if (leaseAllInfo.id && leaseAllInfo.invalidSteps && leaseAllInfo.invalidSteps.length === 0) {
            // After finish editing is completed, we want to close the edit form and open the summary view
            if (isLeaseEditingFinished) {
                openAgreementInfoModal(leaseAllInfo);
                setDetailViewOpened(false);
                closeLeaseAgreementForm();
            }
        }
    }, [isLeaseEditingFinished]);

    useEffect(() => {
        if (
            leaseContentRef.current &&
            step > 1 &&
            step <= agreementUtils.getTotalStepsCount(leaseTypeVal, leaseAllInfo.subLeaseType, location)
        ) {
            scrollToTop();
        }
    }, [step]);

    function createDocument(data) {
        if (mobileViewEnabled && isMobileWidth()) {
            setDetailViewOpened(true);
        }
        if (isLeaseTypeResidentialTenancy(data.leaseType)) {
            return dispatch(lease.createLease(data));
        } else if (isLeaseTypeSales(data.leaseType)) {
            return dispatch(sales.createSalesLease(data));
        } else if (isLeaseTypePm(data.leaseType)) {
            return dispatch(pm.createPmLease(data));
        } else if (isLeaseTypeCommercialLease(data.leaseType)) {
            return dispatch(commercialLease.createCommercialLease(data));
        } else if (isLeaseTypeHolidayLetting(data.leaseType)) {
            return dispatch(holidayLetting.createHolidayLettingLease(data));
        }
    }

    /**
     * This function is currently handling a partial refactor in the way we save state to redux.
     * The first if is checking if the current step is dirty, currently we have not completed
     * all steps for dirty checking. If the step is dirty, finishEditing, just needs to save the dirty step.
     * The old way of doing it, was to scrape the state of the lease from Redux and submit the whole lease.
     */
    const finishEditing = () => {
        const data = getUpdateObject(reduxLease);
        if (childStepRef.current) {
            childStepRef.current.callbackAfterSubmit = () => {
                const data = getUpdateObject(reduxLease);
                updateAgreement(leaseId, data);
            };

            childStepRef.current.submitStep();
        } else {
            // this means this is document step
            updateAgreement(leaseId, data);
        }
    };
    const showEditTenants = () => {
        dispatch(dashboard.openEditTenantsModal(agreementInfo));
    };

    const nextStep = () => {
        dispatch(lease.clearValidationErrors());
        scrollToTop();
        // In here we just get the right step part for relevant to the lease (leaseType/subLeaseType/location/step)
        const part = agreementUtils.getStep(
            steps,
            leaseType,
            location,
            step - 2,
            leaseAllInfo.subLeaseType || DEFAULT_SUB_LEASE_TYPE
        ).part;
        /**
         * Submit a part of the lease agreement.
         *
         * Example:
         * this.props.setLandlord(leaseId, this.props.landlord);
         */
        let action = setFunctions[`set${part[0].toUpperCase() + part.slice(1)}`];

        /*
         * This is work in progress, we are refactoring each component, so the submitting of the form data is
         * contained within each component, the submit is called via childStepRef, so as part of the
         * refactor each set function will be removed from this file.
         * */
        if (action) {
            action(leaseId, reduxLease[part], { leaseType, location });
            scrollToTop();
        } else {
            childStepRef.current.callbackAfterSubmit = () => {
                dispatch(lease.setMoveToNextStepEditingMode()).then(() => {
                    dispatch(setNavigationState());
                });
            };

            childStepRef.current.submitStep();
        }
    };

    /**
     * Submit the form if the user has touched it, validation is
     * skipped.
     * @param step
     * @param currentStep
     */
    const saveStep = (step, currentStep) => {
        scrollToTop();
        dispatch(lease.setSideMenuStep(step)).then(() => {
            if (childStepRef.current) {
                childStepRef.current.bypassFormValidation = true;
                childStepRef.current.callbackAfterSubmit = () => {
                    dispatch(setNavigationState());
                };
                childStepRef.current.submitStep();
            } else {
                /**
                 * If the current step is documents we have a different way of passing the data through
                 */
                if (currentStep.part === 'documents') {
                    setFunctions.setDocuments(leaseId, reduxLease['documents'], null, true);
                } else {
                    goToStep(step);
                }
            }
        });
    };

    /**
     * Calculate progress.
     *
     * @return {number}
     */
    const calculateProgress = () => {
        return agreementUtils.calculateProgress(leaseAllInfo, leaseType, location);
    };

    /**
     * The last step can be submitted if the lease is fully complete or if all is done except the last step
     *
     * @returns {boolean}
     */
    const canFinishLease = () => {
        const lease = leaseAllInfo;
        const leaseType = leaseType ? leaseType : leaseAllInfo.leaseType;

        // Check if the lease is fully complete
        if (agreementUtils.isCompletedAndValidatedAllSteps(lease)) {
            return true;
        }

        // If the lease is not fully complete, check if everything is complete except the last step
        let allSteps = agreementUtils.getAllSteps(leaseType, leaseAllInfo.subLeaseType, location);
        const missedStep = agreementUtils.getMissedStep(lease, leaseType, location);
        // if there is only one step left and that step is the last step and the last step is not filled in
        // Then you can finish the lease (last step will send data with this api call)
        return (
            (calculateProgress() === allSteps.length - 1 || calculateProgress() === allSteps.length) &&
            missedStep === allSteps.length &&
            lease.invalidSteps.length === 1
        );
    };

    /**
     * Check that lease agreement is fully complete.
     *
     * @return {boolean}
     */
    const isLeaseComplete = () => {
        return agreementUtils.isCompleted(leaseAllInfo, leaseType, location);
    };

    const goToStep = (...args) => {
        scrollToTop();
        return dispatch(lease.goToStep(...args));
    };

    const scrollToTop = () => {
        // this part is for main div
        if (leaseContentRef.current) {
            leaseContentRef.current.scrollIntoView(true);
        }

        // this part is for inside div
        if (mobileViewEnabled && isMobileWidth(MOBILE_WIDTH_SMALL)) {
            // For mobile view scroll
            mainContentRef.current.scrollIntoView(true);
        } else {
            // For desktop view scroll
            createLeaseRef.current.scrollIntoView();
        }
    };

    /**
     *
     * @param userInitiated, this is true when the user closes the modal from button
     */
    const closeLeaseAgreementForm = (userInitiated = false) => {
        if (dirtyStep && userInitiated) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            // Special case for documents
                            if (
                                agreementUtils.getStep(
                                    steps,
                                    leaseTypeVal,
                                    location,
                                    step - 2,
                                    leaseAllInfo.subLeaseType || DEFAULT_SUB_LEASE_TYPE
                                ).part === 'documents'
                            ) {
                                setFunctions.setDocuments(leaseId, reduxLease['documents'], null, true);
                                dispatch(lease.closeLeaseAgreementForm());
                            } else {
                                childStepRef.current.bypassFormValidation = true;
                                childStepRef.current.callbackAfterSubmit = () => {
                                    dispatch(setNavigationState());
                                    dispatch(lease.closeLeaseAgreementForm());
                                };
                                childStepRef.current.submitStep();
                            }
                            window.history.pushState(
                                {},
                                '',
                                `/dashboard/agreements/${
                                    mappingsToFrontEnd[leaseAllInfo.leaseType]
                                }/${mapLeaseStatusToCategory(leaseAllInfo.status)}`
                            );
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            dispatch(lease.closeLeaseAgreementForm());
                            window.history.pushState(
                                {},
                                '',
                                `/dashboard/agreements/${
                                    mappingsToFrontEnd[leaseAllInfo.leaseType]
                                }/${mapLeaseStatusToCategory(leaseAllInfo.status)}`
                            );
                        }
                    },
                    {
                        className: 'close close-modal',
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            dispatch(lease.closeLeaseAgreementForm());
            if (leaseAllInfo?.leaseType) {
                window.history.pushState(
                    {},
                    '',
                    `/dashboard/agreements/${mappingsToFrontEnd[leaseAllInfo.leaseType]}/${mapLeaseStatusToCategory(
                        leaseAllInfo.status
                    )}`
                );
            } else {
                window.history.pushState({}, '', `/`);
            }
        }
    };

    const goBack = () => {
        dispatch(lease.goBack());
        scrollToTop();
    };

    const editAddress = address => {
        dispatch(lease.editAddress(address));
    };

    const editAgent = () => {
        dispatch(lease.editAgent());
    };

    const setActiveRequest = (leaseId, step) => {
        dispatch(lease.setActiveRequest(leaseId, step));
    };

    const changeAgreementAgent = () => {
        dispatch(dashboard.changeAgreementAgent());
    };

    const cloneAgreement = leaseId => {
        dispatch(dashboard.cloneAgreement(leaseId));
    };

    const openCloneResTenForm = (leaseId, leaseType) => {
        dispatch(lease.openCloneResTenForm(leaseId, leaseType));
    };

    const deleteAgreement = leaseId => {
        dispatch(dashboard.deleteAgreement(leaseId));
    };

    const openAgreementInfoModal = leaseAllInfo => {
        dispatch(dashboard.openAgreementInfoModal(leaseAllInfo));
    };

    const updateAgreement = (leaseId, data) => {
        dispatch(lease.updateAgreement(leaseId, data));
    };

    // SetFunctions called by nextStep
    const setFunctions = {
        setDocuments: (leaseId, data, additionalData, bypassFormValidation) => {
            return dispatch(lease.setDocuments(leaseId, data, additionalData, bypassFormValidation));
        }
    };

    const renderCurrentStepWithForwardRef = () => {
        return React.createElement(
            agreementUtils.getStep(
                steps,
                leaseTypeVal,
                location,
                step - 2,
                leaseAllInfo.subLeaseType || DEFAULT_SUB_LEASE_TYPE
            ).content,
            {
                leaseId,
                ref: childStepRef
            }
        );
    };
    const leaseTypeVal = leaseType ? leaseType : leaseAllInfo.leaseType;
    return (
        <Modal
            parentSelector={() => document.getElementById('main-layout')}
            ariaHideApp={false}
            isOpen={isOpenLeaseAgreementForm}
            style={customstyle}
            className="modal-create-lease"
            contentLabel="Modal"
        >
            <div className={`create-lease ${detailViewOpened ? 'detail-view-is-opened' : ''}`} ref={createLeaseRef}>
                {step === 1 && !isOpenRequestsToChangeForm && (
                    <CreateNewLease
                        leaseType={leaseTypeVal}
                        createDocument={createDocument}
                        closeLeaseAgreementForm={closeLeaseAgreementForm}
                    />
                )}
                {step > 1 &&
                    step <= agreementUtils.getTotalStepsCount(leaseTypeVal, leaseAllInfo.subLeaseType, location) && (
                        <div className="content-create">
                            <HeaderLease
                                closeModal={closeLeaseAgreementForm}
                                address={address}
                                agent={{
                                    value: leaseAllInfo.agent ? leaseAllInfo.agent.id : agent.id,
                                    label: leaseAllInfo.agent ? leaseAllInfo.agent.fullName : agent.fullName
                                }}
                                leaseId={leaseId}
                                changeAgreementAgent={data => changeAgreementAgent(leaseId, data)}
                                isEdit={isEditLeaseAgreementForm}
                                editAddress={editAddress}
                                editAgent={editAgent}
                                cloneAgreement={cloneAgreement}
                                openCloneResTenForm={openCloneResTenForm}
                                leaseAllInfo={leaseAllInfo}
                                deleteAgreement={deleteAgreement}
                                leaseType={leaseTypeVal}
                                isUserAdmin={isUserAdmin}
                                isCompletedLease={agreementUtils.isCompletedAndValidated(
                                    leaseAllInfo,
                                    leaseAllInfo.leaseType,
                                    leaseAllInfo.location
                                )}
                                showEditTenants={showEditTenants}
                                scrollToTop={scrollToTop}
                            />
                            <div className="content-lease" ref={leaseContentRef}>
                                <SideBarLease
                                    goToStep={goToStep}
                                    saveStep={saveStep}
                                    step={step}
                                    items={
                                        steps[agreementUtils.getLeaseTypeForFrontEnd(leaseTypeVal)][
                                            leaseAllInfo.subLeaseType || DEFAULT_SUB_LEASE_TYPE
                                        ][location]
                                    }
                                    validationErrors={validationErrors}
                                    lease={leaseAllInfo}
                                    isLeaseComplete={isLeaseComplete}
                                    user={user}
                                    requests={requests}
                                    activeRequest={activeRequest}
                                    setDetailViewOpened={value => setDetailViewOpened(value)}
                                    setActiveRequest={setActiveRequest}
                                    isOpenRequestsToChangeForm={isOpenRequestsToChangeForm}
                                />
                                <main className="main bottom-space-ios">
                                    <div className="main-content-inner" ref={mainContentRef}>
                                        <div className="main__header for-sm-modal">
                                            <h6>{agreementUtils.getLeaseTypeDisplayName(leaseTypeVal)}</h6>
                                            <h2>
                                                {
                                                    agreementUtils.getStep(
                                                        steps,
                                                        leaseTypeVal,
                                                        location,
                                                        step - 2,
                                                        leaseAllInfo.subLeaseType || DEFAULT_SUB_LEASE_TYPE
                                                    ).title
                                                }
                                            </h2>
                                            <button
                                                className="back"
                                                onClick={() => {
                                                    scrollToTop();
                                                    setDetailViewOpened(false);
                                                }}
                                            >
                                                <ArrowBackIosSharp className="for-sm-modal" />
                                            </button>
                                        </div>
                                        {agreementUtils.getStep(
                                            steps,
                                            leaseTypeVal,
                                            location,
                                            step - 2,
                                            leaseAllInfo.subLeaseType || DEFAULT_SUB_LEASE_TYPE
                                        ).forwardRef
                                            ? renderCurrentStepWithForwardRef()
                                            : agreementUtils.getStep(
                                                  steps,
                                                  leaseTypeVal,
                                                  location,
                                                  step - 2,
                                                  leaseAllInfo.subLeaseType || DEFAULT_SUB_LEASE_TYPE
                                              ).content}
                                    </div>
                                    {mobileViewEnabled && isMobileWidth(MOBILE_WIDTH_SMALL) && (
                                        <FooterLease
                                            step={step}
                                            goBack={goBack}
                                            isEdit={isEditLeaseAgreementForm}
                                            nextStep={nextStep}
                                            finishEditing={finishEditing}
                                            isSubmitButtonDisabled={isSubmitButtonDisabled}
                                            canFinishLease={canFinishLease}
                                            isSubmittingLease={isSubmittingLease}
                                            isServerBusy={isServerBusy}
                                            leaseType={leaseTypeVal}
                                            subLeaseType={leaseAllInfo.subLeaseType}
                                            location={location}
                                            setDetailViewOpened={setDetailViewOpened}
                                            mobileViewEnabled={mobileViewEnabled}
                                        />
                                    )}
                                </main>
                            </div>
                            {(!mobileViewEnabled || !isMobileWidth(MOBILE_WIDTH_SMALL)) && (
                                <FooterLease
                                    step={step}
                                    goBack={goBack}
                                    isEdit={isEditLeaseAgreementForm}
                                    nextStep={nextStep}
                                    finishEditing={finishEditing}
                                    isSubmitButtonDisabled={isSubmitButtonDisabled}
                                    canFinishLease={canFinishLease}
                                    isSubmittingLease={isSubmittingLease}
                                    isServerBusy={isServerBusy}
                                    leaseType={leaseTypeVal}
                                    subLeaseType={leaseAllInfo.subLeaseType}
                                    location={location}
                                    setDetailViewOpened={setDetailViewOpened}
                                    mobileViewEnabled={mobileViewEnabled}
                                />
                            )}
                        </div>
                    )}
            </div>
            {isOpenRequestsToChangeForm && (
                <RequestsToChange
                    steps={
                        steps[agreementUtils.getLeaseTypeForFrontEnd(leaseTypeVal)][leaseAllInfo.subLeaseType][location]
                    }
                />
            )}
        </Modal>
    );
};

export default memo(LeaseAgreementForm);

const customstyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(148, 157, 176, 0.7)',
        zIndex: 5,
        display: 'flex',
        overflow: 'auto',
        justifyContent: 'center',
        padding: '50px 0'
    }
};
