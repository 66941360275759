import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmAgreementTerm } from '../../../../selectors/lease/pmLease';

import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import '../../../../sass/management/agreementTerm.scss';

import { SERVICE_TYPE_LETTING, TERM_TYPE_SINGLE, TERM_TYPE_CONTINUING } from '../../../../config';

const PM_AGREEMENT_TERM_FORM = 'pmAgreementTermForm';
const initState = {
    startDate: '',
    endDate: '',
    propertyAvailabilityDate: '',
    noticePeriod: 30,
    serviceType: SERVICE_TYPE_LETTING,
    termType: TERM_TYPE_CONTINUING
};

const AgreementTerm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmAgreementTerm = useSelector(getPmAgreementTerm);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmAgreementTerm, setPmAgreementTerm] = useState(reduxPmAgreementTerm || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_AGREEMENT_TERM_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmAgreementTerm(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmAgreementTerm')) {
                    return error.response.data.errors.pmAgreementTerm;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmAgreementTerm = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-agreement-term`, data);
    };

    useEffect(() => {
        const data = cloneDeep(pmAgreementTerm);
        if (data.startDate) {
            data.startDate = new Date(data.startDate);
        }
        if (data.endDate) {
            data.endDate = new Date(data.endDate);
        }
        if (data.propertyAvailabilityDate) {
            data.propertyAvailabilityDate = new Date(data.propertyAvailabilityDate);
        }
        setPmAgreementTerm(data);
    }, []);

    return (
        <div className="agreementTerm">
            <Form onSubmit={submitForm} initialValues={pmAgreementTerm}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_AGREEMENT_TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormRadioGroup
                                label="The agent can perform the following service/s:"
                                name={'serviceType'}
                                radioGroupClass="wrapped-radio-group"
                                value={values.serviceType}
                                required
                                data={[
                                    {
                                        label: SERVICE_TYPE_LETTING,
                                        value: SERVICE_TYPE_LETTING
                                    }
                                ]}
                            />
                            <FormRadioGroup
                                label="The agent has the following term:"
                                name={'termType'}
                                radioGroupClass="wrapped-radio-group"
                                value={values.termType}
                                required
                                data={[
                                    {
                                        label: TERM_TYPE_SINGLE,
                                        value: TERM_TYPE_SINGLE
                                    },
                                    {
                                        label: TERM_TYPE_CONTINUING,
                                        value: TERM_TYPE_CONTINUING
                                    }
                                ]}
                            />
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Start"
                                    name="startDate"
                                    selected={values.startDate}
                                    required
                                />
                            </div>
                            {values.termType === TERM_TYPE_SINGLE && (
                                <div className="calendar separate-item">
                                    <DatePickerInAgencyTimeZone
                                        label="End"
                                        name="endDate"
                                        selected={values.endDate}
                                        required
                                    />
                                </div>
                            )}
                            <div className="calendar bottom-gap-for-calendar-open">
                                <DatePickerInAgencyTimeZone
                                    label="Property Availability Date"
                                    name="propertyAvailabilityDate"
                                    selected={values.propertyAvailabilityDate}
                                    required
                                />
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(AgreementTerm);
