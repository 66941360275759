import * as Lease from '../../reducers/lease';

export const UPDATE_SALES_EXPENSES_AND_CHARGES_FORM = 'UPDATE_SALES_EXPENSES_AND_CHARGES_FORM';

const initState = {
    chargeList: []
};

export default function salesExpensesAndChargesReducer(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case UPDATE_SALES_EXPENSES_AND_CHARGES_FORM: {
            newState = action.data;
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            newState = Object.assign({}, initState, action.payload.agreementInfo.expensesAndCharges);
            break;
        }

        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            if (action.result.data.lease.expensesAndCharges) {
                newState = Object.assign({}, initState, action.result.data.lease.expensesAndCharges);
            } else {
                newState = initState;
            }
            break;
        }
        case Lease.LEASE_SALES_EXPENSES_CHARGES_SUCCESS: {
            return { ...state, ...action.result.data.lease.expensesAndCharges };
        }
    }

    return newState;
}
