import React from 'react';
import PropTypes from 'prop-types';

class Signature extends React.Component {
    render() {
        let { signature } = this.props;
        return (
            <div>
                <div className="signature-head">
                    <span>{signature.type[0].toUpperCase() + signature.type.slice(1)} signature</span>
                    <span className="signature-head-subtitle">{signature.fullName}</span>
                </div>
                <div className="signature">
                    <img src={signature.base64SignatureImage} />
                </div>
            </div>
        );
    }
}

Signature.propTypes = {
    signature: PropTypes.shape({
        type: PropTypes.string,
        fullName: PropTypes.string,
        base64SignatureImage: PropTypes.string,
        signature: PropTypes.string
    })
};

export default Signature;
