export function extractAddressComponents(result, geoResult) {
    result.address_components.forEach(addressComponent =>
        addressComponent.types.forEach(type => {
            if (type === 'administrative_area_level_2' || type === 'administrative_area_level_1') {
                geoResult = { ...geoResult, [type]: addressComponent.short_name };
            } else {
                geoResult = { ...geoResult, [type]: addressComponent.long_name };
            }
        })
    );

    // extract latitude/longitude data
    if (result.geometry && result.geometry.location) {
        if (result.geometry.location.lat && result.geometry.location.lng) {
            geoResult.latitude = result.geometry.location.lat();
            geoResult.longitude = result.geometry.location.lng();
        }
    }
    return geoResult;
}
