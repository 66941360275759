import * as Lease from '../../../reducers/lease';
import * as LeaseWithRequest from '../../../reducers/leaseWithRequest';
import {
    INITIALIZE_NSW_LEASE_AGREEMENT_FORM,
    INITIALIZE_QLD_LEASE_AGREEMENT_FORM,
    LEASE_ADDITIONAL_SUCCESS
} from '../../lease';
import { initState, nswInitState, qldInitState, vicInitState, actInitState, commercialLeaseInitState } from './state';
import { booleanToString } from '../../../utils/agreementUtils';
import produce from 'immer';
import { QLD_STATE, VIC_STATE, ACT_STATE } from '../../../config';
export const UPDATE_ADDITIONAL_FORM = 'dashboard.lease.additional.updateForm';

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case UPDATE_ADDITIONAL_FORM: {
            draftState = action.data;
            draftState.animals = {
                pets: action.data.animals ? [...action.data.animals.pets] : [],
                profCleaning: false
            };

            break;
        }
        case Lease.CREATE_LEASE_SUCCESS: {
            const location = action.result.data.lease.location;
            if (location === QLD_STATE) {
                draftState = { ...qldInitState };
            } else if (location === VIC_STATE) {
                draftState = { ...vicInitState };
            } else if (location === ACT_STATE) {
                draftState = { ...actInitState };
            } else {
                draftState = { ...nswInitState };
            }
            draftState.leaseRenewalDate = null;
            break;
        }
        case Lease.CREATE_COMMERCIAL_LEASE_SUCCESS: {
            draftState = commercialLeaseInitState;
            break;
        }
        case INITIALIZE_NSW_LEASE_AGREEMENT_FORM: {
            draftState = { ...nswInitState };
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            if (action.payload.agreementInfo.additionalTerms) {
                draftState = { ...draftState, ...action.payload.agreementInfo.additionalTerms };
                const additionalTerms = booleanToString(action.payload.agreementInfo.additionalTerms, [
                    'isHardwired',
                    'isBatteryOperated'
                ]);
                draftState = { ...draftState, ...additionalTerms };
                action.payload.agreementInfo.additionalTerms &&
                    action.payload.agreementInfo.additionalTerms.premiseServices &&
                    action.payload.agreementInfo.additionalTerms.premiseServices.premiseServices.map(
                        (service, index) => {
                            if (service.mustPay) {
                                draftState.premiseServices.premiseServices[index].mustPay = 'yes';
                                draftState.premiseServices.premiseServices[
                                    index
                                ].individuallyMetred = service.individuallyMetred ? 'yes' : 'no';
                                draftState.premiseServices.premiseServices[
                                    index
                                ].directToSupplier = service.directToSupplier ? 'yes' : 'no';
                            } else {
                                draftState.premiseServices.premiseServices[index].mustPay = 'no';
                            }
                        }
                    );
            } else {
                const location = action.payload.agreementInfo.location;
                if (location === QLD_STATE) {
                    draftState = { ...qldInitState };
                } else if (location === VIC_STATE) {
                    draftState = { ...vicInitState };
                } else if (location === ACT_STATE) {
                    draftState = { ...actInitState };
                } else {
                    draftState = { ...nswInitState };
                }
                draftState.leaseRenewalDate = null;
            }
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = { ...draftState, ...action.lease.additionalTerms };
            const additionalTerms = booleanToString(action.lease.additionalTerms, ['isHardwired', 'isBatteryOperated']);
            draftState = { ...draftState, ...additionalTerms };
            action.lease.additionalTerms &&
                action.lease.additionalTerms.premiseServices &&
                action.lease.additionalTerms.premiseServices.premiseServices.map((service, index) => {
                    if (service.mustPay) {
                        draftState.premiseServices.premiseServices[index].mustPay = 'yes';
                        draftState.premiseServices.premiseServices[
                            index
                        ].individuallyMetred = service.individuallyMetred ? 'yes' : 'no';
                        draftState.premiseServices.premiseServices[index].directToSupplier = service.directToSupplier
                            ? 'yes'
                            : 'no';
                    } else {
                        draftState.premiseServices.premiseServices[index].mustPay = 'no';
                    }
                });
            break;
        }

        case LEASE_ADDITIONAL_SUCCESS: {
            draftState = {
                ...booleanToString(action.result.data.lease.additionalTerms, ['isHardwired', 'isBatteryOperated']),
                isIndemnityProvisionIncluded: false
            };
            break;
        }
    }

    return draftState;
});
