import React from 'react';
import { FormTextRegular } from '../../../../../components/form/FormText.js';
import { FormRadioGroup } from '../../../../../components/form/FormRadioGroup.js';

type EvidenceSectionProps = {
    isReadOnly?: boolean;
};

const EvidenceSection: React.FC<EvidenceSectionProps> = ({ isReadOnly }) => {
    return (
        <>
            <FormTextRegular
                label="Name of Person authorised to receive this notice on behalf of the Principal"
                name="nameOfAuthorisedPerson"
                disabled={isReadOnly}
            />
            <h4>Evidence</h4>
            <FormRadioGroup
                name="evidence"
                radioGroupClass="wrapped-radio-group"
                data={[
                    {
                        label: 'The Agent attaches evidence in support of the revised ESP to this notice.',
                        value: 'agent_attached',
                        disabled: isReadOnly
                    },
                    {
                        label: 'The Agent has provided the Principal with evidence in support of the revised ESP before the date of this notice.',
                        value: 'agent_provided',
                        disabled: isReadOnly
                    }
                ]}
            />
        </>
    );
};

export default EvidenceSection;
