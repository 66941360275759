import React from 'react';
import { qldNoticeReasons } from '../../../../../containers/dashboard/documents/termination/qldNoticeReasons';

export default function ReasonForTermination({ doc }) {
    const terminationWithoutLease = doc.terminationWithoutLease;
    let reason;
    if (terminationWithoutLease?.reasonForTerminationText?.reason) {
        reason = terminationWithoutLease.reasonForTerminationText.reason;
    } else if (terminationWithoutLease?.qldNoticeReason) {
        const reasons = terminationWithoutLease?.qldNoticeReason;
        const selectedReasons = Object.keys(reasons)
            .map(key => ({ key, value: reasons[key] }))
            .filter(i => i.value === true)
            .map(i => i.key);
        if (selectedReasons.length > 0) {
            reason = qldNoticeReasons
                .filter(i => selectedReasons.includes(i.name))
                .map(i => i.description)
                .join(', ');
        }
    }

    return <b>{reason}</b>;
}
