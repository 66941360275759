import React, { memo } from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { useSelector } from 'react-redux';

import { FLK_A_PDF_GLOBAL_TEMPLATE, FLK_A_PDF_TEMPLATE, FLK_TEMPLATE_LABEL } from '../../../config';
import { getGeneralAppLabel } from '../../../utils/labelUtils.js';
import { getUserInfo } from '../../../selectors/user.js';
import { UAD_GLOBAL_TEMPLATES } from '../../../constants/featureFlags';
import { User } from '../../../types/User';

import FlkAPdfTemplate from './FlkAPdfTemplate.js';

import '../../../sass/react-tabs.scss';

type UploadADocTemplateProps = {
    onTabChange: (tab: string) => void;
};

const UploadADocTemplate = ({ onTabChange }: UploadADocTemplateProps) => {
    const loggedInUser: User = useSelector(getUserInfo);
    const isUadGlobalTemplatesActive = useFeatureFlag(UAD_GLOBAL_TEMPLATES);

    return (
        <>
            {isUadGlobalTemplatesActive ? (
                <div className="settings template-tabs">
                    <Tabs
                        onSelect={(index: number) => {
                            if (onTabChange) {
                                onTabChange(index === 0 ? FLK_A_PDF_TEMPLATE : FLK_A_PDF_GLOBAL_TEMPLATE);
                            }
                        }}
                    >
                        <TabList>
                            <Tab>
                                <span data-test="Agency Template Tab">
                                    {getGeneralAppLabel('agency_templates', loggedInUser.accountType)}
                                </span>
                            </Tab>
                            <Tab>
                                <span data-test="FLK Template Tab">{FLK_TEMPLATE_LABEL}</span>
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <div className="wrapper">
                                <FlkAPdfTemplate templateType={FLK_A_PDF_TEMPLATE} />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="wrapper">
                                <FlkAPdfTemplate templateType={FLK_A_PDF_GLOBAL_TEMPLATE} />
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            ) : (
                <FlkAPdfTemplate templateType={FLK_A_PDF_TEMPLATE} />
            )}
        </>
    );
};

export default memo(UploadADocTemplate);
