import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getAgency } from '../../../../selectors/user';
import Switch from '../../../../common/components/Switch';
import { PreLoader } from '../../../../common/components/PreLoader';
import { addOrReplaceTemplateInList } from '../../../../actions/template';
import { CREATE_A_FLK_GLOBAL_TEMPLATE, FLK_A_PDF_GLOBAL_TEMPLATE } from '../../../../config';
import { changeDisabledGlobalTemplates } from '../../../../actions/user';

function TemplateVisibility({ item, dataTest, disabled, toolTip = 'Enable/Disable template' }) {
    const dispatch = useDispatch();
    const agency = useSelector(getAgency);
    const [loading, setLoading] = useState(false);
    const [error, seterror] = useState(false);

    function changeTemplateVisibility(template) {
        seterror(false);
        setLoading(true);
        axios
            .put(`/api/template/${template.id}/change-visibility`)
            .then(response => {
                if ([CREATE_A_FLK_GLOBAL_TEMPLATE, FLK_A_PDF_GLOBAL_TEMPLATE].includes(template.docType)) {
                    dispatch(changeDisabledGlobalTemplates(response.data.agency.disabledGlobalTemplates));
                }
                dispatch(addOrReplaceTemplateInList(response.data.template));
                setLoading(false);
            })
            .catch(() => {
                seterror(true);
                setLoading(false);
            });
    }
    return (
        <React.Fragment>
            {!loading && (
                <Switch
                    onClick={() => {
                        if (!disabled) {
                            changeTemplateVisibility(item);
                        }
                    }}
                    toolTip={toolTip}
                    value={
                        [CREATE_A_FLK_GLOBAL_TEMPLATE, FLK_A_PDF_GLOBAL_TEMPLATE].includes(item.docType)
                            ? !agency.disabledGlobalTemplates.includes(item.id)
                            : item.enabled
                    }
                    disabled={disabled || loading}
                    isError={error}
                    dataTest={dataTest}
                />
            )}

            {loading && <PreLoader />}
        </React.Fragment>
    );
}
export default TemplateVisibility;
