import { isObject, isEqual, transform } from 'lodash';
import {
    DOCUMENT_READ_ONLY_MODE,
    DOCUMENT_TEMPLATE_MODE,
    DOCUMENT_CREATE_MODE,
    DOCUMENT_TEMPLATE_READ_ONLY_MODE
} from '../config';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_COMPLETE,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING,
    DOC_STATUS_ACTIVE,
    DOC_STATUS_CANCELLED
} from '../actions/dashboard';
import ls from '@livesession/sdk';
import { v4 as uuidv4 } from 'uuid';

export const capitalize = s => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const camelcaseToSpacedWords = s => {
    return s
        .replace(/([A-Z][a-z]+)/g, ' $1')
        .replace(/([A-Z]{2,})/g, ' $1')
        .replace(/\s{2,}/g, ' ')
        .trim();
};

/**
 * Expects a number like this: +61411595337
 * And outputs it to this +61 411 595 337
 * @param s
 * @returns {*}
 */
export const phoneToSpacedPhone = s => {
    return s.substring(0, 3) + ' ' + s.substring(3, 6) + ' ' + s.substring(6, 9) + ' ' + s.substring(9, 12);
};

export const getSelectedText = () => {
    let txt = '';
    if (window.getSelection) {
        txt = window.getSelection();
    } else if (document.getSelection) {
        txt = document.getSelection();
    } else if (document.selection) {
        txt = document.selection.createRange().text;
    }
    return txt.toString();
};

export const difference = (object, base) => {
    function changes(object, base) {
        return transform(object, function(result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
};
export const isDocumentCreateMode = mode => {
    return mode === DOCUMENT_CREATE_MODE;
};

export const isDocumentEditMode = mode => {
    return mode !== DOCUMENT_READ_ONLY_MODE;
};

export const isDocumentReadOnlyMode = mode => {
    return mode === DOCUMENT_READ_ONLY_MODE || mode === DOCUMENT_TEMPLATE_READ_ONLY_MODE;
};

export const isDocumentTemplateMode = mode => {
    return mode === DOCUMENT_TEMPLATE_MODE || mode === DOCUMENT_TEMPLATE_READ_ONLY_MODE;
};

export const isDocumentTemplateReadOnlyMode = mode => {
    return mode === DOCUMENT_TEMPLATE_READ_ONLY_MODE;
};

export const isDocumentStatusDraft = status => {
    return status === LEASE_STATUS_DRAFT;
};

export const isDocumentStatusActive = status => {
    return status === DOC_STATUS_ACTIVE;
};

export const isDocumentStatusCancelled = status => {
    return status === DOC_STATUS_CANCELLED;
};

export const isDocumentStatusSentForSigning = status => {
    return status === LEASE_STATUS_SENT_SIGNING;
};

export const isDocumentAwaitingCompletion = status => {
    return status === LEASE_STATUS_AWAITING_COMPLETION;
};

export const isDocumentComplete = status => {
    return status === LEASE_STATUS_COMPLETE;
};

export function initLiveSession(user) {
    if (LIVE_SESSION_ID) {
        ls.init(LIVE_SESSION_ID, { keystrokes: true });
        ls.newPageView();
        if (user && user.user_id) {
            if (typeof ls.identify === 'function') {
                ls.identify({
                    name: user.name,
                    email: user.email,
                    params: user
                });
            } else {
                console.log('identify is not a function');
            }
        }
    }
}

export const generateRandomId = () => {
    return crypto?.randomUUID?.() ?? uuidv4();
};
