import * as React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getPmStepNo, getPmStepTitle } from '../../../../../utils/agreementUtils';
import { NSW_STATE, QLD_STATE } from '../../../../../config';

const AgreementPmPropertyDetail = ({ leaseAllInfo }) => {
    return (
        <div>
            {leaseAllInfo && (
                <AgreementSection
                    title={getPmStepTitle(leaseAllInfo.location, 'pmDetailsRepairs')}
                    step={getPmStepNo(leaseAllInfo.location, 'pmDetailsRepairs')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <div>
                            <p className="name">
                                Description Of Property: <strong>{leaseAllInfo.pmDetailsRepairs?.description}</strong>
                            </p>
                        </div>
                        {leaseAllInfo.location === QLD_STATE && (
                            <div>
                                {leaseAllInfo.pmDetailsRepairs && (
                                    <p className="name">
                                        Number Of Approved Occupants:{' '}
                                        <strong>
                                            {leaseAllInfo.pmDetailsRepairs.numberOfApprovedOccupants || 'Nil'}
                                        </strong>
                                    </p>
                                )}
                                {leaseAllInfo.pmDetailsRepairs && (
                                    <p className="name">
                                        Inspections Per Year:{' '}
                                        <strong>{leaseAllInfo.pmDetailsRepairs.inspectionsPerYear || 'Nil'}</strong>
                                    </p>
                                )}
                                {leaseAllInfo.pmDetailsRepairs && leaseAllInfo.pmDetailsRepairs.titleReference && (
                                    <p className="name">
                                        Title Reference: <strong>{leaseAllInfo.pmDetailsRepairs.titleReference}</strong>
                                    </p>
                                )}
                            </div>
                        )}
                        {leaseAllInfo.location === NSW_STATE && (
                            <div>
                                {leaseAllInfo.pmDetailsRepairs && (
                                    <p className="name">
                                        Condition Of Exterior:{' '}
                                        <strong>{leaseAllInfo.pmDetailsRepairs.conditionOfExterior}</strong>
                                    </p>
                                )}
                                {leaseAllInfo.pmDetailsRepairs && (
                                    <p className="name">
                                        Condition Of Interior:{' '}
                                        <strong>{leaseAllInfo.pmDetailsRepairs.conditionOfInterior}</strong>
                                    </p>
                                )}
                            </div>
                        )}
                        <div>
                            {leaseAllInfo.pmDetailsRepairs && leaseAllInfo.pmDetailsRepairs.fittingsAndFixtures && (
                                <p className="name">
                                    {leaseAllInfo.location === QLD_STATE
                                        ? 'Fittings Included (for use by Tenant/s): '
                                        : 'Fittings And Fixtures: '}
                                    <strong>{leaseAllInfo.pmDetailsRepairs.fittingsAndFixtures}</strong>
                                </p>
                            )}
                        </div>
                        {leaseAllInfo.location === NSW_STATE && (
                            <div>
                                {leaseAllInfo.pmDetailsRepairs && (
                                    <p className="name">
                                        Smoke Alarms (residential):{' '}
                                        <strong>{leaseAllInfo.pmDetailsRepairs.smokeAlarmsCompliantType}</strong>
                                    </p>
                                )}
                                {leaseAllInfo.pmDetailsRepairs && (
                                    <p className="name">
                                        Smoke alarms will be maintained By:{' '}
                                        <strong>{leaseAllInfo.pmDetailsRepairs.smokeAlarmsMaintainedBy}</strong>
                                        {leaseAllInfo.pmDetailsRepairs.smokeAlarmsMaintainedBy === 'Company' &&
                                            ` (${leaseAllInfo.pmDetailsRepairs.smokeAlarmCompanyName})`}
                                    </p>
                                )}
                            </div>
                        )}
                        <div>
                            {leaseAllInfo.pmDetailsRepairs && leaseAllInfo.pmDetailsRepairs.fittings && (
                                <p className="name">
                                    {leaseAllInfo.location === QLD_STATE
                                        ? 'Fittings Excluded (not for use by Tenant/s): '
                                        : 'Fittings (not being fixed to the property) which are provided with the property: '}
                                    <strong>{leaseAllInfo.pmDetailsRepairs.fittings}</strong>
                                </p>
                            )}
                        </div>
                        <div>
                            {leaseAllInfo.location === NSW_STATE &&
                                leaseAllInfo.pmDetailsRepairs &&
                                leaseAllInfo.pmDetailsRepairs.improvements && (
                                    <p className="name">
                                        Improvements: <strong>{leaseAllInfo.pmDetailsRepairs.improvements}</strong>
                                    </p>
                                )}
                        </div>
                        <div>
                            {leaseAllInfo.pmDetailsRepairs && leaseAllInfo.pmDetailsRepairs.detailsOfWorkToBeCompleted && (
                                <p className="name">
                                    Details Of Work To Be Completed By The Landlord On The Property:{' '}
                                    <strong>{leaseAllInfo.pmDetailsRepairs.detailsOfWorkToBeCompleted}</strong>
                                </p>
                            )}
                        </div>
                        {leaseAllInfo.location === QLD_STATE &&
                            leaseAllInfo.pmDetailsRepairs &&
                            leaseAllInfo.pmDetailsRepairs.isPropertyFurnished && (
                                <div>
                                    <p>
                                        The property is furnished and{' '}
                                        <b>{leaseAllInfo.pmDetailsRepairs.whoPrepareInventoryList}</b> will prepare the
                                        inventory list for the property
                                    </p>
                                    {leaseAllInfo.pmDetailsRepairs && (
                                        <p className="name">
                                            Approval For Pets:{' '}
                                            <strong>{leaseAllInfo.pmDetailsRepairs.approvalForPets}</strong>
                                        </p>
                                    )}
                                    {leaseAllInfo.pmDetailsRepairs &&
                                        leaseAllInfo.pmDetailsRepairs.approvalForPets === 'Yes' && (
                                            <p className="name">
                                                Conditions: <strong>{leaseAllInfo.pmDetailsRepairs.conditions}</strong>
                                            </p>
                                        )}
                                </div>
                            )}
                        <div>
                            {leaseAllInfo.pmDetailsRepairs && leaseAllInfo.pmDetailsRepairs.nominateStrataDetails && (
                                <div>
                                    <p className="name">
                                        <strong>Nominate Strata Details</strong>
                                    </p>
                                    <p className="name">
                                        {leaseAllInfo.location === NSW_STATE ? 'Strata' : ''} Plan No:{' '}
                                        <strong>{leaseAllInfo.pmDetailsRepairs.strataPlanNo || 'Nil'}</strong>
                                    </p>
                                    <p className="name">
                                        Lot No: <strong>{leaseAllInfo.pmDetailsRepairs.lotNo || 'Nil'}</strong>
                                    </p>
                                    <p className="name">
                                        Strata Managing Agent:{' '}
                                        <strong> {leaseAllInfo.pmDetailsRepairs.strataManagingAgent || 'Nil'} </strong>
                                    </p>
                                    <p className="name">
                                        By Laws Provided:{' '}
                                        <strong> {leaseAllInfo.pmDetailsRepairs.byLawsprovided ? 'Yes' : 'No'} </strong>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </AgreementSection>
            )}
        </div>
    );
};

export default AgreementPmPropertyDetail;
