import React, { Component } from 'react';
import { isEmpty, map } from 'lodash';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getPmStepNo } from '../../../../../utils/agreementUtils';

class AgreementPmRebates extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { leaseAllInfo } = this.props;
        const { disclosureOfRebates } = leaseAllInfo;

        if (!disclosureOfRebates) {
            return null;
        }

        return (
            <AgreementSection
                title="Disclosure of Rebates"
                step={getPmStepNo(leaseAllInfo.location, 'disclosureOfRebates')}
                leaseAllInfo={leaseAllInfo}
            >
                <div className="agreement-section-text">
                    {isEmpty(disclosureOfRebates.rebateList) ? (
                        <p>No rebates exist for this agreement.</p>
                    ) : (
                        <div>
                            <ul>
                                {map(disclosureOfRebates.rebateList, (value, index) => {
                                    return (
                                        <div className="agreement-section-text__item" key={index}>
                                            Source: <strong>{value.source}</strong> Description:{' '}
                                            <strong>{value.description}</strong> Amount: <strong>{value.amount}</strong>
                                        </div>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            </AgreementSection>
        );
    }
}

export default AgreementPmRebates;
