import {
    GET_STATISTICS_REQUEST,
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_FAIL,
    GET_ALL_USERS_REQUEST,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAIL,
    GET_ALL_AGENCIES_REQUEST,
    GET_ALL_AGENCIES_SUCCESS,
    GET_ALL_AGENCIES_FAIL,
    CHECK_USER_CAN_VIEW_STATISTICS_REQUEST,
    CHECK_USER_CAN_VIEW_STATISTICS_SUCCESS,
    CHECK_USER_CAN_VIEW_STATISTICS_FAIL,
    GET_AGENT_REPORTS_REQUEST,
    GET_AGENT_REPORTS_SUCCESS,
    GET_AGENT_REPORTS_FAIL,
    GET_AGENT_REPORT_REQUEST,
    GET_AGENT_REPORT_SUCCESS,
    GET_AGENT_REPORT_FAIL,
    GET_ALL_UPLOAD_A_DOCUMENTS_REQUEST,
    GET_ALL_UPLOAD_A_DOCUMENTS_SUCCESS,
    GET_ALL_UPLOAD_A_DOCUMENTS_FAIL,
    GET_ALL_BUILD_A_DOCUMENTS_REQUEST,
    GET_ALL_BUILD_A_DOCUMENTS_SUCCESS,
    GET_ALL_BUILD_A_DOCUMENTS_FAIL
} from './types';

import axios from 'axios';

const statisticsUrl = '/api/reports/all';
export const agentReportsUrl = '/api/reports/agents';

export const canUserViewStatistics = () => {
    return {
        types: [
            CHECK_USER_CAN_VIEW_STATISTICS_REQUEST,
            CHECK_USER_CAN_VIEW_STATISTICS_SUCCESS,
            CHECK_USER_CAN_VIEW_STATISTICS_FAIL
        ],
        promise: axios.head(statisticsUrl)
    };
};

export const getStatistics = () => {
    return {
        types: [GET_STATISTICS_REQUEST, GET_STATISTICS_SUCCESS, GET_STATISTICS_FAIL],
        promise: axios.get(statisticsUrl)
    };
};

export const getAgentReports = data => {
    return {
        types: [GET_AGENT_REPORTS_REQUEST, GET_AGENT_REPORTS_SUCCESS, GET_AGENT_REPORTS_FAIL],
        promise: axios.post(agentReportsUrl, data)
    };
};

export const getAllUploadADocuments = data => {
    return {
        types: [
            GET_ALL_UPLOAD_A_DOCUMENTS_REQUEST,
            GET_ALL_UPLOAD_A_DOCUMENTS_SUCCESS,
            GET_ALL_UPLOAD_A_DOCUMENTS_FAIL
        ],
        promise: axios.post('/api/reports/all-documents/document_custom', data)
    };
};

export const getAllBuildADocuments = data => {
    return {
        types: [GET_ALL_BUILD_A_DOCUMENTS_REQUEST, GET_ALL_BUILD_A_DOCUMENTS_SUCCESS, GET_ALL_BUILD_A_DOCUMENTS_FAIL],
        promise: axios.post('/api/reports/all-documents/create_a_flk', data)
    };
};

export const getAgentReport = (id, data) => {
    return {
        types: [GET_AGENT_REPORT_REQUEST, GET_AGENT_REPORT_SUCCESS, GET_AGENT_REPORT_FAIL],
        promise: axios.post(`/api/reports/agent/${id}`, data)
    };
};

export const getAllUsers = (page, limit, field, direction) => {
    return {
        types: [GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_FAIL],
        promise: axios.get('/api/user', {
            params: {
                page,
                limit,
                field,
                direction
            }
        })
    };
};

export const getAllAgencies = (page, limit, field, direction) => {
    return {
        types: [GET_ALL_AGENCIES_REQUEST, GET_ALL_AGENCIES_SUCCESS, GET_ALL_AGENCIES_FAIL],
        promise: axios.get('/api/reports/agencies', {
            params: {
                page,
                limit,
                field,
                direction
            }
        })
    };
};
