import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection.js';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils.js';
import DataString from '../bloks/DataString.js';
import { LeaseAllInfo } from './types';

type Props = {
    leaseAllInfo: LeaseAllInfo;
};

export default function AgreementClLessorAgent({ leaseAllInfo }: Props) {
    const part = 'licensee';
    if (!leaseAllInfo?.licensee) {
        return <></>;
    }
    const { location, licensee } = leaseAllInfo;
    return (
        <div>
            <AgreementSection
                title={getClStepTitle(location, part)}
                step={getClStepNo(location, part)}
                leaseAllInfo={leaseAllInfo}
            >
                {licensee && (
                    <div className="data data-row">
                        <p className="name">{licensee.agencyName}</p>
                        {licensee.companyName && <p className="name">{licensee.companyName}</p>}
                        <p className="name">{licensee.address}</p>
                        {[
                            {
                                contactType: 'Ph',
                                contactValue: licensee.phone
                            },
                            {
                                contactType: 'E',
                                contactValue: licensee.email
                            },
                            {
                                contactType: 'ABN',
                                contactValue: licensee.abn
                            },
                            {
                                contactType: 'ACN',
                                contactValue: licensee.acn
                            },
                            {
                                contactType: 'Licence No',
                                contactValue: licensee.licenceNo
                            }
                        ]
                            .filter(i => i.contactValue)
                            .map((extraContact, index) => (
                                <DataString
                                    title={extraContact.contactType}
                                    text={extraContact.contactValue}
                                    key={index}
                                />
                            ))}
                        {licensee.isRegisteredForGst && <p>Registered for GST</p>}
                    </div>
                )}
            </AgreementSection>
        </div>
    );
}
