import React from 'react';
import * as PropTypes from 'prop-types';
import styles from './DocType.module.scss';
import cx from 'classnames';

export default function DocType({ docType }) {
    if (docType === 'document_custom') {
        return <span className={cx(styles.uploadedDoc, styles.tag)}>{'Uploaded'}</span>;
    } else {
        return <span className={cx(styles.tag, styles.builtDoc)}>{'Built'}</span>;
    }
}

DocType.propTypes = { docType: PropTypes.string };
