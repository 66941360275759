import cx from 'classnames';
import Downshift from 'downshift';
import { constant, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import '../../../../../../sass/searchBar.scss';
import '../../../../../../sass/searchBarResults.scss';

const PLACEHOLDER = 'Type to search...';
const MARKETING_MESSAGE = 'Once you add contacts, you will be able to search them here.';
const EMPTY = constant('');

function ContactSearch({ onSelect, disabled, searchBarClassName }) {
    const searchInputRef = React.useRef(null);

    function focusInput() {
        if (searchInputRef) {
            searchInputRef.current.focus();
        }
    }

    return (
        <Downshift
            onSelect={item => {
                if (item) {
                    onSelect(item);
                }
            }}
            itemToString={EMPTY}
            defaultHighlightedIndex={0}
        >
            {({ getInputProps, inputValue, reset }) => (
                <div
                    className={cx('search-bar', 'search-bar--contact-search', 'desktop-only', searchBarClassName)}
                >
                    <div data-tip={true} data-for={'search-bar'} className="search-bar__input-container">
                        <input
                            className="search-bar__input-container__input"
                            {...getInputProps({
                                type: 'search',
                                placeholder: PLACEHOLDER
                            })}
                            disabled={disabled}
                            ref={searchInputRef}
                        />
                        {inputValue ? (
                            <>
                                <button
                                    title="Clear search"
                                    onClick={() => {
                                        reset();
                                        focusInput();
                                    }}
                                />
                                <ul className="search-bar-results">
                                    <li className="no-results">
                                        <span>{MARKETING_MESSAGE}</span>
                                    </li>
                                </ul>
                            </>
                        ) : null}
                    </div>
                </div>
            )}
        </Downshift>
    );
}

ContactSearch.propTypes = {
    onSelect: PropTypes.func,
    allowStaffSearch: PropTypes.bool
};
ContactSearch.defaultProps = {
    onSelect: noop,
    allowStaffSearch: false
};

export default ContactSearch;
