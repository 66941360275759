import React from 'react';
import { FormTextRegular } from './FormText.js';
import useSuggestions from '../../hooks/useSuggestions';
import { isEmpty } from 'lodash';

type SuggestionTextFieldProps = React.PropsWithChildren<{
    suggestionsFieldName: string;
    formatSuggestions: (text: string) => string;
    allowSelectMultipleSuggestions: boolean;
}>;

const SuggestionTextField: React.FC<SuggestionTextFieldProps> = ({
    suggestionsFieldName,
    allowSelectMultipleSuggestions = false,
    formatSuggestions,
    ...rest
}) => {
    const { isSuggestionsActive, suggestionsList, suggestionIcon, removeSuggestionFromRedux } =
        useSuggestions(suggestionsFieldName);

    return isSuggestionsActive && !isEmpty(suggestionsList) ? (
        <FormTextRegular
            suggestionsList={formatSuggestions ? suggestionsList?.map(formatSuggestions) : suggestionsList}
            removeSuggestion={removeSuggestionFromRedux}
            suggestionIcon={suggestionIcon}
            allowSelectMultipleSuggestions={allowSelectMultipleSuggestions}
            {...rest}
        />
    ) : (
        <FormTextRegular {...rest} />
    );
};

export default SuggestionTextField;
