import { Core } from '@pdftron/webviewer';
import {
    ANNOTATION_SUBJECT_AGENT_NAME,
    ANNOTATION_SUBJECT_COMPLETION_DATE,
    ANNOTATION_SUBJECT_CUSTOM_PLACEHOLDER,
    ANNOTATION_SUBJECT_SENDER_NAME
} from '../../constants/constants';
import { CustomPlaceholderRespondentType } from '../../types/UploadADoc';

export type ClientSignatureSubjectType = `client-${string}-${number}`;
export type ClientNameSubjectType = `name-${string}-${number}`;
export type ClientDateSubjectType = `date-${string}-${number}`;
export type AgentSignatureSubjectType = 'agent';
export type AgentNameSubjectType = 'Agent name';
export type SenderNameSubjectType = 'Sender name';
export type CompletionDateSubjectType = 'Completion date';
export type FreeTextSubjectType = 'Free Text';
export type WitnessSignatureSubjectType = `witnessSign-${string}-${number}`;
export type WitnessNameSubjectType = `witnessName-${string}-${number}`;
export type CustomPlaceholderSubjectType = 'Custom Placeholder';

export type ClientSignatureAnnotation = Core.Annotations.StampAnnotation & {
    Subject: ClientSignatureSubjectType;
};

export type ClientDateAnnotation = Core.Annotations.Annotation & {
    Subject: ClientDateSubjectType;
};

export type ClientNameAnnotation = Core.Annotations.Annotation & {
    Subject: ClientDateSubjectType;
};

export type AgentSignatureAnnotation = Core.Annotations.Annotation & {
    Subject: AgentSignatureSubjectType;
};

export type CompletionDateAnnotation = Core.Annotations.Annotation & {
    Subject: CompletionDateSubjectType;
};

export type AgentNameAnnotation = Core.Annotations.Annotation & {
    Subject: AgentNameSubjectType;
};

export type SenderNameAnnotation = Core.Annotations.Annotation & {
    Subject: SenderNameSubjectType;
};

export type FreeTextAnnotation = Core.Annotations.Annotation & {
    Subject: FreeTextSubjectType;
};

export type WitnessSignatureAnnotation = Core.Annotations.StampAnnotation & {
    Subject: WitnessSignatureSubjectType;
};

export type WitnessNameAnnotation = Core.Annotations.Annotation & {
    Subject: WitnessNameSubjectType;
};
export type CustomPlaceholderAnnotation = Core.Annotations.FreeTextAnnotation & {
    Subject: CustomPlaceholderSubjectType;
};

export type CustomPlaceholderAnnotationData = {
    customPlaceholderId: string;
};

export type ClientAnnotation = ClientSignatureAnnotation | ClientDateAnnotation | ClientNameAnnotation;

export type WitnessAnnotation = WitnessNameAnnotation | WitnessSignatureAnnotation;

export const isClientSignatureAnnotation = (
    annotation: Core.Annotations.Annotation
): annotation is ClientSignatureAnnotation => {
    const regex = /^client-(.*)-([0-9]+)$/;
    return regex.test(annotation.Subject);
};

export const isClientDateAnnotation = (annotation: Core.Annotations.Annotation): annotation is ClientDateAnnotation => {
    const regex = /^date-(.*)-([0-9]+)$/;
    return regex.test(annotation.Subject);
};

export const isClientNameAnnotation = (annotation: Core.Annotations.Annotation): annotation is ClientNameAnnotation => {
    const regex = /^name-(.*)-([0-9]+)$/;
    return regex.test(annotation.Subject);
};

export const isAgentSignatureAnnotation = (
    annotation: Core.Annotations.Annotation
): annotation is AgentSignatureAnnotation => {
    return annotation.Subject === 'agent';
};

export const isCompletionDateAnnotation = (
    annotation: Core.Annotations.Annotation
): annotation is CompletionDateAnnotation => {
    return annotation.Subject === ANNOTATION_SUBJECT_COMPLETION_DATE;
};

export const isAgentNameAnnotation = (annotation: Core.Annotations.Annotation): annotation is AgentNameAnnotation => {
    return (
        annotation.Subject === ANNOTATION_SUBJECT_AGENT_NAME || annotation.Subject === ANNOTATION_SUBJECT_SENDER_NAME
    );
};

export const isSenderNameAnnotation = (annotation: Core.Annotations.Annotation): annotation is SenderNameAnnotation => {
    return annotation.Subject === ANNOTATION_SUBJECT_SENDER_NAME;
};

export const isFreeTextAnnotation = (annotation: Core.Annotations.Annotation): annotation is FreeTextAnnotation => {
    return annotation.Subject === 'Free Text';
};

export const isWitnessNameAnnotation = (
    annotation: Core.Annotations.Annotation
): annotation is WitnessNameAnnotation => {
    const regex = /^witnessName-(.*)-([0-9]+)$/;
    return regex.test(annotation.Subject);
};

export const isWitnessAnnotation = (
    annotation: Core.Annotations.Annotation
): annotation is WitnessSignatureAnnotation => {
    const regex = /^witnessSign-(.*)-([0-9]+)$/;
    return regex.test(annotation.Subject);
};

export const isCustomPlaceholderAnnotation = (
    annotation: Core.Annotations.Annotation
): annotation is CustomPlaceholderAnnotation => {
    return annotation.Subject === ANNOTATION_SUBJECT_CUSTOM_PLACEHOLDER;
};

export type ClientAnnotationCounts = {
    [clientIndex: string]: number;
};

export type WitnessAnnotationCounts = {
    [witnessIndex: string]: number;
};

export type Point = { x: number; y: number };

export type CreateAnnotationOptions = {
    useImprovedAnnotationAccuracy?: boolean;
    isDecreaseFreeTextDefaultFontSizeEnabled?: boolean;
    customPlaceholderData?: {
        id: string;
        isBlank: boolean;
        respondentType: CustomPlaceholderRespondentType;
    };
};

export enum AnnotationAction {
    ADD = 'add',
    DELETE = 'delete',
    MODIFY = 'modify'
}

// Copied from docs here: https://docs.apryse.com/api/web/Core.AnnotationManager.html#.AnnotationChangedSources__anchor, since the enum is
// not exported from the webviewer package
export enum AnnotationChangedSources {
    MOVE = 'move',
    RESIZE = 'resize',
    ROTATE = 'rotate',
    TEXT_CHANGED = 'textChanged',
    NOTE_CHANGED = 'noteChanged',
    DRAGGING_ACROSS_PAGES = 'draggingAcrossPages'
}

export enum AnnotationError {
    datesWithoutSignatures = 'datesWithoutSignatures',
    defaultTextAnnotations = 'defaultTextAnnotations',
    witnessSignatureNotEqualToWitnessName = 'witnessSignatureNotEqualToWitnessName'
}
