import * as React from 'react';
import ModalDialog from '../../../common/components/ModalDialog';
import '../../../sass/materialFacts.scss';

/**
 * Modal window to display material facts.
 * This window is available from overview agreement page.
 */
const ModalViewMaterialFacts = ({ isOpen, closeModal, materialFacts }) => {
    return (
        <ModalDialog title="Material Facts" isOpen={isOpen} closeModal={closeModal}>
            <div className="materialFacts">
                {Object.keys(materialFacts).map((key, index) => {
                    if (key !== 'other' && materialFacts[key].enabled) {
                        return (
                            <div className="overview" key={index}>
                                <p className="long-text">{materialFacts[key].fact}</p>
                                <strong>Answer: {materialFacts[key].answer ? 'Yes' : 'No'}</strong>
                            </div>
                        );
                    }
                })}
                {materialFacts.other.map((fact, index) => {
                    return (
                        <div className="overview" key={`other${index}`}>
                            <p className="long-text">{fact.fact}</p>
                            <strong>Answer: {fact.answer ? 'Yes' : 'No'}</strong>
                        </div>
                    );
                })}
            </div>
        </ModalDialog>
    );
};

export default ModalViewMaterialFacts;
