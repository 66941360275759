import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateDayWithTh, getAgencyTimezoneFromUser } from '@app/utils/dateUtils';
import { pullLeaseConditionReport, removeLeaseConditionReport } from '@app/actions/lease';
import Button from '@app/common/components/Button';
import { formatUrl } from '@app/config';
import { getCommon } from '@app/selectors/lease';
import { getUserInfo } from '@app/selectors/user';

export default function ConditionReport() {
    const dispatch = useDispatch();
    const lease = useSelector(getCommon);
    const loggedInUser = useSelector(getUserInfo);
    const agencyTimezone = getAgencyTimezoneFromUser(loggedInUser);

    const leaseId = lease?.leaseAllInfo?.id;
    const conditionReport = lease?.leaseAllInfo?.conditionReport;
    const isPullingConditionReport = lease?.leaseAllInfo?.isPullingConditionReport;
    const errorPullingConditionReport = lease?.leaseAllInfo?.errorPullingConditionReport;

    return (
        <div className="conditional-report-container">
            <h3 className="section-title">Condition Report Link</h3>
            <div className="margin-bottom-1rem">
                This link will be provided to the tenant in the introduction email when you send the lease via SMS and
                also on the phone once they finish signing.
            </div>
            <div className="tenant-links">
                {conditionReport ? (
                    <ul className="links-list">
                        <li className="link-box">
                            <div className="link-box-left">
                                <div className="link-url-wrapper">
                                    <div>{formatDateDayWithTh(conditionReport.dateTimeCompleted, agencyTimezone)}</div>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={formatUrl(conditionReport.reportUrl)}
                                    >
                                        {conditionReport.reportUrl}
                                    </a>
                                </div>
                            </div>
                            <div className="link-box-right">
                                <div className="link-icons">
                                    <button
                                        className="btn-delete"
                                        type="button"
                                        onClick={() => dispatch(removeLeaseConditionReport(leaseId))}
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                ) : (
                    <div className="flex-direction-column column-box gap-1">
                        {errorPullingConditionReport && (
                            <p className="error-message">Couldn't find Condition Report for this property</p>
                        )}
                        <Button
                            onClick={() => dispatch(pullLeaseConditionReport(leaseId))}
                            width={'100%'}
                            secondary
                            loading={isPullingConditionReport}
                            data-id={`retrieve-condition-report-button-${leaseId}`}
                        >
                            Retrieve Condition Report
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
