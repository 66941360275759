import React, { useCallback, useState } from 'react';
import '../../../../sass/dashboardContentTable.scss';
import '../../../../sass/documentListButton.scss';
import { emailStandAloneTerminationPDF } from '../../../../actions/document';
import { HIDE_MESSAGE_TIME } from '../../../../config';
import { emailLeaseTerminationPDF } from '../../../../actions/lease';
import ReactTooltip from 'react-tooltip';

const TerminationListButton = ({ doc, lease }) => {
    const [isEmailingTerminateLease, setIsEmailingTerminateLease] = useState(false);
    const [isEmailingTerminateLeaseSuccess, setIsEmailingTerminateLeaseSuccess] = useState(false);
    const [isEmailingTerminateLeaseFail, setIsEmailingTerminateLeaseFail] = useState(false);
    let emailTermination = useCallback((docId, leaseId) => emailStandAloneTerminationPDF(docId), []);

    if (lease) {
        emailTermination = useCallback((docId, leaseId) => emailLeaseTerminationPDF(leaseId, docId), []);
    }

    let viewPDF = (docId, leaseId) => {
        if (leaseId) {
            window.open(`/reader/termination-notice/${leaseId}/${docId}`, '_blank');
        }
        /**
         * @todo view pdf for manually created termination notice
         */
    };

    const emailPDF = (docId, leaseId) => {
        setIsEmailingTerminateLease(true);
        emailTermination(docId, leaseId)
            .then(() => {
                setIsEmailingTerminateLease(false);
                setIsEmailingTerminateLeaseSuccess(true);
                setIsEmailingTerminateLeaseFail(false);
                setTimeout(() => {
                    setIsEmailingTerminateLeaseSuccess(false);
                }, HIDE_MESSAGE_TIME);
            })
            .catch(error => {
                setIsEmailingTerminateLease(false);
                setIsEmailingTerminateLeaseSuccess(false);
                setIsEmailingTerminateLeaseFail(true);
                setTimeout(() => {
                    setIsEmailingTerminateLeaseFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    return (
        <div className="document-buttons-container">
            <ReactTooltip id="sending" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                <p>The termination notice is processing, please check back later</p>
            </ReactTooltip>
            <div
                className="document-buttons"
                data-tip
                data-for={doc.status && doc.status === 'sending' ? 'sending' : ''}
            >
                <button
                    className="btn-email"
                    onClick={() => emailPDF(doc.id, lease ? lease.id : undefined)}
                    disabled={doc.status && doc.status === 'sending'}
                >
                    Email PDF to me
                    {(isEmailingTerminateLease || (doc.status && doc.status === 'sending')) && (
                        <span className="savestatus-spinner-await-completion" />
                    )}
                </button>

                {lease && (
                    <button
                        className="btn-email"
                        onClick={() => viewPDF(doc.id, lease.id)}
                        disabled={doc.status && doc.status === 'sending'}
                    >
                        View PDF
                        {(isEmailingTerminateLease || (doc.status && doc.status === 'sending')) && (
                            <span className="savestatus-spinner-await-completion" />
                        )}
                    </button>
                )}
            </div>
            {isEmailingTerminateLeaseSuccess && (
                <p className="button-result savestatus-saved">
                    Email sent successfully
                    <span className="icon savestatus-icon" />
                </p>
            )}
            {isEmailingTerminateLeaseFail && (
                <p className="button-result savestatus-failed">
                    Email failed <span className="icon savestatus-icon" />
                </p>
            )}
        </div>
    );
};

export default TerminationListButton;
