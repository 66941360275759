import React from 'react';
import '../../../sass/empty-state.scss';
import { isString } from 'lodash';
import { DOCUMENT_CUSTOM, FLK_A_PDF_DISPLAY } from '../../../config';

const EmptyDocState = props => {
    const getType = () => {
        let { type } = props;
        if (!type || !isString(type)) {
            return '';
        }
        if (type === DOCUMENT_CUSTOM) {
            return FLK_A_PDF_DISPLAY;
        }

        type = type.replace(/_/g, ' ');
        type = type.replace('agreement', '');
        type = type.replace('document', '');
        type = type.replace('reduction', 'relief');
        type = type.trim();

        return type.charAt(0).toUpperCase() + type.slice(1);
    };

    return (
        <div className="wrapper-empty-state ">
            <div className="greeting">
                <h1>No documents found</h1>
                <p className="greeting-text">
                    You don't have&nbsp;
                    <span className="agreement-type">{getType()}</span>
                    &nbsp;documents yet. Create your first one by clicking the button in the top right.
                </p>
            </div>
        </div>
    );
};

export default EmptyDocState;
