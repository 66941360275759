import {
    OPEN_TERMINATE_LEASE_MODAL,
    CLOSE_TERMINATE_LEASE_MODAL,
    OPEN_TERMINATE_LEASE_LIST_MODAL,
    CLOSE_TERMINATE_LEASE_LIST_MODAL,
    UPDATE_TERMINATE_LEASE_DATA
} from '../actions/types';

import { leaseTerminationReasonOptions } from '../config';

/**
 * we update the state in here when we open terminate lease modal.
 * because we use only this state for terminate form. not using component state.
 *
 * @param {Object} agreementInfo
 * @param {Object} user
 * @returns {Object} termination lease form data
 */
const setFormData = (agreementInfo, user) => {
    const formData = {
        reasonForTermination: leaseTerminationReasonOptions[agreementInfo.location]
            ? leaseTerminationReasonOptions[agreementInfo.location][0].value
            : ''
    };
    formData.leaseId = agreementInfo.id;
    if (agreementInfo.tenant) {
        formData.tenants = agreementInfo.tenant.tenantsListText;
    }
    if (agreementInfo.address) {
        formData.address = agreementInfo.address;
    }
    if (agreementInfo.tenantsAgent && agreementInfo.tenantsAgent.firstName && agreementInfo.tenantsAgent.secondName) {
        formData.agent = agreementInfo.tenantsAgent;
        formData.agentName = agreementInfo.tenantsAgent.firstName + ' ' + agreementInfo.tenantsAgent.secondName;
    }
    formData.ccEmailAddresses = [user.email];
    formData.emailAddresses = [];
    if (agreementInfo.tenant && agreementInfo.tenant.emailAddressesForServiceNotices !== '') {
        const emailAddressesForNotice = agreementInfo.tenant.emailAddressesForServiceNotices.split(', ');
        if (emailAddressesForNotice.length > 0) {
            formData.emailAddresses = formData.emailAddresses.concat(emailAddressesForNotice);
        }
    }
    formData.deliveryType = 'hand';
    formData.qldNoticeReason = {};
    return formData;
};

const initState = {
    isTerminateLeaseModalOpen: false,
    isTerminateLeaseListModalOpen: false,
    selectedTenants: {},
    data: {}
};

export default function search(state = initState, action) {
    switch (action.type) {
        case UPDATE_TERMINATE_LEASE_DATA: {
            return {
                ...state,
                data: action.payload.data
            };
        }
        case OPEN_TERMINATE_LEASE_MODAL: {
            const data = setFormData(action.payload.lease, action.payload.user);
            return {
                ...state,
                isTerminateLeaseModalOpen: true,
                data
            };
        }
        case CLOSE_TERMINATE_LEASE_MODAL: {
            return {
                ...state,
                isTerminateLeaseModalOpen: false
            };
        }
        case OPEN_TERMINATE_LEASE_LIST_MODAL: {
            return {
                ...state,
                isTerminateLeaseListModalOpen: true
            };
        }
        case CLOSE_TERMINATE_LEASE_LIST_MODAL: {
            return {
                ...initState
            };
        }
    }

    return state;
}
