import React, { forwardRef, useState, useImperativeHandle, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { has } from 'lodash';
import { Form, FormSpy } from 'react-final-form';

import { updateSubmitTypeSuccess, setDirtyStep, formSubmitFail } from '../../../actions/lease';
import * as Lease from '../../../reducers/lease';

import { getLocation, getPermittedUse, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { FormTextMultiline } from '../../../components/form/FormText';

const PERMITTED_USE_FORM = 'permittedUseForm';
const initState = {
    usedFor: ''
};

function PermittedUse(props, ref) {
    const dispatch = useDispatch();
    const reduxPermittedUse = useSelector(getPermittedUse);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [permittedUse, setPermittedUse] = useState(initState);

    useEffect(() => {
        setPermittedUse({
            ...initState,
            ...reduxPermittedUse
        });
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PERMITTED_USE_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const updatePermittedUse = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/permitted-use`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;

        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePermittedUse(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.permittedUse')) {
                    return error.response.data.errors.permittedUse;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="permittedUse">
            <Form onSubmit={submitForm} initialValues={permittedUse}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} id={PERMITTED_USE_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormTextMultiline name="usedFor" label="The premises must only be used for:" required />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(PermittedUse));
