import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';

import DisclosureDocumentForm from './DisclosureDocumentForm';
import {
    getDisclosureAvailableQuestions,
    getIsOpenDisclosureDocumentModal
} from '../../../../selectors/document/discloserDocument';
import { DISCLOSURE_DOCUMENT_QUESTION } from '../../../../config';
import { getQuestions } from '../../../../actions/document';
import logger from '../../../../utils/logger';

const DisclosureDocumentWrapper = () => {
    const dispatch = useDispatch();
    const IsOpenDisclosureDocumentModal = useSelector(getIsOpenDisclosureDocumentModal);
    const availableQuestions = useSelector(getDisclosureAvailableQuestions);
    const [noOfTries, setNoOfTries] = useState(0);

    useEffect(() => {
        // Try to get questions 3 times
        if (isEmpty(availableQuestions) && noOfTries < 3) {
            setNoOfTries(noOfTries + 1);
            dispatch(getQuestions({ document: DISCLOSURE_DOCUMENT_QUESTION }));
        } else if (isEmpty(availableQuestions) && noOfTries > 2 && IsOpenDisclosureDocumentModal) {
            // If there is no availableQuestions and tried 3 times, then, log the message
            //  and throw an error and display error page when open a disclosure document
            logger.push({
                status: 'error',
                env: ENVIRONMENT,
                message: 'Something went wrong getting disclosure document questions'
            });
            throw 'Something went wrong getting disclosure document questions';
        }
    }, [availableQuestions]);

    if (IsOpenDisclosureDocumentModal && !isEmpty(availableQuestions)) {
        return <DisclosureDocumentForm availableQuestions={cloneDeep(availableQuestions)} />;
    } else {
        return null;
    }
};
export default memo(DisclosureDocumentWrapper);
