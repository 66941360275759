import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import { has } from 'lodash';
import produce from 'immer';

const initState = {
    isSection48Notice: true,
    isInformationBrochure: true,
    copyOfAgreement: true,
    isPropertyConditionReport: true,
    isManualsAndInstructions: false,
    specialConditions: false,
    isStatutoryNotice: false,
    isStrataArticles: false,
    isCommunityTitle: false
};

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS: {
            draftState = initState;
            break;
        }
        case Lease.LEASE_INSTRUCTIONS_SUCCESS:
        case Lease.LEASE_CONDITIONS_SUCCESS: {
            if (action.result.data.lease.executedAsAgreement) {
                draftState = action.result.data.lease.executedAsAgreement;
            } else {
                draftState = {
                    ...initState,
                    isManualsAndInstructions:
                        has(action, 'result.data.lease.instructions.instructionsList') &&
                        action.result.data.lease.instructions.instructionsList.length > 0,
                    specialConditions:
                        has(action, 'result.data.lease.specialConditions.clauseList') &&
                        action.result.data.lease.specialConditions.clauseList.length > 0
                };
            }
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            if (action.payload.agreementInfo.executedAsAgreement) {
                draftState = action.payload.agreementInfo.executedAsAgreement;
            } else {
                draftState = {
                    ...initState,
                    isManualsAndInstructions:
                        has(action, 'payload.agreementInfo.instructions.instructionsList') &&
                        action.payload.agreementInfo.instructions.instructionsList.length > 0,
                    specialConditions:
                        has(action, 'payload.agreementInfo.specialConditions.clauseList') &&
                        action.payload.agreementInfo.specialConditions.clauseList.length > 0
                };
            }
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            draftState = action.lease.executedAsAgreement;
            break;
        }

        case Lease.LEASE_EXECUTED_AS_AGREEMENT_SUCCESS: {
            draftState = action.result.data.lease.executedAsAgreement;
        }
    }
    return draftState;
});
