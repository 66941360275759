import React from 'react';
import { useFeatureFlag, useFeatureFlags } from '@harnessio/ff-react-client-sdk';

// Keep track of feature flag subscriptions so we can only add one listener per feature flag
const featureFlagSubscriptions: Record<string, boolean> = {};

/**
 * Reads and writes feature flags to local storage, so we can use the last known feature flag value as its default value.
 * This minimises UI flashes when loading the feature flag values.
 */
// FUTURE IMPROVEMENT: We could store the cached feature flag values in memory to avoid reading from local storage on every render.
export const useCachedFeatureFlag = (featureFlagId: string) => {
    const rawCachedValue = sessionStorage.getItem(featureFlagId);
    const cachedValue = rawCachedValue ? JSON.parse(rawCachedValue) : undefined;
    const featureFlag = useFeatureFlag(featureFlagId, cachedValue);

    React.useEffect(() => {
        const subscribe = () => {
            sessionStorage.setItem(featureFlagId, JSON.stringify(featureFlag ?? null));
        };
        if (!featureFlagSubscriptions[featureFlagId]) {
            window.addEventListener('unload', subscribe);
            featureFlagSubscriptions[featureFlagId] = true;
        }

        return () => {
            window.removeEventListener('unload', subscribe);
            featureFlagSubscriptions[featureFlagId] = false;
        };
    }, [featureFlag, featureFlagId]);

    return featureFlag;
};

export const useCachedFeatureFlags = (featureFlagIds: string[]) => {
    const cachedValues = featureFlagIds.reduce(
        (object, flagId) => {
            const flagValue = sessionStorage.getItem(flagId);
            object[flagId] = flagValue ? JSON.parse(flagValue) : undefined;
            return object;
        },
        {} as Record<string, boolean | undefined>
    );
    const featureFlags = useFeatureFlags(cachedValues);
    const featureFlagsAsString = JSON.stringify(featureFlags);

    React.useEffect(() => {
        const featureFlagListeners = Object.keys(featureFlags)
            .map(flag => {
                const subscribe = () => {
                    sessionStorage.setItem(flag, JSON.stringify(featureFlags[flag] ?? null));
                };
                if (!featureFlagSubscriptions[flag]) {
                    return { flagId: flag, subscribe };
                }
            })
            .filter(obj => !!obj) as {
            flagId: string;
            subscribe: () => void;
        }[];
        featureFlagListeners.forEach(({ flagId, subscribe }) => {
            window.addEventListener('unload', subscribe);
            featureFlagSubscriptions[flagId] = true;
        });

        return () => {
            featureFlagListeners.forEach(({ flagId, subscribe }) => {
                window.removeEventListener('unload', subscribe);
                featureFlagSubscriptions[flagId] = false;
            });
        };
    }, [featureFlags, featureFlagsAsString]);

    return featureFlags;
};
