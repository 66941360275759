import { STATUS_ACTIVE, userRoles } from '../config';
import { filter, find, isEmpty } from 'lodash';
import { AGENCY_ACCOUNT_TYPE, GENERAL_ACCOUNT_TYPE } from '../constants/constants';

export function getRole(role) {
    switch (role) {
        case userRoles.ROLE_AGENCY_PRINCIPAL: {
            return 'Principal';
        }
        case userRoles.ROLE_AGENCY_ADMIN: {
            return 'Admin';
        }
        case userRoles.ROLE_AGENCY_TEAMMATE: {
            return 'Team Member';
        }
        default:
            return '';
    }
}

export const hasBannerId = (bannerActions, bannerId) => {
    return bannerActions.some(banner => banner.bannerId === bannerId);
};

export const getSignaturesByUserId = (agencySignatureList, userId) => {
    return filter(agencySignatureList, { createdBy: { id: userId } });
};

export const isAdmin = role => {
    return role === userRoles.ROLE_AGENCY_PRINCIPAL || role === userRoles.ROLE_AGENCY_ADMIN;
};

export const isTeamMember = role => {
    return role === userRoles.ROLE_AGENCY_TEAMMATE;
};

/**
 * Remove all other kind of team members from the array
 * @param team
 * @returns {Array}
 */
export const getOnlyTeamMates = team => filter(team, member => member.role === userRoles.ROLE_AGENCY_TEAMMATE);

/**
 * Filter the user from the team
 * @param team
 * @param user
 * @returns {Array}
 */
export const getTeamWithoutUser = (team, user) => {
    if (!user) {
        return team;
    }
    return filter(team, member => member.id !== user.id);
};

/**
 * Add user to team and return team
 * @param team
 * @param user
 * @returns {*}
 */
export const getTeamWithUserAdded = (team, user) => {
    const userInTeam = find(team, { id: user.id });
    if (userInTeam) {
        return team;
    } else {
        return [...team, user];
    }
};

export const isBusinessUserAccount = accountType => {
    if (accountType) {
        return accountType === GENERAL_ACCOUNT_TYPE;
    }
    return false;
};

export const isAgencyUserAccount = accountType => {
    if (accountType) {
        return accountType === AGENCY_ACCOUNT_TYPE;
    }
    return false;
};

export const isAccountEnabled = loggedInUser => {
    // Check if user can create lease
    const isActiveUser = loggedInUser.status === STATUS_ACTIVE;
    const isActiveSubscription =
        loggedInUser.agency && !isEmpty(loggedInUser.agency.tariffPlan) && loggedInUser.agency.tariffPlan.active;

    return isActiveUser && isActiveSubscription;
};
