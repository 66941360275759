import React from 'react';
import { PreLoader } from '../PreLoader.js';
import styles from './Dashboard.module.scss';
import useQueryParams from '../../../hooks/useQueryParams';
import Card, { CardStyles } from '../cards/Card';
import Icon, { Icons } from '../Icon';

type DashboardProps = {
    header: React.ReactNode;
    tabBarProps: {
        tabBar: React.ReactNode;
        isPending?: boolean;
    };
    body?: React.ReactNode;
    pagination: React.ReactNode;
    modalProps?: {
        modal: React.ReactNode;
        isOpen: boolean;
    };
};

const Dashboard: React.FC<DashboardProps> = ({ header, tabBarProps, body, pagination, modalProps }) => {
    const query = useQueryParams();
    const source = query.get('source');

    return (
        <>
            <div className={styles.dashboard}>
                {source === 'onboarding' && (
                    <Card style={CardStyles.SQUARE} className={styles.successNotification}>
                        <Icon className={styles.successIcon} icon={Icons.CHECK} /> You have successfully sent the
                        document(s).
                    </Card>
                )}
                {header}
                <div className={styles.tabBar}>
                    {tabBarProps.tabBar}
                    {tabBarProps.isPending && <PreLoader className={styles.preLoader} />}
                </div>
                <div>{body}</div>
                <div>{pagination}</div>
            </div>
            {modalProps?.isOpen && modalProps.modal}
        </>
    );
};

export default Dashboard;
