import React from 'react';
// @ts-ignore
import { LEASE_TYPE_COMMERCIAL_LEASE } from '../../../config';
// @ts-ignore
import * as dashboard from '../../../actions/dashboard';

// @ts-ignore
import TableDraft from './tables/TableDraft';
// @ts-ignore
import TableSentForSigning from './tables/TableSentForSigning';
// @ts-ignore
import TableAwaiting from './tables/TableAwaiting';
// @ts-ignore
import TableArchived from './tables/TableArchived';

import '../../../sass/dashboardContentTable.scss';
import '../../../sass/_radial-progress.scss';

function getContentTable(showModalInfoAgreement: (lease: any) => void, agreementList: [], leaseStatus: string) {
    switch (leaseStatus) {
        case dashboard.LEASE_STATUS_DRAFT: {
            return (
                <TableDraft
                    agreementList={agreementList}
                    showModalInfoAgreement={(lease: any) => showModalInfoAgreement(lease)}
                    leaseStatus={leaseStatus}
                    leaseType={LEASE_TYPE_COMMERCIAL_LEASE}
                />
            );
        }
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            return (
                <TableSentForSigning
                    agreementList={agreementList}
                    showModalInfoAgreement={(lease: any) => showModalInfoAgreement(lease)}
                    leaseStatus={leaseStatus}
                    leaseType={LEASE_TYPE_COMMERCIAL_LEASE}
                    showEditTenants={() => {}}
                />
            );
        }
        case dashboard.LEASE_STATUS_AWAITING_COMPLETION: {
            return (
                <TableAwaiting
                    agreementList={agreementList}
                    showModalInfoAgreement={(lease: any) => showModalInfoAgreement(lease)}
                    leaseStatus={leaseStatus}
                    leaseType={LEASE_TYPE_COMMERCIAL_LEASE}
                />
            );
        }
        case dashboard.LEASE_STATUS_ARCHIVED: {
            return (
                <TableArchived
                    agreementList={agreementList}
                    showModalInfoAgreement={(lease: any) => showModalInfoAgreement(lease)}
                    leaseStatus={leaseStatus}
                    leaseType={LEASE_TYPE_COMMERCIAL_LEASE}
                />
            );
        }
        return null;
    }
}

type CommercialLeaseType = {
    showModalInfoAgreement: (lease: any) => void;
    agreementList: [];
    leaseStatus: string;
};

const CommercialLease: React.FC<CommercialLeaseType> = ({ showModalInfoAgreement, agreementList, leaseStatus }) => {
    const contentTable = getContentTable(showModalInfoAgreement, agreementList, leaseStatus);
    return <div className="table-sort">{contentTable}</div>;
};

export default CommercialLease;
