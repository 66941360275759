import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import './sass/global.scss';
import './sass/agentSignatures/global.scss';
import { initVendor } from './vendor/vendor';

initVendor();

const rootElement = document.getElementById('root');

ReactDOM.render(<App />, rootElement);
