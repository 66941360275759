export default function promiseMiddleware() {
    return next => action => {
        const { promise, types, ...rest } = action;

        if (!promise) {
            return next(action);
        }

        const [REQUEST, SUCCESS, FAILURE] = types;

        next({ ...rest, type: REQUEST });

        return promise
            .then(
                result => {
                    if (result) {
                        const SUCCESS_ACTION =
                            result.data && result.data.successAction ? result.data.successAction : SUCCESS;
                        next({ ...rest, result, type: SUCCESS_ACTION });
                    }
                },
                error => {
                    next({ ...rest, error, type: FAILURE });
                }
            )
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error('MIDDLEWARE ERROR:', error);
                next({ ...rest, error, type: FAILURE });
            });
    };
}
