import React from "react";
import { Add as ZoomInIcon, Remove as ZoomOutIcon } from "@material-ui/icons";
import Button from "./Button";
import cx from "classnames";
import styles from "./ZoomControls.module.scss";

type ZoomControlsProps = {
    onZoomIn: () => void;
    onZoomOut: () => void;
    initialZoom: number;
    currentZoom: number;
    maxZoom: number;
    minZoom: number;
    className?: string;
};
const ZoomControls: React.FC<ZoomControlsProps> = ({
    className,
    onZoomIn,
    onZoomOut,
    initialZoom,
    currentZoom,
    maxZoom,
    minZoom
}) => {
    const zoomPercentage = ((currentZoom / initialZoom) * 100).toFixed(0);

    return (
        <div className={cx(styles.zoomControls, className)}>
            <Button
                className={styles.zoomButton}
                onClick={onZoomOut}
                disabled={currentZoom === minZoom}
            >
                <ZoomOutIcon className={styles.zoomIcon} />
            </Button>
            <span>{zoomPercentage}%</span>
            <Button
                className={styles.zoomButton}
                onClick={onZoomIn}
                disabled={currentZoom === maxZoom}
            >
                <ZoomInIcon className={styles.zoomIcon} />
            </Button>
        </div>
    );
};

export default ZoomControls;
