import React, { memo } from 'react';
import { SelectField } from '../../../../../components/form/FormSelect';
import Button from '../../../../../common/components/Button';
import { FORM_SUBMIT_TYPE_SAVE } from '../../../../../config';
import { DEADLINE_OPTIONS } from '../../../../../utils/formUtils';
import { FORM_SUBMIT_TYPE_SEND } from '../../../../../config';
import { ONBOARDING_TRACKING_PREFIX } from '../../../../../constants/constants';

function FlkAPdfFooterPublic({
    form,
    values,
    documentsList,
    isSubmitting,
    isSubmitFail,
    isSubmitSuccessful,
    isSending,
    isSendFail,
    isSendSuccessful,
    setBackendErrors,
    areAllDocumentsInitialised
}) {
    const optionsNumber = DEADLINE_OPTIONS;

    const renderSendButton = () => {
        const sendButtonText = 'Send';

        return (
            <React.Fragment>
                <div className="send-for-signing-container">
                    <div className="deadline-container for-lg-modal">
                        <SelectField
                            label="Deadline"
                            name="deadline"
                            options={optionsNumber}
                            containerClassName="deadline-select"
                        />
                    </div>
                    <br />
                    <div className="footer-help-text for-lg-modal">
                        <p>
                            * It is the sender's responsibility to ensure the uploaded PDF is allowed to be signed in
                            this format.
                        </p>
                    </div>
                    <Button
                        primary
                        className="btn-terminate"
                        height={48}
                        width={140}
                        type="submit"
                        // set submitType in here, then use that is handleSubmit to decide send or save
                        onClick={() => {
                            setBackendErrors(null);
                            form.change('submitType', FORM_SUBMIT_TYPE_SEND);
                        }}
                        disabled={
                            isSending ||
                            isSubmitting ||
                            documentsList.length === 0 ||
                            values.clients.length === 0 ||
                            !values.deliveryType ||
                            !values.confirmationType
                        }
                        loading={isSending || isSubmitting || !areAllDocumentsInitialised}
                        data-automation-id={`${ONBOARDING_TRACKING_PREFIX}-form-send-button`}
                    >
                        {sendButtonText}
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    const getErrorAndSuccessMessages = () => {
        return (
            (isSubmitSuccessful || isSubmitFail || isSendSuccessful || isSendFail) && (
                <div className="feedback-message">
                    <div className="button-row">
                        {isSendSuccessful && (
                            <span className="savestatus-saved">
                                <span className="icon savestatus-icon" />
                                Document sent successfully
                            </span>
                        )}
                        {isSendFail && (
                            <span className="savestatus-failed">
                                <span className="icon savestatus-icon" />
                                Document save failed
                            </span>
                        )}
                        {isSubmitSuccessful && (
                            <span className="savestatus-saved">
                                <span className="icon savestatus-icon" />
                                Document saved successfully
                            </span>
                        )}
                        {isSubmitFail && (
                            <span className="savestatus-failed">
                                <span className="icon savestatus-icon" />
                                Document save failed
                            </span>
                        )}
                    </div>
                </div>
            )
        );
    };

    const getButtons = () => {
        return (
            <React.Fragment>
                <div className="footer-draft">
                    {getErrorAndSuccessMessages()}
                    <div>
                        <div className="button-footer-docs">{renderSendButton()}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return <React.Fragment>{getButtons()}</React.Fragment>;
}

export default memo(FlkAPdfFooterPublic);
