import { has, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import ModalDialog from '../../../../common/components/ModalDialog.js';
import TariffPlan from '../../../../components/signup/TariffPlan.js';

import { subscriptionStatus } from '../../../../config';
import { TariffPlan as TariffPlanType } from '../../../../types/TariffPlan';
import { isBusinessUserAccount } from '../../../../utils/userUtils.js';
import { getPriceDescription } from '../../../../utils/countryUtils';
import useCountry from '../../../../hooks/useCountry';

import '../../../../sass/tariffPlan.scss';

type ModalChangeSubscriptionPlanProps = {
    plans: Array<{ [key: string]: any }>;
    isOpen: boolean;
    closeModal: () => void;
    setTariffPlan: (id: string, stripeId: string | null) => void;
    tariffPlan: TariffPlanType;
    agency: { [key: string]: any };
    amountOfAgreementsSentThisMonth: number;
    errorMessage: string;
    isSaving: boolean;
};

const ModalChangeSubscriptionPlan: React.FC<ModalChangeSubscriptionPlanProps> = ({
    plans,
    isOpen,
    closeModal,
    setTariffPlan,
    tariffPlan,
    agency,
    amountOfAgreementsSentThisMonth,
    errorMessage,
    isSaving
}) => {
    const { country } = useCountry();

    const [selectedPlanStripeId, setSelectedPlanStripeId] = useState<string | null>(
        has(tariffPlan, 'stripeId') ? tariffPlan.stripeId : null
    );

    useEffect(() => {
        if (tariffPlan !== null && !isOpen) {
            setSelectedPlanStripeId(has(tariffPlan, 'stripeId') ? tariffPlan.stripeId : null);
        }
    }, [tariffPlan, isOpen]);

    const selectPlan = (plan: { stripeId: string }) => {
        setSelectedPlanStripeId(plan.stripeId);
    };

    const setTariffPlanFunc = () => {
        setTariffPlan(agency.id, selectedPlanStripeId);
    };

    const canUpdateToPlan = (plan: any) => {
        if (isEmpty(tariffPlan)) {
            return true;
        }
        // if General account we pass in amountOfDocumentsSentThisMonth as amountOfAgreementsSentThisMonth
        if (isBusinessUserAccount(agency.accountType)) {
            if (agency.tariffPlan.status === subscriptionStatus.TRIALING) {
                return true;
            }
            return amountOfAgreementsSentThisMonth <= plan.agreementsPerMonth.freeDocs;
        } else {
            return amountOfAgreementsSentThisMonth <= plan.agreementsPerMonth.max;
        }
    };

    const renderRightButton = () => {
        return (
            <div className="button btn-spinner">
                <button type="submit" onClick={() => setTariffPlanFunc()} disabled={isSaving || !selectedPlanStripeId}>
                    {isSaving && <span className="savestatus-spinner-white" />}
                    Save plan
                </button>
            </div>
        );
    };

    return (
        <ModalDialog
            title="Change subscription plan"
            isOpen={isOpen}
            closeModal={closeModal}
            rightButton={renderRightButton()}
            className="change-subscription-plan"
        >
            <div className="list-plan">
                <p className="message">{getPriceDescription(country)}</p>
                {plans.map((plan, index) => (
                    <TariffPlan
                        key={index}
                        tariffPlan={plan}
                        isSelected={plan.stripeId === selectedPlanStripeId}
                        changePlan={() => selectPlan(plan)}
                        disabled={!canUpdateToPlan(plan)}
                    />
                ))}
                <p className="has-error">{errorMessage}</p>
            </div>
        </ModalDialog>
    );
};

export default ModalChangeSubscriptionPlan;
