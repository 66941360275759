import React from 'react';
import cx from 'classnames';
import { Item } from '@radix-ui/react-dropdown-menu';
import styles from './DropdownItem.module.scss';

type DropdownItemProps = {
    key: string;
    icon?: React.ReactNode;
    text: string;
    onClick: (e: React.MouseEvent) => void;
    endIcon?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    dataTip?: string;
    dataFor?: string;
};
const DropdownItem: React.FC<DropdownItemProps> = ({
    icon,
    text,
    onClick,
    endIcon,
    className,
    disabled = false,
    dataTip,
    dataFor
}) => {
    return (
        <Item
            className={cx(styles.item, className)}
            disabled={disabled}
            onClick={e => {
                e.stopPropagation();
                if (!disabled) {
                    onClick(e);
                }
            }}
            data-tip={dataTip}
            data-for={dataFor}
        >
            {endIcon ? (
                <>
                    {text}
                    {endIcon}
                </>
            ) : (
                <>
                    {icon}
                    {text}
                </>
            )}
        </Item>
    );
};

export { DropdownItemProps };
export default DropdownItem;
