import { createSelector } from 'reselect';
export const getStatistics = state => state.statistics;

export const getIsLoadingStatistics = createSelector([getStatistics], statistics => statistics.isLoadingStatistics);
export const getIsLoadingAllUserInfo = createSelector([getStatistics], statistics => statistics.isLoadingAllUserInfo);
export const getLeaseAgreementCount = createSelector([getStatistics], statistics => statistics.leaseAgreementCount);
export const getUsers = createSelector([getStatistics], statistics => statistics.users);
export const getTotalUsersCount = createSelector([getStatistics], statistics => statistics.totalUsersCount);
export const getUsersPagination = createSelector([getStatistics], statistics => statistics.usersPagination);
export const getCanUserViewStatistics = createSelector([getStatistics], statistics => statistics.canUserViewStatistics);
export const getAgencies = createSelector([getStatistics], statistics => statistics.agencies);
export const getAgenciesPagination = createSelector([getStatistics], statistics => statistics.agenciesPagination);
export const getAgenciesSorting = createSelector([getStatistics], statistics => statistics.agenciesSorting);
export const getUsersSorting = createSelector([getStatistics], statistics => statistics.usersSorting);
export const getAgentReportsData = createSelector([getStatistics], statistics => statistics.agentReportsData);
export const getAgentReportData = createSelector([getStatistics], statistics => statistics.agentReportData);

export const getIsLoadingAgentReports = createSelector([getStatistics], statistics => statistics.isLoadingAgentReports);
export const getIsLoadingAgentReport = createSelector([getStatistics], statistics => statistics.isLoadingAgentReport);
export const getAllUploadADocumentsData = createSelector(
    [getStatistics],
    statistics => statistics.allUploadADocumentsData
);
export const getAllBuildADocumentsData = createSelector(
    [getStatistics],
    statistics => statistics.allBuildADocumentsData
);
export const getIsLoadingAllUploadADocuments = createSelector(
    [getStatistics],
    statistics => statistics.isLoadingAllUploadADocuments
);
export const getIsLoadingAllBuildADocuments = createSelector(
    [getStatistics],
    statistics => statistics.isLoadingAllBuildADocuments
);
