import React, { memo, useState } from 'react';
import { has, find } from 'lodash';
import appHistory from './../../../AppHistory';
import * as dashboard from '../../../actions/dashboard';
import PopupNotificationCount from '../../../components/popups/PopupNotificationCount';
import { Select } from '../../../components/form/Select';
import cx from 'classnames';
import '../../../sass/statusToolbar.scss';

/**
 * Component to show status toolbar. It need to change lease agreement statuses.
 */
const AgreementStatusToolbar = props => {
    const options = [
        { value: dashboard.LEASE_STATUS_DRAFT, label: 'Drafts' },
        { value: dashboard.LEASE_STATUS_AWAITING_COMPLETION, label: 'Awaiting completion' }
    ];
    let [selectedOption, setSelectedOption] = useState(find(options, { value: props.leaseStatus }));

    /**
     * Change status of agreement to filter.
     * @param {string} status - status of agreement.
     * @return {void}
     */
    const changeAgreementStatus = status => {
        appHistory.push(`/dashboard/agreements/${props.leaseType}/${status}`);
    };

    const onChangeDropdown = item => {
        setSelectedOption(item);
        changeAgreementStatus(item.value);
    };

    const getTotalCount = status => {
        let { pagination } = props;
        if (has(pagination, `${status}.totalItemsCount`)) {
            return pagination[status].totalItemsCount;
        }

        return 0;
    };

    const availableStatuses = dashboard.agreementStatuses[props.leaseType];
    return (
        <div className="tb-status">
            <Select
                className="tb-status__dropdown mobile-only"
                classNamePrefix="react-select"
                options={options}
                onChange={item => onChangeDropdown(item)}
                value={selectedOption}
                isSearchable={false}
            />
            <div className="menu-block">
                {availableStatuses.includes(dashboard.LEASE_STATUS_DRAFT) && (
                    <div
                        className={cx('item-menu drafts', {
                            active: props.leaseStatus === dashboard.LEASE_STATUS_DRAFT
                        })}
                        onClick={() => changeAgreementStatus(dashboard.LEASE_STATUS_DRAFT)}
                    >
                        Drafts
                    </div>
                )}
                {availableStatuses.includes(dashboard.LEASE_STATUS_SENT_SIGNING) && (
                    <div
                        className={cx('item-menu new', {
                            active: props.leaseStatus === dashboard.LEASE_STATUS_SENT_SIGNING
                        })}
                        onClick={() => changeAgreementStatus(dashboard.LEASE_STATUS_SENT_SIGNING)}
                    >
                        Sent for signing
                    </div>
                )}
                {availableStatuses.includes(dashboard.LEASE_STATUS_AWAITING_COMPLETION) && (
                    <div
                        className={cx('item-menu completed', {
                            active: props.leaseStatus === dashboard.LEASE_STATUS_AWAITING_COMPLETION
                        })}
                        onClick={() => changeAgreementStatus(dashboard.LEASE_STATUS_AWAITING_COMPLETION)}
                    >
                        Awaiting completion
                        <PopupNotificationCount
                            notificationsCount={getTotalCount(dashboard.LEASE_STATUS_AWAITING_COMPLETION)}
                            showWhenNone={true}
                        />
                    </div>
                )}
                {availableStatuses.includes(dashboard.LEASE_STATUS_AWAITING_RENEWAL) && (
                    <div
                        className={cx('item-menu awaiting', {
                            active: props.leaseStatus === dashboard.LEASE_STATUS_AWAITING_RENEWAL
                        })}
                        onClick={() => changeAgreementStatus(dashboard.LEASE_STATUS_AWAITING_RENEWAL)}
                    >
                        Renewals due
                        <PopupNotificationCount
                            notificationsCount={getTotalCount(dashboard.LEASE_STATUS_AWAITING_RENEWAL)}
                            showWhenNone={true}
                        />
                    </div>
                )}
                {availableStatuses.includes(dashboard.LEASE_STATUS_ARCHIVED) && (
                    <div
                        className={cx('item-menu archived', {
                            active: props.leaseStatus === dashboard.LEASE_STATUS_ARCHIVED
                        })}
                        onClick={() => changeAgreementStatus(dashboard.LEASE_STATUS_ARCHIVED)}
                    >
                        Complete
                    </div>
                )}
                {availableStatuses.includes(dashboard.LEASE_STATUS_CANCELLED) && (
                    <div
                        className={cx('item-menu cancelled', {
                            active: props.leaseStatus === dashboard.LEASE_STATUS_CANCELLED
                        })}
                        onClick={() => changeAgreementStatus(dashboard.LEASE_STATUS_CANCELLED)}
                    >
                        Cancelled
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(AgreementStatusToolbar);
