import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import React from 'react';
import { Redirect, Route } from 'react-router';

import { NZ_SIGNUP } from '../../constants/featureFlags';
import { AgencySignUp, GeneralSignUp } from './SignUp';
import SignUpLanding from './SignUpLanding';
import SignUpV2 from './SignUpV2';
import { SignUpBaseLayout } from './components';

const SignUpRoutes = () => {
    const isNZSignupEnabled = useFeatureFlag(NZ_SIGNUP);

    return (
        <>
            {isNZSignupEnabled === undefined && <SignUpBaseLayout />}
            {isNZSignupEnabled && <SignUpV2 />}
            {isNZSignupEnabled === false && (
                <>
                    <Route exact path="/signup/landing" component={SignUpLanding} />
                    <Route exact path="/signup" component={AgencySignUp} />
                    <Route exact path="/signup/business" component={GeneralSignUp} />
                </>
            )}
            <Route exact path="/signup/general">
                <Redirect to="/signup/business" />
            </Route>
            <Route
                exact
                path="/signup/business/pricing"
                component={({ location }) => (
                    <Redirect
                        to={{
                            ...location,
                            pathname: '/signup/business'
                        }}
                    />
                )}
            />
        </>
    );
};

export default SignUpRoutes;
