import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Button from '../../../common/components/Button.js';
import { PreLoaderSmall } from '../../../common/components/PreLoaderSmall.js';
import { subscriptionStatus, subscriptionTitle } from '../../../config';
import useCountry from '../../../hooks/useCountry';
import { Agency } from '../../../types/Agency.js';
import { TariffPlan } from '../../../types/TariffPlan.js';
import { getPriceDescription } from '../../../utils/countryUtils';
import styles from './SubscriptionButton.module.scss';

type Props = {
    agency: Agency;
    resumeSubscription: () => void;
    resumeSubscriptionIsLoading: boolean;
    tariffPlan: TariffPlan;
    toggleChangePlanModal: (isOpen: boolean) => void;
};

const SubscriptionButton: React.FC<Props> = ({
    agency,
    resumeSubscription,
    resumeSubscriptionIsLoading,
    tariffPlan,
    toggleChangePlanModal
}) => {
    const { country } = useCountry();

    if (agency?.tariffPlan?.title === subscriptionTitle.LIST_NOW) {
        return null;
    }

    if (
        !tariffPlan ||
        tariffPlan.status === subscriptionStatus.PAST_DUE ||
        tariffPlan.status === subscriptionStatus.UNPAID
    ) {
        return null;
    }

    if (tariffPlan.status === subscriptionStatus.PAUSED) {
        if (!agency.stripeDefaultPaymentMethodId && !agency.defaultPaymentMethod) {
            return (
                <Button
                    onClick={() =>
                        confirmAlert({
                            title: '',
                            message: 'Please add a payment method first',
                            buttons: [
                                {
                                    label: 'OK',
                                    onClick: () => {
                                        // do nothing so it will close the popup
                                    }
                                }
                            ]
                        })
                    }
                >
                    Resume subscription
                </Button>
            );
        } else {
            return (
                <Button
                    onClick={resumeSubscription}
                    disabled={resumeSubscriptionIsLoading}
                    className={styles.resumeSubscriptionButton}
                >
                    Resume subscription
                    {resumeSubscriptionIsLoading && <PreLoaderSmall className={styles.loader} />}
                </Button>
            );
        }
    }

    return (
        <div>
            <Button onClick={() => toggleChangePlanModal(true)} primary height={32}>
                {tariffPlan.active ? 'Change plan' : 'Select plan'}
            </Button>

            {tariffPlan.active && <p className={styles.helperText}>{getPriceDescription(country)}</p>}
        </div>
    );
};

export default SubscriptionButton;
