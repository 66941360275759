import axios from 'axios';
import * as ResetPassword from './../reducers/resetPassword';

/**
 * Reset password request.
 * @param {Object} data - try and sent reset password email
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function requestResetPassword(data) {
    return {
        types: [
            ResetPassword.SEND_RESET_PASSWORD_EMAIL_REQUEST,
            ResetPassword.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
            ResetPassword.SEND_RESET_PASSWORD_EMAIL_FAILURE
        ],
        promise: axios.post('/auth/request-reset-password', data)
    };
}

/**
 * Check token status.
 * @param {Object} data - token to check if its expired
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function checkResetToken(token) {
    return {
        types: [
            ResetPassword.CHECK_TOKEN_STATUS_REQUEST,
            ResetPassword.CHECK_TOKEN_STATUS_SUCCESS,
            ResetPassword.CHECK_TOKEN_STATUS_FAILURE
        ],
        promise: axios.get(`/auth/reset-password/${token.resetToken}`)
    };
}

/**
 * Reset password.
 * @param {Object} data - Update database with new password
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function resetPassword(data) {
    return {
        types: [
            ResetPassword.UPDATE_PASSWORD_REQUEST,
            ResetPassword.UPDATE_PASSWORD_SUCCESS,
            ResetPassword.UPDATE_PASSWORD_FAILURE
        ],
        promise: axios.post('/auth/reset-password', data)
    };
}
