import React from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps, useField } from 'react-final-form';
import PhoneInput from 'react-phone-input-2';
import { startsWith } from 'lodash';

import { getFormError } from '../../../../../utils/finalFormUtils';
import Button from '../../../../../common/components/Button.js';
import Icon, { Icons } from '../../../../../common/components/Icon';

import styles from './MobileInput.module.scss';

type MobileInputProps = {
    name: string;
    label: string;
    isRequired?: boolean;
    validateOnTouch?: boolean;
    disabled?: boolean;
    className?: string;
    showClearButton?: boolean;
};

type MobileInputComponentProps = FieldRenderProps<string, HTMLElement> & MobileInputProps;

export const formatPhoneNumber = (formattedValue: string) => {
    let newFormattedValue = formattedValue;
    if (formattedValue.startsWith('+61')) {
        // Don't allow entering 0 as the first character
        if (formattedValue[4] && formattedValue[4] === '0') {
            if (formattedValue.length === 5) {
                newFormattedValue = formattedValue.substr(0, 4);
            } else {
                newFormattedValue = '+61 ' + formattedValue.substr(5);
            }
            // this means they pasted in the international format like: +61411595337
        } else if (formattedValue.startsWith('+61 61')) {
            newFormattedValue = '+61' + formattedValue.substr(6);
        }
        // Don't allow entering more than 11 digits
        if (newFormattedValue.replace(/[\D]/g, '').length > 11) {
            newFormattedValue = newFormattedValue.slice(0, -1);
        }
    } else if (formattedValue.startsWith('+0')) {
        // This means they removed everything and pasted 0411595337;
        newFormattedValue = '+61' + newFormattedValue.substr(2);
    }

    // separate new value in 3 characters groups with space
    if (newFormattedValue.startsWith('+61')) {
        return newFormattedValue
            .replace(/\s/g, '')
            .replace(/(.{3})/g, '$1 ')
            .replace(/(^\s+|\s+$)/, '');
    } else {
        if (formattedValue === '+') {
            return '';
        } else {
            return newFormattedValue;
        }
    }
};

const MobileInputComponent: React.FC<MobileInputComponentProps> = ({
    input,
    meta,
    label,
    isRequired,
    disabled,
    className,
    showClearButton = true,
    validateOnTouch = true
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const error = getFormError(meta, validateOnTouch);

    return (
        <div
            className={cx(styles.container, { [styles.withError]: !!error, [styles.disabled]: disabled })}
            data-has-error={!!error}
        >
            <label className={styles.label} htmlFor={input.name}>
                {label + (isRequired ? '*' : '')}
            </label>
            <div className={cx(styles.inputContainer, className)}>
                <PhoneInput
                    country={'au'}
                    masks={{ au: '... ... ...' }}
                    placeholder="+61 400 000 000"
                    countryCodeEditable={true}
                    disableDropdown={false}
                    disabled={disabled}
                    value={input.value}
                    copyNumbersOnly={true}
                    enableLongNumbers={true}
                    isValid={(inputNumber: string, _c: object, countries: { dialCode?: string }[]) => {
                        return countries.some(country => {
                            return (
                                startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber)
                            );
                        });
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        // this is used to re render final form if user try to enter more 0 as first character
                        if (e.currentTarget.value.startsWith('+61') && e.currentTarget.value.length === 15) {
                            input.onChange(`${e.currentTarget.value} `);
                        }
                    }}
                    onChange={(value, country, e, formattedValue) => {
                        input.onChange(formatPhoneNumber(formattedValue));
                    }}
                    inputClass={styles.input}
                    dropdownClass={styles.dropdown}
                    buttonClass={styles.button}
                    inputProps={{
                        ref: inputRef
                    }}
                />
                {!!input.value && showClearButton && (
                    <Button
                        onClick={() => {
                            input.onChange('');
                            inputRef.current?.focus();
                        }}
                        type="button"
                        className={cx(styles.endButton, styles.clearButton)}
                        disabled={disabled}
                    >
                        <Icon className={styles.clearIcon} icon={Icons.CLOSE} />
                    </Button>
                )}
            </div>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

const MobileInput: React.FC<MobileInputProps> = ({ ...props }) => {
    return <Field component={MobileInputComponent} {...props} />;
};

export { MobileInput };
