import produce from 'immer';

export const OPEN_RETRACT_LEASE_MODAL = 'OPEN_RETRACT_LEASE_MODAL';
export const CLOSE_RETRACT_LEASE_MODAL = 'CLOSE_RETRACT_LEASE_MODAL';

export const OPEN_CANCEL_FUTURE_ALERTS_MODAL = 'OPEN_CANCEL_FUTURE_ALERTS_MODAL';
export const CLOSE_CANCEL_FUTURE_ALERTS_MODAL = 'CLOSE_CANCEL_FUTURE_ALERTS_MODAL';

export const CLOSE_NO_LICENSEE_NUMBER_MODAL = 'CLOSE_NO_LICENSEE_NUMBER_MODAL';
export const OPEN_NO_LICENSEE_NUMBER_MODAL = 'OPEN_NO_LICENSEE_NUMBER_MODAL';

const initState = {
    lease: null,
    isOpenRetractLeaseConfirmModal: false,
    isOpenCancelFutureAlertsConfirmModal: false,
    isNoLicenseeNumberModalOpen: false
};

export default function confirmModals(state = initState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case OPEN_RETRACT_LEASE_MODAL: {
            return produce(state, draftState => {
                draftState.lease = action.lease;
                draftState.isOpenRetractLeaseConfirmModal = true;
            });
        }
        case CLOSE_RETRACT_LEASE_MODAL: {
            return produce(state, draftState => {
                draftState.lease = null;
                draftState.isOpenRetractLeaseConfirmModal = false;
            });
        }
        case OPEN_CANCEL_FUTURE_ALERTS_MODAL: {
            return produce(state, draftState => {
                draftState.lease = action.lease;
                draftState.isOpenCancelFutureAlertsConfirmModal = true;
            });
        }
        case CLOSE_CANCEL_FUTURE_ALERTS_MODAL: {
            return produce(state, draftState => {
                draftState.lease = null;
                draftState.isOpenCancelFutureAlertsConfirmModal = false;
            });
        }
        case OPEN_NO_LICENSEE_NUMBER_MODAL: {
            return produce(state, draftState => {
                draftState.isNoLicenseeNumberModalOpen = true;
            });
        }
        case CLOSE_NO_LICENSEE_NUMBER_MODAL: {
            return produce(state, draftState => {
                draftState.isNoLicenseeNumberModalOpen = false;
            });
        }
    }
    return newState;
}
