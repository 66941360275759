import * as React from 'react';
import Button from '../../common/components/Button';
import { getTotalStepsCount } from '../../utils/agreementUtils';

import '../../sass/footerLease.scss';
import { confirmAlert } from 'react-confirm-alert';
import * as lease from '../../actions/lease';
import { isMobileWidth } from '../../config';

class FooterLease extends React.Component {
    constructor(props) {
        super(props);

        this.handleDoneOnclick = this.handleDoneOnclick.bind(this);
    }

    handleDoneOnclick() {
        const { canFinishLease, nextStep } = this.props;
        if (false === canFinishLease()) {
            confirmAlert({
                title: '',
                message:
                    'Your document is missing some data, please look at the left hand navigation menu for the title missing the green tick, either fill in the missing data or go to that page and click "next"',
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            });
        } else {
            nextStep();
        }
    }
    setContinue = () => {
        this.props.nextStep();
    };

    render() {
        const {
            className,
            isServerBusy,
            nextStep,
            goBack,
            finishEditing,
            isEdit,
            step,
            isSubmitButtonDisabled,
            isSubmittingLease,
            leaseType,
            location,
            mobileViewEnabled,
            subLeaseType
        } = this.props;
        let totalStepsCount = getTotalStepsCount(leaseType, subLeaseType, location);

        return (
            <footer className={className}>
                <div className="footer-lease">
                    <div className={'button button-back ' + (!isEdit && step !== 2 ? '' : 'visibility-hidden')}>
                        <button onClick={goBack}>Back</button>
                    </div>
                    {!isEdit && step !== totalStepsCount && (
                        <div className={isServerBusy ? 'btn-spinner' : 'button'}>
                            {step !== totalStepsCount && (
                                <button
                                    className={isServerBusy ? 'button-busy' : 'button-ready'}
                                    onClick={nextStep}
                                    disabled={isServerBusy || isSubmitButtonDisabled}
                                >
                                    {isServerBusy ? <span className="savestatus-spinner-white" /> : null}
                                    {isServerBusy ? 'Saving' : 'Next'}
                                </button>
                            )}
                        </div>
                    )}
                    {!isEdit && step === totalStepsCount && (
                        <div className="button">
                            <Button
                                width={108}
                                primary
                                onClick={() => {
                                    if (mobileViewEnabled && isMobileWidth()) {
                                        this.setContinue();
                                    } else {
                                        this.handleDoneOnclick();
                                    }
                                }}
                                loading={isSubmittingLease}
                            >
                                {mobileViewEnabled && isMobileWidth() ? 'Continue' : 'Done'}
                            </Button>
                        </div>
                    )}
                    {isEdit && (
                        <div className="button button-finish-editing">
                            <Button onClick={finishEditing} loading={isSubmittingLease} primary>
                                Finish editing
                            </Button>
                        </div>
                    )}
                </div>
            </footer>
        );
    }
}

export default FooterLease;
