import React, { Component } from 'react';
import TextRow from './TextRow';
import ContactTittle from './ContactTittle';

/**
 * Component represent contact string. String may be only title or text or both.
 * @props {string} title
 * @props {string} text
 */
export default class DataString extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, text } = this.props;
        let content;
        if (title && text) {
            content = (
                <span>
                    <ContactTittle title={title} />
                    <TextRow text={text} />
                </span>
            );
        } else if (title) {
            content = <ContactTittle title={title} />;
        } else {
            content = <TextRow text={text} />;
        }

        return <div className="data-string">{content}</div>;
    }
}
