import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { has } from 'lodash';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmServicesSchedule } from '../../../../selectors/lease/pmLease';
import ServicesScheduleItems from './services-schedule-items.json';
import '../../../../sass/servicesSchedule.scss';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';

const PM_SERVICES_SCHEDULE_FORM = 'pmServicesScheduleForm';

const LEASING_KEY = 'LEASING';
const PROPERTY_MANAGEMENT_KEY = 'PROPERTY_MANAGEMENT';
const ACCOUNTING_KEY = 'ACCOUNTING';

const LEASING_ITEMS = ServicesScheduleItems[LEASING_KEY];
const PROPERTY_MANAGEMENT_ITEMS = ServicesScheduleItems[PROPERTY_MANAGEMENT_KEY];
const ACCOUNTING_ITEMS = ServicesScheduleItems[ACCOUNTING_KEY];

const initState = {
    leasingItems: [],
    propertyManagementItems: [],
    accountingItems: []
};

function ServicesSchedule(props, ref) {
    const dispatch = useDispatch();
    const reduxPmServicesSchedule = useSelector(getPmServicesSchedule);
    const step = useSelector(getStep);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const dirtyStep = useSelector(getDirtyStep);
    const [formattedInitialValues, setFormattedInitialValues] = useState(initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_SERVICES_SCHEDULE_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    useEffect(() => {
        if (reduxPmServicesSchedule) {
            const {
                leasingItems: reduxLeasingItems,
                propertyManagementItems: reduxPropertyManagementItems,
                accountingItems: reduxAccountingItems
            } = reduxPmServicesSchedule;
            let items = [];
            let formattedObject = {};
            if (Array.isArray(reduxLeasingItems)) {
                const enabledItems = reduxLeasingItems.filter(i => i.enabled === true);
                items = items.concat(enabledItems);
                formattedObject.yesToAllLeasing = enabledItems.length === LEASING_ITEMS.length;
            }
            if (Array.isArray(reduxPropertyManagementItems)) {
                const enabledItems = reduxPropertyManagementItems.filter(i => i.enabled === true);
                items = items.concat(enabledItems);
                formattedObject.yesToAllPropertyManagement = enabledItems.length === PROPERTY_MANAGEMENT_ITEMS.length;
            }
            if (Array.isArray(reduxAccountingItems)) {
                const enabledItems = reduxAccountingItems.filter(i => i.enabled === true);
                items = items.concat(enabledItems);
                formattedObject.yesToAllAccounting = enabledItems.length === ACCOUNTING_ITEMS.length;
            }
            formattedObject = {
                ...formattedObject,
                ...items.reduce((p, c) => ({ ...p, [c.name]: c.enabled }), {})
            };

            const { yesToAllLeasing, yesToAllPropertyManagement, yesToAllAccounting } = formattedObject;
            formattedObject.yesToAll = yesToAllLeasing && yesToAllPropertyManagement && yesToAllAccounting;

            setFormattedInitialValues(formattedObject);
        }
    }, [reduxPmServicesSchedule]);

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;

        const leasingItems = LEASING_ITEMS.map(i => {
            const value = values[i.name];
            i.enabled = value && value === true;
            return i;
        });

        const propertyManagementItems = PROPERTY_MANAGEMENT_ITEMS.map(i => {
            const value = values[i.name];
            i.enabled = value && value === true;
            return i;
        });
        const accountingItems = ACCOUNTING_ITEMS.map(i => {
            i.enabled = values[i.name] === true;
            return i;
        });

        const payload = {
            leasingItems,
            propertyManagementItems,
            accountingItems
        };

        return axios
            .post(`/api/agency/lease/${props.leaseId}/pm-services-schedule`, payload)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmServicesSchedule')) {
                    return error.response.data.errors.pmServicesSchedule;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="form-container services-schedule">
            <Form onSubmit={submitForm} initialValues={formattedInitialValues}>
                {({ handleSubmit, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_SERVICES_SCHEDULE_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <b>Services Schedule</b>
                            <p className="text">
                                The Client acknowledges and agrees that the following services are provided by the Agent
                                in accordance with this Authority. The Client acknowledges receiving and reviewing the
                                services prior to or at the time of signing this Authority.
                            </p>
                            <p className="text">
                                Some of the services provided for below may incur additional fees in accordance with
                                this Authority.
                            </p>
                            <p className="text">
                                The Agent may provide the following services, in accordance with any instructions
                                received:
                            </p>
                            <p className="text">
                                <b>
                                    <i>* Any box unticked will default to NO </i>
                                </b>
                            </p>
                            <div className="checkbox-group">
                                <CheckboxCheck
                                    name="yesToAll"
                                    label="Yes to All"
                                    className={'button-all'}
                                    onClick={value => handleYesToAll(form, value)}
                                />
                                <CheckboxCheck
                                    name="yesToAllLeasing"
                                    label='Yes to All "Leasing"'
                                    onClick={value => handleYesToAllLeasing(form, value)}
                                />
                                <CheckboxCheck
                                    name="yesToAllPropertyManagement"
                                    label='Yes to All "Property Management"'
                                    onClick={value => handleYesToAllPropertyManagement(form, value)}
                                />
                                <CheckboxCheck
                                    name="yesToAllAccounting"
                                    label='Yes to All "Accounting"'
                                    onClick={value => handleYesToAllAccounting(form, value)}
                                />
                            </div>
                            <div className="tab-group">
                                <Tabs>
                                    <TabList>
                                        <Tab>
                                            <span>Leasing</span>
                                        </Tab>
                                        <Tab>
                                            <span>Property Management</span>
                                        </Tab>
                                        <Tab>
                                            <span>Accounting</span>
                                        </Tab>
                                    </TabList>
                                    <TabPanel>{renderSection(LEASING_ITEMS)}</TabPanel>
                                    <TabPanel>{renderSection(PROPERTY_MANAGEMENT_ITEMS)}</TabPanel>
                                    <TabPanel>{renderSection(ACCOUNTING_ITEMS)}</TabPanel>
                                </Tabs>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );

    function renderSection(items) {
        return (
            <div className="items-container">
                {items.map((item, index) => {
                    const { text, name } = item;
                    return (
                        <div className="item" key={index}>
                            <CheckboxCheck
                                topAlignInput
                                labelClassName="item__checkbox__label"
                                name={name}
                                label={text}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    function handleYesToAll(form, value) {
        const allItems = [...LEASING_ITEMS, ...PROPERTY_MANAGEMENT_ITEMS, ...ACCOUNTING_ITEMS]
            .map(i => i.name)
            .concat(['yesToAllLeasing', 'yesToAllPropertyManagement', 'yesToAllAccounting']);
        form.batch(() => {
            allItems.forEach(item => {
                form.change(item, value);
            });
        });
    }

    function handleYesToAllLeasing(form, value) {
        form.batch(() => {
            LEASING_ITEMS.forEach(item => {
                form.change(item.name, value);
            });
        });
    }

    function handleYesToAllPropertyManagement(form, value) {
        form.batch(() => {
            PROPERTY_MANAGEMENT_ITEMS.forEach(item => {
                form.change(item.name, value);
            });
        });
    }

    function handleYesToAllAccounting(form, value) {
        form.batch(() => {
            ACCOUNTING_ITEMS.forEach(item => {
                form.change(item.name, value);
            });
        });
    }
}

export default memo(forwardRef(ServicesSchedule));
