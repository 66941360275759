import React, { useState } from 'react';
import cx from 'classnames';
import { Form } from 'react-final-form';
import Button from '../../common/components/Button';
import Card, { CardShadow, CardStyles } from '../../common/components/cards/Card';
import { FormEmail } from '../../components/form/FormEmail';
import { FormPassword } from '../../components/form/FormPassword';
import { FormPhone } from '../form/FormPhone';
import { SelectField } from '../../components/form/FormSelect';
import { FormTextRegular } from '../../components/form/FormText';
import { getAvailableStates, SIGN_UP_FORM_STEPS_COUNT } from '../../config';
import { isAgencyUserAccount } from '../../utils/userUtils.js';
import * as validators from '../Validate';
import styles from './SignUpForms.module.scss';
import axios from 'axios';
import { EmailValidation } from '../../types/User';
import { AccountType, AvailableCountries } from '../../constants/constants';

type SignUpFormContainerProps = React.PropsWithChildren<{
    onBack?: () => void;
    currentStep: number;
    hideBackButton?: boolean;
    className?: string;
    contentClassName?: string;
}>;

const SignUpFormContainer: React.FC<SignUpFormContainerProps> = ({
    onBack,
    currentStep,
    className,
    contentClassName,
    hideBackButton,
    children
}) => {
    return (
        <Card
            className={cx(styles.formContainer, className)}
            shadow={CardShadow.NONE}
            style={CardStyles.SQUARE}
            animate={false}
        >
            {!hideBackButton && onBack && (
                <Button onClick={onBack} className={styles.backButton}>
                    Back
                </Button>
            )}

            <div className={styles.stepCounter}>
                {currentStep} of {SIGN_UP_FORM_STEPS_COUNT}
            </div>
            <div className={cx(styles.formContent, contentClassName)}>{children}</div>
        </Card>
    );
};

type SignUpFormProps<FormValues> = {
    onSubmit: (values: FormValues) => void;
    initialValues?: Partial<FormValues>;
    isPending?: boolean;
    accountType?: AccountType;
    setEmailValidationResults?: React.Dispatch<React.SetStateAction<EmailValidation | undefined>>;
};

// Ideally this would refer to the same type as the backend
type CreateAccountFormValues = {
    firstName: string;
    secondName: string;
    email: string;
    phone: string;
    password: string;
    repeatPassword: string;
};

export type CreateAccountFormProps = SignUpFormProps<CreateAccountFormValues>;

type EmailValidationResults = {
    status: number;
    data: {
        score: EmailValidation;
    };
};

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
    onSubmit,
    initialValues,
    isPending,
    accountType,
    setEmailValidationResults
}) => {
    // Final form wasn't allowing enough control to limit calls to when the field is blurred and a change has been made
    // We need to manage this state seperatly
    // Error message is stored in seperate state as validation is done in async function and the sync validation would overwrite the erorr values whenever it re-runs
    // the validation
    const [currentEmail, setCurrentEmail] = useState<string>();
    const [currentEmailError, setCurrentEmailError] = useState<string>();

    const validateEmail = async (value: string) => {
        if (!value) return 'Email is required';
        if (value && currentEmail !== value) {
            setCurrentEmail(value);
            const response: EmailValidationResults = await axios.post('/auth/validate-email', {
                email: value,
                accountType
            });
            // if the request failed we don't want to display any errors
            if (response && response.status === 200) {
                // if for whatever reason reason the results are not present
                const validationResult = response.data.score;
                if (!validationResult) {
                    return;
                }
                let error;
                if (validationResult.emailStatus === 'Invalid') {
                    error = 'Invalid Email Address';
                    // Api retuns a suggested email address in some cases
                    if (validationResult.suggestion) {
                        error += `, Did you mean ${validationResult.suggestion}?`;
                    }
                }
                setCurrentEmailError(error);
                setEmailValidationResults?.(validationResult);
            }
        }
    };
    const validate = (values: CreateAccountFormValues) => {
        const errors: Partial<CreateAccountFormValues> = {};
        if (!values.firstName) {
            errors.firstName = 'First name is required';
        } else if (!validators.isCorrectNameOrSurname(values.firstName)) {
            errors.firstName = 'First name is not valid';
        }

        if (!values.secondName) {
            errors.secondName = 'Last name is required';
        } else if (!validators.isCorrectNameOrSurname(values.secondName)) {
            errors.secondName = 'Last name is not valid';
        }

        errors.email = currentEmailError;

        if (!values.phone && isAgencyUserAccount(accountType)) {
            errors.phone = 'Phone is required';
        }

        if (!values.password) {
            errors.password = 'Password is required';
        }

        if (!values.repeatPassword) {
            errors.repeatPassword = 'Confirm password is required';
        }
        if (values.password && values.repeatPassword && values.password !== values.repeatPassword) {
            errors.repeatPassword = 'Password and confirm password do not match';
        }
        return errors;
    };

    const isAgency = accountType !== AccountType.GENERAL_ACCOUNT_TYPE;

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate} validateOnBlur>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.signUpForm} noValidate>
                    <FormTextRegular
                        containerClassName={styles.formField}
                        name="firstName"
                        label="First name"
                        required
                        validateOnTouch
                    />
                    <FormTextRegular
                        containerClassName={styles.formField}
                        name="secondName"
                        label="Last name"
                        required
                        validateOnTouch
                    />
                    <FormEmail
                        containerClassName={styles.formField}
                        name="email"
                        label={isAgency ? 'Work email' : 'Email'}
                        required
                        validateOnTouch
                        validate={validateEmail}
                    />
                    <FormPhone
                        containerClassName={styles.formField}
                        name="phone"
                        label={isAgency ? 'Work mobile' : 'Mobile'}
                        required={isAgencyUserAccount(accountType)}
                        validateOnTouch
                    />
                    <FormPassword
                        containerClassName={styles.formField}
                        name="password"
                        label="Choose password"
                        validateOnTouch
                        required
                    />
                    <FormPassword
                        containerClassName={styles.formField}
                        name="repeatPassword"
                        label="Re-enter password"
                        validateOnTouch
                        required
                    />
                    <Button type="submit" primary className={styles.formButton} loading={isPending}>
                        Next
                    </Button>
                </form>
            )}
        </Form>
    );
};

// Ideally this would refer to the same type as the backend
type CreateAgencyDetailsFormValues = {
    agencyName: string;
    companyName: string;
    email: string;
    address: string;
    phone: string;
    location: string;
};

type CreateAgencyDetailsFormProps = SignUpFormProps<CreateAgencyDetailsFormValues>;

const CreateAgencyDetailsForm: React.FC<CreateAgencyDetailsFormProps> = ({ onSubmit, initialValues, isPending }) => {
    const validate = (values: CreateAgencyDetailsFormValues): Partial<CreateAgencyDetailsFormValues> => {
        const errors: Partial<CreateAgencyDetailsFormValues> = {};
        if (!values.agencyName) {
            errors.agencyName = 'Agency name is required';
        }
        if (!values.companyName) {
            errors.companyName = 'Company name is required';
        }
        if (!values.email) {
            errors.email = 'Agency email is required';
        }
        if (!values.address) {
            errors.address = 'Agency address is required';
        }
        if (!values.phone) {
            errors.phone = 'Agency phone number is required';
        }
        if (!values.location) {
            errors.location = 'State is required';
        }
        return errors;
    };

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.signUpForm} noValidate>
                    <FormTextRegular
                        containerClassName={styles.formField}
                        name="agencyName"
                        label="Agency name"
                        required
                        validateOnTouch
                    />
                    <FormTextRegular
                        containerClassName={styles.formField}
                        name="companyName"
                        label="Company name"
                        required
                        validateOnTouch
                    />
                    <FormEmail
                        containerClassName={styles.formField}
                        name="email"
                        label="Agency email"
                        required
                        validateOnTouch
                    />
                    <FormTextRegular
                        containerClassName={styles.formField}
                        name="address"
                        label="Agency address"
                        required
                        validateOnTouch
                    />
                    <FormPhone
                        containerClassName={styles.formField}
                        name="phone"
                        label="Agency phone number"
                        required
                        validateOnTouch
                    />
                    <SelectField
                        containerClassName={styles.formField}
                        ariaLabel="state"
                        label="State"
                        placeholder="Select state"
                        name="location"
                        options={getAvailableStates().map(state => ({
                            value: state,
                            label: state
                        }))}
                        required
                        validateOnTouch
                    />
                    <div className={styles.terms}>
                        By clicking done you agree to our{' '}
                        <a
                            href="https://www.flkitover.com/terms-conditions/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={styles.link}
                        >
                            terms of service
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://www.flkitover.com/privacy-policy/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={styles.link}
                        >
                            privacy policy
                        </a>
                    </div>
                    <Button type="submit" primary className={styles.formButton} loading={isPending}>
                        Done
                    </Button>
                </form>
            )}
        </Form>
    );
};

// Ideally this would refer to the same type as the backend
type CreateGeneralDetailsFormValues = {
    companyName: string;
    email: string;
    address: string;
    phone: string;
    country: string;
};

type CreateGeneralDetailsFormProps = SignUpFormProps<CreateGeneralDetailsFormValues>;

const CreateGeneralDetailsForm: React.FC<CreateGeneralDetailsFormProps> = ({ onSubmit, initialValues, isPending }) => {
    // this is a set value for general for now as we're only allowing it in Australia
    const countryOptions = [
        {
            value: AvailableCountries.AU,
            label: 'Australia',
            disabled: false
        },
        {
            value: AvailableCountries.NZ,
            label: 'New Zealand',
            disabled: false
        }
    ];

    const validate = (values: CreateGeneralDetailsFormValues) => {
        const errors: Partial<CreateGeneralDetailsFormValues> = {};
        if (!values.companyName) {
            errors.companyName = 'Account / company name is required';
        }
        if (!values.email) {
            errors.email = 'Company Email is required';
        }
        return errors;
    };

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={styles.signUpForm} noValidate>
                    <FormTextRegular
                        containerClassName={styles.formField}
                        name="companyName"
                        label="Account / company name"
                        required
                        validateOnTouch
                    />
                    <FormEmail
                        containerClassName={styles.formField}
                        name="email"
                        label="Company email"
                        required
                        validateOnTouch
                    />
                    <FormTextRegular
                        containerClassName={styles.formField}
                        name="address"
                        label="Company address"
                        validateOnTouch
                    />
                    <FormPhone
                        containerClassName={styles.formField}
                        name="phone"
                        label="Company phone number"
                        validateOnTouch
                    />
                    <SelectField
                        containerClassName={styles.formField}
                        ariaLabel="country"
                        label="Default country"
                        placeholder="Select country"
                        name="country"
                        disabled
                        options={countryOptions}
                        required
                        validateOnTouch
                    />
                    <div className={styles.terms}>
                        By clicking done you agree to our{' '}
                        <a
                            href="https://www.flkitover.com/terms-conditions/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={styles.link}
                        >
                            terms of service
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://www.flkitover.com/privacy-policy/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={styles.link}
                        >
                            privacy policy
                        </a>
                    </div>
                    <Button type="submit" primary className={styles.formButton} loading={isPending}>
                        Done
                    </Button>
                </form>
            )}
        </Form>
    );
};
export {
    SignUpFormContainer,
    CreateAccountFormValues,
    CreateAccountForm,
    CreateAgencyDetailsFormValues,
    CreateAgencyDetailsForm,
    CreateGeneralDetailsFormValues,
    CreateGeneralDetailsForm
};
