import React, { memo } from 'react';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { FormTextRegular } from '../../../../components/form/FormText';
import { FormNumber, FormTextCurrency, FormTextMultiline } from '../../../../components/form/FormText';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import {
    COMMISSION_TYPE_FIXED,
    COMMISSION_TYPE_PERCENTAGE,
    SALE_METHOD_AUCTION,
    SALE_METHOD_PRIVATE_TREATY
} from '../../../../config';

const saleMethodOptions = [
    { value: SALE_METHOD_PRIVATE_TREATY, label: 'Private Treaty' },
    { value: SALE_METHOD_AUCTION, label: 'Auction' }
];
const commissionTypeOptions = [
    { value: COMMISSION_TYPE_FIXED, label: 'Fixed commission ' },
    { value: COMMISSION_TYPE_PERCENTAGE, label: 'Commission percentage ' }
];

function PriceAndFee({ values, handleRangeChange, opinionRangeFail }) {
    return (
        <>
            <div className="text">
                <p>Agent's opinion to selling price</p>
            </div>
            <div className="form-checkbox">
                <CheckboxCheck label="Range" name="isRange" onClick={() => handleRangeChange()} />
            </div>
            {values.isRange && (
                <div className="form-half-col">
                    <div>
                        <FormTextCurrency name="opinionPriceFrom" label="From $ " required precision="0" />
                    </div>
                    <div className="custom-error-block">
                        <FormTextCurrency name="opinionPriceTo" label="To $ " required precision="0" />
                        {opinionRangeFail && <div className="has-error custom-error-message">{opinionRangeFail}</div>}
                    </div>
                </div>
            )}
            {!values.isRange && <FormTextCurrency name="exactPrice" label={'Price'} required precision="0" />}
            <FormRadioGroup label="Recommended method of sale" name={'saleMethod'} data={saleMethodOptions} />
            {values.saleMethod && (
                <FormTextRegular
                    name="startingPrice"
                    label={
                        values.saleMethod === SALE_METHOD_PRIVATE_TREATY
                            ? 'The property is to be offered at'
                            : 'The reserve price will be'
                    }
                    required
                />
            )}
            {values.saleMethod === SALE_METHOD_AUCTION && (
                <div>
                    <div className="text">
                        <p>Auction Date</p>
                    </div>
                    <CheckboxCheck label="To be confirmed" name="auctionDateToBeConfirmed" />
                    {!values.auctionDateToBeConfirmed && (
                        <DatePickerInAgencyTimeZone
                            label="Auction Date"
                            key={1}
                            name="auctionDate"
                            selected={values.auctionDate}
                            required
                        />
                    )}
                </div>
            )}
            <FormRadioGroup
                label="Commission (including GST)"
                name={'commissionType'}
                data={commissionTypeOptions}
                required
            />
            {values.commissionType === COMMISSION_TYPE_FIXED && (
                <FormTextCurrency name="commissionFee" label="Commission fee" required precision="2" />
            )}
            {values.commissionType === COMMISSION_TYPE_PERCENTAGE && (
                <React.Fragment>
                    <FormNumber name="commissionPercent" label="Commission Percentage Including GST" required />
                    <p className="help-text">Estimate of commission </p>
                    {values.isRange && (
                        <div className="form-half-col">
                            <div>
                                <FormTextCurrency name="commissionFrom" label="From $" disabled precision="2" />
                            </div>
                            <div>
                                <FormTextCurrency name="commissionTo" label="To $" disabled precision="2" />
                            </div>
                        </div>
                    )}
                    {!values.isRange && (
                        <div className="form-half-col">
                            <div>
                                <FormTextCurrency name="commissionFrom" label="Commission $" disabled precision="2" />
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
            <FormTextMultiline name="commissionComment" label="Commission notes (optional)" />
        </>
    );
}

export default memo(PriceAndFee);
