import React from 'react';
import { FormTextRegular } from '../form/FormText';
import { FormEmail } from '../form/FormEmail';
import { FormPhone } from '../form/FormPhone';

const RentReductionTenantPersonGroup = ({ index, removeTenant, disabled }) => {
    let header;
    if (index === 0) {
        header = <h3>Tenant {index + 1} (only tenant 1 will receive the agreement for signing)</h3>;
    } else {
        header = (
            <span className="removePerson">
                <h3>Tenant {index + 1}</h3>
                <button type="button" onClick={() => removeTenant(index)} disabled={disabled}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="tenant">
            {header}
            <div className="formBox">
                <div className="formBox-column">
                    <FormTextRegular
                        name={`tenants[${index}].fullName`}
                        label="Full Name"
                        required
                        disabled={disabled}
                    />
                    {index === 0 && (
                        <div>
                            <FormEmail name={`tenants[${index}].email`} label="Email" required disabled={disabled} />
                            <FormPhone
                                name={`tenants[${index}].phone`}
                                label="Mobile"
                                dataTest={`tenants-${index}-phone`}
                                required
                                disabled={disabled}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RentReductionTenantPersonGroup;
