import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';

type ListenProps = {
    to: string | string[];
    activeOnly?: boolean;
    onChange: (value?: unknown, name?: string) => void;
};

type FieldListenerProps = {
    value: any;
    name: string;
    onChange: (value: unknown, name: string) => void;
    active?: boolean;
};

const FieldListener: React.FC<FieldListenerProps> = ({ value, onChange, active, name }) => {
    const [previous, setPrevious] = useState(value);

    useEffect(() => {
        if (value !== previous) {
            setPrevious(value);

            if (active) {
                onChange(value, name);
            }
        }
    }, [value, previous, onChange, active, name]);

    return null;
};

const Listen: React.FC<ListenProps> = ({ to, activeOnly = true, onChange }) => {
    if (!Array.isArray(to)) {
        to = [to];
    }

    return (
        <>
            {to.map(name => (
                <Field key={name} name={name} subscription={{ value: true, active: true }}>
                    {({ input: { value }, meta }) => (
                        <FieldListener
                            name={name}
                            value={value}
                            active={meta.active || !activeOnly}
                            onChange={onChange}
                        />
                    )}
                </Field>
            ))}
        </>
    );
};

export default Listen;
