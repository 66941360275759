import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../sass/flkSelectBox.scss';

class FlkSelectBox extends Component {
    static propTypes = {
        noOfBoxes: PropTypes.number,
        firstBox: PropTypes.string,
        title: PropTypes.string,
        setValue: PropTypes.func,
        selected: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            selectableBoxes: []
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            selectableBoxes: this.renderBoxes(nextProps.selected)
        });
    }

    componentDidMount() {
        this.setState({
            selectableBoxes: this.renderBoxes(this.props.selected)
        });
    }

    renderBoxes = selectedBox => {
        const boxes = [];
        if (this.props.firstBox) {
            boxes.push(this.getBoxHtml(selectedBox, this.props.firstBox));
        }

        for (let i = 1; i <= this.props.noOfBoxes; i++) {
            boxes.push(this.getBoxHtml(selectedBox, i));
        }
        return boxes;
    };

    getBoxHtml(boxSelectable, i) {
        return (
            <div
                key={`${i}`}
                className={boxSelectable === `${i}` ? 'select-box-selected' : 'select-box'}
                onClick={() => this.setSelectedBox(`${i}`)}
            >
                <div className={boxSelectable === i ? 'select-box-content-selected' : 'select-box-content'}>
                    {this.props.noOfBoxes === i ? `${i}+` : `${i}`}
                </div>
            </div>
        );
    }

    setSelectedBox(box) {
        this.props.setValue(box);
    }
    render() {
        return (
            <div className={'flk-select-box'}>
                <div className={'select-boxes-title'}>{this.props.title}</div>
                <div className={'select-boxes'}>{this.state.selectableBoxes}</div>
            </div>
        );
    }
}

export default FlkSelectBox;
