import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import * as lease from '../../../actions/lease';
import CreateNewLease from '../../../components/lease/CreateNewLease';
import '../../../sass/createLease.scss';

class CloneResTen extends React.Component {
    constructor(props) {
        super(props);
    }

    createLease(data) {
        this.props.createLease(data);
    }

    render() {
        const { closeLeaseAgreementForm, isOpenCloneResTen, leaseType, cloneLeaseId } = this.props;
        return (
            <Modal
                parentSelector={() => document.getElementById('main-layout')}
                ariaHideApp={false}
                isOpen={isOpenCloneResTen}
                className="modal-create-lease-clone"
                contentLabel="Modal"
            >
                {isOpenCloneResTen && (
                    <div className="create-lease" ref={node => (this.create = node)}>
                        <CreateNewLease
                            cloneLeaseId={cloneLeaseId}
                            leaseType={leaseType}
                            createDocument={this.createLease.bind(this)}
                            closeLeaseAgreementForm={closeLeaseAgreementForm}
                        />
                    </div>
                )}
            </Modal>
        );
    }
}

export default connect(
    state => ({
        isOpenCloneResTen: state.lease.common.isOpenCloneResTen,
        leaseType: state.lease.common.leaseType,
        cloneLeaseId: state.lease.common.cloneLeaseId
    }),
    {
        createLease: lease.createLease,
        closeLeaseAgreementForm: lease.closeLeaseAgreementForm
    }
)(CloneResTen);
