import React from 'react';
import styles from './ContactUs.module.scss';
import Button from '../common/components/Button';
import { useIntercom } from 'react-use-intercom';
import { isAgencyUserAccount, isBusinessUserAccount } from '../utils/userUtils';
import { useAccountProvider } from './providers/AccountProvider';
import { ReactComponent as IntercomIcon } from '../../assets/images/icons/intercom.svg';

export default function ContactUs(): JSX.Element {
    const { show } = useIntercom();
    const accountType = useAccountProvider();

    function talkToCustomerService() {
        if (isAgencyUserAccount(accountType)) {
            show();
        } else if (isBusinessUserAccount(accountType)) {
            window.location.href = `mailto:support@flkitover.com?subject=FLK Business: Customer support issue`;
        } else {
            window.location.href = `mailto:support@flkitover.com?subject=Customer support issue`;
        }
    }

    return (
        <div className={styles.contactUs}>
            <IntercomIcon className={styles.intercomImage} />
            <p className={styles.description}>Contact customer service</p>
            <p className={styles.helpText}>
                {isAgencyUserAccount(accountType) && (
                    <>
                        Having trouble? Contact our support team on{' '}
                        <a
                            href="tel:0481611212"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            0481 611 212
                        </a>{' '}
                        or send your request to{' '}
                        <a
                            href="mailto:support@flkitover.com?subject=FLK Real Estate: Customer support issue"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            support@flkitover.com
                        </a>
                    </>
                )}
                {isBusinessUserAccount(accountType) && (
                    <>
                        Having trouble? Send your request to{' '}
                        <a
                            href="mailto:support@flkitover.com?subject=FLK Business: Customer support issue"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            support@flkitover.com
                        </a>
                    </>
                )}
                {!accountType && (
                    <>
                        Having trouble? Send your request to{' '}
                        <a href="mailto:support@flkitover.com?subject=Customer support issue" target="_blank" rel="noopener noreferrer">
                            support@flkitover.com
                        </a>
                    </>
                )}
            </p>
            <Button type="button" primary className={styles.button} onClick={talkToCustomerService}>
                Talk to customer service
            </Button>
        </div>
    );
}
