import * as Switch from '@radix-ui/react-switch';
import cx from 'classnames';
import * as React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

import styles from './ToggleButtonV2.module.scss';
import Tooltip from './tooltips/Tooltip';
import useComponentId from '@app/hooks/useComponentId';

export enum LabelPosition {
    Start = 'start',
    End = 'end'
}

interface ToggleButtonCommonProps {
    className?: string;
    labelClassName?: string;
    label: string;
    description?: string;
    disabled?: boolean;
    switchClassName?: string;
    iconClassName?: string;
    dataId?: string;
    switchTooltip?: string;
}

type FormToggleButtonProps = {
    name: string;
    labelPosition?: LabelPosition;
} & ToggleButtonCommonProps;

type FormToggleButtonComponentProps = FieldRenderProps<boolean, HTMLElement> & FormToggleButtonProps;

const isControlledToggleButtonProps = (
    props: ControlledToggleButtonProps | FormToggleButtonProps
): props is ControlledToggleButtonProps => {
    return (
        (props as ControlledToggleButtonProps).onClick !== undefined &&
        (props as ControlledToggleButtonProps).checked !== undefined
    );
};

const FormToggleButton: React.FC<FormToggleButtonComponentProps> = ({
    className,
    switchClassName,
    iconClassName,
    label,
    description,
    name,
    labelClassName = '',
    disabled,
    labelPosition = LabelPosition.Start,
    input,
    dataId,
    switchTooltip
}) => {
    const id = useComponentId();
    return (
        <div className={cx(styles.container, className)}>
            {labelPosition === LabelPosition.Start && (
                <label className={styles.labelContainer} htmlFor={name}>
                    <p className={labelClassName}>{label}</p>
                    {description && <p className={styles.description}>{description}</p>}
                </label>
            )}

            <Switch.Root
                disabled={disabled}
                className={cx(styles.SwitchRoot, switchClassName)}
                id={name}
                name={name}
                onCheckedChange={val => input.onChange(val)}
                checked={input.checked}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                data-id={dataId}
                data-tip={switchTooltip}
                data-for={`switch-tooltip-${id}`}
            >
                <Switch.Thumb className={cx(styles.SwitchThumb, iconClassName)} />
            </Switch.Root>
            {switchTooltip && <Tooltip id={`switch-tooltip-${id}`} effect="solid" place="bottom" />}

            {labelPosition === LabelPosition.End && (
                <label className={styles.labelContainer} htmlFor={name}>
                    <p className={labelClassName}>{label}</p>
                    {description && <p className={styles.description}>{description}</p>}
                </label>
            )}
        </div>
    );
};

interface ControlledToggleButtonProps extends ToggleButtonCommonProps {
    checked: boolean;
    onClick: (value: boolean) => void;
}

const ControlledToggleButton: React.FC<ControlledToggleButtonProps> = ({
    className,
    switchClassName,
    iconClassName,
    label,
    onClick,
    labelClassName,
    disabled,
    checked,
    dataId,
    switchTooltip
}) => {
    const id = useComponentId();
    return (
        <div className={cx(styles.container, className)}>
            <label className={labelClassName}>{label}</label>
            <Switch.Root
                disabled={disabled}
                className={cx(styles.SwitchRoot, switchClassName)}
                checked={checked}
                onCheckedChange={onClick}
                data-id={dataId}
                data-tip={switchTooltip}
                data-for={`switch-tooltip-${id}`}
            >
                <Switch.Thumb className={cx(styles.SwitchThumb, iconClassName)} />
            </Switch.Root>
            {switchTooltip && <Tooltip id={`switch-tooltip-${id}`} effect="solid" place="bottom" />}
        </div>
    );
};

const ToggleButtonV2: React.FC<FormToggleButtonProps | ControlledToggleButtonProps> = props => {
    if (isControlledToggleButtonProps(props)) {
        return <ControlledToggleButton {...props} />;
    } else {
        return <Field component={FormToggleButton} {...props} type="checkbox" />;
    }
};

export default ToggleButtonV2;
