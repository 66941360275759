import React from 'react';
import cx from 'classnames';
import { useFieldArray } from 'react-final-form-arrays';
import { debounce } from 'lodash';

import ModalDialog from '../../../common/components/ModalDialog.js';
import Button from '../../../common/components/Button.js';
import Icon, { Icons } from '../../../common/components/Icon';
import { generateRandomId } from '../../../utils/generalUtils.js';
import { ReactComponent as ImageBackground } from '../../../../assets/images/content/gradientBackground.svg';
import { ReactComponent as CustomPlaceholderImage } from '../../../../assets/images/content/customPlaceholderExampleNoBackground.svg';
import { AddPlaceholderButton } from './CustomPlaceholderAccordionContent/AddPlaceholderButton';
import Listen from '../../dashboard/documents/buildADoc/components/Listen';
import { CustomPlaceholderRespondentType } from '../../../types/UploadADoc';
import CustomPlaceholderInputs from '../../dashboard/documents/FlkAPdf/CustomPlaceholderInputs';

import styles from './CustomPlaceholderModal.module.scss';

type CustomPlaceholderModalProps = {
    isOpen: boolean;
    close: () => void;
    deleteAnnotationsForCustomPlaceholder: (customPlaceholderId: string) => void;
};

const CustomPlaceholderModal: React.FC<CustomPlaceholderModalProps> = ({
    isOpen,
    close,
    deleteAnnotationsForCustomPlaceholder
}) => {
    const inputToFocusRef = React.useRef<HTMLInputElement>(null);
    const [showSpinner, setShowSpinner] = React.useState(false);

    const customPlaceholdersFieldArray = useFieldArray('customPlaceholders');
    const { fields } = customPlaceholdersFieldArray;

    // We use this debounced function to hide the spinner 0.5s after the last change to the form
    const debouncedHideSpinner = React.useMemo(() => debounce(() => setShowSpinner(false), 500), []);

    // We have a mock saving status to convey to the user that their changes are immediately applied
    const updateSavingStatus = () => {
        if (!showSpinner) {
            setShowSpinner(true);
        }
        debouncedHideSpinner();
    };

    const handleAddCustomPlaceholder = () => {
        fields.push({
            id: generateRandomId(),
            label: '',
            value: '',
            respondentType: CustomPlaceholderRespondentType.SENDER,
            count: 0
        });

        updateSavingStatus();
        requestAnimationFrame(() => {
            inputToFocusRef.current?.focus();
        });
    };

    const handleDeleteCustomPlaceholder = (customPlaceholderId: string, customPlaceholderFieldIndex: number) => {
        deleteAnnotationsForCustomPlaceholder(customPlaceholderId);
        fields.remove(customPlaceholderFieldIndex);
        updateSavingStatus();
    };

    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={close}
            customBodyAndFooter
            customHeader
            customHeaderContent={() => (
                <div className={styles.header}>
                    <div className={styles.text}>
                        <h1 className={styles.heading}>Add custom info placeholders</h1>
                        <p className={styles.subHeading}>Create areas for information to be placed on your document</p>
                    </div>
                    <div className={styles.imageContainer}>
                        <CustomPlaceholderImage className={styles.image} />
                        <ImageBackground className={styles.background} />
                    </div>
                    <Button className={styles.closeButton} onClick={close} type="button">
                        <Icon icon={Icons.CLOSE} className={styles.closeIcon} />
                    </Button>
                </div>
            )}
            containerClassName={styles.modal}
        >
            <div className={styles.body}>
                {fields.length ? (
                    <>
                        <CustomPlaceholderInputs
                            onDelete={(customPlaceholderData, index) =>
                                handleDeleteCustomPlaceholder(customPlaceholderData.id, index)
                            }
                            inputToFocusRef={inputToFocusRef}
                        />
                        {fields.map((name, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Listen to={`${name}.label`} onChange={updateSavingStatus} />
                                    <Listen to={`${name}.value`} onChange={updateSavingStatus} />
                                    <Listen to={`${name}.respondentType `} onChange={updateSavingStatus} />
                                </React.Fragment>
                            );
                        })}
                    </>
                ) : (
                    <AddPlaceholderButton
                        className={styles.emptyButton}
                        onClick={handleAddCustomPlaceholder}
                        text="Add your first placeholder"
                    />
                )}
            </div>
            <div className={styles.footer}>
                <Button
                    className={cx(styles.button, styles.addButton)}
                    newSecondary
                    onClick={handleAddCustomPlaceholder}
                    type="button"
                    endIcon={<Icon icon={Icons.PLUS} className={styles.addIcon} />}
                >
                    {fields.length ? 'Add another' : 'Add'}
                </Button>
                <Button
                    className={cx(styles.button, styles.saveButton)}
                    primary
                    onClick={close}
                    type="button"
                    disabled={showSpinner}
                    endIcon={
                        <div className={styles.saveIconContainer}>
                            {showSpinner ? (
                                <div className={styles.spinner} />
                            ) : (
                                <Icon icon={Icons.CHEVRON_RIGHT} className={styles.saveIcon} />
                            )}
                        </div>
                    }
                >
                    Save
                </Button>
            </div>
        </ModalDialog>
    );
};

export default CustomPlaceholderModal;
