import React from 'react';
import { Field, useField } from 'react-final-form';
import { getFormError } from '../../utils/finalFormUtils';
import { InfoSharp } from '@material-ui/icons';
import { FormTextMultiline, FormTextRegular, FormNumber } from '../form/FormText';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import '../../sass/form-radio.scss';

const config = {
    subscription: {
        error: true,
        submitError: true,
        dirtySinceLastSubmit: true,
        touched: true,
        modified: true
    }
};

const radioInput = ({ input, value, onClick, meta, disabled, ...rest }) => (
    <div className={cx('form-group', { on: value })}>
        <input type="radio" onClick={onClick} {...input} {...rest} disabled={disabled} />
    </div>
);

/**
 * We can use this component inside form to add radio buttons.
 * ex:  <FormRadioGroup
            label="The notice will be delivered to the tenant by:"
            name="deliveryType"
            data={[
                {
                    label: 'Hand',
                    value: 'value'
                },
                {
                    label: 'Post',
                    value: 'post'
                },
                {
                    label: 'Email',
                    value: 'email',
                    onClick: () => setDeliveryType(form.change, values)
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ]}
        />
 */
export const FormRadioGroup = ({
    className,
    radioGroupClass,
    required,
    label,
    name,
    data,
    validateOnTouch,
    value,
    isYesNoGroup,
    addInfoIcon,
    infoIconToolTipData
}) => {
    const field = useField(name, config);
    return (
        <div className={cx('FormInput', 'FormInputRadio', className, { 'yes-no-group': isYesNoGroup })}>
            <label className={cx('form-radio-label', { FormError: getFormError(field.meta, validateOnTouch) })}>
                {label} {required ? '*' : null}
                {addInfoIcon && (
                    <InfoSharp className="tooltip-info-icon text-gray" data-tip={true} data-for={infoIconToolTipData} />
                )}
            </label>
            <div className={cx(`radioGroup`, radioGroupClass)}>
                {data.map((item, index) => {
                    const itemId = item.id || name + item.label;
                    return (
                        <React.Fragment key={`${item.value}-${index}`}>
                            <div
                                className={cx('itemGroup', { active: value === item.value })}
                                key={index}
                                data-test={item.dataTest}
                            >
                                <Field
                                    className={item.className}
                                    name={name}
                                    value={item.value}
                                    component={radioInput}
                                    type="radio"
                                    id={itemId}
                                    disabled={item.disabled}
                                    onClick={item.onClick}
                                />
                                <label
                                    htmlFor={itemId}
                                    className={cx({ disabled: item.disabled })}
                                    data-tip={true}
                                    data-for={`${itemId}-tooltip`}
                                >
                                    <span />
                                    {item.label}
                                </label>
                                {item.tooltip && (
                                    <ReactTooltip
                                        id={`${itemId}-tooltip`}
                                        class="radio-tooltip"
                                        place="bottom"
                                        effect="solid"
                                        globalEventOff="wheel"
                                    >
                                        {item.tooltip}
                                    </ReactTooltip>
                                )}
                            </div>
                            {item.descriptionLabel && value === item.value && (
                                <FormTextMultiline
                                    containerClassName={'margin-bottom-0'}
                                    name={`${item.value}Description`}
                                    label={item.descriptionLabel}
                                    required={item.descriptionRequired || false}
                                />
                            )}
                            {item.textLabel && value === item.value && (
                                <FormTextRegular
                                    containerClassName={'margin-bottom-0'}
                                    name={`${item.value}Text`}
                                    label={item.textLabel}
                                />
                            )}
                            {item.numberLabel && value === item.value && (
                                <FormNumber
                                    containerClassName={'margin-bottom-0'}
                                    name={`${item.value}Number`}
                                    label={item.numberLabel}
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
            {getFormError(field.meta, validateOnTouch) && (
                <span className={'FormError'}>{getFormError(field.meta, validateOnTouch)}</span>
            )}
        </div>
    );
};
