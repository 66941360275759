import { has } from 'lodash';

export const SEND_RESET_PASSWORD_EMAIL_REQUEST = 'SEND_RESET_PASSWORD_EMAIL_REQUEST';
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'SEND_RESET_PASSWORD_EMAIL_SUCCESS';
export const SEND_RESET_PASSWORD_EMAIL_FAILURE = 'SEND_RESET_PASSWORD_EMAIL_FAILURE';

export const CHECK_TOKEN_STATUS_REQUEST = 'CHECK_TOKEN_STATUS_REQUEST';
export const CHECK_TOKEN_STATUS_SUCCESS = 'CHECK_TOKEN_STATUS_SUCCESS';
export const CHECK_TOKEN_STATUS_FAILURE = 'CHECK_TOKEN_STATUS_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

const initState = {
    isSendingResetRequest: false,
    isCheckingToken: false,
    isUpdatingPassword: false,
    requestSentSuccess: false,
    updatePasswordSuccess: false,
    isTokenExpired: false,
    validationError: '',
    serverError: false,
    updatePasswordFail: false
};

export default function resetPassword(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case SEND_RESET_PASSWORD_EMAIL_REQUEST: {
            newState.isSendingResetRequest = true;
            break;
        }
        case SEND_RESET_PASSWORD_EMAIL_SUCCESS: {
            newState.isSendingResetRequest = false;
            newState.requestSentSuccess = true;
            break;
        }
        case SEND_RESET_PASSWORD_EMAIL_FAILURE: {
            newState.isSendingResetRequest = false;
            //Faking a success even when failure for security reason
            newState.requestSentSuccess = true;
            break;
        }
        case CHECK_TOKEN_STATUS_REQUEST: {
            newState.isCheckingToken = true;
            break;
        }
        case CHECK_TOKEN_STATUS_SUCCESS: {
            newState.isCheckingToken = false;
            newState.isTokenExpired = false;
            break;
        }
        case CHECK_TOKEN_STATUS_FAILURE: {
            const { status } = action.error.response;

            newState.isCheckingToken = false;
            newState.isTokenExpired = false;

            if (status === 404) {
                newState.isTokenExpired = true;
            } else {
                newState.serverError = true;
            }
            break;
        }
        case UPDATE_PASSWORD_REQUEST: {
            newState.isUpdatingPassword = true;
            newState.validationError = '';
            break;
        }
        case UPDATE_PASSWORD_SUCCESS: {
            newState.isUpdatingPassword = false;
            newState.isTokenExpired = false;
            newState.updatePasswordSuccess = true;
            break;
        }
        case UPDATE_PASSWORD_FAILURE: {
            const { status } = action.error.response;

            if (has(action.error.response, 'data.errors.password.message')) {
                newState.validationError = action.error.response.data.errors.password.message;
            }

            newState.isUpdatingPassword = false;
            newState.updatePasswordSuccess = false;
            newState.isTokenExpired = false;
            newState.updatePasswordFail = true;

            if (status === 404) {
                newState.isTokenExpired = true;
            }
            break;
        }
    }

    return newState;
}
