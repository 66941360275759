import { isCorrectEmail } from '@app/components/Validate';
import * as yup from 'yup';
import { UploadADoc } from '@app/types/UploadADoc';
import { SIGN_CONFIRMATION_TYPE } from '@app/constants/constants';

export enum EmailSendType {
    EVERYONE = 'everyone',
    SEPARATELY = 'separately'
}

enum HelpText {
    EVERYONE = 'Email communications will be grouped and sent together to the recipients below on completion.',
    SEPARATELY = 'Email communications will be sent individually to the recipients below on completion.'
}

export type Recipient = {
    email: string;
    name: string;
};

export type ClientEmailNotificationList = {
    clientEmail?: string;
    toNotificationList?: string[];
    ccNotificationList?: string[];
    bccNotificationList?: string[];
};

export type FormModel = {
    signedByAgent?: boolean;
    emailSendType: EmailSendType;
    to?: string[];
    cc?: string[];
    bcc?: string[];
    recipients: {
        to?: string[];
        cc?: string[];
        bcc?: string[];
    }[];
};

export type ApiModel = {
    signedByAgent?: boolean;
} & (
    | {
          sendSeparateEmails: true;
          emails: ClientEmailNotificationList[];
      }
    | ({
          sendSeparateEmails: false;
      } & Omit<ClientEmailNotificationList, 'clientEmail'>)
);

const emailValidation = yup
    .string()
    .test('is-correct-email', 'Please enter a valid email address', email => !email || isCorrectEmail(email));

const emailArraySchema = yup.array().of(emailValidation);
const requiredEmailArraySchema = emailArraySchema.required('Please enter an email address');

const separatelySchema = yup.array().of(
    yup.object().shape({
        to: requiredEmailArraySchema,
        cc: emailArraySchema,
        bcc: emailArraySchema
    })
);

export const validationSchema = yup.object().shape({
    emailSendType: yup.mixed().oneOf(Object.values(EmailSendType)).required(),
    recipients: yup.array().when('emailSendType', {
        is: EmailSendType.SEPARATELY,
        then: () => separatelySchema
    }),
    to: yup.array().when('emailSendType', {
        is: EmailSendType.EVERYONE,
        then: () => requiredEmailArraySchema
    }),
    cc: yup.array().when('emailSendType', {
        is: EmailSendType.EVERYONE,
        then: () => emailArraySchema
    }),
    bcc: yup.array().when('emailSendType', {
        is: EmailSendType.EVERYONE,
        then: () => emailArraySchema
    })
});

export const getHelpText = (emailSendType: EmailSendType): HelpText => {
    switch (emailSendType) {
        case EmailSendType.EVERYONE:
            return HelpText.EVERYONE;
        case EmailSendType.SEPARATELY:
            return HelpText.SEPARATELY;
    }
};

export const getInitialValues = (document: UploadADoc): Partial<FormModel> => {
    if (document.completion) {
        if (document.completion.sendSeparateEmails) {
            return {
                emailSendType: EmailSendType.SEPARATELY,
                signedByAgent: document.completion.signedByAgent,
                recipients: document.completion.emails.map(email => ({
                    to: email.toNotificationList,
                    cc: email.ccNotificationList,
                    bcc: email.bccNotificationList
                }))
            };
        } else {
            return {
                emailSendType: EmailSendType.EVERYONE,
                signedByAgent: document.completion.signedByAgent,
                to: document.completion.toNotificationList,
                cc: document.completion.ccNotificationList,
                bcc: document.completion.bccNotificationList
            };
        }
    }
    return {
        emailSendType: EmailSendType.EVERYONE,
        signedByAgent: document.confirmationType === SIGN_CONFIRMATION_TYPE,
        to: document.client?.clients.map(client => client.email),
        cc: [document.agent.email]
    };
};

export const projectApiModel = (values: FormModel, recipients: Recipient[]): ApiModel => {
    if (values.emailSendType === EmailSendType.EVERYONE) {
        return {
            signedByAgent: values.signedByAgent,
            sendSeparateEmails: false,
            toNotificationList: values.to,
            ccNotificationList: values.cc,
            bccNotificationList: values.bcc
        };
    } else {
        return {
            signedByAgent: values.signedByAgent,
            sendSeparateEmails: true,
            emails: recipients.map((_, index) => ({
                clientEmail: recipients[index].email,
                toNotificationList: values.recipients[index].to,
                ccNotificationList: values.recipients[index].cc,
                bccNotificationList: values.recipients[index].bcc
            }))
        };
    }
};