import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { HIDE_MESSAGE_TIME } from '../../../../config';
import { getCurrentDocument, getDocumentEditMode } from '../../../../selectors/document';
import CommonFooter from '../shared/CommonFooter';

const RentIncreaseFooter = ({
    previewPdf,
    viewPdf,
    form,
    rentIncreaseWithoutLease,
    isSubmitSuccessfull,
    isSubmitFail,
    isSendingRentIncrease,
    isSaveDraft
}) => {
    const currentDocument = useSelector(getCurrentDocument);
    const documentEditMode = useSelector(getDocumentEditMode);

    const [isEmailingRentIncrease, setIsEmailingRentIncrease] = useState(false);
    const [isEmailingRentIncreaseSuccess, setIsEmailingRentIncreaseSuccess] = useState(false);
    const [isEmailingRentIncreaseFail, setIsEmailingRentIncreaseFail] = useState(false);

    const emailPdf = (id, selectedRentIncrease) => {
        setIsEmailingRentIncrease(true);
        axios
            .post(`api/${selectedRentIncrease ? 'agency/lease' : 'document'}/${id}/pdf/rent-increase`, {
                rentIncrease: selectedRentIncrease
            })
            .then(() => {
                setIsEmailingRentIncrease(false);
                setIsEmailingRentIncreaseSuccess(true);
                setIsEmailingRentIncreaseFail(false);
                setTimeout(() => {
                    setIsEmailingRentIncreaseSuccess(false);
                }, HIDE_MESSAGE_TIME);
            })
            .catch(() => {
                setIsEmailingRentIncrease(false);
                setIsEmailingRentIncreaseSuccess(false);
                setIsEmailingRentIncreaseFail(true);
                setTimeout(() => {
                    setIsEmailingRentIncreaseFail(false);
                }, HIDE_MESSAGE_TIME);
            });
    };

    return (
        <CommonFooter
            isDoc={rentIncreaseWithoutLease}
            currentDocument={currentDocument}
            documentEditMode={documentEditMode}
            resend={emailPdf}
            viewPdf={viewPdf}
            previewPdf={previewPdf}
            isResending={isEmailingRentIncrease}
            isResendingSuccess={isEmailingRentIncreaseSuccess}
            isResendingFail={isEmailingRentIncreaseFail}
            isSendingSuccess={isSubmitSuccessfull}
            isSendingFail={isSubmitFail}
            isSending={isSendingRentIncrease}
            form={form}
            isSaveDraft={isSaveDraft}
        />
    );
};
export default RentIncreaseFooter;
