import { format, lastDayOfMonth, startOfMonth, subMonths } from 'date-fns';
import * as PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import queryString from 'query-string';
import { STANDARD_DATE_AMERICAN_FORMAT } from '../../../../config';

const FILTER_THIS_MONTH = 'This month';
const FILTER_LAST_MONTH = 'Last month';
const FILTER_3_MONTH = 'Last 3 months';
const FILTER_6_MONTH = 'Last 6 months';

const QuickFilters = ({ updateFunction, disabled }) => {
    const THIS_MONTH = {
        startDate: startOfMonth(new Date()),
        endDate: lastDayOfMonth(new Date())
    };
    const LAST_MONTH = {
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: lastDayOfMonth(subMonths(new Date(), 1))
    };
    const LAST_3_MONTH = {
        startDate: subMonths(new Date(), 3),
        endDate: new Date()
    };
    const LAST_6_MONTH = {
        startDate: subMonths(new Date(), 6),
        endDate: new Date()
    };
    // figure out which is selected:
    let queryParams = queryString.parse(location.search);

    useEffect(() => {
        if (queryParams.startDate) {
            if (
                format(THIS_MONTH.startDate, STANDARD_DATE_AMERICAN_FORMAT) === queryParams.startDate &&
                format(THIS_MONTH.endDate, STANDARD_DATE_AMERICAN_FORMAT) === queryParams.endDate
            ) {
                setActive(FILTER_THIS_MONTH);
            } else if (
                format(LAST_MONTH.startDate, STANDARD_DATE_AMERICAN_FORMAT) === queryParams.startDate &&
                format(LAST_MONTH.endDate, STANDARD_DATE_AMERICAN_FORMAT) === queryParams.endDate
            ) {
                setActive(FILTER_LAST_MONTH);
            } else if (
                format(LAST_3_MONTH.startDate, STANDARD_DATE_AMERICAN_FORMAT) === queryParams.startDate &&
                format(LAST_3_MONTH.endDate, STANDARD_DATE_AMERICAN_FORMAT) === queryParams.endDate
            ) {
                setActive(FILTER_3_MONTH);
            } else if (
                format(LAST_6_MONTH.startDate, STANDARD_DATE_AMERICAN_FORMAT) === queryParams.startDate &&
                format(LAST_6_MONTH.endDate, STANDARD_DATE_AMERICAN_FORMAT) === queryParams.endDate
            ) {
                setActive(FILTER_6_MONTH);
            } else {
                setActive('none');
            }
        } else {
            setActive(FILTER_THIS_MONTH);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams]);

    const [active, setActive] = useState();

    const thisMonth = () => {
        if (!disabled) {
            setActive(FILTER_THIS_MONTH);
            updateFunction(THIS_MONTH);
        }
    };

    const lastMonth = () => {
        if (!disabled) {
            setActive(FILTER_LAST_MONTH);
            updateFunction(LAST_MONTH);
        }
    };

    const last3Months = () => {
        if (!disabled) {
            setActive(FILTER_3_MONTH);
            updateFunction(LAST_3_MONTH);
        }
    };

    const last6Months = () => {
        if (!disabled) {
            setActive(FILTER_6_MONTH);
            updateFunction(LAST_6_MONTH);
        }
    };

    return (
        <div className="row quick-filters">
            <a className={`${active === FILTER_THIS_MONTH ? 'special-link-active' : ''}`} onClick={thisMonth}>
                {FILTER_THIS_MONTH}
            </a>
            <a className={`${active === FILTER_LAST_MONTH ? 'special-link-active' : ''}`} onClick={lastMonth}>
                {FILTER_LAST_MONTH}
            </a>
            <a className={`${active === FILTER_3_MONTH ? 'special-link-active' : ''}`} onClick={last3Months}>
                {FILTER_3_MONTH}
            </a>
            <a className={`${active === FILTER_6_MONTH ? 'special-link-active' : ''}`} onClick={last6Months}>
                {FILTER_6_MONTH}
            </a>
        </div>
    );
};

QuickFilters.propTypes = {
    updateFunction: PropTypes.func,
    disabled: PropTypes.any
};

export default memo(QuickFilters);
