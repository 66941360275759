import { createSelector } from 'reselect';

export const getAccount = state => state.account;

export const getAgency = createSelector([getAccount], account => account.agency);

export const getAgencyDetailsTemplatesToUpdate = createSelector(
    [getAccount],
    account => account.agencyDetailsTemplatesToUpdate
);
export const getLicenceTemplatesToUpdate = createSelector([getAccount], account => account.licenseTemplatesToUpdate);
export const getPaymentTemplatesToUpdate = createSelector([getAccount], account => account.paymentTemplatesToUpdate);

export const getFollowUpReportSettings = createSelector([getAgency], agency => agency.followUpReportSettings);

export const getFollowUpReportSettingsEmailReceivers = createSelector([getFollowUpReportSettings], followUpSettings =>
    followUpSettings ? followUpSettings.followUpReportEmails : []
);

export const getAllDefaultTenantLinks = createSelector([getAgency], agency =>
    agency.defaultTenantLinks ? agency.defaultTenantLinks : { links: [] }
);

const getLeaseType = (_, leaseType) => leaseType;

export const getDefaultTenantLinksByLeaseType = createSelector(getAgency, getLeaseType, (agency, leaseType) => {
    const links =
        agency.defaultTenantLinks && agency.defaultTenantLinks.links
            ? agency.defaultTenantLinks.links.filter(item => item.leaseType === leaseType)
            : [];
    return { links: links };
});

export const getDefaultDocumentsByLeaseType = createSelector(getAgency, getLeaseType, (agency, leaseType) => {
    return agency.defaultDocuments ? agency.defaultDocuments.filter(item => item.leaseType === leaseType) : [];
});

export const getDefaultDocuments = createSelector([getAgency], agency =>
    agency.defaultDocuments ? agency.defaultDocuments : []
);

export const getDocumentTemplatesToUpdate = createSelector([getAgency], agency =>
    agency.documentTemplatesToUpdate ? agency.documentTemplatesToUpdate : {}
);

export const getLinksTemplatesToUpdate = createSelector([getAgency], agency =>
    agency.linksTemplatesToUpdate ? agency.linksTemplatesToUpdate : {}
);

export const getActivityReportSettings = createSelector([getAgency], agency => agency.activityReportSettings);

export const getValidationErrors = createSelector([getAccount], account => account.validationErrors);

export const getAgencyDefaultLocation = createSelector([getAgency], agency => agency?.details?.location);
