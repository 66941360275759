import React from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';

import '../../sass/form-radio.scss';

const radioInput = ({ input, value, onClick, meta, className, ...rest }) => (
    <div className={cx(className, 'form-group', { on: value })}>
        <input type="radio" onClick={onClick} {...input} {...rest} />
    </div>
);

export const FormRadio = ({ className, name, value, id, disabled, onClick }) => {
    return (
        <Field
            className={className}
            name={name}
            value={value}
            component={radioInput}
            type="radio"
            id={id}
            disabled={disabled}
            onClick={onClick}
        />
    );
};
