import * as React from 'react';
import ModalDialog from '../../../common/components/ModalDialog';
import '../../../sass/conditions.scss';
import { OnePageVersion } from './OnePageVersion';
import { ClauseList } from './ClauseList';

/**
 * Modal window to display special conditions.
 * This window is available from overview agreement page.
 */
export default class ModalViewSpecialConditions extends React.Component {
    render() {
        const { isOpen, closeModal, specialConditions } = this.props;

        let content = <div />;
        if (true === specialConditions.onePageVersion) {
            content = <OnePageVersion specialConditions={specialConditions} />;
        } else {
            content = <ClauseList specialConditions={specialConditions} />;
        }

        return (
            <ModalDialog title="Special terms & conditions" isOpen={isOpen} closeModal={closeModal}>
                <div className="conditions">{content}</div>
            </ModalDialog>
        );
    }
}
