import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import appHistory from './../AppHistory';
import { Form } from 'react-final-form';
import { checkResetToken, resetPassword } from '../actions/resetPassword';
import logoImage from '../../assets/images/logo.png';

import '../sass/resetPassword.scss';
import { FormPassword } from '../components/form/FormPassword';
import { INVALID_PASSWORD_MESSAGE, isValidPassword } from '../components/Validate';
import { useIntercom } from 'react-use-intercom';
import {
    getIsCheckingToken,
    getIsTokenExpired,
    getIsUpdatingPassword,
    getUpdatePasswordSuccess,
    getUpdatePasswordFail,
    getValidationError,
    getServerError
} from '../selectors/user/resetPassword';
import { isMobileWidth } from '../config';

const errorMessage = 'Your password could not be updated. Please try again.';

const validator = values => {
    let { password, confirmPassword } = values;
    return {
        password: password && password.length > 3 && !isValidPassword(password) ? INVALID_PASSWORD_MESSAGE : undefined,
        confirmPassword: confirmPassword && (password !== confirmPassword ? 'Passwords are not equal' : undefined)
    };
};

const ResetPassword = props => {
    const dispatch = useDispatch();
    const { boot } = useIntercom();

    const [passwordData, setPasswordData] = useState({
        password: '',
        confirmPassword: ''
    });
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const isUpdatingPassword = useSelector(getIsUpdatingPassword);
    const isCheckingToken = useSelector(getIsCheckingToken);
    const isTokenExpired = useSelector(getIsTokenExpired);
    const updatePasswordSuccess = useSelector(getUpdatePasswordSuccess);
    const updatePasswordFail = useSelector(getUpdatePasswordFail);
    const validationError = useSelector(getValidationError);
    const serverError = useSelector(getServerError);

    useEffect(() => {
        document.title = 'Reset Password';
        const dataReq = {
            resetToken: props.match.params.resetToken
        };

        dispatch(checkResetToken(dataReq));
        if (!isMobileWidth()) {
            boot({ customAttributes: { page: document.title } });
        }
    }, [boot, dispatch]);

    useEffect(() => {
        if (!isUpdatingPassword && updatePasswordSuccess) {
            // If the update was successful redirect users to login page
            if (updatePasswordSuccess) {
                appHistory.push('/');
            }
        }

        // If the update was not successful, but the token did not expire show a generic error message
        if (updatePasswordFail && !updatePasswordSuccess && !isTokenExpired) {
            setShowErrorMessage(true);
        }
    }, [updatePasswordSuccess, isUpdatingPassword, isTokenExpired, updatePasswordFail]);

    const updatePassword = data => {
        // Remove the error message before we update the password again
        setShowErrorMessage(false);

        const dataReq = {
            password: data.password,
            resetToken: props.match.params.resetToken
        };

        dispatch(resetPassword(dataReq));
    };

    let buttonClass = isUpdatingPassword ? 'loader-button' : 'button';

    return (
        <div className="reset-password-page">
            <div className="content">
                <div className="left">
                    <Link to="/" className="logo">
                        <img className="logo-big" src={logoImage} alt="logo" />
                    </Link>
                    {!serverError && !isTokenExpired && (
                        <div className="reset-password">
                            <h1>Reset Password</h1>
                            <Form
                                initialValues={passwordData}
                                onSubmit={values => updatePassword(values)}
                                validate={validator}
                            >
                                {({ handleSubmit }) => {
                                    return (
                                        <form onSubmit={handleSubmit} noValidate>
                                            <FormPassword name="password" label="New Password" />
                                            <FormPassword name="confirmPassword" label="Confirm Password" />
                                            {showErrorMessage && (
                                                <p className="has-error">{validationError || errorMessage}</p>
                                            )}
                                            <div className={buttonClass}>
                                                <button type="submit" disabled={isCheckingToken}>
                                                    <div className="loader" />
                                                    Update and go back to login
                                                </button>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Form>
                        </div>
                    )}
                    {isTokenExpired && (
                        <div className="expired-reset-password">
                            <h1>Reset Password</h1>
                            <p>
                                The password reset link has expired. <br />
                                Please resend the email to reset your password.
                            </p>
                        </div>
                    )}
                    {serverError && (
                        <div className="expired-reset-password">
                            <h1>Reset Password</h1>
                            <p>
                                Something went wrong. <br />
                                Please refresh the page or resend the email to reset your password.
                            </p>
                        </div>
                    )}
                </div>
                <div className="right" />
            </div>
        </div>
    );
};

export default memo(ResetPassword);
