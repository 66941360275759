import React from 'react';
import { Form } from 'react-final-form';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import Button from '../../../common/components/Button.js';
import { FLK_API_INTEGRATION } from '../../../constants/featureFlags';
import { getAgencyApiAccess } from '../../../selectors/agency.js';
import { updateAgencyInfo } from '../../../actions/account.js';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import styles from './Integration.module.scss';

const FlkApiIntegrationName = 'FlkApi';

const FlkApiIntegration = () => {
    const dispatch = useDispatch();
    const isFlkApiIntegrationActive = useFeatureFlag(FLK_API_INTEGRATION);

    const apiAccess: [{ name: string; enabled: boolean; apiKey: string; connectedUsers: [string] }] =
        useSelector(getAgencyApiAccess);

    const flkApiAccess = apiAccess.find(access => access.name === FlkApiIntegrationName);

    const updateFlkApiIntegration = useMutation(
        (data: { enabled: boolean; name: string }) => {
            return axios.post(`/api/agency/account/api-access`, data);
        },
        {
            onSuccess: response => {
                dispatch(updateAgencyInfo(response.data));
            }
        }
    );

    const handleIntegrateClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        updateFlkApiIntegration.mutate({ name: FlkApiIntegrationName, enabled: true });
    };
    const handleDisableClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        updateFlkApiIntegration.mutate({ name: FlkApiIntegrationName, enabled: false });
    };

    return isFlkApiIntegrationActive ? (
        <Form onSubmit={async () => null}>
            {() => {
                return (
                    <form>
                        <div className={`panel ${styles.container}`}>
                            <h2 className="panel-header">FLK API</h2>
                            {flkApiAccess?.enabled ? (
                                <>
                                    <div className="panel-body">
                                        <p className={styles.apiKey}>API Key: {flkApiAccess.apiKey}</p>
                                        {flkApiAccess.connectedUsers.length > 0 ? (
                                            <span className="savestatus-saved">
                                                Connected <span className="icon savestatus-icon" />
                                            </span>
                                        ) : (
                                            <span className="savestatus-failed">
                                                Not connected <span className="icon icon-cross" />
                                            </span>
                                        )}
                                    </div>
                                    <div className="panel-footer">
                                        <Button
                                            onClick={handleDisableClick}
                                            loading={updateFlkApiIntegration.isLoading}
                                            className={styles.disableButton}
                                            spinnerClassName={styles.spinner}
                                        >
                                            Disable API key
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <div className="panel-body">
                                    <Button
                                        primary
                                        onClick={handleIntegrateClick}
                                        loading={updateFlkApiIntegration.isLoading}
                                    >
                                        Enable API Key
                                    </Button>
                                </div>
                            )}
                        </div>
                    </form>
                );
            }}
        </Form>
    ) : null;
};

export default FlkApiIntegration;
