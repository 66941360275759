import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';

export const UPDATE_AGENT_AUTHORITY_FORM = 'UPDATE_AGENT_AUTHORITY_FORM';

const initState = {
    isObtainReferences: false,
    isSelectTenants: false,
    isSubmitTenants: false,
    isSignTenancy: false,
    customAuthorizations: []
};

export default function agentAuthorityReducer(state = initState, action) {
    let newState;
    switch (action.type) {
        case UPDATE_AGENT_AUTHORITY_FORM: {
            return { ...state, ...action.data };
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            return { ...state, ...action.lease.agentAuthority };
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            return Object.assign({}, initState, action.payload.agreementInfo.agentAuthority);
        }
        case Lease.CREATE_LEASE_SUCCESS: {
            newState = Object.assign({}, initState);
            return newState;
        }
        case Lease.CREATE_PM_LEASE_SUCCESS: {
            if (action.result.data.lease.agentAuthority) {
                newState = Object.assign({}, initState, action.result.data.lease.agentAuthority);
            } else {
                newState = Object.assign({}, initState);
            }
            return newState;
        }
        default: {
            return state;
        }
    }
}
