import React from 'react';
import '../../../../../sass/dashboardContentTable.scss';
import { useDispatch } from 'react-redux';
import { Table, Th, Td, Tr, Thead } from 'reactable';
import AgentLogo from '../../../../AgentLogo';
import { openDocumentForm } from '../../../../../actions/document';
import { formatPeriod, DOCUMENT_RENT_INCREASE, DOCUMENT_READ_ONLY_MODE } from '../../../../../config';
import BasicDocumentsMoreOptionBlock from '../../cells/BasicDocumentsMoreOptionBlock';

const RentIncreaseCancelled = ({ documentList, agentSignature }) => {
    const dispatch = useDispatch();

    function openDocumentFormView(event, doc) {
        event.stopPropagation();
        dispatch(openDocumentForm(DOCUMENT_RENT_INCREASE, doc, DOCUMENT_READ_ONLY_MODE));
    }
    return (
        <Table className="pm-draft-tb">
            <Thead>
                <Th column="agentLogo">AGENT</Th>
                <Th column="address">ADDRESS</Th>
                <Th column="tenants">TENANTS</Th>
                <Th column="newRent">NEW RENT</Th>
                <Th column="payableFrom">EFFECTIVE FROM</Th>
                <Th column="email">EMAIL SENT TO</Th>
                <Th column="dateSent">DATE SENT</Th>
                <Th column="reasonForCancel">REASON FOR CANCEL </Th>
                <Th column="sendEmail"> </Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {documentList &&
                documentList.map((doc, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openDocumentFormView(event, doc);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={doc.agent} />
                            </Td>
                            <Td column="address">
                                <b>{doc.address}</b>
                            </Td>
                            <Td column="tenants">
                                <b>{doc.tenant.tenantsListText}</b>
                            </Td>
                            <Td column="newRent">
                                <b>
                                    ${doc.rentIncrease.newRentAmountForPeriod} /{' '}
                                    {formatPeriod(doc.rentIncrease.newRentPayablePeriod)}
                                </b>
                            </Td>
                            <Td column="payableFrom">
                                <b>{doc.rentIncrease.newRentDateStartFormatted}</b>
                            </Td>
                            <Td column="email">
                                <b>{doc.tenant.tenantsEmailList.join(', ')}</b>
                            </Td>
                            <Td column="dateSent">
                                <b>{doc.rentIncrease.lastSentDate}</b>
                            </Td>
                            <Td column="reasonForCancel">
                                <b>{doc.cancelReason}</b>
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <BasicDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        leaseType={doc.docType}
                                        doc={doc}
                                        agentSignature={agentSignature}
                                    />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default RentIncreaseCancelled;
