import React, { memo } from 'react';
import ModalDialog from '../../common/components/ModalDialog';
import '../../sass/modals/pdfContainsSecurityModal.scss';

type ModalPdfContainsSecurityProps = {
    isOpen: boolean;
    close: () => void;
    encryptedPdfFilenames: string[];
};

const SinglePdfMessage: React.FC<{ pdfName: string }> = ({ pdfName }) => (
    <span className="encrypted-pdf-message">
        We have detected the file: <b>{pdfName}</b> is locked for editing, so can not be uploaded.{' '}
    </span>
);

const MultiplePdfMessage: React.FC<{ encryptedPdfFilenames: string[] }> = ({ encryptedPdfFilenames }) => (
    <>
        <span className="encrypted-pdf-message">
            The following files are locked for editing, so cannot be uploaded:
        </span>
        <ul className="encrypted-pdf-list">
            {encryptedPdfFilenames.map((pdfName, index) => (
                <li key={index} className="encrypted-pdf-list__item">
                    {pdfName}
                </li>
            ))}
        </ul>
    </>
);

const ModalPdfContainsSecurity: React.FC<ModalPdfContainsSecurityProps> = ({
    isOpen,
    close,
    encryptedPdfFilenames
}) => {
    if (!encryptedPdfFilenames?.length) {
        return null;
    }

    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={close}
            className="pdf-contains-security-modal-dialog"
            contentLabel="Modal"
            title={
                encryptedPdfFilenames.length === 1
                    ? `Security detected on PDF: ${encryptedPdfFilenames}`
                    : 'Security detected on PDFs'
            }
            zIndex={20}
        >
            <div className="warning-message-block">
                <div className="warning-message">
                    {encryptedPdfFilenames.length === 1 ? (
                        <SinglePdfMessage pdfName={encryptedPdfFilenames[0]} />
                    ) : (
                        <MultiplePdfMessage encryptedPdfFilenames={encryptedPdfFilenames} />
                    )}
                </div>
            </div>
            <div className={'message-block'}>
                {'Here are a few suggestions to help you upload the file:'}
                <ul>
                    <li className={'list-item'}>Remove security from the PDF file.</li>
                    <li className={'list-item'}>Open the file in Chrome and select print pdf and save a new copy.</li>
                </ul>
                {'If the above steps fail, please contact support so we can assist:'}
            </div>
        </ModalDialog>
    );
};

export default memo(ModalPdfContainsSecurity);
