import React, { memo, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { usePrevious } from '../../utils/reactUtils';
import AgreementHeader from './InfoAgreementComponents/AgreementHeader';
import AgreementFooter from './InfoAgreementComponents/AgreementFooter';
import AgreementMain from './InfoAgreementComponents/AgreementMain';

import { LEASE_STATUS_DRAFT, LEASE_STATUS_ARCHIVED } from '../../actions/dashboard';

import '../../sass/infoAgreement.scss';
import { isEqual, isEmpty } from 'lodash';

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(148, 157, 176, 0.7)',
        zIndex: 5,
        display: 'flex',
        overflow: 'auto',
        justifyContent: 'center',
        padding: '50px 0'
    }
};

function InfoAgreementModal({
    isOpen,
    leaseAllInfo,
    openLeaseRenewalModal,
    closeAgreementInfoModal,
    listLink,
    changeAgreementAgent,
    setSigning,
    resendForSigning,
    closeSigning,
    emailLeasePDF,
    emailLeaseForManualSigning,
    emailOwnerCopyToAgent,
    sendCompletedOwnerCopyToAgent,
    addresses,
    getAddresses,
    rejectRenewal,
    closeAgreementInfoModalWithoutAnyOtherAction,
    emailRentIncreasePDF,
    terminateLease,
    openSummaryView,
    cloneAgreement,
    openCloneResTenForm,
    deleteAgreement,
    showChangeRequestForm,
    isCompletedLease
}) {
    const createLeaseRef = useRef(null);
    const prevLeaseAllInfo = usePrevious(leaseAllInfo);

    const scrollToTop = () => {
        createLeaseRef.current.scrollTop = 0;
    };
    const scrollToBottom = () => {
        if (createLeaseRef && createLeaseRef.current) {
            createLeaseRef.current.scrollTop = createLeaseRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        // Do not scroll to bottom in initial render
        if (
            !isEmpty(prevLeaseAllInfo) &&
            prevLeaseAllInfo.id === leaseAllInfo.id &&
            !isEqual(leaseAllInfo.sendForSigningProgress, prevLeaseAllInfo.sendForSigningProgress)
        ) {
            scrollToBottom();
        }
    }, [leaseAllInfo.sendForSigningProgress]);

    let content = (
        <div>
            <AgreementHeader
                leaseAllInfo={leaseAllInfo}
                closeAgreementInfoModal={closeAgreementInfoModal}
                cloneAgreement={cloneAgreement}
                openCloneResTenForm={openCloneResTenForm}
                deleteAgreement={deleteAgreement}
                changeAgreementAgent={changeAgreementAgent}
                openLeaseRenewalModal={openLeaseRenewalModal}
                closeAgreementInfoModalWithoutAnyOtherAction={closeAgreementInfoModalWithoutAnyOtherAction}
                rejectRenewal={rejectRenewal}
                cancelSigning={closeSigning}
                addresses={addresses}
                getAddresses={getAddresses}
                showChangeRequestForm={showChangeRequestForm}
                isCompletedLease={isCompletedLease}
                openSummaryView={openSummaryView}
            />
            {leaseAllInfo.status === LEASE_STATUS_DRAFT && leaseAllInfo.isRenewal && !leaseAllInfo.isNewLease && (
                <div className="section generic-form-error">
                    This is a clone of the original lease agreement. Please update and confirm all lease agreement
                    details (e.g. rent, start and finish dates and tenant mobile number(s)) prior to re-sending for
                    signing.
                </div>
            )}
            {leaseAllInfo.status === LEASE_STATUS_ARCHIVED && leaseAllInfo.isManuallyCompleted && (
                <div className="section generic-form-error">
                    This agreement was manually moved to complete, this means that the signing of this agreement could
                    have happened outside of FLK it over
                </div>
            )}
            <AgreementMain leaseAllInfo={leaseAllInfo} />
            <AgreementFooter
                leaseAllInfo={leaseAllInfo}
                setSigning={setSigning}
                resendForSigning={resendForSigning}
                closeSigning={closeSigning}
                openLeaseRenewalModal={openLeaseRenewalModal}
                emailLeasePDF={emailLeasePDF}
                emailRentIncreasePDF={emailRentIncreasePDF}
                emailLeaseForManualSigning={emailLeaseForManualSigning}
                emailOwnerCopyToAgent={emailOwnerCopyToAgent}
                sendCompletedOwnerCopyToAgent={sendCompletedOwnerCopyToAgent}
                terminateLease={terminateLease}
                scrollToTop={scrollToTop}
                scrollToBottom={scrollToBottom}
            />
        </div>
    );
    if (
        listLink.length > 0 &&
        leaseAllInfo?.sendForSigningProgress?.[leaseAllInfo.sendForSigningProgress.length - 1]?.completed
    ) {
        let linkListAll = listLink.map((item, i) => {
            return (
                <div key={i}>
                    <p className="text-link">
                        {item.name} ({item.phone}): <br />
                        <a href={item.link}>{item.link}</a>
                    </p>
                    <br />
                </div>
            );
        });
        content = (
            <div>
                <AgreementHeader
                    leaseAllInfo={leaseAllInfo}
                    closeAgreementInfoModal={closeAgreementInfoModal}
                    cloneAgreement={cloneAgreement}
                    openCloneResTenForm={openCloneResTenForm}
                    deleteAgreement={deleteAgreement}
                    cancelSigning={closeSigning}
                    changeAgreementAgent={changeAgreementAgent}
                />
                <div className="ready-for-sign">{linkListAll}</div>
            </div>
        );
    }

    return (
        <Modal
            parentSelector={() => document.getElementById('main-layout')}
            ariaHideApp={false}
            isOpen={isOpen}
            style={customStyle}
            className="modal-create-lease"
            contentLabel="Modal"
        >
            <div className="create-lease" ref={createLeaseRef}>
                <div className="content-create">
                    <div className="info-agreement">{content}</div>
                </div>
            </div>
        </Modal>
    );
}

export default memo(InfoAgreementModal);
