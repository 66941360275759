import { connect } from 'react-redux';
import { IntegrationConnection } from '../../components/integration/IntegrationConnection';
import { getIntegration, getLeaseInfo } from '../../selectors/lease';
import { getAgencyIntegrations } from '../../selectors/agency';

export default connect(state => ({
    integration: getIntegration(state),
    agencyIntegrations: getAgencyIntegrations(state),
    lease: getLeaseInfo(state)
}))(IntegrationConnection);
