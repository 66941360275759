import React from 'react';
import GestureSharpIcon from '@material-ui/icons/GestureSharp';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import EmbeddedSignatureIcon from '../../../../components/ui/icons/EmbeddedSignatureIcon.js';
import AcknowledgementPageIcon from '../../../../components/ui/icons/AcknowledgementPageIcon.js';
import AgreementMainAuditTrail from '../../../../components/dashboard/InfoAgreementComponents/AgreementMainComponents/shared/AgreementMainAuditTrail.js';
import { UploadADoc } from '@app/types/UploadADoc';
import { isDocumentComplete, isDocumentAwaitingCompletion } from '../../../../utils/generalUtils.js';
import AwaitingCompletionForm from './AwaitingCompletionForm';
import MobileCompletionProgress from './MobileCompletionProgress';
import CompletionProgress from '../../../../components/dashboard/InfoAgreementComponents/footerComponents/CompletionProgress.js';
import { DOCUMENT_DELIVERY_TYPE_SMS, DOCUMENT_DELIVERY_TYPE_EMAIL, DOCUMENT_DELIVERY_TYPE_QR_CODE } from '@app/config';
import { EXECUTION_TYPE_EMBEDDED_SIGNATURE, EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE } from '@app/constants/constants';
import Icon, { Icons } from '@app/common/components/Icon';
import LinkedPartnersSummary from './LinkedPartnersSummary';

import '../../../../sass/documentSummary.scss';

type UploadADocSummaryProps = {
    currentDocument: UploadADoc;
    SIGN: string;
    ACKNOWLEDGE: string;
    renderUploadedDocuments: () => JSX.Element;
    isMobileView: boolean;
};

const UploadADocSummary: React.FC<UploadADocSummaryProps> = ({
    currentDocument,
    SIGN,
    ACKNOWLEDGE,
    renderUploadedDocuments,
    isMobileView
}) => {
    return (
        <div className="summary-view">
            <div className="summary-item">
                <h2>Delivery type</h2>
                {currentDocument.deliveryType === DOCUMENT_DELIVERY_TYPE_SMS && (
                    <div className="icon-group">
                        <Icon icon={Icons.SMS_DELIVERY_V2} /> SMS
                    </div>
                )}
                {currentDocument.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL && (
                    <div className="icon-group">
                        <Icon icon={Icons.EMAIL_DELIVERY_V2} /> EMAIL
                    </div>
                )}
                {currentDocument.deliveryType === DOCUMENT_DELIVERY_TYPE_QR_CODE && (
                    <div className="icon-group">
                        <Icon icon={Icons.QR_CODE} /> QR CODE / LINK
                    </div>
                )}
            </div>
            <div className="summary-item">
                <h2>Acknowledgement</h2>
                {currentDocument.confirmationType === SIGN && (
                    <div className="icon-group">
                        <GestureSharpIcon /> SIGN
                    </div>
                )}
                {currentDocument.confirmationType === ACKNOWLEDGE && (
                    <div className="icon-group">
                        <CheckSharpIcon /> ACKNOWLEDGE
                    </div>
                )}
            </div>
            <div className="client-summary summary-item">
                <h2>Recipients</h2>
                <div className="client-item-group">
                    {currentDocument.client?.clients?.map((client, index) => {
                        return (
                            <div key={index} className="client-item">
                                <p className="strong">{client.name}</p>
                                <p>{client.email}</p>
                                <p>{client.phone}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="summary-item">
                <h2>Document instructions</h2>
                <p>{currentDocument.descriptionText}</p>
            </div>
            <div className="summary-item">
                <h2>Documents</h2>
                {renderUploadedDocuments()}
            </div>
            {currentDocument.customPlaceholders && currentDocument.customPlaceholders.length > 0 && (
                <div className="summary-item">
                    <h2>Custom info placeholders</h2>
                    {currentDocument.customPlaceholders.map((placeholder, index) => {
                        if (placeholder.value !== '') {
                            return (
                                <div key={index} className="summary-item__custom-info-placeholder">
                                    <p className="strong summary-item__custom-info-placeholder__item">
                                        {placeholder.label}
                                    </p>
                                    <p className="summary-item__custom-info-placeholder__item">{placeholder.value}</p>
                                </div>
                            );
                        }
                    })}
                </div>
            )}
            <div className="summary-item">
                <h2>Recipient acknowledgement statements</h2>
                <p>{currentDocument.acknowledgementText}</p>
            </div>
            {currentDocument?.listNow?.enabled && <LinkedPartnersSummary currentDocument={currentDocument} />}
            <div className="summary-item">
                <h2>Execution type</h2>
                {currentDocument.executionType === EXECUTION_TYPE_EMBEDDED_SIGNATURE && (
                    <div className="icon-group">
                        <EmbeddedSignatureIcon /> {EXECUTION_TYPE_EMBEDDED_SIGNATURE}
                    </div>
                )}
                {currentDocument.confirmationType === EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE && (
                    <div className="icon-group">
                        <AcknowledgementPageIcon /> {EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE}
                    </div>
                )}
            </div>
            {isDocumentComplete(currentDocument.status) && (
                <AgreementMainAuditTrail leaseId={currentDocument.id} isDocument />
            )}
            {isDocumentAwaitingCompletion(currentDocument.status) && (
                <AwaitingCompletionForm
                    document={currentDocument}
                    showAuditTrail={
                        !isMobileView && currentDocument.auditTrail && currentDocument.auditTrail.length > 0
                    }
                    auditTrail={<AgreementMainAuditTrail leaseId={currentDocument.id} isDocument />}
                    completionProgress={
                        isMobileView ? (
                            <MobileCompletionProgress progress={currentDocument.completion?.progress || []} />
                        ) : (
                            <CompletionProgress progress={currentDocument.completion?.progress || []} />
                        )
                    }
                />
            )}
        </div>
    );
};
export default UploadADocSummary;
