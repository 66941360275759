import React, { forwardRef, memo, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import * as Lease from '../../../reducers/lease';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormTextRegular } from '../../../components/form/FormText';
import { FormEmail } from '../../../components/form/FormEmail';
import { FormPhone } from '../../../components/form/FormPhone';

import '../../../sass/agent.scss';
import { NSW_STATE } from '../../../config';
import { getDirtyStep, getLeaseType, getLocation, getStep, getTenantsAgent } from '../../../selectors/lease';
import axios from 'axios';
import { has } from 'lodash';
const TENANT_AGENT_FORM = 'tenantAgentForm';

const Agent = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const reduxTenantsAgent = useSelector(getTenantsAgent);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [tenantsAgent, setTenantsAgent] = useState(reduxTenantsAgent);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(TENANT_AGENT_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const updateTenantsAgent = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/tenants-agent`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateTenantsAgent(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_TENANTS_AGENT_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.tenantsAgent')) {
                    return error.response.data.errors.tenantsAgent;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    function toggleDoNotHaveAgent(value, form) {
        form.reset();
        setTenantsAgent({ ...tenantsAgent, doNotHaveAgent: value });
    }

    return (
        <div className="agent">
            <Form onSubmit={submitForm} initialValues={tenantsAgent}>
                {props => {
                    let disabled = false;
                    if (tenantsAgent.doNotHaveAgent) {
                        disabled = tenantsAgent.doNotHaveAgent;
                    }
                    return (
                        <form onSubmit={props.handleSubmit} id={TENANT_AGENT_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, props.form)}
                            />
                            {location !== NSW_STATE && <div className="only-nsw-agent">This applies to NSW only.</div>}
                            <div className="names">
                                <FormTextRegular name="firstName" label="First Name" disabled={disabled} required />
                                <FormTextRegular name="secondName" label="Last Name" disabled={disabled} required />
                            </div>
                            <FormTextRegular name="address" label="Address" disabled={disabled} required />
                            <FormPhone
                                className={'tenant-agent-phone'}
                                name="phone"
                                label="Phone"
                                required
                                disabled={disabled}
                            />
                            <FormEmail name="email" label="Email" disabled={disabled} required />
                            {location === NSW_STATE && (
                                <CheckboxCheck
                                    name="doNotHaveAgent"
                                    value={false}
                                    label="No Agent"
                                    className={'do-not-have-agent'}
                                    onClick={value => {
                                        toggleDoNotHaveAgent(value, props.form);
                                    }}
                                />
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Agent);
