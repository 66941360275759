import { createSelector } from 'reselect';

export const getTermination = state => state.terminateLease;

export const getIsTerminateLeaseModalOpen = createSelector(
    [getTermination],
    terminateLease => terminateLease.isTerminateLeaseModalOpen
);

export const getIsTerminateLeaseListModalOpen = createSelector(
    [getTermination],
    terminateLease => terminateLease.isTerminateLeaseListModalOpen
);
