import cx from 'classnames';
import React from 'react';
import ToggleButtonV2 from '../../../common/components/ToggleButtonV2';
import styles from './IntegrationSearchSelect.module.scss';

export const IntegrationSearchSelect = ({
    propertyMeIntegration,
    propertyTreeIntegration,
    managedIntegration,
    setIntegrationSearch,
    isIntegrationSearchEnabled,
    className
}) => {
    const getIntegrationLabel = () => {
        if (propertyMeIntegration) {
            return 'Search Property Me?';
        } else if (propertyTreeIntegration) {
            return 'Search Property Tree?';
        } else if (managedIntegration) {
            return 'Search Managed?';
        }
    };

    return (
        <div className={cx(styles.integrationGroup, className)}>
            <ToggleButtonV2
                label={getIntegrationLabel()}
                onClick={value => setIntegrationSearch(value)}
                dataTest="IntegrationEnabled"
                checked={isIntegrationSearchEnabled}
            />
        </div>
    );
};
