import * as React from 'react';
import Notice from '../components/Notice';
import Sign from '../components/Sign';
import Button from '../components/Button';

import { saveSignature } from '../../../actions/signatures';

import '../../../sass/agentSignatures/signing.scss';
import '../../../sass/agentSignatures/footer.scss';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../../selectors/user';
import { isBusinessUserAccount } from '../../../utils/userUtils';
import { getAgentSignature } from '../../../selectors/signatures';

const AgentSignature = () => {
    const loggedInUser = useSelector(getUserInfo);
    const agentSignature = useSelector(getAgentSignature);

    const dispatch = useDispatch();
    let [isOpenSignModal, setIsOpenSignModal] = useState(false);

    useEffect(() => {
        setIsOpenSignModal(false);
    }, []);

    const openSignModal = () => {
        setIsOpenSignModal(true);
    };

    const closeSignModal = () => {
        setIsOpenSignModal(false);
    };

    const confirmButtonHandler = payload => {
        let agent = {
            firstName: loggedInUser.firstName,
            secondName: loggedInUser.secondName,
            type: agentSignature.type,
            signature: payload.signature
        };

        dispatch(saveSignature(agent));
    };

    const getInstructions = user => {
        if (isBusinessUserAccount(user.accountType)) {
            return (
                <p>
                    You are adding your signature to be used on documents. Only you can access and use your signature.
                </p>
            );
        }
        return (
            <p>
                You are adding your signature to be used on residential tenancy agreements, property management
                agreements and sales agreements. Only you can access and use your signature.
            </p>
        );
    };

    return (
        <div>
            <main className="signing" id="main-layout">
                <Notice>
                    <h4>INSTRUCTIONS</h4>
                    {getInstructions(loggedInUser)}
                </Notice>
            </main>
            <footer className="column">
                <div>
                    <Button onClick={openSignModal}>Ready to go!</Button>
                </div>
                <Sign
                    title={`${loggedInUser.firstName} ${loggedInUser.secondName}'s signature`}
                    isOpen={isOpenSignModal}
                    closeModal={closeSignModal}
                    confirmButtonHandler={confirmButtonHandler}
                />
            </footer>
        </div>
    );
};
export default memo(AgentSignature);
