import React from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import '../../sass/agreement-signatures-new.scss';

const cx = classnames.bind({
    expand: 'agreement-signatures__expand',
    less: 'agreement-signatures__expand--less'
});

export function Expand({ less, className, ...divProps }) {
    return <div {...divProps} className={cx('expand', { less }, className)} />;
}

Expand.propTypes = {
    className: PropTypes.string,
    less: PropTypes.bool
};

Expand.defaultProps = {
    className: '',
    less: false
};
