import axios from 'axios';
import { ADD_OR_REPLACE_KEY_IN_LIST, REMOVE_KEY_IN_LIST, UPDATE_KEY_IN_LIST } from '../reducers/dashboard';

export const OPEN_FLK_A_KEY_FORM = 'OPEN_FLK_A_KEY_FORM';
export const CLOSE_FLK_A_KEY_FORM = 'CLOSE_FLK_A_KEY_FORM';
export const OPEN_FLK_A_KEY_CHECK_IN_MODAL = 'OPEN_FLK_A_KEY_CHECK_IN_MODAL';
export const CLOSE_FLK_A_KEY_CHECK_IN_MODAL = 'CLOSE_FLK_A_KEY_CHECK_IN_MODAL';
export const OPEN_CREATE_KEY_FORM = 'OPEN_CREATE_KEY_FORM';
export const SET_CURRENT_KEY = 'SET_CURRENT_KEY';
export const CHANGE_FLK_A_KEY_FORM_EDITABLE = 'CHANGE_FLK_A_KEY_FORM_EDITABLE';
export const CHANGE_SELECTED_FLK_A_KEY = 'CHANGE_SELECTED_FLK_A_KEY';
export const CHANGE_SELECT_ALL_FLK_A_KEY = 'CHANGE_SELECT_ALL_FLK_A_KEY';
export const RESET_SELECT_FLK_A_KEY = 'RESET_SELECT_FLK_A_KEY';
export const CHANGE_FLK_A_KEY_ADDRESS_FILTER = 'CHANGE_FLK_A_KEY_ADDRESS_FILTER';
export const CHANGE_FLK_A_KEY_IMAGE = 'CHANGE_FLK_A_KEY_IMAGE';
export const OPEN_FLK_A_KEY_REMINDER_MODAL = 'OPEN_FLK_A_KEY_REMINDER_MODAL';
export const CLOSE_FLK_A_KEY_REMINDER_MODAL = 'CLOSE_FLK_A_KEY_REMINDER_MODAL';
export const CHANGE_RELOAD_KEY_LIST_STATUS = 'CHANGE_RELOAD_KEY_LIST_STATUS';

/**
 * Open FLK a Key create form.
 * @param key
 */
export function openKeyForm(key) {
    return {
        type: OPEN_CREATE_KEY_FORM,
        key
    };
}

export function setCurrentKey(currentKey) {
    return {
        type: SET_CURRENT_KEY,
        currentKey
    };
}

export function openFlkAKeyForm() {
    return {
        type: OPEN_FLK_A_KEY_FORM
    };
}

export function closeFlkAKeyForm() {
    return {
        type: CLOSE_FLK_A_KEY_FORM
    };
}

export function openFlkAKeyCheckInModal() {
    return {
        type: OPEN_FLK_A_KEY_CHECK_IN_MODAL
    };
}

export function closeFlkAKeyCheckInModal() {
    return {
        type: CLOSE_FLK_A_KEY_CHECK_IN_MODAL
    };
}

export function addOrReplaceKeyInList(flkAKey) {
    return {
        type: ADD_OR_REPLACE_KEY_IN_LIST,
        payload: { flkAKey }
    };
}

export function removeKeyInList(flkAKey) {
    return {
        type: REMOVE_KEY_IN_LIST,
        payload: { flkAKey }
    };
}

export function updateKeyInList(flkAKey, status) {
    return {
        type: UPDATE_KEY_IN_LIST,
        payload: { flkAKey, status }
    };
}

export function changeFlkAKeyFormEditable(isEditable) {
    return {
        type: CHANGE_FLK_A_KEY_FORM_EDITABLE,
        payload: { isEditable }
    };
}

export function changeSelectedKey(key, value) {
    return {
        type: CHANGE_SELECTED_FLK_A_KEY,
        payload: { key, value }
    };
}

export function changeSelectAllKey(value, selectedIdList) {
    return {
        type: CHANGE_SELECT_ALL_FLK_A_KEY,
        payload: { value, selectedIdList }
    };
}

export function resetSelectKey() {
    return {
        type: RESET_SELECT_FLK_A_KEY
    };
}

export function changeFlkAKeyAddressSearchFilter(value) {
    return {
        type: CHANGE_FLK_A_KEY_ADDRESS_FILTER,
        payload: { value }
    };
}

export function changeCurrentKeyImage(compressedImageUrls, images) {
    return {
        type: CHANGE_FLK_A_KEY_IMAGE,
        payload: { compressedImageUrls, images }
    };
}

export function openFlkAKeyReminderModal(flkAKey) {
    return {
        type: OPEN_FLK_A_KEY_REMINDER_MODAL,
        payload: {
            flkAKey
        }
    };
}

export function closeFlkAKeyReminderModal() {
    return {
        type: CLOSE_FLK_A_KEY_REMINDER_MODAL
    };
}

export function changeReloadKeyListStatus(value) {
    return {
        type: CHANGE_RELOAD_KEY_LIST_STATUS,
        payload: {
            value
        }
    };
}
