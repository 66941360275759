import React, { Component } from 'react';

export default class ContactTittle extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <span className="data-contact">{this.props.title}</span>;
    }
}
