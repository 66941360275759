import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CREATE_A_FLK_DISPLAY, FLK_A_PDF_DISPLAY, routes } from '../../config';
import { NavLink } from 'react-router-dom';
import { isRouteAllowed } from '../../utils/userPermissions';
import '../../sass/sideBar.scss';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import {
    AccountBalanceSharp,
    BarChartSharp,
    BorderColorSharp,
    BusinessSharp,
    ContactPhoneSharp,
    ContactSupportSharp,
    CreditCardSharp,
    FaceSharp,
    ImportExportSharp,
    LibraryBooksSharp,
    MailOutlineSharp,
    PeopleAltSharp,
    LabelSharp,
    AssessmentSharp,
    RecentActorsSharp,
    HomeWorkSharp,
    VerifiedUserSharp,
    EmailSharp,
} from '@material-ui/icons';
import { Link as LinkIcon } from '@material-ui/icons';
import { getBulkModuleActive, getUserInfo } from '../../selectors/user';
import { useLocation } from 'react-router';
import { getGeneralAppLabel } from '../../utils/labelUtils';
import { useAccountProvider } from '../../components/providers/AccountProvider';
import { isAdmin, isAgencyUserAccount } from '../../utils/userUtils';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import {
    ALL_DOCUMENTS_ALL_AGENCIES_REPORT,
    CONTACT_LIST_SETTINGS,
    MULTI_FACTOR_AUTHENTICATION,
    TEMPLATE_SCREEN_V2,
    BULK_PM_IMPORT,
    EMAIL_STUDIO
} from '../../constants/featureFlags';
import HelpContainer from '../../common/components/HelpContainer.tsx';
import { CHANGE_MOBILE_HELP } from '../../reducers/help';

const SideBar = () => {
    const startLocation = useLocation();
    const accountType = useAccountProvider();
    const isMFA = useFeatureFlag(MULTI_FACTOR_AUTHENTICATION);
    const isTemplateScreenV2Active = useFeatureFlag(TEMPLATE_SCREEN_V2);
    const isAllDocumentsReportActive = useFeatureFlag(ALL_DOCUMENTS_ALL_AGENCIES_REPORT);
    const isContactListSettingsActive = useFeatureFlag(CONTACT_LIST_SETTINGS);
    const bulkImportFlag = useFeatureFlag(BULK_PM_IMPORT);
    const emailStudioFlag = useFeatureFlag(EMAIL_STUDIO);

    const doesRouteMatchAgentReport = location => {
        const pathItems = location.pathname.split('/');
        pathItems[3] = ':id';
        const route = pathItems.join('/');
        return route === routes.ROUTE_AGENT_REPORTS;
    };

    const [showSubRoutesForTemplates, setShowSubRoutesForTemplates] = useState(
        startLocation.pathname.includes(`templates`) ||
            startLocation.pathname === routes.ROUTE_SPECIAL_CONDITIONS_TEMPLATES
    );
    const [showSubRoutesForAgencyReports, setShowSubRoutesForAgencyReports] = useState(
        startLocation.pathname === routes.ROUTE_AGENCY_REPORTS || doesRouteMatchAgentReport(startLocation)
    );

    const userInfo = useSelector(getUserInfo);
    const isBulkModuleActive = useSelector(getBulkModuleActive);

    let userRole = userInfo.role;

    const hideSubRoutes = () => {
        setShowSubRoutesForTemplates(false);
        setShowSubRoutesForAgencyReports(false);
    };

    return (
        <div className="sidebar">
            {userRole && (
                <nav>
                    <ul>
                        {isRouteAllowed(userRole, routes.ROUTE_PROFILE) && (
                            <li onClick={hideSubRoutes}>
                                <HelpContainer
                                    helpId={CHANGE_MOBILE_HELP}
                                    header="Change your mobile here"
                                    message="Update your mobile number here, in Profile Settings, then come back to Security Settings to enable SMS authentication."
                                >
                                    <NavLink
                                        activeClassName="active"
                                        isActive={(match, location) => location.pathname === routes.ROUTE_PROFILE}
                                        to={routes.ROUTE_PROFILE}
                                    >
                                        <FaceSharp />
                                        <span>Profile Settings</span>
                                    </NavLink>
                                </HelpContainer>
                            </li>
                        )}
                        {isMFA && (
                            <li onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_SECURITY}
                                    to={routes.ROUTE_SECURITY}
                                >
                                    <VerifiedUserSharp />
                                    <span>Security Settings</span>
                                </NavLink>
                            </li>
                        )}

                        {isRouteAllowed(userRole, routes.ROUTE_AGENCY_SETTINGS) && (
                            <li className="account-settings" onClick={hideSubRoutes}>
                                <NavLink
                                    to={routes.ROUTE_AGENCY_SETTINGS}
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_AGENCY_SETTINGS}
                                >
                                    <AccountCircleSharpIcon />
                                    <span>Account Settings</span>
                                </NavLink>
                            </li>
                        )}
                        {isRouteAllowed(userRole, routes.ROUTE_AGENCY_DETAILS) && (
                            <li className="agency-details" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_AGENCY_DETAILS}
                                    to={routes.ROUTE_AGENCY_DETAILS}
                                >
                                    <BusinessSharp />
                                    <span>{getGeneralAppLabel('agencyDetails', accountType)}</span>
                                </NavLink>
                            </li>
                        )}
                        {(isAgencyUserAccount(accountType) || (isContactListSettingsActive && isAdmin(userRole))) &&
                            isRouteAllowed(userRole, routes.ROUTE_AGENCY_DEFAULTS) && (
                                <li className="agency-defaults" onClick={hideSubRoutes}>
                                    <NavLink
                                        activeClassName="active"
                                        isActive={(match, location) =>
                                            location.pathname === routes.ROUTE_AGENCY_DEFAULTS
                                        }
                                        to={routes.ROUTE_AGENCY_DEFAULTS}
                                    >
                                        <HomeWorkSharp />
                                        <span>Defaults</span>
                                    </NavLink>
                                </li>
                            )}
                        {isRouteAllowed(userRole, routes.ROUTE_TEAM) && (
                            <li className="team" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_TEAM}
                                    to={routes.ROUTE_TEAM}
                                >
                                    <PeopleAltSharp />
                                    <span>Team</span>
                                </NavLink>
                            </li>
                        )}
                        {isRouteAllowed(userRole, routes.ROUTE_SUBSCRIPTION) && (
                            <li className="subscription" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_SUBSCRIPTION}
                                    to={routes.ROUTE_SUBSCRIPTION}
                                >
                                    <CreditCardSharp />
                                    <span>Subscription</span>
                                </NavLink>
                            </li>
                        )}

                        {isAgencyUserAccount(accountType) &&
                            isRouteAllowed(userRole, routes.ROUTE_EMERGENCY_CONTACTS) && (
                                <li className="emergency-contacts" onClick={hideSubRoutes}>
                                    <NavLink
                                        activeClassName="active"
                                        isActive={(match, location) =>
                                            location.pathname === routes.ROUTE_EMERGENCY_CONTACTS
                                        }
                                        to={routes.ROUTE_EMERGENCY_CONTACTS}
                                    >
                                        <ContactPhoneSharp />
                                        <span>Emergency contacts</span>
                                    </NavLink>
                                </li>
                            )}
                        {isRouteAllowed(userRole, routes.ROUTE_SIGNATURES) && (
                            <li className="signatures" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_SIGNATURES}
                                    to={routes.ROUTE_SIGNATURES}
                                >
                                    <BorderColorSharp />
                                    <span>Signatures</span>
                                </NavLink>
                            </li>
                        )}
                        {!isTemplateScreenV2Active && isRouteAllowed(userRole, routes.ROUTE_AGREEMENTS_TEMPLATES) && (
                            <li
                                className="templates"
                                dataTest="sidebarTemplate"
                                onClick={() => {
                                    hideSubRoutes();
                                    setShowSubRoutesForTemplates(true);
                                }}
                            >
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) =>
                                        location.pathname.includes(`templates`) ||
                                        location.pathname === routes.ROUTE_SPECIAL_CONDITIONS_TEMPLATES
                                    }
                                    to={
                                        isAgencyUserAccount(accountType)
                                            ? routes.ROUTE_AGREEMENTS_TEMPLATES
                                            : routes.ROUTE_CREATE_A_FLK_TEMPLATES
                                    }
                                >
                                    <LibraryBooksSharp />
                                    <span>Templates</span>
                                </NavLink>
                                {showSubRoutesForTemplates && (
                                    <ul>
                                        {isAgencyUserAccount(accountType) && (
                                            <li
                                                onClick={() => {
                                                    setShowSubRoutesForTemplates(true);
                                                }}
                                            >
                                                <NavLink
                                                    activeClassName="active"
                                                    isActive={(match, location) =>
                                                        location.pathname === routes.ROUTE_AGREEMENTS_TEMPLATES
                                                    }
                                                    to={routes.ROUTE_AGREEMENTS_TEMPLATES}
                                                >
                                                    <LabelSharp />
                                                    <span>Agreements</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {isAgencyUserAccount(accountType) && (
                                            <li
                                                onClick={() => {
                                                    setShowSubRoutesForTemplates(true);
                                                }}
                                            >
                                                <NavLink
                                                    activeClassName="active"
                                                    isActive={(match, location) =>
                                                        location.pathname === routes.ROUTE_SPECIAL_CONDITIONS_TEMPLATES
                                                    }
                                                    to={routes.ROUTE_SPECIAL_CONDITIONS_TEMPLATES}
                                                >
                                                    <LabelSharp />
                                                    <span>Special conditions</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        <li
                                            onClick={() => {
                                                setShowSubRoutesForTemplates(true);
                                            }}
                                        >
                                            <NavLink
                                                activeClassName="active"
                                                isActive={(match, location) =>
                                                    location.pathname === routes.ROUTE_CREATE_A_FLK_TEMPLATES
                                                }
                                                to={routes.ROUTE_CREATE_A_FLK_TEMPLATES}
                                            >
                                                <LabelSharp />
                                                <span>{CREATE_A_FLK_DISPLAY}</span>
                                            </NavLink>
                                        </li>
                                        <li
                                            onClick={() => {
                                                setShowSubRoutesForTemplates(true);
                                            }}
                                        >
                                            <NavLink
                                                activeClassName="active"
                                                isActive={(match, location) =>
                                                    location.pathname === routes.ROUTE_FLK_A_PDF_TEMPLATES
                                                }
                                                to={routes.ROUTE_FLK_A_PDF_TEMPLATES}
                                            >
                                                <LabelSharp />
                                                <span>{FLK_A_PDF_DISPLAY}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        )}
                        {isAgencyUserAccount(accountType) && isRouteAllowed(userRole, routes.ROUTE_PAYMENTS) && (
                            <li className="payment-methods" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_PAYMENTS}
                                    to={routes.ROUTE_PAYMENTS}
                                >
                                    <AccountBalanceSharp />
                                    <span>Payment methods</span>
                                </NavLink>
                            </li>
                        )}
                        {isAgencyUserAccount(accountType) && isRouteAllowed(userRole, routes.ROUTE_DOCUMENT_LINKS) && (
                            <li className="payment-methods" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_DOCUMENT_LINKS}
                                    to={routes.ROUTE_DOCUMENT_LINKS}
                                >
                                    <LinkIcon />
                                    <span>Default documents/links</span>
                                </NavLink>
                            </li>
                        )}
                        {isRouteAllowed(userRole, routes.ROUTE_INTEGRATIONS) && (
                            <li className="integrations" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_INTEGRATIONS}
                                    to={routes.ROUTE_INTEGRATIONS}
                                >
                                    <ImportExportSharp />
                                    <span>Integrations</span>
                                </NavLink>
                            </li>
                        )}
                        {isAllDocumentsReportActive && isRouteAllowed(userRole, routes.ROUTE_ALL_DOCUMENTS) && (
                            <li className="all-documents" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_ALL_DOCUMENTS}
                                    to={routes.ROUTE_ALL_DOCUMENTS}
                                >
                                    <AssessmentSharp />
                                    <span>Report</span>
                                </NavLink>
                            </li>
                        )}
                        {isBulkModuleActive && (
                            <React.Fragment>
                                {isAgencyUserAccount(accountType) &&
                                    isRouteAllowed(userRole, routes.ROUTE_CONTACTS) && (
                                        <li className="contacts" onClick={hideSubRoutes}>
                                            <NavLink
                                                activeClassName="active"
                                                isActive={(match, location) =>
                                                    location.pathname === routes.ROUTE_CONTACTS
                                                }
                                                to={routes.ROUTE_CONTACTS}
                                            >
                                                <RecentActorsSharp />
                                                <span>Contacts</span>
                                            </NavLink>
                                        </li>
                                    )}
                            </React.Fragment>
                        )}
                        {isAgencyUserAccount(accountType) && bulkImportFlag !== 'Off' && (
                            <React.Fragment>
                                {isRouteAllowed(userRole, routes.ROUTE_BULK_IMPORT) && (
                                    <li className="bulk-import" onClick={hideSubRoutes}>
                                        <NavLink
                                            activeClassName="active"
                                            isActive={(match, location) =>
                                                location.pathname === routes.ROUTE_BULK_IMPORT
                                            }
                                            to={routes.ROUTE_BULK_IMPORT}
                                        >
                                            <RecentActorsSharp />
                                            <span>Bulk Import</span>
                                        </NavLink>
                                    </li>
                                )}
                            </React.Fragment>
                        )}
                        {isAgencyUserAccount(accountType) && emailStudioFlag !== 'Off' && (
                            <React.Fragment>
                                {isRouteAllowed(userRole, routes.ROUTE_EMAIL_STUDIO) && (
                                    <li className="email-studio" onClick={hideSubRoutes}>
                                        <NavLink
                                            activeClassName="active"
                                            isActive={(match, location) =>
                                                location.pathname === routes.ROUTE_EMAIL_STUDIO
                                            }
                                            to={routes.ROUTE_EMAIL_STUDIO}
                                        >
                                            <EmailSharp />
                                            <span>Email Studio</span>
                                        </NavLink>
                                    </li>
                                )}
                            </React.Fragment>
                        )}
                    </ul>
                    <ul>
                        {isRouteAllowed(userRole, routes.ROUTE_CUSTOMER_SUPPORT) && (
                            <li className="customer-support" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_CUSTOMER_SUPPORT}
                                    to={routes.ROUTE_CUSTOMER_SUPPORT}
                                >
                                    <ContactSupportSharp />
                                    <span>Help</span>
                                </NavLink>
                            </li>
                        )}
                        {isAgencyUserAccount(accountType) && (
                            <li className="statistics" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_STATISTICS}
                                    to={routes.ROUTE_STATISTICS}
                                >
                                    <BarChartSharp />
                                    <span>Statistics</span>
                                </NavLink>
                            </li>
                        )}
                        {isAgencyUserAccount(accountType) && isRouteAllowed(userRole, routes.ROUTE_REPORTS) && (
                            <li className="email-reports" onClick={hideSubRoutes}>
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_REPORTS}
                                    to={routes.ROUTE_REPORTS}
                                >
                                    <MailOutlineSharp />
                                    <span>Email Reports</span>
                                </NavLink>
                            </li>
                        )}
                        {isAgencyUserAccount(accountType) && isRouteAllowed(userRole, routes.ROUTE_AGENCY_REPORTS) && (
                            <li
                                className="reports"
                                onClick={() => {
                                    hideSubRoutes();
                                    setShowSubRoutesForAgencyReports(true);
                                }}
                            >
                                <NavLink
                                    activeClassName="active"
                                    isActive={(match, location) => location.pathname === routes.ROUTE_AGENCY_REPORTS}
                                    to={routes.ROUTE_AGENCY_REPORTS}
                                >
                                    <AssessmentSharp />
                                    <span>Agency Reports</span>
                                </NavLink>
                                {isAgencyUserAccount(accountType) && showSubRoutesForAgencyReports && (
                                    <ul>
                                        <li
                                            onClick={() => {
                                                setShowSubRoutesForAgencyReports(true);
                                            }}
                                        >
                                            <NavLink
                                                activeClassName="active"
                                                isActive={(match, location) => {
                                                    return doesRouteMatchAgentReport(location);
                                                }}
                                                to={routes.ROUTE_AGENT_REPORTS.replace(':id', userInfo.id)}
                                            >
                                                <LabelSharp />
                                                <span>Agent report</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        )}
                    </ul>
                </nav>
            )}
        </div>
    );
};

export default memo(SideBar);
