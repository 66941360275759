import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import ModalViewMaterialFacts from '../../../../../components/modals/viewMaterialFacts/ModalViewMaterialFacts';
import ModalViewOtherDisclosures from '../../../../../components/modals/viewMaterialFacts/ModalViewOtherDisclosures';

import { useState } from 'react';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';

const AgreementResidentialMaterialFacts = ({ leaseAllInfo }) => {
    const [isOpenModalViewMaterialFacts, setIsOpenModalViewMaterialFacts] = useState(false);
    const [isOpenModalViewOtherDisclosures, setIsOpenModalViewOtherDisclosures] = useState(false);

    const showMaterialFactsModal = () => {
        setIsOpenModalViewMaterialFacts(true);
    };

    const closeMaterialFactsModal = () => {
        setIsOpenModalViewMaterialFacts(false);
    };
    const showOtherDisclosuresModal = () => {
        setIsOpenModalViewOtherDisclosures(true);
    };

    const closeOtherDisclosuresModal = () => {
        setIsOpenModalViewOtherDisclosures(false);
    };

    return (
        <AgreementSection
            title="Material Facts"
            step={getResTenStepNo(leaseAllInfo.location, 'materialFacts', leaseAllInfo.subLeaseType)}
            leaseAllInfo={leaseAllInfo}
        >
            <div>
                <p>
                    <span className="material-link" onClick={showMaterialFactsModal}>
                        View material facts
                    </span>
                </p>
                <p>
                    <span className="material-link" onClick={showOtherDisclosuresModal}>
                        View other disclosures
                    </span>
                </p>
            </div>

            <ModalViewOtherDisclosures
                isOpen={isOpenModalViewOtherDisclosures}
                closeModal={closeOtherDisclosuresModal}
                otherDisclosures={leaseAllInfo.materialFacts.otherDisclosures}
            />
            <ModalViewMaterialFacts
                isOpen={isOpenModalViewMaterialFacts}
                closeModal={closeMaterialFactsModal}
                materialFacts={leaseAllInfo.materialFacts}
            />
        </AgreementSection>
    );
};

export default AgreementResidentialMaterialFacts;
