import cx from 'classnames';
import React from 'react';
import Button from '../../common/components/Button.js';

import styles from './SignUpButtons.module.scss';

interface SignUpButtonsProps<T> {
    options: {
        value: T;
        label: string;
        icon: React.ReactNode;
    }[];
    selectedValue?: T;
    onSelect: (value: T) => void;
}

function SignUpButtons<T>({ options, selectedValue, onSelect }: SignUpButtonsProps<T>): React.ReactElement {
    return (
        <div className={styles.container}>
            {options.map(option => (
                <Button
                    key={option.value}
                    className={cx(styles.button, {
                        [styles.selected]: selectedValue === option.value
                    })}
                    onClick={() => onSelect(option.value)}
                    startIcon={option.icon}
                >
                    {option.label}
                </Button>
            ))}
        </div>
    );
}

export default SignUpButtons;
