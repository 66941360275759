import React from 'react';
import { find } from 'lodash';
import { FormTextRegular } from './../../../form/FormText';
import { FormRadioGroup } from './../../../form/FormRadioGroup';
import { qldDefaultServices } from '../../../../config';
const serviceDefault = {
    name: '',
    mustPay: null, // Boolean - If the tenant must pay for the service
    individuallyMetred: null, // Boolean - If the tenant must pay for the service then is the service individually metred
    costApportionment: '', // If the service is not individually metred then what must the tenant pay for
    directToSupplier: null, // Boolean - If the payment method is direct to the supplier
    howToPay: '', // If the payment method is not direct to the supplier then how must they pay for the service
    locked: false // Locked services cannot be removed
};

export const LeaseServiceGroup = ({ index, field, service, removeService, error }) => {
    return (
        <div className="service">
            <span className="removeService">
                <h3>{service.locked ? service.name : 'Service'}</h3>
                {!service.locked && !find(qldDefaultServices, { name: service.name }) && (
                    <button type="button" onClick={() => removeService(index)}>
                        <span className="span-remove">Remove</span>
                    </button>
                )}
            </span>
            <div className="formBox">
                <div className="nowrap fields-full-width-for-mobile">
                    <FormTextRegular
                        readOnly={service.locked}
                        name={`premiseServices.premiseServices[${index}].name`}
                        value={service.name}
                        label="Service Name"
                    />
                    <FormRadioGroup
                        label="Tenant Must Pay:"
                        name={`premiseServices.premiseServices[${index}].mustPay`}
                        radioGroupClass="mustPay"
                        data={[
                            {
                                label: 'Yes',
                                value: 'yes',
                                id: `premiseServices${index}yes`
                            },
                            {
                                label: 'No',
                                value: 'no',
                                id: `premiseServices${index}no`
                            }
                        ]}
                    />
                </div>
                {service.mustPay === 'yes' && (
                    <div className="with-margin">
                        <FormRadioGroup
                            label="Billing Type:"
                            name={`premiseServices.premiseServices[${index}].individuallyMetred`}
                            data={[
                                {
                                    label: 'Individually metered',
                                    value: 'yes',
                                    id: `individuallyMetredpremiseServices${index}yes`
                                },
                                {
                                    label: 'Other',
                                    value: 'no',
                                    id: `individuallyMetredpremiseServices${index}no`
                                }
                            ]}
                        />
                        {service.individuallyMetred === 'no' && (
                            <FormTextRegular
                                name={`premiseServices.premiseServices[${index}].costApportionment`}
                                value={service.costApportionment}
                                label="Insert tenant portion of the cost"
                            />
                        )}
                        <FormRadioGroup
                            label="Payment Method:"
                            name={`premiseServices.premiseServices[${index}].directToSupplier`}
                            data={[
                                {
                                    label: 'As account holder direct to the supplier',
                                    value: 'yes',
                                    id: `directToSupplierpremiseServices${index}yes`
                                },
                                {
                                    label: 'Other',
                                    value: 'no',
                                    id: `directToSupplierpremiseServices${index}no`
                                }
                            ]}
                        />
                        {service.directToSupplier === 'no' && (
                            <FormTextRegular
                                name={`premiseServices.premiseServices[${index}].howToPay`}
                                value={service.howToPay}
                                label="Insert payment method"
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
