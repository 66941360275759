import React from 'react';
import cx from 'classnames';

import { CustomPlaceholderRespondentType } from '../../../../types/UploadADoc';
import { getCustomPlaceholderEmptyText } from '../FlkAPdf/utils';
import useComponentId from '../../../../hooks/useComponentId';
import Tooltip from '../../../../common/components/tooltips/Tooltip';

import styles from './CustomPlaceholder.module.scss';
import variables from '../../../../sass/variables.scss';

type CustomPlaceholderProps = {
    number: number;
    label: string;
    value?: string;
    // This is only optional because the feature flag may be disabled
    respondentType?: CustomPlaceholderRespondentType;
    className?: string;
    disabled?: boolean;
    hideTooltip?: boolean;
};

const CustomPlaceholder: React.FC<CustomPlaceholderProps> = ({
    number,
    label,
    value,
    respondentType = CustomPlaceholderRespondentType.SENDER,
    className,
    disabled,
    hideTooltip
}) => {
    const labelRef = React.useRef<HTMLDivElement>(null);
    const valueRef = React.useRef<HTMLDivElement>(null);

    const componentId = useComponentId();
    const labelTooltipId = `label-tooltip-${componentId}`;
    const valueTooltipId = `value-tooltip-${componentId}`;

    const labelOverflow = labelRef.current && labelRef.current.scrollWidth > labelRef.current.clientWidth;
    const valueOverflow = valueRef.current && valueRef.current.scrollWidth > valueRef.current.clientWidth;

    return (
        <div
            className={cx(styles.container, className, {
                [styles.disabled]: disabled,
                [styles.sender]: respondentType === CustomPlaceholderRespondentType.SENDER,
                [styles.client]: respondentType === CustomPlaceholderRespondentType.CLIENT
            })}
        >
            {/* This background is for the number and label. It was originally added to have a smooth gradient behind the two divs */}
            <div className={cx(styles.background)} />
            <div className={styles.number}>{number}</div>
            <div className={styles.label} ref={labelRef} data-tip={label} data-for={labelTooltipId} data-iscapture>
                {label}
            </div>
            <div
                className={cx(styles.value, { [styles.empty]: !value })}
                ref={valueRef}
                data-tip={value}
                data-for={valueTooltipId}
            >
                {value || getCustomPlaceholderEmptyText(respondentType)}
            </div>
            {valueOverflow && !hideTooltip && (
                <Tooltip
                    id={valueTooltipId}
                    place="bottom"
                    className={styles.tooltip}
                    hideArrow
                    backgroundColor={variables.blue100}
                />
            )}
            {labelOverflow && !hideTooltip && (
                <Tooltip
                    id={labelTooltipId}
                    place="bottom"
                    className={styles.tooltip}
                    hideArrow
                    backgroundColor={variables.blue100}
                />
            )}
        </div>
    );
};

export default CustomPlaceholder;
