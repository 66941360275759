import React from 'react';
import { Table, Th, Td, Thead, Tr } from 'reactable';
import LeaseDropdown from '../../../../dropdowns/LeaseDropdown';
import AgentLogo from '../../../../AgentLogo';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { isLeaseLocationQLD } from '../../../../../utils/agreementUtils';
import { isAccountEnabled } from '../../../../../utils/userUtils';

const TableSalesArchived = ({ leaseStatus, agreementList, showModalInfoAgreement }) => {
    const loggedInUser = useSelector(getUserInfo);
    const tableHead = () => {
        return (
            <Thead>
                <Th column="agentLogo"> </Th>
                <Th column="isRenewal"> </Th>
                <Th column="address">address</Th>
                <Th column="agent">agent</Th>
                <Th column="tenants">vendor(s)</Th>
                <Th column="startDate">agreement start date</Th>
                <Th column="term">term</Th>
                <Th column="finishDate">finish date</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
        );
    };
    return (
        <Table>
            {tableHead()}
            {agreementList &&
                agreementList.map((lease, key) => {
                    const loadingClassName = lease.isLoading ? 'loading' : '';
                    return (
                        <Tr
                            key={key}
                            className={`showmodal ${loadingClassName}`}
                            onClick={() => {
                                showModalInfoAgreement(lease);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={lease.agent} />
                            </Td>
                            <Td column="address">
                                <b>{lease.address}</b>
                            </Td>
                            <Td column="agent">{lease.agent.fullName}</Td>
                            <Td column="tenants">{lease.signatory ? lease.signatory.signatoryListText : '_'}</Td>
                            <Td column="startDate">
                                {lease.term && lease.term.startDate
                                    ? formatDateStandard(lease.term.startDate, getAgencyTimezoneFromUser(loggedInUser))
                                    : '-'}
                            </Td>
                            <Td column="term">{lease?.term?.termPeriod || '-'}</Td>
                            <Td column="finishDate">
                                {lease.term && lease.term.endDate
                                    ? formatDateStandard(lease.term.endDate, getAgencyTimezoneFromUser(loggedInUser))
                                    : '-'}
                            </Td>
                            {isAccountEnabled(loggedInUser) && (
                                <Td column="action">
                                    <div className="action">
                                        <LeaseDropdown lease={lease} leaseStatus={leaseStatus}>
                                            <div className="more-action" />
                                        </LeaseDropdown>
                                        <span className="arrow-right" />
                                    </div>
                                </Td>
                            )}
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default TableSalesArchived;
