import {
    OPEN_RETRACT_LEASE_MODAL,
    CLOSE_RETRACT_LEASE_MODAL,
    OPEN_CANCEL_FUTURE_ALERTS_MODAL,
    CLOSE_CANCEL_FUTURE_ALERTS_MODAL,
    CLOSE_NO_LICENSEE_NUMBER_MODAL,
    OPEN_NO_LICENSEE_NUMBER_MODAL
} from '../reducers/confirmModals';

export function openRetractLeaseConfirmModal(lease) {
    return {
        type: OPEN_RETRACT_LEASE_MODAL,
        lease
    };
}

export function closeRetractLeaseConfirmModal() {
    return {
        type: CLOSE_RETRACT_LEASE_MODAL
    };
}

export function openCancelFutureAlertsConfirmModal(lease) {
    return {
        type: OPEN_CANCEL_FUTURE_ALERTS_MODAL,
        lease
    };
}

export function closeCancelFutureAlertsConfirmModal() {
    return {
        type: CLOSE_CANCEL_FUTURE_ALERTS_MODAL
    };
}

export function closeNoLicenseeNumberModal() {
    return {
        type: CLOSE_NO_LICENSEE_NUMBER_MODAL
    };
}

export function openNoLicenseeNumberModal() {
    return {
        type: OPEN_NO_LICENSEE_NUMBER_MODAL
    };
}
