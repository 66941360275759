import React, { memo } from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import '../../../sass/react-tabs.scss';

import CreateFlkTemplate from './CreateFlkTemplate';
import { CREATE_A_FLK_GLOBAL_TEMPLATE, CREATE_A_FLK_TEMPLATE, FLK_TEMPLATE_LABEL } from '../../../config';
import { getGeneralAppLabel } from '../../../utils/labelUtils';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../selectors/user';

const CreateFlkTemplateTabs = ({ onTabChange }) => {
    const loggedInUser = useSelector(getUserInfo);
    return (
        <div className="settings template-tabs">
            <Tabs onSelect={(index) => {
                if (onTabChange) {
                    onTabChange(index === 0 ? CREATE_A_FLK_TEMPLATE: CREATE_A_FLK_GLOBAL_TEMPLATE);
                }
            }}>
                <TabList>
                    <Tab>
                        <span data-test="Agency Template Tab">
                            {getGeneralAppLabel('agency_templates', loggedInUser.accountType)}
                        </span>
                    </Tab>
                    <Tab>
                        <span data-test="FLK Template Tab">{FLK_TEMPLATE_LABEL}</span>
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="wrapper">
                        <CreateFlkTemplate templateType={CREATE_A_FLK_TEMPLATE} />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="wrapper">
                        <CreateFlkTemplate templateType={CREATE_A_FLK_GLOBAL_TEMPLATE} />
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default memo(CreateFlkTemplateTabs);
