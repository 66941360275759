import React from 'react';
import cx from 'classnames';
import styles from './RentAmount.module.scss';

type RentAmountProps = {
    label: string;
    value: string;
    className?: string;
};
const RentAmount: React.FC<RentAmountProps> = ({ label, value, className }) => {
    return (
        <div className={cx(styles.rentAmount, className)}>
            <p className={styles.label}>{label}</p>
            <div className={styles.field}>{value}</div>
        </div>
    );
};

export default RentAmount;
