import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';

const HOUR = 1000 * 60 * 60;
const HALF_HOUR = HOUR / 2;

const UpdateContext = createContext(null);

function getBaseUrl() {
    const { hostname, port } = window.location;
    const url = ['//', hostname];

    if (port) {
        url.push(`:${port}`);
    }

    return url.join('');
}

/**
 * Invokes a callback every X within the hour.
 *
 * @example
 *   // every 20 minutes
 *   onEveryXHour(1000 * 60 * 20, () => console.log(new Date()));
 *   // 12:00, 12:20, 12:40, 13:00, ...
 *
 *   // every half hour
 *   onEveryXHour(1000 * 60 * 30, () => console.log(new Date()));
 *   // 12:00, 12:30, 13:00, ...
 *
 * @param {number} interval The frequency per hour the interval should run.
 * @param {function} cb Callback to invoke.
 * @return {function} The off function to stop the interval.
 */
function onEveryXHour(interval, cb) {
    const now = new Date();
    const minutes = now.getMinutes() + now.getSeconds() + now.getMilliseconds();
    const first = minutes < interval ? interval - minutes : interval - (minutes % interval);

    let int = setTimeout(() => {
        cb();
        int = setInterval(cb, interval);
    }, first);

    return () => {
        clearTimeout(int);
        clearInterval(int);
    };
}

export function useUpdate() {
    return useContext(UpdateContext);
}

export function UpdateProvider({ interval = HALF_HOUR, children }) {
    if (HOUR % interval) {
        throw new Error('Interval should divide by 1 hour evenly.');
    }

    const [update, setUpdate] = useState(false);
    const $interval = useRef(null);

    const check = () =>
        axios
            .get('/build.json', {
                baseURL: getBaseUrl()
            })
            .then(({ data }) => {
                if (parseInt(data) !== parseInt(BUILD_NUMBER)) {
                    setUpdate(true);
                }
            })
            .catch(error => {
                // if the file can not be found, don't show an error (this happens in CI)
                if (error.response && error.response.status) {
                    if (error.response.status !== 404) {
                        return;
                    }
                } else {
                    throw error;
                }
            });

    // kick-start a check on mount
    useEffect(() => {
        if (BUILD_NUMBER !== 'local') {
            check();
        }
    }, []);

    useEffect(() => {
        // we don't check updates on local builds
        if (BUILD_NUMBER === 'local') {
            return;
        }

        // clear current check
        if ($interval.current) {
            $interval.current();
        }

        // update already available
        if (!update) {
            $interval.current = onEveryXHour(interval, check);
        }
    }, [interval, update]);

    return <UpdateContext.Provider value={update}>{children}</UpdateContext.Provider>;
}
