import rollbar from './rollbar/rollbar';
import TagManager from 'react-gtm-module';

const vendor = []; //rollbar

const tagManagerArgs = {
    gtmId: `${GTM_ID}`
};

export const initVendor = () => {
    vendor.map(vendorInit => vendorInit());
};

if (GTM_ID) {
    TagManager.initialize(tagManagerArgs);
}
