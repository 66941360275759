export const WOULD_LIKE_TO_RENEW = 'yes';
export const IS_UNSURE = 'unsure';
export const PLANS_TO_VACATE = 'no';
export const NO_RESPONSE = 'no-response';

export const getResponse = renewalIntention => {
    if (!renewalIntention.isTenantRenewing) {
        return 'Renewal intention sent, awaiting response';
    }

    if (renewalIntention.isTenantRenewing === NO_RESPONSE) {
        return 'No response was received';
    } else if (renewalIntention.isTenantRenewing === PLANS_TO_VACATE) {
        return 'Tenant has indicated they do not want to renew the lease';
    } else if (renewalIntention.isTenantRenewing === IS_UNSURE) {
        return "Tenant has indicated they don't know yet whether they want to renew";
    } else if (renewalIntention.isTenantRenewing === WOULD_LIKE_TO_RENEW) {
        if (!renewalIntention.responseForNewLeaseTerm) {
            return `Tenant has indicated they would like to renew the lease`;
        }
        return `Tenant has indicated they would like to renew the lease, tenant's preferred term: ${renewalIntention.responseForNewLeaseTerm ||
            ''}`;
    }
};
