import React, { useState, useEffect } from 'react';
import EditButton from '../../common/components/EditButton';
import '../../sass/editableContent.scss';
import { isEmpty } from 'lodash';
import useOnClickOutside from 'use-onclickoutside';
import SaveSharpIcon from '@material-ui/icons/SaveSharp';

const EditableTemplateName = props => {
    let [isEditable, setIsEditable] = useState(false);
    let [templateName, setTemplateName] = useState(props.templateName);

    useEffect(() => {
        setTemplateName(props.templateName);
    }, [props.templateName]);

    const handleClickOutside = () => {
        if (isEditable) {
            saveAndClose();
        }
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    function saveAndClose() {
        if (!isEmpty(templateName.trim())) {
            setIsEditable(false);
            return props.saveContent(templateName);
        }
    }

    function changeHandler(value) {
        setTemplateName(value);
    }

    function checkEnter(e) {
        if (e.keyCode === 13) {
            saveAndClose();
        }
    }

    function onEdit() {
        setIsEditable(oldIsEditable => !oldIsEditable);
    }

    if (isEditable) {
        return (
            <div className="header-editable">
                <div className="FormInput">
                    <div className="form-group on">
                        <label>Description</label>
                        <input
                            type="text"
                            name="description"
                            value={templateName}
                            onChange={e => changeHandler(e.target.value)}
                            onKeyDown={e => checkEnter(e)}
                            ref={ref}
                        />
                    </div>
                </div>
                <button className="btn save-button" data-test="edit-save-button" onClick={saveAndClose}>
                    <SaveSharpIcon />
                </button>
            </div>
        );
    } else {
        return (
            <div>
                <div onClick={onEdit} className={`header-container ${props.disabled ? 'disabled' : ''}`}>
                    <label className="select-label" htmlFor="select-agent">
                        <p>
                            <b>Template name:&nbsp;</b>
                        </p>
                    </label>
                    <div className="edit-group">
                        <p className="header-agent"> {templateName}</p>
                        <EditButton id="edit-template" dataTest="edit-template-btn" disabled={props.disabled} />
                    </div>
                </div>
            </div>
        );
    }
};

export default EditableTemplateName;
