import axios from 'axios';
import * as Contacts from './../reducers/emergencyContacts';
/**
 * @param {object} contact
 * @returns {{type, contact: *}}
 */
export function addContact(contact) {
    return {
        type: Contacts.ADD_EMERGENCY_CONTACT,
        contact
    };
}
/**
 * @param {object} contact
 * @returns {{type, contact: *}}
 */
export function updateContact(contact, templatesToUpdate) {
    return {
        type: Contacts.UPDATE_EMERGENCY_CONTACT,
        contact,
        templatesToUpdate
    };
}

export function deleteContact(contactId) {
    return {
        types: [
            Contacts.DELETE_EMERGENCY_CONTACT_REQUEST,
            Contacts.DELETE_EMERGENCY_CONTACT_SUCCESS,
            Contacts.DELETE_EMERGENCY_CONTACT_FAIL
        ],
        promise: axios.delete(`/api/agency/account/emergency-contacts/${contactId}`)
    };
}

export function getEmergencyContactList() {
    return {
        types: [
            Contacts.GET_EMERGENCY_CONTACT_LIST_REQUEST,
            Contacts.GET_EMERGENCY_CONTACT_LIST_SUCCESS,
            Contacts.GET_EMERGENCY_CONTACT_LIST_FAIL
        ],
        promise: axios.get('/api/agency/account/emergency-contacts')
    };
}

export function closeEmergencyContactModal() {
    return {
        type: Contacts.CLOSE_MODAL_EMERGENCY_CONTACT
    };
}

export function openEmergencyContactModal(type, values) {
    return {
        type: Contacts.OPEN_MODAL_EMERGENCY_CONTACT,
        data: { type, values }
    };
}

export function clearContactsTemplatesToUpdate() {
    return {
        type: Contacts.CLEAR_CONTACTS_TEMPLATES_TO_UPDATE
    };
}
