import React from 'react';

import { FormTextRegular, FormTextCurrency } from '../../../../form/FormText';

export const ChargesGroup = ({ index, removeCharge, location }) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Charge {index + 1}</h3>
                    <div className="toggle" onClick={() => removeCharge(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular name={`chargeList[${index}].description`} label="Description" required />
                    <FormTextRegular name={`chargeList[${index}].amount`} label="Amount" required />
                    {location === 'QLD' && <FormTextRegular name={`chargeList[${index}].payable`} label="Payable" />}
                </div>
            </div>
        </div>
    );
};
