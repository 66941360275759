import * as React from 'react';
import { map } from 'lodash';
import { CheckboxCheck } from '../../form/FormCheckboxCheck.js';
import { isAgencyUserAccount, isBusinessUserAccount } from '../../../utils/userUtils.js';
import { FormApi } from 'final-form';

interface UserPermissionsProps {
    team: any[];
    // @todo replace with user type from parent component
    user?: {
        fullName: string;
    };
    // @todo replace with user type
    loggedInUser?: {
        accountType: string;
    };
    formChange: FormApi['change'];
}

const UserPermissions: React.FC<UserPermissionsProps> = ({ team, user, loggedInUser, formChange }) => {
    return (
        <>
            <div className="team-modal-permissions">
                <h3>Authorise Users</h3>
                {loggedInUser && isBusinessUserAccount(loggedInUser.accountType) && (
                    <p className="info">
                        Select a user below to allow {user?.fullName || 'this user'} to view and edit their work in FLK
                    </p>
                )}
                {loggedInUser && isAgencyUserAccount(loggedInUser.accountType) && (
                    <>
                        <p className="info">
                            Selecting a user below will allow their agreements and documents to be seen and edited by{' '}
                            {user?.fullName || 'this user'}
                        </p>
                        <CheckboxCheck
                            name="selectAll"
                            label="Select All"
                            onClick={(selected: boolean) => {
                                team.forEach(value => {
                                    formChange(`hasPermissionTo.${value.id}`, selected);
                                });
                            }}
                        />
                    </>
                )}
                {map(team, value => {
                    return <CheckboxCheck key={value.id} name={`hasPermissionTo.${value.id}`} label={value.fullName} />;
                })}
            </div>

            <div className="team-modal-permissions mt-25">
                <h3>Enable for selected users</h3>
                <CheckboxCheck
                    name={`addToAll`}
                    label={`Add ${
                        user ? user?.fullName : 'this user'
                    } to the selected accounts above to view and edit their agreements and documents`}
                />
            </div>
        </>
    );
};

export default UserPermissions;
