import React from 'react';
import ModalDialog from '../../common/components/ModalDialog';
import EditLandlord from './EditLandlord';
import '../../sass/editModal.scss';
import { getLabel } from '../../utils/labelUtils';
import { getLocation } from '../../selectors/lease';
import { useSelector } from 'react-redux';
import { LEASE_TYPE_PROPERTY_MANAGEMENT } from '../../config';

const EditLandlordsModal = ({ isOpen, closeEditLandlordsModal }) => {
    const location = useSelector(getLocation);
    return (
        <ModalDialog
            title={`Edit ${getLabel('Landlords', location, LEASE_TYPE_PROPERTY_MANAGEMENT)}`}
            isOpen={isOpen}
            closeModal={closeEditLandlordsModal}
            shouldCloseOnOverlayClick={false}
            className="edit-signatory-modal"
        >
            <div>
                <EditLandlord />
            </div>
        </ModalDialog>
    );
};

export default EditLandlordsModal;
