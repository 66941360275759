import useCountry from '@app/hooks/useCountry';
import axios from 'axios';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
    createAccountSuccess,
    createDetailsSuccess,
    loadPlanListForLanding,
    returnToPreviousStep,
    selectPlan,
    setPending,
    setTariffPlan
} from '../../actions/authorization.js';
import { Subscription, SubscriptionCardGroup } from '../../common/components/cards/SubscriptionCard';
import {
    CreateAccountForm,
    CreateAccountFormValues,
    CreateAgencyDetailsForm,
    CreateAgencyDetailsFormValues,
    CreateGeneralDetailsForm,
    CreateGeneralDetailsFormValues,
    SignUpFormContainer,
    VerifyEmail
} from '../../components/signup/index';
import {
    SIGN_UP_ACCOUNT_STEP,
    SIGN_UP_ACCOUNT_STEP_TITLE,
    SIGN_UP_ACCOUNT_STEP_TITLE_BUSINESS,
    SIGN_UP_DETAILS_STEP,
    SIGN_UP_DETAILS_STEP_TITLE,
    SIGN_UP_PRICING_STEP,
    SIGN_UP_PRICING_STEP_TITLE,
    SIGN_UP_PRICING_STEP_TITLE_BUSINESS,
    SIGN_UP_VERIFY_EMAIL_STEP,
    isMobileWidth
} from '../../config';
import { AccountType, AvailableCountries } from '../../constants/constants';
import useQueryParams from '../../hooks/useQueryParams';
import {
    getAgencyId,
    getCouponInfo,
    getIsPending,
    getIsRegistrationComplete,
    getPlans,
    getRegistrationDetails,
    getRememberMe,
    getSelectedPlan,
    getStep
} from '../../selectors/authorization.js';
import { getIsUserLoggedIn, getLoggedInUser } from '../../selectors/login/index.js';
import { getUserInfo } from '../../selectors/user.js';
import { EmailValidation } from '../../types/User.js';
import pushDetailsToHubspot from '../../utils/hubspotUtils.js';
import { toSaveToken } from '../../utils/toSaveToken.js';
import { extractUserAndAgencyInfo } from '../../utils/vendorUtils.js';
import appHistory from './../../AppHistory.js';
import styles from './SignUp.module.scss';
import { SignUpBaseLayout, SignUpLayout } from './components';

type SignUpProps = {
    accountType: AccountType;
};

const SignUp: React.FC<SignUpProps> = ({ accountType }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { boot, update, show } = useIntercom();
    const queryParams = useQueryParams();

    const plans = useSelector(getPlans);
    const selectedPlan = useSelector(getSelectedPlan);
    const step = useSelector(getStep);
    const agencyId = useSelector(getAgencyId);
    const userInfo = useSelector(getUserInfo);
    const registrationDetails = useSelector(getRegistrationDetails);
    const isUserLoggedin = useSelector(getIsUserLoggedIn);
    const userFromLogin = useSelector(getLoggedInUser);
    const rememberMe = useSelector(getRememberMe);
    const couponInfo = useSelector(getCouponInfo);

    const isRegistrationComplete = useSelector(getIsRegistrationComplete);
    const isPending: boolean = useSelector(getIsPending);

    const [subscriptions, setSubscriptions] = React.useState<Subscription[] | null>(null);
    const [emailVerificationIncomplete, setEmailVerificationIncomplete] = React.useState(false);
    const [isResending, setIsResending] = React.useState(false);
    const [emailValidationResults, setEmailValidationResults] = React.useState<EmailValidation>();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Load plans on page load
    React.useEffect(() => {
        dispatch(loadPlanListForLanding(accountType, AvailableCountries.AU));
    }, [dispatch, accountType]);

    // Use planId from query params if it exists.
    React.useEffect(() => {
        const planIdFromQuery = queryParams.get('plan');
        if (planIdFromQuery) {
            // If plans have not loaded, or planIdFromQuery corresponds to a plan from the api
            if (!plans.length || plans.some(p => p.stripeId === planIdFromQuery)) {
                // Navigate to step 2 with selected plan if we are currently on step 1
                if (step === 1) handleSelectPlan(planIdFromQuery);
            }
            // Otherwise plans have loaded and planIdFromQuery does not correspond to api
            else {
                // Remove search params
                history.replace(location.pathname, {
                    search: ''
                });
                // Return to pricing page
                dispatch(returnToPreviousStep());
            }
        }
    }, [queryParams, plans, dispatch, history, location, step, handleSelectPlan]);

    // TODO: Move these to reducer once we update FLK general to use subscription cards
    React.useEffect(() => {
        const createSubscriptionsFromPlans = () => {
            // Ignoring until I add types from backend
            // @ts-ignore
            return plans?.map(p => ({
                id: p.stripeId,
                duration: 'monthly',
                size: p.title,
                price: p.price,
                hasFreeTrial: p.hasFreeTrial,
                constraintText: p.constraintText,
                additionalConstraintText: p.additionalConstraintText,
                featureCategories: p.featureCategories,
                taxDescription: p.taxDescription,
                currency: p.currency
            }));
        };

        if (plans) {
            setSubscriptions(createSubscriptionsFromPlans());
        }
    }, [plans]);

    // Copied from old signup page
    React.useEffect(() => {
        if (isUserLoggedin) {
            toSaveToken(userFromLogin, rememberMe, accountType);
        }
        // Need to check here email is validated
        if (isRegistrationComplete) {
            if (registrationDetails.user.emailVerified) {
                appHistory.push('/dashboard/agreements/residential_tenancy/draft');
            } else {
                setEmailVerificationIncomplete(true);
            }
        }
    }, [isUserLoggedin, rememberMe, isRegistrationComplete, registrationDetails]);

    // Copied from old signup page
    React.useEffect(() => {
        if (registrationDetails && !isMobileWidth(899)) {
            const user = extractUserAndAgencyInfo(registrationDetails.user, registrationDetails);
            boot({
                customAttributes: {
                    step,
                    ...user,
                    page: 'sign up, step ' + step
                }
            });
            update({
                customAttributes: {
                    step,
                    ...user,
                    page: 'sign up, step ' + step
                }
            });
            pushDetailsToHubspot(user.email);
        } else if (!isMobileWidth()) {
            boot({ customAttributes: { step, page: 'sign up, step ' + step } });
        }
    }, [boot, update, registrationDetails, step]);

    const decrementStep = () => {
        dispatch(returnToPreviousStep());
    };

    const showIntercom = () => {
        show();
    };

    const pushUserEmailVerificationTokenToIntercom = (token: string) => {
        update({
            customAttributes: {
                signupToken: token
            }
        });
    };
    const handleSelectPlan = React.useCallback(
        (planId: string) => {
            dispatch(selectPlan({ stripeId: planId }));
        },
        [dispatch, selectPlan]
    );

    /**
     * Find the plan in plans with selectedPlan so that it can be sent to the backend for an email
     * @param selectedPlan
     */
    function findPlanWithStripeId(selectedPlan) {
        const plan = plans.find(plan => {
            return plan.stripeId === selectedPlan.stripeId;
        });
        if (!plan) {
            return false;
        }
        return plan.title;
    }

    const handleCreateAccount = async (values: CreateAccountFormValues) => {
        const additionalDataForGeneralAccount = {
            timezone: timezone
        };

        const valuesWithAdditionalData = {
            ...values,
            accountType: accountType,
            // * only purpose is so that the API can send an email with the selected plan
            selectedPlan: findPlanWithStripeId(selectedPlan),
            ...(accountType == AccountType.GENERAL_ACCOUNT_TYPE && additionalDataForGeneralAccount),
            emailValidation: emailValidationResults
        };

        dispatch(setPending(true));

        try {
            const response = await axios.post('/auth/sign-up', valuesWithAdditionalData);
            dispatch(createAccountSuccess(response.data));
            pushUserEmailVerificationTokenToIntercom(response.data.token);
        } catch (error) {
            dispatch(setPending(false));
            const response = error.response;
            return response.data.errors;
        }
    };

    const attachMarketingData = () => {
        const selectedPlanData = plans.find(p => p.stripeId === selectedPlan.stripeId);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'successfulRegistration',
            planValue: selectedPlanData.price
        });
    };

    const handleCreateDetails = async (values: CreateAgencyDetailsFormValues | CreateGeneralDetailsFormValues) => {
        const additionalDataForGeneralAccount = {
            userTimezone: timezone,
            officeTimezone: timezone,
            agencyName: values.companyName
        };

        const valuesWithAdditionalData = {
            ...values,
            accountType: accountType,
            ...(accountType === AccountType.GENERAL_ACCOUNT_TYPE && additionalDataForGeneralAccount)
        };

        dispatch(setPending(true));

        try {
            const response = await axios.post(`/api/sign-up/${agencyId}/details`, valuesWithAdditionalData);
            dispatch(createDetailsSuccess(response.data));
            attachMarketingData();
            handleSetTariffPlan();
        } catch (error) {
            dispatch(setPending(false));
            const response = error.response;
            return response.data.errors.details;
        }
    };

    const handleSetTariffPlan = () => {
        const promotionId = couponInfo?.promotionId;

        const data = { ...selectedPlan, promotionId };
        dispatch(setTariffPlan(data, agencyId));
    };

    const getUserDetails = () => {
        if (registrationDetails) {
            return registrationDetails.user;
        } else {
            return userInfo;
        }
    };

    const handleResendEmail = async () => {
        const data = {
            userId: getUserDetails().id
        };

        setIsResending(true);
        try {
            await axios.post('/auth/resend-verification-email', data);
            setIsResending(false);
            confirmAlert({
                title: '',
                message: `Verification email has been sent to ${getUserDetails().email}`,
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ]
            });
        } catch (error) {}
    };

    const getSignUpComponentByStep = (step: number) => {
        if (step === SIGN_UP_PRICING_STEP || step === SIGN_UP_ACCOUNT_STEP || step === SIGN_UP_DETAILS_STEP) {
            switch (step) {
                case SIGN_UP_PRICING_STEP:
                    return (
                        <SignUpLayout
                            onContactUs={showIntercom}
                            accountType={accountType}
                            title={
                                accountType === AccountType.AGENCY_ACCOUNT_TYPE
                                    ? SIGN_UP_PRICING_STEP_TITLE
                                    : SIGN_UP_PRICING_STEP_TITLE_BUSINESS
                            }
                        >
                            {subscriptions && (
                                <SubscriptionCardGroup
                                    subscriptions={subscriptions}
                                    handleStartTrial={planId => handleSelectPlan(planId)}
                                />
                            )}
                        </SignUpLayout>
                    );
                case SIGN_UP_ACCOUNT_STEP:
                    return (
                        <SignUpLayout
                            onContactUs={showIntercom}
                            accountType={accountType}
                            title={
                                accountType === AccountType.AGENCY_ACCOUNT_TYPE
                                    ? SIGN_UP_ACCOUNT_STEP_TITLE
                                    : SIGN_UP_ACCOUNT_STEP_TITLE_BUSINESS
                            }
                            layoutBodyClassName={styles.mobileFormBackground}
                            onlyShowChildrenForMobile
                        >
                            <SignUpFormContainer
                                onBack={() => decrementStep()}
                                currentStep={SIGN_UP_ACCOUNT_STEP - 1} //Offset because we index from 1
                                className={styles.formCard}
                            >
                                <CreateAccountForm
                                    onSubmit={handleCreateAccount}
                                    isPending={isPending}
                                    initialValues={{
                                        firstName: registrationDetails?.user?.firstName,
                                        secondName: registrationDetails?.user?.secondName,
                                        email: registrationDetails?.user?.email,
                                        phone: registrationDetails?.user?.phone
                                    }}
                                    accountType={accountType}
                                    setEmailValidationResults={setEmailValidationResults}
                                />
                            </SignUpFormContainer>
                        </SignUpLayout>
                    );
                case SIGN_UP_DETAILS_STEP:
                    return (
                        <SignUpLayout
                            onContactUs={showIntercom}
                            accountType={accountType}
                            title={SIGN_UP_DETAILS_STEP_TITLE}
                            layoutBodyClassName={styles.mobileFormBackground}
                            onlyShowChildrenForMobile
                        >
                            <SignUpFormContainer
                                hideBackButton={true}
                                className={styles.formCard}
                                onBack={() => decrementStep()}
                                currentStep={SIGN_UP_DETAILS_STEP - 1} //Offset because we index from 1
                            >
                                {accountType === AccountType.AGENCY_ACCOUNT_TYPE ? (
                                    <CreateAgencyDetailsForm
                                        onSubmit={handleCreateDetails}
                                        isPending={isPending}
                                        initialValues={{
                                            agencyName: registrationDetails?.details?.agencyName,
                                            companyName: registrationDetails?.details?.companyName,
                                            email: registrationDetails?.details?.email,
                                            address: registrationDetails?.details?.address,
                                            phone: registrationDetails?.details?.phone,
                                            location: registrationDetails?.details?.location
                                        }}
                                    />
                                ) : (
                                    <CreateGeneralDetailsForm
                                        onSubmit={handleCreateDetails}
                                        isPending={isPending}
                                        initialValues={{
                                            companyName: registrationDetails?.details?.companyName,
                                            email: registrationDetails?.details?.email,
                                            address: registrationDetails?.details?.address,
                                            phone: registrationDetails?.details?.phone
                                        }}
                                    />
                                )}
                            </SignUpFormContainer>
                        </SignUpLayout>
                    );
            }
        } else if (step === SIGN_UP_VERIFY_EMAIL_STEP || emailVerificationIncomplete) {
            // Return email component
            return (
                <SignUpBaseLayout onContactUs={showIntercom} onlyShowChildrenForMobile>
                    <VerifyEmail emailAddress={getUserDetails().email} onResendEmail={handleResendEmail} />
                </SignUpBaseLayout>
            );
        }
    };

    return getSignUpComponentByStep(step);
};

const AgencySignUp = () => <SignUp accountType={AccountType.AGENCY_ACCOUNT_TYPE} />;
const GeneralSignUp = () => <SignUp accountType={AccountType.GENERAL_ACCOUNT_TYPE} />;

export { AgencySignUp, GeneralSignUp };
