export const qldNoticeReasonSet1 = [
    {
        name: 'endOfFixedTermAgreement',
        description: 'End of a fixed term agreement'
    },
    {
        name: 'unRemediedBreachRentArrears',
        description: 'Unremedied breach – rent arrears'
    },
    {
        name: 'unRemediedBreachGeneral',
        description: 'Unremedied breach – general'
    },
    {
        name: 'nonComplianceWithTribunalOrder',
        description: 'Non-compliance with Tribunal order'
    },
    {
        name: 'nonLiveability',
        description: 'Non-liveability'
    },
    {
        name: 'compulsoryAcquisition',
        description: 'Compulsory acquisition'
    },
    {
        name: 'saleContract',
        description: 'Sale contract'
    },
    {
        name: 'significantRepairsAndRenovations',
        description: 'Significant repairs and renovations'
    },
    {
        name: 'plannedDemolitionRedevelopment',
        description: 'Planned demolition/redevelopment'
    },
    {
        name: 'endOfShortTenancy',
        description: 'End of short tenancy (moveable dwelling)'
    },
    {
        name: 'nonComplianceMoveableDwelling',
        description: 'Non-compliance (moveable dwelling relocation)'
    }
];

export const qldNoticeReasonSet2 = [
    {
        name: 'ownerOccupation',
        description: 'Owner occupation'
    },
    {
        name: 'changeOfUseOfProperty',
        description: 'Change of use of property'
    },
    {
        name: 'endingOfEntitlementToStudentAccommodation',
        description: 'Ending of entitlement to student accommodation'
    },
    {
        name: 'endingOfEntitlementUnderEmployment',
        description: 'Ending of entitlement under employment'
    },
    {
        name: 'endOfHousingAccommodationAssistance',
        description: 'End of housing/accommodation assistance'
    },
    {
        name: 'deathOfSoleTenant',
        description: 'Death of sole tenant'
    },
    {
        name: 'propertyRequiredForStateGovernmentProgram',
        description: 'Property required for State government program'
    },
    {
        name: 'seriousBreach',
        description: 'Serious breach (only applicable if lessor is the State or community housing provider)'
    },
    {
        name: 'voluntaryParkClosure',
        description: 'Voluntary park closure'
    },
    {
        name: 'compulsoryParkClosure',
        description: 'Compulsory park closure'
    }
];

export const qldNoticeReasons = [...qldNoticeReasonSet1, ...qldNoticeReasonSet2];
