import * as React from 'react';
import DataString from '../bloks/DataString';
import { getPmStepNo, isLeaseLocationSA } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';

const AgreementPmRepairsAndEmergencyContacts = ({ leaseAllInfo }) => {
    const getContactType = (contactType) => {
        switch (contactType) {
            case 'Email':
                return 'E';
            default:
                return '';
        }
    };
    let contacts = [];
    if (leaseAllInfo.pmEmergencyContacts) {
        contacts = leaseAllInfo.pmEmergencyContacts.contacts;
    }
    return (
        <div>
            {!!leaseAllInfo.pmEmergencyContacts && (
                <AgreementSection
                    title={
                        isLeaseLocationSA(leaseAllInfo.location)
                            ? 'Emergency contacts'
                            : 'Repairs & Preferred Tradespeople'
                    }
                    step={getPmStepNo(leaseAllInfo.location, 'pmEmergencyContacts')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        {!isLeaseLocationSA(leaseAllInfo.location) && (
                            <p className="name">
                                The maximum value of repairs and maintenance to be paid by the agent without prior
                                approval by the client is:{' '}
                                <strong>{leaseAllInfo.pmEmergencyContacts.limitOnCostsOfRepairs}</strong>
                            </p>
                        )}
                        {contacts &&
                            contacts.map((item) => {
                                return (
                                    <div className="data data-fix-width" key={item.id}>
                                        <p className="name">{item.tradeType}</p>
                                        <DataString text={item.firstName + ' ' + item.secondName} />
                                        <DataString title={item.company} />
                                        <DataString title="Ph" text={item.phone} />
                                        {item.extraContacts.map((extraContact) => (
                                            <DataString
                                                title={getContactType(extraContact.contactType)}
                                                text={extraContact.contactValue}
                                                key={extraContact._id}
                                            />
                                        ))}
                                    </div>
                                );
                            })}
                    </div>
                </AgreementSection>
            )}
        </div>
    );
};

export default AgreementPmRepairsAndEmergencyContacts;
