import { getDocumentCountForAgency } from '../../api/subscriptionApi';
import { routes, subscriptionStatus, subscriptionTitle } from '../../config';
import { confirmAlert } from 'react-confirm-alert';
import { formatCurrency } from '../../utils/formUtils';
import { formatDateTime, getAgencyTimezoneFromAgency } from '../../utils/dateUtils';
import { closeCreateFlkModal, closeCustomDocumentModal } from '../../actions/document';
import { has } from 'lodash';
import { setAmountOfDocumentsSentThisMonth } from '../../actions/account';
import { isAdmin } from '../../utils/userUtils';

/**
 * @param values to pass on to the send for signing function
 * @param loggedInUser to worked out if they have a payment method
 * @param sendForSigning function to send for signing
 * @param dispatch
 * @param history
 */
export function agreementsSentAlert(values, loggedInUser, sendForSigning, dispatch, history) {
    return getDocumentCountForAgency(loggedInUser.agency.id)
        .then(async response => {
            const { amountOfDocumentsSentThisMonth, tariffPlan } = response.data;

            const amountOfDocumentsSentThisMonthAfterThisDocument = amountOfDocumentsSentThisMonth + 1;

            dispatch(setAmountOfDocumentsSentThisMonth(amountOfDocumentsSentThisMonthAfterThisDocument));
            // IF FREE PLAN
            if (tariffPlan.title === subscriptionTitle.FREE) {
                //Free plan active sending 3rd free document
                if (tariffPlan.status === subscriptionStatus.ACTIVE) {
                    if (amountOfDocumentsSentThisMonthAfterThisDocument === tariffPlan.agreementsPerMonth.freeDocs) {
                        return addFreePlanLastFreeAlert(history, dispatch, tariffPlan);
                    }
                }

                //Free plan active sending 4th document
                if (tariffPlan.status === subscriptionStatus.ACTIVE) {
                    if (amountOfDocumentsSentThisMonthAfterThisDocument > tariffPlan.agreementsPerMonth.freeDocs) {
                        if (!loggedInUser.agency.stripeDefaultPaymentMethodId) {
                            return addPlanAlert(
                                `You have used all ${
                                    tariffPlan.agreementsPerMonth.freeDocs
                                } documents in your free plan for the current period that is due to renew on ${formatDateTime(
                                    tariffPlan.currentPeriodEnd,
                                    getAgencyTimezoneFromAgency(loggedInUser.agency)
                                )}. To send this document now, please add a payment method and then upgrade your plan.`,
                                'Add payment method',
                                history,
                                dispatch
                            );
                        } else {
                            return noMoreFreeDocsAlert(loggedInUser.agency, tariffPlan, loggedInUser);
                        }
                    }
                }
                // IF PAID PLAN
            } else if (tariffPlan.title !== subscriptionTitle.FREE) {
                //Trialing non-free plan trial count down
                if (tariffPlan.status === subscriptionStatus.TRIALING) {
                    const agreementsLeftOnTrial =
                        tariffPlan.agreementsPerMonth.trialMax - amountOfDocumentsSentThisMonthAfterThisDocument;
                    if (
                        amountOfDocumentsSentThisMonthAfterThisDocument <= tariffPlan.agreementsPerMonth.trialMax &&
                        agreementsLeftOnTrial <= 3
                    ) {
                        const hasPaymentMethod = !!loggedInUser.agency.stripeDefaultPaymentMethodId;
                        return countDownTrialAlert(
                            hasPaymentMethod,
                            tariffPlan,
                            amountOfDocumentsSentThisMonthAfterThisDocument,
                            loggedInUser.agency,
                            values,
                            sendForSigning
                        );
                    }
                }

                //Trialing non-free about to send 11th
                if (tariffPlan.status === subscriptionStatus.TRIALING) {
                    if (
                        amountOfDocumentsSentThisMonthAfterThisDocument ===
                        tariffPlan.agreementsPerMonth.trialMax + 1
                    ) {
                        if (!loggedInUser.agency.stripeDefaultPaymentMethodId) {
                            return addPlanAlert(
                                `You have sent your first ${tariffPlan.agreementsPerMonth.trialMax} documents, your trial has ended. Please add a payment method to continue using FLK it over.`,
                                'Add payment method',
                                history,
                                dispatch
                            );
                        }
                    }
                }

                //Active no paymend method (trial ended based on time)
                if (tariffPlan.status === subscriptionStatus.ACTIVE) {
                    if (!loggedInUser.agency.stripeDefaultPaymentMethodId) {
                        return addPlanAlert(
                            `Your trial has ended. Please add a payment method to continue using FLK it over.`,
                            'Add payment method',
                            history,
                            dispatch
                        );
                    }
                }

                //Active non-free paid send alert
                if (tariffPlan.status === subscriptionStatus.ACTIVE) {
                    if (amountOfDocumentsSentThisMonth >= tariffPlan.agreementsPerMonth.freeDocs) {
                        return freeDocsForPaidEndAlert(loggedInUser.agency, tariffPlan, values, sendForSigning);
                    }
                }

                //Active non-free countdown
                if (tariffPlan.status === subscriptionStatus.ACTIVE) {
                    if (
                        amountOfDocumentsSentThisMonthAfterThisDocument <= tariffPlan.agreementsPerMonth.freeDocs &&
                        tariffPlan.agreementsPerMonth.freeDocs - amountOfDocumentsSentThisMonth <= 3
                    ) {
                        return paidCountDownFreeDocsAlert(
                            tariffPlan,
                            amountOfDocumentsSentThisMonthAfterThisDocument,
                            values,
                            sendForSigning,
                            loggedInUser.agency,
                            loggedInUser
                        );
                    }
                }
            }

            return sendForSigning(values);
        })
        .catch(error => {
            window.rollbar.error(
                'Failed to clone Flow document',
                {
                    error_message: error.message,
                    status: 'error',
                    env: ENVIRONMENT
                },
                error
            );

            if (has(error, 'response.status') && error.response.status === 404) {
                this.props.openCancelledPlanModal();
            }
        });

    function freeDocsForPaidEndAlert(agency, tariffPlan, values, sendForSigning) {
        let buttons = [
            {
                label: 'Continue',
                onClick: () => {
                    return sendForSigning(values);
                }
            },
            {
                label: 'Upgrade plan',
                onClick: () => {
                    dispatch(closeCustomDocumentModal());
                    dispatch(closeCreateFlkModal());
                    history.push(routes.ROUTE_SUBSCRIPTION);
                }
            },
            {
                label: 'Cancel',
                onClick: () => {}
            }
        ];

        // RealFlow plan has no upgrade option
        if (tariffPlan.title === subscriptionTitle.LIST_NOW) {
            buttons = [
                {
                    label: 'Continue',
                    onClick: () => {
                        return sendForSigning(values);
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {}
                }
            ];
        }

        confirmAlert({
            title: '',
            message: getPerDocumentPriceMessage(tariffPlan),
            buttons
        });
    }

    function getPerDocumentPriceMessage(tariffPlan) {
        return `You have used all documents for this period, you can upgrade your plan or continue sending the document for an extra charge of $${formatCurrency(
            tariffPlan.pricePerDoc,
            true
        )} ${tariffPlan.currency} (${tariffPlan.taxDescription}). Your subscription is due to renew on ${formatDateTime(
            tariffPlan.currentPeriodEnd,
            getAgencyTimezoneFromAgency(loggedInUser.agency)
        )}`;
    }

    function addPlanAlert(message, buttonLabel, history, dispatch) {
        confirmAlert({
            title: '',
            message: message,
            buttons: [
                {
                    label: buttonLabel,
                    onClick: () => {
                        dispatch(closeCustomDocumentModal());
                        dispatch(closeCreateFlkModal());
                        history.push(routes.ROUTE_SUBSCRIPTION);
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {}
                }
            ]
        });
    }

    async function addFreePlanLastFreeAlert(history, dispatch, tariffPlan) {
        const sentSuccess = await sendForSigning(values);
        if (sentSuccess) {
            confirmAlert({
                title: '',
                message: `You have used all 3 documents in your free plan for the current period that is due to renew on ${formatDateTime(
                    tariffPlan.currentPeriodEnd,
                    getAgencyTimezoneFromAgency(loggedInUser.agency)
                )}. To continue using FLK it over, please add a payment method and then upgrade your plan.`,
                buttons: [
                    {
                        label: 'Add payment method',
                        onClick: () => {
                            dispatch(closeCustomDocumentModal());
                            dispatch(closeCreateFlkModal());
                            history.push(routes.ROUTE_SUBSCRIPTION);
                        }
                    },
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ]
            });
        }
    }

    function noMoreFreeDocsAlert(agency, tariffPlan, loggedInUser) {
        let message = `You have used all 3 documents in your free plan for the current period that is due to renew on ${formatDateTime(
            tariffPlan.currentPeriodEnd,
            getAgencyTimezoneFromAgency(agency)
        )}. To send this document now, please upgrade your plan.`;
        let buttons = [
            {
                label: 'Upgrade plan',
                onClick: () => {
                    dispatch(closeCustomDocumentModal());
                    dispatch(closeCreateFlkModal());
                    history.push(routes.ROUTE_SUBSCRIPTION);
                }
            },
            {
                label: 'Cancel',
                onClick: () => {}
            }
        ];

        if (!isAdmin(loggedInUser.role)) {
            message = `You have used all 3 documents in your free plan for the current period that is due to renew on ${formatDateTime(
                tariffPlan.currentPeriodEnd,
                getAgencyTimezoneFromAgency(agency)
            )}. Please contact your admin to upgrade plan to continue sending documents through.`;
            buttons = [
                {
                    label: 'OK',
                    onClick: () => {}
                }
            ];
        }

        confirmAlert({
            title: '',
            message,
            buttons
        });
    }

    async function countDownTrialAlert(
        hasPaymentMethod,
        tariffPlan,
        amountOfDocumentsSentThisMonth,
        agency,
        values,
        sendForSigning
    ) {
        const agreementsLeftOnTrial = tariffPlan.agreementsPerMonth.trialMax - amountOfDocumentsSentThisMonth;

        const sentSuccess = await sendForSigning(values);
        if (sentSuccess) {
            let alertButtons;
            if (!hasPaymentMethod) {
                alertButtons = [
                    {
                        label: 'Add payment method',
                        onClick: () => {
                            dispatch(closeCustomDocumentModal());
                            dispatch(closeCreateFlkModal());
                            history.push(routes.ROUTE_SUBSCRIPTION);
                        }
                    },
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ];
            } else {
                alertButtons = [
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ];
            }
            // if last document
            if (amountOfDocumentsSentThisMonth === 10) {
                let messageLastDocument = `You have now used all ${tariffPlan.agreementsPerMonth.trialMax} documents that were available in your free trial. To continue using FLK it over, please add a payment method for your monthly subscription.`;
                if (agency.stripeDefaultPaymentMethodId) {
                    messageLastDocument = `You have now used all ${tariffPlan.agreementsPerMonth.trialMax} documents that were available in your free trial. Your monthly subscription plan has started.`;
                }

                confirmAlert({
                    title: '',
                    message: messageLastDocument,
                    buttons: alertButtons
                });
            } else {
                confirmAlert({
                    title: '',
                    message: `You have now sent ${amountOfDocumentsSentThisMonth} of ${
                        tariffPlan.agreementsPerMonth.trialMax
                    } documents included in your free trial.
             \n You have ${agreementsLeftOnTrial} document${
                 agreementsLeftOnTrial === 1 ? `` : `s`
             } remaining before your trial ends on ${formatDateTime(
                 tariffPlan.currentPeriodEnd,
                 getAgencyTimezoneFromAgency(agency)
             )}.`,
                    buttons: alertButtons
                });
            }
        }
    }

    async function paidCountDownFreeDocsAlert(
        tariffPlan,
        amountOfDocumentsSentThisMonth,
        values,
        sendForSigning,
        agency,
        loggedInUser
    ) {
        const freeDocsLeftOnPlan = tariffPlan.agreementsPerMonth.freeDocs - amountOfDocumentsSentThisMonth;
        const sentSuccess = await sendForSigning(values);

        if (sentSuccess) {
            // IF LAST DOCUMENT SENT
            if (amountOfDocumentsSentThisMonth === tariffPlan.agreementsPerMonth.freeDocs) {
                let message = `You have sent your last document for this period, if you need to send additional documents before your subscription renews, you can upgrade your plan or choose to send them at $${formatCurrency(
                    tariffPlan.pricePerDoc,
                    true
                )} ${tariffPlan.currency} per document (${
                    tariffPlan.taxDescription
                }). Your subscription will renew on ${formatDateTime(
                    tariffPlan.currentPeriodEnd,
                    getAgencyTimezoneFromAgency(agency)
                )}.`;
                let buttons = [
                    {
                        label: 'Upgrade plan',
                        onClick: () => {
                            dispatch(closeCustomDocumentModal());
                            dispatch(closeCreateFlkModal());
                            history.push(routes.ROUTE_SUBSCRIPTION);
                        }
                    },
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ];

                // RealFlow plan has no upgrade option
                if (tariffPlan.title === subscriptionTitle.LIST_NOW) {
                    buttons = [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ];
                }

                if (!isAdmin(loggedInUser.role)) {
                    message = `You have sent your last document for this period. Please contact your admin to upgrade plan to continue sending documents through.`;
                    buttons = [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ];
                }
                confirmAlert({
                    title: '',
                    message,
                    buttons
                });
            } else {
                confirmAlert({
                    title: '',
                    message: `You have sent ${amountOfDocumentsSentThisMonth} of ${
                        tariffPlan.agreementsPerMonth.freeDocs
                    } documents from your current ${
                        tariffPlan.title
                    } subscription. You have ${freeDocsLeftOnPlan} document${
                        freeDocsLeftOnPlan === 1 ? `` : `s`
                    } left in your plan. Your subscription will renew on ${formatDateTime(
                        tariffPlan.currentPeriodEnd,
                        getAgencyTimezoneFromAgency(agency)
                    )}.`,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {}
                        }
                    ]
                });
            }
        }
    }
}
