import React, { memo } from 'react';
import FlkAKeyListings from './FlkAKeyListings';

const FlkAKeyTable = props => {
    const { keyStatus, flkAKeyList } = props;

    return (
        <div className="tb-wrapper">
            <FlkAKeyListings flkAKeyList={flkAKeyList} keyStatus={keyStatus} />
        </div>
    );
};

export default memo(FlkAKeyTable);
