import React, { memo } from 'react';

import { FormMaskedText, FormTextRegular, FormTextHidden } from '../../../components/form/FormText';
import { FormPhone } from '../../../components/form/FormPhone';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { FormEmail } from '../../../components/form/FormEmail';
import { isLeaseLocationQLD, isLeaseLocationNSW } from '../../../utils/agreementUtils';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { getAgencyTimezoneDate } from '../../../utils/dateUtils';
import FormCheckboxGroup from '../../../components/form/FormCheckboxGroup';
const SalesLicensee = ({
    values,
    toggleRegisteredForGst,
    toggleConjunctAgent,
    isConjunct,
    changePhone,
    error,
    licensee,
    location,
    loggedInUser
}) => {
    return (
        <div className="formBox">
            {isLeaseLocationQLD(location) && (
                <FormCheckboxGroup
                    label="Licensee Type*"
                    groupName="salesLicenseeTypes"
                    checkboxes={[
                        {
                            name: 'isLicenseeTypeRealEstateAgent',
                            label: 'Real estate agent'
                        },
                        {
                            name: 'isLicenseeTypePropertyAuctioneer',
                            label: 'Property auctioneer'
                        }
                    ]}
                />
            )}
            {isLeaseLocationNSW(location) && (
                <div className="nowrap fields-full-width-for-mobile">
                    <FormTextRegular name="firstName" label="Agent First Name" required />
                    <FormTextRegular name="secondName" label="Agent Last Name" required />
                </div>
            )}
            <div className="formBox-column">
                {isLeaseLocationNSW(location) && (
                    <FormPhone
                        dataTest="licensee-mobile"
                        name="mobile"
                        label="Mobile"
                        required
                        className={`licensee-mobile`}
                    />
                )}
                <FormTextRegular name="agencyName" label="Agency Name" required />
                <FormTextRegular name="companyName" label="Company Name" required />
                <FormMaskedText
                    dataTest="licensee-abn"
                    name="abn"
                    label="ABN"
                    mask="11 111 111 111"
                    required
                    className="licensee-abn"
                />
                <FormMaskedText dataTest="licensee-acn" name="acn" label="ACN" mask="111 111 111" />
                <FormTextRegular name="licenceNo" label="Licence No" required="true" />
                {isLeaseLocationQLD(location) && (
                    <DatePickerInAgencyTimeZone
                        label="Licence Expiry"
                        name="licenceExpiry"
                        selected={values.licenceExpiry}
                        minValue={getAgencyTimezoneDate(new Date(), loggedInUser)}
                        required
                    />
                )}
                <FormTextRegular name="address" label="Address" required />
                {isLeaseLocationQLD(location) && <FormPhone dataTest="licensee-mobile" name="mobile" label="Mobile" />}
                <FormPhone
                    name="phone"
                    label="Phone"
                    className={`licensee-phone`}
                    onChange={value => changePhone(value, values)}
                    dataTest="licensee-phone"
                />
                {error && (
                    <p className="FormInput custom-error">
                        <span className="FormError">{error}</span>
                    </p>
                )}
                <FormMaskedText name="fax" label="Fax" mask="1111 111 111" />
                <FormEmail name="email" label="Email" required value={licensee.email} />
                <CheckboxCheck
                    name="isRegisteredForGst"
                    label="Registered for GST"
                    onClick={value => {
                        // value is the previous value of the checkbox not the new value
                        toggleRegisteredForGst(!value);
                    }}
                />
                {isLeaseLocationNSW(location) && (
                    <>
                        <CheckboxCheck
                            name="isConjunctAgent"
                            label="There is a conjunction agent"
                            onClick={value => {
                                // value is the previous value of the checkbox not the new value
                                toggleConjunctAgent(value);
                            }}
                        />
                        {isConjunct && (
                            <div>
                                <FormTextRegular name="conjunctAgent.agencyName" label="Agency name" />
                                <FormTextRegular name="conjunctAgent.companyName" label="Company name" required />
                                <FormMaskedText name="conjunctAgent.abn" label="ABN" mask="11 111 111 111" required />
                                <FormTextRegular name="conjunctAgent.licenceNo" label="Licence No" required />
                                <FormTextRegular name="conjunctAgent.address" label="Address" required />
                                <FormPhone
                                    name="conjunctAgent.phone"
                                    label="Phone"
                                    required
                                    className={`conjunctAgent-phone`}
                                />
                                <FormMaskedText name="conjunctAgent.fax" label="Fax" mask="1111 111 111" />
                                <FormTextRegular name="conjunctAgent.email" label="Email" required />
                                <CheckboxCheck name="conjunctAgent.isRegisteredForGst" label="Registered for GST" />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(SalesLicensee);
