import * as React from 'react';

import {
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_PROPERTY_MANAGEMENT,
    TEMPLATE_TYPE_SALES,
    LEASE_TYPE_SALES,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_HOLIDAY_LETTING,
    NSW_STATE,
    QLD_STATE,
    VIC_STATE,
    ACT_STATE,
    SA_STATE,
    DEFAULT_SUB_LEASE_TYPE,
    SUB_LEASE_TYPE_LONG_TERM_RENEWAL
} from '../../config';

/** Shared **/
import Licensee from '../../containers/dashboard/shared/Licensee';
import MarketingAndAdvertising from '../../containers/dashboard/shared/MarketingAndAdvertising';
import DisclosureRebate from '../../containers/dashboard/shared/DisclosureRebate';

/** Generic lease agreement **/
import Landlord from '../../containers/dashboard/lease/Landlord';
import Tenant from '../../containers/dashboard/lease/Tenant';
import Agent from '../../containers/dashboard/lease/Agent';
import Term from '../../containers/dashboard/lease/Term';
import Rent from '../../containers/dashboard/lease/Rent';
import Payment from '../../containers/dashboard/lease/Payment';
import Inclusions from '../../containers/dashboard/lease/Inclusions';
import Occupants from '../../containers/dashboard/lease/Occupants';
import Contacts from '../../containers/dashboard/lease/Contacts';
import NominatedRepairers from '../../containers/dashboard/lease/QLD/NominatedRepairers';
import RepairsAndMaintenance from '../../containers/dashboard/lease/SA/RepairsAndMaintenance';
import Additional from '../../containers/dashboard/lease/Additional';
import MaterialFacts from '../../containers/dashboard/lease/MaterialFacts';
import Conditions from '../../containers/dashboard/lease/Conditions';
import Documents from '../../containers/dashboard/lease/Documents';
import Connections from '../../containers/dashboard/lease/Connections';
import Outgoings from '../../containers/dashboard/lease/SA/Outgoings';
import Exclusions from '../../containers/dashboard/lease/SA/Exclusions';
import Insurance from '../../containers/dashboard/lease/SA/Insurance';
import Pets from '../../containers/dashboard/lease/SA/Pets';
import Disclosure from '../../containers/dashboard/lease/SA/Disclosure';
import Instructions from '../../containers/dashboard/lease/SA/Instructions';
import ExecutedAsAgreement from '../../containers/dashboard/lease/SA/ExecutedAsAgreement';
import SaLongTermTerm from '../../containers/dashboard/lease/SA/LongTermTerm';

/** Commercial lease agreement **/
import Lessor from '../../containers/dashboard/commercialLease/Lessor';
import Lessee from '../../containers/dashboard/commercialLease/Lessee';
import Guarantor from '../../containers/dashboard/commercialLease/Guarantor';
import Premises from '../../containers/dashboard/commercialLease/Premises';
import PermittedUse from '../../containers/dashboard/commercialLease/PermittedUse';
import CommercialRent from '../../containers/dashboard/commercialLease/Rent';
import SecurityDeposit from '../../containers/dashboard/commercialLease/SecurityDeposit';
import Information from '../../containers/dashboard/commercialLease/Information';
import CommercialLeaseTerm from '../../containers/dashboard/commercialLease/Term';

/** Sales agreement **/
import LandlordsSolicitor from '../../containers/dashboard/sales/LandlordsSolicitor';
import SalesTerm from '../../containers/dashboard/sales/Term';
import SalesInclusions from '../../containers/dashboard/sales/Inclusions';
import PriceAndFee from '../../containers/dashboard/sales/PriceAndFee';
import InspectionDays from '../../containers/dashboard/sales/InspectionDays';
import ExpensesAndCharges from '../../containers/dashboard/sales/ExpensesAndCharges';
import Signatory from '../../containers/dashboard/sales/Signatory';
import ManagingAgent from '../../containers/dashboard/sales/QLD/ManagingAgent';
import PropertyDetails from '../../containers/dashboard/sales/QLD/PropertyDetails';
import PropertyDescription from '../../containers/dashboard/sales/QLD/PropertyDescription';
import AgreementTerm from '../../containers/dashboard/sales/QLD/AgreementTerm';
import SalesMaterialFacts from '../../containers/dashboard/sales/QLD/MaterialFacts';

/** Property Management **/
import NswAgreementTerm from '../../containers/dashboard/pm/NSW/AgreementTerm';
import QldAgreementTerm from '../../containers/dashboard/pm/QLD/AgreementTerm';
import NswDetailsRepairs from '../../containers/dashboard/pm/NSW/DetailsRepairs';
import QldDetailsRepairs from '../../containers/dashboard/pm/QLD/DetailsRepairs';
import VicDetailsRepairs from '../../containers/dashboard/pm/VIC/DetailsRepairs';
import RentTerm from '../../containers/dashboard/pm/RentTerm';
import RentPayment from '../../containers/dashboard/pm/RentPayment';
import OtherServices from '../../containers/dashboard/pm/OtherServices';
import NswFees from '../../containers/dashboard/pm/NSW/PriceAndFee';
import QldFees from '../../containers/dashboard/pm/QLD/PriceAndFee';
import PmContacts from '../../containers/dashboard/pm/QLD/PmContacts';
import PmExpensesAndCharges from '../../containers/dashboard/pm/ExpensesAndCharges';
import PmLandlord from '../../containers/dashboard/pm/Landlord';
import PmOutgoings from '../../containers/dashboard/pm/PmOutgoings';
import TenantPayments from '../../containers/dashboard/pm/QLD/TenantPayments';
import PmDuties from '../../containers/dashboard/pm/PmDuties';
import PoolSafetyAndSmokeAlarms from '../../containers/dashboard/pm/QLD/PoolSafetyAndSmokeAlarms';
// VIC
import vicPmClient from '../../containers/dashboard/pm/VIC/Client';
import vicPmAuthorityPeriodDetails from '../../containers/dashboard/pm/VIC/AuthorityPeriodDetails';
import vicPmFees from '../../containers/dashboard/pm/VIC/FeesAndCharges';
import vicPmMarketingCosts from '../../containers/dashboard/pm/VIC/MarketingCosts';
import vicPmAdditionalCosts from '../../containers/dashboard/pm/VIC/AdditionalCosts';
import vicPmRepairs from '../../containers/dashboard/pm/VIC/Repairs';
import vicPmOutgoings from '../../containers/dashboard/pm/VIC/PmOutgoings';
import vicDisclosureRebate from '../../containers/dashboard/pm/VIC/DisclosureRebate';
import vicRentPayment from '../../containers/dashboard/pm/VIC/RentPayment';
import vicServicesSchedule from '../../containers/dashboard/pm/VIC/ServicesSchedule';
import vicRentTerm from '../../containers/dashboard/pm/VIC/RentTerm';
//SA
import SaDetailsRepairs from '../../containers/dashboard/pm/SA/DetailsRepairs';
import SaAgreementTerm from '../../containers/dashboard/pm/SA/AgreementTerm';
import SaRentTerm from '../../containers/dashboard/pm/SA/RentTerm';
import SaFees from '../../containers/dashboard/pm/SA/PriceAndFee';
import SaPmInsurance from '../../containers/dashboard/pm/SA/Insurance';
import ThirdPartyPayment from '../../containers/dashboard/pm/SA/ThirdPartyPayment.tsx';
import SaPmDuties from '../../containers/dashboard/pm/SA/PmDuties.tsx';
import SaMarketing from '../../containers/dashboard/pm/SA/PmMarketing.tsx';

export const PropertyManagement = 'Property Management';
export const Sales = 'Sales';
export const Auction = 'Auction';

export const LANDLORD = 'landlord';
export const PM_LANDLORD = 'pmLandlord';
export const SALES_SIGNATORY = 'signatory';

export const STEP_LANDLORD = 'landlord';
export const STEP_TENANT = 'tenant';
export const STEP_TENANTS_AGENT = 'tenantsAgent';
export const STEP_EXCLUSIONS = 'exclusions';
export const STEP_TERM = 'term';
export const STEP_RENT = 'rent';
export const STEP_PAYMENT_METHOD = 'paymentMethod';
export const STEP_OUTGOINGS = 'outgoings';
export const STEP_INSURANCE = 'insurance';
export const STEP_PETS = 'pets';
export const STEP_REPAIRS_AND_MAINTENANCE = 'repairsAndMaintenance';
export const STEP_DISCLOSURE = 'disclosure';
export const STEP_INSTRUCTIONS = 'instructions';
export const STEP_DOCUMENTS = 'documents';
export const STEP_SPECIAL_CONDITIONS = 'specialConditions';
export const STEP_EXECUTED_AS_AGREEMENT = 'executedAsAgreement';
export const STEP_CONNECTIONS = 'connections';
export const STEP_INCLUSIONS = 'inclusions';
export const STEP_MAX_OCCUPANTS = 'maxOccupants';
export const STEP_EMERGENCY_CONTACTS = 'emergencyContacts';
export const STEP_ADDITIONAL_TERMS = 'additionalTerms';
export const STEP_MATERIAL_FACTS = 'materialFacts';
export const STEP_NOMINATED_REPAIRERS = 'nominatedRepairers';

const pmDefaultSteps = [
    { step: 2, title: 'Landlord', content: PmLandlord, part: PM_LANDLORD, forwardRef: true }, //Differs from tenancy landlord
    { step: 3, title: 'Licensee', content: Licensee, part: 'licensee', forwardRef: true },
    { step: 4, title: 'Agreement Term', content: NswAgreementTerm, part: 'pmAgreementTerm', forwardRef: true },
    {
        step: 5,
        title: 'Property Details & Repairs',
        content: NswDetailsRepairs,
        part: 'pmDetailsRepairs',
        forwardRef: true
    },
    { step: 6, title: 'Rent & Term', content: RentTerm, part: 'pmRentAndTerm', forwardRef: true },
    { step: 7, title: 'Fees', content: NswFees, part: 'pmFees', forwardRef: true },
    {
        step: 8,
        title: 'Expenses & Charges',
        content: PmExpensesAndCharges,
        part: 'pmExpensesAndCharges',
        forwardRef: true
    },
    { step: 9, title: 'Other Services', content: OtherServices, part: 'pmOtherServices', forwardRef: true },
    {
        step: 10,
        title: 'Advertising & Promotion',
        content: MarketingAndAdvertising,
        part: 'marketingAndAdvertising',
        forwardRef: true
    },
    { step: 11, title: 'Inspection Days', content: InspectionDays, part: 'inspectionDays', forwardRef: true },
    // { title: 'Agent Authority', content: AgentAuthority, part: 'agentAuthority', forwardRef: true },
    { step: 12, title: 'Additional Terms', content: Conditions, part: 'specialConditions', forwardRef: true },
    { step: 13, title: 'Duties', content: PmDuties, part: 'pmDuties', forwardRef: true },
    { step: 14, title: 'Outgoings', content: PmOutgoings, part: 'pmOutgoings', forwardRef: true },
    { step: 15, title: 'Payment Method', content: Payment, part: 'pmPaymentMethod', forwardRef: true },
    { step: 16, title: 'Documents/Links', content: <Documents />, part: 'documents' },
    {
        step: 17,
        title: 'Disclosure of Rebates',
        content: DisclosureRebate,
        part: 'disclosureOfRebates',
        forwardRef: true
    }
];
const pmQldSteps = [
    { step: 2, title: 'Lessor', content: PmLandlord, part: PM_LANDLORD, forwardRef: true }, //Differs from tenancy landlord
    { step: 3, title: 'Licensee', content: Licensee, part: 'licensee', forwardRef: true },
    { step: 4, title: 'Property Details', content: QldDetailsRepairs, part: 'pmDetailsRepairs', forwardRef: true },
    { step: 5, title: 'Agreement Term', content: QldAgreementTerm, part: 'pmAgreementTerm', forwardRef: true },
    { step: 6, title: 'Rent & Payment', content: RentPayment, part: 'pmRentAndPayment', forwardRef: true },
    { step: 7, title: 'Fees, Charges & Expenses', content: QldFees, part: 'pmFees', forwardRef: true },
    { step: 8, title: 'Other Services', content: OtherServices, part: 'pmOtherServices', forwardRef: true },
    {
        step: 9,
        title: 'Advertising & Promotion',
        content: MarketingAndAdvertising,
        part: 'marketingAndAdvertising',
        forwardRef: true
    },
    {
        step: 10,
        title: 'Disclosure of Rebates',
        content: DisclosureRebate,
        part: 'disclosureOfRebates',
        forwardRef: true
    },
    { step: 11, title: 'Payments by Agent', content: PmOutgoings, part: 'pmOutgoings', forwardRef: true },
    { step: 12, title: 'Tenant Payments', content: TenantPayments, part: 'tenantPayments', forwardRef: true },
    {
        step: 13,
        title: 'Repairs & Preferred Tradespeople',
        content: PmContacts,
        part: 'pmEmergencyContacts',
        forwardRef: true
    },
    // will add Warranty & Maintenance Contracts in future
    // { title: 'Warranty & Maintenance Contracts', content: WarrantyAndMaintenance, part: 'warrantyAndMaintenance', forwardRef: true },
    {
        step: 14,
        title: 'Pool Safety & Smoke Alarms',
        content: PoolSafetyAndSmokeAlarms,
        part: 'poolSafetyAndSmokeAlarms',
        forwardRef: true
    },
    { step: 15, title: 'Additional Terms', content: Conditions, part: 'specialConditions', forwardRef: true },
    { step: 16, title: 'Documents', content: <Documents />, part: 'documents' }
];

const pmVicSteps = [
    { step: 2, title: 'Agent', content: Licensee, part: 'licensee', forwardRef: true },
    { step: 3, title: 'Client', content: vicPmClient, part: PM_LANDLORD, forwardRef: true },
    { step: 4, title: 'Property Details', content: VicDetailsRepairs, part: 'pmDetailsRepairs', forwardRef: true },
    {
        step: 5,
        title: 'Authority Terms',
        content: vicPmAuthorityPeriodDetails,
        part: 'pmAuthorityPeriodDetails',
        forwardRef: true
    },
    { step: 6, title: 'Rent', content: vicRentTerm, part: 'pmRentAndTerm', forwardRef: true },
    { step: 7, title: 'Fees & Charges', content: vicPmFees, part: 'pmFees', forwardRef: true },
    { step: 8, title: 'Marketing Costs', content: vicPmMarketingCosts, part: 'pmMarketingCosts', forwardRef: true },
    { step: 9, title: 'Additional Costs', content: vicPmAdditionalCosts, part: 'pmAdditionalCosts', forwardRef: true },
    { step: 10, title: 'Outgoings', content: vicPmOutgoings, part: 'pmOutgoings', forwardRef: true },
    {
        step: 11,
        title: 'Bank Account Information',
        content: vicRentPayment,
        part: 'pmRentAndPayment',
        forwardRef: true
    },
    {
        step: 12,
        title: 'Disclosure',
        content: vicDisclosureRebate,
        part: 'disclosureOfRebates',
        forwardRef: true
    },
    {
        step: 13,
        title: 'Repairs',
        content: vicPmRepairs,
        part: 'pmRepairs',
        forwardRef: true
    },
    {
        step: 14,
        title: 'Services Schedule',
        content: vicServicesSchedule,
        part: 'pmServicesSchedule',
        forwardRef: true
    },
    { step: 15, title: 'Additional Terms', content: Conditions, part: 'specialConditions', forwardRef: true },
    { step: 16, title: 'Documents/Links', content: <Documents />, part: 'documents' }
];

const pmSaSteps = [
    { step: 2, title: 'Landlord', content: PmLandlord, part: PM_LANDLORD, forwardRef: true },
    { step: 3, title: 'Agent', content: Licensee, part: 'licensee', forwardRef: true },
    {
        step: 4,
        title: 'Property Details & Repairs',
        content: SaDetailsRepairs,
        part: 'pmDetailsRepairs',
        forwardRef: true
    },
    {
        step: 5,
        title: 'Term',
        content: SaAgreementTerm,
        part: 'pmAgreementTerm',
        forwardRef: true
    },
    { step: 6, title: 'Rent', content: SaRentTerm, part: 'pmRentAndTerm', forwardRef: true },
    { step: 7, title: 'Fees', content: SaFees, part: 'pmFees', forwardRef: true },
    {
        step: 8,
        title: 'Advertising & Marketing',
        content: SaMarketing,
        part: 'pmMarketingCosts',
        forwardRef: true
    },
    { step: 9, title: 'Outgoings', content: ThirdPartyPayment, part: 'thirdPartyPayment', forwardRef: true },
    {
        step: 10,
        title: 'Duties',
        content: SaPmDuties,
        part: 'pmDuties',
        forwardRef: true
    },
    {
        step: 11,
        title: 'Insurance',
        content: SaPmInsurance,
        part: 'insurance',
        forwardRef: true
    },
    {
        step: 12,
        title: 'Payment Method',
        content: Payment,
        part: 'paymentMethod',
        forwardRef: true
    },
    {
        step: 13,
        title: 'Emergency Contacts',
        content: PmContacts,
        part: 'pmEmergencyContacts',
        forwardRef: true
    },
    { step: 14, title: 'Additional Terms', content: Conditions, part: 'specialConditions', forwardRef: true },
    { step: 15, title: 'Documents/Links', content: <Documents />, part: 'documents' }
];

const salesDefaultSteps = [
    { step: 2, title: 'Vendor', content: Signatory, part: SALES_SIGNATORY, forwardRef: true },
    {
        step: 3,
        title: 'Vendor solicitor',
        content: LandlordsSolicitor,
        part: 'landlordsSolicitor',
        forwardRef: true
    },
    { step: 4, title: 'Agency details', content: Licensee, part: 'licensee', forwardRef: true },
    { step: 5, title: 'Fixtures & Fittings', content: SalesInclusions, part: 'salesInclusions', forwardRef: true },
    { step: 6, title: 'Term of the agreement', content: SalesTerm, part: 'salesTerm', forwardRef: true },
    { step: 7, title: 'Price and fee', content: PriceAndFee, part: 'priceAndFee', forwardRef: true },
    {
        step: 8,
        title: 'Marketing & advertising',
        content: MarketingAndAdvertising,
        part: 'marketingAndAdvertising',
        forwardRef: true
    },
    {
        step: 9,
        title: 'Expenses and charges',
        content: ExpensesAndCharges,
        part: 'salesExpensesAndCharges',
        forwardRef: true
    },
    { step: 10, title: 'Inspection of property', content: InspectionDays, part: 'inspectionDays', forwardRef: true },
    {
        step: 11,
        title: 'Additional terms and conditions',
        content: Conditions,
        part: 'specialConditions',
        forwardRef: true
    },
    { step: 12, title: 'Documents/Links', content: <Documents />, part: 'documents' },
    {
        step: 13,
        title: 'Disclosure of rebates',
        content: DisclosureRebate,
        part: 'disclosureOfRebates',
        forwardRef: true
    }
];

const salesQldSteps = [
    { step: 2, title: 'Client', content: Signatory, part: SALES_SIGNATORY, forwardRef: true },
    { step: 3, title: 'Client Solicitor', content: LandlordsSolicitor, part: 'landlordsSolicitor', forwardRef: true },
    { step: 4, title: 'Licensee', content: Licensee, part: 'licensee', forwardRef: true },
    { step: 5, title: 'Managing Agent', content: ManagingAgent, part: 'managingAgent', forwardRef: true },
    { step: 6, title: 'Property Details', content: PropertyDetails, part: 'propertyDetails', forwardRef: true },
    {
        step: 7,
        title: 'Property Description',
        content: PropertyDescription,
        part: 'propertyDescription',
        forwardRef: true
    },
    { step: 8, title: 'Agreement Term', content: AgreementTerm, part: 'term', forwardRef: true },
    { step: 9, title: 'Price & Commissions', content: PriceAndFee, part: 'priceAndFee', forwardRef: true },
    {
        step: 10,
        title: 'Advertising & Marketing',
        content: MarketingAndAdvertising,
        part: 'marketingAndAdvertising',
        forwardRef: true
    },
    {
        step: 11,
        title: 'Fees & Charges',
        content: ExpensesAndCharges,
        part: 'salesExpensesAndCharges',
        forwardRef: true
    },
    {
        step: 12,
        title: 'Disclosure of Rebates',
        content: DisclosureRebate,
        part: 'disclosureOfRebates',
        forwardRef: true
    },
    { step: 13, title: 'Material Facts', content: SalesMaterialFacts, part: 'materialFacts', forwardRef: true },
    {
        step: 14,
        title: 'Additional Terms',
        content: Conditions,
        part: 'specialConditions',
        forwardRef: true
    },
    { step: 15, title: 'Documents/Links', content: <Documents />, part: 'documents' }
];

// When removing a step here, we must also consider checking `requiredStepList` variable inside agreementUtils.js
const resTenQldSteps = [
    { step: 2, title: 'Lessor', content: Landlord, part: STEP_LANDLORD, forwardRef: true },
    { step: 3, title: 'Tenant', content: Tenant, part: STEP_TENANT, forwardRef: true },
    { step: 4, title: 'Term', content: Term, part: STEP_TERM, forwardRef: true },
    { step: 5, title: 'Rent and bond', content: Rent, part: STEP_RENT, forwardRef: true },
    { step: 6, title: 'Payment method', content: Payment, part: STEP_PAYMENT_METHOD, forwardRef: true },
    { step: 7, title: 'Inclusions/Repair orders', content: Inclusions, part: STEP_INCLUSIONS, forwardRef: true },
    { step: 8, title: 'Max number of occupants', content: Occupants, part: STEP_MAX_OCCUPANTS, forwardRef: true },
    {
        step: 9,
        title: 'Nominated Repairers',
        content: NominatedRepairers,
        part: STEP_NOMINATED_REPAIRERS,
        forwardRef: true
    },
    { step: 10, title: 'Additional terms', content: Additional, part: STEP_ADDITIONAL_TERMS, forwardRef: true },
    { step: 11, title: 'Special conditions', content: Conditions, part: STEP_SPECIAL_CONDITIONS, forwardRef: true },
    { step: 12, title: 'Documents/Links', content: <Documents />, part: STEP_DOCUMENTS },
    { step: 13, title: 'Connections', content: Connections, part: STEP_CONNECTIONS, forwardRef: true }
];
const resTenActSteps = [
    { step: 2, title: 'Lessor', content: Landlord, part: STEP_LANDLORD, forwardRef: true },
    { step: 3, title: 'Tenant', content: Tenant, part: STEP_TENANT, forwardRef: true },
    { step: 4, title: 'Term', content: Term, part: STEP_TERM, forwardRef: true },
    { step: 5, title: 'Rent and bond', content: Rent, part: STEP_RENT, forwardRef: true },
    { step: 6, title: 'Payment method', content: Payment, part: STEP_PAYMENT_METHOD, forwardRef: true },
    { step: 7, title: 'Inclusions', content: Inclusions, part: STEP_INCLUSIONS, forwardRef: true },
    { step: 8, title: 'Number of persons permitted', content: Occupants, part: STEP_MAX_OCCUPANTS, forwardRef: true },
    { step: 9, title: 'Emergency contacts', content: Contacts, part: STEP_EMERGENCY_CONTACTS, forwardRef: true },
    { step: 10, title: 'Additional terms', content: Additional, part: STEP_ADDITIONAL_TERMS, forwardRef: true },
    { step: 11, title: 'Special conditions', content: Conditions, part: STEP_SPECIAL_CONDITIONS, forwardRef: true },
    { step: 12, title: 'Documents/Links', content: <Documents />, part: STEP_DOCUMENTS },
    { step: 13, title: 'Connections', content: Connections, part: STEP_CONNECTIONS, forwardRef: true }
];
const resTenVicSteps = [
    { step: 2, title: 'Rental provider', content: Landlord, part: STEP_LANDLORD, forwardRef: true },
    { step: 3, title: "Renter's details", content: Tenant, part: STEP_TENANT, forwardRef: true },
    { step: 4, title: 'Term', content: Term, part: STEP_TERM, forwardRef: true },
    { step: 5, title: 'Rent and bond', content: Rent, part: STEP_RENT, forwardRef: true },
    { step: 6, title: 'Payment method', content: Payment, part: STEP_PAYMENT_METHOD, forwardRef: true },
    { step: 7, title: 'Inclusions', content: Inclusions, part: STEP_INCLUSIONS, forwardRef: true },
    { step: 8, title: 'Max number of occupants', content: Occupants, part: STEP_MAX_OCCUPANTS, forwardRef: true },
    { step: 9, title: 'Emergency contacts', content: Contacts, part: STEP_EMERGENCY_CONTACTS, forwardRef: true },
    { step: 10, title: 'Additional terms', content: Additional, part: STEP_ADDITIONAL_TERMS, forwardRef: true },
    { step: 11, title: 'Special conditions', content: Conditions, part: STEP_SPECIAL_CONDITIONS, forwardRef: true },
    { step: 12, title: 'Documents/Links', content: <Documents />, part: STEP_DOCUMENTS },
    { step: 13, title: 'Connections', content: Connections, part: STEP_CONNECTIONS, forwardRef: true }
];
const resTenNSWSteps = [
    { step: 2, title: 'Landlord', content: Landlord, part: STEP_LANDLORD, forwardRef: true },
    { step: 3, title: 'Tenant', content: Tenant, part: STEP_TENANT, forwardRef: true },
    { step: 4, title: "Tenant's agent", content: Agent, part: STEP_TENANTS_AGENT, forwardRef: true },
    { step: 5, title: 'Term', content: Term, part: STEP_TERM, forwardRef: true },
    { step: 6, title: 'Rent and bond', content: Rent, part: STEP_RENT, forwardRef: true },
    { step: 7, title: 'Payment method', content: Payment, part: STEP_PAYMENT_METHOD, forwardRef: true },
    { step: 8, title: 'Inclusions', content: Inclusions, part: STEP_INCLUSIONS, forwardRef: true },
    { step: 9, title: 'Max number of occupants', content: Occupants, part: STEP_MAX_OCCUPANTS, forwardRef: true },
    { step: 10, title: 'Emergency contacts', content: Contacts, part: STEP_EMERGENCY_CONTACTS, forwardRef: true },
    { step: 11, title: 'Additional terms', content: Additional, part: STEP_ADDITIONAL_TERMS, forwardRef: true },
    { step: 12, title: 'Material facts', content: MaterialFacts, part: STEP_MATERIAL_FACTS, forwardRef: true },
    { step: 13, title: 'Special conditions', content: Conditions, part: STEP_SPECIAL_CONDITIONS, forwardRef: true },
    { step: 14, title: 'Documents/Links', content: <Documents />, part: STEP_DOCUMENTS },
    { step: 15, title: 'Connections', content: Connections, part: STEP_CONNECTIONS, forwardRef: true }
];
const resTenSaSteps = [
    { step: 2, title: 'Landlord', content: Landlord, part: STEP_LANDLORD, forwardRef: true },
    { step: 3, title: 'Tenant', content: Tenant, part: STEP_TENANT, forwardRef: true },
    { step: 4, title: 'Exclusions', content: Exclusions, part: STEP_EXCLUSIONS, forwardRef: true },
    { step: 5, title: 'Term', content: Term, part: STEP_TERM, forwardRef: true },
    { step: 6, title: 'Rent and bond', content: Rent, part: STEP_RENT, forwardRef: true },
    { step: 7, title: 'Payment method', content: Payment, part: STEP_PAYMENT_METHOD, forwardRef: true },
    { step: 8, title: 'Outgoings', content: Outgoings, part: STEP_OUTGOINGS, forwardRef: true },
    { step: 9, title: 'Insurance', content: Insurance, part: STEP_INSURANCE, forwardRef: true },
    { step: 10, title: 'Pets', content: Pets, part: STEP_PETS, forwardRef: true },
    {
        step: 11,
        title: 'Repairs And Maintenance',
        content: RepairsAndMaintenance,
        part: STEP_REPAIRS_AND_MAINTENANCE,
        forwardRef: true
    },
    { step: 12, title: 'Disclosure', content: Disclosure, part: STEP_DISCLOSURE, forwardRef: true },
    { step: 13, title: 'Instructions', content: Instructions, part: STEP_INSTRUCTIONS, forwardRef: true },
    { step: 14, title: 'Documents/Links', content: <Documents />, part: STEP_DOCUMENTS },
    { step: 15, title: 'Special conditions', content: Conditions, part: STEP_SPECIAL_CONDITIONS, forwardRef: true },
    {
        step: 16,
        title: 'Executed as an Agreement',
        content: ExecutedAsAgreement,
        part: STEP_EXECUTED_AS_AGREEMENT,
        forwardRef: true
    },
    { step: 17, title: 'Connections', content: Connections, part: STEP_CONNECTIONS, forwardRef: true }
];

const resTenSaLongTermRenewalSteps = [
    { step: 2, title: 'Tenant', content: Tenant, part: STEP_TENANT, forwardRef: true },
    { step: 3, title: 'Term', content: SaLongTermTerm, part: STEP_TERM, forwardRef: true },
    { step: 4, title: 'Rent', content: Rent, part: STEP_RENT, forwardRef: true }
];

const commercialLeaseNSWSteps = [
    { step: 2, title: 'Lessor', content: Lessor, part: 'lessor', forwardRef: true },
    { step: 3, title: "Lessor's Agent", content: Licensee, part: 'licensee', forwardRef: true },
    { step: 4, title: 'Lessee', content: Lessee, part: 'lessee', forwardRef: true },
    { step: 5, title: 'Guarantor', content: Guarantor, part: 'guarantor', forwardRef: true },
    { step: 6, title: 'Premises', content: Premises, part: 'premises', forwardRef: true },
    { step: 7, title: 'Permitted Use', content: PermittedUse, part: 'permittedUse', forwardRef: true },
    { step: 8, title: 'Term of Lease', content: CommercialLeaseTerm, part: STEP_TERM, forwardRef: true },
    { step: 9, title: 'Rent', content: CommercialRent, part: STEP_RENT, forwardRef: true },
    {
        step: 10,
        title: 'Security Deposit/Bank Guarantee',
        content: SecurityDeposit,
        part: 'securityDeposit',
        forwardRef: true
    },
    { step: 11, title: 'Information', content: Information, part: 'information', forwardRef: true },
    { step: 12, title: 'Special conditions', content: Conditions, part: STEP_SPECIAL_CONDITIONS, forwardRef: true },
    { step: 13, title: 'Documents/Links', content: <Documents />, part: STEP_DOCUMENTS }
];

const holidayLettingNswSteps = [];

export const steps = {
    [LEASE_TYPE_RESIDENTIAL]: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: resTenNSWSteps,
            [QLD_STATE]: resTenQldSteps,
            [VIC_STATE]: resTenVicSteps,
            [ACT_STATE]: resTenActSteps,
            [SA_STATE]: resTenSaSteps
        },
        [SUB_LEASE_TYPE_LONG_TERM_RENEWAL]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [ACT_STATE]: [],
            [SA_STATE]: resTenSaLongTermRenewalSteps
        }
    },
    [LEASE_TYPE_PROPERTY_MANAGEMENT]: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: pmDefaultSteps,
            [QLD_STATE]: pmQldSteps,
            [VIC_STATE]: pmVicSteps,
            [SA_STATE]: pmSaSteps
        },
        [SUB_LEASE_TYPE_LONG_TERM_RENEWAL]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [SA_STATE]: []
        }
    },
    [TEMPLATE_TYPE_PROPERTY_MANAGEMENT]: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: pmDefaultSteps,
            [QLD_STATE]: pmQldSteps,
            [VIC_STATE]: pmVicSteps,
            [SA_STATE]: pmSaSteps
        },
        [SUB_LEASE_TYPE_LONG_TERM_RENEWAL]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: [],
            [SA_STATE]: []
        }
    },
    [TEMPLATE_TYPE_SALES]: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: salesDefaultSteps,
            [QLD_STATE]: salesQldSteps,
            [VIC_STATE]: salesDefaultSteps
        },
        [SUB_LEASE_TYPE_LONG_TERM_RENEWAL]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: []
        }
    },
    [LEASE_TYPE_SALES]: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: salesDefaultSteps,
            [QLD_STATE]: salesQldSteps,
            [VIC_STATE]: salesDefaultSteps
        },
        [SUB_LEASE_TYPE_LONG_TERM_RENEWAL]: {
            [NSW_STATE]: [],
            [QLD_STATE]: [],
            [VIC_STATE]: []
        }
    },
    [LEASE_TYPE_COMMERCIAL_LEASE]: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: commercialLeaseNSWSteps
        },
        [SUB_LEASE_TYPE_LONG_TERM_RENEWAL]: {
            [NSW_STATE]: []
        }
    },
    [LEASE_TYPE_HOLIDAY_LETTING]: {
        [DEFAULT_SUB_LEASE_TYPE]: {
            [NSW_STATE]: holidayLettingNswSteps
        },
        [SUB_LEASE_TYPE_LONG_TERM_RENEWAL]: {
            [NSW_STATE]: []
        }
    } /*,
    [Auction]: [
        {title: 'Landlord', content: <div />, part: 'landlord'}, //Differs from tenancy landlord
        {title: 'Landlord\'s solicitor', content: <LandlordsSolicitor />, part: 'landlordsSolicitor'},
        {title: 'Licensee', content: <Licensee />, part: 'licensee'},
        {title: 'Inclusions', content: <Inclusions />, part: 'inclusions'},
        {title: 'Price and fee', content: <PriceAndFee />, part: 'priceAndFee'},
        {title: 'Term of the agreement', content: <Term />, part: 'term'}, //Differs from tenancy landlord
        {title: 'Marketing and advertising', content: <MarketingAndAdvertising />, part: 'marketingAndAdvertising'},
        {title: 'Inspection days', content: <InspectionDays />, part: 'inspectionDays'}
    ]*/
};
