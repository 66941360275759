import React from "react";
import * as RadixDropdown from "@radix-ui/react-dropdown-menu";
import cx from 'classnames';
import styles from "./Dropdown.module.scss";

type DropdownProps = {
    trigger: React.ReactNode;
    className?: string;
    dropdownContentClassName?: string;
    radixDropdownPortalContainer?: HTMLElement;
    dropdownItems: {
        key?: string;
        component: React.ReactNode;
        showItemCondition?: boolean;
    }[];
};

const Dropdown: React.FC<DropdownProps> = ({ 
    trigger, 
    radixDropdownPortalContainer, 
    dropdownItems, className, 
    dropdownContentClassName 
}) => {
    return (
        <RadixDropdown.Root>
            <RadixDropdown.Trigger className={cx(styles.trigger, className)}>
                {trigger}
            </RadixDropdown.Trigger>
            <RadixDropdown.Portal container={radixDropdownPortalContainer}>
                <RadixDropdown.Content className={cx(styles.dropdown, dropdownContentClassName)}>
                        {dropdownItems.map(item => {
                            if (
                                item.showItemCondition ||
                                item.showItemCondition === undefined
                            ) {
                                return item.component;
                            }
                        })}
                </RadixDropdown.Content>
            </RadixDropdown.Portal>
        </RadixDropdown.Root>
    );
};

export default Dropdown;
