import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import Panel from '../../../common/components/Panel.js';
import { getAgency } from '../../../selectors/settings/account.js';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck.js';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup.js';
import { has } from 'lodash';
import axios from 'axios';
import SaveButtonIcon from '../SaveButtonIcon';
import { Agency, ContactList } from '@app/types/Agency.js';
import { contactListShareOptions } from '@app/config';

const ContactListForm = () => {
    const agencySelected: Agency = useSelector(getAgency);

    const saveContactListSettings = useMutation((data: ContactList) => {
        return axios.put(`/api/agency/${agencySelected.id}/contact-list`, data);
    });

    const handleSubmit = async (values: ContactList) => {
        // Reset mutate data
        // Otherwise it will keep old data and display old error/success messages
        saveContactListSettings.reset();
        try {
            await saveContactListSettings.mutateAsync(values);
        } catch (error) {
            let err = {};
            if (has(error, 'response.data.errors.contactList')) {
                err = error.response.data.errors.contactList;
            }
            return err;
        }
    };

    return (
        <Form initialValues={agencySelected.contactList} onSubmit={handleSubmit}>
            {({ handleSubmit, values }) => {
                return (
                    <form onSubmit={handleSubmit} id="contactList">
                        <Panel
                            title="CONTACT LIST"
                            rightButton={
                                <div>
                                    <SaveButtonIcon
                                        isSaving={saveContactListSettings.isLoading}
                                        success={saveContactListSettings.isSuccess}
                                        failure={saveContactListSettings.isError}
                                    />
                                </div>
                            }
                        >
                            <p className="text-gray">
                                When you send a document, allow the adding of the recipient to your individual contact
                                list. This makes it very easy for you and your team to send documents to the same
                                recipient.
                            </p>
                            <br />
                            <CheckboxCheck name="enabled" label="Add to contact list for your team" />
                            {values.enabled && (
                                <FormRadioGroup
                                    name="shareOption"
                                    label=""
                                    radioGroupClass="wrapped-radio-group"
                                    data={[
                                        {
                                            value: contactListShareOptions.ALL,
                                            label: 'All team members share the same contact list'
                                        },
                                        {
                                            value: contactListShareOptions.DOCUMENT_PERMISSION,
                                            label: 'Team members only share the contact list based on document permission settings'
                                        }
                                    ]}
                                />
                            )}
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};

export default ContactListForm;
