import React from 'react';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import cx from 'classnames';
import axios from 'axios';
import { ReactConfirmAlertProps, confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

import { FLK_A_PDF_TEMPLATE } from '../../config';
import { CustomPlaceholder, UploadADoc } from '../../types/UploadADoc';
import { removeFileExtension } from '../../utils/documentUtils.js';
import { getIsDefaultTextAnnotation, saveDocumentPromise, updateCustomPlaceholderCounts } from './utils';
import Button from '../../common/components/Button.js';
import { Point, AnnotationError, AnnotationAction, isCustomPlaceholderAnnotation } from './types';
import { Tag, TagColor, TagSize } from '../../common/components/tag/Tag';
import Card, { CardShadow, CardStyles } from '../../common/components/cards/Card';
import Icon, { Icons } from '../../common/components/Icon';
import { UploadedDocumentState } from './components/useUploadedDocumentState';
import CustomPlaceholderListeners from './components/CustomPlaceholderListeners';
import { DOCUMENT_API_PATH, UPLOAD_A_DOC_API_PATH, UPLOAD_A_DOC_ONBOARDING_API_PATH } from '../../constants/constants';
import TopBar from './components/TopBar';
import WebViewerInstanceMemo from './components/WebViewerInstance';
import Sidebar, { SidebarAccordionItems } from './components/Sidebar';

import styles from './PdfViewer.module.scss';

const renderConfirmAlert = (confirmAlertProps: ReactConfirmAlertProps) => {
    confirmAlert({
        ...confirmAlertProps,
        overlayClassName: styles.confirmAlertOverlay
    });
};

type PdfViewerProps = {
    customDoc: UploadADoc;
    selectedUploadedDocId?: string;
    setSelectedUploadedDocId: (id: string) => void;
    agencyDetails: {
        name: string;
        imageUrl?: string;
    };
    isOpen: boolean;
    onSaveSuccess: (customDoc: UploadADoc) => void;
    close: () => void;
    isOnboarding: boolean;
    uploadedDocumentStateItems: UploadedDocumentState[];
    onCreateWebviewerInstance: (uploadedDocumentId: string, instance: WebViewerInstance, clientCount: number) => void;
    onUpdateAnnotationCount: (
        uploadedDocumentId: string,
        updatedAnnotations: Core.Annotations.Annotation[],
        action: string
    ) => void;
    shouldSaveCustomPlaceholders: boolean;
    defaultOpenSidebarItems?: SidebarAccordionItems[];
    numberOfFormClients: number;
    onWebViewerInitialised: (uploadedDocumentId: string) => void;
};

const PdfViewer: React.FC<PdfViewerProps> = ({
    isOpen,
    close,
    customDoc,
    selectedUploadedDocId,
    setSelectedUploadedDocId,
    agencyDetails,
    isOnboarding,
    onSaveSuccess,
    uploadedDocumentStateItems,
    onCreateWebviewerInstance,
    onUpdateAnnotationCount,
    defaultOpenSidebarItems,
    numberOfFormClients,
    onWebViewerInitialised
}) => {
    const dropPointRef = React.useRef<Point>();
    const [isSaving, setIsSaving] = React.useState(false);
    const [hideCustomPlaceholderTitles, setHideCustomPlaceholderTitles] = React.useState(false);
    const [hoveredCustomPlaceholderId, setHoveredCustomPlaceholderId] = React.useState<string | undefined>();

    const customPlaceholders = useFieldArray<CustomPlaceholder>('customPlaceholders');
    const uploadADocForm = useForm();

    const selectedWebViewerInstance = uploadedDocumentStateItems.find(
        item => item.uploadedDocumentId === selectedUploadedDocId
    )?.instance;

    const clientLabel = 'Recipient';
    const clients = React.useMemo(
        () =>
            customDoc.docType === FLK_A_PDF_TEMPLATE
                ? [
                      { name: `${clientLabel} 01`, id: undefined, email: '', phone: '' },
                      { name: `${clientLabel} 02`, id: undefined, email: '', phone: '' },
                      { name: `${clientLabel} 03`, id: undefined, email: '', phone: '' },
                      { name: `${clientLabel} 04`, id: undefined, email: '', phone: '' }
                  ]
                : customDoc.client?.clients || [],
        [customDoc.docType, customDoc.client?.clients]
    );

    const handleDrop = React.useCallback((point: Point) => (dropPointRef.current = point), []);

    type UploadedDocumentErrors = {
        [uploadedDocumentId: string]: AnnotationError[];
    };

    const handleWebviewerCreated = React.useCallback(
        (uploadedDocumentId: string, instance: WebViewerInstance) => {
            onCreateWebviewerInstance(uploadedDocumentId, instance, clients.length);
        },
        [onCreateWebviewerInstance, clients]
    );

    const validateAnnotations = () => {
        const validationErrors: UploadedDocumentErrors = {};

        uploadedDocumentStateItems.forEach(webViewerInstance => {
            const errors: AnnotationError[] = [];
            // validate that all dates have a matching signature
            const signatureAnnotationCounts = webViewerInstance.clientAnnotationsCounts.signatureAnnotationCounts;
            const dateAnnotationCounts = webViewerInstance.clientAnnotationsCounts.dateAnnotationCounts;
            const allDatesHaveMatchingSignatures = Object.keys(dateAnnotationCounts).every(clientIndex => {
                const dateCount = dateAnnotationCounts[clientIndex];
                const signatureCount = signatureAnnotationCounts[clientIndex];
                return dateCount <= signatureCount;
            });

            // validate that there are no default text annotations
            const annotations = webViewerInstance.instance.Core.annotationManager.getAnnotationsList();
            const hasDefaultTextAnnotations = annotations.some(annotation => getIsDefaultTextAnnotation(annotation));

            // Validate that there are no witness name without signature
            const witnessSignatureAnnotationCounts =
                webViewerInstance.clientAnnotationsCounts.witness.signatureAnnotationCounts;
            const witnessNameAnnotationCounts = webViewerInstance.clientAnnotationsCounts.witness.nameAnnotationCounts;

            const allWitnessNamesHaveMatchingSignatures = Object.keys(witnessNameAnnotationCounts).every(
                clientIndex => {
                    const nameCount = witnessNameAnnotationCounts[clientIndex];
                    const signatureCount = witnessSignatureAnnotationCounts[clientIndex];
                    return nameCount === signatureCount;
                }
            );

            const allWitnessSignaturesHaveMatchingNames = Object.keys(witnessSignatureAnnotationCounts).every(
                clientIndex => {
                    const nameCount = witnessNameAnnotationCounts[clientIndex];
                    const signatureCount = witnessSignatureAnnotationCounts[clientIndex];
                    return nameCount === signatureCount;
                }
            );

            if (!allDatesHaveMatchingSignatures) {
                errors.push(AnnotationError.datesWithoutSignatures);
            }

            if (hasDefaultTextAnnotations) {
                errors.push(AnnotationError.defaultTextAnnotations);
            }

            if (!allWitnessNamesHaveMatchingSignatures || !allWitnessSignaturesHaveMatchingNames) {
                errors.push(AnnotationError.witnessSignatureNotEqualToWitnessName);
            }

            if (errors.length > 0) {
                validationErrors[webViewerInstance.uploadedDocumentId] = errors;
            }
        });
        return validationErrors;
    };

    const renderValidationAlert = (validationErrors: UploadedDocumentErrors) => {
        // get the number of total errors
        const errorCount = Object.values(validationErrors).reduce((acc, errors) => acc + errors.length, 0);
        renderConfirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Card
                        animate={false}
                        className={styles.annotationErrorsAlert}
                        style={CardStyles.SQUARE}
                        shadow={CardShadow.SMALL}
                    >
                        <Button className={styles.closeButton} onClick={onClose}>
                            <Icon className={styles.closeIcon} icon={Icons.CLOSE} />
                        </Button>
                        <Tag
                            color={TagColor.Error}
                            size={TagSize.Small}
                            text={`${errorCount} Errors`}
                            startIcon={<Icon className={styles.errorIcon} icon={Icons.WARNING} />}
                        />
                        <h1 className={styles.errorHeading}>The following documents have errors</h1>
                        <ul className={styles.documentList}>
                            {Object.keys(validationErrors).map(uploadedDocumentId => {
                                const errors = validationErrors[uploadedDocumentId];
                                const uploadedDocument = customDoc.uploadedDocuments.find(
                                    doc => doc.id === uploadedDocumentId
                                );

                                return (
                                    <li className={styles.errorDocument} key={uploadedDocument?.document.id}>
                                        <ol className={styles.errorList}>
                                            <h2 className={styles.errorDocumentTitle}>
                                                {removeFileExtension(uploadedDocument?.document?.documentName)}
                                            </h2>
                                            {errors.map((error, index) => {
                                                if (error === AnnotationError.datesWithoutSignatures) {
                                                    return (
                                                        <li key={index}>
                                                            Uneven amount of signature dates to signatures. Too many
                                                            signature dates added.
                                                        </li>
                                                    );
                                                } else if (error === AnnotationError.defaultTextAnnotations) {
                                                    return (
                                                        <li key={index}>
                                                            Added free text is still showing default message. You added
                                                            some free text, but forgot to change the wording.
                                                        </li>
                                                    );
                                                } else if (
                                                    error === AnnotationError.witnessSignatureNotEqualToWitnessName
                                                ) {
                                                    return (
                                                        <li key={index}>
                                                            To add a witness, a document requires both a witness name
                                                            and signature.
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ol>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className={styles.buttonGroup}>
                            <Button disabled={isSaving} className={styles.errorButton} primary onClick={onClose}>
                                Back to edit
                            </Button>
                        </div>
                    </Card>
                );
            }
        });
    };

    const handleSaveDocument = async () => {
        const promises = uploadedDocumentStateItems.map(updatedDocumentStateItems => {
            const { uploadedDocumentId, instance, agentNameAnnotationCount, agentName, agentSignatureAnnotationCount } =
                updatedDocumentStateItems;
            const senderDetails = {
                senderName: agentName,
                nameCount: agentNameAnnotationCount,
                signatureCount: agentSignatureAnnotationCount
            };
            if (uploadedDocumentId && instance) {
                return saveDocumentPromise(
                    instance,
                    customDoc.id,
                    uploadedDocumentId,
                    customDoc.confirmationType,
                    !!isOnboarding,
                    senderDetails
                );
            }
        });

        try {
            setIsSaving(true);
            await Promise.all(promises);

            let updatedDocument;

            // Save latest form values if custom placeholders have been updated
            if (uploadADocForm.getState().dirtyFields.customPlaceholders) {
                const documentApiPostUrl = isOnboarding
                    ? `${UPLOAD_A_DOC_ONBOARDING_API_PATH}/${customDoc.id}?bypassFormValidation=1`
                    : `${UPLOAD_A_DOC_API_PATH}/${customDoc.id}?bypassFormValidation=1`;

                const postDocumentResponse = await axios.post(documentApiPostUrl, uploadADocForm.getState().values);
                updatedDocument = postDocumentResponse.data.customDocument;
            } else {
                const documentApiGetUrl = isOnboarding
                    ? `${UPLOAD_A_DOC_ONBOARDING_API_PATH}/${customDoc.id}`
                    : `${DOCUMENT_API_PATH}/${customDoc.id}`;
                // Get the updated document to ensure that we have the latest version, since we are sending multiple save requests
                // in parallel.
                const getDocumentResponse = await axios.get(documentApiGetUrl);
                updatedDocument = getDocumentResponse.data.doc;
            }

            if (updatedDocument) {
                onSaveSuccess(updatedDocument);
                setIsSaving(false);
                close();
            } else {
                throw new Error('Error saving document');
            }
        } catch (error) {
            renderConfirmAlert({
                title: 'Error saving document',
                message: 'There was an error saving the document. Please try again.',
                buttons: [
                    {
                        label: 'OK',
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick: () => {}
                    }
                ]
            });
            setIsSaving(false);
        }
    };

    const validateAndSave = async () => {
        const validationErrors = validateAnnotations();

        if (Object.keys(validationErrors).length > 0) {
            renderValidationAlert(validationErrors);
            return;
        }

        await handleSaveDocument();
    };

    const handleUpdateAnnotationCount = React.useCallback(
        (
            uploadedDocumentId: string,
            updatedAnnotations: Core.Annotations.Annotation[],
            action: AnnotationAction,
            isLoadingAnnotationString: boolean
        ) => {
            onUpdateAnnotationCount(uploadedDocumentId, updatedAnnotations, action);

            if (!isLoadingAnnotationString) {
                // We do not want to update the custom placeholder counts when we load the annotations because it will
                // result in annotations being double counted.
                updateCustomPlaceholderCounts(
                    uploadADocForm,
                    updatedAnnotations.filter(isCustomPlaceholderAnnotation),
                    action
                );
            }
        },
        [onUpdateAnnotationCount, uploadADocForm]
    );

    return (
        <>
            <div className={cx(styles.layout, { [styles.hidden]: !isOpen })} data-test="annotation-viewer-v2">
                <TopBar
                    className={styles.topBar}
                    isSaving={isSaving}
                    webViewerInstance={selectedWebViewerInstance}
                    saveDocument={validateAndSave}
                    dropPointRef={dropPointRef}
                    uploadedDocumentStateItems={uploadedDocumentStateItems}
                    customDoc={customDoc}
                    onClickDocument={setSelectedUploadedDocId}
                    selectedUploadedDocId={selectedUploadedDocId}
                />
                <div className={styles.webViewerContainer}>
                    {customDoc.uploadedDocuments?.map((uploadedDocument, index) => {
                        return (
                            <WebViewerInstanceMemo
                                key={`${uploadedDocument.id}-${index}`}
                                customDoc={customDoc}
                                uploadedDocument={uploadedDocument}
                                isSelected={uploadedDocument.id === selectedUploadedDocId}
                                onWebViewerCreated={handleWebviewerCreated}
                                onUpdateClientAnnotationCounts={handleUpdateAnnotationCount}
                                isOnboarding={isOnboarding}
                                onDrop={handleDrop}
                                customPlaceholders={customPlaceholders.fields.value}
                                hideCustomPlaceholderTitles={hideCustomPlaceholderTitles}
                                hoveredCustomPlaceholderId={hoveredCustomPlaceholderId}
                                numberOfFormClients={numberOfFormClients}
                                onWebViewerInitialised={onWebViewerInitialised}
                            />
                        );
                    })}
                </div>
                <Sidebar
                    dropPointRef={dropPointRef}
                    className={styles.sidebar}
                    clients={clients}
                    uploadedDocumentStateItems={uploadedDocumentStateItems}
                    selectedUploadedDocId={selectedUploadedDocId}
                    selectedWebViewerInstance={selectedWebViewerInstance}
                    customDoc={customDoc}
                    onClickDocument={setSelectedUploadedDocId}
                    agencyDetails={agencyDetails}
                    isOnboarding={isOnboarding}
                    customPlaceholders={customPlaceholders.fields.value}
                    defaultOpenItems={defaultOpenSidebarItems}
                    hideCustomPlaceholderTitles={hideCustomPlaceholderTitles}
                    toggleHideCustomPlaceholderTitles={() => setHideCustomPlaceholderTitles(oldValue => !oldValue)}
                    setHoveredCustomPlaceholderId={setHoveredCustomPlaceholderId}
                />
            </div>
            <CustomPlaceholderListeners
                webviewerInstances={uploadedDocumentStateItems.map(uploadedDocument => uploadedDocument.instance)}
            />
        </>
    );
};

export default React.memo(PdfViewer);
