import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import debounce from 'lodash/debounce';
import { DEBOUNCE_TIME } from '../../config';
import { REQUEST_TO_CHANGE_STATUS_RESOLVED } from '../../config';
import * as lease from '../../actions/lease';
import { CheckboxCheck } from '../../components/form/FormCheckboxCheck';
import RequestsToChangeItem from '../../components/dashboard/requestsToChange/RequestsToChangeItem';

import '../../sass/requestsToChange.scss';

class RequestsToChange extends React.Component {
    constructor() {
        super();
        this.changeForm = debounce(this.changeForm.bind(this), DEBOUNCE_TIME);
        this.state = {
            isShowResolved: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.leaseAllInfo !== nextProps.leaseAllInfo && !!nextProps.leaseAllInfo.id) {
            this.props.initialiseStateAgreement(nextProps.leaseAllInfo);
            this.props.editLeaseAgreement(nextProps.leaseAllInfo, nextProps.activeRequest.step);
        }
    }

    componentDidMount() {
        const { requests } = this.props;

        this.setLeaseAgreement(requests[0].lease, requests[0].leaseAgreementStep);
    }

    render() {
        const { requests, activeRequest, resolveRequest, unresolveRequest, steps } = this.props;
        const { isShowResolved } = this.state;

        return (
            <Form initialValues={{ isShowResolved: false }} onSubmit={() => {}}>
                <form className="requests-to-change">
                    <FormSpy onChange={state => this.changeForm(state)} />
                    <div className="requests-to-change-container">
                        <div className="head">Request(s) to change</div>
                        <div className="body">
                            {requests.map((request, index) => {
                                if (!isShowResolved || request.status === REQUEST_TO_CHANGE_STATUS_RESOLVED) {
                                    return (
                                        <RequestsToChangeItem
                                            key={index}
                                            request={request}
                                            title={
                                                steps[request.leaseAgreementStep - 2]
                                                    ? steps[request.leaseAgreementStep - 2].title
                                                    : ''
                                            }
                                            isActive={
                                                activeRequest.leaseId === request.lease &&
                                                activeRequest.step === request.leaseAgreementStep
                                            }
                                            setLeaseAgreement={() =>
                                                this.setLeaseAgreement(request.lease, request.leaseAgreementStep)
                                            }
                                            resolveRequest={resolveRequest}
                                            unresolveRequest={unresolveRequest}
                                        />
                                    );
                                }
                            })}
                        </div>
                        <div className="footer">
                            <CheckboxCheck name="isShowResolved" label="Show Resolved" value={false} />
                        </div>
                    </div>
                </form>
            </Form>
        );
    }

    setLeaseAgreement(leaseId, step) {
        this.props.getInfoLease(leaseId);
        this.props.setActiveRequest(leaseId, step);
    }

    changeForm(dataForm) {
        this.setState({
            ...this.state,
            isShowResolved: dataForm.values.isShowResolved
        });
    }
}

export default connect(
    state => ({
        requests: state.lease.common.requests,
        activeRequest: state.lease.common.activeRequest,
        leaseAllInfo: state.lease.common.leaseAllInfo
    }),
    {
        getInfoLease: lease.getInfoLease,
        editLeaseAgreement: lease.editLeaseAgreement,
        initialiseStateAgreement: lease.initialiseStateAgreement,
        setActiveRequest: lease.setActiveRequest,
        resolveRequest: lease.resolveRequest,
        unresolveRequest: lease.unresolveRequest
    }
)(RequestsToChange);

RequestsToChange.propTypes = {
    steps: PropTypes.array,
    requests: PropTypes.array,
    leaseAllInfo: PropTypes.object,
    activeRequest: PropTypes.object,
    getInfoLease: PropTypes.func,
    editLeaseAgreement: PropTypes.func,
    initialiseStateAgreement: PropTypes.func,
    resolveRequest: PropTypes.func,
    unresolveRequest: PropTypes.func
};
