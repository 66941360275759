import React from 'react';
import { getPmStepNo, getPmStepTitle } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { QLD_STATE } from '../../../../../config';

export default function AgreementPmFee({ leaseAllInfo }) {
    const { pmFees, location } = leaseAllInfo;
    if (!pmFees) {
        return null;
    }
    return (
        <div>
            {!!pmFees && (
                <AgreementSection
                    title={getPmStepTitle(location, 'pmFees')}
                    step={getPmStepNo(location, 'pmFees')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <React.Fragment>
                            <div className="propertyDetails">
                                <p className="name">
                                    Management fee: <strong>{pmFees.managementFee}%</strong> (
                                    {pmFees.managementFeeIncGST})
                                    {leaseAllInfo.location === QLD_STATE
                                        ? ` When payable: ${pmFees.managementFeePayable}`
                                        : ''}
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Leasing fee: <strong>{pmFees.initialLeasingFee}</strong> (
                                    {pmFees.initialLeasingFeeIncGST})
                                    {leaseAllInfo.location === QLD_STATE
                                        ? ` When payable: ${pmFees.initialLeasingFeePayable}`
                                        : ''}
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Lease renewal fee: <strong>{pmFees.leaseRenewalFee}</strong> (
                                    {pmFees.leaseRenewalFeeIncGST})
                                    {leaseAllInfo.location === QLD_STATE
                                        ? ` When payable: ${pmFees.leaseRenewalFeePayable}`
                                        : ''}
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Monthly admin fee: <strong>{pmFees.adminFee}</strong> ({pmFees.adminFeeIncGST})
                                    {leaseAllInfo.location === QLD_STATE
                                        ? ` When payable: ${pmFees.adminFeePayable}`
                                        : ''}
                                </p>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">
                                    Lease preparation fee: <strong>{pmFees.leasePreparationFee}</strong> (
                                    {pmFees.leasePreparationFeeIncGST})
                                    {leaseAllInfo.location === QLD_STATE
                                        ? ` When payable: ${pmFees.leasePreparationFeePayable}`
                                        : ''}
                                </p>
                            </div>
                            {leaseAllInfo.location === QLD_STATE &&
                                pmFees.photographyCharges &&
                                pmFees.photographyCharges !== '$' && (
                                    <p className="name">
                                        Photography Charges: <strong>{pmFees.photographyCharges}</strong> (
                                        {pmFees.photographyChargesIncGST}) When payable:{' '}
                                        {pmFees.photographyChargesPayable}
                                    </p>
                                )}
                            {leaseAllInfo.location === QLD_STATE && pmFees.bankCharges && pmFees.bankCharges !== '$' && (
                                <p className="name">
                                    Bank Charges: <strong>{pmFees.bankCharges}</strong> ({pmFees.bankChargesIncGST})
                                    When payable: {pmFees.bankChargesPayable}
                                </p>
                            )}
                            {leaseAllInfo.location === QLD_STATE &&
                                pmFees.financialYearSummary &&
                                pmFees.financialYearSummary !== '$' && (
                                    <p className="name">
                                        Financial Year Summary: <strong>{pmFees.financialYearSummary}</strong> (
                                        {pmFees.financialYearSummaryIncGST}) When payable:{' '}
                                        {pmFees.financialYearSummaryPayable}
                                    </p>
                                )}
                        </React.Fragment>
                        {pmFees.additionalFeesList.map((fee, index) => {
                            return (
                                <div key={index} className="propertyDetails">
                                    <p className="name">
                                        {fee.description}: <strong>{fee.amount}</strong>{' '}
                                        <React.Fragment>({fee.incGST})</React.Fragment>
                                        {location === QLD_STATE ? ` When payable: ${fee.payable}` : ''}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </AgreementSection>
            )}
        </div>
    );
}
