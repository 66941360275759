import React from 'react';
import { useSelector } from 'react-redux';
import { getToastList } from '../selectors/toast/index.js';
import Toast from '../common/components/Toast';

import styles from './ToastsContainer.module.scss';

type ToastsContainerProps = {
    children: React.ReactNode;
};

const ToastsContainer: React.FC<ToastsContainerProps> = ({ children }) => {
    const toastList: any[] = useSelector(getToastList);
    return (
        <>
            {toastList?.length > 0 && (
                <div className={styles.toasts}>
                    {toastList.map((toast, index) => {
                        return <Toast key={index} {...toast} />;
                    })}
                </div>
            )}
            {children}
        </>
    );
};

export default ToastsContainer;
