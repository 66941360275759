import { useEffect, useRef } from 'react';
/**
 * In functional components we can't check the previous value inside a useEffect
 * With this function we can save previous value and use the in useEffect to compare
 * const prevDocStatus = usePrevious(docStatus);
 * useEffect(() => {
 *      if (prevDocStatus) {
 *          do something here
 *      }
 * }, [docStatus]);
 */
export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
