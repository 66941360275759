import React from 'react';

const ClauseItem = ({ item }) => {
    return (
        <div className="clause-item">
            <h3>{item.clause}</h3>
            <p className="clause-content">{item.comment}</p>
        </div>
    );
};

export const ClauseList = ({ specialConditions }) => {
    const content = specialConditions.clauseList.map((item, index) => {
        return <ClauseItem key={index} item={item} />;
    });

    return <div className="clause-list">{content}</div>;
};
