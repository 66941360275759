import { useState } from "react";

export default function useResendCounter() {
    const [resendWaitSeconds, setResendWaitSeconds] = useState(0);

    const startResendCounter = () => {
        let count = 30;

        setResendWaitSeconds(count);

        const timer = setInterval(() => {
            if (count > 0) {
                count--;
                setResendWaitSeconds(count);
            } else {
                clearInterval(timer);
            }
        }, 1000);
    };

    return { resendWaitSeconds, startResendCounter };
}
