import { cloneDeep } from 'lodash';
import {
    DISCLOSURE_DOCUMENT,
    DOCUMENT_CUSTOM,
    DOCUMENT_TERMINATION_NOTICE,
    LEASE_TYPE_RESIDENTIAL,
    NSW_STATE,
    documentCategories
} from '../config';
import { getLeaseTypeForFrontEnd } from './agreementUtils';

export const removeAttributesForClone = value => {
    const data = cloneDeep(value);
    if (data.docType === DOCUMENT_TERMINATION_NOTICE) {
        data.terminationWithoutLease.dateOfNotice = null;
    }
    delete data.completion;
    delete data.pdf;
    return data;
};

export const isDocumentDisclosure = docType => {
    if (docType === DISCLOSURE_DOCUMENT) {
        return true;
    }
    return false;
};

export const isDocumentCustom = docType => {
    if (docType === DOCUMENT_CUSTOM) {
        return true;
    }
    return false;
};

export const getClientTypeFromDoc = (currentDocument, type) => {
    let clientType = currentDocument[type].label;
    if (currentDocument[type].label === 'Custom') {
        clientType = currentDocument[type].primaryClient
            ? currentDocument[type].primaryClient.type
            : currentDocument[type].clients[0].type;
    }
    return clientType;
};

export const removeFileExtension = fileName => {
    return fileName.substring(0, fileName.lastIndexOf('.'));
};

export const getAllPdfSenderDetails = documents => {
    const senderDetails = {
        name: '',
        totalAnnotationCount: 0
    };
    documents.map(doc => {
        if (doc?.senderDetails) {
            senderDetails.name = doc.senderDetails.senderName;
            const totalAnnotationCount = doc.senderDetails.nameCount;
            senderDetails.totalAnnotationCount += totalAnnotationCount;
        }
    });
    return senderDetails;
};

export function getDocumentCategory(leaseType = LEASE_TYPE_RESIDENTIAL, location = NSW_STATE) {
    let frontEndLeaseType = getLeaseTypeForFrontEnd(leaseType);
    frontEndLeaseType = frontEndLeaseType.replace(/_template/g, '');
    return documentCategories[frontEndLeaseType][location];
}
