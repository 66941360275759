import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { has } from 'lodash';

import ModalDialog from '../../../../common/components/ModalDialog';
import { FormRadioGroup } from '../../../form/FormRadioGroup';
import Button from '../../../../common/components/Button';
import styles from './CancelSubscriptionInfoModal.module.scss';
import axios from 'axios';
import { useAccountProvider } from '../../../providers/AccountProvider';
import { isBusinessUserAccount, isAgencyUserAccount } from '../../../../utils/userUtils';

function CancelSubscriptionInfoModal({ isOpen, closeModal, agencyId }) {
    const [isLoading, setIsLoading] = useState(false);
    const accountType = useAccountProvider();
    let options = [];

    const submitForm = values => {
        setIsLoading(true);
        return axios
            .post(`/api/agency/${agencyId}/tariff-plan/cancel-subscription-info`, values)
            .then(() => {
                setIsLoading(false);
                closeModal();
            })
            .catch(error => {
                setIsLoading(false);
                let errors = {};
                if (has(error, 'response.data.errors.subscriptionCancelFeedbackDescription')) {
                    errors[`${values.subscriptionCancelFeedback}Description`] =
                        error.response.data.errors.subscriptionCancelFeedbackDescription;
                }
                return errors;
            });
    };

    if (isAgencyUserAccount(accountType)) {
        options = [
            {
                label: 'It didn’t integrate with our PM software',
                value: 'notIntegratedWithPMSoftware',
                descriptionLabel: 'Which PM Software do you use?',
                descriptionRequired: true
            },
            {
                label: 'Switched to other software that also has a lease signing product',
                value: 'switchedToOther',
                descriptionLabel: 'Which application will you be switching to?',
                descriptionRequired: true
            },
            {
                label: 'This agency will no longer continue to do property management',
                value: 'noLongerDoPropertyManagement'
            },
            {
                label: 'FLK It Over does not have the features I am looking for',
                value: 'flkItOverNotHaveFeatures',
                descriptionLabel: 'Could you give us some examples?',
                descriptionRequired: true
            },
            {
                label: 'Too expensive',
                value: 'expensive'
            },
            {
                label: 'Other',
                value: 'other',
                descriptionLabel: 'Could you give us some more information?',
                descriptionRequired: true
            }
        ];
    } else if (isBusinessUserAccount(accountType)) {
        options = [
            {
                label: 'It was missing functionality we need',
                value: 'missingFunctionality',
                descriptionLabel: 'Could you give us some examples?',
                descriptionRequired: true
            },
            {
                label: 'We are using another document signing product',
                value: 'usingAnotherDocumentProduct',
                descriptionLabel: 'Which product will you be switching to?',
                descriptionRequired: true
            },
            {
                label: 'We have sold our company',
                value: 'soldOurCompany'
            },
            {
                label: 'It is too expensive',
                value: 'expensive'
            },
            {
                label: 'Other',
                value: 'other',
                descriptionLabel: 'Could you give us some more information?',
                descriptionRequired: true
            }
        ];
    }

    return (
        <ModalDialog
            title="We hate to see you go and would love some feedback to
            improve FLK it over. Why are you looking to cancel your
            subscription?"
            isOpen={isOpen}
            closeModal={() => closeModal()}
            modalHeadClassName={styles.subscriptionCancelInfoHeader}
        >
            <Form onSubmit={submitForm}>
                {({ handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="radioGroupList">
                                <FormRadioGroup
                                    name={'subscriptionCancelFeedback'}
                                    radioGroupClass="wrapped-radio-group-grid"
                                    value={values.subscriptionCancelFeedback}
                                    data={options}
                                />
                            </div>
                            <div className={styles.submitFeedbackButton}>
                                <Button
                                    primary
                                    width={220}
                                    type="submit"
                                    loading={isLoading}
                                    disabled={!values.subscriptionCancelFeedback}
                                >
                                    Submit Feedback
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
}

export default CancelSubscriptionInfoModal;
