import React, { memo, useState, forwardRef, useImperativeHandle, RefObject } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { has, cloneDeep } from 'lodash';

import * as Lease from '../../../../reducers/lease.js';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease.js';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease/index.js';
import { getPmDuties } from '../../../../selectors/lease/pmLease.js';

import { FormRadioGroup } from '../../../../components/form/FormRadioGroup.js';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck.js';

import { NO_LABEL, NO_VALUE, YES_LABEL, YES_VALUE } from '../../../../config';
import { FieldArray } from 'react-final-form-arrays';
import { FormTextRegular, FormNumber, FormTextMultiline } from '../../../../components/form/FormText.js';

import '../../../../sass/outgoings.scss';
import styles from './PmDuties.module.scss';
import Button from '../../../../common/components/Button.js';
import { ControlPoint } from '@material-ui/icons';
import { PmDutiesType } from '../../../../types/pm';
import { DEFINE_ME } from '../../../../types/utilityTypes';

const PM_DUTIES_FORM = 'pmDutiesForm';

const initState: PmDutiesType = {
    isPropertyMeteredIndividual: '',
    serviceList: []
};

type FormData = PmDutiesType & {
    bypassFormValidation?: boolean;
};

interface LayoutRef {
    leaseId: string;
    callbackAfterSubmit: () => void;
    bypassFormValidation?: boolean;
}

interface FormRefObject {
    submitStep: () => void;
    callbackAfterSubmit?: () => void;
    bypassFormValidation?: boolean;
}

const PmDuties = forwardRef<FormRefObject, LayoutRef>((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmDuty: PmDutiesType = useSelector(getPmDuties);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const [pmDuties, setPmDuties] = useState(reduxPmDuty || initState);
    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_DUTIES_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values: FormData, form: DEFINE_ME) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = (values: FormData) => {
        const refObject = ref as RefObject<FormRefObject>;
        const currentRef = refObject.current;
        values.bypassFormValidation = currentRef?.bypassFormValidation;
        return updatePmDuties(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef && currentRef.callbackAfterSubmit) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmDuties')) {
                    return error.response.data.errors.pmDuties;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmDuties = (data: DEFINE_ME) => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-duties`, data);
    };

    const removeOtherService = (values: FormData, index: number) => {
        const duties = cloneDeep(values);
        duties.serviceList = [...duties.serviceList.slice(0, index), ...duties.serviceList.slice(index + 1)];
        setPmDuties(duties);
    };

    return (
        <div className="pmDuties">
            <Form
                onSubmit={submitForm}
                initialValues={pmDuties}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push, removeBatch }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_DUTIES_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={(state: { values: FormData }) => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <p className="text margin-bottom-1rem">Charge to Tenant</p>
                                <CheckboxCheck
                                    className="wrap"
                                    name="isWaterUsageCostAdjusted"
                                    label="All water usage costs adjusted for the period of tenancy"
                                />
                                <CheckboxCheck
                                    className="wrap"
                                    name="isWaterUsageCostExcess"
                                    label="All water usage costs in excess of stated KL per annum, with such allowance to be adjusted for the period of tenancy"
                                />
                                {values?.isWaterUsageCostExcess === true && (
                                    <FormNumber name="klPerAnnum" label="Excess KL/annum" required />
                                )}
                                <CheckboxCheck
                                    className="wrap"
                                    name="isWaterSupplyCostAdjusted"
                                    label="All water supply charges adjusted for the period of tenancy"
                                />
                                <CheckboxCheck className="wrap" name="isNoCharges" label="No Charge for water" />
                                <CheckboxCheck className="wrap" name="isOthers" label="Other" />
                                {values?.isOthers === true && (
                                    <FormTextMultiline
                                        name="otherDetails"
                                        label="If Other, please detail the terms"
                                        required
                                    />
                                )}

                                <FormRadioGroup
                                    name="isPropertyMeteredIndividual"
                                    label="Is there a service that the Property is not individually metered for and the Tenant needs to pay a portion of the costs?"
                                    required
                                    radioGroupClass={styles.radioGroupVertical}
                                    value={values.isPropertyMeteredIndividual}
                                    data={[
                                        {
                                            value: YES_VALUE,
                                            label: YES_LABEL,
                                            onClick: () => {
                                                push('serviceList', {
                                                    description: '',
                                                    apportionment: ''
                                                });
                                            }
                                        },
                                        {
                                            value: NO_VALUE,
                                            label: NO_LABEL,
                                            onClick: () => {
                                                const indexes = values?.serviceList?.map((_, i) => i) || [];
                                                removeBatch('serviceList', indexes);
                                            }
                                        }
                                    ]}
                                />
                                {values.isPropertyMeteredIndividual === YES_VALUE && (
                                    <React.Fragment>
                                        <p>Tenant to pay the following apportionment of the cost of the service*</p>
                                        <FieldArray name="serviceList" initialValue={pmDuties.serviceList}>
                                            {({ fields }) => (
                                                <React.Fragment>
                                                    {fields.map((name, index) => {
                                                        return (
                                                            <div key={`outer-${index}`} className="item-condition">
                                                                <div key={`inner-${index}`} className="clauses">
                                                                    <div className="clauses-header">
                                                                        <h3>Service {index + 1}</h3>
                                                                        {index > 0 && (
                                                                            <div
                                                                                className="toggle"
                                                                                onClick={() =>
                                                                                    removeOtherService(values, index)
                                                                                }
                                                                            >
                                                                                <span className="span-remove">
                                                                                    Remove
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="formBox-column">
                                                                        <FormTextRegular
                                                                            name={`serviceList[${index}].service`}
                                                                            label="Service"
                                                                            required
                                                                        />
                                                                        <FormNumber
                                                                            name={`serviceList[${index}].apportionment`}
                                                                            label="Apportionment %"
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            )}
                                        </FieldArray>
                                        <Button
                                            type="button"
                                            onClick={() =>
                                                push('serviceList', {
                                                    description: '',
                                                    apportionment: ''
                                                })
                                            }
                                            startIcon={<ControlPoint />}
                                            className={styles.buttonContainer}
                                        >
                                            Add Service
                                        </Button>
                                    </React.Fragment>
                                )}
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(PmDuties);
