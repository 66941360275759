import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { Form, FormSpy } from 'react-final-form';
import { cloneDeep, has } from 'lodash';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

import { closeAgreementInfoModal, LEASE_STATUS_DRAFT } from '../../../../../../actions/dashboard';
import { updateLeaseInfo } from '../../../../../../actions/lease';

import { getAgency, getUserInfo } from '../../../../../../selectors/user';
import { getLeaseInfo } from '../../../../../../selectors/lease';

import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import IssueNoticeToLeaveSwitch from './components/IssueNoticeToLeaveSwitch';
import RenewalFlowSummary from './RenewalFlowSummary';
import RenewalFlowModal from './RenewalFlowModal';

import styles from './RenewalFlow.module.scss';
import Banner from '../../../../../../common/components/Banner';
import { BANNER_ID_QLD_LEGISLATION } from '../../../../../../config';
import { hasBannerId } from '../../../../../../utils/userUtils';

function RenewalFlow() {
    const dispatch = useDispatch();

    const agencySelected = useSelector(getAgency);
    const loggedInUser = useSelector(getUserInfo);
    const lease = useSelector(getLeaseInfo);

    const [formData, setFormData] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const handleFormDirtyChange = form => {
        if (form.getState().dirty && !isDirty) {
            setIsDirty(true);
        }
    };

    useEffect(() => {
        resetFormData();
    }, [lease, agencySelected]);

    function resetFormData() {
        if (lease.renewalNoticeToLeaveDetails) {
            setFormData({
                ...lease.renewalNoticeToLeaveDetails,
                vacantPossessionDate: lease.renewalNoticeToLeaveDetails.vacantPossessionDate
                    ? new Date(lease.renewalNoticeToLeaveDetails.vacantPossessionDate)
                    : null,
                noticeToLeaveDate: lease.renewalNoticeToLeaveDetails.noticeToLeaveDate
                    ? new Date(lease.renewalNoticeToLeaveDetails.noticeToLeaveDate)
                    : null,
                reminderEmailDate: lease.renewalNoticeToLeaveDetails.reminderEmailDate
                    ? new Date(lease.renewalNoticeToLeaveDetails.reminderEmailDate)
                    : null
            });
        } else if (lease.status === LEASE_STATUS_DRAFT && agencySelected?.renewalNoticeToLeaveDetails) {
            setFormData({ ...agencySelected.renewalNoticeToLeaveDetails });
        } else {
            setFormData({});
        }
        setIsDirty(false);
    }

    const saveRenewal = useMutation(
        data => {
            return axios.post(`/api/agency/lease/${lease.id}/renewal-notice-to-leave-details`, data);
        },
        {
            // We should do this in here. We can't access updated data inside handleSubmit function
            onSuccess: data => {
                dispatch(updateLeaseInfo(data.data.lease));
            }
        }
    );

    const handleSubmit = async values => {
        // Reset mutate data
        // Otherwise it will keep old data and display old error/success messages
        saveRenewal.reset();
        const data = cloneDeep(values);
        try {
            await saveRenewal.mutateAsync(data);
            setIsDirty(false);
            setIsOpen(false);
        } catch (error) {
            let err = {};
            if (has(error, 'response.data.errors.renewalNoticeToLeaveDetails')) {
                err = error.response.data.errors.renewalNoticeToLeaveDetails;
            }
            return err;
        }
    };

    function changeNoticeToLeaveFlow(isRenewalToLeaveFlow, values) {
        if (!isRenewalToLeaveFlow) {
            saveRenewal.reset();
            saveRenewal.mutateAsync({ ...values, isRenewalToLeaveFlow });
            setIsDirty(false);
        } else {
            setFormData({
                ...values,
                isRenewalToLeaveFlow
            });
        }
        setIsOpen(isRenewalToLeaveFlow);
    }

    function closeModal(form) {
        if (isDirty) {
            confirmAlert({
                title: '',
                message: 'You have unsaved changes. Do you want to save before you exit?',
                buttons: [
                    {
                        label: 'Save changes',
                        onClick: async () => {
                            form.submit();
                        }
                    },
                    {
                        label: 'Discard changes',
                        onClick: () => {
                            resetFormData();
                            setIsOpen(false);
                        }
                    }
                ]
            });
        } else {
            resetFormData();
            setIsOpen(false);
        }
    }

    const disabled = lease.status !== LEASE_STATUS_DRAFT;

    return (
        <AgreementSection title="">
            {/* After agent close the banner then we store it in DB. And never show it again */}
            {loggedInUser?.bannerActions && !hasBannerId(loggedInUser?.bannerActions, BANNER_ID_QLD_LEGISLATION) && (
                <Banner
                    title="Qld legislation has recently changed"
                    message="We have included the below functionality when renewing a lease, to assist with the time requirement for issuing a form 12 from October 1st 2022."
                    link="/user/defaults"
                    linkLabel="Click here to update default settings"
                    bannerId={BANNER_ID_QLD_LEGISLATION}
                    agencyId={agencySelected.id}
                    onClick={() => {
                        dispatch(closeAgreementInfoModal());
                    }}
                />
            )}
            <div className={styles.renewalNoticeSection}>
                <Form initialValues={formData} onSubmit={handleSubmit}>
                    {({ handleSubmit, values, form }) => {
                        return (
                            <form
                                onSubmit={handleSubmit}
                                id="renewalNoticeToLeaveDetailsForm"
                                data-test="renewal-notice-to-leave-details-form"
                            >
                                <FormSpy subscription={{ values: true }} onChange={() => handleFormDirtyChange(form)} />
                                <IssueNoticeToLeaveSwitch
                                    changeNoticeToLeaveFlow={changeNoticeToLeaveFlow}
                                    disabled={disabled}
                                    showTooltip={true}
                                    values={values}
                                />
                                {values.isRenewalToLeaveFlow && (
                                    <React.Fragment>
                                        <RenewalFlowSummary
                                            values={values}
                                            summaryView={true}
                                            setIsOpen={setIsOpen}
                                            disabled={disabled}
                                        />
                                        <br />
                                        {!lease?.renewalNoticeToLeaveDetails && (
                                            <p className="text-error">
                                                Dates for the Issue notice to leave have not been configured.
                                                <br />
                                                Please update the dates or switch off the Issue notice to leave.
                                            </p>
                                        )}
                                        <p className="text-gray">
                                            All Termination Notices will have reason 'End of term agreement has expired'
                                        </p>
                                    </React.Fragment>
                                )}
                                <RenewalFlowModal
                                    form={form}
                                    values={values}
                                    isOpen={isOpen}
                                    changeNoticeToLeaveFlow={changeNoticeToLeaveFlow}
                                    setFormData={setFormData}
                                    closeModal={closeModal}
                                    isLoading={saveRenewal.isLoading}
                                    disabled={disabled}
                                />
                            </form>
                        );
                    }}
                </Form>
            </div>
        </AgreementSection>
    );
}

export default RenewalFlow;
