import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import Panel from '../../../common/components/Panel';
import ReactTooltip from 'react-tooltip';
import { get } from 'lodash';
import '../../../sass/lightTooltip.scss';
import { editPermissions } from '../../../utils/userPermissions';
import { tabs, HIDE_MESSAGE_TIME, imageCompressionOptions } from '../../../config';
import loadImage from '../../../../assets/images/icons/load-img.jpg';
import { getUserInfo } from '../../../selectors/user';
import { getUserAgency } from '../../../selectors/agency';
import { getAccount } from '../../../selectors/settings/account';
import imageCompression from 'browser-image-compression';
import { useRollbarProvider } from '../../../components/providers/RollbarProvider';
import { getGeneralAppLabel } from '../../../utils/labelUtils';
import { useAccountProvider } from '../../../components/providers/AccountProvider';

const fileTypes = ['.png', '.jpg', '.jpeg', '.gif'];

const AgencyLogoForm = props => {
    const loggedInUser = useSelector(getUserInfo);
    const agency = useSelector(getUserAgency);
    const accountState = useSelector(getAccount);
    const Rollbar = useRollbarProvider();
    const accountType = useAccountProvider();

    const [logoPreview, setLogoPreview] = useState('');
    const [file, setFile] = useState('');
    const [saveStatusTimeout, setSaveStatusTimeout] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [dropZoneError, setDropZoneError] = useState('');

    useEffect(() => {
        return function cleanup() {
            if (saveStatusTimeout) {
                clearTimeout(saveStatusTimeout);
            }
        };
    });

    useEffect(() => {
        if (saveStatusTimeout == true) {
            setTimeout(() => {
                setSaveStatusTimeout(null);
            }, HIDE_MESSAGE_TIME);
        }
    }, [saveStatusTimeout]);

    const onDropRejected = errors => {
        setDropZoneError(errors[0].errors[0].message);
    };

    const onDrop = files => {
        setDropZoneError('');
        const reader = new FileReader();
        reader.onload = event => {
            setLogoPreview(event.target.result);
        };
        reader.readAsDataURL(files[0]);
        setFile(files[0]);
    };

    const addAgencyLogo = () => {
        // if a timeout handler already exists, clear it
        if (saveStatusTimeout) {
            clearTimeout(saveStatusTimeout);
        }
        // this changes state so the useEffect fires to create timeout
        setSaveStatusTimeout(true);
        imageCompression(file, imageCompressionOptions)
            .then(compressedFile => {
                let data = new FormData();
                data.append('logo', compressedFile);
                return props.addAgencyLogo(agency.id, data).then(response => {
                    let message = get(response, 'error.response.data.error');
                    if (typeof message !== 'undefined') {
                        setValidationError(message);
                    }
                });
            })
            .catch(error => {
                Rollbar.error(
                    'Something went wrong in image compression',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
            });
    };

    const renderLogoSaveFailed = () => {
        return (
            <div className="logo-save-failed-wrapper">
                <span className="savestatus-failed">
                    Saving failed{' '}
                    <span className="icon savestatus-icon" data-tip={true} data-for={'logo-save-failed'} />
                </span>
                {validationError && (
                    <ReactTooltip
                        id="logo-save-failed"
                        class="tooltip-save-failed light-tooltip"
                        place="bottom"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        {validationError}
                    </ReactTooltip>
                )}
            </div>
        );
    };

    let userRole = loggedInUser.role;
    const isPending = accountState.ADD_AGENCY_LOGO_REQUEST_PENDING;
    const savedSuccessfully = accountState.ADD_AGENCY_LOGO_SUCCESS;
    const saveFailed = accountState.ADD_AGENCY_LOGO_FAIL;

    return (
        <Panel
            title={getGeneralAppLabel('agencyLogo', accountType)}
            leftButton={
                <div>
                    {savedSuccessfully && saveStatusTimeout && (
                        <span className="savestatus-saved">
                            Saved
                            <span className="icon savestatus-icon" />
                        </span>
                    )}
                    {saveFailed && saveStatusTimeout && renderLogoSaveFailed()}
                </div>
            }
            rightButton={
                <div>
                    {!isPending && logoPreview && (
                        <button
                            type="submit"
                            className="savestatus-button btn-agency-details-save"
                            onClick={addAgencyLogo}
                        >
                            Save changes
                        </button>
                    )}
                    {isPending && logoPreview && (
                        <span>
                            <button
                                type="submit"
                                disabled="disabled"
                                className="savestatus-button btn-agency-details-save"
                            >
                                <span className="savestatus-spinner" /> Saving changes
                            </button>
                        </span>
                    )}
                </div>
            }
        >
            <div className="dropzone-container">
                {!!agency.logo && !logoPreview && (
                    <div className="logo-preview">
                        <img src={agency.logo} alt="" />
                    </div>
                )}
                {!!logoPreview && (
                    <div className="logo-preview">
                        <img src={logoPreview} alt="" />
                    </div>
                )}
                <Dropzone
                    className="dropzone"
                    multiple={false}
                    maxSize={8000000}
                    accept={fileTypes.join(', ')}
                    onDrop={files => onDrop(files)}
                    onDropRejected={errors => onDropRejected(errors)}
                >
                    {({ getRootProps, getInputProps }) => {
                        return (
                            <div className="dropzone" {...getRootProps()}>
                                <input {...getInputProps()} />
                                {dropZoneError && <span className="error-message">{dropZoneError}</span>}
                                {!agency.logo && !logoPreview && (
                                    <img className="load-img" src={loadImage} alt="load" />
                                )}
                                {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                    <span>Upload {!!agency.logo || !!logoPreview ? 'another' : ''} logo</span>
                                )}
                                {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                    <div className="help-text">
                                        <p>PNG, JPG, GIF max file size 8 Mb.</p>
                                        <p>White or transparent background.</p>
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </Dropzone>
            </div>
        </Panel>
    );
};

export default AgencyLogoForm;
