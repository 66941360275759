import React, { useEffect, useState } from 'react';
import { FormTextRegular, FormMaskedText } from '../../../form/FormText';
import { FormEmail } from '../../../form/FormEmail';
import { FormPhone } from '../../../form/FormPhone';
import { CheckboxCheck } from '../../../form/FormCheckboxCheck';
import { FormRadioGroup } from '../../../form/FormRadioGroup';
import { ContactServiceExtraContacts } from './ContactServiceExtraContacts';
import { corporationRolesList, YES_VALUE, NO_VALUE, NO_LABEL, YES_LABEL } from '../../../../config';
import { FieldArray } from 'react-final-form-arrays';
import { getResTenLabel } from '../../../../utils/labelUtils';
import ReactTooltip from 'react-tooltip';
import { InfoSharp } from '@material-ui/icons';
import { FormButtonSelect } from '../../../form/responsive/FormButtonSelect';
import {
    isLeaseLocationSA,
    isLeaseLocationVIC,
    isLeaseLocationQLD,
    isLongTermRenewalSubLease
} from '../../../../utils/agreementUtils';
import { useSelector } from 'react-redux';
import { getSubLeaseType } from '../../../../selectors/lease';

export default function TenantPersonGroup({
    index,
    removeTenant,
    location,
    push,
    tenant,
    handlePrimaryTenantSelect,
    handleEmailChange,
    form
}) {
    const subLeaseType = useSelector(getSubLeaseType);
    const [isCorporation, setIsCorporation] = useState(tenant.isCorporation);
    const [addModal, setAddModal] = useState(false);

    const { extraContacts, tenantToEnterEmergencyContact } = tenant;

    useEffect(() => {
        setIsCorporation(tenant.isCorporation);
    }, [tenant]);

    // If tenant needs to enter emergency contacts, set the values to empty strings
    useEffect(() => {
        if (tenantToEnterEmergencyContact == YES_VALUE) {
            form.change(`tenants[${index}].emergencyContactFullnames`, '');
            form.change(`tenants[${index}].emergencyContactEmail`, '');
            form.change(`tenants[${index}].emergencyContactPhone`, '');
        }
    }, [tenantToEnterEmergencyContact, form, index]);

    const toggleAddModal = () => {
        setAddModal(!addModal);
    };

    const addMethod = (index, type) => {
        let extraContact = {
            contactValue: '',
            contactType: type
        };
        push(`tenants[${index}].extraContacts`, extraContact);
        setAddModal(!addModal);
    };

    function clearPerson(index) {
        form.change(`tenants[${index}].phone`, '+61');
        form.change(`tenants[${index}].corporationName`, '');
        form.change(`tenants[${index}].corporationAddress`, '');
        form.change(`tenants[${index}].firstName`, '');
        form.change(`tenants[${index}].secondName`, '');
        form.change(`tenants[${index}].address`, '');
        form.change(`tenants[${index}].ABN`, '');
        form.change(`tenants[${index}].ACN`, '');
        form.change(`tenants[${index}].role`, '');
    }

    const setCorporation = (value, index) => {
        setIsCorporation(value);
        clearPerson(index);
    };

    let header;
    if (index === 0) {
        header = (
            <h3>
                {getResTenLabel('tenant', location)} {index + 1}
            </h3>
        );
    } else {
        header = (
            <span className="removePerson">
                <h3>
                    {getResTenLabel('tenant', location)} {index + 1}
                </h3>
                <button type="button" onClick={() => removeTenant(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="tenant">
            {header}
            <div className="formBox">
                <div className="formBox-column">
                    <CheckboxCheck
                        name={`tenants[${index}].isCorporation`}
                        className={`corporation-${index}-check`}
                        label={`${getResTenLabel('tenant', location)} is a corporation`}
                        value={`tenants[${index}].isCorporation`}
                        onClick={value => setCorporation(value, index)}
                    />
                    {isCorporation && (
                        <React.Fragment>
                            <FormTextRegular
                                name={`tenants[${index}].corporationName`}
                                label="Corporation name"
                                required
                            />
                            <FormTextRegular
                                name={`tenants[${index}].corporationAddress`}
                                label="Corporation address"
                                required
                            />
                            <FormMaskedText name={`tenants[${index}].ABN`} label="ABN" mask="11 111 111 111" />
                            <FormMaskedText name={`tenants[${index}].ACN`} label="ACN" mask="111 111 111" />
                            <p className="form-field-label">Person signing:</p>
                        </React.Fragment>
                    )}
                </div>
                <div className="nowrap fields-full-width-for-mobile">
                    <FormTextRegular name={`tenants[${index}].firstName`} label="First Name" required />
                    <FormTextRegular name={`tenants[${index}].middleName`} label="Middle Name" />
                </div>
                <div className="formBox-column">
                    <FormTextRegular name={`tenants[${index}].secondName`} label="Last Name" required />
                    {isCorporation && (
                        <React.Fragment>
                            <FormButtonSelect
                                name={`tenants[${index}].role`}
                                selectClassName={`tenant-${index}-role`}
                                options={corporationRolesList}
                                value={tenant.role}
                                label="Role of person signing"
                                isButtonGroup={true}
                            />
                            {tenant.role === 'Other' && (
                                <FormTextRegular name={`tenants[${index}].otherRole`} label="Role name" required />
                            )}
                        </React.Fragment>
                    )}
                    <FormPhone
                        className={`tenants-${index}-phone`}
                        name={`tenants[${index}].phone`}
                        label="Phone"
                        required
                        dataTest={`tenantsPhone[${index}]`}
                    />
                    <FormEmail
                        name={`tenants[${index}].email`}
                        label={getResTenLabel('tenantEmail', location)}
                        required
                        onChange={e => handleEmailChange(e, index)}
                    />
                    {isLeaseLocationVIC(location) && (
                        <React.Fragment>
                            <i data-tip={true} data-for="explanation-pending">
                                Consent to electronic service of notices:
                                <InfoSharp className="tooltip-info-icon text-gray" />
                            </i>
                            <ReactTooltip
                                id="explanation-pending"
                                class="tooltip"
                                place="top"
                                effect="solid"
                                globalEventOff="wheel"
                            >
                                <p>
                                    As part of the new legislation the renter is required to be given the option to tick
                                    YES or NO to allow the delivery of documents by electronic method and then nominate
                                    their contact details.
                                </p>
                                <p>
                                    We have built this into the process of FLK it over for you, when the renter is sent
                                    a link to sign they will be required to select YES or NO to the above, if they
                                    choose YES we will enter the email address from the dashboard above for them to
                                    confirm, or they could choose a different email.
                                </p>
                                <p>We will display and show a (tick) next to this email in the dashboard for you.</p>
                            </ReactTooltip>
                        </React.Fragment>
                    )}
                    {isLeaseLocationVIC(location) && !isCorporation && (
                        <React.Fragment>
                            <FormTextRegular name={`tenants[${index}].address`} label="Current address" required />
                            <FormTextRegular name={`tenants[${index}].ACN`} label="ACN" />
                        </React.Fragment>
                    )}
                    {isLeaseLocationQLD(location) && (
                        <>
                            <FormRadioGroup
                                label="The tenant's emergency contact details are:"
                                name={`tenants[${index}].tenantToEnterEmergencyContact`}
                                data={[
                                    {
                                        label: 'Tenant to enter',
                                        id: `tenants[${index}].tenantToEnterEmergencyContactYes`,
                                        value: YES_VALUE
                                    },
                                    {
                                        label: 'Agent to enter',
                                        id: `tenants[${index}].tenantToEnterEmergencyContactNo`,
                                        value: NO_VALUE
                                    }
                                ]}
                                required
                            />
                            {tenantToEnterEmergencyContact == NO_VALUE && (
                                <>
                                    <FormTextRegular
                                        name={`tenants[${index}].emergencyContactFullnames`}
                                        label="Emergency contact full name/s"
                                        required
                                    />
                                    <FormTextRegular
                                        name={`tenants[${index}].emergencyContactPhone`}
                                        label="Emergency contact phone"
                                        required
                                    />
                                    <FormTextRegular
                                        name={`tenants[${index}].emergencyContactEmail`}
                                        label="Emergency contact email"
                                        required
                                    />
                                </>
                            )}
                        </>
                    )}

                    <FieldArray name={`tenants[${index}].extraContacts`} initialValue={extraContacts}>
                        {({ fields }) => (
                            <React.Fragment>
                                {fields.map((name, fieldsIndex) => {
                                    return (
                                        <ContactServiceExtraContacts
                                            key={fieldsIndex}
                                            index={index}
                                            indexKey={fieldsIndex}
                                            blockType="tenants"
                                            extraContact={fields.value[fieldsIndex]}
                                            removeContact={() => fields.remove(fieldsIndex)}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </FieldArray>
                    {!(isLongTermRenewalSubLease(subLeaseType) && isLeaseLocationSA(location)) && (
                        <CheckboxCheck
                            name={`tenants[${index}].isPrimaryTenant`}
                            className={`primary-${index}-check`}
                            label={`Primary ${getResTenLabel('tenantLower', location)}`}
                            onClick={value => handlePrimaryTenantSelect(index, value)}
                        />
                    )}
                    <div className="button">
                        <button className="add-more-contact-details" type="button" onClick={() => toggleAddModal()}>
                            Add more contact details
                        </button>
                        <div className={addModal ? 'addModal' : 'addModal hide'}>
                            <div className="choose-items">
                                <div className="choose" onClick={() => addMethod(index, 'Other')}>
                                    Other
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
