import * as React from 'react';
import { Form } from 'react-final-form';

import { FormTextRegular, FormTextHidden } from '../../../../components/form/FormText';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import ModalDialog from '../../../../common/components/ModalDialog';
import Button from '../../../../common/components/Button';
import { addContact, updateContact } from '../../../../actions/emergencyContacts';

import '../../../../sass/modalEmergencyContacts.scss';
import { connect } from 'react-redux';
import axios from 'axios';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';

class ModalEmergencyContacts extends React.Component {
    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            addModal: false,
            contact: {},
            extraContacts: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        // if the component was updated with props (not with state)
        // if the prevState was set but no contact was sent in via props
        if (
            this.props.contact !== prevProps.contact ||
            (this.props.contact === undefined && prevState.contact.firstName !== '')
        ) {
            this.setState({
                contact: this.props.contact || {
                    firstName: '',
                    secondName: '',
                    tradeType: '',
                    phone: '',
                    company: '',
                    extraContacts: []
                },
                extraContacts: (this.props.contact && this.props.contact.extraContacts) || []
            });
        }
    }

    saveContactRequest = contact => {
        if (this.state.contact.id) {
            return axios.put(`/api/agency/account/emergency-contacts/${contact.id}`, contact);
        } else {
            return axios.post('/api/agency/account/emergency-contacts', contact);
        }
    };

    handleSubmit = values => {
        return this.saveContactRequest(values)
            .then(response => {
                if (this.state.contact.id) {
                    this.props.onSubmitUpdateContactSuccess(
                        response.data.emergencyContact,
                        response.data.templatesToUpdate
                    ); // store response contact in state
                } else {
                    this.props.onSubmitNewContactSuccess(response.data.emergencyContact);
                }
                return Promise.resolve();
            })
            .catch(error => {
                const response = error.response;
                return response.data.errors;
            });
    };

    toggleAddModal() {
        this.setState({
            addModal: !this.state.addModal
        });
    }

    renderLeftButton = () => {
        const { type, deleteContact } = this.props;

        if (type === 'Edit') {
            return (
                <Button
                    error
                    className="btn-edit-remove"
                    onClick={() => {
                        deleteContact(this.props.contact.id);
                    }}
                >
                    <i className="icon-remove" />
                    Remove contact
                </Button>
            );
        }
    };

    clickHandler = () => {
        this.formRef.dispatchEvent(new Event('submit'));
    };

    renderRightButton = () => {
        const { type } = this.props;
        if (type === 'Add') {
            return (
                <Button primary onClick={this.clickHandler}>
                    Create contact
                </Button>
            );
        }
        if (type === 'Edit') {
            return (
                <Button primary className="btn-edit-save" onClick={this.clickHandler}>
                    Save changes
                    <i className="icon-save" />
                </Button>
            );
        }
    };

    render() {
        const { isOpen, closeModal, type } = this.props;
        const { contact, extraContacts } = this.state;

        return (
            <ModalDialog
                title={`${type} emergency contact`}
                className="emergency-contact"
                isOpen={isOpen}
                closeModal={closeModal}
                leftButton={this.renderLeftButton()}
                rightButton={this.renderRightButton()}
            >
                <Form
                    initialValues={contact}
                    onSubmit={this.handleSubmit}
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                >
                    {({
                        handleSubmit,
                        form: {
                            mutators: { push }
                        }
                    }) => {
                        return (
                            <form
                                id="contact-form"
                                noValidate
                                className="emergency-contacts-form"
                                onSubmit={handleSubmit}
                                ref={f => (this.formRef = f)}
                            >
                                <FormTextRegular name="tradeType" label="Trade Type (e.g. Plumber or Electrician)" />
                                <div className="emergency-contacts-form-names">
                                    <div className="emergency-contacts-form-names-item">
                                        <FormTextRegular name="firstName" label="First Name" />
                                    </div>
                                    <div className="emergency-contacts-form-names-item">
                                        <FormTextRegular name="secondName" label="Last Name" />
                                    </div>
                                </div>
                                <FormTextRegular name="company" label="Company" />
                                <FormTextRegular name="phone" label="Phone" />
                                <CheckboxCheck name="default" label="Contact will appear on lease by default" />
                                <FieldArray name="extraContacts" initialValue={extraContacts}>
                                    {({ fields }) => (
                                        <div>
                                            {fields.map((name, index) => {
                                                return (
                                                    <div className="extra-contact" key={index}>
                                                        <FormTextHidden name={`${name}.contactType`} />
                                                        {/* Below we grab the value of contactType (Example: Email, Phone) to populate the label */}
                                                        <FormTextRegular
                                                            name={`${name}.contactValue`}
                                                            label={fields.value[index].contactType}
                                                        />
                                                        <button type="button" className="button-default button-error">
                                                            <i
                                                                className="icon-cross"
                                                                onClick={() => fields.remove(index)}
                                                            />
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </FieldArray>
                                <div className="button">
                                    <button type="button" onClick={() => this.toggleAddModal()}>
                                        Add extra contact details
                                    </button>
                                    <div className={this.state.addModal ? 'addModal' : 'addModal hide'}>
                                        <div className="choose-items">
                                            <div
                                                className="choose"
                                                onClick={() => {
                                                    push('extraContacts', { contactType: 'Email' });
                                                    this.toggleAddModal();
                                                }}
                                            >
                                                Email
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            </ModalDialog>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmitNewContactSuccess: contact => {
            dispatch(addContact(contact));
        },
        onSubmitUpdateContactSuccess: (contact, templatesToUpdate) => {
            dispatch(updateContact(contact, templatesToUpdate));
        }
    };
};

export default connect(null, mapDispatchToProps)(ModalEmergencyContacts);
