import React, { memo } from 'react';

import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../../config';
import { FormRadioGroup } from '../../../../form/FormRadioGroup';

const SeparatedWaterUsage = () => {
    return (
        <FormRadioGroup
            label="Will the tenant be required to pay separately for water usage?"
            name={'separatedWaterUsage'}
            className="additional-term-item"
            data={[
                {
                    label: YES_LABEL,
                    className: 'separated-water-usage-yes',
                    id: 'separated-water-usage-yes',
                    value: YES_VALUE
                },
                {
                    label: NO_LABEL,
                    className: 'separated-water-usage-no',
                    id: 'separated-water-usage-no',
                    value: NO_VALUE
                }
            ]}
        />
    );
};

export default memo(SeparatedWaterUsage);
