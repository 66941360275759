import { OPEN_HELP, CLOSE_HELP } from '../reducers/help';

export function openHelp(helpId) {
    return {
        type: OPEN_HELP,
        helpId
    };
}

export function closeHelp(helpId) {
    return {
        type: CLOSE_HELP,
        helpId
    };
}
