import React, { Component } from 'react';
import * as dashboard from '../../../../actions/dashboard';
import ReactTooltip from 'react-tooltip';

import Signee from '../../../../components/dashboard/components/Signee.js';
import { getTotalStepsCount } from '../../../../utils/agreementUtils';

import '../../../../sass/progressBlock.scss';
import '../../../../sass/lightTooltip.scss';

class ProgressBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { status, signatures, progress, keys, leaseType, location, subLeaseType } = this.props;
        let percent = 0;
        let stepsCount = 0;
        let classProgress = 'progress';
        let signList = signatures ? signatures.filter(sign => true === sign.isSigned) : null;
        let countOfSign = signList ? signList.length : null;
        if (leaseType && location) {
            stepsCount = getTotalStepsCount(leaseType, subLeaseType, location);
            if (progress >= 0) {
                percent = (progress * 100) / stepsCount;
            }
            if (percent > 20) {
                classProgress = 'yellow progress';
            }
            if (percent > 50) {
                classProgress = 'green progress';
            }
        }
        if (status === dashboard.LEASE_STATUS_DRAFT) {
            return (
                <div className="progress-block">
                    <div className="progress-circle-container mobile-only">
                        <div className={'progress-circle progress-' + Math.round(percent) + ' ' + classProgress}>
                            <span>
                                {progress}/{stepsCount}
                            </span>
                        </div>
                    </div>
                    <div className="line desktop-only">
                        <div className={classProgress} style={{ width: percent + '%' }} />
                    </div>
                    <div className="numbers-pr desktop-only">
                        <span className="signed">{progress}/</span>
                        {stepsCount}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="progress-block" data-tip={true} data-for={`signatures-${keys}`}>
                    <div className="numbers-pr">
                        <span className="signed">{countOfSign}/</span>
                        {signatures.length}
                    </div>
                    <ReactTooltip
                        id={`signatures-${keys}`}
                        class="tooltip-signatures light-tooltip"
                        place="bottom"
                        effect="solid"
                        globalEventOff="wheel"
                    >
                        {signatures.map((item, i) => {
                            return <Signee person={item} role={item.role} key={i} />;
                        })}
                    </ReactTooltip>
                </div>
            );
        }
    }
}

export default ProgressBlock;
