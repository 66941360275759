import React, { Component } from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import {
    getPmStepNo,
    getPmStepTitle,
    isLeaseLocationQLD,
    isLeaseLocationVIC,
    isLeaseLocationSA
} from '../../../../../utils/agreementUtils';

class AgreementPmLicensee extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        if (!leaseAllInfo.licensee) {
            return null;
        }

        const sectionTitle = leaseAllInfo.licensee.isConjunctAgent
            ? 'Licensee & Conjunct Agent'
            : getPmStepTitle(leaseAllInfo.location, 'licensee');
        return (
            <div>
                <AgreementSection
                    title={sectionTitle}
                    step={getPmStepNo(leaseAllInfo.location, 'licensee')}
                    leaseAllInfo={leaseAllInfo}
                >
                    {leaseAllInfo.licensee && (
                        <div className="data data-row">
                            {!isLeaseLocationQLD(leaseAllInfo.location) &&
                                !isLeaseLocationSA(leaseAllInfo.location) && (
                                    <p className="name">{leaseAllInfo.licensee.fullName}</p>
                                )}
                            <p className="name">{leaseAllInfo.licensee.agencyName}</p>
                            {leaseAllInfo.licensee.companyName && (
                                <p className="name">{leaseAllInfo.licensee.companyName}</p>
                            )}
                            <p className="name">{leaseAllInfo.licensee.abn}</p>
                            {leaseAllInfo.licensee.acn && <p className="name">{leaseAllInfo.licensee.acn}</p>}
                            {!isLeaseLocationVIC(leaseAllInfo.location) &&
                                !isLeaseLocationSA(leaseAllInfo.location) && (
                                    <p className="name">{leaseAllInfo.licensee.licenceNo}</p>
                                )}
                            {isLeaseLocationSA(leaseAllInfo.location) && (
                                <p className="name">{leaseAllInfo.licensee.rlaNumber}</p>
                            )}
                            <p className="name">{leaseAllInfo.licensee.address}</p>
                            <p className="name">{leaseAllInfo.licensee.mobile}</p>
                            <p className="name">{leaseAllInfo.licensee.email}</p>
                            {leaseAllInfo.licensee.isRegisteredForGst && <p className="text">Registered for GST</p>}
                        </div>
                    )}
                </AgreementSection>
            </div>
        );
    }
}

export default AgreementPmLicensee;
