import React from 'react';
import Card, { CardShadow, CardStyles } from '@app/common/components/cards/Card';
import Icon, { IconSize, Icons } from '@app/common/components/Icon';
import { formatCurrencyWithCurrencySign } from '@app/utils/formUtils.js';
import { UploadADoc } from '@app/types/UploadADoc';

import styles from './LinkedPartnersSummary.module.scss';

type LinkedPartnersSummaryProps = {
    currentDocument: UploadADoc;
};

const LinkedPartnersSummary: React.FC<LinkedPartnersSummaryProps> = ({ currentDocument }) => {
    return (
        <div className={styles.summaryItem}>
            <h2 className={styles.header}>Linked Partner(s)</h2>
            <Card style={CardStyles.SQUARE} shadow={CardShadow.SMALL} animate={false}>
                <div className={styles.listNowSummary}>
                    <Icon icon={Icons.LIST_NOW} size={IconSize.LARGE} />
                    <div className={styles.section}>
                        <p className={styles.title}>List Now</p>
                        <p className={styles.address}>{currentDocument?.listNow?.address}</p>
                    </div>
                    <p className={styles.budget}>
                        {formatCurrencyWithCurrencySign(currentDocument?.listNow?.budget, true, 2)}
                    </p>
                </div>
            </Card>
        </div>
    );
};

export default LinkedPartnersSummary;
