import React from 'react';
import { FormTextRegular } from '../../../../components/form/FormText';

export function CostGroup({ index, removeFee, captionName, captionDescription }) {
    return (
        <div className="item-condition">
            <div className="clauses">
                <div className="clauses-header">
                    <h3>Cost {index + 1}</h3>
                    <div className="toggle" onClick={() => removeFee(index)}>
                        <span className="span-remove">Remove</span>
                    </div>
                </div>
                <div className="formBox-column">
                    <FormTextRegular
                        name={`additionalCostsList[${index}].${captionName || 'description'}`}
                        label={captionDescription || 'Description'}
                        required
                    />
                    <div className="fee-item">
                        <div className="nowrap fields-full-width-for-mobile">
                            <FormTextRegular name={`additionalCostsList[${index}].amount`} label="Amount" required />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
