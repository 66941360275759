import React from 'react';
import { FormTextRegular } from './../../../form/FormText';

export const PetDetailGroup = ({ index, removePet }) => {
    let header;
    if (index === 0) {
        header = <h3>Pet {index + 1}</h3>;
    } else {
        header = (
            <span className="removePet">
                <h3>Pet {index + 1}</h3>
                <button type="button" onClick={() => removePet(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }

    return (
        <div className="pets">
            {header}
            <div className="formBox">
                <FormTextRegular name={`animals.pets[${index}].information`} label="Type of pet" />
                <FormTextRegular name={`animals.pets[${index}].amount`} label="Number" />
            </div>
        </div>
    );
};
