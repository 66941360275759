import { CLOSE_DOCUMENT_HEADER_BANNER, DISPLAY_DOCUMENT_HEADER_BANNER } from '../../actions/types';

import produce from 'immer';

const initState = {
    isDocumentBannerVisible: false
};

export default function banner(state = initState, action) {
    switch (action.type) {
        case CLOSE_DOCUMENT_HEADER_BANNER: {
            return produce(state, draftState => {
                draftState.isDocumentBannerVisible = false;
            });
        }
        case DISPLAY_DOCUMENT_HEADER_BANNER: {
            return produce(state, draftState => {
                draftState.isDocumentBannerVisible = true;
            });
        }
    }

    return state;
}
