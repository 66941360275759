import React, { Component } from 'react';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo } from '../../../../../utils/agreementUtils';
import { formatCurrencyWithSeparator, formatCurrency } from '../../../../../utils/formUtils';

class AgreementSalesMarketing extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {leaseAllInfo.marketingAndAdvertising && (
                    <AgreementSection
                        title="Marketing & advertising"
                        step={getSalesStepNo(leaseAllInfo.location, 'marketingAndAdvertising')}
                        leaseAllInfo={this.props.leaseAllInfo}
                    >
                        <div className="agreement-section-text">
                            <div>
                                {leaseAllInfo.marketingAndAdvertising.marketingEstimate && (
                                    <p className="name">
                                        Marketing expense:{' '}
                                        <strong>
                                            {formatCurrencyWithSeparator(
                                                leaseAllInfo.marketingAndAdvertising.totalCost
                                            )}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            {leaseAllInfo.marketingAndAdvertising.isMarketingPlan &&
                                leaseAllInfo.marketingAndAdvertising.marketingPlans.map((plan, index) => {
                                    return (
                                        <p key={index}>
                                            {plan.description}:{' '}
                                            {formatCurrencyWithSeparator(formatCurrency(plan.cost, true))}
                                        </p>
                                    );
                                })}
                            <br />
                            {(leaseAllInfo.marketingAndAdvertising.isAgencyDatabase ||
                                leaseAllInfo.marketingAndAdvertising.isListWebsites ||
                                leaseAllInfo.marketingAndAdvertising.isSignboard ||
                                leaseAllInfo.marketingAndAdvertising.isOther ||
                                leaseAllInfo.marketingAndAdvertising.specialInstructions) && (
                                <b>Advertising and promotion</b>
                            )}
                            <div>
                                {leaseAllInfo.marketingAndAdvertising.isAgencyDatabase && <p>Agency Database.</p>}
                            </div>
                            <div>
                                {leaseAllInfo.marketingAndAdvertising.isListWebsites && (
                                    <p>
                                        Property to be listed on websites:{' '}
                                        {leaseAllInfo.marketingAndAdvertising.websiteList}
                                    </p>
                                )}
                            </div>
                            <div>{leaseAllInfo.marketingAndAdvertising.isSignboard && <p>Signboard included.</p>}</div>
                            <div>
                                {leaseAllInfo.marketingAndAdvertising.isOther && (
                                    <p>{leaseAllInfo.marketingAndAdvertising.otherText}</p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.marketingAndAdvertising.specialInstructions && (
                                    <p>{leaseAllInfo.marketingAndAdvertising.specialInstructions}</p>
                                )}
                            </div>
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementSalesMarketing;
