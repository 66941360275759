import React, { memo, useImperativeHandle, useState, forwardRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getDirtyStep, getLeaseType, getStep } from '../../../../selectors/lease';
import * as Lease from '../../../../reducers/lease';
import axios from 'axios';
import { has } from 'lodash';
import FormCheckboxGroup from '../../../../components/form/FormCheckboxGroup';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { getInsurance } from '../../../../selectors/lease/pmLease';
const INSURANCE_FORM = 'insuranceForm';
const initState = {};

const Insurance = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const leaseType = useSelector(getLeaseType);
    const reduxInsurance = useSelector(getInsurance);

    let [formState, setFormState] = useState(reduxInsurance || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(INSURANCE_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const updateInsurance = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/insurance`, data);
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateInsurance(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.insurance')) {
                    return error.response.data.errors.insurance;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="insurance">
            <Form onSubmit={submitForm} initialValues={formState}>
                {({ handleSubmit, form, values }) => {
                    return (
                        <form id={INSURANCE_FORM} onSubmit={handleSubmit} noValidate>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormCheckboxGroup
                                label="Which acknowledgements apply to this Agreement?"
                                groupName="acknowledgements"
                                checkboxes={[
                                    {
                                        name: 'isLandlordInsuranceRecommended',
                                        label: 'Informed them that Landlord Insurance is recommended'
                                    },
                                    {
                                        name: 'isLandlordInsuranceProvided',
                                        label: "Will provide information on Landlord Insurance by the Agent's preferred provider"
                                    }
                                ]}
                            />
                            <FormCheckboxGroup
                                label="What insurance policies are required for this Agreement?"
                                groupName="insurancePolicies"
                                checkboxes={[
                                    {
                                        name: 'isBuildingStrata',
                                        label: 'Building / Strata',
                                        dataTest: 'Building'
                                    },
                                    {
                                        name: 'isLandlord',
                                        label: 'Landlord',
                                        dataTest: 'landlord'
                                    },
                                    {
                                        name: 'isPublicLiability',
                                        label: 'Public Liability',
                                        dataTest: 'Public Liability'
                                    },
                                    {
                                        name: 'isBasicContents',
                                        label: 'Basic Contents',
                                        dataTest: 'Basic Contents'
                                    }
                                ]}
                            />
                            <FormRadioGroup
                                label="Who will arrange Landlord insurance?"
                                required
                                radioGroupClass="wrapped-radio-group"
                                name="landlordInsuranceArrangePerson"
                                value={values.landlordInsuranceArrangePerson}
                                data={[
                                    {
                                        label: 'Landlord',
                                        value: 'Landlord'
                                    },
                                    {
                                        label:
                                            'The Agent to arrange Landlord Insurance through their preferred provider',
                                        value: 'Agent',
                                        disabled: !values?.acknowledgements?.isLandlordInsuranceProvided
                                    }
                                ]}
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Insurance);
