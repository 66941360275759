import React, { memo } from 'react';

import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { FormTextRegular } from '../../../../components/form/FormText';
import { FormTextMultiline } from '../../../../components/form/FormText';

const MARKETING_ADVERTISING_FORM = 'marketingAndAdvertisingForm';

function MarketingAndAdvertising({ values }) {
    return (
        <>
            <p className="text">
                Advertising and promotion
                <br />
                (the manner in which the property is to be promoted):
            </p>
            <CheckboxCheck className="mobile-multi-select-enabled" name="isAgencyDatabase" label="Agency Database" />
            <CheckboxCheck
                className="mobile-multi-select-enabled"
                name="isSignboard"
                label="Authority to install signboard"
            />
            <CheckboxCheck className="mobile-multi-select-enabled" name="isListWebsites" label="List websites" />
            {values.isListWebsites && (
                <FormTextRegular name="websiteList" label="List Websites (include a comma after each website)" />
            )}
            <CheckboxCheck
                className="mobile-multi-select-enabled"
                name="isOther"
                label="Other advertising and promotion (optional)"
            />
            {values.isOther && <FormTextRegular name="otherText" label="List other advertising and promotion" />}
            <FormTextMultiline
                name="specialInstructions"
                label="Any special instruction about Marketing and Showing of the Property."
            />
        </>
    );
}

export default memo(MarketingAndAdvertising);
