import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import { Form } from 'react-final-form';
import Button from '../../common/components/Button';
import FormAddressDetailsAutocomplete from '../../components/form/FormAddressDetailsAutocomplete';
import { ArrowDropDownSharp } from '@material-ui/icons';

import { getAddressDetails } from '../../selectors/lease/addresses';

import { clearAddressDetailsStore, openAddressDetailsModal } from '../../actions/addressDetails';

import nswImage from '../../../assets/images/content/NSW.svg';
import qldImage from '../../../assets/images/content/QLD.svg';
import vicImage from '../../../assets/images/content/VIC.svg';
import actImage from '../../../assets/images/content/ACT.svg';
import saImage from '../../../assets/images/content/SA.svg';

import {
    getAgencyInfo,
    getAgencyPropertyMeIntegration,
    getAgencyPropertyTreeIntegration,
    getAgencyManagedIntegration
} from '../../selectors/agency';
import IntegrationAddressInput from '../../components/lease/IntegrationAddressInput';
import axios from 'axios';
import * as Lease from '../../reducers/lease';
import { IntegrationSearchSelect } from '../../components/dashboard/integration/IntegrationSearchSelect';
import '../../sass/documentLocationAndAddress.scss';
import '../../sass/createNewLease.scss';
import { FLK_A_KEY } from '../../constants/constants';

import styles from './LocationAndAddressSelection.module.scss';

const stateImagesMap = {
    NSW: nswImage,
    QLD: qldImage,
    VIC: vicImage,
    ACT: actImage,
    SA: saImage
};

const LocationAndAddressSelection = ({
    docType,
    location,
    setLocation,
    confirmDetails,
    closeModal,
    allowedStates,
    address,
    handleAddressChange,
    setAddressDetails,
    setIntegrationProperty,
    hideIntegration,
    hideLocation,
    isCreating,
    allowEmptyTenancy,
    defaultIsIntegrationSearch = true
}) => {
    const dispatch = useDispatch();

    const [showStates, setShowStates] = useState(null);
    const [isIntegrationSearch, setIsIntegrationSearch] = useState(defaultIsIntegrationSearch);
    const [isIntegrationPropertySelected, setIsIntegrationPropertySelected] = useState(false);
    const [isGettingProperty, setIsGettingProperty] = useState(false);
    const [createForm, setCreateForm] = useState({ isIntegrationSearch: defaultIsIntegrationSearch });

    const addressDetails = useSelector(getAddressDetails);
    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const managedIntegration = useSelector(getAgencyManagedIntegration);
    const agencyInfo = useSelector(getAgencyInfo);

    const setIntegrationSearch = value => {
        handleAddressChange(null);
        setIntegrationProperty(null);
        setCreateForm({ isIntegrationSearch: value });
        setIsIntegrationSearch(value);
        setIsIntegrationPropertySelected(false);
    };

    function getIntegrationProperty(url) {
        setIsGettingProperty(true);
        return axios
            .get(url)
            .then(result => {
                setIsIntegrationPropertySelected(true);
                setIntegrationProperty(result.data.propertyDetails);
                setIsGettingProperty(false);
            })
            .catch(error => {
                dispatch({
                    type: Lease.CREATE_LEASE_FAILURE,
                    error
                });
            });
    }

    const handlePropertySelect = property => {
        let url;
        if (property && property.integration === 'propertyMe') {
            url = `/api/integrations/property-me/document/${property.Id}`;
            return getIntegrationProperty(url);
        }
        if (property && property.integration === 'propertyTree') {
            url = `/api/integrations/property-tree/document/${property.id}`;
            return getIntegrationProperty(url);
        }
        if (property && property.integration === 'managed') {
            url = `/api/integrations/managed/document/${property.id}`;
            return getIntegrationProperty(url);
        }
    };

    function clearIntegrationPropertySelected() {
        handleAddressChange(null);
        setIsIntegrationPropertySelected(false);
    }

    return (
        <div className="document-location-address-details">
            {docType === FLK_A_KEY && (
                <section className="import-notice-container">
                    <p>
                        FLK a key is an additional feature to your current subscription, we have made it free for usage
                        for the next two months while we add further upgrades.
                    </p>
                    <p>It's the key tracking feature of your dreams and includes:</p>
                    <ul>
                        <li>Integration with your PM software</li>
                        <li>Auto SMS conversations chasing overdue keys</li>
                        <li>On-screen QR codes to include pictures of keys for tracking</li>
                        <li>Add staff to a watchlist so everyone is kept in the loop</li>
                    </ul>
                    <p>Slash the time your agency takes to manage your keys - No more spreadsheets and missing keys!</p>
                </section>
            )}

            {!hideLocation && (
                <div className="column-box select-state-group">
                    <div className={`state-image`}>
                        <img src={stateImagesMap[location]} />
                    </div>
                    <div className="select-state-container">
                        <div className="selected-location">{location}</div>
                        {Array.isArray(allowedStates) && allowedStates.length > 1 && (
                            <button
                                type="button"
                                className="button-change-state"
                                onClick={() => setShowStates(!showStates)}
                            >
                                Change state
                                <ArrowDropDownSharp className="Select-arrow" />
                            </button>
                        )}
                        {showStates && (
                            <div className={'showStates'}>
                                <div className="state-dropdown">
                                    {map(allowedStates, state => {
                                        return (
                                            <p
                                                key={state}
                                                className="state-dropdown-item"
                                                onClick={() => {
                                                    setLocation(state);
                                                    setShowStates(false);
                                                }}
                                            >
                                                {state}
                                            </p>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!hideIntegration && (
                <Form onSubmit={() => {}} initialValues={createForm}>
                    {({ handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                {(propertyMeIntegration || propertyTreeIntegration || managedIntegration) && (
                                    <IntegrationSearchSelect
                                        className={styles.integrationSearchSelect}
                                        propertyMeIntegration={propertyMeIntegration}
                                        propertyTreeIntegration={propertyTreeIntegration}
                                        managedIntegration={managedIntegration}
                                        setIntegrationSearch={setIntegrationSearch}
                                        isIntegrationSearchEnabled={isIntegrationSearch}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Form>
            )}
            <div className={`address-auto-complete-group`}>
                {!hideIntegration &&
                isIntegrationSearch &&
                (propertyMeIntegration || propertyTreeIntegration || managedIntegration) ? (
                    <IntegrationAddressInput
                        name="address"
                        placeholder="Address"
                        onChange={() => clearIntegrationPropertySelected()}
                        onSelect={property => handlePropertySelect(property)}
                        allowEmptyTenancy={allowEmptyTenancy}
                    />
                ) : (
                    <FormAddressDetailsAutocomplete
                        name="address"
                        value={address}
                        editAddressFormData={addressDetails.editAddressFormData}
                        editedNewAddress={addressDetails.selectedAddressEdited}
                        editMode={addressDetails.editMode}
                        showAddressBelow={false}
                        detailsConfirmed={addressDetails.detailsConfirmed}
                        label={'Address'}
                        onChange={address => handleAddressChange(address)}
                        setAddressDetails={(address, details) => setAddressDetails(address, details)}
                        openAddressDetailsModal={data => dispatch(openAddressDetailsModal(data))}
                        clearAddressDetailsStore={() => dispatch(clearAddressDetailsStore())}
                    />
                )}
            </div>
            <div className="button-group">
                <Button data-test="button-cancel" secondary className="btn-close" height={40} onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    primary
                    className="btn-start button-start"
                    data-test="button-start"
                    height={40}
                    type="button"
                    onClick={confirmDetails}
                    disabled={(!address && !isIntegrationPropertySelected) || isCreating}
                >
                    {(isGettingProperty || isCreating) && <span className="savestatus-spinner-await-completion" />}
                    Start
                </Button>
            </div>
        </div>
    );
};
export default LocationAndAddressSelection;
