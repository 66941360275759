import React, { forwardRef, ForwardedRef, useRef, useImperativeHandle } from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps, useField } from 'react-final-form';

import Button from '../../../../../common/components/Button.js';
import { getFormError } from '../../../../../utils/finalFormUtils';
import Icon, { Icons } from '../../../../../common/components/Icon';

import styles from './CustomLabelTextInput.module.scss';

export type CustomLabelTextInputData = {
    label: string;
    value: string;
};

type CustomLabelFieldRenderProps = FieldRenderProps<CustomLabelTextInputData, HTMLElement>;

export const clearCustomLabelTextInput = (field: CustomLabelFieldRenderProps) => {
    field.input.onChange({ label: '', value: '' });
};

type valueInputRefProps = {
    focus: () => void;
};

type CustomLabelTextInputProps = {
    name: string;
    endButton?: {
        onClick: () => void;
        icon: React.ReactNode;
        className?: string;
    };
    isRequired?: boolean;
    validateOnTouch?: boolean;
    disabled?: boolean;
    valueDisabled?: boolean;
    showClearButton?: boolean;
    showDeleteButton?: boolean;
    onDelete?: () => void;
    labelInputRef?: React.RefObject<HTMLInputElement>;
    valueInputRef?: React.RefObject<valueInputRefProps>;
    errorsRef?: React.RefObject<HTMLDivElement>;
    className?: string;
    labelClassName?: string;
    valuePlaceholder?: string;
    additionalErrors?: string[];
    hidePlaceholder?: boolean;
};

const CustomLabelTextInput = forwardRef(
    (
        {
            name,
            endButton,
            isRequired,
            validateOnTouch,
            disabled,
            valueDisabled,
            onDelete,
            labelInputRef,
            valueInputRef,
            errorsRef,
            className,
            labelClassName,
            valuePlaceholder,
            additionalErrors,
            showDeleteButton = false,
            showClearButton = true,
            hidePlaceholder = false
        }: CustomLabelTextInputProps,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        const inputRef = React.useRef<HTMLInputElement>(null);

        useImperativeHandle(valueInputRef, () => ({
            focus: () => {
                inputRef.current?.focus();
            }
        }));

        const { input: labelInput, meta: labelMeta } = useField(`${name}.label`, {
            subscription: { error: true, touched: true }
        });
        const labelError = getFormError(labelMeta, validateOnTouch);

        const { meta: valueMeta, input: valueInput } = useField(`${name}.value`, {
            subscription: { error: true, touched: true, value: true },
            parse: value => value
        });
        const valueError = getFormError(valueMeta, validateOnTouch);

        const hasAdditionalErrors = additionalErrors?.some(error => !!error);

        return (
            <div
                tabIndex={1}
                className={cx(styles.container, className, {
                    [styles.withButton]: !!endButton,
                    [styles.disabled]: disabled
                })}
                data-has-error={!!(labelError || valueError || hasAdditionalErrors)}
                ref={ref}
            >
                <Field
                    disabled={disabled}
                    aria-label={`Label for ${name}`}
                    className={cx(styles.labelInput, labelClassName, { [styles.withError]: !!labelError })}
                    name={`${name}.label`}
                    component="input"
                    placeholder="Enter field title"
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                        labelInput.onFocus(e);
                        e.target.select();
                    }}
                    ref={labelInputRef}
                    parse={value => value} // this is needed so it will submit empty fields as a string '' see https://github.com/final-form/react-final-form/issues/130
                />
                <div
                    className={cx(styles.inputContainer, {
                        [styles.withError]: !!valueError || hasAdditionalErrors,
                        [styles.disabled]: valueDisabled
                    })}
                >
                    <Field
                        disabled={disabled || valueDisabled}
                        aria-label={`Value for ${name}`}
                        required={isRequired}
                        ref={inputRef}
                        className={styles.input}
                        component="input"
                        name={`${name}.value`}
                        placeholder={!hidePlaceholder ? valuePlaceholder : ''}
                        parse={value => value} // this is needed so it will submit empty fields as a string '' see https://github.com/final-form/react-final-form/issues/130
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            valueInput.onFocus(e);
                            e.target.select();
                        }}
                    />
                    {!!valueInput.value && showClearButton && !disabled && (
                        <Button
                            onClick={() => {
                                valueInput.onChange('');
                                inputRef.current?.focus();
                            }}
                            type="button"
                            className={cx(styles.endButton, styles.clearButton)}
                            disabled={disabled}
                        >
                            <Icon className={styles.clearIcon} icon={Icons.CLOSE} />
                        </Button>
                    )}
                    {showDeleteButton && !disabled && onDelete && (
                        <Button
                            onClick={onDelete}
                            type="button"
                            className={cx(styles.endButton, styles.deleteButton)}
                            disabled={disabled}
                            dataTest={`delete-${name}`}
                        >
                            <Icon className={styles.deleteIcon} icon={Icons.TRASH} />
                        </Button>
                    )}
                </div>
                {(!!labelError || !!valueError || hasAdditionalErrors) && (
                    <div ref={errorsRef}>
                        {labelError && <div className={styles.error}>{labelError}</div>}
                        {valueError && <div className={styles.error}>{valueError}</div>}
                        {additionalErrors?.map((error, index) => (
                            <div key={index} className={styles.error}>
                                {error}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
);

export default CustomLabelTextInput;
