import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropertyMeIntegration from '../../../components/settings/account/PropertyMeIntegration';
import { getAgencyPropertyMeIntegration, isFetchingAgencyInfo, getSyncIntegrations } from '../../../selectors/agency';
import { deleteAccountIntegration, syncAccountIntegration } from '../../../actions/account';
import { getUserInfo } from '../../../actions/user';
import * as user from '../../../actions/user';

const mapStateToProps = state => ({
    agencyIntegration: getAgencyPropertyMeIntegration(state),
    isFetching: isFetchingAgencyInfo(state),
    syncIntegrations: getSyncIntegrations(state),
    agency: state.user.agency
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUserInfo,
            deleteAccountIntegration,
            syncAccountIntegration
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PropertyMeIntegration);
