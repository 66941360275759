import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { useParams } from 'react-router';
import SignupModal from './components/SignupModal';
import FlkAPdfFormPublic from './FlkAPdfFormPublic';
import { ReactComponent as FlkLogo } from '../../../../../../assets/images/logoWhite.svg';

import styles from './UploadADocOnboarding.module.scss';

const UploadADocOnboarding = () => {
    const { uploadADocId: idParam} = useParams();
    const [uploadADocId, setUploadADocId] = React.useState(idParam || undefined);

    const { boot } = useIntercom();
    const [showSignupModal, setShowSignupModal] = React.useState(false);

    React.useEffect(() => {
        boot({ customAttributes: { page: 'Upload A Doc Onboarding' } });
    }, []);

    return (
        <div id="main-layout" className={styles.container}>
            <FlkLogo className={styles.logo} />
            <FlkAPdfFormPublic uploadADocId={uploadADocId} setUploadADocId={setUploadADocId} openSignupModal={() => setShowSignupModal(true)} />
            <SignupModal isOpen={showSignupModal} close={() => setShowSignupModal(false)} docId={uploadADocId} />
        </div>
    );
};

export default UploadADocOnboarding;
