import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../../sass/dashboardContentTable.scss';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import AgentLogo from '../../../../AgentLogo';
import { openDocumentForm } from '../../../../../actions/document';
import ProgressBlock from '../../../agreementList/cells/progressBlock';

import FlowDocumentsMoreOptionBlock from '../../cells/FlowDocumentsMoreOptionBlock';
import { DOCUMENT_CREATE_A_FLK, DOCUMENT_EDIT_MODE, DOCUMENT_READ_ONLY_MODE } from '../../../../../config';
import * as dashboard from '../../../../../actions/dashboard';
import { formatDateTime, getAgencyTimezoneFromUser } from '../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../selectors/user';
import { getGeneralAppLabel } from '../../../../../utils/labelUtils';
import { useAccountProvider } from '../../../../providers/AccountProvider';

const CreateFlkSentForSigning = ({ documentList, agentSignature }) => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getUserInfo);
    const accountType = useAccountProvider();

    function openDocumentFormView(event, doc) {
        event.stopPropagation();
        if (doc.status === dashboard.LEASE_STATUS_DRAFT) {
            dispatch(openDocumentForm(DOCUMENT_CREATE_A_FLK, doc, DOCUMENT_EDIT_MODE));
        } else {
            dispatch(openDocumentForm(DOCUMENT_CREATE_A_FLK, doc, DOCUMENT_READ_ONLY_MODE));
        }
    }
    return (
        <Table className="rent-reduction-table">
            <Thead>
                <Th column="agent">{getGeneralAppLabel('agent', accountType)}</Th>
                <Th column="title">Document Title</Th>
                <Th column="to">To</Th>
                <Th column="from">From</Th>
                <Th column="sentForSigning">Sent for signing</Th>
                <Th column="status">Status</Th>
                <Th column="signs">signatures</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {documentList &&
                documentList.map((doc, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openDocumentFormView(event, doc);
                            }}
                        >
                            <Td column="agent">
                                <AgentLogo agent={doc.agent} />
                            </Td>
                            <Td column="title">
                                <b>{doc.documentTitle}</b>
                            </Td>
                            <Td column="to">
                                <b>{doc.to.clientsListText}</b>
                            </Td>
                            <Td column="from">
                                <b>{doc.from.clientsListText}</b>
                            </Td>
                            <Td column="sentForSigning">
                                {formatDateTime(doc.sentForSigning, getAgencyTimezoneFromUser(loggedInUser))}
                            </Td>
                            <Td column="status">
                                <span
                                    className={
                                        doc.completion && doc.completion.status === 'error'
                                            ? 'icon-document-error'
                                            : 'icon-document-sent'
                                    }
                                >
                                    {doc.completion && doc.completion.status === 'error' ? 'Error' : 'Sent'}
                                </span>
                            </Td>
                            <Td column="signs">
                                <ProgressBlock keys={key} signatures={doc.signatures} status={doc.status} />
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <FlowDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        leaseType={doc.docType}
                                        doc={doc}
                                        agentSignature={agentSignature}
                                    />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default CreateFlkSentForSigning;
