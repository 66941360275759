import React from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

export const NewDocumentHeader = ({ headerTitle, className }) => (
    <div className={cx('modal-head', className)}>
        <h3>{headerTitle}</h3>
    </div>
);

NewDocumentHeader.propTypes = {
    headerTitle: PropTypes.string.isRequired
};
