import React from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps, useField } from 'react-final-form';

import styles from './RadioButtonGroup.module.scss';
import Icon, { Icons } from '@app/common/components/Icon';
import { fieldConfig, getFormError } from '../util/final-form-utils';

type RadioItemData = {
    label: string;
    value: string;
    disabled?: boolean;
};

enum RadioButtonGroupStyle {
    DEFAULT,
    SEPARATED,
    SEPARATED_VERTICAL
}

type RadioItemProps = RadioItemData & FieldRenderProps<string, HTMLElement>;

function getIcon(checked: boolean, disabled: boolean) {
    if (disabled) {
        if (checked) {
            return Icons.ACTIVE_RADIO; // TODO: Add a new icon for this case, we will temporarily handle with CSS
        } else {
            return Icons.INACTIVE_RADIO;
        }
    } else if (checked) {
        return Icons.ACTIVE_RADIO;
    }
    return Icons.INACTIVE_RADIO;
}

const RadioItem: React.FC<RadioItemProps> = ({ label, input, disabled, className }) => {
    return (
        <label
            className={cx(styles.radioItem, className, {
                [styles.checked]: input.checked,
                [styles.disabled]: disabled
            })}
        >
            <Icon className={styles.indicator} icon={getIcon(!!input.checked, !!disabled)} />
            {label}
            <input className={styles.hiddenInput} type="radio" {...input} />
        </label>
    );
};

type RadioButtonGroupProps = {
    name: string;
    label?: string;
    options: RadioItemData[];
    radioGroupStyle?: RadioButtonGroupStyle;
    className?: string;
    required?: boolean;
    validateOnTouch?: boolean;
    disabled?: boolean;
};

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
    options,
    name,
    label,
    className,
    required,
    disabled,
    validateOnTouch = true,
    radioGroupStyle = RadioButtonGroupStyle.DEFAULT
}) => {
    const field = useField(name, { ...fieldConfig });
    const error = getFormError(field.meta, validateOnTouch);
    return (
        <div
            className={cx(styles.container, className, {
                [styles.withError]: !!error,
                [styles.separated]: radioGroupStyle === RadioButtonGroupStyle.SEPARATED,
                [styles.separatedVertical]: radioGroupStyle === RadioButtonGroupStyle.SEPARATED_VERTICAL
            })}
        >
            {label && <div className={styles.radioGroupLabel}>{label + (required ? '*' : '')}</div>}
            <div className={cx(styles.radioGroup)}>
                {options.map(radioItem => (
                    <Field
                        key={radioItem.value}
                        type="radio"
                        label={radioItem.label}
                        name={name}
                        value={radioItem.value}
                        component={RadioItem}
                        className={className}
                        disabled={radioItem.disabled || disabled}
                    />
                ))}
            </div>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

export { RadioButtonGroup, RadioButtonGroupStyle };
