import {
    OPEN_TERMINATE_LEASE_MODAL,
    CLOSE_TERMINATE_LEASE_MODAL,
    OPEN_TERMINATE_LEASE_LIST_MODAL,
    CLOSE_TERMINATE_LEASE_LIST_MODAL,
    UPDATE_TERMINATE_LEASE_DATA,
    OPEN_RENT_INCREASE_LEASE_LIST_MODAL,
    CLOSE_RENT_INCREASE_LEASE_LIST_MODAL
} from './types';

export function openTerminateLeaseModal(lease, user) {
    return {
        type: OPEN_TERMINATE_LEASE_MODAL,
        payload: {
            lease,
            user
        }
    };
}

export function closeTerminateLeaseModal() {
    return {
        type: CLOSE_TERMINATE_LEASE_MODAL
    };
}

export function updateTerminateLeaseData(data) {
    return {
        type: UPDATE_TERMINATE_LEASE_DATA,
        payload: {
            data
        }
    };
}

export function openTerminateLeaseListModal(data) {
    return {
        type: OPEN_TERMINATE_LEASE_LIST_MODAL,
        payload: {
            data
        }
    };
}

export function closeTerminateLeaseListModal() {
    return {
        type: CLOSE_TERMINATE_LEASE_LIST_MODAL
    };
}

export function openRentIncreaseLeaseListModal(data) {
    return {
        type: OPEN_RENT_INCREASE_LEASE_LIST_MODAL,
        payload: {
            data
        }
    };
}

export function closeRentIncreaseLeaseListModal() {
    return {
        type: CLOSE_RENT_INCREASE_LEASE_LIST_MODAL
    };
}
