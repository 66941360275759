import axios, { AxiosPromise } from 'axios';

import { CREATE_HOLIDAY_LETTING_REQUEST, CREATE_HOLIDAY_LETTING_SUCCESS, CREATE_HOLIDAY_LETTING_FAILURE } from '../../reducers/lease.js';

export function createHolidayLettingLease(data: unknown): {
    types: string[],
    promise: AxiosPromise
} {
    return {
        types: [
            CREATE_HOLIDAY_LETTING_REQUEST,
            CREATE_HOLIDAY_LETTING_SUCCESS,
            CREATE_HOLIDAY_LETTING_FAILURE
        ],
        promise: axios.post('/api/agency/lease', data)
    };
}