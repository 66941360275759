import React from 'react';
import { Table, Th, Td, Thead, Tr } from 'reactable';
import LeaseDropdown from '../../../dropdowns/LeaseDropdown';
import AgentLogo from '../../../AgentLogo';
import renewalsIcon from '../../../../../assets/images/icons/renewals-icon.svg';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';
import { getUserInfo } from '../../../../selectors/user';
import { useSelector } from 'react-redux';
import { getLabel, getSubLeaseTypeLabel } from '../../../../utils/labelUtils';
import { getTenantNames, hasSaExtensions, isLeaseTypeResidentialTenancy } from '../../../../utils/agreementUtils';
import { isAccountEnabled } from '../../../../utils/userUtils';

const TableArchived = ({ leaseStatus, agreementList, showModalInfoAgreement, leaseType }) => {
    const loggedInUser = useSelector(getUserInfo);
    const haveSaExtensions = hasSaExtensions(agreementList);
    const tableHead = () => {
        return (
            <Thead>
                <Th column="agentLogo"> </Th>
                <Th column="isRenewal"> </Th>
                <Th column="address">address</Th>
                {haveSaExtensions && <Th column="subLeaseType">lease type</Th>}
                <Th column="tenants">{getLabel('signorsHeader', null, leaseType)}</Th>
                <Th column="startDate">lease start date</Th>
                <Th column="term">term</Th>
                <Th column="finishDate">finish date</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
        );
    };

    return (
        <Table>
            {tableHead()}
            {agreementList &&
                agreementList.map((lease, key) => {
                    const loadingClassName = lease.isLoading ? 'loading' : '';
                    return (
                        <Tr
                            key={key}
                            className={`showmodal ${loadingClassName}`}
                            onClick={() => {
                                showModalInfoAgreement(lease);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={lease.agent} />
                            </Td>
                            {lease.isRenewal && (
                                <Td column="isRenewal">
                                    <img src={renewalsIcon} />
                                </Td>
                            )}
                            <Td column="address">
                                <b>{lease.address}</b>
                            </Td>
                            {haveSaExtensions && isLeaseTypeResidentialTenancy(lease.leaseType) && (
                                <Td column="subLeaseType">{getSubLeaseTypeLabel(lease.subLeaseType)}</Td>
                            )}
                            <Td column="tenants">{getTenantNames(lease)}</Td>
                            <Td column="startDate">
                                {lease.term && lease.term.startDate
                                    ? formatDateStandard(lease.term.startDate, getAgencyTimezoneFromUser(loggedInUser))
                                    : '-'}
                            </Td>
                            <Td column="term">
                                {`${
                                    lease.term
                                        ? `${lease.term.qty} ${lease.term.period}${
                                              lease.term.isPeriodic ? ', Periodic' : ''
                                          }`
                                        : '-'
                                }`}
                            </Td>
                            <Td column="finishDate">
                                {lease.term && !lease.term.periodic && lease.term.endDate
                                    ? formatDateStandard(lease.term.endDate, getAgencyTimezoneFromUser(loggedInUser))
                                    : '-'}
                            </Td>
                            {isAccountEnabled(loggedInUser) && (
                                <Td column="action">
                                    <div className="action">
                                        <LeaseDropdown lease={lease} leaseStatus={leaseStatus}>
                                            <div className="more-action" />
                                        </LeaseDropdown>
                                        <span className="arrow-right" />
                                    </div>
                                </Td>
                            )}
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default TableArchived;
