import React from 'react';
import cx from 'classnames';
import { useFieldArray } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';

import { ShouldShowError, defaultShouldShowErrorFunction } from '../util/final-form-utils';
import Icon, { Icons } from '@app/common/components/Icon';
import { Tag, TagSize, TagColor, TagBorderRadius, TagBorder } from '@app/common/components/tag/Tag';

import styles from './TagInput.module.scss';

type InnerTextInputProps = {
    addTag: (item: string) => void;
    removeLastTag: () => void;
    placeholder?: string;
    addTagOnBlur?: boolean;
    disabled?: boolean;
    validateTag?: (item: string) => boolean;
    setIsInputActive: (value: boolean) => void;
    updateTag: (index: number, value: string) => void;
    tagListLength: number | undefined;
    setIsInputTouched: (value: boolean) => void;
};

const InnerTextInput = React.forwardRef<HTMLInputElement, InnerTextInputProps>(
    (
        {
            addTag,
            removeLastTag,
            placeholder,
            addTagOnBlur,
            disabled,
            validateTag,
            setIsInputActive,
            updateTag,
            tagListLength,
            setIsInputTouched
        },
        ref
    ) => {
        const [value, setValue] = React.useState<string>('');

        const isValid = (value: string) => validateTag?.(value) ?? true;

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            // We are adding a new item
            if (value === '') {
                addTag(newValue);
                setIsInputActive(true);
            } else if (tagListLength !== undefined && tagListLength > 0) {
                // We are clearing the input
                if (newValue === '') {
                    updateTag(tagListLength - 1, newValue);
                    removeLastTag();
                    setIsInputActive(false);
                    setIsInputTouched(false);
                } else {
                    updateTag(tagListLength - 1, newValue);
                }
            }

            setValue(newValue);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                if (value && isValid(value)) {
                    setValue('');
                    setIsInputActive(false);
                    setIsInputTouched(false);
                }
            }
            if (e.key === 'Backspace' && !value) {
                removeLastTag();
            }
        };

        const handleBlur = () => {
            setIsInputTouched(true);
            if (addTagOnBlur && value && isValid(value)) {
                setValue('');
                setIsInputActive(false);
                setIsInputTouched(false);
            }
        };

        return (
            <input
                ref={ref}
                value={value}
                className={styles.input}
                disabled={disabled}
                placeholder={placeholder}
                autoComplete="off"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
            />
        );
    }
);

export interface TagInputProps
    extends Pick<InnerTextInputProps, 'placeholder' | 'addTagOnBlur' | 'disabled' | 'validateTag'> {
    name: string;
    label: string;
    required?: boolean;
    shouldShowError?: ShouldShowError;
}

export const TagInput: React.FC<TagInputProps> = ({
    name,
    label,
    required,
    disabled,
    placeholder,
    addTagOnBlur,
    shouldShowError = defaultShouldShowErrorFunction,
    validateTag,
    children
}) => {
    // Active means that there is some value in the input and it is not a tag yet.
    const [isInnerInputActive, setIsInnerInputActive] = React.useState(false);
    const [isInnerInputTouched, setIsInnerInputTouched] = React.useState(false);
    // Final form does not track touched state for array fields, so we need to track it ourselves.
    const [isTagListTouched, setIsTagListTouched] = React.useState(false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const form = useForm();
    const tagList = useFieldArray(name);

    const tagListLength = tagList.fields.length ?? 0;
    const inputName = `${name}.${tagListLength - 1}`;
    const { errors, submitErrors } = form.getState();
    const { meta: tagListMeta } = tagList;
    const isTagListDirtySinceLastSubmit = form.getState().dirtyFieldsSinceLastSubmit[name];

    const inputError =
        isInnerInputActive && tagListLength ? errors?.[inputName] || submitErrors?.[inputName] : undefined;
    const showInputError = !!inputError && isInnerInputTouched;

    const tagListError = tagListMeta.error || tagListMeta.submitError;
    const showTagListError =
        !!tagListError &&
        (typeof shouldShowError === 'function'
            ? shouldShowError({
                  ...tagListMeta,
                  touched: isTagListTouched,
                  dirtySinceLastSubmit: isTagListDirtySinceLastSubmit
              })
            : shouldShowError);

    const showError = showInputError || showTagListError;

    return (
        <div
            className={cx(styles.container, {
                [styles.disabled]: disabled,
                [styles.withError]: showError
            })}
            onBlur={() => {
                setIsTagListTouched(true);
            }}
        >
            <label htmlFor={name} className={styles.label}>
                {label}
                {required ? '*' : undefined}
            </label>
            { children }
            <div className={styles.innerContainer}>
                {tagList.fields.map((name, index) => {
                    if (isInnerInputActive && tagListLength && index === tagListLength - 1) {
                        return null;
                    }

                    const value = tagList.fields.value[index];
                    return (
                        <Tag
                            key={name}
                            text={value}
                            endIcon={<Icon icon={Icons.CLOSE} />}
                            onClickEndIcon={() => {
                                inputRef.current?.focus();
                                tagList.fields.remove(index);
                            }}
                            size={TagSize.X_Small}
                            color={showError ? TagColor.InputError : TagColor.White}
                            borderRadius={TagBorderRadius.Square}
                            border={TagBorder.None}
                            disabled={disabled}
                        />
                    );
                })}
                <InnerTextInput
                    placeholder={tagListLength === 0 ? placeholder : undefined}
                    addTag={item => tagList.fields.push(item)}
                    removeLastTag={() => tagList.fields.pop()}
                    ref={inputRef}
                    addTagOnBlur={addTagOnBlur}
                    disabled={disabled}
                    validateTag={validateTag}
                    setIsInputActive={setIsInnerInputActive}
                    tagListLength={tagListLength}
                    updateTag={(index, value) => tagList.fields.update(index, value)}
                    setIsInputTouched={setIsInnerInputTouched}
                />
            </div>
            {showInputError ? (
                <div className={styles.error}>{inputError}</div>
            ) : showTagListError ? (
                <div className={styles.error}>{tagListError}</div>
            ) : undefined}
        </div>
    );
};

export default TagInput;
