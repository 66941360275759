import React from 'react';
import { useIntercom } from 'react-use-intercom';
import appHistory from './../../AppHistory';
import Button from '../../common/components/Button';
import { SignUpLayout } from './components';
import styles from './SignUpLanding.module.scss';

import { SIGN_UP_ACCOUNT_LANDING_PAGE_TITLE } from '../../config';

const SignUp = () => {
    const { boot, show } = useIntercom();

    // Initialise intercom
    React.useEffect(() => {
        boot();
    }, [boot]);

    function goToRealEstateSignup() {
        appHistory.push('/signup');
    }

    function goToGeneralSignUp() {
        appHistory.push('/signup/business');
    }

    return (
        <SignUpLayout
            title={SIGN_UP_ACCOUNT_LANDING_PAGE_TITLE}
            onContactUs={() => show()}
            isLandingPageLayout
            hideDisclaimer
            layoutBodyClassName={styles.landingContainer}
        >
            <h2 className={styles.landingTitle}>Choose your industry</h2>
            <div className={styles.buttonContainer}>
                <Button className={styles.button} tertiary dark onClick={goToRealEstateSignup}>
                    Real estate
                </Button>
                <Button className={styles.button} tertiary dark onClick={goToGeneralSignUp}>
                    Business
                </Button>
            </div>
        </SignUpLayout>
    );
};

export default SignUp;
