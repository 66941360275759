import * as Lease from '../../../reducers/lease';
import * as LeaseWithRequest from '../../../reducers/leaseWithRequest';
import { initState, salesInitState } from './state';
export const UPDATE_MATERIAL_FACTS_FORM = 'UPDATE_MATERIAL_FACTS_FORM';
import { booleanToString, isLeaseTypeSales } from '../../../utils/agreementUtils';
import produce from 'immer';

export default produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.CREATE_LEASE_SUCCESS: {
            draftState = { ...initState };
            break;
        }
        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            draftState = { ...salesInitState };
            break;
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            const materialFacts = booleanToString(action.payload.agreementInfo.materialFacts, [
                'enabled',
                'askClientToDisclose'
            ]);
            let initialState = isLeaseTypeSales(action.payload.agreementInfo.leaseType) ? salesInitState : initState;
            draftState = { ...initialState, ...materialFacts };
            break;
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            const materialFacts = booleanToString(action.lease.materialFacts, ['enabled', 'askClientToDisclose']);
            let initialState = isLeaseTypeSales(action.lease.leaseType) ? salesInitState : initState;
            draftState = { ...initialState, ...materialFacts };
            break;
        }
        case Lease.LEASE_MATERIAL_FACTS_SUCCESS: {
            const materialFacts = booleanToString(action.result.data.lease.materialFacts, [
                'enabled',
                'askClientToDisclose'
            ]);
            draftState = materialFacts;
            break;
        }
        default: {
            return draftState;
        }
    }
    return draftState;
});
