import React, { Component } from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import ModalViewSpecialConditions from '../../../../../components/modals/viewSpecialConditions/ModalViewSpecialCondition';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';

export default class AgreementResidentialSpecialConditions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenModalViewSpecialCondition: false
        };

        this.showSpecialConditionsModal = this.showSpecialConditionsModal.bind(this);
        this.closeSpecialConditionsModal = this.closeSpecialConditionsModal.bind(this);
    }

    showSpecialConditionsModal() {
        this.setState({
            ...this.state,
            isOpenModalViewSpecialCondition: true
        });
    }

    closeSpecialConditionsModal() {
        this.setState({
            ...this.state,
            isOpenModalViewSpecialCondition: false
        });
    }

    render() {
        const { leaseAllInfo } = this.props;
        const { isOpenModalViewSpecialCondition } = this.state;

        return (
            <AgreementSection
                title="Special terms & conditions"
                step={getResTenStepNo(leaseAllInfo.location, 'specialConditions', leaseAllInfo.subLeaseType)}
                leaseAllInfo={leaseAllInfo}
            >
                <span className="special-link" onClick={this.showSpecialConditionsModal}>
                    View special terms & conditions
                </span>
                <ModalViewSpecialConditions
                    isOpen={isOpenModalViewSpecialCondition}
                    closeModal={this.closeSpecialConditionsModal}
                    specialConditions={leaseAllInfo.specialConditions}
                />
            </AgreementSection>
        );
    }
}
