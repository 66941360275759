import { createSelector } from 'reselect';
export const getJoin = state => state.join;

export const getCheckTokenStatus = createSelector([getJoin], join => join.checkTokenStatus);
export const getCheckTokenStatusRequesting = createSelector([getJoin], join => join.checkTokenStatusRequesting);
export const getCheckTokenError = createSelector([getJoin], join => join.checkTokenError);
export const getAcceptUserStatus = createSelector([getJoin], join => join.acceptUserStatus);
export const getAcceptUserinfo = createSelector([getJoin], join => join.acceptUserinfo);
export const getAgency = createSelector([getJoin], join => join.agency);
export const getUser = createSelector([getJoin], join => join.user);
export const getInviting = createSelector([getJoin], join => join.inviting);
export const getInvite = createSelector([getJoin], join => join.invite);
