import React, { useState } from 'react';
import { getSalesStepNo, getSalesStepTitle, isLeaseLocationNSW } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import ModalViewSalesAdditionalTerms from '../../../../modals/sales/ModalViewSalesAdditionalTerms';

export default function AgreementSalesAdditionalTerms({ leaseAllInfo }) {
    const partName = 'specialConditions';
    const { location, specialConditions } = leaseAllInfo;
    const [isViewSalesAdditionTermsOpen, setIsViewSalesAdditionTermsOpen] = useState(false);

    const showViewAdditionTermsModal = () => {
        setIsViewSalesAdditionTermsOpen(true);
    };

    const closeViewAdditionTermsModal = () => {
        setIsViewSalesAdditionTermsOpen(false);
    };
    let extraTerms = <></>;
    if (isLeaseLocationNSW(location)) {
        extraTerms = (
            <div className="agreement-section-text__cooling">
                <p className="text">
                    <strong>Cooling off period waived:</strong>
                    {specialConditions?.isCoolingOffPeriodWaived ? ' Yes' : ' No'}
                </p>
            </div>
        );
    }
    const title = getSalesStepTitle(location, partName);
    return (
        <div>
            <AgreementSection title={title} step={getSalesStepNo(location, partName)} leaseAllInfo={leaseAllInfo}>
                <span className="special-link" onClick={showViewAdditionTermsModal}>
                    View additional terms
                </span>
                <ModalViewSalesAdditionalTerms
                    title={title}
                    isOpen={isViewSalesAdditionTermsOpen}
                    closeModal={closeViewAdditionTermsModal}
                    leaseAllInfo={leaseAllInfo}
                    extraTerms={extraTerms}
                    noItemsMessage="No additional terms."
                />
            </AgreementSection>
        </div>
    );
}
