import React, { useEffect, useState } from 'react';
import CacheIcon from '@material-ui/icons/CachedSharp';
import { CloudDoneSharp } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getIsSavingStep, getLeaseType, getLeaseAllInfo } from '../../../selectors/lease';
import { LEASE_STATUS_DRAFT } from '../../../actions/dashboard';

export function SavingIndicator() {
    const isSaving = useSelector(getIsSavingStep);
    const leaseType = useSelector(getLeaseType);
    const leaseAllInfo = useSelector(getLeaseAllInfo);

    let [displaySavedText, setDisplaySavedText] = useState(false);
    useEffect(() => {}, [leaseType]);

    useEffect(() => {
        if (!isSaving) {
            setTimeout(() => {
                setDisplaySavedText(false);
            }, 2000);
        }
        if (isSaving) {
            setDisplaySavedText(true);
        }
    }, [isSaving]);

    function getSavingStatus(saving) {
        if (leaseAllInfo.status === LEASE_STATUS_DRAFT) {
            return saving ? (
                <div className="header-saving-indicator">
                    <CacheIcon />
                    <div className="text">Saving...</div>
                </div>
            ) : (
                <div className="header-saving-indicator">
                    <CloudDoneSharp />
                    {displaySavedText && <div className="text">Saved</div>}
                </div>
            );
        }
        return null;
    }

    return getSavingStatus(isSaving);
}

export default SavingIndicator;
