import React, { ReactNode } from 'react';
import styles from './HeaderBanner.module.scss';
import Icon, { Icons } from '../../common/components/Icon';

type HeaderBannerProps = {
    closeAction?: () => void;
    children: ReactNode;
};

const HeaderBanner: React.FC<HeaderBannerProps> = ({ closeAction, children }) => {
    function closeBanner(): void {
        if (closeAction) {
            closeAction();
        }
    }

    return (
        <div className={styles.banner}>
            <div className={styles.children}>{children}</div>
            {closeAction && (
                <div className={styles.close} onClick={() => closeBanner()}>
                    {<Icon className={styles.closeIcon} icon={Icons.CLOSE} />}
                </div>
            )}
        </div>
    );
};

export default HeaderBanner;
