import { createSelector } from 'reselect';
export const getSignatures = state => state.signatures;

export const getShouldLogOut = createSelector([getSignatures], signatures => signatures.shouldLogOut);
export const getIsTokenValid = createSelector([getSignatures], signatures => signatures.isTokenValid);
export const getIsCheckingToken = createSelector([getSignatures], signatures => signatures.isCheckingToken);
export const getSignatureAddSuccess = createSelector([getSignatures], signatures => signatures.signatureAddSuccess);
export const getIsSendingRequest = createSelector([getSignatures], signatures => signatures.isSendingRequest);
export const getAgentSignature = createSelector([getSignatures], signatures => signatures.agentSignature);
export const getIsNoSignatureModalOpen = createSelector(
    [getSignatures],
    signatures => signatures.isNoSignatureModalOpen
);
