import { InfoSharp } from '@material-ui/icons';
import cx from 'classnames';
import parse from 'html-react-parser';
import React from 'react';
import { Field } from 'react-final-form';
import ReactTooltip from 'react-tooltip';
import '../../sass/form-chekbox.scss';
import { getError } from '../../utils/formUtils';

const ToggleCheckboxInput = ({
    children,
    className,
    dataTest,
    disabled,
    input,
    inputClassName,
    label,
    labelClassName,
    meta,
    onClick,
    text,
    toolTip,
    topAlignInput,
    validateOnTouch
}) => {
    return (
        <div className="form-switch">
            <label className="choice">{label}</label>
            <div className="FormInput FormInputCheck">
                <div
                    data-test={dataTest}
                    className={cx('checkbox-container', className, {
                        active: input.checked,
                        disabled: disabled,
                        'checkbox-container--top-aligned-input': topAlignInput
                    })}
                    onClick={() => {
                        if (!disabled) {
                            input.onChange(!input.checked);
                            if (onClick) {
                                onClick(!input.checked);
                            }
                        }
                    }}
                >
                    <div
                        className={cx('input-checkbox', inputClassName, {
                            active: input.checked,
                            disabled: disabled,
                            'input-checkbox--top-aligned': topAlignInput
                        })}
                    />
                    <div className={cx('checkbox-label', labelClassName, { disabled: disabled })}>
                        {text ? text : children}
                    </div>
                    {toolTip && (
                        <InfoSharp className="tooltip-info-icon text-gray" data-tip={true} data-for={input.name} />
                    )}
                    <ReactTooltip id={input.name} class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                        <p>{parse(`${toolTip}`)} </p>
                    </ReactTooltip>
                </div>
                {getError(meta, validateOnTouch) && <p className={'FormError'}>{getError(meta, validateOnTouch)}</p>}
            </div>
        </div>
    );
};

/**
 * @deprecated
 * The ToggleButton component is deprecated and should no longer be used.
 * Please use the ToggleButtonV2 component instead.
 */

export const ToggleButton = ({
    children,
    className,
    dataTest,
    disabled,
    inputClassName,
    label,
    labelClassName,
    name,
    onClick,
    text,
    toolTip,
    topAlignInput,
    validateOnTouch = false
}) => {
    return (
        <Field
            className={className}
            component={ToggleCheckboxInput}
            dataTest={dataTest}
            disabled={disabled}
            inputClassName={inputClassName}
            label={label}
            labelClassName={labelClassName}
            name={name}
            onClick={onClick}
            text={text}
            toolTip={toolTip}
            topAlignInput={topAlignInput}
            type={'checkbox'}
            validateOnTouch={validateOnTouch}
        >
            {children}
        </Field>
    );
};
