import React, { useCallback, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { StatusIcon } from './StatusIcon';
import { Expand } from './Expand';
import { SignaturesDisplay } from './SignaturesDisplay';
import * as SignatureTypes from './propTypes';

import '../../sass/agreement-signatures-new.scss';
import { getLabel } from '../../utils/labelUtils';

const cx = classnames.bind({
    signature: 'agreement-signatures__signature',
    witness: 'agreement-signature__witness-arrow',
    awaiting: 'agreement-signature__awaiting-signature'
});

function WitnessRole() {
    return (
        <Fragment>
            <div className={cx('witness')} />
            <span>Witness</span>
        </Fragment>
    );
}

export function Signature({
    name,
    primary,
    witness,
    signed,
    less,
    className,
    onClick,
    expanded,
    signature,
    initials,
    label,
    location,
    leaseType,
    isGuarantor,
    noSignatureRequired,
    ...divProps
}) {
    const handleClick = useCallback(
        event => {
            event.preventDefault();
            if (onClick) {
                onClick(event);
            }
        },
        [onClick]
    );

    return (
        <Fragment>
            <a href="#" {...divProps} className={cx('signature', className)} onClick={handleClick}>
                <StatusIcon success={signed} />
                <div>{name}</div>
                <div>
                    {label && (witness ? <WitnessRole /> : <span>{label}</span>)}
                    {!label && !isGuarantor && (
                        <React.Fragment>
                            {primary && `Primary ${getLabel('tenantLower', location, leaseType)}`}
                            {witness && <WitnessRole />}
                            {!primary && !witness && `${getLabel('tenant', location, leaseType)}`}
                        </React.Fragment>
                    )}
                    {!label && isGuarantor && <span>Guarantor</span>}
                </div>
                <Expand less={expanded} />
            </a>
            {expanded && (
                <SignaturesDisplay
                    signature={signature}
                    initials={initials}
                    noSignatureRequired={noSignatureRequired}
                />
            )}
        </Fragment>
    );
}

Signature.propTypes = {
    name: PropTypes.string.isRequired,
    primary: PropTypes.bool,
    witness: PropTypes.bool,
    signed: PropTypes.bool,
    signature: SignatureTypes.signature,
    initials: PropTypes.arrayOf(SignatureTypes.signature),
    expanded: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isGuarantor: PropTypes.bool,
    noSignatureRequired: PropTypes.bool
};

Signature.defaultProps = {
    primary: false,
    witness: false,
    signed: false,
    expanded: false,
    className: '',
    isGuarantor: false
};
