import axios from 'axios';
import axiosRetry from 'axios-retry';
import cookie from 'react-cookie';
import { SETTING_STEP_REQUEST } from '../reducers/authorization';
import appHistory from './../AppHistory';

import { toSaveToken } from '../utils/toSaveToken';
import { setLoginRedirect, logOutSuccess } from './login';
import {
    AGENCY_ACCOUNT_TYPE,
    AUTHENTICATION_FAILED,
    GENERAL_ACCOUNT_TYPE,
    REFRESH_TOKEN_EXPIRED,
    MFA_AUTHENTICATION_FAILED,
    MFA_CODE_EXPIRE_MESSAGE,
    VERIFICATION_CODE_REQUIRED_MESSAGE
} from '../constants/constants';
import { isEmpty } from 'lodash';

let refreshing = false;
let failedRequests = [];

function _removeCookies() {
    cookie.remove('rememberMe', { path: '/' });
    cookie.remove('userFromLogin', { path: '/' });
    cookie.remove('accountType', { path: '/' });
}

function _logoutOfRollBar(error) {
    if (window.rollbar) {
        window.rollbar.error(
            'User force logged out',
            {
                error_message: error ? error.message : 'index.js force log out',
                status: 'error',
                env: ENVIRONMENT
            },
            error
        );
    }
}

const clientLogOut = (error, store) => {
    const accountType = cookie.load('accountType') ?? '';
    _removeCookies();
    store.dispatch(logOutSuccess(false));
    // Save the current url if we're not logged in
    let currentPath = `${appHistory.location.pathname}${appHistory.location.search}`;
    if (currentPath !== '/') {
        store.dispatch(setLoginRedirect(currentPath, accountType));
    }
    appHistory.push('/');
    _logoutOfRollBar(error);
    return Promise.reject({ data: error.data });
};

const replayFailedRequests = error => {
    failedRequests.forEach(promise => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve();
        }
    });

    failedRequests = [];
};

/**
 * Update access token and retry failed request after success update.
 * @returns {Promise<*>}
 */
const updateAccessToken = store => {
    return axios
        .post('/auth/refresh')
        .then(response => {
            toSaveToken(
                response.data.user,
                cookie.load('rememberMe'),
                response.data.accountType ? response.data.accountType : AGENCY_ACCOUNT_TYPE
            );
            refreshing = false;
            return Promise.resolve(response.data.accessToken);
        })
        .catch(err => {
            return clientLogOut(err, store);
        });
};

/**
 * Initialize http client. Set up request/response interceptors and base url.
 * @return {void}
 */
export function configureAxios(store) {
    axios.defaults.baseURL = SERVICE_URL;
    axios.defaults.withCredentials = true;
    // By default, it retries if it is a network error or a 5xx
    axiosRetry(axios, { retries: 3 });

    axios.interceptors.request.use(config => {
        if (config.url.includes(SERVERLESS_URL)) {
            return config;
        }

        if (config && !config.refresh) {
            config.headers = {
                accountType: cookie.load('accountType') || ''
            };
        }
        return config;
    });
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            let status = 'unknown';
            let data = {};
            const config = error.config;
            if (error.response) {
                status = error.response.status;
                data = error.response.data;
            }
            switch (status) {
                // Not authorized or accessToken expired.
                case 401: {
                    const refreshTokenExpired = !isEmpty(data.error) && data.error === REFRESH_TOKEN_EXPIRED;
                    const authenticationFailed = !isEmpty(data.error) && data.error.includes(AUTHENTICATION_FAILED);
                    const mfaAuthenticationFailed =
                        !isEmpty(data.error) &&
                        [MFA_AUTHENTICATION_FAILED, MFA_CODE_EXPIRE_MESSAGE, VERIFICATION_CODE_REQUIRED_MESSAGE].includes(data.error);

                    if (authenticationFailed || mfaAuthenticationFailed) {
                        return Promise.reject(error);
                    }
                    // Don't do anything if this action is to get the twilio access token only
                    if (config && config.url === '/api/user/twilio-access-token') {
                        return;
                    }
                    // 401 thrown when trying to refresh access token
                    if (config && config.url === '/auth/refresh') {
                        return clientLogOut(null, store);
                    }
                    if (data.error === REFRESH_TOKEN_EXPIRED) {
                        return clientLogOut('JWT expired', store);
                    }

                    // if this request does not come at the time of refreshing, and we are
                    // not trying to call API with expired refresh token
                    if (!refreshing && !refreshTokenExpired && !authenticationFailed) {
                        refreshing = true;
                        return updateAccessToken(store)
                            .then(() => {
                                config.refresh = true;
                                if (typeof config.data === 'string') {
                                    config.data = JSON.parse(config.data);
                                }
                                replayFailedRequests(null);

                                return axios.request(config);
                            })
                            .catch(err => {
                                clientLogOut(err, store);
                            })
                            .finally(() => {
                                refreshing = false;
                            });
                    } else if (refreshing) {
                        //Add the failed requests while we are refreshing to a replay list
                        return new Promise((resolve, reject) => {
                            failedRequests.push({ resolve, reject });
                        })
                            .then(() => {
                                return axios.request(config);
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                        // Save the failed requests to replay once we have an access token
                    } else if (!cookie.load('userFromLogin')) {
                        appHistory.push('/');
                        return Promise.reject({ error, data: data });
                    }
                    break;
                }
                // Registration not complete.
                case 406: {
                    if (data.accountType === GENERAL_ACCOUNT_TYPE) {
                        appHistory.push('/signup/business');
                    } else {
                        appHistory.push('/signup');
                    }
                    data.successAction = SETTING_STEP_REQUEST;
                    return Promise.resolve({ data });
                }
                // The state is in maintenance mode redirect to maintenance page
                case 503: {
                    const systemMaintenance = data.originError ? data.originError.additionalInfo : data._doc;
                    if (
                        // Means all Agreement types is disabled
                        !systemMaintenance?.affectedAgreements ||
                        isEmpty(systemMaintenance?.affectedAgreements) ||
                        systemMaintenance?.affectedAgreements?.includes('ALL')
                    ) {
                        appHistory.push({
                            pathname: '/maintenance',
                            state: systemMaintenance
                        });
                    } else {
                        return Promise.reject({ error, data: data, message: systemMaintenance });
                    }
                    break;
                }
                case 'unknown': {
                    return Promise.reject(error);
                }
                default: {
                    return Promise.reject(error);
                }
            }
        }
    );
}
