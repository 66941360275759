import React, { Component } from 'react';
import { QLD_STATE, NSW_STATE } from '../../../../../config';
import { getPmStepNo } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';

class AgreementPmOtherServices extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.pmOtherServices && (
                    <AgreementSection
                        title="Other Services"
                        step={getPmStepNo(leaseAllInfo.location, 'pmOtherServices')}
                        leaseAllInfo={this.props.leaseAllInfo}
                    >
                        <div className="agreement-section-text">
                            {leaseAllInfo.location === QLD_STATE && (
                                <p className="text">All prices are inclusive GST.</p>
                            )}
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Additional Tenant Searches (ASIC/Bankruptcy/TICA/TRA):{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.additionalTenantSearches
                                                ? leaseAllInfo.pmOtherServices.additionalTenantSearches
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.location === QLD_STATE && leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Title Search:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.titleSearch
                                                ? leaseAllInfo.pmOtherServices.titleSearch
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Inspection Of Premises/Report Fee:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.inspectionOfPremises
                                                ? leaseAllInfo.pmOtherServices.inspectionOfPremises
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Arranging Quotes:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.arrangingForQuotes
                                                ? leaseAllInfo.pmOtherServices.arrangingForQuotes
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Arranging Repairs Or Maintenance:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.arrangingRepairsOrMaintenance
                                                ? leaseAllInfo.pmOtherServices.arrangingRepairsOrMaintenance
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Negotiating Rent Increases:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.negotiatingRentIncreases
                                                ? leaseAllInfo.pmOtherServices.negotiatingRentIncreases
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Applying For A Tribunal Order:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.applyForTribunal
                                                ? leaseAllInfo.pmOtherServices.applyForTribunal
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Preparation Of Evidence For The Tribunal:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.preparationOfEvidenceForTheTribunal
                                                ? leaseAllInfo.pmOtherServices.preparationOfEvidenceForTheTribunal
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Appearing At The Tribunal:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.appearingAtTribunal
                                                ? leaseAllInfo.pmOtherServices.appearingAtTribunal
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Applying For A Warrant Of Possession:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.applyingForWarrentOfPossession
                                                ? leaseAllInfo.pmOtherServices.applyingForWarrentOfPossession
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.location === NSW_STATE && leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Sheriff's Fees:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.sheriffsFees
                                                ? leaseAllInfo.pmOtherServices.sheriffsFees
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Obtaining A Certified Order And Filling With The Local Court:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.obtainingCertifiedOrder
                                                ? leaseAllInfo.pmOtherServices.obtainingCertifiedOrder
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Calculation And Collection Of Water And Sewerage:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.waterAndSewerage
                                                ? leaseAllInfo.pmOtherServices.waterAndSewerage
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Processing Insurance Claims:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.processingInsuaranceClaims
                                                ? leaseAllInfo.pmOtherServices.processingInsuaranceClaims
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            <div>
                                {leaseAllInfo.pmOtherServices && (
                                    <p className="name">
                                        Preparing Inventory Report Where Premises Are Furnished:{' '}
                                        <strong>
                                            {leaseAllInfo.pmOtherServices.preparingInventoryReport
                                                ? leaseAllInfo.pmOtherServices.preparingInventoryReport
                                                : 'Nil'}
                                        </strong>
                                    </p>
                                )}
                            </div>
                            {leaseAllInfo.pmOtherServices.serviceList.map((service, index) => {
                                return (
                                    <div key={index}>
                                        <p className="name">
                                            {service.serviceName}: <strong>{service.cost}</strong>
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementPmOtherServices;
