import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getUserInfo } from '../../../selectors/user';
import { getSalesTerm } from '../../../selectors/lease/salesLease';

import '../../../sass/term.scss';
import { FormNumber } from '../../../components/form/FormText';
import { calculateFinishDate } from '../../../utils/agreementUtils';
import { SelectField } from '../../../components/form/FormSelect';
import { salesTermPeriods } from '../../../config';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { calculateEndDate, formatDateDayWithTh, getAgencyTimezoneFromUser } from '../../../utils/dateUtils';

const SALES_TERM_FORM = 'salesTermForm';

const Term = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const loggedInUser = useSelector(getUserInfo);
    const leaseType = useSelector(getLeaseType);
    const reduxSalesTerm = useSelector(getSalesTerm);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [salesTerm, setSalesTerm] = useState(reduxSalesTerm || {});

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(SALES_TERM_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateSalesTerm(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SALES_TERM_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.term')) {
                    return error.response.data.errors.term;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateSalesTerm = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/term`, data);
    };

    useEffect(() => {
        let data = cloneDeep(salesTerm);
        if (!data.period) {
            data.period = 'day';
        }
        if (data.startDate) {
            data.startDate = new Date(data.startDate);
        }
        if (data.endDate) {
            data.endDate = new Date(data.endDate);
        }
        setSalesTerm(data);
    }, []);

    const handleStartDateChange = (date, values) => {
        let data = cloneDeep(values);
        data.startDate = date;
        // if date is null or undefined then calculateFinishDate return 'invalied date' this will cause problems.
        // therefore check that and assign null as endDate
        data.endDate = date ? calculateFinishDate(data.period, data.startDate, data.qty) : null;
        setSalesTerm(data);
    };

    const handlePeriodChange = (period, values) => {
        let data = cloneDeep(values);
        data.endDate = calculateFinishDate(period, data.startDate, data.qty);
        setSalesTerm(data);
    };

    const handleQtyChange = (qty, values) => {
        let data = cloneDeep(values);
        // if date is null or undefined then calculateFinishDate return 'invalied date' this will cause problems.
        // therefore check that and assign null as endDate
        if (data.startDate) {
            data.endDate = calculateFinishDate(data.period, data.startDate, qty);
        } else {
            data.endDate = null;
        }
        setSalesTerm(data);
    };

    return (
        <div className="form-container term">
            <Form onSubmit={submitForm} initialValues={salesTerm}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={SALES_TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            {/*Below help-text uses absolute position to drop into the form Label area 
                            If the label needs to be added, or form changed in some way, will need to review
                            Utilised scss - src\sass\term.scss*/}
                            <p className="help-text">The exclusive term of this agreement is:</p>
                            <div className="agreement-select">
                                <div className="quantity-input">
                                    <FormNumber
                                        name="qty"
                                        addLabelSpacing
                                        onChange={e => {
                                            values.qty = parseInt(e.target.value);
                                            handleQtyChange(values.qty, values);
                                        }}
                                    />
                                </div>
                                <div>
                                    <SelectField
                                        addLabelSpacing
                                        name="period"
                                        options={salesTermPeriods}
                                        onChange={value => {
                                            values.period = value.value;
                                            handlePeriodChange(values.period, values);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="The agreement will start on"
                                    name="startDate"
                                    selected={salesTerm.startDate}
                                    onChange={date => handleStartDateChange(date, values)}
                                />
                            </div>
                            <p>
                                and finish date is{' '}
                                <strong>
                                    {salesTerm.endDate &&
                                        formatDateDayWithTh(salesTerm.endDate, getAgencyTimezoneFromUser(loggedInUser))}
                                </strong>
                            </p>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Term);
