import * as React from 'react';
import cx from 'classnames';

export function Warning({ children, className }) {
    return (
        <p className={cx("text-warning", className)}>
            <span className="icon icon-warning" /> {children}
        </p>
    );
}

export default Warning;
