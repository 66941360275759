import produce from 'immer';

import * as Lease from './lease';
import { OPEN_RESEND_FOR_SIGNING_MODAL, CLOSE_RESEND_FOR_SIGNING_MODAL } from '../actions/types';

import { RESEND_POST_SIGNING_REQUEST, RESEND_POST_SIGNING_SUCCESS, RESEND_POST_SIGNING_FAIL } from './lease';
import { CLOSE_AGREEMENT_INFO_MODAL } from './dashboard';

const initState = {
    isResendLeaseModalOpen: false,
    isResendingForSigning: false,
    isPostReSigningFail: false,
    data: {}
};

const resendLease = produce((draftState = initState, action) => {
    switch (action.type) {
        case Lease.UPDATE_VENDOR_SUCCESS:
        case Lease.UPDATE_LANDLORD_SUCCESS:
        case Lease.UPDATE_TENANT_SUCCESS:
        case OPEN_RESEND_FOR_SIGNING_MODAL: {
            draftState.isResendLeaseModalOpen = true;
            draftState.data = action.payload.data;
            break;
        }
        case RESEND_POST_SIGNING_REQUEST: {
            draftState.isResendingForSigning = true;
            break;
        }
        case CLOSE_RESEND_FOR_SIGNING_MODAL:
        case CLOSE_AGREEMENT_INFO_MODAL:
        case RESEND_POST_SIGNING_SUCCESS: {
            draftState = initState;
            break;
        }
        case RESEND_POST_SIGNING_FAIL: {
            draftState.isPostReSigningFail = true;
            draftState.isResendLeaseModalOpen = false;
            break;
        }
    }
    return draftState;
});

export default resendLease;
