import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { isEmpty, has, debounce } from 'lodash';

import AwaitingCompletionSignatures from './AwaitingCompletionSignatures';
import { FormNumber } from '../../../form/FormText';
import { FormRadioGroup } from '../../../form/FormRadioGroup';
import {
    handleCompletionFormChange,
    resetToDefault,
    getAgentSignature,
    getWitnesses
} from '../../../../actions/completion';
import {
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales,
    isLeaseTypeCommercialLease
} from '../../../../utils/agreementUtils';
import { DEBOUNCE_TIME, VIC_STATE } from '../../../../config';
import '../../../../sass/awaitingCompletion.scss';
import { getUserInfo } from '../../../../selectors/user';
import { getCompletionFormErrors } from '../../../../selectors/completion';
import { getAgency } from '../../../../selectors/settings/account';
import CompletionEmailNotifications from '../../../CompletionEmailNotifications.tsx';

import { updateAgreementLoadingState } from '../../../../actions/dashboard';

const AwaitingCompletion = ({ leaseAllInfo }) => {
    const dispatch = useDispatch();

    const loggedInUser = useSelector(getUserInfo);
    const lease = leaseAllInfo;
    const completionFormErrors = useSelector(getCompletionFormErrors);

    const agency = useSelector(getAgency);

    const renewalDays =
        agency.details?.defaultRenewalPeriod === 'custom' ? agency.details?.customDefaultRenewalDays : null;
    const emailsRef = React.useRef([]);
    const ccEmailsRef = React.useRef([]);
    const bccEmailsRef = React.useRef([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleFormChange = useCallback(
        debounce(form => {
            dispatch(handleCompletionFormChange(form.values));
        }, DEBOUNCE_TIME),
        []
    );

    const [renewalAlert, setRenewalAlert] = useState(
        agency.details?.defaultRenewalPeriod ? agency.details?.defaultRenewalPeriod : '60'
    );

    const [emailType, setEmailType] = useState([]);

    useEffect(() => {
        dispatch(resetToDefault());
        dispatch(getWitnesses());
    }, []);

    useEffect(() => {
        if (lease) {
            if (
                lease.completion &&
                lease.completion.emailNotificationList &&
                lease.completion.emailNotificationList.length > 0
            ) {
                lease.completion.emailNotificationList.forEach(emailItem => {
                    if (emailItem.recipientType === 'to') {
                        emailsRef.current.push(emailItem.email);
                    } else if (emailItem.recipientType === 'cc') {
                        if (!emailsRef.current.includes(emailItem.email)) {
                            ccEmailsRef.current.push(emailItem.email);
                        }
                    } else if (emailItem.recipientType === 'bcc') {
                        if (!emailsRef.current.includes(emailItem.email)) {
                            bccEmailsRef.current.push(emailItem.email);
                        }
                    }
                });
            } else {
                if (isLeaseTypeResidentialTenancy(lease.leaseType)) {
                    lease.tenant.tenants.forEach(tenant => {
                        if (has(tenant, 'email') && !isEmpty(tenant.email)) {
                            emailsRef.current.push(tenant.email);
                        }
                        if (lease.location === VIC_STATE && tenant.givesPermissionForElectronicNotices) {
                            if (tenant.givesPermissionForElectronicNotices !== tenant.email) {
                                if (!emailsRef.current.includes(tenant.emailForElectronicNotices)) {
                                    ccEmailsRef.current.push(tenant.emailForElectronicNotices);
                                }
                            }
                        }
                    });
                    if (!isEmpty(agency.resTenDefaultEmails)) {
                        if (agency.resTenDefaultEmails.ccEmailList.length > 0) {
                            ccEmailsRef.current = [...ccEmailsRef.current, ...agency.resTenDefaultEmails.ccEmailList];
                        }
                        if (agency.resTenDefaultEmails.bccEmailList.length > 0) {
                            bccEmailsRef.current = [
                                ...bccEmailsRef.current,
                                ...agency.resTenDefaultEmails.bccEmailList
                            ];
                        }
                    }
                } else if (isLeaseTypeSales(lease.leaseType)) {
                    lease.signatory.signatories.forEach(signatory => {
                        if (has(signatory, 'email') && !isEmpty(signatory.email)) {
                            emailsRef.current.push(signatory.email);
                        }
                    });
                } else if (isLeaseTypePm(lease.leaseType)) {
                    lease.pmLandlord.persons.forEach(pmLandlord => {
                        if (has(pmLandlord, 'email') && !isEmpty(pmLandlord.email)) {
                            emailsRef.current.push(pmLandlord.email);
                        }
                    });
                } else if (isLeaseTypeCommercialLease(lease.leaseType)) {
                    lease.lessee.persons.forEach(lessee => {
                        if (has(lessee, 'email') && !isEmpty(lessee.email)) {
                            emailsRef.current.push(lessee.email);
                        }
                    });
                    lease.guarantor.persons.forEach(guarantor => {
                        if (has(guarantor, 'email') && !isEmpty(guarantor.email)) {
                            emailsRef.current.push(guarantor.email);
                        }
                    });
                }
                if (!isEmpty(loggedInUser.email)) {
                    if (!emailsRef.current.includes(loggedInUser.email)) {
                        ccEmailsRef.current.push(loggedInUser.email);
                    }
                }
            }
        }
        if (lease.term && lease.term.periodic) {
            setRenewalAlert('none');
        }
    }, []);

    useEffect(() => {
        if (loggedInUser) {
            dispatch(getAgentSignature(loggedInUser.id));
        }
    }, [loggedInUser]);

    return (
        <div className="awaiting-completion">
            <Form
                onSubmit={() => {}}
                initialValues={{ renewalAlert, emailType, renewalDays }}
                validate={values => {
                    const errors = completionFormErrors;
                    if (!errors) {
                        return;
                    }

                    let completionErrors = errors.completion ? errors.completion : {};
                    if (values.renewalAlert === 'custom' && has(errors.completion, 'renewalAlert.days')) {
                        completionErrors.renewalDays = 'Value must be between 1 and 365 days';
                        // remove backend error and replace
                        completionErrors.renewalAlert = undefined;
                    }
                    dispatch(updateAgreementLoadingState(leaseAllInfo.id, false));
                    return completionErrors;
                }}
            >
                {({ handleSubmit, form, values }) => {
                    return (
                        <div className="row">
                            <div className="column renewal-options pm-await-to-sign-form">
                                <form onSubmit={handleSubmit}>
                                    <FormSpy onChange={debouncedHandleFormChange} />
                                    {lease.leaseType === 'Residential Tenancy' && (
                                        <React.Fragment>
                                            <FormRadioGroup
                                                label="Renewal alert"
                                                name="renewalAlert"
                                                value={values.renewalAlert}
                                                radioGroupClass="wrapped-radio-group"
                                                data={[
                                                    {
                                                        label: '30 days',
                                                        value: '30'
                                                    },
                                                    {
                                                        label: '60 days',
                                                        value: '60'
                                                    },
                                                    {
                                                        label: '90 days',
                                                        value: '90'
                                                    },
                                                    {
                                                        label: 'None',
                                                        value: 'none'
                                                    },
                                                    {
                                                        label: 'Custom',
                                                        value: 'custom'
                                                    }
                                                ]}
                                            />
                                            {values.renewalAlert === 'custom' && (
                                                <FormNumber
                                                    name="renewalDays"
                                                    label="Enter the number of days for the renewal alert"
                                                    onChange={() => {
                                                        completionFormErrors.completion = _.omit(
                                                            completionFormErrors.completion,
                                                            'renewalDays'
                                                        );
                                                    }}
                                                />
                                            )}
                                        </React.Fragment>
                                    )}

                                    <CompletionEmailNotifications
                                        form={form}
                                        values={values}
                                        emails={emailsRef.current}
                                        ccEmails={ccEmailsRef.current}
                                        bccEmails={bccEmailsRef.current}
                                    />
                                </form>
                            </div>
                            <div className="column">
                                <AwaitingCompletionSignatures completionFormErrors={completionFormErrors} />
                            </div>
                        </div>
                    );
                }}
            </Form>
        </div>
    );
};

export default memo(AwaitingCompletion);
