import React from 'react';
import { FormTextRegular } from '../form/FormText';
import { FormEmail } from '../form/FormEmail';

const RentIncreaseTenantPersonGroup = ({ index, removeTenant, rentIncreaseWithoutLease, disabled, onChangeEmail }) => {
    let header;
    if (index === 0) {
        header = <h3>Tenant {index + 1}</h3>;
    } else {
        header = (
            <span className="removePerson">
                <h3>Tenant {index + 1}</h3>
                <button type="button" onClick={disabled ? () => {} : () => removeTenant(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="tenant">
            {header}
            <div className="formBox">
                <div className="formBox-column">
                    <div className="nowrap large">
                        <FormTextRegular
                            name={`tenants[${index}].firstName`}
                            label="First Name"
                            required
                            disabled={!rentIncreaseWithoutLease || disabled}
                        />
                        <FormTextRegular
                            name={`tenants[${index}].middleName`}
                            label="Middle Name"
                            disabled={!rentIncreaseWithoutLease || disabled}
                        />
                    </div>
                    <FormTextRegular
                        name={`tenants[${index}].secondName`}
                        label="Last Name"
                        required
                        disabled={!rentIncreaseWithoutLease || disabled}
                    />
                    {rentIncreaseWithoutLease && (
                        <FormEmail
                            name={`tenants[${index}].email`}
                            label="Email"
                            onChange={value => onChangeEmail(value, index)}
                            required
                            disabled={!rentIncreaseWithoutLease || disabled}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RentIncreaseTenantPersonGroup;
