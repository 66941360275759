import { AvailableCountries } from '@app/constants/constants';
import useCountry from '@app/hooks/useCountry';
import { InfoSharp } from '@material-ui/icons';
import cx from 'classnames';
import { isEmpty, startsWith } from 'lodash';
import React, { ChangeEvent, ComponentType, FC, InputHTMLAttributes, KeyboardEvent } from 'react';
import { Field, FieldMetaState, FieldRenderProps } from 'react-final-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactTooltip from 'react-tooltip';
import Icon, { Icons } from '../../common/components/Icon';
import '../../sass/formPhone.scss';
import { getError, handleInputValueChange } from '../../utils/formUtils.js';
import { formatPhoneNumber } from '../../utils/phoneUtils';
import Suggestion from '../suggestions/Suggestion';

type FormPhoneProps = {
    className?: string;
    containerClassName?: string;
    dataTest?: string;
    disabled?: boolean;
    icon?: Icons;
    label: string;
    name: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    removeSuggestion?: (index: number) => void;
    required?: boolean;
    suggestionIcon?: Icons;
    suggestionsList?: string[];
    tooltip?: string;
    tooltipPlace?: 'top' | 'right' | 'bottom' | 'left';
    validateOnTouch?: boolean;
};

type PhoneInputProps = FormPhoneProps & {
    defaultCountry: AvailableCountries;
    disabledLabel?: string;
    input: InputHTMLAttributes<HTMLInputElement>;
    meta: FieldMetaState<unknown>;
};

type ReactPhoneNumberCountryType = {
    dialCode: string;
};

function hasDialCode(country: any): country is ReactPhoneNumberCountryType {
    return country && typeof country.dialCode === 'string';
}

const phoneInput: ComponentType<FieldRenderProps<PhoneInputProps>> = ({
    className,
    containerClassName,
    defaultCountry = AvailableCountries.AU,
    dataTest,
    disabled,
    disabledLabel,
    icon,
    input,
    label,
    meta,
    onChange,
    removeSuggestion,
    required,
    suggestionIcon,
    suggestionsList,
    tooltip,
    tooltipPlace,
    validateOnTouch
}) => {
    return (
        <div
            className={cx(
                'FormInput',
                'form-phone',
                containerClassName,
                getError(meta, validateOnTouch) ? 'FormInput--error' : undefined
            )}
        >
            <div className={'form-group regular on'}>
                <label className={getError(meta, validateOnTouch) ? `FormError ${disabledLabel}` : disabledLabel}>
                    {label}
                    {required ? '*' : null}
                </label>
                {tooltip ? (
                    <InfoSharp
                        className="tooltip-info-icon text-gray form-input-tooltip on"
                        data-tip
                        data-for={`${label}-tooltip`}
                    />
                ) : null}
                <ReactTooltip
                    id={`${label}-tooltip`}
                    class="tooltip"
                    place={tooltipPlace ? tooltipPlace : 'bottom'}
                    effect="solid"
                    globalEventOff="wheel"
                >
                    <div>
                        <div className="info-segment">{tooltip}</div>
                    </div>
                </ReactTooltip>
                {!isEmpty(suggestionsList) && removeSuggestion && suggestionIcon && (
                    <div className="with-suggestions">
                        {suggestionsList?.map((suggestion: string, index: number) => {
                            return (
                                <Suggestion
                                    className={'form-suggestions'}
                                    key={index}
                                    text={suggestion}
                                    icon={suggestionIcon}
                                    onClick={() => {
                                        handleInputValueChange(input, onChange, { target: { value: suggestion } });
                                        removeSuggestion(index);
                                    }}
                                    onClickEndIcon={e => {
                                        e.stopPropagation();
                                        removeSuggestion(index);
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
                {icon && <Icon className="form-phone-icon" icon={icon} />}
                <PhoneInput
                    country={defaultCountry === AvailableCountries.NZ ? 'nz' : 'au'}
                    masks={{ au: '... ... ...', nz: '.. ... .....' }}
                    placeholder={defaultCountry === AvailableCountries.NZ ? '+64 00 000 0000' : '+61 000 000 000'}
                    countryCodeEditable={true}
                    disableDropdown={false}
                    disabled={disabled}
                    value={input.value?.toString()}
                    copyNumbersOnly={true}
                    enableLongNumbers={true}
                    isValid={(inputNumber, country, countries) => {
                        return countries.some(country => {
                            if (hasDialCode(country)) {
                                return (
                                    startsWith(inputNumber, country.dialCode) ||
                                    startsWith(country.dialCode, inputNumber)
                                );
                            }
                        });
                    }}
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                        if (!input || !input?.onChange) return;

                        const target = e.target as HTMLInputElement;

                        // this is used to re render final form if user try to enter more 0 as first character
                        if (
                            (target.value.startsWith('+61') && target.value.length === 15) ||
                            (target.value.startsWith('+64') && target.value.length === 16)
                        ) {
                            input.onChange(`${target.value} `);
                        }
                    }}
                    onChange={(value, country, e, formattedValue) => {
                        const countryCode = 'countryCode' in country ? country.countryCode.toUpperCase() : undefined;
                        if (!input || !input?.onChange) {
                            return;
                        }

                        input.onChange(formatPhoneNumber(formattedValue, countryCode));
                        if (onChange) {
                            onChange(formatPhoneNumber(formattedValue, countryCode));
                        }
                    }}
                    inputClass={`form-phone-input ${className}`}
                    inputProps={{
                        'data-test': dataTest,
                        'data-has-error': !!getError(meta, validateOnTouch)
                    }}
                    containerClass={cx('form-phone-container', disabled ? 'form-phone-container--disabled' : '')}
                    dropdownClass="form-phone-dropdown"
                />
                {getError(meta, validateOnTouch) && (
                    <span className={'FormError'}>{getError(meta, validateOnTouch)}</span>
                )}
            </div>
        </div>
    );
};

export const FormPhone: FC<FormPhoneProps> = ({
    className,
    containerClassName,
    dataTest,
    disabled,
    icon,
    label,
    name,
    onChange,
    removeSuggestion,
    required,
    suggestionIcon,
    suggestionsList,
    tooltip,
    tooltipPlace,
    validateOnTouch
}) => {
    let disabledLabel = '';

    if (true === disabled) {
        disabledLabel = 'disabled';
    }

    /* This wraps react-final-form's <Field/> component.
     * The identity function ensures form values never get set to null,
     * but rather, empty strings.
     *
     * See https://github.com/final-form/react-final-form/issues/130
     */
    const identity = (value: unknown) => value;

    const { country } = useCountry();

    return (
        <Field
            defaultCountry={country}
            name={name}
            disabled={disabled}
            type="text"
            required={required}
            label={label}
            disabledLabel={disabledLabel}
            component={phoneInput}
            validateOnTouch={validateOnTouch}
            onChange={onChange}
            className={className}
            tooltip={tooltip}
            tooltipPlace={tooltipPlace}
            parse={identity}
            containerClassName={containerClassName}
            dataTest={dataTest}
            icon={icon}
            suggestionsList={suggestionsList}
            removeSuggestion={removeSuggestion}
            suggestionIcon={suggestionIcon}
        />
    );
};
