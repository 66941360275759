import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import axios from 'axios';
import { debounce, constant, noop, size, uniqBy, isEmpty } from 'lodash';
import { PreLoader } from '../../common/components/PreLoader';
import '../../sass/searchBar.scss';
import ReactTooltip from 'react-tooltip';
import ContactSearchResults from './ContactSearchResults';
import cx from 'classnames';

const PLACEHOLDER = 'Type to search...';
const ERROR_MESSAGE = 'Sorry, we failed to contact our search service.';
const TYPE_MORE_MESSAGE = 'Your search is too short, keep typing.';
const DEBOUNCE_WAIT = 1000;
const REQUEST_TIMEOUT = 10000;
const EMPTY = constant('');

function ContactSearch({
    onSelect,
    allowStaffSearch,
    contactType,
    disabled,
    searchBarClassName,
    placeholder,
    hideTooltip = false,
    toolTipText = 'Type to search all contacts imported'
}) {
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [query, setQuery] = useState('');
    const [message, setMessage] = useState(null);
    const placeholderText = placeholder ? placeholder : PLACEHOLDER;

    const searchInputRef = React.useRef(null);

    function focusInput() {
        if (searchInputRef) {
            searchInputRef.current.focus();
        }
    }

    function clearResults() {
        setMessage(null);
        setSearching(false);
        setSearchResults([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchSearchQuery = useCallback(
        debounce(
            query =>
                axios
                    .get(
                        `/api/integrations/contacts?query=${encodeURIComponent(query.trim())}&allowStaffSearch=${
                            allowStaffSearch ? 1 : 0
                        }${contactType ? `&contactType=${contactType}` : ''}`,
                        {
                            timeout: REQUEST_TIMEOUT
                        }
                    )
                    .then(({ data }) => {
                        if (data.success === true) {
                            return data.contacts;
                        }
                        return [];
                    })
                    .catch(() => {
                        setMessage(ERROR_MESSAGE);
                        return [];
                    })
                    .then(handleFetchSearchQueryResults),
            DEBOUNCE_WAIT
        ),
        []
    );

    function getItems() {
        return uniqBy(searchResults, '_id');
    }

    function handleInputValueChange(value) {
        const valueLength = size(value);

        // search is empty
        if (valueLength === 0) {
            clearResults();
            return;
        }

        // don't perform expensive searches
        if (valueLength < 3) {
            return setMessage(TYPE_MORE_MESSAGE);
        }

        setQuery(value);

        setMessage(null);
        setSearching(true);
        return fetchSearchQuery(value);
    }

    function handleFetchSearchQueryResults(searchResults) {
        setSearching(false);
        setSearchResults(isEmpty(searchResults) ? [] : searchResults);
    }

    return (
        <Downshift
            onInputValueChange={handleInputValueChange}
            onSelect={item => {
                if (item) {
                    onSelect(item);
                }
            }}
            itemToString={EMPTY}
            defaultHighlightedIndex={0}
        >
            {({ getInputProps, getMenuProps, getItemProps, inputValue, reset, highlightedIndex }) => (
                <div
                    className={cx('search-bar', 'search-bar--contact-search', searchBarClassName)}
                    onClick={ReactTooltip.hide()}
                >
                    {!hideTooltip && (
                        <ReactTooltip
                            id="search-bar"
                            class="tooltip"
                            place="bottom"
                            effect="solid"
                            globalEventOff="wheel"
                        >
                            <p>{toolTipText}</p>
                        </ReactTooltip>
                    )}
                    <div data-tip={true} data-for={'search-bar'} className="search-bar__input-container">
                        <input
                            className="search-bar__input-container__input"
                            {...getInputProps({
                                type: 'search',
                                placeholder: placeholderText
                            })}
                            disabled={disabled}
                            ref={searchInputRef}
                        />
                        {searching ? <PreLoader className="search-bar__input-container__preloader" /> : null}
                        {inputValue ? (
                            <button
                                title="Clear search"
                                onClick={() => {
                                    reset();
                                    clearResults();
                                    focusInput();
                                }}
                            />
                        ) : null}
                        {inputValue ? (
                            <ContactSearchResults
                                {...getMenuProps({
                                    searching,
                                    refKey: 'menuRef',
                                    items: getItems(),
                                    displayMessage: message,
                                    highlightedIndex,
                                    getItemProps,
                                    query,
                                    allowStaffSearch,
                                    contactType
                                })}
                            />
                        ) : null}
                    </div>
                </div>
            )}
        </Downshift>
    );
}

ContactSearch.propTypes = {
    onSelect: PropTypes.func,
    allowStaffSearch: PropTypes.bool
};
ContactSearch.defaultProps = {
    onSelect: noop,
    allowStaffSearch: false
};

export default ContactSearch;
