import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getAgreementTerm } from '../../../../selectors/lease/salesLease';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import '../../../../sass/management/agreementTerm.scss';

import {
    SERVICE_TYPE_AUCTION,
    SERVICE_TYPE_SALE,
    TERM_TYPE_SINGLE,
    BASIS_TYPE_OPEN_LISTING,
    BASIS_TYPE_SOLE_AGENCY,
    BASIS_TYPE_EXCLUSIVE_AGENCY,
    LABEL_AGREE,
    LABEL_DO_NOT_AGREE,
    NO_LABEL,
    YES_LABEL,
    SETTLEMENT_TYPE_CASH,
    SETTLEMENT_TYPE_OTHER
} from '../../../../config';
import { FormTextCurrency, FormTextRegular } from '../../../../components/form/FormText';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';

const AGREEMENT_TERM_FORM = 'agreementTermForm';

const AgreementTerm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxAgreementTerm = useSelector(getAgreementTerm);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [agreementTerm, setAgreementTerm] = useState(() => {
        const data = cloneDeep(reduxAgreementTerm);
        if (data.startDate) {
            data.startDate = new Date(data.startDate);
        }
        if (data.endDate) {
            data.endDate = new Date(data.endDate);
        }
        if (data.auctionDate) {
            data.auctionDate = new Date(data.auctionDate);
        }
        return data;
    });

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(AGREEMENT_TERM_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateAgreementTerm(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_AGREEMENT_TERM_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.term')) {
                    return error.response.data.errors.term;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateAgreementTerm = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/term`, data);
    };

    return (
        <div className="agreementTerm">
            <Form onSubmit={submitForm} initialValues={agreementTerm}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={AGREEMENT_TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormRadioGroup
                                label="The agent is appointed to perform the following service:"
                                name={'serviceType'}
                                radioGroupClass="wrapped-radio-group"
                                value={values.serviceType}
                                required
                                data={[
                                    {
                                        label: SERVICE_TYPE_SALE,
                                        value: SERVICE_TYPE_SALE
                                    },
                                    {
                                        label: SERVICE_TYPE_AUCTION,
                                        value: SERVICE_TYPE_AUCTION
                                    }
                                ]}
                            />
                            <FormRadioGroup
                                label="The agent has the following term:"
                                name={'termType'}
                                radioGroupClass="wrapped-radio-group"
                                value={values.termType}
                                required
                                data={[
                                    {
                                        label: TERM_TYPE_SINGLE,
                                        value: TERM_TYPE_SINGLE
                                    }
                                ]}
                            />
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Start"
                                    name="startDate"
                                    selected={values.startDate}
                                    required
                                />
                            </div>
                            <div className="calendar separate-item">
                                <DatePickerInAgencyTimeZone
                                    label="End"
                                    name="endDate"
                                    selected={values.endDate}
                                    required={values.basisType !== BASIS_TYPE_OPEN_LISTING}
                                />
                            </div>
                            <FormRadioGroup
                                label="The agent is appointed to sell the property on the following basis:"
                                name={'basisType'}
                                radioGroupClass="column-radio-group"
                                value={values.basisType}
                                required
                                data={[
                                    {
                                        label: BASIS_TYPE_OPEN_LISTING,
                                        value: BASIS_TYPE_OPEN_LISTING
                                    },
                                    {
                                        label: BASIS_TYPE_SOLE_AGENCY,
                                        value: BASIS_TYPE_SOLE_AGENCY
                                    },
                                    {
                                        label: BASIS_TYPE_EXCLUSIVE_AGENCY,
                                        value: BASIS_TYPE_EXCLUSIVE_AGENCY
                                    }
                                ]}
                            />
                            {[BASIS_TYPE_SOLE_AGENCY, BASIS_TYPE_EXCLUSIVE_AGENCY].includes(values.basisType) && (
                                <FormRadioGroup
                                    label="At the end of the sole or exclusive agency, the parties wish for the appointment to continue as an open listing:"
                                    name={'agreeToOpenListing'}
                                    radioGroupClass="wrapped-radio-group"
                                    value={values.agreeToOpenListing}
                                    required
                                    data={[
                                        {
                                            label: LABEL_AGREE,
                                            value: LABEL_AGREE
                                        },
                                        {
                                            label: LABEL_DO_NOT_AGREE,
                                            value: LABEL_DO_NOT_AGREE
                                        }
                                    ]}
                                />
                            )}
                            {values.serviceType === SERVICE_TYPE_AUCTION && (
                                <>
                                    <h3>Auction Details</h3>
                                    <br />
                                    <b>Auction date</b>
                                    <br />
                                    <CheckboxCheck name="isAuctionDateToBeConfirmed" label="To be confirmed" />
                                    <div className="calendar bottom-gap-for-calendar-open">
                                        <DatePickerInAgencyTimeZone
                                            label="Auction Date"
                                            name="auctionDate"
                                            selected={values.auctionDate}
                                            disabled={values.isAuctionDateToBeConfirmed}
                                            required
                                        />
                                    </div>
                                    <FormRadioGroup
                                        label="The agent is authorised to sell the Property by the way of public auction:"
                                        name={'publicAuction'}
                                        radioGroupClass="wrapped-radio-group"
                                        value={values.publicAuction}
                                        required
                                        data={[
                                            {
                                                label: YES_LABEL,
                                                value: YES_LABEL
                                            },
                                            {
                                                label: NO_LABEL,
                                                value: NO_LABEL
                                            }
                                        ]}
                                    />
                                    {values.publicAuction === YES_LABEL && (
                                        <>
                                            <FormTextRegular name="auctionTime" label="Auction time" required />
                                            <FormTextRegular name="auctionPlace" label="Place of Auction" required />
                                            <FormTextCurrency
                                                name="auctioneerFee"
                                                label="Auctioneer Fee"
                                                required
                                                precision="2"
                                            />
                                            <h3 className="section-title">Property Terms of Settlement</h3>
                                            <FormRadioGroup
                                                name={'termOfSettlement'}
                                                radioGroupClass="wrapped-radio-group"
                                                value={values.termOfSettlement}
                                                data={[
                                                    {
                                                        label: 'Cash Settlement 30 days from the date of the Contract.',
                                                        value: SETTLEMENT_TYPE_CASH
                                                    },
                                                    {
                                                        label: SETTLEMENT_TYPE_OTHER,
                                                        value: SETTLEMENT_TYPE_OTHER
                                                    }
                                                ]}
                                            />
                                            {values.termOfSettlement === SETTLEMENT_TYPE_OTHER && (
                                                <>
                                                    <FormTextRegular
                                                        name="termOfSettlementOtherDescription"
                                                        label="Other description"
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(AgreementTerm);
