import React, { Component } from 'react';
import { getPmStepNo, isLeaseLocationQLD } from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';

class AgreementPmMarketing extends Component {
    render() {
        const { leaseAllInfo } = this.props;
        return (
            <div>
                <AgreementSection
                    title="Advertising & Promotion"
                    step={getPmStepNo(leaseAllInfo.location, 'marketingAndAdvertising')}
                    leaseAllInfo={this.props.leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <div>
                            {leaseAllInfo.marketingAndAdvertising?.isAgencyDatabase && (
                                <p className="name">Agency Database.</p>
                            )}
                        </div>
                        <div>
                            {leaseAllInfo.marketingAndAdvertising?.isSignboard && (
                                <p className="name">Signboard included.</p>
                            )}
                        </div>
                        <div>
                            {leaseAllInfo.marketingAndAdvertising?.isListWebsites && (
                                <div>
                                    <p className="name">Property to be listed on websites.</p>
                                    <p>
                                        <b>{leaseAllInfo.marketingAndAdvertising.websiteList}</b>
                                    </p>
                                </div>
                            )}
                        </div>
                        <div>
                            {leaseAllInfo.marketingAndAdvertising?.isOther && (
                                <div>
                                    <p className="name">Other advertising and promotion</p>
                                    <p>
                                        <b>{leaseAllInfo.marketingAndAdvertising.otherText}</b>
                                    </p>
                                </div>
                            )}
                        </div>
                        <div>
                            {leaseAllInfo.marketingAndAdvertising?.specialInstructions && (
                                <div>
                                    <p className="name">
                                        Special instruction about Marketing and Showing of the Property:
                                    </p>
                                    <p>
                                        <b>{leaseAllInfo.marketingAndAdvertising.specialInstructions}</b>
                                    </p>
                                </div>
                            )}
                        </div>
                        {isLeaseLocationQLD(leaseAllInfo.location) && leaseAllInfo.marketingAndAdvertising && (
                            <div>
                                <p className="name">
                                    Authorised Advertising Amount:{' '}
                                    <strong>{leaseAllInfo.marketingAndAdvertising.authorisedAdvertisingAmount}</strong>
                                </p>
                                <p className="name">
                                    When payable: <strong>{leaseAllInfo.marketingAndAdvertising.whenPayable}</strong>
                                </p>
                            </div>
                        )}
                    </div>
                </AgreementSection>
            </div>
        );
    }
}

export default AgreementPmMarketing;
