import React, { forwardRef, useState, useImperativeHandle, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { has } from 'lodash';
import { Form, FormSpy } from 'react-final-form';

import { updateSubmitTypeSuccess, setDirtyStep, formSubmitFail } from '../../../actions/lease';
import * as Lease from '../../../reducers/lease';

import { getLocation, getInformation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { FormTextRegular } from '../../../components/form/FormText';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import {
    OUTGOING_HANDLED_AS_FIXED_PERCENTAGE,
    OUTGOING_HANDLED_AS_ESTIMATES_PROVIDED,
    OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITHOUT_INCREASES,
    OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITH_INCREASES
} from '../../../config';

const INFORMATION_FORM = 'informationForm';
const initState = {
    outgoingsPercentage: '',
    publicLiabilityInsurance: '$',
    outgoingsEstimate: '$',
    interest: ''
};

function Information(props, ref) {
    const dispatch = useDispatch();
    const reduxInformation = useSelector(getInformation);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [information, setInformation] = useState(initState);

    useEffect(() => {
        setInformation({
            ...initState,
            ...reduxInformation
        });
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(INFORMATION_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const updateInformation = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/information`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;

        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateInformation(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.information')) {
                    return error.response.data.errors.information;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    return (
        <div className="information">
            <Form onSubmit={submitForm} initialValues={information}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} id={INFORMATION_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <FormRadioGroup
                                label="How do you want to handle outgoings?"
                                name="outgoingsHandledAs"
                                radioGroupClass="wrapped-radio-group"
                                data={[
                                    {
                                        label: 'Fixed Percentage',
                                        id: `outgoingsHandledAs-${OUTGOING_HANDLED_AS_FIXED_PERCENTAGE}`,
                                        value: OUTGOING_HANDLED_AS_FIXED_PERCENTAGE,
                                        dataTest: OUTGOING_HANDLED_AS_FIXED_PERCENTAGE
                                    },
                                    {
                                        label: 'Estimates Provided',
                                        id: `outgoingsHandledAs-${OUTGOING_HANDLED_AS_ESTIMATES_PROVIDED}`,
                                        value: OUTGOING_HANDLED_AS_ESTIMATES_PROVIDED,
                                        dataTest: OUTGOING_HANDLED_AS_ESTIMATES_PROVIDED
                                    }
                                ]}
                            />
                            {values.outgoingsHandledAs === OUTGOING_HANDLED_AS_FIXED_PERCENTAGE && (
                                <>
                                    <FormRadioGroup
                                        label="How do you want to handle outgoings?"
                                        name="outgoingsHandledAsFixedPercentage"
                                        radioGroupClass="wrapped-radio-group"
                                        data={[
                                            {
                                                label:
                                                    'By way of further rent the Lessee will pay to the Lessor on demand an amount equal to the percentage stated in Item 8 of the outgoings (as defined in clause 20)',
                                                id: `outgoingsHandledAsFixedPercentage-${OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITHOUT_INCREASES}`,
                                                value: OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITHOUT_INCREASES,
                                                dataTest: OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITHOUT_INCREASES
                                            },
                                            {
                                                label:
                                                    'By way of further rent the Lessee will pay to the Lessor on demand an amount equal to the percentage stated in Item 8 of all increases in the outgoings (as defined in clause 20) over the respective amounts assessed, charged or paid for the relevant rating, taxing or insurance period current at the commencement of the term of this lease.',
                                                id: `outgoingsHandledAsFixedPercentage-${OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITH_INCREASES}`,
                                                value: OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITH_INCREASES,
                                                dataTest: OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITH_INCREASES
                                            }
                                        ]}
                                        required
                                    />
                                    <FormTextRegular
                                        name="outgoingsPercentage"
                                        label="The percentage of outgoings contributed is:"
                                        required
                                    />
                                </>
                            )}
                            {values.outgoingsHandledAs === OUTGOING_HANDLED_AS_ESTIMATES_PROVIDED && (
                                <FormTextRegular
                                    name="outgoingsEstimate"
                                    label="The estimates provided for outgoings contributed is:"
                                    required
                                />
                            )}
                            <FormTextRegular
                                name="publicLiabilityInsurance"
                                label="Public Liability Insurance required is:"
                            />
                            <FormTextRegular name="interest" label="Default Interest is ( % )" required />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(Information));
