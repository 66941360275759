import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import * as dashboard from '../../../../actions/dashboard';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../../sass/resendModal.scss';
import '../../../../sass/reactConfirmAlert.scss';
import SendForSigningContainer from './SendForSigningContainer';
import { NSW_STATE } from '../../../../config';
import { canSendRentIncreaseLetter, isLeaseLocationSA } from '../../../../utils/agreementUtils';
import { getLeaseInfo } from '../../../../selectors/lease';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentSignature } from '../../../../selectors/completion';
import { isEmpty } from 'lodash';
import { openNoSignatureModal } from '../../../../actions/user';
import { getAgency } from '../../../../selectors/user';
import { openNoLicenseeNumberModal } from '../../../../actions/confirmModals';
import { CheckboxCheck } from '../../../form/FormCheckboxCheck';

function RenderSendForSigningFormResTen({
    leaseAllInfo,
    isPostSigningFail,
    postSigningFailMessage,
    isPostReSigningFail,
    optionsNumber,
    leaseType,
    confirmRequestsResolvedAndSendForSigning,
    handleSendForSigning,
    isSubscriptionCancelled,
    isLoading
}) {
    const dispatch = useDispatch();
    const lease = useSelector(getLeaseInfo);
    const agentSignature = useSelector(getAgentSignature);
    const agency = useSelector(getAgency);

    const sendRentIncreaseLetter = canSendRentIncreaseLetter(leaseAllInfo);
    const [isSendRentIncreaseLetter, setIsSendRentIncreaseLetter] = useState(false);

    const [initialValues, setInitialValues] = useState({
        deadline: '72'
    });

    useEffect(() => {
        const initState = { deadline: '72' };
        lease?.complianceChecklistAnswers?.clauseList.forEach(clause => {
            initState[`checklist-item-${clause.defaultSettingClauseId}`] = clause?.answer;
        });
        setInitialValues(initState);
    }, [lease]);

    return (
        <div>
            <Form
                initialValues={initialValues}
                onSubmit={values => {
                    if (
                        lease?.renewalNoticeToLeaveDetails?.isRenewalToLeaveFlow &&
                        lease.status === dashboard.LEASE_STATUS_DRAFT &&
                        isEmpty(agentSignature)
                    ) {
                        dispatch(openNoSignatureModal());
                    } else {
                        if (!!leaseAllInfo.status && leaseAllInfo.status === dashboard.LEASE_STATUS_SENT_SIGNING) {
                            // Open the resend for signing tenant modal
                            confirmRequestsResolvedAndSendForSigning(values);
                        } else if (
                            isLeaseLocationSA(leaseAllInfo.location) &&
                            !(agency?.licenseDetails?.licenseNumber && agency?.licenseDetails?.corporationLicenseNumber)
                        ) {
                            dispatch(openNoLicenseeNumberModal());
                        } else {
                            handleSendForSigning(values);
                        }
                    }
                }}
            >
                {({ handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            {false && sendRentIncreaseLetter && leaseAllInfo.location === NSW_STATE && (
                                <CheckboxCheck
                                    className="footer-checkbox"
                                    value={isSendRentIncreaseLetter}
                                    name="sendRentIncreaseLetter"
                                    label="Send rent increase letter to tenant (with agent Cc’ed)"
                                    onClick={value => setIsSendRentIncreaseLetter(value)}
                                />
                            )}
                            <SendForSigningContainer
                                leaseAllInfo={leaseAllInfo}
                                leaseType={leaseType}
                                optionsNumber={optionsNumber}
                                isPostSigningFail={isPostSigningFail}
                                postSigningFailMessage={postSigningFailMessage}
                                isPostReSigningFail={isPostReSigningFail}
                                isSubscriptionCancelled={isSubscriptionCancelled}
                                isLoading={isLoading}
                                values={values}
                                handleSubmit={handleSubmit}
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default RenderSendForSigningFormResTen;
