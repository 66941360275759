import * as React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions/renewal';
import LeaseRenewalModal from '../../components/dashboard/leaseRenewal/LeaseRenewalModal';

class Renewal extends React.Component {
    render() {
        return <LeaseRenewalModal {...this.props} />;
    }
}

export default connect(
    state => ({
        agreementInfo: state.dashboard.agreementInfo,
        renewal: state.renewal.renewal,
        lease: state.renewal.lease,
        daysBeforeExpire: state.renewal.daysBeforeExpire,
        isShowLeaseRenewallStart: state.renewal.isShowLeaseRenewallStart,
        avaliableStepsNumber: state.renewal.avaliableStepsNumber,
        activeStepNumber: state.renewal.activeStepNumber,
        validationErrors: state.renewal.validationErrors,
        isFailRenewal: state.renewal.isFailRenewal,
        isOpenLeaseRenewalModal: state.renewal.isOpenLeaseRenewalModal,
        success: state.renewal.success,
        error: state.renewal.error,
        errorCode: state.renewal.errorCode,
        isStartingRenewal: state.renewal.isStartingRenewal,
        isCancellingRenewal: state.renewal.isCancellingRenewal
    }),
    actions
)(Renewal);
