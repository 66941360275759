import React, { useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as account from '../../../actions/account';
import NoticeToLeaveFlowDetailsForm from './NoticeToLeaveFlowDetailsForm';
import DefaultEmailDetailsForm from './DefaultEmailDetailsForm';
import ComplianceChecklistForm from './ComplianceChecklistForm.tsx';

import '../../../sass/details.scss';
import { isAdmin, isAgencyUserAccount, isBusinessUserAccount } from '../../../utils/userUtils';
import { useAccountProvider } from '../../../components/providers/AccountProvider';
import AgencyDefaultDetailsForm from './AgencyDefaultDetailsForm';
import IdentificationImagesForm from './IdentificationImagesForm';
import ContactListForm from './ContactListForm.tsx';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { IDENTIFICATION_IMAGES, COMPLIANCE_CHECKLIST, CONTACT_LIST_SETTINGS } from '../../../constants/featureFlags';
import { getUserInfo, getAgencyId } from '../../../selectors/user.js';

const DefaultSettings = () => {
    const dispatch = useDispatch();
    const accountType = useAccountProvider();
    const agencyId = useSelector(getAgencyId);
    const loggedInUser = useSelector(getUserInfo);
    const isIdentificationImagesActive = useFeatureFlag(IDENTIFICATION_IMAGES);
    const isComplianceChecklistActive = useFeatureFlag(COMPLIANCE_CHECKLIST);
    const isContactListSettingsActive = useFeatureFlag(CONTACT_LIST_SETTINGS);

    useEffect(() => {
        dispatch(account.getAgencyInfo(agencyId));
    }, []);

    return (
        <div className="settings-container">
            <div className="column">
                {isAgencyUserAccount(accountType) && <AgencyDefaultDetailsForm />}
                {isAgencyUserAccount(accountType) && <DefaultEmailDetailsForm />}
                {isComplianceChecklistActive && isAgencyUserAccount(accountType) && <ComplianceChecklistForm />}
            </div>
            <div className="column">
                {isAgencyUserAccount(accountType) && <NoticeToLeaveFlowDetailsForm />}
                {isIdentificationImagesActive && isAgencyUserAccount(accountType) && <IdentificationImagesForm />}
                {isContactListSettingsActive && isAdmin(loggedInUser.role) && isBusinessUserAccount(accountType) && (
                    <ContactListForm />
                )}
            </div>
        </div>
    );
};

export default memo(DefaultSettings);
