import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import DeleteButton from '../../../../common/components/DeleteButton.js';
import CloneButton from '../../../../common/components/CloneButton.js';
import EditButton from '../../../../common/components/EditButton.js';
import ViewButton from '../../../../common/components/ViewButton.js';
import TemplateVisibility from './TemplateVisibility.js';
import { getLeaseTypeForTemplateType } from '../../../../utils/agreementUtils.js';
import { cloneDeep } from 'lodash';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import { TEMPLATE_SCREEN_V2 } from '../../../../constants/featureFlags';
import { DOCUMENT_DELIVERY_TYPE_QR_CODE } from '../../../../config';
import Icon, { Icons } from '../../../../common/components/Icon';
import Tooltip from '@app/common/components/tooltips/Tooltip';

import cx from 'classnames';

const SORT_OPTION_CREATED = 'created';
const SORT_OPTION_TEMPLATE_NAME = 'templateName';
const SORT_OPTION_LOCATION = 'location';
const SORT_OPTION_DOCUMENT_TITLE = 'documentTitle';

interface Template {
    templateName: string;
    location?: string;
    documentTitle?: string;
    deliveryType: string;
    enableQrCode?: boolean;
}

interface PaginationInfo {
    page: number;
    limit: number;
    total: number;
    pages: number;
}

interface SortOptions {
    [key: string]: 'asc' | 'desc';
}

interface UadTemplateTableProps {
    templateType: string;
    isAdmin: boolean;
    templates: Template[];
    templatePagination: PaginationInfo;
    templatesSorting: { field: string; direction: 'asc' | 'desc' };
    createNewTemplate: () => void;
    deleteTemplate: (template: Template) => void;
    editTemplate: (template: Template) => void;
    viewTemplate: (template: Template) => void;
    cloneTemplate: (template: Template) => void;
    changePage: (pageNumber: number) => void;
    isDocumentTemplate: boolean;
    blockActions: boolean;
    addVisibilitySwitch: boolean;
    changeSortOptions: (sortOptions: SortOptions) => void;
}

const UadTemplateTable: React.FC<UadTemplateTableProps> = ({
    templateType,
    isAdmin,
    templates,
    templatePagination,
    templatesSorting,
    createNewTemplate,
    deleteTemplate,
    editTemplate,
    viewTemplate,
    cloneTemplate,
    changePage,
    isDocumentTemplate,
    blockActions,
    addVisibilitySwitch,
    changeSortOptions
}) => {
    const isTemplateScreenV2Active = useFeatureFlag(TEMPLATE_SCREEN_V2);
    const [sortOptions, setSortOptions] = useState<SortOptions>({});

    useEffect(() => {
        if (templatesSorting) {
            setSortOptions({ [templatesSorting.field]: templatesSorting.direction });
        }
    }, [templatesSorting]);

    const onChangeSort = (item: string) => {
        const oldSort = cloneDeep(sortOptions);
        let newSort: SortOptions = {};

        switch (oldSort[item]) {
            // if current sort option is 'asc' then next option is 'desc'
            case 'asc':
                newSort[item] = 'desc';
                break;
            // if current sort option is 'desc' then next option is no sort
            case 'desc':
                newSort = { [SORT_OPTION_CREATED]: 'desc' };
                break;
            // default means we do not have any sort option. then next sort option is 'asc'
            default:
                newSort[item] = 'asc';
                break;
        }

        setSortOptions(newSort);
        changeSortOptions(newSort);
    };

    const getClassName = (item: string) => {
        let newClassName = 'no-sort';
        if (sortOptions[item] === 'asc') {
            newClassName = 'sort-asc';
        } else if (sortOptions[item] === 'desc') {
            newClassName = 'sort-desc';
        }
        return newClassName;
    };

    return (
        <div className="wrapper flex-direction-column">
            <div className="templates-block table-sort table-default">
                <div className="button-row table-head">
                    <h2>{getLeaseTypeForTemplateType(templateType)} Templates</h2>
                    {isAdmin && !blockActions && !isTemplateScreenV2Active && (
                        <div
                            className="btn-add"
                            onClick={() => {
                                createNewTemplate();
                            }}
                        >
                            Add Template
                        </div>
                    )}
                </div>
                <Table>
                    <Thead>
                        <Th column="icon"> </Th>
                        <Th className="template-name-column" column="name">
                            <div
                                className={`table-header-sort ${getClassName(SORT_OPTION_TEMPLATE_NAME)}`}
                                onClick={() => onChangeSort(SORT_OPTION_TEMPLATE_NAME)}
                            >
                                Template name
                            </div>
                        </Th>
                        {!isDocumentTemplate && (
                            <Th column="state">
                                <div
                                    className={`table-header-sort ${getClassName(SORT_OPTION_LOCATION)}`}
                                    onClick={() => onChangeSort(SORT_OPTION_LOCATION)}
                                >
                                    State
                                </div>
                            </Th>
                        )}
                        {isDocumentTemplate && (
                            <Th column="title">
                                <div
                                    className={`table-header-sort ${getClassName(SORT_OPTION_DOCUMENT_TITLE)}`}
                                    onClick={() => onChangeSort(SORT_OPTION_DOCUMENT_TITLE)}
                                >
                                    Document Title
                                </div>
                            </Th>
                        )}
                        {((isAdmin && !blockActions) || blockActions) && (
                            <Th column="actions" className="actions">
                                <b>Actions</b>
                            </Th>
                        )}
                    </Thead>
                    {templates &&
                        templates.map((item, index) => {
                            const isQrCodeTemplate = item.deliveryType === DOCUMENT_DELIVERY_TYPE_QR_CODE;
                            const visibilitySwitchTooltip = isQrCodeTemplate
                                ? 'QR Code in use: sharing this template with other team members is restricted.'
                                : 'Enable/Disable template';
                            const qrCodeTooltipId = `qr-code-template-${index}`;
                            return (
                                <Tr key={index}>
                                    <Td column="icon">
                                        <span>
                                            {isQrCodeTemplate && (
                                                <>
                                                    <Tooltip id={qrCodeTooltipId} place="left" effect="solid" />
                                                    <Icon
                                                        data-for={qrCodeTooltipId}
                                                        data-tip={`Share QR code/link is ${item.enableQrCode ? 'active' : 'inactive'}`}
                                                        icon={Icons.QR_CODE}
                                                        className={cx({
                                                            active: item.enableQrCode,
                                                            inactive: !item.enableQrCode
                                                        })}
                                                    />
                                                </>
                                            )}
                                        </span>
                                    </Td>
                                    <Td column="name">
                                        <span className="capitalize">{`${item.templateName}`}</span>
                                    </Td>
                                    {!isDocumentTemplate && <Td column="state">{item.location}</Td>}
                                    {isDocumentTemplate && <Td column="title">{item.documentTitle}</Td>}
                                    {isAdmin && !blockActions && (
                                        <Td column="actions" className="actions">
                                            <div className="flex">
                                                <EditButton
                                                    onClick={() => {
                                                        editTemplate(item);
                                                    }}
                                                    toolTip="Edit template"
                                                    className="edit-btn"
                                                    dataTest={`${item.templateName}-edit-btn`}
                                                />
                                                <CloneButton
                                                    onClick={() => {
                                                        cloneTemplate(item);
                                                    }}
                                                    toolTip="Clone template"
                                                    className="clone-btn"
                                                    dataTest={`${item.templateName}-clone-btn`}
                                                />
                                                <DeleteButton
                                                    onClick={() => {
                                                        deleteTemplate(item);
                                                    }}
                                                    toolTip="Remove template"
                                                    className="delete-btn"
                                                    dataTest={`${item.templateName}-delete-btn`}
                                                />
                                                {addVisibilitySwitch && (
                                                    <TemplateVisibility
                                                        item={item}
                                                        dataTest={`${item.templateName}-switch-btn`}
                                                        disabled={isQrCodeTemplate}
                                                        toolTip={visibilitySwitchTooltip}
                                                    />
                                                )}
                                            </div>
                                        </Td>
                                    )}
                                    {blockActions && (
                                        <Td column="actions" className="actions">
                                            <div className="flex">
                                                <ViewButton
                                                    onClick={() => {
                                                        viewTemplate(item);
                                                    }}
                                                    toolTip="View template"
                                                    className="view-btn"
                                                />

                                                {isAdmin && addVisibilitySwitch && (
                                                    <TemplateVisibility
                                                        item={item}
                                                        dataTest="switch-btn"
                                                        disabled={isQrCodeTemplate}
                                                        toolTip={visibilitySwitchTooltip}
                                                    />
                                                )}
                                            </div>
                                        </Td>
                                    )}
                                </Tr>
                            );
                        })}
                </Table>
            </div>
            <div className="wrapper-pagination">
                <Pagination
                    hideDisabled
                    activePage={templatePagination.page ? templatePagination.page : 1}
                    itemsCountPerPage={templatePagination.limit ? templatePagination.limit : 0}
                    totalItemsCount={templatePagination.total ? templatePagination.total : 0}
                    pageRangeDisplayed={3}
                    onChange={changePage}
                    lastPageText={<div className="last-icon" />}
                    firstPageText={<div className="first-icon" />}
                    nextPageText="..."
                    prevPageText="..."
                />
                {templatePagination.limit < templatePagination.total ? (
                    <p className="pagination-text">
                        Page {templatePagination.page} of {templatePagination.pages}
                    </p>
                ) : (
                    <p />
                )}
            </div>
        </div>
    );
};

export default UadTemplateTable;
