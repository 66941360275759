import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editPermissions } from '../../../utils/userPermissions';
import { tabs } from '../../../config';

import * as contacts from './../../../actions/emergencyContacts';
import ModalEmergencyContacts from './../../../components/settings/subscription/modals/ModalEmergencyContacts';

import '../../../sass/emergencyContacts.scss';
import EditButton from '../../../common/components/EditButton';
import { getUserInfo } from '../../../selectors/user';
import {
    getEmergencyContacts,
    getValidationErrors,
    getIsOpenModalEmergencyContacts,
    getModalEmergencyContactData,
    getContactsTemplatesToUpdate
} from '../../../selectors/contacts';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmUpdateTemplates from '../../../containers/settings/UpdateTemplatesConfirmation';
import DefaultAnswers from './DefaultAnswers';
const EmergencyContacts = () => {
    const dispatch = useDispatch();

    const loggedInUser = useSelector(getUserInfo);
    const emergencyContacts = useSelector(getEmergencyContacts);
    const validationErrors = useSelector(getValidationErrors);
    const isOpenModal = useSelector(getIsOpenModalEmergencyContacts);
    const modalEmergencyContactData = useSelector(getModalEmergencyContactData);
    const templatesToUpdate = useSelector(getContactsTemplatesToUpdate);

    const clearTemplates = () => {
        dispatch(contacts.clearContactsTemplatesToUpdate());
    };

    useEffect(() => {
        if (templatesToUpdate && templatesToUpdate.templates && templatesToUpdate.templates.length > 0) {
            confirmAlert({
                customUI: ConfirmUpdateTemplates(templatesToUpdate, clearTemplates, () => {}, 'EMERGENCY CONTACTS'),
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templatesToUpdate]);

    useEffect(() => {
        dispatch(contacts.getEmergencyContactList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openModalContact = (type, values) => {
        dispatch(contacts.openEmergencyContactModal(type, values));
    };

    const closeModalContact = () => {
        dispatch(contacts.closeEmergencyContactModal());
    };

    const deleteContact = contactId => {
        dispatch(contacts.deleteContact(contactId));
    };

    let userRole = loggedInUser.role;

    return (
        <div className="settings-container">
            <div className="column">
                {!emergencyContacts.length && (
                    <div className="add-users">
                        <p>You don’t have contacts yet.</p>
                        {editPermissions(userRole, tabs.TAB_EMERGENCY_CONTACTS) && (
                            <button className="btn-add-user" onClick={() => openModalContact('Add')}>
                                Add contact
                            </button>
                        )}
                    </div>
                )}
                {!!emergencyContacts.length && (
                    <div className="contacts-block">
                        <div className="contacts-title">
                            <p>Emergency contacts</p>
                            {editPermissions(userRole, tabs.TAB_EMERGENCY_CONTACTS) && (
                                <div className="add-user">
                                    <p onClick={() => openModalContact('Add')}>Add contact</p>
                                </div>
                            )}
                        </div>
                        {emergencyContacts.map((contact, index) => {
                            return (
                                <div className="contacts-item" key={index}>
                                    <div className="contacts-item-header">
                                        <h3>
                                            {contact.tradeType}{' '}
                                            {contact.default ? <span className="default-contact">(Default)</span> : ''}
                                        </h3>
                                        {editPermissions(userRole, tabs.TAB_EMERGENCY_CONTACTS) && (
                                            <EditButton
                                                onClick={() => openModalContact('Edit', contact)}
                                                toolTip="Edit emergency contact"
                                            />
                                        )}
                                    </div>
                                    <p className="capitalize">{`${contact.firstName} ${contact.secondName} (${contact.company})`}</p>
                                    <table className="contacts-item-table">
                                        <tbody>
                                            <tr>
                                                <td>Phone:</td>
                                                <td>{contact.phone}</td>
                                            </tr>
                                            {contact.extraContacts &&
                                                contact.extraContacts.map((extraContact, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="capitalize">{extraContact.contactType}:</td>
                                                            <td>{extraContact.contactValue}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            );
                        })}
                    </div>
                )}
                <ModalEmergencyContacts
                    isOpen={isOpenModal}
                    type={modalEmergencyContactData.type}
                    closeModal={closeModalContact}
                    contact={modalEmergencyContactData.values}
                    deleteContact={deleteContact}
                    validationErrors={validationErrors}
                />
            </div>
            <div className="column">
                <DefaultAnswers />
            </div>
        </div>
    );
};

export default EmergencyContacts;
