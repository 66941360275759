import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import '../../../../../sass/infoAgreement.scss';
import { getSalesStepNo, getSalesStepTitle } from '../../../../../utils/agreementUtils';

export default function AgreementSalesSignors({ leaseAllInfo }) {
    const partName = 'landlordsSolicitor';
    const { location } = leaseAllInfo;
    return (
        <AgreementSection
            title={getSalesStepTitle(location, partName)}
            step={getSalesStepNo(location, partName)}
            leaseAllInfo={leaseAllInfo}
        >
            {leaseAllInfo.landlordsSolicitor && (
                <div className="data data-fix-width data-row">
                    {leaseAllInfo.landlordsSolicitor.doNotHaveSolicitor && <p className="name">To be advised</p>}
                    {!leaseAllInfo.landlordsSolicitor.doNotHaveSolicitor && (
                        <React.Fragment>
                            {leaseAllInfo.landlordsSolicitor.firstName && (
                                <p className="name">
                                    {leaseAllInfo.landlordsSolicitor.firstName}{' '}
                                    {leaseAllInfo.landlordsSolicitor.middleName
                                        ? `${leaseAllInfo.landlordsSolicitor.middleName} `
                                        : ''}
                                    {leaseAllInfo.landlordsSolicitor.secondName}
                                </p>
                            )}
                            {leaseAllInfo.landlordsSolicitor.companyName && (
                                <p className="name">{leaseAllInfo.landlordsSolicitor.companyName}</p>
                            )}
                            {leaseAllInfo.landlordsSolicitor.address && (
                                <p className="address">{leaseAllInfo.landlordsSolicitor.address}</p>
                            )}
                            {leaseAllInfo.landlordsSolicitor.fax && (
                                <p className="fax">Fax: {leaseAllInfo.landlordsSolicitor.fax}</p>
                            )}
                            {leaseAllInfo.landlordsSolicitor.phone && (
                                <p className="phone">Phone: {leaseAllInfo.landlordsSolicitor.phone}</p>
                            )}
                            {leaseAllInfo.landlordsSolicitor.mobile && (
                                <p className="mobile">Mobile: {leaseAllInfo.landlordsSolicitor.mobile}</p>
                            )}
                            {leaseAllInfo.landlordsSolicitor.email && (
                                <p className="email">{leaseAllInfo.landlordsSolicitor.email}</p>
                            )}
                            {leaseAllInfo.landlordsSolicitor.other && (
                                <p className="other">
                                    Document service address: {leaseAllInfo.landlordsSolicitor.other}
                                </p>
                            )}
                        </React.Fragment>
                    )}
                </div>
            )}
        </AgreementSection>
    );
}
