import React from 'react';
import { useSelector } from 'react-redux';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import '../../../../../sass/notesModal.scss';
import NotesOpenButton from '../../../../../common/components/NotesOpenButton';
import NotesTable from '../../../components/NotesTable';

const AgreementMainNotes = props => {
    const team = useSelector(state => state.agency.team);
    const lastNote = props.notes[props.notes.length - 1];
    return (
        <AgreementSection title="Notes">
            <div className="notes-section">
                <NotesTable notesList={[lastNote]} allowDelete={true} />
                <NotesOpenButton id={props.id} isDocument={props.isDocument} showText={true} />
            </div>
        </AgreementSection>
    );
};

export default AgreementMainNotes;
