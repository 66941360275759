import React, { memo } from 'react';
import '../../../sass/dashboardContentTable.scss';
import RentIncrease from './tables/rentIncrease/RentIncrease';
import RentIncreaseDraft from './tables/rentIncrease/RentIncreaseDraft';
import { DOC_STATUS_CANCELLED, LEASE_STATUS_DRAFT } from '../../../actions/dashboard';
import RentIncreaseCancelled from './tables/rentIncrease/RentIncreaseCancelled';

const RentIncreaseListings = ({ documentList, docType, agentSignature, docStatus }) => {
    switch (docStatus) {
        case LEASE_STATUS_DRAFT: {
            return (
                <div className="table-sort">
                    <RentIncreaseDraft documentList={documentList} docType={docType} agentSignature={agentSignature} />
                </div>
            );
        }
        case DOC_STATUS_CANCELLED: {
            return (
                <div className="table-sort">
                    <RentIncreaseCancelled
                        documentList={documentList}
                        docType={docType}
                        agentSignature={agentSignature}
                    />
                </div>
            );
        }
        default: {
            return (
                <div className="table-sort">
                    <RentIncrease documentList={documentList} docType={docType} agentSignature={agentSignature} />
                </div>
            );
        }
    }
};

export default memo(RentIncreaseListings);
