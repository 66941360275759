import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../sass/terminateLeaseListModal.scss';
import ModalDialog from '../../common/components/ModalDialog';

import { confirmAddressDetails } from '../../actions/addressDetails';
import { FormNumber, FormTextRegular } from '../form/FormText';
import Button from '../../common/components/Button';
import { Form, FormSpy } from 'react-final-form';
import { isAddressDetailsTouched } from '../../utils/formUtils';
import WarningIcon from '@material-ui/icons/WarningSharp';
import { getAddressDetails, getAddressDetailsOpen } from '../../selectors/lease/addresses';

const AddressDetailsModal = () => {
    const dispatch = useDispatch();
    const [formattedAddress, setFormattedAddress] = useState('');
    const [addressDetailsForm, setAddressDetailsForm] = useState();

    const addressDetails = useSelector(getAddressDetails);
    const addressDetailsOpen = useSelector(getAddressDetailsOpen);

    const changeForm = form => {
        if (isAddressDetailsTouched(form)) {
            let unitDisplay = '';
            if (form.values.subpremise) {
                unitDisplay = `${form.values.subpremise}/`;
            }
            setFormattedAddress(
                `${unitDisplay}${form.values.street_number || ''} ${form.values.route || ''}, ${form.values.locality ||
                    ''} ${form.values.administrative_area_level_1 || ''} ${form.values.postal_code || ''}`
            );
        }
        setAddressDetailsForm(form.values);
    };

    const confirmAddress = () => {
        dispatch(confirmAddressDetails(formattedAddress, addressDetailsForm));
    };

    useEffect(() => {
        if (addressDetails) {
            setFormattedAddress(addressDetails.selectedAddress);
            setAddressDetailsForm(addressDetails.geoResult);
        }
    }, [addressDetails]);

    return (
        <ModalDialog title={'Address details'} isOpen={addressDetailsOpen} hideCloseButton={true} zIndex={6}>
            <p>Lease address: {formattedAddress}</p>
            {addressDetails.noResultsFromGoogle && (
                <div className="address-details-auto-complete-details-message text-danger">
                    <WarningIcon>warning</WarningIcon>
                    <b>Google did not return any results for this address, please use the fields below</b>
                </div>
            )}
            <br />
            <Form onSubmit={() => {}} initialValues={addressDetailsForm}>
                {({ submitForm, form, values }) => {
                    return (
                        <form onSubmit={submitForm} autoComplete="off">
                            <FormSpy onChange={changeForm} />
                            <div className="address-details-auto-complete-details">
                                {addressDetails.differentFromGoogle && (
                                    <div className="address-details-auto-complete-details-message text-danger">
                                        <WarningIcon>warning</WarningIcon>
                                        <b>
                                            The address that was returned from google was slightly different to the one
                                            you selected, please confirm the details below and make any required edits
                                            prior to confirming
                                        </b>
                                    </div>
                                )}
                                <b>Please use below fields to update address if not correct:</b>
                                <FormTextRegular name="subpremise" label="Unit" />
                                <FormTextRegular name="street_number" label="Number" id="streetNumber" />
                                <FormTextRegular name="route" label="Street" />
                                <FormTextRegular name="locality" label="Suburb" />
                                <FormNumber name="postal_code" label="Post code" />
                                <FormTextRegular name="administrative_area_level_1" label="State" />
                            </div>
                        </form>
                    );
                }}
            </Form>
            <Button primary onClick={confirmAddress}>
                Confirm address details
            </Button>
        </ModalDialog>
    );
};

export default memo(AddressDetailsModal);
