import React, { Component } from 'react';
import '../../../sass/empty-state.scss';
import { isString } from 'lodash';

class EmptyState extends Component {
    getType() {
        let { type } = this.props;
        if (!type || !isString(type)) {
            return '';
        }
        type = type.replace(/_/g, ' ');

        return type.charAt(0).toUpperCase() + type.slice(1);
    }

    render() {
        return (
            <div className="wrapper-empty-state ">
                <div className="greeting">
                    <h1>No agreements found</h1>
                    <p className="greeting-text">
                        You don't have&nbsp;
                        <span className="agreement-type">{this.getType()}</span>
                        &nbsp;agreements yet. Create your first one by clicking the button in the top right.
                    </p>
                </div>
            </div>
        );
    }
}

export default EmptyState;
