import React, { Component } from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../../utils/agreementUtils';
import { STEP_EXECUTED_AS_AGREEMENT } from '../../../../../lease/steps';

class AgreementResidentialExecutedAsAgreement extends Component {
    render() {
        const { leaseAllInfo } = this.props;

        return (
            <div>
                {!!leaseAllInfo.executedAsAgreement && (
                    <AgreementSection
                        title="Executed as an Agreement"
                        step={getResTenStepNo(
                            leaseAllInfo.location,
                            STEP_EXECUTED_AS_AGREEMENT,
                            leaseAllInfo.subLeaseType
                        )}
                        leaseAllInfo={leaseAllInfo}
                    >
                        <div>
                            <p>The agent has provided the Tenant(s) the following documents:</p>
                            {leaseAllInfo.executedAsAgreement.isSection48Notice && (
                                <p className="text">
                                    Section 48(1) Notice (prior to or at the time of signing this Agreement)
                                </p>
                            )}
                            {leaseAllInfo.executedAsAgreement.copyOfAgreement && (
                                <p className="text">A copy of this Agreement</p>
                            )}
                            {leaseAllInfo.executedAsAgreement.isInformationBrochure && (
                                <p className="text">The Residential Tenancies Act 1995 (SA) Information Brochure</p>
                            )}
                            {leaseAllInfo.executedAsAgreement.isPropertyConditionReport && (
                                <p className="text">Two copies of the Property Condition Report</p>
                            )}
                            {leaseAllInfo.executedAsAgreement.isManualsAndInstructions && (
                                <p className="text">
                                    Manufacturer’s Manuals and Instructions as an Annexure if not provided for in this
                                    Agreement
                                </p>
                            )}
                            {leaseAllInfo.executedAsAgreement.isStatutoryNotice && (
                                <p className="text">
                                    Statutory Notice for Short Fixed Term Tenancy (if less than 90 days)
                                </p>
                            )}
                            {leaseAllInfo.executedAsAgreement.isStrataArticles && (
                                <p className="text">Strata Articles (if applicable)</p>
                            )}
                            {leaseAllInfo.executedAsAgreement.isCommunityTitle && (
                                <p className="text">Community Title By-Laws (if applicable)</p>
                            )}
                        </div>
                    </AgreementSection>
                )}
            </div>
        );
    }
}

export default AgreementResidentialExecutedAsAgreement;
