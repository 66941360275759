import React, { memo } from 'react';
import Dropdown from '../../common/components/dropdown/Dropdown';
import DropdownItem from '../../common/components/dropdown/DropdownItem';
import { isEmpty } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';

type UploadADocDropdownProps = React.PropsWithChildren<{
    uploadedDocument: any;
    removeFileToUpload: () => void;
    handleClearSignaturePlaceholders: (fileId: number) => boolean;
    handleClearSignaturePlaceholdersOnAllDocs: () => boolean;
    uploadedDocumentsCount: number;
    radixDropdownPortalContainer: HTMLElement;
    index: number;
}>;

const UploadADocDropdown: React.FC<UploadADocDropdownProps> = ({
    uploadedDocument,
    removeFileToUpload,
    handleClearSignaturePlaceholders,
    handleClearSignaturePlaceholdersOnAllDocs,
    uploadedDocumentsCount,
    radixDropdownPortalContainer,
    index,
    children
}) => {
    const confirmDeleteUploadedDoc = () => {
        let didUserConfirm = false;

        confirmAlert({
            title: '',
            message: 'Are you sure you want to delete this file ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        didUserConfirm = true;
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ],
            // @ts-expect-error react-confirm-alert type definition is incorrect, see https://github.com/GA-MO/react-confirm-alert/blob/master/Document-v2.md
            afterClose: () => {
                if (didUserConfirm) {
                    removeFileToUpload();
                }
            }
        });
    };

    return (
        <Dropdown
            radixDropdownPortalContainer={radixDropdownPortalContainer}
            trigger={children}
            dropdownItems={[
                {
                    key: 'delete',
                    component: <DropdownItem key="delete" text="Delete" onClick={confirmDeleteUploadedDoc} />
                },
                {
                    key: 'clear',
                    component: (
                        <DropdownItem
                            key="clear"
                            text="Clear placeholders"
                            onClick={() => handleClearSignaturePlaceholders(uploadedDocument?.id)}
                        />
                    ),
                    showItemCondition: !isEmpty(uploadedDocument?.annotationsCount)
                },
                {
                    key: 'clearAll',
                    component: (
                        <DropdownItem
                            key="clear"
                            text="Clear placeholders on all docs"
                            onClick={() => handleClearSignaturePlaceholdersOnAllDocs()}
                        />
                    ),
                    showItemCondition: !isEmpty(uploadedDocument?.annotationsCount) && uploadedDocumentsCount > 1
                }
            ]}
            data-test={`document-${index}-dropdown`}
        />
    );
};

export default memo(UploadADocDropdown);
