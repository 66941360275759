import React from 'react';

import '../../sass/editableContent.scss';
import { openEditAddressModal } from '../../actions/dashboard';
import EditButton from '../../common/components/EditButton';
import { connect } from 'react-redux';

class EditableContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            address: this.props.address
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({ address: nextProps.address });
    }

    onEdit = () => {
        this.props.openModal(this.props.leaseId, this.state.address);
    };

    render() {
        let { address } = this.state;

        return (
            <div>
                <div onClick={this.onEdit} className="address-info header-container for-lg-modal">
                    <label className="select-label" htmlFor="select-address">
                        <p>
                            <b>Address:&nbsp;</b>
                        </p>
                    </label>
                    <div className="edit-group">
                        <p className="header-address">{address}</p>
                        <EditButton id="edit-address" />
                    </div>
                </div>
                <div className="validation-error">{this.state.validationError}</div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isOpen: state.dashboard.isEditAddressModalOpen
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: (leaseId, address) => {
            dispatch(openEditAddressModal(leaseId, address));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditableContent);
