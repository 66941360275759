import React from 'react';
import {
    BASIS_TYPE_EXCLUSIVE_AGENCY,
    BASIS_TYPE_SOLE_AGENCY,
    SERVICE_TYPE_AUCTION,
    YES_LABEL
} from '../../../../../../config';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo, getSalesStepTitle } from '../../../../../../utils/agreementUtils';
import DataString from '../../bloks/DataString';

export default function AgreementSalesAgreementTerm({ leaseAllInfo }) {
    const partName = 'term';
    const { term, location } = leaseAllInfo;

    if (!term) {
        return <></>;
    }

    const step = getSalesStepNo(location, partName);

    return (
        <>
            <AgreementSection title={getSalesStepTitle(location, partName)} step={step} leaseAllInfo={leaseAllInfo}>
                <div className="data data-row">
                    <DataString title={'The agent can perform the following service:'} text={term.serviceType} />
                    <DataString title={'The agent has the following term:'} text={term.termType} />
                    <DataString
                        title={'Start and End Date:'}
                        text={`${term.startDateFormatted} to ${term.endDateFormatted}`}
                    />
                    <DataString title={'Agent appointment type:'} text={term.basisType} />
                    {[BASIS_TYPE_SOLE_AGENCY, BASIS_TYPE_EXCLUSIVE_AGENCY].includes(term.basisType) && (
                        <DataString
                            title={
                                'At the end of the sole or exclusive agency, the parties wish for the appointment to continue as an open listing:'
                            }
                            text={term.agreeToOpenListing}
                        />
                    )}
                </div>
            </AgreementSection>
            {term.serviceType === SERVICE_TYPE_AUCTION && renderAuctionInformation()}
        </>
    );

    function renderAuctionInformation() {
        return (
            <>
                <AgreementSection title={'Auction Details'} step={step} leaseAllInfo={leaseAllInfo}>
                    <div className="data data-row">
                        <DataString title={'Auction date:'} text={term.auctionDateFormatted || 'N/A'} />
                        <DataString title={'Authorised to sell by public auction:'} text={term.publicAuction} />
                        {term.publicAuction === YES_LABEL && (
                            <>
                                <DataString title={'Auction time:'} text={term.auctionTime || 'N/A'} />
                                <DataString title={'Place of Auction:'} text={term.auctionPlace || 'N/A'} />
                                <DataString title={'Auctioneer Fee:'} text={term.auctioneerFee || 'N/A'} />
                                <DataString
                                    title={'Property Terms of Settlement:'}
                                    text={term.termOfSettlementText || 'N/A'}
                                />
                            </>
                        )}
                    </div>
                </AgreementSection>
            </>
        );
    }
}
