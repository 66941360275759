import * as React from 'react';
import { getRole } from '../../../utils/userUtils';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import { formatDateTime, getAgencyTimezoneFromUser } from '../../../utils/dateUtils';
import ReactTooltip from 'react-tooltip';

export default class Invitations extends React.Component {
    render() {
        const { invitations, loggedInUser } = this.props;
        return (
            <div>
                {!invitations.length && <div />}
                {!!invitations.length && (
                    <div className="team-block">
                        <div className="name">
                            <p>Invitations</p>
                        </div>
                        <div className="table-sort table">
                            <Table
                                sortable={[
                                    {
                                        column: 'expires',
                                        sortFunction: function(a, b) {
                                            return a > b ? 1 : -1;
                                        }
                                    },
                                    {
                                        column: 'name',
                                        sortFunction: function(a, b) {
                                            return a.props.children > b.props.children ? 1 : -1;
                                        }
                                    },
                                    {
                                        column: 'role',
                                        sortFunction: function(a, b) {
                                            return a.props.children > b.props.children ? 1 : -1;
                                        }
                                    }
                                ]}
                                defaultSort={{ column: 'name', direction: 'desc' }}
                            >
                                <Thead>
                                    <Th column="avatar">&nbsp;</Th>
                                    <Th column="name" className="sort">
                                        <b>name</b>
                                    </Th>
                                    <Th column="email">
                                        <b>email</b>
                                    </Th>
                                    <Th column="invitationSent">
                                        <b>invitation sent</b>
                                    </Th>
                                    <Th column="role" className="sort">
                                        <b>role</b>
                                    </Th>
                                    <Th column="expires" className="sort">
                                        <b>expires</b>
                                    </Th>
                                    <Th column="type" className="sort">
                                        <b>New user</b>
                                    </Th>
                                    <Th column="action">&nbsp;</Th>
                                </Thead>
                                {invitations.map((item, index) => (
                                    <Tr key={index}>
                                        <Td column="avatar">
                                            <div>
                                                {item.user && item.user.avatar && (
                                                    <div className="user-avatar">
                                                        <img src={item.user.avatar} alt="avatar" />
                                                    </div>
                                                )}
                                                {item.user && !item.user.avatar && (
                                                    <div className="user-initials">
                                                        {item.user.firstName[0]}
                                                        {item.user.secondName[0]}
                                                    </div>
                                                )}
                                            </div>
                                        </Td>
                                        <Td column="name" className="sort">
                                            <b className="capitalize">
                                                {' '}
                                                {`${item.user.firstName ? item.user.firstName : ''} ${
                                                    item.user.secondName ? item.user.secondName : ''
                                                }`}
                                            </b>
                                        </Td>
                                        <Td column="email">{item.email ? item.email : ''}</Td>
                                        <Td column="invitationSent">
                                            {item.created
                                                ? formatDateTime(item.created, getAgencyTimezoneFromUser(loggedInUser))
                                                : item.user.created
                                                ? formatDateTime(
                                                      item.user.created,
                                                      getAgencyTimezoneFromUser(loggedInUser)
                                                  )
                                                : ''}
                                        </Td>
                                        <Td column="role">
                                            <div className="icon-block">
                                                <i className={`icon-${item.user.role}`} />
                                                {getRole(item.user.role)}
                                            </div>
                                        </Td>
                                        {item.created && (
                                            <Td column="expires" className="sort">
                                                {item.expire >= 0 ? (
                                                    `${item.expire}d`
                                                ) : (
                                                    <span className="expire-red">Expired</span>
                                                )}
                                            </Td>
                                        )}
                                        {!item.created && (
                                            <Td column="expires" className="sort">
                                                {item.user.expire >= 0 ? (
                                                    `${item.user.expire}d`
                                                ) : (
                                                    <span className="expire-red">Expired</span>
                                                )}
                                            </Td>
                                        )}
                                        {item.type && item.type === 'new' && (
                                            <Td column="type" className="sort">
                                                <span>Yes</span>
                                            </Td>
                                        )}
                                        {item.type && item.type !== 'new' && (
                                            <Td column="type" className="sort">
                                                <span>No</span>
                                            </Td>
                                        )}
                                        <Td column="action">
                                            <div className="action">
                                                <span
                                                    data-tip
                                                    data-for="cancelInvitation"
                                                    className="icon-cancel"
                                                    onClick={() => this.props.cancelInvitation(item.id)}
                                                />
                                                <ReactTooltip
                                                    id="cancelInvitation"
                                                    class="tooltip"
                                                    effect="solid"
                                                    globalEventOff="wheel"
                                                >
                                                    Cancel invitation
                                                </ReactTooltip>
                                                <span
                                                    data-tip
                                                    data-for="resendInvitation"
                                                    className="icon-refresh"
                                                    onClick={() => this.props.refreshInvitation(item.id)}
                                                />
                                                <ReactTooltip
                                                    id="resendInvitation"
                                                    class="tooltip"
                                                    effect="solid"
                                                    globalEventOff="wheel"
                                                >
                                                    Resend invitation
                                                </ReactTooltip>
                                            </div>
                                        </Td>
                                    </Tr>
                                ))}
                            </Table>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
