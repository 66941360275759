import React, { memo } from 'react';
import '../../../sass/dashboardContentTable.scss';
import BreachActive from './tables/breach/BreachActive';
import BreachCancelled from './tables/breach/BreachCancelled';
import BreachDraft from './tables/breach/BreachDraft';
import { DOC_STATUS_CANCELLED, LEASE_STATUS_DRAFT } from '../../../actions/dashboard';

const BreachListings = ({ documentList, docType, agentSignature, docStatus }) => {
    switch (docStatus) {
        case LEASE_STATUS_DRAFT: {
            return (
                <div className="table-sort">
                    <BreachDraft documentList={documentList} docType={docType} agentSignature={agentSignature} />
                </div>
            );
        }
        case DOC_STATUS_CANCELLED: {
            return (
                <div className="table-sort">
                    <BreachCancelled documentList={documentList} docType={docType} agentSignature={agentSignature} />
                </div>
            );
        }
        default: {
            return (
                <div className="table-sort">
                    <BreachActive documentList={documentList} docType={docType} agentSignature={agentSignature} />
                </div>
            );
        }
    }
};

export default memo(BreachListings);
