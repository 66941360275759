import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropertyTreeIntegration from '../../../components/settings/account/PropertyTreeIntegration';
import { getAgencyPropertyTreeIntegration, isFetchingAgencyInfo } from '../../../selectors/agency';
import { deleteAccountIntegration, updateAgency, updateIntegration } from '../../../actions/account';
import { getUserInfo } from '../../../actions/user';
import React from 'react';

const mapStateToProps = state => ({
    agencyIntegration: getAgencyPropertyTreeIntegration(state),
    isFetching: isFetchingAgencyInfo(state),
    agency: state.user.agency
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUserInfo,
            deleteAccountIntegration,
            updateAgency,
            updateIntegration
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTreeIntegration);
