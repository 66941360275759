import React from 'react';
import cx from 'classnames';
import Button from '@app/common/components/Button';
import Icon, { IconSize, Icons } from '@app/common/components/Icon';
import styles from './AgreementResidentialPropertyTreeSync.module.scss';

type ComparisonRowProps = {
    cdata: {
        field: string;
        label: string;
        value1: unknown;
        value2: unknown;
        value1Formatted: string;
        value2Formatted: string;
        isEqual: boolean;
        skipSync?: boolean;
    };
    value: string | number;
    disabled: boolean;
    onChange: (field: string, value: string | number) => void;
};

const AttributeComparisonRow: React.FC<ComparisonRowProps> = ({ cdata, onChange, value, disabled }) => {
    return (
        <tr key={cdata.field} className={cx({ [styles.conflictedRow]: disabled && !cdata.isEqual })}>
            <td style={{ width: '16px' }}>
                {!cdata.isEqual && disabled && (
                    <Icon className={styles.errorIcon} icon={Icons.NOTE_ERROR} size={IconSize.SMALL} />
                )}
            </td>
            <td>{cdata.label}</td>
            <td>
                <Button
                    secondary
                    className={cx(styles.btnLeaseValue, {
                        [styles.selectedRow]: !disabled && value === cdata.value1,
                        [styles.clickableButton]: !cdata.skipSync
                    })}
                    type="button"
                    onClick={() => {
                        onChange(cdata.field, cdata.value1);
                    }}
                    disabled={cdata.skipSync || disabled}
                >
                    {cdata.value1Formatted}
                </Button>
            </td>
            <td>
                <Button
                    secondary
                    className={cx(styles.btnLeaseValue, {
                        [styles.selectedRow]: !disabled && value === cdata.value2,
                        [styles.clickableButton]: !cdata.skipSync
                    })}
                    type="button"
                    onClick={() => {
                        onChange(cdata.field, cdata.value2);
                    }}
                    disabled={cdata.skipSync || disabled}
                >
                    {cdata.value2Formatted}
                </Button>
            </td>
        </tr>
    );
};

export default AttributeComparisonRow;
