import React from 'react';
import cx from 'classnames';
import { WebViewerInstance } from '@pdftron/webviewer';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import { IMPROVE_ANNOTATION_ACCURACY, DECREASE_FREE_TEXT_DEFAULT_FONT_SIZE } from '../../../constants/featureFlags';
import Button from '../../../common/components/Button.js';
import { CreateAnnotationOptions, Point } from '../types';
import { calculateDragImageBottomLeftPoint, calculateDragImageCenter, getOffsetFromDragEvent } from '../utils';

import styles from './AnnotationButton.module.scss';

export enum AnnotationButtonTypes {
    TOP_BAR,
    SENDER,
    CUSTOM_PLACEHOLDER,
    // Clients are indexed from 1
    CLIENT_1,
    CLIENT_2,
    CLIENT_3,
    CLIENT_4
}

export enum DragImageBorderStyle {
    DASHED,
    SOLID
}

export enum DragImageTextColor {
    BLACK,
    GREY
}

type AnnotationButtonProps = React.PropsWithChildren<{
    webViewerInstance?: WebViewerInstance;
    dropPointRef?: React.MutableRefObject<Point | undefined>;
    createAnnotation: (point?: Point, options?: CreateAnnotationOptions) => void;
    icon?: React.ReactNode;
    type: AnnotationButtonTypes;
    // We should only use one of dragImageText or dragImageSource
    // If both are provided, dragImageText will be used
    dragImageText?: string;
    dragImageSource?: string;
    // Only applies when dragImageText is used
    dragImageBorderStyle?: DragImageBorderStyle;
    // Only applies when dragImageText is used
    dragImageTextColor?: DragImageTextColor;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onDragStart?: () => void;
    onDragEnd?: () => void;
}>;

const AnnotationButton: React.FC<AnnotationButtonProps> = ({
    webViewerInstance,
    dropPointRef,
    createAnnotation,
    icon,
    type,
    dragImageText,
    dragImageSource,
    dragImageBorderStyle = DragImageBorderStyle.DASHED,
    dragImageTextColor = DragImageTextColor.BLACK,
    onMouseEnter,
    onMouseLeave,
    onDragStart,
    onDragEnd,
    children
}) => {
    const dragStartOffsetRef = React.useRef({ x: 0, y: 0 });
    const dragImageFromTextRef = React.useRef<HTMLDivElement>(null);
    const dragImageFromSourceRef = React.useRef<HTMLImageElement>(null);

    const useImprovedAnnotationAccuracy = useFeatureFlag(IMPROVE_ANNOTATION_ACCURACY);
    const isDecreaseFreeTextDefaultFontSizeEnabled = useFeatureFlag(DECREASE_FREE_TEXT_DEFAULT_FONT_SIZE);

    return (
        <>
            {useImprovedAnnotationAccuracy && dragImageText && (
                <div
                    ref={dragImageFromTextRef}
                    className={cx(styles.dragImageFromText, {
                        [styles.senderImage]: type === AnnotationButtonTypes.SENDER,
                        [styles.client1Image]: type === AnnotationButtonTypes.CLIENT_1,
                        [styles.client2Image]: type === AnnotationButtonTypes.CLIENT_2,
                        [styles.client3Image]: type === AnnotationButtonTypes.CLIENT_3,
                        [styles.client4Image]: type === AnnotationButtonTypes.CLIENT_4,
                        [styles.solidBorder]: dragImageBorderStyle === DragImageBorderStyle.SOLID,
                        [styles.greyText]: dragImageTextColor === DragImageTextColor.GREY,
                        [styles.smallFont]: isDecreaseFreeTextDefaultFontSizeEnabled
                    })}
                >
                    {dragImageText}
                </div>
            )}
            {useImprovedAnnotationAccuracy && dragImageSource && (
                <img
                    src={dragImageSource}
                    alt="Drag Image"
                    ref={dragImageFromSourceRef}
                    className={styles.dragImageFromSource}
                />
            )}
            <Button
                draggable
                startIcon={icon}
                onDragEnd={(e: DragEvent) => {
                    onDragEnd?.();
                    if (webViewerInstance && dropPointRef?.current) {
                        const dragImage =
                            dragImageFromTextRef.current || dragImageFromSourceRef.current || (e.target as HTMLElement);
                        const normalisedDragImagePoint = useImprovedAnnotationAccuracy
                            ? calculateDragImageCenter(dragImage, dropPointRef.current, dragStartOffsetRef.current)
                            : calculateDragImageBottomLeftPoint(
                                  dragImage,
                                  dropPointRef.current,
                                  dragStartOffsetRef.current
                              );

                        createAnnotation(normalisedDragImagePoint, {
                            useImprovedAnnotationAccuracy,
                            isDecreaseFreeTextDefaultFontSizeEnabled
                        });
                        dragStartOffsetRef.current = { x: 0, y: 0 };
                        dropPointRef.current = undefined;
                    }

                    e.preventDefault();
                }}
                onDragStart={(e: DragEvent) => {
                    onDragStart?.();
                    dragStartOffsetRef.current = getOffsetFromDragEvent(e);

                    const dragImage = dragImageFromTextRef.current || dragImageFromSourceRef.current;
                    if (useImprovedAnnotationAccuracy && dragImage) {
                        const { height: dragImageHeight, width: dragImageWidth } = dragImage.getBoundingClientRect();

                        e.dataTransfer?.setDragImage(dragImage, dragImageWidth * 0.5, dragImageHeight * 0.75);

                        // Override offset as we have changed the drag image
                        dragStartOffsetRef.current = {
                            x: dragImageWidth * 0.5,
                            y: dragImageHeight * 0.75
                        };
                    }
                }}
                onClick={() => {
                    createAnnotation(undefined, {
                        useImprovedAnnotationAccuracy,
                        isDecreaseFreeTextDefaultFontSizeEnabled
                    });
                }}
                className={cx({
                    [styles.topBarButton]: type === AnnotationButtonTypes.TOP_BAR,
                    [styles.senderButton]: type === AnnotationButtonTypes.SENDER,
                    [styles.client1Button]: type === AnnotationButtonTypes.CLIENT_1,
                    [styles.client2Button]: type === AnnotationButtonTypes.CLIENT_2,
                    [styles.client3Button]: type === AnnotationButtonTypes.CLIENT_3,
                    [styles.client4Button]: type === AnnotationButtonTypes.CLIENT_4
                })}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {children}
            </Button>
        </>
    );
};

export default AnnotationButton;
