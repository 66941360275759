export const UPDATE_FEES_FORM = 'UPDATE_FEES_FORM';

const initState = {
    lettingFeeAmmount: '',
    leaseRenewalFee: '',
    managementFee: '',
    leasePreparationFee: '',
    adminFee: '',
    andFinancialYearFee: ''
};

export default function feesReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_FEES_FORM: {
            return { ...state, ...action.data };
        }

        default: {
            return state;
        }
    }
}
