import React, { Component } from 'react';
import { forOwn } from 'lodash';

import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';
import { PAYMENT_METHOD_EFT, PAYMENT_METHOD_DIRECT_DEBIT, VIC_STATE } from '../../../../../config';
import { getResTenLabel } from '../../../../../utils/labelUtils';

export default class AgreementResidentialPayment extends Component {
    render() {
        const { paymentMethod, leaseAllInfo } = this.props;
        let selectedPaymentMethods = [];
        forOwn(paymentMethod, (item, key) => {
            if (item && item.enabled) {
                if (key === PAYMENT_METHOD_EFT) {
                    selectedPaymentMethods.push(getResTenLabel('eft', leaseAllInfo.location));
                } else if (key === PAYMENT_METHOD_DIRECT_DEBIT) {
                    selectedPaymentMethods.push('Direct Debit');
                } else {
                    selectedPaymentMethods.push(key);
                }
            }
        });

        return (
            <AgreementSection
                title="Payment Details"
                className="section-payment"
                step={getResTenStepNo(leaseAllInfo.location, 'paymentMethod', leaseAllInfo.subLeaseType)}
                leaseAllInfo={this.props.leaseAllInfo}
            >
                {leaseAllInfo.location !== VIC_STATE && (
                    <div className="data-container column">
                        <div className="text">
                            {selectedPaymentMethods.length === 1 && (
                                <p>
                                    Payment method is <span className="bold">{selectedPaymentMethods.join(', ')}</span>
                                </p>
                            )}
                            {selectedPaymentMethods.length > 1 && (
                                <p>
                                    Payment methods are{' '}
                                    <span className="bold">{selectedPaymentMethods.join(', ')}</span>
                                </p>
                            )}
                            {paymentMethod.rentLocation && <p>Place for rent payment: {paymentMethod.rentLocation}</p>}
                        </div>
                    </div>
                )}
                {leaseAllInfo.location === VIC_STATE && paymentMethod.DirectDebit && paymentMethod.DirectDebit.enabled && (
                    <div className="data data-fix-width-large">
                        <p className="name">Direct Debit</p>
                        {paymentMethod.DirectDebit.details.BSB && (
                            <div className="data-string">
                                <span className="data-contact">BSB</span>
                                <p className="text">{paymentMethod.DirectDebit.details.BSB}</p>
                            </div>
                        )}
                        {paymentMethod.DirectDebit.details.accountName && (
                            <div className="data-string">
                                <span className="data-contact">Account Name</span>
                                <p className="text">{paymentMethod.DirectDebit.details.accountName}</p>
                            </div>
                        )}
                        {paymentMethod.DirectDebit.details.accountNumber && (
                            <div className="data-string">
                                <span className="data-contact">Account Number</span>
                                <p className="text">{paymentMethod.DirectDebit.details.accountNumber}</p>
                            </div>
                        )}
                        {paymentMethod.DirectDebit.details.debitStartDateFormatted && (
                            <div className="data-string">
                                <span className="data-contact">Start date</span>
                                <p className="text">{paymentMethod.DirectDebit.details.debitStartDateFormatted}</p>
                            </div>
                        )}
                        {paymentMethod.DirectDebit.details.debitEndDateFormatted && (
                            <div className="data-string">
                                <span className="data-contact">End date</span>
                                <p className="text">{paymentMethod.DirectDebit.details.debitEndDateFormatted}</p>
                            </div>
                        )}
                        <div className="data-string">
                            <span className="data-contact">Max debit amount</span>
                            <p className="text">{paymentMethod.DirectDebit.details.maximumDebitAmount}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">Permission to debit invoices</span>
                            <p className="text">
                                {paymentMethod.DirectDebit.details.permissionToDebitInvoices === true ? 'Yes' : 'No'}
                            </p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">Comments</span>
                            <p className="text">{paymentMethod.DirectDebit.details.comments}</p>
                        </div>
                    </div>
                )}
                {leaseAllInfo.location === VIC_STATE && paymentMethod.EFT && paymentMethod.EFT.enabled && (
                    <div className="data data-fix-width-large">
                        <p className="name">Bank Deposit</p>
                        <div className="data-string">
                            <span className="data-contact">BSB</span>
                            <p className="text">{paymentMethod.EFT.details.BSB}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">Bank Name</span>
                            <p className="text">{paymentMethod.EFT.details.bankName}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">Account Number</span>
                            <p className="text">{paymentMethod.EFT.details.accountNumber}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">Account Name</span>
                            <p className="text">{paymentMethod.EFT.details.accountName}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">Reference</span>
                            <p className="text">{paymentMethod.EFT.details.reference}</p>
                        </div>
                    </div>
                )}
                {leaseAllInfo.location === VIC_STATE && paymentMethod.BPAY && paymentMethod.BPAY.enabled && (
                    <div className="data data-fix-width-large">
                        <p className="name">BPAY</p>
                        <div className="data-string">
                            <span className="data-contact">Biller Code</span>
                            <p className="text">{paymentMethod.BPAY.details.billerCode}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">Customer Reference Number</span>
                            <p className="text">{paymentMethod.BPAY.details.customerReferenceNumber}</p>
                        </div>
                    </div>
                )}
                {leaseAllInfo.location === VIC_STATE && paymentMethod.Cheque && paymentMethod.Cheque.enabled && (
                    <div className="data data-fix-width-large">
                        <p className="name">Cheque</p>
                        <div className="data-string">
                            <span className="data-contact">Payable to</span>
                            <p className="text">{paymentMethod.Cheque.details.madePayableTo}</p>
                        </div>
                    </div>
                )}
                {leaseAllInfo.location === VIC_STATE && paymentMethod.Cash && paymentMethod.Cash.enabled && (
                    <div className="data data-fix-width-large">
                        <p className="name">Cash</p>
                        <div className="data-string">
                            <span className="data-contact">Payable to</span>
                            <p className="text">{paymentMethod.Cash.details}</p>
                        </div>
                    </div>
                )}

                {leaseAllInfo.location === VIC_STATE && paymentMethod.MoneyOrder && paymentMethod.MoneyOrder.enabled && (
                    <div className="data data-fix-width-large">
                        <p className="name">Money Order</p>
                        <div className="data-string">
                            <span className="data-contact">Details</span>
                            <p className="text">{paymentMethod.MoneyOrder.details}</p>
                        </div>
                    </div>
                )}

                {leaseAllInfo.location === VIC_STATE && paymentMethod.Other && paymentMethod.Other.enabled && (
                    <div className="data data-fix-width-large">
                        <p className="name">Other electronic form of payment, including Centrepay</p>
                        <div className="data-string">
                            <span className="data-contact">Details</span>
                            <p className="text">{paymentMethod.Other.details}</p>
                        </div>
                    </div>
                )}
            </AgreementSection>
        );
    }
}
