import { CheckSharp, HelpOutline } from '@material-ui/icons';
import cx from 'classnames';
import { capitalize, debounce } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { isMobileWidth } from '../../../config';
import { getCouponInfo } from '../../../selectors/authorization.js';
import { formatCurrency } from '../../../utils/formUtils.js';
import Button from '../Button.js';
import Icon, { Icons } from '../Icon';
import Carousel from '../carousel/Carousel';
import { Tag, TagColor, TagSize } from '../tag/Tag';
import Tooltip from '../tooltips/Tooltip';
import ExpandableCard from './ExpandableCard';
import styles from './SubscriptionCard.module.scss';

type Subscription = {
    id: string;
    duration: 'monthly';
    size: 'small' | 'medium' | 'large';
    price: number;
    hasFreeTrial: boolean;
    constraintText: string;
    additionalConstraintText?: string;
    featureCategories: FeatureCategoryData[];
    taxDescription: string;
    currency: string;
};

type FeatureCategoryData = {
    title: string;
    items: FeatureData[];
};

type FeatureData = {
    name: string;
    helpText?: string;
    subText?: string;
};

type FeatureCategoryProps = {
    cardType: 'primary' | 'secondary';
} & FeatureCategoryData;

const FeatureCategory: React.FC<FeatureCategoryProps> = ({ title, items, cardType }) => {
    return (
        <section className={cx(styles.featureCategory, cardType === 'primary' ? styles.primary : styles.secondary)}>
            <h3 className={styles.featureCategoryTitle}>{title}</h3>
            <ul className={styles.featureList}>
                {items?.map((feature, index) => (
                    <li key={index} className={styles.feature}>
                        <div
                            // We don't display text content for secondary features, but we include it in the layout to maintain alignment with the primary card
                            className={cx(styles.featureTextContent, cardType === 'secondary' ? styles.hidden : null)}
                            aria-hidden={cardType === 'secondary'}
                        >
                            <div className={styles.featureName}>{feature.name}</div>
                            {feature.helpText && (
                                <>
                                    <HelpOutline className={styles.featureTooltipTrigger} data-tip={feature.helpText} />
                                    <Tooltip className={styles.featureTooltip} html />
                                </>
                            )}
                            {feature.subText && <div className={styles.featureSubText}>{feature.subText}</div>}
                        </div>
                        {cardType === 'secondary' && <CheckSharp className={styles.checkIcon} />}
                    </li>
                ))}
            </ul>
        </section>
    );
};

export type CouponInfo = {
    name: string;
    percent_off: number;
    amount_off?: number;
    duration_in_months: number;
    success: boolean;
};

type PriceProps = {
    price: number;
    taxDescription: string;
    couponInfo: CouponInfo;
    currency: string;
};

export const getPriceWithCoupon = (price: number, couponInfo: CouponInfo): number => {
    let priceWithCoupon = price;
    if (couponInfo.amount_off) {
        priceWithCoupon = price - couponInfo.amount_off / 100;
    } else if (couponInfo.percent_off) {
        priceWithCoupon = price * (1 - couponInfo.percent_off / 100);
    }

    return Math.max(priceWithCoupon, 0);
};

const PriceWithCoupon: React.FC<PriceProps> = ({ price, couponInfo, taxDescription, currency }) => {
    const newPrice = getPriceWithCoupon(price, couponInfo);

    return (
        <>
            <div className={styles.price}>
                <span className={styles.unit}>$</span>
                <span className={styles.amount}>{formatCurrency(newPrice, true)}</span>
            </div>
            <div className={styles.pricePostFix}>
                <span className={styles.crossedOutPrice}>${price}</span>
                <span className={styles.currency}>{currency}</span>
                <span className={styles.tax}>{taxDescription}</span>
            </div>
        </>
    );
};

const Price: React.FC<PriceProps> = ({ price, taxDescription, currency }) => {
    return (
        <>
            <span className={styles.unit}>$</span>
            <span className={styles.amount}>{price}</span>
            <div className={styles.pricePostFix}>
                <span className={styles.currency}>{currency}</span>
                <span className={styles.tax}>{taxDescription}</span>
            </div>
        </>
    );
};

const CouponTag: React.FC<PriceProps> = ({ couponInfo }) => {
    let couponAmount;
    if (couponInfo.amount_off) {
        const amount = couponInfo.amount_off / 100;
        couponAmount = `$${formatCurrency(amount, true)}`;
    } else {
        couponAmount = `${couponInfo.percent_off}%`;
    }
    const discountText = `${couponAmount} off ${
        couponInfo.duration_in_months ? 'for ' + couponInfo.duration_in_months + ' months' : 'forever'
    }`;

    return (
        <Tag
            text={discountText}
            className={styles.discountTag}
            size={TagSize.Small}
            color={TagColor.Green}
            endIcon={<Icon className={styles.tickIcon} icon={Icons.TICK} />}
        />
    );
};

type HeaderProps = { headerButton?: JSX.Element } & Pick<
    Subscription,
    'duration' | 'size' | 'price' | 'taxDescription' | 'currency'
>;

const Header: React.FC<HeaderProps> = ({ headerButton, duration, size, price, taxDescription, currency }) => {
    const couponInfo: CouponInfo = useSelector(getCouponInfo);

    return (
        <div className={styles.header}>
            <div className={styles.duration}>{capitalize(duration)} Subscription</div>
            <h2 className={styles.heading}>{capitalize(size)}</h2>
            <div className={styles.priceContainer}>
                {couponInfo && price ? (
                    <PriceWithCoupon
                        price={price}
                        couponInfo={couponInfo}
                        taxDescription={taxDescription}
                        currency={currency}
                    />
                ) : (
                    <Price price={price} couponInfo={couponInfo} taxDescription={taxDescription} currency={currency} />
                )}
            </div>
            {headerButton && headerButton}
            {couponInfo && (
                <>
                    {price > 0 ? (
                        <CouponTag
                            price={price}
                            couponInfo={couponInfo}
                            taxDescription={taxDescription}
                            currency={currency}
                        />
                    ) : (
                        <div className={styles.discountTagWhiteSpace} />
                    )}
                </>
            )}
            <hr />
        </div>
    );
};

type ConstraintTextProps = {
    text: string;
    additionalText?: string;
};

const ConstraintText: React.FC<ConstraintTextProps> = ({ text, additionalText }) => {
    const words = text.split(' ');
    const firstWord = words.slice(0, 1);
    const remainingWords = words.slice(1).join(' ');

    return (
        <div className={styles.constraintText}>
            <p>
                <strong>{firstWord}</strong> {remainingWords}
            </p>
            {additionalText && <p>{additionalText}</p>}
        </div>
    );
};

type SubscriptionCardProps = {
    subscription: Subscription;
    cardType: 'primary' | 'secondary';
    expanded: boolean;
    handleExpand?: () => void;
    handleStartTrial: (subscriptionId: string) => void;
};

/** Mobile subscription card. State must be controlled from the parent component
 Does not display any content in collapsed state, and has sign up button immediately below pricing section
 */
const MobileSubscriptionCard: React.FC<SubscriptionCardProps> = ({
    subscription,
    cardType,
    expanded,
    handleExpand,
    handleStartTrial
}) => {
    return (
        <ExpandableCard
            className={styles.subscriptionCard}
            header={
                <Header
                    {...subscription}
                    headerButton={
                        <Button
                            primary
                            className={styles.headerButton}
                            onClick={() => handleStartTrial(subscription.id)}
                        >
                            Start{subscription.hasFreeTrial && ' Free Trial'}
                        </Button>
                    }
                />
            }
            expandedContent={
                <>
                    <ConstraintText text={subscription.constraintText} />
                    {subscription.featureCategories.map((featureCategory, index) => (
                        <FeatureCategory key={index} {...featureCategory} cardType={cardType} />
                    ))}
                </>
            }
            expanded={expanded}
            expandButtonProps={
                cardType === 'primary'
                    ? {
                          collapsedLabel: 'See all features',
                          expandedLabel: 'Hide all features',
                          handleExpand: handleExpand!
                      }
                    : undefined
            }
        />
    );
};

/** Desktop subscription card. State must be controlled from parent component
 Displays number of included agreements and first feature section in collapsed state. Free trial button is in card footer.
 */
const DesktopSubscriptionCard: React.FC<SubscriptionCardProps> = ({
    subscription,
    cardType,
    expanded,
    handleExpand,
    handleStartTrial
}) => {
    const featureCategories = [...subscription.featureCategories];
    // Remove and store first feature section from array (we are assuming featureCategories is not empty)
    const firstFeatureCategory = featureCategories.shift()!;
    // Store remaininig feature sections
    const remainingFeatureCategories = featureCategories;

    return (
        <ExpandableCard
            className={styles.subscriptionCard}
            testId={`desktop-subscription-card-${subscription.size.toLowerCase()}`}
            header={<Header {...subscription} />}
            collapsedContent={
                <>
                    <ConstraintText text={subscription.constraintText} />
                    <FeatureCategory {...firstFeatureCategory} cardType={cardType} />
                </>
            }
            expandedContent={
                <>
                    {remainingFeatureCategories.map((featureCategory, index) => (
                        <FeatureCategory key={index} {...featureCategory} cardType={cardType} />
                    ))}
                </>
            }
            expanded={expanded}
            expandButtonProps={
                cardType === 'primary'
                    ? {
                          collapsedLabel: 'More',
                          expandedLabel: 'Less',
                          handleExpand: handleExpand!
                      }
                    : undefined
            }
            footer={
                <Button primary onClick={() => handleStartTrial(subscription.id)}>
                    Start{subscription.hasFreeTrial && ' Free Trial'}
                </Button>
            }
        />
    );
};

type SubscriptionCardGroupProps = {
    className?: string;
    subscriptions: Subscription[];
    handleStartTrial: (subscriptionId: string) => void;
};

/** Returns a group of subscription cards in a horizontal row*/
const DesktopSubscriptionCardGroup: React.FC<SubscriptionCardGroupProps> = ({
    className,
    subscriptions,
    handleStartTrial
}) => {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Carousel
            ariaLabel="Subscription plans carousel"
            carouselOptions={{ align: 'start', containScroll: 'trimSnaps' }}
            className={className}
            navigationButtons
            slideContainerClassName={styles.carouselSlideContainer}
        >
            {subscriptions.map((subscription, index) => (
                <DesktopSubscriptionCard
                    key={index}
                    subscription={subscription}
                    cardType={index === 0 ? 'primary' : 'secondary'}
                    expanded={expanded}
                    handleExpand={() => setExpanded(oldExpanded => !oldExpanded)}
                    handleStartTrial={handleStartTrial}
                />
            ))}
        </Carousel>
    );
};

/** Returns a group of subscription cards in a carousel */
const MobileSubscriptionCardGroup: React.FC<SubscriptionCardGroupProps> = ({
    className,
    subscriptions,
    handleStartTrial
}) => {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Carousel
            ariaLabel="Subscription plans carousel"
            className={className}
            navigationDots
            slideContainerClassName={styles.carouselSlideContainer}
            carouselOptions={{ align: 'start' }}
        >
            {subscriptions.map((subscription, index) => (
                <MobileSubscriptionCard
                    key={index}
                    subscription={subscription}
                    cardType="primary"
                    expanded={expanded}
                    handleExpand={() => setExpanded(oldExpanded => !oldExpanded)}
                    handleStartTrial={handleStartTrial}
                />
            ))}
        </Carousel>
    );
};

const SubscriptionCardGroup: React.FC<SubscriptionCardGroupProps> = props => {
    const checkIsMobileWidth = () => isMobileWidth(899);
    // TODO: Have explicit breakpoint functions consistent with breakpoint variables used in css
    const [isMobile, setIsMobile] = React.useState(checkIsMobileWidth());

    // TODO: Move this into app.js and store value/current breakpoint in redux store
    React.useEffect(() => {
        const handleResize = debounce(() => {
            setIsMobile(checkIsMobileWidth());
        }, 250);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile ? <MobileSubscriptionCardGroup {...props} /> : <DesktopSubscriptionCardGroup {...props} />;
};

export { FeatureData, Subscription, SubscriptionCardGroup };
