import React, { memo, useEffect, useState } from 'react';
import appHistory from '../AppHistory';
import { PreLoader } from '../common/components/PreLoader';
import axios from 'axios';
import { useRollbarProvider } from '../components/providers/RollbarProvider';
import { useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import '../sass/emailAuth.scss';
import logoPng from '../../assets/images/logo.png';
import * as Login from '../reducers/login';
import { toSaveToken } from '../utils/toSaveToken';
import { GENERAL_ACCOUNT_TYPE } from '../constants/constants';
import { DOCUMENT_CUSTOM } from '../config';
import { LEASE_STATUS_SENT_SIGNING } from '../actions/dashboard';

const OnboardingEmailAuthSend = () => {
    const { token } = useParams();
    const Rollbar = useRollbarProvider();
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Email auth';
        getEmailAuth();
    }, []);

    function goToDashboard(response) {
        const { docId, user } = response;

        dispatch({
            type: Login.VERIFY_SUCCESS,
            result: response
        });

        toSaveToken(user, false, GENERAL_ACCOUNT_TYPE);

        appHistory.push(`/dashboard/documents/${DOCUMENT_CUSTOM}/${LEASE_STATUS_SENT_SIGNING}?source=onboarding`);
    }

    const getEmailAuth = () => {
        return axios
            .get(`/api/onboarding/verify-email-and-send/${token}`)
            .then(result => {
                goToDashboard(result.data);
            })
            .catch(error => {
                confirmAlert({
                    title: '',
                    message: `This link is not valid please contact FLK if you are trying to register.`,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => { }
                        }
                    ]
                });
                Rollbar.error(
                    'Failed to get email auth from URL ',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
            });
    };

    return (
        <div className="email-auth">
            <img src={logoPng} className="email-auth-logo" alt="logo" />
            <div className="email-auth-preloader">
                <PreLoader className="email-auth-preloader__spinner"/>
            </div>
        </div>
    );
};

export default memo(OnboardingEmailAuthSend);
