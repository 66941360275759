import React from 'react';
import { LandlordSuperiorTitlesGroup } from '../../../../components/lease/mainScreen/common/LandlordSuperiorTitlesGroup';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { FormTextRegular } from '../../../../components/form/FormText';
import { FieldArray } from 'react-final-form-arrays';

const SaLandlordFields = ({ values, push, removeBatch, landlord }) => {
    return (
        <React.Fragment>
            <div className="form-checkbox">
                <CheckboxCheck
                    value={true}
                    name="haveSuperiorTitle"
                    label="There are Person(s) who have Superior Title to the Landlord"
                    onClick={value => {
                        if (value && values.superiorTitles.length === 0) {
                            push('superiorTitles', {
                                firstName: '',
                                secondName: '',
                                ABN: '',
                                address: ''
                            });
                        } else if (!value && values.superiorTitles.length > 0) {
                            let indexes = values.superiorTitles.map((_, i) => i) || [];
                            removeBatch('superiorTitles', indexes);
                        }
                    }}
                />
            </div>
            {values.haveSuperiorTitle && (
                <React.Fragment>
                    <FieldArray name="superiorTitles" initialValue={landlord.superiorTitles}>
                        {({ fields }) => (
                            <React.Fragment>
                                {fields.map((name, index) => {
                                    return (
                                        <div className="persons" key={index}>
                                            <LandlordSuperiorTitlesGroup
                                                key={index}
                                                index={index}
                                                person={fields.value[index]}
                                                removePerson={() => fields.remove(index)}
                                                values={values}
                                            />
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </FieldArray>
                    <div className="button">
                        <button
                            type="button"
                            onClick={() => {
                                push('superiorTitles', {
                                    firstName: '',
                                    secondName: '',
                                    ABN: '',
                                    address: ''
                                });
                            }}
                        >
                            Add Person with Superior Title to the Landlord
                        </button>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default SaLandlordFields;
