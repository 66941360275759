import React from 'react';
import * as agreementUtils from '../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getAgencyTimezoneFromUser, formatDateDay } from '../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';
import { getResTenLabel } from '../../../../../utils/labelUtils';
import { formatCurrency } from '../../../../../utils/formUtils';
import { SA_STATE, QLD_STATE, formatPeriod } from '../../../../../config';

function RentSummary({ rent, location, isLongTermRenewal }) {
    const loggedInUser = useSelector(getUserInfo);
    return (
        <>
            <p className="text text-gray">Weekly rent: ${formatCurrency(rent.amount, true)}</p>
            <p className="text">
                The tenant will pay: ${agreementUtils.getRentForPeriod(parseFloat(rent.amount), rent.payablePeriod)} per{' '}
                {formatPeriod(rent.payablePeriod)}
            </p>
            {location === QLD_STATE && (
                <p className="text">
                    When was the rent for the premises last increased: {rent.nominateDatedLastIncreasedAnswer}
                </p>
            )}
            <p className="text">
                Payable in advance starting on {formatDateDay(rent.dateStart, getAgencyTimezoneFromUser(loggedInUser))}
            </p>
            {!isLongTermRenewal && location === SA_STATE && (
                <React.Fragment>
                    <p className="text">
                        The rent is payable in advance is <strong>{rent.payableInAdvanced} </strong>
                    </p>
                    <p className="text">
                        The first payment of <strong>${rent.firstPayment?.toFixed(2)} </strong>
                        is due on {formatDateDay(rent.firstPaymentDueOn, getAgencyTimezoneFromUser(loggedInUser))}
                    </p>
                    <p className="text">
                        The second payment of <strong>${rent.secondPayment?.toFixed(2)} </strong>
                        is due on {formatDateDay(rent.secondPaymentDueOn, getAgencyTimezoneFromUser(loggedInUser))}
                    </p>
                    <p className="text">
                        Thereafter payment of <strong>${rent.thereAfterPayment?.toFixed(2)} </strong>
                        on the {rent.dueOnDayOfMonth} of each&nbsp;
                        {formatPeriod(rent.dueOnDayOfMonthPeriod)}
                    </p>
                </React.Fragment>
            )}
            {!isLongTermRenewal && rent.isBond && (
                <p className="text">
                    Bond amount <strong>${rent.bondAmount}</strong>
                </p>
            )}
            {!isLongTermRenewal && rent.bondAlreadyHeld && <p className="text">Bond amount already held</p>}
        </>
    );
}

function AgreementResidentialRent({ leaseAllInfo }) {
    return (
        <AgreementSection
            title={getResTenLabel('rentTitle', leaseAllInfo.location)}
            step={getResTenStepNo(leaseAllInfo.location, 'rent', leaseAllInfo.subLeaseType)}
            leaseAllInfo={leaseAllInfo}
        >
            {!!leaseAllInfo.rent && (
                <div className="data">
                    <RentSummary
                        rent={leaseAllInfo.rent}
                        location={leaseAllInfo.location}
                        isLongTermRenewal={agreementUtils.isLongTermRenewalSubLease(leaseAllInfo.subLeaseType)}
                    />
                </div>
            )}
        </AgreementSection>
    );
}

export default AgreementResidentialRent;
