import React from 'react';
import { Table, Th, Td, Thead, Tr } from 'reactable';
import LeaseDropdown from '../../../../dropdowns/LeaseDropdown';
import AgentLogo from '../../../../AgentLogo';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../selectors/user';
import { useSelector } from 'react-redux';
import { isAccountEnabled } from '../../../../../utils/userUtils';

const TablePmArchived = ({ leaseStatus, agreementList, leaseType, showModalInfoAgreement }) => {
    const loggedInUser = useSelector(getUserInfo);
    const tableHead = () => {
        return (
            <Thead>
                <Th column="agentLogo"> </Th>
                <Th column="isRenewal"> </Th>
                <Th column="address">address</Th>
                <Th column="tenants">landlord(s)</Th>
                <Th column="startDate">agreement start date</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
        );
    };
    return (
        <Table>
            {tableHead(leaseType)}
            {agreementList &&
                agreementList.map((lease, key) => {
                    const loadingClassName = lease.isLoading ? 'loading' : '';
                    let startDate = '-';
                    if (lease?.pmAgreementTerm?.startDate) {
                        startDate = formatDateStandard(
                            lease.pmAgreementTerm.startDate,
                            getAgencyTimezoneFromUser(loggedInUser)
                        );
                    } else if (lease?.pmAuthorityPeriodDetails?.startDate) {
                        startDate = formatDateStandard(
                            lease.pmAuthorityPeriodDetails.startDate,
                            getAgencyTimezoneFromUser(loggedInUser)
                        );
                    }
                    return (
                        <Tr
                            key={key}
                            className={`showmodal ${loadingClassName}`}
                            onClick={() => {
                                showModalInfoAgreement(lease);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={lease.agent} />
                            </Td>
                            <Td column="address">
                                <b>{lease.address}</b>
                            </Td>
                            <Td column="tenants">{lease.pmLandlord && lease.pmLandlord.pmLandlordListText}</Td>
                            <Td column="startDate">{startDate}</Td>
                            {isAccountEnabled(loggedInUser) && (
                                <Td column="action">
                                    <div className="action">
                                        <LeaseDropdown lease={lease} leaseStatus={leaseStatus}>
                                            <div className="more-action" />
                                        </LeaseDropdown>
                                        <span className="arrow-right" />
                                    </div>
                                </Td>
                            )}
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default TablePmArchived;
