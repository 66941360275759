import React from 'react';
import CommonFooter from '../shared/CommonFooter.js';
import { DEFINE_ME } from '../../../../types/utilityTypes';

interface IntentionToSellFormFooterProps {
    isSendingIntentionToSellNotice: boolean;
    isSendingIntentionToSellNoticeSuccess: boolean;
    isSendingIntentionToSellNoticeFail: boolean;
    currentDocument: DEFINE_ME;
    documentEditMode: boolean;
    previewPdf: () => void;
    viewPdf: (docId: string) => void;
    form: DEFINE_ME;
    isSaveDraft: boolean;
    showLoadingIcon: boolean;
}

const IntentionToSellFormFooter: React.FC<IntentionToSellFormFooterProps> = ({
    isSendingIntentionToSellNotice,
    isSendingIntentionToSellNoticeSuccess,
    isSendingIntentionToSellNoticeFail,
    currentDocument,
    documentEditMode,
    previewPdf,
    viewPdf,
    form,
    isSaveDraft,
    showLoadingIcon
}) => {

    return (
        <CommonFooter
            isDoc={true}
            currentDocument={currentDocument}
            documentEditMode={documentEditMode}
            viewPdf={viewPdf}
            previewPdf={previewPdf}
            isResending={false}
            isSendingSuccess={isSendingIntentionToSellNoticeSuccess}
            isSendingFail={isSendingIntentionToSellNoticeFail}
            isSending={isSendingIntentionToSellNotice}
            form={form}
            isSaveDraft={isSaveDraft}
            showLoadingIcon={showLoadingIcon}
            hideResend={true}
        />
    );
};
export default IntentionToSellFormFooter;
