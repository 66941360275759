import { createSelector } from 'reselect';

export const getDashboard = state => {
    return state.dashboard;
};

export const getIsConnectIntegrationModalOpen = createSelector(
    [getDashboard],
    dashboard => dashboard.isConnectIntegrationModalOpen
);
export const isLeftMenuOpen = createSelector([getDashboard], dashboard => dashboard.isLeftMenuOpen);
export const isAuditTrailModalOpen = createSelector([getDashboard], dashboard => dashboard.isAuditTrailModalOpen);
export const isAuditTrailDocument = createSelector([getDashboard], dashboard => dashboard.isAuditTrailDocument);
export const isNotesOpen = createSelector([getDashboard], dashboard => dashboard.isNotesOpen);
export const isIntentionRenewalModalOpen = createSelector(
    [getDashboard],
    dashboard => dashboard.isIntentionRenewalModalOpen
);
export const getLeaseFromDashboard = createSelector([getDashboard], dashboard => dashboard.leaseFromDashboard);
export const isNotesDocument = createSelector([getDashboard], dashboard => dashboard.isNotesDocument);
export const isNotificationPanelOpen = createSelector([getDashboard], dashboard => dashboard.isNotificationPanelOpen);
export const getMobileViewEnabled = createSelector([getDashboard], dashboard => dashboard.mobileViewEnabled);
export const getIsAgreementMenuOpen = createSelector([getDashboard], dashboard => dashboard.isAgreementsMenuOpen);
export const getIsDocumentsMenuOpen = createSelector([getDashboard], dashboard => dashboard.isDocumentsMenuOpen);
export const getReloadDocumentList = createSelector([getDashboard], dashboard => dashboard.reloadDocumentList);
export const getIsEditAddressModalOpen = createSelector([getDashboard], dashboard => dashboard.isEditAddressModalOpen);
export const getIsOpenAgreementInfoModal = createSelector(
    [getDashboard],
    dashboard => dashboard.isOpenAgreementInfoModal
);
export const getAllowedTemplateList = createSelector([getDashboard], dashboard => dashboard.allowedTemplateList);
export const getLeaseToClone = createSelector([getDashboard], dashboard => dashboard.leaseToClone);

export const getIsOpenEditTenantsModal = createSelector([getDashboard], dashboard => dashboard.isOpenEditTenantsModal);

export const getIsOpenEditLandlordsModal = createSelector(
    [getDashboard],
    dashboard => dashboard.isOpenEditLandlordsModal
);

export const getIsOpenEditVendorsModal = createSelector([getDashboard], dashboard => dashboard.isOpenEditVendorsModal);

export const getIsOpenExtendExpiryModal = createSelector(
    [getDashboard],
    dashboard => dashboard.isOpenExtendExpiryModal
);
