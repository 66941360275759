import React from 'react'

import styles from './SignUpLoader.module.scss'

const SignUpLoader = () => {
    return (
        <div className={styles.container}>
            <div className={styles.spinner} />
        </div >
    )
}

export default SignUpLoader