import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../common/components/Button';
import ModalDialog from '../../common/components/ModalDialog';
import FormAddressDetailsAutocomplete from '../../components/form/FormAddressDetailsAutocomplete';

import { getAddressDetails } from '../../selectors/lease/addresses';

import { clearAddressDetailsStore, openAddressDetailsModal } from '../../actions/addressDetails';

const DocumentEditAddressModal = ({
    isOpen,
    closeModal,
    address,
    handleAddressChange,
    setAddressDetails,
    parentSelector
}) => {
    const dispatch = useDispatch();
    const addressDetails = useSelector(getAddressDetails);
    return (
        <ModalDialog
            title="Edit Address details"
            isOpen={isOpen}
            closeModal={closeModal}
            className="edit-address-modal-dialog"
            parentSelector={parentSelector}
        >
            <div className={`address-auto-complete-group`}>
                <FormAddressDetailsAutocomplete
                    name="address"
                    value={address}
                    editAddressFormData={addressDetails.editAddressFormData}
                    editedNewAddress={addressDetails.selectedAddressEdited}
                    editMode={addressDetails.editMode}
                    showAddressBelow={false}
                    detailsConfirmed={addressDetails.detailsConfirmed}
                    label={'Address'}
                    onChange={address => handleAddressChange(address)}
                    setAddressDetails={(address, details) => setAddressDetails(address, details)}
                    openAddressDetailsModal={data => dispatch(openAddressDetailsModal(data))}
                    clearAddressDetailsStore={() => dispatch(clearAddressDetailsStore())}
                />
            </div>
            <Button primary onClick={closeModal}>
                Done
            </Button>
        </ModalDialog>
    );
};

export default DocumentEditAddressModal;
