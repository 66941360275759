import { OPEN_AGREEMENT_INFO_MODAL } from './dashboard';
import { CHANGE_REQUEST_CHANGE_STEP } from './leaseWithRequest';

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

const initState = {
    serverBusy: false
};

export default function serverRequests(state = initState, action) {
    let newState = Object.assign({}, state);

    if (action.type.includes('_')) {
        const actionType = action.type.split('_').pop();

        if (actionType.toUpperCase().includes(REQUEST)) {
            newState = {
                isServerBusy: true
            };
        } else if (actionType.toUpperCase().includes(SUCCESS)) {
            newState = {
                isServerBusy: false
            };
        } else if (actionType.toUpperCase().includes(FAIL)) {
            newState = {
                isServerBusy: false
            };
        }
    }

    switch (action.type) {
        case CHANGE_REQUEST_CHANGE_STEP:
        case OPEN_AGREEMENT_INFO_MODAL: {
            newState = {
                isServerBusy: false
            };
            break;
        }
    }

    return newState;
}
