import React from 'react';
import { useSortBy, useTable } from 'react-table';
import { formatCurrency, formatCurrencyWithCurrencySign } from '../../../../utils/formUtils';
import { ArrowDropDownSharp, ArrowDropUpSharp } from '@material-ui/icons';
import {
    BREACH_NOTICE,
    DOCUMENT_READ_ONLY_MODE,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_TERMINATION_NOTICE,
    NSW_STATE,
    STANDARD_DATE_FORMAT
} from '../../../../config';
import { openAgreementInfoModal } from '../../../../actions/dashboard';
import { useDispatch } from 'react-redux';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import CancelSharp from '@material-ui/icons/CancelSharp';
import { parse } from 'date-fns';
import { openDocumentForm } from '../../../../actions/document';

const CONFIRMED_IN_FLK = 'confirmed-in-flk';
const CONFIRMED_OUTSIDE_OF_FLK = 'confirmed-outside-flk';
const NOT_SENT = 'not-sent';

const getDateSortingFunction = field => {
    return (a, b) => {
        const a1 = parse(a.values[field], STANDARD_DATE_FORMAT, new Date()).getTime();
        const b1 = parse(b.values[field], STANDARD_DATE_FORMAT, new Date()).getTime();
        if (a1 < b1) {
            return 1;
        } else if (a1 > b1) {
            return -1;
        }
        return 0;
    };
};
const AgentReportTable = ({ reportType, agentReport, agency }) => {
    const data = agentReport;
    const dispatch = useDispatch();

    const columns = React.useMemo(() => {
        if (reportType === 'lease') {
            const leaseColumns = [
                {
                    Header: 'address',
                    accessor: 'address',
                    Cell: props => {
                        return (
                            <a
                                className="special-link"
                                onClick={() => dispatch(openAgreementInfoModal(props.cell.row.original))}
                            >
                                {props.value}
                            </a>
                        );
                    }
                },
                {
                    Header: 'Term',
                    id: 'term',
                    accessor: 'term.termInWordsWithoutDates'
                },
                {
                    Header: 'Start date',
                    id: 'startdate',
                    accessor: 'term.startDateFormattedNormal',
                    sortType: getDateSortingFunction('startdate')
                },
                {
                    Header: 'End date',
                    id: 'enddate',
                    accessor: 'term.endDateFormattedNormal',
                    sortType: getDateSortingFunction('enddate')
                },
                {
                    Header: 'Current Rent',
                    id: 'rent',
                    accessor: 'rent.amount',
                    Cell: props => {
                        return <div> $ {formatCurrency(props.value, true)} </div>;
                    }
                },
                {
                    Header: 'Rent increase',
                    accessor: 'totalRentIncrease',
                    Cell: props => {
                        return <div> $ {formatCurrency(props.value, false)} </div>;
                    }
                },
                {
                    Header: 'Annual Rent Increase',
                    accessor: 'annualRentIncrease',
                    Cell: props => {
                        return <div> $ {formatCurrency(props.value, false)} </div>;
                    }
                },
                {
                    Header: 'signed & completed',
                    id: 'completed',
                    accessor: 'completion.signedDateFormattedForPdf',
                    sortType: getDateSortingFunction('completed')
                }
            ];

            if (agency.details && agency.details.location === NSW_STATE) {
                leaseColumns.splice(leaseColumns.length - 1, 0, {
                    Header: 'LLIS status',
                    accessor: 'landlord',
                    Cell: props => {
                        return (
                            <div>
                                {props.value && (
                                    <React.Fragment>
                                        {props.value.landlordInformationStatementAction === CONFIRMED_IN_FLK && (
                                            <div className="LLIS-status LLIS-status-confirmed">
                                                <CheckCircleSharpIcon /> in FLK
                                            </div>
                                        )}
                                        {props.value.landlordInformationStatementAction ===
                                            CONFIRMED_OUTSIDE_OF_FLK && (
                                            <div className="LLIS-status LLIS-status-confirmed">
                                                <CheckCircleSharpIcon /> outside FLK
                                            </div>
                                        )}
                                        {props.value.landlordInformationStatementAction === NOT_SENT && (
                                            <div className="LLIS-status LLIS-status-not-sent">
                                                <CancelSharp /> not sent
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        );
                    }
                });
            }
            return leaseColumns;
        }

        if (reportType === DOCUMENT_TERMINATION_NOTICE) {
            return [
                {
                    Header: 'address',
                    accessor: 'address',
                    Cell: props => {
                        return (
                            <a
                                className="special-link"
                                onClick={() =>
                                    dispatch(
                                        openDocumentForm(
                                            DOCUMENT_TERMINATION_NOTICE,
                                            props.cell.row.original,
                                            DOCUMENT_READ_ONLY_MODE
                                        )
                                    )
                                }
                            >
                                {props.value}
                            </a>
                        );
                    }
                },
                {
                    Header: 'Tenant(s)',
                    id: 'tenantList',
                    accessor: 'tenant.tenantsListText'
                },
                {
                    Header: 'Reason for termination',
                    id: 'reason',
                    accessor: 'terminationWithoutLease.reasonForTerminationText.reason'
                },
                {
                    Header: 'Delivery type',
                    id: 'deliveryType',
                    accessor: 'terminationWithoutLease.deliveryTypeText'
                },
                {
                    Header: 'Date of notice',
                    id: 'dateOfNotice',
                    accessor: 'terminationWithoutLease.dateOfNoticeFormatted'
                },
                {
                    Header: 'Vacant Possession Date',
                    id: 'vacantPossessionDate',
                    accessor: 'terminationWithoutLease.vacantPossessionDateFormatted'
                }
            ];
        }

        if (reportType === DOCUMENT_RENT_INCREASE) {
            return [
                {
                    Header: 'address',
                    accessor: 'address',
                    Cell: props => {
                        return (
                            <a
                                className="special-link"
                                onClick={() =>
                                    dispatch(
                                        openDocumentForm(
                                            DOCUMENT_RENT_INCREASE,
                                            props.cell.row.original,
                                            DOCUMENT_READ_ONLY_MODE
                                        )
                                    )
                                }
                            >
                                {props.value}
                            </a>
                        );
                    }
                },
                {
                    Header: 'Tenant(s)',
                    id: 'tenantList',
                    accessor: 'tenant.tenantsListText'
                },
                {
                    Header: 'Current rent amount',
                    id: 'currentRent',
                    accessor: 'currCombinedRentFrequency'
                },
                {
                    Header: 'New rent amount',
                    id: 'newRent',
                    accessor: 'newCombinedRentFrequency'
                },
                {
                    Header: 'Rent increase',
                    id: 'rentIncreaseAmount',
                    accessor: 'increaseAmount',
                    Cell: props => {
                        return <div> {formatCurrencyWithCurrencySign(props.value, false)} </div>;
                    }
                },
                {
                    Header: 'Annual rent increase',
                    id: 'annualRentIncrease',
                    accessor: 'annualRentIncrease',
                    Cell: props => {
                        return <div> {formatCurrencyWithCurrencySign(props.value, false)} </div>;
                    }
                },
                {
                    Header: 'New rent start date',
                    id: 'newRentStartDate',
                    accessor: 'rentIncrease.newRentDateStartFormatted'
                }
            ];
        }

        if (reportType === BREACH_NOTICE) {
            return [
                {
                    Header: 'address',
                    accessor: 'address',
                    Cell: props => {
                        return (
                            <a
                                className="special-link"
                                onClick={() =>
                                    dispatch(
                                        openDocumentForm(
                                            BREACH_NOTICE,
                                            props.cell.row.original,
                                            DOCUMENT_READ_ONLY_MODE
                                        )
                                    )
                                }
                            >
                                {props.value}
                            </a>
                        );
                    }
                },
                {
                    Header: 'Tenant(s)',
                    id: 'tenantList',
                    accessor: 'tenant.tenantsListText'
                },
                {
                    Header: 'Delivery type',
                    id: 'deliveryType',
                    accessor: 'details.deliveryTypeText'
                },
                {
                    Header: 'Date sent',
                    id: 'dateSent',
                    accessor: 'createdDateFormattedForPdf'
                }
            ];
        }

        return [];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: 'startdate',
                        desc: true
                    }
                ]
            }
        },
        useSortBy
    );

    return (
        <div className="table-sort data-table">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, key) => {
                        return (
                            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                                {headerGroup.headers.map((column, columnIndex) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={'sort'}
                                        key={columnIndex}
                                    >
                                        <div>
                                            <span className="table-header">{column.render('Header')}</span>
                                            <span className="table-sort-icon">
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ArrowDropDownSharp />
                                                    ) : (
                                                        <ArrowDropUpSharp />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        );
                    })}
                </thead>
                <tbody {...getTableBodyProps()} className="reactable-data">
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={rowIndex}>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <td {...cell.getCellProps()} key={cellIndex}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default AgentReportTable;
