import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';

import CreateFlkForm from './CreateFlkForm';
import { getCreateFlkClientAvailableFields, getIsOpenCreateFlkModal } from '../../../../selectors/document/createFlk';
import { DOCUMENT_CREATE_A_FLK } from '../../../../config';
import { getClientFieldDetails } from '../../../../actions/document';
import logger from '../../../../utils/logger';
import { isAgencyUserAccount, isBusinessUserAccount } from '../../../../utils/userUtils';
import { useAccountProvider } from '../../../../components/providers/AccountProvider';
import { CreateAFLKGeneralFromFormFields } from '../../../../constants/CreateAFLKGeneral';

const CreateFlkWrapper = () => {
    const dispatch = useDispatch();
    const isOpenCreateFlkModal = useSelector(getIsOpenCreateFlkModal);
    const accountType = useAccountProvider();
    const agencyFields = useSelector(getCreateFlkClientAvailableFields);
    const availableFields = isBusinessUserAccount(accountType) ? CreateAFLKGeneralFromFormFields : agencyFields;
    const [noOfTries, setNoOfTries] = useState(0);

    useEffect(() => {
        // Try to get client field details 3 times
        if (isEmpty(availableFields) && noOfTries < 3 && isAgencyUserAccount(accountType)) {
            setNoOfTries(noOfTries + 1);
            dispatch(getClientFieldDetails({ documentType: DOCUMENT_CREATE_A_FLK }));
        } else if (isEmpty(availableFields) && noOfTries > 2 && isOpenCreateFlkModal) {
            // If there is no availableFields and tried 3 times, then, log the message
            //  and throw an error and display error page when open a create a flk document
            logger.push({
                status: 'error',
                env: ENVIRONMENT,
                message: 'Something went wrong getting create a flk client field data'
            });
            throw 'Something went wrong getting create a flk client field data';
        }
    }, [availableFields, isOpenCreateFlkModal]);

    if (isOpenCreateFlkModal && !isEmpty(availableFields)) {
        return <CreateFlkForm availableFields={cloneDeep(availableFields)} />;
    } else {
        return null;
    }
};
export default memo(CreateFlkWrapper);
