import React from 'react';
import { useQuery } from '@tanstack/react-query';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils';
import { signorLinkExists } from '../../../../../utils/signorUtils';
import DataString from '../bloks/DataString';
import { LeaseAllInfo } from './types';
import useClipboard from '../../../../../hooks/useClipboard.ts';
import { getSignorLinks } from '../../../../../actions/lease';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_AWAITING_RENEWAL,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../../actions/dashboard';

type Props = {
    leaseAllInfo: LeaseAllInfo;
};

export default function AgreementClGuarantor({ leaseAllInfo }: Props) {
    const part = 'guarantor';
    const { copyToClipboard } = useClipboard();
    if (!leaseAllInfo?.guarantor) {
        return <></>;
    }
    const { location, guarantor } = leaseAllInfo;

    const { data: signorLinks } = useQuery(['signorLinks', leaseAllInfo], async () => {
        if (leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING) {
            const promise = getSignorLinks(leaseAllInfo.id).promise;
            const response = await promise;
            return response.data.signorsWithLinks;
        }
        return [];
    });
    const copyLinkToClipboard = (userId) => {
        if (signorLinks) {
            const matchingSignor = signorLinks.find((signor) => signor._id === userId);
            if (matchingSignor) {
                copyToClipboard(matchingSignor.signorLink, 'Link Copied!');
            }
        }
    };
    return (
        <div>
            <AgreementSection
                title={getClStepTitle(location, part)}
                step={getClStepNo(location, part)}
                leaseAllInfo={leaseAllInfo}
            >
                {guarantor && (
                    <>
                        {guarantor.noGuarantor && <p className="name">No guarantors</p>}
                        {!guarantor.noGuarantor && (
                            <div>
                                {Array.isArray(guarantor.persons) &&
                                    guarantor.persons.map((person, index) => (
                                        <div className="data data-row" key={index}>
                                            <p className="name">{person.fullName}</p>
                                            <p className="name">{person.address}</p>
                                            {[
                                                {
                                                    contactType: 'Ph',
                                                    contactValue: person.phone
                                                },
                                                {
                                                    contactType: 'E',
                                                    contactValue: person.email
                                                },
                                                ...person.extraContacts
                                            ]
                                                .filter(i => i.contactValue)
                                                .map((extraContact, index) => (
                                                    <DataString
                                                        title={extraContact.contactType}
                                                        text={extraContact.contactValue}
                                                        key={index}
                                                    />
                                                ))}
                                            {leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING &&
                                                signorLinkExists(signorLinks, person.id) && (
                                                    <div
                                                        className="special-link"
                                                        onClick={() => copyLinkToClipboard(person.id)}
                                                    >
                                                        Copy signing link
                                                    </div>
                                                )}
                                        </div>
                                    ))}
                            </div>
                        )}
                    </>
                )}
            </AgreementSection>
        </div>
    );
}
