import { FINISH_EDITING_LEASE_AGREEMENT_SUCCESS } from './lease';

const initState = {
    step: 0,
    isOpenForm: false,
    activeRequest: null
};

export const CHANGE_REQUEST_CHANGE_STEP = 'CHANGE_REQUEST_CHANGE_STEP';
export const SHOW_REQUEST_FORM = 'lease.form.with.request.show';
export const HIDE_REQUEST_FORM = 'lease.form.with.request.hide';
export const SET_ACTIVE_CHANGE_REQUEST = 'lease.form.with.request.set.active.request';
export const LOAD_LEASE_AGREEMENT = 'lease.form.with.request.load.form.data';

export default function leaseWithRequest(state = initState, action) {
    switch (action.type) {
        case CHANGE_REQUEST_CHANGE_STEP: {
            return {
                ...state,
                step: action.step,
                activeRequest: null
            };
        }
        case SHOW_REQUEST_FORM: {
            return {
                ...state,
                isOpenForm: true
            };
        }
        case HIDE_REQUEST_FORM: {
            return {
                ...state,
                isOpenForm: false
            };
        }
        case FINISH_EDITING_LEASE_AGREEMENT_SUCCESS: {
            return {
                ...state,
                isOpenForm: false
            };
        }
        case SET_ACTIVE_CHANGE_REQUEST: {
            return {
                ...state,
                activeRequest: action.request
            };
        }
    }
    return state;
}

export function goToRequestChangeStep(step) {
    return {
        type: CHANGE_REQUEST_CHANGE_STEP,
        step
    };
}

export function showForm() {
    return {
        type: SHOW_REQUEST_FORM
    };
}

export function hideForm() {
    return {
        type: HIDE_REQUEST_FORM
    };
}

export function setActiveRequest(request) {
    return {
        type: SET_ACTIVE_CHANGE_REQUEST,
        request
    };
}

export function loadLease(lease) {
    return {
        type: LOAD_LEASE_AGREEMENT,
        lease
    };
}
