import * as Lease from '../../reducers/lease';
import * as Dashboard from '../../reducers/dashboard';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';
import produce from 'immer';

export const UPDATE_SIGNATORY_FORM = 'dashboard.lease.signatory.updateForm';
export const SIGNATORY_FORM_ADD_PERSON = 'dashboard.lease.signatory.addPerson';
export const SIGNATORY_FORM_REMOVE_PERSON = 'dashboard.lease.signatory.removePerson';

const initState = {
    signatories: [{ firstName: '', secondName: '', isPrimaryVendor: true }],
    errors: null
};

export default function signatoryReducer(state = initState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case UPDATE_SIGNATORY_FORM: {
            return produce(state, draftState => {
                draftState = action.data;
                return draftState;
            });
        }
        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            if (
                action.result.data.lease.signatory
            ) {
                return produce(state, draftState => {
                    draftState = action.result.data.lease.signatory;
                    if (!action.result.data.lease.signatory.signatories?.length) {
                        draftState.signatories = [{ firstName: '', secondName: '', isPrimaryVendor: true }];
                    }
                    draftState.errors = null;
                    return draftState;
                });
            } else {
                return produce(state, draftState => {
                    draftState.signatories = [{ firstName: '', secondName: '', isPrimaryVendor: true }];
                    draftState.errors = null;
                    return draftState;
                });
            }
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            return produce(state, draftState => {
                draftState = action.payload.agreementInfo.signatory;
                return draftState;
            });
        }
        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            return produce(state, draftState => {
                draftState = action.lease.signatory;
                return draftState;
            });
        }
        case SIGNATORY_FORM_ADD_PERSON: {
            return produce(state, draftState => {
                draftState.signatories = [...draftState.signatories, { firstName: '', secondName: '' }];
                return draftState;
            });
        }
        case SIGNATORY_FORM_REMOVE_PERSON: {
            return produce(state, draftState => {
                draftState.signatories = [
                    ...draftState.signatories.slice(0, action.index),
                    ...draftState.signatories.slice(action.index + 1)
                ];
                return draftState;
            });
        }
        case Lease.LEASE_SIGNATORY_SUCCESS: {
            return produce(state, draftState => {
                draftState = action.result.data.lease.signatory;
                return draftState;
            });
        }
        case Dashboard.OPEN_EDIT_VENDORS_MODAL: {
            return produce(state, draftState => {
                draftState = action.payload.agreementInfo.signatory;
                return draftState;
            });
        }
        case Lease.LEASE_VENDOR_DETAILS_SUCCESS: {
            return produce(state, draftState => {
                draftState = action.result.data.signatory;
                return draftState;
            });
        }
        case Lease.UPDATE_VENDOR_SUCCESS: {
            return produce(state, draftState => {
                draftState = action.result.data.lease.signatory;
                return draftState;
            });
        }
    }
    return newState;
}
