export enum BuildADocApi {
    Save = 'api/document/create-a-flk',
    Send = 'api/document/send-create-a-flk',
    SaveTemplate = 'api/template/create-a-flk'
}

export enum FieldType {
    Text = 'text',
    Email = 'email',
    Phone = 'phone'
}

export type ClientFieldConfig = {
    showIsCorporation?: boolean;
    showIncludeLandlordAgentDetails?: boolean;
    fields: {
        mappingName: string;
        label: string;
        isRequired?: boolean;
        order: number;
        prerequisite?: { [key: string]: any }[];
        type: FieldType;
    }[];
};

export const agentFieldsConfig: ClientFieldConfig = {
    showIsCorporation: false,
    fields: [
        {
            mappingName: 'agencyName',
            label: 'Agency Name',
            isRequired: true,
            type: FieldType.Text,
            order: 0
        },
        {
            mappingName: 'address',
            label: 'Agency Address',
            type: FieldType.Text,
            order: 1
        },
        {
            mappingName: 'companyName',
            label: 'Company Name',
            type: FieldType.Text,
            order: 2
        },
        {
            mappingName: 'ABN',
            label: 'ABN / ACN',
            type: FieldType.Text,
            order: 3
        },
        {
            mappingName: 'email',
            label: 'Agency Email',
            isRequired: true,
            type: FieldType.Email,
            order: 4
        },
        {
            mappingName: 'agencyPhone',
            label: 'Agency Phone',
            type: FieldType.Text,
            order: 5
        }
    ]
};

export const entityFieldsConfig: ClientFieldConfig = {
    showIsCorporation: true,
    fields: [
        {
            mappingName: 'firstName',
            label: 'First Name',
            isRequired: true,
            prerequisite: [
                {
                    isCorporation: false
                }
            ],
            type: FieldType.Text,
            order: 0
        },
        {
            mappingName: 'middleName',
            label: 'Middle Name',
            isRequired: false,
            prerequisite: [
                {
                    isCorporation: false
                }
            ],
            type: FieldType.Text,
            order: 1
        },
        {
            mappingName: 'secondName',
            label: 'Last Name',
            isRequired: true,
            prerequisite: [
                {
                    isCorporation: false
                }
            ],
            type: FieldType.Text,
            order: 2
        },
        {
            mappingName: 'address',
            label: 'Address',
            isRequired: false,
            prerequisite: [
                {
                    isCorporation: false
                }
            ],
            type: FieldType.Text,
            order: 3
        },
        {
            mappingName: 'mobile',
            label: 'Mobile',
            type: FieldType.Phone,
            order: 4
        },
        {
            mappingName: 'email',
            label: 'Email',
            isRequired: true,
            type: FieldType.Email,
            order: 5
        }
    ]
};

export const corporationEntityFieldsConfig: ClientFieldConfig = {
    showIsCorporation: true,
    fields: [
        {
            mappingName: 'corporationName',
            label: 'Corporation Name',
            isRequired: true,
            prerequisite: [
                {
                    isCorporation: true
                }
            ],
            type: FieldType.Text,
            order: 0
        },
        {
            mappingName: 'ABN',
            label: 'ABN / ACN',
            isRequired: true,
            prerequisite: [
                {
                    isCorporation: true
                }
            ],
            type: FieldType.Text,
            order: 1
        },
        {
            mappingName: 'address',
            label: 'Corporation Address',
            isRequired: true,
            prerequisite: [
                {
                    isCorporation: true
                }
            ],
            type: FieldType.Text,
            order: 2
        },
        {
            mappingName: 'mobile',
            label: 'Mobile',
            isRequired: true,
            type: FieldType.Phone,
            order: 3
        },
        {
            mappingName: 'email',
            label: 'Email',
            isRequired: true,
            type: FieldType.Email,
            order: 4
        }
    ]
};

export const landlordFieldsConfig: ClientFieldConfig = {
    showIncludeLandlordAgentDetails: true,
    showIsCorporation: true,
    fields: entityFieldsConfig.fields
};

export const corporationLandlordFieldsConfig: ClientFieldConfig = {
    showIncludeLandlordAgentDetails: true,
    showIsCorporation: true,
    fields: corporationEntityFieldsConfig.fields
};

export const customEntityFieldsConfig: ClientFieldConfig = {
    fields: [
        {
            mappingName: 'type',
            label: 'Type',
            isRequired: true,
            type: FieldType.Text,
            order: 0
        },
        ...entityFieldsConfig.fields.map(field => ({
            ...field,
            order: field.order + 1
        }))
    ]
};

export const tradingNameFieldsConfig: ClientFieldConfig = {
    fields: [
        {
            mappingName: 'agencyName',
            label: 'Trading Name',
            isRequired: true,
            type: FieldType.Text,
            order: 0
        },
        {
            mappingName: 'address',
            label: 'Address',
            type: FieldType.Text,
            order: 1
        },
        {
            mappingName: 'companyName',
            label: 'Company Name',
            type: FieldType.Text,
            order: 2
        },
        {
            mappingName: 'email',
            label: 'Email',
            isRequired: true,
            type: FieldType.Text,
            order: 3
        },
        {
            mappingName: 'phone',
            label: 'Phone',
            type: FieldType.Text,
            order: 4
        }
    ]
};
