import * as React from 'react';
import ModalDialog from '../../../common/components/ModalDialog';
import '../../../sass/materialFacts.scss';

/**
 * Modal window to display Other disclosures.
 * This window is available from overview agreement page.
 */
const ModalViewOtherDisclosures = ({ isOpen, closeModal, otherDisclosures }) => {
    if (!otherDisclosures) {
        return <React.Fragment />;
    }

    return (
        otherDisclosures && (
            <ModalDialog title="Other Disclosures" isOpen={isOpen} closeModal={closeModal}>
                <div className="materialFacts">
                    {Object.keys(otherDisclosures).map((key, index) => {
                        if (otherDisclosures[key].enabled) {
                            return (
                                <div className="overview" key={index}>
                                    <p className="long-text">{otherDisclosures[key].fact}</p>
                                    <strong>Answer: {otherDisclosures[key].answer ? 'Yes' : 'No'}</strong>
                                </div>
                            );
                        }
                    })}
                </div>
            </ModalDialog>
        )
    );
};

export default ModalViewOtherDisclosures;
