import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getPmDuties } from '../../../selectors/lease/pmLease';

import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';

import '../../../sass/agentAuthority.scss';

const PM_DUTIES_FORM = 'pmDutiesForm';
const initState = {
    undertakeInitial: false,
    obtainReferences: false,
    executeResidentialTenancyAgreement: false,
    effectRepairs: false,
    payDisbursements: false,
    collectRent: false,
    receive: false,
    payAccounts: false,
    serveTerminationNotices: false,
    undertakeTheNecessarySteps: false,
    representTheLandlord: false,
    advertiseTheProperty: false,
    reviewTheRent: false,
    theRecovery: false,
    toTerminateTheTenancy: false
};

const PmDuties = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmDuties = useSelector(getPmDuties);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmDuties, setPmDuties] = useState(reduxPmDuties || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_DUTIES_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmDuties(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmDuties')) {
                    return error.response.data.errors.pmDuties;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmDuties = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-duties`, data);
    };

    const yesToAll = values => {
        let newPmDuties = cloneDeep(values);
        Object.keys(newPmDuties).map(function (key, index) {
            if (!['customDuties', 'serviceList', 'id'].includes(key)) {
                newPmDuties[key] = true;
            }
        });
        setPmDuties(newPmDuties);
    };

    return (
        <div className="form-container agent-authority">
            <Form onSubmit={submitForm} initialValues={pmDuties}>
                {({ handleSubmit, form, values }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_DUTIES_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <p className="text">
                                Subject to the limitations contained in this Agreement the Landlord authorises the{' '}
                                Agent, on behalf of the Landlord, from time to time to perform the duties as set out{' '}
                                below:
                            </p>
                            <p className="text">
                                <b>
                                    <i>*Any box unticked will default to NO </i>
                                </b>
                            </p>
                            <div className="button-all">
                                <button type="button" onClick={() => yesToAll(values)}>
                                    Yes To All
                                </button>
                            </div>
                            <br />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="undertakeInitial"
                                label="Undertake initial, periodic and other inspections of the Property, including with
                                prospective tenants"
                            />
                            <CheckboxCheck className="mobile-multi-select-enabled" name="obtainReferences">
                                Obtain references from prospective tenants, but not undertake additional searches
                                subject to the instructions in Part D of this Agreement
                                <ol>
                                    <li>Submit prospective tenants to the Landlord</li>
                                    <li>
                                        In the absence of instructions for the Landlord after seeking approval of a
                                        prospective tenant, the Agent may approve a prospective tenant on terms set out
                                        in this Agreement or as otherwise provided in writing by the Landlord
                                    </li>
                                </ol>
                            </CheckboxCheck>
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="executeResidentialTenancyAgreement"
                                label="Execute a residential tenancy agreement with a prospective Tenant by signing “for
                                and on behalf of” the Landlord and causing the Landlord to be legally bound to the
                                Tenant"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="effectRepairs"
                                label="Effect repairs to and maintain the property, or engage a trades person to do so, in
                                accordance with Item G of this agreement"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="payDisbursements"
                                label="Pay disbursements and expenses incurred in connection with the Agent’s management of
                                the Property, as set out in Item C"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="collectRent"
                                label="Collect rent"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="receive"
                                label="Receive, claim and disburse rental bond money"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="payAccounts"
                                label="Pay accounts for amounts owing in relation to the Property as set out in Item I of
                                the Particulars"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="serveTerminationNotices"
                                label="Serve Termination Notices as required.Any termination notice served by the agent may
                                bring about the early termination of the residential tenancy agreement"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="undertakeTheNecessarySteps"
                                label="Undertake the necessary steps to obtain vacant possession and recover any money
                                owing to the Landlord"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="representTheLandlord"
                                label="Represent the Landlord at the Tribunal"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="advertiseTheProperty"
                                label="Advertise the Property for letting "
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="reviewTheRent"
                                label="Review the rent at the end of the tenancy"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="theRecovery"
                                label="The recovery of monies due"
                            />
                            <CheckboxCheck
                                className="mobile-multi-select-enabled"
                                name="toTerminateTheTenancy"
                                label="To terminate the Tenancy and or seek orders of possession and or specific
                                performance"
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(PmDuties);
