import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Text } from 'react-final-form';

import '../../../sass/fees.scss';
import { FormTextRegular, FormNumber, FormTextCurrency } from '../../../components/form/FormText';
import { editPermissions } from '../../../utils/userPermissions';
import { tabs, HIDE_MESSAGE_TIME } from '../../../config';
import * as pmFees from '../../../actions/pmFees';

class Fees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveStatusTimeout: null
        };
    }
    componentWillUnmount() {
        // clear pending save status timeout
        if (this.state.saveStatusTimeout) {
            clearTimeout(this.state.saveStatusTimeout);
        }
    }

    submit(agencyId, values) {
        // if a timeout handler already exists, clear it
        if (this.state.saveStatusTimeout) {
            clearTimeout(this.state.saveStatusTimeout);
        }
        // create a timeout handler that we'll use to determine whether to show the save result
        this.setState({
            saveStatusTimeout: setTimeout(() => {
                this.setState({ saveStatusTimeout: null });
            }, HIDE_MESSAGE_TIME)
        });
        this.props.updateDefaultPmFeesDetails(agencyId, values);
    }
    getIsPending() {
        return this.props.accountState.UPDATE_PM_FEES_REQUEST_PENDING;
    }

    getIsSaved() {
        return this.props.accountState.UPDATE_PM_FEES_REQUEST_SUCCESS;
    }

    getIsSaveFailed() {
        return this.props.accountState.UPDATE_PM_FEES_REQUEST_FAIL;
    }
    render() {
        /*const isPending = this.props.userState.SAVE_CUSTOM_FEES_REQUEST_PENDING;
        const savedSuccessfully = this.props.userState.SAVE_CUSTOM_FEES_SUCCESS;
        const saveFailed = this.props.userState.SAVE_CUSTOM_FEES_FAIL;*/
        const userRole = this.props.loggedInUser.role;
        const { agency } = this.props;
        const isPending = this.getIsPending();
        const savedSuccessfully = this.getIsSaved();
        const saveFailed = this.getIsSaveFailed();

        const enterForm = (
            <Form
                initialValues={agency.pmFees}
                onSubmit={values => this.submit(agency.id, values)}
                validate={props => {
                    let errors = this.props.validationErrors;
                    if (!errors) {
                        return;
                    }
                    let touched = !errors ? {} : errors.pmFees;
                    return {
                        values: props.values,
                        touched: touched,
                        errors: touched
                    };
                }}
            >
                {({ handleSubmit }) => {
                    return (
                        <div className="flex-row">
                            <div className="fees">
                                <div className="panel">
                                    <h2>Standard default fees</h2>
                                    <div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-content">
                                                <FormNumber
                                                    name="managementFee"
                                                    label="Management fee (inc. GST)"
                                                    required
                                                />
                                                <FormTextRegular
                                                    name="initialLeasingFee"
                                                    label="Initial leasing fee (+GST)"
                                                    required
                                                />
                                                <FormTextRegular
                                                    name="leaseRenewalFee"
                                                    label="Lease renewal fee (+GST)"
                                                    required
                                                />
                                                <FormTextCurrency
                                                    name="adminFee"
                                                    label="Admin fee (+GST)"
                                                    precision="0"
                                                    required
                                                />
                                                <FormTextCurrency
                                                    name="leasePreparationFee"
                                                    label="Lease preparation fee (inc. GST)"
                                                    precision="0"
                                                    required
                                                />
                                            </div>
                                            <div className="footer">
                                                {!isPending &&
                                                    !this.state.saveStatusTimeout &&
                                                    editPermissions(userRole, tabs.TAB_PM_FEES) && (
                                                        <button
                                                            type="submit"
                                                            className="savestatus-button btn-agency-details-save"
                                                        >
                                                            Save changes
                                                        </button>
                                                    )}
                                                {isPending && (
                                                    <span>
                                                        <button
                                                            type="submit"
                                                            disabled="disabled"
                                                            className="savestatus-button btn-agency-details-save"
                                                        >
                                                            <span className="savestatus-spinner" /> Saving changes
                                                        </button>
                                                    </span>
                                                )}
                                                {savedSuccessfully && this.state.saveStatusTimeout && (
                                                    <span className="savestatus-saved">
                                                        Saved
                                                        <span className="icon savestatus-icon" />
                                                    </span>
                                                )}
                                                {saveFailed && this.state.saveStatusTimeout && (
                                                    <span className="savestatus-failed">
                                                        Save failed <span className="icon savestatus-icon" />
                                                    </span>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Form>
        );
        return <div>{enterForm}</div>;
    }
}

export default connect(
    state => ({
        loggedInUser: state.user.userInfo,
        agency: state.account.agency,
        accountState: state.account,
        validationErrors: state.account.validationErrors
    }),
    {
        updateDefaultPmFeesDetails: pmFees.updateDefaultPmFeesDetails
    }
)(Fees);
