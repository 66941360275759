import React, { useState, useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { cloneDeep, has } from 'lodash';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { SelectField } from '../../../../components/form/FormSelect';
import { FormTextRegular } from '../../../../components/form/FormText';
import Warning from '../../../../components/dashboard/warnings/Warning';
import DatePickerInAgencyTimeZone from '../../../../components/form/DatePickerInAgencyTimeZone';
import * as agreementUtils from '../../../../utils/agreementUtils';

import { rentPeriods, termPeriods, PERIOD_MONTHLY } from '../../../../config';
import { calculateMonthlyRent, calculateFinishDate } from '../../../../utils/agreementUtils';
import { useSelector } from 'react-redux';
import FlkSelectBox from '../../../../components/lease/new/FlkSelectBox';
import { calculateEndDateForChange } from '../../../../utils/dateUtils';
import { isValid, addDays, endOfDay } from 'date-fns';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';
import { getUserInfo } from '../../../../selectors/user';
import { isLeaseLocationNSW } from '../../../../utils/agreementUtils';

function RenewalRentForm({ renewal, lease, changeRenewalForm, validationErrors }) {
    const loggedInUser = useSelector(getUserInfo);

    const [rentMonthly, setRentMonthly] = useState('');
    const [newRentMonthly, setNewRentMonthly] = useState('');

    useEffect(() => {
        let endDate = new Date(lease.term.endDate);
        if (!has(lease, 'term.endDate') || !isValid(endDate)) {
            return;
        }

        let updatedData = cloneDeep(renewal);

        // Set the initial value of the term start date to the day after the end date of the previous lease term
        updatedData.term.startDate = addDays(endDate, 1);

        // Update the term end date based on the term start date, duration and period
        updatedData.term.endDate = calculateFinishDate(
            updatedData.term.period,
            updatedData.term.startDate,
            updatedData.term.qty
        );

        //grab data from lease if exists
        if (lease.resTenMeta) {
            updatedData.numberOfBedrooms = lease.resTenMeta.numberOfBedrooms;
            updatedData.numberOfBathrooms = lease.resTenMeta.numberOfBathrooms;
            updatedData.numberOfCarSpaces = lease.resTenMeta.numberOfCarSpaces;
        }

        changeRenewalForm(updatedData);
    }, []);

    function calcRentMonthly(amount, payablePeriod) {
        if (amount === undefined) {
            amount = 0;
        }

        switch (payablePeriod) {
            case PERIOD_MONTHLY: {
                return calculateMonthlyRent(amount);
            }
            default: {
                return amount;
            }
        }
    }

    function handleChange(formState) {
        let values = formState.values;
        changeRenewalForm(formState.values);

        if (!values.rent.unRoundedAmount) {
            values.rent.unRoundedAmount = values.rent.amount;
        }
        if (!values.rent.rentIncreaseList[0].unRoundedNewAmount) {
            values.rent.rentIncreaseList[0].unRoundedNewAmount = values.rent.rentIncreaseList[0].newRentAmount;
        }
        setRentMonthly(calcRentMonthly(values.rent.unRoundedAmount, values.rent.payablePeriod));
        setNewRentMonthly(
            calcRentMonthly(
                values.rent.rentIncreaseList[0].unRoundedNewAmount,
                values.rent.rentIncreaseList[0].newRentPayablePeriod
            )
        );
    }

    function roundDownMonthlyRent() {
        const currentMonthly = Math.ceil(rentMonthly) - 1;
        const rentAmount = agreementUtils.calculateFromMonthlyRent(currentMonthly);
        const updatedForm = {
            values: {
                ...renewal,
                rent: {
                    ...renewal.rent,
                    amount: rentAmount.toFixed(2),
                    unRoundedAmount: rentAmount
                }
            }
        };
        handleChange(updatedForm);
    }

    function roundUpMonthlyRent() {
        const currentMonthly = Math.floor(rentMonthly) + 1;
        const rentAmount = agreementUtils.calculateFromMonthlyRent(currentMonthly);
        const updatedForm = {
            values: {
                ...renewal,
                rent: {
                    ...renewal.rent,
                    amount: rentAmount.toFixed(2),
                    unRoundedAmount: rentAmount
                }
            }
        };
        handleChange(updatedForm);
    }

    function roundUpNewMonthlyRent() {
        const currentMonthly = Math.floor(newRentMonthly) + 1;
        const rentAmount = agreementUtils.calculateFromMonthlyRent(currentMonthly);
        const rentIncreaseList = cloneDeep(renewal.rent.rentIncreaseList);
        rentIncreaseList[0].newRentAmount = rentAmount.toFixed(2);
        rentIncreaseList[0].unRoundedNewAmount = rentAmount;
        const updatedForm = {
            values: {
                ...renewal,
                rent: {
                    ...renewal.rent,
                    rentIncreaseList
                }
            }
        };
        handleChange(updatedForm);
    }

    function roundDownNewMonthlyRent() {
        const currentMonthly = Math.ceil(newRentMonthly) - 1;
        const rentAmount = agreementUtils.calculateFromMonthlyRent(currentMonthly);
        const rentIncreaseList = cloneDeep(renewal.rent.rentIncreaseList);
        rentIncreaseList[0].newRentAmount = rentAmount.toFixed(2);
        rentIncreaseList[0].unRoundedNewAmount = rentAmount;
        const updatedForm = {
            values: {
                ...renewal,
                rent: {
                    ...renewal.rent,
                    rentIncreaseList
                }
            }
        };
        handleChange(updatedForm);
    }

    function syncUnRoundedAmount(e) {
        const updatedForm = {
            values: {
                ...renewal,
                rent: {
                    ...renewal.rent,
                    amount: e.target.value,
                    unRoundedAmount: e.target.value
                }
            }
        };
        changeRenewalForm(updatedForm.values);
    }

    function updateRenewalRentAmount(value) {
        const updatedForm = {
            values: {
                ...renewal,
                rent: {
                    ...renewal.rent,
                    amount: value,
                    unRoundedAmount: value
                }
            }
        };
        changeRenewalForm(updatedForm.values);
    }

    function syncUnRoundedNewAmount(e) {
        const rentIncreaseList = cloneDeep(renewal.rent.rentIncreaseList);
        rentIncreaseList[0].newRentAmount = e.target.value;
        rentIncreaseList[0].unRoundedNewAmount = e.target.value;
        const updatedForm = {
            values: {
                ...renewal,
                rent: {
                    ...renewal.rent,
                    rentIncreaseList
                }
            }
        };
        changeRenewalForm(updatedForm.values);
    }

    const tomorrow = addDays(new Date(), 1);

    return (
        <Form
            onSubmit={() => {}}
            initialValues={renewal}
            validate={() => {
                let errors = validationErrors;
                if (!errors) {
                    return {};
                }
                return errors;
            }}
        >
            {({ handleSubmit, values, errors, form }) => {
                return (
                    <form onSubmit={handleSubmit} className="renewal-form" noValidate>
                        <FormSpy subscription={{ values: true }} onChange={state => handleChange(state)} />
                        <div className="rent-section">
                            <h3>Rent</h3>
                            {lease.rent.amount && (
                                <p className="renewal-form-gray-label effective-from">
                                    The previous rent was{' '}
                                    <span
                                        className="special-link"
                                        onClick={() => updateRenewalRentAmount(lease.rent.amount)}
                                    >
                                        ${lease.rent.amount}
                                    </span>{' '}
                                    {lease.rent?.currentRentPayablePeriod}
                                </p>
                            )}
                            <div className="renewal-form-group">
                                <div className="renewal-form-group-item">
                                    <FormTextRegular
                                        name="rent.amount"
                                        label="Rent per week is"
                                        onChange={e => syncUnRoundedAmount(e)}
                                    />
                                </div>
                                <div className="renewal-form-group-item">
                                    <SelectField label="Payable" name="rent.payablePeriod" options={rentPeriods} />
                                </div>
                            </div>

                            {values.rent.payablePeriod === PERIOD_MONTHLY && !!rentMonthly && (
                                <p className="total-rent">
                                    The monthly rent is
                                    <span className="button-minus" onClick={roundDownMonthlyRent} />
                                    <span>{rentMonthly}</span>
                                    <span className="button-plus" onClick={roundUpMonthlyRent} />
                                </p>
                            )}

                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="Payable in advance starting on:"
                                    name="rent.dateStart"
                                    selected={values.rent.dateStart}
                                />
                            </div>
                            <CheckboxCheck name="rent.bondAlreadyHeld" label="Bond already held?" />
                            <CheckboxCheck
                                name="rent.isRentIncrease"
                                label="There is a rent increase during the term of this lease"
                            />
                            {values.rent.isRentIncrease && (
                                <div>
                                    <div className="renewal-form-group">
                                        <div className="renewal-form-group-item">
                                            <FormTextRegular
                                                name="rent.rentIncreaseList[0].newRentAmount"
                                                label="New rent per week is"
                                                onChange={e => syncUnRoundedNewAmount(e)}
                                            />
                                        </div>
                                        <div className="renewal-form-group-item">
                                            <SelectField
                                                label="Payable"
                                                name="rent.rentIncreaseList[0].newRentPayablePeriod"
                                                options={rentPeriods}
                                            />
                                        </div>
                                    </div>
                                    {values.rent.rentIncreaseList[0].newRentPayablePeriod === PERIOD_MONTHLY &&
                                        !!newRentMonthly && (
                                            <p className="total-rent">
                                                The monthly rent is
                                                <span className="button-minus" onClick={roundDownNewMonthlyRent} />
                                                <span>{newRentMonthly}</span>
                                                <span className="button-plus" onClick={roundUpNewMonthlyRent} />
                                            </p>
                                        )}
                                    <div className="calendar">
                                        <DatePickerInAgencyTimeZone
                                            label="Effective from:"
                                            name="rent.rentIncreaseList[0].newRentDateStart"
                                            selected={values.rent.rentIncreaseList[0].newRentDateStart}
                                            minValue={addDays(values.term.startDate, 1)}
                                        />
                                        {validationErrors &&
                                            validationErrors.rent &&
                                            typeof validationErrors.rent === 'string' && (
                                                <p className="FormInput">
                                                    <span className="FormError">{validationErrors.rent}</span>
                                                </p>
                                            )}
                                    </div>

                                    {endOfDay(values.rent.rentIncreaseList[0].newRentDateStart) <
                                        addDays(tomorrow, 60) && (
                                        <div className="effective-from">
                                            <Warning>
                                                {`This date is less than 60 days
                                                    from today's date. 60 days from now is on the ${formatDateStandard(
                                                        addDays(tomorrow, 60),
                                                        getAgencyTimezoneFromUser(loggedInUser)
                                                    )}.`}
                                            </Warning>
                                        </div>
                                    )}
                                </div>
                            )}
                            {has(errors, 'rent') && typeof errors.rent === 'string' && (
                                <div className="FormInput">
                                    <div className="FormError">{errors.rent}</div>
                                </div>
                            )}
                        </div>
                        <div className="term-section">
                            <h3>Term</h3>
                            <div className="renewal-form-group">
                                <div className="renewal-form-group-item">
                                    <FormTextRegular
                                        label="The term of this agreement is:"
                                        name="term.qty"
                                        onChange={value => {
                                            values.term.qty = value.target.value;
                                            calculateEndDateForChange(values.term, form, 'term.endDate');
                                        }}
                                    />
                                </div>
                                <div className="renewal-form-group-item">
                                    <SelectField
                                        addLabelSpacing
                                        name="term.period"
                                        options={termPeriods}
                                        onChange={value => {
                                            values.term.period = value.value;
                                            calculateEndDateForChange(values.term, form, 'term.endDate');
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="The Lease will start on"
                                    name="term.startDate"
                                    selected={values.term.startDate}
                                    onChange={value => {
                                        values.term.startDate = value;
                                        calculateEndDateForChange(values.term, form, 'term.endDate');

                                        const newRentDateStart = addDays(values.term.startDate, 1);
                                        if (isValid(newRentDateStart)) {
                                            form.change('rent.newRentDateStart', newRentDateStart);
                                        }
                                    }}
                                />
                                {validationErrors &&
                                    validationErrors.term &&
                                    typeof validationErrors.term === 'string' && (
                                        <p className="FormInput">
                                            <span className="FormError"> {validationErrors.term} </span>
                                        </p>
                                    )}
                            </div>
                            <div className="calendar">
                                <DatePickerInAgencyTimeZone
                                    label="and finish date is"
                                    name="term.endDate"
                                    selected={values.term.endDate}
                                />
                            </div>
                            {has(errors, 'term') && typeof errors.term === 'string' && (
                                <div className="FormInput">
                                    <div className="FormError">{errors.term}</div>
                                </div>
                            )}
                        </div>
                        {isLeaseLocationNSW &&
                            lease.materialFacts && ( // TS checking for materialFacts means it would only add this field if it was created after the legislation changes (we introduced material facts)
                                <div className="condition-report-section">
                                    <h3>Condition Report</h3>
                                    <div className="calendar">
                                        <DatePickerInAgencyTimeZone
                                            label="Original condition report date"
                                            name="conditionReportDate"
                                            selected={values.conditionReportDate}
                                        />
                                    </div>
                                </div>
                            )}
                        <div className="new-report-data">
                            <p className="new-report-data-warning">
                                <span className="icon icon-warning"></span>This data below is used for your reporting
                                only and is NOT entered onto your agreement
                            </p>
                            <FlkSelectBox
                                noOfBoxes={4}
                                firstBox={'S'}
                                title={'No. of bedrooms'}
                                setValue={data => {
                                    form.change('numberOfBedrooms', data);
                                }}
                                selected={values.numberOfBedrooms}
                            />
                            {validationErrors && validationErrors.numberOfBedrooms && (
                                <p className="FormInput">
                                    <span className="FormError">{validationErrors.numberOfBedrooms}</span>
                                </p>
                            )}

                            <FlkSelectBox
                                noOfBoxes={5}
                                title={'No. of bathrooms'}
                                setValue={data => {
                                    form.change('numberOfBathrooms', data);
                                }}
                                selected={values.numberOfBathrooms}
                            />
                            {validationErrors && validationErrors.numberOfBathrooms && (
                                <p className="FormInput">
                                    <span className="FormError">{validationErrors.numberOfBathrooms}</span>
                                </p>
                            )}

                            <FlkSelectBox
                                noOfBoxes={4}
                                firstBox={'0'}
                                title={'No. of car spaces'}
                                setValue={data => {
                                    form.change('numberOfCarSpaces', data);
                                }}
                                selected={values.numberOfCarSpaces}
                            />
                            {validationErrors && validationErrors.numberOfCarSpaces && (
                                <p className="FormInput">
                                    <span className="FormError">{validationErrors.numberOfCarSpaces}</span>
                                </p>
                            )}
                        </div>
                    </form>
                );
            }}
        </Form>
    );
}

export default RenewalRentForm;
