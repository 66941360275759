import React from 'react';
import Listen from '../buildADoc/components/Listen';
import { useFieldArray } from 'react-final-form-arrays';
import { CustomPlaceholder } from '@app/types/UploadADoc';

type DeactivateQrCodeListenersProps = { onChange: () => void };

const DeactivateQrCodeListeners: React.FC<DeactivateQrCodeListenersProps> = ({ onChange }) => {
    const customPlaceholderFields = useFieldArray<CustomPlaceholder>('customPlaceholders');
    const prevCustomPlaceholderFieldsLength = React.useRef<number>();

    React.useEffect(() => {
        if (
            prevCustomPlaceholderFieldsLength.current !== undefined &&
            prevCustomPlaceholderFieldsLength.current !== customPlaceholderFields.fields.length
        ) {
            onChange();
        }
        prevCustomPlaceholderFieldsLength.current = customPlaceholderFields.fields.length;
    }, [customPlaceholderFields.fields.length, onChange]);

    return (
        <>
            <Listen to="confirmationType" onChange={onChange} activeOnly={false} />
            <Listen to="includeDescriptionTextInEmail" onChange={onChange} />
            {customPlaceholderFields.fields.map(fieldName => (
                <>
                    <Listen to={`${fieldName}.value`} onChange={onChange} />
                    <Listen to={`${fieldName}.respondentType`} onChange={onChange} />
                    <Listen to={`${fieldName}.label`} onChange={onChange} />
                </>
            ))}
            <Listen to="descriptionText" onChange={onChange} />
            <Listen to="includeDescriptionTextInEmail" onChange={onChange} />
            <Listen to="acknowledgementText" onChange={onChange} />
        </>
    );
};

export default DeactivateQrCodeListeners;
