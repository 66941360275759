import axios from 'axios';

import * as Lease from '../../reducers/lease';

export function createPmLease(data) {
    return {
        types: [Lease.CREATE_PM_LEASE_REQUEST, Lease.CREATE_PM_LEASE_SUCCESS, Lease.CREATE_PM_LEASE_FAILURE],
        promise: axios.post('/api/agency/lease', data)
    };
}
