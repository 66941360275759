import React from 'react';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';

import LeaseRenewalStart from './LeaseRenewalStart';
import LeaseRenewalForm from './LeaseRenewalForm';

import '../../../sass/leaseRenewalModal.scss';

class LeaseRenewalModal extends React.Component {
    startRenewal = values => {
        const { startRenewal, agreementInfo } = this.props;
        startRenewal(agreementInfo.id, values);
    };
    rejectRenewal = () => {
        const { rejectRenewal, agreementInfo } = this.props;
        rejectRenewal(agreementInfo.id);
    };

    cancelRenewal = () => {
        const { rejectRenewal, agreementInfo } = this.props;

        confirmAlert({
            title: '',
            message:
                'This action will cancel the renewal of the lease, and you will not be able to renew the lease in the future. Do you want to continue?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        rejectRenewal(agreementInfo.id);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    closeLeaseRenewalModal = () => {
        const { closeLeaseRenewalModal, resetStartRenewal } = this.props;

        resetStartRenewal();
        closeLeaseRenewalModal();
    };

    render() {
        const {
            closeLeaseRenewalModal,
            agreementInfo,
            activeStepNumber,
            isOpenLeaseRenewalModal,
            success,
            error,
            errorCode,
            isStartingRenewal,
            isCancellingRenewal
        } = this.props;

        return (
            <Modal
                parentSelector={() => document.getElementById('main-layout')}
                ariaHideApp={false}
                isOpen={isOpenLeaseRenewalModal}
                className={!activeStepNumber ? 'modal-lease-renewal start' : 'modal-lease-renewal'}
                contentLabel="Modal"
                style={style}
            >
                {!activeStepNumber && (success || errorCode === 4001) && (
                    <LeaseRenewalStart
                        agreementInfo={agreementInfo}
                        closeModal={closeLeaseRenewalModal}
                        startRenewal={this.startRenewal}
                        cancelRenewal={this.cancelRenewal}
                        isStartingRenewal={isStartingRenewal}
                        isCancellingRenewal={isCancellingRenewal}
                    />
                )}
                {activeStepNumber > 0 && (success || errorCode === 4001) && (
                    <LeaseRenewalForm closeModal={this.closeLeaseRenewalModal} {...this.props} />
                )}
                {!success && errorCode !== 4001 && (
                    <div className="renewal-info">
                        <h2>{error}</h2>
                        <span className="cancel" onClick={this.rejectRenewal}>
                            Cancel renewal
                        </span>
                    </div>
                )}
            </Modal>
        );
    }
}

const style = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(148, 157, 176, 0.7)',
        zIndex: 5,
        display: 'flex',
        overflow: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '50px 0'
    }
};

export default LeaseRenewalModal;
