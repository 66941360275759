import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { each, find, isEmpty } from 'lodash';
import { updateAgreement, resolveRequest, unResolveRequest, setActiveRequest } from '../../actions/lease';
import { openAgreementInfoModal, closeSigning } from '../../actions/dashboard';
import { steps } from '../../components/lease/steps';
import SideBarLease from '../../components/lease/SideBarLease';
import FooterLease from '../../components/lease/FooterLease';
import AgreementHeader from '../../components/dashboard/InfoAgreementComponents/AgreementHeader';
import { hideForm, loadLease, goToRequestChangeStep } from '../../reducers/leaseWithRequest';
import ChangeRequestToolbar from '../../components/lease/ChangeRequestToolbar';
import Address from '../../containers/dashboard/lease/Address';
import '../../sass/leaseAgreementWithRequest.scss';
import { isLeaseTypeResidentialTenancy, allRequestsResolved, getUpdateObject } from '../../utils/agreementUtils';
import * as agreementUtils from '../../utils/agreementUtils';
import { useRef } from 'react';
import {
    getLeaseId,
    getStep,
    getLeaseType,
    getLocation,
    getLeaseAllInfo,
    getValidationErrors,
    getIsSubmittingLease,
    getLease
} from '../../selectors/lease';
import { getActiveRequest, getLeaseWithRequestsStep } from '../../selectors/lease/leaseWithRequest';
import { getAddresses as addressesSelector } from '../../selectors/lease/addresses';
import { getIsServerBusy } from '../../selectors/serverRequests';
import * as lease from '../../actions/lease';
import { confirmAlert } from 'react-confirm-alert';

const LeaseAgreementWithRequestsForm = () => {
    const childStepRef = useRef();

    const dispatch = useDispatch();

    const leaseWithRequestStep = useSelector(getLeaseWithRequestsStep);
    const leaseId = useSelector(getLeaseId);
    const step = useSelector(getStep);
    const leaseType = useSelector(getLeaseType);
    const location = useSelector(getLocation);
    const addresses = useSelector(addressesSelector);
    const isServerBusy = useSelector(getIsServerBusy);
    const reduxLease = useSelector(getLease);
    const leaseAllInfo = useSelector(getLeaseAllInfo);
    const validationErrors = useSelector(getValidationErrors);
    const activeLeaseWithRequest = useSelector(getActiveRequest);
    const isSubmittingLease = useSelector(getIsSubmittingLease);

    useEffect(() => {
        dispatch(loadLease(leaseAllInfo));
    }, []);

    useEffect(() => {
        if (validationErrors) {
            const key = agreementUtils.getLeaseTypeForFrontEnd(leaseAllInfo.leaseType);
            let appPart = find(steps[key][leaseAllInfo.subLeaseType][location], { step: step });
            // match appPart.part with index of steps in change request menu
            const stepConfig = getStepConfig();
            for (let i = 0; i < stepConfig.length; i++) {
                let step = stepConfig[i];
                if (appPart?.part.indexOf(step?.part) !== -1) {
                    dispatch(goToRequestChangeStep(i));
                }
            }
        }
    }, [step]);

    const saveStep = (step, currentStep) => {
        dispatch(lease.setSideMenuStep(step)).then(() => {
            if (childStepRef.current) {
                childStepRef.current.bypassFormValidation = true;
                childStepRef.current.callbackAfterSubmit = () => {
                    dispatch(goToRequestChangeStep(step - 2));
                };
                childStepRef.current.submitStep();
            } else {
                if (currentStep.part === 'documents') {
                    dispatch(lease.setDocuments(leaseId, reduxLease['documents'], null, true));
                    dispatch(goToRequestChangeStep(step - 2));
                } else {
                    dispatch(goToRequestChangeStep(step - 2));
                }
            }
        });
    };

    const renderCurrentStepWithForwardRef = (content, step, leaseId) => {
        return React.createElement(content, {
            leaseId,
            ref: childStepRef
        });
    };

    const isLeaseComplete = () => {
        return agreementUtils.isCompleted(leaseAllInfo, leaseType, location);
    };

    const confirmRequestsResolvedAndFinishEditing = () => {
        if (!_allRequestsResolved()) {
            confirmAlert({
                title: '',
                message:
                    'You must resolve all change requests before you finish editing, Click resolve in right hand column.',
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            });
            return;
        }

        finishEditing();
    };

    const _allRequestsResolved = () => {
        const changeRequestList = leaseAllInfo.changeRequestList;
        return allRequestsResolved(changeRequestList);
    };

    const finishEditing = () => {
        if (childStepRef.current) {
            childStepRef.current.callbackAfterSubmit = () => {
                const data = getUpdateObject(reduxLease, true);
                dispatch(updateAgreement(leaseId, data));
            };

            childStepRef.current.submitStep();
        } else {
            //This is an address or document update
            const data = getUpdateObject(reduxLease, true);
            dispatch(updateAgreement(leaseId, data));
        }
    };

    const getStepConfig = () => {
        const { changeRequestList } = leaseAllInfo;
        if (!changeRequestList) {
            return [];
        }

        let stepConfig = [];

        // For each change request sent by the mobile app
        each(changeRequestList, value => {
            value.leaseAgreementStep.part.forEach(part => {
                let mobileStepPart = part;

                // Find the value in desktop app where part is equal to the mobile part
                // Checking on part name instead of step number, to be more precise.
                const key = agreementUtils.getLeaseTypeForFrontEnd(leaseAllInfo.leaseType);
                let appPart = find(steps[key][leaseAllInfo.subLeaseType][location], {
                    part: mobileStepPart
                });

                // since address step only exist on mobile app, we have to add it manually to the desktop agreement.
                if (appPart) {
                    stepConfig.push(appPart);
                } else if (!appPart && mobileStepPart === 'address') {
                    stepConfig.push({ title: 'Address', content: <Address />, part: 'address' });
                }
            });
        });

        return stepConfig;
    };

    const getContent = () => {
        let stepConfig = getStepConfig();
        if (stepConfig.length && stepConfig[leaseWithRequestStep]) {
            if (stepConfig[leaseWithRequestStep].title === 'Address') {
                return stepConfig[leaseWithRequestStep].content;
            } else if (stepConfig[leaseWithRequestStep].title === 'Documents/Links') {
                return stepConfig[leaseWithRequestStep].content;
            } else {
                return renderCurrentStepWithForwardRef(
                    stepConfig[leaseWithRequestStep].content,
                    leaseWithRequestStep,
                    leaseId
                );
            }
        }

        return '';
    };

    const _setActiveRequest = (leaseId, newStep) => {
        dispatch(setActiveRequest(leaseId, newStep));
    };

    const _goToStep = step => {
        dispatch(goToRequestChangeStep(step - 2));
    };

    const _resolveRequest = changeRequest => {
        dispatch(resolveRequest(changeRequest));
    };

    const _unResolveRequest = changeRequest => {
        dispatch(unResolveRequest(changeRequest));
    };

    const getCurrentStep = () => {
        let stepConfig = getStepConfig();
        if (null === activeLeaseWithRequest) {
            return stepConfig[leaseWithRequestStep];
        }

        for (let i = 0; i < stepConfig.length; i++) {
            let step = stepConfig[i];
            if (activeLeaseWithRequest.leaseAgreementStep.part.indexOf(step.part) !== -1) {
                _goToStep(i + 2);
                return step;
            }
        }
        return stepConfig[0];
    };

    const selectChangeRequest = request => {
        let stepConfig = getStepConfig();
        for (let i = 0; i < stepConfig.length; i++) {
            let step = stepConfig[i];
            if (-1 !== request.leaseAgreementStep.part.indexOf(step.part)) {
                dispatch(goToRequestChangeStep(i));
            }
        }
    };

    const hideChangeRequestForm = () => {
        dispatch(hideForm());
        dispatch(openAgreementInfoModal(leaseAllInfo));
    };

    // Why would this be empty?
    // The modal should be closed at this point
    if (isEmpty(leaseAllInfo)) {
        return null;
    }

    return (
        <Modal
            parentSelector={() => document.getElementById('main-layout')}
            isOpen={true}
            style={customstyle}
            className="modal-edit-lease"
            contentLabel="Modal"
            ariaHideApp={false}
        >
            <div className="edit-lease">
                <div className="content-create">
                    <AgreementHeader
                        leaseAllInfo={leaseAllInfo}
                        closeAgreementInfoModal={() => dispatch(hideForm())}
                        cancelSigning={() => dispatch(closeSigning())}
                        addresses={addresses}
                        showChangeRequestForm={() => hideChangeRequestForm()}
                    />
                    <div className="content-lease">
                        <SideBarLease
                            goToStep={step => _goToStep(step)}
                            saveStep={saveStep}
                            step={leaseWithRequestStep + 2}
                            items={getStepConfig()}
                            validationErrors={validationErrors}
                            lease={leaseAllInfo}
                            isLeaseComplete={isLeaseComplete}
                            requests={leaseAllInfo.changeRequestList}
                            activeRequest={activeLeaseWithRequest}
                            setActiveRequest={_setActiveRequest}
                            isOpenRequestsToChangeForm={true}
                        />
                        <main className="main">{getContent()}</main>
                    </div>
                    <FooterLease
                        step={getCurrentStep()}
                        isEdit={true}
                        finishEditing={confirmRequestsResolvedAndFinishEditing}
                        isServerBusy={isServerBusy}
                        leaseType={leaseType}
                        subLeaseType={leaseAllInfo.subLeaseType}
                        isSubmittingLease={isSubmittingLease}
                        location={location}
                    />
                </div>
            </div>
            <ChangeRequestToolbar
                step={getCurrentStep()}
                select={selectChangeRequest}
                changeRequestList={leaseAllInfo.changeRequestList}
                resolveRequest={_resolveRequest}
                unResolveRequest={_unResolveRequest}
            />
        </Modal>
    );
};

export default memo(LeaseAgreementWithRequestsForm);

const customstyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(148, 157, 176, 0.7)',
        zIndex: 5,
        display: 'flex',
        overflow: 'auto',
        justifyContent: 'center',
        padding: '50px 0'
    }
};
