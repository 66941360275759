import React from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import cx from 'classnames';
import { format } from 'date-fns';

import { createFreeTextAnnotation } from '../utils';
import { Point } from '../types';
import { STANDARD_DATE_FORMAT } from '../../../config';
import Button from '../../../common/components/Button.js';
import Icon, { Icons } from '../../../common/components/Icon';
import { DOCUMENTS_DROPDOWN } from '../../../constants/featureFlags';
import {
    ANNOTATION_CONTENT_COMPLETION_DATE,
    ANNOTATION_FREE_TEXT_DEFAULT_VALUE,
    ANNOTATION_SUBJECT_COMPLETION_DATE,
    ANNOTATION_SUBJECT_FREE_TEXT
} from '../../../constants/constants';
import AnnotationButton, { AnnotationButtonTypes } from './AnnotationButton';
import { UploadADoc } from '../../../types/UploadADoc';
import UploadedDocumentsDropdown from './UploadedDocumentsDropdown';
import { UploadedDocumentState } from './useUploadedDocumentState';

import styles from './TopBar.module.scss';

type TopBarProps = {
    className?: string;
    webViewerInstance?: WebViewerInstance;
    saveDocument: () => void;
    isSaving: boolean;
    dropPointRef: React.MutableRefObject<Point | undefined>;
    customDoc: UploadADoc;
    uploadedDocumentStateItems: UploadedDocumentState[];
    selectedUploadedDocId?: string;
    onClickDocument: (documentId: string) => void;
};

const TopBar: React.FC<TopBarProps> = ({
    saveDocument,
    webViewerInstance,
    isSaving,
    dropPointRef,
    customDoc,
    uploadedDocumentStateItems,
    selectedUploadedDocId,
    onClickDocument,
    className
}) => {
    const isDocumentsDropdownActive = useFeatureFlag(DOCUMENTS_DROPDOWN);
    const annotationHistoryManager = webViewerInstance?.Core.documentViewer.getAnnotationHistoryManager();

    const [canUndo, setCanUndo] = React.useState(annotationHistoryManager?.canUndo());
    const [canRedo, setCanRedo] = React.useState(annotationHistoryManager?.canRedo());

    React.useEffect(() => {
        const updateUndoRedo = () => {
            setCanUndo(annotationHistoryManager?.canUndo());
            setCanRedo(annotationHistoryManager?.canRedo());
        };

        annotationHistoryManager?.addEventListener('historyChanged', updateUndoRedo);

        return () => {
            annotationHistoryManager?.removeEventListener('historyChanged', updateUndoRedo);
        };
    }, [annotationHistoryManager]);

    // TODO (VA) refine zoom buttons
    return (
        <div className={cx(styles.topBar, className)}>
            <div className={styles.startButtons}>
                <Button
                    className={cx(styles.panelButton, styles.button)}
                    onClick={() => {
                        if (webViewerInstance?.UI.isElementOpen('leftPanel')) {
                            webViewerInstance.UI.closeElements(['leftPanel']);
                        } else {
                            webViewerInstance?.UI.openElements(['leftPanel']);
                        }
                    }}
                    startIcon={<Icon className={styles.icon} icon={Icons.PAGES} />}
                >
                    Pages
                </Button>
                {isDocumentsDropdownActive && (
                    <UploadedDocumentsDropdown
                        customDoc={customDoc}
                        uploadedDocumentStateItems={uploadedDocumentStateItems}
                        selectedUploadedDocId={selectedUploadedDocId}
                        onClickDocument={onClickDocument}
                        triggerClassName={cx(styles.documentsButton, styles.button)}
                    />
                )}
            </div>
            <div className={styles.centerButtons}>
                <div className={styles.buttonGroup}>
                    <Button
                        className={styles.iconButton}
                        onClick={() => {
                            webViewerInstance?.UI.setZoomLevel(webViewerInstance.UI.getZoomLevel() - 0.1);
                        }}
                    >
                        <Icon className={styles.icon} icon={Icons.MINUS} />
                    </Button>
                    <Button
                        className={styles.iconButton}
                        onClick={() => {
                            webViewerInstance?.UI.setZoomLevel(webViewerInstance.UI.getZoomLevel() + 0.1);
                        }}
                    >
                        <Icon className={styles.icon} icon={Icons.PLUS} />
                    </Button>
                </div>
                <div className={styles.buttonGroup}>
                    <Button
                        className={styles.iconButton}
                        disabled={!canUndo}
                        onClick={() => {
                            if (annotationHistoryManager?.canUndo()) {
                                annotationHistoryManager.undo();
                            }
                        }}
                    >
                        <Icon className={styles.icon} icon={Icons.UNDO} />
                    </Button>
                    <Button
                        className={styles.iconButton}
                        disabled={!canRedo}
                        onClick={() => {
                            if (annotationHistoryManager?.canRedo()) {
                                annotationHistoryManager.redo();
                            }
                        }}
                    >
                        <Icon className={styles.icon} icon={Icons.REDO} />
                    </Button>
                </div>
                <AnnotationButton
                    webViewerInstance={webViewerInstance}
                    dropPointRef={dropPointRef}
                    createAnnotation={(dropPoint, options) => {
                        if (webViewerInstance) {
                            createFreeTextAnnotation(
                                ANNOTATION_FREE_TEXT_DEFAULT_VALUE,
                                ANNOTATION_SUBJECT_FREE_TEXT,
                                false,
                                webViewerInstance,
                                dropPoint,
                                options
                            );
                        }
                    }}
                    icon={<Icon className={styles.icon} icon={Icons.FREE_TEXT} />}
                    type={AnnotationButtonTypes.TOP_BAR}
                    dragImageText={ANNOTATION_FREE_TEXT_DEFAULT_VALUE}
                >
                    Free text
                </AnnotationButton>
                <AnnotationButton
                    webViewerInstance={webViewerInstance}
                    dropPointRef={dropPointRef}
                    createAnnotation={(dropPoint, options) => {
                        if (webViewerInstance) {
                            createFreeTextAnnotation(
                                format(new Date(), STANDARD_DATE_FORMAT),
                                ANNOTATION_SUBJECT_FREE_TEXT,
                                true,
                                webViewerInstance,
                                dropPoint,
                                options
                            );
                        }
                    }}
                    icon={<Icon className={styles.icon} icon={Icons.DATE} />}
                    type={AnnotationButtonTypes.TOP_BAR}
                    dragImageText={format(new Date(), STANDARD_DATE_FORMAT)}
                >
                    Today's Date
                </AnnotationButton>
                <AnnotationButton
                    webViewerInstance={webViewerInstance}
                    dropPointRef={dropPointRef}
                    createAnnotation={(dropPoint, options) => {
                        if (webViewerInstance) {
                            createFreeTextAnnotation(
                                ANNOTATION_CONTENT_COMPLETION_DATE,
                                ANNOTATION_SUBJECT_COMPLETION_DATE,
                                true,
                                webViewerInstance,
                                dropPoint,
                                options
                            );
                        }
                    }}
                    icon={<Icon className={styles.icon} icon={Icons.DATE} />}
                    type={AnnotationButtonTypes.TOP_BAR}
                    dragImageText={ANNOTATION_CONTENT_COMPLETION_DATE}
                >
                    Completion Date
                </AnnotationButton>
            </div>
            <div className={styles.endButtons}>
                <Button
                    className={styles.doneButton}
                    onClick={saveDocument}
                    id={'uad-onboarding-save-pdf'}
                    endIcon={
                        isSaving ? (
                            <div className={styles.spinner} />
                        ) : (
                            <Icon className={styles.icon} icon={Icons.CHEVRON_RIGHT} />
                        )
                    }
                    disabled={isSaving}
                >
                    {isSaving ? 'Saving...' : 'Done'}
                </Button>
            </div>
        </div>
    );
};

export default TopBar;
