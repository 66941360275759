import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateSuggestion } from '../actions/lease';

interface SuggestionsData {
    isLoading: boolean;
    error: Error | null;
    suggestions: string | string[] | null;
}

/**
 * Custom hook for fetching suggestions data from the API based on the integrationId and updating the Redux store with the fetched data.
 * @param integrationId - The ID of the integration to fetch suggestions for
 * @returns isLoading, error, and suggestions
 */
const useFetchSuggestions = (integrationId: string | undefined): SuggestionsData => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [suggestions, setSuggestions] = useState<string | string[] | null>(null);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/integrations/suggestions/${integrationId}`);
            // Dispatch action to update suggestions in Redux store
            dispatch(updateSuggestion(response.data.suggestions));
            setSuggestions(response.data.suggestions);
        } catch (error) {
            setError(error instanceof Error ? error : new Error('An error occurred while fetching suggestions.'));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (integrationId) {
            fetchData(); // Fetch data only if integrationId is defined
        }
    }, [integrationId]);

    return { isLoading, error, suggestions };
};

export default useFetchSuggestions;
