import React from 'react';
import '../../../sass/dashboardContentTable.scss';
import CustomDocument from './tables/custom/CustomDocument';
import * as dashboard from '../../../actions/dashboard';
import CustomDocumentSentForSigning from './tables/custom/CustomDocumentSentForSigning';
import CustomDocumentAwaitingCompletion from './tables/custom/CustomDocumentAwaitingCompletion';
import CustomDocumentCompleted from './tables/custom/CustomDocumentCompleted';

const CustomDocumentListings = ({ documentList, docType, docStatus }) => {
    let contentTable;

    switch (docStatus) {
        case dashboard.LEASE_STATUS_DRAFT: {
            contentTable = <CustomDocument documentList={documentList} docType={docType} />;
            break;
        }
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            contentTable = <CustomDocumentSentForSigning documentList={documentList} docType={docType} />;
            break;
        }
        case dashboard.LEASE_STATUS_AWAITING_COMPLETION: {
            contentTable = <CustomDocumentAwaitingCompletion documentList={documentList} docType={docType} />;
            break;
        }
        case dashboard.LEASE_STATUS_COMPLETE: {
            contentTable = <CustomDocumentCompleted documentList={documentList} docType={docType} />;
            break;
        }
    }

    return <div className="table-sort">{contentTable}</div>;
};

export default CustomDocumentListings;
