import React from 'react';
import '../../../sass/dashboardContentTable.scss';

import { Table, Th, Tr, Thead, Td } from 'reactable';
import AgentLogo from '../../AgentLogo';
import { useDispatch, useSelector } from 'react-redux';
import { openKeyForm, changeSelectedKey } from '../../../actions/flkAKey';
import { DOCUMENT_READ_ONLY_MODE } from '../../../config';
import FlkAKeyMoreOptionBlock from './cells/FlkAKeyMoreOptionBlock';
import { getSelectedKeys } from '../../../selectors/flkAKey';
import KeyReminderIndicator from './cells/KeyReminderIndicator';
import OpenReminderHistoryButton from '../../../containers/dashboard/flkAKey/OpenReminderHistoryButton';
import { differenceInHours } from 'date-fns';

const CheckedOutAndOverdueTable = ({ flkAKeyList, isOverdue = false }) => {
    const dispatch = useDispatch();

    const selectedKeys = useSelector(getSelectedKeys);

    function openKeyFormView(event, key) {
        event.stopPropagation();
        dispatch(openKeyForm(key, DOCUMENT_READ_ONLY_MODE));
    }

    function selectKey(event, key) {
        event.stopPropagation();
        dispatch(changeSelectedKey(key, event.target.checked));
    }

    const getChecked = flkAKey => {
        return selectedKeys.includes(flkAKey.id);
    };

    const getOverdueTime = overdueTime => {
        let text = overdueTime + 'h';
        if (overdueTime > 24) {
            if (overdueTime % 24 === 0) {
                text = `${Math.floor(overdueTime / 24)}d`;
            } else {
                text = `${Math.floor(overdueTime / 24)}d ${overdueTime % 24}h`;
            }
        }

        return text;
    };

    return (
        <Table>
            <Thead>
                <Th column="select">&nbsp;</Th>
                <Th column="agentLogo">AGENT</Th>
                <Th column="key">KEY#</Th>
                <Th column="address">ADDRESS</Th>
                <Th column="dueDate">Return Due Date</Th>
                <Th column="company">Company</Th>
                <Th column="contact">Contact</Th>
                {isOverdue && <Th column="status">Status</Th>}
                <Th column="action">&nbsp;</Th>
            </Thead>
            {Array.isArray(flkAKeyList) &&
                flkAKeyList.map((flkAKey, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openKeyFormView(event, flkAKey);
                            }}
                        >
                            <Td column="select">
                                <input
                                    className="checkbox-select"
                                    type="checkbox"
                                    onClick={e => selectKey(e, flkAKey)}
                                    checked={getChecked(flkAKey)}
                                />
                            </Td>
                            <Td column="agentLogo">
                                <AgentLogo agent={flkAKey.agent} />
                            </Td>
                            <Td column="key">
                                <b>{flkAKey.key}</b>
                            </Td>
                            <Td column="address">
                                <b>{flkAKey.address}</b>
                            </Td>
                            <Td column="dueDate">
                                <div>
                                    <b>{flkAKey.dueDateFormatted}</b>
                                    {isOverdue && <b> - {getOverdueTime(flkAKey.overdueTime)}</b>}
                                </div>
                            </Td>
                            <Td column="company">
                                <b>{flkAKey.company}</b>
                            </Td>
                            <Td column="contact">
                                <b>{flkAKey.name}</b>
                            </Td>
                            {isOverdue && (
                                <Td column="status">
                                    <div className="table-reminder-group">
                                        <KeyReminderIndicator
                                            reminder={
                                                flkAKey.reminders && flkAKey.reminders.length > 0
                                                    ? flkAKey.reminders[flkAKey.reminders.length - 1]
                                                    : null
                                            }
                                        />
                                        <OpenReminderHistoryButton flkAKey={flkAKey} isEyeIcon={true} />
                                    </div>
                                </Td>
                            )}
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <FlkAKeyMoreOptionBlock flkAKey={flkAKey} />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default CheckedOutAndOverdueTable;
