import React from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Button from '../../../../../common/components/Button.js';
import Icon, { Icons } from '../../../../../common/components/Icon';

import '../../../../../sass/documentModalDialog.scss';
import { FormModel, generateNewPrincipal } from '../EstimatedSellingPriceForm.model';
import FormSection from '../../../../../components/form/FormSection';
import Principal from './Principal';

import styles from './PrincipalSection.module.scss';

type PrincipalSectionProps = {
    isReadOnly?: boolean;
};

const PrincipalSection: React.FC<PrincipalSectionProps> = ({ isReadOnly }) => {
    const {
        mutators: { push }
    } = useForm<FormModel>();
    return (
        <>
            <FieldArray name="principals">
                {({ fields }) => (
                    <React.Fragment>
                        {fields.map((name, index) => {
                            return (
                                <FormSection
                                    key={index}
                                    title={`Principal (Vendor) ${index + 1}`}
                                    showRemoveButton={index > 0}
                                    removeButtonProps={{
                                        onClick: () => fields.remove(index),
                                        label: 'Remove',
                                        loading: false
                                    }}
                                >
                                    <Principal
                                        index={`${index}`}
                                        principal={fields.value[index]}
                                        isReadOnly={isReadOnly}
                                    />
                                </FormSection>
                            );
                        })}
                    </React.Fragment>
                )}
            </FieldArray>
            <Button
                className={styles.addPrincipalButton}
                type="button"
                onClick={() => {
                    push('principals', generateNewPrincipal());
                }}
                tertiary
                startIcon={<Icon icon={Icons.PLUS} className={styles.addPrincipalIcon} />}
                disabled={isReadOnly}
            >
                Add principal (vendor)
            </Button>
        </>
    );
};

export default PrincipalSection;
