import React, { memo, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getPmRentAndTerm } from '../../../selectors/lease/pmLease';

import {
    DEFAULT_NUMBER_OF_WEEKS_FOR_PROPERTY_MANAGEMENT_BOND,
    NSW_STATE,
    PERIOD_WEEK,
    propertyManagementRentPeriods
} from '../../../config';
import { FormNumber, FormTextRegular } from '../../../components/form/FormText';
import { FormResponsiveNumbers } from '../../../components/form/responsive/FormResponsiveNumbers';
import { FormButtonSelect } from '../../../components/form/responsive/FormButtonSelect';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';

import '../../../sass/management/rentAndTerm.scss';
import { ToggleButton } from '../../../components/form/FormToggleButton';

const PM_RENT_TERM_FORM = 'pmRentAndTermForm';
const initState = {
    lengthOfTerm: {
        notLessThan: null,
        notMoreThan: null
    },
    useMarketRate: false,
    rent: null,
    payablePer: PERIOD_WEEK,
    numberOfWeeksForRentalBond: DEFAULT_NUMBER_OF_WEEKS_FOR_PROPERTY_MANAGEMENT_BOND,
    agentPrepareInventoryList: 'true'
};

const RentTerm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmRentAndTerm = useSelector(getPmRentAndTerm);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmRentAndTerm, setPmRentAndTerm] = useState(reduxPmRentAndTerm || initState);

    useEffect(() => {
        if (reduxPmRentAndTerm) {
            setPmRentAndTerm({
                ...reduxPmRentAndTerm,
                agentPrepareInventoryList: reduxPmRentAndTerm.agentPrepareInventoryList ? 'true' : 'false'
            });
        }
    }, [reduxPmRentAndTerm]);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_RENT_TERM_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmRentAndTerm(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmRentAndTerm')) {
                    return error.response.data.errors.pmRentAndTerm;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmRentAndTerm = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-rent-and-term`, data);
    };

    return (
        <div className="form-container rent rent-term">
            <Form onSubmit={submitForm} initialValues={pmRentAndTerm}>
                {({ handleSubmit, values, form }) => {
                    const { useMarketRate } = values;
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_RENT_TERM_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            {location === NSW_STATE && (
                                <React.Fragment>
                                    <div className="form-section">
                                        <h3>Length of term in weeks</h3>
                                        <div className="formBox">
                                            <FormNumber
                                                name="lengthOfTerm.notLessThan"
                                                label="Not less than"
                                                required
                                            />
                                            <FormNumber
                                                name="lengthOfTerm.notMoreThan"
                                                label="Not more than"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-section">
                                        <h3>Rent and bond</h3>
                                        <div className="formBox">
                                            <div className="nowrap fields-full-width-for-mobile">
                                                <FormTextRegular
                                                    name="rent"
                                                    label="The market rent is"
                                                    required
                                                    disabled={useMarketRate}
                                                />
                                                <FormButtonSelect
                                                    name="payablePer"
                                                    options={propertyManagementRentPeriods}
                                                    disabled={useMarketRate}
                                                    value={values.payablePer}
                                                    label="Payable per"
                                                />
                                            </div>
                                            <FormResponsiveNumbers
                                                name="numberOfWeeksForRentalBond"
                                                label="Number of weeks for rental bond"
                                                options={[2, 3, 4]}
                                                otherLabel="Other"
                                                required
                                                disabled={useMarketRate}
                                                form={form}
                                            />
                                        </div>
                                    </div>
                                    <ToggleButton name="isPropertyFurnished" label="The property is furnished" />
                                    {values.isPropertyFurnished && (
                                        <FormRadioGroup
                                            label="Who will prepare the inventory list for the property"
                                            name={'agentPrepareInventoryList'}
                                            value={values.agentPrepareInventoryList}
                                            data={[
                                                {
                                                    label: 'Agent',
                                                    value: 'true'
                                                },
                                                {
                                                    label: 'Landlord',
                                                    value: 'false'
                                                }
                                            ]}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(RentTerm);
