export enum EspApi {
    Save = 'api/document/esp-notice',
    Send = 'api/document/esp-notice/send',
    UploadApi = 'api/document/upload-esp-documents'
};

export const ESP_API = 'api/document/esp-notice';

export const AGENT_ATTACHED = 'agent_attached';

export const EMAIL = 'email';