import React from 'react';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getSalesStepNo, getSalesStepTitle } from '../../../../../../utils/agreementUtils';
import DataString from '../../bloks/DataString';

export default function AgreementSalesPropertyDescription({ leaseAllInfo }) {
    const partName = 'propertyDescription';
    const { propertyDescription, location } = leaseAllInfo;

    if (!propertyDescription) {
        return <></>;
    }

    return (
        <AgreementSection
            title={getSalesStepTitle(location, partName)}
            step={getSalesStepNo(location, partName)}
            leaseAllInfo={leaseAllInfo}
        >
            <div className="data data-row">
                <DataString title={'Summary of property:'} text={propertyDescription.summaryOfProperty} />
                <DataString title={'Features:'} text={propertyDescription.features} />
                <DataString title={'Outdoor features:'} text={propertyDescription.outdoorFeatures} />
                {[
                    {
                        title: 'Construction of property:',
                        text: propertyDescription.constructionOfProperty
                    },
                    {
                        title: 'Roof materials',
                        text: propertyDescription.roofMaterials
                    },
                    {
                        title: 'Style of property:',
                        text: propertyDescription.styleOfProperty
                    },
                    {
                        title: 'Interior walls:',
                        text: propertyDescription.interiorWalls
                    },
                    {
                        title: 'Hot water system:',
                        text: propertyDescription.hotWaterSystem
                    },
                    {
                        title: 'Outlook:',
                        text: propertyDescription.outlook
                    },
                    {
                        title: 'Appliances:',
                        text: propertyDescription.appliances
                    },
                    {
                        title: 'Location facilities:',
                        text: propertyDescription.locationFacilities
                    }
                ]
                    .map(i => ({ ...i, text: i.text || 'Not stated' }))
                    .map((condition, index) => (
                        <DataString {...condition} key={index} />
                    ))}
            </div>
        </AgreementSection>
    );
}
