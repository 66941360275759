export const GAME_SIGNING_REQUEST = 'GAME_SIGNING_REQUEST';
export const GAME_SIGNING_SUCCESS = 'SEND_RESET_PASSWORD_EMAIL_SUCCESS';
export const GAME_SIGNING_REQUEST_FAILURE = 'SEND_RESET_PASSWORD_EMAIL_FAILURE';
export const RESET = 'RESET';

const initState = {
    isSendingGameRequest: false,
    gameRequestSentSuccess: false,
    gameLinks: null
};

export default function signLeaseGame(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case GAME_SIGNING_REQUEST: {
            newState.isSendingGameRequest = true;
            newState.gameLinks = null;
            break;
        }
        case GAME_SIGNING_SUCCESS: {
            newState.isSendingGameRequest = false;
            newState.gameRequestSentSuccess = true;
            newState.gameLinks = action.result.data.links;
            break;
        }
        case GAME_SIGNING_REQUEST_FAILURE: {
            newState.isSendingGameRequest = false;
            //Faking a success even when failure for security reason
            newState.gameRequestSentSuccess = true;
            break;
        }
        case RESET: {
            newState.gameRequestSentSuccess = false;
            break;
        }
    }
    return newState;
}
