import React, { memo, useCallback, useRef } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';

const Chart = ({ chartId, id, filter, environmentSpecific }) => {
    const refChart = useRef(null);

    const getSdk = useCallback(() => {
        if (environmentSpecific) {
            return ENVIRONMENT === 'production'
                ? 'https://charts.mongodb.com/charts-flk-production-fviuz'
                : 'https://charts.mongodb.com/charts-flk-staging-jpbth';
        } else {
            return 'https://charts.mongodb.com/charts-flk-production-fviuz';
        }
    }, [environmentSpecific]);
    const sdk = new ChartsEmbedSDK({
        baseUrl: getSdk()
    });

    const chart = sdk.createChart({
        chartId: chartId,
        filter
    });

    const renderChart = useCallback(async ref => {
        try {
            await chart.render(ref);
        } catch (e) {
            console.error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setRefChart = useCallback(
        ref => {
            if (ref) {
                renderChart(ref);
            }
            // Save a reference to the node
            refChart.current = ref;
        },
        [renderChart]
    );

    return <div id={id} className="chart" ref={setRefChart} />;
};

export default memo(Chart);
