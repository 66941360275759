import React from 'react';
import cx from 'classnames';

import Button from './Button.js';
import Card, { CardShadow, CardStyles } from './cards/Card';
import Icon, { Icons } from './Icon';

import styles from './Note.module.scss';

export enum NoteType {
    INFO,
    SUCCESS,
    WARNING,
    ERROR,
    WARNING2
}

export type NoteProps = {
    title: string;
    type: NoteType;
    className?: string;
    isClosable?: boolean;
    hideIcon?: boolean;
    onClose?: () => void;
};

const getIcon = (type: NoteType) => {
    switch (type) {
        case NoteType.INFO:
            return Icons.INFO;
        case NoteType.SUCCESS:
            return Icons.NOTE_SUCCESS;
        case NoteType.WARNING:
            return Icons.WARNING;
        case NoteType.ERROR:
            return Icons.NOTE_ERROR;
        case NoteType.WARNING2:
            return Icons.NOTE_ERROR;
    }
};

export const Note: React.FC<NoteProps> = ({ title, children, className, isClosable, onClose, type, hideIcon }) => {
    const cardClass = {
        [styles.info]: type === NoteType.INFO,
        [styles.success]: type === NoteType.SUCCESS,
        [styles.warning]: type === NoteType.WARNING,
        [styles.error]: type === NoteType.ERROR,
        [styles.warning2]: type === NoteType.WARNING2
    };

    return (
        <Card
            style={CardStyles.SQUARE}
            shadow={CardShadow.NONE}
            className={cx(className, styles.note, cardClass)}
            animate={false}
        >
            {!hideIcon && <Icon icon={getIcon(type)} className={styles.icon} />}
            <div className={styles.titleAndContentContainer}>
                {!!title && <h5>{title}</h5>}
                {children && <div className={styles.content}>{children}</div>}
            </div>
            {isClosable && (
                <Button onClick={onClose} className={styles.closeButton}>
                    <Icon icon={Icons.CLOSE} className={styles.closeIcon} />
                </Button>
            )}
        </Card>
    );
};

export default Note;
