import * as React from 'react';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import ReactTooltip from 'react-tooltip';
import { memo } from 'react';

const Agencies = props => {
    const agencies = props.agencies;
    return (
        <div className="agencies">
            {agencies && !!agencies.length && (
                <div className="agencies-block">
                    <div className="table-sort table">
                        <Table
                            sortable={[
                                {
                                    column: 'agencyName',
                                    sortFunction: function(a, b) {
                                        return a.props.children > b.props.children ? 1 : -1;
                                    }
                                },
                                {
                                    column: 'companyName',
                                    sortFunction: function(a, b) {
                                        return a.props.children > b.props.children ? 1 : -1;
                                    }
                                },
                                {
                                    column: 'address',
                                    sortFunction: function(a, b) {
                                        return a.props.children > b.props.children ? 1 : -1;
                                    }
                                }
                            ]}
                            defaultSort={{ column: 'name', direction: 'desc' }}
                        >
                            <Thead>
                                <Th column="agencyName" className="sort">
                                    <b>agency name</b>
                                </Th>
                                <Th column="companyName" className="sort">
                                    <b>company name</b>
                                </Th>
                                <Th column="address" className="sort">
                                    <b>address</b>
                                </Th>
                                <Th column="action">&nbsp;</Th>
                            </Thead>
                            {agencies.map((item, index) => (
                                <Tr key={index}>
                                    <Td column="agencyName" className="sort">
                                        <b className="capitalize">{item.details.agencyName}</b>
                                    </Td>
                                    <Td column="companyName" className="sort">
                                        <span>{item.details.companyName}</span>
                                    </Td>
                                    <Td column="address" className="sort">
                                        <span>{item.details.address}</span>
                                    </Td>
                                    <Td column="action">
                                        <div className="action">
                                            <span
                                                data-tip
                                                data-for="deleteFromAgency"
                                                className="icon-cancel"
                                                onClick={() => props.deleteUserFromAgency(item.id)}
                                            />
                                            <ReactTooltip
                                                id="deleteFromAgency"
                                                class="tooltip"
                                                effect="solid"
                                                globalEventOff="wheel"
                                            >
                                                Delete yourself from this agency
                                            </ReactTooltip>
                                        </div>
                                    </Td>
                                </Tr>
                            ))}
                        </Table>
                    </div>
                </div>
            )}
        </div>
    );
};
export default memo(Agencies);
