import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import { FormApi } from 'final-form';
import { Field } from 'react-final-form';

import ClausesAccordion from './components/ClausesAccordion';
import ClientsAccordion from './components/ClientsAccordion';
import { getFieldError, toggleClientCorporation } from './utils';
import Listen from './components/Listen';
import {
    AcknowledgementType,
    BuildADocUiModel,
    ClientSection,
    DeliveryType,
    generateNewClient
} from './BuildADoc.model';
import ClientAcknowledgement from '../../../../components/ClientAcknowledgement';
import RadioIconGroup from './components/RadioIconGroup';
import Icon, { Icons } from '../../../../common/components/Icon';
import TextInputField from './components/TextInput';
import { capitalize } from 'lodash';
import { Textarea } from './components/Textarea';
import Card, { CardShadow, CardStyles } from '../../../../common/components/cards/Card';
import Note, { NoteType } from '../../../../common/components/Note';

import styles from './BuildADocForm.module.scss';

type BuildADocFormProps = {
    form: FormApi;
    uiState: BuildADocUiModel;
    docId?: string;
};

const BuildADocForm: React.FC<BuildADocFormProps> = ({ uiState, form, docId }) => {
    const {
        from: fromUiState,
        to: toUiState,
        questions: questionsUiState,
        questionSectionDisplayNameLowercase,
        showInstructionsField,
        showInstructions,
        isReadOnly
    } = uiState;

    return (
        <div className={styles.formBody}>
            {isReadOnly && (
                <Note type={NoteType.WARNING} className={styles.readOnlyWarning}>
                    You can't edit this template, but you can create a copy by cloning using the 3 dots at the top of the screen
                </Note>
            )}
            {showInstructionsField && (
                <section className={styles.section}>
                    <h2>Instructions</h2>
                    <Textarea
                        disabled={isReadOnly}
                        ariaLabel="Template instructions"
                        name="documentInstructions"
                        placeholder="Give your team instructions on how to use this template in the future"
                        isChatGpt={true}
                        gpt_fieldName="documentInstructions"
                        gpt_isDocument={true}
                        gpt_documentId={docId}
                    />
                </section>
            )}
            {showInstructions && (
                <Card className={styles.instructions} style={CardStyles.SQUARE} shadow={CardShadow.NONE}>
                    <div className={styles.header}>
                        <Icon icon={Icons.INFO} className={styles.instructionsIcon} />
                        <h2>Instructions</h2>
                    </div>
                    <Field name="documentInstructions">{({ input }) => <p>{input.value}</p>}</Field>
                </Card>
            )}
            <section className={styles.section}>
                <h2
                    className={cx({
                        [styles.error]: !!getFieldError(form, 'deliveryType')
                    })}
                >
                    Delivery Type
                </h2>
                <RadioIconGroup
                    name="deliveryType"
                    ariaLabel="Delivery Type"
                    data={[
                        {
                            label: 'SMS',
                            value: DeliveryType.SMS,
                            icon: <Icon icon={Icons.SMS_DELIVERY} />
                        },
                        {
                            label: 'Email',
                            value: DeliveryType.Email,
                            icon: <Icon icon={Icons.EMAIL} />
                        }
                    ]}
                    disabled={isReadOnly}
                />
            </section>
            <section className={styles.section}>
                <h2>Acknowledgement Type</h2>
                <RadioIconGroup
                    name="acknowledgementType"
                    ariaLabel="Acknowledgement Type"
                    data={[
                        {
                            label: 'Sign',
                            value: AcknowledgementType.Sign,
                            icon: <Icon icon={Icons.SIGN} />
                        }
                    ]}
                    disabled={isReadOnly}
                />
            </section>
            <h2>This document is between the below parties</h2>
            <section className={styles.section}>
                <h2>Sender details</h2>
                <FieldArray name={`${ClientSection.From}.clients`}>
                    {({ fields }) => (
                        <>
                            <ClientsAccordion
                                onToggleCorporation={(index: number) => {
                                    toggleClientCorporation(form, ClientSection.From, index);
                                }}
                                clientSectionData={fromUiState}
                                clientSectionType={ClientSection.From}
                                onAddClient={() => {
                                    fields.push(generateNewClient(fromUiState.type, ClientSection.From));
                                }}
                                onRemoveClient={(index: number) => {
                                    if (fields.value[index].isPrimary) {
                                        fields.update(0, { ...fields.value[0], isPrimary: true });
                                    }
                                    fields.remove(index);
                                }}
                                onClearClient={(index: number) => {
                                    fields.update(index, generateNewClient(fromUiState.type, ClientSection.From));
                                }}
                                isReadOnly={isReadOnly}
                            />
                            <Listen
                                to={`${ClientSection.From}.type`}
                                onChange={() =>
                                    fields.forEach((_, index) => {
                                        fields.update(index, generateNewClient(fromUiState.type, ClientSection.From));
                                    })
                                }
                            />
                        </>
                    )}
                </FieldArray>
            </section>
            <section className={styles.section}>
                <h2>Recipient details</h2>
                <FieldArray name={`${ClientSection.To}.clients`}>
                    {({ fields }) => (
                        <>
                            <ClientsAccordion
                                onToggleCorporation={(index: number) => {
                                    toggleClientCorporation(form, ClientSection.To, index);
                                }}
                                clientSectionData={toUiState}
                                clientSectionType={ClientSection.To}
                                onAddClient={() => fields.push(generateNewClient(toUiState.type, ClientSection.To))}
                                onRemoveClient={(index: number) => {
                                    if (fields.value[index].isPrimary && index !== 0) {
                                        fields.update(0, { ...fields.value[0], isPrimary: true });
                                    } else if (fields.value[index].isPrimary && index === 0) {
                                        fields.update(1, { ...fields.value[1], isPrimary: true });
                                    }
                                    fields.remove(index);
                                }}
                                onClearClient={(index: number) => {
                                    fields.update(index, {
                                        ...generateNewClient(toUiState.type, ClientSection.To),
                                        isPrimary: true
                                    });
                                }}
                                onToggleIsPrimary={(newValue: boolean, toggledClientIndex: number) => {
                                    if (newValue) {
                                        fields.forEach((_, index) => {
                                            if (index !== toggledClientIndex) {
                                                fields.update(index, { ...fields.value[index], isPrimary: false });
                                            }
                                        });
                                    } else {
                                        fields.update(0, { ...fields.value[0], isPrimary: true });
                                    }
                                }}
                                isReadOnly={isReadOnly}
                            />
                            <Listen
                                to={`${ClientSection.To}.type`}
                                onChange={() =>
                                    fields.forEach((_, index) => {
                                        fields.update(index, {
                                            ...generateNewClient(toUiState.type, ClientSection.To),
                                            isPrimary: index === 0
                                        });
                                    })
                                }
                            />
                        </>
                    )}
                </FieldArray>
            </section>
            <section className={styles.section}>
                <div className={styles.sectionHeader}>
                    <h2>Document description</h2>
                    <p className={styles.helpText}>
                        Introduce your document; your client will see this before they sign/acknowledge
                    </p>
                </div>
                <Textarea
                    ariaLabel="Introduce your document; your client will see this before they sign/acknowledge"
                    placeholder="Example: **Name** requires you to review the following document"
                    name="documentDescription"
                    validateOnTouch
                    required
                    disabled={isReadOnly}
                    isChatGpt={true}
                    gpt_fieldName="documentDescription"
                    gpt_isDocument={true}
                    gpt_documentId={docId}
                />
            </section>
            <section className={styles.section}>
                <h2>{`Main document ${questionSectionDisplayNameLowercase}s`}</h2>
                <TextInputField
                    name="clauseTitle"
                    label={`${capitalize(questionSectionDisplayNameLowercase)}(s) heading`}
                    labelClassName={styles.headingLabel}
                    disabled={isReadOnly}
                    isRequired
                />
                <FieldArray name="questions">
                    {({ fields }) => (
                        <ClausesAccordion
                            clauseFields={questionsUiState}
                            onAddClauseField={() =>
                                fields.push({
                                    title: '',
                                    subTitle: '',
                                    description: ''
                                })
                            }
                            onRemoveClauseField={(index: number) => fields.remove(index)}
                            onReorderClauseFields={(activeIndex: number, overIndex: number) =>
                                fields.move(activeIndex, overIndex)
                            }
                            sectionDisplayNameLowercase={questionSectionDisplayNameLowercase}
                            isReadOnly={isReadOnly}
                        />
                    )}
                </FieldArray>
            </section>
            <ClientAcknowledgement shouldShowMandatoryStatement className={styles.section} isReadOnly={isReadOnly} />
        </div>
    );
};

export default BuildADocForm;
