import React, { useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Form } from 'react-final-form';

import { FormEmail } from '../../components/form/FormEmail';
import { FormPassword } from '../../components/form/FormPassword';
import { updateUserLoginData } from '../../actions/user';
import { isValidPassword, isCorrectEmail, INVALID_PASSWORD_MESSAGE } from '../../components/Validate';
import { HIDE_MESSAGE_TIME } from '../../config';

import '../../sass/account.scss';
import '../../sass/settings.scss';
import SaveButtonIcon from './SaveButtonIcon';

const Account = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.userInfo);

    const [isPending, setIsPending] = useState(false);
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    const [saveFailed, setSaveFailed] = useState(false);
    const [formData, setFormData] = useState({
        email: user.email,
        password: '',
        newPassword: '',
        newPasswordConfirm: ''
    });
    const [isHidden, setIsHidden] = useState(true);

    const updateUserDetails = loginData => {
        return axios.put(`/api/user/profile-settings/login-data`, loginData);
    };
    const handleSubmit = values => {
        let loginData = {
            email: values.email,
            password: values.password
        };

        if (!isHidden) {
            loginData.newPassword = values.newPassword;
        }
        setIsPending(true);
        return updateUserDetails(loginData)
            .then(response => {
                setIsPending(false);
                setSavedSuccessfully(true);
                //update new data in redux
                dispatch(updateUserLoginData(response.data.user));
                setTimeout(() => {
                    setSavedSuccessfully(false);
                }, HIDE_MESSAGE_TIME);
                return Promise.resolve();
            })
            .catch(err => {
                setIsPending(false);
                setSaveFailed(true);
                setTimeout(() => {
                    setSaveFailed(false);
                }, HIDE_MESSAGE_TIME);
                return err.response.data.errors;
            });
    };

    const validateNewPassword = newPassword => {
        if (isHidden) {
            return undefined;
        }
        if (!newPassword) {
            return 'Password is required';
        }
        if (!isValidPassword(newPassword)) {
            return INVALID_PASSWORD_MESSAGE;
        }
        return undefined;
    };

    const validateEmail = email => {
        if (!email) {
            return 'Email is required';
        }
        if (!isCorrectEmail(email)) {
            return 'Wrong email format';
        }
        return undefined;
    };

    return (
        <div className="settings">
            <div className="settings-container">
                <div className="account">
                    <h2>Email & Password</h2>
                    <Form
                        initialValues={formData}
                        validate={({ email, password, newPassword, newPasswordConfirm }) => {
                            let errors = {
                                email: validateEmail(email),
                                password: !password ? 'Password is required' : undefined,
                                newPassword: validateNewPassword(newPassword),
                                newPasswordConfirm:
                                    !newPasswordConfirm && !isHidden
                                        ? 'Password is required'
                                        : undefined || (newPasswordConfirm !== newPassword && !isHidden)
                                        ? 'Passwords are not equal'
                                        : undefined
                            };
                            return errors;
                        }}
                        onSubmit={values => handleSubmit(values)}
                    >
                        {({ handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className="form-content">
                                        <FormEmail name="email" label="Email" />
                                        <FormPassword name="password" label="Current password" validateOnTouch={true} />
                                        <div
                                            className={isHidden ? 'toggle toggle-plus' : 'toggle'}
                                            onClick={() => setIsHidden(!isHidden)}
                                        >
                                            Change password
                                        </div>
                                        <div className={isHidden ? 'hidden' : ''}>
                                            <FormPassword
                                                name="newPassword"
                                                label="New password"
                                                validateOnTouch={true}
                                            />
                                            <FormPassword
                                                name="newPasswordConfirm"
                                                label="Confirm Password"
                                                validateOnTouch={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="footer">
                                        <SaveButtonIcon
                                            isSaving={isPending}
                                            success={savedSuccessfully}
                                            failure={saveFailed}
                                        />
                                    </div>
                                </form>
                            );
                        }}
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default memo(Account);
