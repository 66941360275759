/**
 * Helper function to generate actions that can be used for Promise (example. Axios requests)
 * example:
 * ``` 
 * const GET_USER = statefulActions('api.getuser'); 
 * ```
 * will generate
 * ```
 * GET_USER.REQUEST, GET_USER.SUCCESS, GET_USER.FAIL
 * ```
 * Can be used in action like
 * ```
 * export function getUser(userId: string) {
 *  return {
 *      types: [GET_USER.REQUEST, GET_USER.SUCCESS, GET_USER.FAIL],
 *      promise: axios.get(`/api/user/${userId}`)
 *  }
 * }
 * ````
 * Can be used inside Component like this.
 * ```
 * dispatch(getUser(userId))
 * ```
 *
 * @param action Unique action identifier
 * @returns
 */
export function statefulActions(action: string) {
    return {
        REQUEST: `${action}.request`,
        SUCCESS: `${action}.success`,
        FAIL: `${action}.fail`
    };
}
