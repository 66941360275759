import React from 'react';
import { useSelector } from 'react-redux';
import { parseISO, differenceInDays, isPast, isBefore } from 'date-fns';

import CircleChecked from '@material-ui/icons/CheckCircleSharp';
import CircleOutline from '@material-ui/icons/PanoramaFishEyeSharp';
import CircleFilled from '@material-ui/icons/Brightness1Sharp';
import CancelIcon from '@material-ui/icons/CancelSharp';
import InfoIcon from '@material-ui/icons/InfoSharp';

import { formatDateDay, getAgencyTimezoneFromUser } from '../../../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../../../selectors/user';
import { getLeaseInfo } from '../../../../../../../selectors/lease';
import { LEASE_STATUS_DRAFT } from '../../../../../../../actions/dashboard';

import cx from 'classnames';
import styles from '../RenewalFlowSummary.module.scss';
import ReactTooltip from 'react-tooltip';
import { SEND_STATUS_PENDING, SEND_STATUS_ERROR, SEND_STATUS_SENT } from '../../../../../../../config';

export const ScheduledSummary = ({ values, summaryView }) => {
    const loggedInUser = useSelector(getUserInfo);
    const lease = useSelector(getLeaseInfo);

    let daysAfterSendForSigning = 0;
    if (values.reminderEmailDate) {
        daysAfterSendForSigning = differenceInDays(
            values.reminderEmailDate,
            lease.status === LEASE_STATUS_DRAFT ? new Date() : parseISO(lease.firstSentForSigning)
        );
    } else {
        daysAfterSendForSigning = differenceInDays(
            values.noticeToLeaveDate,
            lease.status === LEASE_STATUS_DRAFT ? new Date() : parseISO(lease.firstSentForSigning)
        );
    }

    function GetTimelineIcon({ date, status }) {
        if (status === SEND_STATUS_SENT) {
            return <CircleChecked className={cx(styles.timelineDot, styles.active)} />;
        } else if (status === SEND_STATUS_ERROR) {
            return <CancelIcon className={cx(styles.timelineDot, styles.error)} />;
        } else if (status === SEND_STATUS_PENDING) {
            // If pending and date is passed then mark it as error
            if (
                (lease?.renewalNoticeToLeaveDetails?.isCancelled &&
                    isBefore(parseISO(lease.renewalNoticeToLeaveDetails.cancelledDate), date)) ||
                (date && isPast(date))
            ) {
                return <CancelIcon className={cx(styles.timelineDot, styles.error)} />;
            } else {
                return <CircleOutline className={styles.timelineDot} />;
            }
        } else {
            // calculate the status based on today and event date (vacant possession date)
            if (
                lease?.renewalNoticeToLeaveDetails?.isCancelled &&
                isBefore(parseISO(lease.renewalNoticeToLeaveDetails.cancelledDate), date)
            ) {
                return <CancelIcon className={cx(styles.timelineDot, styles.error)} />;
            } else if (date && isPast(date)) {
                return <CircleChecked className={cx(styles.timelineDot, styles.active)} />;
            } else {
                return <CircleOutline className={styles.timelineDot} />;
            }
        }
    }
    const reminderEmailToolTipId = summaryView ? 'summary-reminder-email' : 'reminder-email';
    const noticeToLeaveToolTipId = summaryView ? 'summary-notice-to-leave' : 'notice-to-leave';
    return (
        <React.Fragment>
            <div className={styles.lines}>
                {values.reminderEmailDate && (
                    <React.Fragment>
                        <CircleFilled className={cx(styles.timelineDot, styles.subItem)} />
                        <div className={styles.line} />
                        <GetTimelineIcon date={values.reminderEmailDate} status={values.reminderEmailStatus} />
                        <div className={styles.line} />
                    </React.Fragment>
                )}
                <CircleFilled className={cx(styles.timelineDot, styles.subItem)} />
                <div className={styles.line} />
                <GetTimelineIcon date={values.noticeToLeaveDate} status={values.noticeToLeaveStatus} />
                <div className={styles.line} />
                <CircleFilled className={cx(styles.timelineDot, styles.subItem)} />
                <div className={styles.line} />
                <GetTimelineIcon date={values.vacantPossessionDate} />
            </div>
            <div className={styles.timelineItems}>
                <div className={styles.item}>
                    <table className={styles.detailsSection}>
                        <tr>
                            <td>
                                {isNaN(daysAfterSendForSigning)
                                    ? 'No date added'
                                    : `${daysAfterSendForSigning} days after lease is sent`}{' '}
                            </td>
                        </tr>
                    </table>
                </div>
                {values.reminderEmailDate && (
                    <div className={styles.item}>
                        <table className={styles.detailsSection}>
                            <tr>
                                <td className={cx(styles.label, styles.withTooltip)}>
                                    Reminder to renew lease
                                    <InfoIcon
                                        className={styles.tooltipIcon}
                                        data-tip
                                        data-for={reminderEmailToolTipId}
                                    />
                                    <ReactTooltip
                                        id={reminderEmailToolTipId}
                                        class={cx(styles.fixedWidthTooltip, 'tooltip')}
                                        effect="solid"
                                        globalEventOff="wheel"
                                    >
                                        <div>
                                            <p>Hi {lease.tenant.tenantsListText},</p>
                                            <p className={styles.paragraph}>
                                                As per our previous communication – If you would like to renew your
                                                lease all tenants are required sign by 12pm{' '}
                                                {values.noticeToLeaveDate
                                                    ? formatDateDay(
                                                          values.noticeToLeaveDate,
                                                          getAgencyTimezoneFromUser(loggedInUser)
                                                      )
                                                    : '[notice to leave date]'}
                                                . The lease renewal was sent to you via SMS for {lease.address}.
                                            </p>
                                            <p className={styles.paragraph}>
                                                If it is expired, then contact me for an updated link
                                            </p>
                                            <p className={styles.paragraph}>
                                                At time of sending this email - the status for signing your renewal is:
                                            </p>
                                            <ul>
                                                {lease.tenant.tenants.map((tenant, index) => (
                                                    <li key={index}>
                                                        {tenant.fullName} - Tenant {index + 1} signature status
                                                    </li>
                                                ))}
                                            </ul>
                                            <p>Regards,</p>
                                            <p>{loggedInUser.fullName}</p>
                                            <p>{lease.agency.details.agencyName}</p>
                                            <p>{loggedInUser.email}</p>
                                            <p>{loggedInUser.mobile}</p>
                                        </div>
                                    </ReactTooltip>
                                </td>
                                <td>
                                    {values.reminderEmailDate
                                        ? formatDateDay(
                                              values.reminderEmailDate,
                                              getAgencyTimezoneFromUser(loggedInUser)
                                          )
                                        : 'No date added'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {values.daysBeforeReminderToSign
                                        ? `${values.daysBeforeReminderToSign} days later`
                                        : 'No date added'}
                                </td>
                            </tr>
                        </table>
                    </div>
                )}
                <div className={styles.item}>
                    <table className={styles.detailsSection}>
                        <tr>
                            <td className={cx(styles.label, styles.withTooltip)}>
                                Issue Notice to Leave
                                <InfoIcon className={styles.tooltipIcon} data-tip data-for={noticeToLeaveToolTipId} />
                                <ReactTooltip
                                    id={noticeToLeaveToolTipId}
                                    class={cx(styles.fixedWidthTooltip, 'tooltip')}
                                    effect="solid"
                                    globalEventOff="wheel"
                                >
                                    <div>
                                        <p>Hi {lease.tenant.tenantsListText},</p>
                                        <p className={styles.paragraph}>
                                            The lease you were sent by {lease.agency.details.agencyName} was not signed
                                            by the deadline of 12pm of{' '}
                                            {values.noticeToLeaveDate
                                                ? formatDateDay(
                                                      values.noticeToLeaveDate,
                                                      getAgencyTimezoneFromUser(loggedInUser)
                                                  )
                                                : '[notice to leave date]'}
                                        </p>
                                        <p className={styles.paragraph}>
                                            As per our lessor’s instructions, we are required to issue the attached
                                            notice, Form 12 - Notice to vacate confirming the lease will not be renewed
                                            for {lease.address} and you are required to vacate on{' '}
                                            {values.vacantPossessionDate
                                                ? formatDateDay(
                                                      values.vacantPossessionDate,
                                                      getAgencyTimezoneFromUser(loggedInUser)
                                                  )
                                                : '[vacant possession date]'}
                                        </p>
                                        <p className={styles.paragraph}>
                                            Attached is a copy of the Notice To Leave for your records.
                                        </p>
                                        <p className={styles.paragraph}>
                                            Please contact {lease.agent.fullName} if you have any questions.
                                        </p>
                                        <br />
                                        <p>Regards,</p>
                                        <p>{loggedInUser.fullName}</p>
                                        <p>{lease.agency.details.agencyName}</p>
                                        <p>{loggedInUser.email}</p>
                                        <p>{loggedInUser.mobile}</p>
                                    </div>
                                </ReactTooltip>
                            </td>
                            <td>
                                {values.noticeToLeaveDate
                                    ? formatDateDay(values.noticeToLeaveDate, getAgencyTimezoneFromUser(loggedInUser))
                                    : 'No date added'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {values.daysBeforeNoticeToLeave
                                    ? `${values.daysBeforeNoticeToLeave} days later`
                                    : 'No date added'}
                            </td>
                        </tr>
                    </table>
                </div>
                <div className={styles.item}>
                    <table className={styles.detailsSection}>
                        <tr>
                            <td className={styles.label}>Vacant possession date</td>
                            <td>
                                {values.vacantPossessionDate
                                    ? formatDateDay(
                                          values.vacantPossessionDate,
                                          getAgencyTimezoneFromUser(loggedInUser)
                                      )
                                    : 'No date added'}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export const EmptySummary = () => {
    return (
        <React.Fragment>
            <div className={styles.lines}>
                <CircleOutline className={styles.timelineDot} />
            </div>
            <div className={styles.timelineItems}>
                <div className={styles.item}>
                    <table className={styles.detailsSection}>
                        <tbody>
                            <tr>
                                <td className={styles.label}>Vacant possession date</td>
                                <td>No date added</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export const SendTogetherSummary = () => {
    const lease = useSelector(getLeaseInfo);
    return (
        <React.Fragment>
            <div className={styles.lines}>
                {lease.status === LEASE_STATUS_DRAFT ? (
                    <CircleOutline className={styles.timelineDot} />
                ) : (
                    <CircleChecked className={cx(styles.timelineDot, styles.active)} />
                )}
                <div className={styles.space} />
                {lease.status === LEASE_STATUS_DRAFT ? (
                    <CircleOutline className={styles.timelineDot} />
                ) : (
                    <CircleChecked className={cx(styles.timelineDot, styles.active)} />
                )}
                <div className={styles.space} />
            </div>
            <div className={styles.timelineItems}>
                <div className={styles.singleItem}>
                    <table className={styles.detailsSection}>
                        <tbody>
                            <tr>
                                <td className={styles.label}>Lease renewal</td>
                                <td>Send together</td>
                            </tr>
                            <tr>
                                <td className={styles.label}>Notice to leave</td>
                                <td>Send together</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};
