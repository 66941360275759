import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import axios from 'axios';
import { debounce, constant, noop, size, escapeRegExp, concat, uniqBy, isEmpty } from 'lodash';
import SearchBarResults from './SearchBarResults';
import KeySearchBarResults from './KeySearchBarResults';
import { PreLoader } from '../../common/components/PreLoader';
import '../../sass/searchBar.scss';
import ReactTooltip from 'react-tooltip';
import { ALL, FLK_A_KEY } from '../../constants/constants';
import { useAccountProvider } from '../providers/AccountProvider';
import { isAgencyUserAccount } from '../../utils/userUtils';
import { CREATE_A_FLK_DISPLAY, FLK_A_PDF_DISPLAY } from '../../config';

const PLACEHOLDER = 'Type to search...';
const ERROR_MESSAGE = 'Sorry, we failed to contact our search service.';
const TYPE_MORE_MESSAGE = 'Your search is too short, keep typing.';
const DEBOUNCE_WAIT = 1000;
const REQUEST_TIMEOUT = 10000;
const EMPTY = constant('');

function SearchBar({ placeholder, onSelect, items, searchContext }) {
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [query, setQuery] = useState('');
    const [message, setMessage] = useState(null);
    const accountType = useAccountProvider();

    const searchInputRef = React.useRef(null);

    function focusInput() {
        if (searchInputRef) {
            searchInputRef.current.focus();
        }
    }

    function clearResults() {
        setMessage(null);
        setSearching(false);
        setSearchResults([]);
        setFilteredItems([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchSearchQuery = useCallback(
        debounce(
            query =>
                axios
                    .get(`/api/dashboard/search?query=${encodeURIComponent(query.trim())}&context=${searchContext}`, {
                        timeout: REQUEST_TIMEOUT
                    })
                    .then(({ data }) => {
                        if (data.success === true) {
                            return data.agreementList;
                        }
                        return [];
                    })
                    .catch(() => {
                        setMessage(ERROR_MESSAGE);
                        return [];
                    })
                    .then(handleFetchSearchQueryResults),
            DEBOUNCE_WAIT
        ),
        [searchContext]
    );

    function filterItems(value) {
        if (size(items) === 0) {
            return [];
        }
        if (searchContext === FLK_A_KEY) {
            const groupedKeyList = [];
            const itemList = items.filter(item => {
                if (item && typeof item.address === 'string') {
                    const regex = new RegExp(escapeRegExp(value), 'i');
                    return regex.test(item.address);
                }
                return false;
            });
            itemList.forEach(item => {
                const groupedItem = {
                    id: `${item.key}-${item.address}`,
                    key: item.key,
                    address: item.address
                };
                if (!groupedKeyList.includes(groupedItem)) {
                    groupedKeyList.push(groupedItem);
                }
            });
            return groupedKeyList;
        }
        return items.filter(item => {
            if (item && typeof item.address === 'string') {
                const regex = new RegExp(escapeRegExp(value), 'i');
                return regex.test(item.address);
            }
            return false;
        });
    }

    function getItems() {
        return uniqBy(concat(filteredItems, searchResults), 'id');
    }

    function handleInputValueChange(value) {
        const valueLength = size(value);

        // search is empty
        if (valueLength === 0) {
            clearResults();
            return;
        }

        const filteredItems = filterItems(value);

        // don't perform expensive searches
        if (valueLength < 3) {
            setMessage(TYPE_MORE_MESSAGE);
            return setFilteredItems(filteredItems);
        }

        setQuery(value);

        setMessage(null);
        setSearching(true);
        // apply filter items and search the api
        setFilteredItems(filteredItems);
        return fetchSearchQuery(value);
    }

    function handleFetchSearchQueryResults(searchResults) {
        setSearching(false);
        setSearchResults(isEmpty(searchResults) ? [] : searchResults);
    }

    const filteredItemsSize = size(filteredItems);
    const showResults = filteredItemsSize > 0 || !searching;

    return (
        <Downshift
            onInputValueChange={handleInputValueChange}
            onSelect={item => onSelect(item)}
            itemToString={EMPTY}
            defaultHighlightedIndex={0}
        >
            {({ getInputProps, getMenuProps, getItemProps, inputValue, reset, isOpen, highlightedIndex }) => (
                <div className="search-bar desktop-only" onClick={ReactTooltip.hide()}>
                    <ReactTooltip id="search-bar" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                        {searchContext && searchContext === FLK_A_KEY && (
                            <React.Fragment>
                                <p>Type to search all keys</p>
                                <div>
                                    You can search via
                                    <ul>
                                        <li>Address</li>
                                        <li>Key#</li>
                                    </ul>
                                </div>
                            </React.Fragment>
                        )}
                        {searchContext !== FLK_A_KEY && (
                            <React.Fragment>
                                <p>Type to search all agreements/documents</p>
                                <div>
                                    You can search via
                                    <ul>
                                        {isAgencyUserAccount(accountType) && <li>Address</li>}
                                        <li>Client name</li>
                                        <li>
                                            Document name (For {FLK_A_PDF_DISPLAY} and {CREATE_A_FLK_DISPLAY} only)
                                        </li>
                                        <li>Full mobile number (eg: +61 400 000 00)</li>
                                        <li>Full email (eg: email@example.com)</li>
                                    </ul>
                                </div>
                            </React.Fragment>
                        )}
                    </ReactTooltip>
                    <div data-tip={true} data-for={'search-bar'} className="search-bar__input-container">
                        <input
                            className="search-bar__input-container__input"
                            {...getInputProps({
                                type: 'search',
                                placeholder: placeholder || PLACEHOLDER
                            })}
                            ref={searchInputRef}
                        />
                        {searching ? <PreLoader className="search-bar__input-container__preloader" /> : null}
                        {inputValue ? (
                            <button
                                title="Clear search"
                                onClick={() => {
                                    reset();
                                    clearResults();
                                    focusInput();
                                }}
                            />
                        ) : null}
                        {searchContext !== FLK_A_KEY && isOpen && inputValue && showResults ? (
                            <SearchBarResults
                                {...getMenuProps({
                                    refKey: 'menuRef',
                                    items: getItems(),
                                    separatorIndex: filteredItemsSize,
                                    displayMessage: message,
                                    highlightedIndex,
                                    getItemProps,
                                    query
                                })}
                            />
                        ) : null}
                        {searchContext === FLK_A_KEY && isOpen && inputValue && showResults ? (
                            <KeySearchBarResults
                                {...getMenuProps({
                                    refKey: 'menuRef',
                                    items: getItems(),
                                    separatorIndex: filteredItemsSize,
                                    displayMessage: message,
                                    highlightedIndex,
                                    getItemProps,
                                    query
                                })}
                            />
                        ) : null}
                    </div>
                </div>
            )}
        </Downshift>
    );
}

SearchBar.propTypes = {
    onSelect: PropTypes.func,
    items: PropTypes.array,
    searchContext: PropTypes.string
};
SearchBar.defaultProps = {
    onSelect: noop,
    items: [],
    searchContext: ALL
};

export default SearchBar;
