import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { updateLeaseInfo } from '../../actions/lease';
import { SCHEDULE_TYPE_LATER, SCHEDULE_TYPE_NOW } from '../../config';
import { FormTextMultiline } from '../form/FormText';
import { CheckboxCheck } from '../form/FormCheckboxCheck';
import Button from '../../common/components/Button';
import ModalDialog from '../../common/components/ModalDialog';
import { getIsOpenRetractLeaseConfirmModal, getConfirmModalLease } from '../../selectors/confirmModals';
import { closeRetractLeaseConfirmModal } from '../../actions/confirmModals';

import styles from './ConfirmModal.module.scss';

function RetractOfferConfirmModal() {
    const dispatch = useDispatch();

    const isOpen = useSelector(getIsOpenRetractLeaseConfirmModal);
    const lease = useSelector(getConfirmModalLease);

    function closeModal() {
        dispatch(closeRetractLeaseConfirmModal());
    }

    const retractOfferOfRenewalCall = useMutation(
        data => {
            return axios.post(`/api/agency/lease/${lease.id}/retract-offer-of-renewal`, data);
        },
        {
            onSuccess: data => {
                dispatch(updateLeaseInfo(data.data.lease));
            }
        }
    );

    let message = (
        <div className="message">
            <p>
                This will retract the offer of lease for this property and move the lease to the "cancelled" lease tab.
            </p>
        </div>
    );
    if (
        lease?.renewalNoticeToLeaveDetails?.isRenewalToLeaveFlow &&
        lease.renewalNoticeToLeaveDetails.scheduleType === SCHEDULE_TYPE_NOW
    ) {
        message = (
            <div className="message">
                <p>The Notice to Leave has already been sent to the tenant(s).</p>
                <p>
                    This will retract the offer of lease for this property and move the lease to the "cancelled" lease
                    tab.
                </p>
            </div>
        );
    } else if (
        lease?.renewalNoticeToLeaveDetails?.isRenewalToLeaveFlow &&
        lease.renewalNoticeToLeaveDetails.scheduleType === SCHEDULE_TYPE_LATER
    ) {
        message = (
            <div className="message">
                <br />
                <p>This will move the lease to the Cancelled lease tab.</p>
                {!lease.renewalNoticeToLeaveDetails.isCancelled && (
                    <>
                        <br />
                        <p>It will also cancel all future scheduled alert(s) related to this lease as listed below:</p>
                        <ol>
                            {lease.renewalNoticeToLeaveDetails.reminderEmailDate && (
                                <li>
                                    The Reminder to renew lease was scheduled to send on{' '}
                                    {lease.renewalNoticeToLeaveDetails.reminderEmailDateFormatted}.
                                </li>
                            )}
                            <li>
                                Notice to Leave was scheduled to send on{' '}
                                {lease.renewalNoticeToLeaveDetails.noticeToLeaveDateFormatted}
                            </li>
                        </ol>
                    </>
                )}
            </div>
        );
    }
    return (
        <ModalDialog
            isOpen={isOpen}
            title="Retract offer of lease"
            closeModal={() => closeModal()}
            shouldCloseOnOverlayClick={false}
            className={styles.confirmModal}
        >
            {message}
            <Form initialValues={{ notifyViaEmail: true }} onSubmit={() => {}}>
                {({ handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormTextMultiline name="reason" label="Reason" />
                            <CheckboxCheck name="notifyViaEmail" label="Notify tenants via email" value={true} />
                            <p>Do you wish to proceed?</p>
                            <div className={styles.footerButtons}>
                                <Button
                                    type="button"
                                    onClick={async () => {
                                        retractOfferOfRenewalCall.reset();
                                        await retractOfferOfRenewalCall.mutateAsync(values);
                                        closeModal();
                                    }}
                                    primary
                                    className={styles.actionButton}
                                    loading={retractOfferOfRenewalCall.isLoading}
                                    disabled={!values.reason}
                                >
                                    Proceed
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                    secondary
                                    className={styles.actionButton}
                                    loading={retractOfferOfRenewalCall.isLoading}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
}

export default RetractOfferConfirmModal;
