import { createSelector } from 'reselect';
import { DOCUMENT_CREATE_A_FLK, DOCUMENT_CUSTOM } from '../../config';
import { getCurrentDocument } from './index';

const createSignatureProps = ({ id, base64SignatureImage: imageUrl }, tooltip = { name: null, index: null }) => ({
    id,
    base64SignatureImage: imageUrl,
    tooltip: tooltip.name,
    index: tooltip.index
});

export const getSignatures = createSelector([getCurrentDocument], document => {
    if (document) {
        let clients = [];
        const uploadedDocsClientSignaturePages = document.uploadedDocsClientSignaturePages;
        if (document.docType === DOCUMENT_CREATE_A_FLK) {
            clients = document.to.clients;
        } else {
            clients = document.client.clients;
        }
        return clients.reduce((signatures, client, clientIndex) => {
            const { id, signature } = client;
            let name;
            let signed;
            let primary;
            let noSignatureRequired;
            if (document.docType === DOCUMENT_CREATE_A_FLK) {
                name = client.fullName;
                signed = client.acknowledgedDate;
                primary = client.isPrimaryClient;
            } else {
                name = client.name;
                signed = client.isSigned;
                primary = client.isPrimaryTenant;
                if (document.docType === DOCUMENT_CUSTOM) {
                    noSignatureRequired =
                        !uploadedDocsClientSignaturePages ||
                        Object.values(uploadedDocsClientSignaturePages).every(uploadedDoc => !uploadedDoc[clientIndex]);
                }
            }

            const updates = [
                ...signatures,
                {
                    id,
                    name,
                    signed,
                    primary,
                    noSignatureRequired,
                    signature: signature ? createSignatureProps(signature) : null
                }
            ];
            if (client.hasWitnessSignature && client.witnesssAcknowledgedDate) {
                const witnessName = `${client.witnessFirstName} ${client.witnessLastName}`;
                updates.push({
                    id: client.id,
                    name: witnessName,
                    signed: true,
                    witness: true,
                    signature: client.witnessSignature ? createSignatureProps(client.witnessSignature) : null,
                    initials: []
                });
            }
            return updates;
        }, []);
    }
    return [];
});
