import React, { memo } from 'react';

const BulkApprovalStatus = props => {
    function getApprovalStatus(doc) {
        if (doc.requiresApproval) {
            let className;
            let approvalStatus = doc.bulkSendApprovalStatus;
            switch (approvalStatus) {
                case 'pending':
                    className = `${props.class} status draft`;
                    break;
                case 'approved':
                    className = `${props.class} status completed`;
                    break;
                case 'declined':
                    className = `${props.class} status declined`;
            }
            return <span className={className}>{approvalStatus}</span>;
        }
        return null;
    }
    return <div className="status-wrapper">{getApprovalStatus(props.doc)}</div>;
};

export default memo(BulkApprovalStatus);
