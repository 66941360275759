import { FormApi } from 'final-form';
import { ClientSection, ClientType, generateNewClient, getClientConfig } from './BuildADoc.model';
import { TagColor } from '../../../../common/components/tag/Tag';
import { getFormError } from '../../../../utils/finalFormUtils';

export const getFieldError = (form: FormApi, fieldName: string) => {
    const fieldState = form.getFieldState(fieldName);
    const fieldMeta = { error: fieldState?.error, touched: fieldState?.touched };
    return getFormError(fieldMeta, true);
};

const ToClientTagColors = [
    TagColor.Orange,
    TagColor.Yellow,
    TagColor.Blue,
    TagColor.Red,
    TagColor.Pink,
    TagColor.Mint,
    TagColor.Aqua,
    TagColor.Purple
];

export const getClientTagColor = (clientSectionType: ClientSection, clientIndex: number) => {
    switch (clientSectionType) {
        case ClientSection.From:
            return TagColor.Green;
        case ClientSection.To:
            return ToClientTagColors[clientIndex % ToClientTagColors.length];
        // Should never enter default
        default:
            return TagColor.Grey;
    }
};

export const getClientTypeOptions = (clientSection: ClientSection, isBusinessUser: boolean, tradingName?: string) => {
    if (isBusinessUser) {
        if (clientSection === ClientSection.From) {
            return [
                { value: ClientType.TradingName, label: tradingName || 'a business' },
                { value: ClientType.Other, label: 'other' }
            ];
        } else if (clientSection === ClientSection.To) {
            return [{ value: ClientType.Other, label: 'other' }];
        }
    } else {
        if (clientSection === ClientSection.From) {
            return [
                { value: ClientType.Agent, label: 'an agent' },
                { value: ClientType.Landlord, label: 'a landlord' },
                { value: ClientType.Tenant, label: 'a tenant' },
                { value: ClientType.Vendor, label: 'a vendor' },
                { value: ClientType.Custom, label: 'other' }
            ];
        } else if (clientSection === ClientSection.To) {
            return [
                { value: ClientType.Landlord, label: 'a landlord' },
                { value: ClientType.Tenant, label: 'a tenant' },
                { value: ClientType.Vendor, label: 'a vendor' },
                { value: ClientType.Custom, label: 'other' }
            ];
        }
    }

    return [];
};

export const toggleClientCorporation = (form: FormApi, clientSection: ClientSection, clientIndex: number) => {
    const clientNamePrefix = `${clientSection}.clients[${clientIndex}].data`;
    const formValues = form.getState().values;
    const clientType = formValues[clientSection].type;
    const client = formValues[clientSection].clients[clientIndex];

    const oldData = client.data;

    const { data: newData } = generateNewClient(clientType, clientSection, undefined, false, client.isCorporation);

    // Keep fields that are consistent between both client types
    const newDataWithUpdatedValues = newData.map(field => {
        const oldDataField = oldData.find(oldField => oldField.label === field.label);

        return oldDataField ? { ...field, value: oldDataField.value } : field;
    });

    form.change(clientNamePrefix, newDataWithUpdatedValues);
};
