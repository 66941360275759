import { createSelector } from 'reselect';

/* If you require agency related data use the below selector getUserAgency */
const getAgency = state => state.agency;

export const getUser = state => {
    return state.user;
};

export const getUserAgency = createSelector([getUser], user => (user ? user.agency : null));

export const getAgencyInfo = createSelector([getUser], ({ agency }) => agency);

export const getAgencyId = createSelector([getAgencyInfo], agency => agency.id);

export const getAgencyIntegrations = createSelector([getAgencyInfo], agency => (agency ? agency.integrations : null));

export const getAgencyPropertyMeIntegration = createSelector([getAgencyIntegrations], integrations => {
    if (integrations && integrations.propertyMe && integrations.propertyMe.enabled) {
        return integrations.propertyMe;
    }
});

export const getAgencyPropertyTreeIntegration = createSelector([getAgencyIntegrations], integrations => {
    if (integrations && integrations.propertyTree && integrations.propertyTree.enabled) {
        return integrations.propertyTree;
    }
});
export const getAgencyManagedIntegration = createSelector([getAgencyIntegrations], integrations => {
    if (integrations && integrations.managed && integrations.managed.enabled) {
        return integrations.managed;
    }
});

export const getAgencyApiAccess = createSelector([getAgencyInfo], agency => agency?.apiAccess || []);

export const isFetchingAgencyInfo = createSelector([getAgencyInfo], agencyInfo => agencyInfo === true);

export const getSyncIntegrations = createSelector([getUserAgency], ({ syncIntegrations }) => syncIntegrations);

export const getTeam = createSelector([getAgency], ({ team }) => team);
export const getIsRequestingTeam = createSelector([getAgency], ({ isRequestingTeam }) => isRequestingTeam);
