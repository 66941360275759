import React, { memo } from 'react';
import { useIntercom } from 'react-use-intercom';

import intercom from '../../../assets/images/icons/intercom.svg';

const LiveChatBubble = () => {
    const { show } = useIntercom();

    return (
        <a className="special-link" onClick={show}>
            {' '}
            live chat <img id="intercom-bubble" src={intercom} alt="live chat bubble" />
        </a>
    );
};

export default memo(LiveChatBubble);
