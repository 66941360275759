import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import HeaderBanner from './HeaderBanner';
import styles from './Banner.module.scss';
import { closeDocumentHeaderBanner, displayDocumentHeaderBanner } from '../../actions/banner/banner.js';
import { setAmountOfDocumentsSentThisMonth } from '../../actions/account.js';
import { getDocumentCountForAgency } from '../../api/subscriptionApi.js';
import { subscriptionStatus } from '../../config';
import { UserHistory } from '../../types/UserHistory';
import { User } from '../../types/User';
import { Account } from '../../types/Account';
import { TariffPlan } from '../../types/TariffPlan';
import { differenceInSeconds, differenceInCalendarDays } from 'date-fns';
import BannerMessage from './BannerMessage';
import { getUserInfo, isUserRoleAdmin } from '../../selectors/user.js';
import { getIsDocumentBannerVisible } from '../../selectors/banner.js';
import { getAccount } from '../../selectors/settings/account.js';
import { AgreementCountInfo } from '../../types/AgreementCountInfo';

const DocumentBanner: React.FC = () => {
    const dispatch = useDispatch();

    const loggedInUser: User = useSelector(getUserInfo);
    const isDocumentBannerVisible: boolean = useSelector(getIsDocumentBannerVisible);
    const isUserAdmin: boolean = useSelector(isUserRoleAdmin);
    const account: Account = useSelector(getAccount);

    const [agreementCountInfo, setAgreementCountInfo] = useState<AgreementCountInfo>();

    async function getDocumentBannerVisible() {
        try {
            const response = await axios.get('/api/user/user-history');
            const historyList = response.data?.agreementList?.history;

            if (historyList && loggedInUser.latestClosedDocumentBannerEvent) {
                // if there was a close event, check if the user has logged in multiple times since then
                const loginHistories: UserHistory[] = historyList.filter(
                    (item: { type: string }) => item.type === 'login'
                );

                if (loginHistories && loginHistories.length > 0) {
                    const loginsSinceCloseEvent = loginHistories.filter(
                        item => item.timestamp > loggedInUser.latestClosedDocumentBannerEvent.closedDate
                    );

                    // show banner if the use has logged in 3 times since the banner was closed
                    if (loginsSinceCloseEvent?.length > 2) {
                        dispatch(displayDocumentHeaderBanner());
                    }
                }
            } else {
                dispatch(displayDocumentHeaderBanner());
            }
        } catch (error) {
            window.rollbar.error(
                'Failed to get info about the document banner',
                {
                    error_message: error.message,
                    status: 'error'
                },
                error
            );
        }
    }

    function setAgreementsLeft(tariffPlan: TariffPlan, amountOfDocumentsSentThisMonth: number) {
        const agreementCountInfo = {
            agreementsLeftOnTrial: 0,
            daysLeftOnTrial: 0,
            agreementsLeftOnPlan: 0,
            trialEnded: false
        };

        if (tariffPlan.status === subscriptionStatus.TRIALING) {
            agreementCountInfo.agreementsLeftOnTrial =
                tariffPlan.agreementsPerMonth.trialMax - amountOfDocumentsSentThisMonth;
            agreementCountInfo.daysLeftOnTrial = differenceInCalendarDays(new Date(tariffPlan.trialEnd), new Date());
            const secondsLeftOnTrial = differenceInSeconds(new Date(tariffPlan.trialEnd), new Date());

            if (agreementCountInfo.agreementsLeftOnTrial <= 0 || secondsLeftOnTrial <= 0) {
                agreementCountInfo.trialEnded = true;
            }
        } else if (tariffPlan.status === 'active') {
            agreementCountInfo.agreementsLeftOnPlan = Math.max(
                tariffPlan.agreementsPerMonth.freeDocs - amountOfDocumentsSentThisMonth,
                0
            );
        }
        setAgreementCountInfo(agreementCountInfo);
    }

    useEffect(() => {
        if (loggedInUser.agency) {
            getDocumentBannerVisible().then(() => {
                getDocumentCountForAgency(loggedInUser.agency.id)
                    .then(
                        async (response: {
                            data: { amountOfDocumentsSentThisMonth: number; tariffPlan: TariffPlan };
                        }) => {
                            const { amountOfDocumentsSentThisMonth, tariffPlan } = response.data;
                            dispatch(setAmountOfDocumentsSentThisMonth(amountOfDocumentsSentThisMonth));

                            setAgreementsLeft(tariffPlan, amountOfDocumentsSentThisMonth);
                        }
                    )
                    .catch((error: { message: string }) => {
                        window.rollbar.error(
                            'Failed to getDocumentCountForAgency',
                            {
                                error_message: error.message,
                                status: 'error'
                            },
                            error
                        );
                    });
            });
        }
    }, [loggedInUser]);

    useEffect(() => {
        if (account.agency?.tariffPlan?.agreementsPerMonth) {
            const tariffPlan = account.agency.tariffPlan;
            const amountOfDocumentsSentThisMonth = account.amountOfDocumentsSentThisMonth;

            setAgreementsLeft(tariffPlan, amountOfDocumentsSentThisMonth);
        }
    }, [account]);

    if (!loggedInUser || !loggedInUser.agency) {
        return null;
    }

    const agency = loggedInUser.agency;
    const hasPaymentMethod = !!agency.stripeDefaultPaymentMethodId;
    const onTrial = agency.tariffPlan.status === subscriptionStatus.TRIALING;

    async function closeBanner() {
        const data = { bannerId: 'DOCUMENT_BANNER' };
        await axios.post(`/api/agency/${agency.id}/close-banner`, data);
        dispatch(closeDocumentHeaderBanner());
    }

    function shouldDisplayBanner() {
        return (
            isDocumentBannerVisible &&
            (!hasPaymentMethod || (agreementCountInfo && agreementCountInfo.agreementsLeftOnPlan <= 3))
        );
    }

    return shouldDisplayBanner() && agreementCountInfo ? (
        <HeaderBanner closeAction={closeBanner}>
            <div className={styles.bannerBody}>
                <BannerMessage
                    agency={agency}
                    onTrial={onTrial}
                    isUserAdmin={isUserAdmin}
                    agreementCountInfo={agreementCountInfo}
                />
            </div>
        </HeaderBanner>
    ) : null;
};

export default memo(DocumentBanner);
