import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import useComponentId from '../../../hooks/useComponentId';
import Button from '../../../common/components/Button.js';
import styles from './ButtonSubscribe.module.scss';
import { routes } from '../../../config';

type ButtonEnterPaymentDetailsProps = {
    isUserAdmin: boolean;
};

const BUTTON_HEIGHT = 40;
const TOOLTIP_TEXT = 'Please contact your admin to enter payment details to continue sending documents through';

const ButtonEnterPaymentDetails: React.FC<ButtonEnterPaymentDetailsProps> = ({ isUserAdmin }) => {
    const history = useHistory();
    const toolTipId = useComponentId();

    const handleButtonClick = () => {
        if (isUserAdmin) {
            history.push(routes.ROUTE_SUBSCRIPTION);
        }
    };

    return (
        <Button
            primary
            className={styles.bannerButton}
            height={BUTTON_HEIGHT}
            onClick={handleButtonClick}
            toolTipId={`${toolTipId}-enter-payment-details`}
            tooltip={!isUserAdmin ? TOOLTIP_TEXT : null}
        >
            Enter payment details
        </Button>
    );
};

export default memo(ButtonEnterPaymentDetails);
