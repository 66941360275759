import React from 'react';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { FormTextHidden } from '../../../../components/form/FormText';
import { qldNoticeReasonSet1, qldNoticeReasonSet2 } from './qldNoticeReasons';

export default function QldNoticeReason({ form, disabled }) {
    return (
        <div>
            <div className="formBox-column">
                <div className="nowrap">
                    <label>Notice issued for</label>
                </div>
                {/* Use this for display error message for checkbox group */}
                <FormTextHidden name="qldNoticeReason.error" />
                {form.getFieldState(`qldNoticeReason.error`) && form.getFieldState(`qldNoticeReason.error`).error ? (
                    <>
                        <p className="FormInput">
                            <span className="FormError">{form.getFieldState(`qldNoticeReason.error`).error}</span>
                        </p>
                    </>
                ) : (
                    <br />
                )}
                <div className="nowrap">
                    <div>
                        {qldNoticeReasonSet1.map(item => {
                            const { name, description } = item;
                            const formattedName = `qldNoticeReason.${name}`;
                            const formattedClassName = `qldNoticeReason_${name}`;
                            return (
                                <CheckboxCheck
                                    key={name}
                                    name={formattedName}
                                    label={description}
                                    className={formattedClassName}
                                    disabled={disabled}
                                />
                            );
                        })}
                    </div>
                    <div>
                        {qldNoticeReasonSet2.map(item => {
                            const { name, description } = item;
                            const formattedName = `qldNoticeReason.${name}`;
                            const formattedClassName = `qldNoticeReason_${name}`;
                            return (
                                <CheckboxCheck
                                    key={name}
                                    name={formattedName}
                                    label={description}
                                    className={formattedClassName}
                                    disabled={disabled}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
