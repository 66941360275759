import React from 'react';
import { getPmStepNo, getPmStepTitle } from '../../../../../../utils/agreementUtils';
import AgreementSection from '../../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { LeaseAllInfo } from '../../../../../types';
import { YES_LABEL, YES_VALUE, AGENT_LABEL } from '../../../../../../config';
import { formatCurrencyWithCurrencySign } from '../../../../../../utils/formUtils.js';

interface Props {
    leaseAllInfo: LeaseAllInfo;
}

const AgreementPmPropertyDetail: React.FC<Props> = ({ leaseAllInfo }) => {
    return (
        <div>
            {leaseAllInfo && (
                <AgreementSection
                    title={getPmStepTitle(leaseAllInfo.location, 'pmDetailsRepairs')}
                    step={getPmStepNo(leaseAllInfo.location, 'pmDetailsRepairs')}
                    leaseAllInfo={leaseAllInfo}
                >
                    <div className="agreement-section-text">
                        <div>
                            {leaseAllInfo.pmDetailsRepairs &&
                                leaseAllInfo.pmDetailsRepairs.detailsOfWorkToBeCompleted && (
                                    <p className="name">
                                        Details Of Work To Be Completed By The Landlord On The Property:{' '}
                                        <strong>{leaseAllInfo.pmDetailsRepairs.detailsOfWorkToBeCompleted}</strong>
                                    </p>
                                )}
                        </div>

                        <div>
                            {leaseAllInfo.pmDetailsRepairs && leaseAllInfo.pmDetailsRepairs.nominateStrataDetails && (
                                <div>
                                    <p className="name">
                                        <strong>Nominate Strata Details</strong>
                                    </p>
                                    <p className="name">
                                        Plan No: <strong>{leaseAllInfo.pmDetailsRepairs.strataPlanNo || 'Nil'}</strong>
                                    </p>
                                    <p className="name">
                                        Lot No: <strong>{leaseAllInfo.pmDetailsRepairs.lotNo || 'Nil'}</strong>
                                    </p>
                                    <p className="name">
                                        Strata Managing Agent:&nbsp;
                                        <strong> {leaseAllInfo.pmDetailsRepairs.strataManagingAgent || 'Nil'} </strong>
                                    </p>
                                    <p className="name">
                                        By Laws Provided:&nbsp;
                                        <strong> {leaseAllInfo.pmDetailsRepairs.byLawsprovided ? 'Yes' : 'No'} </strong>
                                    </p>
                                </div>
                            )}
                        </div>

                        <div>
                            <div className="propertyDetails">
                                <p className="name">Council area:&nbsp;</p>
                                <strong>{leaseAllInfo?.pmDetailsRepairs?.councilArea}</strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Strata/Community title:&nbsp;</p>
                                <strong>{leaseAllInfo?.pmDetailsRepairs?.strataCommunityTitle}</strong>
                            </div>
                            {leaseAllInfo?.pmDetailsRepairs?.strataCommunityTitle === YES_LABEL && (
                                <div>
                                    <div className="propertyDetails">
                                        <p className="name">Company Name:&nbsp;</p>
                                        <strong>{leaseAllInfo?.pmDetailsRepairs?.strataManagerName}</strong>
                                    </div>
                                    <div className="propertyDetails">
                                        <p className="name">Company Address:&nbsp;</p>
                                        <strong>{leaseAllInfo?.pmDetailsRepairs?.strataAddress}</strong>
                                    </div>
                                    <div className="propertyDetails">
                                        <p className="name">Company Phone:&nbsp;</p>
                                        <strong>{leaseAllInfo?.pmDetailsRepairs?.strataPhone}</strong>
                                    </div>
                                    <div className="propertyDetails">
                                        <p className="name">Company Email:&nbsp;</p>
                                        <strong>{leaseAllInfo?.pmDetailsRepairs?.strataEmail}</strong>
                                    </div>
                                </div>
                            )}
                            <div className="propertyDetails">
                                <p className="name">Special or other matters relating to the Property:&nbsp;</p>
                                <strong>{leaseAllInfo.pmDetailsRepairs?.specialMatters || 'Not Stated'}</strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Maximum expenditure allowed without Landlord's approval:&nbsp;</p>
                                <strong>
                                    {formatCurrencyWithCurrencySign(
                                        leaseAllInfo?.pmDetailsRepairs?.maximumExpenditure,
                                        true
                                    )}
                                </strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Does the Property have a swimming pool?&nbsp;</p>
                                <strong>{leaseAllInfo?.pmDetailsRepairs?.haveSwimmingPool}</strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Smoke alarms (residential):&nbsp;</p>
                                <strong>{leaseAllInfo?.pmDetailsRepairs?.smokeAlarmsCompliantType}</strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Smoke alarms to be maintained by:&nbsp;</p>
                                <strong>{leaseAllInfo.pmDetailsRepairs?.smokeAlarmsMaintainedByLabel}</strong>
                            </div>
                            <div className="propertyDetails">
                                <p className="name">Repairs prior to first tenancy:&nbsp;</p>
                                <strong>{leaseAllInfo?.pmDetailsRepairs?.isRepairRequiredBeforeTenancy}</strong>
                            </div>
                            {leaseAllInfo?.pmDetailsRepairs?.isRepairRequiredBeforeTenancy === YES_LABEL && (
                                <div className="propertyDetails">
                                    <p className="name">Repairs to be made prior to first tenancy:&nbsp;</p>
                                    <strong>{leaseAllInfo?.pmDetailsRepairs?.repairBeforeTenancyDetails}</strong>
                                </div>
                            )}
                            <div className="propertyDetails">
                                <p className="name">Repairs required on a regular basis:&nbsp;</p>
                                <strong>{leaseAllInfo?.pmDetailsRepairs?.isRegularRepairRequired}</strong>
                            </div>
                            {leaseAllInfo?.pmDetailsRepairs?.isRegularRepairRequired === YES_LABEL && (
                                <div className="propertyDetails">
                                    <p className="name">Repairs to be made on a regular basis:&nbsp;</p>
                                    <strong>{leaseAllInfo?.pmDetailsRepairs?.regularRepairDetails}</strong>
                                </div>
                            )}
                        </div>
                    </div>
                </AgreementSection>
            )}
        </div>
    );
};

export default AgreementPmPropertyDetail;
