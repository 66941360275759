import React from 'react';
import { FormTextRegular, FormMaskedText } from '../../../../../components/form/FormText.js';
import { Principal as PrincipalType } from '../EstimatedSellingPriceForm.model.js';

import styles from './Principal.module.scss';

interface PrincipalProps {
    index: string;
    principal: PrincipalType;
    isReadOnly?: boolean;
}
const Principal: React.FC<PrincipalProps> = ({ index, principal, isReadOnly }) => {
    return (
        <div className={styles.principal}>
            <FormTextRegular
                name={`principals[${index}].name`}
                label="Name"
                required
                value={principal.name}
                disabled={isReadOnly}
            />
            <FormTextRegular
                name={`principals[${index}].email`}
                label="Email"
                required
                value={principal.email}
                disabled={isReadOnly}
            />
            <FormTextRegular
                name={`principals[${index}].phone`}
                label="Phone"
                value={principal.phone}
                disabled={isReadOnly}
            />
            <FormTextRegular
                name={`principals[${index}].address`}
                label="Address"
                required
                value={principal.address}
                disabled={isReadOnly}
            />
            <FormMaskedText
                name={`principals[${index}].ABN`}
                label="ABN"
                mask="11 111 111 111"
                value={principal.ABN}
                disabled={isReadOnly}
                dataTest={`principals[${index}].ABN`}
            />
            <FormMaskedText
                name={`principals[${index}].ACN`}
                label="ACN"
                mask="11 111 111 111"
                value={principal.ACN}
                disabled={isReadOnly}
                dataTest={`principals[${index}].ACN`}
            />
        </div>
    );
};
export default Principal;
