import React, { useState } from 'react';
import cx from 'classnames';
import axios from 'axios';
import { FormTextRegular } from '../../../../components/form/FormText';
import { AsyncSelect } from '../../../../components/form/AsyncSelect';
import { customSelectStyle } from '../../../../utils/formUtils';
import logger from '../../../../utils/logger';
import Button from '../../../../common/components/Button';
import {
    CREATE_A_FLK_TEMPLATE,
    CREATE_A_FLK_GLOBAL_TEMPLATE,
    DOCUMENT_CREATE_A_FLK,
    FLK_TEMPLATE_LABEL
} from '../../../../config';
import { getGeneralAppLabel } from '../../../../utils/labelUtils';
import { debounce } from 'lodash';

const CreateCreateAFlk = ({
    className,
    values,
    isTemplate,
    changeTemplate,
    closeModal,
    onClickStart,
    selectedTemplate,
    setAvailableTemplates,
    isSendingCreateFlk,
    loggedInUser,
    isLoadingTemplates
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const canStart = values => {
        return !(!values.documentTitle || (isTemplate && !values.templateName));
    };
    const noOptionsMessage = props => {
        return (
            <div className="no-option-message" {...props}>
                {searchQuery
                    ? searchQuery.length < 3
                        ? 'Your search is too short, keep typing'
                        : 'No result found for your input'
                    : 'Please type to search templates and agreements'}
            </div>
        );
    };

    // load options using API call
    const loadOptions = (inputValue, callback) => {
        setSearchQuery(inputValue);

        // we need to load all templates in default. Also need 3 characters to start search
        if (!inputValue || (inputValue && inputValue.length > 2)) {
            const agencyTemplatesLabel = getGeneralAppLabel('agency_templates', loggedInUser.accountType);
            return axios
                .post(`/api/template/search-document-templates-and-documents`, {
                    templateTypesList: [CREATE_A_FLK_TEMPLATE, CREATE_A_FLK_GLOBAL_TEMPLATE],
                    documentTypesList: [isTemplate ? DOCUMENT_CREATE_A_FLK : ''],
                    searchQuery: inputValue
                })
                .then(response => {
                    const templates = response.data.templates ? response.data.templates : [];
                    const documents = response.data.documents ? response.data.documents : [];
                    setAvailableTemplates([...templates, ...documents]);
                    const options = [
                        {
                            label: agencyTemplatesLabel,
                            options: []
                        },
                        {
                            label: FLK_TEMPLATE_LABEL,
                            options: []
                        },
                        {
                            label: 'Previous Documents',
                            options: []
                        }
                    ];
                    templates.forEach(template => {
                        if (template.docType === CREATE_A_FLK_GLOBAL_TEMPLATE) {
                            options[1].options.push({
                                label: template.templateName,
                                value: template.id,
                                docType: template.docType
                            });
                        } else if (template.docType === CREATE_A_FLK_TEMPLATE) {
                            options[0].options.push({
                                label: template.templateName,
                                value: template.id,
                                docType: template.docType
                            });
                        }
                    });
                    documents.forEach(document => {
                        options[2].options.push({
                            label: document.documentTitle,
                            value: document.id,
                            docType: document.docType
                        });
                    });
                    return callback(options);
                })
                .catch(error => {
                    logger.push({
                        status: 'error',
                        env: ENVIRONMENT,
                        message: 'Something went wrong getting templates',
                        error_message: error.message,
                        errorObj: error
                    });
                });
        }
        return callback([]);
    };

    const loadOptionsDebounced = debounce(loadOptions, 500);

    return (
        <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className={cx('template-selection', className)}>
                {isTemplate && <FormTextRegular name="templateName" label="Template Name" disabled={values.disabled} />}
                <FormTextRegular name="documentTitle" label="Document title" disabled={values.disabled} />
                <AsyncSelect
                    defaultOptions
                    isClearable={true}
                    value={selectedTemplate}
                    styles={customSelectStyle}
                    loadOptions={loadOptionsDebounced}
                    onChange={changeTemplate}
                    selectClassName="async-select"
                    label="Template"
                    placeholder="Select Template..."
                    components={{
                        NoOptionsMessage: noOptionsMessage
                    }}
                    isSearchable
                />
                <div className="footer footer-draft">
                    <div className="button-row">
                        <div className="button-rent-increase">
                            <Button secondary className="btn-close" height={40} onClick={closeModal}>
                                Cancel
                            </Button>
                        </div>
                        <div className="button-start">
                            <Button
                                primary
                                className="btn-start"
                                height={40}
                                type="button"
                                onClick={() => onClickStart()}
                                disabled={!canStart(values) || isSendingCreateFlk || isLoadingTemplates}
                                loading={isSendingCreateFlk || isLoadingTemplates}
                            >
                                Start
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CreateCreateAFlk;
