import React from 'react';
import { FormTextRegular } from '../form/FormText';
import { FormEmail } from '../form/FormEmail';
import { FormPhone } from '../form/FormPhone';
import { SelectField } from '../form/FormSelect';
import { entryPartyTypes } from '../../config';
import { isLeaseLocationNSW } from '../../utils/agreementUtils.js';

const EntryPartyGroup = ({ index, removeEntryParty, showHeader, values, disabled }) => {
    let header;
    if (showHeader) {
        header = <h3>Party {index + 1}</h3>;
    } else {
        header = (
            <span className="removePerson">
                <h3>Party {index + 1}</h3>
                <button type="button" onClick={() => removeEntryParty(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="tenant">
            {header}
            <div className="formBox">
                <div className="formBox-column">
                    <FormTextRegular
                        name={`entryParty.parties[${index}].name`}
                        label="Name of company/person entering the premises"
                        required
                        disabled={disabled}
                    />
                    <SelectField
                        label="Authority type of party entering the premises"
                        name={`entryParty.parties[${index}].type`}
                        options={entryPartyTypes}
                        required
                        disabled={disabled}
                    />
                    {values?.entryParty?.parties?.[index]?.type === 'Other' && (
                        <FormTextRegular
                            name={`entryParty.parties[${index}].otherType`}
                            label="If other, state type"
                            required
                            disabled={disabled}
                        />
                    )}
                    <div className="nowrap large">
                        <FormTextRegular
                            name={`entryParty.parties[${index}].phone`}
                            label="Phone"
                            required
                            disabled={disabled}
                        />
                        {isLeaseLocationNSW(values.location) ? (
                            <FormEmail
                                name={`entryParty.parties[${index}].email`}
                                label="Email"
                                required
                                disabled={disabled}
                            />
                        ) : (
                            <div /> // This div is used to fill up space.
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EntryPartyGroup;
