import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getPmRentAndPayment, getPmAgreementTerm } from '../../../selectors/lease/pmLease';

import {
    PAYMENT_METHOD_EFT,
    propertyManagementRentPeriodsQLD,
    PAYMENT_METHOD_OWNER_TO_ENTER,
    PAYMENT_METHOD_CHEQUE,
    isPaymentMethodAvailable,
    LEASE_TYPE_PROPERTY_MANAGEMENT
} from '../../../config';
import { FormTextRegular } from '../../../components/form/FormText';
import { FormButtonSelect } from '../../../components/form/responsive/FormButtonSelect';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import { isLeaseLocationQLD, isTemplate } from '../../../utils/agreementUtils';
import '../../../sass/management/rentAndTerm.scss';
import { getLabel } from '../../../utils/labelUtils';
import { FormYesNoCheckbox } from '../../../components/form/responsive/FormSingleCheckbox';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import { getUserInfo } from '../../../selectors/user';
import { getAgencyTimezoneDate } from '../../../utils/dateUtils';

const PM_RENT_PAYMENT_FORM = 'pmRentAndPaymentForm';

const initState = {
    rent: '$',
    landlordDisbursement: 'Monthly',
    statementIssued: 'Monthly',
    paymentData: {
        EFT: {
            enabled: false,
            details: {
                accountName: '',
                accountNumber: '',
                bankName: '',
                BSB: '',
                reference: ''
            }
        },
        Cheque: {
            enabled: false,
            details: {
                madePayableTo: '',
                address: ''
            }
        },
        OwnerToEnter: {
            enabled: false
        }
    },
    rentAmountPerMonth: ''
};

const RentTerm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const loggedInUser = useSelector(getUserInfo);
    const reduxPmRentAndPayment = useSelector(getPmRentAndPayment);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmRentAndPayment, setPmRentAndPayment] = useState(
        reduxPmRentAndPayment || {
            ...initState,
        }
    );
    const [error, setError] = useState('');

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_RENT_PAYMENT_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        const payload = {
            ...values,
            bypassFormValidation: currentRef.bypassFormValidation
        };
        return updatePmRentAndPayment(payload)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmRentAndPayment')) {
                    return error.response.data.errors.pmRentAndPayment;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmRentAndPayment = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-rent-and-payment`, data);
    };

    const validateData = values => {
        let isValid = false;
        if (isTemplate(leaseType)) {
            isValid = true;
        } else {
            for (const key in values.paymentData) {
                // eslint-disable-next-line no-prototype-builtins
                if (values.paymentData.hasOwnProperty(key)) {
                    isValid = isValid || !!values.paymentData[key].enabled;
                }
            }
        }
        if (!isValid) {
            setError('At least one payment method must be selected');
        } else {
            setError('');
        }
    };

    const handleOwnerToEnter = (value, values) => {
        let newState = cloneDeep(values);
        if (value) {
            newState.paymentData.EFT.enabled = false;
            newState.paymentData.Cheque.enabled = false;
            newState.paymentData.OwnerToEnter.enabled = true;
        }
        setPmRentAndPayment(newState);
        validateData(newState);
    };

    const handleCheque = (value, values) => {
        let newState = cloneDeep(values);
        if (value) {
            newState.paymentData.EFT.enabled = false;
            newState.paymentData.Cheque.enabled = true;
            newState.paymentData.OwnerToEnter.enabled = false;
        }
        setPmRentAndPayment(newState);
        validateData(newState);
    };
    const handleEFT = (value, values) => {
        let newState = cloneDeep(values);
        if (value) {
            newState.paymentData.EFT.enabled = true;
            newState.paymentData.Cheque.enabled = false;
            newState.paymentData.OwnerToEnter.enabled = false;
        }
        setPmRentAndPayment(newState);
        validateData(newState);
    };

    useEffect(() => {
        validateData(pmRentAndPayment);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pmRentAndPayment]);

    return (
        <div className="form-container rent rent-term payment">
            <Form onSubmit={submitForm} initialValues={pmRentAndPayment}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="mobile-multi-select-wrapper"
                            noValidate
                            id={PM_RENT_PAYMENT_FORM}
                        >
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <FormTextRegular name="rent" label="Rent" required />
                                <FormButtonSelect
                                    name="landlordDisbursement"
                                    options={propertyManagementRentPeriodsQLD}
                                    value={values.landlordDisbursement}
                                    label={`${getLabel(
                                        'landlord',
                                        location,
                                        LEASE_TYPE_PROPERTY_MANAGEMENT
                                    )} disbursement`}
                                    isButtonGroup={true}
                                />
                                {values.landlordDisbursement === 'Other' && (
                                    <FormTextRegular
                                        name="otherLandlordDisbursement"
                                        label={`Other ${getLabel(
                                            'tenantLower',
                                            location,
                                            LEASE_TYPE_PROPERTY_MANAGEMENT
                                        )} disbursement`}
                                        required
                                    />
                                )}
                                <FormButtonSelect
                                    name="statementIssued"
                                    options={propertyManagementRentPeriodsQLD}
                                    value={values.statementIssued}
                                    label="Statement issued"
                                    isButtonGroup={true}
                                />
                                {values.statementIssued === 'Other' && (
                                    <FormTextRegular
                                        name="otherStatementIssued"
                                        label="Other statement issued"
                                        required
                                    />
                                )}
                                <p className="FormInput">Payment method</p>
                                {isPaymentMethodAvailable(PAYMENT_METHOD_EFT, leaseType, location) && (
                                    <React.Fragment>
                                        <CheckboxCheck
                                            name="paymentData.EFT.enabled"
                                            label="EFT"
                                            className={'eft mobile-multi-select-enabled'}
                                            onClick={value => handleEFT(value, values)}
                                        />
                                        {values.paymentData.EFT.enabled && (
                                            <div className="details">
                                                <p className="name">Details for EFT</p>
                                                <FormTextRegular
                                                    name="paymentData.EFT.details.accountName"
                                                    label="Account name"
                                                    required
                                                />
                                                <FormTextRegular
                                                    name="paymentData.EFT.details.bankName"
                                                    label="Bank name"
                                                    required
                                                />
                                                <FormTextRegular
                                                    name="paymentData.EFT.details.BSB"
                                                    label="BSB"
                                                    required
                                                />
                                                <FormTextRegular
                                                    name="paymentData.EFT.details.accountNumber"
                                                    label="Account number"
                                                    required
                                                />
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                                {isPaymentMethodAvailable(PAYMENT_METHOD_CHEQUE, leaseType, location) && (
                                    <React.Fragment>
                                        <CheckboxCheck
                                            name="paymentData.Cheque.enabled"
                                            label="Cheque"
                                            className={'cheque mobile-multi-select-enabled'}
                                            onClick={value => handleCheque(value, values)}
                                        />
                                        {values.paymentData.Cheque.enabled && (
                                            <div className="details">
                                                <p className="name">Details for Cheque</p>
                                                <FormTextRegular
                                                    name="paymentData.Cheque.details.madePayableTo"
                                                    label="Made payable to"
                                                    required
                                                />
                                                <FormTextRegular
                                                    name="paymentData.Cheque.details.address"
                                                    label="Address"
                                                    required
                                                />
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                                {isPaymentMethodAvailable(PAYMENT_METHOD_OWNER_TO_ENTER, leaseType, location) && (
                                    <CheckboxCheck
                                        className="mobile-multi-select-enabled"
                                        name="paymentData.OwnerToEnter.enabled"
                                        label="Owner To Enter"
                                        onClick={value => handleOwnerToEnter(value, values)}
                                    />
                                )}
                                <p className="FormInput">
                                    <span className="FormError">{error}</span>
                                </p>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(RentTerm);
