import { FormApi } from 'final-form';
import React from 'react';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { IconSize } from '../../../../common/components/Icon';
import TooltipIcon, { TooltipIconType } from '../../../../common/components/TooltipIcon';
import { IntegrationSearchSelect } from '../../../../components/dashboard/integration/IntegrationSearchSelect.js';
import IntegrationAddressInput from '../../../../components/lease/IntegrationAddressInput.js';
import {
    getAgencyManagedIntegration,
    getAgencyPropertyMeIntegration,
    getAgencyPropertyTreeIntegration
} from '../../../../selectors/agency.js';
import { User } from '../../../../types/User';
import { IntegrationDetails, getIntegrationPropertyDetails } from './utils';

import styles from './IntegrationAddressSection.module.scss';

type IntegrationAddressSectionProps = {
    showIntegrationSearch: boolean;
    setShowIntegrationSearch: (show: boolean) => void;
    setIsLoadingIntegration: (loading: boolean) => void;
    loggedInUser: User;
    integrationError?: string;
    setIntegrationError: (error?: string) => void;
};

// We only allow one integration provider at a time
const getIntegrationProviderDisplayName = (
    propertyMeIntegration: boolean,
    propertyTreeIntegration: boolean,
    managedIntegration: boolean
) => {
    if (propertyMeIntegration) {
        return 'PropertyMe';
    } else if (propertyTreeIntegration) {
        return 'PropertyTree';
    } else if (managedIntegration) {
        return 'Managed';
    }
};

const IntegrationAddressSection: React.FC<IntegrationAddressSectionProps> = ({
    showIntegrationSearch,
    setShowIntegrationSearch,
    setIsLoadingIntegration,
    loggedInUser,
    integrationError,
    setIntegrationError
}) => {
    const form = useForm();

    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const managedIntegration = useSelector(getAgencyManagedIntegration);

    const integrationProviderDisplayName = getIntegrationProviderDisplayName(
        !!propertyMeIntegration,
        !!propertyTreeIntegration,
        !!managedIntegration
    );

    const addIntegrationDetailsToDocument = async (form: FormApi, selectedIntegration?: IntegrationDetails) => {
        try {
            setIsLoadingIntegration(true);
            const propertyDetails = await getIntegrationPropertyDetails(selectedIntegration);
            setIsLoadingIntegration(false);

            if (propertyDetails) {
                form.batch(() => {
                    form.change('address', propertyDetails.address);
                    form.change('integrationPropertyId', propertyDetails.id);
                    if (!form.getState().values.documentTitle) {
                        form.change('documentTitle', propertyDetails.address);
                    }
                });
            }
        } catch (error) {
            setIsLoadingIntegration(false);
            setIntegrationError('Unable to load integration details');
        }
    };

    const resetIntegration = (form: FormApi) => {
        setIntegrationError(undefined);
        const { address, documentTitle } = form.getState().values;
        form.batch(() => {
            if (documentTitle === address) {
                form.change('documentTitle', '');
            }
            form.change('address', '');
            form.change('integrationPropertyId', '');
        });
    };
    return (
        <>
            {(propertyMeIntegration || propertyTreeIntegration || managedIntegration) && (
                <>
                    <div className={styles.addressSectionHeading}>
                        <h3>Address for the property notice</h3>
                        <TooltipIcon type={TooltipIconType.HELP} iconSize={IconSize.SMALL}>
                            <div className={styles.integrationTooltipHeading}>{integrationProviderDisplayName}</div>
                            <div className={styles.integrationTooltipBody}>
                                Enhance your workflow by linking this document to {integrationProviderDisplayName}.
                                Simply enter the property's address, and after the document is completed it will be
                                automatically pushed into your CRM.
                            </div>
                        </TooltipIcon>
                    </div>
                    <IntegrationSearchSelect
                        className={styles.integrationSearchSelect}
                        propertyMeIntegration={propertyMeIntegration}
                        propertyTreeIntegration={propertyTreeIntegration}
                        managedIntegration={managedIntegration}
                        setIntegrationSearch={(newValue: boolean) => {
                            resetIntegration(form);
                            setShowIntegrationSearch(newValue);
                        }}
                        isForDocuments={true}
                        isIntegrationSearchEnabled={showIntegrationSearch}
                    />
                </>
            )}
            {showIntegrationSearch && (
                <IntegrationAddressInput
                    name="address"
                    placeholder="Address"
                    onChange={() => resetIntegration(form)}
                    onSelect={(selectedIntegration: IntegrationDetails) => {
                        return addIntegrationDetailsToDocument(form, selectedIntegration);
                    }}
                    loggedInUser={loggedInUser}
                />
            )}
            {integrationError && <div className={styles.integrationError}>{integrationError}</div>}
        </>
    );
};

export default IntegrationAddressSection;
