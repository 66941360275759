import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { isEmpty, has } from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../../sass/resendModal.scss';
import '../../../../sass/reactConfirmAlert.scss';
import { getActiveSubscriptionForAgency } from '../../../../api/subscriptionApi';
import { openCancelledPlanModal, openConfirmPlanUpgradeModal } from '../../../../actions/subscription';
import { openResendForSigningModal } from '../../../../actions/resendLease';
import { subscriptionStatus, NSW_STATE } from '../../../../config';
import {
    allRequestsResolved,
    isLeaseTypeSales,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeCommercialLease
} from '../../../../utils/agreementUtils';
import * as validators from '../../../Validate';
import {
    LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_SMS,
    LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_EMAIL
} from '../../../../config';

import RenderSendForSigningFormResTen from './RenderSendForSigningFormResTen';
import RenderSendForSigningFormSales from './RenderSendForSigningFormSales';
import RenderSendForSigningFormPm from './RenderSendForSigningFormPm';
import RenderSendForSigningFormCommercialLease from './RenderSendForSigningFormCommercialLease';
import { DEADLINE_OPTIONS } from '../../../../utils/formUtils';
import useDoubleSendGuard, { clearDoubleSendGuard } from '../../../../hooks/useDoubleSendGuard';

function RenderSendForSigningForm(props) {
    const [isLoading, setIsLoading] = useState(false);
    const doubleSendGuard = useDoubleSendGuard();

    const sendForSigning = values => doubleSendGuard(() => {
        props.setSigning(values);
    });

    const handleSendForSigning = values => {
        //Validate we have email address for selected landlords
        if (props.leaseAllInfo.location === NSW_STATE && values.isSendInformationStatement) {
            const selectedPersons = values.persons.filter(item => item.isPersonSelect); //Reurns new filtered array
            if (selectedPersons.length > 0) {
                let validated = true;
                selectedPersons.forEach(person => {
                    if (!person.sendMethod) {
                        landlordSendMethodAlert();
                        validated = false;
                    } else if (
                        person.sendMethod === LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_EMAIL &&
                        (!person.email || !validators.isCorrectEmail(person.email))
                    ) {
                        landlordEmailAlert();
                        validated = false;
                    } else if (
                        person.sendMethod === LANDLORD_INFORMATION_STATEMENT_SEND_METHOD_SMS &&
                        (!person.mobile || !validators.isMobilePhone(person.mobile))
                    ) {
                        landlordPhoneAlert();
                        validated = false;
                    }
                });
                if (validated) {
                    setIsLoading(true);
                    checkActiveSubscription(values);
                }
            } else if (selectedPersons.length === 0) {
                selectedAuthorityAlert();
            }
        } else {
            // Get the active subscription
            setIsLoading(true);
            checkActiveSubscription(values);
        }
    };

    const checkActiveSubscription = values => {
        // if its not not a residential agreement, allow send for signing always
        // or if the agreement has been sent before don't perform the check
        if (!props.leaseAllInfo.isAgreementTypeResidential || props.leaseAllInfo.firstSentForSigning) {
            const sendForSigningResult = sendForSigning(values);
            setIsLoading(false);
            return sendForSigningResult;
        }

        getActiveSubscriptionForAgency(props.agencyId)
            .then(response => {
                let { amountOfAgreementsSentThisMonth, tariffPlan } = response.data;
                let agreementsLeftOnSubscription = tariffPlan.agreementsPerMonth.max - amountOfAgreementsSentThisMonth;

                // If the agency does not have an active subscription
                if (isEmpty(tariffPlan) || !tariffPlan.active) {
                    props.openCancelledPlanModal();
                    setIsLoading(false);
                    return;
                }

                if (
                    tariffPlan.agreementsPerMonth.max !== 'Infinity' &&
                    tariffPlan.status !== subscriptionStatus.TRIALING &&
                    amountOfAgreementsSentThisMonth >= tariffPlan.agreementsPerMonth.max
                ) {
                    // Open confirm plan upgrade modal
                    props.openConfirmPlanUpgradeModal(tariffPlan);
                    setIsLoading(false);
                    return;
                }

                // Show alert if agency have less than 3 lease agreements left on their subscription
                if (agreementsLeftOnSubscription <= 3 && tariffPlan.status !== subscriptionStatus.TRIALING) {
                    // Show 0 agreements left if they have created more than limit while on trial
                    if (agreementsLeftOnSubscription < 0) {
                        agreementsLeftOnSubscription = 0;
                    }

                    leaseAgreementLimitAlert(values, tariffPlan, agreementsLeftOnSubscription);
                    setIsLoading(false);
                    return;
                }

                // Otherwise send the lease for signing
                sendForSigning(values);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);

                if (has(error, 'response.status') && error.response.status === 404) {
                    props.openCancelledPlanModal();
                }
            });
    };

    const leaseAgreementLimitAlert = (values, tariffPlan, agreementsLeftOnSubscription) => {
        confirmAlert({
            title: '',
            message: `You are reaching the limit of your current ${tariffPlan.title} subscription. Currently, you have ${agreementsLeftOnSubscription} agreements left in your plan`,
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {
                        sendForSigning(values);
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {}
                }
            ]
        });
    };

    const landlordSendMethodAlert = () => {
        confirmAlert({
            title: 'landlord information statement authority send method is required',
            message: `You have selected a landlord to receive the landlord information statement authority, please select a send method for the selected landlord.`,
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {}
                }
            ]
        });
    };

    const landlordEmailAlert = () => {
        confirmAlert({
            title: 'Landlord email address is not valid',
            message: `You have selected a landlord to receive the landlord information statement authority, please provide a valid email address for the selected landlord.`,
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {}
                }
            ]
        });
    };

    const landlordPhoneAlert = () => {
        confirmAlert({
            title: 'Landlord mobile number is not valid',
            message: `You have selected a landlord to receive the landlord information statement authority, please provide a valid mobile number for the selected landlord.`,
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {}
                }
            ]
        });
    };

    const selectedAuthorityAlert = () => {
        confirmAlert({
            title: 'Send statement authority is selected',
            message: `Please select a landlord to receive the statement.`,
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {}
                }
            ]
        });
    };

    const isSubscriptionCancelled = () => {
        const { agency } = props;
        return has(agency, 'tariffPlan.active') && !agency.tariffPlan.active;
    };

    const confirmRequestsResolvedAndSendForSigning = values => {
        if (!checkAllRequestsResolved()) {
            alert('You must resolve all change requests before you can send this lease for signing');
            return;
        }

        props.openResendForSigningModal(values);
    };

    const checkAllRequestsResolved = () => {
        const changeRequestList = props.leaseAllInfo.changeRequestList;
        return allRequestsResolved(changeRequestList);
    };

    const { leaseAllInfo, isPostSigningFail, postSigningFailMessage, isPostReSigningFail, isResendingForSigning } = props;

    let optionsNumber = DEADLINE_OPTIONS;

    const leaseType = props.leaseType ? props.leaseType : leaseAllInfo.leaseType;

    useEffect(() => {
        if (isPostSigningFail || isPostReSigningFail) {
            clearDoubleSendGuard();
        }
    }, [isPostSigningFail, isPostReSigningFail]);

    return (
        <div>
            {isLeaseTypeResidentialTenancy(leaseType) && (
                <RenderSendForSigningFormResTen
                    leaseAllInfo={leaseAllInfo}
                    leaseType={leaseType}
                    optionsNumber={optionsNumber}
                    isPostSigningFail={isPostSigningFail}
                    postSigningFailMessage={postSigningFailMessage}
                    isPostReSigningFail={isPostReSigningFail}
                    confirmRequestsResolvedAndSendForSigning={confirmRequestsResolvedAndSendForSigning}
                    handleSendForSigning={handleSendForSigning}
                    isSubscriptionCancelled={isSubscriptionCancelled}
                    isLoading={isLoading || isResendingForSigning}
                />
            )}
            {isLeaseTypeSales(leaseType) && (
                <RenderSendForSigningFormSales
                    leaseAllInfo={leaseAllInfo}
                    leaseType={leaseType}
                    isPostSigningFail={isPostSigningFail}
                    isPostReSigningFail={isPostReSigningFail}
                    postSigningFailMessage={postSigningFailMessage}
                    confirmRequestsResolvedAndSendForSigning={confirmRequestsResolvedAndSendForSigning}
                    handleSendForSigning={handleSendForSigning}
                    isSubscriptionCancelled={isSubscriptionCancelled}
                    isLoading={isLoading || isResendingForSigning}
                />
            )}
            {isLeaseTypePm(leaseType) && (
                <RenderSendForSigningFormPm
                    leaseAllInfo={leaseAllInfo}
                    leaseType={leaseType}
                    optionsNumber={optionsNumber}
                    isPostSigningFail={isPostSigningFail}
                    postSigningFailMessage={postSigningFailMessage}
                    isPostReSigningFail={isPostReSigningFail}
                    confirmRequestsResolvedAndSendForSigning={confirmRequestsResolvedAndSendForSigning}
                    handleSendForSigning={handleSendForSigning}
                    isSubscriptionCancelled={isSubscriptionCancelled}
                    isLoading={isLoading || isResendingForSigning}
                />
            )}
            {isLeaseTypeCommercialLease(leaseType) && (
                <RenderSendForSigningFormCommercialLease
                    leaseAllInfo={leaseAllInfo}
                    leaseType={leaseType}
                    optionsNumber={optionsNumber}
                    isPostSigningFail={isPostSigningFail}
                    postSigningFailMessage={postSigningFailMessage}
                    isPostReSigningFail={isPostReSigningFail}
                    confirmRequestsResolvedAndSendForSigning={confirmRequestsResolvedAndSendForSigning}
                    handleSendForSigning={handleSendForSigning}
                    isSubscriptionCancelled={isSubscriptionCancelled}
                    isLoading={isLoading || isResendingForSigning}
                />
            )}
        </div>
    );
}

export default connect(
    state => ({
        agencyId: state.user.agencyId,
        agency: state.user.agency,
        lease: state.lease,
        leaseType: state.lease.common.leaseType,
        isPostSigningFail: state.lease.common.isPostSigningFail,
        postSigningFailMessage: state.lease.common.postSigningFailMessage,
        isPostReSigningFail: state.resendLease.isPostReSigningFail,
        isResendingForSigning: state.resendLease.isResendingForSigning
    }),
    {
        openConfirmPlanUpgradeModal: openConfirmPlanUpgradeModal,
        openCancelledPlanModal: openCancelledPlanModal,
        openResendForSigningModal: openResendForSigningModal
    }
)(RenderSendForSigningForm);
