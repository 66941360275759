import {
    OPEN_RESEND_LANDLORD_INFORMATION_MODAL,
    CLOSE_RESEND_LANDLORD_INFORMATION_MODAL,
    UPDATE_LEASE_DATA
} from './types';

export function openResendLandlordInformationStatementModal() {
    return {
        type: OPEN_RESEND_LANDLORD_INFORMATION_MODAL
    };
}

export function closeResendLandlordInformationStatementModal() {
    return {
        type: CLOSE_RESEND_LANDLORD_INFORMATION_MODAL
    };
}
export function updateLeaseData(lease) {
    return {
        type: UPDATE_LEASE_DATA,
        payload: {
            lease
        }
    };
}
