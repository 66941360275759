import React, { Component } from 'react';
import * as dashboard from '../../../actions/dashboard';

class Auction extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { leaseType, leaseStatus } = this.props;

        return <div />;
    }
}

export default Auction;
