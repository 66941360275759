import React, { memo } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../../config';
import { getResTenLabel } from '../../../../../utils/labelUtils';
import { FormRadioGroup } from '../../../../form/FormRadioGroup';
import { PetDetailGroup } from '../PetDetailGroup';

const AllowAnimals = ({ values, allowPets, clearPets, addPet, push, additional, location }) => {
    return (
        <React.Fragment>
            <FormRadioGroup
                label={getResTenLabel('tenantPet', location)}
                name={'allowAnimals'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'allow-animals-yes',
                        id: 'allow-animals-yes',
                        value: YES_VALUE,
                        onClick: () => allowPets(push, values)
                    },
                    {
                        label: NO_LABEL,
                        className: 'allow-animals-no',
                        id: 'allow-animals-no',
                        value: NO_VALUE,
                        onClick: () => clearPets(values)
                    }
                ]}
            />
            {values.allowAnimals === YES_VALUE && (
                <div className="additional-group">
                    <div className="animals">
                        <div className="formBox-column">
                            <p>
                                {`The ${getResTenLabel('landlordLower', location)} agrees that the ${getResTenLabel(
                                    'tenantLower',
                                    location
                                )} may keep the following animals on
                                the residential premises: (Suggested information: type/breed, weight,
                                council registration number)`}
                            </p>
                            <FieldArray name="animals.pets" initialValue={additional.animals.pets}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <PetDetailGroup
                                                    key={index}
                                                    index={index}
                                                    pets={fields.value[index]}
                                                    removePet={() => fields.remove(index)}
                                                />
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button">
                                <button type="button" onClick={() => addPet(push)}>
                                    Add pet
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default memo(AllowAnimals);
