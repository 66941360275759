import {
    GET_STATISTICS_REQUEST,
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_FAIL,
    GET_AGREEMENTS_PER_MONTH_REQUEST,
    GET_AGREEMENTS_PER_MONTH_SUCCESS,
    GET_AGREEMENTS_PER_MONTH_FAIL,
    GET_ALL_USERS_REQUEST,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAIL,
    GET_ALL_AGENCIES_REQUEST,
    GET_ALL_AGENCIES_SUCCESS,
    GET_ALL_AGENCIES_FAIL,
    CHECK_USER_CAN_VIEW_STATISTICS_SUCCESS,
    CHECK_USER_CAN_VIEW_STATISTICS_FAIL,
    GET_AVERAGE_RENT_10KM_SYDNEY_FAIL,
    GET_AVERAGE_RENT_10KM_SYDNEY_SUCCESS,
    GET_AVERAGE_RENT_10KM_SYDNEY_REQUEST,
    GET_AGENT_REPORTS_REQUEST,
    GET_AGENT_REPORTS_SUCCESS,
    GET_AGENT_REPORTS_FAIL,
    GET_AGENT_REPORT_REQUEST,
    GET_AGENT_REPORT_SUCCESS,
    GET_AGENT_REPORT_FAIL,
    GET_ALL_UPLOAD_A_DOCUMENTS_REQUEST,
    GET_ALL_UPLOAD_A_DOCUMENTS_SUCCESS,
    GET_ALL_UPLOAD_A_DOCUMENTS_FAIL,
    GET_ALL_BUILD_A_DOCUMENTS_REQUEST,
    GET_ALL_BUILD_A_DOCUMENTS_SUCCESS,
    GET_ALL_BUILD_A_DOCUMENTS_FAIL
} from '../actions/types';

import produce from 'immer';

const initState = {
    canUserViewStatistics: false,
    isLoadingStatistics: false,
    isLoadingAllUserInfo: false,
    isLoadingAllAgenciesInfo: false,
    isLoadingAgentReports: false,
    isLoadingAgentReport: false,
    isLoadingAllUploadADocuments: false,
    users: [],
    leaseAgreementCount: null,
    totalUsersCount: null,
    usersPagination: {
        limit: 10,
        page: 1
    },
    usersSorting: {
        field: 'created',
        direction: 'desc'
    },
    agencies: [],
    agenciesPagination: {
        limit: 10,
        page: 1
    },
    agenciesSorting: {
        field: 'created',
        direction: 'desc'
    },
    agreementsPerMonth: '',
    agentsReportData: [],
    agentReportData: [],
    allUploadADocumentsData: [],
    allBuildADocumentsData: []
};

export default function search(state = initState, action) {
    switch (action.type) {
        case CHECK_USER_CAN_VIEW_STATISTICS_SUCCESS: {
            return {
                ...state,
                canUserViewStatistics: true
            };
        }
        case CHECK_USER_CAN_VIEW_STATISTICS_FAIL: {
            return {
                ...state,
                canUserViewStatistics: false
            };
        }
        case GET_STATISTICS_REQUEST: {
            return {
                ...state,
                isLoadingStatistics: true
            };
        }
        case GET_STATISTICS_SUCCESS: {
            return {
                ...state,
                isLoadingStatistics: false,
                leaseAgreementCount: action.result.data.data.leaseAgreementCount,
                canUserViewStatistics: true
            };
        }
        case GET_STATISTICS_FAIL: {
            const { status } = action.error.response;
            let newState = {
                isLoadingStatistics: false
            };

            if (status === 403) {
                newState.canUserViewStatistics = false;
            }

            return {
                ...state,
                ...newState
            };
        }
        case GET_AGENT_REPORTS_REQUEST: {
            return {
                ...state,
                isLoadingAgentReports: true
            };
        }
        case GET_AGENT_REPORTS_SUCCESS: {
            return {
                ...state,
                isLoadingAgentReports: false,
                agentReportsData: action.result.data.reportData
            };
        }
        case GET_AGENT_REPORTS_FAIL: {
            let newState = {
                isLoadingAgentReports: false
            };

            return {
                ...state,
                ...newState
            };
        }
        case GET_AGENT_REPORT_REQUEST: {
            return {
                ...state,
                isLoadingAgentReport: true
            };
        }
        case GET_AGENT_REPORT_SUCCESS: {
            return {
                ...state,
                isLoadingAgentReport: false,
                agentReportData: action.result.data.reportData
            };
        }
        case GET_AGENT_REPORT_FAIL: {
            let newState = {
                isLoadingAgentReport: false
            };

            return {
                ...state,
                ...newState
            };
        }
        case GET_ALL_UPLOAD_A_DOCUMENTS_REQUEST: {
            return {
                ...state,
                isLoadingAllUploadADocuments: true
            };
        }
        case GET_ALL_UPLOAD_A_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                isLoadingAllUploadADocuments: false,
                allUploadADocumentsData: action.result.data.reportData
            };
        }
        case GET_ALL_UPLOAD_A_DOCUMENTS_FAIL: {
            let newState = {
                isLoadingAllUploadADocuments: false
            };

            return {
                ...state,
                ...newState
            };
        }
        case GET_ALL_BUILD_A_DOCUMENTS_REQUEST: {
            return {
                ...state,
                isLoadingAllBuildADocuments: true
            };
        }
        case GET_ALL_BUILD_A_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                isLoadingAllBuildADocuments: false,
                allBuildADocumentsData: action.result.data.reportData
            };
        }
        case GET_ALL_BUILD_A_DOCUMENTS_FAIL: {
            let newState = {
                isLoadingAllBuildADocuments: false
            };

            return {
                ...state,
                ...newState
            };
        }
        case GET_AGREEMENTS_PER_MONTH_REQUEST: {
            return produce(state, draftState => {
                draftState.isLoadingAgreementsPerMonth = true;
            });
        }
        case GET_AGREEMENTS_PER_MONTH_SUCCESS: {
            return produce(state, draftState => {
                draftState.isLoadingAgreementsPerMonth = false;
                draftState.agreementsPerMonth = action.result.data.url;
            });
        }
        case GET_AGREEMENTS_PER_MONTH_FAIL: {
            return produce(state, draftState => {
                draftState.isLoadingAgreementsPerMonth = false;
            });
        }

        case GET_AVERAGE_RENT_10KM_SYDNEY_REQUEST: {
            return produce(state, draftState => {
                draftState.isLoadingAvgRent10KmSydney = true;
            });
        }
        case GET_AVERAGE_RENT_10KM_SYDNEY_SUCCESS: {
            return produce(state, draftState => {
                draftState.isLoadingAvgRent10KmSydney = false;
                draftState.avgRent10KmSydney = action.result.data.url;
            });
        }
        case GET_AVERAGE_RENT_10KM_SYDNEY_FAIL: {
            return produce(state, draftState => {
                draftState.isLoadingAvgRent10KmSydney = false;
            });
        }
        case GET_ALL_USERS_REQUEST: {
            return {
                ...state,
                isLoadingAllUserInfo: true
            };
        }
        case GET_ALL_USERS_SUCCESS: {
            let { users, ...pagination } = action.result.data;
            return {
                ...state,
                isLoadingAllUserInfo: false,
                users,
                totalUsersCount: pagination.total,
                usersPagination: pagination,
                canUserViewStatistics: true
            };
        }
        case GET_ALL_USERS_FAIL: {
            const { status } = action.error.response;
            let newState = {
                isLoadingAllUserInfo: false
            };

            if (status === 403) {
                newState.canUserViewStatistics = false;
            }

            return {
                ...state,
                ...newState
            };
        }
        case GET_ALL_AGENCIES_REQUEST: {
            return {
                ...state,
                isLoadingAllAgenciesInfo: true
            };
        }
        case GET_ALL_AGENCIES_SUCCESS: {
            let { agencies, ...pagination } = action.result.data;
            return {
                ...state,
                isLoadingAllAgenciesInfo: false,
                agencies,
                agenciesPagination: pagination,
                canUserViewStatistics: true
            };
        }
        case GET_ALL_AGENCIES_FAIL: {
            const { status } = action.error.response;
            let newState = {
                isLoadingAllAgenciesInfo: false
            };

            if (status === 403) {
                newState.canUserViewStatistics = false;
            }

            return {
                ...state,
                ...newState
            };
        }
    }

    return state;
}
