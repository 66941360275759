import React from 'react';
import { FormTextRegular } from '../form/FormText';
import { FormEmail } from '../form/FormEmail';

const DisclosureRenterGroup = ({ index, removeRenter, disabled }) => {
    let header;
    if (index === 0) {
        header = <h3 className="form-section__heading">Renter {index + 1}</h3>;
    } else {
        header = (
            <span className="form-section__header">
                <h3>Renter {index + 1}</h3>
                <button type="button" onClick={disabled ? () => {} : () => removeRenter(index)}>
                    <span className="span-remove">Remove</span>
                </button>
            </span>
        );
    }
    return (
        <div className="form-section">
            {header}
            <div className="form-section__body">
                <div className="formBox-column">
                    <div className="nowrap">
                        <FormTextRegular
                            name={`tenants[${index}].firstName`}
                            label="First Name"
                            required
                            disabled={disabled}
                        />
                        <FormTextRegular
                            name={`tenants[${index}].secondName`}
                            label="Last Name"
                            required
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DisclosureRenterGroup;
