import React from 'react';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { getClStepNo, getClStepTitle } from '../../../../../utils/agreementUtils';
import { LeaseAllInfo } from './types';
import { formatCurrencyWithSeparator, formatCurrency } from '../../../../../utils/formUtils';

type Props = {
    leaseAllInfo: LeaseAllInfo,
}

export default function AgreementClRent({ leaseAllInfo }: Props) {
    const part = 'rent';
    if (!leaseAllInfo?.rent) {
        return <></>;
    }
    const { location, rent, term } = leaseAllInfo;
    return (
        <div>
            <AgreementSection
                title={getClStepTitle(location, part)}
                step={getClStepNo(location, part)}
                leaseAllInfo={leaseAllInfo}
            >
                {rent && (
                    <div className="data data-row">
                        <div className="data-string">
                            <span className="data-contact">An initial base rent (inclusive of GST) of</span>
                            <p className="text">
                                {formatCurrencyWithSeparator(formatCurrency(rent.amount))} per {rent.payablePeriod}
                            </p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">commencing on</span>
                            <p className="text">{rent.dateStartFormatted}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">Date of payment of rent</span>
                            <p className="text">{rent.payableOn}</p>
                        </div>
                        <div className="data-string">
                            <span className="data-contact">of each</span>
                            <p className="text">{rent.ofEach}</p>
                        </div>
                        <p>
                            in advance to the Lessor/Agent and upon the terms and conditions set out in this Lease.
                        </p>
                        {rent.optionOfRenewal && (
                            <>
                                <div className="data-string">
                                    <span className="data-contact">Method for review of rent:</span>
                                    <p className="text">
                                        {rent.methodOfReviewText}
                                    </p>
                                </div>
                                {term.isThereAnOption && (
                                    <div className="data-string">
                                        <span className="data-contact">Method for Option Renewal:</span>
                                        <p className="text">
                                            {rent.optionOfRenewalText}
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </AgreementSection>
        </div>
    );
}
