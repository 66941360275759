import * as React from 'react';
import ModalDialog from '../../../common/components/ModalDialog';
import '../../../sass/conditions.scss';
import { isEmpty, map } from 'lodash';
import SpecialCondition from '../shared/SpecialCondition';
import SpecialConditionWithResponse from '../shared/SpecialConditionWithAnswer';

export default function ModalViewSalesAdditionalTerms({
    title,
    isOpen,
    closeModal,
    leaseAllInfo,
    extraTerms,
    noItemsMessage
}) {
    const { specialConditions, signatory } = leaseAllInfo;
    return (
        <ModalDialog title={title} isOpen={isOpen} closeModal={closeModal}>
            <div className="agreement-section-text">
                {extraTerms}
                <div>
                    {isEmpty(specialConditions?.clauseList) ? (
                        <p>{noItemsMessage || 'No Terms and Conditions of Sale known to Licensee.'}</p>
                    ) : (
                        <div>
                            <ul>
                                {!signatory?.primaryVendor?.agentAdditionalTerms &&
                                    map(specialConditions?.clauseList, (value, index) => {
                                        return <SpecialCondition key={index} item={value} />;
                                    })}
                                {signatory?.primaryVendor?.agentAdditionalTerms?.questions.map((value, index) => {
                                    return <SpecialConditionWithResponse key={index} item={value} />;
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </ModalDialog>
    );
}
