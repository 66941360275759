import React from 'react';
import { SelectField } from '../FormSelect';
import { FormRadioGroup } from '../FormRadioGroup';
import { find } from 'lodash';

import '../../../sass/responsive/buttonSelect.scss';

export const FormButtonSelect = ({
    selectClassName,
    onChange,
    name,
    options,
    disabled,
    value,
    label,
    isButtonGroup,
    dataTest,
    ...selectProps
}) => {
    let checkboxData = [];
    if (onChange) {
        const radioOnchange = e => {
            onChange(find(options, { value: e.target.value }));
        };
        options.forEach(option => {
            checkboxData.push({
                ...option,
                onClick: radioOnchange
            });
        });
    } else {
        checkboxData = options;
    }
    return (
        <React.Fragment>
            <div className="for-lg-modal FormInput button-select">
                <SelectField
                    label={label}
                    name={name}
                    selectClassName={selectClassName}
                    onChange={onChange}
                    options={options}
                    disabled={disabled}
                    dataTest={dataTest}
                    {...selectProps}
                />
            </div>
            <div className="for-sm-modal FormInput button-select">
                <FormRadioGroup
                    name={name}
                    className={selectClassName}
                    radioGroupClass={isButtonGroup ? 'button-group-for-select' : ''}
                    label={label}
                    data={checkboxData}
                    value={value}
                    isYesNoGroup={true}
                    {...selectProps}
                />
            </div>
        </React.Fragment>
    );
};
