import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearTemplatesToUpdate } from '../../../actions/account';
import { updateUsedTemplates } from '../../../actions/template';
import Panel from '../../../common/components/Panel';
import { FormPhone } from '../../../components/form/FormPhone';
import { SelectField } from '../../../components/form/FormSelect';
import { FormMaskedText, FormTextRegular } from '../../../components/form/FormText';
import { useAccountProvider } from '../../../components/providers/AccountProvider';
import { getAvailableStates, tabs } from '../../../config';
import useCountry from '../../../hooks/useCountry';
import * as Account from '../../../reducers/account';
import { getAgency, getAgencyDetailsTemplatesToUpdate } from '../../../selectors/settings/account';
import { getUserInfo as userInfoSelector } from '../../../selectors/user';
import { listOfTimezones } from '../../../utils/dateUtils';
import { getGeneralAppLabel } from '../../../utils/labelUtils';
import { editPermissions } from '../../../utils/userPermissions';
import { isAgencyUserAccount, isBusinessUserAccount } from '../../../utils/userUtils';
import SaveButtonIcon from '../SaveButtonIcon';
import ConfirmUpdateTemplates from '../UpdateTemplatesConfirmation';
import OtherDetails from './OtherDetails';

const locationOptions = [];

getAvailableStates().map(state => {
    locationOptions.push({ value: state, label: state, disabled: false });
});

const AgencyDetailsForm = props => {
    const formFieldPrefix = props.formType === Account.AGENCY_DETAILS ? 'details.' : 'salesDetails.';
    const formFieldPostFix = props.formType === Account.AGENCY_DETAILS ? 'details' : 'salesDetails';
    const LEASE_PATH = 'licensee';
    const dispatch = useDispatch();
    const loggedInUser = useSelector(userInfoSelector);
    const agency = useSelector(getAgency);
    const defaultAgencyDetailsTemplatesToUpdate = useSelector(getAgencyDetailsTemplatesToUpdate);
    const accountType = useAccountProvider();

    let [isPending, setIsPending] = useState(false);
    let [savedSuccessfully, setSavedSuccessfully] = useState(false);
    let [saveFailed, setSaveFailed] = useState(false);

    const { country } = useCountry();

    const clearTemplates = () => {
        dispatch(clearTemplatesToUpdate());
    };

    const updateTemplates = () => {
        const templatesToUpdate = { ...defaultAgencyDetailsTemplatesToUpdate, leasePath: LEASE_PATH };
        dispatch(updateUsedTemplates(templatesToUpdate));
    };

    useEffect(() => {
        if (agency && agency.details) {
            props.setDefaultState(agency.details.location);
        }
    }, [agency]);

    useEffect(() => {
        if (
            defaultAgencyDetailsTemplatesToUpdate &&
            defaultAgencyDetailsTemplatesToUpdate.templates &&
            defaultAgencyDetailsTemplatesToUpdate.templates.length > 0
        ) {
            confirmAlert({
                customUI: ConfirmUpdateTemplates(
                    defaultAgencyDetailsTemplatesToUpdate,
                    clearTemplates,
                    updateTemplates,
                    'AGENCY DETAILS'
                ),
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        }
    }, [defaultAgencyDetailsTemplatesToUpdate]);

    const updateAgency = (isSalesDetails, agencyId, data, form) => {
        const updatedData = data[formFieldPostFix];
        //Set the dirty Fields
        updatedData.dirtyFields = { ...form.getState().dirtyFields, leasePath: LEASE_PATH };
        if (isSalesDetails) {
            return axios.put(`/api/agency/${agencyId}/sales-details`, updatedData);
        }
        return axios.put(`/api/agency/${agencyId}/details`, updatedData);
    };

    function setSavedSuccessState() {
        setIsPending(false);
        setSavedSuccessfully(true);
        setSaveFailed(false);
    }

    function setSavedFailState() {
        setIsPending(false);
        setSavedSuccessfully(false);
        setSaveFailed(true);
    }

    function setSavedPendingState() {
        setIsPending(true);
        setSavedSuccessfully(false);
        setSaveFailed(false);
    }

    function updateReduxState(response) {
        if (response) {
            dispatch(props.updateAgencyInfo(response.data)); //update new agency in redux
        }
    }

    const submit = (agencyId, values, form) => {
        setSavedPendingState();
        // this changes state so the useEffect fires to create timeout
        return updateAgency(props.formType === Account.SALES_AGENCY_DETAILS, agencyId, values, form)
            .then(response => {
                updateReduxState(response);
                setSavedSuccessState();
                return Promise.resolve();
            })
            .catch(error => {
                setSavedFailState();
                const response = error.response;
                let errors;
                if (response && response.data) {
                    errors = response.data.errors ? response.data.errors : {};
                    // this means we have User validation failed
                    if (errors.email) {
                        // if we have other messages then add email to it or create new details object
                        if (errors.details) {
                            errors.details.email = errors.email;
                        } else {
                            errors.details = {
                                email: errors.email
                            };
                        }
                    }
                }
                return errors;
            });
    };

    let userRole = loggedInUser.role;

    return (
        <Form
            initialValues={agency}
            mutators={{
                ...arrayMutators
            }}
            onSubmit={(values, form) => submit(agency.id, values, form)}
        >
            {({
                handleSubmit,
                form,
                form: {
                    mutators: { push, pull }
                }
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Panel
                            title={getGeneralAppLabel('agencyDetails', accountType)}
                            rightButton={
                                <div>
                                    {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                        <SaveButtonIcon
                                            isSaving={isPending}
                                            success={savedSuccessfully}
                                            failure={saveFailed}
                                        />
                                    )}
                                </div>
                            }
                        >
                            <FormTextRegular
                                name={formFieldPrefix + 'agencyName'}
                                label={getGeneralAppLabel('agencyName', accountType) + ' *'}
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormTextRegular
                                name={formFieldPrefix + 'companyName'}
                                label={getGeneralAppLabel('companyName', accountType) + ' *'}
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormTextRegular
                                name={formFieldPrefix + 'address'}
                                label="Address *"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormPhone
                                className={'details-phone-input'}
                                name={formFieldPrefix + 'phone'}
                                label="Phone *"
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            <FormTextRegular
                                name={formFieldPrefix + 'email'}
                                label={getGeneralAppLabel('agencyEmail', accountType) + ' *'}
                                disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                            />
                            {isBusinessUserAccount(accountType) && agency.details && (
                                <div>
                                    <p>Timezone:</p>
                                    <SelectField
                                        name={formFieldPrefix + 'officeTimezone'}
                                        options={listOfTimezones(country)}
                                        selectClassName="sel-4"
                                    />
                                </div>
                            )}
                            {isBusinessUserAccount(accountType) && (
                                <OtherDetails
                                    fieldName={formFieldPrefix + 'otherDetails'}
                                    disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                                    push={push}
                                />
                            )}
                            {isAgencyUserAccount(accountType) && (
                                <FormMaskedText
                                    name={formFieldPrefix + 'ABN'}
                                    label="ABN"
                                    mask="11 111 111 111"
                                    disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                                />
                            )}
                            {isAgencyUserAccount(accountType) && (
                                <FormMaskedText
                                    name={formFieldPrefix + 'ACN'}
                                    label="ACN"
                                    mask="111 111 111"
                                    disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                                />
                            )}
                            {isAgencyUserAccount(accountType) && (
                                <SelectField
                                    label="Default State:"
                                    name={formFieldPrefix + 'location'}
                                    options={locationOptions}
                                    disabled={!editPermissions(userRole, tabs.TAB_AGENCY_DETAILS)}
                                    onChange={value => {
                                        form.change(formFieldPrefix + 'location', value.value);
                                        props.setDefaultState(value.value);
                                    }}
                                />
                            )}
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};
export default AgencyDetailsForm;
