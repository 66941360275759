import React from 'react';
import { FormTextRegular, FormTextMultiline } from '../../../../../components/form/FormText.js';

type DetailsRevisedEstimatedSellingPriceSectionProps = {
    isReadOnly?: boolean;
};

const DetailsRevisedEstimatedSellingPriceSection: React.FC<DetailsRevisedEstimatedSellingPriceSectionProps> = ({
    isReadOnly
}: DetailsRevisedEstimatedSellingPriceSectionProps) => {
    return (
        <>
            <h4>Details of Revised Estimated Selling Price (ESP)</h4>
            <FormTextRegular label="Revised ESP" name="revisedEsp" required disabled={isReadOnly} />
            <FormTextMultiline
                name="reason"
                label="Reason"
                staticText={
                    <p className="breachReasonTextNote">
                        The original ESP on the Agency Agreement is no longer a reasonable estimate of the likely
                        selling price of the Property because:
                    </p>
                }
                required={true}
                disabled={isReadOnly}
            />
        </>
    );
};

export default DetailsRevisedEstimatedSellingPriceSection;
