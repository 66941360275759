/**
 * we can add this component to any document like this
 * <ResendDocForSigningModal
 *      isOpen={isOpenResendModal}
 *      clients={values.clients}
 *      closeModal={() => setIsOpenResendModal(false)}
 *      shouldResendForAll={true}
 *      isResendingForSigning={false}
 *      resendForSigning={selectedClients => resendCustomDocument(currentDocument.id, values, selectedClients)}
 * />
 * */
import React, { useEffect, useState, memo } from 'react';
import { filter, cloneDeep } from 'lodash';

import ModalDialog from '../../../common/components/ModalDialog';
import Button from '../../../common/components/Button';

import '../../../sass/resendLeaseModal.scss';
import { CheckboxCheck } from '../../form/FormCheckboxCheck';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import SignatoryCheckbox from './SignatoryCheckbox';
import useDoubleSendGuard from '../../../hooks/useDoubleSendGuard';
import Note, { NoteType } from '../../../common/components/Note.tsx';

const ResendDocForSigningModal = ({
    isOpen,
    clients,
    closeModal,
    shouldResendForAll,
    isResendingForSigning,
    resendForSigning,
    enforceSequentialSigning
}) => {
    const [formState, setFormState] = useState({
        signatories: clients,
        selectAll: shouldResendForAll
    });
    const doubleSendGuard = useDoubleSendGuard();

    useEffect(() => {
        if (isOpen && clients.length > 0) {
            if (shouldResendForAll) {
                handleSelectAll(true);
            }
        }
    }, [isOpen]);

    const handleResendForSigning = values =>
        doubleSendGuard(() => {
            const selectedSignatories = [];
            values.signatories.forEach(signatory => {
                if (signatory.send) {
                    selectedSignatories.push(signatory._id);
                }
            });
            resendForSigning(selectedSignatories);
        });

    const getSignatoryName = () => {
        let signatory = 'client';
        return signatory;
    };

    const handleSelectAll = value => {
        const allSignatories = cloneDeep(clients).map(signatory => {
            if (!signatory.acknowledgedDate) {
                signatory.send = value;
            }
            return signatory;
        });
        setFormState({
            ...formState,
            signatories: allSignatories,
            selectAll: value
        });
    };

    return (
        <ModalDialog
            title={`Resend Document For Signing`}
            isOpen={isOpen}
            closeModal={closeModal}
            customBodyAndFooter={true}
            shouldCloseOnOverlayClick={false}
            className="resend-lease-modal small-modal-dialog"
        >
            <Form
                initialValues={formState}
                mutators={{
                    ...arrayMutators
                }}
                onSubmit={handleResendForSigning}
            >
                {({ handleSubmit }) => {
                    return (
                        <form id="resend-for-signing-form" onSubmit={handleSubmit} noValidate>
                            <div className="modal-body">
                                <br />
                                <div className="select-tenants">
                                    <p className="instructions">
                                        {`Select which recipient should receive the link to sign the document`}
                                    </p>
                                    {enforceSequentialSigning ? (
                                        <Note
                                            type={NoteType.INFO}
                                            title="Signing Order has been enforced"
                                            className="margin-bottom-1rem"
                                        >
                                            Only the topmost Recipient in the signing order who has not signed their
                                            documents yet can have the documents resent to them.
                                        </Note>
                                    ) : (
                                        <CheckboxCheck name="selectAll" onClick={handleSelectAll} label="Select All" />
                                    )}
                                    <FieldArray
                                        name="signatories"
                                        validate={values => {
                                            const isValid = filter(values, person => person.send).length;

                                            if (!isValid) {
                                                return `You must select at least one ${getSignatoryName()} to send the document to.`;
                                            }
                                            return undefined;
                                        }}
                                    >
                                        {({ fields, meta: { error } }) => (
                                            <React.Fragment>
                                                {fields.map((name, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <SignatoryCheckbox
                                                                signatory={fields.value[index]}
                                                                name={`signatories[${index}].send`}
                                                                disabled={fields.value[index].acknowledgedDate}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                                <div className="has-error">{error}</div>
                                            </React.Fragment>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <div>
                                    <Button
                                        secondary
                                        className="btn-close"
                                        height={40}
                                        onClick={closeModal}
                                        disabled={isResendingForSigning}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        primary
                                        className="btn-upgrade"
                                        height={40}
                                        type="submit"
                                        disabled={isResendingForSigning}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
};

export default memo(ResendDocForSigningModal);
