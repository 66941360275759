// This hook add's the user's ID into Microsoft Clarity to enable searching on user ID.
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { getUserInfo } from '../selectors/user';
import { useSelector } from 'react-redux';

export const useClarity = () => {
    const location = useLocation();
    const userInfo = useSelector(getUserInfo);
    useEffect(() => {
        if (window.clarity && userInfo && userInfo.id) {
            window.clarity('identify', userInfo.id);
        }
    }, [location]);
};
