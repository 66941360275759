import { AvailableCountries } from '@app/constants/constants';
import { getUserAgency } from '@app/selectors/agency.js';
import { Agency } from '@app/types/Agency.js';
import React, { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface CountryContextProps {
    country: AvailableCountries | null;
    setCountry: (country: AvailableCountries) => void;
    countryFromLocale?: AvailableCountries;
    isAustralia: boolean;
    isNewZealand: boolean;
}

const CountryContext = createContext<CountryContextProps | undefined>(undefined);

const CountryProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const agency: Agency = useSelector(getUserAgency);
    const [country, setCountry] = useState<AvailableCountries | null>(null);
    const [countryFromLocale, setCountryFromLocale] = useState<AvailableCountries>();

    useEffect(() => {
        const getCountryFromLocale = () => {
            const locale = Intl.DateTimeFormat().resolvedOptions().locale;
            // locale is in the format en-AU, so we need to split it to get the country code
            const splitLocale = locale.split('-');
            // If the locale is just 'en', we default to AU
            // There are some locales that have two - in them, so we need to check the last element. eg: zh-Hans-CN
            const countryCode = splitLocale.length > 1 ? splitLocale[splitLocale.length - 1] : AvailableCountries.AU;
            if (Object.values(AvailableCountries).includes(countryCode as AvailableCountries)) {
                setCountryFromLocale(countryCode as AvailableCountries);
            }
        };

        getCountryFromLocale();
    }, []);

    useEffect(() => {
        if (agency?.details?.country && agency.details.country !== country) {
            setCountry(agency.details.country);
        }
    }, [agency, country]);

    const isAustralia = country === AvailableCountries.AU;
    const isNewZealand = country === AvailableCountries.NZ;

    return (
        <CountryContext.Provider
            value={{
                country,
                isAustralia,
                setCountry,
                isNewZealand,
                countryFromLocale
            }}
        >
            {children}
        </CountryContext.Provider>
    );
};

export { CountryContext, CountryProvider };
