import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setInvalidToken } from '../../../actions/signatures';

import '../../../sass/agentSignatures/finish.scss';

import Title from '../components/Title';
import Label from '../components/Label';
import successIcon from '../../../../assets/images/icons/success.svg';
import { isBusinessUserAccount } from '../../../utils/userUtils';

class Success extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // On the success page set the token to invalid in redux, so if the user goes back int he browser
        // they are not taken to any other page than the Expired Token page.
        // We do not check token validity on each api request or url change because we don't want the user to be
        // interrupted if the token becomes invalid while adding their signature
        this.props.setInvalidToken();
    }

    render() {
        let { loggedInUser } = this.props;
        return (
            <div className="finish-screen">
                <div className="success">
                    <img src={successIcon} alt="logo" />
                </div>
                <Title className="title-center">Success!</Title>
                {this.getLabel(loggedInUser)}
            </div>
        );
    }

    getLabel(user) {
        if (isBusinessUserAccount(user.accountType)) {
            return <Label>You have added your signature and can now start signing documents.</Label>;
        }
        return <Label>You have added your agent signature and can now start executing agreements.</Label>;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setInvalidToken: () => {
            dispatch(setInvalidToken());
        }
    };
};

const mapStateToProps = state => {
    return {
        loggedInUser: state.user.userInfo
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
