import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { has } from 'lodash';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmDetailsRepairs } from '../../../../selectors/lease/pmLease';

import { FormTextMultiline } from '../../../../components/form/FormText';

const PM_DETAIL_REPAIRS_FORM = 'pmDetailsRepairsForm';
const initState = {
    description: ''
};

function DetailsRepairsLayout(props, ref) {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmDetailsRepairs = useSelector(getPmDetailsRepairs);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmDetailsRepairs] = useState(reduxPmDetailsRepairs || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_DETAIL_REPAIRS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return axios
            .post(`/api/agency/lease/${props.leaseId}/pm-details-repairs`, values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmDetailsRepairs')) {
                    return error.response.data.errors.pmDetailsRepairs;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    return (
        <div className="detailsRepairs">
            <Form onSubmit={submitForm} initialValues={pmDetailsRepairs}>
                {({ handleSubmit, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_DETAIL_REPAIRS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <FormTextMultiline name="description" label={'Property Description'} />
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(DetailsRepairsLayout));
