import React from 'react';
import Pagination from 'react-js-pagination';
import { Table, Th, Tr, Td, Thead } from 'reactable';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../../utils/dateUtils';

class UserTable extends React.Component {
    render() {
        let { users, usersPagination, loggedInUser } = this.props;

        return (
            <div>
                <div className="users-block">
                    <div className="name">
                        <p>Users</p>
                    </div>
                    <div className="table-sort data-table">
                        <Table>
                            <Thead>
                                <Th column="name">
                                    <b>Name</b>
                                </Th>
                                <Th column="agency">
                                    <b>Agency</b>
                                </Th>
                                <Th column="numOfLeaseAgreementsStarted">
                                    <b>Agreements started</b>
                                </Th>
                                <Th column="numOfLeaseAgreements">
                                    <b>Agreements signed</b>
                                </Th>
                                <Th column="numOfLeaseAgreementsInLast7Days">
                                    <b>
                                        Agreements signed <br />
                                        in last 7 days
                                    </b>
                                </Th>
                                <Th column="created">
                                    <b>Created date</b>
                                </Th>
                            </Thead>
                            {users.map((item, index) => (
                                <Tr key={index}>
                                    <Td column="name">
                                        <b className="capitalize">{`${item.firstName} ${item.secondName}`}</b>
                                    </Td>
                                    <Td column="agency">{item.agencyName}</Td>
                                    <Td column="numOfLeaseAgreementsStarted" className="center">
                                        {item.numOfLeaseAgreementsStarted}
                                    </Td>
                                    <Td column="numOfLeaseAgreements" className="center">
                                        {item.numOfLeaseAgreements}
                                    </Td>
                                    <Td column="numOfLeaseAgreementsInLast7Days" className="center">
                                        {item.numOfLeaseAgreementsInLast7Days}
                                    </Td>
                                    <Td column="created">
                                        {formatDateStandard(
                                            new Date(item.created),
                                            getAgencyTimezoneFromUser(loggedInUser)
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                        </Table>
                    </div>
                </div>
                <div className="wrapper-pagination">
                    <Pagination
                        hideDisabled
                        activePage={usersPagination.page ? usersPagination.page : 1}
                        itemsCountPerPage={usersPagination.limit ? usersPagination.limit : 0}
                        totalItemsCount={usersPagination.total ? usersPagination.total : 0}
                        pageRangeDisplayed={3}
                        onChange={e => this.props.changePage(e)}
                        lastPageText={<div className="last-icon" />}
                        firstPageText={<div className="first-icon" />}
                        nextPageText="..."
                        prevPageText="..."
                    />
                    {usersPagination.limit < usersPagination.total ? (
                        <p className="pagination-text">
                            Page {usersPagination.page} of {usersPagination.pages}
                        </p>
                    ) : (
                        <p />
                    )}
                </div>
            </div>
        );
    }
}

export default UserTable;
