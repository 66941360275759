import React from 'react';
import useCountry from '@app/hooks/useCountry';

import styles from './Integration.module.scss';
import { getLabelForCountry } from '@app/utils/countryUtils';

const ListNowIntegration = () => {
    const { isNewZealand, country } = useCountry();
    const brandName = getLabelForCountry(country, 'listNow');
    const integrationLink = isNewZealand
        ? 'https://realflowfinance.atlassian.net/servicedesk/customer/portal/4/group/6/create/35'
        : 'https://realflowfinance.atlassian.net/servicedesk/customer/portal/1/group/1/create/26';
    const contactEmail = isNewZealand ? 'karen@listnow.co.nz' : 'info@campaignflow.com.au';

    return (
        <div className={`panel ${styles.container}`}>
            <h2 className="panel-header">{brandName.toUpperCase()}</h2>
            <div className="panel-body">
                <p className={styles.apiKey}>Connection instructions:</p>
                <ol>
                    <li>
                        Submit a request to turn on the integration with {brandName}{' '}
                        <a href={integrationLink} target="_blank" rel="noreferrer">
                            here
                        </a>
                    </li>
                    <li>{brandName} and FLK it over will set up the integration within one business day</li>
                    <li>
                        FLK it over will send you an email confirmation once the integration set up has been completed
                    </li>
                </ol>
                <p>
                    <i>
                        Please note, you will be able to create & send documents from FLK it over while the integration
                        is being set up.
                    </i>
                </p>
                <p>
                    Not a {brandName} customer? Contact <a href={`mailto:${contactEmail}`}>{contactEmail}</a> to
                    enquire.
                </p>
            </div>
        </div>
    );
};

export default ListNowIntegration;
