import React, { memo, useState } from 'react';

import { useSelector } from 'react-redux';
import PopupFlowDocumentsMore from '../../../popups/PopupFlowDocumentsMore';
import { getUserInfo, isUserRoleAdmin } from '../../../../selectors/user';
import { isAccountEnabled } from '../../../../utils/userUtils';

const FlowDocumentsMoreOptionBlock = props => {
    const [morePopup, setMorePopup] = useState(false);

    const isUserAdmin = useSelector(isUserRoleAdmin);
    const loggedInUser = useSelector(getUserInfo);

    const docId = props.docId;

    const toggleMorePopup = e => {
        e.stopPropagation();
        setMorePopup(!morePopup);
    };
    const closeMorePopupList = () => {
        setMorePopup(false);
    };

    const showModalInfoAgreement = event => {
        event.stopPropagation();
        props.showModalInfoAgreement();
        closeMorePopupList();
    };
    const showEditTenants = event => {
        event.stopPropagation();
        props.showEditTenants();
        closeMorePopupList();
    };

    const { agreementStatus } = props;

    return (
        <div className="more-action-wrapper" style={{ position: 'relative' }}>
            {isAccountEnabled(loggedInUser) && <div className="more-action" onClick={e => toggleMorePopup(e)} />}

            {morePopup && (
                <PopupFlowDocumentsMore
                    agreementStatus={agreementStatus}
                    doc={props.doc}
                    agentSignature={props.agentSignature ? props.agentSignature : null}
                    morePopupListAgreements={morePopup}
                    closeMorePopupList={closeMorePopupList}
                    showEditTenants={showEditTenants}
                    showModalInfoAgreement={showModalInfoAgreement}
                    isUserAdmin={isUserAdmin}
                    docType={props.docType}
                    leaseId={docId}
                    closePopup={closeMorePopupList}
                    isReadOnlyTemplate={props.isReadOnlyTemplate}
                />
            )}
        </div>
    );
};

export default memo(FlowDocumentsMoreOptionBlock);
