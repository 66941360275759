import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { getMobileViewEnabled } from '../../../../../selectors/dashboard';
import { isMobileWidth } from '../../../../../config';

import AgreementMainAuditTrail from '../shared/AgreementMainAuditTrail';
import AgreementMainNotes from '../shared/AgreementMainNotes';
import AgreementPmSignors from './AgreementPmSignors';
import AgreementPmTermAndRent from './AgreementPmTermAndRent';
import AgreementPmTerm from './AgreementPmTerm';
import AgreementPmPropertyDetail from './AgreementPmPropertyDetail';
import AgreementPmFees from './AgreementPmFee';
import AgreementPmExpensesAndCharges from './AgreementPmExpensesAndCharges';
import AgreementPmOtherServices from './AgreementPmOtherServices';
import AgreementPmMarketing from './AgreementPmMarketing';
import AgreementPmInspectionDays from './AgreementPmInspectionDays';
import AgreementPmPayment from './AgreementPmPayment';
import AgreementPmOutgoings from './AgreementPmOutgoings';
import AgreementPmAdditionalTerms from './AgreementPmAdditionalTerms';
import AgreementPmLicensee from './AgreementPmLicensee';
import AgreementPmRebates from './AgreementPmRebates';
import AgreementMainDocuments from '../shared/AgreementMainDocuments';
import AgreementPmRentAndPayment from './AgreementPmRentAndPayment';
import AgreementPmTenantPayments from './AgreementPmTenantPayments';
import AgreementPmRepairsAndEmergencyContacts from './AgreementPmRepairsAndEmergencyContacts';
import AgreementPmPoolSafetyAndSmokeAlarms from './AgreementPmPoolSafetyAndSmokeAlarms';
import AgreementPmDuties from './AgreementPmDuties';
import AgreementSignorLinks from '../shared/AgreementSignorLinks';

// SA
import SaAgreementPmInsurance from './SA/AgreementPmInsurance';
import SaAgreementPmMarketing from './SA/AgreementPmMarketing.tsx';
import SaAgreementPmPropertyDetail from './SA/AgreementPmPropertyDetail.tsx';
import SaAgreementPmFee from './SA/AgreementPmFee.tsx';
import SaAgreementPmOutgoings from './SA/AgreementPmOutgoings.tsx';

// VIC
import VicAgreementPmFees from './VIC/AgreementPmFee';
import VicAgreementPmMarketingCosts from './VIC/AgreementPmMarketingCosts';
import VicAgreementPmAuthorityPeriodDetails from './VIC/AgreementPmAuthorityPeriodDetails';
import VicAgreementPmAdditionalCosts from './VIC/AgreementPmAdditionalCosts';
import VicAgreementPmRepairs from './VIC/AgreementPmRepairs';
import VicAgreementPmServicesSchedule from './VIC/AgreementPmServicesSchedule';
import VicAgreementPmRebates from './VIC/AgreementPmRebates';

import '../../../../../sass/infoAgreement.scss';
import AgreementPmClientInstructions from './AgreementPmClientInstructions';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_COMPLETE,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING,
} from '../../../../../actions/dashboard';
import {
    isLeaseLocationNSW,
    isLeaseLocationQLD,
    isLeaseLocationVIC,
    isLeaseLocationSA,
} from '../../../../../utils/agreementUtils';
import AgreementPmFee from './AgreementPmFee';

/**
 * Common component to render pm agreement info.
 */
const AgreementPmCommon = ({ leaseAllInfo }) => {
    const mobileViewEnabled = useSelector(getMobileViewEnabled);
    const [hideDetails, setHideDetails] = useState(mobileViewEnabled && isMobileWidth());
    return (
        <div>
            {/* COMMENTED THIS "REVIEW AGREEMENT DETAILS" BUTTON AS PER THE CURRENT DESIGN. */}
            {/* <div className="mobile-button-wrapper">
                <button className="mobile outline" onClick={() => setHideDetails(!hideDetails)}>
                    Review agreement details
                </button>
            </div> */}
            {!hideDetails && isLeaseLocationNSW(leaseAllInfo.location) && (
                <React.Fragment>
                    {![LEASE_STATUS_DRAFT, LEASE_STATUS_SENT_SIGNING].includes(leaseAllInfo.status) && (
                        <AgreementPmClientInstructions leaseAllInfo={leaseAllInfo} />
                    )}
                    <AgreementPmSignors leaseAllInfo={leaseAllInfo} />
                    <AgreementPmLicensee leaseAllInfo={leaseAllInfo} />
                    <AgreementPmTerm leaseAllInfo={leaseAllInfo} />
                    <AgreementPmPropertyDetail leaseAllInfo={leaseAllInfo} />
                    <AgreementPmTermAndRent leaseAllInfo={leaseAllInfo} />
                    <AgreementPmFees leaseAllInfo={leaseAllInfo} />
                    <AgreementPmExpensesAndCharges leaseAllInfo={leaseAllInfo} />
                    <AgreementPmOtherServices leaseAllInfo={leaseAllInfo} />
                    <AgreementPmMarketing leaseAllInfo={leaseAllInfo} />
                    <AgreementPmInspectionDays leaseAllInfo={leaseAllInfo} />
                    {/* <AgreementPmAgentAuthority leaseAllInfo={leaseAllInfo} /> */}
                    <AgreementPmAdditionalTerms leaseAllInfo={leaseAllInfo} />
                    <AgreementPmOutgoings leaseAllInfo={leaseAllInfo} />
                    <AgreementPmPayment leaseAllInfo={leaseAllInfo} />
                    <AgreementMainDocuments documents={leaseAllInfo.documents} leaseAllInfo={leaseAllInfo} />
                    <AgreementPmRebates leaseAllInfo={leaseAllInfo} />
                </React.Fragment>
            )}
            {!hideDetails && isLeaseLocationQLD(leaseAllInfo.location) && (
                <React.Fragment>
                    {[LEASE_STATUS_AWAITING_COMPLETION, LEASE_STATUS_COMPLETE].includes(leaseAllInfo.status) && (
                        <AgreementPmClientInstructions leaseAllInfo={leaseAllInfo} />
                    )}
                    <AgreementPmSignors leaseAllInfo={leaseAllInfo} />
                    <AgreementPmLicensee leaseAllInfo={leaseAllInfo} />
                    <AgreementPmPropertyDetail leaseAllInfo={leaseAllInfo} />
                    <AgreementPmTerm leaseAllInfo={leaseAllInfo} />
                    <AgreementPmRentAndPayment leaseAllInfo={leaseAllInfo} />
                    <AgreementPmFees leaseAllInfo={leaseAllInfo} />
                    <AgreementPmOtherServices leaseAllInfo={leaseAllInfo} />
                    <AgreementPmMarketing leaseAllInfo={leaseAllInfo} />
                    <AgreementPmRebates leaseAllInfo={leaseAllInfo} />
                    <AgreementPmOutgoings leaseAllInfo={leaseAllInfo} />
                    <AgreementPmTenantPayments leaseAllInfo={leaseAllInfo} />
                    <AgreementPmRepairsAndEmergencyContacts leaseAllInfo={leaseAllInfo} />
                    <AgreementPmPoolSafetyAndSmokeAlarms leaseAllInfo={leaseAllInfo} />
                    <AgreementPmAdditionalTerms leaseAllInfo={leaseAllInfo} />
                    <AgreementMainDocuments documents={leaseAllInfo.documents} leaseAllInfo={leaseAllInfo} />
                </React.Fragment>
            )}
            {!hideDetails && isLeaseLocationVIC(leaseAllInfo.location) && (
                <React.Fragment>
                    {[LEASE_STATUS_AWAITING_COMPLETION, LEASE_STATUS_COMPLETE].includes(leaseAllInfo.status) && (
                        <AgreementPmClientInstructions leaseAllInfo={leaseAllInfo} />
                    )}
                    <AgreementPmLicensee leaseAllInfo={leaseAllInfo} />
                    <AgreementPmSignors leaseAllInfo={leaseAllInfo} />
                    <AgreementPmPropertyDetail leaseAllInfo={leaseAllInfo} />
                    <VicAgreementPmAuthorityPeriodDetails leaseAllInfo={leaseAllInfo} />
                    <AgreementPmTermAndRent leaseAllInfo={leaseAllInfo} />
                    <VicAgreementPmFees leaseAllInfo={leaseAllInfo} />
                    <VicAgreementPmMarketingCosts leaseAllInfo={leaseAllInfo} />
                    <VicAgreementPmAdditionalCosts leaseAllInfo={leaseAllInfo} />
                    <AgreementPmOutgoings leaseAllInfo={leaseAllInfo} />
                    <AgreementPmRentAndPayment leaseAllInfo={leaseAllInfo} />
                    <VicAgreementPmRebates leaseAllInfo={leaseAllInfo} />
                    <VicAgreementPmRepairs leaseAllInfo={leaseAllInfo} />
                    <VicAgreementPmServicesSchedule leaseAllInfo={leaseAllInfo} />
                    <AgreementPmAdditionalTerms leaseAllInfo={leaseAllInfo} />
                    <AgreementMainDocuments documents={leaseAllInfo.documents} leaseAllInfo={leaseAllInfo} />
                </React.Fragment>
            )}
            {!hideDetails && isLeaseLocationSA(leaseAllInfo.location) && (
                <React.Fragment>
                    {![LEASE_STATUS_DRAFT, LEASE_STATUS_SENT_SIGNING].includes(leaseAllInfo.status) && (
                        <AgreementPmClientInstructions leaseAllInfo={leaseAllInfo} />
                    )}
                    <AgreementPmSignors leaseAllInfo={leaseAllInfo} />
                    <AgreementPmLicensee leaseAllInfo={leaseAllInfo} />
                    <SaAgreementPmPropertyDetail leaseAllInfo={leaseAllInfo} />
                    <AgreementPmTerm leaseAllInfo={leaseAllInfo} />
                    <AgreementPmTermAndRent leaseAllInfo={leaseAllInfo} />
                    <SaAgreementPmFee leaseAllInfo={leaseAllInfo} />
                    <SaAgreementPmMarketing leaseAllInfo={leaseAllInfo} />
                    <SaAgreementPmOutgoings leaseAllInfo={leaseAllInfo} />
                    <AgreementPmPayment leaseAllInfo={leaseAllInfo} />
                    <AgreementPmDuties leaseAllInfo={leaseAllInfo} />
                    <SaAgreementPmInsurance leaseAllInfo={leaseAllInfo} />
                    <AgreementPmAdditionalTerms leaseAllInfo={leaseAllInfo} />
                    <AgreementPmRepairsAndEmergencyContacts leaseAllInfo={leaseAllInfo} />
                    <AgreementMainDocuments documents={leaseAllInfo.documents} leaseAllInfo={leaseAllInfo} />
                </React.Fragment>
            )}
            {!hideDetails && leaseAllInfo.auditTrail && leaseAllInfo.auditTrail.length ? (
                <AgreementMainAuditTrail auditTrail={leaseAllInfo.auditTrail} showText={true} />
            ) : null}
            {!hideDetails && leaseAllInfo.notes && leaseAllInfo.notes.length ? (
                <AgreementMainNotes notes={leaseAllInfo.notes} id={leaseAllInfo.leaseId} />
            ) : null}
            {leaseAllInfo.status === LEASE_STATUS_SENT_SIGNING && leaseAllInfo.isManuallyShareLinks && (
                <AgreementSignorLinks leaseAllInfo={leaseAllInfo} />
            )}
        </div>
    );
};

export default memo(AgreementPmCommon);
