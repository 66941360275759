import React from 'react';
import styles from './Toast.module.scss';
import cx from 'classnames';
import Card, { CardStyles } from './cards/Card';
import Button from '../../common/components/Button.js';
import Icon, { Icons } from './Icon';
import { useDispatch } from 'react-redux';
import { removeToast } from '../../actions/toast.js';

enum ToastTypes {
    SUCCESS,
    WARNING,
    ERROR
}

interface ToastProps {
    type?: ToastTypes;
    message: string;
    id: string;
    timeout?: number;
    closable?: boolean;
    className?: string;
}

const getClass = (type: ToastTypes) => {
    switch (type) {
        case ToastTypes.SUCCESS:
            return styles.successNotification;
        case ToastTypes.WARNING:
            return styles.warningNotification;
        case ToastTypes.ERROR:
            return styles.errorNotification;
        default:
            return '';
    }
};

const getIcon = (type: ToastTypes) => {
    switch (type) {
        case ToastTypes.SUCCESS:
            return Icons.CHECK;
        case ToastTypes.WARNING:
            return Icons.WARNING;
        case ToastTypes.ERROR:
            return Icons.WARNING;
        default:
            throw new Error('An invalid icon prop was passed to the Toast component.');
    }
};

/**
 * To add a toast message use addNewToast
 * const { addNewToast } = useToast();
 * addNewToast('Sample message', ToastTypes.SUCCESS, true)
 */
const Toast: React.FC<ToastProps> = ({
    type = ToastTypes.SUCCESS,
    message,
    id,
    timeout = 10000,
    closable = false,
    className
}) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        setTimeout(() => {
            dispatch(removeToast(id));
        }, timeout);
    }, [id]);

    return (
        <Card style={CardStyles.SQUARE} className={cx(styles.toast, getClass(type), className)} animate={false}>
            <Icon className={styles.icon} icon={getIcon(type)} />
            <span className={styles.message}>{message}</span>
            {closable && (
                <Button className={styles.close} onClick={() => dispatch(removeToast(id))}>
                    <Icon className={styles.closeIcon} icon={Icons.CLOSE} />
                </Button>
            )}
        </Card>
    );
};

export default Toast;
export { ToastTypes };
