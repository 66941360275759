import { capitalize } from '../../utils/generalUtils';
import LinkSharpIcon from '@material-ui/icons/LinkSharp';
import * as PropTypes from 'prop-types';
import React from 'react';

export const IntegrationConnect = props => {
    return (
        <div className="integration header-container">
            <label>
                <p>
                    <b>{capitalize(props.label)}:</b>
                </p>
            </label>
            <p className="integration-details">N/A</p>
            {!props.viewOnly && (
                <p>
                    <a onClick={props.onLink}>
                        <LinkSharpIcon />
                        <span>Link</span>
                    </a>
                </p>
            )}
        </div>
    );
};

IntegrationConnect.propTypes = {
    label: PropTypes.any.isRequired,
    onLink: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool.isRequired
};
