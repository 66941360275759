import React, { Component } from 'react';
import { DeleteSharp, AddSharp } from '@material-ui/icons/';
import { FormTextRegular } from './../../../form/FormText';
import { ContactServiceExtraContacts } from './../common/ContactServiceExtraContacts';
import { FieldArray } from 'react-final-form-arrays';
import { VIC_STATE } from '../../../../config';

class ContactServiceGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addModal: false
        };
    }

    toggleAddModal = () => {
        this.setState({
            addModal: !this.state.addModal
        });
    };

    addMethod = (index, type) => {
        let extraContact = {
            index: index,
            contactType: type
        };
        this.props.push(`${this.props.fieldName}[${index}].extraContacts`, extraContact);
        this.setState({
            addModal: !this.state.addModal
        });
    };

    render() {
        const { addModal } = this.state;
        const { index, contact, removeContactPerson, location, fieldName } = this.props;
        const { extraContacts } = this.props.contact;

        return (
            <div className="submodule">
                <div className="submodule__header">
                    <h3>{contact.tradeType}</h3>
                    <div className="submodule--remove" title="Remove" onClick={() => removeContactPerson(index)}>
                        <DeleteSharp />
                        Remove
                    </div>
                </div>
                <div className="submodule__body">
                    <div className="submodule__fields submodule__fields--inline">
                        <FormTextRegular name={`${fieldName}[${index}].firstName`} label="First Name" />
                        <FormTextRegular name={`${fieldName}[${index}].secondName`} label="Last Name" />
                    </div>
                    <div className="submodule__fields">
                        <FormTextRegular name={`${fieldName}[${index}].company`} label="Company" required />
                        <FormTextRegular name={`${fieldName}[${index}].phone`} label="Phone" required />
                        {location === VIC_STATE && (
                            <FormTextRegular name={`${fieldName}[${index}].email`} label="Email" />
                        )}
                        <FieldArray name={`${fieldName}[${index}].extraContacts`} initialValue={extraContacts}>
                            {({ fields }) => (
                                <React.Fragment>
                                    {fields.map((name, fieldsIndex) => {
                                        return (
                                            <ContactServiceExtraContacts
                                                key={fieldsIndex}
                                                index={index}
                                                indexKey={fieldsIndex}
                                                blockType={fieldName}
                                                extraContact={fields.value[fieldsIndex]}
                                                removeContact={() => fields.remove(fieldsIndex)}
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            )}
                        </FieldArray>
                        <div className="submodule__footer">
                            <button
                                title="Add more"
                                className="add-button"
                                type="button"
                                onClick={() => this.toggleAddModal()}
                            >
                                <AddSharp />
                                Add more contact details
                            </button>
                            <div className={addModal ? 'add-fields' : 'add-fields hide'}>
                                <div className="add-fields__types">
                                    <div className="field-type" onClick={() => this.addMethod(index, 'Email')}>
                                        Email
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactServiceGroup;
