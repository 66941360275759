import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { subscriptionStatus } from '../../../config';
import Panel from '../../../common/components/Panel';
import Button from '../../../common/components/Button';
import ModalChangeSubscriptionPlan from '../../../components/settings/subscription/modals/ModalChangeSubscriptionPlan.tsx';
import { confirmAlert } from 'react-confirm-alert';
import { formatDateStandard, getAgencyTimezoneFromUser } from '../../../utils/dateUtils';
import { getBulkModuleActive, getIsSuperAdmin, getUserInfo } from '../../../selectors/user';
import { formatCurrency, formatCurrencyWithSeparator } from '../../../utils/formUtils';
import { isAgencyUserAccount, isBusinessUserAccount } from '../../../utils/userUtils';
import { useState } from 'react';
import { getAgency } from '../../../selectors/settings/account';
import { getPlans } from '../../../selectors/authorization';
import {
    useDeleteTariffPlan,
    useGetTariffPlan,
    useSetTariffPlan,
    useUpdateTariffPlan
} from '../../../hooks/query/useTariffPlanData';
import { PreLoaderSmall } from '../../../common/components/PreLoaderSmall';
import CancelSubscriptionInfoModal from '../../../components/settings/subscription/modals/CancelSubscriptionInfoModal';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import SubscriptionStatusMessage from './SubscriptionStatusMessage';
import SubscriptionButton from './SubscriptionButton';
import { setTariffPlanRedux } from '../../../actions/account';
import styles from './SubscriptionPlan.module.scss';

const setSubscriptionPlanFailMessage =
    'Your subscription plan could not be updated. Please try again. If the problem persist contact customer support for help.';

const SubscriptionPlan = () => {
    const loggedInUser = useSelector(getUserInfo);
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const agency = useSelector(getAgency);
    const plans = useSelector(getPlans);
    const isBulkModuleActive = useSelector(getBulkModuleActive);
    const dispatch = useDispatch();

    let [isOpenModal, setIsOpenModal] = useState(false);
    let [isOpenFeedbackModal, setIsOpenFeedbackModal] = useState(false);
    let [modalErrorMessage, setModalErrorMessage] = useState(' ');
    const [resumeSubscriptionIsLoading, setResumeSubscriptionIsLoading] = useState(false);

    //Data request hooks
    const {
        isLoading: tariffPlanRequestPending,
        data: tariffPlanResponse,
        refetch: refetchTariffPlan
    } = useGetTariffPlan(agency.id, data => {
        // Stop resumeSubscriptionLoading
        setResumeSubscriptionIsLoading(false);
        // Create an object that redux understands in terms of response
        const result = { data };

        dispatch(setTariffPlanRedux(result));
    });

    const customerPortalSessionMutation = useMutation({
        mutationFn: () => {
            return axios.get(`/api/agency/account/create-customer-portal-session`).then(response => response.data);
        },
        onSuccess: data => {
            window.open(data.url, '_blank');
        }
    });

    const toggleChangePlanModal = (open = true) => {
        setIsOpenModal(open);
        setModalErrorMessage('');
    };

    const setTariffPlanSuccess = async () => {
        toggleChangePlanModal(false);
    };

    const setTariffPlanFail = async () => {
        setModalErrorMessage(setTariffPlanMutation?.error?.response?.data?.error || setSubscriptionPlanFailMessage);
    };

    //Mutation hooks
    const setTariffPlanMutation = useSetTariffPlan(agency.id, setTariffPlanSuccess, setTariffPlanFail);
    const updateTariffPlanMutation = useUpdateTariffPlan(agency.id, setTariffPlanSuccess, setTariffPlanFail);

    function deleteTariffPlanSuccess() {
        setIsOpenFeedbackModal(true);
    }

    function deleteTariffPlanFail() {
        openCancellationFailedAlert();
    }

    const deleteTariffPlanMutation = useDeleteTariffPlan(agency.id, deleteTariffPlanSuccess, deleteTariffPlanFail);

    const getActiveTariff = tariffPlan => {
        if (isEmpty(tariffPlan) || !tariffPlan.active) {
            return {};
        }
        return tariffPlan;
    };

    const cancelSubscription = () => {
        let message;

        if (agency.tariffPlan.status === subscriptionStatus.ACTIVE) {
            if (isBusinessUserAccount(loggedInUser.accountType)) {
                message =
                    'Cancelling your subscription will take effect at the end of your subscription period. You will be able to continue for the rest of your subscription period. When your subscription period ends, you will not be able to create new documents, but you will still be able to access all of your existing documents. Are you sure you want to continue?';
            } else {
                message =
                    'Cancelling your subscription will take effect at the end of your subscription period. You will be able to continue for the rest of your subscription period. When your subscription period ends, you will not be able to create new leases, but you will still be able to access all of your existing agreements. Are you sure you want to continue?';
            }
        } else {
            if (isBusinessUserAccount(loggedInUser.accountType)) {
                message =
                    'Cancelling your subscription trial will take effect immediately and you will not be able to create any more new documents. You will still be able to access all of your existing documents. Are you sure you want to continue?';
            } else {
                message =
                    'Cancelling your subscription trial will take effect immediately and you will not be able to create any more new agreements. You will still be able to access all of your existing agreements. Are you sure you want to continue?';
            }
        }

        confirmAlert({
            title: '',
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        cancelTariffPlan(agency.id);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const reActivateSubscription = () => {
        confirmAlert({
            title: '',
            message:
                'Reactivating your subscription will continue your subscription. Are you sure you want to reactivate your subscription?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setTariffPlan(agency.id, agency.tariffPlan.stripeId);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const resumeSubscriptionMutation = useMutation({
        mutationFn: () => {
            return axios.post(`/api/agency/${agency.id}/resume-subscription`).then(response => response.data);
        },
        onSuccess: () => {
            // Wait 7.5 seconds and then fetch the tariffPlan again that should now have been updated
            setTimeout(() => {
                refetchTariffPlan();
            }, 7500);
        }
    });

    const resumeSubscription = () => {
        setResumeSubscriptionIsLoading(true);
        resumeSubscriptionMutation.mutate();
    };

    const openCancellationFailedAlert = () => {
        confirmAlert({
            title: '',
            message: 'Your subscription could not be cancelled. Please contact customer support for help.',
            confirmLabel: 'OK'
        });
    };

    const renderCurrencyPrice = ({ title, price, currency }) => {
        return `${title} - ${formatCurrencyWithSeparator(formatCurrency(price))} ${currency}*`;
    };

    const renderTariffPlan = (
        tariffPlan,
        amountOfAgreementsSentThisMonth,
        amountOfDocumentsSentThisMonth,
        amountOfBulkDocumentsSentThisMonth
    ) => {
        if (!isEmpty(tariffPlan) && tariffPlan.active && tariffPlan.status !== subscriptionStatus.CANCELLING) {
            const {
                agreementsPerMonth,
                constraintText,
                created,
                currency,
                currentPeriodEnd,
                currentPeriodStart,
                nextInvoiceCost,
                price,
                title
            } = tariffPlan;

            return (
                <div className={styles['tariffPlan']}>
                    <p className={styles['title']}>{renderCurrencyPrice({ title, price, currency })} per month</p>

                    {nextInvoiceCost > 0 && (
                        <p className={styles['nextInvoiceCost']}>
                            Next invoice: {renderCurrencyPrice({ title, price: nextInvoiceCost / 100, currency })}
                        </p>
                    )}

                    <div className={styles['description']}>
                        <p>Unlimited number of users.</p>
                        <p>{constraintText}</p>
                    </div>

                    <SubscriptionButton
                        tariffPlan={tariffPlanResponse?.tariffPlan}
                        toggleChangePlanModal={toggleChangePlanModal}
                        agency={agency}
                        resumeSubscription={resumeSubscription}
                        resumeSubscriptionIsLoading={resumeSubscriptionIsLoading}
                    />

                    <hr />

                    <div className={styles['descriptionTable']}>
                        <div className={styles['entry']}>
                            <p>Started</p>
                            <p>{formatDateStandard(created, getAgencyTimezoneFromUser(loggedInUser))}</p>
                        </div>
                        <div className={styles['entry']}>
                            <p>Period start</p>
                            <p>{formatDateStandard(currentPeriodStart, getAgencyTimezoneFromUser(loggedInUser))}</p>
                        </div>
                        <div className={styles['entry']}>
                            <p>Period end</p>
                            <p>{formatDateStandard(currentPeriodEnd, getAgencyTimezoneFromUser(loggedInUser))}</p>
                        </div>

                        {isAgencyUserAccount(loggedInUser.accountType) && (
                            <div className={styles['entry']}>
                                <p>Agreements sent</p>
                                <p>{amountOfAgreementsSentThisMonth}</p>
                            </div>
                        )}

                        {isBusinessUserAccount(loggedInUser.accountType) && (
                            <div className={styles['entry']}>
                                <p>Documents sent</p>
                                <p>{amountOfDocumentsSentThisMonth}</p>
                            </div>
                        )}

                        {isBulkModuleActive && (
                            <>
                                <div className={styles['entry']}>
                                    <p>Bulk documents sent</p>
                                    <p>
                                        {amountOfBulkDocumentsSentThisMonth} of {agreementsPerMonth.maxBulkSend}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>

                    <Button
                        onClick={() => customerPortalSessionMutation.mutate()}
                        className={styles['viewInvoiceButton']}
                        secondary
                        height={32}
                    >
                        View invoices {customerPortalSessionMutation.isLoading && <PreLoaderSmall />}
                    </Button>
                </div>
            );
        }

        if (!isEmpty(tariffPlan) && !tariffPlan.active) {
            return (
                <div className={styles.cancelledState}>
                    <SubscriptionStatusMessage tariffPlan={tariffPlan} loggedInUser={loggedInUser} />
                    <SubscriptionButton
                        tariffPlan={tariffPlanResponse?.tariffPlan}
                        toggleChangePlanModal={toggleChangePlanModal}
                        agency={agency}
                        resumeSubscription={resumeSubscription}
                        resumeSubscriptionIsLoading={resumeSubscriptionIsLoading}
                    />
                </div>
            );
        }

        return <SubscriptionStatusMessage tariffPlan={tariffPlan} loggedInUser={loggedInUser} />;
    };

    const renderSubscriptionActionButton = tariffPlan => {
        if (isEmpty(tariffPlan) || !tariffPlan.active) {
            return null;
        }

        if (tariffPlan.active && tariffPlan.status === subscriptionStatus.CANCELLING) {
            return (
                <Button dataTest="reActivate" onClick={reActivateSubscription}>
                    Reactivate subscription
                </Button>
            );
        }

        return (
            <Button onClick={cancelSubscription} error>
                Cancel subscription
            </Button>
        );
    };

    const activateAccount = () => {
        const params = { agencyId: agency.id, data: { status: 'active', active: true } };
        updateTariffPlanMutation.mutate(params);
    };
    const increaseAgreements = tariffPlan => {
        const params = {
            agencyId: agency.id,
            data: {
                agreementsPerMonth: {
                    max: parseInt(tariffPlan.agreementsPerMonth.max, 10) + 1,
                    min: tariffPlan.agreementsPerMonth.min
                }
            }
        };
        return updateTariffPlanMutation.mutate(params);
    };
    const decreaseAgreements = tariffPlan => {
        const params = {
            agencyId: agency.id,
            data: {
                agreementsPerMonth: {
                    max: parseInt(tariffPlan.agreementsPerMonth.max, 10) - 1,
                    min: tariffPlan.agreementsPerMonth.min
                }
            }
        };
        return updateTariffPlanMutation.mutate(params);
    };

    function setTariffPlan(agencyId, stripeId) {
        const params = { stripeId };
        setTariffPlanMutation.mutate(params);
    }

    function cancelTariffPlan(id) {
        deleteTariffPlanMutation.mutate({ agencyId: id });
    }
    return (
        <div>
            {tariffPlanRequestPending && <PreLoaderSmall />}
            {tariffPlanResponse?.tariffPlan && (
                <Panel
                    title="Subscription plan"
                    leftButton={renderSubscriptionActionButton(tariffPlanResponse?.tariffPlan)}
                >
                    {renderTariffPlan(
                        tariffPlanResponse?.tariffPlan,
                        tariffPlanResponse?.amountOfAgreementsSentThisMonth,
                        tariffPlanResponse?.amountOfDocumentsSentThisMonth,
                        tariffPlanResponse?.amountOfBulkDocumentsSentThisMonth
                    )}
                    {isSuperAdmin && (
                        <>
                            {!tariffPlanResponse?.tariffPlan.active && (
                                <Button primary onClick={() => activateAccount()}>
                                    Activate
                                </Button>
                            )}
                            <div
                                className="superadmin-container-small-buttons"
                                style={{
                                    display: 'flex',
                                    'justify-content': 'space-evenly'
                                }}
                            >
                                {tariffPlanResponse?.tariffPlan.active &&
                                    tariffPlanResponse?.tariffPlan.agreementsPerMonth.max !== 'Infinity' && (
                                        <>
                                            <Button
                                                primary
                                                onClick={() => increaseAgreements(tariffPlanResponse?.tariffPlan)}
                                            >
                                                Add 1
                                            </Button>
                                            <Button
                                                secondary
                                                onClick={() => decreaseAgreements(tariffPlanResponse?.tariffPlan)}
                                            >
                                                Subtract 1
                                            </Button>
                                        </>
                                    )}
                            </div>
                        </>
                    )}
                </Panel>
            )}
            {tariffPlanResponse && tariffPlanResponse?.tariffPlan && (
                <ModalChangeSubscriptionPlan
                    isOpen={isOpenModal}
                    plans={plans}
                    agency={agency}
                    amountOfAgreementsSentThisMonth={
                        isBusinessUserAccount(agency.accountType)
                            ? tariffPlanResponse?.amountOfDocumentsSentThisMonth
                            : tariffPlanResponse?.amountOfAgreementsSentThisMonth
                    }
                    tariffPlan={getActiveTariff(tariffPlanResponse?.tariffPlan)}
                    closeModal={() => toggleChangePlanModal(false)}
                    setTariffPlan={setTariffPlan}
                    errorMessage={modalErrorMessage}
                    isSaving={setTariffPlanMutation.isLoading}
                    isLoading={tariffPlanRequestPending}
                />
            )}
            {isOpenFeedbackModal && (
                <CancelSubscriptionInfoModal
                    isOpen={isOpenFeedbackModal}
                    agencyId={agency.id}
                    closeModal={() => setIsOpenFeedbackModal(false)}
                />
            )}
        </div>
    );
};

SubscriptionPlan.propTypes = {
    agency: PropTypes.shape({
        id: PropTypes.string,
        tariffPlan: PropTypes.object,
        lastPayment: PropTypes.string,
        nextPayment: PropTypes.string
    }),
    plans: PropTypes.array
};

export default SubscriptionPlan;
