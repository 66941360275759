import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { Form } from 'react-final-form';
import { editPermissions } from '../../../utils/userPermissions';
import { tabs } from '../../../config';
import Panel from '../../../common/components/Panel';
import { getUserInfo } from '../../../selectors/user';
import { getAgency } from '../../../selectors/settings/account';
import { useEffect, useState } from 'react';
import { has } from 'lodash';
import axios from 'axios';
import EmailList from '../../../components/form/EmailList';
import SaveButtonIcon from '../SaveButtonIcon';

const DefaultEmailDetailsForm = () => {
    const loggedInUser = useSelector(getUserInfo);
    const agencySelected = useSelector(getAgency);
    const [agency, setAgency] = useState(agencySelected);

    useEffect(() => {
        setAgency(agencySelected);
    }, [agencySelected]);

    const saveDefaultEmail = useMutation(data => {
        return axios.put(`/api/agency/${agency.id}/res-ten-default-email-details`, data);
    });

    const handleSubmit = async values => {
        // Reset mutate data
        // Otherwise it will keep old data and display old error/success messages
        saveDefaultEmail.reset();
        try {
            await saveDefaultEmail.mutateAsync(values);
        } catch (error) {
            let err = {};
            if (has(error, 'response.data.errors.resTenDefaultEmails')) {
                err = error.response.data.errors.resTenDefaultEmails;
            }
            return err;
        }
    };

    let userRole = loggedInUser.role;

    return (
        <Form initialValues={agency.resTenDefaultEmails} onSubmit={handleSubmit}>
            {({ handleSubmit, form, values }) => {
                return (
                    <form onSubmit={handleSubmit} id="defaultEmailsForm">
                        <Panel
                            title="EMAIL ADDRESS FOR FINALISED LEASE AGREEMENTS"
                            rightButton={
                                <div>
                                    {editPermissions(userRole, tabs.TAB_AGENCY_DETAILS) && (
                                        <SaveButtonIcon
                                            isSaving={saveDefaultEmail.isLoading}
                                            success={saveDefaultEmail.isSuccess}
                                            failure={saveDefaultEmail.isError}
                                        />
                                    )}
                                </div>
                            }
                        >
                            <p className="text-gray">
                                Default CC and BCC email addresses for every finalised lease agreement you send:
                            </p>
                            <br />
                            <p className="emails-title">CC:</p>
                            <EmailList
                                name={'ccEmailList'}
                                inputField="ccEmailAddressField"
                                value={values.ccEmailList}
                                form={form}
                            />
                            <p className="emails-title">BCC:</p>
                            <EmailList
                                name={'bccEmailList'}
                                inputField="bccEmailAddressField"
                                value={values.bccEmailList}
                                form={form}
                            />
                        </Panel>
                    </form>
                );
            }}
        </Form>
    );
};

export default DefaultEmailDetailsForm;
