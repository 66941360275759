import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import { Form } from 'react-final-form';

import reminderSmsThread from '../../../assets/images/sms/key-reminder-message.png';
import { FormRadioGroup } from '../form/FormRadioGroup';
import RenewalIntentionIndicator from '../dashboard/agreementList/tables/TableRenewals/RenewalIntentionIndicator';
import ModalDialog from '../../common/components/ModalDialog';
import Button from '../../common/components/Button';

import '../../sass/intentionRenewalModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addOrReplaceKeyInList, closeFlkAKeyReminderModal } from '../../actions/flkAKey';
import { PreLoader } from '../../common/components/PreLoader';
import { getIsReminderModalOpen, getReminderKey } from '../../selectors/flkAKey';
import { getAgencyInfo } from '../../selectors/agency';
import { useRollbarProvider } from '../providers/RollbarProvider';

const FlkAKeyReminderModal = () => {
    const dispatch = useDispatch();
    const Rollbar = useRollbarProvider();

    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState('');
    const isOpen = useSelector(getIsReminderModalOpen);
    const flkAKey = useSelector(getReminderKey);
    const agencyInfo = useSelector(getAgencyInfo);

    const closeModal = () => {
        dispatch(closeFlkAKeyReminderModal());
    };

    useEffect(() => {
        return () => {
            setError('');
        };
    }, [isOpen]);

    const handleInitiateReminder = () => {
        setIsSending(true);
        axios
            .post(`api/flk-a-key/${flkAKey.id}/send-reminder`)
            .then(response => {
                dispatch(addOrReplaceKeyInList(response.data.flkAKey));
                closeModal();
            })
            .catch(error => {
                setError('Something went wrong, please try it again');
                Rollbar.error(
                    'Failed to initiate flk a key reminder',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
            })
            .finally(() => setIsSending(false));
    };

    const renderRightButton = () => {
        return (
            <div>
                <Button type="submit" primary className="btn-upgrade" height={50} disabled={isSending}>
                    Send
                </Button>
            </div>
        );
    };

    const renderLeftButton = () => {
        return (
            <Button secondary className="btn-close" height={50} onClick={closeModal} disabled={isSending}>
                Cancel
            </Button>
        );
    };

    return (
        <ModalDialog
            title="Send SMS reminder notification"
            isOpen={isOpen}
            closeModal={closeModal}
            customBodyAndFooter={true}
            shouldCloseOnOverlayClick={false}
            className="modal-send-renewal-intention-modal-dialog"
        >
            {flkAKey && flkAKey.id && (
                <Form
                    initialValues={{
                        borrower: flkAKey.name
                    }}
                    onSubmit={handleInitiateReminder}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <form id="resend-landlord-information-statement-form" onSubmit={handleSubmit} noValidate>
                                <div className="modal-body">
                                    <div className="renewal-intent-sms-example">
                                        <div className="iphone-container">
                                            <img src={reminderSmsThread} alt="SMS example" />
                                        </div>
                                        <p className="expiry-info">{`* The borrower has 48 hours to reply to the SMS`}</p>
                                    </div>

                                    <div className="send-information-wrapper">
                                        <div className="send-information-email">
                                            <FormRadioGroup
                                                label=""
                                                name="borrower"
                                                radioGroupClass="wrapped-radio-group desktop-bordered"
                                                data={[
                                                    {
                                                        label: `${flkAKey.name} (${flkAKey.mobile})`,
                                                        value: flkAKey.name
                                                    }
                                                ]}
                                                value={(values && values.tenant) || ''}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <h4>Below is what happens depending on the reply:</h4>
                                        <ul>
                                            <li>
                                                <div className="left">
                                                    <RenewalIntentionIndicator
                                                        renewalIntention={{
                                                            isTenantRenewing: 'yes',
                                                            responseReceivedAt: true,
                                                            responseForNewLeaseTerm: '12 months'
                                                        }}
                                                    />
                                                </div>
                                                <div className="right">
                                                    <b>
                                                        They plan to return the key within 24hrs. <br />
                                                        {`Your button turns green and borrower is sent the following message:`}
                                                    </b>
                                                    <p className="quote">{`Thank you. We will see you soon!`}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left">
                                                    <RenewalIntentionIndicator
                                                        renewalIntention={{
                                                            isTenantRenewing: 'unsure',
                                                            responseReceivedAt: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="right">
                                                    <b>
                                                        They will call the agency <br />
                                                        {`Your button turns orange and borrower is sent the following message:`}
                                                    </b>
                                                    <p className="quote">
                                                        {`Thank you. Our office number is ${
                                                            agencyInfo && agencyInfo.details
                                                                ? agencyInfo.details.phone
                                                                : ''
                                                        }`}
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="left">
                                                    <RenewalIntentionIndicator
                                                        renewalIntention={{
                                                            isTenantRenewing: 'no',
                                                            responseReceivedAt: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="right">
                                                    <b>
                                                        They are still working at the premises <br />
                                                        {`Your button turns red and borrower is sent the following message:`}
                                                    </b>
                                                    <p className="quote">
                                                        Can you please reply with a quick update on why & when they will
                                                        be returned.
                                                    </p>
                                                    <p className="quote">
                                                        <i>
                                                            <b>Tenant rescheduled, going back tomorrow.</b>
                                                        </i>
                                                    </p>

                                                    <p className="quote">
                                                        Thank you. I'll update the owner and property manager.
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {error && <div className="error-message">{error}</div>}
                                </div>
                                <div className="modal-footer">
                                    <div>{renderLeftButton()}</div>
                                    <div>{renderRightButton()}</div>
                                </div>
                            </form>
                        );
                    }}
                </Form>
            )}
            {!flkAKey && <PreLoader />}
        </ModalDialog>
    );
};

export default memo(FlkAKeyReminderModal);
