import React, { memo, useState, useEffect } from 'react';
import { Select } from '../../../components/form/Select';
import { values } from 'lodash';
import TenantLinks from './TenantLinks';
import DocumentUpload from './../../shared/DocumentUpload';
import ConditionReport from './ConditionReport';
import { viewUploadedDocument, formatUrl } from '../../../config';
import { updateDocuments } from '../../../actions/lease';
import '../../../sass/documents.scss';
import {
    isLeaseTypeResidentialTenancy,
    isLeaseTypeCommercialLease,
    mappingsToBackend,
    isLeaseTypePm,
    isLeaseLocationSA,
    getDefaultAttachedDocuments
} from '../../../utils/agreementUtils';
import { useSelector, useDispatch } from 'react-redux';
import {
    getLeaseType,
    getLocation,
    getValidationErrors,
    getLeaseDocuments,
    getCommon,
    getDocumentUploadProgress
} from '../../../selectors/lease';
import { getPmMarketingCosts } from '../../../selectors/lease/pmLease';
import { getIsServerBusy } from '../../../selectors/serverRequests';
import { getDocumentCategory } from '../../../utils/documentUtils';

const OWNERS_CORPORATION_RULES = 'owners_corporation_rules';

const Documents = () => {
    const dispatch = useDispatch();
    const documents = useSelector(getLeaseDocuments);
    const lease = useSelector(getCommon);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const validationErrors = useSelector(getValidationErrors);
    const isServerBusy = useSelector(getIsServerBusy);
    const documentUploadProgress = useSelector(getDocumentUploadProgress);
    const pmMarketingCosts = useSelector(getPmMarketingCosts);
    let [selectedOption, setSelectedOption] = useState(getDocumentCategory(leaseType, location).other);
    let [defaultDocs, setDefaultDocs] = useState([]);
    let [backendError, setBackendError] = useState('');

    useEffect(() => {
        setDefaultDocs(getDefaultAttachedDocuments(leaseType, location));
    }, []);

    useEffect(() => {
        let backendError = '';
        if (validationErrors && validationErrors.documents) {
            setBackendError(
                typeof validationErrors.documents === 'string'
                    ? validationErrors.documents
                    : validationErrors.documents.error
            );
        } else {
            setBackendError(backendError);
        }
    }, [validationErrors]);

    const selectOption = selectedOption => {
        setSelectedOption(selectedOption);
    };

    const canSelectDocumentTypes = () => {
        if (isLeaseTypeResidentialTenancy(leaseType) || isLeaseTypeCommercialLease(leaseType)) {
            return true;
        } else if (isLeaseTypePm(leaseType)) {
            return isLeaseLocationSA(location) && pmMarketingCosts?.isProvideMarketingSchedule;
        }
        return false;
    };

    const conditionReport = lease?.leaseAllInfo?.conditionReport;
    const withInspectionManager = !!lease?.leaseAllInfo?.agency?.integrations?.inspectionManager?.apiKey;
    const canPullConditionReport = lease?.leaseAllInfo?.integration?.active && withInspectionManager;
    const showConditionReportContainer = conditionReport || canPullConditionReport;

    return (
        <div className="documents">
            {showConditionReportContainer && <ConditionReport />}
            <div className="add-files-container">
                <h3 className="section-title">Upload Files</h3>
                {canSelectDocumentTypes() && (
                    <div data-test="document-category">
                        <Select
                            defaultValue={selectedOption}
                            options={values(getDocumentCategory(leaseType, location))}
                            className="selectDocumentCategory"
                            isSearchable={false}
                            onChange={selectOption}
                            placeholder="Select file category"
                            classNamePrefix="react-select"
                        />
                    </div>
                )}
                <DocumentUpload
                    dropzoneClassName="add-files-container__document-upload"
                    uploading={isServerBusy}
                    lease={lease}
                    viewDocument={docId => viewUploadedDocument(lease.id, docId)}
                    updateDocuments={files => dispatch(updateDocuments(files))}
                    hideFileCategory={!canSelectDocumentTypes()}
                    // Files with category OWNERS_CORPORATION_RULES are merged into our final pdf
                    rejectEncryptedPdfs={selectedOption?.value === OWNERS_CORPORATION_RULES}
                    flattenPdfs={selectedOption?.value === OWNERS_CORPORATION_RULES}
                    agencyDefault={false}
                    category={selectedOption?.value ?? 'other'}
                    leaseType={mappingsToBackend[leaseType]}
                    documents={documents}
                    defaultDocuments={defaultDocs}
                    backendError={backendError}
                    documentUploadProgress={documentUploadProgress}
                    maxFileSize={100}
                    maxTotalSize={100}
                />
                <TenantLinks lease={lease.leaseAllInfo} />
            </div>
        </div>
    );
};

export default memo(Documents);
