import * as Lease from '../../reducers/lease';
import * as LeaseWithRequest from '../../reducers/leaseWithRequest';

export const UPDATE_SALES_LANDLORD_SOLICITOR_FORM = 'UPDATE_SALES_LANDLORD_SOLICITOR_FORM';

const initState = {
    firstName: '',
    secondName: '',
    address: '',
    fax: '',
    phone: '',
    mobile: '',
    email: '',
    other: '',
    doNotHaveSolicitor: false
};

export default function landlordsSolicitorReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_SALES_LANDLORD_SOLICITOR_FORM: {
            return { ...initState, ...action.data };
        }
        case Lease.EDIT_LEASE_AGREEMENT_FORM: {
            return Object.assign({}, initState, action.payload.agreementInfo.landlordsSolicitor);
        }

        case Lease.CREATE_SALES_LEASE_SUCCESS: {
            if (action.result.data.lease.landlordsSolicitor) {
                return Object.assign({}, initState, action.result.data.lease.landlordsSolicitor);
            } else {
                return Object.assign({}, initState);
            }
        }

        case LeaseWithRequest.LOAD_LEASE_AGREEMENT: {
            return { ...initState, ...action.lease.landlordsSolicitor };
        }
        case Lease.LEASE_LANDLORD_SOLICITOR_SUCCESS: {
            return { ...state, ...action.result.data.lease.landlordsSolicitor };
        }
        default: {
            return state;
        }
    }
}
