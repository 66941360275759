import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, cloneDeep } from 'lodash';
import { ErrorOutlineSharp } from '@material-ui/icons';

import ModalDialog from '../../../common/components/ModalDialog';
import Button from '../../../common/components/Button';

import { closeResendForSigningModal } from '../../../actions/resendLease';
import { resendForSigning } from '../../../actions/lease';

import { getLabel } from '../../../utils/labelUtils';
import { getData, getIsResendingForSigning, getIsResendLeaseModalOpen } from '../../../selectors/resendLease';
import { getAgreementInfo, getSignatories } from '../../../selectors/dashboard/agreementInfo';

import '../../../sass/resendLeaseModal.scss';
import { CheckboxCheck } from '../../form/FormCheckboxCheck';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import SignatoryCheckbox from './SignatoryCheckbox';
import useDoubleSendGuard from '../../../hooks/useDoubleSendGuard';

const ResendAgreementForSigningModal = () => {
    const dispatch = useDispatch();

    const isResendLeaseModalOpen = useSelector(getIsResendLeaseModalOpen);
    const originalData = useSelector(getData);
    const isResendingForSigning = useSelector(getIsResendingForSigning);
    const agreementInfo = useSelector(getAgreementInfo);
    const leaseInfoSignatories = useSelector(getSignatories);
    const doubleSendGuard = useDoubleSendGuard();

    const [formState, setFormState] = useState({
        signatories: leaseInfoSignatories,
        selectAll: agreementInfo.shouldResendForAll,
        sendAttachedDocuments: false
    });

    useEffect(() => {
        if (isResendLeaseModalOpen && agreementInfo && leaseInfoSignatories.length > 0) {
            if (agreementInfo.shouldResendForAll) {
                handleSelectAll(true);
            } else if (!isResendingForSigning) {
                // State should not change while resending
                setFormState({
                    ...formState,
                    signatories: leaseInfoSignatories
                });
            }
        }
    }, [dispatch, isResendLeaseModalOpen, leaseInfoSignatories]);

    const closeModal = () => {
        dispatch(closeResendForSigningModal());
    };

    const handleResendForSigning = values =>
        doubleSendGuard(() => {
            const selectedSignatories = values.signatories.filter(signatory => signatory.send);

            let leaseId = agreementInfo.id;
            let data = {
                ...originalData,
                selectedSignatories: selectedSignatories.map(signatory => signatory.id),
                sendAttachedDocuments: values.sendAttachedDocuments
            };

            dispatch(resendForSigning(leaseId, data));
        });

    const resendInstructions = (shouldResendForAll, amountOfSignatories) => {
        const getAlert = content => {
            return (
                <p className="text-warning">
                    <ErrorOutlineSharp />
                    <strong>Alert!</strong> {content}
                </p>
            );
        };

        const signatory = getLabel('signatoryName', agreementInfo.location, agreementInfo.leaseType);

        if (shouldResendForAll && amountOfSignatories > 1) {
            return getAlert(`You have changed information on the agreement that ${signatory}(s) have already agreed
                    to, to ensure the agreement is valid we recommend it be resent to all ${signatory}s so they can agree to the
                    new information.`);
        } else {
            return getAlert(`If you resend an agreement to a ${signatory} who has ALREADY
                    SIGNED, it will remove their previous signatures and force them to sign again`);
        }
    };

    const handleSelectAll = value => {
        const allSignatories = cloneDeep(leaseInfoSignatories).map(signatory => {
            signatory.send = value;
            return signatory;
        });
        setFormState({
            ...formState,
            signatories: allSignatories,
            selectAll: value
        });
    };

    return (
        <ModalDialog
            title={`Resend ${getLabel(
                'resendLeaseType',
                agreementInfo.location,
                agreementInfo.leaseType
            )} Agreement For Signing`}
            isOpen={isResendLeaseModalOpen}
            closeModal={closeModal}
            customBodyAndFooter={true}
            shouldCloseOnOverlayClick={false}
            className="resend-lease-modal small-modal-dialog"
        >
            <Form
                initialValues={formState}
                mutators={{
                    ...arrayMutators
                }}
                onSubmit={handleResendForSigning}
            >
                {({ handleSubmit }) => {
                    return (
                        <form id="resend-for-signing-form" onSubmit={handleSubmit} noValidate>
                            <div className="modal-body">
                                {resendInstructions(agreementInfo.shouldResendForAll, leaseInfoSignatories.length)}
                                <br />
                                <div className="select-tenants">
                                    <p className="instructions">
                                        {`Select which ${getLabel(
                                            'signatoryName',
                                            agreementInfo.location,
                                            agreementInfo.leaseType
                                        )} should receive the link to sign the ${getLabel(
                                            'resendDocName',
                                            agreementInfo.location,
                                            agreementInfo.leaseType
                                        )}`}
                                    </p>
                                    <CheckboxCheck name="selectAll" onClick={handleSelectAll} label="Select All" />
                                    <FieldArray
                                        name="signatories"
                                        validate={values => {
                                            const isValid = filter(values, person => person.send).length;

                                            if (!isValid) {
                                                return `You must select at least one ${getLabel(
                                                    'signatoryName',
                                                    agreementInfo.location,
                                                    agreementInfo.leaseType
                                                )} to send the agreement to.`;
                                            }
                                            return undefined;
                                        }}
                                    >
                                        {({ fields, meta: { error } }) => (
                                            <React.Fragment>
                                                {fields.map((name, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <SignatoryCheckbox
                                                                signatory={fields.value[index]}
                                                                name={`signatories[${index}].send`}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                                <div className="has-error">{error}</div>
                                            </React.Fragment>
                                        )}
                                    </FieldArray>
                                </div>
                                <div className="resend-email">
                                    <p className="instructions">
                                        {`Do you want to re-send the email with all attached documents to the selected ${getLabel(
                                            'signatoryNamePlural',
                                            agreementInfo.location,
                                            agreementInfo.leaseType
                                        )}?`}
                                    </p>
                                    <CheckboxCheck
                                        className="send-documents-checkbox"
                                        label="Re-send email"
                                        name="sendAttachedDocuments"
                                    />
                                </div>
                            </div>

                            <div className="modal-footer">
                                <div>
                                    <Button
                                        secondary
                                        className="btn-close"
                                        height={40}
                                        onClick={closeModal}
                                        disabled={isResendingForSigning}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        primary
                                        className="btn-upgrade"
                                        height={40}
                                        type="submit"
                                        disabled={isResendingForSigning}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
};

export default memo(ResendAgreementForSigningModal);
