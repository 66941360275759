import React from 'react';
import '../../../sass/dashboardContentTable.scss';

import { Table, Th, Tr, Thead, Td } from 'reactable';
import AgentLogo from '../../AgentLogo';
import { useDispatch } from 'react-redux';
import { openKeyForm } from '../../../actions/flkAKey';
import { DOCUMENT_READ_ONLY_MODE } from '../../../config';
import FlkAKeyMoreOptionBlock from './cells/FlkAKeyMoreOptionBlock';
import { FLK_A_KEY_STATUS_DRAFT } from '../../../actions/dashboard';

const CheckInTable = ({ flkAKeyList }) => {
    const dispatch = useDispatch();

    function openKeyFormView(event, key) {
        event.stopPropagation();
        dispatch(openKeyForm(key, DOCUMENT_READ_ONLY_MODE));
    }
    return (
        <Table>
            <Thead>
                <Th column="agentLogo">AGENT</Th>
                <Th column="key">KEY#</Th>
                <Th column="address">ADDRESS</Th>
                <Th column="checkedIn">Checked In</Th>
                <Th column="company">Company</Th>
                <Th column="contact">Contact</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {Array.isArray(flkAKeyList) &&
                flkAKeyList.map((flkAKey, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openKeyFormView(event, flkAKey);
                            }}
                        >
                            <Td column="agentLogo">
                                <AgentLogo agent={flkAKey.agent} />
                            </Td>
                            <Td column="key">
                                <b>{flkAKey.key}</b>
                            </Td>
                            <Td column="address">
                                <b>{flkAKey.address}</b>
                            </Td>
                            <Td column="checkedIn">
                                <b>{flkAKey.returnedDateFormatted}</b>
                            </Td>
                            <Td column="company">
                                <b>{flkAKey.company}</b>
                            </Td>
                            <Td column="contact">
                                <b>{flkAKey.name}</b>
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <FlkAKeyMoreOptionBlock flkAKey={flkAKey} />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default CheckInTable;
