import React, { Component } from 'react';
import ModalDialog from '../../common/components/ModalDialog';
import EditTenant from './EditTenant';
import '../../sass/editModal.scss';

class EditTenantsModal extends Component {
    render() {
        const { isOpen, closeEditTenantsModal, finishEdit } = this.props;
        return (
            <ModalDialog
                title="Edit Tenants"
                isOpen={isOpen}
                closeModal={closeEditTenantsModal}
                shouldCloseOnOverlayClick={false}
                className="edit-signatory-modal"
            >
                <div>
                    <EditTenant />
                </div>
            </ModalDialog>
        );
    }
}

export default EditTenantsModal;
