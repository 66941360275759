import React from 'react';
import Card, { CardShadow, CardStyles } from '../../../../common/components/cards/Card';
import Icon, { Icons } from '../../../../common/components/Icon';

import styles from './UploadedDocumentMobile.module.scss';

type Props = { documentName: string };

const UploadedDocumentMobile: React.FC<Props> = ({ documentName }) => {
    return (
        <Card style={CardStyles.SQUARE} shadow={CardShadow.SMALL} animate={false} className={styles.container}>
            <Icon icon={Icons.PDF} />
            {documentName}
        </Card>
    );
};

export default UploadedDocumentMobile;
