import { getMissedStep, isCompletedAndValidatedAllSteps } from '../utils/agreementUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../selectors/user';
import { getNotifications } from '../selectors/notication';
import { initialiseStateAgreement, editLeaseAgreement } from '../actions/lease';
import * as dashboard from '../actions/dashboard';
import { REQUEST_TO_CHANGE_STATUS_RESOLVED, isMobileWidth } from '../config';
import * as leaseActions from '../reducers/leaseWithRequest';
import * as lease from '../actions/lease';
import { getMobileViewEnabled } from '../selectors/dashboard';

export function useOpenAgreement(agreementInfo, leaseType) {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const mobileViewEnabled = useSelector(getMobileViewEnabled);
    const requests = useSelector(getNotifications);

    const openModalInfoAgreement = (agreementInfo, leaseType) => {
        let isDraft = dashboard.LEASE_STATUS_DRAFT === agreementInfo.status;
        let isCompleted = isCompletedAndValidatedAllSteps(agreementInfo);

        window.history.pushState({}, '', `/dashboard/agreement/${agreementInfo.id}`);
        if ((isDraft && !isCompleted) || (mobileViewEnabled && isMobileWidth() && isDraft)) {
            let step = getMissedStep(agreementInfo, leaseType, agreementInfo.location);
            dispatch(initialiseStateAgreement(agreementInfo));
            dispatch(editLeaseAgreement(user, agreementInfo, step));
        } else {
            getUnresolvedChangeRequest(agreementInfo.id).then(request => {
                if (request) {
                    openNotification(request);
                } else {
                    dispatch(dashboard.openAgreementInfoModal(agreementInfo));
                }
            });
        }
    };

    const getUnresolvedChangeRequest = id => {
        let unresolvedRequest = undefined;
        requests.map(request => {
            if (request.lease === id) {
                if (typeof request.status === 'undefined' || request.status !== REQUEST_TO_CHANGE_STATUS_RESOLVED) {
                    unresolvedRequest = request;
                }
            }
        });
        return new Promise(function (resolve) {
            resolve(unresolvedRequest);
        });
    };

    const openNotification = request => {
        dispatch(leaseActions.setActiveRequest(request));
        dispatch(lease.getInfoLease(request.lease)).then(() => {
            dispatch(leaseActions.showForm());
        });
    };

    return {
        openModalInfoAgreement,
        getUnresolvedChangeRequest,
        openNotification,
    };
}
