import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AgentLogo extends Component {
    render() {
        return (
            <div className="agent-logo">
                <Link to="../../../user" className="userAvatar">
                    {!!this.props.agent.image && this.props.agent.image !== '' && (
                        <div className="profile-img">
                            <div
                                className="profile-img-with-background"
                                style={{ backgroundImage: 'url(' + this.props.agent.image + ')' }}
                            />
                        </div>
                    )}
                    {!this.props.agent.image && (
                        <div className="no-photo">
                            <span className="name-letters">
                                {this.props.agent.firstName ? this.props.agent.firstName.substr(0, 1) : ''}
                                {this.props.agent.firstName ? this.props.agent.secondName.substr(0, 1) : ''}
                            </span>
                        </div>
                    )}
                </Link>
            </div>
        );
    }
}
export default AgentLogo;
