import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop, identity } from 'lodash';
import '../../sass/searchBarResults.scss';
import { escapeRegExp } from 'lodash';

const NO_KEYS_RESULTS_MESSAGE = 'No keys match your search.';

/**
 * This functions runs through the highlights we get from mongo atlas search
 * This then highlights the text that the search has matched with
 * If there are no highlights it will use the query to highlight what was typed in in the results
 * @param highlights
 * @param text
 * @param path
 * @param query
 * @returns {JSX.Element|*}
 */
function highlightQuery(highlights, text, path, query) {
    if (!highlights && !query) {
        return text;
    }
    let newText = text;
    if (highlights && highlights.length > 0) {
        highlights.forEach(highlight => {
            if (highlight.texts.length > 0 && highlight.path === path) {
                highlight.texts.forEach(highlightText => {
                    if (highlightText.type === 'hit') {
                        const reg = new RegExp(escapeRegExp(highlightText.value), 'gi');
                        newText = newText.replace(reg, `<span class='highlighted-text'>${highlightText.value}</span>`);
                    }
                });
            }
        });
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: newText
                }}
            />
        );
    } else {
        newText = replaceTextHighlight(newText, query);
    }

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: newText
            }}
        />
    );
}

function replaceTextHighlight(text, replaceValue) {
    let newText = text;
    if (replaceValue) {
        const reg = new RegExp(escapeRegExp(replaceValue), 'gi');
        if (newText) {
            newText = newText.replace(reg, `<span class='highlighted-text'>${replaceValue}</span>`);
        }
    }
    return newText;
}

function KeySearchBarResults({
    items,
    highlightedIndex,
    getItemProps,
    menuRef,
    separatorIndex,
    displayMessage,
    query,
    ...props
}) {
    const hasItems = items.length > 0;
    let keyResults = [];

    function getSearchItem(item, index) {
        return (
            <li
                {...getItemProps({
                    key: item.id,
                    index,
                    item,
                    className: classnames({
                        highlighted: highlightedIndex === index
                    })
                })}
            >
                <strong>
                    {highlightQuery(item.highlights, item.key, 'key', query)}{' '}
                    {highlightQuery(item.highlights, item.address, 'address', query)}
                </strong>
            </li>
        );
    }

    if (hasItems) {
        // pre-render the items...
        items.map((item, index) => {
            keyResults.push(getSearchItem(item, index));
        });

        // ... so we can inject the separator
        if (separatorIndex > 0 && separatorIndex < items.length) {
            keyResults.splice(separatorIndex, 0, <li key="___SEPARATOR___" className="separator" />);
        }
    }

    return (
        <div>
            <ul {...props} className="search-bar-results" ref={menuRef}>
                <li className="result-section-title">
                    <span>Keys</span> -{' '}
                    <small>Displaying top 10 results, enter more specific search information if not shown below</small>
                </li>
                {keyResults.length > 0 ? (
                    keyResults
                ) : (
                    <li className="no-results">
                        <span>{displayMessage ? displayMessage : NO_KEYS_RESULTS_MESSAGE}</span>
                    </li>
                )}
            </ul>
        </div>
    );
}

KeySearchBarResults.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            key: PropTypes.string,
            address: PropTypes.string
        })
    ),
    highlightedIndex: PropTypes.number,
    getItemProps: PropTypes.func,
    menuRef: PropTypes.func,
    separatorIndex: PropTypes.number,
    displayMessage: PropTypes.string
};

KeySearchBarResults.defaultProps = {
    items: [],
    highlightedIndex: -1,
    getItemProps: identity,
    menuRef: noop,
    separatorIndex: -1,
    displayError: false
};

export default KeySearchBarResults;
