import { createSelector } from 'reselect';

export const getLeaseWithRequest = state => {
    return state.leaseWithRequest;
};

export const getActiveRequest = createSelector(
    [getLeaseWithRequest],
    leaseWithRequest => leaseWithRequest.activeRequest
);

export const getLeaseWithRequestsStep = createSelector(
    [getLeaseWithRequest],
    leaseWithRequest => leaseWithRequest.step
);
