import merge from 'deepmerge';
import * as Lease from './lease';
import { FINISH_COMPLETION_PROCESS_FAIL, FINISH_COMPLETION_PROCESS_REQUEST } from '../reducers/dashboard';
import { has } from 'lodash';

const initState = {
    form: {
        renewalAlert: 'none',
        renewalDays: 1,
        toNotificationList: [],
        ccNotificationList: [],
        bccNotificationList: []
    },
    completionFormErrors: {},
    completionErrors: '',
    witnesses: [],
    isGettingWitnessSignature: false,
    isGettingAgentSignature: false,
    isGettingWitnessSignatureSuccess: true,
    witnessSignature: {},
    agentSignature: {},
    witnessSignatureError: ''
};

export const COMPLETION_FORM_ADD_EMAIL = 'COMPLETION_FORM_ADD_EMAIL';
export const COMPLETION_FORM_SET_EMAIL = 'COMPLETION_FORM_SET_EMAIL';
export const COMPLETION_FORM_DELETE_EMAIL = 'COMPLETION_FORM_DELETE_EMAIL';
export const HANDLE_COMPLETION_FORM_CHANGE = 'HANDLE_COMPLETION_FORM_CHANGE';
export const COMPLETION_FORM_RESET_TO_DEFAULT = 'COMPLETION_FORM_RESET_TO_DEFAULT';
export const GET_WITNESSES_REQUEST = 'GET_WITNESSES_REQUEST';
export const GET_WITNESSES_SUCCESS = 'GET_WITNESSES_SUCCESS';
export const GET_WITNESSES_FAILURE = 'GET_WITNESSES_FAILURE';
export const GET_WITNESSES_SIGNATURE_REQUEST = 'GET_WITNESSES_SIGNATURE_REQUEST';
export const GET_WITNESSES_SIGNATURE_SUCCESS = 'GET_WITNESSES_SIGNATURE_SUCCESS';
export const GET_WITNESSES_SIGNATURE_FAILURE = 'GET_WITNESSES_SIGNATURE_FAILURE';
export const GET_AGENT_SIGNATURE_REQUEST = 'GET_AGENT_SIGNATURE_REQUEST';
export const GET_AGENT_SIGNATURE_SUCCESS = 'GET_AGENT_SIGNATURE_SUCCESS';
export const GET_AGENT_SIGNATURE_FAILURE = 'GET_AGENT_SIGNATURE_FAILURE';

export function completion(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case COMPLETION_FORM_SET_EMAIL: {
            let form = {
                ...state.form,
                [action.payload.listType]: action.payload.email
            };

            return {
                ...state,
                form
            };
        }
        case COMPLETION_FORM_ADD_EMAIL: {
            let form = {
                ...state.form,
                [action.payload.listType]: [...state.form[action.payload.listType], action.payload.email]
            };

            return {
                ...state,
                form
            };
        }
        case COMPLETION_FORM_DELETE_EMAIL: {
            let index = action.payload.index;
            let form = {
                ...state.form,
                [action.payload.listType]: [
                    ...state.form[action.payload.listType].slice(0, index),
                    ...state.form[action.payload.listType].slice(index + 1, state.form[action.payload.listType].length)
                ]
            };

            return {
                ...state,
                form
            };
        }
        case HANDLE_COMPLETION_FORM_CHANGE: {
            return {
                ...state,
                form: merge(state.form, action.data)
            };
        }
        case COMPLETION_FORM_RESET_TO_DEFAULT: {
            return initState;
        }
        case FINISH_COMPLETION_PROCESS_REQUEST: {
            return {
                ...state,
                completionFormErrors: {},
                completionErrors: ''
            };
        }
        case FINISH_COMPLETION_PROCESS_FAIL: {
            if (action.error.response) {
                if (action.error.response.status === 400) {
                    return {
                        ...state,
                        completionFormErrors: Lease.processServerErrors(action.error.response.data.errors, true)
                    };
                } else {
                    return {
                        ...state,
                        completionErrors: action.error.response.data.error
                    };
                }
            } else {
                return {
                    ...state,
                    completionErrors: true
                };
            }
        }
        case GET_WITNESSES_SUCCESS: {
            return {
                ...state,
                witnesses: action.result.data.witnesses
            };
        }
        case GET_WITNESSES_FAILURE: {
            return {
                ...state,
                witnesses: []
            };
        }
        case GET_WITNESSES_SIGNATURE_REQUEST: {
            return {
                ...state,
                isGettingWitnessSignature: true
            };
        }
        case GET_WITNESSES_SIGNATURE_SUCCESS: {
            action.result.data.agencySignature.type = 'witness';
            return {
                ...state,
                isGettingWitnessSignature: false,
                isGettingWitnessSignatureSuccess: true,
                witnessSignature: action.result.data.agencySignature,
                witnessSignatureError: ''
            };
        }
        case GET_WITNESSES_SIGNATURE_FAILURE: {
            return {
                ...state,
                isGettingWitnessSignature: false,
                isGettingWitnessSignatureSuccess: false,
                witnessSignatureError: has(action, 'error.response.data.error') ? action.error.response.data.error : '',
                witnessSignature: {}
            };
        }
        case GET_AGENT_SIGNATURE_REQUEST: {
            return {
                ...state,
                isGettingAgentSignature: true
            };
        }
        case GET_AGENT_SIGNATURE_FAILURE: {
            return {
                ...state,
                agentSignature: null,
                isGettingAgentSignature: false
            };
        }
        case GET_AGENT_SIGNATURE_SUCCESS: {
            return {
                ...state,
                agentSignature: action.result.data.agencySignature,
                isGettingAgentSignature: false
            };
        }
    }

    return newState;
}
